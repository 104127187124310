import { actionTypes } from '../actions/idx_admin_sla_rule.ac';

const initialState = {
  error: null,
  slaRules: null,
  isFetching: true
};

export const idxAdminSLARule = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ADMIN_SLA_RULES:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.RECEIVED_IDX_ADMIN_SLA_RULES:
      return {
        ...state,
        error: null,
        isFetching: false,
        slaRules: action.data
      };

    case actionTypes.SET_IDX_ADMIN_SLA_RULES:
      return {
        ...state,
        slaRules: action.data
      };

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_SLA_RULES:
      return {
        ...state,
        error: true,
        slaRules: null,
        isFetching: false
      };

    default:
      return state;
  }
};
