import { actionTypes } from '../actions/activity_log_audit.ac';

const {
  QUERY_ACTIVITY_LOG_AUDIT,
  QUERY_ACTIVITY_LOG_AUDIT_SUCCESS,
  QUERY_ACTIVITY_LOG_AUDIT_FAILURE,
} = actionTypes;

export const initialState = {
  results: {
    data: [],
    pagination: {},
    isFetching: false,
    error: null,
  },
};

export function activityLogAudit(state = initialState, action) {
  switch (action.type) {
    case QUERY_ACTIVITY_LOG_AUDIT:
      return Object.assign({}, state, {
        results: {
          ...state.results,
          isFetching: true,
        },
      });
    case QUERY_ACTIVITY_LOG_AUDIT_SUCCESS:
      return Object.assign({}, state, {
        results: {
          isFetching: false,
          data: action.data || [],
          pagination: action.pagination || {},
          error: null,
        },
      });
    case QUERY_ACTIVITY_LOG_AUDIT_FAILURE:
      return Object.assign({}, state, {
        results: {
          ...state.results,
          isFetching: false,
          error: action.error,
        },
      });
    default:
      return state;
  }
}
