export function docSubmissionsInView(
  state={
    isFetching:false,
    idn: null,
    error: null,
    records: null
  },
  action
){
  switch (action.type) {
    case 'REQUEST_DOC_SUBMISSIONS':
      return Object.assign({}, state, {
        records: null,
        error: null,
        isFetching: true,
        idn: action.idn
      })
    case 'FAILED_RECEIVED_DOC_SUBMISSIONS':
      return Object.assign({}, state, {
        records: null,
        error: action.error,
        isFetching: false
      })
    case 'RECEIVED_DOC_SUBMISSIONS':
      return Object.assign({}, state, {
         records: action.records,
         error: null,
         isFetching: false
       })

    case 'RESET_DOC_SUBMISSIONS':
      return Object.assign({}, state, {
        records: null,
        error: null,
        idn: null,
        isFetching: false
      })


    default:
      return state
  }
}

export function docSubmissionActivity(
  state={
    submissionFiles: [],
    state:'new',
    error:false,
    successPkg: null
  },
  action
){
  switch (action.type) {
    case 'ATTACH_DOC_SUBMISSION_FILES_TO_STORE':
      return Object.assign({}, state, {
        submissionFiles: action.submissionFiles
      })

    case 'CREATE_DOC_SUBMISSION_ACTIVITY':
      return Object.assign({}, state, {
        state:'inprog'
      })
    case 'FAILED_DOC_SUBMISSION_ACTIVITY':
      return Object.assign({}, state, {
        state:'error',
        error: action.error
      })

    //createDocSubmission (faxDoc creation) data service successful
    case 'SUCCESS_DOC_SUBMISSION_ACTIVITY':
      return Object.assign({}, state, {
        state:'readyForUploads',
        successPkg: action.successPkg,
        submissionFiles: action.submissionFiles,
        error: false
      })
    case 'BEGIN_UPLOAD_FILES_FOR_SUBMISSION':
      return Object.assign({}, state, {
        state:'uploadsInProgress',
      })

    case 'COMPLETED_UPLOAD_FILES_FOR_SUBMISSION':
      return Object.assign({}, state, {
        state:'uploadsComplete',
      })

    case 'FAILED_UPLOAD_FILES_FOR_SUBMISSION':
      return Object.assign({}, state, {
        state:'uploadsFailed',
      })

    case 'RESET_DOC_SUBMISSION_ACTIVITY':
      return Object.assign({}, state, {
        submissionFiles: [],
        state:'new',
        successPkg: null,
        error:false
      })
    default:
      return state
  }
}
