import { FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac'
import {
  fetchTodos_ac,
  locallyUpdateTodoDoc_ac,
  toggleAllCards_ac
} from '../../actions/todos.ac.js'
import Loader from '../Shared/Loader'
// import TodosSummaryBoard from './TodosSummaryBoard' Dont render for now
import { TodosTableOnly } from './TodosLandingTable'
import TodosSummaryTree from './TodosSummaryTree'

const styles = theme => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  boardContainer: {
    height: 'calc(100% - 8px)',
    marginTop: 8,
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit * 0.5
  }
})

class TodosTableWrapper extends React.Component {
  constructor() {
    super()

    this.state = {
      lastToggleAllCards: true,
      currentPageNum: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.filters, this.props.filters)) {
      this.fetchByFilters(this.props.filters)
    }
  }

  handleToggleCards = () => {
    this.props.toggleAllCards_ac(!this.state.lastToggleAllCards)
    this.setState({ lastToggleAllCards: !this.state.lastToggleAllCards })
  }

  onLocalUpdate = (posInSet, todoDoc) => {
    this.props.locallyUpdateTodoDoc_ac(posInSet, todoDoc)
  }

  fetchByFilters = (filters, page = 0) => {
    this.props.fetchTodos_ac({
      ...filters,
      do_not_save: true,
      page
    })
  }

  onPageChange = e => {
    const { value } = e.target

    if (value !== this.state.currentPageNum) {
      this.fetchByFilters(this.props.filters, value)
      this.setState({
        currentPageNum: value
      })
    }
  }

  render() {
    const { todosInView } = this.props

    if (todosInView.isFetching) {
      return <Loader />
    }

    if (todosInView.error) {
      return <Typography color="error">...error fetching tasks</Typography>
    }

    if (!todosInView.records) {
      return (
        <Typography color="textSecondary">
          Click on a tree node to view corresponding tasks.
        </Typography>
      )
    }

    const { pagination: pg } = todosInView
    let pageSelector = ''
    let pageStatement = ''

    if (pg) {
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Records',
        'Page ' +
          (this.state.currentPageNum + 1) +
          ' of ' +
          pg.totalPagesAvailable,
        'From Selected Filters'
      ].join(' - ')
      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form autoComplete="off">
            <FormControl>
              <Select
                onChange={this.onPageChange}
                displayEmpty
                value={this.state.currentPageNum}
                name="age">
                {_.range(0, pg.totalPagesAvailable).map(pgNum => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        )
      }
    }

    return (
      <TodosTableOnly
        hideSwitch
        todosInView={todosInView}
        onLocalUpdate={this.onLocalUpdate}
        onToggleCards={this.handleToggleCards}
        pageStatement={pageStatement}
        pageSelector={pageSelector}
      />
    )
  }
}

const mapStateToPropsForWrapper = stateFromStore => ({
  todosInView: stateFromStore.todosInView
})

const mapDispatchToPropsForWrapper = dispatch =>
  bindActionCreators(
    {
      fetchTodos_ac,
      locallyUpdateTodoDoc_ac,
      toggleAllCards_ac
    },
    dispatch
  )

const TodosTableWrapperWithState = connect(
  mapStateToPropsForWrapper,
  mapDispatchToPropsForWrapper
)(TodosTableWrapper)

class TodosSummaryLanding extends React.Component {
  constructor() {
    super()

    this.state = {
      todoStateRefMap: null,
      defaultFilters: {
        by: 'filters'
        //ownership: ['all'],
        //status: []
      },
      filters: null
    }
  }

  componentDidMount() {
    const { me, lookups, lookups_ac, setTitle_ac } = this.props

    if (!me.user) return

    setTitle_ac('DF Task Summary')

    if (!lookups.ink) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (!this.state.todoStateRefMap) {
      const { TODO_STATE_REFERENCE: todoStateRef } = lookups.ink.k

      this.setState({
        todoStateRefMap: todoStateRef.reduce(
          (acc, curr) => Object.assign(acc, { [curr.code]: { ...curr } }),
          {}
        )
      })
    }
  }

  componentDidUpdate() {
    const { me, lookups, lookups_ac } = this.props

    if (!me.user) return

    if (!lookups.ink) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (!this.state.todoStateRefMap) {
      const { TODO_STATE_REFERENCE: todoStateRef } = lookups.ink.k

      this.setState({
        todoStateRefMap: todoStateRef.reduce(
          (acc, curr) => Object.assign(acc, { [curr.code]: { ...curr } }),
          {}
        )
      })
    }
  }

  applyFilters = filters => {
    this.setState(state => ({
      filters: {
        ...state.defaultFilters,
        ...filters
      }
    }))
  }

  render() {
    const { todoStateRefMap } = this.state
    const { lookups, classes } = this.props

    if (!lookups.ink) {
      return <Loader />
    }

    return (
      <div className={classes.mainContainer}>
        <TodosSummaryTree
          width={324}
          todoStateRefMap={todoStateRefMap}
          applyFilters={this.applyFilters}
        />
        <div
          className={classes.boardContainer}
          style={{ width: 'calc(100% - 324px)' }}>
          {/* <TodosSummaryBoard todoStateRefMap={todoStateRefMap} /> Dont render for now */}
          <TodosTableWrapperWithState filters={this.state.filters} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TodosSummaryLanding))
)
