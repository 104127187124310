/* eslint no-unused-vars: "off" */
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { push } from 'connected-react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { bindActionCreators } from 'redux'
import DateUtil from '../../util/dateProcessing'

class BaseLandingTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      filterSequence: ['filter_name'],
      filters: {
        replace_this_with_filter_name: {
          lbl: 'Replace with Filter Label',
          kind: 'checkbox',
          options: [
            { code: 'opt1', checked: true },
            { code: 'opt2', checked: false }
          ]
        }
      }
    }
    this.onFilterClearField = this.onFilterClearField.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onFilterToggle = this.onFilterToggle.bind(this)
    this.fetch = this.fetch.bind(this)
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this)
    this.onUserFilterUpdated = this.onUserFilterUpdated.bind(this)
  }

  componentDidMount() {
    const { records, isFetching } = this.state
    if (isFetching) return
    if (records === null) this.fetch()
  }

  componentDidUpdate() {
    const { records, isFetching } = this.state
    if (isFetching) return
    if (records === null) this.fetch()
  }

  fetchWithPageReset() {
    //construct payload based on filters
    let selectedFilters = {}
    this.state.filterSequence.forEach(fName => {
      const f = this.state.filters[fName]
      switch (f.kind) {
        case 'checkbox':
          selectedFilters[fName] = f.options
            .filter(o => o.checked)
            .map(o => o.code)
          break
        case 'users':
          if (f.values.length > 0) {
            selectedFilters[fName] = f.values.map(v => v.email)
          }
          break
        default:
          break
      }
    })

    this.props.fetchDocRequests_ac({
      by: 'filters',
      val: selectedFilters,
      page: 0
    })
    return
  }

  onUserFilterUpdated(filterName, values) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          values
        }
      }
    })
  }

  onFilterChange = filterName => evt => {
    let sf = _.cloneDeep(this.state.filters[filterName])
    switch (sf.kind) {
      case 'text':
        sf.value = evt.target.value
        break
      case 'date':
        sf.value = evt.target.value
        sf.error = DateUtil.isValidDate(evt.target.value) ? '' : true
        sf.dirty = true
        break
      case 'dateTime':
        sf.value = evt.target.value
        sf.error = DateUtil.isValidDateTime(evt.target.value) ? '' : true
        sf.dirty = true
        break
      default:
        //checkboxes
        sf.options = sf.options.map(s => {
          if (evt.target.value === s.code) {
            s.checked = evt.target.checked
          }
          return s
        })
        break
    }
    this.setState({ filters: { ...this.state.filters, [filterName]: sf } })
  }

  onFilterClearField = filterName => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          value: ''
        }
      }
    })
  }

  //only applies to checkbox filters
  onFilterToggle(filterName, on) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          options: this.state.filters[filterName].options.map(o => {
            o.checked = on
            return o
          })
        }
      }
    })
  }

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) })
  }

  render() {
    const { records } = this.state
    const { classes } = this.props

    return (
      <Grid container spacing={8}>
        <Grid item xs={12} sm={7}>
          <main className={classes.content}>
            <label className={classes.label}>
              Overwrite this with Child Component
            </label>
            <div className={classes.scrollArea}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Week of 2019</TableCell>
                    <TableCell
                      align="right"
                      title="Count of Non-Manual WorkOrders scheduled">
                      Total Schedueld in DT
                    </TableCell>
                    <TableCell
                      align="right"
                      title="Reached ALLGOOD or GSO Shipment Receipt">
                      Delivered in DT
                    </TableCell>
                    {/*
                  <TableCell align="right">DF Valid (% of Delivered)</TableCell>
                  */}
                    <TableCell
                      align="right"
                      title="Unique # of Confirmation Work Units created For HDMS Work Order With Schedule Date in the Week ">
                      Confirmation Work Units Created (% of Delivered)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.map(r => (
                    <TableRow key={r._id}>
                      <TableCell component="th" scope="row" />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </main>
        </Grid>

        <Grid item xs={12} sm={5}>
          {records && (
            <main className={classes.content}>
              <label className={classes.label}>TITLE 2</label>
              <div className={classes.scrollArea} />
            </main>
          )}
        </Grid>
      </Grid>
    )
  }
}

BaseLandingTemplate.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default BaseLandingTemplate
