import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

const styles = theme => ({
  ul: {
    padding: 2,
    display: 'none'
  },
  li: {
    margin: 12,
    fontSize: 12,
    float: 'left'
  },

  wp: {
    fontSize: '.82em',
    margin: '2px 8px 2px 8px',
    padding: 2
  },
  patientNm: {
    fontSize: '.92em',
    margin: '2px 8px 2px 8px',
    padding: 2
  },
  /* img stuff */
  imgHolder: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    border: '1px dashed #dcdcce',
    marginTop: 6,
    marginBottom: 6,
    paddingTop: 6,
    paddingBottom: 6
  },

  rotHolder: {
    border: '1px solid #dcdcce',
    backgroundColor: '#f0ffff',
    padding: 3
  },
  rot0: { transform: 'rotate(0deg)' },
  rot1: { transform: 'rotate(90deg)' },
  rot2: { transform: 'rotate(180deg)' },
  rot3: { transform: 'rotate(270deg)' },

  fill: { objectFit: 'fill' },
  contain: {
    //objectFit: 'contain',
    //width: '95%',
    border: '1px solid #9c27b0'
  },
  topInfo: {
    //border: '1px solid #9c27b0',
    paddingLeft: 0
  },
  bottomInfo: {
    //border: '1px solid #9c27b0',
    paddingLeft: 0
  },
  pageNum: {
    float: 'right',
    color: 'gray',
    fontSize: 11,
    fontWeight: 500
  }
})

class ImgHolder extends React.Component {
  constructor(props) {
    super(props)
    this.state = { dims: null }
  }

  getNaturalDims() {
    this.rawImg = new Image()
    this.rawImg.onload = () => {
      this.setState({
        dims: {
          w: this.rawImg.width,
          h: this.rawImg.height,
          ratio: this.rawImg.width / this.rawImg.height
        }
      })
    }
    this.rawImg.src = this.props.page.imgSrc
    this.onAnalyze = this.onAnalyze.bind(this)
  }

  stretchHeight(w) {
    //assumes width got streched, reverse the ratio , keeping w the same
    let cush = 0.25
    let ratio_reversed = 1 / this.state.dims.ratio
    let stretchedHeight = Math.floor((w / ratio_reversed) * (1 - cush))
    return stretchedHeight
  }
  onAnalyze() {
    if (this.props.onAnalyze) {
      this.props.onAnalyze(this.props.pageIdx)
    }
    return
  }

  render() {
    if (!this.state.dims) {
      this.getNaturalDims()
      return <div>...</div>
    }

    const { classes } = this.props

    let page = this.props.page

    let imgHolderStyle = {
      width: Math.floor(0.95 * this.props.width) //parent widht from ContainerDimensions
    }

    let rotHolderStyle = {
      width: imgHolderStyle.width - 12
    }

    let imgStyle = {
      width: imgHolderStyle.width - 24
    }

    if (page['rotation-pref'] === 1 || page['rotation-pref'] === 3) {
      rotHolderStyle = { height: imgHolderStyle.width - 12 }
      imgStyle = {
        height: imgHolderStyle.width - 24,
        width: imgHolderStyle.width - 24
      }
    } else {
      if (this.state.dims.ratio > 1) {
        imgStyle.height = this.stretchHeight(imgStyle.width)
      }
    }
    let topInfoStyle = { width: rotHolderStyle.width }

    return (
      <div style={imgHolderStyle} className={classes.imgHolder}>
        <div style={topInfoStyle} className={classes.topInfo}>
          <Grid container spacing={8}>
            <Grid item xs={9}>
              <ul className={classes.ul}>
                <li className={classes.li}>Parent width: {this.props.width}</li>
                <li className={classes.li}>
                  imgHolder width: {imgHolderStyle.width}
                </li>
                <li className={classes.li}>
                  rotHolder width: {rotHolderStyle.width}
                  Pref:{page['rotation-pref']}
                </li>
                <li className={classes.li}>img width: {imgStyle.width}</li>
                <li className={classes.li}>
                  orig: {this.state.dims.w} X {this.state.dims.h}
                  ratio: {this.state.dims.ratio.toFixed(2)}
                </li>
              </ul>
            </Grid>
            <Grid item xs={3} style={{ float: 'right' }}>
              <div className={classes.pageNum}>
                {this.props.allowAnalyze && (
                  <Button
                    size="small"
                    onClick={this.onAnalyze}
                    style={{
                      cursor: 'pointer',
                      color: 'blue',
                      marginRight: 8
                    }}>
                    Analyze Page {this.props.pageIdx + 1}
                  </Button>
                )}
                Top of Page {this.props.pageIdx + 1} of {this.props.pageCount}
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={rotHolderStyle}
          className={[
            classes.rotHolder,
            classes['rot' + page['rotation-pref']]
          ].join(' ')}>
          <img alt="pageImg" style={imgStyle} src={page.imgSrc} />
        </div>
        <div style={topInfoStyle} className={classes.bottomInfo}>
          <Grid container spacing={8}>
            <Grid item xs={9} />
            <Grid item xs={3} style={{ float: 'right' }}>
              <div className={classes.pageNum}>
                Bottom of Page {this.props.pageIdx + 1} of{' '}
                {this.props.pageCount}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

ImgHolder.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ImgHolder)
