import {
  Grid,
  Button,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel
} from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import {
  DoctorSearch,
  SalespersonSearch
} from '../../Lookups/HDMSLookupsSearch'

const styles = theme => ({
  formContainer: {
    padding: 8,
    border: '1px solid #00000020',
    borderRadius: 2,
    margin: '8px 0'
  },
  formSet: {
    marginBottom: 24,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 200
  },
  formSetHeader: {
    textTransform: 'uppercase',
    fontWeight: 600
  },
  formElement: {
    marginBottom: 8,
    marginTop: 8,
    minWidth: 200
  },
  formFoot: {
    marginBottom: 8,
    borderTop: '1px solid #00000020'
  }
})

const PLACE_OF_SERVICE_INFO_OPTIONS = [
  {
    value: 'Home 12',
    label: 'Home 12'
  },
  {
    value: 'Intermediate Care Facility - Mentally Retarded 54',
    label: 'Intermediate Care Facility - Mentally Retarded 54'
  },
  {
    value: 'Skilled Nursing Facility 31',
    label: 'Skilled Nursing Facility 31'
  }
]

const INCIDENT_ON_FILE_OPTIONS = [
  {
    value: 'incident1',
    label: 'Incident 1'
  },
  {
    value: 'incident2',
    label: 'Incident 2'
  }
]

const PRESCRIPTION_ON_FILE_OPTIONS = [
  {
    value: 'prescription1',
    label: 'Prescription 1'
  },
  {
    value: 'prescription2',
    label: 'Prescription 2'
  }
]

const AUTHORIZATION_ON_FILE_OPTIONS = [
  {
    value: 'authorization1',
    label: 'Authorization 1'
  },
  {
    value: 'authorization2',
    label: 'Authorization 2'
  }
]

class SummaryTabTopComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      formData: {
        placeOfService: 'none',
        referralDoctor: '',
        renderingDoctor: '',
        referralSalesperson: '',
        incidentOnFile: 'none',
        prescriptionOnFile: 'none',
        authorizationOnFile: 'none',
        placeOfServicePhone: '',
        placeOfServiceContact: '',
        placeOfServicePhone2: '',
        placeOfServiceEmail: '',
        admissionDate: '',
        dischargeDate: '',
        poNumber: '',
        referralContact: '',
        referralDate: '',
        isForEmergencyCare: false,
        diabeticTimesTesting: false,
        diabeticModifierOnCMN: false
      }
    }
  }

  handleSalespersonSelection = suggestion => {

  }

  handleReferralDoctorSelection = suggestion => {
    if (suggestion) {
      this.setState(state => ({
        formData: {
          ...state.formData,
          referralDoctor: [suggestion.first, suggestion.last].join(' '),
          referralSalesperson: suggestion.salesperson_nm || ''
        }
      }))
    }
  }

  handleRenderingDoctorSelection = suggestion => {
    if (suggestion) {
      this.setState(state => ({
        formData: {
          ...state.formData,
          renderingDoctor: [suggestion.first, suggestion.last].join(' ')
        }
      }))
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target

    this.setState(state => ({
      formData: {
        ...state.formData,
        [name]: value
      }
    }))
  }

  handleCopyAccountBtnClick = () => {
    console.log('COPY ACCOUNT')
  }

  handleAddEditBtnClick = () => {
    console.log('ADD / EDIT')
  }

  handleAccountNotesBtnClick = () => {
    console.log('ACCOUNT NOTES')
  }

  render() {
    const { formData } = this.state
    const { classes } = this.props
    const inputLabelPropsObj = {
      shrink: true,
      style: {
        fontSize: '1rem'
      }
    }

    return (
      <div className={classes.formContainer}>
        <Grid container spacing={16} justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={5} className={classes.formSet}>
            <Typography
              variant="body2"
              color="primary"
              className={classes.formSetHeader}>
              Place of Service Information
            </Typography>
            <TextField
              select
              fullWidth
              margin="dense"
              name="placeOfService"
              value={formData.placeOfService}
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange}>
              <MenuItem value="none">
                <Typography variant="inherit" color="textSecondary">
                  Select place of service...
                </Typography>
              </MenuItem>
              {PLACE_OF_SERVICE_INFO_OPTIONS.map(opt => (
                <MenuItem key={opt.value} value={opt.value}>
                  {opt.label}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              margin="dense"
              name="placeOfServicePhone"
              value={formData.placeOfServicePhone}
              label="Place of Service Phone"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              disabled />

            <Grid container justify="space-between">
              <TextField
                margin="dense"
                name="placeOfServiceContact"
                value={formData.placeOfServiceContact}
                label="Contact"
                InputLabelProps={inputLabelPropsObj}
                className={classes.formElement}
                disabled />

              <TextField
                margin="dense"
                name="placeOfServicePhone2"
                value={formData.placeOfServicePhone2}
                label="Phone"
                InputLabelProps={inputLabelPropsObj}
                className={classes.formElement}
                disabled />
            </Grid>

            <TextField
              fullWidth
              margin="dense"
              name="placeOfServiceEmail"
              value={formData.placeOfServiceEmail}
              label="E-mail"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              disabled />
          </Grid>
          <Grid item xs={12} md={6} className={classes.formSet}>
            <Typography
              variant="body2"
              color="primary"
              className={classes.formSetHeader}>
              Referral Information
            </Typography>

            <div className={classes.formElement}>
              <DoctorSearch
                label="Search for Doctor"
                onSelect={this.handleReferralDoctorSelection} />
            </div>

            <TextField
              fullWidth
              margin="dense"
              name="referralDoctor"
              value={formData.referralDoctor}
              label="Doctor"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              disabled />

            <div className={classes.formElement}>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={this.handleCopyAccountBtnClick}>
                Copy Account
              </Button>
            </div>

            <Grid container justify="space-between">
              <TextField
                margin="dense"
                name="referralContact"
                value={formData.referralContact}
                label="Contact"
                InputLabelProps={inputLabelPropsObj}
                className={classes.formElement}
                onChange={this.handleInputChange} />

              <TextField
                margin="dense"
                name="referralDate"
                value={formData.referralDate}
                label="Date"
                placeholder="MM-DD-YYYY"
                InputLabelProps={inputLabelPropsObj}
                className={classes.formElement}
                onChange={this.handleInputChange} />
            </Grid>

            <TextField
              fullWidth
              margin="dense"
              name="referralSalesperson"
              value={formData.referralSalesperson}
              label="Salesperson"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              disabled />
          </Grid>
        </Grid>

        <Grid container spacing={16} justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={5} className={classes.formSet}>
            <div className={classes.formElement}>
              <SalespersonSearch
                label="Search for Salesperson"
                onSelect={this.handleSalespersonSelection} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.formSet}>
            <Typography
              variant="body2"
              color="primary"
              className={classes.formSetHeader}>
              Rendering Doctor
            </Typography>

            <div className={classes.formElement}>
              <DoctorSearch
                label="Search for Doctor"
                onSelect={this.handleRenderingDoctorSelection} />
            </div>

            <TextField
              fullWidth
              margin="dense"
              name="renderingDoctor"
              label="Doctor"
              value={formData.renderingDoctor}
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              disabled />
          </Grid>
        </Grid>

        <Grid container spacing={16} justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={5} className={classes.formSet}>
            <TextField
              margin="dense"
              name="admissionDate"
              value={formData.admissionDate}
              label="Admission Date"
              placeholder="MM-DD-YYYY"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange} />

            <TextField
              margin="dense"
              name="dischargeDate"
              value={formData.dischargeDate}
              label="Discharge Date"
              placeholder="MM-DD-YYYY"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.formSet}>
            <Typography
              variant="body2"
              color="primary"
              className={classes.formSetHeader}>
              Incident On File
            </Typography>

            <TextField
              select
              fullWidth
              margin="dense"
              name="incidentOnFile"
              value={formData.incidentOnFile}
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange}>
              <MenuItem value="none">
                <Typography variant="inherit" color="textSecondary">
                  Select incident on file...
                </Typography>
              </MenuItem>
              {INCIDENT_ON_FILE_OPTIONS.map(incident => (
                <MenuItem key={incident.value} value={incident.value}>
                  {incident.label}
                </MenuItem>
              ))}
            </TextField>

            <Grid container justify="space-between" alignItems="center">
              <FormControlLabel
                name="isForEmergencyCare"
                label={
                  <Typography variant="inherit" color="inherit">
                    <strong>Is for Emergency Care?</strong>
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={formData.isForEmergencyCare}
                    onChange={e => this.handleInputChange({
                      target: {
                        name: 'isForEmergencyCare',
                        value: !formData.isForEmergencyCare
                      }
                    })}
                    style={{ padding: 8 }} />
                }
                className={classes.formElement}
                style={{ margin: 0 }} />

              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={this.handleAddEditBtnClick}>
                Add/Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={16} justify="space-between" alignItems="flex-start">
          <Grid item xs={12} md={5} className={classes.formSet}>
            <TextField
              margin="dense"
              name="poNumber"
              value={formData.poNumber}
              label="PO Number"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange} />
          </Grid>
          <Grid item xs={12} md={6} className={classes.formSet}>
            <Typography
              variant="body2"
              color="primary"
              className={classes.formSetHeader}>
              Documents On File
            </Typography>

            <TextField
              select
              fullWidth
              margin="dense"
              name="prescriptionOnFile"
              value={formData.prescriptionOnFile}
              label="Prescriptions on File"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange}>
              <MenuItem value="none">
                <Typography variant="inherit" color="textSecondary">
                  Select prescription on file...
                </Typography>
              </MenuItem>
              {PRESCRIPTION_ON_FILE_OPTIONS.map(prescription => (
                <MenuItem key={prescription.value} value={prescription.value}>
                  {prescription.label}
                </MenuItem>
              ))}
            </TextField>

            <FormControlLabel
              name="diabeticTimesTesting"
              label={
                <Typography variant="inherit" color="inherit">
                  <strong>Diabetic times testing (from script on file)</strong>
                </Typography>
              }
              control={
                <Checkbox
                  checked={formData.diabeticTimesTesting}
                  style={{ padding: 8 }} />
              }
              className={classes.formElement}
              style={{ margin: 0 }}
              disabled />

            <FormControlLabel
              name="diabeticModifierOnCMN"
              label={
                <Typography variant="inherit" color="inherit">
                  <strong>Diabetic modifier on CMN</strong>
                </Typography>
              }
              control={
                <Checkbox
                  checked={formData.diabeticModifierOnCMN}
                  style={{ padding: 8 }} />
              }
              className={classes.formElement}
              style={{ margin: 0 }}
              disabled />

            <TextField
              select
              fullWidth
              margin="dense"
              name="authorizationOnFile"
              value={formData.authorizationOnFile}
              label="Authorizations on File"
              InputLabelProps={inputLabelPropsObj}
              className={classes.formElement}
              onChange={this.handleInputChange}>
              <MenuItem value="none">
                <Typography variant="inherit" color="textSecondary">
                  Select authorization on file...
                </Typography>
              </MenuItem>
              {AUTHORIZATION_ON_FILE_OPTIONS.map(authorization => (
                <MenuItem key={authorization.value} value={authorization.value}>
                  {authorization.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <div className={[classes.formSet, classes.formFoot].join(' ')}>
          <div className={classes.formElement}>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={this.handleAccountNotesBtnClick}>
              Account Notes
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(SummaryTabTopComponent)
