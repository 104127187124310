import { ClientAppsActionTypes } from '../actions/client_apps.ac.js';

export const clientAppsInView = (
    state = {
        isSubmitting: false,
        isGenerating: false,
        isFetching: false,
        error: null,
        client_apps: null,
        records: null
    },
    action
) => {

    switch (action.type) {
        case ClientAppsActionTypes.SET_IS_SUBMITTING:
            return {
                ...state,
                isSubmitting: action.isSubmitting,
            }

        case ClientAppsActionTypes.SET_IS_GENERATING_KEY_PAIR:
            return {
                ...state,
                isGenerating: action.isGenerating,
            }

        case ClientAppsActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            }
        case ClientAppsActionTypes.CLIENT_APPLICATION_CREATED:
        case ClientAppsActionTypes.UPDATE_CLIENT_APPS:
            return {
                ...state,
                client_apps: action.client_apps
            }

        case ClientAppsActionTypes.APPLICATION_KEY_PAIR_GENERATED:
            const { client_apps } = state;
            if (!client_apps.keyPairs) {
                client_apps.keyPairs = [];
            }
            client_apps.keyPairs.push(action.pair)

            return {
                ...state,
                client_apps
            }

        case ClientAppsActionTypes.CLIENT_APPLICATION_LIST_RECEIVED:
            return {
                ...state,
                records: action.records
            }

        case ClientAppsActionTypes.CLIENT_APPLICATION_RECEIVING_FAILED:
            return {
                ...state,
                records: null,
                error: action.error
            }

        case ClientAppsActionTypes.CLIENT_APPLICATION_UPDATED:
            const { records } = state;
            records.find(record => record._id === action._id).preferences = action.preferences
            return {
                ...state,
                records
            }

        case ClientAppsActionTypes.APPLICATION_KEY_PAIR_DELETED:
            const { client_apps: apps } = state;
            (apps.keyPairs || []).forEach((pair, i) => {
                if (pair.id === action.pair_id) {
                    apps.keyPairs.splice(i, 1);
                }
            })
            return {
                ...state,
                client_apps: apps
            }

        default:
            return {
                ...state
            }
    }
};
