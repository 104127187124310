import PropTypes from 'prop-types';
import React from 'react';

import { Button, Grid, Typography } from '@material-ui/core';

import { getPageStatement } from '../../../util/pagination';
import { PaginationWithNums } from '../../PaginationNew';

const PaginationWithReset = (props) => {
  const {
    sortSupport = true,
    currPage,
    data,
    disabled = false,
    isFetching,
    handleResetSorting,
    handleClearAllSorting,
    handlePageChange,
  } = props;

  return (
    <div>
      <Grid container spacing={8} alignItems="center">
        {!disabled && (
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {getPageStatement(data)}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <PaginationWithNums
            disableAll={isFetching || disabled}
            onPageChange={handlePageChange}
            currentPageNum={currPage || data.currentPageNum}
            totalPagesAvailable={data.totalPagesAvailable}
          />
        </Grid>

        {sortSupport && (
          <Grid item>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              disabled={isFetching || disabled}
              onClick={handleResetSorting}>
              Reset Sorting to Default
            </Button>
          </Grid>
        )}
        {sortSupport && handleClearAllSorting !== undefined && (
          <Grid item>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              disabled={isFetching || disabled}
              onClick={handleClearAllSorting}>
              Clear Sort Settings
            </Button>
          </Grid>
        )}

        {props.children}
      </Grid>
    </div>
  );
};

PaginationWithReset.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  handleResetSorting: PropTypes.func,
  fetchIdxAdminReps_ac: PropTypes.func,
  isFetching: PropTypes.bool.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default PaginationWithReset;
