import {
  Grid,
  Typography
} from '@material-ui/core'
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { replace } from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import Loader from '../Shared/Loader'
import HealthCallEncounterCard from './HealthCallEncounterCard'
import HealthCallProgramCard from './HealthCallProgramCard'
import { fetchPatientHealthCallData_ac } from '../../actions/patient_healthcall_data.ac'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing.unit * 0.5
  },
  pageStatement: {
    fontSize: 12
  },
  fullHeight: {
    height: '100%'
  },
  dynamicFullHeight: {
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  }
})

class IdnHealthCall extends React.Component {
  componentDidMount() {
    this.checkSituation()
    this.clearLocationState()
  }

  componentDidUpdate(prevProps) {
    this.checkSituation(prevProps)
    this.clearLocationState()
  }

  checkSituation = prevProps => {
    const { patientHCData, idnData, fetchPatientHealthCallData_ac } = this.props
    const { isFetching, data, error, idnStr } = patientHCData

    if (isFetching) return;

    if (
      (data === null && error === null) ||
      idnData.idnStr !== idnStr
    ) {
      return fetchPatientHealthCallData_ac({
        aa_num: _.get(idnData, 'idnDoc.aa_num', []),
        idnStr: idnData.idnStr
      })
    }
  }

  clearLocationState = () => {
    let { location } = this.props

    if (location.state && location.state.from === 'HC_SNACK') {
      this.props.clearLocationState(location)
    }
  }

  render() {
    const { classes, idnData } = this.props
    const { isFetching, data, error } = this.props.patientHCData

    if (isFetching) {
      return (
        <Loader message="...loading healthcal data" />
      )
    }

    if (error) {
      return (
        <Typography variant="body2" color="error">
          Error loading healthcall data
        </Typography>
      )
    }

    if (!data) {
      return (
        <Typography variant="body2">
          ...
        </Typography>
      )
    }

    const encounters = data.encounters || []
    const programs = data.patientPrograms || []

    return (
      <Grid container spacing={8} className={classes.dynamicFullHeight}>
        <Grid item xs={12} md={7} className={classes.dynamicFullHeight}>
          <div className={classes.content}>
            <Typography
              className={classes.pageStatement}
              variant="body2"
              color="textSecondary"
            >
              {encounters.length}&ensp;
              <strong>
                {encounters.length === 1 ? 'Encounter' : 'Encounters'}
              </strong>
              &ensp;found for {idnData.idnStr.replace(/_/g, ' ')}
            </Typography>
            <div className={classes.scrollArea}>
              {encounters.map((enc, idx) => (
                <HealthCallEncounterCard
                  idx={idx}
                  key={enc.id}
                  encounter={enc}
                />
              ))}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} className={classes.dynamicFullHeight}>
          <div className={classes.content}>
            <Typography
              className={classes.pageStatement}
              variant="body2"
              color="textSecondary"
            >
              {programs.length}&ensp;
              <strong>
                {programs.length === 1 ? 'Program' : 'Programs'}
              </strong>
              &ensp;found for {idnData.idnStr.replace(/_/g, ' ')}
            </Typography>
            <div className={classes.scrollArea}>
              {programs.map((program, idx) => (
                <HealthCallProgramCard
                  idx={idx}
                  key={program.id}
                  ptProgram={program}
                />
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  patientHCData: stateFromStore.patientHealthcallData
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    fetchPatientHealthCallData_ac,
    clearLocationState: location => replace(location.path)
  },
  dispatch
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IdnHealthCall))
)
