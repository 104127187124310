//reducer for lookups
export function lookups(
  state = {
    ink: null,
    payerSets: null
  },
  action
) {
  switch (action.type) {
    case 'FETCHING_LOOKUPS':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'FAILED_GOT_INK_FULLCACHE':
      return Object.assign({}, state, {
        //sessionId: action.meDoc.tasty_fruit,
        isFetching: false,
        error: action.error
      })
    case 'GOT_INK_FULLCACHE':
      return Object.assign({}, state, {
        //sessionId: action.meDoc.tasty_fruit,
        isFetching: false,
        ink: {
          k: action.pkg.k,
          divs: action.pkg.divs,
          products: action.pkg.products,
          modes: action.pkg.modes,
          pa_exp_actions: action.pkg.pa_exp_actions
        }
      })
    case 'GOT_PAYER_SETS':
      return Object.assign({}, state, {
        payerSets: action.payerSets,
        isFetching: false
      })
    case 'GAPI_VALIDATING':
      return Object.assign({}, state, {
        isGapiValidating: true
      })
    case 'GOT_GAPI_RESULT':
      return Object.assign({}, state, {
        isGapiValidating: false,
        isGapiValid: action.valid
      })
    default:
      return state
  }
}
