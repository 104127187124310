import { Button, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  topContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    marginTop: 40,
    height: 'calc(100% - 40px)',
    backgroundColor: '#fff'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100%',
    overflow: 'auto',
    '-webkitOverflowScrolling': 'touch'
  }
})

class AccountToIdnLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      redirectInProgress: false,
      error: false,
      errorMsg: '',
      txt: 'Redirecting to ...',
      drawerOpen: false,
      masterSearchStr: ''
    }
    this.retry = this.retry.bind(this)
  }

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation() {
    if (this.props.me.user === null) {
      return
    }
    if (this.state.redirectInProgress) {
      return console.log('redirect in progress')
    }

    const { match } = this.props
    let default_tab = 'doclisting'
    if (match.params.tabName) {
      default_tab = match.params.tabName.toString()
    }

    let target_url = `/api/idn/get/by/acct`
    let postData = {
      aa_num: parseInt(
        this.props.match.params.accountNumber.replace(/a/, ''),
        10
      )
    }

    if (this.state.error) return

    this.setState({ redirectInProgress: true })

    const dfa_base_path = this.props.basePath || 'dfa'

    window.sch
      .post(target_url, postData)
      .then(results => {
        console.log('got results', results)
        let to = `/${dfa_base_path}/idn/${results.idnDoc._id.replace(
          / /g,
          '_'
        )}/tabs/${default_tab}`

        if (match.params.extras) {
          to += '/' + match.params.extras.toString()
        }

        if (to === window.location.pathname) {
          console.log('Already at ', to)
          this.setState({
            redirectInProgress: false,
            error: null,
            errorMsg: ''
          })
          return
        }

        this.props.redirect(to)
      })
      .catch(error => {
        this.setState({
          redirectInProgress: false,
          error: true,
          errorMsg: JSON.stringify(
            {
              postData,
              target_url,
              user_id: this.props.me.user._id,
              user_email: this.props.me.user.mail,
              user_nm: this.props.me.user.nm,
              error_message: error.message
            },
            null,
            '  '
          ),
          txt: `Failed getting docflow patient from account#  ${
            this.props.match.params.accountNumber
          } - This is probably because the DF Patient record has NOT been linked to the HDMS Account - Search for the patient by name or try the RETRY button below:`
        })
      })
  }

  retry() {
    this.setState({ error: null })
  }

  render() {
    const { classes } = this.props

    return (
      <main className={classes.topContent}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={6}>
            <main className={classes.content}>
              {this.state.redirectInProgress && (
                <div>....attempting to load patient</div>
              )}

              {!this.state.redirectInProgress && <div>{this.state.txt}</div>}

              {this.state.error && (
                <div style={{ marginTop: 20 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.retry}>
                    Retry Loading Patient
                  </Button>
                </div>
              )}

              {this.state.errorMsg && (
                <div
                  style={{
                    marginTop: 20
                  }}>
                  <h1>DO NOT REQUEST SUPPORT UNTIL YOU'VE TRIED THIS FIRST:</h1>
                  <h1>
                    Manually search for the patient by name or birth date - if
                    you are able to locate the patient by name or birthdate,
                    link the account number to that patient using the MERGE WITH
                    EXISTING DOCFLOW PATIENT RECORD button in the HDMS ACCOUNTS
                    tab for that patient.
                  </h1>
                  <div>
                    Send the following message to Helpdesk ONLY if you still can
                    work this account after trying the above step. <br />
                    COPY AND PASTE IT - DO NOT SEND A SCREEN SHOT:
                  </div>
                  <pre
                    style={{
                      padding: 6,
                      backgroundColor: '#CFD8DC',
                      fontSize: 10
                    }}>
                    {this.state.errorMsg}
                  </pre>
                </div>
              )}
            </main>
          </Grid>
        </Grid>
      </main>
    )
  }
}

AccountToIdnLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch,
  dfaInView: stateFromStore.dfaInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: path => {
        return push(path)
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountToIdnLanding))
)
