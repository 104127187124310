import {
  FETCH_WORKUNITS,
  RECEIVED_WORKUNITS,
  FAILED_FETCHING_WORKUNITS,
  FETCH_WORKUNITS_REPORT,
  RECEIVED_WORKUNITS_REPORT,
  FAILED_FETCHING_WORKUNITS_REPORT,
  FLUSH_WORKUNITS,
} from '../../actions/workunits_listing.ac';

// Example
// workunits: {
//   open: {
//     data: null,
//     isFetching: false,
//     error: null,
//   }
// }

export const initialStates = {
  workunitsReport: {
    data: null,
    isFetching: false,
    error: null,
  },
  workunits: {},
};

const workunitsReport = (state = initialStates.workunitsReport, action) => {
  switch (action.type) {
    case FETCH_WORKUNITS_REPORT:
      return {
        ...state,
        isFetching: true,
      };

    case RECEIVED_WORKUNITS_REPORT:
      return {
        ...state,
        isFetching: false,
        data: action.data,
        error: null,
      };

    case FAILED_FETCHING_WORKUNITS_REPORT:
      return {
        ...state,
        isFetching: false,
        data: {},
        error: true,
      };

    default:
      return { ...state };
  }
};

const workunits = (state = initialStates.workunits, action) => {
  switch (action.type) {
    case FETCH_WORKUNITS:
      return {
        ...state,
        [action.kind || 'open']: {
          ...state[action.kind || 'open'],
          isFetching: true,
        },
      };

    case RECEIVED_WORKUNITS:
      return {
        ...state,
        [action.kind || 'open']: {
          ...state[action.kind || 'open'],
          isFetching: false,
          data: action.data,
          error: null,
        },
      };

    case FLUSH_WORKUNITS:
      return {
        ...state,
        [action.kind || 'open']: {
          ...state[action.kind || 'open'],
          isFetching: false,
          data: {},
          error: null,
        },
      }

    case FAILED_FETCHING_WORKUNITS:
      return {
        ...state,
        [action.kind || 'open']: {
          ...state[action.kind || 'open'],
          isFetching: false,
          data: {},
          error: true,
        },
      };

    default:
      return { ...state };
  }
};

export default {
  workunits,
  workunitsReport,
};
