import React from 'react';

import QueueRow from './QueueRow';
import ListAccordion from '../../common/ListAccordion';

const UNGROUPED_QUEUES = 'Ungrouped';
const RAE_QUEUES = 'RAEQueue';

const parseQueues = (queues, temp) =>
  queues.reduce((acc, curr) => {
    const activeGroup = curr.group?._id || UNGROUPED_QUEUES;

    return {
      ...acc,
      [activeGroup]: {
        ...(acc[activeGroup] || {}),
        queues: [...((acc[activeGroup] || {}).queues || []), curr]
      }
    };
  }, temp);

const QueueList = props => {
  const {
    classes,
    queues,
    queueGroups = [],
    activeQueue,
    onQueueAdd,
    onQueueClick,
    onGroupClick,
    ...rest
  } = props;

  const groupTemplate = {};

  queueGroups.forEach(grp => {
    groupTemplate[grp._id] = { orig: grp, queues: [] };
  });

  groupTemplate[UNGROUPED_QUEUES] = {
    orig: { _id: UNGROUPED_QUEUES, name: UNGROUPED_QUEUES },
    queues: []
  };

  const filteredQueues = parseQueues(queues, groupTemplate);

  const data = Object.keys(filteredQueues).map(group => {
    const { queues, orig = {} } = filteredQueues[group];

    return {
      title: orig.name || group,
      special: [UNGROUPED_QUEUES, RAE_QUEUES].includes(orig.name),
      value: orig,
      onTitleClick: onGroupClick,
      content: () => (
        <QueueRow
          classes={classes}
          queues={queues}
          group={orig}
          onNodeClick={onQueueClick}
          activeQueue={activeQueue}
          handleAddClick={() => {
            onQueueAdd(null, orig);
          }}
          {...rest}
        />
      ),
      info: queues.length
    };
  });

  return data.length ? (
    <ListAccordion data={data} style={{ paddingRight: '6px' }} />
  ) : (
    <div className={classes['fallbackInfo']}>
      {'Click on add group to create a new queue group.'}
    </div>
  );
};

export default QueueList;
