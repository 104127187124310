//reducer for me
export function room(
  state={
    socket: null,
    status: 'disconnected',
    ub_msg: null,
    last_df_msg: null,
    last_df_msg_sent: false,
  },
  action
) {
  switch (action.type) {
    case 'RESET_MAIN_SOCKET':
      return Object.assign({}, state, {
        status: 'disconnected',
        socket: null,
        last_location: null
      })
    case 'SETUP_MAIN_SOCKET':
      return Object.assign({}, state, {
        status: 'connecting',
      })
    case 'UPDATE_MAIN_SOCKET_STATE':
      return Object.assign({}, state, {
        status: action.status,
        socket: action.socket
      })
    case 'STORE_LAST_ROOM_LOCATION':
      return Object.assign({}, state, {
        last_location: action.location
      })

    case 'SOCKET_GOT_ROOM_EVENT':
      return Object.assign({}, state, {
        status: action.room_event.state,
      })
    case 'SOCKET_GOT_UB_MSG':
      return Object.assign({}, state, {
        ub_msg: action.msg,
      })
    case 'SET_DF_MSG':
      return Object.assign({}, state, {
        last_df_msg: action.msg, //outbound to socket server, only storing so that we can re-issue if previously disconnecetd
        sent: false
      })
    case 'SENT_DF_MSG':
      return Object.assign({}, state, {
        last_df_msg: action.msg, //outbound to socket server, only storing so that we can re-issue if previously disconnecetd
        last_df_msg_sent: true
      })
    default:
      return state
  }
}
