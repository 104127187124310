//reducer for dfaInView
export function axiom(
  state = {
    selectedContract: null,
    suggestions: [],
  },
  action
) {
  switch (action.type) {
    case 'RECEIVED_SUGGESTIONS':
      return Object.assign({}, state, {
        suggestions: action.suggestions,
      });
    case 'CONTRACT_SELECTED':
      return Object.assign({}, state, {
        selectedContract: action.selectedContract,
      });
    case 'RESET_AXIOM': //reset the store
      return Object.assign({}, state, {
        selectedContract: null,
        suggestions: [],
      });
    default:
      return state;
  }
}
