export const actionTypes = {
  FETCH_FORM_TEMPLATES: 'FETCH_FORM_TEMPLATES',
  FETCH_FORM_TEMPLATES_SUCCESSFUL: 'FETCH_FORM_TEMPLATES_SUCCESSFUL',
  FETCH_FORM_TEMPLATES_FAILED: 'FETCH_FORM_TEMPLATES_FAILED',
  FETCH_FORM_TEMPLATE_DETAIL: 'FETCH_FORM_TEMPLATE_DETAIL',
  FETCH_FORM_TEMPLATE_DETAIL_SUCCESSFUL:
    'FETCH_FORM_TEMPLATE_DETAIL_SUCCESSFUL',
  FETCH_FORM_TEMPLATE_DETAIL_FAILED: 'FETCH_FORM_TEMPLATE_DETAIL_FAILED_FAILED',

  FLUSH_FORM_TEMPLATE_DETAIL: 'FLUSH_FORM_TEMPLATE_DETAIL',

  CREATE_FORM_TEMPLATE: 'CREATE_FORM_TEMPLATE',
  CREATE_FORM_TEMPLATE_SUCCESSFUL: 'CREATE_FORM_TEMPLATE_SUCCESSFUL',
  CREATE_FORM_TEMPLATE_FAILED: 'CREATE_FORM_TEMPLATE_FAILED',
  UPDATE_FORM_TEMPLATE: 'UPDATE_FORM_TEMPLATE',
  UPDATE_FORM_TEMPLATE_SUCCESSFUL: 'UPDATE_FORM_TEMPLATE_SUCCESSFUL',
  UPDATE_FORM_TEMPLATE_FAILED: 'UPDATE_FORM_TEMPLATE_FAILED',
  REMOVE_FORM_TEMPLATE: 'REMOVE_FORM_TEMPLATE',
  REMOVE_FORM_TEMPLATE_SUCCESSFUL: 'REMOVE_FORM_TEMPLATE_SUCCESSFUL',
  REMOVE_FORM_TEMPLATE_FAILED: 'REMOVE_FORM_TEMPLATE_FAILED',

  GET_BASE_FORM_TEMPLATES: 'GET_BASE_FORM_TEMPLATES',
  GET_BASE_FORM_TEMPLATES_SUCCESSFUL: 'GET_BASE_FORM_TEMPLATES_SUCCESSFUL',
  GET_BASE_FORM_TEMPLATES_FAILED: 'GET_BASE_FORM_TEMPLATES_FAILED',
};

export function fetchFormTemplates_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_TEMPLATES });
    return window.sch
      .post('/api/forms/templates/get', params)
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_TEMPLATES_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_TEMPLATES_FAILED,
          error,
        });
      });
  };
}

export function fetchFormTemplateDetail_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_TEMPLATE_DETAIL });
    return window.sch
      .post('/api/forms/templates/detail', params)
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_TEMPLATE_DETAIL_SUCCESSFUL,
          data: result?.result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_TEMPLATE_DETAIL_FAILED,
          error,
        });
      });
  };
}

export function flushFormTemplateDetail_ac() {
  return (dispatch) => {
    dispatch({ type: actionTypes.FLUSH_FORM_TEMPLATE_DETAIL });
  };
}

export function fetchBaseFormTemplates_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_BASE_FORM_TEMPLATES });
    return window.sch
      .post('/api/forms/templates/base', params)
      .then((result) => {
        dispatch({
          type: actionTypes.GET_BASE_FORM_TEMPLATES_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_BASE_FORM_TEMPLATES_FAILED,
          error,
        });
      });
  };
}

export function createFormTemplate_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_FORM_TEMPLATE });
    return window.sch
      .post(`/api/forms/templates/create`, params)
      .then((result) => {
        if (result.success === false) {
          const err = new Error(result.error || result.message || '');
          if (onError) {
            onError(err, result.msgs);
          }
          return dispatch({
            type: actionTypes.CREATE_FORM_TEMPLATE_FAILED,
            error: err,
          });
        }
        dispatch({
          type: actionTypes.CREATE_FORM_TEMPLATE_SUCCESSFUL,
          record: result.record,
        });
        if (onSuccess) {
          onSuccess(result?.result || {});
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE_FORM_TEMPLATE_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function updateFormTemplate_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FORM_TEMPLATE });
    return window.sch
      .post(`/api/forms/templates/update`, params)
      .then((result) => {
        if (result.success === false) {
          const err = new Error(result.error || result.message || '');
          if (onError) {
            onError(err, result.msgs);
          }
          return dispatch({
            type: actionTypes.UPDATE_FORM_TEMPLATE_FAILED,
            error: err,
          });
        }
        dispatch({
          type: actionTypes.UPDATE_FORM_TEMPLATE_SUCCESSFUL,
          record: result.record,
          updated_id: params._id,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_FORM_TEMPLATE_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function removeFormTemplate_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_FORM_TEMPLATE });
    return window.sch
      .post(`/api/forms/templates/delete`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_TEMPLATE_SUCCESSFUL,
          data: result,
          removed_id: params._id,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_TEMPLATE_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}
