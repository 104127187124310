import classnames from 'classnames'
import React, { useState, useEffect } from 'react'
import { RiCloseCircleLine } from 'react-icons/ri'

import { IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Loader from '../Shared/Loader'

const styles = () => ({
  imageWrapper: {
    background: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 2000
  },
  loadingWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    paddingTop: '25%',
    textAlign: 'center'
  },
  image: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    maxWidth: '100%',
    maxHeight: '80%',
    margin: 'auto',
    overflow: 'auto',
    display: 'none'
  },
  crossButton: {
    position: 'absolute',
    right: '32px',
    top: '32px',
    opacity: 0.8,
    zIndex: 20,
    color: 'white',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1
    }
  },
  loadedImage: {
    display: 'block'
  }
})

const ImageViewer = props => {
  const { visible, onClose, image, onError, classes } = props

  const onKeyDown = event => event.key === 'Escape' && onClose()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown, false)
    document.body.style.overflow = visible ? 'hidden' : 'auto'

    return () => {
      document.removeEventListener('keydown', onKeyDown, false)
    }
    // eslint-disable-next-line
  }, [visible])

  return (
    visible && (
      <div className={classes.imageWrapper}>
        <IconButton onClick={onClose} className={classes.crossButton}>
          <RiCloseCircleLine size={48} />
        </IconButton>
        {loading && (
          <div className={classes.loadingWrapper}>
            <Loader type="circular" />
          </div>
        )}
        <img
          className={classnames(classes.image, {
            [classes.loadedImage]: !loading
          })}
          src={image.src}
          alt={image.alt}
          onLoad={() => {
            setLoading(false)
          }}
          onError={onError}
        />
      </div>
    )
  )
}

export default withStyles(styles)(ImageViewer)
