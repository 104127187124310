import { open_snack_ac } from './snack.ac.js'
const NON_SPECIFIC_PATIENT = 'NON-SPECIFIC-PATIENT'

export const ShoppingCartActionTypes = {
  RECEIVED_SHOPPING_CART_ITEMS: 'RECEIVED_SHOPPING_CART_ITEMS',
  FAILED_RECEIVING_ITEMS: 'FAILED_RECEIVING_ITEMS',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_ITEM_FROM_CART: 'REMOVE_ITEM_FROM_CART',
  REMOVE_CART: 'REMOVE_CART',
  CHANGE_QUANTITY: 'CHANGE_QUANTITY',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  UPDATE_CART: 'UPDATE_CART',
  ADD_MACRO_ITEMS_TO_CART: 'ADD_MACRO_ITEMS_TO_CART'
}

export const fetchCartItems_ac = params => dispatch => {
  dispatch(fetching(true))
  return window.sch
    .post('/api/catalog/cart/list', { ...params })
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.RECEIVED_SHOPPING_CART_ITEMS,
        cart: result.carts[0] || {}
      })
    })
    .catch(error => {
      dispatch({
        type: ShoppingCartActionTypes.FAILED_RECEIVING_ITEMS,
        error
      })
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}
export const addToCart_ac = (item, qty) => (dispatch, getState) => {
  const { idnInView, shoppingCartInView } = getState()
  let { items = [], _id } = shoppingCartInView.cart
  if (items.some(cartItem => cartItem.prod_code_v_id === item.prod_code_v_id)) {
    return dispatch(
      open_snack_ac({
        variant: 'warning',
        message: 'Item Already Exist On Cart'
      })
    )
  }
  item.state = 'new'
  item.isUsed = false
  item.unitCost = item.uomcost / item.uomcount;
  item.uomcount = qty * 1
  const requestData = {
    idn: idnInView.idnStr || NON_SPECIFIC_PATIENT,
    cart: { items: [...items, item] },
    cart_id: _id
  }
  return window.sch
    .post('/api/catalog/cart/save', requestData)
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.ADD_TO_CART,
        item,
        cart_id: result.cart_id
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Adding Item'
        })
      )
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const addMacroItemsToCart_ac = (macroItems) => (dispatch, getState) => {
  const { idnInView, shoppingCartInView } = getState()

  macroItems.forEach(macroItem => {
    macroItem.state = 'new'
    macroItem.isUsed = false
    macroItem.unitCost = macroItem.uomcost / macroItem.uomcount;
  })
  let { items = [], _id } = shoppingCartInView.cart

  const existingIds = items.map(({ prod_code_v_id }) => prod_code_v_id)
  const newItems = macroItems.filter(({ prod_code_v_id }) => !existingIds.includes(prod_code_v_id));

  const finalItems = [
    ...items,
    ...newItems
  ]

  const requestData = {
    idn: idnInView.idnStr || NON_SPECIFIC_PATIENT,
    cart: { items: finalItems },
    cart_id: _id
  }
  return window.sch
    .post('/api/catalog/cart/save', requestData)
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.ADD_MACRO_ITEMS_TO_CART,
        items: finalItems,
        cart_id: result.cart_id
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Adding Items'
        })
      )
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const removeItemFromCart_ac = cart_item_id => (dispatch, getState) => {
  const { shoppingCartInView, idnInView } = getState()
  const { items, _id } = shoppingCartInView.cart
  items.forEach((cartItem, i) => {
    if (cartItem.prod_code_v_id === cart_item_id) {
      items.splice(i, 1)
    }
  })
  const requestData = {
    idn: idnInView.idnStr || NON_SPECIFIC_PATIENT,
    cart: { items },
    cart_id: _id
  }
  return window.sch
    .post('/api/catalog/cart/save', requestData)
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.REMOVE_ITEM_FROM_CART,
        items: items
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Removing Item'
        })
      )
    })
}
export const removeCart_ac = () => (dispatch, getState) => {
  const { shoppingCartInView, idnInView } = getState()
  const { items, _id } = shoppingCartInView.cart

  const requestData = {
    idn: idnInView.idnStr || NON_SPECIFIC_PATIENT,
    cart: { items },
    cart_id: _id
  }
  return window.sch
    .post('/api/catalog/cart/delete', requestData)
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.REMOVE_CART,
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Removing Cart'
        })
      )
    })
}

export const changeQuantity_ac = (cart_item_id, quantity) => (
  dispatch,
  getState
) => {
  const { idnInView, shoppingCartInView } = getState()
  const { items, _id } = shoppingCartInView.cart
  items.find(
    cartItem => cartItem.prod_code_v_id === cart_item_id
  ).uomcount = quantity

  const requestData = {
    idn: idnInView.idnStr || NON_SPECIFIC_PATIENT,
    cart: { items },
    cart_id: _id
  }
  return window.sch
    .post('/api/catalog/cart/save', requestData)
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.CHANGE_QUANTITY,
        items
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Changing Quantity'
        })
      )
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const updateCart_ac = (cart_item_id, isUsed) => (dispatch, getState) => {
  const { idnInView, shoppingCartInView } = getState()
  const { items, _id } = shoppingCartInView.cart
  let state = isUsed ? 'used' : 'new'
  items.forEach(cartItem => {
    if (cartItem.prod_code_v_id === cart_item_id) {
      cartItem.state = state
      cartItem.isUsed = isUsed
    }
  })

  const requestData = {
    idn: idnInView.idnStr || NON_SPECIFIC_PATIENT,
    cart: { items },
    cart_id: _id
  }
  return window.sch
    .post('/api/catalog/cart/save', requestData)
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: ShoppingCartActionTypes.UPDATE_CART,
        items
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Updating Cart'
        })
      )
    })
}

export const draftEmail_ac = params => dispatch => {
  return window.sch
    .post('/api/draft_email', { ...params })
    .then(() => {
      dispatch(open_snack_ac({
        variant: 'info',
        message: 'Check the drafts folder in your email'
      }))
    })
    .catch(() => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Failed to create draft'
        })
      )
    });
}

export const fetching = isFetching => ({
  type: ShoppingCartActionTypes.SET_IS_FETCHING,
  isFetching
})
