import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchExpiringPAsReport_ac } from '../../actions/expiringPAs.ac.js';
import { setTitle_ac } from '../../actions/me.ac.js';
import FetchingStatementWrap from '../FetchingStatementWrap';

const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {},
  title: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  padding4: {
    padding: 4,
  },
});

class ExpiringPAsReportLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: {
        payer_group:
          _.get(this.props, 'match.params.payer_group', false) || 'ccs',
      },
    };
    this.payer_types = props.match.params.payer_types;
    this.payerGroupFilterChange = this.payerGroupFilterChange.bind(this);
    this.downloadAllCurrent = this.downloadAllCurrent.bind(this);
    this.downloadRecordsAsCSV = this.downloadRecordsAsCSV.bind(this);
    this.checkSituation = this.checkSituation.bind(this);
  }

  componentDidMount() {
    this.props.setTitle_ac('Expiring PAs Report');
    this.checkSituation();
  }
  componentDidUpdate() {
    this.checkSituation();
  }

  checkSituation() {
    const { expiringPAsReport, match } = this.props;
    if (expiringPAsReport.isFetching) return;
    if (expiringPAsReport.error) return;

    if (!match.params.start) {
      let m = moment();
      let end = m.format('MM-DD-YYYY');
      let start = m.add(-6, 'days').format('MM-DD-YYYY');
      let path = `/reports/pa_expiring/${this.payer_types}/${start}/${end}`;
      return this.props.changePage(path);
    }

    let params = {
      payer_types: this.payer_types,
      start: this.props.match.params.start,
      end: this.props.match.params.end,
    };
    params.start =
      params.start.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.start;
    params.end =
      params.end.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.end;

    if (expiringPAsReport.pkg === null)
      this.props.fetchExpiringPAsReport_ac(params);
  }

  downloadAllCurrent() {
    let params = {
      start: this.props.match.params.start,
      end: this.props.match.params.end,
    };
    params.start =
      params.start.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.start;
    params.end =
      params.end.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.end;

    const path = `/api/expiring-pas-mco/activity_report/csv/${
      this.payer_types
    }/all/${params.start}/${params.end}/${new Date().getTime()}`;
    window.sch.download_as_csv(
      path,
      'MCO_Expiring_ALLQs_' +
        this.props.expiringPAsReport.pkg.staging_info.date_range_str +
        '.csv'
    );
  }

  downloadRecordsAsCSV = (qname) => () => {
    let params = {
      start: this.props.match.params.start,
      end: this.props.match.params.end,
    };
    params.start =
      params.start.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.start;
    params.end =
      params.end.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.end;
    const path = `/api/expiring-pas-mco/records/csv/${
      this.payer_types
    }/${qname}/${params.start}/${params.end}/${new Date().getTime()}`;
    window.sch.download_as_csv(
      path,
      'MCO_Expiring_IND_' +
        this.props.expiringPAsReport.pkg.staging_info.date_range_str +
        '.csv'
    );
  };

  payerGroupFilterChange(e, v) {
    let _selectedFilters = {
      payer_group: v,
    };
    this.setState({ selectedFilters: _selectedFilters });
    let path = `/unbilled_stats/${v}`;
    this.props.changePage(path);
  }

  render() {
    const { classes, expiringPAsReport } = this.props;
    const { pkg } = expiringPAsReport;

    let title = 'Expiring Productivity Report';
    let dt_params = {
      start: this.props.match.params.start,
      end: this.props.match.params.end,
    };
    if (expiringPAsReport.error) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'Something went wrong. Contact IT'}
        />
      );
    }

    if (!pkg) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      );
    }

    if (expiringPAsReport.isFetching || !pkg.by_user) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      );
    }
    return (
      <main className={classes.content}>
        <div className={classes.padding4}>
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: 12 }}
            onClick={this.downloadAllCurrent}>
            Download Activity from {dt_params.start} to {dt_params.end} Records
            as CSV - ALl Qs
          </Button>
          <Button
            color="primary"
            variant="outlined"
            style={{ marginRight: 12 }}
            onClick={this.downloadRecordsAsCSV('ind')}>
            Download IND Q Only - Expiring from {dt_params.start} to{' '}
            {dt_params.end} as CSV
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.downloadRecordsAsCSV('cap')}>
            Download CAP Q Only - Expiring from {dt_params.start} to{' '}
            {dt_params.end} as CSV
          </Button>
        </div>

        <div className={classes.scrollArea}>
          <label className={classes.label}>Unique PAs Worked By Rep:</label>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rep</TableCell>
                {pkg.by_user.day_hdrs.map((dt) => (
                  <TableCell key={dt} align="right">
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: 11,
                      }}>
                      {dt}
                    </div>
                    <div style={{ color: 'gray', fontSize: 11 }}>
                      {pkg.by_user.day_totals[dt].n}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pkg.by_user.records.map((r) => (
                <TableRow key={r._id}>
                  <TableCell component="th" scope="row">
                    <div style={{ fontSize: 12 }}>{r._id}</div>
                    <div style={{ color: 'gray', fontSize: 12 }}>{r.total}</div>
                  </TableCell>
                  {r.day_counts.map((d, di) => (
                    <TableCell key={di} align="right">
                      {d.n}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <label className={classes.label}>PA Touches By Rep:</label>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Rep</TableCell>
                {pkg.by_user_touches.day_hdrs.map((dt) => (
                  <TableCell key={dt} align="right">
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: 11,
                      }}>
                      {dt}
                    </div>
                    <div style={{ color: 'gray', fontSize: 11 }}>
                      {pkg.by_user_touches.day_totals[dt].n}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pkg.by_user_touches.records.map((r) => (
                <TableRow key={r._id}>
                  <TableCell component="th" scope="row">
                    <div style={{ fontSize: 12 }}>{r._id}</div>
                    <div style={{ color: 'gray', fontSize: 12 }}>{r.total}</div>
                  </TableCell>
                  {r.day_counts.map((d, di) => (
                    <TableCell key={di} align="right">
                      {d.n}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <label className={classes.label}>By PayerType:</label>
          {pkg.by_payertype && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Rep</TableCell>
                  {pkg.by_payertype.day_hdrs.map((dt, dti) => (
                    <TableCell align="right" key={dti}>
                      <div
                        style={{
                          fontWeight: 'bold',
                          fontSize: 11,
                        }}>
                        {dt}
                      </div>
                      <div style={{ color: 'gray', fontSize: 11 }}>
                        {pkg.by_payertype.day_totals[dt].n}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {pkg.by_payertype.records.map((r) => (
                  <TableRow key={r._id}>
                    <TableCell component="th" scope="row">
                      <div style={{ fontSize: 12 }}>{r._id}</div>
                      <div style={{ color: 'gray', fontSize: 12 }}>
                        {r.total}
                      </div>
                    </TableCell>
                    {r.day_counts.map((d, di) => (
                      <TableCell key={di} align="right">
                        {d.n}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </main>
    );
  }
}

ExpiringPAsReportLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  expiringPAsReport: stateFromStore.expiringPAsReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchExpiringPAsReport_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExpiringPAsReportLanding))
);
