import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setTitle_ac } from '../../actions/me.ac.js';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
import CreateMailboxes from './CreateMailboxes';
import { MailBoxes } from './MailBoxes';
const styles = () => ({
  filterRoot: {
    paddingLeft: 16,
  },
  tabsRoot: {
    minHeight: 24,
    maxHeight: 48,
  },
  tabSpecificContainer: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },

  formRoot: {
    flexGrow: 1,
    alignItems: 'left',
  },
  leftColumn: {
    padding: 8,
    flexDirection: 'column',
    display: 'flex',
    '& > *': {
      margin: 12,
      width: 240,
    },
  },
  rightColumn: {
    padding: 8,
    flexDirection: 'column',
    display: 'flex',
    '& > *': {
      margin: 12,
      width: 240,
    },
  },
});

class MailBoxesLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  onTabSelect = (e, _selectedTab) => {
    let toPath = this.props.match.path.replace(/:tabName/, _selectedTab);
    return this.props.changePage(toPath);
  };

  render() {
    const { classes, match } = this.props;
    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot,
    };
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer,
    };

    return (
      <>
        <Tabs
          classes={tabsClass}
          value={match.params.tabName}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab value="ringcentral" label="Ring Central" classes={tabClass} />
          <Tab value="concord" label="Concord" classes={tabClass} />
          <Tab value="gmail" label="Gmail" classes={tabClass} />
          <Tab value="scanner" label="Scanner" classes={tabClass} />
          <Tab value="pilot" label="Pilot" classes={tabClass} />
          <Tab value="sc_gso" label="Sc-Gso" classes={tabClass} />
          <Tab value="create" label="Create Mailbox" classes={tabClass} />
        </Tabs>
        <div className={classes.tabSpecificContainer}>
          <Switch>
            <Route
              path="/mailboxes/create"
              render={() => <CreateMailboxes {...this.props} />}
            />
            <Route path="/mailboxes/:tab_name" component={MailBoxes} />
          </Switch>
        </div>
      </>
    );
  }
}

MailBoxesLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      close_snack_ac,
      setTitle_ac,
      changePage: (path) => {
        return push(path.replace(/:tabParam/, ''));
      },
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MailBoxesLanding))
);
