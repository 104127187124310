import { actionTypes } from '../actions/indexing_job.ac';

export const initialState = {
  faxDetail: { isFetching: false, data: null, error: null },
  indexingLabels: { isFetching: false, data: null, error: null },
  faxPotentialDuplicates: { isFetching: false, data: [], error: null },
  rotationPrefs: [],
  activeImageIndex: 0,
  contextEntries: [],
  focused: { contextEntry: null, doc: null, page: null },
  xDataId: null,
  xDoc: null,
  outcome: {}, // Response to context:save to determine teams etc.
  isSavingContext: false,
  saveError: null,
  isCompletingContext: false,
  completeError: null,
  isResettingContext: false,
  isTrashingContext: false,
  trashError: null,
  isUntrashingContext: false,
  untrashError: null,
  resetError: null,
};

export const mapXDataToState = (xData, faxData, state = initialState) => {
  const images = [...Array(faxData?.pages?.length || 0).keys()];
  if (!Boolean(xData)) {
    return {
      ...state,
      outcome: {},
      activeImageIndex: 0,
      focused: { contextEntry: null, doc: null, page: null },
      contextEntries: [],
      xDataId: null,
      xDoc: null,
      rotationPrefs: new Array(images.length).fill(0),
      images,
    };
  }

  const {
    contextEntries = [],
    rotationPrefs = new Array(images.length).fill(0),
  } = xData || {};
  const firstDivider = (contextEntries.length ? contextEntries[0].docs : [])[0];

  return {
    ...state,
    outcome: {},
    activeImageIndex: 0,
    xDataId: xData._id,
    xDoc: xData,
    contextEntries,
    rotationPrefs,
    focused: {
      contextEntry: contextEntries.length ? contextEntries[0].id : null,
      doc: firstDivider ? firstDivider.id : null,
      page: null,
    },
    images,
  };
};

function mergeContextEntry(entry, changes = {}) {
  const newEntry = { ...entry };
  const { labels, ...rest } = changes;

  if (Boolean(labels)) {
    Object.keys(labels).forEach((key) => {
      if (labels[key] && labels[key].length) {
        newEntry.labels[key] = labels[key];
      }
    });
  }

  Object.keys(rest).forEach((key) => {
    if (Boolean(rest[key])) {
      newEntry[key] = rest[key];
    }
  });

  return newEntry;
}

export function indexingJob(state = initialState, action) {
  switch (action.type) {
    // LabelFetch
    case actionTypes.FETCH_INDEXING_LABELS_PENDING:
      return {
        ...state,
        indexingLabels: { isFetching: true, data: null, error: null },
      };
    case actionTypes.FETCH_INDEXING_LABELS_SUCCESS:
      return {
        ...state,
        indexingLabels: { isFetching: false, data: action.data, error: null },
      };
    case actionTypes.FETCH_INDEXING_LABELS_ERROR:
      return {
        ...state,
        indexingLabels: { isFetching: false, data: null, error: action.error },
      };

    // FaxFetch
    case actionTypes.FETCH_FAX_JOB_DETAIL_PENDING:
      return {
        ...state,
        faxDetail: { ...state.faxDetail, isFetching: true },
      };

    case actionTypes.FETCH_FAX_JOB_DETAIL_SUCCESS:
      return {
        ...mapXDataToState(action.xData, action.faxData, state),
        faxDetail: {
          ...state.faxDetail,
          isFetching: false,
          data: action.faxData,
        },
      };
    case actionTypes.FETCH_FAX_JOB_DETAIL_ERROR:
      return {
        ...state,
        faxDetail: {
          ...state.faxDetail,
          isFetching: false,
          error: action.error,
        },
      };

    // FaxDuplicates
    case actionTypes.FETCH_FAX_POTENTIAL_DUPLICATES_PENDING:
      return {
        ...state,
        faxPotentialDuplicates: {
          ...state.faxPotentialDuplicates,
          isFetching: true,
        },
      };

    case actionTypes.FETCH_FAX_POTENTIAL_DUPLICATES_SUCCESS:
      return {
        ...state,
        faxPotentialDuplicates: {
          ...state.faxPotentialDuplicates,
          isFetching: false,
          data: action.faxes,
        },
      };
    case actionTypes.FETCH_FAX_POTENTIAL_DUPLICATES_ERROR:
      return {
        ...state,
        faxPotentialDuplicates: {
          ...state.faxPotentialDuplicates,
          isFetching: false,
          error: action.error,
        },
      };

    // FaxViewer
    case actionTypes.SET_ROTATION_PREFS:
      return {
        ...state,
        rotationPrefs: action.data,
      };
    case actionTypes.SET_ACTIVE_IMAGE_INDEX:
      return {
        ...state,
        activeImageIndex: action.data,
      };

    // ContextEntries
    case actionTypes.ADD_CONTEXT_ENTRY:
      return {
        ...state,
        contextEntries: [...state.contextEntries, action.data],
      };

    case actionTypes.REMOVE_CONTEXT_ENTRY:
      return {
        ...state,
        contextEntries: state.contextEntries.filter(
          (entry) => entry.id !== action.data
        ),
      };

    case actionTypes.UPDATE_CONTEXT_ENTRY:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? { ...entry, ...action.data.updates }
            : entry
        ),
      };

    case actionTypes.ADD_CONTEXT_ENTRY_DOC:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? { ...entry, docs: [...entry.docs, action.data.doc] }
            : entry
        ),
      };

    case actionTypes.REMOVE_CONTEXT_ENTRY_DOC:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? {
                ...entry,
                docs: entry.docs.filter((doc) => doc.id !== action.data.docId),
              }
            : entry
        ),
      };

    case actionTypes.ADD_CONTEXT_ENTRY_LABEL:
      if (Boolean(!action.data.labelName)) {
        console.error(
          `${action.data.value} can't be added to nonexistent label 'undefined' to CE: ${action.data.contextId}`
        );
        return state;
      }
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId &&
          !(entry.labels[action.data.labelName] || []).includes(
            action.data.value
          )
            ? {
                ...entry,
                labels: {
                  ...entry.labels,
                  [action.data.labelName]: [
                    ...(entry.labels[action.data.labelName] || []),
                    action.data.value,
                  ],
                },
              }
            : entry
        ),
      };

    case actionTypes.REMOVE_CONTEXT_ENTRY_LABEL:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? {
                ...entry,
                labels: {
                  ...entry.labels,
                  [action.data.labelName]: entry.labels[
                    action.data.labelName
                  ].filter((value) => value !== action.data.value),
                },
              }
            : entry
        ),
      };

    case actionTypes.SET_DOC_DETAIL:
      const detail = action.data.detail;
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? {
                ...entry,
                docs: entry.docs.map((doc) =>
                  doc.id === action.data.docId
                    ? {
                        ...doc,
                        detail:
                          typeof detail === 'function'
                            ? detail(doc.detail || {})
                            : detail || {},
                      }
                    : doc
                ),
              }
            : entry
        ),
      };

    case actionTypes.LINK_IMAGE_TO_DOC:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? {
                ...entry,
                docs: entry.docs.map((doc) =>
                  doc.id === action.data.docId
                    ? { ...doc, pages: [...doc.pages, action.data.imageIdx] }
                    : doc
                ),
              }
            : entry
        ),
      };

    case actionTypes.UNLINK_IMAGE_FROM_DOC:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? {
                ...entry,
                docs: entry.docs.map((doc) =>
                  doc.id === action.data.docId
                    ? {
                        ...doc,
                        pages: doc.pages.filter(
                          (page) => page !== action.data.imageIdx
                        ),
                      }
                    : doc
                ),
              }
            : entry
        ),
      };

    case actionTypes.SET_FOCUSED_STATE:
      return {
        ...state,
        focused: {
          ...state.focused,
          ...action.data,
        },
      };

    // Set OC and Zip

    case actionTypes.SET_ORDERING_CLINICIAN:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? {
                ...entry,
                oc: action.data.oc,
                ocLocation: Boolean(action.data.oc)
                  ? (action.data.oc.locations || [])[0]
                  : null,
              }
            : entry
        ),
      };

    case actionTypes.SET_OC_LOCATION:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? { ...entry, ocLocation: action.data.location }
            : entry
        ),
      };

    case actionTypes.SET_ZIP_CODE:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? { ...entry, zip: action.data.zip }
            : entry
        ),
      };

    case actionTypes.SET_OC_ZIP_CODE:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? { ...entry, oc_zip: action.data.oc_zip }
            : entry
        ),
      };

    case actionTypes.POPULATE_CONTEXT_ENTRY:
      return {
        ...state,
        contextEntries: state.contextEntries.map((entry) =>
          entry.id === action.data.contextId
            ? mergeContextEntry(entry, action.data.changes)
            : entry
        ),
      };

    // Save, Reset, Complete

    case actionTypes.SAVE_INDEXING_CONTEXT_PENDING:
      return { ...state, isSavingContext: true };

    case actionTypes.SAVE_INDEXING_CONTEXT_SUCCESS:
      return {
        ...state,
        isSavingContext: false,
        xDataId: state.xDataId || action.data.new_xId,
        outcome: {
          lastSaved: action.data.newTs,
          rule_results: action.data.rule_results,
        },
      };

    case actionTypes.SAVE_INDEXING_CONTEXT_ERROR:
      return { ...state, isSavingContext: false, saveError: action.error };

    case actionTypes.RESET_INDEXING_CONTEXT_PENDING:
      return { ...state, isResettingContext: true };

    case actionTypes.RESET_INDEXING_CONTEXT_SUCCESS:
      return {
        ...state,
        isResettingContext: false,
      };

    case actionTypes.RESET_INDEXING_CONTEXT_ERROR:
      return { ...state, isResettingContext: false, resetError: action.error };

    case actionTypes.COMPLETE_INDEXING_CONTEXT_PENDING:
      return { ...state, isCompletingContext: true };

    case actionTypes.COMPLETE_INDEXING_CONTEXT_SUCCESS:
      return {
        ...state,
        isCompletingContext: false,
      };

    case actionTypes.COMPLETE_INDEXING_CONTEXT_ERROR:
      return {
        ...state,
        isCompletingContext: false,
        completeError: action.error,
      };

    case actionTypes.TRASH_INDEXING_CONTEXT_PENDING:
      return { ...state, isTrashingContext: true };

    case actionTypes.TRASH_INDEXING_CONTEXT_SUCCESS:
      return {
        ...state,
        isTrashingContext: false,
        trashError: null,
      };

    case actionTypes.TRASH_INDEXING_CONTEXT_ERROR:
      return {
        ...state,
        isTrashingContext: false,
        trashError: action.error,
      };

    case actionTypes.UNTRASH_INDEXING_CONTEXT_PENDING:
      return { ...state, isUntrashingContext: true };

    case actionTypes.UNTRASH_INDEXING_CONTEXT_SUCCESS:
      return {
        ...state,
        isUntrashingContext: false,
        untrashError: null,
      };

    case actionTypes.UNTRASH_INDEXING_CONTEXT_ERROR:
      return {
        ...state,
        isUntrashingContext: false,
        untrashError: action.error,
      };

    case actionTypes.SET_INDEXING_STATE:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}
