import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';

const styles = {
  card: {
    border: '1px solid #cfd8dc',
    backgroundColor: '#fafefff0',
    transitionDuration: '0.3s',
    padding: '4px',
  },
  activeCardHeader: {
    backgroundColor: 'yellow',
  },
  inActiveCardHeader: {
    backgroundColor: 'lightgray',
  },
};

const NameLabelView = ({ left = {}, right = {} }) => {
  const leftKeys = Object.keys(left);
  const rightKeys = Object.keys(right);

  return (
    <Grid container spacing={4}>
      {Boolean(leftKeys.length) && (
        <Grid item xs={6}>
          <List>
            {leftKeys.map((key, index) => {
              return left[key] ? (
                <ListItem key={index}>
                  <ListItemText primary={key} secondary={left[key]} />
                </ListItem>
              ) : null;
            })}
          </List>
        </Grid>
      )}
      {Boolean(rightKeys.length) && (
        <Grid item xs={6}>
          <List>
            {rightKeys.map((key, index) => {
              return right[key] ? (
                <ListItem key={index}>
                  <ListItemText primary={key} secondary={right[key]} />
                </ListItem>
              ) : null;
            })}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

const KeyValuePair = (props) => {
  const {
    source,
    partitions = 1,
    font = 14,
    sort = false,
    keyCb = null,
    nodeStyle = {},
  } = props;

  let keys = Object.keys(source);

  if (sort) {
    keys = _.sortBy(keys, (key) => key);
  }

  const groups = _.chunk(keys, partitions);
  const size = Math.floor(12 / partitions);

  return (
    <>
      {groups.map((group, index) => {
        return (
          <Grid container key={index}>
            {group.map((key, index) => {
              return (
                Boolean(source[key]) && (
                  <Grid item xs={size} key={index}>
                    <span style={{ fontSize: `${font}px`, ...nodeStyle }}>
                      <b>
                        {keyCb ? keyCb(key) : key} {' :'}
                      </b>{' '}
                      {source[key]}
                    </span>
                  </Grid>
                )
              );
            })}
          </Grid>
        );
      })}
    </>
  );
};

const statusColorMap = {
  success: '#4caf50',
  danger: '#f44336',
  warning: '#d49100',
};

const CardNameLabelView = ({ label = '', data = {}, statuses, ...rest }) => {
  const keys = Object.keys(data);
  return (
    <Grid item xs={5} {...rest} style={{ ...rest.style, marginTop: '16px' }}>
      <Card variant="outlined" style={styles.card}>
        <CardHeader
          title={label}
          style={{ fontSize: 12, cursor: 'pointer', paddingBottom: '6px' }}
        />
        <CardContent style={{ paddingTop: 0 }}>
          {statuses.map((status, index) => {
            return (
              <Typography
                key={index}
                style={{
                  color: statusColorMap[status.type],
                  fontWeight: '600',
                }}>
                {status.text}
              </Typography>
            );
          })}
          {keys.map((key, index) => {
            return data[key] ? (
              <div key={index}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  component="span">
                  <b>
                    {key} {' :'}
                  </b>{' '}
                  {data[key]}
                </Typography>
              </div>
            ) : null;
          })}
        </CardContent>
      </Card>
    </Grid>
  );
};

export { NameLabelView as default, CardNameLabelView, KeyValuePair };
