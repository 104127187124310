import { open_snack_ac } from './snack.ac.js'
function fetchOpsFormRecords(param) {
  return dispatch => {
    //params passed in by thunk middleware
    let target_url = `/api/ops_form_record/list`
    let postData
    if (typeof param === 'string') {
      postData = { by: 'idn', val: param.replace(/_/g, ' '), page: 0 }
      dispatch({ type: 'REQUEST_OPS_FORM_RECORDS', idn: param })
    } else {
      dispatch({ type: 'REQUEST_OPS_FORM_RECORDS', idn: null })
      postData = param
    }

    return window.sch
      .post(target_url, postData)
      .then(pkg => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        dispatch({
          type: 'RECEIVED_OPS_FORM_RECORDS',
          records: pkg.rr,
          pagination: pkg.pagination
        })
      })
      .catch(error => {
        console.error('error:', error)
        dispatch({ type: 'FAILED_RECEIVED_OPS_FORM_RECORDS', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Failed fetching MR requests'
          })
        )
      })
  }
}

export function commentOpsFormRecord_ac(_id, comment_txt) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_UPDATE_FAXNUM' })
    let target_url = `/api/outbound/comment`
    return window.sch
      .post(target_url, { _id, txt: comment_txt })
      .then(pkg => {
        let _records = getState().opsFormRecordsInView.records.map(r => {
          if (r._id === pkg.updatedDoc._id) {
            return pkg.updatedDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_UPDATE_FAXNUM', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_UPDATE_FAXNUM', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Update failed'
          })
        )
      })
  }
}

export function updateTrmFaxNumber_ac(oDoc_id, mode_kind, mode_val) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_UPDATE_FAXNUM' })
    let target_url = `/api/outbound/update_trm_fax_number`
    return window.sch
      .post(target_url, { oDoc_id, mode_kind, mode_val })
      .then(pkg => {
        let _records = getState().opsFormRecordsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_UPDATE_FAXNUM', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_UPDATE_FAXNUM', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Update failed'
          })
        )
      })
  }
}

export function attemptTransmission_ac(oDoc_id, opts) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_TRANSMIT' })
    let target_url = `/api/outbound/trm`
    let params = { oDoc_id }
    if (opts) {
      params = { ...params, opts }
    }

    return window.sch
      .post(target_url, params)
      .then(pkg => {
        let _records = getState().opsFormRecordsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_TRANSMIT', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message:
              'Transmission Failed - check if your attached PDFs are corrupt'
          })
        )
      })
  }
}

export function markTransmissionMan_ac(oDoc_id, mark_state) {
  return (dispatch, getState) => {
    dispatch({ type: 'ATTEMPT_TRANSMIT' })
    let target_url = `/api/outbound/mark`
    return window.sch
      .post(target_url, { oDoc_id, mark_state })
      .then(pkg => {
        let _records = getState().opsFormRecordsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_TRANSMIT', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Update failed'
          })
        )
      })
  }
}

export const fetchOpsFormRecords_ac = fetchOpsFormRecords

// DOC REQUEST ACTIVITY RELATED
export function attachFilesToStore_ac(outboundFiles) {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTACH_OPS_FORM_RECORD_FILES_TO_STORE', outboundFiles })
  }
}

export function resetFilesToStore_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({
      type: 'ATTACH_OPS_FORM_RECORD_FILES_TO_STORE',
      outboundFiles: []
    })
  }
}

export function resetOpsFormRecordActivity_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'RESET_OPS_FORM_RECORD_ACTIVITY' })
    dispatch({
      type: 'ATTACH_OPS_FORM_RECORD_FILES_TO_STORE',
      outboundFiles: []
    })
  }
}

export function pre_createOpsFormRecord_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'CREATE_OPS_FORM_RECORD_ACTIVITY' })
  }
}

export function undo_createOpsFormRecord_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'UNDO_CREATE_OPS_FORM_RECORD_ACTIVITY' })
  }
}

export function createOpsFormRecord_ac(pkg) {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'CREATE_OPS_FORM_RECORD_ACTIVITY' })
    let target_url = `/api/ops_form_record/create`
    let postData = { ...pkg }
    postData.idn = postData.idn.replace(/ /g, ' ')
    return window.sch
      .post(target_url, postData)
      .then(pkg => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        fetchOpsFormRecords(pkg.idn)
        dispatch({ type: 'SUCCESS_OPS_FORM_RECORD_ACTIVITY', successPkg: pkg })
        dispatch({ type: 'RESET_OPS_FORM_RECORDS' })
      })
      .catch(error => {
        console.error('error:', error)
        dispatch({ type: 'FAILED_OPS_FORM_RECORD_ACTIVITY' })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Ops Form Record creation failed'
          })
        )
      })
  }
}

/**
 * Submit request to VOID and update records locally on success.
 * @param {number} _id ID of record.
 * @param {boolean} isGeneral Flag to indicate general listing page
 */
export function voidOpsFormRecord_ac(_id, isGeneral) {
  return (dispatch, getState) => {
    const stateFromStore = getState()
    const { records } =
      isGeneral
        ? (stateFromStore.opsFormsReport.report || {})
        : stateFromStore.opsFormRecordsInView
    const { user } = stateFromStore.me

    dispatch({
      type: 'IS_SUBMITTING_OPS_RECORD_VOID',
      isSubmitting: true
    })
    window.sch
      .post('/api/ops_form/void', { _id })
      .then(res => {
        dispatch({
          type: 'OPEN_SNACK',
          variant: 'success',
          message: 'Record marked as VOID successfully!'
        })

        if (records && records.length > 0) {
          let newRecords = [...records]
          let currRecIdx = newRecords.findIndex(rec => rec._id === _id)
          if (currRecIdx > -1) {
            newRecords[currRecIdx] =
              res.record
                ? { ...res.record }
                : {
                    ...newRecords[currRecIdx],
                    log: [
                      ...(newRecords[currRecIdx].log || []),
                      {
                        u_id: user._id,
                        code: 'void',
                        ts: new Date()
                      }
                    ],
                    void: true
                  }

            dispatch({
              type: isGeneral
                ? 'UPDATE_LOCAL_OPS_FORM_RECORDS_ON_SUCCESS_GENERAL'
                : 'UPDATE_LOCAL_OPS_FORM_RECORDS_ON_SUCCESS',
              records: [...newRecords]
            })
          }
        }
      })
      .catch(err => {
        dispatch({
          type: 'OPEN_SNACK',
          variant: 'error',
          message: 'Failed to mark record as VOID!'
        })
      })
      .finally(() => {
        dispatch({
          type: 'IS_SUBMITTING_OPS_RECORD_VOID',
          isSubmitting: false
        })
      })
  }
}

/**
 * Submit new comment for a record and update records on success
 * @param {object} params Request parameters: '_id' and 'txt'
 * @param {Function} callbackOnSuccess Callback function to reset comment field on success
 * @param {boolean} isGeneral Flag to indicate general listing page
 */
export function addOpsFormRecordComment_ac(params, callbackOnSuccess, isGeneral) {
  return (dispatch, getState) => {
    const stateFromStore = getState()
    const { records } =
      isGeneral
        ? (stateFromStore.opsFormsReport.report || {})
        : stateFromStore.opsFormRecordsInView
    const { user } = stateFromStore.me

    dispatch({
      type: 'IS_SUBMITTING_OPS_RECORD_COMMENT',
      isSubmitting: true
    })
    window.sch
      .post('/api/ops_form_record/comment', params)
      .then(res => {
        dispatch({
          type: 'OPEN_SNACK',
          variant: 'success',
          message: 'Comment added successfully!'
        })

        if (records && records.length > 0) {
          let newRecords = [...records]
          let currRecIdx = newRecords.findIndex(rec => rec._id === params._id)
          if (currRecIdx > -1) {
            newRecords[currRecIdx] =
              res.updatedDoc
                ? { ...res.updatedDoc }
                : {
                    ...newRecords[currRecIdx],
                    comments: [
                      ...(newRecords[currRecIdx].comments || []),
                      {
                        u_id: user._id,
                        txt: params.txt,
                        ts: new Date()
                      }
                    ]
                  }

            dispatch({
              type: isGeneral
                ? 'UPDATE_LOCAL_OPS_FORM_RECORDS_ON_SUCCESS_GENERAL'
                : 'UPDATE_LOCAL_OPS_FORM_RECORDS_ON_SUCCESS',
              records: [...newRecords]
            })

            callbackOnSuccess()
          }
        }
      })
      .catch(err => {
        dispatch({
          type: 'OPEN_SNACK',
          variant: 'error',
          message: 'Failed to add comment!'
        })
      })
      .finally(() => {
        dispatch({
          type: 'IS_SUBMITTING_OPS_RECORD_COMMENT',
          isSubmitting: false
        })
      })
  }
}
