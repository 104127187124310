import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createIdnShell_ac } from '../../actions/idn_shell.ac';
import { cancel_masterSearch_ac } from '../../actions/masterSearch.ac.js';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';
import MasterSearchResults from '../MasterSearchResults.js';
import IdnShellSearchForm from './IdnShellSearchForm.js';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
});

class IdnShellLanding extends React.Component {
  componentDidMount() {
    setTitle_ac('IDN Shell');
    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  init = () => {
    const { me, lookups, lookups_ac } = this.props;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;

      return lookups_ac();
    }
  };

  createShellForIdn = () => {
    this.props.createIdnShell_ac(this.idnSearchStr);
  };

  goToAccountTab = () => {
    const { idn_shell } = this.props.idnShellInView;
    let path = `/dfa/idn/${idn_shell._id.replace(/ /g, '_')}/tabs/account_info`;
    return this.props.changePage(path);
  };

  render() {
    const { classes, idnShellInView } = this.props;
    return (
      <Grid container spacing={8} item className={classes.fullHeight}>
        <div className={classes.scrollArea}>
          <main className={classes.content}>
            <IdnShellSearchForm />

            <Grid item xs={12} sm={12} md={12}>
              {!idnShellInView.idn_shell && (
                <MasterSearchResults
                  idnShellSearch={true}
                  createShell={this.createShellForIdn}
                  isCreatingIdn={idnShellInView.isSubmitting}
                />
              )}
              {idnShellInView.idn_shell && (
                <div style={{ color: 'blue', fontSize: 12 }}>
                  <Button onClick={this.goToAccountTab}>
                    Go To IDN Account Tab
                  </Button>
                </div>
              )}
            </Grid>
          </main>
        </div>
      </Grid>
    );
  }
}

IdnShellLanding.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  idnShellInView: stateFromStore.idnShellInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac,
      createIdnShell_ac,
      cancel_masterSearch_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IdnShellLanding))
);
