import actionTypes from '../actionTypes';

export function clearDrawer() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.clearDrawer,
    });
  };
}

export function toggleDrawer() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.toggleDrawer,
    });
  };
}

export function openDrawer() {
  return (dispatch) => {
    dispatch({ type: actionTypes.pdfGen.openDrawer });
  };
}
export function closerawer() {
  return (dispatch) => {
    dispatch({ type: actionTypes.pdfGen.closeDrawer });
  };
}

export function addDoc(doc) {
  return (dispatch) => {
    let formattedDoc = {
      patientIdn: doc.displayAs.split(':')[0],
      nm: doc.displayAs.split(':')[1],
      vId: doc?.vId,
      wId: doc?.wId,
      docIdx: doc.docIdx,
      pageCount: doc.pages.length,
      faxTsMDY: doc.faxTsMDY,
      pageSelectorsRevealed: false,
      showPagePicker: false,
      selectedPages: doc.pages.map((page, idx) => {
        return {
          idx,
          txt: idx + 1,
          active: true,
        };
      }),
      selectedPageCount: doc.pages.length,
      docname: doc.displayAs.replace(':', '_').replace(/\.| /g, '_'),
    };

    dispatch({
      type: actionTypes.pdfGen.doc.add,
      doc: formattedDoc,
    });
    dispatch({ type: actionTypes.pdfGen.openDrawer });
  };
}

export function removeDoc(index) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.doc.remove,
      index,
    });
  };
}

export function moveUpDoc(index) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.doc.moveUp,
      index,
    });
  };
}

export function moveDownDoc(index) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.doc.moveDown,
      index,
    });
  };
}

export function togglePage(listIndex, pageIndex) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.page.toggle,
      listIndex,
      pageIndex,
    });
  };
}

export function togglePagePicker(listIndex) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.pdfGen.pagePicker,
      listIndex,
    });
  };
}

export function preview(list) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    let patientIdn;
    let docsToDownload = list.reduce((acc, doc) => {
      // Remove docs with 0 selected pages
      for (let page of doc.selectedPages) {
        if (page.active) {
          acc.push(JSON.parse(JSON.stringify(doc)));
          return acc;
        }
      }
      return acc;
    }, []);

    if (docsToDownload.length === 0) {
      dispatch({
        type: actionTypes.pdfGen.preview.fail,
        error: {
          message: 'No pages to preview!',
        },
      });
      return;
    } else if (list.length !== docsToDownload.length) {
      dispatch({
        type: actionTypes.pdfGen.preview.try,
        list: docsToDownload.map((d) => JSON.parse(JSON.stringify(d))),
      });
    } else {
      dispatch({
        type: actionTypes.pdfGen.preview.try,
      });
    }

    for (let i = 0; i < docsToDownload.length; i++) {
      if (patientIdn) {
        if (patientIdn !== docsToDownload[i].patientIdn) {
          dispatch({
            type: actionTypes.pdfGen.preview.fail,
            error: {
              message: 'Cannot generate a PDF across multiple patients',
            },
          });
          return;
        }
      } else {
        patientIdn = docsToDownload[i].patientIdn;
      }

      delete docsToDownload[i].patientIdn;
      delete docsToDownload[i].nm;
      delete docsToDownload[i].showPagePicker;
    }

    const action = docsToDownload[0].wId
      ? window.sch.post(`/api/fax:download:get_map`, {
          patientIdn,
          docsToDownload,
        })
      : window.sch.post(`/api/fax:download`, { patientIdn, docsToDownload });

    return action
      .then((pkg) => {
        dispatch({
          type: actionTypes.pdfGen.preview.success,
          result: { payloads: pkg.payloads },
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.pdfGen.preview.fail,
          error,
        });
      });
  };
}

export function convert(list) {
  return async (dispatch) => {
    let patientIdn;
    let docsToDownload = list.reduce((acc, doc) => {
      // Remove docs with 0 selected pages
      for (let page of doc.selectedPages) {
        if (page.active) {
          acc.push(JSON.parse(JSON.stringify(doc)));
          return acc;
        }
      }
      return acc;
    }, []);

    if (docsToDownload.length === 0) {
      dispatch({
        type: actionTypes.pdfGen.convert.fail,
        error: {
          message: 'No pages to convert!',
        },
      });
      return;
    } else if (list.length !== docsToDownload.length) {
      dispatch({
        type: actionTypes.pdfGen.convert.try,
        list: docsToDownload.map((d) => JSON.parse(JSON.stringify(d))),
      });
    } else {
      dispatch({
        type: actionTypes.pdfGen.convert.try,
      });
    }

    for (let i = 0; i < docsToDownload.length; i++) {
      if (patientIdn) {
        if (patientIdn !== docsToDownload[i].patientIdn) {
          dispatch({
            type: actionTypes.pdfGen.convert.fail,
            error: {
              message: 'Cannot generate a PDF across multiple patients',
            },
          });
          return;
        }
      } else {
        patientIdn = docsToDownload[i].patientIdn;
      }

      delete docsToDownload[i].patientIdn;
      delete docsToDownload[i].nm;
      delete docsToDownload[i].showPagePicker;
    }

    console.log('PDF conversion args', { patientIdn, docsToDownload });
    let target_url = `/api/fax/download/packet`;
    return window.sch
      .post(target_url, { patientIdn, docsToDownload })
      .then((pkg) => {
        console.log(pkg);

        dispatch({
          type: actionTypes.pdfGen.convert.success,
          result: {
            name: pkg.paths.pdf_name,
            link: pkg.paths.path_to_url,
          },
        });
      })
      .catch((error) => {
        console.log(
          error.response && error.response.data ? error.response.data : error
        );
        dispatch({
          type: actionTypes.pdfGen.convert.fail,
          error,
        });
      });
  };
}
