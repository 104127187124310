export const actionTypes = {
  SET_IS_FETCHING_OPS_FORMS_REPORT: 'SET_IS_FETCHING_OPS_FORMS_REPORT',
  RECEIVED_OPS_FORMS_REPORT: 'RECEIVED_OPS_FORMS_REPORT',
  FAILED_OPS_FORMS_REPORT: 'FAILED_OPS_FORMS_REPORT'
}

export const fetchOpsFormsReport_ac = params => dispatch => {
  dispatch(setIsFetching(true))

  window.sch
    .post('/api/reports/ops_forms', params)
    .then(response => {
      dispatch({
        type: actionTypes.RECEIVED_OPS_FORMS_REPORT,
        records: response.rr || [],
        pagination: response.pagination || {}
      })
    })
    .catch(error => {
      dispatch({
        type: actionTypes.FAILED_OPS_FORMS_REPORT,
        error: true
      })
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

const setIsFetching = isFetching => ({
  type: actionTypes.SET_IS_FETCHING_OPS_FORMS_REPORT,
  isFetching
})
