export function fetchExpiringCCSMCalReport_ac(params){
  //params passed in by thunk middleware
  return (dispatch) => {
    dispatch({type:'REQUEST_EXPIRING_CCS_MCAL_REPORT'})

    let path = (params.days_end) ?
    `/api/expiring-pas-ccs/report/ui/${params.days}/${params.days_end}`
    :
    `/api/expiring-cmn-oxy/report/${params.days}/${params.days}`

    return window.sch.get(path)
    .then( result => {
      if(result.pkg.error) throw new Error(result.pkg.error)
      dispatch({
        type: 'RECEIVED_EXPIRING_CCS_MCAL_REPORT',
        pkg: result.pkg
      })
    })
    .catch(error=> {
      dispatch({
        type: 'FAILED_EXPIRING_CCS_MCAL_REPORT',
        error
      })
    });
  }
}
