import _ from 'lodash';
import { actionTypes } from '../actions/user_profiles.ac';

export const initialState = {
  profiles: {
    isFetching: false,
    error: null,
    data: [],
    options: [],
  },
  isCreatingProfile: false,
  isCopyingProfile: false,
  profileCreationError: null,
  isUpdatingProfile: false,
  profileUpdateError: null,
  isDeletingProfile: false,
  profileDeletionError: null,
  isActivatingProfile: false,
  profileActivationError: null,
};

const userProfiles = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILES_PENDING:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: true,
          error: null,
        },
      };
    case actionTypes.FETCH_USER_PROFILES_SUCCESSFUL:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: null,
          ...(action.optionOnly
            ? {
                options: action.data,
              }
            : {
                data: action.data,
                options: action.data.map((pf) =>
                  _.pick(pf, ['_id', 'name', 'copied_from_profile_id'])
                ),
              }),
        },
      };

    case actionTypes.FETCH_USER_PROFILES_FAILED:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: action.error,
        },
      };

    case actionTypes.CREATE_USER_PROFILE_PENDING:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: true,
          error: null,
        },
        isCreatingProfile: true,
      };

    case actionTypes.CREATE_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: null,
          data: [...state.profiles.data, action.data],
          options: [
            ...state.profiles.options,
            _.pick(action.data, ['_id', 'name', 'copied_from_profile_id']),
          ],
        },
        isCreatingProfile: false,
      };

    case actionTypes.CREATE_USER_PROFILE_FAILED:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: action.error,
        },
        isCreatingProfile: false,
      };

    case actionTypes.COPY_USER_PROFILE_PENDING:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: true,
          error: null,
        },
        isCopyingProfile: true,
      };

    case actionTypes.COPY_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: null,
          data: [...state.profiles.data, action.data],
          options: [
            ...state.profiles.options,
            _.pick(action.data, ['_id', 'name', 'copied_from_profile_id']),
          ],
        },
        isCopyingProfile: false,
      };

    case actionTypes.COPY_USER_PROFILE_FAILED:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: action.error,
        },
        isCopyingProfile: false,
      };

    case actionTypes.UPDATE_USER_PROFILE_PENDING:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: true,
          error: null,
        },
        isUpdatingProfile: true,
      };

    case actionTypes.UPDATE_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          isFetching: false,
          error: null,
          data: state.profiles.data.map((profile) => {
            if (profile._id === action.data._id) {
              return {
                ...profile,
                ...action.data,
              };
            }
            return profile;
          }),
          options: state.profiles.options.map((profile) => {
            if (profile._id === action.data._id) {
              return _.pick(action.data, [
                '_id',
                'name',
                'copied_from_profile_id',
              ]);
            }
            return profile;
          }),
        },
        isUpdatingProfile: false,
      };

    case actionTypes.UPDATE_USER_PROFILE_FAILED:
      return {
        ...state,
        profiles: {
          ...state.profiles,

          isFetching: false,
          error: action.error,
        },
        isUpdatingProfile: false,
      };

    case actionTypes.DELETE_USER_PROFILE_PENDING:
      return {
        ...state,
        profiles: {
          ...state.profiles,

          isFetching: true,
          error: null,
        },
        isDeletingProfile: true,
      };

    case actionTypes.DELETE_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        profiles: {
          ...state.profiles,

          isFetching: false,
          error: null,
          data: state.profiles.data.filter(
            (profile) => profile._id !== action.data._id
          ),
          options: state.profiles.options.filter(
            (profile) => profile._id !== action.data._id
          ),
        },
        isDeletingProfile: false,
      };

    case actionTypes.DELETE_USER_PROFILE_FAILED:
      return {
        ...state,
        profiles: {
          ...state.profiles,

          isFetching: false,
          error: action.error,
        },
        isDeletingProfile: false,
      };

    case actionTypes.ACTIVATE_USER_PROFILE_PENDING:
      return {
        ...state,
        isActivatingProfile: true,
        profileActivationError: null,
      };

    case actionTypes.ACTIVATE_USER_PROFILE_SUCCESSFUL:
      return {
        ...state,
        isActivatingProfile: false,
        profileActivationError: null,
      };

    case actionTypes.ACTIVATE_USER_PROFILE_FAILED:
      return {
        ...state,
        isActivatingProfile: false,
        profileActivationError: action.error,
      };

    default:
      return state;
  }
};

export default userProfiles;
