import { Grid } from '@material-ui/core';
import React from 'react';
import _ from 'lodash';
import { renderString } from './utils';
const MAIN_INDEX_ORDER = [
  {
    idx: 5,
  },
  {
    idx: 0,
    label: 'PAYER NAME',
  },
  {
    idx: 1,
  },
  {
    idx: 2,
    yn: true,
  },
  {
    idx: 3,
  },
  {
    idx: 4,
  },
];

const renderMainInfo = (props, classes) => {
  const { Main = [], Products = [] } = props;

  // chunk products into 2 groups
  const chunkedProducts = _.chunk(Products, Math.ceil(Products.length / 2));

  const givenIndexOrder = MAIN_INDEX_ORDER.map((item) => item.idx);
  const remainingIndexOrder = Object.keys(Main)
    .map((key) => parseInt(key))
    .filter((idx) => {
      return !givenIndexOrder.includes(idx);
    })
    .map((idx) => {
      return {
        idx,
      };
    });

  return (
    <>
      {[...MAIN_INDEX_ORDER, ...remainingIndexOrder].map((item) => {
        const { idx, label, yn } = item;
        const value = Main[idx];

        if (!value) {
          return '';
        }

        return (
          <>
            <Grid item xs={6} className={classes.keyContainer} key={idx + 'a'}>
              {label || value.k?.toUpperCase()}
            </Grid>
            <Grid
              key={idx + 'b'}
              item
              xs={6}
              className={classes.valueContainer}
              style={
                yn
                  ? {
                      background:
                        value.v.toUpperCase() === 'YES' ? '#6AA74F' : '#E06666',
                      fontWeight: 'bold',
                    }
                  : {}
              }>
              {renderString(value.v)}
            </Grid>
          </>
        );
      })}

      <Grid xs={12} className={classes.titleKeyContainer}>
        PRODUCTS
      </Grid>

      {chunkedProducts.map((chunk, idx) => {
        return (
          <Grid container key={idx} item xs={6}>
            {chunk.map((p, idx) => {
              return (
                <>
                  <Grid
                    key={idx + 'd'}
                    item
                    xs={6}
                    className={classes.keyContainer}>
                    {p.k}
                  </Grid>
                  <Grid
                    key={idx + 'e'}
                    item
                    xs={6}
                    className={classes.valueContainer}>
                    {renderString(p.v)}
                  </Grid>
                </>
              );
            })}
          </Grid>
        );
      })}
    </>
  );
};

const renderContractInfo = (props, classes) => {
  const cInfo = props['Contract Info'] || [];

  return (
    <>
      <Grid item xs={12} className={classes.titleKeyContainer}>
        CONTRACT INFO
      </Grid>
      {cInfo.map((p, idx) => {
        return (
          <>
            <Grid key={idx + 'f'} item xs={6} className={classes.keyContainer}>
              {p.k}
            </Grid>
            <Grid
              key={idx + 'g'}
              item
              xs={6}
              className={classes.valueContainer}>
              {renderString(p.v)}
            </Grid>
          </>
        );
      })}
    </>
  );
};

const renderMiscInfo = (props, classes) => {
  const miscInfo = (props.Misc || []).filter((mi) => mi.k !== 'COMMENTS');

  return (
    <>
      {miscInfo.map((p, idx) => {
        return (
          <>
            <Grid key={idx + 'h'} item xs={6} className={classes.keyContainer}>
              {p.k}
            </Grid>
            <Grid
              key={idx + 'i'}
              item
              xs={6}
              className={classes.valueContainer}>
              {renderString(p.v)}
            </Grid>
          </>
        );
      })}
    </>
  );
};

const renderDMEServicesInfo = (props, classes) => {
  const DMEServices = props['DME Services'] || [];
  const commentNode = DMEServices.find((p) => p.k === 'DME Services Comments');
  const otherNodes = DMEServices.filter((p) => p.k !== 'DME Services Comments');

  const totalRowCount = Math.ceil(otherNodes.length / 2);

  const firstGroupRowCount = Math.ceil((4 * totalRowCount) / 5);

  // 2 columns, 4 rows
  const chunks = _.chunk(otherNodes, firstGroupRowCount * 2);

  return (
    <>
      <Grid item xs={12} className={classes.titleKeyContainer}>
        DME SERVICES
      </Grid>
      {chunks.map((chunk, cidx) => {
        return (
          <Grid container key={cidx} item xs={6}>
            {chunk.map((p, idx) => {
              return (
                <>
                  <Grid
                    key={idx + 'j'}
                    item
                    xs={3}
                    className={classes.keyContainer}>
                    {p.k}
                  </Grid>
                  <Grid
                    key={idx + 'k'}
                    item
                    xs={3}
                    className={classes.valueContainer}
                    style={{
                      justifyContent: 'center',
                    }}>
                    {renderString(p.v)}
                  </Grid>
                </>
              );
            })}

            {cidx === 1 && commentNode && (
              <>
                <Grid
                  item
                  xs={12}
                  className={classes.keyContainer}
                  style={{
                    justifyContent: 'center',
                  }}>
                  DME Services Comments
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.valueContainer}
                  style={{
                    background: '#E06666',
                  }}>
                  {renderString(commentNode.v)}
                </Grid>
              </>
            )}
          </Grid>
        );
      })}

      {}
    </>
  );
};

const renderBillingInfo = (props, classes) => {
  const { 'Billing & CCS Dept Info': billingInfo = [] } = props;

  const uAndPNode = billingInfo.find((p) => p.k === 'User Name & Password');
  const otherNodes = billingInfo.filter((p) => p.k !== 'User Name & Password');

  return (
    <>
      <Grid container item xs={9}>
        <Grid item xs={12} className={classes.titleKeyContainer}>
          BILLING & CCS DEPT INFO
        </Grid>
        {otherNodes.map((p, idx) => {
          return (
            <>
              <Grid
                key={idx + 'l'}
                item
                xs={4}
                className={classes.keyContainer}>
                {p.k}
              </Grid>
              <Grid
                key={idx + 'm'}
                item
                xs={8}
                className={classes.valueContainer}>
                {renderString(p.v, '', 70)}
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid container item xs={3}>
        <Grid item xs={12} className={classes.titleKeyContainer}>
          User Name & Password
        </Grid>
        <Grid item xs={12} className={classes.valueContainer}>
          {renderString(uAndPNode?.v)}
        </Grid>
      </Grid>
    </>
  );
};

const renderCommentsAndChangesInfo = (props, classes) => {
  const { 'COVID 19 APPROVED CHANGES': c19Changes = [], Misc = [] } = props;
  const commentNode = Misc.find((p) => p.k === 'COMMENTS');

  return (
    <>
      <Grid container item xs={8}>
        <Grid item xs={12} className={classes.titleKeyContainer}>
          COMMENTS
        </Grid>
        <Grid item xs={12} className={classes.valueContainer}>
          {renderString(commentNode?.v)}
        </Grid>
      </Grid>
      <Grid container item xs={4}>
        <Grid item xs={12} className={classes.titleKeyContainer}>
          COVID 19 APPROVED CHANGES
        </Grid>
        {c19Changes.map((p, idx) => {
          return (
            <>
              <Grid
                key={idx + 'n'}
                item
                xs={6}
                className={classes.keyContainer}>
                {p.k}
              </Grid>
              <Grid
                key={idx + 'o'}
                item
                xs={6}
                className={classes.valueContainer}>
                {renderString(p.v)}
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

const renderTables = (selectedContract, classes) => {
  return (
    <Grid container>
      <Grid item xs={4}>
        <Grid container style={{ marginBottom: '16px' }}>
          {renderMainInfo(selectedContract, classes)}
        </Grid>
        <Grid container style={{ marginBottom: '16px' }}>
          {renderContractInfo(selectedContract, classes)}
        </Grid>
        <Grid container> {renderMiscInfo(selectedContract, classes)}</Grid>
      </Grid>
      <Grid item xs={8} style={{ paddingLeft: '16px' }}>
        <Grid container style={{ marginBottom: '16px' }}>
          {renderDMEServicesInfo(selectedContract, classes)}
        </Grid>
        <Grid container style={{ marginBottom: '16px' }}>
          {renderBillingInfo(selectedContract, classes)}
        </Grid>
        <Grid container>
          {renderCommentsAndChangesInfo(selectedContract, classes)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export {
  renderTables,
  renderMainInfo,
  renderContractInfo,
  renderMiscInfo,
  renderDMEServicesInfo,
  renderBillingInfo,
  renderCommentsAndChangesInfo,
};
