import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons'
import React from 'react'

const styles = theme => ({
  card: {
    margin: 4,
    fontSize: '0.8rem'
  },
  header: {
    padding: '4px 8px'
  },
  content: {
    padding: '4px 8px'
  },
  actions: {
    padding: '4px 8px',
    justifyContent: 'flex-end'
  }
})

class DraftOnView extends React.Component {
  constructor() {
    super()

    this.state = {}
  }

  editDraft = (field, idx) => {
    this.props.onEditField(field, idx)
  }

  removeField = idx => {
    this.props.onRemoveField(idx)
  }

  editOption = (option, fieldIndex, optionIndex) => {
    this.props.onEditOption(option, fieldIndex, optionIndex)
  }

  removeOption = (field, optionIndex) => {
    this.props.onRemoveOption(field, optionIndex)
  }

  prepareCoordinateDetails = field => {
    return (
      <>
        <span>
          <strong>[x, y]:&ensp;</strong>[{field.coords.x}, {field.coords.y}]
        </span>
        &ensp;|&ensp;
        <span>
          <strong>Width:&ensp;</strong>
          {field.coords.w}
        </span>
        &ensp;|&ensp;
        <span>
          <strong>Height:&ensp;</strong>
          {field.coords.h}
        </span>
      </>
    )
  }

  prepareOtherDetails = field => {
    return (
      <>
        &ensp;|&ensp;
        <span>
          <strong>Font-Size:&ensp;</strong>
          {field.fontSize ? `${field.fontSize}px` : 'N/A'}
        </span>
        &ensp;|&ensp;
        <span>
          <strong>Max-character-per-line:&ensp;</strong>
          {field.maxCharsPerLine || 'N/A'}
        </span>
        &ensp;|&ensp;
        <span>
          <strong>Max-width:&ensp;</strong>
          {field.maxWidth ? `${field.maxWidth}px` : 'N/A'}
        </span>
        &ensp;|&ensp;
        <span>
          <strong>Full-width:&ensp;</strong>
          {field.fullWidth ? 'Yes' : 'No'}
        </span>
        &ensp;|&ensp;
        <span>
          <strong>Multiline:&ensp;</strong>
          {field.multiline ? 'Yes' : 'No'}
        </span>
      </>
    )
  }

  render() {
    const { draft, classes } = this.props
    return (
      <>
        <div>
          {draft.name || 'Untitled draft'} {draft.tpl_id} Page{' '}
          {draft.page_pos + 1}&ensp;&gt;&ensp;
        </div>
        <div>
          {draft.fields.length < 1 ? (
            <Typography variant="subtitle2" color="textSecondary">
              No fields selected.
            </Typography>
          ) : (
            draft.fields.map((field, idx) => (
              <Card key={idx} className={classes.card}>
                <CardHeader
                  className={classes.header}
                  title={
                    <>
                      <strong>{idx + 1}.&ensp;</strong>
                      <span>
                        {field.label}&ensp;({field.nm})
                      </span>
                    </>
                  }
                  titleTypographyProps={{
                    variant: 'inherit'
                  }}
                />
                <CardContent className={classes.content}>
                  <span>
                    <strong>Type:&ensp;</strong>
                    {field.type || 'N/A'}
                  </span>
                  &ensp;|&ensp;
                  {['checkbox', 'radio'].includes(field.type) ? (
                    <>
                      <br />
                      <span>
                        <strong>
                          <u>Options:&ensp;</u>
                        </strong>
                      </span>
                      <br />
                      {field.options && field.options.length > 0 ? (
                        field.options.map((opt, optIdx) => (
                          <React.Fragment key={optIdx}>
                            &ensp;&ensp;
                            <strong>
                              {idx + 1}.{optIdx + 1}.&ensp;
                            </strong>
                            <span>
                              {opt.label}&ensp;({opt.nm})
                            </span>
                            <br />
                            &ensp;&ensp;&ensp;&ensp;
                            {this.prepareCoordinateDetails(opt)}
                            <br />
                            <div>
                              <IconButton
                                onClick={() =>
                                  this.editOption(opt, idx, optIdx)
                                }>
                                <EditIcon fontSize="small" color="primary" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  this.removeOption(field, optIdx)
                                }>
                                <DeleteIcon fontSize="small" color="error" />
                              </IconButton>
                            </div>
                          </React.Fragment>
                        ))
                      ) : (
                        <Typography variant="subtitle2" color="textSecondary">
                          No options selected.
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {this.prepareCoordinateDetails(field)}
                      {this.prepareOtherDetails(field)}
                    </>
                  )}
                </CardContent>
                <CardActions className={classes.actions}>
                  <IconButton onClick={() => this.editDraft(field, idx)}>
                    <EditIcon fontSize="small" color="primary" />
                  </IconButton>
                  <IconButton onClick={() => this.removeField(idx)}>
                    <DeleteIcon fontSize="small" color="error" />
                  </IconButton>
                </CardActions>
              </Card>
            ))
          )}
        </div>
      </>
    )
  }
}

export default withStyles(styles)(DraftOnView)
