import { WorkOrderActiontypes } from '../actions/workorder_ac.js';
const inititalState = {
    isFetching: false,
    isUpdating: false,
    records: null,
    error: null,
}
export const workOrderInView = (state = inititalState, action) => {
    switch (action.type) {
        case WorkOrderActiontypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case WorkOrderActiontypes.SET_IS_UPDATING:
            return {
                ...state,
                isUpdating: action.isUpdating,
            };

        case WorkOrderActiontypes.WORK_ORDER_UPDATED:
        case WorkOrderActiontypes.RECEIVED_DATA:
            return {
                ...state,
                records: action.records,
            };
        case WorkOrderActiontypes.UPDATE_LINE_ITEM:
            return {
                ...state,
                records: action.lines,
            };


        case WorkOrderActiontypes.FAILED_RECEIVING_DATA:
            return {
                ...state,
                records: null,
                isFetching: false,
                isUpdating: false,
                error: action.error,
            };

        default:
            return state;
    }
};
