import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import Select from '@material-ui/core/Select';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  fetchActiveRentalsList_ac,
  setCurrentIdn_ac,
  updateIdnActiveRentalList_ac,
} from '../actions/active_rentals.ac';
import Loader from './Shared/Loader';
import { lookups_ac } from '../actions/me.ac';
import { getCatalogImageUrl } from './common/utils';
import StyledTooltip from './Shared/StyledTooltip';

const styles = (theme) => ({
  label: {
    color: theme.palette.primary.main,
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  message: {
    fontSize: '1em',
    padding: '8px',
  },
  noBorderBottom: {
    borderBottom: 'unset',
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.primary.main,
    transition: 'transform 0.3s',
  },
  expandedIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main,
    transition: 'transform 0.3s',
  },
  collapsableRowContent: {
    backgroundColor: '#f1f1f1',
    boxShadow: '0px 0px 5px grey inset',
  },
  collapsableCellSpacing: {
    padding: '4px',
    margin: '4px 0px',
  },
  collapsableCellScroll: {
    maxHeight: '250px',
    overflowY: 'auto',
  },
  alternateRow: {
    '&:nth-child(odd)': {
      backgroundColor: `${theme.palette.secondary['50']}50`,
    },
  },
  padding8: {
    padding: 8,
  },
});

const LabelValueSet = (props) => {
  return (
    <Typography component="div" gutterBottom>
      <Typography color="primary">
        <strong>{props.label}</strong>&ensp;
      </Typography>
      <div>{props.value}</div>
    </Typography>
  );
};

const LabelValue = ({ label, value }) => {
  return (
    <div>
      <strong>{label}:</strong>&ensp;
      {value}
    </div>
  );
};

const StyledLoader = withStyles(() => ({
  message: {
    fontSize: '1em',
    padding: '8px',
  },
}))(Loader);

const NotAvailable = () => {
  return (
    <Typography color="textSecondary" inline>
      N/A
    </Typography>
  );
};

const ExpandableRow = (props) => {
  const { record, classes, allExpanded, isImageVisible } = props;

  const [isExpanded, setIsExpanded] = useState(allExpanded);

  useEffect(() => {
    return setIsExpanded(allExpanded);
  }, [allExpanded]);

  return (
    <>
      <TableRow>
        <TableCell
          width="1%"
          style={{ padding: 0 }}
          className={classes.noBorderBottom}>
          <IconButton
            className={isExpanded ? classes.expandedIcon : classes.expandIcon}
            onClick={() => setIsExpanded(!isExpanded)}>
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
        <TableCell
          width="12%"
          className={[classes.noBorderBottom, classes.padding8].join(' ')}>
          <Typography color="textPrimary" title="Item ID">
            <strong>#{record.id_item}</strong>
          </Typography>
          <div>
            <StyledTooltip
              placement="bottom"
              title={
                <>
                  <Typography align="center" color="inherit">
                    Encounter
                  </Typography>
                  <Typography color="inherit">
                    Type:&ensp;'{record.type_of_encounter}'&ensp;
                    |&ensp;ID:&ensp;{record.id_encounter}
                  </Typography>
                </>
              }>
              <span>
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  component="span"
                  inline>
                  <strong>{record.type_of_encounter}</strong>
                </Typography>
                {'-'}
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  component="span"
                  inline>
                  {record.id_encounter}
                </Typography>
              </span>
            </StyledTooltip>
          </div>
          <Typography variant="caption" style={{ color: '#1565c0' }}>
            {record.from_date
              ? moment(record.from_date, 'YYYY-MM-DD').format('MM-DD-YYYY') +
                ' '
              : ''}
            {record.through_date
              ? moment('To ' + record.through_date, 'YYYY-MM-DD').format(
                  'MM-DD-YYYY'
                )
              : ''}
          </Typography>
          {isImageVisible && (
            <div>
              <img
                src={getCatalogImageUrl(record.productcode)}
                onError={function (e) {
                  e.target.style.display = 'none';
                }}
                alt="img"
                style={{ width: '60px' }}
              />
            </div>
          )}
        </TableCell>
        <TableCell
          width="24%"
          className={[classes.noBorderBottom, classes.padding8].join(' ')}>
          <Typography variant="body">{record.productcode} </Typography>
          <Typography variant="body2">
            {record.productdescription}&ensp;&ensp;
            {record.unit_price ? (
              <>
                [<strong>${record.unit_price}</strong>]
              </>
            ) : (
              ''
            )}
          </Typography>
          <Typography color="textSecondary">
            {record.categorydescription}
          </Typography>
          {record.serial_number && (
            <strong color="textSecondary">SN: {record.serial_number}</strong>
          )}
        </TableCell>
        <TableCell
          width="22%"
          className={[classes.noBorderBottom, classes.padding8].join(' ')}>
          <Typography variant="body2">{record.organizationname}</Typography>
          <Typography color="textSecondary">
            Address: {record.provaddress_1}
            {record.provaddress_2 ? `, ${record.provaddress_2}` : ''}
          </Typography>
        </TableCell>
        <TableCell
          width="17%"
          className={[classes.noBorderBottom, classes.padding8].join(' ')}>
          <Typography variant="body2">
            By: {[record.salesrepfirstname, record.salesreplastname].join(' ')}
          </Typography>
          <Typography color="textSecondary">
            To: {record.deliverylocationname}
          </Typography>
        </TableCell>
        <TableCell
          width="18%"
          className={[classes.noBorderBottom, classes.padding8].join(' ')}>
          <Typography variant="body2">
            {record.billingcodedescription}
          </Typography>
          <Typography color="textSecondary">
            Payments: {record.totalpaymentsmade}
          </Typography>
          <Typography color="textSecondary">
            Months Left: {record.billingmonthsremaining}
          </Typography>

          <LabelValue
            label="Next billing date"
            value={
              record.nextbillingdate
                ? moment(record.nextbillingdate, 'YYYY-MM-DD').format(
                    'MM-DD-YYYY'
                  )
                : ''
            }
          />
        </TableCell>
      </TableRow>
      <TableRow style={{ height: 0 }}>
        <TableCell width="1%" style={{ padding: 0 }} />
        <TableCell
          colSpan={5}
          style={{ padding: 0 }}
          width="99%"
          className={classes.collapsableRowContent}>
          <Collapse in={isExpanded}>
            <Table padding="dense">
              <TableBody>
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell width="12.12%" className={classes.padding8}>
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet label="ID" value={record.id_item} />
                      <LabelValueSet
                        label="Encounter"
                        value={
                          <>
                            <LabelValue
                              label="ID"
                              value={record.id_encounter || <NotAvailable />}
                            />
                            <LabelValue
                              label="Type"
                              value={
                                record.type_of_encounter || <NotAvailable />
                              }
                            />
                          </>
                        }
                      />
                      <LabelValueSet label="Member" value={record.member} />
                      {record.medicalrecordnumber ? (
                        <LabelValueSet
                          label="Medical Record Number"
                          value={record.medicalrecordnumber}
                        />
                      ) : (
                        ''
                      )}
                      {record.frequency ? (
                        <LabelValueSet
                          label="Frequency"
                          value={record.frequency}
                        />
                      ) : (
                        ''
                      )}
                      {record.linecomment ? (
                        <LabelValueSet
                          label="Comment"
                          value={record.linecomment}
                        />
                      ) : (
                        ''
                      )}
                      <LabelValueSet
                        label="From"
                        value={
                          record.from_date ? (
                            moment(record.from_date, 'YYYY-MM-DD').format(
                              'MM-DD-YYYY'
                            )
                          ) : (
                            <NotAvailable />
                          )
                        }
                      />
                      <LabelValueSet
                        label="Through"
                        value={
                          record.through_date ? (
                            moment(record.through_date, 'YYYY-MM-DD').format(
                              'MM-DD-YYYY'
                            )
                          ) : (
                            <NotAvailable />
                          )
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell width="24.24%" className={classes.padding8}>
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Product"
                        value={
                          <>
                            <LabelValue
                              label="ID"
                              value={record.id_product || <NotAvailable />}
                            />
                            <LabelValue
                              label="Code"
                              value={record.productcode || <NotAvailable />}
                            />
                            <LabelValue
                              label="Description"
                              value={
                                record.productdescription || <NotAvailable />
                              }
                            />
                            <LabelValue
                              label="Unit Price"
                              value={
                                record.unit_price ? (
                                  `$${record.unit_price}`
                                ) : (
                                  <NotAvailable />
                                )
                              }
                            />
                          </>
                        }
                      />
                      <LabelValueSet
                        label="Product Category"
                        value={
                          <>
                            <LabelValue
                              label="ID"
                              value={
                                record.id_productcategory || <NotAvailable />
                              }
                            />
                            <LabelValue
                              label="Description"
                              value={
                                record.productcategorydescription || (
                                  <NotAvailable />
                                )
                              }
                            />
                          </>
                        }
                      />
                      <LabelValueSet
                        label="Category"
                        value={
                          <>
                            <LabelValue
                              label="ID"
                              value={record.id_category || <NotAvailable />}
                            />
                            <LabelValue
                              label="Description"
                              value={
                                record.categorydescription || <NotAvailable />
                              }
                            />
                          </>
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell width="22.22%" className={classes.padding8}>
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Organization Name"
                        value={record.organizationname}
                      />
                      <LabelValueSet
                        label="Address"
                        value={
                          <>
                            {record.provaddress_1 ? (
                              <>
                                {record.provaddress_1}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {record.provaddress_2 ? (
                              <>
                                {record.provaddress_2}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {record.provcity ? `${record.provcity}, ` : ''}
                            {record.provstate ? `${record.provstate}, ` : ''}
                            {record.provzip || ''}
                          </>
                        }
                      />
                      <LabelValueSet
                        label="Contact"
                        value={
                          <>
                            <LabelValue
                              label="Phone"
                              value={
                                record.provphone_number || <NotAvailable />
                              }
                            />
                            <LabelValue
                              label="Fax"
                              value={record.provfax_number || <NotAvailable />}
                            />
                          </>
                        }
                      />
                      {record.physicianlastname && record.physicianfirstname ? (
                        <LabelValueSet
                          label="Physician Details"
                          value={
                            <>
                              <LabelValue
                                label="Name"
                                value={
                                  record.physicianfirstname +
                                  ' ' +
                                  record.physicianlastname
                                }
                              />
                              <LabelValue
                                label="Degree"
                                value={
                                  record.physiciandegree || <NotAvailable />
                                }
                              />
                              <LabelValue
                                label="Address"
                                value={
                                  <>
                                    <br />
                                    {record.physicianaddress1 ? (
                                      <>
                                        {record.physicianaddress1}
                                        <br />
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {record.physicianaddress2 ? (
                                      <>
                                        {record.physicianaddress2}
                                        <br />
                                      </>
                                    ) : (
                                      ''
                                    )}
                                    {record.physiciancity
                                      ? `${record.physiciancity}, `
                                      : ''}
                                    {record.physicianstate
                                      ? `${record.physicianstate}, `
                                      : ''}
                                    {record.physicianzip || ''}
                                  </>
                                }
                              />
                              <LabelValue
                                label="Phone"
                                value={
                                  record.physicianphone || <NotAvailable />
                                }
                              />
                              <LabelValue
                                label="Fax"
                                value={
                                  record.physicianfaxnumber || <NotAvailable />
                                }
                              />
                            </>
                          }
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="17.17%" className={classes.padding8}>
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Sales Rep."
                        value={
                          <>
                            <LabelValue
                              label="Code"
                              value={record.salesrepcode}
                            />
                            <LabelValue
                              label="Name"
                              value={[
                                record.salesrepfirstname,
                                record.salesreplastname,
                              ].join(' ')}
                            />
                          </>
                        }
                      />
                      <LabelValueSet
                        label="Delivery Location"
                        value={
                          <>
                            <LabelValue
                              label="ID"
                              value={record.id_deliverylocation}
                            />
                            <LabelValue
                              label="Name"
                              value={record.deliverylocationname}
                            />
                          </>
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell width="18.18%" className={classes.padding8}>
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Billing"
                        value={
                          <>
                            <LabelValue
                              label="ID"
                              value={record.id_billing_code}
                            />
                            <LabelValue
                              label="Code"
                              value={record.billingcode || <NotAvailable />}
                            />
                            <LabelValue
                              label="Description"
                              value={
                                record.billingcodedescription || (
                                  <NotAvailable />
                                )
                              }
                            />
                            <LabelValue
                              label="Billable party member"
                              value={record.billable_party_member}
                            />
                            <LabelValue
                              label="Months remaining"
                              value={record.billingmonthsremaining}
                            />
                            <LabelValue
                              label="Next billing date"
                              value={
                                record.nextbillingdate
                                  ? moment(record.nextbillingdate).format(
                                      'MM-DD-YYYY'
                                    )
                                  : ''
                              }
                            />
                          </>
                        }
                      />
                      <LabelValueSet
                        label="Payment"
                        value={
                          <>
                            <LabelValue
                              label="Payer name"
                              value={record.payername || <NotAvailable />}
                            />
                            <LabelValue
                              label="Payer type"
                              value={record.payertypename || <NotAvailable />}
                            />
                            <LabelValue
                              label="Policy number"
                              value={record.policy_number || <NotAvailable />}
                            />
                            <LabelValue
                              label="Total payments"
                              value={record.totalpaymentsmade}
                            />
                          </>
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

class ActiveRentalsLanding extends React.Component {
  constructor() {
    super();

    this.state = {
      allExpanded: false,
      selectedPageNum: 0,
      isImageVisible: false,
    };
  }

  componentDidMount() {
    const { me, lookups, lookups_ac, activeRentals, account, currentIdn } =
      this.props;
    const { isFetching, error, records, accountNums, idn } = activeRentals;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;

      return lookups_ac();
    }

    if (currentIdn && currentIdn !== idn) {
      this.props.setCurrentIdn_ac(currentIdn);
      return this.fetch();
    }

    const hasError = currentIdn && account ? error[account] : error.all;

    if (!isFetching && !hasError && !records) {
      return this.fetch();
    }

    if (
      account &&
      currentIdn &&
      !(accountNums && accountNums.includes(account))
    ) {
      return this.fetch();
    }
  }

  componentDidUpdate() {
    const { me, lookups, lookups_ac, activeRentals, currentIdn, account } =
      this.props;
    const { isFetching, records, error, idn } = activeRentals;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;

      return lookups_ac();
    }

    if (currentIdn && currentIdn !== idn) {
      this.props.setCurrentIdn_ac(currentIdn);
      return this.fetch();
    }

    const hasError = currentIdn && account ? error[account] : error.all;

    if (!isFetching && !hasError && !records) {
      return this.fetch();
    }
  }

  fetch = (page = 0) => {
    const { account, currentIdn } = this.props;
    const params = {
      page,
    };

    if (currentIdn && account) {
      params.account = account;

      return this.props.updateIdnActiveRentalList_ac(params);
    }

    this.props.fetchActiveRentalsList_ac(params);
  };

  getPageStatement = (pagination) => {
    const { recordsPerPage, totalRecords, pageList } = pagination;
    const pageRef = recordsPerPage * +this.state.selectedPageNum;
    const pageEnd =
      pageRef + recordsPerPage > totalRecords
        ? totalRecords
        : pageRef + recordsPerPage;
    const totalPagesAvailable = pageList ? pageList.length : 0;

    return [
      `Displaying ${pageRef + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${+this.state.selectedPageNum + 1} of ${totalPagesAvailable}`,
    ].join('-');
  };

  handleAllCollapseToggle = () => {
    this.setState({
      allExpanded: !this.state.allExpanded,
    });
  };

  handleImageToggle = () => {
    this.setState({
      isImageVisible: !this.state.isImageVisible,
    });
  };

  onPageSelection = (event) => {
    const { value } = event.target;

    this.setState({
      selectedPageNum: value,
    });

    return this.fetch(value);
  };

  render() {
    const { allExpanded, selectedPageNum } = this.state;
    const { activeRentals, classes, account, currentIdn } = this.props;
    const { isFetching, records, pagination, error } = activeRentals;

    if (isFetching) {
      return <StyledLoader />;
    }

    if (error.all || error[account]) {
      return (
        <Typography color="error" className={classes.message}>
          Error fetchnig report!
        </Typography>
      );
    }

    if (!(records && pagination)) {
      return <div className={classes.message}>...</div>;
    }

    const displayRecords =
      currentIdn && account
        ? records.filter((record) => record.account === account)
        : [...records];

    return (
      <>
        <Grid container style={{ padding: 8 }}>
          <Grid item xs={6}>
            <Typography
              inline
              color="textSecondary"
              style={{ cursor: 'pointer' }}
              onClick={this.handleAllCollapseToggle}>
              {allExpanded ? 'Collapse All' : 'Expand All'}
              &ensp;[+/-]
            </Typography>
            <Typography color="textSecondary">
              {!account ? this.getPageStatement(pagination) : ''}
            </Typography>
            <Typography
              inline
              color="textSecondary"
              style={{ cursor: 'pointer' }}
              onClick={this.handleImageToggle}>
              {this.state.isImageVisible
                ? 'Collapse All Images'
                : 'Display All Images'}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {!account ? (
              <Select
                value={selectedPageNum}
                name="pageSelector"
                onChange={this.onPageSelection}>
                {pagination.pageList ? (
                  pagination.pageList.map((pgNum) => (
                    <MenuItem key={pgNum} value={pgNum}>
                      Page {1 + pgNum}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="0" disabled>
                    <Typography color="textSecondary">No Pages</Typography>
                  </MenuItem>
                )}
              </Select>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container style={{ height: 'calc(100% - 36px)' }}>
          <Grid item xs={12} style={{ height: '100%', overflowY: 'auto' }}>
            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell
                    width="6%"
                    style={{
                      borderRight: '1px solid #e0e0e0',
                      padding: 4,
                    }}>
                    Account
                  </TableCell>
                  <TableCell width="1%" />
                  <TableCell width="12%" className={classes.padding8}>
                    Rental
                  </TableCell>
                  <TableCell width="24%" className={classes.padding8}>
                    Product &#38; Category
                  </TableCell>
                  <TableCell width="22%" className={classes.padding8}>
                    Provider
                  </TableCell>
                  <TableCell width="17%" className={classes.padding8}>
                    Sales &#38; Delivery
                  </TableCell>
                  <TableCell width="18%" className={classes.padding8}>
                    Billing &#38; payment
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {displayRecords.length ? (
                  displayRecords.map((record) => (
                    <TableRow
                      key={record.account}
                      className={classes.alternateRow}>
                      <TableCell
                        colSpan="1"
                        style={{
                          borderRight: '1px solid #e0e0e0',
                          padding: 4,
                        }}>
                        <Typography
                          variant="body2"
                          color="primary"
                          align="center">
                          <strong>{record.account}</strong>
                        </Typography>
                      </TableCell>
                      <TableCell colSpan="6" style={{ padding: 0 }}>
                        <Table padding="dense">
                          <TableBody>
                            {record.lines.map((line) => (
                              <ExpandableRow
                                key={line.id_item}
                                record={line}
                                classes={classes}
                                allExpanded={allExpanded}
                                isImageVisible={this.state.isImageVisible}
                              />
                            ))}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      className={classes.collapsableRowContent}>
                      <Typography variant="subtitle1" color="textSecondary">
                        No Records Found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  activeRentals: stateFromStore.activeRentals,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      lookups_ac,
      setCurrentIdn_ac,
      fetchActiveRentalsList_ac,
      updateIdnActiveRentalList_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ActiveRentalsLanding))
);
