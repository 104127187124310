import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { every_landing_ac, login_with_superauth_ac } from '../actions/me.ac.js';

class GLogin extends React.Component {
  constructor(props) {
    super(props);
    this.responseGoogle = this.responseGoogle.bind(this);
    this.failed_responseGoogle = this.failed_responseGoogle.bind(this);
  }

  componentDidMount() {
    if (!this.props.me.landingChecked) {
      return this.props.every_landing_ac();
    }
    window.onGoogleLoginResponse = this.responseGoogle;
  }

  /*
  componentWillMount() {
    console.log('in comp willMount', window.googleLoginResponse);
    window.onGoogleLoginResponse = this.responseGoogle;
  }
  */

  componentDidUpdate() {
    window.onGoogleLoginResponse = this.responseGoogle;
  }

  failed_responseGoogle(response) {
    if (typeof this.props.onFailedGoogleResponse === 'function') {
      return this.props.onFailedGoogleResponse();
    }
  }

  responseGoogle(response) {
    window.googleUserObject = response;
    return this.props.login_with_superauth_ac();
  }

  render() {
    if (!this.props.me.landingChecked) {
      return <div>..checking your log in status</div>;
    }

    //already logged in
    if (
      this.props.me.isLoggedIn === false
      //|| this.props.forceDisplay === true
    ) {
      return (
        <div>
          <div
            id="g_id_onload"
            data-client_id={this.props.clientId}
            data-callback={'handleCredentialResponse'}
            data-auto_select="true"
            data-cancel_on_tap_outside="false"
            data-auto_prompt="true"
          />
          <div
            className="g_id_signin"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="rectangular"
            data-logo_alignment="left"></div>
        </div>
      );
    }

    //already logged in
    if (this.props.me.user) {
      return <div>Logged in with SuperCare GMail</div>;
    }
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      every_landing_ac,
      login_with_superauth_ac,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(GLogin);
