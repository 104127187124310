export function dcs(
  state = {
    isFetching: false,
    isUpdating: false,
    updateError: null,
    error: null,
    pkg: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_DCS':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'RECEIVED_DCS':
      return Object.assign({}, state, {
        isFetching: false,
        pkg: action.pkg,
        error: null
      })
    case 'FAILED_DCS':
      return Object.assign({}, state, {
        isFetching: false,
        pkg: null,
        error: action.error
      })

    case 'UPDATE_DC_PENDING':
      return Object.assign({}, state, {
        isUpdating: true
      })

    case 'UPDATE_DC_SUCCESS':
      return Object.assign({}, state, {
        isUpdating: false,
        pkg: action.pkg,
        error: null
      })

    case 'UPDATE_DC_FAILED':
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.error
      })
      
    default:
      return state
  }
}
