import { open_snack_ac } from './snack.ac.js'

export const WorkOrderActiontypes = {
    RECEIVED_DATA: 'RECEIVED_DATA',
    FAILED_RECEIVING_DATA: 'FAILED_RECEIVING_DATA',
    SET_IS_FETCHING: 'SET_IS_FETCHING',
    WORK_ORDER_UPDATED: 'WORK_ORDER_UPDATED',
    UPDATE_LINE_ITEM: 'UPDATE_LINE_ITEM',
    SET_IS_UPDATING: 'SET_IS_UPDATING'
};
export const fetchWorkOrders_ac = (params) => {
    return (dispatch) => {
        dispatch(fetching(true));
        return window.sch.post('/api/dtrack/wo/', { ...params })
            .then(response => {
                if (response.error) {
                    throw new Error(response.error)
                }
                dispatch({
                    type: WorkOrderActiontypes.RECEIVED_DATA,
                    records: response.result.lines
                });
            })
            .catch(error => {
                dispatch({
                    type: WorkOrderActiontypes.FAILED_RECEIVING_DATA,
                    error
                });
            })
            .finally(() => {
                dispatch(fetching(false));
            });
    }
}
export const saveWorkOrder_ac = (params) => {
    return (dispatch) => {
        dispatch(updating(true));
        return window.sch.post('/api/dtrack/sn_update', { ...params })
            .then(response => {
                if (response.error) {
                    throw new Error(response.error)
                }
                dispatch({
                    type: WorkOrderActiontypes.WORK_ORDER_UPDATED,
                    records: params.lines,
                });
                dispatch(open_snack_ac({
                    variant: 'info',
                    message: 'Work order updated'
                }))
            })
            .catch(error => {
                dispatch(open_snack_ac({
                    variant: 'error',
                    message: 'error updating work order'
                }))
            })
            .finally(() => {
                dispatch(updating(false));
            });
    }
}

export const updateWorkOrder_ac = (lines) => {
    return (dispatch) => {
        dispatch({
            type: WorkOrderActiontypes.UPDATE_LINE_ITEM,
            lines,
        });
    }
}
export const fetching = isFetching => ({
    type: WorkOrderActiontypes.SET_IS_FETCHING,
    isFetching
})

export const updating = isUpdating => ({
    type: WorkOrderActiontypes.SET_IS_UPDATING,
    isUpdating
})
