import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Button, Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';

import * as routes from '../../../constants/routes';
import IdxOrderingCliniciansEdit from './IdxOrderingCliniciansEdit';
import IdxOrderingCliniciansInfo from './IdxOrderingCliniciansInfo';

const CREATE_MODE = 'create';
const DETAIL_MODE = 'detail';
const EDIT_MODE = 'edit';
const ACTION_MODE = 'action';

const modeToTitleMap = {
  [DETAIL_MODE]: 'Viewing Clinician Details',
  [CREATE_MODE]: 'Create New Ordering Clinician',
  [EDIT_MODE]: 'Editing Clinician Details',
  [ACTION_MODE]: 'Action Panel',
};

const styles = () => ({
  closeLabel: {
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold',
    },
    textTransform: 'uppercase',
    fontSize: 13,
    marginLeft: 6,
    fontWeight: 'normal',
  },
});

const IdxOrderingCliniciansDetails = (props) => {
  const { classes, repMode = false, ...rest } = props;

  const [clinicianDetail, setClinicianDetail] = useState();
  const [ocRelationships, setOCRelationships] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const handleCancel = () => {
    if (
      props.history.location.pathname.includes('update') &&
      document.referrer !== '/' &&
      document.referrer !== ''
    ) {
      props.history.goBack();
    } else {
      props.history.push(
        repMode ? routes.SALES_REP_DOCTOR : routes.ADMIN_IDX_OC
      );
    }
  };

  const fetchOCDetail = () => {
    if (isFetching) return;
    setIsFetching(true);

    window.sch
      .post('/api/idx/admin/oc/detail', { _id: +props.match.params.id })
      .then((res) => {
        if (!res.oc) {
          handleCancel();
        } else {
          setClinicianDetail(res.oc);
          if (!repMode) setOCRelationships(res.existing_rels);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (!isNaN(props.match.params.id)) fetchOCDetail();
    // eslint-disable-next-line
  }, [props.match.params.id]);

  const handleUpdate = (newOC) => {
    if (newOC?._id) {
      return setClinicianDetail(newOC);
    }

    return fetchOCDetail();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto',
        marginBottom: '18px',
      }}>
      <Typography
        inline
        color="textPrimary"
        style={{ fontSize: '13px', fontWeight: 'bold', padding: '0px 12px' }}>
        <Switch>
          <Route
            exact
            path={repMode ? routes.SALES_REP_DOCTOR : routes.ADMIN_IDX_OC}
            render={() => <>{modeToTitleMap[ACTION_MODE]}</>}
          />
          <Route
            exact
            path={
              repMode
                ? routes.SALES_REP_DOCTOR_CREATE
                : routes.ADMIN_IDX_OC_CREATE
            }
            render={() => <>{modeToTitleMap[CREATE_MODE]}</>}
          />
          <Route
            exact
            path={
              repMode ? routes.SALES_REP_DOCTOR_VIEW : routes.ADMIN_IDX_OC_VIEW
            }
            render={() => (
              <>
                {modeToTitleMap[DETAIL_MODE]}{' '}
                <Typography
                  inline
                  color="secondary"
                  variant="inherit"
                  onClick={() => {
                    props.history.push(
                      repMode ? routes.SALES_REP_DOCTOR : routes.ADMIN_IDX_OC
                    );
                  }}
                  className={classes.closeLabel}>
                  Close this Section (Back to Action Panel)
                </Typography>
              </>
            )}
          />
          <Route
            exact
            path={
              repMode
                ? routes.SALES_REP_DOCTOR_UPDATE
                : routes.ADMIN_IDX_OC_UPDATE
            }
            render={() => <>{modeToTitleMap[EDIT_MODE]}</>}
          />
        </Switch>
      </Typography>

      <Divider />
      <Switch>
        <Route
          exact
          path={repMode ? routes.SALES_REP_DOCTOR : routes.ADMIN_IDX_OC}
          render={() => {
            return (
              <div style={{ padding: '8px 8px 8px 12px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => {
                    props.history.push(
                      repMode
                        ? routes.SALES_REP_DOCTOR_CREATE
                        : routes.ADMIN_IDX_OC_CREATE
                    );
                  }}>
                  Create new ordering clinician <AddIcon />
                </Button>
                <Typography
                  variant="body2"
                  style={{ textAlign: 'left', marginTop: '6px' }}>
                  <>
                    OR <br /> Click on any OCs/Doctors from the list to
                    view/edit an existing one.
                  </>
                </Typography>
              </div>
            );
          }}
        />
        <Route
          exact
          path={
            repMode
              ? routes.SALES_REP_DOCTOR_CREATE
              : routes.ADMIN_IDX_OC_CREATE
          }
          render={() => {
            return (
              <div style={{ height: '100%', overflowY: 'auto' }}>
                <IdxOrderingCliniciansEdit
                  {...rest}
                  repMode={repMode}
                  createMode={true}
                  onCancel={handleCancel}
                />
              </div>
            );
          }}
        />
        <Route
          exact
          path={
            repMode ? routes.SALES_REP_DOCTOR_VIEW : routes.ADMIN_IDX_OC_VIEW
          }
          render={() =>
            clinicianDetail && (
              <IdxOrderingCliniciansInfo
                {...rest}
                repMode={repMode}
                onCancel={handleCancel}
                isFetching={isFetching}
                data={clinicianDetail}
                relationships={ocRelationships}
                onUpdate={handleUpdate}
              />
            )
          }
        />
        <Route
          exact
          path={
            repMode
              ? routes.SALES_REP_DOCTOR_UPDATE
              : routes.ADMIN_IDX_OC_UPDATE
          }
          render={(props) =>
            clinicianDetail && (
              <div style={{ height: '100%', overflowY: 'auto' }}>
                <IdxOrderingCliniciansEdit
                  repMode={repMode}
                  createMode={false}
                  isFetching={isFetching}
                  data={clinicianDetail}
                  {...rest}
                  onCancel={handleCancel}
                  onUpdate={handleUpdate}
                />
              </div>
            )
          }
        />
      </Switch>
    </div>
  );
};

export default withRouter(withStyles(styles)(IdxOrderingCliniciansDetails));
