/* eslint no-unused-vars: "off" */
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchCatalog_ac,
  toggleCatalogDiscontinued_ac,
} from '../../actions/catalog.ac';
import { fetchFALookups_ac } from '../../actions/fa_lookups.ac';
import FetchingStatementWrap from '../FetchingStatementWrap';
import { Pagination } from '../PaginationNew';
import Loader from '../Shared/Loader';
import ProductCategory from './ProductCategory/ProductCategory';
import ProductListing from './ProductListing/index';

const autoSearchConfig = {
  has: [
    { label: 'allow_pickup', value: true },
    { label: 'sn_required', value: true },
    { label: 'aob_required', value: true },
    { label: 'is_tank', value: true },
    { label: 'pap_vent_alert', value: true },
    { label: 'req_wheelchair_form', value: true },
    { label: 'image_set', value: true },
  ],
  not: [
    { label: 'allow_pickup', value: false },
    { label: 'aob_required', value: false },
    { label: 'sn_required', value: false },
    { label: 'is_tank', value: false },
    { label: 'pap_vent_alert', value: false },
    { label: 'req_wheelchair_form', value: false },
    { label: 'image_set', value: false },
  ],
};

const parseSearch = (search = '', queryKey = 'query') => {
  const filterKeys = Object.keys(autoSearchConfig);
  const items = search.split(' ');

  const textItems = [];
  const filterItems = {};

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (!item) continue;
    if (item.includes(':')) {
      const [filterKey, filterLabel] = item.split(':');
      if (filterKeys.includes(filterKey)) {
        filterItems[filterLabel] = autoSearchConfig[filterKey].find(
          (it) => it.label === filterLabel
        ).value;
        continue;
      }
    }
    textItems.push(item);
  }

  const parsedFilter = { ...filterItems };

  const finalQuery = textItems.join(' ');

  if (Boolean(finalQuery)) {
    parsedFilter[queryKey] = finalQuery;
  }

  return parsedFilter;
};

const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    paddingBottom: 32,
  },
  tableRow: {
    fontSize: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 10,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  formGroupRoot: {
    marginTop: 2,
    marginBottow: 2,
    flexDirection: 'row',
  },
  spacer: {
    height: 40,
  },
  logEntry: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 11,
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    marginLeft: 4,
    color: '#244e91',
    fontSize: '.98em',
  },
  fullHeight: {
    height: '100%',
  },
});

const HEXES = [
  '#E91E63',
  '#673AB7',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#CDDC39',
  '#FFC107',
  '#FF5722',
  '#9E9E9E',
];

const SearchOptions = ({ watch, config, invoker = ':', onOptionClick }) => {
  const [visible, setVisible] = React.useState(null);

  const getLastSearchCmd = () => {
    return watch.substring(` ${watch}`.lastIndexOf(' '), watch.length - 1);
  };

  const searchKeys = Object.keys(config);

  const shortestKey = React.useMemo(
    () => searchKeys.reduce((a, b) => (a.length < b.length ? a : b)),
    // eslint-disable-next-line
    [config]
  );

  const invokeOffset = shortestKey.length + 1;

  React.useEffect(() => {
    if (
      watch.length >= invokeOffset &&
      watch.charAt(watch.length - 1) === invoker
    ) {
      const lastSearchCmd = getLastSearchCmd();
      setVisible(searchKeys.includes(lastSearchCmd) ? lastSearchCmd : null);
    } else {
      setVisible(null);
    }
    // eslint-disable-next-line
  }, [watch]);

  const handleOptionClick = (o) => {
    onOptionClick(o);
  };

  const renderOptions = (opt) =>
    opt.map((o, i) => (
      <MenuItem
        key={i}
        value={o.label}
        style={{
          borderBottom: i < opt.length - 1 ? '1px solid grey' : 'none',
        }}
        onClick={() => handleOptionClick(o.label)}>
        {o.label}
      </MenuItem>
    ));

  return visible ? (
    <div
      style={{
        position: 'absolute',
        background: 'white',
        border: '1px solid grey',
        borderWidth: '0px 1px 1px 1px',
      }}>
      {renderOptions(config[visible])}
    </div>
  ) : (
    <></>
  );
};

class AccountSelection extends React.Component {
  render() {
    const { idnInView, classes, selectedHDMSAccountPosition } = this.props;
    const formGroupRow = { root: classes.formGroupRoot };

    if (
      !idnInView.idnDoc ||
      !idnInView.idnDoc.aa ||
      idnInView.idnDoc.aa.length === 0
    ) {
      return (
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormHelperText style={{ marginTop: 0 }}>
            No HDMS Accounts Linked
          </FormHelperText>
        </FormControl>
      );
    }

    if (idnInView.idnDoc.aa.length === 1) {
      return (
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="label">
            Account: {idnInView.idnDoc.aa[0]}
          </FormLabel>
          <FormHelperText style={{ marginTop: 0 }}>
            Select the applicable account number.
          </FormHelperText>
        </FormControl>
      );
    }

    return (
      <FormControl
        required
        component="fieldset"
        style={{ margin: 0, padding: 0 }}>
        <FormHelperText style={{ marginTop: 0 }}>
          Select the applicable account number.
        </FormHelperText>
        <RadioGroup
          classes={formGroupRow}
          onChange={this.props.onHdmsAccountPositionSelected}
          value={selectedHDMSAccountPosition}>
          {idnInView.idnDoc.aa.map((a, pos) => (
            <FormControlLabel
              labelPlacement="left"
              key={a}
              value={pos.toString()}
              label={a}
              control={<Radio />}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

AccountSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  onHdmsAccountPositionSelected: PropTypes.func.isRequired,
};

class CatalogLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records: null,
      posInEdit: null,
      itemOnEdit: {},
      searchStr: '',
      selectedHDMSAccountPosition: '0',
      page: 0,
      discontinued: false,
      filterSequence: [],
      filters: {},
    };
    this.onCatExpand = this.onCatExpand.bind(this);
    this.onCatCollapse = this.onCatCollapse.bind(this);
    this.onSelect = this.onSelect.bind(this);

    this.edit = this.edit.bind(this);
    this.onSearchStrKeyPress = this.onSearchStrKeyPress.bind(this);
    this.onSearchStrChanged = this.onSearchStrChanged.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onHdmsAccountPositionSelected =
      this.onHdmsAccountPositionSelected.bind(this);
  }

  onHdmsAccountPositionSelected(evt, value) {
    this.setState({ selectedHDMSAccountPosition: value });
  }

  onPageChange(newPageNum) {
    let targetPage = parseInt(newPageNum, 10) - 1;
    return this.props.fetchCatalog_ac(
      'items',
      this.props.catalog.lastMethod === 'select'
        ? {
            acctMember: _.get(this.props.idnInView.idnDoc, 'aa', []).length
              ? this.props.idnInView.idnDoc.aa[
                  this.state.selectedHDMSAccountPosition
                ]
              : -1,
            cat_id: this.props.catalog.lastParams.cat_id,
            mfg_nm: this.props.catalog.lastParams.mfg_nm,
            page: targetPage,
            lastMethod: 'select',
            ...parseSearch(this.state.searchStr, 'str'),
            discontinued: this.props.catalog.includeDiscontinued,
          }
        : {
            acctMember: _.get(this.props.idnInView.idnDoc, 'aa', []).length
              ? this.props.idnInView.idnDoc.aa[
                  this.state.selectedHDMSAccountPosition
                ]
              : -1,
            ...parseSearch(this.state.searchStr, 'str'),
            page: targetPage,
            lastMethod: 'search',
            discontinued: this.props.catalog.includeDiscontinued,
          }
    );
  }

  componentDidMount() {
    const { catalog } = this.props;

    this.props.fetchFALookups_ac({ kind: 'tracking_group' });

    if (catalog.pkg || catalog.error || catalog.isFetching) return;
    return this.props.fetchCatalog_ac('cat', {
      discontinued: catalog.includeDiscontinued,
    });
  }

  componentDidUpdate(prevProps) {
    const { catalog } = this.props;
    const { catalog: prev_catalog } = prevProps;

    if (catalog.error || catalog.isFetching) return;
    if (
      catalog.pkg === null ||
      catalog.includeDiscontinued !== prev_catalog.includeDiscontinued
    ) {
      return this.props.fetchCatalog_ac('cat', {
        discontinued: catalog.includeDiscontinued,
      });
    }
  }

  onSearchStrChanged(e) {
    this.setState({ searchStr: e.target.value });
  }

  onSearchStrKeyPress(e) {
    if (e.key === 'Enter') {
      return this.props.fetchCatalog_ac('items', {
        acctMember: _.get(this.props.idnInView.idnDoc, 'aa', []).length
          ? this.props.idnInView.idnDoc.aa[
              this.state.selectedHDMSAccountPosition
            ]
          : -1,
        ...parseSearch(this.state.searchStr, 'str'),
        lastMethod: 'search',
        discontinued: this.props.catalog.includeDiscontinued,
      });
    }
  }

  onCatExpand(cat_id) {
    return this.props.fetchCatalog_ac('mfg', {
      cat_id,
      discontinued: this.props.catalog.includeDiscontinued,
    });
  }

  onCatCollapse(a) {}

  onSelect(cat_id, mfg_nm, is_macro) {
    return this.props.fetchCatalog_ac('items', {
      cat_id,
      acctMember: _.get(this.props.idnInView.idnDoc, 'aa', []).length
        ? this.props.idnInView.idnDoc.aa[this.state.selectedHDMSAccountPosition]
        : -1,
      mfg_nm,
      lastMethod: 'select',
      discontinued: this.props.catalog.includeDiscontinued,
      ...parseSearch(this.state.searchStr, 'str'),
      is_macro: is_macro || false,
    });
  }

  edit = (posInEdit) => () => {
    this.setState({
      isEditing: true,
      posInEdit,
      itemOnEdit: {
        ...this.state.records[posInEdit],
        instruction: '',
      },
    });
  };

  closeEdit() {
    this.setState({ isEditing: false, posInEdit: null, itemOnEdit: {} });
  }

  render() {
    const { classes, me, catalog, idnInView, isMaintainance } = this.props;
    const title = 'Product Catalog Listing';

    if (catalog.isFetching) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      );
    }

    if (catalog.pkg === null) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      );
    }

    let pageStatement = '';
    let pageSelector = '';
    if (catalog.pkg.pagination) {
      const { pagination: pg } = catalog.pkg;
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Items',
        catalog.lastParams.is_macro
          ? ` in Macro: ${catalog.lastParams.mfg_nm} `
          : '',
        'Page ' + (pg.currentPageNum + 1) + ' of ' + pg.totalPagesAvailable,
      ].join(' - ');

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <Pagination
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.onPageChange}
            disableAll={catalog.isFetchingItems}
          />
        );
      }
    }

    return (
      <main className={classes.content}>
        <Grid container direction="row" spacing={8}>
          <Grid item xs={4} style={{ position: 'relative' }}>
            <TextField
              fullWidth
              onKeyPress={this.onSearchStrKeyPress}
              onChange={this.onSearchStrChanged}
              placeholder="Search by Product Code or Description"
              style={{ fontSize: 10, margin: 0 }}
              value={this.state.searchStr}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <SearchOptions
              watch={this.state.searchStr}
              config={autoSearchConfig}
              onOptionClick={(opt) => {
                this.setState({ searchStr: this.state.searchStr + opt + ' ' });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              style={{ marginTop: 0, fontSize: 10 }}
              control={
                <Switch
                  checked={catalog.includeDiscontinued}
                  classes={{ switchBase: classes.sitSwitch }}
                  onChange={this.props.toggleCatalogDiscontinued_ac}
                  value={true}
                />
              }
              label={'Include Discontinued'}
            />
          </Grid>

          <Grid item xs={2}>
            <Typography className={classes.pageStatement} color="textSecondary">
              {pageStatement}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {pageSelector}
          </Grid>
          {!isMaintainance && (
            <Grid item xs={2}>
              <AccountSelection
                classes={classes}
                onHdmsAccountPositionSelected={
                  this.onHdmsAccountPositionSelected
                }
                idnInView={idnInView}
                selectedHDMSAccountPosition={
                  this.state.selectedHDMSAccountPosition
                }
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction="row"
          spacing={8}
          className={classes.scrollArea}>
          <Grid
            xs={3}
            item
            className={[classes.tableRow, classes.fullHeight].join(' ')}>
            <ProductCategory
              categories={catalog.pkg}
              onExpand={this.onCatExpand}
              onCollapse={this.onCatCollapse}
              onSelect={this.onSelect}
            />
          </Grid>
          <Grid
            xs={9}
            item
            className={[classes.tableRow, classes.fullHeight].join(' ')}>
            {catalog.isFetchingItems ? (
              <Loader />
            ) : (
              <ProductListing
                isCatalogueMaintainer={this.props.isCatalogueMaintainer}
                configView={true}
                products={catalog.pkg.items || []}
                display_as="row"
                isMaintainance={isMaintainance}
              />
            )}
          </Grid>
        </Grid>
      </main>
    );
  }
}

CatalogLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  catalog: stateFromStore.catalog,
  idnInView: stateFromStore.idnInView,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCatalog_ac,
      fetchFALookups_ac,
      toggleCatalogDiscontinued_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CatalogLanding))
);
