import { Button, Card, CardContent, Chip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchActiveRentalsList_ac } from '../../actions/active_rentals.ac.js';
import { fetchIdnAccountInfo_ac } from '../../actions/idn_account_info.ac.js';
import { fetchIdnWos_ac } from '../../actions/idn_wos.ac.js';
import {
  downloadReactHealthReport_ac,
  fetchReactHealthClinicians_ac,
  fetchReactHealthPatient_ac,
  fetchReactHealthProviders_ac,
  flushReactHealthPatient_ac,
  flushReactHealthProviders_ac,
  onboardPatientReactHealth_ac,
} from '../../actions/react_health.ac';
import { open_snack_ac } from '../../actions/snack.ac';
import { snakeCaseToTitleCase } from '../../util/string';
import Loader from '../Shared/Loader';
import { Accordion, DynamicAccordion } from '../common';
import ReactHealthOnboard from './ReactHealthOnboard';
import ReactHealthReportModal from './ReactHealthReportModal';

const styles = () => ({});

const getLocLabel = (loc) => (
  <div style={{ color: '#000', margin: 0 }}>
    {loc.address1 && <>{loc.address1}</>} {loc.address2 && <> {loc.address2}</>}
    {' | '}
    {[loc.city || '', loc.state || ''].join(', ')}
    <strong style={{ color: 'blue' }}> {loc.zip}</strong>
    {' | '}
    <strong style={{ color: 'orange' }}> {loc.country}</strong>
  </div>
);

const DeviceNode = ({ device }) => {
  const { model, serial, sn_assigned_date, sn_removed_date, settings } = device;

  const latestSetting = settings
    ? settings?.length
      ? settings[0]
      : settings
    : undefined;

  return (
    <Card
      variant="outlined"
      style={{
        marginBottom: '10px',
        marginRight: '10px',
        display: 'inline-block',
      }}>
      <CardContent
        style={{
          padding: '6px 12px',
        }}>
        <Typography variant="h5" component="h2">
          {model}
        </Typography>
        <Typography style={{ color: '#000' }} gutterBottom>
          Serial: {serial}
        </Typography>
        <Typography style={{ color: '#000' }} gutterBottom>
          SN Assigned Date: {sn_assigned_date}
        </Typography>
        {sn_removed_date ? (
          <Typography color="textSecondary" gutterBottom>
            SN Removed Date: {sn_removed_date}
          </Typography>
        ) : null}

        {latestSetting ? (
          <Accordion
            includeHeaderClick={true}
            header={
              <span
                style={{
                  fontWeight: 'normal',
                  fontSize: '12px',
                }}>
                {'Settings:'}
              </span>
            }>
            {Object.keys(latestSetting).map((key, idx) => {
              return (
                <Typography key={idx} color="textSecondary" gutterBottom>
                  {snakeCaseToTitleCase(key)}:{' '}
                  <span
                    style={{
                      fontWeight: 'normal',
                      color: '#000',
                    }}>
                    {latestSetting[key]}
                  </span>
                </Typography>
              );
            })}
          </Accordion>
        ) : null}
      </CardContent>
    </Card>
  );
};

const PhysicianNode = ({ physician }) => {
  const { organization_name, first_name, last_name, npi, id } = physician;

  return (
    <Card
      variant="outlined"
      style={{
        marginBottom: '10px',
        marginRight: '10px',
        display: 'inline-block',
      }}>
      <CardContent
        style={{
          padding: '6px 12px',
        }}>
        <Typography variant="h5" component="h2">
          {`${last_name}, ${first_name}`}
        </Typography>
        <Typography style={{ color: '#000' }} gutterBottom>
          ID: {id}
        </Typography>
        <Typography style={{ color: '#000' }} gutterBottom>
          NPI: {npi}
        </Typography>
        {organization_name ? (
          <Typography color="textSecondary" gutterBottom>
            Organization: {organization_name}
          </Typography>
        ) : null}
      </CardContent>
    </Card>
  );
};

const ReactHealthView = (props) => {
  const { idnData, reactHealth } = props;

  const [isReportModalOpen, setIsReportModalOpen] = React.useState(false);

  const accts = idnData?.idnDoc?.aa_num || [];
  const acct = accts[0];

  const patientCtr = reactHealth?.patient;
  const isFetchingPTReport = reactHealth?.isFetchingPTReport || false;

  const { data: patient, isFetching } = patientCtr || {};

  useEffect(() => {
    if (!acct) return;
    props.fetchReactHealthPatient_ac(acct);

    // eslint-disable-next-line
  }, [acct]);

  return (
    <div
      style={
        patient && {
          width: '60%',
          border: '2px solid lightgrey',
          padding: 12,
          borderRadius: 6,
        }
      }>
      {isFetching ? (
        <Loader type="circular" />
      ) : patient ? (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 12,
            }}>
            <span
              style={{
                fontSize: 12,
              }}>
              <b>ReactHealth Patient ID:</b> <span>{patient.patient_id}</span>
              <Chip
                style={{
                  marginLeft: 10,
                  fontWeight: 600,
                  color: 'white',
                  backgroundColor: patient.active === 1 ? 'green' : 'red',
                }}
                label={patient.active === 1 ? 'Active' : 'Inactive'}
              />
            </span>

            <Button
              color="primary"
              variant="outlined"
              disabled={isFetchingPTReport || !patient.patient_id}
              onClick={() => {
                setIsReportModalOpen(true);
              }}>
              {'Report'}
            </Button>
          </div>
          <DynamicAccordion
            list={[
              {
                label: 'Email / Phone / Mobilephone',
                value: (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 5,
                      padding: 6,
                    }}>
                    <span>{patient.email || 'NA'} /</span>
                    <span>{patient.phone || 'NA'} /</span>
                    <span>{patient.mobilephone || 'NA'}</span>
                  </div>
                ),
              },
              {
                label: 'Birth Date',
                value: patient.birth_date,
              },
              {
                label: 'Address',
                value: getLocLabel(patient),
              },
              {
                label: 'Therapy Start',
                value: moment(patient.therapy_start).format('lll'),
              },
              {
                label: 'Created at',
                value: moment(patient.created).format('lll'),
              },
              {
                label: 'Modified at',
                value: moment(patient.modified).format('lll'),
              },
              {
                label: 'Physician',
                value: Boolean(patient.physician) ? (
                  <div
                    style={{
                      padding: '6px',
                      paddingLeft: '24px',
                      overflowX: 'auto',
                    }}>
                    {(patient.physician?.length
                      ? patient.physician
                      : [patient.physician]
                    ).map((d) => (
                      <PhysicianNode physician={d} />
                    ))}
                  </div>
                ) : (
                  'N/A'
                ),
              },
              {
                label: 'Devices',
                value: (
                  <div
                    style={{
                      padding: '6px',
                      paddingLeft: '24px',
                      overflowX: 'auto',
                    }}>
                    {(patient.devices || []).map((d) => (
                      <DeviceNode device={d} />
                    ))}
                  </div>
                ),
              },
            ]}
          />
        </>
      ) : (
        <ReactHealthOnboard {...props} />
      )}

      {patient && (
        <ReactHealthReportModal
          open={isReportModalOpen}
          onClose={() => setIsReportModalOpen(false)}
          patient={patient}
          isDownloading={isFetchingPTReport}
          openSnack={props.open_snack_ac}
          onDownload={(id, params) => {
            props.downloadReactHealthReport_ac(id, params, {
              onSuccess: () => {
                setIsReportModalOpen(false);
                props.open_snack_ac({
                  variant: 'success',
                  message:
                    'Report downloaded, Please check your downloads folder',
                });
              },
              onError: () => {
                props.open_snack_ac({
                  variant: 'error',
                  message: 'Failed to download report',
                });
                setIsReportModalOpen(false);
              },
            });
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  reactHealth: stateFromStore.reactHealth,
  idnAccountInfo: stateFromStore.idnAccountInfo,
  activeRentals: stateFromStore.activeRentals,
  idnWos: stateFromStore.idnWos,
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchIdnWos_ac,
      fetchReactHealthPatient_ac,
      downloadReactHealthReport_ac,
      fetchIdnAccountInfo_ac,
      open_snack_ac,
      flushReactHealthPatient_ac,
      flushReactHealthProviders_ac,
      fetchReactHealthClinicians_ac,
      fetchReactHealthProviders_ac,
      onboardPatientReactHealth_ac,
      fetchActiveRentalsList_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ReactHealthView));
