import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dateUtil from '../../../util/dateProcessing';
import StyledTooltip from '../../Shared/StyledTooltip';
import SmartAvatar from '../../common/SmartAvatar';
import { PRODUCT_ROW_GRIDS } from '../config';
import { addToCart_ac } from './../../../actions/shopping_cart.ac.js';
import ConfigView from './ConfigView';
const HOWTOORDER_MAP = {
  0: 'Optional',
  1: 'Purchase Only',
  2: 'Rental Only',
  3: 'Cash Sale Only',
  4: 'Cash Rental Only',
  30: 'Purchase or Cash Sale Only',
  40: 'Rental or Cash Rental Only',
};

const RECTYPE_MAP = {
  E: 'Product Entry for HME',
  C: 'Oxygen Cylinder',
  D: 'Pharmacy Drug Product',
  T: 'Pharmacy Compound Drug Product or a TPN type',
};

const CONFIGS = [
  { code: 'sn_required', lbl: 'SN Required' },
  { code: 'aob_required', lbl: 'AOB Required if Delivery' },
  { code: 'allow_pickup', lbl: 'Allow Pickup' },
  { code: 'pap_vent_alert', lbl: 'Alert RAE when Scheduled' },
  { code: 'req_wheelchair_form', lbl: 'Req. Wheelchair Assessment' },
];

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: '8px',
  },
  title: {
    fontSize: 14,
    flexDirection: 'column',
  },
  content: {
    fontSize: 12,
    flexDirection: 'column',
  },
  pos: {
    marginBottom: 12,
    textAlign: '-webkit-left',
  },
  typoContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  productImageContainer: {
    marginBottom: '8px',
    fontSize: 'inherit',
  },
  productImage: {
    width: '98px',
  },
  vendor: {
    padding: '4px',
    overflow: 'hidden !important',
    borderBottom: '1px dashed #B0BEC5',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: '8px',
  },
  marginBottom8: {
    marginBottom: '8px',
  },
  cat: {
    fontSize: 8,
    color: '#004D40',
  },
  borderRight: {
    borderRight: '1px solid #cdcdcd',
  },
  macroTxt: {
    color: '#546E7A',
    fontSize: 10,
  },
  prefVendor: {
    color: 'blue',
    fontWeight: 'bold',
  },
});

const rowColorIndexMap = {
  0: '#f3f3f3',
  1: '#ffffff',
};

const sortVendors = (vendors) => {
  let preferredVendors = [];
  let nonPreferredVendors = [];

  vendors.forEach((vendor) => {
    if (vendor.preferred) {
      preferredVendors.push(vendor);
    } else {
      nonPreferredVendors.push(vendor);
    }
  });

  let [preferredVendorsByName, nonPreferredVendorsByName] = [
    preferredVendors,
    nonPreferredVendors,
  ].map((vendors) => {
    return vendors.sort((vendorA, vendorB) => {
      if (vendorA.nm.toLowerCase() <= vendorB.nm.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
    });
  });

  return {
    preferred: [...preferredVendorsByName],
    nonPreferred: [...nonPreferredVendorsByName],
  };
};

const MacrosList = (props) => {
  const { macros, classes } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={{ marginBottom: 8, marginTop: 4 }}>
      <Typography
        inline
        color="textSecondary"
        variant="button"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ cursor: 'pointer' }}>
        <strong>
          {isExpanded ? 'Collapse' : 'Expand'}&ensp;macros&ensp;[+/-]
        </strong>
      </Typography>
      <Collapse in={isExpanded}>
        {_.values(macros).map((m, i) => (
          <div key={i} className={classes.macroTxt}>
            {m.selected && (
              <strong>
                Macro: {m.code} - QTY: {m.qty}
              </strong>
            )}
            {!m.selected && (
              <span>
                Macro: {m.code} - QTY: {m.qty}
              </span>
            )}
          </div>
        ))}
      </Collapse>
    </div>
  );
};

const VendorsList = (props) => {
  const { product, vendors, backColor, classes, onAddClick, isMaintainance } =
    props;
  const [isExpanded, setIsExpanded] = useState(false);

  const getVendorComponent = (vendor) => {
    return (
      <>
        <StyledTooltip title={`Vendor Part Desc: ${vendor.part_desc}`}>
          <div>
            <Typography
              className={classes[vendor.preferred ? 'prefVendor' : '']}
              inline
              component="span">
              {vendor.nm}
            </Typography>
            <Typography inline component="span">
              {' '}
              - Part# {vendor.part}
            </Typography>
          </div>
        </StyledTooltip>
        {vendor.method_nm && (
          <div>
            <Typography inline component="span" style={{ color: '#FF3D00' }}>
              {vendor.method_nm}
            </Typography>
          </div>
        )}

        <Typography component="span" inline>
          $<strong>{(vendor.uomcost || 0).toFixed(2)}</strong>
          &ensp;per&ensp;
          <strong>{vendor.uom}</strong>
          &ensp;of&ensp;
          <strong>{vendor.uomcount}</strong>
        </Typography>
        <Typography
          inline
          component="span"
          style={{ marginLeft: 4, marginRight: 4 }}>
          (${(vendor.uomcost / vendor.uomcount).toFixed(2)} per UOM)
        </Typography>

        {!isMaintainance && (
          <div>
            {vendor.preferred && (
              <Button
                onClick={() => onAddClick(vendor)}
                variant="outlined"
                size="small"
                color="secondary">
                Add {product.macro_qty ? product.macro_qty + ' ' : ''} to Cart
              </Button>
            )}
          </div>
        )}

        {false && (
          <Paper
            style={{
              margin: 15,
              width: '15px',
              height: '15px',
              ...backColor,
            }}
            elevation={2}
          />
        )}
      </>
    );
  };

  return (
    <>
      {vendors.preferred.map((vendor, i) => (
        <div key={i} className={classes.vendor}>
          {getVendorComponent(vendor)}
        </div>
      ))}
      {vendors.nonPreferred.length > 0 && (
        <>
          <Typography
            inline
            color="textSecondary"
            variant="button"
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ cursor: 'pointer' }}>
            <strong>
              {isExpanded ? 'Collapse' : 'Expand'}&ensp;vendors&ensp;[+/-]
            </strong>
          </Typography>
          <Collapse in={isExpanded}>
            {vendors.nonPreferred.map((vendor, i) => (
              <div key={i} className={classes.vendor}>
                {getVendorComponent(vendor)}
              </div>
            ))}
          </Collapse>
        </>
      )}
    </>
  );
};

const ProductRowItem = (props) => {
  const {
    classes,
    product,
    rowColorIndex,
    isMaintainance,
    isCatalogueMaintainer,
    configView,
  } = props;

  const productColor = (colorCode) => {
    switch (colorCode) {
      case 0:
        return { backgroundColor: '#00ee0088' };
      case 1:
        return { backgroundColor: '#5588ee' };
      case 2:
        return { backgroundColor: '#88ddee' };
      default:
        return { backgroundColor: '#ee0088' };
    }
  };

  const addToCart = (selectedItem, product_code, qty) => {
    selectedItem.prod_code_v_id = `${product_code} - ${selectedItem.v_id}`;
    props.addToCart_ac(selectedItem, qty);
  };

  const lastChangedDate = dateUtil.formatDate(product.lastchange);
  const backColor = productColor(product.color_code);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={16}
        style={{ backgroundColor: rowColorIndexMap[`${rowColorIndex}`] }}>
        <Grid
          item
          className={classes.borderRight}
          style={{
            backgroundColor: product.discontinued ? '#f4433630' : 'transparent',
            width: `${PRODUCT_ROW_GRIDS[0]}%`,
          }}>
          {product.discontinued && (
            <div className={classes.marginBottom8}>
              <Typography color="error">
                <strong>Discontinued</strong>
              </Typography>
            </div>
          )}
          {product.config?.image_tn_url && (
            <div className={classes.typoContainer}>
              <SmartAvatar
                size={60}
                borderRadius={0}
                alt={product.prod_code}
                className={classes.productImageContainer}
                imageClassName={classes.productImage}
                src={product.config.image_tn_url}
                originalSrc={product.config.image_url}>
                {product.prod_code}
              </SmartAvatar>
            </div>
          )}
          <div className={classes.typoContainer}>
            <StyledTooltip title={`Product Code: ${product.prod_code}`}>
              <Typography
                color="secondary"
                component="span"
                variant="subtitle1"
                inline>
                <strong>{product.prod_code}</strong>
              </Typography>
            </StyledTooltip>
          </div>
          <div className={classes.typoContainer}>
            <Typography component="span" inline>
              {product.uom} of {product.perunitqty}
            </Typography>
          </div>
          <div className={classes.marginBottom8}>
            <div style={{ color: 'orange', fontWeight: 'bold', fontSize: 11 }}>
              HCPC: {product.hcpc || '??'}
            </div>
            <Typography variant="caption" component="span">
              HDMS Prod ID {product.prod_id}
            </Typography>
            <Typography variant="caption" component="span">
              H: {HOWTOORDER_MAP[product.howtoorder?.toString()]}
            </Typography>
            <Typography variant="caption" component="span">
              Type: {RECTYPE_MAP[product.prod_rectype]}
            </Typography>
            <Typography variant="caption" component="span">
              Last Changed:&ensp;
            </Typography>
            <Typography variant="caption" component="span">
              {lastChangedDate}
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          style={{ width: `${PRODUCT_ROW_GRIDS[1]}%` }}
          className={classes.borderRight}>
          <Typography color="textPrimary" className={classes.marginBottom8}>
            {product.description}
          </Typography>
          <Typography
            color="textSecondary"
            title={product.billing_code_desc}
            className={classes.marginBottom8}>
            Billing code: {product.billing_code}: {product.billing_code_desc}
          </Typography>
          {product.hcpc && (
            <div
              className={classes.marginBottom8 + ' ' + classes.typoContainer}>
              <Typography
                component="span"
                style={{ color: 'rgba(125, 193, 16, 0.92)' }}>
                {product.hcpc}
              </Typography>
            </div>
          )}

          {/*product.unitweight > 0 && (
            <div className={classes.typoContainer}>
              <Typography color="primary" component="span" inline>
                Weight:&ensp;
              </Typography>
              <Typography component="span" inline>
                {product.unitweight}
              </Typography>
            </div>
          )*/}

          {product.macros && (
            <MacrosList
              macros={product.macros}
              classes={{ macroTxt: classes.macroTxt }}
            />
          )}

          {product.comments && (
            <div className={classes.marginBottom8}>
              <Typography component="span">Comments:</Typography>
              <Typography
                component="span"
                inline
                style={{ color: 'rgba(224, 11, 158, 0.85)' }}>
                {product.comments}
              </Typography>
            </div>
          )}

          {product.config?.desc_for_patient && (
            <div className={classes.marginBottom8}>
              <Typography component="span">Description for Patient:</Typography>
              <Typography
                component="span"
                inline
                style={{ color: 'deepskyblue' }}>
                {product.config.desc_for_patient}
              </Typography>
            </div>
          )}

          {product.config?.urls && (
            <div className={classes.marginBottom8}>
              <Typography component="span">Linked URLs:</Typography>
              {product.config.urls.map(({ url, nm }, index) => (
                <Link
                  key={index}
                  href={url}
                  target="_blank"
                  rel="noopener"
                  style={{
                    color: '#1976d2',
                    display: 'block',
                  }}>
                  <strong>{nm}</strong>
                </Link>
              ))}
            </div>
          )}

          {configView && (
            <div className={classes.marginBottom8}>
              {CONFIGS.map((c, index) => (
                <Typography key={index} variant="caption" component="span">
                  {c.lbl}:{' '}
                  {product.config
                    ? product.config[c.code]
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </Typography>
              ))}
            </div>
          )}

          {product.config?.fa_tracking_group && (
            <div className={classes.marginBottom8}>
              <Typography variant="caption" component="span">
                {`FA Tracking Group: ${product.config.fa_tracking_group}`}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid
          item
          style={{ width: `${PRODUCT_ROW_GRIDS[2]}%` }}
          className={classes.borderRight}>
          {/*<div className={classes.marginBottom8}>
            <Typography component="span" inline>
              HCPC:&ensp;
            </Typography>
          </div>*/}
          <div className={classes.marginBottom8}>
            <Typography
              component="span"
              style={{ color: 'rgba(68, 14, 232, 0.92)' }}>
              {product.mfg_nm}
            </Typography>
            <Typography component="span" className={classes.cat}>
              {product.cat}
            </Typography>
          </div>
          <div>
            {product.upc && (
              <Typography color="primary" component="span">
                UPC: {product.upc} - UPN: {product.upn}
              </Typography>
            )}
            {product.upn && (
              <Typography color="primary" component="span">
                UPN: {product.upn}
              </Typography>
            )}
          </div>
          {product.upc3 && (
            <div
              className={classes.marginBottom8 + ' ' + classes.typoContainer}>
              <Typography color="primary" component="span">
                UPC3: {product.upc3}
              </Typography>
            </div>
          )}
        </Grid>
        <Grid item style={{ width: `${PRODUCT_ROW_GRIDS[3]}%` }}>
          <VendorsList
            isMaintainance={isMaintainance}
            product={product}
            vendors={sortVendors(product.vendors)}
            backColor={backColor}
            classes={{
              vendor: classes.vendor,
              prefVendor: classes.prefVendor,
            }}
            onAddClick={(vendor) =>
              addToCart(
                {
                  ...vendor,
                  uom: product.uom,
                },
                product.prod_code,
                product.macro_qty || 1
              )
            }
          />
        </Grid>
        {configView && isCatalogueMaintainer ? (
          <Grid item style={{ width: `${PRODUCT_ROW_GRIDS[4]}%` }}>
            <ConfigView product={product} />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

ProductRowItem.propTypes = {
  product: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addToCart_ac,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ProductRowItem));
