export const FBSearchActionTypes = {
  SEARCH_RESULT_RECEIVED: 'SEARCH_RESULT_RECEIVED',
  FAILED_SEARCHING: 'FAILED_SEARCHING',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  RESET_SEARCH: 'RESET_SEARCH'
}

export const searchFBPatient_ac = params => dispatch => {
  dispatch(fetching(true));
  return window.sch
    .post(`/api/fb/search`, params)
    .then(response => {
      dispatch({
        type: FBSearchActionTypes.SEARCH_RESULT_RECEIVED,
        result: response.result,
        pagination: response.pagination
      })
    })
    .catch(error => {
      dispatch({
        type: FBSearchActionTypes.FAILED_SEARCHING,
        error
      })
    })
    .finally(() => {
      dispatch(fetching(false));
    })
}

export const searchFBAccounting_ac = params => dispatch => {
  dispatch(fetching(true));
  return window.sch
    .post(`/api/fba/search`, params)
    .then(response => {
      dispatch({
        type: FBSearchActionTypes.SEARCH_RESULT_RECEIVED,
        result: response.result,
        pagination: response.pagination
      })
    })
    .catch(error => {
      dispatch({
        type: FBSearchActionTypes.FAILED_SEARCHING,
        error
      })
    })
    .finally(() => {
      dispatch(fetching(false));
    })
}

export const resetSearchResult_ac = () => dispatch => {
  dispatch({
    type: FBSearchActionTypes.RESET_SEARCH
  })
}

export const fetching = isFetching => ({
  type: FBSearchActionTypes.SET_IS_FETCHING,
  isFetching
})
