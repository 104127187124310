export const WorkOrdersReportsActionTypes = {
  RECEIVED_WORK_ORDERS_REPORTS: 'RECEIVED_WORK_ORDERS_REPORTS',
  FAILED_RECEIVING_WORK_ORDERS_REPORTS: 'FAILED_RECEIVING_WORK_ORDERS_REPORTS',
  RECEIVED_WORK_ORDER: 'RECEIVED_WORK_ORDER',
  FAILED_RECEIVING_WORK_ORDER: 'FAILED_RECEIVING_WORK_ORDER',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_FETCHING_WORK_ORDER: 'SET_IS_FETCHING_WORK_ORDER',
};

export const fetchWorkOrdersReports_ac = (params) => (dispatch) => {
  dispatch(fetching(true));
  return window.sch
    .post('/api/cohort_wos/list', { ...params })
    .then((result) => {
      if (result.error) {
        throw new Error(result.error);
      }
      const response = result.pkg;
      dispatch({
        type: WorkOrdersReportsActionTypes.RECEIVED_WORK_ORDERS_REPORTS,
        records: response.rr,
        pagination: response.pagination,
      });
    })
    .catch((error) => {
      dispatch({
        type: WorkOrdersReportsActionTypes.FAILED_RECEIVING_WORK_ORDERS_REPORTS,
        error,
      });
    })
    .finally(() => {
      dispatch(fetching(false));
    });
};
export const fetchWorkOrder_ac = (params) => (dispatch) => {
  dispatch(fetchingWorkOrder(true));
  let target_url = `/api/hdms:recentwos:prime`;
  return window.sch
    .post(target_url, params)
    .then((response) => {
      dispatch({
        type: WorkOrdersReportsActionTypes.RECEIVED_WORK_ORDER,
        wo: response.wos[0],
      });
    })
    .catch((error) => {
      dispatch({
        type: WorkOrdersReportsActionTypes.FAILED_RECEIVING_WORK_ORDER,
        error,
      });
    })
    .finally(() => {
      dispatch(fetchingWorkOrder(false));
    });
};

export const fetching = (isFetching) => ({
  type: WorkOrdersReportsActionTypes.SET_IS_FETCHING,
  isFetching,
});
export const fetchingWorkOrder = (isFetchingWorkOrder) => ({
  type: WorkOrdersReportsActionTypes.SET_IS_FETCHING_WORK_ORDER,
  isFetchingWorkOrder,
});
