export const REQUEST_HOURLY_WOS_GROUP = 'REQUEST_HOURLY_WOS_GROUP'
export const RECEIVED_HOURLY_WOS_GROUP = 'RECEIVED_HOURLY_WOS_GROUP'
export const FAILED_HOURLY_WOS_GROUP = 'FAILED_HOURLY_WOS_GROUP'

export function fetchHourlyWosGroup_ac(id, payload) {
  return (dispatch, _) => {
    dispatch({ type: REQUEST_HOURLY_WOS_GROUP })
    return window.sch
      .post(`/api/hourly_wos/${id}`, payload)
      .then(result => {
        dispatch({
          type: RECEIVED_HOURLY_WOS_GROUP,
          pkg: result
        })
      })
      .catch(error => {
        dispatch({
          type: FAILED_HOURLY_WOS_GROUP,
          error
        })
      })
  }
}
