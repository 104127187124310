import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TextField
} from '@material-ui/core/'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  accountPABranchMarkAsSelected_ac,
  expandCollapseAllTrees_ac,
  fetchAccountPAs_ac,
  highlightPABranches_ac,
  setAccountPAsToEmpty_ac,
  toggleTreeExpansion_ac
} from '../../actions/account_in_view.ac.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto'
  },
  main: {
    flexGrow: 1,
    height: '92v'
  },
  scrollBody: {
    overflowY: 'scroll',
    '-webkitOverflowScrolling': 'touch'
  },
  payerName: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 8,
    color: '#65502f'
  },
  treeName: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13)
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8
  },
  branchGridRootSelected: {
    flexGrow: 1,
    paddingBottom: 8,
    backgroundColor: 'lightgreen'
  },
  paNumber: {
    fontWeight: 600
  },
  notLogged: {
    color: '#757575'
  },
  byTabKlass: {
    minWidth: 16
  },
  dateValid: {
    color: '#5C6BC0',
    fontWeight: 'bold'
  },
  hcpcValid: {
    color: 'rgb(255, 109, 0)',
    fontWeight: 'bold'
  },
  hilite: {
    backgroundColor: '#B2FF59',
    marginBottom: 12
  }
})

class PABranch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnStatus: ''
    }
    this.onTreeSelect = this.onTreeSelect.bind(this)
  }

  onTreeSelect() {
    return this.props.onTreeSelect(this.props.tIdx, this.props.bIdx)
  }

  render() {
    const { branch, classes, highlightActivity } = this.props
    let hh = ''
    let hilite = branch.hcpc_valid && branch.date_valid ? 'hilite' : false
    if (highlightActivity) {
      hh = highlightActivity.hcpc.substr(0, 5)
    }

    branch.hcpcs.sort((a, b) => (a.hcpc > b.hcpc ? 1 : -1))

    let bClass = 'branchGridRoot'
    if (
      this.props.selectedIdxs !== undefined &&
      this.props.tIdx === this.props.selectedIdxs.treeIdx
    ) {
      if (this.props.bIdx === this.props.selectedIdxs.branchIdx) {
        bClass = 'branchGridRootSelected'
      }
    }

    if (branch.pa_branch_id === null) {
      if (highlightActivity) {
        return <></>
      }

      return (
        <div className={classes[bClass]}>
          <Grid container direction="row" justify="flex-start" spacing={8}>
            <Grid item sm={12}>
              <Typography>No branches under this tree</Typography>
            </Grid>
          </Grid>
        </div>
      )
    }

    return (
      <div className={classes[hilite || bClass]}>
        <Grid container direction="row" justify="flex-start" spacing={8}>
          <Grid item sm={1}>
            {branch.rank ? (
              <Typography>R{branch.rank}) </Typography>
            ) : (
              <Typography>B{this.props.bIdx + 1})</Typography>
            )}
          </Grid>
          <Grid item sm={3}>
            <Typography className={classes.paNumber}>
              Branch: {branch.pa_branch_id}
            </Typography>
            <Typography
              className={classes[branch.date_valid ? 'dateValid' : 'nd']}>
              {moment(branch.pa_branch_date).format('MM-DD-YYYY')}
            </Typography>
            {branch.pa_branch_exp_date ? (
              <Typography
                className={classes[branch.date_valid ? 'dateValid' : 'nd']}>
                {moment(branch.pa_branch_exp_date).format('MM-DD-YYYY')}
              </Typography>
            ) : (
              <Typography
                className={classes[branch.date_valid ? 'dateValid' : 'nd']}>
                Duration: {branch.pa_branch_dur}
              </Typography>
            )}
            {!this.props.hidePopulateBtn && (
              <Button
                onClick={this.onTreeSelect}
                variant="contained"
                size="small"
                disabled={this.state.btnStatus === 'disabled'}
                color="secondary">
                Select
              </Button>
            )}
          </Grid>
          <Grid item sm={4}>
            {branch.tree_info && (
              <>
                <Typography className={classes.payerName}>
                  {branch.tree_info.pa_tree_payer_name}:
                </Typography>
                <Typography className={classes.treeName}>
                  {branch.tree_info.pa_tree_name} ({branch.tree_info.pa_tree_id}
                  )
                </Typography>
              </>
            )}

            {branch.pa_branch_number ? (
              <Typography className={classes.paNumber}>
                LOGGED: {branch.pa_branch_number.trim()}
              </Typography>
            ) : (
              <Typography className={classes.notLogged}>NOT LOGGED</Typography>
            )}
            {branch.pa_doctor ? (
              <Typography className={classes.paNumber}>
                DOCTOR: {branch.pa_doctor}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item sm={4}>
            {branch.hcpcs.map((h, hIdx) => (
              <Typography
                key={hIdx}
                className={
                  classes[h.hcpc.substr(0, 5) === hh ? 'hcpcValid' : 'nd']
                }>
                {h.hcpc} (Q:{h.qty}) {h.mod}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </div>
    )
  }
}

class PATree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.onToggle = this.onToggle.bind(this)
  }

  onToggle(e, object) {
    console.log(e, object)
    this.props.toggleTreeExpansion_ac(this.props.tIdx)
  }

  render() {
    const { tree, classes, highlightActivity } = this.props
    return (
      <ExpansionPanel expanded={tree.expanded} onChange={this.onToggle}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.payerName}>
            T{this.props.tIdx + 1}) {tree.pa_tree_payer_name}:
          </Typography>
          <Typography className={classes.treeName}>
            {tree.pa_tree_name} ({tree.pa_tree_id})
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="column" justify="flex-start" wrap="nowrap">
            {tree.branches.map((branch, bIdx) => (
              <Grid item key={bIdx} className={classes.branchGrid}>
                <PABranch
                  highlightActivity={highlightActivity}
                  key={branch.pa_branch_id}
                  bIdx={bIdx}
                  classes={classes}
                  branch={branch}
                  tIdx={this.props.tIdx}
                  hidePopulateBtn={this.props.hidePopulateBtn}
                  onTreeSelect={this.props.onTreeSelect}
                  selectedIdxs={this.props.selectedIdxs}
                />
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

class AccountPAs_ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAccountPosition: 0
    }
    this.handleSelectAccount = this.handleSelectAccount.bind(this)
    this.onTreeSelect = this.onTreeSelect.bind(this)
  }

  handleSelectAccount(e, _pos) {
    const { idnInView } = this.props
    this.setState({ selectedAccountPosition: _pos })

    const [account, member] = idnInView.idnDoc.aa[_pos].split(/-/)
    return this.props.fetchAccountPAs_ac(
      parseInt(account, 10),
      parseInt(member, 10)
    )
  }

  componentDidMount() {
    const { idnInView, accountPAsInView } = this.props
    if (idnInView.idnDoc === null) return
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) return
    if (accountPAsInView.pasPkg === null) {
      if (accountPAsInView.isFetching) return
      if (accountPAsInView.error) return
      if (_.get(idnInView.idnDoc, 'aa', []).length === 0) {
        this.props.setAccountPAsToEmpty_ac()
        return
      }

      const [account, member] = idnInView.idnDoc.aa[
        this.state.selectedAccountPosition
      ].split(/-/)
      return this.props.fetchAccountPAs_ac(
        parseInt(account, 10),
        parseInt(member, 10)
      )
    }
  }

  onTreeSelect(tIdx, bIdx) {
    this.props.accountPABranchMarkAsSelected_ac(tIdx, bIdx)
  }

  componentDidUpdate(prevProps) {
    const { idnInView, accountPAsInView } = this.props
    if (idnInView.idnDoc === null) return
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) return
    if (
      idnInView.idnStr !== prevProps.idnInView.idnStr ||
      accountPAsInView.pasPkg === null ||
      accountPAsInView.account !== prevProps.accountPAsInView.account ||
      accountPAsInView.member !== prevProps.accountPAsInView.member
    ) {
      if (accountPAsInView.isFetching) return
      if (accountPAsInView.error) return
      if (_.get(idnInView.idnDoc, 'aa', []).length === 0) {
        this.props.setAccountPAsToEmpty_ac()
        return
      }

      const [account, member] = idnInView.idnDoc.aa[
        this.state.selectedAccountPosition
      ].split(/-/)
      return this.props.fetchAccountPAs_ac(
        parseInt(account, 10),
        parseInt(member, 10)
      )
    }
  }

  render() {
    const { classes, idnInView, accountPAsInView } = this.props
    const { pasPkg, isFetching } = accountPAsInView
    const byTabKlass = {
      root: classes.byTabKlass
    }
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) {
      return <div className={classes.card}>NO HDMS Account is attached</div>
    }

    return (
      <div className={classes.main}>
        {this.props.noTabs !== true && (
          <Tabs
            value={this.state.selectedAccountPosition}
            onChange={this.handleSelectAccount}
            indicatorColor="secondary"
            textColor="secondary">
            {_.get(idnInView.idnDoc, 'aa', []).map((act, pos) => (
              <Tab
                classes={byTabKlass}
                key={act}
                value={pos}
                label={'A' + act + ' PAs'}
              />
            ))}
          </Tabs>
        )}
        {isFetching && <div>...loading</div>}
        {pasPkg && (
          <>
            {pasPkg.pa_trees.length === 0 && (
              <Typography className={classes.payerName}>
                No PA Trees (shells) have been created for HDMS Account: A
                {accountPAsInView.account}-{accountPAsInView.member}
              </Typography>
            )}

            {pasPkg.pa_trees.map((tree, tIdx) => (
              <PATree
                tIdx={tIdx}
                key={tree.pa_tree_id}
                classes={classes}
                tree={tree}
                toggleTreeExpansion_ac={this.props.toggleTreeExpansion_ac}
                selectedAccountPosition={this.state.selectedAccountPosition}
                hidePopulateBtn={this.props.hidePopulateBtn}
                selectedIdxs={pasPkg.selectedIdxs}
                onTreeSelect={this.onTreeSelect}
              />
            ))}
          </>
        )}
      </div>
    )
  }
}

class AccountPAsMerged_ extends AccountPAs_ {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      lastToggle: true,
      dateFilterEntry: '',
      hcpcFilterEntry: ''
    }
    this.onDateFilterEntry = this.onDateFilterEntry.bind(this)
    this.onHcpcFilterEntry = this.onHcpcFilterEntry.bind(this)
    this.applyDateHcpcFilter = this.applyDateHcpcFilter.bind(this)
    this.toggleAll = this.toggleAll.bind(this)
  }

  onDateFilterEntry(e) {
    this.setState({ dateFilterEntry: e.target.value })
  }
  onHcpcFilterEntry(e) {
    this.setState({ hcpcFilterEntry: e.target.value })
  }

  handleSelectAccount(e, _pos) {
    const { idnInView } = this.props
    this.setState({ selectedAccountPosition: _pos })

    const [account, member] = idnInView.idnDoc.aa[_pos].split(/-/)
    return this.props.fetchAccountPAs_ac(
      parseInt(account, 10),
      parseInt(member, 10)
    )
  }

  applyDateHcpcFilter() {
    if (!moment(this.state.dateFilterEntry, 'MM-DD-YYYY').isValid()) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `Invalid date value. Use MM-DD-YYYY`
      })
      return
    }
    if (!this.state.hcpcFilterEntry.trim()) {
      this.props.open_snack_ac({
        variant: 'error',
        message: `HCPC required`
      })
      return
    }

    return this.props.highlightPABranches_ac(
      this.state.hcpcFilterEntry,
      this.state.dateFilterEntry
    )
  }
  toggleAll() {
    this.setState({ lastToggle: !this.state.lastToggle })
    this.props.expandCollapseAllTrees_ac(!this.state.lastToggle)
  }

  render() {
    const { classes, idnInView, accountPAsInView } = this.props
    const { pasPkg, isFetching, highlightActivity } = accountPAsInView
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) {
      return <div className={classes.card}>NO HDMS Account is attached</div>
    }

    return (
      <div className={classes.main}>
        {isFetching && <div>...loading</div>}
        {pasPkg && (
          <>
            {pasPkg.pa_trees.length === 0 && (
              <Typography className={classes.payerName}>
                No PA Trees (shells) have been created for HDMS Account: A
                {accountPAsInView.account}-{accountPAsInView.member}
              </Typography>
            )}

            <Grid
              container
              style={{ marginBottom: 12 }}
              direction="column"
              justify="flex-start"
              wrap="nowrap">
              <Grid item className={classes.branchGrid}>
                <TextField
                  placeholder={'Date MM-DD-YYYY'}
                  onChange={this.onDateFilterEntry}
                  value={this.state.dateFilterEntry}
                />
                <TextField
                  style={{ marginLeft: 8 }}
                  onChange={this.onHcpcFilterEntry}
                  placeholder={'HCPC'}
                  value={this.state.hcpcFilterEntry}
                />
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={this.applyDateHcpcFilter}
                  variant="contained"
                  size="small"
                  color="secondary">
                  Apply Filter
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color={this.state.lastToggle ? 'primary' : 'secondary'}
                  onClick={this.toggleAll}
                  style={{ cursor: 'pointer', marginLeft: 8 }}>
                  [+/-]
                </Button>
              </Grid>
            </Grid>

            <div className={classes.scrollBody}>
              {null === highlightActivity &&
                pasPkg.pa_trees.map((tree, tIdx) => (
                  <PATree
                    tIdx={tIdx}
                    toggleTreeExpansion_ac={this.props.toggleTreeExpansion_ac}
                    key={tIdx}
                    classes={classes}
                    tree={tree}
                    selectedAccountPosition={this.state.selectedAccountPosition}
                    hidePopulateBtn={this.props.hidePopulateBtn}
                    selectedIdxs={pasPkg.selectedIdxs}
                    onTreeSelect={this.onTreeSelect}
                  />
                ))}
            </div>

            {pasPkg.merged_branches && (
              <div className={classes.scrollBody}>
                {pasPkg.merged_branches.map((branch, bIdx) => (
                  <PABranch
                    key={branch.pa_branch_id}
                    highlightActivity={highlightActivity}
                    bIdx={bIdx}
                    classes={classes}
                    branch={branch}
                    tIdx={this.props.tIdx}
                    hidePopulateBtn={this.props.hidePopulateBtn}
                    onTreeSelect={this.props.onTreeSelect}
                    selectedIdxs={this.props.selectedIdxs}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  accountPAsInView: stateFromStore.accountPAsInView,
  idnInView: stateFromStore.idnInView
})

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      fetchAccountPAs_ac,
      setAccountPAsToEmpty_ac,
      highlightPABranches_ac,
      toggleTreeExpansion_ac,
      expandCollapseAllTrees_ac,
      accountPABranchMarkAsSelected_ac
    },
    dispatch
  )

export const AccountPAsMerged = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountPAsMerged_))
)

export const AccountPAs = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountPAs_))
)
