export const DELIVERY_UPDATES = [
  { code: 'backordered', label: 'Backordered' },
  { code: 'canceled', label: 'Canceled' },
  { code: 'cannot_dispense', label: 'Cannot Dispense' },
  { code: 'completed', label: 'Completed' },
  {
    code: 'confirmation_in_progress',
    label: 'Confirmation In Progress',
  },
  { code: 'contacting_patient', label: 'Contacting Patient' },
  {
    code: 'delivery_successful',
    label: 'Delivery Successful',
    fields: [
      {
        name: 'actual_delivery_date',
        label: 'Delivered Date',
        type: 'date',
        required: true,
      },
    ],
  },
  { code: 'delivery_unsuccessful', label: 'Delivery Unsuccessful' },
  { code: 'held_for_copay', label: 'Held For Copay' },
  { code: 'held_for_documentation', label: 'Held For Documentation' },
  {
    code: 'held_for_documentation_and_copay',
    label: 'Held For Documentation And Copay',
  },
  { code: 'missing_documentation', label: 'Missing Documentation' },
  { code: 'needs_face_to_face', label: 'Needs Face To Face' },
  { code: 'partially_shipped', label: 'Partially Shipped' },
  { code: 'patient_refused', label: 'Patient Refused' },
  { code: 'pending_authorization', label: 'Pending Authorization' },
  { code: 'pending_delivery_ticket', label: 'Pending Delivery Ticket' },
  { code: 'pending_further_review', label: 'Pending Further Review' },
  {
    code: 'ready_for_delivery',
    label: 'Ready For Delivery',
    fields: [
      {
        name: 'expected_delivery_date',
        label: 'Expected Delivery Date',
        type: 'date',
        required: true,
      },
    ],
  },
  { code: 'return_complete', label: 'Return Complete' },
  { code: 'return_in_progress', label: 'Return In Progress' },
  { code: 'scheduling_delivery', label: 'Scheduling Delivery' },
  { code: 'third_party_delivery', label: 'Third Party Delivery' },
];

export const ORDER_DETAILS = {
  fulfillments: [
    {
      id: 'FYR45-3ZKY3-9MYH',
      created_at: '2024-01-29T17:55:33.191Z',
      accepted_at: '2024-01-29T18:09:32.045Z',
      delivery_note: null,
      delivery_date_requested: '2024-01-29',
      delivery_date_expected: null,
      delivery_date_actual: null,
      delivery_status: 'pending_authorization',
      tracking_numbers: [],
      supplier_system_id: 'KDK4-A8-XRAV-4HV',
      items: [
        {
          length_of_need_months: 99,
          quantity: 1,
          unit: null,
          private_pay: false,
          payment_method: 'insurance',
          description: 'Gel Overlay Mattress',
          hcpcs: 'E0185',
          product_category_name: 'Hospital Beds',
          supplier_system_id: 'AUTO-90a825ff76f024242481',
          attributes: [],
        },
      ],
      delivery_address: {
        line1: '7682 MAIN CT',
        line2: null,
        city: 'RANCHO CUCAMONGA',
        state: 'CA',
        zip: '91730',
      },
    },
    {
      id: '1RMF-QX-X54Q-MBL',
      created_at: '2024-01-29T17:59:04.174Z',
      accepted_at: '2024-01-29T18:09:33.771Z',
      delivery_note: null,
      delivery_date_requested: '2024-01-29',
      delivery_date_expected: null,
      delivery_date_actual: null,
      delivery_status: 'pending_further_review',
      tracking_numbers: [],
      supplier_system_id: 'KDK4-A8-XRAV-4HV',
      items: [
        {
          length_of_need_months: 99,
          quantity: 1,
          unit: null,
          private_pay: false,
          payment_method: 'insurance',
          description: 'Other Product (See Notes)',
          hcpcs: 'Other',
          product_category_name: 'Mobility',
          supplier_system_id: 'AUTO-f98a2c72ef7765e658fe',
          attributes: [],
        },
      ],
      delivery_address: {
        line1: '757 Main Blvd',
        line2: null,
        city: 'Los Angeles',
        state: 'CA',
        zip: '90095',
      },
    },
  ],
};
