import { MappingToolActionTypes } from '../actions/mapping_tool.ac.js'

const initialState = {
  isFetching: false,
  isFetchingDraft: false,
  isSaving: false,
  isDeploying: false,
  jobs: null,
  existing_tpls: [],
  drafts: {},
  error: null
}
export const mappingToolInView = (state = initialState, action) => {
  switch (action.type) {
    case MappingToolActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }

    case MappingToolActionTypes.SET_IS_FETCHING_DRAFT:
      let currentDrafts = { ...state.drafts }
      currentDrafts[action.tpl_id_page_pos] = {
        ...currentDrafts[action.tpl_id_page_pos],
        isFetching: action.isFetchingDraft
      }
      return {
        ...state,
        drafts: currentDrafts
      }

    case MappingToolActionTypes.RECEIVED_PNG_TEMPLATES:
      return {
        ...state,
        jobs: action.jobs,
        existing_tpls: action.existing_tpls
      }

    case MappingToolActionTypes.FAILED_RECEIVING_PNG_TEMPLATES:
      return {
        ...state,
        jobs: null,
        error: action.error
      }
    case MappingToolActionTypes.RECEIVED_DRAFTS:
      let currentDrafts1 = { ...state.drafts }
      currentDrafts1[action.tpl_id_page_pos].data = action.drafts

      return {
        ...state,
        drafts: currentDrafts1
      }

    case MappingToolActionTypes.DRAFT_SAVED:
      let currentDrafts2 = { ...state.drafts }
      const selectedDraft =
        currentDrafts2[action.tpl_id_page_pos] &&
        currentDrafts2[action.tpl_id_page_pos].data.find(
          draft => draft._id === action.draftId
        )
      if (selectedDraft) {
        selectedDraft.tpl_as_object = action.draft
      }
      return {
        ...state,
        drafts: currentDrafts2
      }

    case MappingToolActionTypes.FAILED_RECEIVING_DRAFTS:
      return {
        ...state,
        drafts: null,
        error: action.error
      }

    case MappingToolActionTypes.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving
      }
    case MappingToolActionTypes.SET_IS_DEPLOYING:
      return {
        ...state,
        isDeploying: action.isDeploying
      }
    default:
      return state
  }
}
