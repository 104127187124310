import React from 'react';

import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  message: {
    paddingTop: 0,
    fontSize: 12,
  },
  sideMargin: {
    marginLeft: 4,
    marginRight: 4,
  },
});

const Loader = (props) => {
  const { classes, type, message, size, color, align = 'left' } = props;
  const loaderMessage = message || '...loading';
  const loaderSize = size ? Number(size) : 40;

  if (type === 'circular') {
    return (
      <div className={classes.sideMargin} style={{ textAlign: align }}>
        <CircularProgress
          value={50}
          color={color || 'primary'}
          variant="indeterminate"
          thickness={4}
          size={loaderSize}
        />
      </div>
    );
  }

  return (
    <Typography variant="inherit" color="textSecondary">
      {loaderMessage}
    </Typography>
  );
};

export default withStyles(styles)(Loader);
