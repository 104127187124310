import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = () => ({
  countsTable: {
    '& > thead > tr > th, & > tbody > tr > td': {
      padding: 8,
      fontSize: '0.75em'
    },
    '& > thead > tr > th': {
      backgroundColor: grey[50],
      borderTop: `1px solid ${grey[300]}`
    }
  },
  yesCell: {
    backgroundColor: green[50]
  },
  noCell: {
    backgroundColor: red[50]
  }
})

const OpenCountsTable = props => {
  const { reps, classes, isForUser, userName } = props

  const tableTitle = (
    <Typography component="div" variant="body2" color="primary">
      <strong>{isForUser ? userName : `${userName}'s team members`}</strong>&ensp;
      <Typography inline variant="inherit" color="textSecondary">
        <strong>({reps.length})</strong>
      </Typography>
    </Typography>
  )

  if (reps.length < 1) {
    return (
      <>
        {tableTitle}
        <Typography variant="body2" color="textSecondary">
          No Records.
        </Typography>
      </>
    )
  }

  return (
    <>
      {tableTitle}
      <Table padding="dense" className={classes.countsTable}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="center">DF Team Rep</TableCell>
            <TableCell align="center">Task Team Rep</TableCell>
            <TableCell align="right">DF Tasks</TableCell>
            <TableCell align="right">DF Workunits</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reps.map((rep, idx) => (
            <TableRow key={idx}>
              <TableCell>
                {rep.nm}
              </TableCell>
              <TableCell
                align="center"
                className={rep.is_df_team_rep
                  ? classes.yesCell
                  : classes.noCell}
              >
                <strong>{rep.is_df_team_rep ? 'Yes' : 'No'}</strong>
              </TableCell>
              <TableCell
                align="center"
                className={rep.is_task_team_rep
                  ? classes.yesCell
                  : classes.noCell}
              >
                <strong>{rep.is_task_team_rep ? 'Yes' : 'No'}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{rep.df_tasks || 0}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{rep.df_workunits || 0}</strong>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default withStyles(styles)(OpenCountsTable)
