import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { push } from 'connected-react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchIndexLogs_ac } from '../../actions/index_logs.ac.js'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import formatDate from '../../util/dateProcessing'
import FilterListing from '../FilterListing'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  completedTime: {
    color: '#4a148c'
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    height: '100%'
  },
  patientNm: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  title: {
    flexGrow: 1
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  spacer: {
    height: 40
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  txtVal: {
    fontSize: 12,
    color: '#000'
  },
  fullHeight: {
    height: '100%'
  }
})

const MAP_FILTER = {
  u_id: 'Actioner',
  team: 'Team'
}

const DEFAULT_FILTERS = {
  u_id: {
    lbl: 'Actioner',
    options: [{ lbl: 'ALL', code: 'all', checked: false }]
  },
  team: {
    lbl: 'Team',
    options: [{ lbl: 'ALL', code: 'all', checked: false }]
  }
}
const initialCondition = {
  requestedPageNum: 0,
  filterBy: 'code',
  filterVal: 'idxc'
}

/**
 * @param {object} tags
 * @returns Object
 */
function filterBuilder(tags) {
  if (!tags) return {}
  return Object.keys(tags).reduce((acc, item) => {
    acc[item] = {
      lbl: MAP_FILTER[item],
      options: _buildOptions(tags[item])
    }
    return acc
  }, {})

  function _buildOptions(options) {
    return options.map(item => ({
      code: item._id,
      lbl: item._id ? `${item._id}(${item.n})` : `(${item.n})`,
      checked: true
    }))
  }
}

class IndexLogsComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props)
    this.state = {
      requestedPageNum: 0,
      filterSequence: ['u_id', 'team'],
      filterChangeInProgress: false,
      filters: { ...DEFAULT_FILTERS },
      filtersSet: false,
      filterMsg: ''
    }
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.fetch = this.fetch.bind(this)
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this)
    this.getFetchParams = this.getFetchParams.bind(this)
  }

  onPageChange(evt) {
    this.setState({ requestedPageNum: parseInt(evt.target.value, 10) })
  }

  componentDidMount() {
    const { lookups, setTitle_ac, me } = this.props
    if (!me.user) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    setTitle_ac('Indexing Logs')

    return this.fetch()
  }

  componentDidUpdate() {
    const { indexLogsInView, lookups, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }
    if (indexLogsInView.tags && this.state.filtersSet === false) {
      const filters = filterBuilder(indexLogsInView.tags)
      let _default_filters = {
        ...DEFAULT_FILTERS,
        ...filters
      }
      this.setState({
        filters: _default_filters,
        filtersSet: true
      })
      return
    }

    if (
      indexLogsInView.isFetching === false &&
      !indexLogsInView.error &&
      indexLogsInView.records === null
    ) {
      return this.fetch()
    }

    if (
      indexLogsInView.pagination &&
      indexLogsInView.isFetching === false &&
      indexLogsInView.pagination.currentPageNum !==
        this.state.requestedPageNum &&
      indexLogsInView.pagination.currentPageNum !== -1
    ) {
      return this.fetch()
    }
  }

  getFetchParams() {
    return {
      tagFilters: {
        u_id: this.state.filters.qname.options
          .filter(s => s.checked)
          .map(s => s.code),
        team: this.state.filters.sp.options
          .filter(s => s.checked)
          .map(s => s.code)
      }
    }
  }

  fetch() {
    let fetchCondition = {
      ...initialCondition,
      requestedPageNum: this.state.requestedPageNum
    }
    return this.props.fetchIndexLogs_ac(fetchCondition)
  }

  fetchWithPageReset() {
    let fetchCondition = {
      ...initialCondition,
      ...this.getFetchParams(),
      requestedPageNum: 0
    }
    this.props.fetchIndexLogs_ac(fetchCondition)
    return this.setState({ requestedPageNum: 0 })
  }

  render() {
    const { indexLogsInView, classes, me, lookups } = this.props

    if (indexLogsInView.isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading records" />
        </main>
      )
    }
    if (!lookups.ink || indexLogsInView.records === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>...</h4>
        </div>
      )
    }
    if (this.state.filtersSet === false) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....filters not set</h4>
        </div>
      )
    }

    if (indexLogsInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching logs</h4>
        </div>
      )
    }

    if (!indexLogsInView.records.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>No records found.</h4>
        </div>
      )
    }

    const { pagination: pg } = indexLogsInView

    let pageSelector = ''
    let pageStatement = ''
    if (pg) {
      pageStatement = [
        `Indexing Logs - Displaying 
        ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
        `Page ${this.state.requestedPageNum + 1} of ${pg.totalPagesAvailable}`
      ].join('-')

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                onChange={this.onPageChange}
                displayEmpty
                value={this.state.requestedPageNum}
                name="age"
                className={classes.selectEmpty}>
                {_.range(0, pg.totalPagesAvailable).map(pgNum => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        )
      }
    }

    return (
      <Grid container spacing={0} item className={classes.scrollArea}>
        <FilterListing
          me={me}
          applyFilters={this.fetchWithFiltersApplied}
          filters={this.state.filters}
          filterSequence={this.state.filterSequence}
          onFilterChange={this.onFilterChange}
          onFilterToggle={this.onFilterToggle}
        />
        <main className={classes.content}>
          <div className={classes.pagination}>
            <Grid container spacing={8}>
              <Grid item xs={8} sm={8}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {pageStatement}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                {pageSelector}
              </Grid>
            </Grid>
          </div>

          <div className={classes.scrollArea}>
            {indexLogsInView.records.map((item, index) => (
              <Grid item xs={12} key={index}>
                <div className={classes.lbl}>
                  <strong className={classes.primary}>{item.codeNm}</strong> by{' '}
                  {item.u_id} on {formatDate.relativeTime(item.ts)}
                </div>
                <div className={classes.txtVal}>
                  {item.nm ? `Patient: ${item.nm}` : ''}
                </div>
                <br />
                <Divider component="hr" />
              </Grid>
            ))}
          </div>
        </main>
      </Grid>
    )
  }
}

IndexLogsComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  indexLogsInView: stateFromStore.indexLogsInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchIndexLogs_ac,
      lookups_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export const IndexLogs = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IndexLogsComponent))
)
