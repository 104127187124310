export function fetchComplianceDocs_ac(params){
  return (dispatch, getStore) => {
    dispatch({
      type: 'REQUEST_COMPLIANCE_DOCS',
      params
    })

    return window.sch.post('/api/compliance/report',params)
    .then(result => {
      if (result.error){
        throw new Error(result.error)
      }
      dispatch({
        type: 'RECEIVED_COMPLIANCE_DOCS',
        pkg: result.pkg,
        pagination: result.pagination
      })
    })
    .catch(error => {
      dispatch({
        type: 'FAILED_COMPLIANCE_DOCS',
        error
      })
    });
  }
}
