import React from 'react'
import { connect } from 'react-redux'

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto'
  },
  payerName: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 8,
    color: '#65502f'
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13)
  },
  formControl: {
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8
  },
  branchGrid: {
    marginTop: '6px'
  },
  paNumber: {
    fontWeight: 600
  },
  notLogged: {
    color: '#757575'
  },
  byTabKlass: {
    minWidth: 16
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  lblAction: {
    fontSize: 11,
    color: '#9c27b0',
    cursor: 'pointer'
  },
  activityMsg: {
    fontSize: 11,
    color: 'orange'
  },
  noteUser: {
    color: '#607D8B',
    fontWeight: 600
  },
  memoPara: {
    color: '#000',
    margin: '4px 4px 4px 0px',
    padding: 6,
    fontSize: 12,
    marginTop: 0,
    borderRadius: 4,
    backgroundColor: 'rgba(233, 236, 241, 0.49)'
  }
})

const DCCard = props => {
  const {
    classes,
    dc,
    onEditClick,
    onChange,
    isSelected,
    expanded = true,
    disabled = false
  } = props

  const handleEditClick = () => {
    onEditClick(dc)
  }

  const handleChange = (_, changed) => {
    onChange(dc.dc_id, changed)
  }

  return (
    <ExpansionPanel expanded={expanded} onChange={handleChange}>
      <ExpansionPanelSummary
        style={{
          backgroundColor: dc.touched ? 'rgb(228, 234, 204)' : '#fff'
        }}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.panelHdrTxt}>
          {dc.dc_nm.trim()}&nbsp;
          <span style={{ color: 'green' }}>
            #{dc.num} - ID: {dc.dc_id}
          </span>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container direction="column" justify="flex-start" wrap="nowrap">
          {['rom', 'supervisor', 'dispatcher'].map(k => {
            return (
              <Grid key={k} item className={classes.branchGrid}>
                <label className={classes.lbl}>{k.toUpperCase()}:</label>
                <Typography className={classes.payerName}>
                  {dc[k] !== undefined
                    ? dc[k].nm + '(' + dc[k].email + ')'
                    : 'None'}
                </Typography>
              </Grid>
            )
          })}
          <Grid item className={classes.branchGrid}>
            <Button
              variant="outlined"
              size="small"
              disabled={disabled}
              onClick={handleEditClick}>
              {isSelected ? 'Halt' : 'Edit'}
            </Button>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  dcs: stateFromStore.dcs,
  lookups: stateFromStore.lookups
})

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(DCCard))
