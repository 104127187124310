import React from 'react';
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField';

export const HeaderLevelForms = ({ handleChange, headerField }) => {
    return <FormGroup>
        <TextField
            autoFocus
            margin="dense"
            id="id"
            name="id"
            onChange={handleChange}
            label="ID"
            value={headerField.id}
            type="text"
            fullWidth
        />
        <TextField
            margin="dense"
            id="help_text"
            name="help_text"
            onChange={handleChange}
            label="Help Text"
            value={headerField.help_text}
            type="text"
            fullWidth
        />

        <TextField
            margin="dense"
            id="display_name"
            name="display_name"
            onChange={handleChange}
            label="Display Name"
            value={headerField.display_name}
            type="text"
            fullWidth
        />
        <TextField
            margin="dense"
            id="number"
            name="number"
            onChange={handleChange}
            label="Number"
            value={headerField.number}
            type="text"
            fullWidth
        />
    </FormGroup>
}
