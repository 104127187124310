import _ from 'lodash';
export async function fetchACL({ before, after, onSuccess, onError } = {}) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/rbac/list-rules', {});

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.rules || []);
    else return response?.rules;
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function createACL(
  data,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/rbac/create-rule', data);

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.rule || {});
    else return response?.rule || {};
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function updateACL(
  _id,
  data,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/rbac/update-rule', {
      _id,
      ...data,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.updated || {});
    else return response?.updated || {};
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function deleteACL(
  _id,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/rbac/delete-rule', {
      _id,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    if (onSuccess) onSuccess(response?.message || '');
    else return response?.message || '';
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}

export async function checkRule(
  roles = [],
  route = '',
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  try {
    const response = await window.sch.post('/api/rbac/check-access', {
      roles,
      route,
    });

    if (response.error) {
      console.error(response.error);
      if (onError) onError(new Error(response.error));
    }

    const res = _.isBoolean(response?.hasAccess) ? response.hasAccess : null;
    if (onSuccess) onSuccess(res);
    else return res;
  } catch (err) {
    if (onError) onError(err);
    console.error(err);
  }

  if (after) after();
}
