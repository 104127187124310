import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Button
} from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import CopyIcon from '@material-ui/icons/FileCopyRounded'


export const KeyValuePairsTableView = ({
    keyPairs,
    classes,
    removeNameValue,
    viewOnly
}) => {
    if (!(keyPairs || []).length) {
        return (
            <p>No any Key/Pair added yet</p>
        )
    }
    return (
        <>
            <p>Key Pairs</p>
            <div className={classes.fake_tableScrollable}>
                <Table padding="dense">
                    <TableHead padding="dense">
                        <TableRow className={classes.tableHeader}>
                            <TableCell align="left" width="20%">
                                Label
                            </TableCell>
                            <TableCell align="left" width="30%">
                                Private Key
                            </TableCell>
                            <TableCell align="left" width="30%">
                                Public Key
                            </TableCell>
                            <TableCell align="left" width="20%">
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(keyPairs || []).map((pair, index) => (
                            <TableRow key={index}>
                                <TableCell
                                    align="left"
                                    width="20%"
                                    variant="body"
                                    className={classes.tableBodyCell}>
                                    <span style={{ fontSize: 10 }}>
                                        {index + 1}){pair.label}
                                    </span>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width="30%"
                                    variant="body"
                                    style={{ padding: '0px' }}
                                    className={classes.tableBodyCell}
                                >
                                    <div style={{ fontSize: 10 }}>
                                        {pair.private.length > 10 ? `${pair.private.substring(0, 10)}...` : `${pair.private}`}
                                        <Tooltip title="click to copy">
                                            <Button onClick={() => { navigator.clipboard.writeText(pair.private) }}>
                                                <CopyIcon />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                                <TableCell
                                    align="left"
                                    width="30%"
                                    variant="body"
                                    style={{ padding: '0px' }}
                                    className={classes.tableBodyCell}
                                >
                                    <div style={{ fontSize: 10 }}>
                                        {pair.public.length > 10 ? `${pair.public.substring(0, 10)}...` : `${pair.public}`}
                                        <Tooltip title="click to copy">
                                            <Button onClick={() => { navigator.clipboard.writeText(pair.public) }}>
                                                <CopyIcon />
                                            </Button>
                                        </Tooltip>
                                    </div>
                                </TableCell>
                                {
                                    !viewOnly && (
                                        <TableCell
                                            align="left"
                                            width="20%"
                                            variant="body"
                                            style={{ padding: '0px' }}
                                            className={classes.tableBodyCell}
                                        >
                                            <div>
                                                <Tooltip title="remove all items">
                                                    <IconButton
                                                        aria-label="delete"
                                                        size="small"
                                                        onClick={() => removeNameValue(pair)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    )
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}
