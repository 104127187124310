import React from 'react'
import {
  Grid,
  TextField,
  Link,
  IconButton,
  Typography,
  FormControl,
  FormLabel
} from '@material-ui/core'

import { IoMdAddCircle } from 'react-icons/io'
import { AiFillDelete } from 'react-icons/ai'

const AddButton = props => {
  const { disabled = false, onAdd } = props

  return (
    <IconButton
      disabled={disabled}
      onClick={onAdd}
      style={{ padding: 8, cursor: 'pointer' }}>
      <IoMdAddCircle size={26} />
    </IconButton>
  )
}

export const URLList = props => {
  const { list, onDelete } = props

  return (
    <div style={{ magin: '8px ​0px 12px 0px' }}>
      {list.length ? (
        list.map(({ url, nm }, index) => (
          <div
            style={{
              background: 'gainsboro',
              borderRadius: '4px',
              padding: '6px',
              marginBottom: '8px',
              display: 'flex',
              justifyContent: 'space-between'
            }}
            key={index}>
            <div>
              <Link
                href={url}
                target="_blank"
                rel="noopener"
                style={{
                  color: '#9c27b0',
                  display: 'block',
                  fontSize: '0.9rem'
                }}>
                {url}
              </Link>
              <Typography
                variant="caption"
                style={{ color: 'rgb(25, 118, 210)' }}>
                {nm}
              </Typography>
            </div>
            <IconButton
              onClick={() => onDelete(index)}
              style={{ padding: 4, cursor: 'pointer' }}>
              <AiFillDelete size={20} />
            </IconButton>
          </div>
        ))
      ) : (
        <Typography
          variant="body1"
          style={{ textAlign: 'center', paddingBottom: '14px', color: 'grey' }}>
          No URLs to show.
        </Typography>
      )}
    </div>
  )
}

export const URLSelect = props => {
  const { onAdd } = props

  const [url, setUrl] = React.useState('')
  const [nm, setNm] = React.useState('')

  const isUrlValid = React.useMemo(
    () =>
      new RegExp(
        '((http|https)://){0,1}([a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*))',
        'i'
      ).test(url),
    [url]
  )

  const isNmValid = React.useMemo(() => nm.trim().length > 1, [nm])

  const handleChange = e => {
    const { name, value } = e.target

    if (name === 'url') {
      setUrl(value)
    } else if (name === 'nm') {
      setNm(value)
    }
  }

  const handleAddURL = () => {
    if (!isUrlValid || !isNmValid) return

    onAdd({
      url: encodeURIComponent(url.replace(/^(http:\/\/|https:\/\/)/, '')),
      nm: nm.trim()
    })
    setUrl('')
    setNm('')
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleAddURL()
  }

  return (
    <Grid
      container
      alignItems="baseline"
      onKeyDown={handleKeyDown}
      style={{ marginTop: '16px' }}>
      <FormControl style={{ marginLeft: 0 }} fullWidth>
        <FormLabel component="label">Add an URL:</FormLabel>
        <TextField
          margin="dense"
          type="text"
          name="url"
          placeholder="somelink.com"
          value={url}
          onChange={handleChange}
        />
        <TextField
          style={{ marginTop: '6px' }}
          margin="dense"
          name="nm"
          type="text"
          placeholder="Describe that URL."
          value={nm}
          onChange={handleChange}
        />
      </FormControl>
      &ensp;
      <div style={{ textAlign: 'right', width: '100%', marginTop: '-18px' }}>
        <AddButton disabled={!isUrlValid || !isNmValid} onAdd={handleAddURL} />
      </div>
    </Grid>
  )
}
