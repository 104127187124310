import { onboardedReportActionTypes } from '../actions/onboarded_report.ac'

export function onboardedReport(
  state = {
    records: null,
    error: null,
    isFetching: false
  },
  action
) {
  switch (action.type) {
    case onboardedReportActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case onboardedReportActionTypes.RECEIVED_ONBOARDED_REPORT:
      return {
        ...state,
        records: action.report.results,
        pagination: action.report.pagination,
        error: null
      };
    case onboardedReportActionTypes.FAILED_FETCHING_ONBOARDED_REPORT:
      return {
        ...state,
        records: null,
        pagination: null,
        error: action.error
      };
    default:
      return state;
  }
}
