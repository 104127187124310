import { SNLookUpsActionTypes } from '../actions/sn_lookup.ac.js';

export const snlookupsinview = (
    state = {
        isFetching: false,
        records: null,
        error: null
    },
    action
) => {

    switch (action.type) {
        case SNLookUpsActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case SNLookUpsActionTypes.RECEIVED_SN_LOOKUP_RECORD:
            return {
                ...state,
                records: action.records,
                error: null
            };

        case SNLookUpsActionTypes.FAILED_RECEIVING_SN_LOOKUP:
            return {
                ...state,
                records: null,
                error: action.error,
            };

        default:
            return state;
    }
};
