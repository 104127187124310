import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { orange, purple } from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchExpiringCCSMCalReport_ac } from '../../actions/expiringCCSMCal.ac.js';
import { setTitle_ac } from '../../actions/me.ac.js';
import FetchingStatementWrap from '../FetchingStatementWrap';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange,
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
  overrides: {
    MuiTableCell: {
      body: {
        fontSize: 12,
        verticalAlign: 'top',
      },
    },
  },
});
const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {},
  title: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  tblCell: {
    verticalAlign: 'top',
  },
  fullHeight: {
    height: '100%',
  },
});

/*
class ReportFilters extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5>Payer Group</h5>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="Payer Group"
            className={classes.group}
            value={this.props.selectedFilters.payer_group}
            onChange={this.props.onPayerGroupFilterChange}>
            <FormControlLabel value="ccs" label="CCS" control={<Radio />} />
            <FormControlLabel
              value="mcal"
              label="Medi-Cal"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }
}
*/

class ExpiringCCSMCalReportLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: {
        payer_group:
          _.get(this.props, 'match.params.payer_group', false) || 'ccs',
      },
    };
    this.payerGroupFilterChange = this.payerGroupFilterChange.bind(this);
    this.downloadAllCurrent = this.downloadAllCurrent.bind(this);
    this.downloadIndCurrent = this.downloadIndCurrent.bind(this);
    this.checkSituation = this.checkSituation.bind(this);
  }

  componentDidMount() {
    const { expiringCCSMCalReport } = this.props;
    if (expiringCCSMCalReport.isFetching) return;
    if (expiringCCSMCalReport.error) return;

    this.props.setTitle_ac('Expiring CCS-MCAL Report');
    this.checkSituation();
    return;
  }

  componentDidUpdate() {
    const { expiringCCSMCalReport } = this.props;
    if (expiringCCSMCalReport.isFetching) return;
    if (expiringCCSMCalReport.error) return;
    this.checkSituation();
    return;
  }

  checkSituation() {
    const { expiringCCSMCalReport, match } = this.props;
    if (!match.params.days) {
      let path = `/reports/ccs_mcal_pa_expiring/90/91`;
      return this.props.changePage(path);
    }
    let params = {
      days: match.params.days,
    };

    if (match.params.days_end) {
      params.days_end = match.params.days_end;
    }

    if (
      expiringCCSMCalReport.pkg === null &&
      expiringCCSMCalReport.error === null
    ) {
      this.props.fetchExpiringCCSMCalReport_ac(params);
    }
  }

  downloadAllCurrent() {
    const path = '/api/expiring-pas-mco/csv/all';
    window.sch.download_as_csv(
      path,
      'MCO_Expiring_ALLQs_' +
        this.props.expiringCCSMCalReport.pkg.staging_info.date_range_str +
        '.csv'
    );
  }

  downloadIndCurrent() {
    const path = '/api/expiring-pas-mco/csv/ind';
    window.sch.download_as_csv(
      path,
      'MCO_Expiring_IND_' +
        this.props.expiringCCSMCalReport.pkg.staging_info.date_range_str +
        '.csv'
    );
  }

  payerGroupFilterChange(e, v) {
    let _selectedFilters = {
      payer_group: v,
    };
    this.setState({ selectedFilters: _selectedFilters });
    let path = `/unbilled_stats/${v}`;
    this.props.changePage(path);
  }

  render() {
    const { match, classes, expiringCCSMCalReport } = this.props;
    const title = 'Expiring Oxygen Medicare CMNs';
    if (expiringCCSMCalReport.error) {
      return (
        <FetchingStatementWrap
          isError
          title={title}
          msg={
            expiringCCSMCalReport.error.message ||
            'Something went wrong. Contact IT'
          }
        />
      );
    }

    if (!expiringCCSMCalReport.pkg) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This might take up to 30 seconds'}
        />
      );
    }

    if (expiringCCSMCalReport.isFetching) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This might take up to 30 seconds'}
        />
      );
    }

    const { pkg } = expiringCCSMCalReport;
    let subtitle = 'CCS MCAL - Expiring PAs';
    if (match.params.days) {
      subtitle += ' - Expiring in ' + match.params.days + ' Days';
      if (match.params.days_end) {
        subtitle += ' to  ' + match.params.days_end + ' Days';
      }
    }

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={[classes.root, classes.fullHeight].join(' ')}>
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Grid container spacing={8} className={classes.fullHeight}>
                {/* BEGIN STATS */}
                <Grid item xs={12} sm={12} className={classes.fullHeight}>
                  {pkg.rr && (
                    <main
                      className={[
                        classes.content,
                        classes.pagination,
                        classes.fullHeight,
                      ].join(' ')}>
                      <label className={classes.label}>
                        {subtitle} ({pkg.n} distinct account-pa-expirations):
                      </label>
                      <div className={classes.scrollArea}>
                        <Table padding="dense">
                          <TableHead>
                            <TableRow>
                              <TableCell>Patient</TableCell>
                              <TableCell>Automation:</TableCell>
                              <TableCell>Expiring PA Branches</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pkg.rr.map((r, idx) => (
                              <TableRow key={idx}>
                                <TableCell variant="body">
                                  {r.idns[0]}
                                  <br />
                                  <Link
                                    to={'/dfa/account_docs/' + r.acctMember}>
                                    A{r.acctMember}
                                  </Link>
                                </TableCell>
                                <TableCell variant="body">
                                  {r.action}
                                  <br />
                                  {r.docs.length > 0 && (
                                    <a
                                      target={r.acctMember + 'HNP'}
                                      href={r.docs[0].url}>
                                      H&P Received: {r.docs[0].faxTs}
                                    </a>
                                  )}
                                </TableCell>
                                <TableCell variant="body">
                                  {r.pa_cmn_pairs.map((c, cidx) => (
                                    <div
                                      style={{
                                        marginBottom: 4,
                                        borderBottom: '1px solid lightgray',
                                      }}
                                      key={cidx}>
                                      <Grid container direction="row">
                                        <Grid item sm={6}>
                                          <div>{c.billing_code_desc}</div>
                                          <div>{c.PayerTypeName}</div>
                                          <div>PA Expires: {c.pa_exp}</div>
                                        </Grid>
                                        <Grid item sm={6}>
                                          <div>
                                            RX Dates: {c.cmn_start} to{' '}
                                            {c.cmn_exp}
                                          </div>
                                          <div>
                                            {c.rx_active
                                              ? 'RX Active'
                                              : 'RX NOT Active'}
                                          </div>
                                          {c.manual_review_required && (
                                            <div>
                                              Reason: {c.manual_review_reason}
                                            </div>
                                          )}
                                          <div>
                                            H&P must be more recent than:{' '}
                                            {c.hnp_must_be_more_recent_than}{' '}
                                            (180 days prior to pa exp)
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  ))}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </main>
                  )}
                </Grid>
                {/* END TODO CARDS LIST */}
              </Grid>
            </main>
          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

ExpiringCCSMCalReportLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  expiringCCSMCalReport: stateFromStore.expiringCCSMCalReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchExpiringCCSMCalReport_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExpiringCCSMCalReportLanding))
);
