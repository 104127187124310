import { useEffect, useState } from 'react';

const useConsent = (props) => {
  const {
    fetchAccountConsent_ac,
    acctMember,
    accountConsent,
    confirmAccountConsent_ac,
    updateHDMSField_ac,
    asModal = false,
    openSnack,
    modalOpen = false,
    onModalClose = () => {},
  } = props;
  const {
    events,
    consentRecord: consentRecordOrig,
    reference,
    hdms,
    isFetching: isFetchingConsentRecord,
    error: consentRecordError,
  } = accountConsent;

  const [actionPanelDisabled, setActionPanelDisabled] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const [tabIdx, setTabIdx] = useState(0); //for modal only
  const [consentRecord, setConsentRecord] = useState(null);

  const onReset = () => {
    fetchAccountConsent_ac(acctMember);
    setIsPristine(true);
  };

  const onConfirm = (
    new_consent_record_override = {},
    extras = {},
    onComplete = null
  ) => {
    let payload = {
      acctMember,
      last_consent_record: consentRecordOrig,
      //this is the consent_record reflecting the requested changes
      new_consent_record: {
        ...consentRecord,
        ...new_consent_record_override, //because the latest consentRecord may not have completed the last state chage
      },
      //optional depending on the action
      ...extras,
    };
    if (props.flow) {
      payload.flow = props.flow;
      payload.flow_record_id = props.flow_record_id;
    }
    console.log({ payload });
    confirmAccountConsent_ac(payload, onComplete);
    return;
  };

  const updateFieldValue = (which_mode, new_value, postUpdateAction) => {
    console.log(' inside updateFieldValue');
    console.log({ which_mode, new_value, postUpdateAction });
    switch (which_mode) {
      case 'mode_email':
        return updateHDMSField_ac(
          {
            acctMember,
            field_name: 'email',
            new_value,
            mode: which_mode,
          },
          postUpdateAction
        );
      case 'mode_sms':
        return updateHDMSField_ac(
          {
            acctMember,
            field_name: 'alt_phone',
            new_value,
            mode: which_mode,
          },
          postUpdateAction
        );
      default:
        return;
    }
  };

  useEffect(() => {
    if (consentRecordOrig && hdms) {
      setConsentRecord({
        ...consentRecordOrig,
        mode_email: {
          ...consentRecordOrig.mode_email,
          value: consentRecordOrig.mode_email.value || hdms.email,
        },
        mode_sms: {
          ...consentRecordOrig.mode_sms,
          value: consentRecordOrig.mode_sms.value || hdms.alt_phone,
        },
      });
    }
  }, [consentRecordOrig, hdms]);

  useEffect(() => {
    if (!isFetchingConsentRecord && !consentRecord && !consentRecordError) {
      fetchAccountConsent_ac(acctMember);
    }
  }, [
    acctMember,
    consentRecord,
    consentRecordError,
    isFetchingConsentRecord,
    fetchAccountConsent_ac,
  ]);

  return {
    actionPanelDisabled,
    isPristine,
    tabIdx,
    consentRecord,
    consentRecordOrig,
    isFetchingConsentRecord,
    consentRecordError,
    events,
    asModal,
    reference,
    hdms,

    openSnack,
    modalOpen,
    onModalClose,

    setConsentRecord,
    setIsPristine,
    setActionPanelDisabled,
    setTabIdx,
    onReset,
    onConfirm,
    updateFieldValue,
  };
};

export default useConsent;
