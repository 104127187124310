import {
  Button,
  Checkbox,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  linkTo: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
  },
  cardHdrRoot: {
    paddingBottom: 8,
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
    color: '#000',
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  cardContentRoot: {
    paddingBottom: 40,
    marginBottom: 40,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  listItemText: {
    color: '#3F51B5',
    fontWeight: 600,
    fontSize: '.92em',
  },

  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  slaStart: {
    marginTop: 6,
    color: '#008AE0',
  },
  slaPasses: {
    color: '#E51400',
    marginBottom: 6,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteTxt: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    color: '#244e91',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
  },

  formTplRoot: {
    width: '90%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControlInTpl: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: 0,
    marginTop: theme.spacing.unit * 2,
    paddingRight: 8,
  },

  tblItem_formControlInTpl: {
    marginLeft: theme.spacing.unit * 1,
    marginRight: 0,
    marginTop: theme.spacing.unit * 2,
    paddingRight: 8,
  },
  formGroupCols: {
    marginTop: 8,
    marginBottom: 16,
  },
  formLabel: {
    position: 'initial',
    marginBottom: '-16px',
  },
  formGroupChild: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('xl')]: {
      flexBasis: 'calc(12% - 32px)',
    },
    [theme.breakpoints.down('lg')]: {
      flexBasis: 'calc(16% - 32px)',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: 'calc(20% - 32px)',
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'calc(33% - 32px)',
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: 'calc(50% - 32px)',
    },
  },

  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  formHelper: {
    color: '#000',
  },
  formHelperError: {
    color: '#d50000',
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
});

const PF = {
  sc_contact_name: (parentProps) => {
    return parentProps.me.user.nm;
  },
  transmission_date: () => {
    return moment().format('MM-DD-YYYY');
  },
  pt_addr: (parentProps) => {
    let ptAddress = [];
    try {
      for (let account of parentProps.idnInView.idnDoc.aa) {
        for (let doc of parentProps.idnAccountInfo.pkg.accounts) {
          if (account !== doc.acctMember) continue;

          let name = doc.first_name.trim() + ' ' + doc.last_name.trim();
          let addr1 =
            typeof doc.addr_1 === 'string' ? doc.addr_1.trim() : false;
          let addr2 =
            typeof doc.addr_2 === 'string' ? doc.addr_2.trim() : false;
          let city = typeof doc.city === 'string' ? doc.city.trim() : false;
          let state = typeof doc.state === 'string' ? doc.state.trim() : false;
          let zip =
            typeof doc.zip === 'string'
              ? doc.zip.trim()
              : isNaN(doc.zip)
              ? false
              : String(doc.zip).trim();

          if (addr1 && city && state && zip) {
            if (ptAddress.length > 0)
              ptAddress.push('===== MULTIPLE ADDRESSES MATCHED =====');
            ptAddress.push(name);
            ptAddress.push(addr1);
            if (addr2) ptAddress.push(addr2);
            ptAddress.push(`${city}, ${state} ${zip}`);
          }
        }
      }
    } catch (e) {
      console.log('Error getting patient address:', e.message);
    }

    return ptAddress.join('\n');
  },
  ptname: (parentProps) => {
    let posOfDob = parentProps.idnInView.idnStr.search(/[0-9]/);
    return parentProps.idnInView.idnStr.replace(/_/g, ' ').substr(0, posOfDob);
  },
  patient_full_name: (parentProps) => {
    let posOfDob = parentProps.idnInView.idnStr.search(/[0-9]/);
    return parentProps.idnInView.idnStr.replace(/_/g, ' ').substr(0, posOfDob);
  },
  patient_dob_mmddyyyy: (parentProps) => {
    let posOfDob = parentProps.idnInView.idnStr.search(/[0-9]/);
    return parentProps.idnInView.idnStr.substr(posOfDob);
  },
  ptdob: (parentProps) => {
    let posOfDob = parentProps.idnInView.idnStr.search(/[0-9]/);
    return parentProps.idnInView.idnStr.substr(posOfDob);
  },
  schacct: (parentProps) => {
    if (parentProps.idnInView.idnDoc.aa.length) {
      return parentProps.idnInView.idnDoc.aa_num[0];
    }

    return '';
  },
  sc_account_number: (parentProps) => {
    if (parentProps.idnInView.idnDoc.aa.length) {
      return parentProps.idnInView.idnDoc.aa_num[0];
    }

    return '';
  },
};

function wrapStrToLines(str, max) {
  if (str.length < max) return [str];
  let parts = str.split(/ /g);
  let c = [];
  let t = '';
  parts.forEach((p) => {
    let count_of_asterisk_pairs = p.match(/\*\*/g);
    count_of_asterisk_pairs = count_of_asterisk_pairs
      ? count_of_asterisk_pairs.length
      : 0;
    if (t.length + p.length + 1 - count_of_asterisk_pairs > max) {
      c.push(t);
      t = '';
    }
    t += p.toString() + ' ';
  });
  if (t.trim().length) c.push(t);
  return c;
}

class TemplateForm extends React.Component {
  constructor(props) {
    super(props);
    let _state = {
      workChecked: false,
      status: 'NOT_RENDERED',
      work_values: null,
      work_restored: false,
      localKey: [this.props.tpl.id, this.props.idnInView.idnDoc._id].join('-'),
      allFields: [],
    };
    if (this.props.tpl.no_render) {
      _state.status = 'RENDERED';
    }

    let work_pkg = window.localStorage.getItem(_state.localKey);
    if (work_pkg) {
      work_pkg = JSON.parse(work_pkg);
      if (work_pkg.tpl_id === this.props.tpl.id) {
        _state.workChecked = true;
        _state.work_values = work_pkg.field_values;
      }
    }

    this.state = { ..._state };
    this.produceFields = this.produceFields.bind(this);
    this.predfinedValue = this.predfinedValue.bind(this);
    this.predfinedArrayValue = this.predfinedArrayValue.bind(this);
    this.predefinedTblField_TextValue =
      this.predefinedTblField_TextValue.bind(this);
    this.onRenderPreview = this.onRenderPreview.bind(this);
    this.genTableField = this.genTableField.bind(this);
    this.saveWork = this.saveWork.bind(this);
    this.restoreFromWork = this.restoreFromWork.bind(this);
  }

  /* PREDEFINED DEFAULT VALUES */
  predfinedValue(field) {
    const { dataSet } = this.props;
    if (typeof PF[field.nm] === 'function') {
      return PF[field.nm](this.props);
    }

    //check for provided data
    if (dataSet === undefined) return '';
    if (field.multiline && field.maxCharsPerLine) {
      return wrapStrToLines(
        _.get(dataSet, field.nm, ''),
        field.maxCharsPerLine
      ).join('\n');
    }

    return _.get(dataSet, field.nm, '');
  }

  predfinedArrayValue(fieldName) {
    const { dataSet } = this.props;
    //check for provided data
    if (dataSet === undefined) return [];
    return _.get(dataSet, fieldName, []);
  }

  predefinedTblField_TextValue(fieldName, nrows, tblField) {
    const { dataSet } = this.props;

    let row_data = [...Array(nrows).keys()].map(() => '');

    if (dataSet === undefined) return row_data;

    let tbl_field_data_rows = _.get(dataSet, fieldName, []);
    if (tbl_field_data_rows.length === 0) return row_data;

    tbl_field_data_rows.forEach((r, ri) => {
      if (tblField.multiline && tblField.maxCharsPerLine) {
        row_data[ri] = wrapStrToLines(
          _.get(r, tblField.nm, ''),
          tblField.maxCharsPerLine
        ).join('\n');
      } else {
        row_data[ri] = _.get(r, tblField.nm, '');
      }
    });
    return row_data;
  }

  produceFields() {
    const { tpl } = this.props;
    let _fields = [];
    tpl.pages.forEach((page, pageIdx) => {
      _fields = _fields.concat(
        page.fields.map((f, fPos) => {
          f.fPos = fPos * 1;
          f.page = pageIdx * 1;
          switch (f.type) {
            case 'checkbox':
              f.value = _.isArray(f.value)
                ? f.value
                : this.predfinedArrayValue(f.nm);
              //console.log(f.nm,f.value)
              f.options = f.options.map((o) => {
                o.checked = f.value.indexOf(o.nm) > -1;
                return o;
              });
              break;
            case 'table':
              f.fields = f.fields.map((tblField, tblFieldPos) => {
                tblField.pos = tblFieldPos * 1;
                switch (tblField.type) {
                  case 'checkbox':
                    tblField.row_values =
                      tblField.row_values === undefined
                        ? [...Array(f.nrows).keys()].map(() => [])
                        : tblField.row_values;
                    /*
                    tblField.row_values = _.isArray(tblField.row_values) ? tblField.row_values :
                      [...Array(f.nrows).keys()].map(a=>[]) :
                    */
                    break;

                  default:
                    //text and radio
                    //tblField.row_values = (tblField.row_values===undefined) ? [...Array(f.nrows).keys()].map(a=>"") :
                    //tblField.row_values;

                    tblField.row_values = _.isArray(tblField.row_values)
                      ? tblField.row_values
                      : this.predefinedTblField_TextValue(
                          f.nm,
                          f.nrows,
                          tblField
                        );
                    break;
                }
                return tblField;
              });
              break;
            default:
              //text and radio
              f.value =
                undefined === f.value || null === f.value
                  ? this.predfinedValue(f)
                  : f.value.toString();
              break;
          }
          return f;
        })
      );
    });

    return _fields;
  }

  onRenderPreview() {
    const { tpl } = this.props;

    // Page validations
    for (let page of tpl.pages) {
      let errorMessage;

      // Check blank page for valid address
      if (/BLANK_COVER/.test(page.path_to_png)) {
        for (let field of page.fields) {
          if (
            field.nm === 'cover_page_title' &&
            (!field.value ||
              !field.value.trim() ||
              //field.value.search(/-----|P\.O\.|:|, [A-Z][A-Z]\./) !== -1 ||
              field.value.trim().toLowerCase() === 'unknown payer address')
          ) {
            errorMessage = [
              'Invalid payer address on cover page',
              'Please update the "Cover Page Text" value with a valid address to proceed',
            ].join('\n');
          }
        }
      }

      if (errorMessage) {
        alert(errorMessage);
        return;
      }
    }

    this.setState({ status: 'INPROG' });
    this.saveWork();
    this.props.onTemplateRenderPreview(tpl.id);
  }

  genTableField(f) {
    const { classes, tpl, dataSet } = this.props;
    const formGroup = { root: classes.formGroupRoot };

    let rows_to_display = f.show_blank_rows
      ? f.nrows * 1
      : _.get(dataSet, f.nm, []).length;

    return (
      <div key={'tbl_form_' + f.nm} className="table_field">
        <FormControl
          required
          component="fieldset"
          className={classes.formControlInTpl}>
          <FormLabel
            component="label"
            style={{ color: '#000', fontWeight: 500, fontSize: '.88em' }}>
            {f.label} {tpl.pages.length > 1 && 'Page ' + (f.page + 1)}
          </FormLabel>
          <FormHelperText style={{ fontSize: '.82em', fontWeight: 500 }}>
            This is a repeating set of {f.nrows} questions, each containing{' '}
            {f.fields.length} fields.
          </FormHelperText>
        </FormControl>
        <div>
          {[...Array(rows_to_display).keys()].map((row_position) => {
            return (
              <ExpansionPanel
                defaultExpanded={false}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  marginLeft: 12,
                  border: '1px dashed #607D8B',
                  width: '96%',
                }}
                key={f.nm + '_row_' + row_position}>
                <ExpansionPanelSummary
                  style={{
                    fontSize: 12,
                    fontWeight: 'bold',
                    margin: 0,
                    backgroundColor: this.props.backgroundColor,
                  }}
                  expandIcon={<ExpandMoreIcon />}>
                  <span className={classes.pageStatement}>
                    {f.label} - {this.props.tableRowFieldPrefix || ''}:{' '}
                    {row_position + 1} - Expand to fill out
                  </span>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  style={{
                    padding: 12,
                    backgroundColor: this.props.backgroundColor,
                  }}>
                  <div>
                    {f.fields.map((tbl_field, posOfField) => {
                      let extra_label = this.props.tableRowFieldPrefix
                        ? 'For ' + f.label + ' #' + (row_position + 1)
                        : '';
                      switch (tbl_field.type) {
                        case 'text-multipos':
                        case 'text':
                          return (
                            <FormControl
                              required
                              fullWidth={tbl_field.fullWidth}
                              component="fieldset"
                              className={classes.tbl_formControlInTpl}
                              key={'tbl_f' + posOfField}>
                              <TextField
                                value={tbl_field.row_values[row_position]}
                                onChange={this.props.onChangeTplTextFieldValue_TableRowField(
                                  tpl.id,
                                  f.page,
                                  f.fPos,
                                  row_position,
                                  tbl_field
                                )}
                                placeholder=""
                                label={tbl_field.label}
                                multiline={tbl_field.multiline}
                                fullWidth={tbl_field.fullWidth}
                                margin="normal"
                                InputLabelProps={{
                                  style: {
                                    marginTop: -16,
                                    color: '#263238',
                                    fontWeight: 600,
                                    fontSize: '1.03em',
                                  },
                                  className: classes.formLabel || '',
                                  shrink: true,
                                }}
                              />
                              <FormHelperText className={classes.formHelper}>
                                {tbl_field.help_text} {extra_label}
                              </FormHelperText>
                            </FormControl>
                          );
                        case 'checkbox':
                          return (
                            <FormControl
                              key={'tbl_f' + posOfField}
                              required
                              component="fieldset"
                              className={classes.tbl_formControlInTpl}
                              fullWidth>
                              <FormLabel
                                component="label"
                                style={{
                                  color: '#263238',
                                  fontWeight: 600,
                                  fontSize: '1.03em',
                                }}>
                                {tbl_field.label}{' '}
                                {this.props.tableRowFieldPrefix &&
                                  f.label +
                                    ' ' +
                                    this.props.tableRowFieldPrefix +
                                    ' ' +
                                    (row_position + 1)}
                              </FormLabel>
                              <FormHelperText className={classes.formHelper}>
                                {tbl_field.help_text} {extra_label}
                              </FormHelperText>
                              <FormGroup classes={formGroup}>
                                {tbl_field.options.map((opt) => (
                                  <FormControlLabel
                                    labelPlacement="top"
                                    key={opt.nm}
                                    style={{ color: 'darkgreen' }}
                                    control={
                                      <Checkbox
                                        onChange={this.props.onChangeTplCheckboxFieldValue_TableRowField(
                                          tpl.id,
                                          f.page,
                                          f.fPos,
                                          row_position,
                                          tbl_field
                                        )}
                                        checked={opt.checked}
                                        value={opt.nm}
                                      />
                                    }
                                    label={opt.label}
                                  />
                                ))}
                              </FormGroup>
                            </FormControl>
                          );
                        case 'radio':
                          return (
                            <FormControl
                              key={'tbl_f' + posOfField}
                              className={classes.tbl_formControlInTpl}
                              fullWidth>
                              <InputLabel
                                style={{
                                  color: '#263238',
                                  fontWeight: 600,
                                  fontSize: '1.04em',
                                }}
                                className={classes.formLabel || ''}>
                                {tbl_field.label}
                              </InputLabel>
                              <Select
                                value={tbl_field.row_values[row_position]}
                                onChange={this.props.onChangeTplTextFieldValue_TableRowField(
                                  tpl.id,
                                  f.page,
                                  f.fPos,
                                  row_position,
                                  tbl_field
                                )}
                                input={<Input name="{tbl_field.nm}" />}>
                                {tbl_field.options.map((opt) => (
                                  <MenuItem key={opt.nm} value={opt.nm}>
                                    <em>{opt.label}</em>
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText className={classes.formHelper}>
                                Select 1 - {tbl_field.help_text} {extra_label}
                              </FormHelperText>
                            </FormControl>
                          );
                        default:
                          return '';
                      }
                    })}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
      </div>
    );
  }

  /* store field values in window localstorage as backup */
  saveWork() {
    const { tpl } = this.props;
    const fields = [...this.state.allFields];
    let field_values = fields.map((f, posOfField) => {
      switch (f.type) {
        case 'text-multipos':
        case 'text':
        case 'radio':
          return f.value;
        case 'checkbox':
          return f.options;
        case 'table':
          return f.fields;
        default:
          return '';
      }
    });
    window.localStorage.setItem(
      this.state.localKey,
      JSON.stringify({ tpl_id: tpl.id, field_values })
    );
    console.log('Saved work to local storagr');
    return;
  }

  restoreFromWork() {
    let storedFields = this.state.allFields.map((f, fi) => {
      switch (f.type) {
        case 'text':
        case 'radio':
          f.value = this.state.work_values[fi];
          break;
        case 'checkbox':
          f.options = this.state.work_values[fi].length
            ? [...this.state.work_values[fi]]
            : this.state.work_values[fi];
          break;
        case 'table':
          f.fields = this.state.work_values[fi];
          break;
        default:
          break;
      }
      return f;
    });

    return this.setState({
      work_restored: true,
      allFields: [...storedFields],
    });
  }

  componentDidUpdate(prevProps) {
    let newFields = this.produceFields() || [];
    const { allFields } = this.state;

    if (this.props.isPreSaving) {
      console.log('in isPreSaving');
      this.saveWork();
      this.props.uponLocalSave(this.state.localKey);
    }

    if (!_.isEqual(newFields, allFields)) {
      return this.setState({
        allFields: [...newFields],
      });
    }
  }

  render() {
    const { classes, tpl, resultingPreviewTab } = this.props;
    const { allFields: fields } = this.state;
    let renderStatus = this.state.status.toString();
    if (resultingPreviewTab) {
      renderStatus = resultingPreviewTab.status.toString();
    }
    const formGroup = { root: classes.formGroupRoot };

    return (
      <form className={classes.formTplRoot}>
        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel component="label">
            <b style={{ paddingRight: 4, color: 'blue' }}>
              Step {this.props.sectionNumber}.
            </b>
            {tpl.display_name} - Form Fields
          </FormLabel>
          {this.props.hideRenderHelpText === undefined && (
            <FormHelperText>
              Review the pre-populated form data for this template, then click
              on the GENERATE PREVIEW button at the bottom of the form to
              preview the rendered PDF.{' '}
            </FormHelperText>
          )}

          {this.state.work_values && this.state.work_restored === false && (
            <>
              <Button
                onClick={this.restoreFromWork}
                variant="contained"
                color="secondary"
                style={{ marginTop: 24 }}
                className={classes.button}>
                Restore Entries from Last Attempt
              </Button>
              <FormHelperText>
                If the previous request creation attempt failed and you would
                like your entries from the last attempt, click this button.
              </FormHelperText>
            </>
          )}
          {this.state.work_values && this.state.work_restored === true && (
            <strong>Entries from last attempt restored</strong>
          )}
        </FormControl>

        {fields.map((f, posOfField) => {
          let currentLength = 0;
          let maxChars = -1;
          if (
            (f.type === 'text' || f.type === 'text-multipos') &&
            f.maxLines &&
            f.maxCharsPerLine
          ) {
            maxChars = f.maxLines * f.maxCharsPerLine;
            currentLength = document.querySelector(
              `#${tpl.id}_${f.nm}_${posOfField}`
            )
              ? document.querySelector(`#${tpl.id}_${f.nm}_${posOfField}`).value
                  .length
              : 0;
          }
          switch (f.type) {
            case 'text-multipos':
            case 'text':
              return (
                <FormControl
                  required
                  component="fieldset"
                  fullWidth={f.fullWidth}
                  className={classes.formControlInTpl}
                  key={'f' + posOfField}>
                  <TextField
                    id={`${tpl.id}_${f.nm}_${posOfField}`}
                    onChange={this.props.onChangeTplTextFieldValue(tpl.id, f)}
                    value={f.value}
                    placeholder=""
                    label={
                      tpl.pages.length > 1
                        ? `${f.label} (Pg ${f.page + 1})`
                        : f.label
                    }
                    multiline={f.multiline}
                    margin="normal"
                    error={maxChars > 0 && currentLength > maxChars}
                    InputLabelProps={{
                      style: {
                        marginTop: -16,
                        color: '#263238',
                        fontWeight: 600,
                        fontSize: '1.03em',
                      },
                      className: classes.formLabel || '',
                      shrink: true,
                    }}
                  />
                  {maxChars > 0 && (
                    <FormHelperText
                      className={
                        maxChars > 0 && currentLength > maxChars
                          ? classes.formHelperError
                          : ''
                      }>
                      {currentLength}/{maxChars} max characters
                    </FormHelperText>
                  )}
                  <FormHelperText>{f.help_text}</FormHelperText>
                </FormControl>
              );
            case 'checkbox':
              return (
                <FormControl
                  key={'f' + posOfField}
                  required
                  component="fieldset"
                  className={classes.formControl}
                  fullWidth>
                  <FormLabel
                    component="label"
                    style={{
                      color: '#263238',
                      fontWeight: 600,
                      fontSize: '1.03em',
                    }}>
                    {tpl.pages.length > 1
                      ? `${f.label} (Pg ${f.page + 1})`
                      : f.label}
                  </FormLabel>
                  <FormHelperText>{f.help_text}</FormHelperText>
                  <FormGroup classes={formGroup}>
                    {f.options.map((opt) => (
                      <FormControlLabel
                        style={{
                          textAlign: 'center',
                          marginBottom: 24,
                          width: 120,
                        }}
                        labelPlacement="top"
                        key={opt.nm}
                        control={
                          <Checkbox
                            onChange={this.props.onChangeTplCheckboxFieldValue(
                              tpl.id,
                              f
                            )}
                            checked={opt.checked}
                            value={opt.nm}
                          />
                        }
                        label={opt.label}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              );
            case 'radio':
              return (
                <FormControl
                  key={'f' + posOfField}
                  className={classes.formControlInTpl}
                  fullWidth>
                  <InputLabel
                    shrink
                    style={{
                      color: '#263238',
                      fontWeight: 600,
                      fontSize: '1.03em',
                    }}
                    className={classes.formLabel || ''}>
                    {tpl.pages.length > 1
                      ? `${f.label} (Pg ${f.page + 1})`
                      : f.label}
                  </InputLabel>
                  <Select
                    value={f.value}
                    onChange={this.props.onChangeTplTextFieldValue(tpl.id, f)}
                    input={<Input name="{f.nm}" />}>
                    {f.options.map((opt) => (
                      <MenuItem key={opt.nm} value={opt.nm}>
                        <em>{opt.label}</em>
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>Select from Dropdown Menu</FormHelperText>
                </FormControl>
              );
            case 'table':
              return this.genTableField(f);
            default:
              return '';
          }
        })}

        {this.props.isPreSaving !== undefined && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            {this.props.isPreSaving ? '...saving work in progress' : '--'}
          </FormControl>
        )}

        {this.props.showSaveWorkBtn === true && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <Button
              onClick={this.saveWork}
              variant="contained"
              color="secondary"
              className={classes.button}>
              Save My Work in Progress
            </Button>
          </FormControl>
        )}

        {this.props.noRender !== true && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            {renderStatus === 'NOT_RENDERED' && (
              <Button
                onClick={this.onRenderPreview}
                variant="contained"
                color="secondary"
                className={classes.button}>
                Generate Preview
              </Button>
            )}

            {(renderStatus === 'INPROG' || renderStatus === 'NEW') && (
              <CircularProgress
                value={50}
                color="primary"
                variant="indeterminate"
                thickness={4}
              />
            )}

            {renderStatus === 'RENDERED' && this.props.tpl.no_render !== true && (
              <Button
                onClick={this.onRenderPreview}
                variant="contained"
                color="secondary"
                className={classes.button}>
                Update Preview
              </Button>
            )}
          </FormControl>
        )}
      </form>
    );
  }
}

export default withStyles(styles)(TemplateForm);
