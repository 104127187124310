import _ from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Loader from '../Shared/Loader'
import { PaginationWithNums } from '../PaginationNew'

const styles = theme => ({
  pill: {
    backgroundColor: 'orange',
    fontSize: 10,
    padding: 6,
    float: 'left',
    marginRight: 3
  },
  scrollableTable: {
    height: '100%',
    overflowY: 'auto'
  },
  padding8: {
    padding: 8
  }
})

const MednecIssueLogTable = props => {
  const { classes, mednecIssueLog, onSelectForEdit } = props
  const { records, pagination, error, isFetching } = mednecIssueLog
  const [selectedPageNum, setPageNum] = useState(_.get(pagination, 'currentPageNum', 0))

  useEffect(() => {
    setPageNum(pagination.currentPageNum)
  }, [pagination.currentPageNum])

  const handlePageChange = newPage => {
    let targetPage = parseInt(newPage, 10)
    setPageNum(targetPage - 1)
    return props.fetch(targetPage - 1)
  }

  const getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  if (isFetching) {
    return <Loader classes={{ message: classes.message }} />
  }

  if (error) {
    return (
      <>
        <Typography variant="body2" gutterBottom color="error" className={classes.message}>
          Something Went Wrong !
        </Typography>
        <Typography variant="body1" gutterBottom color="error" className={classes.message}>
          Error Fetching Records....
        </Typography>
      </>
    )
  }

  if (!(records && pagination)) {
    return (
      <Typography variant="subtitle1" color="textSecondary">
        No Records Found!
      </Typography>
    )
  }

  return (
    <>
      <Grid container item className={classes.padding8}>
        <Grid item xs={6}>
          <Typography color="textSecondary">
            {getPageStatement(pagination)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {pagination.totalPagesAvailable >= 1 && (
            <PaginationWithNums
              currentPageNum={selectedPageNum}
              totalPagesAvailable={pagination.totalPagesAvailable}
              onPageChange={handlePageChange}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.padding8}
      >
        <Grid item xs={12} className={classes.scrollableTable}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell width="20%">
                  Work Order
                </TableCell>
                <TableCell >
                  Issue Logged By
                </TableCell>
                <TableCell width="15%">
                  PCR who Released order
                </TableCell>
                <TableCell width="24%">
                  Status / Issues
                </TableCell>
                <TableCell width="24%">
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.length ? (
                records.map(r => (
                  <TableRow key={r._id}>
                    <TableCell width="20%" className={classes.rowCell}>
                      <Typography variant="body2" inline>
                        <strong>W{r._id}</strong>
                      </Typography>{' '}
                      <Typography variant="body2" inline>
                        A{r.account}-{r.member}
                      </Typography>
                      <div
                        onClick={e => onSelectForEdit({ ...r })}
                        style={{ cursor: 'pointer' }}>
                        [View Edit]
                      </div>
                    </TableCell>

                    <TableCell className={classes.rowCell}>
                      <Typography color="primary" variant="body2">
                        <strong>{r.mednec_issue.u_nm}</strong>
                      </Typography>
                      <Typography variant="caption" color="textPrimary">
                        on{' '}
                        {moment(r.mednec_issue.ts).format('MM-DD-YYYY hh:mm a')}
                      </Typography>
                    </TableCell>

                    <TableCell width="15%" className={classes.rowCell}>
                      <Typography variant="body2" color="textPrimary">
                        <strong>{r.pcr_nm}</strong>
                      </Typography>
                    </TableCell>

                    <TableCell width="24%" className={classes.rowCell}>
                      {r.mednec_issue.issue_lbls.map((issue_lbl, issueIdx) => (
                        <Typography
                          key={issueIdx}
                          gutterBottom
                          className={classes.pill}>
                          {issue_lbl}
                        </Typography>
                      ))}
                    </TableCell>

                    <TableCell width="24%" className={classes.rowCell}>
                      <Typography variant="body2" color="textPrimary">
                        {r.mednec_issue.comment}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}>
                    <Typography variant="subtitle1" color="textSecondary">
                      No Records Found!
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(MednecIssueLogTable)
