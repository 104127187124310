import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100%',
    overflow: 'auto',
    '-webkitOverflowScrolling': 'touch',
  },
});

class DocKeyToIdnLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      txt: 'Redirecting to ...',
      drawerOpen: false,
    };
  }

  componentDidMount() {
    console.log('comp did mount', this.props.me.user);
    if (this.props.me.user === null) {
      return;
    }
    this.checkSituation();
  }

  componentDidUpdate() {
    console.log('comp did mount', this.props.me.user);
    if (this.props.me.user === null) {
      return;
    }
    this.checkSituation();
  }

  checkSituation() {
    let target_url = `/api/idn/get/by/dockey`;
    let postData = { docKey: this.props.match.params.docKey };
    return window.sch
      .post(target_url, postData)
      .then((results) => {
        console.log('got results', results);
        if (results.fbKeyParts) {
          //dockey is actually a path to an fb doc search
          let to = `/dfa/idn/${results.idnDoc._id.replace(
            / /g,
            '_'
          )}/tabs/account_docs_dockey/${postData.docKey}/DOC`;
          return this.props.redirect(to);
        }

        let to = `/dfa/idn/${results.idnDoc._id.replace(
          / /g,
          '_'
        )}/tabs/account_docs_dockey/${postData.docKey}/DOC`;
        return this.props.redirect(to);
      })
      .catch((error) => {
        this.setState({
          error: true,
          txt: `Failed getting account from document id ${this.props.match.params.docKey}`,
        });
      });
  }

  render() {
    const { classes } = this.props;

    return <main className={classes.content}>{this.state.txt}</main>;
  }
}

DocKeyToIdnLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  dfaInView: stateFromStore.dfaInView,
});

const mapDispatchToProps = (dispatch, propsOfDocKeyToIdnLanding) =>
  bindActionCreators(
    {
      redirect: (path) => {
        return push(path);
      },
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DocKeyToIdnLanding))
);
