import {
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import {
  brown,
  green,
  indigo,
  orange,
  red,
  yellow
} from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  ExpandMore as ExpandMoreIcon,
  Phone as PhoneIcon
} from '@material-ui/icons'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import dateUtils from '../../util/dateProcessing'

const styles = theme => ({
  row: {
    verticalAlign: 'top',
    '&.even-row': {
      backgroundColor: yellow['50']
    },
    '&>td': {
      padding: 8
    }
  },
  scrollableCellContent: {
    maxHeight: 300,
    overflowY: 'auto'
  },
  expandClosedIcon: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard
    }),
    color: theme.palette.primary.main
  },
  expandOpenIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main
  },
  chip: {
    height: 24,
    borderRadius: 6,
    fontWeight: 800,
    margin: '4px 0px',
    textTransform: 'uppercase',
    '&.success': {
      backgroundColor: green['500'],
      color: '#ffffff'
    },
    '&.processed': {
      backgroundColor: orange['500'],
      color: '#ffffff'
    }
  },
  chipError: {
    backgroundColor: 'red'
  },

  claimId: {
    padding: 4,
    backgroundColor: theme.palette.primary['50']
  },
  userName: {
    color: brown['500'],
    fontWeight: 600
  },
  infoBlock: {
    marginBottom: 8
  },
  noteTxt: {
    color: indigo['700'],
    marginBottom: 4,
    padding: 4,
    backgroundColor: indigo['50']
  },
  errorBlock: {
    backgroundColor: red['50'],
    padding: 4
  },
  trmStatusOK: {
    color: green['500'],
    fontSize: 14
  },
  trmStatusNotOK: {
    color: theme.palette.error.main,
    fontSize: 14
  }
})

function ExpandablePatientLetterRow(props) {
  const { rowIdx, record, isExpandedDefault, classes } = props

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault)

  useEffect(() => {
    setIsExpanded(isExpandedDefault)
  }, [isExpandedDefault])

  if (record.result && record.trm) {
    record.has_trm_error =
      ['pdf_ok', 'sftp_ok', 'move_ok'].filter(k => record.trm[k] === false)
        .length > 0
  }

  return (
    <TableRow
      className={classNames(classes.row, {
        'even-row': rowIdx % 2 === 0
      })}>
      <TableCell width="1%" style={{ padding: 2 }}>
        <IconButton
          className={classNames(classes.expandClosedIcon, {
            [classes.expandOpenIcon]: isExpanded
          })}
          onClick={() => setIsExpanded(!isExpanded)}>
          <ExpandMoreIcon />
        </IconButton>
      </TableCell>

      <TableCell width="20%">
        <div className={classes.scrollableCellContent}>
          <div className={classes.infoBlock}>
            <Typography variant="body2">
              <strong>A{record.details.account}</strong>
            </Typography>

            <Typography variant="body2" gutterBottom>
              <strong>{record.details.pt_name}</strong>
            </Typography>
          </div>

          {isExpanded && (
            <div className={classes.infoBlock}>
              <Typography variant="body2" color="primary">
                <strong>Address:</strong>
              </Typography>
              <Typography variant="body2">
                {record.details.address_1 ? (
                  <>
                    {record.details.address_1}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {record.details.address_2 ? (
                  <>
                    {record.details.address_2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {record.details.city ? `${record.details.city}, ` : ''}
                {record.details.state ? `${record.details.state}, ` : ''}
                {record.details.zip || ''}
              </Typography>

              {record.details.phone && (
                <Typography variant="body2">
                  <PhoneIcon fontSize="small" color="disabled" />
                  &ensp;{record.details.phone}
                </Typography>
              )}
            </div>
          )}
        </div>
      </TableCell>

      <TableCell width="20%">
        <div className={classes.scrollableCellContent}>
          <div className={classes.infoBlock}>
            <Typography variant="body2">
              <strong>
                #{record._id} {record.details.type.toUpperCase()}
              </strong>
              &ensp;
              {record.duplicate_of && (
                <Typography
                  inline
                  component="span"
                  variant="inherit"
                  color="textSecondary">
                  <strong>[Duplicate of #{record.duplicate_of}]</strong>&ensp;
                </Typography>
              )}
            </Typography>
            {record.processed !== true && (
              <div>
                <Chip
                  label={'UNPROCESSED'}
                  className={[classes.chip].join(' ')}
                />
              </div>
            )}
            {record.result &&
              (record.result.error || record.has_trm_error) &&
              !isExpanded && (
                <div>
                  <Chip
                    label={'HAS ERROR'}
                    className={[classes.chip, classes.chipError].join(' ')}
                  />
                </div>
              )}
            {record.result && (
              <div>
                <Typography inline variant="body2" color="textSecondary">
                  GoogleDoc Produced
                  {record.result.success ? (
                    <CheckCircleIcon className={classes.trmStatusOK} />
                  ) : (
                    <CancelIcon className={classes.trmStatusNotOK} />
                  )}
                </Typography>
              </div>
            )}

            {record.trm && (
              <div>
                <Typography inline variant="body2" color="textSecondary">
                  PDF
                  {record.trm.pdf_ok ? (
                    <CheckCircleIcon className={classes.trmStatusOK} />
                  ) : (
                    <CancelIcon className={classes.trmStatusNotOK} />
                  )}
                </Typography>
                &ensp;&ensp;
                <Typography inline variant="body2" color="textSecondary">
                  Move
                  {record.trm.move_ok ? (
                    <CheckCircleIcon className={classes.trmStatusOK} />
                  ) : (
                    <CancelIcon className={classes.trmStatusNotOK} />
                  )}
                </Typography>
                &ensp;&ensp;
                <Typography inline variant="body2" color="textSecondary">
                  SFTP
                  {record.trm.sftp_ok ? (
                    <CheckCircleIcon className={classes.trmStatusOK} />
                  ) : (
                    <CancelIcon className={classes.trmStatusNotOK} />
                  )}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {record.trm.ts && dateUtils.formatDate(record.trm.ts)}
                </Typography>
              </div>
            )}
          </div>

          {isExpanded && (
            <>
              {record.result && record.result.error && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="error">
                    <strong>Error:&ensp;{record.result.error.message}!</strong>
                  </Typography>
                  <Typography
                    variant="body2"
                    component="div"
                    className={classes.errorBlock}>
                    {Object.keys(record.result.error.meta).map(errMetaKey => (
                      <div key={errMetaKey}>
                        <strong>{errMetaKey}:</strong>&ensp;
                        {record.result.error.meta[errMetaKey].join(', ')}
                      </div>
                    ))}
                  </Typography>
                </div>
              )}
              <div className={classes.infoBlock}>
                <Typography variant="body2" color="primary">
                  <strong>Claim ID(s):</strong>
                </Typography>
                <div className={classes.claimId}>
                  {record.claim_ids?.length > 0 &&
                    record.claim_ids.map((clId, clIdx) => (
                      <span key={clIdx}>
                        {clId}
                        {clIdx < record.claim_ids.length - 1 ? (
                          <>,&ensp;</>
                        ) : (
                          ''
                        )}
                      </span>
                    ))}
                </div>
              </div>
              {record.result && record.result.fileName && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="primary">
                    <strong>File:</strong>
                  </Typography>
                  <Typography variant="body2">
                    {record.result.fileName}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
      </TableCell>

      <TableCell width="15%">
        <div className={classes.scrollableCellContent}>
          <div className={classes.infoBlock}>
            <Typography variant="body2" className={classes.userName}>
              {record.u_nm}
            </Typography>
            <Typography variant="body2">({record.u_id})</Typography>
          </div>
        </div>
      </TableCell>

      <TableCell width="32%">
        <div className={classes.scrollableCellContent}>
          <div className={classes.infoBlock}>
            <Typography variant="body2" color="primary" inline>
              <strong>Health Plan:&ensp;</strong>
            </Typography>
            <Typography variant="body2" inline>
              {record.details.health_plan ? (
                record.details.health_plan
              ) : (
                <Typography component="span" inline color="textSecondary">
                  Missing
                </Typography>
              )}
            </Typography>
          </div>
          <div className={classes.infoBlock}>
            <Typography variant="body2" color="primary" inline>
              <strong>Group:&ensp;</strong>
            </Typography>
            <Typography variant="body2" inline>
              {record.details.medical_group ? (
                record.details.medical_group
              ) : (
                <Typography component="span" inline color="textSecondary">
                  Missing
                </Typography>
              )}
            </Typography>
          </div>

          {isExpanded && (
            <>
              <div className={classes.infoBlock}>
                <Typography variant="body2" color="primary" inline>
                  <strong>Type:&ensp;</strong>
                </Typography>
                <Typography variant="body2" inline>
                  {record.details.type ? (
                    record.details.type
                  ) : (
                    <Typography component="span" inline color="textSecondary">
                      Unhandled
                    </Typography>
                  )}
                </Typography>
              </div>
              {(record.details.billing_code_desc_1 ||
                record.details.billing_code_desc_2) && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="primary">
                    <strong> Billing Description:</strong>
                  </Typography>
                  {record.details.billing_code_desc_1 && (
                    <Typography variant="body2">
                      -&ensp;{record.details.billing_code_desc_1}
                    </Typography>
                  )}
                  {record.details.billing_code_desc_2 && (
                    <Typography variant="body2">
                      -&ensp;{record.details.billing_code_desc_2}
                    </Typography>
                  )}
                </div>
              )}
              <div className={classes.infoBlock}>
                <Typography variant="body2" color="primary">
                  <strong>Payer Name:</strong>
                </Typography>
                <Typography component="div" variant="body2">
                  {record.details.payer_name ? (
                    record.details.payer_name
                  ) : (
                    <Typography variant="inherit" color="textSecondary">
                      N/A
                    </Typography>
                  )}
                </Typography>
              </div>

              <div className={classes.infoBlock}>
                {record.details.coverage_start ? (
                  <Typography variant="body2" className={classes.noteTxt}>
                    <strong>Coverage</strong>
                    &ensp;started on {record.details.coverage_start}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    Missing <strong>coverage</strong>
                    &ensp;start date!
                  </Typography>
                )}
              </div>
            </>
          )}
        </div>
      </TableCell>

      <TableCell width="12%">
        <div className={classes.scrollableCellContent}>
          <Typography variant="body2" className={classes.infoBlock}>
            {dateUtils.formatDate(record.created_dt)}
          </Typography>

          {isExpanded && (
            <div className={classes.infoBlock}>
              {record.created_dt !== record.ts && (
                <Typography variant="body2" className={classes.noteTxt}>
                  -&ensp;last updated this packet&ensp;
                  {dateUtils.relativeTime(record.ts)}
                </Typography>
              )}
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(ExpandablePatientLetterRow)
