import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { markUB_ac } from '../../actions/ub.ac.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'lightblue', //theme.palette.background.paper,
    overflowX: 'auto'
  },
  name: {
    color: 'gray',
    fontSize: '.90em'
  },
  claimId: {
    textDecoration: 'italic',
    color: 'red'
  },
  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3
  },
  activityChip: {
    backgroundColor: '#96b1a7',
    fontWeight: 400,
    color: '#000'
  },

  balance: {
    paddingLeft: 12,
    fontWeight: 800,
    top: '38px' //bottom aligned with  account #
  },
  pointer: {
    cursor: 'pointer'
  },
  card: {
    minWidth: 275,
    backgroundColor: '#eff7f5', //theme.palette.background.paper,
    marginBottom: 32,
    marginTop: 2
  },
  cardHdrRoot: {
    paddingBottom: 8,
    backgroundColor: '#e0f7fa',
    cursor: 'pointer'
  },

  cardMarked: {
    minWidth: 275,
    backgroundColor: '#fcffd6',
    marginBottom: 32,
    marginTop: 2
  },
  cardHdrRootMarked: {
    paddingBottom: 8,
    backgroundColor: '#4bff00'
  },

  cardMarkedRed: {
    minWidth: 275,
    backgroundColor: '#fff',
    marginBottom: 32,
    marginTop: 2
  },
  cardHdrRootMarkedRed: {
    paddingBottom: 8,
    backgroundColor: '#f44336',
    color: '#fff'
  },

  cardHdrSubHdr: {
    fontSize: '.92em',
    color: 'blue'
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800
  },
  hcpcBal: {
    color: '#1976d2'
  },
  prodDesc: {
    color: '#42a5f5'
  },
  qty: {
    paddingLeft: 12
  },
  paErrorMsg: {
    color: 'red',
    fontWeight: 600
  },
  actions: {
    display: 'flex'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  formControl: {
    margin: theme.spacing.unit * 2
  }
})

const ACTIONS_FOR_DENIED = [
  { code: 'rebill', lbl: 'Rebill claim to Secondary payer' },
  { code: 'resubmit', lbl: 'Resubmit with required docs' },
  { code: 'pickup', lbl: 'Request Pick-Up' },
  { code: 'bill_private', lbl: 'Bill Private pay' }
]
const ACTIONS_FOR_DEFERRED = [
  { code: 'rebill', lbl: 'Rebill claim to Secondary Payer' },
  { code: 'resubmit', lbl: 'Resubmit with Required Docs' },
  { code: 'request_docs', lbl: 'Requet Additional Docs' }
]
const ACTIONS_FOR_AUTHORIZED = [
  { code: 'logged', lbl: 'Logged in HDMS' },
  { code: 'not_logged', lbl: 'Not qualified for logging,pending modification' },
  { code: 'logged_with_issue', lbl: 'Already Logged in HDMS, but has issue' }
]

class UBCard extends React.Component {
  constructor(props) {
    super(props)
    let applicable_action_opts = JSON.parse(
      JSON.stringify(ACTIONS_FOR_AUTHORIZED)
    )
    switch (this.props.ubDoc._id.pa_state) {
      case 'DENIED':
        applicable_action_opts = JSON.parse(JSON.stringify(ACTIONS_FOR_DENIED))
        break
      case 'DEFERRED':
        applicable_action_opts = JSON.parse(
          JSON.stringify(ACTIONS_FOR_DEFERRED)
        )
        break
      default:
        break
    }

    this.state = {
      error: null,
      detailsPanelExpanded: false,
      activityLabel: 'Mark Activity',
      markExpanded: false,
      lastAction: null,
      actionOpts: applicable_action_opts
    }
    this.handleDetailsToggle = this.handleDetailsToggle.bind(this)
    this.handleMarkToggle = this.handleMarkToggle.bind(this)
    this.onMarkAction = this.onMarkAction.bind(this)
    this.sarClicked = this.sarClicked.bind(this)
  }

  componentDidMount() {
    let opts = [...this.state.actionOpts]

    if (!this.props.ubDoc.ua) return
    const { ua } = this.props.ubDoc

    let checks = _.get(this.props.ubDoc, 'ua.codes', [])
    if (checks === null) checks = []
    opts.map(o => {
      o.checked = checks.indexOf(o.code) > -1
      return o
    })

    let activityLabel =
      'Mark ' +
      ` - Last worked by ${ua.u_nm} on
    ${moment(ua.ts).format('M-D-YY h:mma')}`

    this.setState({
      actionOpts: opts,
      activityLabel: activityLabel,
      lastAction: {
        u: ua.u_nm,
        ts: ua.ts
      }
    })
  }

  componentDidUpdate() {}

  claimIdSelected() {
    return
  }

  handleDetailsToggle() {
    this.setState({ detailsPanelExpanded: !this.state.detailsPanelExpanded })
  }

  handleMarkToggle() {
    this.setState({ markExpanded: !this.state.markExpanded })
  }

  onMarkAction(e) {
    if (this.props.ubList.activityUpdating) {
      return alert('Activity in progress')
    }

    if (this.props.ubList.isValidating) {
      return alert('Validation in progress')
    }

    let opts = [...this.state.actionOpts]
    let pos = opts.findIndex(o => o.code === e.target.value)
    opts[pos].checked = e.target.checked

    const c = this.props.ubList.records[this.props.posInSet]
    let params = {
      ub_ids: c.entries.map(e => e.ub_id),
      mark_codes: opts.filter(o => o.checked).map(o => o.code),
      payer_group: this.props.payerGroup
    }

    this.props.markUB_ac(params)
  }

  sarClicked = (sarNum, ubDoc) => () => {
    return this.props.sarClicked(sarNum, ubDoc.idn)
  }
  onCardHdrClicked = ubDoc => () => {
    return this.props.onCardHdrClicked(ubDoc)
  }

  render() {
    const { classes, ubDoc } = this.props

    const cardClass = {
      root: classes.card
    }
    const cardHdrClasses = {
      root: classes.cardHdrRoot,
      title: classes.cardHdrContent,
      subheader: classes.cardHdrSubHdr
    }

    let codes = _.get(ubDoc, 'ua.codes', [])
    if (codes === null) codes = []

    if (
      this.state.actionOpts.filter(a => a.code === 'logged' && a.checked)
        .length ||
      codes.indexOf('logged') > -1
    ) {
      cardClass['root'] = classes.cardMarked
      cardHdrClasses['root'] = classes.cardHdrRootMarked
    }

    if (
      this.state.actionOpts.filter(a => a.code === 'not_logged' && a.checked)
        .length ||
      codes.indexOf('not_logged') > -1
    ) {
      cardClass['root'] = classes.cardMarkedRed
      cardHdrClasses['root'] = classes.cardHdrRootMarkedRed
    }

    return (
      <Card classes={cardClass}>
        <CardHeader
          onClick={this.onCardHdrClicked(ubDoc)}
          classes={cardHdrClasses}
          title={
            <h5 style={{ paddingTop: 0, margin: 0 }}>
              <span>{ubDoc._id.nm.replace(/"/g, '')}</span>
              <span style={{ paddingLeft: 12 }}>A{ubDoc._id.a}</span>
              <span style={{ paddingLeft: 12 }}>${ubDoc.bal.toFixed(2)}</span>
              <span style={{ paddingLeft: 12 }}>{codes.join(',')}</span>
            </h5>
          }
          subheader={
            <h5 style={{ fontWeight: 400, fontSize: 12, margin: 0 }}>
              MedGrp {ubDoc._id.medgrp}
              <span style={{ paddingLeft: 12 }}>{ubDoc.hcpcs.join(',')}</span>
            </h5>
          }
        />
        <CardContent>
          {/*
          <div style={{fontSize:12}}>
            <span style={{color:'darkgreen'}}>
              {ubDoc._id.medgrp}
            </span>
          </div>
          */}
          <div>
            {_.uniq(_.get(ubDoc, '_id.pa_numbers', [])).map(s => (
              <Button
                key={s}
                variant="outlined"
                color="primary"
                size="small"
                onClick={this.sarClicked(s, ubDoc)}>
                AuthRequest# {s} {ubDoc._id.pa_state}
              </Button>
            ))}
          </div>
          <div style={{ fontSize: 10, color: 'purple' }}>
            Exported from UBDash{' '}
            {ubDoc.export_dates.map(ets => (
              <span key={ets}>{moment(ets).format('MM-DD-YY')}</span>
            ))}
          </div>
        </CardContent>

        {/* BEGIN DETAILS PANEL */}
        <CardActions className={classes.panelBtn}>
          <Button
            size="small"
            color="primary"
            onClick={this.handleDetailsToggle}>
            View {ubDoc.entries.length} Claim Lines
          </Button>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: this.state.detailsPanelExpanded
            })}
            onClick={this.handleDetailsToggle}
            aria-expanded={this.state.detailsPanelExpanded}
            aria-label="Show Comments">
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>

        <Collapse
          in={this.state.detailsPanelExpanded}
          timeout="auto"
          unmountOnExit>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>Claim</TableCell>
                <TableCell>DOS</TableCell>
                <TableCell>HCPC</TableCell>
                <TableCell align="right">Bal</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ubDoc.entries.map(e => (
                <TableRow key={e.Claim}>
                  <TableCell style={{ paddingRight: 0 }}>{e.Claim}</TableCell>

                  <TableCell style={{ paddingRight: 0 }}>
                    {moment(e.DOS, 'YYYY-MM-DD').format('MM-DD-YYYY')}
                  </TableCell>

                  <TableCell style={{ paddingRight: 0 }}>
                    {e.HCPC}
                    <br />
                    {e.UBReason}
                  </TableCell>

                  <TableCell align="right">${e.Balance.toFixed(2)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>

        {/* BEGIN WORK PANEL */}
        <CardActions className={classes.panelBtn}>
          <Button size="small" color="primary" onClick={this.handleMarkToggle}>
            {this.state.activityLabel}
          </Button>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: this.state.markExpanded
            })}
            onClick={this.handleMarkToggle}
            aria-expanded={this.state.markExpanded}
            aria-label="Show Mark Panel">
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>

        <Collapse in={this.state.markExpanded} timeout="auto" unmountOnExit>
          <CardContent style={{ fontSize: 12 }} className={classes.panelRoot}>
            <div>
              <FormControl
                required
                component="fieldset"
                className={classes.formControl}>
                {/*<FormLabel component="legend">Check all applicable</FormLabel> */}
                <FormHelperText>Check applicable</FormHelperText>
                <FormGroup>
                  {this.state.actionOpts.map(a => (
                    <FormControlLabel
                      key={a.code}
                      control={
                        <Checkbox
                          checked={a.checked}
                          onChange={this.onMarkAction}
                          value={a.code}
                        />
                      }
                      label={a.lbl}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </div>
          </CardContent>
        </Collapse>
      </Card>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  ubList: stateFromStore.ubList
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      markUB_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UBCard))
