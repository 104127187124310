import _ from 'lodash';

export const FETCH_WORKUNITS_REPORT = 'FETCH_WORKUNITS_REPORT';
export const RECEIVED_WORKUNITS_REPORT = 'RECEIVED_WORKUNITS_REPORT';
export const FAILED_FETCHING_WORKUNITS_REPORT =
  'FAILED_FETCHING_WORKUNITS_REPORT';

export const FETCH_WORKUNITS = 'FETCH_WORKUNITS';
export const FLUSH_WORKUNITS = 'FLUSH_WORKUNITS';
export const RECEIVED_WORKUNITS = 'RECEIVED_WORKUNITS';
export const FAILED_FETCHING_WORKUNITS = 'FAILED_FETCHING_WORKUNITS';

export const fetchManagerWorkunitsStats_ac = (kinds, params = {}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_WORKUNITS_REPORT });

    window.sch
      .post(`/api/df/workunits/report`, {
        kinds: kinds,
        ...params,
      })
      .then((res) => {
        dispatch({
          type: RECEIVED_WORKUNITS_REPORT,
          data: res,
        });
      })
      .catch((error) => {
        dispatch({ type: FAILED_FETCHING_WORKUNITS_REPORT });
      });
  };
};

export const fetchManagerWorkunitsList_ac = (params = {}, kind) => {
  return (dispatch) => {
    dispatch({ type: FETCH_WORKUNITS, kind });
    window.sch
      .post(`/api/df/workunits`, params)
      .then((res) => {
        dispatch({ type: RECEIVED_WORKUNITS, data: res, kind });
      })
      .catch((error) => {
        dispatch({ type: FAILED_FETCHING_WORKUNITS, kind, error });
      });
  };
};

export const fetchWorkunitsListWithArchived_ac = (params = {}, kind) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_WORKUNITS, kind });
    try {
      const isAscending =
        (params.sort['m.created.ts'] || -1) === 1 ? true : false;

      const unArchived = await window.sch.post(`/api/df/workunits`, {
        ...params,
        from_archive: false,
      });
      const archived = await window.sch.post(`/api/df/workunits`, {
        ...params,
        from_archive: true,
      });

      const res = {
        results: _.orderBy(
          [...(unArchived.results || []), ...(archived.results || [])],
          ['m.created.ts'],
          [isAscending ? 'asc' : 'desc']
        ),

        // Meta would be unreliable after the merge so DO NOT USE.
        meta: {
          totalRecords:
            (unArchived.meta?.totalRecords || 0) +
            (archived.meta?.totalRecords || 0),
          pageCount: Math.max(
            unArchived.meta?.pageCount || 0,
            archived.meta?.pageCount || 0
          ),
          pageIndex: Math.min(
            unArchived.meta?.pageIndex || 0,
            archived.meta?.pageIndex || 0
          ),
          pageSize: Math.max(
            unArchived.meta?.pageSize || 0,
            archived.meta?.pageSize || 0
          ),
        },
      };

      dispatch({ type: RECEIVED_WORKUNITS, data: res, kind });
    } catch (error) {
      dispatch({ type: FAILED_FETCHING_WORKUNITS, kind, error });
    }
  };
};

export const flushManagerWorkunitsList_ac = (kind) => ({
  type: FLUSH_WORKUNITS,
  kind,
});
