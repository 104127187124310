import { NotificationPreferenceActionTypes } from './../actions/notification_preference.ac'

export const notificationPreferenceInView = (
    state = {
        result: null,
        error: null,
        isLoading: false
    },
    action
) => {
    switch (action.type) {
        case NotificationPreferenceActionTypes.NOTIFICATION_PREFERENCE_UPDATED:
            return {
                ...state,
                result: action.result,
                error: null
            }
        case NotificationPreferenceActionTypes.FAILED_UPDATING_NETWORK_PREFERENCE:
            return {
                ...state,
                error: action.error
            }
        case NotificationPreferenceActionTypes.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            }
        default:
            return state
    }
}
