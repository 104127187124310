//reducer for idnInView
export function idnInView(
  state = {
    idnStr: '',
    idnDoc: null, //distinguish from results:[]  (empty result set) vs null: not initiated
    selectedTab: 'doclisting',
    isFetching: false,
    preMergedIdn: null,
    mode: null,
    good_idn: null,
    error: false
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_IDN_DOC':
      return Object.assign({}, state, {
        idnStr: action.idnStr,
        isFetching: true,
        preMergedIdn: null,
        good_idn: null,
        error: null,
        mode: null
      })
    case 'FAILED_RECEIVED_IDN_DOC':
      return Object.assign({}, state, {
        preMergedIdn: null,
        isFetching: false,
        error: action.error
      })
    case 'RECEIVED_IDN_DOC':
      return Object.assign({}, state, {
        idnDoc: action.idnDoc,
        isFetching: false,
        preMergedIdn: action.preMergedIdn,
        good_idn: null,
        error: null,
        mode: null
      })
    case 'MERGE_IDN_SETUP':
      return Object.assign({}, state, {
        preMergedIdn: null,
        good_idn: action.good_idn
      })
    case 'IDN_FIX_MODE':
      return Object.assign({}, state, {
        preMergedIdn: null,
        mode: 'merge'
      })
    case 'CLEAR_IDN_FIX_MODE':
      return Object.assign({}, state, {
        preMergedIdn: null,
        mode: null,
        good_idn: null
      })
    case 'RESET_IDN_IN_VIEW':
      return Object.assign({}, state, {
        preMergedIdn: null,
        idnDoc: action.idnDoc,
        selectedTab: 'doclisting',
        mode: null,
        good_idn: null,
        error: null,
        isFetching: false
      })
    case 'IDN_TAB_SELECTED':
      return Object.assign({}, state, {
        preMergedIdn: null,
        selectedTab: action.selectedTab,
        error: null,
        mode: null,
        good_idn: null
      })
    default:
      return state
  }
}
