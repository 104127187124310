import { serialize } from '../util/object'

export const REQUEST_HOURLY_WOS = 'REQUEST_HOURLY_WOS'
export const RECEIVED_HOURLY_WOS = 'RECEIVED_HOURLY_WOS'

export const REQUEST_HOURLY_WOS_LIST = 'REQUEST_HOURLY_WOS_LIST'
export const RECEIVED_HOURLY_WOS_LIST = 'RECEIVED_HOURLY_WOS_LIST'

export const FAILED_HOURLY_WOS = 'FAILED_HOURLY_WOS'

export function fetchHourlyWos_ac(params) {
  return (dispatch, _) => {
    dispatch({ type: REQUEST_HOURLY_WOS })
    return window.sch
      .get(`/api/hourly_wos:list?` + serialize(params))
      .then(result => {
        dispatch({
          type: RECEIVED_HOURLY_WOS,
          pkg: result
        })
      })
      .catch(error => {
        dispatch({
          type: FAILED_HOURLY_WOS,
          error
        })
      })
  }
}

export function fetchHourlyWosList_ac(params, payload) {
  return (dispatch, _) => {
    dispatch({ type: REQUEST_HOURLY_WOS_LIST });
    return window.sch
      .post(`/api/hourly_wos?` + serialize(params), payload)
      .then((result) => {
        dispatch({
          type: RECEIVED_HOURLY_WOS_LIST,
          pkg: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: FAILED_HOURLY_WOS,
          error,
        });
      });
  };
}
