export const DEFAULT_FREQUENCY_LIST = [
  {
    dos: '',
    hcpc_code: 'A7030',
    description: 'full face mask',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7031',
    description: 'full face cushion',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7034',
    description: 'nasal/pillow mask frame',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7032',
    description: 'nasal cushion replacements',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7033',
    description: 'nasal pillow replacement',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7035',
    description: 'headgear',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: 'Not for individual sale. Only in Mask Kit.',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7036',
    description: 'chin strap',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: 'Item only ordered upon request.',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7037',
    description: 'standard tubing',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7038',
    description: 'disposable filters',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7039',
    description: 'reusable filter',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: 'Only applies to Phillips Respironics brand.',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A7046',
    description: 'water chamber',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  },
  {
    dos: '',
    hcpc_code: 'A4604',
    description: 'heated tubing',
    eligible: '',
    quantity: '',
    frequency: '',
    additional_info: '',
    isChecked: false
  }
]

export const TUBING_ITEMS_HCPC_CODES = ['A4604', 'A7037'];

export const PAP_ORDER_STATUS = [
  'CANCELLED',
  'COMPLETED BY ANOTHER CCS',
  'EXCHANGE',
  'FOLLOW UP ON PENDING DOCUMENTS',
  'PROCESSED',
  'SHIPPED'
].map(d => ({ code: d, lbl: d }))

export const CONTACT_TYPES = [
  'CALLED DR`S OFFICE & SPOKE TO',
  'EMAIL SENT',
  'F/U CALL FROM DR`S OFFICE INQUIRING ON PT. ORDER. SPOKE TO ',
  'LEFT PT. VM',
  'RECEIVED PT. CALL',
  'RECEIVED HEALTHCALL ORDER INTAKE',
  'RETURNED PT. CALL',
  'TRANSFERRED TO',
  'UNABLE TO CONTACT PT. OR DR. OFFICE AFTER  # ATTEMPTED CALL(S)',
  'WEB ORDER',
  'CHAT RESOURCE'
].map(d => ({ code: d, lbl: d }))

export const REQUESTING_PATIENTS = [
  'ITEM EXCHANGE',
  'NEW SUPPLIES',
  'ORDER STATUS',
  'ORDER TRACKING '
].map(d => ({ code: d, lbl: d }))

export const REASONS = [
  'BACTERIA BUILD-UP',
  'BROKEN/TORN & NOT REPAIRABLE',
  'DISCOLORATION',
  'DUST BUILD-UP',
  'LEAKS',
  'MOLD',
  'NOT ADHERING',
  'POLLEN BUILD-UP',
  'STRETCHED',
  'WEAR & TEAR'
].map(d => ({ code: d, lbl: d }))

export const NEW_ORDERED_ITEMS_QUANTITES = [
  'QTY 1',
  'QTY 2',
  'QTY 3',
  'QTY 4',
  'QTY 5',
  'QTY 6'
].map(d => ({ code: d, lbl: d }))

export const NEW_ORDERED_ITEMS_SIZES = [
  'P - Petite',
  'XS - Extra Small',
  'S - Small',
  'M - Medium',
  'L - Large',
  'XL - Extra Large'
].map(d => ({ code: d, lbl: d }))

export const PAP_UNITS = [
  'DREAMSTATION',
  'RESMED S9',
  'RESMED S10',
  'REMSTAR SYSTEM ONE'
].map(d => ({ code: d, lbl: d }))

export const DOCUMENTS_REQUESTED = [
  '1st OR 2nd SLEEP STUDY',
  'AUTHORIZATION',
  'CMN/RX',
  'COMPLIANCE REPORT',
  'CURRENT CHART NOTES',
  'F2F CHART NOTES',
  'PRIOR CHART NOTES',
  'NONE'
].map(d => ({ code: d, lbl: d }))

export const PT_CO_PAYMENTS = [
  'COLLECTED $',
  'None'
].map(d => ({ code: d, lbl: d }))

export const DF_PATIENT_NOTIFICATIONS = [
  'PT REFUSED',
  'ENABLED',
  'ACTIVE'
].map(d => ({ code: d, lbl: d }))

export const payers = ['medi-cal', 'medicare'].map(d => {
  return {
    code: d,
    lbl: d === 'medicare' ? 'medicare/ppo/hmo' : d
  }
})

export const maskTypes = ['fullface', 'nasal-cushion', 'nasal-pillow'].map(d => ({ code: d, lbl: d }))

export const FULL_FACE_CODES = ['A7030', 'A7031']
export const NASAL_PILLOW_CODES = ['A7034', 'A7033']
export const NASAL_CUSHION_CODES = ['A7034', 'A7032']

export const MASK_KIT_HELP_TEXT = 'MASK KIT includes: Mask, Cushion/Pillow & Headgear. Items are counted towards allowable frequencies. Adjust quantities for cushion/pillow & headgear when processing order.'

export const MASK_OPTIONS = {
  fullface: ['Airfit F20', 'Amara View', 'Dreamwear FF mask', 'Vitera FF', 'FF Mirage Quattro', 'Airtouch F20', 'Airfit F30i', 'Airfit F30', 'FF Simplus', 'Airfit F10', 'FF Flexifit'],
  'nasal-cushion': ['Nasal Wisp', 'Dreamwear nasal mask', 'Airfit N20', 'Airfit N30', 'Airfit N30i', 'Nasal Pico', 'Airfit N10', 'Nasal Mirage Fx', 'Nasal Swift Fx nano', 'Nasal Eson 2', 'Nasal Eson', 'Nasal Evora', 'Nasal Dreamwisp'],
  'nasal-pillow': ['Dreamwear Silicone', 'Brevida Nasal Pillow', 'Airfit P10', 'Cannula Swift Fx', 'Airfit P30i', 'Cannula Nuance Gel', 'Cannula Nuance Pro', 'Mask Pillow Swift Fx Bella.Cannula Opus', 'Cannula Pilairo Q']
}