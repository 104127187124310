import { TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'

const styles = theme => ({
  textField: {
    maxWidth: 240,
    minWidth: 180
  },
  densePadding: {
    padding: '10px 12px 8px 12px'
  },
  denseSelectField: {
    paddingRight: 24
  },
  denseFont: {
    fontSize: '0.8rem'
  }
})

/**
 * 
 * @returns {TextField}
 */
const FilterTextField = props => {
  const {
    children,
    classes,
    label,
    required = false,
    InputProps = {},
    InputLabelProps = {},
    SelectProps = {},
    ...otherProps
  } = props

  return (
    <TextField
      margin="dense"
      variant="outlined"
      label={label !== undefined ? <strong>{label}</strong> : null}
      InputLabelProps={{
        shrink: true,
        required,
        ...InputLabelProps
      }}
      InputProps={{
        classes: {
          inputMarginDense: classes.densePadding,
          root: classes.denseFont,
          ...(InputProps.classes || {})
        },
        ...InputProps
      }}
      SelectProps={{
        inputProps: {
          className: classes.denseSelectField,
          ...(SelectProps.inputProps || {})
        },
        ...SelectProps
      }}
      className={classes.textField}
      {...otherProps}
    >
      {children}
    </TextField>
  )
}

export default withStyles(styles)(FilterTextField)
