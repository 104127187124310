import { open_snack_ac } from './snack.ac.js';

export const TeamsGridActionTypes = {
  RECEIVED_TEAMS_GRID_DATA: 'RECEIVED_TEAMS_GRID_DATA',
  FAILED_RECEIVED_TEAMS_GRID_DATA: 'FAILED_RECEIVED_TEAMS_GRID_DATA',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_ADDING_MEMBER: 'SET_IS_ADDING_MEMBER',
  SET_IS_REMOVING_MEMBER: 'SET_IS_REMOVING_MEMBER',
  ADD_MEMBER: 'ADD_MEMBER',
  REMOVE_MEMBER_FROM_TEAM: 'REMOVE_MEMBER_FROM _TEAM',
  DOC_FLOW_STATE_CHANGED: 'DOC_FLOW_STATE_CHANGED',
};

export function fetchTeamsGridData_ac(params) {
  return (dispatch) => {
    dispatch(fetching(true));
    return window.sch
      .post('/api/teams:get', { ...params })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: TeamsGridActionTypes.RECEIVED_TEAMS_GRID_DATA,
          records: result.teams,
        });
      })
      .catch((error) => {
        dispatch({
          type: TeamsGridActionTypes.FAILED_RECEIVED_TEAMS_GRID_DATA,
          error,
        });
      })
      .finally(() => {
        dispatch(fetching(false));
      });
  };
}

export function addMember_ac(params) {
  return (dispatch) => {
    dispatch(addingMember(params, true));
    return window.sch
      .post('/api/user:team:toggle', { ...params })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: TeamsGridActionTypes.ADD_MEMBER,
          team: params.team,
          user: result.user,
        });
      })
      .catch((error) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'adding member failed',
          })
        );
      })
      .finally(() => {
        dispatch(addingMember(params, false));
        dispatch(
          open_snack_ac({
            variant: 'info',
            message: `${params.email} added to ${params.team}`,
          })
        );
      });
  };
}

export function removeMemberFromTeam_ac(params, itemIndex) {
  return (dispatch) => {
    dispatch(removingMember(params, true));
    return window.sch
      .post('/api/user:team:toggle', { ...params })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: TeamsGridActionTypes.REMOVE_MEMBER_FROM_TEAM,
          user: result.user,
          team: params.team,
        });
      })
      .catch((error) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'member removing failed',
          })
        );
      })
      .finally(() => {
        dispatch(removingMember(params, false));
        dispatch(
          open_snack_ac({
            variant: 'info',
            message: `${params.email} removed from ${params.team}`,
          })
        );
      });
  };
}

export function changeDocFlowState_ac(params) {
  const { docFlowState, email, team } = params;
  return (dispatch) => {
    return window.sch
      .post('/api/user:state:toggle', { docFlowState, email })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: TeamsGridActionTypes.DOC_FLOW_STATE_CHANGED,
          user: result.user,
          team,
          docFlowState,
        });
        dispatch(
          open_snack_ac({
            variant: `info`,
            message: `Status updated`,
          })
        );
      })
      .catch((error) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Error changing doc flow state',
          })
        );
      });
  };
}

export const fetching = (isFetching) => ({
  type: TeamsGridActionTypes.SET_IS_FETCHING,
  isFetching,
});

const addingMember = (teamInfo, isAddingMember) => ({
  type: TeamsGridActionTypes.SET_IS_ADDING_MEMBER,
  teamName: teamInfo.team,
  isAddingMember,
});

const removingMember = (teamInfo, isRemovingMember) => ({
  type: TeamsGridActionTypes.SET_IS_REMOVING_MEMBER,
  memberEmail: teamInfo.email,
  teamName: teamInfo.team,
  isRemovingMember,
});
