import React from 'react';
import { truncate } from '../../util/string';

const SECTION_SEQUENCE = [
  'Products',
  'Contract Info',
  'DME Services',
  'COVID 19 APPROVED CHANGES',
  'Billing & CCS Dept Info',
  'Misc',
];
const YES_NO_SECTION = ['Products', 'DME Services'];
function isURL(str) {
  // Regular expression pattern to match a URL
  const urlPattern =
    /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{2,5})?(\/\S*)?$/i;

  // Test if the string matches the URL pattern
  return urlPattern.test(str);
}

function renderString(oStr, fallback = 'N/A', trchar = 40) {
  const str = (oStr || '').trim() || fallback;
  if (isURL(str)) {
    // If the string is a URL, return an <a> tag to open a new tab
    return (
      <a href={str} rel="noreferrer noopener" target="_blank">
        {truncate(str, trchar)}
      </a>
    );
  } else {
    // If the string is not a URL, return the input string as is
    return str;
  }
}

export { SECTION_SEQUENCE, YES_NO_SECTION, isURL, renderString };
