export const actionTypes = {
  SET_IS_FETCHING_APPEAL_REQUESTS_REPORT: 'SET_IS_FETCHING_APPEAL_REQUESTS_REPORT',
  RECEIVED_APPEAL_REQUESTS_REPORT: 'RECEIVED_APPEAL_REQUESTS_REPORT',
  FAILED_APPEAL_REQUESTS_REPORT: 'FAILED_APPEAL_REQUESTS_REPORT'
}

export const fetchAppealRequestsReport_ac = params => {
  return dispatch => {
    dispatch(setIsFetching(true))

    window.sch
      .post('/api/appeal_requests/gen/list', params)
      .then(result => {
        dispatch({
          type: actionTypes.RECEIVED_APPEAL_REQUESTS_REPORT,
          report: {
            records: result.pkg.rr,
            pagination: result.pkg.pagination
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_APPEAL_REQUESTS_REPORT,
          error: true
        })
      })
      .finally(() => {
        dispatch(setIsFetching(false))
      })
  }
}

const setIsFetching = isFetching => {
  return {
    type: actionTypes.SET_IS_FETCHING_APPEAL_REQUESTS_REPORT,
    isFetching
  }
}
