import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchAllScriptsList_ac,
  fetchReferralDetails_ac,
} from '../../actions/all_scripts_report.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import dateUtil from '../../util/dateProcessing';
import FilterListing from '../FilterListing';
import { PaginationWithNums } from '../PaginationNew';
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent';
import Loader from '../Shared/Loader';
import { ReferralSearch } from './ReferralSearch';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 72px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  title: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: 0,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  tableScrollable: {
    minHeight: '180px',
    height: 'calc(100% - 66px)',
    overflowY: 'auto',
  },
  tableRowSelected: {
    backgroundColor: 'yellow',
  },
  tableHeader: {
    height: 40,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
    },
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
  },
});

const DEFAULT_FILTERS = {
  tsStart: {
    kind: 'date',
    lbl: 'Start Time',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false,
    includeTime: true,
  },
  tsEnd: {
    kind: 'date',
    lbl: 'End Time',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false,
    includeTime: true,
  },
};

class AllScriptsReportComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        ...DEFAULT_FILTERS,
        tsEnd: {
          ...DEFAULT_FILTERS.tsEnd,
          value: `${moment().add(1, 'days').format('MM-DD-YYYY')}`,
        },
      },
      filterSequence: ['tsStart', 'tsEnd'],
      selectedReferral: '',
      isSearching: false,
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.fetch = this.fetch.bind(this);
  }

  onPageChange = (newPage) => {
    const page = parseInt(newPage, 10);

    this.props.changePage(page);
  };

  viewReferralDetails = (referral) => {
    const { id } = referral;
    const params = {
      id,
    };
    this.props.fetchReferralDetails_ac(params);
    this.setState({
      selectedReferral: id,
    });
  };

  hasInvalidIDN = (idn) => {
    var selected_idn = idn.split(' ');
    return !dateUtil.isValidDate(selected_idn.pop());
  };

  componentDidMount() {
    return this.fetch(this.props.currentPage);
  }

  componentDidUpdate(prevProps) {
    const { allScriptsReportInView, currentPage } = this.props;
    if (
      allScriptsReportInView.isFetching === false &&
      !allScriptsReportInView.error &&
      allScriptsReportInView.records === null
    ) {
      return this.fetch();
    }

    if (
      allScriptsReportInView.pagination &&
      allScriptsReportInView.isFetching === false &&
      allScriptsReportInView.pagination.currentPageNum !== currentPage - 1 &&
      allScriptsReportInView.pagination.currentPageNum !== -1
    ) {
      return this.fetch(currentPage);
    }

    if (currentPage !== prevProps.currentPage) {
      return this.fetch(currentPage);
    }
  }

  fetch(page = 1) {
    const { filters } = this.state;

    let params = {
      page: page - 1,
    };

    if (filters.tsStart.value && dateUtil.isValidDate(filters.tsStart.value)) {
      params.tsStart = moment(filters.tsStart.value).valueOf();
    }

    if (filters.tsEnd.value && dateUtil.isValidDate(filters.tsEnd.value)) {
      params.tsEnd = moment(filters.tsEnd.value).valueOf();
    }

    return this.props.fetchAllScriptsList_ac(params);
  }

  onRadioFilterChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [name]: value,
      },
    });
  };

  applyFilters = () => {
    if (this.props.currentPage === 1) {
      return this.fetch();
    }

    this.props.changePage(1);
  };

  searchReferralFromStr = (params) => {
    this.setState({
      isSearching: true,
    });
    this.props.fetchAllScriptsList_ac(params, true);
  };

  resetSearch = () => {
    if (this.props.currentPage === 1) {
      this.fetch();
    } else {
      this.props.changePage(1);
    }

    this.setState({
      isSearching: false,
    });
  };

  render() {
    const { allScriptsReportInView, classes, currentPage } = this.props;
    const { filters, filterSequence, selectedFilters } = this.state;

    if (allScriptsReportInView.isFetching) {
      return (
        <>
          {this.state.isSearching && (
            <ReferralSearch
              classes={classes}
              onSearch={this.searchReferralFromStr}
              resetSearch={this.resetSearch}
            />
          )}

          <main className={classes.content}>
            <Loader message="...loading records" />
          </main>
        </>
      );
    }

    let filterListing = (
      <>
        <FilterListing
          filters={filters}
          selected={selectedFilters}
          filterSequence={filterSequence}
          applyFilters={this.applyFilters}
          onFilterChange={this.onFilterChange}
          onFilterClearField={this.onFilterClearField}
          onRadioFilterChange={this.onRadioFilterChange}
        />
        <ReferralSearch
          classes={classes}
          onSearch={this.searchReferralFromStr}
          resetSearch={this.resetSearch}
          isSearching={this.state.isSearching}
        />
      </>
    );

    if (allScriptsReportInView.records === null) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>...</h4>
        </div>
      );
    }

    if (allScriptsReportInView.error) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>....error fetching Records</h4>
        </div>
      );
    }

    if (!allScriptsReportInView.records.length) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>No records found.</h4>
        </div>
      );
    }

    /*
    const tblRowClass = {
      root: classes['tableRow'],
      selected: classes['tableRowSelected'],
    };
    */

    const { pagination: pg } = allScriptsReportInView;

    let pageSelector = '';
    let pageStatement = '';
    if (pg) {
      const tsStartStatement = filters.tsStart
        ? `from ${moment(filters.tsStart).format('MM-DD-YYYY')} `
        : null;
      pageStatement = [
        `Displaying 
          ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
        `Page ${currentPage} of ${
          pg.totalPagesAvailable
        } Currently filtered ${tsStartStatement}to ${moment(
          filters.tsEnd
        ).format('MM-DD-YYYY')}`,
      ].join('-');

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.onPageChange}
          />
        );
      }
    }

    return (
      <>
        {filterListing}
        <main className={classes.content}>
          <div className={classes.pagination}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography color="textSecondary">{pageStatement}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {pageSelector}
              </Grid>
            </Grid>
          </div>

          <div className={classes.tableScrollable}>
            <Table padding="dense">
              <TableHead padding="dense">
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" width="20%">
                    Referral
                  </TableCell>
                  <TableCell align="left" width="40%">
                    IDN
                  </TableCell>
                  <TableCell align="left" width="15%">
                    First Received
                  </TableCell>
                  <TableCell align="left" width="15%">
                    Last Activity
                  </TableCell>
                  <TableCell align="left" width="10%">
                    View / Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allScriptsReportInView.records.map((item, index) => {
                  let isSelected = this.state.selectedReferral === item.id;
                  return (
                    <TableRow key={index} selected={isSelected}>
                      <TableCell
                        align="left"
                        width="20%"
                        variant="body"
                        style={{ padding: '0px' }}>
                        {index + 1}) {item.id}
                      </TableCell>
                      <TableCell
                        align="left"
                        width="40%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <div style={{ fontSize: 11, fontWeight: 'bold' }}>
                          <a
                            style={{ color: '#000', textDecoration: 'none' }}
                            href={'/dfa/idn/' + item.idn}
                            target={'tab-' + item.idn}>
                            {item.idn}
                          </a>
                        </div>
                        <div style={{ fontSize: 11, color: 'blue' }}>
                          {item.files.length} file(s),{' '}
                          {
                            item.files.filter((f) => f.downloads.count > 0)
                              .length
                          }{' '}
                          imported
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="15%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <span style={{ fontSize: 10 }}>
                          {dateUtil.formatDate(
                            item.requests[0]?.dynamoKey?.timeReceived
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="15%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <span style={{ fontSize: 10 }}>
                          {dateUtil.formatDate(item.lastReceived)}
                        </span>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="10%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <Tooltip title="View Details">
                          <IconButton
                            disabled={this.hasInvalidIDN(item.idn)}
                            onClick={() => this.viewReferralDetails(item)}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </main>
      </>
    );
  }
}

AllScriptsReportComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  allScriptsReportInView: stateFromStore.allScriptsReportInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllScriptsList_ac,
      fetchReferralDetails_ac,
      open_snack_ac,
    },
    dispatch
  );

export const AllScriptsReport = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AllScriptsReportComponent))
);
