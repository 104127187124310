import { FormHelperText, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  sectionLbl: {
    fontSize: 11,
    color: '#9c27b0',
    fontWeight: 700,
    paddingTop: 12,
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200,
  },
  fullHeight: {
    height: '100%',
  },
});

class DTTools extends Component {
  constructor() {
    super();
    this.state = {
      msg: '',
      wos_ids: '',
    };
  }

  componentDidMount() {
    const { lookups, setTitle_ac } = this.props;
    if (!this.props.me.user) {
      return;
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return this.props.lookups_ac();
    }
    setTitle_ac('Work Order Tools');
  }

  componentDidUpdate() {
    const { lookups, me } = this.props;
    if (!me.user) return;
    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return this.props.lookups_ac();
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  retry_ppl = () => {
    const { wos_ids } = this.state;
    this.setState({
      msg: `..retrying paperless to DF submission (must be in ALLGOOD) for: ${wos_ids}`,
    });
    return window.sch
      .post(`/api/dt_etls/proxy/check`, { wos_ids })
      .then((result) => {
        this.setState({
          msg:
            typeof result.msg === 'string' ? result.msg : result.msg.join('\n'),
        });
      })
      .catch((error) => {
        this.setState({
          wos_ids: '',
          msg: error.message,
        });
      });
  };

  reproduce = () => {
    const { wos_ids } = this.state;
    this.setState({
      msg: `..resetting paperless status for re-rendering for: ${wos_ids}`,
    });
    window.sch
      .post(`/api/dt_etls/proxy/reproduce`, { wos_ids })
      .then((result) => {
        this.setState({
          msg:
            typeof result.msg === 'string' ? result.msg : result.msg.join('\n'),
        });
      })
      .catch((error) => {
        this.setState({
          wos_ids: '',
          msg: error.message,
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <main className={classes.content}>
        <Grid container spacing={8} direction="column">
          <Grid item xs={12} sm={12} md={12}>
            <FormControl
              style={{ marginLeft: 0 }}
              className={classes.formControl}
              fullWidth>
              <FormLabel component="label">
                <strong>Paste Order #s:</strong>
              </FormLabel>
              <TextField
                fullWidth
                variant="outlined"
                onChange={this.handleChange}
                placeholder="Comma or space separated"
                name="wos_ids"
                value={this.state.wos_ids || ''}
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <FormControl
                style={{ marginLeft: 0 }}
                className={classes.formControl}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={this.retry_ppl}>
                  Retry Paperless to DF
                </Button>
                <FormHelperText
                  style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }}>
                  This will retry the paperless submission to DF. The work
                  orders must have already reached ALLGOOD and NOT already
                  reached Docflow
                </FormHelperText>
              </FormControl>
              <FormControl
                style={{ marginLeft: 24 }}
                className={classes.formControl}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={this.reproduce}>
                  Reset for Paperless Render
                </Button>
                <FormHelperText
                  style={{ marginTop: 10, paddingTop: 10, paddingBottom: 10 }}>
                  This must be be done with caution - only for work orders where
                  ALLGODO has NOT been reached.
                </FormHelperText>
              </FormControl>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <pre style={{ fontSize: 12, padding: 4 }}>{this.state.msg}</pre>
          </Grid>
        </Grid>
      </main>
    );
  }
}

DTTools.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DTTools))
);
