import { open_snack_ac } from './snack.ac.js';

export const CommunicationPreferenceActionTypes = {
  COMMUNICATION_PREFERENCE_UPDATED: 'COMMUNICATION_PREFERENCE_UPDATED',
  COMMUNICATION_PREFERENCE_ADDED: 'COMMUNICATION_PREFERENCE_ADDED',
  COMMUNICATION_PREFERENCE_RECEIVED: 'COMMUNICATION_PREFERENCE_RECEIVED',
  FAILED_RECEIVING_COMMUNICATION_PREFERENCE:
    'FAILED_RECEIVING_COMMUNICATION_PREFERENCE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_FETCHING: 'SET_IS_PREFERENCE_FETCHING',
  SET_SELECTED_PREFERENCE: 'SET_SELECTED_PREFERENCE',
};

export const updateCommunicationPreference_ac = (params) => (dispatch) => {
  const url = '/api/admin/comm_mgt/account/comm_pref_update';
  dispatch(loading(true));
  return window.sch
    .post(url, { ...params })
    .then((result) => {
      if (result.error) {
        throw new Error(result.error);
      }
      dispatch(
        open_snack_ac({
          variant: 'success',
          message: result.message || 'Communication Preferences Updated',
        })
      );
      dispatch({
        type: CommunicationPreferenceActionTypes.COMMUNICATION_PREFERENCE_UPDATED,
        result: result,
      });
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Failed to update Communication preference',
        })
      );
    })
    .finally(() => {
      dispatch(loading(false));
    });
};
export const fetchCommunicationPreference_ac = (params) => (dispatch) => {
  const target_url = '/api/comm_pref/get';
  dispatch(fetching(true));
  return window.sch
    .post(target_url, { ...params })
    .then((result) => {
      if (result.error) {
        throw new Error(result.error);
      }
      dispatch({
        type: CommunicationPreferenceActionTypes.COMMUNICATION_PREFERENCE_RECEIVED,
        preferences: result.records,
      });
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Failed to fetch communication preferences',
        })
      );
      dispatch({
        type: CommunicationPreferenceActionTypes.FAILED_RECEIVING_COMMUNICATION_PREFERENCE,
        error,
      });
    })
    .finally(() => {
      dispatch(fetching(false));
    });
};
export const addCommunicationPreference_ac =
  (acctMember, application) => (dispatch) => {
    if (!acctMember) {
      return dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'Acct Member is missing',
        })
      );
    }
    const params = {
      acctMember: acctMember,
      client_app_id: application.nm,
      preference_names: (application.preferences || []).map((item) => item.nm),
      settings: _getPreferenceSettings(application.preferences),
      is_pending: true,
    };

    function _getPreferenceSettings(preferences = []) {
      return preferences.reduce((acc, item) => {
        acc[item.nm] = _getSettingsFromMethods(item.methods);
        return acc;
      }, {});
    }

    function _getSettingsFromMethods(methods = []) {
      return methods.reduce((acc, item) => {
        acc[item] = true;
        return acc;
      }, {});
    }

    const target_url = '/api/admin/comm_mgt/comm_pref/onboard';
    return window.sch
      .post(target_url, { ...params })
      .then((response) => {
        if (response.error) {
          throw new Error(response.error);
        }
        dispatch(
          open_snack_ac({
            variant: 'info',
            message: 'communication preferences added',
          })
        );
        dispatch({
          type: CommunicationPreferenceActionTypes.COMMUNICATION_PREFERENCE_ADDED,
          preference: response.result,
        });
      })
      .catch((error) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Failed to add communication preferences',
          })
        );
      });
  };

export const selectPreference_ac = (pref) => (dispatch) => {
  dispatch({
    type: CommunicationPreferenceActionTypes.SET_SELECTED_PREFERENCE,
    preference: pref,
  });
};

export const loading = (isLoading) => ({
  type: CommunicationPreferenceActionTypes.SET_IS_LOADING,
  isLoading,
});

export const fetching = (isFetching) => ({
  type: CommunicationPreferenceActionTypes.SET_IS_FETCHING,
  isFetching,
});
