export const confirmationLogActionTypes = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_SAVING: 'SET_IS_SAVING',
  SET_IS_FETCHING_CONFIRMATION_ISSUES: 'SET_IS_FETCHING_CONFIRMATION_ISSUES',
  RECEIVED_CONFIRMATION_LOG_LIST: 'RECEIVED_CONFIRMATION_LOG_LIST',
  FAILED_CONFIRMATION_LOG_LIST: 'FAILED_CONFIRMATION_LOG_LIST',
  RECEIVED_UPDATED_CONFIRMATION_LOG_LIST: 'RECEIVED_UPDATED_CONFIRMATION_LOG_LIST',
  RECEIVED_CONFIRMATION_ISSUES: 'RECEIVED_CONFIRMATION_ISSUES',
  FAILED_CONFIRMATION_ISSUES: 'FAILED_CONFIRMATION_ISSUES',
  STORE_FORM_DATA: 'STORE_FORM_DATA'
};

export function fetchConfirmationLogList_ac(url, params) {
  return (dispatch) => {
    dispatch(setIsFetching(true));

    window.sch.post(url, params)
      .then(response => {
        dispatch({
          type: confirmationLogActionTypes.RECEIVED_CONFIRMATION_LOG_LIST,
          record: response
        });
      })
      .catch(err => {
        dispatch({
          type: confirmationLogActionTypes.FAILED_CONFIRMATION_LOG_LIST,
          error: true
        });
      })
      .finally(() => {
        dispatch(setIsFetching(false));
      })
  }
}

export function fetchIssues_ac() {
  return (dispatch) => {
    dispatch(setIsFetchingIssues(true));

    window.sch.post('/api/conflog/issues/master', {})
      .then(response => {
        dispatch({
          type: confirmationLogActionTypes.RECEIVED_CONFIRMATION_ISSUES,
          record: response
        });
      })
      .catch(err => {
        dispatch({
          type: confirmationLogActionTypes.FAILED_CONFIRMATION_ISSUES,
          error: true
        });
      })
      .finally(() => {
        dispatch(setIsFetchingIssues(false));
      })
  }
}

export const addFormDataInStore_ac = (data) => {
  return (dispatch) => {
    dispatch({
      type: confirmationLogActionTypes.STORE_FORM_DATA,
      data
    });
  }
}

export function saveConfirmation_ac(params) {
  return (dispatch) => {
    dispatch(setIsSaving(true));

    window.sch.post('/api/conflog/save', params)
      .then(response => {
        dispatch({
          type: 'OPEN_SNACK',
          message: 'Saved confirmation entry successfully!',
          variant: 'success'
        });

        dispatch({
          type: confirmationLogActionTypes.RECEIVED_UPDATED_CONFIRMATION_LOG_LIST,
          record: response
        });
      })
      .catch(err => {
        dispatch({
          type: 'OPEN_SNACK',
          message: 'Failed to save confirmation entry!',
          variant: 'error'
        });
      })
      .finally(() => {
        dispatch(setIsSaving(false));
      })
  }
}

function setIsFetching(isFetching) {
  return {
    type: confirmationLogActionTypes.SET_IS_FETCHING,
    isFetching
  };
}

function setIsFetchingIssues(isFetching) {
  return {
    type: confirmationLogActionTypes.SET_IS_FETCHING_CONFIRMATION_ISSUES,
    isFetching
  };
}

function setIsSaving(isSaving) {
  return {
    type: confirmationLogActionTypes.SET_IS_SAVING,
    isSaving
  };
}
