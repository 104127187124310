import { Link, TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';

import Loader from '../Shared/Loader';

export const LoaderRow = (props) => {
  return (
    <TableRow>
      <TableCell colSpan={props.colSpan || 1} align="center">
        <Loader message="Loading..." />
      </TableCell>
    </TableRow>
  );
};

export const ErrorRow = (props) => {
  return (
    <TableRow>
      <TableCell colSpan={props.colSpan || 1} align="center">
        <Typography variant="inherit" color="error">
          Error Loading Oxygen Counts!
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export const EmptyRow = (props) => {
  return (
    <TableRow>
      <TableCell colSpan={props.colSpan || 1} align="center">
        <Typography variant="inherit" color="textSecondary">
          No Entries Found
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export const DataRow = (props) => {
  const { countData } = props;
  const wosIDs = countData.wos_ids || [];
  const wosText =
    wosIDs.length > 15 ? (
      <>
        {wosIDs.slice(0, 15).join(', ')}
        <strong>...({wosIDs.length - 15} More)</strong>
      </>
    ) : (
      wosIDs.join(', ')
    );

  return (
    <TableRow>
      <TableCell>{countData.prod}</TableCell>
      <TableCell align="right">{countData.qty_delivered}</TableCell>
      <TableCell align="right">{countData.qty_pickup}</TableCell>
      <TableCell align="right">{countData.qty_exchange}</TableCell>
      <TableCell align="right">{countData.line_count}</TableCell>
      <TableCell>
        {wosText}
        <br />
        {countData.dtrackWosLink ? (
          <Link
            href={countData.dtrackWosLink}
            target="_blank"
            rel="noopener"
            style={{ color: '#1976d2' }}>
            <strong>
              View {wosIDs.length} Order{wosIDs.length !== 1 ? 's' : ''}
            </strong>
          </Link>
        ) : (
          ''
        )}
      </TableCell>
    </TableRow>
  );
};
