import { WorkOrdersReportsActionTypes } from '../actions/work_orders_report_ac.js';

export const workOrdersReportInView = (
    state = {
        isFetching: false,
        records: null,
        error: null,
        wo_error: null,
        pagination: null,
        isFetchingWorkOrder: false,
        wo: null
    },
    action
) => {

    switch (action.type) {
        case WorkOrdersReportsActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case WorkOrdersReportsActionTypes.RECEIVED_WORK_ORDERS_REPORTS:
            return {
                ...state,
                records: action.records,
                pagination: action.pagination
            };

        case WorkOrdersReportsActionTypes.FAILED_RECEIVING_WORK_ORDERS_REPORTS:
            return {
                ...state,
                records: null,
                error: action.error,
                pagination: null
            };
        case WorkOrdersReportsActionTypes.SET_IS_FETCHING_WORK_ORDER:
            return {
                ...state,
                isFetchingWorkOrder: action.isFetchingWorkOrder,
            };

        case WorkOrdersReportsActionTypes.RECEIVED_WORK_ORDER:
            return {
                ...state,
                wo: action.wo,
            };

        case WorkOrdersReportsActionTypes.FAILED_RECEIVING_WORK_ORDER:
            return {
                ...state,
                wo: null,
                wo_error: action.error,
            };

        default:
            return state;
    }
};
