import {
  Card,
  Grid,
  TextField,
  CardHeader,
  IconButton,
  Typography
} from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import {
  Edit as EditIcon,
  Done as DoneIcon,
  Clear as ClearIcon,
  Cancel as CancelIcon,
  AddCircle as AddCircleIcon
} from '@material-ui/icons'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import RepSearchField from './RepSearchField'

export const AddButton = props => {
  const {
    disabled = false,
    onAdd,
    fontSize = 'small',
    style = {},
    className
  } = props

  return (
    <IconButton
      className={className}
      disabled={disabled}
      onClick={onAdd}
      style={{ padding: 8, ...style }}>
      <AddCircleIcon fontSize={fontSize} />
    </IconButton>
  )
}

const DoneButton = props => {
  const { disabled = false, onDone } = props

  return (
    <IconButton disabled={disabled} onClick={onDone} style={{ padding: 8 }}>
      <DoneIcon fontSize="small" />
    </IconButton>
  )
}

const CancelButton = props => {
  const { disabled = false, onCancel } = props

  return (
    <IconButton disabled={disabled} onClick={onCancel} style={{ padding: 8 }}>
      <CancelIcon fontSize="small" />
    </IconButton>
  )
}

export const PhoneField = props => {
  const { restrictAddition, withExtensionNumber } = props

  const phNumRef = useRef(null)
  const extNumRef = useRef(null)
  const areaCode2Ref = useRef(null)
  const [phNum, setPhNum] = useState('')
  const [extNum, setExtNum] = useState('')
  const [areaCode, setAreaCode] = useState('')
  const [areaCode2, setAreaCode2] = useState('')

  const handleAreaCodeChange = e => {
    // console.log(areaCode2Ref.current)
    const { value } = e.target

    if (value.length > 3 || isNaN(value)) return

    setAreaCode(value)

    if (value.length === 3) areaCode2Ref.current.focus()
  }
  const handleAreaCode2Change = e => {
    const { value } = e.target

    if (value.length > 3 || isNaN(value)) return

    setAreaCode2(value)

    if (value.length === 3) phNumRef.current.focus()
  }
  const handlePhNumChange = e => {
    const { value } = e.target

    if (value.length > 4 || isNaN(value)) return

    setPhNum(value)

    if (value.length === 4 && withExtensionNumber) extNumRef.current.focus()
  }
  const handleExtNumChange = e => {
    const { value } = e.target

    if (value.length > 5 || isNaN(value)) return
    setExtNum(value)
  }
  const resetPhoneNumberFields = () => {
    setAreaCode('')
    setAreaCode2('')
    setPhNum('')
    setExtNum('')
  }

  const handleAddPhoneNumber = () => {
    if (!allowToAddPhoneNum()) return

    let newPhoneNumber = ['1', areaCode, areaCode2, phNum].join('-')

    if (extNum.length > 0) newPhoneNumber += ` Ext. ${extNum}`

    props.onAdd(newPhoneNumber)
    resetPhoneNumberFields()
  }

  const allowToAddPhoneNum = () => {
    if (
      areaCode.length === 3 &&
      areaCode2.length === 3 &&
      phNum.length === 4 &&
      extNum.length <= 5
    )
      return true

    return false
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleAddPhoneNumber()
  }

  if (restrictAddition) return null

  return (
    <Grid container alignItems="baseline">
      <Typography variant="body1" inline>
        1&ensp;-
      </Typography>
      &ensp;
      <TextField
        type="text"
        margin="dense"
        value={areaCode}
        placeholder="XXX"
        style={{ width: 30 }}
        onKeyDown={handleKeyDown}
        onChange={handleAreaCodeChange}
      />
      &ensp;
      <Typography variant="body1" inline>
        -
      </Typography>
      &ensp;
      <TextField
        type="text"
        margin="dense"
        placeholder="XXX"
        value={areaCode2}
        style={{ width: 30 }}
        inputRef={areaCode2Ref}
        onKeyDown={handleKeyDown}
        onChange={handleAreaCode2Change}
      />
      &ensp;
      <Typography variant="body1" inline>
        -
      </Typography>
      &ensp;
      <TextField
        type="text"
        value={phNum}
        margin="dense"
        inputRef={phNumRef}
        placeholder="XXXX"
        style={{ width: 40 }}
        onKeyDown={handleKeyDown}
        onChange={handlePhNumChange}
      />
      &ensp;
      {withExtensionNumber && (
        <>
          <Typography variant="body1" inline>
            Ext.
          </Typography>
          &ensp;
          <TextField
            type="text"
            margin="dense"
            value={extNum}
            placeholder="XXXXX"
            inputRef={extNumRef}
            style={{ width: 50 }}
            onKeyDown={handleKeyDown}
            onChange={handleExtNumChange}
          />
          &ensp;
        </>
      )}
      <AddButton
        disabled={!allowToAddPhoneNum()}
        onAdd={handleAddPhoneNumber}
      />
    </Grid>
  )
}

export const EmailField = props => {
  const { restrictAddition } = props

  const [email, setEmail] = useState('')

  const handleEmailChange = e => {
    const { value } = e.target

    setEmail(value)
  }

  const handleAddEmail = () => {
    if (!allowToAddEmail()) return

    props.onAdd(email)
    setEmail('')
  }

  const allowToAddEmail = () => {
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    )
      return true

    return false
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleAddEmail()
  }

  if (restrictAddition) return null

  return (
    <Grid container alignItems="baseline">
      <TextField
        margin="dense"
        type="text"
        placeholder="abc123@example.com"
        value={email}
        onKeyDown={handleKeyDown}
        onChange={handleEmailChange}
      />
      &ensp;
      <AddButton disabled={!allowToAddEmail()} onAdd={handleAddEmail} />
    </Grid>
  )
}

export const URLField = props => {
  const { restrictAddition } = props

  const [url, setURL] = useState('')

  const handleURLChange = e => {
    const { value } = e.target

    setURL(value)
  }

  const handleAddURL = () => {
    if (!allowToAddURL()) return

    props.onAdd(encodeURIComponent(url.replace(/^(http:\/\/|https:\/\/)/, '')))
    setURL('')
  }

  const allowToAddURL = () => {
    let urlRegexp = new RegExp(
      '((http|https)://){0,1}([a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*))',
      'i'
    )
    if (urlRegexp.test(url)) return true
    // /^(http:\/\/|https:\/\/){0,1}(www\.){0,1}(.){3,50}(\.){1}[a-z]{2,3}(.)*$/
    return false
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleAddURL()
  }

  if (restrictAddition) return null

  return (
    <Grid container alignItems="baseline">
      <TextField
        margin="dense"
        type="text"
        placeholder="somelink.com"
        value={url}
        onKeyDown={handleKeyDown}
        onChange={handleURLChange}
      />
      &ensp;
      <AddButton disabled={!allowToAddURL()} onAdd={handleAddURL} />
    </Grid>
  )
}

export const TagField = props => {
  const { restrictAddition } = props

  const [tag, setTag] = useState('')

  const handleTagChange = e => {
    const { value } = e.target

    setTag(value)
  }

  const handleAddTag = () => {
    if (!allowToAddTag()) return

    props.onAdd(tag)
    setTag('')
  }

  const allowToAddTag = () => {
    if (tag.length >= 3) return true

    return false
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') handleAddTag()
  }

  if (restrictAddition) return null

  return (
    <Grid container alignItems="flex-start">
      <TextField
        margin="dense"
        type="text"
        placeholder="Enter tag"
        value={tag}
        onKeyDown={handleKeyDown}
        onChange={handleTagChange}
        helperText="Min 3 characters required"
      />
      &ensp;
      <AddButton disabled={!allowToAddTag()} onAdd={handleAddTag} />
    </Grid>
  )
}

export const RepsField = props => {
  const DATE_FORMAT = 'MM-DD-YYYY'

  const { reps, editMode = true } = props

  const [editRepIdx, setEditRepIdx] = useState(null)
  const [selectedRep, setSelectedRep] = useState(null)

  useEffect(() => {
    if (editMode) {
      setEditRepIdx(null)
      setSelectedRep(null)
    }
  }, [editMode])

  const handleRepAdd = () => {
    if (!isFormValid()) return

    let rep = {
      ...selectedRep,
      rep_id: selectedRep._id,
      s: selectedRep.startDate || null,
      e: selectedRep.endDate || null
    }

    props.onRepAdd(
      rep,
      null,
      () => {
        setSelectedRep(null)
      } // success callback
    )
  }

  const handleRepEdit = (rep, repIdx) => {
    setEditRepIdx(repIdx)
    handleRepSelect(rep)
  }

  const handleRepEditSave = () => {
    if (editRepIdx === null || selectedRep === null) return

    if (!isFormValid()) return

    let newRep = {
      ...selectedRep,
      rep_id: selectedRep.rep_id || selectedRep._id,
      s: selectedRep.startDate || null,
      e: selectedRep.endDate || null
    }

    props.onRepUpdate(newRep, editRepIdx, () => {
      // success callback
      setSelectedRep(null)
      setEditRepIdx(null)
    })
  }

  const handleDateChange = e => {
    const { name, value } = e.target

    if (name === 'startDate')
      setSelectedRep(rep => ({
        ...rep,
        startDate: value
      }))
    else
      setSelectedRep(rep => ({
        ...rep,
        endDate: value
      }))
  }

  const isFormValid = () => {
    if (
      selectedRep.startDate !== '' &&
      !moment(selectedRep.startDate, DATE_FORMAT).isValid()
    )
      return false

    if (
      selectedRep.endDate !== '' &&
      !moment(selectedRep.endDate, DATE_FORMAT).isValid()
    )
      return false

    return true
  }

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      if (editRepIdx === null) return handleRepAdd()

      handleRepEditSave()
    }
  }

  const handleRepSelect = rep => {
    setSelectedRep({
      rep_id: rep._id || rep.rep_id,
      startDate: rep.s ? moment(rep.s).format(DATE_FORMAT) : '',
      endDate: rep.e ? moment(rep.e).format(DATE_FORMAT) : '',
      ...rep
    })
  }

  const handleRepCancel = () => {
    setEditRepIdx(null)
    setSelectedRep(null)
  }

  return (
    <div style={{ width: '100%', margin: '4px 0px' }}>
      <Grid container>
        {reps?.length === 0 && (
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ margin: '12px 0px' }}>
            {'No reps added yet'}
          </Typography>
        )}
        {reps &&
          reps.length > 0 &&
          reps.map((rep, idx) => (
            <Card
              key={idx}
              elevation={4}
              style={{ margin: 4, backgroundColor: orange['100'] }}>
              <CardHeader
                titleTypographyProps={{
                  variant: 'body2'
                }}
                subheaderTypographyProps={{
                  variant: 'caption'
                }}
                action={
                  editMode ? (
                    <>
                      <IconButton
                        style={{ padding: 8 }}
                        disabled={editRepIdx === idx}
                        onClick={() => handleRepEdit(rep, idx)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        style={{ padding: 8 }}
                        disabled={editRepIdx === idx}
                        onClick={() => props.onRepDelete(idx, rep)}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : null
                }
                title={
                  rep.first_name && rep.last_name
                    ? [rep.first_name, rep.last_name].join(' ')
                    : rep.display_name || rep.rep_id
                }
                subheader={
                  <>
                    {rep.s
                      ? `From ${moment(rep.s).format(DATE_FORMAT)} `
                      : null}
                    {rep.e ? `To ${moment(rep.e).format(DATE_FORMAT)}` : null}
                  </>
                }
              />
            </Card>
          ))}
      </Grid>
      {editMode &&
        (selectedRep ? (
          <Grid container alignItems="baseline">
            <Typography variant="body2">
              {[selectedRep.first_name, selectedRep.last_name].join(' ')}&ensp;
            </Typography>
            <TextField
              margin="dense"
              name="startDate"
              label="Start Date"
              placeholder={DATE_FORMAT}
              onKeyDown={handleKeyDown}
              onChange={handleDateChange}
              value={selectedRep.startDate}
            />
            &ensp;
            <TextField
              margin="dense"
              name="endDate"
              label="End Date"
              placeholder={DATE_FORMAT}
              onKeyDown={handleKeyDown}
              onChange={handleDateChange}
              value={selectedRep.endDate}
            />
            &ensp;
            {editRepIdx === null ? (
              <AddButton disabled={!isFormValid()} onAdd={handleRepAdd} />
            ) : (
              <DoneButton
                disabled={!isFormValid()}
                onDone={handleRepEditSave}
              />
            )}
            <CancelButton onCancel={handleRepCancel} />
          </Grid>
        ) : (
          <RepSearchField
            label={null}
            placeholder={props.placeholder || 'Search Rep By Name'}
            selectRep={rep => handleRepSelect(rep)}
          />
        ))}
    </div>
  )
}
