import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { open_snack_ac } from '../../actions/snack.ac';
import {
  fetchUserProfiles_ac,
  activateUserProfile_ac,
} from '../../actions/user_profiles.ac';
import Loader from '../Shared/Loader';
import {
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  Popover,
  Button,
  Tooltip,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

const ProfileControl = (props) => {
  const { user = {}, userProfiles, mobileView = false } = props;
  const {
    profiles: { options: profiles, isFetching: isFetchingProfiles } = {},
    isActivatingProfile,
  } = userProfiles;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleControlOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleControlClose = () => {
    setAnchorEl(null);
  };

  const activeProfileId = user.active_profile_id;

  const [selectedProfile, setSelectedProfile] = React.useState(activeProfileId);
  const selectedProfileName = profiles.find(
    (profile) => profile._id === selectedProfile
  )?.name;

  React.useEffect(() => {
    setSelectedProfile(activeProfileId);
  }, [activeProfileId]);

  React.useEffect(() => {
    if (selectedProfile === activeProfileId) {
      return;
    }
    props.activateUserProfile(selectedProfile, {
      onSuccess: () => {
        props.openSnack({
          message: 'Profile activated successfully',
          variant: 'success',
        });
      },
      onError: () => {
        props.openSnack({
          message: 'Failed to activate profile',
          variant: 'error',
        });
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProfile]);

  React.useEffect(() => {
    props.fetchUserProfiles(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProfileChange = (e) => {
    setSelectedProfile(e.target.value);
    handleControlClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile_control' : undefined;

  return isFetchingProfiles ? (
    <Loader type="circular" />
  ) : (
    <div>
      <Tooltip title={'Switch between tailored profiles'} placement="bottom">
        <div>
          <Button
            variant="text"
            aria-describedby={id}
            color="inherit"
            onClick={handleControlOpen}>
            <AccountCircle />{' '}
            {!mobileView && (
              <span style={{ marginLeft: 4, fontSize: 10, minWidth: '50px' }}>
                {isActivatingProfile ? 'Updating' : selectedProfileName}
              </span>
            )}
          </Button>
        </div>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleControlClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <FormControl style={{ padding: '16px' }}>
          <FormLabel htmlFor={'user_profile'}>{'Set Active profile'}</FormLabel>
          <TextField
            select
            variant="outlined"
            margin="dense"
            id={'user_profile'}
            name={'user_profile'}
            onChange={handleProfileChange}
            disabled={isFetchingProfiles || isActivatingProfile}
            value={selectedProfile}
            style={{ minWidth: 250 }}>
            {(profiles || []).map((profile, idx) => (
              <MenuItem key={idx} value={profile._id}>
                {profile.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userProfiles: state.userProfiles,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSnack: open_snack_ac,
      fetchUserProfiles: fetchUserProfiles_ac,
      activateUserProfile: activateUserProfile_ac,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProfileControl);
