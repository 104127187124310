import AppBar from '@material-ui/core/AppBar'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { push } from 'connected-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchpaErrorsList_ac,
  fetchPaErrorStats_ac
} from '../../actions/pa_errors_list.ac.js'
import PaErrors from './PaErrors'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  }
})

function TabEntry(props) {
  return (
    <Typography component="div" style={{ padding: 8 }}>
      {props.children}
    </Typography>
  )
}

TabEntry.propTypes = {
  children: PropTypes.node.isRequired
}

class TabsPaErrors extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: this.props.payerGroup,
      selectedKind: this.props.kind,
      selectedDate: moment().format('MM-DD-YYYY')
    }
  }

  componentDidMount() {
    let tabToApply = this.props.match.params.payerGroup
    let kindToApply = this.props.match.params.kind
    let dateToApply = this.props.match.params.dateFilter

    if (
      tabToApply !== this.state.selectedTab ||
      kindToApply !== this.state.selectedKind ||
      dateToApply !== this.state.selectedDate
    ) {
      this.setState({
        selectedTab: tabToApply,
        selectedKind: kindToApply,
        selectedDate: dateToApply
      })
      this.props.fetchpaErrorsList_ac(tabToApply, kindToApply, dateToApply)
      //this.props.fetchPaErrorStats_ac(tabToApply, kindToApply, dateToApply)
    }
  }

  componentDidUpdate(prevProps) {
    let tabToApply = this.props.match.params.payerGroup
    let kindToApply = this.props.match.params.kind
    let dateToApply = this.props.match.params.dateFilter

    if (
      tabToApply !== this.state.selectedTab ||
      kindToApply !== this.state.selectedKind ||
      dateToApply !== this.state.selectedDate
    ) {
      this.setState({
        selectedTab: tabToApply,
        selectedKind: kindToApply,
        selectedDate: dateToApply
      })
      this.props.fetchpaErrorsList_ac(tabToApply, kindToApply, dateToApply)
      //this.props.fetchPaErrorStats_ac(tabToApply, kindToApply, dateToApply)
    }
  }

  handleChange = (event, selectedTab) => {
    this.props.changePage(
      selectedTab,
      this.state.selectedKind,
      this.state.selectedDate
    )
    return
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.selectedTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto">
            <Tab value="ccs" label={'CCS ' + this.state.selectedDate} />
            <Tab
              value="medical"
              label={'Medi-Cal ' + this.state.selectedDate}
            />
            <Tab value="cap" label={'Private CAP ' + this.state.selectedDate} />
            <Tab
              value="non_cap"
              label={'Private NON-CAP ' + this.state.selectedDate}
            />
            <Tab value="all" label={'ALL ' + this.state.selectedDate} />
          </Tabs>
        </AppBar>
        <PaErrors />
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  payerGroup: stateFromStore.paErrorsList.payerGroup
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchpaErrorsList_ac,
      fetchPaErrorStats_ac,
      changePage: (_selTab, _selKind, _dt) =>
        push('/pa_errors/' + _selTab + '/' + _selKind + '/' + _dt)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TabsPaErrors))
)
