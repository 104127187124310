export function fetchExpiringPAStats_ac(payer_types) {
  //params passed in by thunk middleware
  return (dispatch, getState) => {
    dispatch({ type: 'REQUEST_EXPIRING_PA_STATS' });
    return window.sch
      .post(`/api/expiring-pas-mco/stats`, { payer_types })
      .then((result) => {
        if (result.partial_only) {
          let _stats = getState().expiringPAs.stats;
          dispatch({
            type: 'RECEIVED_EXPIRING_PA_STATS',
            stats: {
              ..._stats,
              ...result.partial,
            },
          });
        }

        dispatch({
          type: 'RECEIVED_EXPIRING_PA_STATS',
          stats: result.stats,
        });
      })
      .catch((statsError) => {
        dispatch({
          type: 'FAILED_EXPIRING_PA_STATS',
          statsError,
        });
      });
  };
}

/*
function fetchExpiringPAStatsPartial_ac(){
  return (dispatch, getState) => {
    return window.sch.get(`/api/expiring-pas-mco/stats_partial`)
    .then( result => {
      let _stats = getState().expiringPAs.stats;
      dispatch({
        type: 'RECEIVED_EXPIRING_PA_STATS',
        stats: {
          ..._stats,
          ...result.partial
        }
      })
    })
    .catch(statsError=> {

    });
  }
}
*/

export function fetchExpiringPAs_ac(params) {
  //params passed in by thunk middleware
  return (dispatch) => {
    dispatch({ type: 'REQUEST_EXPIRING_PAS_LIST' });
    return window.sch
      .post(`/api/expiring-pas-mco/list`, params)
      .then((result) => {
        dispatch({
          type: 'RECEIVED_EXPIRING_PAS_LIST',
          records: result.rr,
          pagination: result.pagination,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FAILED_RECEIVED_EXPIRING_PAS_LIST',
          error,
        });
      });
  };
}

export function markExpiringPA_ac(params) {
  //params passed in by thunk middleware
  return (dispatch, getState) => {
    dispatch({ type: 'EXPIRING_PA_ACTIVITY', posOfActivity: params.posInSet });

    let _ep = getState().expiringPAs;
    return window.sch
      .post(`/api/expiring-pas-mco/update`, params)
      .then((result) => {
        _ep.records[params.posInSet] = result.updatedRecord;
        dispatch({
          type: 'EXPIRING_PA_ACTIVITY_DONE',
          records: _ep.records,
        });
        return window.sch.post(`/api/expiring-pas-mco/stats_partial`, {
          payer_types: params.payer_types,
        });
      })
      .then((result) => {
        let _stats = getState().expiringPAs.stats;
        dispatch({
          type: 'RECEIVED_EXPIRING_PA_STATS',
          stats: {
            ..._stats,
            ...result.partial,
          },
        });
      })
      .catch(() => {
        if (_ep.records[params.posInSet]) {
          _ep.records[params.posInSet].msg = 'Failed to update record';
        }
        dispatch({
          type: 'EXPIRING_PA_ACTIVITY_DONE',
          records: _ep.records,
        });
      });
  };
}

export function fetchExpiringPAsReport_ac(params) {
  //params passed in by thunk middleware
  return (dispatch) => {
    dispatch({ type: 'REQUEST_EXPIRING_PA_REPORT' });
    return window.sch
      .post(`/api/expiring-pas-mco/report`, params)
      .then((result) => {
        dispatch({
          type: 'RECEIVED_EXPIRING_PA_REPORT',
          pkg: result.pkg,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'FAILED_EXPIRING_PA_REPORT',
          error,
        });
      });
  };
}
