import classnames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

import Loader from '../Shared/Loader';
import { readFileAsBlob } from '../../util/image';
import { MdDeleteOutline } from 'react-icons/md';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  label: {
    color: 'grey',
    marginBottom: '0px',
  },
  initialLabel: {
    marginTop: '19%',
  },
  image: {
    maxWidth: '100%',
  },
  selectedImageContainer: {
    position: 'relative',
    maxWidth: '100%',
  },
  actionButton: {
    position: 'absolute',
    bottom: '5px',
    right: '5px',
    backgroundColor: 'whitesmoke',
    borderRadius: '50%',
    border: '1px solid #ccc',
    cursor: 'pointer',
  },
  selectedImage: {},
  crossButton: {
    '&:hover': {
      opacity: '1',
      background: 'black',
    },
    opacity: '0.7',
    position: 'absolute',
    color: 'red',
    background: 'black',
    padding: '8px',
    top: '-10px',
    right: '-10px',
  },
  dropzone: {
    margin: '10px 6px 10px -8px',
    border: '2px dashed #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
    minHeight: '160px',
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: '20px',
  },
  imagedDropZone: {
    minHeight: '100px',
  },
});

const DropImageInput = (props) => {
  const {
    multiple = false,
    onSelect,
    style,
    actionButton,
    scrollConfig = null,
    dimCapture = false,
    allowRemoval = true,
    disabled,
    text,
    classes,
  } = props;

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null); // [File, setFile]
  const [imgDim, setImgDim] = React.useState(null);
  const [loadingBlob, setLoadingBlob] = React.useState(false);

  const loadImage = useCallback(
    async (file) => {
      if (loadingBlob) return;
      setLoadingBlob(true);
      const blob = await readFileAsBlob(file);
      setSelectedImage(blob);
      setLoadingBlob(false);
    },
    // eslint-disable-next-line
    [loadingBlob]
  );

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length === 0 || acceptedFiles.length > 1) return;
      await loadImage(acceptedFiles[0]);
      if (dimCapture) {
        setSelectedFile(acceptedFiles[0]);
      } else {
        return onSelect(acceptedFiles[0]);
      }
    },

    // eslint-disable-next-line
    [loadImage]
  );

  useEffect(() => {
    if (dimCapture && selectedFile && imgDim) {
      onSelect(selectedFile, imgDim);
    }

    // eslint-disable-next-line
  }, [dimCapture, selectedFile, imgDim]);

  const onDropRejected = (...args) => {
    // open for extension
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    disabled,
    accept: 'image/jpeg,image/jpg,image/png',
  });

  const handleCrossClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedImage(null);
    setSelectedFile(null);
    setImgDim(null);
    onSelect(null);
  };

  return (
    <div
      {...getRootProps()}
      className={classnames(classes.dropzone, {
        [classes.imagedDropZone]: selectedImage,
      })}
      style={style}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className={classes.label}>{`Drop ${
          multiple ? 'them' : 'it'
        } here ...`}</p>
      ) : (
        <>
          {loadingBlob ? (
            <Loader />
          ) : (
            selectedImage && (
              <div
                className={classes.selectedImageContainer}
                style={
                  scrollConfig
                    ? {
                        maxHeight: scrollConfig.maxHeight || '100%',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                      }
                    : {}
                }>
                {' '}
                <img
                  src={selectedImage}
                  onLoad={(e) => {
                    if (!dimCapture) return;
                    setImgDim([
                      e.target.naturalWidth || 0,
                      e.target.naturalHeight || 0,
                    ]);
                  }}
                  style={
                    scrollConfig
                      ? {
                          width: '100%',
                          height: 'auto',
                        }
                      : {}
                  }
                  className={classes.image}
                  alt="Selected product"
                />
                {actionButton && (
                  <div
                    className={classes.actionButton}
                    style={
                      scrollConfig
                        ? {
                            position: 'sticky',

                            width: 'fit-content',
                          }
                        : {}
                    }>
                    {actionButton}
                  </div>
                )}
                {allowRemoval && (
                  <IconButton
                    onClick={handleCrossClick}
                    className={classes.crossButton}
                    style={
                      scrollConfig
                        ? {
                            top: '5px',
                            right: '5px',
                          }
                        : {}
                    }>
                    <MdDeleteOutline size={18} />
                  </IconButton>
                )}
              </div>
            )
          )}
          <p
            className={classnames(classes.label, {
              [classes.initialLabel]: !selectedImage,
            })}>
            {text ||
              (selectedImage
                ? 'Click the upload icon to save the image'
                : "Drag 'n' drop the image here, or simply click here and select one")}
          </p>
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(DropImageInput);
