import { open_snack_ac } from './snack.ac.js'

export const MappingToolActionTypes = {
  RECEIVED_PNG_TEMPLATES: 'RECEIVED_PNG_TEMPLATES',
  FAILED_RECEIVING_PNG_TEMPLATES: 'FAILED_RECEIVING_PNG_TEMPLATES',
  RECEIVED_DRAFTS: 'RECEIVED_DRAFTS',
  FAILED_RECEIVING_DRAFTS: 'FAILED_RECEIVING_DRAFTS',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_FETCHING_DRAFT: 'SET_IS_FETCHING_DRAFT',
  SET_IS_SAVING: 'SET_IS_SAVING',
  DRAFT_SAVED: 'DRAFT_SAVED',
  DRAFT_SAVING_FAILED: 'DRAFT_SAVING_FAILED',
  SET_IS_DEPLOYING: 'SET_IS_DEPLOYING',
  DRAFT_DEPLOYED: 'DRAFT_DEPLOYED',
  DRAFT_DEPLOYING_FAILED: 'DRAFT_DEPLOYING_FAILED'
}

export const saveAsDraft_ac = params => dispatch => {
  dispatch(saving(true))
  window.sch
    .post('/api/mappingtool/ppls/drafts/save', { ...params })
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: MappingToolActionTypes.DRAFT_SAVED,
        draft: params.tpl_as_object,
        tpl_id_page_pos: params.tpl_id_page_pos,
        draftId: params.draft_id
      })
      dispatch(
        open_snack_ac({
          variant: `info`,
          message: `Saved as Draft`
        })
      )
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error saving as draft'
        })
      )
    })
    .finally(() => {
      dispatch(saving(false))
    })
}

export const fetchAllTemplates_ac = params => dispatch => {
  dispatch(fetching(true))

  window.sch
    .post('/api/mappingtool/ppls/to_work_folders', { ...params })
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: MappingToolActionTypes.RECEIVED_PNG_TEMPLATES,
        existing_tpls: result.existing_tpls,
        jobs: result.jobs
      })
    })
    .catch(error => {
      dispatch({
        type: MappingToolActionTypes.FAILED_RECEIVING_PNG_TEMPLATES,
        error
      })
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const fetchDrafts_ac = params => dispatch => {
  dispatch(fetchingDraft(params.tpl_id + '_' + params.page_pos, true))

  window.sch
    .post('/api/mappingtool/ppls/drafts/list', { ...params })
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: MappingToolActionTypes.RECEIVED_DRAFTS,
        tpl_id_page_pos: params.tpl_id + '_' + params.page_pos,
        drafts: result.drafts
      })
    })
    .catch(error => {
      console.error(error)
      dispatch({
        type: MappingToolActionTypes.FAILED_RECEIVING_DRAFTS,
        error
      })
    })
    .finally(() => {
      dispatch(fetchingDraft(params.tpl_id + '_' + params.page_pos, false))
    })
}

export const deployDraft_ac = params => dispatch => {
  dispatch(deploying(true))
  window.sch
    .post('/api/mappingtool/ppls/drafts/deploy', { ...params })
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: MappingToolActionTypes.DRAFT_DEPLOYED,
        draftId: params.draft_id
      })
      dispatch(
        open_snack_ac({
          variant: `info`,
          message: `Draft Deployed`
        })
      )
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error deploying draft'
        })
      )
    })
    .finally(() => {
      dispatch(saving(false))
    })
}

const fetching = isFetching => ({
  type: MappingToolActionTypes.SET_IS_FETCHING,
  isFetching
})

const fetchingDraft = (tpl_id_page_pos, isFetchingDraft) => ({
  type: MappingToolActionTypes.SET_IS_FETCHING_DRAFT,
  isFetchingDraft,
  tpl_id_page_pos
})

const saving = isSaving => ({
  type: MappingToolActionTypes.SET_IS_SAVING,
  isSaving
})

const deploying = isDeploying => ({
  type: MappingToolActionTypes.SET_IS_DEPLOYING,
  isDeploying
})
