export function hdmsAccountInsurance(
  state={
    isFetching:false,
    idn: null,
    titleMsg: null,
    error: null,
    pkg: null
  },
  action
){
  switch (action.type) {
    case 'REQUEST_HDMS_ACCT_INSURANCE':
      return Object.assign({}, state, {
        idn: action.idn,
        accounts: null,
        error: null,
        isFetching: true
      })
    case 'FAILED_RECEIVED_HDMS_ACCT_INSURANCE':
      return Object.assign({}, state, {
        accounts: null,
        error: action.error,
        titleMsg: null,
        isFetching: false
      })
    case 'RECEIVED_HDMS_ACCT_INSURANCE':
      return Object.assign({}, state, {
         accounts: action.accounts,
         error: null,
         titleMsg: action.titleMsg,
         isFetching: false
       })

    case 'RESET_HDMS_ACCT_INSURANCE':
      return Object.assign({}, state, {
         idn: null,
         accounts: null,
        titleMsg: null,
         error: null,
         isFetching: false
       })

    default:
      return state
  }
}
