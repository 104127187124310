/* eslint-disable react/jsx-filename-extension */
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import ProductItem from '../ProductItem'
import ProductRowHeader from '../ProductItem/ProductRowHeader'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    // padding: theme.spacing.unit,
    textAlign: 'left',
    color: theme.palette.text.secondary,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap'
  },
  paperHeader: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: 'none',
    borderRadius: '2px'
  },
  fullHeight: {
    height: '100%'
  }
})

const ProductListing = props => {
  const { classes, isCatalogueMaintainer, configView = false } = props
  const { products, display_as, isMaintainance } = props

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.fullHeight}>
      {display_as === 'row' && (
        <ProductRowHeader
          recordCount={products.length}
          isCatalogueMaintainer={isCatalogueMaintainer}
        />
      )}
      <div className={classes.main}>
        {products.map((product, idx) => (
          <ProductItem
            key={product._id}
            isCatalogueMaintainer={isCatalogueMaintainer}
            configView={configView}
            display_as={display_as}
            product={product}
            rowColorIndex={idx % 2}
            isMaintainance={isMaintainance}
          />
        ))}
      </div>
    </Grid>
  )
}

ProductListing.propTypes = {
  classes: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  display_as: PropTypes.string.isRequired
}

export default withStyles(styles)(ProductListing)
