import React from 'react'

import { ListItem, ListItemText, Typography } from '@material-ui/core'

const Loading = () => {
  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <Typography variant="h5" color="textSecondary" align="center">
              Loading...
            </Typography>
          }
        />
      </ListItem>
    </>
  )
}

export default Loading
