import { IconButton, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Clear as ClearIcon, Error as ErrorIcon } from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { defaultTheme } from 'react-autosuggest/dist/theme';

import Loader from './Loader';
import LocalPopper from './LocalPopper';

const styles = (theme) => ({
  searchIndicator: {
    height: 20,
    width: 20,
  },
  smallIconButton: {
    padding: 4,
  },
  suggestionsContainerOpen: {
    maxHeight: '250px',
    overflowY: 'auto',
    margin: '2px 0',
  },
  suggestionsList: { paddingLeft: 0, margin: 0 },
  suggestion: {
    listStyleType: 'none',
    padding: 8,
    fontSize: 12,
    marginBottom: 2,
    cursor: 'pointer',
  },
  suggestionHighlighted: {
    backgroundColor: 'rgba(20, 20, 20, 0.20)',
  },
});

/**
 * Component to show the search results in a dropdown
 * @param {object} props props
 * @param {React.CSSProperties} props.style style
 * @returns
 */
const SearchField = (props) => {
  const {
    delay,
    style,
    label,
    overrideClasses,
    globalOptions,
    classes,
    disabled,
    minChars,
    autoFocus,
    apiMethod,
    fullWidth,
    helperText,
    resultsKey,
    apiEndpoint,
    onCrossClick,
    placeholder,
    clearOnSelect,
    renderSuggestion,
    selectSuggestion,
    scrollableParentId,
    search: propSearch,
    onSuggestionsReceived,
  } = props;

  const popperAnchor = useRef(null);
  const [searchStr, setSearchStr] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const delaySearch = useCallback(
    _.debounce((s) => {
      search(s);
    }, delay),
    []
  );

  useEffect(() => {
    if (searchResults.length > 0 && searchStr.length < minChars) {
      return setSearchResults([]);
    }
  }, [searchResults, searchStr, minChars]);

  const _localSearch = (str) => {
    if (!globalOptions || !globalOptions.length) {
      return [];
    }

    const results = globalOptions.filter((o) =>
      o.label.toLowerCase().includes(str.toLowerCase())
    );
    return results;
  };

  const search = (searchStrArg = '') => {
    if (searchStrArg.length < minChars) return;

    const searchFunction =
      typeof propSearch === 'function' ? propSearch : window.sch[apiMethod];

    const _localResults = _localSearch(searchStrArg);
    if (_localResults.length > 0) {
      setSearchResults(_localResults);
      return;
    }
    setIsSearching(true);
    searchFunction(apiEndpoint, { searchStr: searchStrArg })
      .then((res) => {
        setSearchResults(resultsKey ? _.get(res, resultsKey, []) : res);
        if (typeof onSuggestionsReceived === 'function') {
          onSuggestionsReceived(resultsKey ? _.get(res, resultsKey, []) : res);
        }
        setSearchError(false);
      })
      .catch((err) => {
        setSearchError(true);
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  const getSearchStateIndicator = () => {
    let indicator = '';

    if (isSearching) indicator = <Loader type="circular" size="15" />;
    else if (searchError)
      indicator = <ErrorIcon fontSize="small" color="error" />;

    return <span className={classes.searchIndicator}>{indicator}</span>;
  };

  const handleChange = (event, { newValue }) => {
    setSearchStr(newValue);
  };

  const handleSuggestionSelect = (event, { suggestion, method }) => {
    if (suggestion && (method === 'enter' || method === 'click')) {
      selectSuggestion(suggestion);

      if (clearOnSelect) {
        setSearchStr('');
        handleSuggestionsClear();
      }
    }
  };

  const handleSuggestionsClear = () => {
    setSearchResults([]);
    setSearchError(false);
  };

  const renderInputComponent = (inputProps) => {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        margin="dense"
        inputRef={(node) => {
          ref(node);
          inputRef(node);
        }}
        {...other}
      />
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <LocalPopper
        anchorEl={popperAnchor.current}
        open={true}
        placement="bottom-start"
        localParentId={scrollableParentId || ''}
        style={{
          width: popperAnchor.current
            ? popperAnchor.current.clientWidth
            : '250px',
          zIndex: 1400,
        }}>
        <Paper elevation={4} {...containerProps}>
          {children}
        </Paper>
      </LocalPopper>
    );
  };

  const shouldRenderSuggestions = (currentSearchStr) => {
    return currentSearchStr.trim().length >= minChars;
  };

  const handleFetchRequest = ({ value, reason }) => {
    delaySearch(value || '');
  };

  return (
    <div style={{ display: fullWidth ? 'block' : 'inline-block', ...style }}>
      <Autosuggest
        theme={{ ...defaultTheme, ...classes, ...overrideClasses }}
        suggestions={searchResults}
        onSuggestionsFetchRequested={handleFetchRequest}
        onSuggestionsClearRequested={handleSuggestionsClear}
        onSuggestionSelected={handleSuggestionSelect}
        getSuggestionValue={() => searchStr}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={{
          autoFocus,
          fullWidth,
          value: searchStr,
          placeholder,
          label,
          inputRef: (node) => {
            popperAnchor.current = node;
          },
          helperText: helperText,
          disabled: disabled,
          InputProps: {
            endAdornment: (
              <>
                {getSearchStateIndicator()}
                <IconButton
                  onClick={() => {
                    setSearchStr('');
                    if (onCrossClick) onCrossClick();
                  }}
                  disabled={isSearching}
                  className={
                    classes.smallIconButton +
                    (overrideClasses?.crossIconButton
                      ? ` ${overrideClasses?.crossIconButton}`
                      : '')
                  }>
                  <ClearIcon
                    fontSize="small"
                    className={overrideClasses?.clearIcon}
                  />
                </IconButton>
              </>
            ),
          },
          onChange: handleChange,
        }}
        focusInputOnSuggestionClick={false}
        hightlightFirstSuggestion={true}
      />
    </div>
  );
};

SearchField.propTypes = {
  search: PropTypes.func,
  label: PropTypes.string,
  delay: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  minChars: PropTypes.number,
  apiMethod: PropTypes.string,
  resultsKey: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  scrollableParentId: PropTypes.string,
  apiEndpoint: PropTypes.string.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
  onSuggestionsReceived: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

SearchField.defaultProps = {
  style: {},
  delay: 1000,
  minChars: 1,
  label: null,
  disabled: false,
  autoFocus: false,
  fullWidth: false,
  apiMethod: 'post',
  clearOnSelect: false,
  scrollableParentId: '',
  placeholder: 'Search...',
  helperText: 'Type in the field to search',
};

export default withStyles(styles)(SearchField);
