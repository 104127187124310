import { actionTypes } from '../../actions/fa_admin_places.ac'

export const initialStates = {
  faAdminPlaces: {
    error: null,
    places: null,
    isFetching: false
  },
  faAdminPlaceSearchFilters: {
    name: null,
    geo_fixed: null,
    dc_id: null,
    tags: [],
    tracking_group: null
  },
  faAdminPlaceDetails: {
    placeId: null,
    place: null,
    isFetching: false,
    error: null
  }
}

const faAdminPlaces = (state = initialStates.faAdminPlaces, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_FA_ADMIN_PLACES:
      return {
        ...state,
        isFetching: true,
        placeId: action.placeId
      }

    case actionTypes.RECEIVED_FA_ADMIN_PLACES:
      return {
        ...state,
        error: null,
        isFetching: false,
        places: action.places,
      }

    case actionTypes.FAILED_FETCHING_FA_ADMIN_PLACES:
      return {
        ...state,
        error: true,
        places: null,
        isFetching: false
      }

    default:
      return { ...state }
  }
}

const faAdminPlaceDetails = (
  state = initialStates.faAdminPlaceDetails,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PLACE_ID:
      return {
        ...state,
        placeId: action.placeId
      }

    case actionTypes.FETCHING_FA_ADMIN_PLACE_DETAILS:
      return {
        ...state,
        isFetching: true
      }

    case actionTypes.RECEIVED_FA_ADMIN_PLACE_DETAILS:
      return {
        ...state,
        isFetching: false,
        place: action.place,
        error: null
      }

    case actionTypes.FAILED_FETCHING_FA_ADMIN_PLACE_DETAILS:
      return {
        ...state,
        isFetching: false,
        place: null,
        error: true
      }

    default:
      return { ...state }
  }
}

const faAdminPlaceSearchFilters = (
  state = initialStates.faAdminPlaceSearchFilters,
  action
) => {
  switch (action.type) {
    case actionTypes.SAVE_PLACE_SEARCH_FILTERS:
      return { ...action.filterData }

    default:
      return { ...state }
  }
}

export default {
  faAdminPlaceDetails,
  faAdminPlaceSearchFilters,
  faAdminPlaces
}
