import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';

const chipStyle = (selected = false) => ({
  cursor: 'pointer',
  margin: '2px 1px',
  maxHeight: '30px',
  height: 'auto',
  backgroundColor: selected ? 'lightseagreen' : '#e0e0e0',
  color: selected ? 'white' : 'black',
});

export default function TagsInput({ ...props }) {
  const {
    onChange,
    placeholder,
    style,
    containerStyle,
    variant,
    tags,
    disabled = false,
    selectedTag,
    onDeleteCb,
    onTagClick,
    backspaceOnTags = true,
    listStyle,
    ...other
  } = props;
  const [inputValue, setInputValue] = React.useState('');
  const [selectedItem, setSelectedItem] = React.useState([]);

  useEffect(() => {
    setSelectedItem(tags);
  }, [tags]);

  const setNewSetOfTags = (newSelectedItem) => {
    if (tags === undefined) setSelectedItem(newSelectedItem);

    onChange(newSelectedItem);
  };

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const newSelectedItem = [...selectedItem];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim()
      );

      if (duplicatedValues !== -1) {
        setInputValue('');
        return;
      }
      if (!event.target.value.replace(/\s/g, '').length) return;

      newSelectedItem.push(event.target.value.trim());
      setNewSetOfTags(newSelectedItem);
      setInputValue('');
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === 'Backspace' &&
      backspaceOnTags
    ) {
      setNewSetOfTags(selectedItem.slice(0, selectedItem.length - 1));
    }
  }
  function handleChange(item) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue('');
    setNewSetOfTags(newSelectedItem);
  }

  const handleDelete = (item) => () => {
    if (onDeleteCb) {
      return onDeleteCb(item);
    }

    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setNewSetOfTags(newSelectedItem);
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }
  return (
    <React.Fragment>
      <Downshift
        id="downshift-multiple"
        inputValue={inputValue}
        onChange={handleChange}
        selectedItem={selectedItem}>
        {({ getInputProps }) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
            onKeyDown: handleKeyDown,
            placeholder,
          });
          return (
            <div>
              <TextField
                variant={variant}
                disabled={disabled}
                style={containerStyle}
                InputProps={{
                  style,
                  startAdornment: (
                    <div style={listStyle}>
                      {selectedItem.map((item) => (
                        <Chip
                          key={item}
                          tabIndex={-1}
                          label={item}
                          onClick={(e) => {
                            if (onTagClick) {
                              onTagClick(item, e);
                            }
                          }}
                          style={chipStyle(item === selectedTag)}
                          onDelete={handleDelete(item)}
                        />
                      ))}
                    </div>
                  ),
                  onBlur,
                  onChange: (event) => {
                    handleInputChange(event);
                    onChange(event);
                  },
                  onFocus,
                }}
                {...other}
                {...inputProps}
              />
            </div>
          );
        }}
      </Downshift>
    </React.Fragment>
  );
}
TagsInput.defaultProps = {
  tags: [],
};
TagsInput.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};
