import React from 'react'
import { DEFAULT_DATE_FORMAT } from '../../../constants/date'
import dateUtils from '../../../util/dateProcessing'
import { getFullName } from '../../../util/string'
import { CardNameLabelView } from '../../common'

const formatDate = date => dateUtils.formatDate(date, DEFAULT_DATE_FORMAT)

const HourlyWos = props => {
  const data = {
    Name: `${getFullName(props)} ${formatDate(props.dob)}`,
    DC: props.dc_nm,
    Method: props.method_name,
    'Conf Log': props.confirmation_entry?.state
  }

  const completedAt = props.completed_dt ? formatDate(props.completed_dt) : null
  const cancelledAt = props.cancel_dt ? formatDate(props.cancel_dt) : null

  const orderStatus =
    !completedAt && !cancelledAt
      ? { text: 'Open order', type: 'warning' }
      : completedAt
      ? { text: `Completed at ${completedAt}`, type: 'success' }
      : { text: `Cancelled at ${cancelledAt}`, type: 'danger' }

  const scheduledAt = props.sched_dt ? formatDate(props.sched_dt) : null

  const scheduleStatus =
    orderStatus.text === 'Open order' &&
    (!scheduledAt
      ? { text: 'UNSCHEDULED', type: 'danger' }
      : scheduledAt === '09-09-2099'
      ? { text: 'PENDING RESCHEDULE', type: 'warning' }
      : scheduledAt === '08-08-1988' || scheduledAt === '07-07-1977'
      ? { text: 'PENDING CANCELLATION', type: 'warning' }
      : { text: `Scheduled for ${scheduledAt}`, type: 'success' })

  const releasedAt = props.release_dt ? formatDate(props.release_dt) : null

  const releaseStatus = !releasedAt
    ? { text: 'UNRELEASED', type: 'danger' }
    : { text: `Released at ${releasedAt}`, type: 'success' }

  return (
    <CardNameLabelView
      label={`W${props._id} A${props.account}`}
      statuses={[releaseStatus, orderStatus, scheduleStatus]}
      data={data}
      {...props}
    />
  )
}

export default HourlyWos
