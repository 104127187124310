/*
   paErrorsList.ac.js
   action creators for paErrorsList in the master store
 */
import _ from 'lodash';

export function fetchpaErrorsList_ac(payerGroup,kind,dateStr) {
  return (dispatch, getState) => { //params passed in by thunk middleware
    dispatch({type:'REQUEST_PA_ERRORS_LIST',payerGroup,kind,dateStr})

    return window.sch.get(`/api/claims-listing/${payerGroup}/${kind}/${dateStr}`)
    .then(
      result => {
        let by_pcr = _.toPairs( _.mapValues( _.groupBy(result.pkg.rr,'PCR'), 'length' ) )
          .sort( (a,b) => ((a[0]>b[0])?1:-1) )
        let by_error = _.toPairs( _.mapValues( _.groupBy(result.pkg.rr,'PA Errors'), 'length' ) )
          .sort( (a,b) => ((a[0]>b[0])?1:-1) )
        let by_hcpc = _.toPairs( _.mapValues( _.groupBy(result.pkg.rr,'HCPC'), 'length' ) )
          .sort( (a,b) => ((a[0]>b[0])?1:-1) )

        dispatch({
          type: 'RECEIVED_PA_ERRORS_LIST',
          records: result.pkg.rr,
          stats: {
            by_error,
            by_pcr,
            by_hcpc
          }
        })
      },
      error => {
        throw error;
      }
    )
    .catch(error=>{
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_RECEIVED_PA_ERRORS_LIST',
        error
      })
    })
  }
}

export function fetchPaErrorStats_ac(payerGroup,kind,dateStr) {
  return (dispatch, getState) => { //params passed in by thunk middleware
    console.log("REQUESTING STATS")
    dispatch({type:'REQUEST_PA_ERR_STATS',payerGroup,kind,dateStr})
    window.sch.get(`/api/pa-error-stats/${payerGroup}/${kind}/${dateStr}`)
    .then(
      result => {
        dispatch({
          type:'RECEIVED_PA_ERR_STATS',
          stats:result.pkg.stats
        })
      },
      error => {
        throw error;
      }
    )
    .catch(error=>{
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_RECEIVED_PA_ERR_STATS',
        error
      })
    })
  }
}

/*
 claim user activity
*/
export function updateClaimActivity_ac(claimId, posOfActivity, kind, details, u ) {

  return (dispatch, getStore) => { //params passed in by thunk middleware

    dispatch({
      type: 'REQUEST_CLAIM_ACTIVITY',
      claimId,
      posOfActivity
    })

    let postData = {
      claimId,
      kind, details,
      u: getStore().me.user._id
    }


    return window.sch.post(`/api/claim-activity`,postData)
    .then(
      result => {
        dispatch({
          type: 'RECEIVED_CLAIM_ACTIVITY',
          claimId,
          posOfActivity,
          result
        })
      },
      error => {
        throw error;
      }
    )
    .catch(error=>{
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_RECEIVED_CLAIM_ACTIVITY',
        claimId,
        posOfActivity,
        error
      })
    })
  }
}

export function updateClaimLineActivity_ac(claimId, lineIdx, posOfActivity, kind, details) {
  return (dispatch, getStore) => { //params passed in by thunk middleware
    dispatch({
      type: 'REQUEST_CLAIM_LINE_ACTIVITY',
      claimId,
      posOfActivity
    })

    let postData = {
      claimId,
      lineIdx,
      kind, details,
      u: getStore().me.user._id
    }

    return window.sch.post(`/api/claim-activity-on-line`,postData)
    .then(
      result => {
        dispatch({
          type: 'RECEIVED_CLAIM_LINE_ACTIVITY',
          claimId,
          posOfActivity,
          result
        })
      },
      error => {
        throw error
      }
    )
    .catch(error=>{
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_RECEIVED_CLAIM_LINE_ACTIVITY',
        claimId,
        posOfActivity,
        error
      })
    })
  }
}

export function validateClaimLineFix_ac(claimId, lineIdx, posInSet){
  return (dispatch, getStore) => { //params passed in by thunk middleware
    let _records = _.concat( getStore().paErrorsList.records );
    if (_records[posInSet]['LineIdx'] !==  lineIdx ){
      dispatch({
        type: 'FAILED_VALIDATE_CLAIM_LINE_FIX',
        isValidating:false,
        validationError:"MIS MATCHED RECORD POSITION AND LINE - Validation aborted"
      })
      return
    }


    dispatch({
      type: 'VALIDATE_CLAIM_LINE_FIX',
      isValidating:true,
    })

    let postData = {
      claimId,
      saveToMongo:true,
      asRow: true,
      modelType: _records[posInSet]['ModelType'],
      u: getStore().me.user._id
    }


    return window.sch.post( `/api/claim/${claimId}`,postData)
    .then(
      result => {
        console.log(result.pkg)

        if (
          result.pkg.claimDoc.lines[lineIdx].pa_errors.length === 0
        ){
          //lines_as_rows would not contain this claim line entry if errors were resolved
          _records[posInSet]['PA Errors Resolved'] = true;
          _records[posInSet]['Balance'] = result.pkg.claimDoc.lines[lineIdx].tx.balance;
          _records[posInSet]['rec_idx'] = posInSet;
        }
        else if ( result.pkg.claimDoc.lines[lineIdx].tx.balance === 0 ){
          _records[posInSet]['Balance'] = 0;
          _records[posInSet]['SyncMsg'] = 'PA Errors not resolved, but balance now 0';
          _records[posInSet]['rec_idx'] = posInSet;
        }
        else{
          //we cant determine the matching line by position in lines_as_rows ,
          //because lines_as_rows only returns errored lines
          result.pkg.lines_as_rows.forEach( lineAsRow => {
            if ( _records[posInSet]['LineIdx'] === lineAsRow['LineIdx'] ){
              _records[posInSet] = lineAsRow;
              _records[posInSet]['SyncMsg'] = 'PA Errors still found';
              _records[posInSet]['rec_idx'] = posInSet;
            }
          })
        }

        dispatch({
          type: 'RESPONSE_VALIDATE_CLAIM_LINE_FIX',
          records: _records,
          isValidating:false,
          validationError:false
        })
      },
      error => {
        throw error;
      }
    )
    .catch(error=>{
      dispatch({
        type: 'FAILED_VALIDATE_CLAIM_LINE_FIX',
        isValidating:false,
        validationError:error
      })
    })
  }
}
