/* eslint no-unused-vars: "off" */
import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  title: {
    flexGrow: 1
  },
  formControl: {
    paddingBottom: 32
  },
  tableRow: {
    fontSize: 11
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowX: 'auto',
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    maxHeight: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  loadBtn: {
    marginTop: 15
  }
})

const TASK_TYPES = [
  'ADDRESS',
  'ADDRESS AND CONTACTS',
  'CONTACT',
  'CP360 INV NMD CALL',
  'CP360 INV NMD HOMEVISIT',
  'CP360 INV RESP CALL',
  'CP360 INV RESP HOMEVISIT',
  'CP360 NIV NMD CALL',
  'CP360 NIV NMD HOMEVISIT',
  'CP360 NIV RESP CALL',
  'CP360 NIV RESP HOMEVISIT',
  'DEMOGRAPHIC',
  'IBREATHE GENERAL SURVEY',
  'IBREATHE VITALS',
  'IPAD SETUP',
  'NINETY DAY ASSESSMENT',
  'OXYGEN CALL',
  'PHARMACY',
  'SC NINETY DAY PROGRAM WIZARD',
  'SC PROGRAM',
  'SC PROGRAM WIZARD',
  'SC REVIEW PROGRAM',
  'SUPERKIDS AC CALL',
  'SUPERKIDS INV VENT CALL',
  'SUPERKIDS INV VENT HOMEVISIT',
  'SUPERKIDS NIV VENT CALL',
  'SUPERKIDS NIV VENT HOMEVISIT',
  'SUPERKIDS NUTRITION',
  'SUPERKIDS OXY CALL',
  'SUPERKIDS PROGAM WIZARD',
  'SUPERKIDS RESUPPLY CALL'
]

class CareConnectReports extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      task: null, // COMPLETED | PENDING | SCHEDULED | UNSCHEDULED
      view: null, // SUMMARY | FULL
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      isFetching: false,
      data: null,
      dataTask: null, // the task the data was loaded for, to prevent post-load toggle issue
      dataView: null, // the view the data was loaded for, to prevent post-load toggle issue
      docsLoaded: 0,
      period: null,
      error: ''
    }
    this.fetch = this.fetch.bind(this)
    this.exportCSV = this.exportCSV.bind(this)
    this.renderData = this.renderData.bind(this)
  }

  fetch() {
    let params = {
      task: this.state.task,
      view: this.state.view,
      year: this.state.year,
      month: this.state.month - 1
    }

    let error = ''
    if (!params.task) error = 'Please select task state'
    else if (!params.view) error = 'Please select view type'
    else if (
      params.year === new Date().getFullYear() &&
      params.month > new Date().getMonth()
    )
      error = 'Cannot load from the future'
    else;
    if (error) {
      this.setState({ error })
      return
    }

    this.setState(
      {
        isFetching: true,
        data: null,
        dataTask: null,
        dataView: null,
        docsLoaded: 0,
        period: null,
        error: ''
      },
      () => {
        window.sch
          .post('/api/reports/careconnect', params)
          .then(result => {
            this.setState({
              isFetching: false,
              data: result.output,
              dataTask: params.task,
              dataView: params.view,
              docsLoaded: result.docsLoaded,
              period: result.period
            })
          })
          .catch(err => {
            console.error(err)
            this.setState({
              isFetching: false,
              error: err.error ? err.error : err.message
            })
          })
      }
    )
  }

  exportCSV() {
    if (!this.state.data) {
      this.setState({
        error: 'No data has been loaded to export'
      })
      return
    }

    let users = []
    if (this.state.dataView === 'SUMMARY') {
      users = Object.keys(this.state.data)
      users.sort()
    }
    let fileName = `${this.state.year}-${this.state.month}-${
      this.state.dataTask
    }-${this.state.dataView}.csv`
    let csv = ''

    if (this.state.dataView === 'FULL') {
      csv += 'Time,Staff,Patient,Task,ID\r\n'
      for (let item of this.state.data) {
        csv += `${item.time},${item.staff},${item.patient},${item.task},${
          item.id
        }\r\n`
      }
    } else {
      csv += 'Task type,' + users.join(',') + '\r\n'
      for (let t of TASK_TYPES) {
        let row = [t]
        for (let u of users) row.push(this.state.data[u][t])
        csv += row.join(',') + '\r\n'
      }
    }

    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    })

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName)
    } else {
      let link = document.querySelector('#csv')
      if (link.download === undefined) {
        alert('This browser does not support CSV export')
      } else {
        let url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.click()
      }
    }
  }

  renderData() {
    const { classes } = this.props

    let users = []
    if (this.state.dataView === 'SUMMARY') {
      users = Object.keys(this.state.data)
      users.sort()
    }

    return (
      <>
        <p>
          {this.state.docsLoaded} records loaded from {this.state.period[0]} to{' '}
          {this.state.period[1]}
        </p>
        <Paper>
          <Table size="small">
            <TableHead>
              {this.state.dataView === 'FULL' ? (
                <TableRow>
                  <TableCell>Time</TableCell>
                  <TableCell>Staff</TableCell>
                  <TableCell>Patient</TableCell>
                  <TableCell>Task</TableCell>
                  <TableCell>ID</TableCell>
                </TableRow>
              ) : (
                <TableRow>
                  <TableCell>Task type</TableCell>
                  {users.map((u, i) => {
                    return <TableCell key={i}>{u}</TableCell>
                  })}
                </TableRow>
              )}
            </TableHead>
            {this.state.dataView === 'FULL' ? (
              <TableBody>
                {this.state.data.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.time}</TableCell>
                    <TableCell>{item.staff}</TableCell>
                    <TableCell>{item.patient}</TableCell>
                    <TableCell>{item.task}</TableCell>
                    <TableCell>{item.id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {TASK_TYPES.map((t, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{t}</TableCell>
                      {users.map((u, i) => {
                        return (
                          <TableCell key={i}>{this.state.data[u][t]}</TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            )}
          </Table>
        </Paper>
      </>
    )
  }

  render() {
    const { classes } = this.props

    let years = []
    let year = 2019
    while (year <= new Date().getFullYear()) {
      years.push(year)
      year++
    }

    return (
      <main className={classes.content}>
        <label className={classes.label}>CareConnect Reports</label>
        <div>
          <p>1. Select task state</p>
          <Button
            variant={this.state.task === 'COMPLETED' ? 'contained' : 'outlined'}
            color="primary"
            disabled={this.state.isFetching}
            onClick={() => {
              this.setState({ task: 'COMPLETED' })
            }}>
            Completed
          </Button>
          <Button
            variant={this.state.task === 'PENDING' ? 'contained' : 'outlined'}
            color="primary"
            disabled={this.state.isFetching}
            onClick={() => {
              this.setState({ task: 'PENDING' })
            }}>
            Pending
          </Button>
          <Button
            variant={this.state.task === 'SCHEDULED' ? 'contained' : 'outlined'}
            color="primary"
            disabled={this.state.isFetching}
            onClick={() => {
              this.setState({ task: 'SCHEDULED' })
            }}>
            Scheduled
          </Button>
          <Button
            variant={
              this.state.task === 'UNSCHEDULED' ? 'contained' : 'outlined'
            }
            color="primary"
            disabled={this.state.isFetching}
            onClick={() => {
              this.setState({ task: 'UNSCHEDULED' })
            }}>
            Unscheduled
          </Button>
        </div>
        <div>
          <p>2. Select view type</p>
          <Button
            variant={this.state.view === 'SUMMARY' ? 'contained' : 'outlined'}
            color="primary"
            disabled={this.state.isFetching}
            onClick={() => {
              this.setState({ view: 'SUMMARY' })
            }}>
            Summary
          </Button>
          <Button
            variant={this.state.view === 'FULL' ? 'contained' : 'outlined'}
            color="primary"
            disabled={this.state.isFetching}
            onClick={() => {
              this.setState({ view: 'FULL' })
            }}>
            Full
          </Button>
        </div>
        <div>
          <p>3. Select year and month</p>
          <FormControl>
            <InputLabel id="yearLabel">Year</InputLabel>
            <Select
              id="year"
              value={this.state.year}
              disabled={this.state.isFetching}
              onChange={event => {
                this.setState({ year: event.target.value })
              }}>
              {years.map((y, i) => {
                return (
                  <MenuItem key={i} value={y}>
                    {y}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="monthLabel">Month</InputLabel>
            <Select
              id="month"
              value={this.state.month}
              disabled={this.state.isFetching}
              onChange={event => {
                this.setState({ month: event.target.value })
              }}>
              <MenuItem value={1}>Jan</MenuItem>
              <MenuItem value={2}>Feb</MenuItem>
              <MenuItem value={3}>Mar</MenuItem>
              <MenuItem value={4}>Apr</MenuItem>
              <MenuItem value={5}>May</MenuItem>
              <MenuItem value={6}>Jun</MenuItem>
              <MenuItem value={7}>Jul</MenuItem>
              <MenuItem value={8}>Aug</MenuItem>
              <MenuItem value={9}>Sep</MenuItem>
              <MenuItem value={10}>Oct</MenuItem>
              <MenuItem value={11}>Nov</MenuItem>
              <MenuItem value={12}>Dec</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.loadBtn}>
          <Button
            variant="outlined"
            onClick={this.fetch}
            disabled={this.state.isFetching}>
            Load
          </Button>
          <Button
            variant="outlined"
            onClick={this.exportCSV}
            disabled={this.state.isFetching}>
            Export current view to CSV
          </Button>
        </div>
        <div className={classes.scrollArea}>
          {this.state.error ? (
            <p>{this.state.error}</p>
          ) : this.state.isFetching ? (
            <p>Loading...</p>
          ) : this.state.data ? (
            this.renderData()
          ) : (
            <p>Please make a selection</p>
          )}
        </div>
        <a id="csv" href="#!">
          &nbsp;
        </a>
      </main>
    )
  }
}

CareConnectReports.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CareConnectReports))
)
