import React from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { withStyles } from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2
  },
  link: {
    textDecoration: 'none',
    color: '#6784d2',
  },
  expandIcon: {
    cursor: 'pointer'
  }
});


class NestedList extends React.Component {
  state = {
    open: true
  };

  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    const { classes, pages, primaryPageLabel } = this.props;

    return (
      <div className={classes.root}>
        <div>
          <Typography
            variant="body1"
            color="inherit"
            onClick={this.handleClick}
          >
            <strong>{primaryPageLabel}</strong>
            {this.state.open ? <ExpandLess className={classes.expandIcon} /> : <ExpandMore className={classes.expandIcon} />}
          </Typography>
        </div>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          {pages.map((page,idx) => (
            <div className={classes.nested} key={idx}>
              {page?.subPages ? (
                <NestedList
                  classes={classes}
                  pages={page.subPages}
                  primaryPageLabel={page.label}
                />
              ) : (
                <Link to={page.path} className={classes.link}>
                  <Typography
                    inline
                    variant="body1"
                    color="inherit"
                    component="span"
                  >
                    <strong>{page.label}</strong>
                  </Typography>
                </Link>
              )}
            </div>
          ))}
        </Collapse>
      </div>
    );
  }
}

NestedList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NestedList);
