import { Button, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

/*

{
    "ShipmentInfo": [
        {
            "AccountNumber": 56191,
            "TrackingNumber": "SCH-4174097-1",
            "ShipperCompany": "SuperCare Health",
            "ShipperContact": "SuperCare Health",
            "ShipperPhone": "8002064880",
            "ShipperEmail": "jmosqueda@supercare.com",
            "PickupAddress1": "16017 Valley Blvd",
            "PickupAddress2": "",
            "PickupCity": "City of Industry",
            "PickupState": "CA",
            "PickupZip": "91744",
            "ShipToCompany": "Some Co",
            "ShipToAttention": "John Doe",
            "ShipToPhone": "(949) 999-9999",
            "ShipToEmail": "",
            "DeliveryAddress1": "123 Main",
            "DeliveryAddress2": "#204",
            "DeliveryCity": "LOS ANGELES",
            "DeliveryState": "CA",
            "DeliveryZip": "90000",
            "DeliveryZone": 2,
            "ServiceCode": "PDS",
            "ShipmentReference": "4174097",
            "DeclaredValue": 0,
            "CODValue": 0,
            "SpecialInstructions": "PLEASE SHIP GSO OVERNIGHT FOR DELIVERY TOMORROW THANK YOUAlt Phone: 949-378-4848",
            "Weight": 2,
            "SignatureCode": "SIG_REQD",
            "AddnShipperReference1": "",
            "AddnShipperReference2": "",
            "PODImage": "",
            "PODImageMessage": "",
            "Delivery": {
                "ShipDate": "10/09/2024",
                "ScheduledDeliveryDate": "10/10/2024",
                "ScheduledDeliveryTime": "10:30 AM",
                "DeliveryDate": "10/10/2024",
                "TransitStatus": "DELIVERED",
                "DeliveryTime": "10:14 AM",
                "SignedBy": "jdoe"
            },
            "TransitNotes": [
                {
                    "EventDate": "10/9/2024 7:46:46 PM",
                    "Comments": "ARRIVAL SCAN DELIVERY SCHED  FOR 10/10/2024",
                    "Location": "SANTA FE SPRINGS, CA"
                },
                {
                    "EventDate": "10/10/2024 9:14:52 AM",
                    "Comments": "SCANNED AT DELIVERY DEPOT ",
                    "Location": "GARDENA, CA"
                },
                {
                    "EventDate": "10/10/2024 10:09:18 AM",
                    "Comments": "ON ROUTE FOR DELIVERY ",
                    "Location": "GARDENA, CA"
                },
                {
                    "EventDate": "10/10/2024 10:14:23 AM",
                    "Comments": "SHIPMENT DELIVERED ",
                    "Location": "GARDENA, CA"
                }
            ],
            "ShipmentCharges": {
                "TransportationCharge": 10.12,
                "CODCharge": 0,
                "InsuranceCharge": 0,
                "FuelSurcharge": 2.58,
                "OtherCharge": [
                    {
                        "code": "DSR",
                        "description": "Delivery Signature Required Fee",
                        "fee": 3.11
                    },
                    {
                        "code": "RDF",
                        "description": "Residential Delivery Fee",
                        "fee": 1.5
                    }
                ],
                "TotalCharge": 17.31
            }
        }
    ],
    "StatusCode": 200,
    "StatusDescription": "OK",
    "AuthenticationResultCode": 0,
    "RequestDateTime": "2024-10-10T13:55:42.4796634-07:00",
    "ResponseDateTime": "2024-10-10T13:55:42.4965419-07:00",
    "ErrorCount": 0,
    "ErrorDetail": null
}

*/

// import OpenOrdersTrackingReportLanding from '../../Reports/OpenOrdersTrackingReportLanding';
import WoData from './WoData';

const DeliveryInfo = (props) => {
  const { status, msg, tn, ShipmentInfo } = props;
  if (status === 'error') {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 8,
          border: '1px solid #ccc',
          borderRadius: 8,
        }}>
        <strong>Delivery Information for {tn}</strong>
        <p>
          {msg || 'There was an error fetching delivery information'}- This may
          be because GSO has not picked up the shipment and registered the
          tracking number yet. Please check back later.
        </p>
      </div>
    );
  }

  // debugger;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        marginTop: 20,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          fontSize: '12px',
        }}>
        <h2 style={{ margin: '0 0 10px 0' }}>
          Delivery Information for {tn} - Supercare GSO Account#:
          {ShipmentInfo[0].AccountNumber}
        </h2>
        {ShipmentInfo.map((info, index) => {
          console.log(info);

          return (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}>
              <div style={{ flex: 1, padding: '5px' }}>
                <h3 style={{ margin: '0 0 5px 0' }}>Ship To</h3>
                <p>
                  <strong>ShipToAttention:</strong> {info.ShipToAttention}
                </p>
                <p>
                  <strong>ShipToCompany:</strong> {info.ShipToCompany}
                </p>
                <p>
                  <strong>ShipToEmail:</strong> {info.ShipToEmail}
                </p>
                <p>
                  <strong>ShipToPhone:</strong> {info.ShipToPhone}
                </p>
                <p>
                  <strong>SpecialInstructions:</strong>{' '}
                  {info.SpecialInstructions}
                </p>
                {info.Delivery.Signature && (
                  <div>
                    <strong>Signature:</strong>
                    <img
                      src={`data:image/png;base64,${info.Delivery.Signature}`}
                      alt="Signature"
                      style={{
                        display: 'block',
                        marginTop: '5px',
                        maxWidth: '100px',
                      }}
                    />
                  </div>
                )}
              </div>
              <div style={{ flex: 1, padding: '5px' }}>
                <h3 style={{ margin: '0 0 5px 0' }}>Delivery</h3>
                <p>
                  <strong>DeliveryDate:</strong> {info.Delivery.DeliveryDate}
                </p>
                <p>
                  <strong>DeliveryTime:</strong> {info.Delivery.DeliveryTime}
                </p>
                <p>
                  <strong>ScheduledDeliveryDate:</strong>{' '}
                  {info.Delivery.ScheduledDeliveryDate}
                </p>
                <p>
                  <strong>ScheduledDeliveryTime:</strong>{' '}
                  {info.Delivery.ScheduledDeliveryTime}
                </p>
                <p>
                  <strong>ShipDate:</strong> {info.Delivery.ShipDate}
                </p>
              </div>
              <div style={{ padding: '5px' }}>
                <h3 style={{ margin: '0 0 5px 0' }}>Transit Notes</h3>
                {info.TransitNotes.map((note, noteIndex) => (
                  <div
                    key={noteIndex}
                    style={{
                      marginBottom: '5px',
                      borderBottom: '1px solid #ccc',
                    }}>
                    <p>
                      <strong>Event Date:</strong> {note.EventDate}
                    </p>
                    <p>
                      <strong>Comments:</strong> {note.Comments}
                    </p>
                    <p>
                      <strong>Location:</strong> {note.Location}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const PastTracks = ({
  data,
  updateWoField,
  validatePosInt,
  checkWo,
  resetWo,
  open_snack_ac,
}) => {
  console.log(data);
  const { trackingNumbers } = data;
  const [trackingResults, setTrackingResults] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const getTrackingStatus = async () => {
    setIsFetching(true);
    const endpoint = '/api/gso/track_pkg';
    const params = {
      tns: trackingNumbers,
    };
    try {
      let res = await window.sch.post(endpoint, params);
      console.log('res: ', res);
      setTrackingResults(res.results);
    } catch (e) {
      open_snack_ac({
        variant: 'error',
        message: 'GSO API Error',
      });
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getTrackingStatus();
  }, [trackingNumbers]);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ marginTop: 12, marginBottom: 12 }}>
        <TextField
          id="wo"
          label="Lookup another WO#"
          margin="dense"
          onKeyUp={checkWo}
          onChange={validatePosInt}
          placeholder="Work Order Number"
        />
      </div>

      <WoData
        wos_doc={data}
        updateWoField={updateWoField}
        validatePosInt={validatePosInt}
      />
      <div style={{ marginTop: 12, marginBottom: 12 }}>
        <div>
          Tracking #s:{' '}
          {trackingNumbers?.length ? (
            trackingNumbers.map((tn, i) => <div key={`tn${i}`}>{tn}</div>)
          ) : (
            <div>No tracking numbers</div>
          )}
        </div>

        <Button variant="contained" color="primary" onClick={getTrackingStatus}>
          Refetch Tracking# Status
        </Button>
      </div>
      <div className="row" style={{ height: '480px', overflowY: 'auto' }}>
        {trackingResults.map((tr, index) => (
          <DeliveryInfo key={index} {...tr} />
        ))}
      </div>
      {/* <div
        style={{ marginTop: 24, borderTop: '1px solid #ccc', paddingTop: 8 }}>
        Do you want to cancel these orders so that you can recreate the shipping
        notifs?
        <Grid container spacing={8}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={cancelTracks}>
              Yes
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={resetWo}>
              No
            </Button>
          </Grid>
        </Grid>
      </div> */}
    </div>
  );
};

export default PastTracks;
