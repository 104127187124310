import {
  FETCH_IDX_LOOKUPS,
  FETCH_IDX_LOOKUPS_SUCCESSFUL,
  FETCH_IDX_LOOKUPS_FAILED,
  FETCH_IDX_LOOKUP_DETAIL,
  FETCH_IDX_LOOKUP_DETAIL_SUCCESSFUL,
  FETCH_IDX_LOOKUP_DETAIL_FAILED,
  FETCH_IDX_LOOKUPS_STRUCTURE,
  FETCH_IDX_LOOKUPS_STRUCTURE_SUCCESSFUL,
  FETCH_IDX_LOOKUPS_STRUCTURE_FAILED,
  ADD_IDX_LOOKUP,
  ADD_IDX_LOOKUP_SUCCESSFUL,
  ADD_IDX_LOOKUP_FAILED,
  REMOVE_IDX_LOOKUP,
  REMOVE_IDX_LOOKUP_SUCCESSFUL,
  REMOVE_IDX_LOOKUP_FAILED,
  UPDATE_IDX_LOOKUPS_SEQUENCE,
  UPDATE_IDX_LOOKUPS_SEQUENCE_SUCCESSFUL,
  UPDATE_IDX_LOOKUPS_SEQUENCE_FAILED,
  SET_IDX_LOOKUP_PROPS,
} from '../actions/idx_lookups.ac';

const initialState = {
  structure: {
    data: {},
    isFetching: false,
    error: null,
  },
  list: {
    data: [],
    isFetching: false,
    error: null,
  },
  detail: {
    data: null,
    isFetching: false,
    error: null,
  },
  isAddingIdxLookup: false,
  isRemovingIdxLookup: false,
  isUpdatingIdxLookupsSequence: false,
};

export function idxLookups(state = initialState, action) {
  switch (action.type) {
    // FETCH_IDX_LOOKUPS_STRUCTURE
    case FETCH_IDX_LOOKUPS_STRUCTURE:
      return Object.assign({}, state, {
        structure: {
          ...state.structure,
          isFetching: true,
        },
      });
    case FETCH_IDX_LOOKUPS_STRUCTURE_SUCCESSFUL:
      return Object.assign({}, state, {
        structure: {
          isFetching: false,
          data: action.data.pkg,
          error: null,
        },
        list: {
          ...initialState.list,
        },
        detail: {
          ...initialState.detail,
        },
      });
    case FETCH_IDX_LOOKUPS_STRUCTURE_FAILED:
      return Object.assign({}, state, {
        structure: {
          isFetching: false,
          data: {},
          error: action.error,
        },
      });

    // FETCH_IDX_LOOKUPS
    case FETCH_IDX_LOOKUPS:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          isFetching: true,
        },
      });
    case FETCH_IDX_LOOKUPS_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: action.data.rr,
          error: null,
        },
        detail: {
          ...initialState.detail,
        },
      });
    case FETCH_IDX_LOOKUPS_FAILED:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    // FETCH_IDX_LOOKUP_DETAIL
    case FETCH_IDX_LOOKUP_DETAIL:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          isFetching: true,
        },
      });
    case FETCH_IDX_LOOKUP_DETAIL_SUCCESSFUL:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      });
    case FETCH_IDX_LOOKUP_DETAIL_FAILED:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      });

    // ADD_IDX_LOOKUP
    case ADD_IDX_LOOKUP:
      return Object.assign({}, state, {
        isAddingIdxLookup: true,
      });
    case ADD_IDX_LOOKUP_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          data: [...state.list.data, action.data],
        },
        isAddingIdxLookup: false,
      });
    case ADD_IDX_LOOKUP_FAILED:
      return Object.assign({}, state, {
        isAddingIdxLookup: false,
      });

    // REMOVE_IDX_LOOKUP
    case REMOVE_IDX_LOOKUP:
      return Object.assign({}, state, {
        isRemovingIdxLookup: true,
      });
    case REMOVE_IDX_LOOKUP_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          data: state.list.data.filter((_, index) =>
            action.removedIndex !== undefined
              ? index !== action.removedIndex
              : index !== action.data.removed_seq
          ),
        },
        detail: {
          ...state.detail,
          data: null,
        },
        isRemovingIdxLookup: false,
      });
    case REMOVE_IDX_LOOKUP_FAILED:
      return Object.assign({}, state, {
        isRemovingIdxLookup: false,
      });

    // UPDATE_IDX_LOOKUPS_SEQUENCE
    case UPDATE_IDX_LOOKUPS_SEQUENCE:
      return Object.assign({}, state, {
        isUpdatingIdxLookupsSequence: true,
      });
    case UPDATE_IDX_LOOKUPS_SEQUENCE_SUCCESSFUL:
    case UPDATE_IDX_LOOKUPS_SEQUENCE_FAILED:
      return Object.assign({}, state, {
        isUpdatingIdxLookupsSequence: false,
      });

    case SET_IDX_LOOKUP_PROPS:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          data: {
            ...state.detail.data,
            props: action.props,
          },
        },
      });

    default:
      return state;
  }
}
