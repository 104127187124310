import {
  Divider,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  FormControlLabel,
  Modal,
  IconButton,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'

import {
  PAP_ORDER_STATUS,
  CONTACT_TYPES,
  REQUESTING_PATIENTS,
  REASONS,
  NEW_ORDERED_ITEMS_QUANTITES,
  PAP_UNITS,
  DOCUMENTS_REQUESTED,
  PT_CO_PAYMENTS,
  DF_PATIENT_NOTIFICATIONS,
  NEW_ORDERED_ITEMS_SIZES,
  MASK_OPTIONS
} from '../../constants/PAPFrequencyConstant'

const oldItemsDetails = {
  item: '',
  reason: ''
}

const newItemsOrdered = {
  item: '',
  quantity: '',
  size: '',
  option: ''
}

const defaultForm = {
  pap_order_status: '',
  contact_type: '',
  patient_requesting: '',
  itemsReplacement: [{
    ...oldItemsDetails
  }],
  newItemsOrdered: [{
    ...newItemsOrdered
  }],
  pap_unit: '',
  equipment_functioning: '',
  ins_payer_verified: '',
  documents_requested: [],
  pt_co_payment: '',
  confirmed_all_demo_and_order: '',
  df_patient_notification: '',
  additional_notes: ''
}

const ReplacementItemsRow = ({
  items,
  classes,
  handleReasonChange
}) => {
  return (
    <>
      <Divider component="hr" />
      <div className={classes.tableScrollable}>
        <Table padding="dense">
          <TableHead padding="dense">
            <TableRow className={classes.tableHeader}>
              <TableCell align="left" width="20%">
                #
              </TableCell>
              <TableCell align="left" width="40%">
                Item
              </TableCell>
              <TableCell align="left" width="40%">
                Reason
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(items || []).map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  align="left"
                  width="5%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <span style={{ fontSize: 20 }}>
                    {index + 1}
                  </span>
                </TableCell>
                <TableCell
                  align="left"
                  width="40%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}
                >
                  {item.description.toUpperCase()}
                </TableCell>
                <TableCell
                  align="left"
                  width="40%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}
                >
                  <Select
                    fullWidth
                    value={item.reason || ''}
                    name="reason"
                    onChange={(e) => handleReasonChange(index, e.target.value)}
                  >
                    {(REASONS || []).map(d => (
                      <MenuItem
                        style={{ fontSize: 11, fontWeight: 600 }}
                        key={d.code}
                        value={d.code}>
                        {d.lbl.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

const NewItemsRow = ({
  items,
  classes,
  handleQuantityChange,
  handleSizeChange,
  handleMaskOptionsChange,
  maskType
}) => {
  return (
    <>
      <Divider component="hr" />
      <div className={classes.tableScrollable}>
        <Table padding="dense">
          <TableHead padding="dense">
            <TableRow className={classes.tableHeader}>
              <TableCell align="left" width="10%">
                #
              </TableCell>
              <TableCell align="left" width="30%">
                Description
              </TableCell>
              <TableCell align="left" width="20%">
                Quantity
              </TableCell>
              <TableCell align="left" width="20%">
                Size
              </TableCell>
              <TableCell align="left" width="20%">
                Options
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(items || []).map((item, index) => (
              <TableRow key={index}>
                <TableCell
                  align="left"
                  width="10%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <span style={{ fontSize: 20 }}>
                    {index + 1}
                  </span>
                </TableCell>
                <TableCell
                  align="left"
                  width="30%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}
                >
                  {item.description.toUpperCase()}
                </TableCell>
                <TableCell
                  align="left"
                  width="20%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}
                >
                  <Select
                    fullWidth
                    value={item.qty || ''}
                    name="qty"
                    onChange={(e) => handleQuantityChange(index, e.target.value)}
                  >
                    {(NEW_ORDERED_ITEMS_QUANTITES || []).map(d => (
                      <MenuItem
                        style={{ fontSize: 11, fontWeight: 600 }}
                        key={d.code}
                        value={d.code}>
                        {d.lbl.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell
                  align="left"
                  width="20%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}
                >
                  <Select
                    fullWidth
                    value={item.size || ''}
                    name="size"
                    onChange={(e) => handleSizeChange(index, e.target.value)}
                  >
                    {(NEW_ORDERED_ITEMS_SIZES || []).map(d => (
                      <MenuItem
                        style={{ fontSize: 11, fontWeight: 600 }}
                        key={d.code}
                        value={d.code}>
                        {d.lbl.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell
                  align="left"
                  width="20%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}
                >
                  <Select
                    fullWidth
                    value={item.option || ''}
                    name="option"
                    onChange={(e) => handleMaskOptionsChange(index, e.target.value)}
                  >
                    {(MASK_OPTIONS[maskType] || [])
                      .map(d => (
                        <MenuItem
                          style={{ fontSize: 11, fontWeight: 600 }}
                          key={d}
                          value={d}>
                          {d.toUpperCase()}
                        </MenuItem>
                      ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

export const NotesGenerationForm = (props) => {

  const [formFields, setFormFields] = useState({ ...defaultForm });

  useEffect(() => {
    // sync state with props value
    setFormFields({
      ...defaultForm,
      itemsReplacement: props.selectedItems.map(item => ({ ...item })),
      newItemsOrdered: props.selectedItems.map(item => ({ ...item })),
    })

  }, [props.selectedItems])

  const handleChange = e => {
    let { type, name, value, checked } = e.target;
    if (type === 'checkbox') {
      value = checked
    }
    setFormFields({
      ...formFields,
      [name]: value
    })
  }
  const handleReasonChange = (index, reason) => {
    formFields.itemsReplacement[index].reason = reason;
    setFormFields({
      ...formFields
    })
  }

  const handleQuantityChange = (index, qty) => {
    formFields.newItemsOrdered[index].qty = qty;
    setFormFields({
      ...formFields
    })
  }

  const handleSizeChange = (index, size) => {
    formFields.newItemsOrdered[index].size = size;
    setFormFields({
      ...formFields
    })
  }

  const handleMaskOptionsChange = (index, option) => {
    formFields.newItemsOrdered[index].option = option;
    setFormFields({
      ...formFields
    })
  }

  const handleSaveClick = () => {
    const hasAllReasonsForReplacements = formFields.itemsReplacement.every(item => item.reason);
    const hasAllQuantityForNewItems = formFields.newItemsOrdered.every(item => item.qty);

    if (!hasAllReasonsForReplacements) {
      return props.open_snack_ac({
        variant: 'info',
        message: 'Reason is required'
      })
    }
    if (!hasAllQuantityForNewItems) {
      return props.open_snack_ac({
        variant: 'info',
        message: 'Quantity is required'
      })
    }
    props.saveNotes({ ...formFields })
  }

  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.handleCloseClick}
    >
      <div className={props.classes.modalWrapper} >
        <Grid
          container
          className={props.classes.modalStickyComponentBottom}
        >
          <Grid container item>
            <Typography
              align="center"
              variant="h5"
              color="primary"
              style={{ width: '100%', padding: 8 }}
            >
              <strong> Add Details to SAVE HDMS Notes </strong>
            </Typography>
          </Grid>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton style={{ padding: 8 }} onClick={props.handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        <div style={{ padding: 8 }}>
          <Grid container spacing={16}>
            <Grid item style={{ width: '100%' }}>
              <FormGroup>
                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>PAP Order Status</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.pap_order_status}
                  name="pap_order_status"
                  onChange={handleChange}
                >
                  {(PAP_ORDER_STATUS || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>Contact Type</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.contact_type}
                  name="contact_type"
                  onChange={handleChange}
                >
                  {(CONTACT_TYPES || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>Requesting Patient</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.patient_requesting}
                  name="patient_requesting"
                  onChange={handleChange}
                >
                  {(REQUESTING_PATIENTS || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                {
                  formFields.itemsReplacement && formFields.itemsReplacement.length > 0 && (
                    <>
                      <FormLabel className={props.classes.mt10} component="legend">
                        <strong>Item(s) Replacement On Hand</strong>
                      </FormLabel>
                      <ReplacementItemsRow
                        items={formFields.itemsReplacement}
                        classes={props.classes}
                        handleReasonChange={handleReasonChange}
                      >
                      </ReplacementItemsRow>
                    </>
                  )
                }

                {
                  formFields.newItemsOrdered && formFields.newItemsOrdered.length > 0 && (
                    <>
                      <FormLabel className={props.classes.mt10} component="legend">
                        <strong>New Item(s) Ordered</strong>
                      </FormLabel>
                      <NewItemsRow
                        items={formFields.newItemsOrdered}
                        classes={props.classes}
                        handleQuantityChange={handleQuantityChange}
                        handleSizeChange={handleSizeChange}
                        handleMaskOptionsChange={handleMaskOptionsChange}
                        maskType={props.selectedMaskType}
                      >
                      </NewItemsRow>
                    </>
                  )
                }

                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>PAP Unit</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.pap_unit}
                  name="pap_unit"
                  onChange={handleChange}
                >
                  {(PAP_UNITS || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormControlLabel
                  name="equipment_functioning"
                  control={
                    <Checkbox
                      className={props.classes.inlineBlock}
                      checked={formFields.equipment_functioning}
                      onChange={handleChange}
                    />
                  }
                  label="Equipment Functioning"

                />
                <FormControlLabel
                  name="ins_payer_verified"
                  control={
                    <Checkbox
                      className={props.classes.inlineBlock}
                      checked={formFields.ins_payer_verified}
                      onChange={handleChange}
                    />
                  }
                  label="INS. Payer Verified"
                />
                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>Documents Requested</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.documents_requested}
                  name="documents_requested"
                  onChange={handleChange}
                  multiple
                >
                  {(DOCUMENTS_REQUESTED || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>PT Co-Payment</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.pt_co_payment}
                  name="pt_co_payment"
                  onChange={handleChange}
                >
                  {(PT_CO_PAYMENTS || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
                <FormLabel className={props.classes.mt10} component="legend">
                  <strong>DF Patient Notification</strong>
                </FormLabel>
                <Select
                  fullWidth
                  value={formFields.df_patient_notification}
                  name="df_patient_notification"
                  onChange={handleChange}
                >
                  {(DF_PATIENT_NOTIFICATIONS || []).map(d => (
                    <MenuItem
                      style={{ fontSize: 11, fontWeight: 600 }}
                      key={d.code}
                      value={d.code}>
                      {d.lbl.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>

                <FormControlLabel
                  name="confirmed_all_demo_and_order"
                  control={
                    <Checkbox
                      className={props.classes.inlineBlock}
                      checked={formFields.confirmed_all_demo_and_order}
                      onChange={handleChange}
                    />
                  }
                  label="Confirmed All Demo/Order"
                />

                <TextField
                  label="Additional Notes"
                  fullWidth
                  variant="standard"
                  multiline
                  rows={2}
                  onChange={handleChange}
                  placeholder="Additional Notes"
                  name="additional_notes"
                  value={formFields.additional_notes}
                />
              </FormGroup>
            </Grid>
          </Grid>
          <div />
        </div>
        <Grid
          container
          justify="flex-end"
          className={props.classes.modalStickyComponentTop}
        >
          <Grid item style={{ padding: 8 }}>
            <Button
              variant="contained"
              onClick={props.handleCloseClick}
              color="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleSaveClick}
              className={props.classes.button}
              disabled={props.isSaving}
            >
              Save Notes to HDMS
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
