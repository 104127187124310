/*

store shape:
ubList:{
  records:[],
  stats: null,
  kind:"initials",
  payerGroup: "ccs",
  isFetching: false,
  activityUpdating: false,
  posOfActivity: 0,
  isValidating: false,
  validationError: false
},
*/

export function fetchUBList_ac(params) {
  return (dispatch, getState) => { //params passed in by thunk middleware
    dispatch({type:'REQUEST_UB_LIST', params})
    return window.sch.post('/api/unbilled/list',params)
    .then( result => {
      dispatch({
        type: 'RESPONSE_UB_LIST',
        records: result.pkg.rr,
        pagination: result.pkg.pagination
      })
    })
    .catch(error => {
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_UB_LIST',
        error
      })
    })
  }
}

export function fetchCcsPkg_ac(account) {
  return (dispatch, getState) => {
    dispatch({type:'REQUEST_CCS_PKG', account})
    return window.sch
    .get(`/api/ccs_sars/account/${account}`)
    .then( result => {
      dispatch({
        type: 'RESPONSE_CCS_PKG',
        ccsPkg: result.ccsPkg
      })
    })
    .catch(error => {
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_CCS_PKG',
        error
      })
    })
  }
}

export function fetchSarDoc_ac(sarNum,payerGroup) {
  return (dispatch, getState) => { //params passed in by thunk middleware
    dispatch({type:'GET_SAR_DOC'})
    return window.sch.get(`/api/unbilled/getpa/${payerGroup}/${sarNum}`)
    .then(result => {
      dispatch({
        type: 'GOT_SAR_DOC',
        sarDoc: result.sarDoc
      })
    })
    .catch(error => {
      console.log("**FAIL******",error)
    })
  }
}


export function markUB_ac(params) {
  return (dispatch, getStore) => { //params passed in by thunk middleware
    dispatch({type:'MARK_UB', params})
    return window.sch.post('/api/unbilled/mark',params)
    .then( result => {
      dispatch({
        type: 'RES_MARK_UB',
        pkg: result.pkg
      })
    })
    .catch(error =>{
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_MARK_UB',
        error
      })
    })
  }
}

export function fetchUBStats_ac(params) {
  return (dispatch, getState) => { //params passed in by thunk middleware

    dispatch({type:'REQUEST_UB_STATS', params})
    return window.sch.get(`/api/unbilled/stats/${params.payer_group}`)
    .then( result => {
      dispatch({
        type: 'RESPONSE_UB_STATS',
        stats: result.pkg.stats,
      })
    })
    .catch( error => {
      console.log("**FAIL******",error)
      dispatch({
        type: 'FAILED_UB_STATS',
        error
      })
    })
  }
}
