export function ivrInfo(
  state={
    isFetching:false,
    idnAccountPosition:0,
    acctMember: null,
    error: null,
    pkg: null
  },
  action
){
  switch (action.type) {
    case 'RESET_IVR_INFO':
      return Object.assign({}, state, {
        idnAccountPosition:0,
        isFetching:false,
        acctMember: null,
        error: null,
        pkg: null
      })
    case 'REQUEST_IVR_INFO':
      return Object.assign({}, state, {
        isFetching: true,
        idnAccountPosition: action.idnAccountPosition,
        acctMember: action.acctMember,
        error: null,
      })
    case 'RECEIVED_IVR_INFO':
      return Object.assign({}, state, {
         pkg: action.pkg,
         error: null,
         isFetching: false
       })
    case 'FAILED_RECEIVED_IVR_INFO':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })

    default:
      return state
  }
}
