export const ROUTE_GROUPS = [
  {
    groupLabel: 'Admin',
    groupIcon: 'supervisor_account',
    pages: [
      {
        label: 'Client-apps communication management',
        subPages: [
          {
            label: 'Apps',
            path: '/admin/comm_mgt/client_apps/apps',
          },
          {
            label: 'Onboard',
            path: '/admin/comm_mgt/client_apps/onboard',
          },
        ],
      },
    ],
  },
  {
    groupLabel: 'Index',
    groupIcon: 'list',
    pages: [
      {
        label: 'Done',
        path: '/idx/done',
      },
      {
        label: 'Fax Received',
        path: '/idx/fax_received',
      },
      {
        label: 'From Summary',
        path: '/idx/from_summary',
      },
      {
        label: 'In Progress',
        path: '/idx/in_progress',
      },
      {
        label: 'Last 72 Hours',
        path: '/idx/last_72',
      },
      {
        label: 'Ready to Index',
        path: '/idx/ready_to_index',
      },
      {
        label: 'Summary',
        path: '/idx/summary',
      },
      {
        label: 'Trashed',
        path: '/idx/trashed',
      },
    ],
  },
  {
    groupLabel: 'Mail-Boxes',
    groupIcon: 'mail',
    pages: [
      {
        label: 'Concord',
        path: '/mailboxes/concord',
      },
      {
        label: 'Gmail',
        path: '/mailboxes/gmail',
      },
      {
        label: 'Pilot',
        path: '/mailboxes/pilot',
      },
      {
        label: 'Ring Central',
        path: '/mailboxes/ringcentral',
      },
      {
        label: 'Scanner',
        path: '/mailboxes/scanner',
      },
      {
        label: 'SC-GSO',
        path: '/mailboxes/sc_gso',
      },
    ],
  },
  {
    groupLabel: 'Patient Account',
    groupIcon: 'account_box',
    pages: [
      {
        label: 'Create manual ticket',
        path: '/create_manual_ticket',
      },
      {
        label: 'Create shell',
        path: '/create_shell',
      },
    ],
  },
  {
    groupLabel: 'Reports',
    groupIcon: 'assessment',
    pages: [
      {
        label: 'WOS by Hour',
        path: '/reports/wos/hourly',
      },
      {
        label: 'Active Rentals',
        path: '/rentals/active',
      },
      {
        label: 'Adjustments',
        path: '/adjustments',
      },
      {
        label: 'Allscripts',
        path: '/reports/allscripts',
      },
      {
        label: 'Appeals',
        path: '/appeals',
      },
      {
        label: 'Careconnect',
        path: '/reports/careconnect',
      },
      {
        label: 'Compliance',
        path: '/reports/compliance',
      },
      {
        label: 'Confirmation Log',
        path: '/confirmationlog',
      },
      {
        label: 'DF Snapshots',
        path: '/reports/df_snapshots',
      },
      {
        label: 'Recurring',
        path: '/reports/recurring',
      },
      {
        label: 'PA Checks',
        path: '/reports/wos/pa_checks',
      },
      {
        label: 'DF-Workunits',
        subPages: [
          {
            label: 'All-Open',
            path: '/reports/dfworkunits/allopen',
          },
          {
            label: 'Closed',
            path: '/reports/dfworkunits/closed',
          },
          {
            label: 'Closed-Counts',
            path: '/reports/dfworkunits/closedcounts',
          },
          {
            label: 'Flow-Report',
            path: '/reports/dfworkunits/flowreport',
          },
          {
            label: 'Lifecare-Docs',
            path: '/reports/dfworkunits/lifecaredocs',
          },
          {
            label: 'Open-Counts',
            path: '/reports/dfworkunits/opencounts',
          },
        ],
      },
      {
        label: 'DF-Workunits By Date/Hours',
        path: '/reports/dfworkunits/by_date_by_hours/all',
      },
      {
        label: 'Expiring CCS-MCAL',
        path: '/reports/ccs_mcal_pa_expiring',
      },
      {
        label: 'Expiring CMNs',
        subPages: [
          {
            label: 'By Situation',
            path: '/reports/cmn_expiring_activity',
          },
          {
            label: 'By Time',
            path: '/reports/cmn_expiring',
          },
        ],
      },
      {
        label: 'Expiring PAs',
        path: '/reports/pa_expiring',
      },
      {
        label: 'GSO Serial',
        path: '/reports/gso/serial',
      },
      {
        label: 'Killed Items',
        path: '/reports/killed_items',
      },
      {
        label: 'Mednec Issue Log',
        path: '/mednec_issuelog',
      },
      {
        label: 'MR Request List',
        path: '/outbound_requests',
      },
      {
        label: 'OPS Forms',
        path: '/reports/ops_forms',
      },
      {
        label: 'Onboarded Accounts',
        path: '/reports/onboarded',
      },
      {
        label: 'Open Orders',
        path: '/reports/dtickets/stats',
      },
      {
        label: 'Parachute',
        path: '/reports/parachute_new',
      },
      {
        label: 'Patient Letters',
        path: '/patient_letters',
      },
      {
        label: 'Patient Portal Registrations',
        path: '/pp_registrations',
      },
    ],
  },
  {
    groupLabel: 'SalesDash Home',
    groupIcon: 'dashboard',
    pages: [
      {
        label: 'My Brown List',
        path: '/salesdash/home/my_brown_list',
      },
      {
        label: 'My Buckets',
        path: '/salesdash/home/my_buckets',
      },
      {
        label: 'My History',
        path: '/salesdash/home/my_history',
      },
      {
        label: 'My Progress',
        path: '/salesdash/home/my_progress',
      },
    ],
  },
  {
    groupLabel: 'Teams-Grid',
    groupIcon: 'people_outline',
    pages: [
      {
        label: 'All Teams',
        path: '/teamsgrid/all',
      },
      {
        label: 'Back-End Teams',
        path: '/teamsgrid/back',
      },
      {
        label: 'Front-End Teams',
        path: '/teamsgrid/front',
      },
      {
        label: 'Index Log',
        path: '/teamsgrid/idx_log',
      },
      {
        label: 'My Teams',
        path: '/teamsgrid/mineOnly',
      },
      {
        label: 'User Management Log',
        path: '/teamsgrid/user_mgt_log',
      },
    ],
  },

  {
    groupLabel: 'Dt-Pages',
    groupIcon: 'build',
    pages: [
      {
        label: 'Dt-list',
        path: '/dt',
      },
    ],
  },
  {
    groupLabel: 'DF Tasks',
    groupIcon: 'build',
    pages: [
      {
        label: 'Card View',
        path: '/todos',
      },
      {
        label: 'Lite View',
        path: '/todos_lite',
      },
      {
        label: 'Table View',
        path: '/todos_table',
      },
      {
        label: 'Summary',
        path: '/todos_summary',
      },
    ],
  },
  {
    groupLabel: 'Tools',
    groupIcon: 'build',
    pages: [
      {
        label: 'Lookups',
        path: '/lookups',
      },
      {
        label: 'Mapping Tool',
        path: '/mappingtool',
      },
      {
        label: 'Pricing Calculation',
        path: '/pricingcalc',
      },
      {
        label: 'Serial No. Lookup',
        path: '/sn_lookup',
      },
      {
        label: 'Forms Module',
        path: '/forms',
      },
    ],
  },
  {
    groupLabel: 'Others',
    groupIcon: 'more_horiz',
    pages: [
      {
        label: 'Careconnect',
        path: '/careconnect',
      },
      {
        label: 'Careplans',
        path: '/careplans',
      },
      {
        label: 'Correspondence',
        path: '/correspondence',
      },
      {
        label: 'DCs',
        path: '/dcs',
      },
      {
        label: 'Dev-Only',
        path: '/dev_only',
      },
      {
        label: 'DTrack-Workorder',
        path: '/dtrack/wo',
      },
      {
        label: 'My-Teams',
        path: '/myteams',
      },
      {
        label: 'Equipment Counts',
        path: '/oxygen_counts',
      },
      {
        label: 'PAs',
        subPages: [
          {
            label: 'Errors',
            path: '/pa_errors',
          },
          {
            label: 'Expiring',
            path: '/pa_expiring',
          },
        ],
      },
      {
        label: 'Plans of Service',
        path: '/plans_of_service',
      },
      {
        label: 'Teams',
        path: '/teams',
      },
      {
        label: 'Todos',
        subPages: [
          {
            label: 'Card View',
            path: '/todos',
          },
          {
            label: 'Lite View',
            path: '/todos_lite',
          },
          {
            label: 'Table View',
            path: '/todos_table',
          },
          {
            label: 'Summary',
            path: '/todos_summary',
          },
        ],
      },
      {
        label: 'Filebound',
        subPages: [
          {
            label: 'FB Patient Search',
            path: '/fb/search',
          },
          {
            label: 'FB Accounting Search',
            path: '/fba/search',
          },
          {
            label: 'FB Accounting Documents Indexing',
            path: '/fba/indexing',
          },
        ],
      },
      {
        label: 'Unbilled Stats',
        subPages: [
          {
            label: 'CCS',
            path: '/unbilled_stats/ccs',
          },
          {
            label: 'MCAL',
            path: '/unbilled_stats/mcal',
          },
        ],
      },
      {
        label: 'Workorders',
        path: '/workorders',
      },
    ],
  },
];
