/* eslint react/no-find-dom-node: "off", no-prototype-builtins: "off", react/forbid-foreign-prop-types: "off" */
import createReactClass from 'create-react-class'
import objectAssign from 'object-assign'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOM from 'react-dom'
import S3Upload from './S3Upload.js'

var ReactS3Uploader = createReactClass({
  propTypes: {
    onFilesAttached: PropTypes.func,
    signingUrl: PropTypes.string,
    getSignedUrl: PropTypes.func,
    preprocess: PropTypes.func,
    onSignedUrl: PropTypes.func,
    onProgress: PropTypes.func,
    onFinish: PropTypes.func,
    onError: PropTypes.func,
    signingUrlMethod: PropTypes.string,
    signingUrlHeaders: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    signingUrlQueryParams: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.func
    ]),
    signingUrlWithCredentials: PropTypes.bool,
    uploadRequestHeaders: PropTypes.object,
    contentDisposition: PropTypes.string,
    server: PropTypes.string,
    scrubFilename: PropTypes.func,
    s3path: PropTypes.string,
    inputRef: PropTypes.func,
    autoUpload: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      preprocess: function(file, next) {
        console.log('Pre-process: ' + file.name)
        next(file)
      },
      onSignedUrl: function(signingServerResponse) {
        console.log('Signing server response: ', signingServerResponse)
      },
      onProgress: function(percent, message) {
        console.log('Upload progress: ' + percent + '% ' + message)
      },
      onFinish: function(signResult) {
        console.log('Upload finished: ' + signResult.publicUrl)
      },
      onError: function(message) {
        console.log('Upload error: ' + message)
      },
      server: '',
      signingUrlMethod: 'GET',
      scrubFilename: function(filename) {
        return filename.replace(/[^\w\d_\-.]+/gi, '')
      },
      s3path: '',
      autoUpload: true
    }
  },

  uploadFiles: function(files, extraParams) {
    this.myUploader = new S3Upload({
      fileElement: null,
      files,
      signingUrl: this.props.signingUrl,
      getSignedUrl: this.props.getSignedUrl,
      preprocess: this.props.preprocess,
      onSignedUrl: this.props.onSignedUrl,
      onProgress: this.props.onProgress,
      onFinishS3Put: this.props.onFinish,
      onError: this.props.onError,
      signingUrlMethod: this.props.signingUrlMethod,
      signingUrlHeaders: this.props.signingUrlHeaders,
      signingUrlQueryParams: extraParams
        ? { ...this.props.signingUrlQueryParams, ...extraParams }
        : this.props.signingUrlQueryParams,
      signingUrlWithCredentials: this.props.signingUrlWithCredentials,
      uploadRequestHeaders: this.props.uploadRequestHeaders,
      contentDisposition: this.props.contentDisposition,
      server: this.props.server,
      scrubFilename: this.props.scrubFilename,
      s3path: this.props.s3path
    })
  },

  getInitialState: function() {
    return { files: [] }
  },

  abort: function() {
    this.myUploader && this.myUploader.abortUpload()
  },

  clear: function() {
    clearInputFile(ReactDOM.findDOMNode(this))
  },

  render: function() {
    return React.createElement('input', this.getInputProps())
  },

  onFilesAttached(files) {
    console.log('got files attached in ReactS3UploaderSC', files)
    console.log(this.props)
    this.props.onFilesAttached(files)
    this.setState({ files })
    if (this.props.autoUpload) {
      this.uploadFiles(files)
    }
    return
  },

  getInputProps: function() {
    var additional = {
      type: 'file',
      ref: this.props.inputRef
    }

    /* not used with dropzone
      if ( this.props.autoUpload ) {
        additional.onChange = this.uploadFile;
      }
      */

    var temporaryProps = objectAssign({}, this.props, additional)
    var inputProps = {}
    var invalidProps = Object.keys(ReactS3Uploader.propTypes)
    for (var key in temporaryProps) {
      if (
        temporaryProps.hasOwnProperty(key) &&
        invalidProps.indexOf(key) === -1
      ) {
        inputProps[key] = temporaryProps[key]
      }
    }

    return inputProps
  }
})

// http://stackoverflow.com/a/24608023/194065
function clearInputFile(f) {
  if (f.value) {
    try {
      f.value = '' //for IE11, latest Chrome/Firefox/Opera...
    } catch (err) {
      console.error("failed to clear f.value") 
    }
    if (f.value) {
      //for IE5 ~ IE10
      var form = document.createElement('form'),
        parentNode = f.parentNode,
        ref = f.nextSibling
      form.appendChild(f)
      form.reset()
      parentNode.insertBefore(f, ref)
    }
  }
}

export default ReactS3Uploader
