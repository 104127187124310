import { actionTypes } from '../actions/new_patient_record.ac'

export const newPatientRecord = (
  state = {
    record: null,
    isFetching: false,
    allScriptsReferral: null,
    error: null,
    selected_value: null,
    selected_property: null,
    selected_field: null
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_NEW_PATIENT_RECORD:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case actionTypes.RECEIVED_NEW_PATIENT_RECORD:
      return {
        ...state,
        record: action.record,
        error: null,
        allScriptsReferral: action.allScriptsReferral || null
      }
    case actionTypes.FAILED_NEW_PATIENT_RECORD:
      return {
        ...state,
        record: null,
        error: action.error
      }
    case actionTypes.SET_SELECTED_VALUE:
      return {
        ...state,
        selected_value: action.selected_value,
        record: action.record
      }
    case actionTypes.SET_SELECTED_PROPERTY:
      return {
        ...state,
        selected_property: action.property,
        selected_field: action.selected_field
      }
    default:
      return state
  }
}
