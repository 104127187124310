import { actionTypes } from '../actions/adjustment_requests_report.ac'

export function adjRequestsReport(
  state = {
    isFetchingReport: false,
    report: null,
    error: null
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_ADJ_REQUESTS_REPORT:
      return {
        ...state,
        isFetchingReport: action.isFetching
      }

    case actionTypes.RECEIVED_ADJ_REQUESTS_REPORT:
      return {
        ...state,
        report: action.report,
        error: null
      }

    case actionTypes.FAILED_ADJ_REQUESTS_REPORT:
      return {
        ...state,
        error: action.error,
        report: null
      }

    default:
      return state
  }
}
