import React, { useState } from 'react';
import { AiOutlineCopy } from 'react-icons/ai';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { en } from '../../constants/lang';

const styles = () => ({
  copy: {
    cursor: 'pointer',
    marginLeft: '2px'
  }
});

const CopyToClipboardButton = props => {
  const { text, classes } = props;

  const [title, setTitle] = useState(false);

  const handleCopyClick = text => {
    navigator.clipboard.writeText(text);
    setTitle(prev => !prev);

    setTimeout(() => {
      setTitle(false);
    }, 1000);
  };

  return (
    <Tooltip title={title ? en.copied : en.copy} placement="top">
      <AiOutlineCopy
        onClick={() => handleCopyClick(text)}
        className={classes.copy}
      />
    </Tooltip>
  );
};

export default withStyles(styles)(CopyToClipboardButton);
