import { orange, purple } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac } from '../../actions/me.ac.js'
import { open_snack_ac } from '../../actions/snack.ac.js'
import {
  fetchTodosSummary_ac,
  fetchTodos_ac,
  locallyUpdateTodoDoc_ac,
  resetTodos_ac,
  toggleAllCards_ac
} from '../../actions/todos.ac.js'
import TodoCard from './TodoCard'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

class TodosLandingSingle_ extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateFilters: [
        { nm: 'NEW', checked: true },
        { nm: 'PENDING', checked: true },
        { nm: 'CLOSED', checked: false }
      ],
      categoryFilters: [],
      ownershipFilter: 'Assigned to Me'
    }
    this.handleStateFilterChange = this.handleStateFilterChange.bind(this)
    this.handleCatFilterChange = this.handleCatFilterChange.bind(this)
    this.handleOwnershipFilterSelected = this.handleOwnershipFilterSelected.bind(
      this
    )
    this.gotoTodos = this.gotoTodos.bind(this)
    this.onLocalUpdate = this.onLocalUpdate.bind(this)
  }
  onLocalUpdate(posInSet, todoDoc) {
    this.props.locallyUpdateTodoDoc_ac(posInSet, todoDoc)
  }

  componentDidMount() {
    const { todosInView, lookups } = this.props
    if (this.props.me.user === null) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k
    if (this.state.categoryFilters.length === 0) {
      this.setState({
        categoryFilters: TODO_STATE_REF.map(cat => {
          return { ...cat, checked: true }
        })
      })
      return
    }

    if (todosInView.params === null) {
      this.fetch()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { todosInView, lookups } = this.props
    if (this.props.me.user === null) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k
    if (this.state.categoryFilters.length === 0) {
      this.setState({
        categoryFilters: TODO_STATE_REF.map(cat => {
          return { ...cat, checked: true }
        })
      })
      return
    }

    let filtersChanged =
      prevState.stateFilters
        .filter(s => s.checked)
        .map(s => s.nm)
        .join(',') !==
        this.state.stateFilters
          .filter(s => s.checked)
          .map(s => s.nm)
          .join(',') ||
      (prevState.categoryFilters.length > 0
        ? prevState.categoryFilters
            .filter(s => s.checked)
            .map(s => s.code)
            .join(',') !==
          this.state.categoryFilters
            .filter(s => s.checked)
            .map(s => s.code)
            .join(',')
        : false) ||
      prevState.ownershipFilter !== this.state.ownershipFilter

    if (todosInView.params === null || filtersChanged) {
      this.fetch()
    }
  }

  fetch() {
    /*
    let final_states = _.flatten(TODO_STATE_REF.map(s => s.states))
      .filter(s => s.final)
      .map(s => s.nm)
      .join(',')
    */

    if (this.props.match.params.todoId) {
      return this.props.fetchTodos_ac({
        by: 'ids',
        ids: [parseInt(this.props.match.params.todoId)]
      })
    }

    return
  }

  handleOwnershipFilterSelected(evt) {
    this.setState({
      ownershipFilter: evt.target.value
    })
  }

  handleStateFilterChange(evt) {
    let _sf = this.state.stateFilters.map(s => {
      let _s = Object.assign({}, s)
      if (evt.target.value === _s.nm) {
        _s.checked = evt.target.checked
      }
      return _s
    })
    this.setState({ stateFilters: _sf })
  }

  handleCatFilterChange(evt) {
    let _sf = this.state.categoryFilters.map(s => {
      let _s = Object.assign({}, s)
      if (evt.target.value === _s.code) {
        _s.checked = evt.target.checked
      }
      return _s
    })
    this.setState({ categoryFilters: _sf })
  }

  gotoTodos() {
    this.props.resetTodos_ac()
    this.props.changePage('/todos')
  }

  render() {
    const { classes, todosInView, lookups } = this.props
    if (
      !lookups.ink ||
      null === todosInView.params ||
      todosInView.records === null
    ) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....</h4>
        </div>
      )
    }

    if (todosInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      )
    }

    const { summary } = todosInView

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        {/* BEGIN TODO CARDS LIST */}
        <Grid item xs={12} sm={7} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Typography
                onClick={this.gotoTodos}
                className={classes.pageStatement}
                color="textSecondary">
                View My Task List
              </Typography>
              <Typography
                className={classes.pageStatement}
                color="textSecondary">
                <i>Task #</i>: {this.props.match.params.todoId}
              </Typography>
            </div>

            <div className={classes.scrollArea}>
              <div className={classes.root}>
                {todosInView.records.map((todoDoc, posInSet) => {
                  return (
                    <TodoCard
                      key={todoDoc._id + '-' + posInSet}
                      expanded={
                        todoDoc.expanded === undefined || todoDoc.expanded
                      }
                      todoDoc={todoDoc}
                      onLocalUpdate={this.onLocalUpdate}
                      posInSet={posInSet}
                    />
                  )
                })}
              </div>
            </div>
          </main>
        </Grid>
        {/* END TODO CARDS LIST */}

        <Grid item xs={12} sm={5} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Typography
                className={classes.pageStatement}
                color="textSecondary">
                Patient Task Form
              </Typography>
            </div>

            <div className={classes.scrollArea}>
              <h5>
                To create a new task, search for patient and click on Tasks tab.
              </h5>
              <div className={classes.root}>
                {summary && (
                  <div>
                    <label className={classes.label}>Open Task Summary:</label>
                    <hr />

                    <label className={classes.label}>By Status</label>
                    {summary.by_state.map((r, kIdx) => (
                      <div key={kIdx} className={classes.summaryEntryWrap}>
                        <span className={classes.summaryEntry}>
                          {r.k} ({r.n})
                        </span>
                        <div className={classes.summaryCountWrap}>
                          {/*
                        r.ee.map(e=>(
                          <div className={classes.summaryCount}>{e.owner} ({e.n})</div>
                        ))
                        */}
                        </div>
                      </div>
                    ))}

                    <div className={classes.spacer} />

                    <label className={classes.label}>By Assignee</label>
                    {summary.by_owner.map((r, kIdx) => (
                      <div key={kIdx} className={classes.summaryEntryWrap}>
                        <span className={classes.summaryEntry}>
                          {r.k} ({r.n})
                        </span>
                        <div className={classes.summaryCountWrap}>
                          {/*
                        r.ee.map(e=>(
                          <div className={classes.summaryCount}>{e.state} ({e.n})</div>
                        ))
                        */}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
    )
  }
}

class MobileTodosLandingSingle_ extends TodosLandingSingle_ {
  render() {
    const { classes, todosInView, lookups } = this.props
    if (
      !lookups.ink ||
      null === todosInView.params ||
      todosInView.records === null
    ) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....</h4>
        </div>
      )
    }

    if (todosInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      )
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        {/* BEGIN TODO CARDS LIST */}
        <Grid item xs={12} sm={7} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.scrollArea}>
              <div className={classes.root}>
                {todosInView.records.map((todoDoc, posInSet) => {
                  return (
                    <TodoCard
                      key={todoDoc._id + '-' + posInSet}
                      expanded={
                        todoDoc.expanded === undefined || todoDoc.expanded
                      }
                      todoDoc={todoDoc}
                      onLocalUpdate={this.onLocalUpdate}
                      posInSet={posInSet}
                    />
                  )
                })}
              </div>
            </div>
          </main>
        </Grid>
        {/* END TODO CARDS LIST */}
      </Grid>
    )
  }
}

TodosLandingSingle_.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  todosInView: stateFromStore.todosInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTodos_ac,
      fetchTodosSummary_ac,
      resetTodos_ac,
      locallyUpdateTodoDoc_ac,
      toggleAllCards_ac,
      open_snack_ac,
      lookups_ac,
      changePage: path => push(path)
    },
    dispatch
  )

const TodosLandingSingle = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles(theme))(TodosLandingSingle_))
)

const MobileTodosLandingSingle = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles(theme))(MobileTodosLandingSingle_))
)

export { TodosLandingSingle, MobileTodosLandingSingle }
