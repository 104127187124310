export function open_snack_ac(params) {
  return (dispatch, getState) => {
    if (getState().snack.open) {
      //close it first
      dispatch({ type: 'CLOSE_SNACK', ...params })
      return setTimeout(() => {
        dispatch({ type: 'OPEN_SNACK', ...params })
      }, 300)
    }
    dispatch({ type: 'OPEN_SNACK', ...params })
  }
}
export function close_snack_ac(params) {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_SNACK', ...params })
  }
}

export function open_notif_snack_ac(params) {
  return (dispatch, getState) => {
    let _snack = getState().snack

    if (_snack.notifsPanelOpen) {
      console.log("Don't fire when panel is open")
      return
    }

    if (_snack.open) {
      //close it first
      dispatch({ type: 'CLOSE_NOTIF_SNACK', ...params })
      return setTimeout(() => {
        dispatch({ type: 'OPEN_NOTIF_SNACK', ...params })
      }, 300)
    }
    dispatch({ type: 'OPEN_NOTIF_SNACK', ...params })
  }
}
export function close_notif_snack_ac(params) {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_NOTIF_SNACK', ...params })
  }
}

export function openNotifsPanel_ac() {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_NOTIF_SNACK' })
    dispatch({ type: 'OPEN_NOTIFS_PANEL' })
  }
}

export function closeNotifsPanel_ac(params) {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_NOTIFS_PANEL', ...params })
  }
}
