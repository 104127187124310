export const actionTypes = {
  OPEN_CONFIRMATION_DIALOG: 'OPEN_CONFIRMATION_DIALOG',
  CLOSE_CONFIRMATION_DIALOG: 'CLOSE_CONFIRMATION_DIALOG',
}

export const open_confirmation_dialog_ac = params => {
  return (dispatch, getState) => {
    if (getState().confirmationDialog.open) return
    dispatch({ type: actionTypes.OPEN_CONFIRMATION_DIALOG, params })
  }
}

export const close_confirmation_dialog_ac = () => {
  return dispatch => {
    dispatch({ type: actionTypes.CLOSE_CONFIRMATION_DIALOG })
  }
}
