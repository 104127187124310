import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push, replace } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import FetchingStatementWrap from '../FetchingStatementWrap';
import ParachuteImportNew from './ParachuteImportNew';
import { ParachuteReportNew } from './ParachuteReportNew';

const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {},
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  fullHeight: {
    height: '100%',
  },
});

const PAGE_TITLE = 'Parachute - Report';
const BASE_PATH = '/reports/parachute_new';

class ParachuteLandingNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isImporting: false,
      importResult: null,
      err: null,
      importErr: null,
      selectedOrder: null,
    };
  }

  componentDidMount() {
    this.checkSituation();
  }
  componentDidUpdate() {
    this.checkSituation();
  }
  checkSituation() {
    const { lookups, me, setTitle_ac } = this.props;
    if (!me.user) {
      return;
    }

    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return this.props.lookups_ac();
    }

    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE);
    }

    const { match, redirectToPage } = this.props;
    if (isNaN(match.params.pageNum)) {
      redirectToPage(1);
    }
  }

  showDocImportForm = (orderData) => {
    this.setState({
      selectedOrder: orderData ? { ...orderData } : null,
    });
  };

  cancelImport = () => {
    this.setState({
      selectedOrder: null,
    });
  };

  parseDocs = (pkg, docs, orderId) => {
    return docs
      .filter((doc) => doc.state !== 'done')
      .map((doc, idx) => ({
        type: doc.type,
        position: idx,
        meta: {
          autoindexRequest: `#autoindex #${pkg.idn} #${doc.type}`,
          assignToTeam: pkg.team,
          assignToUser: pkg.member,
          modeLabels: ['PARACHUTE', orderId.toString()],
          productLabel: pkg.product,
          tmLabel: pkg.rae || '',
        },
      }));
  };

  render() {
    const { me, lookups, classes, match, changePageNum } = this.props;
    const { selectedOrder } = this.state;

    if (!lookups.ink) {
      return <div>...</div>;
    }

    let title = PAGE_TITLE;
    if (this.state.err) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'Something went wrong. Contact IT'}
        />
      );
    }

    /*
    if (){
      return <FetchingStatementWrap title={title} msg={'Loading...'} />
    }
    if (pkg === null) {
      return <FetchingStatementWrap title={title} msg={'..loading data'} />
    }
    */

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} md={7} className={classes.fullHeight}>
          <ParachuteReportNew
            me={me}
            currentPage={parseInt(match.params.pageNum, 10) || 1}
            changePage={changePageNum}
            orderIdOnSelection={selectedOrder ? selectedOrder._id : ''}
            isImporting={this.state.isImporting}
            onImportClick={this.showDocImportForm}
            cancelImport={this.cancelImport}
          />
        </Grid>
        <Grid item xs={12} md={5} className={classes.fullHeight}>
          <div id="teamPcrSelectorPopperParent" className={classes.content}>
            {selectedOrder === null ? (
              <Typography variant="body2" color="textSecondary" align="center">
                Click the IMPORT DOCS button to import documents for referral.
              </Typography>
            ) : (
              <ParachuteImportNew
                selectedOrderId={selectedOrder._id}
                isImporting={this.state.isImporting}
                onCancelImport={this.cancelImport}
              />
            )}
          </div>
        </Grid>
      </Grid>
    );
  }
}

ParachuteLandingNew.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,

  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      lookups_ac,
      open_snack_ac,
      setTitle_ac,
      changePageNum: (newPageNum) => push(`${BASE_PATH}/${newPageNum}`),
      redirectToPage: (newPageNum) => replace(`${BASE_PATH}/${newPageNum}`),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ParachuteLandingNew))
);
