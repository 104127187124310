import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import DnsIcon from '@material-ui/icons/Dns'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import GroupIcon from '@material-ui/icons/Group'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { getNavList_ac } from '../../actions/dfa.ac.js'
import { lookups_ac } from '../../actions/me.ac.js'
import {
  closeNotifsPanel_ac,
  openNotifsPanel_ac,
  open_snack_ac
} from '../../actions/snack.ac.js'

const styles = theme => ({
  root: {
    width: '100%',
    color: 'brown',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  primary: {
    fontSize: '10px',
    color: 'red'
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  link: {
    textDecoration: 'none'
  }
})

function getIcon(icon) {
  switch (icon) {
    case 'dns':
      return <DnsIcon />
    case 'insertChart':
      return <InsertChartIcon />
    default:
      return <GroupIcon />
  }
}

const v2WuMobileMenu = {
  lbl: 'DFM',
  icon: 'insertChart',
  items: [
    {
      noPagePath: true,
      nm: 'Mobile Summary',
      pathBase: '/dfm/workunits_summary',
      searchPath: '',
    },
    {
      noPagePath: true,
      nm: 'Open Listing',
      pathBase: '/dfm/open_workunits',
      searchPath: '',
    },
  ],
};

class SubMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.openState || false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }))
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <ListItem button onClick={this.handleClick}>
          <ListItemIcon>{getIcon(this.props.icon)}</ListItemIcon>
          <ListItemText inset primary={this.props.menu.lbl} />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {this.props.menu.items.map(n => (
              <NavLink
                className={classes.link}
                onClick={this.props.close}
                key={n.pathBase + n.searchPath}
                to={
                  n.noPagePath
                    ? n.pathBase + n.searchPath
                    : n.pathBase + n.searchPath + '/1'
                }>
                <ListItem button>
                  <ListItemText
                    inset
                    className={classes.primary}
                    primary={n.nm}
                  />
                </ListItem>
              </NavLink>
            ))}
          </List>
        </Collapse>
      </>
    )
  }
}

class DfaNavList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: true
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }))
  }
  componentDidMount() {
    if (!this.props.navList.loaded) {
      console.log('getting navList')
      return this.props.getNavList_ac()
    }
  }

  componentDidUpdate() {}

  render() {
    const { classes, navList } = this.props

    if (this.props.mobile) {
      return (
        <div className={classes.root}>
          <List component="nav" />
        </div>
      )
    }
    return (
      <div className={classes.root}>
        <List component="nav">
          {navList.baseItems.map(n => (
            <NavLink
              className={classes.link}
              onClick={this.props.close}
              key={n.pathBase + n.searchPath}
              to={
                n.noPagePath
                  ? n.pathBase + n.searchPath
                  : n.pathBase + n.searchPath + '/1'
              }>
              <ListItem button>
                <ListItemText className={classes.primary} primary={n.nm} />
              </ListItem>
            </NavLink>
          ))}
          <SubMenu
            icon={v2WuMobileMenu.icon}
            openState={v2WuMobileMenu.openState || false}
            menu={v2WuMobileMenu}
            key={v2WuMobileMenu.lbl}
            classes={classes}
          />
          {navList.menus.map(m => (
            <SubMenu
              icon={m.icon}
              openState={m.openState || false}
              menu={m}
              key={m.lbl}
              classes={classes}
            />
          ))}
          {navList.secondaryBaseItems &&
            navList.secondaryBaseItems.map((n, ni) => {
              if (n.onClick) {
                return (
                  <ListItem key={ni} onClick={this.props[n.onClick]} button>
                    <ListItemText className={classes.primary} primary={n.nm} />
                  </ListItem>
                )
              }

              return (
                <NavLink
                  className={classes.link}
                  onClick={this.props.close}
                  key={n.pathBase + n.searchPath}
                  to={
                    n.noPagePath
                      ? n.pathBase + n.searchPath
                      : n.pathBase + n.searchPath + '/1'
                  }>
                  <ListItem button>
                    <ListItemText className={classes.primary} primary={n.nm} />
                  </ListItem>
                </NavLink>
              )
            })}
        </List>
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  dfaInView: stateFromStore.dfaInView,
  navList: stateFromStore.navList
})

const mapDispatchToProps = (dispatch, propsOfDfaNavList) =>
  bindActionCreators(
    {
      lookups_ac,
      getNavList_ac,
      open_snack_ac,
      openNotifsPanel_ac,
      closeNotifsPanel_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DfaNavList))
)
