import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Grid, Typography } from '@material-ui/core';

import { open_snack_ac } from '../../../actions/snack.ac';
import { getErrorMessage } from '../../../util/string';
import { LocationsField } from '../../common/LocationsField';

const LocationsView = (props) => {
  const { locations, oc, onUpdate, readonly, metaInfo, metaNote } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingLocations, setIsSavingLocations] = useState(false);

  const handleLocationChange = (loc, isAdding = true, cb) => {
    setIsSavingLocations(true);
    window.sch
      .post('/api/idx/admin/oc/change_loc', {
        oc_id: oc._id,
        loc_id: loc._id || loc.loc_id,
        loc_start: loc.s || null,
        loc_end: loc.e || null,
        meta_info: metaInfo,
        meta_note: metaNote,
      })
      .then((res) => {
        props.open_snack_ac({
          variant: 'success',
          message: `Location ${isAdding ? 'added' : 'updated'} successfully!`,
        });
        onUpdate(
          Boolean(res.new_loc)
            ? {
                ...oc,
                locations: [
                  { ...loc, ...res.new_loc, new: true },
                  ...oc.locations,
                ],
              }
            : oc
        );
        setIsEditMode(false);

        if (cb) cb();
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to ${isAdding ? 'add' : 'update'} the location!`
          ),
        });
      })
      .finally(() => {
        setIsSavingLocations(false);
      });
  };

  const handleLocationAdd = (loc, isAdding, cb) => {
    handleLocationChange(loc, isAdding, cb);
  };

  const handleLocationUpdate = (updatedLocation) => {
    handleLocationChange(updatedLocation, false);
  };

  const handleLocationDelete = (loc, locIdx) => {
    setIsSavingLocations(false);
    window.sch
      .post('/api/idx/admin/oc/remove_loc', {
        oc_id: oc._id,
        loc_id: loc?._id || locations[locIdx].loc_id,
        meta_info: metaInfo,
      })
      .then((res) => {
        props.open_snack_ac({
          variant: 'success',
          message: `Location removed successfully!`,
        });
        onUpdate();
        setIsEditMode(false);
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(err, 'Failed to delete the location!'),
        });
        setIsEditMode(false);
      })
      .finally(() => {
        setIsSavingLocations(false);
      });
  };

  const handleLocationsEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <div style={{ margin: '16px 0px' }}>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: '12px' }}>
        {'Locations: '}
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={9}>
          <LocationsField
            metaInfo={metaInfo}
            readonly={readonly}
            locations={locations}
            disabled={isSavingLocations}
            editMode={isEditMode}
            onLocationAdd={handleLocationAdd}
            onLocationUpdate={handleLocationUpdate}
            onLocationDelete={handleLocationDelete}
            placeholder={
              'Search a clinician location to link with this Clinician.'
            }>
            {!readonly && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                disabled={isSavingLocations}
                style={{
                  marginTop: '12px',
                }}
                onClick={handleLocationsEditModeToggle}>
                {isEditMode ? 'Cancel' : 'Edit Locations'}
              </Button>
            )}
          </LocationsField>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      open_snack_ac,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(LocationsView);
