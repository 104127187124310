import {
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import dateUtils from '../../util/dateProcessing'

const styles = theme => ({
  row: {
    verticalAlign: 'top',
    '&.even-row': {
      backgroundColor: '#fffef4'
    },
    '&>td': {
      padding: 8
    }
  },
  scrollableCellContent: {
    maxHeight: 300,
    overflowY: 'auto'
  },
  expandClosedIcon: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard
    }),
    color: theme.palette.primary.main
  },
  expandOpenIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main
  },
  link: {
    textDecoration: 'none',
    color: '#2e61ef',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      color: '#2196f3'
    }
  },
  chip: {
    height: 24,
    borderRadius: 6,
    fontWeight: 800,
    margin: '4px 0px',
    textTransform: 'uppercase',
    '&.submitted': {
      backgroundColor: '#ffffff',
      color: '#009688',
      border: '1px solid #009688'
    },
    '&.incorrect': {
      backgroundColor: '#ff9800',
      color: '#ffffff'
    },
    '&.info': {
      backgroundColor: '#7e57c2',
      color: '#ffffff'
    },
    '&.approved': {
      backgroundColor: '#4caf50',
      color: '#ffffff'
    },
    '&.addition': {
      backgroundColor: '#42a5f5',
      color: '#ffffff'
    }
  },
  claimId: {
    padding: 2,
    backgroundColor: theme.palette.primary['50']
  },
  userName: {
    color: '#795548',
    fontWeight: 600
  },
  infoBlock: {
    marginBottom: 8
  },
  logItem: {
    backgroundColor: 'lightblue',
    padding: 4,
    marginBottom: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    '&>.comment': {
      color: '#455A64'
    }
  },
  reasonGroup: {
    padding: 4,
    marginBottom: 8,
    backgroundColor: '#f0f4c3'
  },
  noteTxt: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2'
  },
  qaGroup: {
    marginLeft: 4,
    padding: '0px 0px 4px 4px'
  },
  lineItem: {
    padding: 4,
    margin: '0px 0px 4px 4px',
    borderBottom: '2px dashed #00000050'
  }
})

const CHIP_CLASS_MAP = {
  approved: 'approved',
  info_required: 'info',
  submitted: 'submitted',
  comment_added: 'addition',
  code_incorrect: 'incorrect',
  amount_incorrect: 'incorrect',
  amount_and_code_incorrect: 'incorrect'
}

const ExpandableAdjRow = props => {
  const { rowIdx, record, isExpandedDefault, classes } = props

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault)

  useEffect(() => {
    setIsExpanded(isExpandedDefault)
  }, [isExpandedDefault])

  return (
    <TableRow
      className={classNames(classes.row, {
        "even-row": rowIdx % 2 === 0
      })}
    >
      <TableCell width="1%" style={{ padding: 2 }}>
        <IconButton
          className={classNames(classes.expandClosedIcon, {
            [classes.expandOpenIcon]: isExpanded
          })}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <ExpandMoreIcon />
        </IconButton>
      </TableCell>

      <TableCell width="17%">
        <div className={classes.scrollableCellContent}>
          <div className={classes.infoBlock}>
            <Typography variant="body2">
              <strong>REQ#: {record._id || ''}</strong>
            </Typography>
            {record.state && (
              <Chip
                color="secondary"
                label={record.state.replace(/_/g, ' ')}
                className={classNames(classes.chip, {
                  [CHIP_CLASS_MAP[record.state]]: record.state
                })}
              />
            )}
            <Typography variant="body2">
              <Link
                to={`/dfa/idn/${record.idn}/tabs/adjustments`}
                className={classes.link}
              >
                {record.idn}
              </Link>
            </Typography>
            <Typography variant="body2">
              <strong>A:</strong>
              {record.acctMember}
            </Typography>
          </div>

          {isExpanded && (
            <>
              <Typography
                variant="body2"
                component="div"
                className={classes.infoBlock}
              >
                <Typography variant="inherit" component="span" color="primary">
                  <strong>All Claim ID(s):</strong>
                </Typography>
                <div className={classes.claimId}>
                  {record.claim_ids_all?.length > 0 &&
                    record.claim_ids_all.map((clId, clIdx) => (
                      <span key={clIdx}>
                        {clId}
                        {clIdx < record.claim_ids_all.length - 1
                          ? <>,&ensp;</>
                          : ''}
                      </span>
                    ))}
                </div>
              </Typography>
              {record.summary && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="primary">
                    <strong>Summary Narrative:</strong>
                  </Typography>
                  <Typography variant="body2">
                    {record.summary}
                  </Typography>
                </div>
              )}
            </>
          )}
        </div>
      </TableCell>

      <TableCell width="14%">
        <div className={classes.scrollableCellContent}>
          <div className={classes.infoBlock}>
            <Typography variant="body2" className={classes.userName}>
              {record.u_nm}
            </Typography>
            <Typography variant="body2">
              ({record.u_id})
            </Typography>
            <div className={classes.noteTxt}>
              - created this packet {dateUtils.relativeTime(record.created_dt)}
            </div>
          </div>

          {isExpanded && (
            <>
              {record.reviewer_u_nm && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="primary">
                    <strong>Reviewer:</strong>
                  </Typography>
                  <Typography variant="body2" className={classes.userName}>
                    {record.reviewer_u_nm}
                  </Typography>
                  <Typography variant="body2">
                    ({record.reviewer_u_id || ''})
                  </Typography>
                  <div className={classes.noteTxt}>
                    - last reviewed this packet&ensp;
                    {dateUtils.relativeTime(record.ts)}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </TableCell>

      <TableCell width="28%">
        <div className={classes.scrollableCellContent}>
          {
            record.log && record.log.length ? (
              !isExpanded ? (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" inline>
                    {record.log[0].msg}
                    {record.log[1] ? <>,<br />{record.log[1].msg}</> : ''}
                  </Typography>
                  {record.log.length > 2 && (
                    <Typography variant="caption" color="textSecondary" inline>
                      ,&ensp;...and {record.log.length - 2} more
                    </Typography>
                  )}
                </div>
              ) : record.log.map((logItem, logIdx) => (
                <div key={logIdx} className={classes.logItem}>
                  <Typography variant="body2">
                    {logItem.msg}
                  </Typography>
                  {logItem.comment && (
                    <Typography variant="body2" className="comment">
                      Comment:&ensp;{logItem.comment}
                    </Typography>
                  )}
                </div>
              ))
            ) : (
                <Typography variant="body2" color="textSecondary">
                  N/A
                </Typography>
              )
          }
        </div>
      </TableCell>

      <TableCell width="40%">
        <div className={classes.scrollableCellContent}>
          {record.reasongroups && record.reasongroups.length ? (
            <>
              <div className={classes.infoBlock}>
                <Typography variant="body2">
                  Reasons:&ensp;
                  <strong>
                    {record.reasongroups.map(grp => grp.reason).join(' | ')}
                  </strong>
                </Typography>
                <Typography variant="body2">
                  <span>
                    Adj. amount requested:&ensp;
                    <strong>${parseFloat(record.totalAdj).toFixed(2)}</strong>
                  </span><br />
                  <span>
                    Adj. absolute value:&ensp;
                    <strong>${parseFloat(record.totalAbs).toFixed(2)}</strong>
                  </span>
                </Typography>
              </div>
              {isExpanded &&
                record.reasongroups.map((reasonItem, reasonIdx) => (
                  <div key={reasonIdx} className={classes.reasonGroup}>
                    <div className={classes.infoBlock}>
                      <Typography variant="body2" color="primary">
                        <strong>Reason:</strong>
                      </Typography>
                      <Typography variant="body2">
                        {reasonItem.reason}
                      </Typography>
                    </div>
                    <div className={classes.infoBlock}>
                      <Typography variant="body2" color="primary">
                        <strong>QAs:</strong>
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        <strong>
                          HDMS#{reasonItem.qa.hdms_id}&ensp;|&ensp;
                        {reasonItem.qa.kind}&ensp;|&ensp;
                        {reasonItem.qa.lbl}
                        </strong>
                      </Typography>
                      {reasonItem.qa.qq && reasonItem.qa.qq.length
                        ? reasonItem.qa.qq.map((qqItem, qqIdx) => (
                          <div key={qqIdx} className={classes.qaGroup}>
                            <Typography variant="body2" color="primary">
                              Q{qqIdx + 1})&ensp;{qqItem.lbl}
                            </Typography>
                            <Typography variant="body2">
                              Ans:&ensp;{qqItem.value}
                            </Typography>
                          </div>
                        ))
                        : 'N/A'}
                    </div>
                    <div className={classes.infoBlock}>
                      <Typography variant="body2" color="primary">
                        <strong>Lines:</strong>
                      </Typography>
                      {reasonItem.lines && reasonItem.lines.length
                        ? reasonItem.lines.map((lineItem, lineIdx) => (
                          <div key={lineIdx} className={classes.lineItem}>
                            <div>
                              <span>
                                <strong>Claim#</strong>{lineItem.claim_id}
                              &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>HCPC#</strong>{lineItem.hcpc}
                              &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>Bill#</strong>{lineItem.bill_id}
                              </span>
                            </div>
                            <div>
                              <strong>Payer:</strong>&ensp;{lineItem.payer_name}
                            </div>
                            <div>
                              <span>
                                <strong>Charge:</strong>&ensp;
                                ${parseFloat(lineItem.tx.charge).toFixed(2)}
                                &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>Allowable:</strong>&ensp;
                                ${parseFloat(lineItem.tx.allowable).toFixed(2)}
                                &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>Paid:</strong>&ensp;
                                ${parseFloat(lineItem.tx.payment).toFixed(2)}
                                &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>Adj:</strong>&ensp;
                              ${parseFloat(lineItem.tx.adj).toFixed(2)}
                              &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>Adj_Requested:</strong>&ensp;
                                ${parseFloat(lineItem.tx.adj_requested).toFixed(2)}
                                &ensp;|&ensp;
                            </span>
                              <span>
                                <strong>Balance:</strong>&ensp;
                                ${parseFloat(lineItem.tx.balance).toFixed(2)}
                              </span>
                            </div>
                          </div>
                        ))
                        : 'N/A'}
                    </div>
                  </div>
                ))}
            </>
          ) : (
              <Typography variant="body2" color="textSecondary">
                N/A
              </Typography>
            )
          }
        </div>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(ExpandableAdjRow)
