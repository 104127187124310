import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac'
import { fetchAllPatientLettersReport_ac } from '../../actions/patient_letters_report.ac'
import FilterListing from '../FilterListing'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'
import PatientLettersReportTable from './PatientLettersReportTable'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  }
})

const PAGE_TITLE = 'Patient Letters Report'
const DEFAULT_FILTERS = {
  start: {
    kind: 'dateTime',
    lbl: 'From',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false
  },
  end: {
    kind: 'dateTime',
    lbl: 'To',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false
  }
}

class PatientLettersReportLanding extends BaseLandingTemplate {
  constructor() {
    super()

    this.state = {
      filters: { ...DEFAULT_FILTERS },
      filterSequence: ['start', 'end']
    }

    this.fetch = this.fetch.bind(this)
  }

  checkSituation = method => {
    const {
      allPatientLettersReport,
      me,
      lookups,
      lookups_ac,
      setTitle_ac
    } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE)
    }

    if (method === 'mount') {
      return this.fetch()
    }

    if (
      !allPatientLettersReport.report &&
      !allPatientLettersReport.error &&
      !allPatientLettersReport.isFetchingReport
    ) {
      this.fetch()
    }
  }

  componentDidMount() {
    this.checkSituation('mount')
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  fetch(pageNum = 0) {
    const { filters } = this.state
    let params = {
      page: pageNum
    }

    if (filters.start.value) {
      params.start = moment(filters.start.value)
        .utc()
        .format()
    }

    if (filters.end.value) {
      params.end = moment(filters.end.value)
        .utc()
        .format()
    }

    this.props.fetchAllPatientLettersReport_ac(params)
  }

  applyFilters = () => {
    return this.fetch()
  }

  render() {
    const { filters, filterSequence } = this.state
    const { classes, allPatientLettersReport, me } = this.props
    const { report, isFetchingReport, error } = allPatientLettersReport

    if (isFetchingReport) {
      return <Loader classes={{ message: classes.message }} />
    }

    let filterListing = (
      <FilterListing
        me={me}
        filters={filters}
        onFilterChange={this.onFilterChange}
        filterSequence={filterSequence}
        applyFilters={this.applyFilters}
        onFilterClearField={this.onFilterClearField}
      />
    )

    if (error) {
      return (
        <>
          {filterListing}
          <Typography variant="body2" color="error" className={classes.message}>
            Error fetching report
          </Typography>
        </>
      )
    }

    if (!report) {
      return (
        <>
          {filterListing}
          <div className={classes.message}>...</div>
        </>
      )
    }

    return (
      <>
        {filterListing}
        <PatientLettersReportTable report={report} fetch={this.fetch} />
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  allPatientLettersReport: stateFromStore.allPatientLettersReport
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac,
      fetchAllPatientLettersReport_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PatientLettersReportLanding))
)
