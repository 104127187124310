export function dfSnapshots(
  state={
    params: null,
    isFetching:false,
    pkg: null,
    error:false
  },
  action
) {
  switch (action.type){

   case 'REQUEST_DF_SNAPSHOTS':
     return Object.assign({}, state, {
        params: action.params,
        isFetching: true
      })

   case 'RESPONSE_DF_SNAPSHOTS':
     return Object.assign({}, state, {
        isFetching: false,
        pkg: action.pkg
      })

   case 'FAILED_DF_SNAPSHOTS':
     return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })

    default:
      return state
  }
}
