import _ from 'lodash'

export const actionTypes = {
  FETCHING_FA_ADMIN_PLACES: 'FETCHING_FA_ADMIN_PLACES',
  RECEIVED_FA_ADMIN_PLACES: 'RECEIVED_FA_ADMIN_PLACES',
  FAILED_FETCHING_FA_ADMIN_PLACES: 'FAILED_FETCHING_FA_ADMIN_PLACES',

  SET_SELECTED_PLACE_ID: 'SET_SELECTED_PLACE_ID',

  FETCHING_FA_ADMIN_PLACE_DETAILS: 'FETCHING_FA_ADMIN_PLACE_DETAILS',
  RECEIVED_FA_ADMIN_PLACE_DETAILS: 'RECEIVED_FA_ADMIN_PLACE_DETAILS',
  FAILED_FETCHING_FA_ADMIN_PLACE_DETAILS: 'FAILED_FETCHING_FA_ADMIN_PLACE_DETAILS',

  SAVE_PLACE_SEARCH_FILTERS: 'SAVE_PLACE_SEARCH_FILTERS'
}

export const fetchFAAdminPlaces_ac = (filterData = null, page = 0) => {
  return (dispatch, getState) => {
    let params = filterData

    if (filterData === null)
      params = { ...getState().faAdminReducers.faAdminPlaceSearchFilters }

    dispatch({
      type: actionTypes.FETCHING_FA_ADMIN_PLACES
    })

    window.sch
      .post('/api/fa/admin/places/search', { ...params, page })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_FA_ADMIN_PLACES,
          places: res.pkg
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_FA_ADMIN_PLACES,
          error: error
        })
      })
  }
}

export const setSelectedPlaceId_ac = placeId => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_PLACE_ID,
      placeId: placeId || null
    })
  }
}

export const fetchPlaceDetials_ac = placeId => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCHING_FA_ADMIN_PLACE_DETAILS
    })

    window.sch
      .post('/api/fa/admin/places/detail', { _id: placeId })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_FA_ADMIN_PLACE_DETAILS,
          place: res.place
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_FA_ADMIN_PLACE_DETAILS,
          error
        })
      })
  }
}

export const updatePlaceDetailsLocally_ac = updateObj => {
  return (dispatch, getState) => {
    if (!updateObj || !_.isObject(updateObj)) return

    let placeDetials = getState().faAdminReducers.faAdminPlaceDetails

    if (!placeDetials.place) return

    dispatch({
      type: actionTypes.RECEIVED_FA_ADMIN_PLACE_DETAILS,
      place: {
        ...placeDetials.place,
        ...updateObj
      }
    })
  }
}

export const updatePlacesListLocally_ac = (placeData, remove = false) => {
  return (dispatch, getState) => {
    if (!placeData || !_.isObject(placeData)) return

    let placesListState = getState().faAdminReducers.faAdminPlaces

    if (!placesListState.places) return

    let placeIdx = placesListState.places.rr
      .findIndex(place => place._id === placeData._id)

    if (placeIdx < 0) return

    let updatedList

    if (remove) {
      updatedList = [
        ...placesListState.places.rr.slice(0, placeIdx),
        ...placesListState.places.rr.slice(placeIdx + 1)
      ]
    }
    else {
      updatedList = [
        ...placesListState.places.rr.slice(0, placeIdx),
        {
          ...placesListState.places.rr[placeIdx],
          ...placeData
        },
        ...placesListState.places.rr.slice(placeIdx + 1)
      ]
    }

    dispatch({
      type: actionTypes.RECEIVED_FA_ADMIN_PLACES,
      places: {
        ...placesListState.places,
        rr: updatedList
      }
    })
  }
}

export const savePlaceSearchFilters_ac = filterData => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_PLACE_SEARCH_FILTERS,
      filterData
    })
  }
}
