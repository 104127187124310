import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import React from 'react'

class CancelIvrForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false,
      cancelReason: ''
    }
    this.setCancelReason = this.setCancelReason.bind(this)
    this.cancel = this.cancel.bind(this)
    this.toggleDisplay = this.toggleDisplay.bind(this)
  }
  toggleDisplay() {
    this.setState({ display: !this.state.display })
  }
  cancel() {
    return this.props.onCancelIvr(this.state.cancelReason)
  }
  setCancelReason(e) {
    let str = e.target.value
    this.setState({
      cancelReason: str
    })
  }
  render() {
    const { classes } = this.props
    return (
      <div style={{ width: '100%' }}>
        <FormControl
          style={{ margin: 0 }}
          className={classes.formControl}
          fullWidth>
          <FormLabel
            onClick={this.toggleDisplay}
            style={{
              fontSize: 11,
              color: 'blue',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
            component="label">
            Click here to cancel all future IVR activity associated with this
            CMN Branch [+/-]
          </FormLabel>
        </FormControl>

        {this.state.display && (
          <>
            <FormControl
              style={{ marginLeft: 0 }}
              className={classes.formControl}
              fullWidth>
              <FormLabel onClick={this.toggleDisplay} component="label">
                Reason for Cancellation
              </FormLabel>
              <FormHelperText>
                This will get added as a note in HDMS.
              </FormHelperText>
              <TextField
                fullWidth
                multiline
                onChange={this.setCancelReason}
                value={this.state.cancelReason}
              />
            </FormControl>
            <FormControl
              style={{ marginLeft: 0 }}
              className={classes.formControl}>
              <Button
                disabled={this.state.cancelReason === ''}
                variant="contained"
                size="small"
                color="secondary"
                onClick={this.cancel}>
                Cancel Remaining IVR Activity
              </Button>
            </FormControl>
          </>
        )}
      </div>
    )
  }
}
export default CancelIvrForm;