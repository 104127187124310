import React, { useLayoutEffect, useMemo, useState } from 'react';

import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import * as routes from '../../../constants/routes';
import IdxOrderingCliniciansDetail from '../../IndexingAdmin/idxOrderingClinicians/IdxOrderingCliniciansDetail';
import IdxOrderingCliniciansFilter from '../../IndexingAdmin/idxOrderingClinicians/IdxOrderingCliniciansFilter';
import IdxOrderingCliniciansList from '../../IndexingAdmin/idxOrderingClinicians/IdxOrderingCliniciansList';
import Loader from '../../Shared/Loader';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
  },
  scrollable: {
    overflowY: 'auto',
  },
  nonLastColumn: {
    borderRight: '2px solid #c5c5c5',
  },
  fullHeight: {
    height: '100%',
  },
  gutterGrid4: {
    padding: 4,
  },
});

const searchRepsWithMail = async (email, { onSuccess, onError, after }) => {
  try {
    const res = await window.sch.post('/api/idx/admin/reps/search', { email });

    if (onSuccess) onSuccess();
    if (after) after();
    return res.pkg?.rr || [];
  } catch (err) {
    if (onError) onError(err);
    if (after) after();
    return [];
  }
};

const IdxOrderingClinicians = (props) => {
  const { classes, me, ...rest } = props;

  const userMail = me.user.mail;

  const [loadingAuthRep, setLoadingAuthRep] = useState(true);
  const [authRep, setAuthRep] = useState(false);

  useLayoutEffect(() => {
    searchRepsWithMail(userMail, {
      after: () => setLoadingAuthRep(false),
    }).then((res) => {
      if (res.length) {
        setAuthRep(res[0] || { _id: -1 });
      }
    });
  }, [userMail]);

  const columnsContainerClass = [
    classes.scrollable,
    classes.fullHeight,
    classes.gutterGrid4,
  ].join(' ');

  const memoized = useMemo(
    () =>
      (true || authRep) && (
        <>
          <IdxOrderingCliniciansFilter
            repMode={true}
            authRep={authRep._id !== -1 && authRep}
          />
          <div className={columnsContainerClass}>
            <Grid container spacing={8} className={classes.fullHeight}>
              <Grid
                item
                md={5}
                xs={12}
                className={[classes.fullHeight, classes.nonLastColumn].join(
                  ' '
                )}>
                <IdxOrderingCliniciansList repMode={true} {...rest} />
              </Grid>
              <Grid item md={7} xs={12} className={classes.fullHeight}>
                <Switch>
                  <Route
                    exact
                    path={[
                      routes.SALES_REP_DOCTOR,
                      routes.SALES_REP_DOCTOR_VIEW,
                      routes.SALES_REP_DOCTOR_CREATE,
                      routes.SALES_REP_DOCTOR_UPDATE,
                    ]}
                    component={(props) => (
                      <IdxOrderingCliniciansDetail repMode={true} {...props} />
                    )}
                  />
                </Switch>
              </Grid>
            </Grid>
          </div>
          )
        </>
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authRep]
  );

  return loadingAuthRep ? (
    <Loader type="circular" />
  ) : (
    <div id="salesRepContainer" className={classes.content}>
      {memoized}
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.me,
});

export default withRouter(
  connect(mapStateToProps, null)(withStyles(styles)(IdxOrderingClinicians))
);
