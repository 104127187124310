import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchTeams_ac } from '../../actions/teams.ac.js'
import FetchingStatementWrap from '../FetchingStatementWrap'
import TeamCard from './TeamCard'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

class TeamsLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.checkSituation()
  }
  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation() {
    const { teams } = this.props
    if (teams.isFetching) return
    if (teams.error) return
    if (teams.pkg === null) {
      this.props.fetchTeams_ac()
    }
  }

  render() {
    const { classes, teams } = this.props
    if (teams.error) {
      return (
        <FetchingStatementWrap
          title={'Teams Listing'}
          msg={'Something went wrong. Contact IT'}
        />
      )
    }

    if (!teams.pkg || teams.isFetching) {
      return (
        <FetchingStatementWrap
          title="Teams Listing"
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    const { pkg } = teams

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={[classes.root, classes.scrollArea].join(' ')}>
            <main className={classes.content}>
              {/* <div className={classes.appBarSpacer} /> */}
              <Grid container spacing={8} className={classes.fullHeight}>
                {/* BEGIN STATS */}
                <Grid item xs={4} sm={4} className={classes.fullHeight}>
                  <main
                    className={[
                      classes.content,
                      classes.pagination,
                      classes.fullHeight
                    ].join(' ')}>
                    <label className={classes.label}>All Teams:</label>
                    <div className={classes.scrollArea}>
                      {pkg.teams.map((r, idx) => (
                        <TeamCard key={idx} team={r} idx={idx} />
                      ))}
                    </div>
                  </main>
                </Grid>
              </Grid>
            </main>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

TeamsLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  teams: stateFromStore.teams
})

const mapDispatchToProps = (dispatch, propsOfTeamsLanding) =>
  bindActionCreators(
    {
      fetchTeams_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TeamsLanding))
)
