import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchConfirmationLogList_ac } from '../../actions/confirmation_log.ac';
import { lookups_ac } from '../../actions/me.ac';
import dateUtil from '../../util/dateProcessing';
import { checkIfStringHasIntegersFollowedByW } from '../DocViewer/DocViewer';
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent';
import FiltersForm from '../Shared/FiltersFormComponent/FiltersForm';
import ConfirmationEntryForm from './ConfirmationEntryForm';
import ConfirmationLogTable from './ConfirmationLogTable';

const getIntegerValueFromProduct = (product) => {
  return Number(product.substring(1));
};
const getFirstAccountNumberForWorkListing = (hdmsAccts, log) => {
  if (_.isArray(hdmsAccts) && hdmsAccts.length) {
    return hdmsAccts[0].replace(/A/, '');
  }
  if (log.records && log.records.length) {
    return log.records[0].account;
  }

  return '';
};

const validateMinLengthText = (value = '', minLength = 3) => {
  let isValid = value === '' || value.length >= minLength;

  return {
    isValid,
    errorText: isValid ? '' : `Minimum ${minLength} characters are required!`,
  };
};

const validateNumber = (value) => {
  let isValid = value === '' || !isNaN(value);

  return {
    isValid,
    errorText: isValid ? '' : 'Not a Number!',
  };
};

const validateDateFormat = (value = '') => {
  let isValid = value === '' || dateUtil.isValidDate(value);
  let errorText = isValid ? '' : 'Invalid date format!';

  return { isValid, errorText };
};

const styles = (theme) => ({
  entryFormContainer: {
    height: '100%',
    overflowY: 'auto',
    padding: 8,
    borderLeft: '2px dashed #c5c5c5',
  },
  fullHeight: {
    height: '100%',
  },
});

const DEFAULT_FILTERS = {
  workOrder: {
    kind: 'text',
    lbl: 'Work Order No.',
    placeholder: 'Enter Work Order No.',
    validator: (value) => {
      let { isValid, errorText } = validateMinLengthText(value);

      if (!isValid) return { isValid, errorText };

      return validateNumber(value);
    },
  },
  account: {
    kind: 'text',
    lbl: 'Account No.',
    placeholder: 'Enter Account No.',
    validator: (value) => {
      let { isValid, errorText } = validateMinLengthText(value);

      if (!isValid) return { isValid, errorText };

      return validateNumber(value);
    },
  },
  user: {
    kind: 'text',
    lbl: 'User',
    placeholder: "Enter User's Name",
    validator: validateMinLengthText,
  },
  pcr: {
    kind: 'text',
    lbl: 'PCR',
    placeholder: 'Enter PCR',
    validator: validateMinLengthText,
  },
  state: {
    lbl: 'State',
    kind: 'multi-select',
    options: [
      { lbl: 'No Entries', code: 'NO_ENTRIES' },
      { lbl: 'Confirmed', code: 'CONFIRMED' },
      { lbl: 'Not Confirmed', code: 'NOT CONFIRMED' },
      { lbl: 'Cancelled', code: 'CANCELLED' },
    ],
  },
  start_date: {
    kind: 'date',
    lbl: 'From',
    placeholder: 'MM-DD-YYYY',
    isRequired: false,
    validator: validateDateFormat,
  },
  end_date: {
    kind: 'date',
    lbl: 'To',
    placeholder: 'MM-DD-YYYY',
    isRequired: false,
    validator: validateDateFormat,
  },
};
const INITIAL_FILTER_VALUES = {
  workOrder: '',
  account: '',
  user: '',
  pcr: '',
  state: ['NO_ENTRIES', 'CONFIRMED', 'NOT CONFIRMED', 'CANCELLED'],
  start_date: '',
  end_date: '',
};

class ConfirmationLogLanding extends BaseLandingTemplate {
  constructor() {
    super();

    this.state = {
      filterData: {
        ...INITIAL_FILTER_VALUES,
        start_date: moment()
          .subtract(7, 'days') //default to past 7 days
          .format('MM-DD-YYYY'),
        end_date: moment().format('MM-DD-YYYY'),
      },
      selectedPageNum: 0,
      selectedRecordForEdit: null,
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    const { lookups, me, confirmationLog, wDoc } = this.props;
    const { isFetching, error, records } = confirmationLog;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return this.props.lookups_ac();
    }

    if (!isFetching && !error && !records) {
      return this.fetch(this.state.selectedPageNum);
    }

    if (wDoc && records && !records.length) {
      return this.fetch(this.state.selectedPageNum);
    }

    let currentWDocWoId =
      wDoc && getIntegerValueFromProduct(this.getValidOwnersArrProduct(wDoc));
    let isDifferentRecord =
      records && records.length && currentWDocWoId !== records[0]._id;

    if (wDoc && (isDifferentRecord || !records)) {
      return this.fetch(this.state.selectedPageNum);
    }
  }

  componentDidUpdate(prevProps) {
    const { lookups, me, confirmationLog, wDoc } = this.props;
    const { isFetching, error, records } = confirmationLog;

    if (!me.user) return;
    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return this.props.lookups_ac();
    }

    if (!isFetching && !error && !records) {
      return this.fetch(this.state.selectedPageNum);
    }

    let currentWDocWoId =
      wDoc && getIntegerValueFromProduct(this.getValidOwnersArrProduct(wDoc));
    let prevWDocWoId =
      prevProps.wDoc &&
      getIntegerValueFromProduct(this.getValidOwnersArrProduct(prevProps.wDoc));
    if (
      wDoc &&
      currentWDocWoId &&
      prevWDocWoId &&
      currentWDocWoId !== prevWDocWoId
    ) {
      return this.fetch(this.state.selectedPageNum);
    }
  }

  fetch(pageNum = 0) {
    const { filterData } = this.state;
    let params = {
      state: filterData.state,
      page: pageNum,
    };

    if (filterData.start_date) {
      params.start_date = moment(filterData.start_date).utc().format();
    }

    if (filterData.end_date) {
      params.end_date = moment(filterData.end_date).utc().format();
    }

    if (filterData.account) params.account = filterData.account;

    if (filterData.user) params.user = filterData.user;

    if (filterData.pcr) params.pcr = filterData.pcr;

    if (filterData.workOrder) params.workOrder = filterData.workOrder;

    let requestURL = '/api/conflog/list';

    if (this.props.wDoc) {
      requestURL = '/api/conflog/wo';
      params.wo_id =
        this.props.wDoc &&
        getIntegerValueFromProduct(
          this.getValidOwnersArrProduct(this.props.wDoc)
        );
    }

    this.props.fetchConfirmationLogList_ac(requestURL, params);

    if (this.state.selectedRecordForEdit)
      this.setState({
        selectedRecordForEdit: null, // reset selected record on fetching the list again
      });
  }

  applyFilters = (newfilterData) => {
    this.setState(
      {
        selectedPageNum: 0,
        filterData: { ...newfilterData },
      },
      () => this.fetch()
    );
  };

  openEditForm = (selectedRecord, openState) => {
    this.setState({
      selectedRecordForEdit: openState ? { ...selectedRecord } : null,
    });
  };

  /**
   * Returns the product of either ownersArr or pastOwnersArr,
   * whichever is of the confirmation workunit
   */
  getValidOwnersArrProduct = (wDoc) => {
    if (
      wDoc.ownersArr &&
      wDoc.ownersArr.length > 0 &&
      wDoc.ownersArr[0].product_bn === 'WO-CONF' &&
      checkIfStringHasIntegersFollowedByW(wDoc.ownersArr[0].product)
    )
      return wDoc.ownersArr[0].product;

    if (
      wDoc.pastOwnersArr &&
      wDoc.pastOwnersArr.length > 0 &&
      wDoc.pastOwnersArr[0].product_bn === 'WO-CONF' &&
      checkIfStringHasIntegersFollowedByW(wDoc.pastOwnersArr[0].product)
    )
      return wDoc.pastOwnersArr[0].product;

    return '';
  };

  render() {
    const { selectedRecordForEdit } = this.state;
    const { classes, confirmationLog, wDoc } = this.props;
    const hasSingleEntry = wDoc ? true : false;

    return (
      <Grid container className={classes.fullHeight}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          item
          xs={8}
          className={classes.fullHeight}
          style={{ overflowY: 'auto' }}>
          {!hasSingleEntry && (
            <FiltersForm
              filters={{ ...DEFAULT_FILTERS }}
              filterSequence={[
                'workOrder',
                'account',
                'user',
                'pcr',
                'state',
                'start_date',
                'end_date',
              ]}
              initialValues={{ ...this.state.filterData }}
              applyFilters={this.applyFilters}
              isDisabled={confirmationLog.isFetching}
            />
          )}
          <ConfirmationLogTable
            confirmationLog={confirmationLog}
            selectedRecordForEdit={selectedRecordForEdit}
            hasSingleEntry={hasSingleEntry}
            openEditForm={this.openEditForm}
            fetch={this.fetch}
          />
        </Grid>
        <Grid item xs={4} className={classes.entryFormContainer}>
          {selectedRecordForEdit || this.props.wDoc ? (
            <ConfirmationEntryForm
              wo={
                hasSingleEntry
                  ? _.get(confirmationLog.records, 0, {
                      confirmation_entry: {
                        pcr: { nm: '', mail: '' },
                        state: '',
                      },
                    })
                  : selectedRecordForEdit
              }
              wo_id={
                this.props.wDoc
                  ? getIntegerValueFromProduct(
                      this.getValidOwnersArrProduct(this.props.wDoc)
                    )
                  : selectedRecordForEdit && selectedRecordForEdit._id
              }
              account={
                this.props.wDoc
                  ? getFirstAccountNumberForWorkListing(
                      this.props.wDoc.hdmsAccts,
                      confirmationLog
                    )
                  : selectedRecordForEdit && selectedRecordForEdit.account
              }
              isSaving={confirmationLog.isSaving}
              closeEditForm={() => {
                this.openEditForm(selectedRecordForEdit, false);
              }}
              hasSingleEntry={hasSingleEntry}
            />
          ) : (
            <Typography variant="subtitle2" color="textSecondary">
              Click 'EDIT' button of any Work Order to add a new Confirmation
              Entry
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  confirmationLog: stateFromStore.confirmationLog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchConfirmationLogList_ac,
      lookups_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ConfirmationLogLanding))
);
