import { actionTypes } from '../actions/patient_letters_report.ac'

export function allPatientLettersReport(
  state = {
    isFetchingReport: false,
    report: null,
    error: null
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_ALL_PATIENT_LETTERS_REPORT:
      return {
        ...state,
        isFetchingReport: action.isFetching
      }

    case actionTypes.RECEIVED_ALL_PATIENT_LETTERS_REPORT:
      return {
        ...state,
        report: action.report,
        error: null
      }

    case actionTypes.FAILED_ALL_PATIENT_LETTERS_REPORT:
      return {
        ...state,
        error: action.error,
        report: null
      }

    default:
      return state
  }
}

export function patientLettersReport(
  state = {
    isFetchingReport: false,
    report: null,
    error: null,
    idnStr: ''
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_PATIENT_LETTERS:
      return {
        ...state,
        isFetchingReport: action.isFetching,
        idnStr: action.idnStr || ''
      }

    case actionTypes.RECEIVED_PATIENT_LETTERS:
      return {
        ...state,
        report: action.report,
        idnStr: action.idnStr || '',
        error: null
      }

    case actionTypes.FAILED_PATIENT_LETTERS:
      return {
        ...state,
        error: action.error,
        report: null,
        idnStr: action.idnStr || ''
      }

    default:
      return state
  }
}
