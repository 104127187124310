import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { orange, purple } from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GLogin from './GLogin';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange,
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
});

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});

class GapiNotValidPage extends React.Component {
  constructor(props) {
    super(props);
    this.onFailedGoogleResponse = this.onFailedGoogleResponse.bind(this);
    this.state = {
      msg: '',
    };
  }

  onFailedGoogleResponse() {
    this.setState({ msg: 'Login Via Google Failed.' });
  }

  render() {
    const { classes, lookups } = this.props;
    let msg = '';
    let href = '';
    let vtag = '';
    switch (window.location.host) {
      case 'dflow-int.supercaredev.com':
        msg = `You are on the version of DF+ that is available ONLY over CITRIX or 
        from inside a SuperCare location.  If niether of those situations apply to you, 
        AND you have been approved by IT Helpdesk as 2Factor enabled, 
        use this version instead:`;
        href = window.location.href.replace(/dflow-int/, 'dflow');
        vtag = <a href={href}>https://dflow.supercaredev.com</a>;
        break;
      case 'dflow.supercaredev.com':
        msg = `You are on the version of DF+ that is only available if you have been approved
        by IT Helpdesk as 2Factor Enabled. If you are using CITRIX or in a SuperCare location,
        use this version instead:`;
        href = window.location.href.replace(/dflow/, 'dflow-int');
        vtag = <a href={href}>https://dflow-int.supercaredev.com</a>;
        break;
      case 'scus08.supercaremed.com:3000':
        msg = `YOU ARE ON A TEST ENVIRONMENT`;
        break;
      case 'localhost:3000':
      case 'localhost:3002':
        msg = `YOU ARE ON LOCALHOST`;
        break;
      default:
        break;
    }
    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Grid
              container
              justify="center"
              spacing={8}
              className={classes.gridContainer}>
              <Grid item md={8} xs={12} className={classes.center}>
                <div
                  style={{
                    padding: 16,
                    fontSize: 12,
                    marginTop: 24,
                    marginBottom: 24,
                    border: '1px solid #000',
                  }}>
                  {msg}
                  <div style={{ paddingTop: 12 }}>{vtag}</div>
                </div>
              </Grid>

              {lookups.isGapiValidating ? (
                <Grid item xs={12} className={classes.center}>
                  <h5 className={classes.h5}>
                    ...checking if you've granted DF+ access to your GMail
                    account...
                  </h5>
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.center}>
                  <h5 className={classes.h5}>
                    You are logged in but you need to grant DF+ acces to your
                    GMail and Drive accounts.
                  </h5>
                  <br />
                  {this.state.msg && (
                    <h5 style={{ color: 'red' }}>{this.state.msg}</h5>
                  )}

                  <GLogin
                    forceDisplay
                    theme="dark"
                    clientId={process.env.REACT_APP_DOCFLOW_LOGIN_CLIENT_ID}
                    btnLabel="Grant GMail Permissions"
                  />
                  <br />
                  <label style={{ fontSize: 15 }}>
                    MAKE SURE YOUR BROWSER POPUPS ARE NOT BLOCKED!!!!
                  </label>

                  <div style={{ height: 100 }} />
                  {this.props.me.error && (
                    <h5>ERR CODE: {this.props.me.errorFrom}</h5>
                  )}
                  <div style={{ height: 100 }} />
                </Grid>
              )}
            </Grid>
          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GapiNotValidPage));

//export default withStyles(styles)(GapiNotValidPage)
