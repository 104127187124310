import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
    //backgroundColor: theme.palette.background.paper,
  },
  scrollArea: {
    overflowY: 'auto',
    height: '92vh'
  },
  patientLbl: {
    fontSize: 12,
    margin: '2px 8px 0 4px',
    fontWeight: 600,
    color: '#00675b',
    padding: 2
  },
  accountLbl: {
    fontSize: 12,
    padding: '3px',
    margin: '2px 8px 8px 4px',
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#BBDEFB',
    borderBottom: '1px solid #000'
  },
  docEntry: {
    cursor: 'pointer',
    marginLeft: 12,
    padding: 8
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  }
})

class FetchingStatement extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.pagination}>
          <Typography className={classes.pageStatement} color="textSecondary">
            {this.props.children}
          </Typography>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(FetchingStatement)
