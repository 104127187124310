export const actionTypes = {
  SET_IS_FETCHING_OPEN_COUNTS_BY_EMAIL: 'SET_IS_FETCHING_OPEN_COUNTS_BY_EMAIL',
  RECEIVED_OPEN_COUNTS_BY_EMAIL: 'RECEIVED_OPEN_COUNTS_BY_EMAIL',
  FAILED_OPEN_COUNTS_BY_EMAIL: 'FAILED_OPEN_COUNTS_BY_EMAIL'
}

export function fetchOpenCountsByEmail_ac(params) {
  return dispatch => {
    dispatch(setIsFetching(true, params.email))

    window.sch.post(`/api/dashboard/get`, params)
      .then(response => {
        dispatch({
          type: actionTypes.RECEIVED_OPEN_COUNTS_BY_EMAIL,
          record: {
            ...(response.pkg || {}),
            userName: response.principal_user_nm || ''
          },
          email: params.email || ''
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_OPEN_COUNTS_BY_EMAIL,
          error: true,
          email: params.email || ''
        })
      })
      .finally(() => {
        dispatch(setIsFetching(false, params.email))
      })
  }
}

const setIsFetching = (isFetching, email) => ({
  type: actionTypes.SET_IS_FETCHING_OPEN_COUNTS_BY_EMAIL,
  email: email || '',
  isFetching
})
