import { orange, purple } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  cancel_masterSearch_ac,
  masterSearch_ac
} from '../../actions/masterSearch.ac.js'
import { setTitle_ac } from '../../actions/me.ac.js'
import PaErrorsNavList from './PaErrorsNavList'
import TabsPaErrors from './TabsPaErrors'

const drawerWidth = 240
const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})

const styles = theme => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9
    }
  },
  appBarSpacer: {
    /*...theme.mixins.toolbar, */
    minHeight: 34
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto'
  },
  chartContainer: {
    marginLeft: -22
  },
  tableContainer: {
    height: 320
  },
  h5: {
    marginBottom: theme.spacing.unit * 2
  },
  fullHeight: {
    height: '100%'
  }
})

class Dashboard extends React.Component {
  state = {
    drawerOpen: false
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true })
  }

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false })
  }
  componentDidMount() {
    this.props.setTitle_ac('PA Errors')
  }
  componentDidUpdate(prevProps) {
    if (this.props.me.user === null) {
      return
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ drawerOpen: false })
    }

    let now = moment()
    let offset = -1
    if (now.day() === 1) offset = -3
    if (now.day() === 0) offset = -2
    let path = `/pa_errors/ccs/initials/${moment()
      .add(offset, 'days')
      .format('MM-DD-YYYY')}`
    if (this.props.location.pathname === '/pa_errors') {
      return this.props.redirect(path)
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={2} className={classes.fullHeight}>
          <PaErrorsNavList />
        </Grid>
        <Grid item xs={10} className={classes.fullHeight}>
          <Route
            path="/pa_errors/:payerGroup/:kind"
            exact
            component={TabsPaErrors}
          />
          <Route
            path="/pa_errors/:payerGroup/:kind/:dateFilter"
            component={TabsPaErrors}
          />
        </Grid>
      </Grid>
    )
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch,
  dfaInView: stateFromStore.dfaInView,
  accountInView: stateFromStore.accountInView,
  docInView: stateFromStore.docInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      masterSearch_ac,
      cancel_masterSearch_ac,
      redirect: path => {
        return push(path)
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles(theme))(Dashboard))
)
