import { Typography } from '@material-ui/core';
import React from 'react';

const typeColorMap = {
  normal: '#000000',
  warning: '#ffc107',
  error: '#f44336',
  success: '#4caf50',
  info: '#2196f3',
};

const TextInfo = ({
  value = '',
  variant = 'h5',
  type = 'info',
  style = {},
  align = 'left',
  bold = false,
  ...rest
}) => (
  <Typography
    variant={variant}
    {...rest}
    style={{
      textAlign: align,
      fontSize: 11,
      color: typeColorMap[type],
      fontWeight: bold ? 'bold' : 'normal',
      ...style,
    }}>
    {value}
  </Typography>
);

export default TextInfo;
