import {
  Button,
  Chip,
  FormLabel,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  fetchMednecIssueLogsList_ac,
  getIssueCodes_ac,
  saveMednecIssue_ac
} from '../../actions/mednec_issue_log.ac'
import { open_snack_ac } from '../../actions/snack.ac'
import Loader from '../Shared/Loader'
import SearchSelect from '../Shared/SearchSelect'

const defaultFormData = {
  wo_id: '',
  account: '',
  comment: '',
  issues: []
}

const SmallChip = withStyles({
  root: {
    height: 24,
    borderRadius: 12
  },
  deleteIcon: {
    height: '80%',
    fontSize: 20,
    margin: '0 2px 0 -10px'
  }
})(Chip)

const styles = theme => ({
  issuesList: {
    maxHeight: 450,
    overflowY: 'auto',
    flexWrap: 'nowrap',
    padding: '16px'
  },
  selectedIssuesList: {
    maxHeight: 150,
    overflowY: 'auto',
    marginTop: 16,
    padding: 8,
    backgroundColor: `${theme.palette.secondary.main}10`
  },
  error: {
    color: red[500]
  },
  fontSize_11: {
    fontSize: 11
  }
})

class MednecIssueLogEntryFormComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      formData: {
        ...defaultFormData
      },
      errors: {
        issues: false,
        wo_id: false,
        account: false
      },
      issuesList: []
    }
  }

  componentDidMount() {
    const { wo_id, account, mednecIssueLog } = this.props
    if (
      !mednecIssueLog.isFetching &&
      !mednecIssueLog.error &&
      !mednecIssueLog.issues
    ) {
      this.props.getIssueCodes_ac()
    }

    this.setFormData(wo_id, account)
    return
  }

  componentDidUpdate(prevProps) {
    const { wo_id, account, mednecIssueLog } = this.props
    if (
      mednecIssueLog.issues &&
      mednecIssueLog.issues.length &&
      !this.state.issuesList.length
    ) {
      this.setState({
        issuesList: mednecIssueLog.issues
      })
    }
    if (wo_id !== prevProps.wo_id) {
      this.setFormData(wo_id, account)
      return
    }
  }

  setFormData = (wo_id, account) => {
    return this.setState(state => ({
      formData: {
        ...defaultFormData,
        wo_id: wo_id ? `${wo_id}` : '',
        account: account ? `${account}` : ''
      },
      errors: {
        issues: false,
        wo_id: false,
        account: false
      }
    }))
  }

  handleFieldChange = event => {
    const { name, value } = event.target
    this.setState(
      (state, props) => ({
        formData: {
          ...state.formData,
          [name]: value
        }
      }),
      () => {
        if (this.state.errors[name]) {
          this.validateForm()
        }
      }
    )
  }

  handleIssueSelection = e => {
    const { formData } = this.state
    const { issues } = formData

    const { value } = e.target
    if (issues.includes(value)) return

    this.setState(
      pre => ({
        formData: {
          ...pre.formData,
          issues: [...pre.formData.issues, value]
        }
      }),
      () => {
        if (this.state.errors.issues) {
          this.validateForm()
        }
      }
    )
  }

  removeIssueSelection = i => {
    const { formData } = this.state
    const issues = _.cloneDeep(formData.issues)
    issues.splice(i, 1)

    this.setState(pre => ({
      formData: {
        ...pre.formData,
        issues: issues
      }
    }))
  }

  resetForm = (closePanel = true) => {
    this.setState(pre => ({
      formData: {
        ...defaultFormData,
        wo_id: pre.formData.wo_id,
        account: pre.formData.account
      }
    }))

    if (closePanel) {
      this.props.closeMednecLog()
    }
  }

  handleEntrySave = () => {
    const isFormValid = this.validateForm()

    if (!isFormValid) return

    let entry = this.getEntryPayload()

    this.props.saveMednecIssue_ac(entry, this.props.fromIdnWoEntry)
    this.resetForm(false)
  }

  validateForm = () => {
    const { formData } = this.state

    let issuesError = false
    let woIdError = false
    let accountError = false
    let isValid = true

    if (!formData.wo_id || isNaN(formData.wo_id)) {
      woIdError = true
      isValid = false
    }

    if (!formData.account || isNaN(formData.account.replace(/-[1-9]$/, ''))) {
      accountError = true
      isValid = false
    }

    if (!formData.issues.length) {
      issuesError = true
      isValid = false
    }

    this.setState({
      errors: {
        issues: issuesError,
        wo_id: woIdError,
        account: accountError
      }
    })

    return isValid
  }

  getlabelFromId = id => {
    const { issuesList } = this.state
    return issuesList.find(i => i._id === parseInt(id))?.lbl
  }

  getEntryPayload = () => {
    const { formData } = this.state
    return {
      account: parseInt(formData.account.replace(/-[0-9]$/, '')),
      comment: formData.comment,
      issues: formData.issues.map(issueId => ({
        _id: parseInt(issueId),
        lbl: this.getlabelFromId(issueId)
      })),
      wo_id: parseInt(formData.wo_id)
    }
  }

  render() {
    const { classes, mednecIssueLog } = this.props
    const { formData, issuesList, errors } = this.state

    const { isFetching, isSaving } = mednecIssueLog
    const isSaveDisabled =
      isFetching || isSaving || !this.state.formData.account

    const issueOptions = (issuesList || []).map(d => ({
      ...d,
      code: d._id.toString(),
      lbl: d.lbl
    }))

    return (
      <div className={classes.issuesList}>
        {/* 
        <Grid container spacing={16}>
          <Grid item xs={12} md={6}>
            <TextField
              className={classes.fontSize_11}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="dense"
              name="wo_id"
              label="Work Order Id"
              value={formData.wo_id}
              required
              error={errors.wo_id}
              helperText={errors.wo_id ? 'Please enter non-empty number' : ''}
              disabled={wo_id ? true : false}
              onChange={this.handleFieldChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              className={classes.fontSize_11}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="dense"
              name="account"
              label="Accound No."
              value={formData.account}
              required
              error={errors.account}
              helperText={errors.account ? 'Please enter non-empty number' : ''}
              disabled={account ? true : false}
              onChange={this.handleFieldChange}
            />
          </Grid>
        </Grid>
            */}
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TextField
              className={classes.fontSize_11}
              multiline
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              name="comment"
              label="Comment"
              value={formData.comment}
              onChange={this.handleFieldChange}
            />
          </Grid>
        </Grid>

        {formData.issues.length > 0 && (
          <div className={classes.selectedIssuesList}>
            <Grid container spacing={8}>
              {formData.issues.map((issue, i) => (
                <Grid key={issue} item>
                  <SmallChip
                    className={classes.fontSize_11}
                    color="secondary"
                    label={this.getlabelFromId(issue)}
                    onDelete={() => this.removeIssueSelection(i)}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <FormLabel style={{ fontSize: '11' }} component="label">
              Issues
            </FormLabel>
          </Grid>
        </Grid>
        <Grid container spacing={8} direction="row" justify="space-between">
          {/* not needed <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.resetForm}
              disabled={isFetching || isSaving}>
              Cancel
            </Button>
          </Grid>*/}
          <Grid item>
            {!isFetching && issueOptions && issueOptions.length > 10 && (
              <SearchSelect
                name="issues"
                options={issueOptions}
                placeholder="Search Issues"
                fullWidth
                scrollableParentId="mednecIssueLabelPopper"
                onSelect={this.handleIssueSelection}
              />
            )}
            {errors.issues ? (
              <Typography
                component="span"
                className={classes.error}
                color="textPrimary">
                Please Select Issues
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleEntrySave}
              disabled={isSaveDisabled}>
              Add Mednec Issue
              {isSaving && <Loader type="circular" size={15} color="primary" />}
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  mednecIssueLog: stateFromStore.mednecIssueLog
})

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      getIssueCodes_ac,
      fetchMednecIssueLogsList_ac,
      open_snack_ac,
      saveMednecIssue_ac
    },
    dispatch
  )

export const MednecIssueLogEntryForm = connect(
  mapStateToProps,
  mapDispatchtoProps
)(withStyles(styles)(MednecIssueLogEntryFormComponent))
