import React from 'react';
import { BiSearch as SearchIcon } from 'react-icons/bi';
import DatePicker from 'react-datepicker';

import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { Accordion } from '../common';

const Filters = (props) => {
  const {
    classes,
    filterData,
    setFilters,
    disabled = false,
    onSearch,
    onReset,
  } = props;

  const onFilterChange = (value, field) => {
    setFilters({
      ...filterData,
      [field]: value,
    });
  };

  return (
    <Accordion
      isExpanded={props.isExpanded}
      setIsExpanded={props.setIsExpanded}
      includeHeaderClick={true}
      header={
        <span
          style={{
            fontWeight: 'normal',
            fontSize: '14px',
          }}>
          {'Filters:'}
        </span>
      }>
      <div className={classes.formContainer}>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item>
            <FormControl className={classes.formControl}>
              <FormLabel htmlFor="user" className={classes.formLabel}>
                User email
              </FormLabel>
              <TextField
                margin="dense"
                variant="outlined"
                id="user"
                name="user"
                placeholder="User Email"
                value={filterData.user}
                onChange={(e) => {
                  onFilterChange(e.target.value, 'user');
                }}
                className={classes.field}
                InputProps={{
                  startAdornment: <SearchIcon size={20} color="#0000008a" />,
                  style: { height: '32px' },
                }}
                style={{ minWidth: 200 }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl className={classes.formControl}>
              <FormLabel htmlFor="patient" className={classes.formLabel}>
                Patient/Acct# Search
              </FormLabel>
              <TextField
                margin="dense"
                variant="outlined"
                id="patient"
                name="patient"
                placeholder="Patient Name or Acct#"
                value={filterData.patient}
                onChange={(e) => {
                  onFilterChange(e.target.value, 'patient');
                }}
                className={classes.field}
                InputProps={{
                  startAdornment: <SearchIcon size={20} color="#0000008a" />,
                  style: { height: '32px' },
                }}
                style={{ minWidth: 200 }}
                disabled={disabled}
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl className={classes.formControl}>
              <FormLabel htmlFor="startDate" className={classes.formLabel}>
                Start Date
              </FormLabel>

              <DatePicker
                name="startDate"
                showTimeInput={false}
                dateFormat={'MM-dd-yyyy'}
                className={classes.date}
                selected={new Date(filterData.startDate)}
                onChange={(date) =>
                  onFilterChange(moment(date).format('YYYY-MM-DD'), 'startDate')
                }
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl className={classes.formControl}>
              <FormLabel htmlFor="endDate" className={classes.formLabel}>
                End Date
              </FormLabel>

              <DatePicker
                name="endDate"
                showTimeInput={false}
                dateFormat={'MM-dd-yyyy'}
                className={classes.date}
                selected={new Date(filterData.endDate)}
                onChange={(date) =>
                  onFilterChange(moment(date).format('YYYY-MM-DD'), 'endDate')
                }
              />
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl className={classes.formControl}>
              <FormLabel htmlFor="by" className={classes.formLabel}>
                Group By:
              </FormLabel>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Typography
                  variant="caption"
                  style={{ marginRight: 8, fontSize: 11 }}>
                  {'Patient'}
                </Typography>
                <Switch
                  name="by"
                  checked={filterData.by === 'user'}
                  onChange={(e) => {
                    onFilterChange(e.target.checked ? 'user' : 'patient', 'by');
                  }}
                  disabled={disabled}
                />
                <Typography
                  variant="caption"
                  style={{ marginRight: 8, fontSize: 11 }}>
                  {'User'}
                </Typography>
              </div>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onSearch}
              style={{ marginBottom: 6 }}
              disabled={disabled}>
              {'Search'}
            </Button>
          </Grid>

          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={onReset}
              style={{ marginBottom: 6 }}
              disabled={disabled}>
              {'Reset'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Accordion>
  );
};

export default Filters;
