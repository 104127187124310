import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import {
  addMember_ac,
  changeDocFlowState_ac,
  fetchTeamsGridData_ac,
  removeMemberFromTeam_ac
} from '../../actions/teams_grid.ac.js'
import formatDate from '../../util/dateProcessing'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'
import UserSearch from './../Users/UserSearch'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  description: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  title: {
    flexGrow: 1
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  spacer: {
    height: 40
  },
  card: {
    position: 'relative',
    float: 'left',
    margin: 10,
    border: '1px solid #cfd8dc',
    backgroundColor: '#fafefff0',
    width: '14vw',
    transitionDuration: '0.3s',
    height: '12vw',
    padding: '4px',
    fontSize: '0.75em'
  },
  txtVal: {
    fontSize: 12,
    color: '#000'
  },
  activeCardHeader: {
    backgroundColor: 'yellow'
  },
  inActiveCardHeader: {
    backgroundColor: 'lightgray'
  },
  classHeaderRoot: {
    padding: '8px'
  },
  classHeaderTitle: {
    fontSize: '.82em',
    fontWeight: 800
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center'
  },
  cardAction: {
    position: 'absolute',
    bottom: '0',
    zIndex: '1000',
    width: 'calc(100% - 8px)',
    backgroundColor: '#fafeff',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)'
  },
  fullHeight: {
    height: '100%'
  }
})

const MAP_REQUEST_PARAMS_STATE = {
  front: { filterBy: 'front' },
  back: { filterBy: 'back' },
  all: { filterBy: 'all' },
  mineOnly: { mineOnly: true }
}
const MAP_COMPONENT_TITLE = {
  front: 'Front End Teams',
  back: 'Back End Teams',
  all: 'All Teams',
  mineOnly: 'My Teams'
}

class TeamsGridComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props)
    this.state = {
      showUserSelection: false
    }
    this.fetch = this.fetch.bind(this)
    this.addMember = this.addMember.bind(this)
    this.removeMemberFromTeam = this.removeMemberFromTeam.bind(this)
    this.onUserSelected = this.onUserSelected.bind(this)
  }

  onPageChange(evt) {
    this.setState({ requestedPageNum: parseInt(evt.target.value, 10) })
  }

  addMember() {
    this.setState({
      showUserSelection: true
    })
  }
  onUserSelected = team => selectedValue => {
    return this.props.addMember_ac({
      team,
      email: selectedValue.email,
      act: 'add'
    })
  }
  /**
   *
   * @param {string} team
   * @param {string} email
   */
  removeMemberFromTeam(team, email) {
    return this.props.removeMemberFromTeam_ac({
      team,
      email,
      act: 'remove'
    })
  }
  /**
   *
   * @param {string} email
   * @param {boolean} docFlowState
   */
  changeDocFlowState(team, email, docFlowState) {
    return this.props.changeDocFlowState_ac({
      team,
      email,
      docFlowState
    })
  }

  componentDidMount() {
    const { lookups, setTitle_ac, match, me } = this.props
    if (!me.user) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    setTitle_ac(MAP_COMPONENT_TITLE[match.params.tab_name])

    return this.fetch()
  }

  componentDidUpdate(prevProps) {
    const { teamsGridInView, lookups, me, match } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return this.props.lookups_ac()
    }

    if (
      teamsGridInView.isFetching === false &&
      !teamsGridInView.error &&
      teamsGridInView.records === null
    ) {
      return this.fetch()
    }

    if (prevProps.match.params.tab_name !== match.params.tab_name) {
      return this.fetch()
    }
  }

  fetch() {
    let fetchCondition = {
      ...MAP_REQUEST_PARAMS_STATE[this.props.match.params.tab_name]
    }

    return this.props.fetchTeamsGridData_ac(fetchCondition)
  }

  render() {
    const { teamsGridInView, classes, lookups } = this.props

    const { showUserSelection } = this.state

    const { teamsLoading, cardsLoading } = teamsGridInView

    const cardHdrClasses = {
      root: classes['classHeaderRoot'],
      title: classes['classHeaderTitle']
    }

    if (!lookups.ink || teamsGridInView.records === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.description}>...</h4>
        </div>
      )
    }

    if (teamsGridInView.isFetching) {
      return (
        <div className={classes.root}>
          <Loader message="...loading records" />
        </div>
      )
    }

    if (teamsGridInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.description}>....error fetching records</h4>
        </div>
      )
    }

    if (teamsGridInView.records && !teamsGridInView.records.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.description}>No records found.</h4>
        </div>
      )
    }

    return (
      <Grid container className={classes.scrollArea}>
        <Grid container spacing={0} item className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.scrollArea}>
              <div className={classes.root}>
                {teamsGridInView.records.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <div className={classes.txtVal}>
                          <strong className={classes.primary}>{item.nm}</strong>
                        </div>
                        <div className={classes.txtVal}>
                          <ins>next:{item.nextInLine} </ins>
                        </div>
                        <div className={classes.txtVal}>
                          {item.supervisor.is_super
                            ? `${item.supervisor.nm}(Super)`
                            : item.supervisor.nm}
                        </div>
                        <div className={classes.txtVal}>
                          {item.supervisor.email}
                        </div>
                        <div className={classes.flexDisplay}>
                          <Button
                            onClick={this.addMember}
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={teamsLoading[item.nm]}>
                            Add Member
                          </Button>
                          {teamsLoading[item.nm] && (
                            <Loader type="circular" size={15} color="primary" />
                          )}
                        </div>
                        {showUserSelection && (
                          <UserSearch
                            onSelect={this.onUserSelected(item.nm)}
                            includeDisabled
                          />
                        )}
                      </Grid>
                      <Grid item xs={10}>
                        {item.members.map((member, index) => (
                          <Card
                            key={index}
                            className={classes.card}
                            variant="outlined">
                            <CardHeader
                              onClick={() =>
                                this.changeDocFlowState(
                                  item.nm,
                                  member.email,
                                  !member.active
                                )
                              }
                              title={`${member.nm}-${member.totalAssignments}`}
                              style={{ cursor: 'pointer' }}
                              className={
                                member.active
                                  ? classes.activeCardHeader
                                  : classes.inActiveCardHeader
                              }
                              classes={cardHdrClasses}
                            />
                            <CardContent>
                              <div>{member.email}</div>
                              <div style={{ fontSize: '.92em' }}>
                                {member.ts &&
                                  `Last activity: ${formatDate.relativeTime(
                                    member.ts
                                  )}`}
                              </div>
                              <div>COUNT: {member.totalAssignments}</div>
                              {member.on_leave === true && (
                                <div
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: 'red',
                                    color: '#fff'
                                  }}>
                                  ON LEAVE
                                </div>
                              )}
                            </CardContent>
                            <CardActions className={classes.cardAction}>
                              <Button
                                onClick={() =>
                                  this.removeMemberFromTeam(
                                    item.nm,
                                    member.email
                                  )
                                }
                                size="small"
                                color="secondary"
                                disabled={
                                  cardsLoading[member.email] &&
                                  cardsLoading[member.email][item.nm]
                                }>
                                Remove from Team
                              </Button>
                              {cardsLoading[member.email] &&
                                cardsLoading[member.email][item.nm] && (
                                  <Loader
                                    type="circular"
                                    size={15}
                                    color="secondary"
                                  />
                                )}
                            </CardActions>
                          </Card>
                        ))}
                      </Grid>
                    </Grid>
                    <br />
                    <Divider component="hr" />
                  </Grid>
                ))}
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
    )
  }
}

TeamsGridComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  teamsGridInView: stateFromStore.teamsGridInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchTeamsGridData_ac,
      removeMemberFromTeam_ac,
      changeDocFlowState_ac,
      addMember_ac,
      lookups_ac,

      changePage: path => push(path)
    },
    dispatch
  )

export const TeamsGrid = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TeamsGridComponent))
)
