import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  TextField
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { loadFrequencyList_ac, selectItems_ac, itemsDOSChanged_ac } from '../../actions/pap_calculator.ac'
import { open_snack_ac } from '../../actions/snack.ac.js'
import DateUtil from '../../util/dateProcessing'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '50%'
  },
  tableScrollable: {
    width: '100%',
    overflow: 'auto'
  },
  tableHeader: {
    height: 20,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
      whiteSpace: 'nowrap'
    }
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
    padding: '4px'
  },
  inlineBlock: {
    display: 'inline-block'
  },
  eligible: {
    backgroundColor: green[500]
  }
})

const getAdditionalInfo = (frequencyData) => {
  let { quantity, frequency, additional_info } = frequencyData;
  if (additional_info) {
    return additional_info;
  }
  let perMonth = parseInt(quantity / frequency);
  if (perMonth) {
    return `Quantity based on ${perMonth} per month`
  }
}

const FrequencyRowData = ({
  frequencyList,
  classes,
  selectFrequency,
  handleDOSChange
}) => {
  return (
    <>
      <Divider component="hr" />
      <div className={classes.tableScrollable}>
        <Table padding="dense">
          <TableHead padding="dense">
            <TableRow className={classes.tableHeader}>
              <TableCell align="left" width="5%">
                #
              </TableCell>
              <TableCell align="left" width="5%">

              </TableCell>
              <TableCell align="left" width="10%">
                ITEM DOS
              </TableCell>
              <TableCell align="left" width="10%">
                HCPC CODE
              </TableCell>
              <TableCell align="left" width="20%">
                DESCRIPTION
              </TableCell>
              <TableCell align="left" width="10%">
                ELIGIBLE
              </TableCell>
              <TableCell align="left" width="10%">
                QUANTITY
              </TableCell>
              <TableCell align="left" width="10%">
                FREQUENCY
              </TableCell>
              <TableCell align="left" width="20%">
                ADDITIONAL INFO
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(frequencyList || []).map((frequency, frequencyIndex) => (
              <TableRow key={frequencyIndex}>
                <TableCell
                  align="left"
                  width="5%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <span style={{ fontSize: 10 }}>
                    {frequencyIndex + 1}
                  </span>
                </TableCell>
                <TableCell
                  align="left"
                  width="5%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={classes.inlineBlock}
                        checked={frequency.isChecked}
                        onChange={e => selectFrequency(frequency.hcpc_code, !frequency.isChecked)}
                      />
                    }
                  />

                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <div>
                    <TextField
                      fullWidth
                      variant="standard"
                      onChange={(e) => handleDOSChange(frequency.hcpc_code, e.target.value)}
                      required
                      placeholder="MM-DD-YYYY"
                      name="last_date_of_service"
                      value={frequency.dos}
                    />
                  </div>

                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <div>
                    {frequency.hcpc_code}
                  </div>

                </TableCell>
                <TableCell
                  align="left"
                  width="20%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <div>
                    {frequency.description.toUpperCase()}
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <div>
                    <strong>
                      {frequency.eligible && DateUtil.formatDate(frequency.eligible, "MM-DD-YYYY")}
                    </strong>
                  </div>

                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <div>
                    {frequency.quantity}
                  </div>

                </TableCell>
                <TableCell
                  align="left"
                  width="10%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <div>
                    {frequency.frequency && `Every ${frequency.frequency} Months`}
                  </div>

                </TableCell>
                <TableCell
                  align="left"
                  width="20%"
                  variant="body"
                  className={classes.tableBodyCell}
                >
                  <div>
                    {getAdditionalInfo(frequency)}
                  </div>

                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

class PAPFrequencyListingComponent extends React.Component {

  componentDidMount() {
    this.props.loadFrequencyList_ac();
  }

  selectFrequency = (hcpc_code, checked) => {
    this.props.selectItems_ac(hcpc_code, checked)
  }

  handleDOSChange = (hcpc_code, dos) => {
    this.props.itemsDOSChanged_ac(hcpc_code, dos)
  }

  render() {

    const { papCalculator, classes } = this.props

    if (papCalculator.frequencyList === null) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>
            Unable to list frequencyList
          </h4>
        </div>
      )
    }

    return (
      <div className={classes.content}>
        <FrequencyRowData
          frequencyList={papCalculator.frequencyList}
          classes={classes}
          selectFrequency={this.selectFrequency}
          handleDOSChange={this.handleDOSChange}
        />
      </div>
    )
  }
}

PAPFrequencyListingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  papCalculator: stateFromStore.papCalculator,
  lookups: stateFromStore.lookups
})

// might be needed in future
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadFrequencyList_ac,
      selectItems_ac,
      itemsDOSChanged_ac,
      open_snack_ac
    },
    dispatch
  )

export const PAPFrequencyListing = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PAPFrequencyListingComponent))
)
