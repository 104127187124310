import { Grid, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import className from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  confirmAccountConsent_ac,
  fetchAccountConsent_ac,
  updateHDMSField_ac,
} from '../../actions/account_in_view.ac';
import { open_snack_ac as openSnack } from '../../actions/snack.ac';
import Loader from '../Shared/Loader';
import { FormModal } from '../common';
import ConsentHistory from './ConsentHistory';
import {
  ConsentActionPanel,
  ConsentActionPanelMobile,
  ConsentSettings,
  ConsentSettingsMobile,
} from './ConsentSettings';
import useConsent from './useConsent';

const styles = (theme) => ({
  card: {
    marginBottom: 20,
    border: '2px solid rgba(0,0,0,0.14)',
    borderRadius: 6,
  },
  commentForm: {
    width: '100%',
  },
  statusForm: {
    padding: '8px 10px',
  },
  tabsRoot: {
    minHeight: 24,
    maxHeight: 48,
  },
  root: {
    // width: 800,
  },
  tabRoot: {
    minWidth: 40,
    maxWidth: 400,
    '& .labelContainer': {
      padding: '6px 12px',
    },
    '&:first-child': {
      '& .labelContainer': {
        paddingLeft: 6,
      },
    },
    '&:last-child': {
      '& .labelContainer': {
        paddingRight: 6,
      },
    },
  },
  tabSpecificContainer: {
    overflowX: 'hidden',
    padding: '0px 8px',
  },
  tabOverflow: {
    height: '50vh',
    overflow: 'auto',
  },
  formContainer: {},
  listContainer: {},
  addButton: {
    marginTop: '6px',
    transition: 'all 0.5s ease-in-out',
  },
  checkbox: {
    padding: '6px 6px 6px 0px',
  },

  baseSwitch: {
    marginRight: 8,
    fontSize: 11,
    fontWeight: 'bold',
    color: 'rgb(156, 39, 176)',
    border: '1px solid rgb(156, 39, 176)',
    padding: '8px 12px',
    borderRadius: ' 6px',
    cursor: 'pointer',
  },
  switchOn: {
    backgroundColor: 'rgb(156, 39, 176)',
    color: 'white',
  },
  error: {
    color: 'red',
    fontSize: 12,
    margin: '8px 0px',
  },
  timelineContainer: {
    maxHeight: 'calc(100vh - 160px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
});

const ConsentModal = (props) => {
  const {
    actionPanelDisabled,
    setActionPanelDisabled,
    reference,
    hdms,
    isPristine,
    setIsPristine,
    consentRecord,
    consentRecordOrig,
    setConsentRecord,
    onReset,
    onConfirm,
    updateFieldValue,
    isFetchingConsentRecord,
    consentRecordError,
    events,
    openSnack,
    modalOpen,
    onModalClose,
    tabIdx,
    setTabIdx,
  } = useConsent(props);

  const { classes, acctMember } = props;

  const tabsClass = {
    root: classes.tabsRoot,
  };
  const tabClass = {
    root: classes.tabRoot,
    labelContainer: 'labelContainer',
  };

  return (
    <FormModal
      isModalOpen={modalOpen}
      disableBackdropClick={true}
      disabled={isFetchingConsentRecord}
      onClose={onModalClose}
      windowLike={true}
      positionOffset={{ x: '-25%', y: '-15%' }}
      style={{
        width: '60%',
      }}
      wrapperStyles={{
        overflowY: 'unset',
      }}
      containerStyle={{
        padding: 0,
        justifyContent: 'space-between',
      }}
      title={`Patient Consent Management ${acctMember}`}
      actions={
        consentRecord &&
        consentRecordOrig && (
          <ConsentActionPanel
            setConsentRecord={setConsentRecord}
            isPristine={
              isPristine &&
              [
                reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
                reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful']
                  .state,
              ].includes(consentRecord.state)
            }
            disabled={actionPanelDisabled}
            consentRecord={consentRecord}
            consentRecordOrig={consentRecordOrig}
            onReset={onReset}
            reference={reference}
            onConfirm={onConfirm}
          />
        )
      }>
      <div className={classes.root}>
        <Tabs
          classes={tabsClass}
          value={tabIdx}
          onChange={(_, s) => setTabIdx(s)}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab label="Consent Onboarding" value={0} classes={tabClass} />
          <Tab
            label={`History (${events.length})`}
            value={1}
            classes={tabClass}
          />
        </Tabs>
        {tabIdx === 0 && (
          <div
            className={className(classes.tabSpecificContainer, {
              [classes.tabOverflow]: [0, 2].includes(tabIdx),
            })}>
            {consentRecord && consentRecordOrig && (
              <ConsentSettings
                openSnack={openSnack}
                setConsentRecord={setConsentRecord}
                updateFieldValue={updateFieldValue}
                isPristine={
                  isPristine &&
                  [
                    reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
                    reference.CONSENT_STATES_BY_STATE_NAME[
                      'attempted_unsuccessful'
                    ].state,
                  ].includes(consentRecord.state)
                }
                setIsPristine={setIsPristine}
                disableActionPanel={setActionPanelDisabled}
                hdms={hdms}
                reference={reference}
                consentRecord={consentRecord}
                consentRecordOrig={consentRecordOrig}
              />
            )}
            {consentRecordError && (
              <div>
                <h2>There was an error fetching the consent record.</h2>
              </div>
            )}
            {isFetchingConsentRecord && <Loader />}
          </div>
        )}
        {tabIdx === 1 && (
          <ConsentHistory isModal classes={classes} events={events} />
        )}
      </div>
    </FormModal>
  );
};

const ConsentWrapper = (props) => {
  const {
    actionPanelDisabled,
    setActionPanelDisabled,
    reference,
    hdms,
    isPristine,
    setIsPristine,
    consentRecord,
    consentRecordOrig,
    setConsentRecord,
    onReset,
    onConfirm,
    updateFieldValue,
    isFetchingConsentRecord,
    consentRecordError,
    events,
  } = useConsent(props);

  return (
    <Grid container spacing={8}>
      {consentRecord && consentRecordOrig && (
        <>
          <Grid item xs={12} sm={6}>
            <ConsentSettings
              openSnack={openSnack}
              setConsentRecord={setConsentRecord}
              updateFieldValue={updateFieldValue}
              isPristine={
                isPristine &&
                [
                  reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
                  reference.CONSENT_STATES_BY_STATE_NAME[
                    'attempted_unsuccessful'
                  ].state,
                ].includes(consentRecord.state)
              }
              setIsPristine={setIsPristine}
              disableActionPanel={setActionPanelDisabled}
              hdms={hdms}
              reference={reference}
              consentRecord={consentRecord}
              consentRecordOrig={consentRecordOrig}
            />
            <ConsentActionPanel
              setConsentRecord={setConsentRecord}
              isPristine={
                isPristine &&
                [
                  reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
                  reference.CONSENT_STATES_BY_STATE_NAME[
                    'attempted_unsuccessful'
                  ].state,
                ].includes(consentRecord.state)
              }
              disabled={actionPanelDisabled}
              consentRecord={consentRecord}
              consentRecordOrig={consentRecordOrig}
              onReset={onReset}
              reference={reference}
              onConfirm={onConfirm}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ConsentHistory events={events} />
          </Grid>
        </>
      )}
      {consentRecordError && (
        <div>
          <h2>There was an error fetching the consent record.</h2>
        </div>
      )}
      {isFetchingConsentRecord && <Loader />}
    </Grid>
  );
};

const ConsentDirectLanding = (props) => {
  const { extras } = props.match.params;
  const [acctMember, flow_record_id] = extras.split(/,/);
  const flow = 'wos';
  const {
    actionPanelDisabled,
    setActionPanelDisabled,
    reference,
    hdms,
    isPristine,
    setIsPristine,
    consentRecord,
    consentRecordOrig,
    events,
    setConsentRecord,
    onReset,
    onConfirm,
    updateFieldValue,
    openSnack,
  } = useConsent({ ...props, acctMember, flow, flow_record_id });

  //obtain acctMember from match.params
  if (!consentRecord || !consentRecordOrig) {
    return <Loader />;
  }

  if (props.historyOnly === true) {
    return (
      <div style={{ height: '80vh', overflowY: 'auto', paddingBottom: 48 }}>
        <Grid container spacing={8} direction="row">
          <Grid item xs={12} sm={6}>
            <ConsentHistory events={events} mobileView />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div style={{ height: '80vh', overflowY: 'auto', paddingBottom: 48 }}>
      <Grid container spacing={8} direction="row">
        <Grid item xs={12} sm={6}>
          <div
            style={{
              background: 'yellow',
              border: '1px solid #000',
              padding: 8,
              margin: 8,
              fontSize: 12,
            }}>
            <strong>
              You are working consent activity for Work Order: {flow_record_id}
            </strong>
          </div>

          <ConsentSettingsMobile
            acctMember={acctMember}
            openSnack={openSnack}
            setConsentRecord={setConsentRecord}
            updateFieldValue={updateFieldValue}
            isPristine={
              isPristine &&
              [
                reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
                reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful']
                  .state,
              ].includes(consentRecord.state)
            }
            setIsPristine={setIsPristine}
            disableActionPanel={setActionPanelDisabled}
            hdms={hdms}
            reference={reference}
            consentRecord={consentRecord}
            consentRecordOrig={consentRecordOrig}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div
            style={{
              background: 'yellow',
              border: '1px solid #000',
              padding: 8,
              margin: 8,
            }}>
            <strong style={{ fontSize: 42 }}>WARNING-Drivers/RTs:</strong>
            <p>
              DO NOT do anything here if PATIENT IS ALREADY SUBSCRIBED AND
              VERIFIED for EITHER SMS or EMAIL. You will not get blocked from
              reaching ALLGOOD in that scenario. Attempt the ALLGOOD FIRST
              before doing antyhing on this screen. If you can successfully
              reach ALLGOOD, don't modify anything here unless the patient
              specifically asked you to.
            </p>
          </div>

          <ConsentActionPanelMobile
            setConsentRecord={setConsentRecord}
            isPristine={
              isPristine &&
              [
                reference.CONSENT_STATES_BY_STATE_NAME['no_attempts'].state,
                reference.CONSENT_STATES_BY_STATE_NAME['attempted_unsuccessful']
                  .state,
              ].includes(consentRecord.state)
            }
            disabled={actionPanelDisabled}
            consentRecord={consentRecord}
            consentRecordOrig={consentRecordOrig}
            onReset={onReset}
            reference={reference}
            onConfirm={onConfirm}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  me: state.me,
  accountConsent: state.accountConsent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSnack,
      confirmAccountConsent_ac,
      fetchAccountConsent_ac,
      updateHDMSField_ac,
    },
    dispatch
  );

// const ConnectedConsentActionPanel = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(styles)(ConsentActionPanel));

const ConnectedConsentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentWrapper));

const ConnectedConsentModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentModal));

const ConnectedConsentDirectLanding = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsentDirectLanding));

export {
  ConnectedConsentDirectLanding,
  ConnectedConsentModal,
  ConnectedConsentWrapper,
};
