export function fetchDCs_ac() {
  return (dispatch, getState) => {
    dispatch({ type: 'REQUEST_DCS' })
    return window.sch
      .get(`/api/dt/dcs/list`)
      .then(result => {
        dispatch({
          type: 'RECEIVED_DCS',
          pkg: result.pkg
        })
      })
      .catch(error => {
        dispatch({
          type: 'FAILED_DCS',
          error
        })
      })
  }
}

export function updateDC_ac(recordIdx, params, { onSuccess, onError } = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_DC_PENDING'
    })
    let _pkg = getState().dcs.pkg
    const { dc_id } = _pkg.dcs[recordIdx]
    let payload = {
      ...params,
      dc_id
    }
    return window.sch
      .post(`/api/dt/dc/update`, payload)
      .then(result => {
        _pkg.dcs[recordIdx] = result.updatedDC
        dispatch({
          type: 'UPDATE_DC_SUCCESS',
          pkg: _pkg
        })
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
        dispatch({
          type: 'UPDATE_DC_FAILED',
          error
        })
      })
  }
}
