import _ from 'lodash';

const serialize = function (obj) {
  const str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

const deserialize = function (str) {
  const obj = {};
  str.split('&').forEach(function (part) {
    const item = part.split('=');
    obj[item[0]] = decodeURIComponent(item[1]);
  });
  return obj;
};

function arrayMinus(A = [], B = [], get = (v) => v) {
  // 'get' to evaluate unique key for each item
  const map = {},
    C = [];

  for (let i = 0; i < B.length; i++) map[get(B[i])] = 1;

  for (let i = 0; i < A.length; i++) {
    if (!map.hasOwnProperty(get(A[i]))) C.push(A[i]);
  }

  return C;
}

const compareArrays = (a, b, accessor = '_id') => {
  if (a.length !== b.length) return false;

  return a.every((item, index) => item[accessor] === b[index][accessor]);
};

const justId = (obj = null, key = '_id') => {
  return Boolean(obj) ? { [key]: obj[key] } : null;
};

const cleanObject = (obj) =>
  _.omitBy(obj, (value) => {
    if (_.isArray(value)) {
      return _.isEmpty(value);
    } else if (_.isNull(value) || value === '') {
      return true;
    }
    return false;
  });

export {
  serialize,
  justId,
  arrayMinus,
  deserialize,
  compareArrays,
  cleanObject,
};
