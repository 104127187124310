import { serialize } from '../util/object';

export const actionTypes = {
  FETCH_QUEUES_SUCCESS: 'FETCH_QUEUES_SUCCESS',
  FETCH_QUEUES_ERROR: 'FETCH_QUEUES_ERROR',
  FETCH_QUEUES_PENDING: 'FETCH_QUEUES_PENDING',

  REMOVE_QUEUE_SUCCESS: 'REMOVE_QUEUE_SUCCESS',
  REMOVE_QUEUE_ERROR: 'REMOVE_QUEUE_ERROR',
  REMOVE_QUEUE_PENDING: 'REMOVE_QUEUE_PENDING',

  UPDATE_QUEUE_SUCCESS: 'UPDATE_QUEUE_SUCCESS',
  UPDATE_QUEUE_ERROR: 'UPDATE_QUEUE_ERROR',
  UPDATE_QUEUE_PENDING: 'UPDATE_QUEUE_PENDING',

  ADD_QUEUE_SUCCESS: 'ADD_QUEUE_SUCCESS',
  ADD_QUEUE_ERROR: 'ADD_QUEUE_ERROR',
  ADD_QUEUE_PENDING: 'ADD_QUEUE_PENDING',

  FETCH_QUEUE_GROUPS_SUCCESS: 'FETCH_QUEUE_GROUPS_SUCCESS',
  FETCH_QUEUE_GROUPS_ERROR: 'FETCH_QUEUE_GROUPS_ERROR',
  FETCH_QUEUE_GROUPS_PENDING: 'FETCH_QUEUE_GROUPS_PENDING',

  REMOVE_QUEUE_GROUP_SUCCESS: 'REMOVE_QUEUE_GROUP_SUCCESS',
  REMOVE_QUEUE_GROUP_ERROR: 'REMOVE_QUEUE_GROUP_ERROR',
  REMOVE_QUEUE_GROUP_PENDING: 'REMOVE_QUEUE_GROUP_PENDING',

  UPDATE_QUEUE_GROUP_SUCCESS: 'UPDATE_QUEUE_GROUP_SUCCESS',
  UPDATE_QUEUE_GROUP_ERROR: 'UPDATE_QUEUE_GROUP_ERROR',
  UPDATE_QUEUE_GROUP_PENDING: 'UPDATE_QUEUE_GROUP_PENDING',

  ADD_QUEUE_GROUP_SUCCESS: 'ADD_QUEUE_GROUP_SUCCESS',
  ADD_QUEUE_GROUP_ERROR: 'ADD_QUEUE_GROUP_ERROR',
  ADD_QUEUE_GROUP_PENDING: 'ADD_QUEUE_GROUP_PENDING'
};

export function fetchQueues_ac(params) {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_QUEUES_PENDING });

    return window.sch
      .get('/api/connect_holds/queues' + serialize(params), {})
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_QUEUES_SUCCESS,
          data: result.results
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_QUEUES_ERROR,
          error
        });
      });
  };
}

export function removeQueue_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: actionTypes.REMOVE_QUEUE_PENDING });

    return window.sch
      .post('/api/connect_holds/queues/remove', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.REMOVE_QUEUE_SUCCESS,
          data: params._id
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.REMOVE_QUEUE_ERROR,
          error
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function updateQueue_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: actionTypes.UPDATE_QUEUE_PENDING });

    return window.sch
      .post('/api/connect_holds/queues/update', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.UPDATE_QUEUE_SUCCESS,
          data: params
        });

        if (onSuccess) {
          onSuccess(params);
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_QUEUE_ERROR,
          error
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function addQueue_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: actionTypes.ADD_QUEUE_PENDING });

    return window.sch
      .post('/api/connect_holds/queues/add', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        const created =
          typeof result.data === 'object'
            ? result.data
            : { _id: result.data, ...params };

        dispatch({
          type: actionTypes.ADD_QUEUE_SUCCESS,
          data: created
        });

        if (onSuccess) {
          onSuccess(created);
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_QUEUE_ERROR,
          error
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function fetchQueueGroups_ac(params) {
  return dispatch => {
    dispatch({ type: actionTypes.FETCH_QUEUE_GROUPS_PENDING });

    return window.sch
      .get('/api/connect_holds/queue_groups' + serialize(params), {})
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_QUEUE_GROUPS_SUCCESS,
          data: result.results
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FETCH_QUEUE_GROUPS_ERROR,
          error
        });
      });
  };
}

export function removeQueueGroup_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: actionTypes.REMOVE_QUEUE_GROUP_PENDING });

    return window.sch
      .post('/api/connect_holds/queue_groups/remove', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.REMOVE_QUEUE_GROUP_SUCCESS,
          data: params._id
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.REMOVE_QUEUE_GROUP_ERROR,
          error
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function updateQueueGroup_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: actionTypes.UPDATE_QUEUE_GROUP_PENDING });

    return window.sch
      .post('/api/connect_holds/queue_groups/update', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.UPDATE_QUEUE_GROUP_SUCCESS,
          data: params
        });

        if (onSuccess) {
          onSuccess(params);
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.UPDATE_QUEUE_GROUP_ERROR,
          error
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function addQueueGroup_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: actionTypes.ADD_QUEUE_GROUP_PENDING });

    return window.sch
      .post('/api/connect_holds/queue_groups/add', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error);
        }

        const created =
          typeof result.data === 'object'
            ? result.data
            : { _id: result.data, ...params };

        dispatch({
          type: actionTypes.ADD_QUEUE_GROUP_SUCCESS,
          data: created
        });

        if (onSuccess) {
          onSuccess(created);
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.ADD_QUEUE_GROUP_ERROR,
          error
        });

        if (onError) {
          onError(error);
        }
      });
  };
}
