import { Grid, FormControl, FormLabel, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import moment from 'moment';
import { FormModal } from '../common';

const styles = () => ({
  formControl: {
    flexDirection: 'column',
    marginRight: 24,
    fontSize: 11,
    width: 250,
  },
  formLabel: {
    marginRight: 8,
    fontSize: 11,
  },
  date: {
    width: '100%',
    padding: '3px 8px',
    borderRadius: '4px',
    margin: '8px 0 4px 0',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
});

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

// Last month
const defaultFilters = {
  start_date: moment().subtract(1, 'month').format(DEFAULT_DATE_FORMAT),
  end_date: moment().format(DEFAULT_DATE_FORMAT),
};

const ReactHealthReportModal = (props) => {
  const {
    classes,
    open = true,
    onClose,
    patient,
    isDownloading,
    openSnack,
    onDownload,
  } = props;
  const [filters, setFilters] = useState(defaultFilters);

  const onFilterChange = (value, field) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const handleSubmit = () => {
    if (moment(filters.start_date).isSameOrAfter(filters.end_date)) {
      openSnack({
        message: 'Start date cannot be same or after end date',
        variant: 'error',
      });
      return;
    }
    onDownload(patient.patient_id, filters);
  };

  const handleReset = () => {
    setFilters(defaultFilters);
  };

  return (
    <FormModal
      isModalOpen={open}
      disabled={isDownloading}
      closeOnSubmit={false}
      disableBackdropClick={true}
      onClose={onClose}
      onReset={handleReset}
      submitLabel={'Download'}
      onSubmit={handleSubmit}
      cancelLabel="Close"
      wrapperStyles={{
        width: '60%',
      }}
      title={`ReactHealth Report for ${patient.last_name}, ${patient.first_name}`}>
      <Grid
        container
        style={{
          padding: '12px',
        }}>
        <Grid item>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="start_date" className={classes.formLabel}>
              Start Date
            </FormLabel>

            <TextField
              margin="dense"
              name={'start_date'}
              id={'start_date'}
              disabled={isDownloading}
              value={filters.start_date}
              onChange={(e) => {
                onFilterChange(
                  moment(e.target.value).format(DEFAULT_DATE_FORMAT),
                  'start_date'
                );
              }}
              style={{ minWidth: 150 }}
              type={'date'}
              inputProps={{
                format: 'MM-DD-YYYY',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="end_date" className={classes.formLabel}>
              End Date
            </FormLabel>

            <TextField
              margin="dense"
              name={'end_date'}
              id={'end_date'}
              disabled={isDownloading}
              value={filters.end_date}
              onChange={(e) => {
                onFilterChange(
                  moment(e.target.value).format(DEFAULT_DATE_FORMAT),
                  'end_date'
                );
              }}
              style={{ minWidth: 150 }}
              type={'date'}
              inputProps={{
                format: 'MM-DD-YYYY',
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </FormModal>
  );
};

export default withStyles(styles)(ReactHealthReportModal);
