import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';

const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {
    backgroundColor: 'red',
    color: '#fff',
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0',
  },
  formControl: {
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row',
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
  },
  scrollArea: {
    overflowY: 'auto',
    height: '100%',
    marginBottom: 40,
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  padding4: {
    padding: 4,
  },
});

const DownloadFaxLanding = ({
  changePage,
  classes,
  match: {
    params: { faxId },
  },
}) => {
  const [statusMsg, setError] = useState('...retrieving original fax');
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    let endpoint = '/api/direct_download/' + faxId;
    setError('...preparing pdf....');
    window.sch
      .download(endpoint, faxId + '.pdf', true)
      .then((dataUrl) => {
        if (dataUrl.signedUrl) {
          //download di not return a dataUrl, instead it returned a signedUrl
          window.location.href = dataUrl.signedUrl;
          return;
        }
        setPdfUrl(dataUrl);
        setError('...done');
      })
      .catch((err) => {
        console.log(err);
        setError('something went wrong');
      });
    return;
  }, [faxId]);

  return (
    <div className={classes.content}>
      <div>{statusMsg}</div>
      <div className={classes.scrollArea}>
        {pdfUrl && (
          <iframe
            title="pdf preview"
            src={pdfUrl + '#zoom=96'}
            style={{ width: '99%', height: '94%' }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  dcs: stateFromStore.dcs,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      close_snack_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DownloadFaxLanding))
);
