import types from '../types';

export function getAllPdfDetail() {
  return {
    type: types.GETALL_PDFDETAIL,
  };
}
export function getAllPdfDetailSuccess(payload) {
  return {
    type: types.GETALL_PDFDETAIL_SUCCESS,
    payload,
  };
}

export function postPdfDetail() {
  return {
    type: types.POST_PDFDETAIL,
  };
}
export function postPdfDetailSuccess(payload) {
  return {
    type: types.POST_PDFDETAIL_SUCCESS,
    payload,
  };
}

export function pdfDetailRequestFailed(payload) {
  return {
    type: types.PDFDETAIL_REQUEST_FAILED,
    payload,
  };
}

export function getPdfFiles(params) {
  return (dispatch) => {
    dispatch(getAllPdfDetail());

    window.sch
      .get(`/api/packets/${params}`)
      .then((res) => {
        const data = res.pkg;
        dispatch(getAllPdfDetailSuccess(data));
      })
      .catch((error) => {
        dispatch(pdfDetailRequestFailed(error));
      });
  };
}

export function addSelectedPdfFiles(params) {
  return (dispatch) => {
    dispatch(postPdfDetail());

    window.sch
      .post(`/api/packets`, { docs: params })
      .then((res) => {
        const data = res.pkg;
        dispatch(postPdfDetailSuccess(data));
      })
      .catch((error) => {
        dispatch(pdfDetailRequestFailed(error));
      });
  };
}
