/* eslint no-unused-vars: "off" */
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import { orange, purple } from '@material-ui/core/colors'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import dateProcessing from '../../util/dateProcessing.js'
import { PaginationWithNums } from '../PaginationNew.js'
import Loader from '../Shared/Loader.js'
import { searchFBPatient_ac } from '../../actions/fb_search.ac'


const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  title: {
    flexGrow: 1
  },
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 0.25,
    height: '100%',
    backgroundColor: '#fbf8f896',
    display:'flex',
    flexDirection:'column'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  fullHeight: {
    height: '100%'
  },
  scrollable: {
    height: '100%',
    overflowY: 'auto',
    '& .table':{
      minHeight: '180px',
    }
  },
  tableRowSelected: {
    backgroundColor: 'yellow'
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white'
  }
})

const getpath = n => {
  return (
    (Math.floor(n / 1000) * 1000)
      .toString()
      .padStart(8)
      .replace(/ /g, '0') +
    '/' +
    n
  )
}

class FBPatientSearchResult extends React.Component {

  /* temporary DIRTY fix to let user download TIFS from S3 */
  downloadTif(e) {
    let proms = (e.pages || []).map(p => {
      return new Promise((resolve, reject) => {
        const s3key = [
          'https://s3.us-west-2.amazonaws.com',
          'sch.filebound',
          getpath(p.doc_id) + '.' + p.ext
        ].join('/')
        let params = {
          s3key
        }
        return window.sch
          .post('/api/s3/object/getsignedurl', params)
          .then(result => {
            window.open(result.signedUrl, '_' + p.doc_id + '.' + p.ext)
            return resolve()
          })
          .catch(err => {
            console.log(err)
            return reject()
          })
      })
    })

    return Promise.all(proms)
      .then(() => {
        console.log('completed')
      })
      .catch(e => console.error(e))
  }

  render() {
    const { classes, fbSearchInView } = this.props
    const { result, isFetching, error, pagination: pg } = fbSearchInView
    if (isFetching) {
      return (
        <div className={classes.root}>
          <Loader message="...loading" />
        </div>
      )
    }

    if (!result) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      )
    }
    if (error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>....error searching</h4>
        </div>
      )
    }
    if (!result.files.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No search result found.</h4>
        </div>
      )
    }
    const tblRowClass = {
      root: classes['tableRow'],
      selected: classes['tableRowSelected']
    }

    const pageStatement = `${result.files.length} matches in Filebound ${result.ver
      } for ${result.search_entry}`

    let pagination = '';
    let pageSelector = ''

    if (pg) {
      // const tsStartStatement = filters.tsStart
      //   ? `from ${moment(filters.tsStart).format('MM-DD-YYYY')} `
      //   : null
      // pageStatement = [
      //   `Displaying 
      //     ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
      //   `Page ${this.state.currentPage} of ${pg.totalPagesAvailable
      //   } Currently filtered ${tsStartStatement}to ${moment(
      //     filters.tsEnd
      //   ).format('MM-DD-YYYY')}`
      // ].join('-')

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.props.onPageChange}
          />
        )
      }
    }

    return (
      <Grid container spacing={8} className={classes.scrollable}>
        <Grid item xs={12} sm={12} className={classes.fullHeight}>
          <main className={classes.content}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {pageStatement}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {pageSelector}
              </Grid>
            </Grid>
            <div className={`${classes.scrollable} table`}>
              <Table padding="dense">
                <TableBody>
                  {(result.files || []).map((item, index) => {
                    return (
                      <TableRow key={index} classes={tblRowClass}>
                        <TableCell
                          align="left"
                          width="40%"
                          variant="body"
                          style={{ padding: '0px' }}
                          className={classes.tableBodyCell}>
                          <strong>
                            {`${index + 1}) ${item.name} - DOB: ${item.dob
                              } - A${item.account}`}{' '}
                          </strong>
                          {(item.docs_for_display || []).map((d, di) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={di}
                              onClick={() => this.downloadTif(d)}>
                              <span>
                                {d.divname} ({d.pages.length}pgs)
                              </span>
                            </div>
                          ))}
                        </TableCell>
                        <TableCell
                          align="left"
                          width="40%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          <span>DOS:{item.service_dt}</span>
                          <div style={{ fontSize: 11, fontWeight: 'bold' }}>
                            <span>
                              WO: {item.wo_id ? item.wo_id : 'n/a/'} -{' '}
                              {dateProcessing.formatDate(
                                item.order_dt,
                                'MM/DD/YYYY'
                              )}
                            </span>
                            <br />
                            <span>FILE ID: {item.fileid}</span>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          width="20%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          <span>Status: {item.status}</span>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </main>
        </Grid>
      </Grid>
    )
  }
}

FBPatientSearchResult.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch => bindActionCreators({
  searchFBPatient_ac
}, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBPatientSearchResult))
)
