import _ from 'lodash'

import { activeRentalsActionTypes } from '../actions/active_rentals.ac'

export function activeRentals(state = {
  isFetching: false,
  records: null,
  accountNums: null,
  pagination: null,
  error: {
    all: null
  },
  idn: null
}, action) {
  switch (action.type) {
    case activeRentalsActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case activeRentalsActionTypes.RECEIVED_ACTIVE_RENTALS_LIST:
      return {
        ...state,
        records: action.record.records,
        accountNums: action.record.account_nums,
        pagination: {
          recordsPerPage: action.record.records_per_page,
          totalRecords: _.get(action.record, ['total', 'n'], 0),
          pageList: getPageList(action.record)
        },
        error: {
          all: null
        }
      }
    case activeRentalsActionTypes.FAILED_ACTIVE_RENTALS_LIST:
      return {
        ...state,
        records: null,
        accountNums: null,
        pagination: null,
        error: {
          all: action.error
        }
      }
    case activeRentalsActionTypes.SET_CURRENT_IDN:
      return {
        ...state,
        idn: action.idn
      }
    case activeRentalsActionTypes.RECEIVED_IDN_ACTIVE_RENTAL:
      return {
        ...state,
        records: state.records ?
          [...state.records, ...action.record.records] :
          action.record.records,
        accountNums: state.accountNums ?
          [...state.accountNums, ...action.record.account_nums] :
          action.record.account_nums,
        pagination: 'None',
        error: {
          ...state.error,
          [action.account]: null
        }
      }
    case activeRentalsActionTypes.FAILED_IDN_ACTIVE_RENTAL:
      return {
        ...state,
        error: {
          ...state.error,
          [action.account]: true
        }
      }
    default:
      return state
  }
}

const getPageList = record => {
  if (!record.records_per_page || !_.get(record, ['total', 'n'], false)) {
    return null
  }

  const { records_per_page, total } = record
  const totalPagesAvailable = Math.ceil(total.n / records_per_page)

  return _.range(0, totalPagesAvailable)
}
