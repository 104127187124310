import { WorkUnitActionTypes } from '../actions/dfworkunits_report_bydate_byhours.ac'

const initialState = {
  isFetching: false,
  records: null,
  bkt_label_hash: null,
  pagination: null,
  error: null
}
export const workUnitReportsInView = (state = initialState, action) => {
  switch (action.type) {
    case WorkUnitActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case WorkUnitActionTypes.RECEIVED_BY_DATE_BY_HOURS_REPORT:
      return {
        ...state,
        bkt_label_hash: action.bkt_label_hash,
        records: action.records,
        pagination: action.pagination,
        error: null
      }
    case WorkUnitActionTypes.FAILED_RECEIVING_REPORT:
      return {
        ...state,
        records: null,
        pagination: null,
        bkt_label_hash: null,
        error: action.error
      }
    default:
      return {
        ...state
      }
  }
}
