import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import dateUtils from '../../../util/dateProcessing';
import { COLLECTION_KINDS } from '../IdxAdminLog/constants';

const getParamsString = (params) => {
  let { action, ...otherParams } = params;

  return JSON.stringify(otherParams, null, 2);
};

const LogItem = (props) => {
  const { log } = props;

  return (
    <Grid
      container
      spacing={8}
      style={{ borderBottom: '1px solid #c5c5c5', padding: 4 }}>
      <Grid item>
        <Typography variant="subtitle1" color="primary">
          <strong>{log.a.toUpperCase()}</strong>&ensp;
          {log.a === 'remove' ? 'from' : 'in'}&ensp;
          {COLLECTION_KINDS[log.c]}
        </Typography>
        <Typography variant="body2">
          By{' '}
          <strong>
            {log.u_nm} ({log.u_id})
          </strong>
          ,&ensp;
          {dateUtils.relativeTime(log.ts)}
          <br />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" component="div">
          <strong>Params:</strong>
          <pre
            style={{
              fontSize: 'inherit',
              padding: 8,
              borderRadius: 4,
              margin: 0,
              backgroundColor: '#e5e5e5',
              maxHeight: 200,
              overflowY: 'auto',
            }}>
            {getParamsString(log.p)}
          </pre>
        </Typography>
      </Grid>
    </Grid>
  );
};
export default LogItem;
