export function attachGenSubmissionFilesToStore_ac(submissionFiles) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTACH_GEN_UPLOAD_FILES_TO_STORE', submissionFiles });
  };
}

export function resetGenSubmissionFilesToStore_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'RESET_GEN_UPLOAD_ACTIVITY' });
  };
}

export function beginGenUploadFiles_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({
      type: 'BEGIN_UPLOAD_FILES_FOR_GEN_SUBMISSION',
    });
  };
}

export function completedGenUploadFiles_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'COMPLETED_UPLOAD_FILES_FOR_GEN_SUBMISSION' });
    dispatch({ type: 'RESET_GEN_UPLOAD_ACTIVITY' });
  };
}

export function failedGenUploadFiles_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'FAILED_UPLOAD_FILES_FOR_GEN_SUBMISSION' });
  };
}
