import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setFrequencyIdentifiers_ac, loadFrequencyList_ac } from '../../actions/pap_calculator.ac'
import { open_snack_ac } from '../../actions/snack.ac.js'
import Loader from '../Shared/Loader'
import DateUtil from '../../util/dateProcessing'
import { payers, maskTypes } from './../../constants/PAPFrequencyConstant'


const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '50%'
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  error: {
    color: red[500]
  },
  mt10: {
    marginTop: '10px'
  },
})

const defaultFormFields = {
  last_date_of_service: '',
  payer: 'medi-cal',
  mask_type: 'nasal-cushion'
}

const validationFields = {
  last_date_of_service: false,
  payer: false
}

class PAPFrequencyIdentifierComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      formFields: {
        ...defaultFormFields
      },
      errors: {
        ...validationFields
      }
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(pre => ({
      formFields: {
        ...pre.formFields,
        [name]: value
      }
    }), () => {
      this.setIdentifiers()
      if (this.state.errors[name]) {
        this.validateForm()
      }
    })
  }

  generate = () => {
    this.props.handleModalOpenClick();
  }

  setIdentifiers = () => {
    const isValidForm = this.validateForm()
    if (!isValidForm) return
    const { formFields } = this.state
    this.props.setFrequencyIdentifiers_ac(formFields)
    this.props.loadFrequencyList_ac();

  }

  validateForm = () => {
    let dos_err = false // date of service
    let payer_err = false
    let is_valid_form = true
    let isValidDate = false

    const { formFields } = this.state

    if (!formFields['last_date_of_service']) {
      dos_err = true
      is_valid_form = false
    }

    if (formFields['last_date_of_service']) {
      isValidDate = DateUtil.isValidDate(formFields['last_date_of_service'])
      dos_err = !isValidDate
      is_valid_form = isValidDate
    }

    if (!formFields['payer']) {
      payer_err = true
      is_valid_form = false
    }

    this.setState({
      errors: {
        last_date_of_service: dos_err,
        payer: payer_err
      }
    })

    return is_valid_form
  }


  render() {
    const { accountId } = this.props;
    const { papCalculator, classes } = this.props
    const { errors } = this.state

    let actionButtons = <>
      <Grid item xs={12}>
        <FormControl
          style={{ marginLeft: 0 }}
          className={classes.formControl}>
          <div className={classes.flexDisplay}>
            {papCalculator.isSaving && (
              <Loader type="circular" size={15} color="primary" />
            )}
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={this.generate}
              className={classes.button}
              disabled={papCalculator.isSaving || papCalculator.selectedItems.length === 0}
            >
              Generate Notes
            </Button>
          </div>
        </FormControl>
      </Grid>
    </>

    return (
      <div className={classes.content}>
        <Grid item xs={12}>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}
            fullWidth
          >
            {accountId &&
              (
                <TextField
                  label="Account ID"
                  fullWidth
                  variant="standard"
                  disabled
                  name="account_id"
                  value={accountId}
                />
              )}
            <TextField
              label="Last Date Of Service"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              required
              placeholder="MM-DD-YYYY"
              name="last_date_of_service"
              value={this.state.formFields.last_date_of_service}
              error={errors.last_date_of_service}
              helperText={errors.last_date_of_service ? 'Please enter valid last date of service' : ''}
            />
            <FormLabel className={classes.mt10} component="legend">
              <strong>Payer</strong>
            </FormLabel>
            <Select
              fullWidth
              value={this.state.formFields.payer}
              name="payer"
              onChange={this.handleChange}
            >
              {(payers || []).map(d => (
                <MenuItem
                  style={{ fontSize: 11, fontWeight: 600 }}
                  key={d.code}
                  value={d.code}>
                  {d.lbl.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
            {errors.payer ? (
              <Typography
                component="span"
                className={classes.error}
                color="textPrimary">
                Please Select Payer
              </Typography>
            ) : (
              ''
            )}
            <FormLabel className={classes.mt10} component="legend">
              <strong>Mask Type</strong>
            </FormLabel>
            <Select
              fullWidth
              value={this.state.formFields.mask_type}
              name="mask_type"
              onChange={this.handleChange}
            >
              {(maskTypes || []).map(d => (
                <MenuItem
                  style={{ fontSize: 11, fontWeight: 600 }}
                  key={d.code}
                  value={d.code}>
                  {d.lbl.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Divider component="hr" />
        {actionButtons}
      </div>
    )
  }
}

PAPFrequencyIdentifierComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  papCalculator: stateFromStore.papCalculator,
  lookups: stateFromStore.lookups,
})

// might be needed in future
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      open_snack_ac,
      setFrequencyIdentifiers_ac,
      loadFrequencyList_ac
    },
    dispatch
  )

export const PAPFrequencyIdentifier = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PAPFrequencyIdentifierComponent))
)
