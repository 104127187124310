import { Divider } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const styles = theme => ({
  issue: {
    maxHeight: 300,
    overflowY: 'auto',
    flexWrap: 'nowrap',
    marginTop: 0,
    padding: '0 16px 16px 16px'
  },
  pill: {
    backgroundColor: 'orange',
    fontSize: 10,
    padding: 6,
    float: 'left',
    marginRight: 3
  }
})
class MednecIssueLog extends React.Component {
  render() {
    const { classes, issue } = this.props
    return (
      <div className={classes.issue}>
        <Typography color="primary">
          Issue logged by {issue.u_nm}{' '}
          {moment(issue.ts).format('MM-DD-YYYY hh:mma')}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            {issue.issue_lbls.length > 0 &&
              issue.issue_lbls.map((issueLbl, i) => (
                <div key={i} className={classes.pill}>
                  {issueLbl}
                </div>
              ))}
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">
              <strong> Comment:</strong> {issue.comment}
            </Typography>
          </Grid>
        </Grid>
        <Divider component="hr" />
      </div>
    )
  }
}

MednecIssueLog.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({})

const mapDispatchToProps = (dispatch, propsOfMednecIssueLog) =>
  bindActionCreators({}, dispatch)

export const MednecIssueLogView = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MednecIssueLog))
)
