import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { NavLink, withRouter } from 'react-router-dom'

const styles = theme => ({
  root: {
    width: '100%',
    color: 'brown',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  primary: {
    fontSize: '10px',
    color: 'red'
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4
  },
  link: {
    textDecoration: 'none'
  },
  btnText: {
    fontSize: 12,
    color: 'blue'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  }
})

class PaErrorsNavList extends React.Component {
  state = {
    open: true
  }

  handleClick = () => {
    this.setState(state => ({ open: !state.open }))
  }

  render() {
    const { classes } = this.props
    let now = new moment()
    let pathParts = this.props.location.pathname.split(/\//)
    let dtStr = pathParts.pop()
    let kind = pathParts.pop()

    let datesDisplay = _.range(0, 5).map(dayOffset => {
      return now.add(-1, 'days').format('ddd MM-DD-YYYY')
    })
    now = new moment()
    let dates = _.range(0, 5).map(dayOffset => {
      let dt = now.add(-1, 'days').format('MM-DD-YYYY')
      return {
        dt,
        selected: dtStr === dt && kind === 'initials'
      }
    })

    now = new moment()
    let rental_dates = _.range(0, 5).map(dayOffset => {
      let dt = now.add(-1, 'days').format('MM-DD-YYYY')
      return {
        dt,
        selected: dtStr === dt && kind === 'rentals'
      }
    })
    /*
    entries = [
      { payerGroup: 'ccs', kind: 'initials'},
      { payerGroup: 'medical', kind: 'initials'},
      { payerGroup: 'non_cap', kind: 'initials'},
      { payerGroup: 'cap', kind: 'initials'},

      { payerGroup: 'ccs', kind: 'rentals'},
      { payerGroup: 'medical', kind: 'rentals'},
      { payerGroup: 'non_cap', kind: 'rentals'},
      { payerGroup: 'cap', kind: 'rentals'}
    ]
    */
    let listItemClass = {
      primary: classes.btnText
    }

    return (
      <div className={[classes.root, classes.scrollArea].join(' ')}>
        <List component="nav">
          <ListItem>
            <ListItemText
              className={classes.primary}
              primary={'Today is ' + moment().format('ddd MM-DD-YYYY')}
            />
          </ListItem>
          {dates.map((d, idx) => (
            <NavLink
              key={'initials' + d.dt}
              className={classes.link}
              to={'/pa_errors/ccs/initials/' + d.dt}>
              <ListItem selected={d.selected} button>
                <ListItemText
                  classes={listItemClass}
                  primary={'Initials ' + datesDisplay[idx]}
                />
              </ListItem>
            </NavLink>
          ))}
          <Divider component="hr" />

          {rental_dates.map((d, idx) => (
            <NavLink
              key={'rentals' + d.dt}
              className={classes.link}
              to={'/pa_errors/ccs/rentals/' + d.dt}>
              <ListItem selected={d.selected} button>
                <ListItemText
                  classes={listItemClass}
                  primary={'Rentals ' + datesDisplay[idx]}
                />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
    )
  }
}

PaErrorsNavList.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = stateFromStore => ({
  paErrorsPayerGroup: stateFromStore.paErrorsList.payerGroup
})

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(PaErrorsNavList))
)
