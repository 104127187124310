export function myHistory_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware

    dispatch({
      type: 'REQUEST_MY_HISTORY',
      isFetching: true
    })

    //defaults:
    let postData = {}
    let target_url = `/api/userlists/idn/get`

    /* apply search pattern logic here */
    const ptn = 'idn'
    switch (ptn) {
      default:
        //idn or account
        return window.sch
          .post(target_url, postData)
          .then(results => {
            dispatch({
              type: 'RECEIVED_MY_HISTORY',
              results: results,
              msg: results.msg
            })
          })
          .catch(error => {
            console.error('error:', error)
            dispatch({
              type: 'FAILED_RECEIVED_MY_HISTORY',
              error,
              msg: ''
            })
          })
    }
  }
}
