import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { push } from 'connected-react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import { open_snack_ac } from '../../actions/snack.ac.js'
import {
  fetchTodosSummary_ac,
  fetchTodos_ac,
  locallyUpdateTodoDoc_ac,
  toggleAllCards_ac
} from '../../actions/todos.ac.js'
import FilterListing from '../FilterListing'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import ExpandableTodoRow from './ExpandableTodoRow'

const commonStyles = {
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  pagination: {
    marginTop: 0
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  }
}

const styles = theme => ({
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: { ...commonStyles.scrollArea },
  pagination: { ...commonStyles.pagination },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  summaryLabel: {
    color: '#56493e',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: { ...commonStyles.pageStatement },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    cursor: 'pointer',
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

const LANDING_BASEPATH = '/todos_new'

const DEFAULT_FILTERS = {
  status: {
    lbl: 'Status Filter',
    options: [
      { code: 'new', lbl: 'NEW', checked: true },
      { code: 'pending', lbl: 'PENDING', checked: true },
      { code: 'closed', lbl: 'CLOSED', checked: false }
    ]
  },
  category: {
    lbl: 'Category Filter',
    options: [{ lbl: 'ALL', code: 'all', checked: true }]
  },
  ownership: {
    lbl: 'Involvement Filter',
    options: [
      { code: 'all', lbl: 'All', checked: false },
      { code: 'assignee_id', lbl: 'Assigned to Me', checked: true },
      { code: 'helper_ids', lbl: "I'm a Member Of", checked: true },
      { code: 'creator_id', lbl: 'Created by Me', checked: true }
    ]
  }
}

const UnstyledTodosTableOnly = props => {
  const {
    classes,
    todosInView,
    onLocalUpdate,
    onToggleCards,
    pageStatement,
    pageSelector
  } = props

  return (
    <>
      <div className={classes.pagination}>
        <Grid container spacing={8} alignItems="flex-end">
          <Grid item xs={6} sm={7}>
            <Typography
              style={{ cursor: 'pointer' }}
              color="textSecondary"
              onClick={onToggleCards}>
              [Expand/Collapse All +/-]
            </Typography>
            <Typography className={classes.pageStatement} color="textSecondary">
              {pageStatement}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            {pageSelector}
          </Grid>
          {!props.hideSwitch && (
            <Grid item xs={3} sm={3} style={{ textAlign: 'right' }}>
              <Link className={classes.switchLink} to="/todos">
                <Typography variant="body2" color="inherit">
                  <strong>Switch to card view</strong>
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </div>

      <div className={classes.scrollArea}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell width="1%" style={{ padding: '8px' }} />
              <TableCell width="26%" style={{ padding: '8px' }}>
                Task
              </TableCell>
              <TableCell width="18%" style={{ padding: '8px' }}>
                Assignee
              </TableCell>
              <TableCell width="21%" style={{ padding: '8px' }}>
                Logs (Newest to Oldest)
              </TableCell>
              <TableCell width="21%" style={{ padding: '8px' }}>
                Comments (Newest to Oldest)
              </TableCell>
              <TableCell width="13%" style={{ padding: '8px' }}>
                Timeline
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {todosInView && todosInView.records ? (
              todosInView.records.map((todoDoc, posInSet) => {
                return (
                  <ExpandableTodoRow
                    key={todoDoc._id + '-' + posInSet}
                    expanded={
                      todoDoc.expanded === undefined || todoDoc.expanded
                    }
                    todoDoc={todoDoc}
                    onLocalUpdate={onLocalUpdate}
                    posInSet={posInSet}
                  />
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography color="textSecondary">
                    No data to show.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}

const TodosTableOnly = withStyles(() => ({
  pageStatement: { ...commonStyles.pageStatement },
  pagination: { ...commonStyles.pagination },
  scrollArea: { ...commonStyles.scrollArea },
  switchLink: {
    color: '#2196f3',
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))(UnstyledTodosTableOnly)

class TodosLanding extends BaseLandingTemplate {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      last_stats_ts: null,
      filterSequence: ['ownership', 'status', 'category'],
      filterChangeInProgress: false,
      filters: {},
      filtersSet: false,
      filterMsg: '',
      activeFilterBy: 'filters',
      lastToggleAllCards: true,
      savingToGoogleSheet: false,
      simpleView: false,
      isTableView: true
    }
    this.onToggleCards = this.onToggleCards.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.fetch = this.fetch.bind(this)
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this)
    this.fetchByIds = this.fetchByIds.bind(this)
    this.filterStatement = this.filterStatement.bind(this)
    this.onLocalUpdate = this.onLocalUpdate.bind(this)
    this.getFetchParams = this.getFetchParams.bind(this)
    this.saveToGoogleSheet = this.saveToGoogleSheet.bind(this)
    this.fetchBySmartFilter = this.fetchBySmartFilter.bind(this)
  }

  onToggleCards() {
    this.props.toggleAllCards_ac(!this.state.lastToggleAllCards)
    this.setState({ lastToggleAllCards: !this.state.lastToggleAllCards })
  }

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) })
  }

  componentDidMount() {
    const { todosInView, lookups, setTitle_ac, me } = this.props
    if (this.props.me.user === null) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    setTitle_ac('DF Task Listing')

    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k

    if (this.state.filtersSet === false) {
      let _default_filters = {
        ...DEFAULT_FILTERS,
        category: {
          lbl: 'Category Filter',
          options: TODO_STATE_REF.map(cat => {
            return { ...cat, checked: true }
          })
        }
      }

      if (_.get(me, 'user.filters.todos', false)) {
        for (const k in _default_filters) {
          _default_filters[k].options = _default_filters[k].options.map(opt => {
            if (me.user.filters.todos[k]) {
              opt.checked = me.user.filters.todos[k].indexOf(opt.code) !== -1
            }
            return opt
          })
        }
      }

      this.setState({
        filters: _default_filters,
        filtersSet: true
      })
      return
    }

    if (
      todosInView.isFetching === false &&
      todosInView.records === null &&
      !todosInView.error
    ) {
      return this.fetch()
    }

    if (
      todosInView.pagination &&
      todosInView.isFetching === false &&
      todosInView.pagination.currentPageNum !== this.state.page &&
      todosInView.pagination.currentPageNum !== -1
    ) {
      if (this.state.activeFilterBy === 'ids') {
        return this.fetchByIds(
          this.state.lastEE,
          this.state.filterMsg,
          this.state.page
        )
      }
      return this.fetch()
    }
  }

  componentDidUpdate() {
    const { todosInView, lookups, me } = this.props
    if (this.props.me.user === null) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k
    if (this.state.filtersSet === false) {
      let _default_filters = {
        ...DEFAULT_FILTERS,
        category: {
          lbl: 'Category Filter',
          options: TODO_STATE_REF.map(cat => {
            return { ...cat, checked: true }
          })
        }
      }
      if (_.get(me, 'user.filters.todos', false)) {
        for (const k in _default_filters) {
          _default_filters[k].options = _default_filters[k].options.map(opt => {
            if (me.user.filters.todos[k]) {
              opt.checked = me.user.filters.todos[k].indexOf(opt.code) !== -1
            }
            return opt
          })
        }
      }
      this.setState({
        filters: _default_filters,
        filtersSet: true
      })
      return
    }

    if (
      todosInView.isFetching === false &&
      !todosInView.error &&
      (todosInView.records === null ||
        _.get(todosInView, 'params.by', '') === 'idn')
    ) {
      return this.fetch()
    }

    if (
      todosInView.pagination &&
      todosInView.isFetching === false &&
      todosInView.pagination.currentPageNum !== this.state.page &&
      todosInView.pagination.currentPageNum !== -1
    ) {
      if (this.state.activeFilterBy === 'ids') {
        return this.fetchByIds(
          this.state.lastEE,
          this.state.filterMsg,
          this.state.page
        )()
      }
      return this.fetch()
    }
  }

  getFetchParams() {
    return {
      by: 'filters',
      page: this.state.page,
      status: this.state.filters.status.options
        .filter(s => s.checked)
        .map(s => s.code),
      category: this.state.filters.category.options
        .filter(s => s.checked)
        .map(s => s.code),
      ownership: this.state.filters.ownership.options
        .filter(s => s.checked)
        .map(s => s.code)
    }
  }

  fetch() {
    /*
    let final_states = _.flatten(TODO_STATE_REF.map(s=>s.states)).filter(s=>(s.final)).map(s=>s.nm).join(",")
    let stateVal = this.state.filters.statusFilters.filter(s=>(s.checked)).map(s=>s.nm).join(",").replace(/CLOSED/,final_states)
    */
    this.props.fetchTodos_ac(this.getFetchParams())
    this.props.fetchTodosSummary_ac({})
    this.setState({ activeFilterBy: 'filters' })
    return
  }

  fetchWithPageReset() {
    this.props.fetchTodos_ac({ ...this.getFetchParams(), page: 0 })
    this.props.fetchTodosSummary_ac({})
    this.setState({ page: 0, activeFilterBy: 'filters' })
    return
  }

  fetchBySmartFilter = _params => () => {
    if (_params.category[0].search(/^pat_outreach/) > -1) {
      _params.ownership = ['me_or_docflow']
    }
    this.props.fetchTodos_ac({ ..._params, by: 'filters', page: 0 })
    this.props.fetchTodosSummary_ac({})
    this.setState({
      page: 0,
      activeFilterBy: 'filters',
      filters: {
        category: {
          ...this.state.filters.category,
          options: this.state.filters.category.options.map(o => {
            o.checked = _params.category.indexOf(o.code) !== -1
            return o
          })
        },
        status: {
          ...this.state.filters.status,
          options: this.state.filters.status.options.map(o => {
            o.checked = _params.status.indexOf(o.code) !== -1
            return o
          })
        },
        ownership: {
          ...this.state.filters.ownership,
          options: this.state.filters.ownership.options.map(o => {
            o.checked = _params.ownership.indexOf(o.code) !== -1
            return o
          })
        }
      }
    })
    return
  }

  fetchByIds = (ee, filterMsg, page = 0) => () => {
    let ids = _.flatten(ee.map(e => e.ids))
    this.props.fetchTodos_ac({
      by: 'ids',
      ids,
      page: page
    })
    this.setState({ activeFilterBy: 'ids', filterMsg, page, lastEE: ee })
  }

  saveToGoogleSheet() {
    this.setState({ savingToGoogleSheet: true })
    return window.sch
      .post('/api/todos/sheets/create', this.getFetchParams())
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }
        window.open(result.sheet.spreadsheetUrl, result.sheet.properties.title)
        this.setState({ savingToGoogleSheet: false })
      })
      .catch(error => {
        console.error(error)
        this.setState({ savingToGoogleSheet: false })
        return this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed creating google sheet'
        })
      })
  }

  buildRoute() {
    const { lookups } = this.props
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k
    let final_states = _.flatten(TODO_STATE_REF.map(s => s.states))
      .filter(s => s.final)
      .map(s => s.nm)
      .join(',')
    let s_f = this.state.filters.statusFilters
      .filter(s => s.checked)
      .map(s => s.nm)
      .join(',')
      .replace(/CLOSED/, final_states)
    if (s_f === '') s_f = '_'
    let c_f = this.state.filters.categoryFilters
      .filter(s => s.checked)
      .map(s => s.nm)
      .join(',')
    if (c_f === '') c_f = '_'
    let o_f = this.state.filters.ownershipFilters
      .filter(s => s.checked)
      .map(s => s.nm)
      .join(',')
    if (o_f === '') o_f = '_'

    let tags = '_'
    let path = `${LANDING_BASEPATH}/${s_f}/${c_f}/${o_f}/${tags}`
    return path
  }

  onLocalUpdate(posInSet, todoDoc) {
    this.props.locallyUpdateTodoDoc_ac(posInSet, todoDoc)
  }

  filterStatement() {
    switch (this.state.activeFilterBy) {
      case 'filters':
        //this.state.filters.forEach(f => {})
        break
      case 'ids':
        return 'Selected Assignee'
      default:
        return ''
    }
  }

  handleSwitchToggle = () => {
    this.setState({
      isTableView: !this.state.isTableView
    })
  }

  render() {
    const { classes, me, todosInView, lookups } = this.props
    if (
      !lookups.ink ||
      null === todosInView.params ||
      todosInView.records === null
    ) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....</h4>
        </div>
      )
    }
    if (this.state.filtersSet === false) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....filters not set</h4>
        </div>
      )
    }

    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k
    const todoCodeHash = _.keyBy(TODO_STATE_REF, 'code')

    if (todosInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      )
    }

    /*
    let assignmentFiltersAllowed = ['Assigned to Me', 'Created by Me', 'All']
    if (me.user.roles.indexOf('vct_super')>-1){
      assignmentFiltersAllowed =
      ['Assigned to Me', 'Created by Me', 'All']
    }
    */

    const { summary, pagination: pg } = todosInView
    /*
    let activeCatFilters = this.state.categoryFilters.filter(s=>(s.checked));
    let activeCatFiltersStatement = (activeCatFilters.length === this.state.categoryFilters.length) ? 'All Categories' :
      activeCatFilters.map(s=>s.lbl).join(",")
    */

    let pageSelector = ''
    let pageStatement = ''
    if (pg) {
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Records',
        'Page ' + (this.state.page + 1) + ' of ' + pg.totalPagesAvailable,
        'From Selected Filters'
      ].join(' - ')
      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                onChange={this.onPageChange}
                displayEmpty
                value={this.state.page}
                name="age"
                className={classes.selectEmpty}>
                {_.range(0, pg.totalPagesAvailable).map(pgNum => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        )
      }
    }

    let disableGoogleSave =
      _.intersection(this.props.me.user.roles, [
        'it-admin',
        'executive',
        'compliance',
        'allowed-to-export'
      ]).length === 0
    return (
      <Grid container className={classes.fullHeight}>
        <Grid
          item
          xs={12}
          sm={this.state.simpleView ? 12 : 9}
          className={classes.fullHeight}>
          <FilterListing
            me={me}
            applyFilters={this.fetchWithPageReset}
            filters={this.state.filters}
            filterSequence={this.state.filterSequence}
            onFilterChange={this.onFilterChange}
            onFilterToggle={this.onFilterToggle}
          />
          <main className={classes.content}>
            <TodosTableOnly
              todosInView={todosInView}
              pageStatement={pageStatement}
              pageSelector={pageSelector}
              onLocalUpdate={this.onLocalUpdate}
              onToggleCards={this.onToggleCards}
            />
          </main>
        </Grid>
        {/* END TODO CARDS LIST */}

        {this.state.simpleView !== true && (
          <Grid item xs={12} sm={3} className={classes.fullHeight}>
            <main className={classes.content}>
              <div
                className={classes.pagination}
                style={{ padding: 24, paddingTop: 0 }}>
                <label className={classes.label}>
                  Filters Applied to the Listing
                </label>
                {this.state.activeFilterBy === 'filters' && (
                  <>
                    <Typography
                      className={classes.pageStatement}
                      color="textSecondary">
                      <i>Involvement</i>:{' '}
                      {this.state.filters.ownership.options
                        .filter(s => s.checked)
                        .map(s => s.lbl)
                        .join(',')}
                    </Typography>
                    <Typography
                      className={classes.pageStatement}
                      color="textSecondary">
                      <i>Status</i>:{' '}
                      {this.state.filters.status.options
                        .filter(s => s.checked)
                        .map(s => s.lbl)
                        .join(',')}
                    </Typography>
                    <Typography
                      className={classes.pageStatement}
                      color="textSecondary">
                      <i>Category</i>:{' '}
                      {this.state.filters.category.options.filter(
                        s => s.checked
                      ).length === this.state.filters.category.options.length
                        ? 'ALL Categories'
                        : this.state.filters.category.options
                            .filter(s => s.checked)
                            .map(s => s.lbl)
                            .join(',')}
                    </Typography>
                  </>
                )}
                {this.state.activeFilterBy === 'ids' && (
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    - <i>By</i>: {this.state.filterMsg}
                  </Typography>
                )}

                {this.state.activeFilterBy === 'filters' && (
                  <Button
                    onClick={this.saveToGoogleSheet}
                    disabled={
                      disableGoogleSave || this.state.savingToGoogleSheet
                    }
                    color="primary"
                    variant="contained">
                    Save to Google Sheet
                  </Button>
                )}
                {this.state.savingToGoogleSheet && (
                  <CircularProgress
                    value={50}
                    color="primary"
                    variant="indeterminate"
                    thickness={4}
                  />
                )}
              </div>

              <div className={classes.scrollArea} style={{ minHeight: 100 }}>
                <div className={classes.root} style={{ padding: 4 }}>
                  {summary && (
                    <ExpansionPanel defaultExpanded={true}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <label className={classes.label}>
                          Open (New and Pending) Task Summary:
                        </label>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{ textAlign: 'right' }}>
                        <Grid container spacing={8}>
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Assignee (Click to Filter)
                            </label>
                            {summary.open.by_owner.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Open Tasks assigned to: ' + r.k
                                  )}
                                  className={classes.summaryEntry}>
                                  {r.k} ({r.n})
                                </span>
                                <div className={classes.summaryCountWrap}>
                                  {/*
                                r.ee.map(e=>(
                                  <div className={classes.summaryCount}>{e.state} ({e.n})</div>
                                ))
                                */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Status (Click to Filter)
                            </label>
                            {summary.open.by_state.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Open Tasks in status:' + r.k
                                  )}
                                  className={classes.summaryEntry}>
                                  {r.k} ({r.n})
                                </span>
                                <div className={classes.summaryCountWrap}>
                                  {/*
                                r.ee.map(e=>(
                                  <div className={classes.summaryCount}>{e.owner} ({e.n})</div>
                                ))
                                */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                          {/* <div className={classes.spacer} /> */}
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Category (Click to Filter)
                            </label>
                            {summary.open.by_code.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchBySmartFilter({
                                    category: [r.k],
                                    status: ['new', 'pending'],
                                    ownership: ['all']
                                  })}
                                  className={classes.summaryEntry}>
                                  {todoCodeHash[r.k].lbl} ({r.n})
                                </span>
                                <div className={classes.summaryCountWrap}>
                                  {/*
                                r.ee.map(e=>(
                                  <div className={classes.summaryCount}>{e.owner} ({e.n})</div>
                                ))
                                */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}

                  {summary && (
                    <ExpansionPanel defaultExpanded={false}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <label className={classes.summaryLabel}>
                          Snoozed Task Summary:
                        </label>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{ textAlign: 'right' }}>
                        <Grid container spacing={8}>
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Assignee (Click to Filter)
                            </label>
                            {summary.snoozed.by_owner.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Snoozed Tasks assigned to ' + r.k
                                  )}
                                  className={classes.summaryEntry}>
                                  {r.k} ({r.n})
                                </span>
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Status (Click to Filter)
                            </label>
                            {summary.snoozed.by_state.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Snoozed Tasks in status:' + r.k
                                  )}
                                  className={classes.summaryEntry}>
                                  {r.k} ({r.n})
                                </span>
                                <div className={classes.summaryCountWrap}>
                                  {/*
                                r.ee.map(e=>(
                                  <div className={classes.summaryCount}>{e.owner} ({e.n})</div>
                                ))
                                */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                          {/* <div className={classes.spacer} /> */}
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Category (Click to Filter)
                            </label>
                            {summary.snoozed.by_code.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Snoozed Tasks in category: ' +
                                      todoCodeHash[r.k].lbl
                                  )}
                                  className={classes.summaryEntry}>
                                  {todoCodeHash[r.k].lbl} ({r.n})
                                </span>
                              </div>
                            ))}
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}

                  {summary && (
                    <ExpansionPanel defaultExpanded={false}>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <label className={classes.summaryLabel}>
                          Closed Task Summary:
                        </label>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{ textAlign: 'right' }}>
                        <Grid container spacing={8}>
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Assignee (Click to Filter)
                            </label>
                            {summary.closed.by_owner.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Closed Tasks assigned to ' + r.k
                                  )}
                                  className={classes.summaryEntry}>
                                  {r.k} ({r.n})
                                </span>
                                <div className={classes.summaryCountWrap}>
                                  {/*
                                  r.ee.map(e=>(
                                    <div className={classes.summaryCount}>{e.state} ({e.n})</div>
                                  ))
                                  */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Status (Click to Filter)
                            </label>
                            {summary.closed.by_state.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Closed Tasks in status:' + r.k
                                  )}
                                  className={classes.summaryEntry}>
                                  {r.k} ({r.n})
                                </span>
                                <div className={classes.summaryCountWrap}>
                                  {/*
                                  r.ee.map(e=>(
                                    <div className={classes.summaryCount}>{e.owner} ({e.n})</div>
                                  ))
                                  */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                          {/* <div className={classes.spacer} /> */}
                          <Grid item xs={12}>
                            <label className={classes.label}>
                              By Category (Click to Filter)
                            </label>
                            {summary.closed.by_code.map((r, kIdx) => (
                              <div
                                key={kIdx}
                                className={classes.summaryEntryWrap}>
                                <span
                                  onClick={this.fetchByIds(
                                    r.ee,
                                    'Closed Tasks in category: ' +
                                      todoCodeHash[r.k].lbl
                                  )}
                                  className={classes.summaryEntry}>
                                  {todoCodeHash[r.k].lbl} ({r.n})
                                </span>

                                <div className={classes.summaryCountWrap}>
                                  {/*
                                  r.ee.map(e=>(
                                    <div className={classes.summaryCount}>{e.owner} ({e.n})</div>
                                  ))
                                  */}
                                </div>
                              </div>
                            ))}
                          </Grid>
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  )}
                </div>
              </div>
            </main>
          </Grid>
        )}
      </Grid>
    )
  }
}

class _TodosLandingSimple extends TodosLanding {
  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      simpleView: true
    }
  }
}

TodosLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  todosInView: stateFromStore.todosInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchTodos_ac,
      fetchTodosSummary_ac,
      locallyUpdateTodoDoc_ac,
      toggleAllCards_ac,
      open_snack_ac,
      lookups_ac,
      changePage: path => push(path)
    },
    dispatch
  )

const TodosLandingSimple = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(_TodosLandingSimple))
)

const TodosLandingTable = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TodosLanding))
)

export { TodosLandingTable, TodosLandingSimple, TodosTableOnly }
