import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import Loader from '../../Shared/Loader';

const LoaderRow = ({ col = 7 }) => {
  return (
    <TableRow>
      <TableCell colSpan={col} align="center">
        <Loader message="Loading..." />
      </TableCell>
    </TableRow>
  );
};

export default LoaderRow;
