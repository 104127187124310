import { salesdashActionTypes } from '../actions/salesdash.ac'

export function salesdashSalesPersons(
  state = {
    salesPersons: null,
    isFetchingSalesPersons: false,
    salesPersonsError: null
  },
  action
) {
  switch (action.type) {
    case salesdashActionTypes.SET_IS_FETCHING_SALES_PERSONS:
      return {
        ...state,
        isFetchingSalesPersons: action.isFetching
      };
    case salesdashActionTypes.RECEIVED_SALES_PERSONS:
      return {
        ...state,
        salesPersons: action.record,
        salesPersonsError: null
      };
    case salesdashActionTypes.FAILED_SALES_PERSONS:
      return {
        ...state,
        salesPersons: null,
        salesPersonsError: action.error
      };
    default:
      return state;
  }
}

export function salesdashRecords(
  state = {
    myProgress: null,
    myBuckets: null,
    myHistory: null,
    myBrownList: null,
    isFetchingRecords: false,
    recordError: null
  },
  action
) {
  switch (action.type) {
    case salesdashActionTypes.SET_IS_FETCHING_RECORDS:
      return {
        ...state,
        isFetchingRecords: action.isFetching
      };
    case salesdashActionTypes.RECEIVED_SALES_MY_PROGRESS:
      return {
        ...state,
        myProgress: action.record,
        recordError: null
      };
    case salesdashActionTypes.FAILED_SALES_MY_PROGRESS:
      return {
        ...state,
        myProgress: null,
        recordError: action.error
      };
    case salesdashActionTypes.RECEIVED_SALES_MY_BUCKETS:
      return {
        ...state,
        myBuckets: action.record,
        recordError: null
      };
    case salesdashActionTypes.FAILED_SALES_MY_BUCKETS:
      return {
        ...state,
        myBuckets: null,
        recordError: action.error
      };
    case salesdashActionTypes.RECEIVED_SALES_MY_HISTORY:
      return {
        ...state,
        myHistory: action.record,
        recordError: null
      };
    case salesdashActionTypes.FAILED_SALES_MY_HISTORY:
      return {
        ...state,
        myHistory: null,
        recordError: action.error
      };
    case salesdashActionTypes.RECEIVED_SALES_MY_BROWN_LIST:
      return {
        ...state,
        myBrownList: action.record,
        recordError: null
      };
    case salesdashActionTypes.FAILED_SALES_MY_BROWN_LIST:
      return {
        ...state,
        myBrownList: null,
        recordError: action.error
      }
    default:
      return state;
  }
}

export function salesdashRefreshTS(
  state = {
    isFetchingRefreshTS: false,
    refreshTS: null
  },
  action
) {
  switch (action.type) {
    case salesdashActionTypes.SET_IS_FETCHING_SALES_REFRESH_TS:
      return {
        ...state,
        isFetchingRefreshTS: action.isFetching
      };
    case salesdashActionTypes.RECEIVED_SALES_REFRESH_TS:
      return {
        ...state,
        refreshTS: action.record
      };
    default:
      return state;
  }
}

export function salesdashWOSRecords(
  state = {
    wosRecords: null,
    isFetchingWOSRecords: false,
    wosRecordError: null
  },
  action
) {
  switch (action.type) {
    case salesdashActionTypes.SET_IS_FETCHING_WOS_RECORDS:
      return {
        ...state,
        isFetchingWOSRecords: action.isFetching
      };
    case salesdashActionTypes.RECEIVED_SALES_WOS_RECORDS:
      return {
        ...state,
        wosRecords: action.record,
        wosRecordError: null
      };
    case salesdashActionTypes.FAILED_SALES_WOS_RECORDS:
      return {
        ...state,
        wosRecords: null,
        wosRecordError: action.error
      };
    case salesdashActionTypes.UPDATE_SALES_WOS_COLUMNS:
      return {
        ...state,
        wosRecords: {
          ...state.wosRecords,
          cols: [...action.columns]
        }
      }
    default:
      return state;
  }
}

export function salesdashWOSFilters(
  state = {
    wosFilters: null,
    isFetchingWOSFilters: false,
    wosFilterError: null
  },
  action
) {
  switch (action.type) {
    case salesdashActionTypes.SET_IS_FETCHING_SALES_WOS_FILTERS:
      return {
        ...state,
        isFetchingWOSFilters: action.isFetching
      };
    case salesdashActionTypes.RECEIVED_SALES_WOS_FILTERS:
      return {
        ...state,
        wosFilters: action.record,
        wosFilterError: null
      };
    case salesdashActionTypes.FAILED_SALES_WOS_FILTERS:
      return {
        ...state,
        wosFilters: null,
        wosFilterError: true
      };
    default:
      return state;
  }
}
