export function axiom_select_contract_ac(contract) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({
      type: 'CONTRACT_SELECTED',
      selectedContract: contract,
    });
  };
}

const MAX_SUGGESTIONS = 25;

export function axiom_update_suggestions_ac(suggestions) {
  return (dispatch, getState) => {
    let existing_ss_ids = getState().axiom.suggestions.map((s) => s._id);
    suggestions = suggestions.filter((s) => !existing_ss_ids.includes(s._id));

    const n_ss_to_fill = Math.min(suggestions.length, MAX_SUGGESTIONS);
    const n_last_ss_to_keep = MAX_SUGGESTIONS - n_ss_to_fill;
    //params passed in by thunk middleware
    dispatch({
      type: 'RECEIVED_SUGGESTIONS',
      suggestions: [
        ...suggestions.slice(0, n_ss_to_fill),
        ...getState().axiom.suggestions.slice(0, n_last_ss_to_keep),
      ],
    });
  };
}
