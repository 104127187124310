import { combineReducers } from 'redux';

import {
  initialState as formLookupsInitialState,
  formLookups,
} from './form_lookups.red';

import {
  initialState as formTemplateInitialState,
  formTemplates,
} from './form_templates.red';

import {
  initialState as formBackgroundInitialState,
  formBackgrounds,
} from './form_backgrounds.red';

import {
  initialState as formSubmissionInitialState,
  formSubmissions,
} from './form_submissions.red';

const initialState = {
  lookups: formLookupsInitialState,
  templates: formTemplateInitialState,
  backgrounds: formBackgroundInitialState,
  submissions: formSubmissionInitialState,
};

const formReducer = combineReducers({
  lookups: formLookups,
  templates: formTemplates,
  backgrounds: formBackgrounds,
  submissions: formSubmissions,
});

export { initialState, formReducer as default };
