/* eslint no-unused-vars: "off" */
import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { push } from 'connected-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  title: {
    flexGrow: 1
  },
  formControl: {
    paddingBottom: 32
  },
  tableRow: {
    fontSize: 11
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowX: 'auto',
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  loadBtn: {
    marginTop: 15
  }
})

class GSOSerialReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      data: null,
      error: ''
    }
    this.fetch = this.fetch.bind(this)
    this.exportCSV = this.exportCSV.bind(this)
    this.renderData = this.renderData.bind(this)
  }

  componentDidMount() {
    this.fetch()
  }

  fetch() {
    let day = document.querySelector('#date').value
    let today = moment().format('YYYY-MM-DD')

    if (day > today) {
      this.setState({
        error: 'Cannot load from the future'
      })
      return
    }

    day = day.split('-')
    day = `${day[1]}-${day[2]}-${day[0]}`

    this.setState(
      {
        isFetching: true,
        data: null,
        error: ''
      },
      () => {
        window.sch
          .post('/api/reports/gso/serial', { day })
          .then(result => {
            result.sort((a, b) => {
              return a._id < b._id ? -1 : 1
            })
            this.setState({
              isFetching: false,
              data: result
            })
          })
          .catch(err => {
            console.error(err)
            this.setState({
              isFetching: false,
              error: err.error ? err.error : err.message
            })
          })
      }
    )
  }

  exportCSV() {
    if (!this.state.data || this.state.data.length === 0) {
      this.setState({
        error: 'No data has been loaded to export'
      })
      return
    }

    let fileName = `gso_${document.querySelector('#date').value}.csv`
    let csv = 'WorkOrderID,Account,Name,TrackingNumbers\r\n'
    for (let d of this.state.data)
      csv += `${d._id},${d.acct},"${d.patient_nm}",${d.trackingNumbers.join(
        ';'
      )}\r\n`

    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    })

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName)
    } else {
      let link = document.querySelector('#csv')
      if (link.download === undefined) {
        alert('This browser does not support CSV export')
      } else {
        let url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.click()
      }
    }
  }

  renderData() {
    const { classes } = this.props

    return (
      <>
        {this.state.data.length} records loaded from{' '}
        {document.querySelector('#date').value}
        <Paper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>WorkOrderID</TableCell>
                <TableCell>Account</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Tracking #</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>{item._id}</TableCell>
                  <TableCell>{item.acct}</TableCell>
                  <TableCell>{item.patient_nm}</TableCell>
                  <TableCell>{item.trackingNumbers.join(', ')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </>
    )
  }

  render() {
    const { classes, me } = this.props

    return (
      <main className={classes.content}>
        <div>
          <label className={classes.label}>GSO Serial Reports</label>
          <div>
            <TextField
              id="date"
              type="date"
              label="Date"
              defaultValue={moment().format('YYYY-MM-DD')}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
          <div className={classes.loadBtn}>
            <Button
              variant="outlined"
              onClick={this.fetch}
              disabled={this.state.isFetching}>
              Load
            </Button>
            <Button
              variant="outlined"
              onClick={this.exportCSV}
              disabled={this.state.isFetching}>
              Export current view to CSV
            </Button>
          </div>
        </div>
        <div className={classes.scrollArea}>
          {this.state.error ? (
            <p>{this.state.error}</p>
          ) : this.state.data ? (
            this.renderData()
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <a id="csv" href="#!">
          &nbsp;
        </a>
      </main>
    )
  }
}

GSOSerialReport.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(GSOSerialReport))
)
