import {
  Grid,
  SvgIcon,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import {Link } from 'react-router-dom'
import { ROUTE_GROUPS } from './groupedRoutes'

const styles = theme => ({
  scrollArea: {
    height: '100%',
    overflowY: 'auto',
    padding: 4
  },
  group: {
    backgroundColor: '#fafafa',
    width: 250,
  },
  groupHeader: {
    padding: 8,
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: 'gray',
    color: 'white'
  },
  link: {
    textDecoration: 'none',
    color: '#2e61ef',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  groupContainer: {
    padding: 8,
    height: 300,
    overflowY: 'auto',
    border: '2px solid gray'
  },
  list: {
    paddingLeft: 16,
    margin: '4px 0px',
    '&>li': {
      padding: '4px 0px'
    }
  }
})

const PageList = props => {
  const { pages, classes } = props

  return (
    <ul className={classes.list}>
      {(pages || []).map(page => (
        <li key={page.label}>
          {page.subPages
            ? (
              <Typography variant="body1" color="textPrimary">
                <strong>{page.label}</strong>
              </Typography>
            )
            : (
              <Link to={page.path} className={classes.link}>
                <Typography
                  inline
                  variant="body1"
                  color="inherit"
                  component="span"
                >
                  <strong>{page.label}</strong>
                </Typography>
              </Link>
            )}
          {page.subPages && (
            <PageList
              key={page.label}
              pages={page.subPages}
              classes={classes}
            />
          )}
        </li>
      ))}
    </ul>
  )
}

class CardViewSiteMap extends React.Component {

  render() {
    const { classes, icons } = this.props

    return (
      <div className={classes.scrollArea}>
        <Grid container spacing={8}>
          {ROUTE_GROUPS.map(group => (
            <Grid item key={group.groupLabel}>
              <div className={classes.group}>
                <Typography
                  variant="subtitle1"
                  className={classes.groupHeader}
                >
                  {group.groupIcon && (
                    <>
                      <SvgIcon component={icons[group.groupIcon]}>
                        {''}
                      </SvgIcon>
                      <span>&ensp;</span>
                    </>
                  )}
                  <span>
                    <strong>{group.groupLabel}</strong>
                  </span>
                </Typography>
                <div className={classes.groupContainer}>
                  <PageList pages={group.pages} classes={classes} />
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(CardViewSiteMap);
