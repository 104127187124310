import { Divider, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { resetSearchResult_ac } from '../../actions/fb_search.ac'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import FBAccountingSearch from './FBAccountingSearch'
import FBAccountingSearchResult from './FBAccountingSearchResult'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  fullHeight: {
    height: '100%'
  }
})

class FBAccountingLandingComponent extends Component {
  constructor() {
    super()
    this.state = {
      currentPage: 0
    }
  }

  componentDidMount() {
    const { setTitle_ac, resetSearchResult_ac } = this.props
    resetSearchResult_ac()
    setTitle_ac('FB Patient Search')
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation() {
    const { lookups, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return

      // return lookups_ac()
    }
  }

  onPageChange = page => {
    const targetPage = parseInt(page, 10)
    this.setState({
      currentPage: targetPage - 1
    })
  }

  render() {
    const { classes, fbSearchInView } = this.props

    return (
      <Grid container spacing={8} item className={classes.fullHeight}>
        <Grid item xs={12} sm={12} md={12} className={classes.fullHeight}>
          <FBAccountingSearch
            fbSearchInView={fbSearchInView}
            page={this.state.currentPage}
          />
          <Divider component="hr" />
          <FBAccountingSearchResult
            onPageChange={this.onPageChange}
            fbSearchInView={fbSearchInView}
          />
        </Grid>
      </Grid>
    )
  }
}

FBAccountingLandingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  fbSearchInView: stateFromStore.fbSearchInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
      resetSearchResult_ac
    },
    dispatch
  )

export const FBAccountingLanding = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBAccountingLandingComponent))
)
