import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import Modal from '@material-ui/core/Modal'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  cancel_masterSearch_ac,
  masterSearch_ac
} from '../../actions/masterSearch.ac.js'
import { refresh_user_profile_ac } from '../../actions/me.ac.js'
import TeamToggle from './TeamToggle.js'
import UserSearch from './UserSearch.js'
import SelectTable from './widgets/SelectTable.js'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})

const styles = theme => ({
  root: {
    marginTop: 12,
    flexGrow: 1,
    width: '100%',
    height: 'calc(100% - 12px)',
    padding: theme.spacing.unit * 0.5,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  padMe: {
    paddingTop: '8vh'
  },
  topContent: {
    flexShrink: 1,
    height: '100%'
  },
  bubble: {
    marginLeft: '4vh',
    marginRight: '4vh',
    marginTop: '2vh',
    marginBottom: '2vh'
  },
  tinybubble: {
    marginLeft: '4vh'
  }
})

class MyTeams extends React.Component {
  constructor() {
    super()
    this.state = {
      addModal: false,
      mounting: true,
      creatingNew: false
    }
    this.updateLists = this.updateLists.bind(this)
    this.banish = this.banish.bind(this)
    this.welcome = this.welcome.bind(this)
    this.clickedAdd = this.clickedAdd.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.clearUrl = this.clearUrl.bind(this)
  }

  async updateLists() {
    if (
      this.props.match.params.team === undefined ||
      this.props.match.params.cat === undefined
    ) {
      return
    }
    try {
      let x = await window.sch.post('/api/users/groups', {
        nm: this.props.match.params.team,
        cat: this.props.match.params.cat
      })
      let submit = JSON.parse(JSON.stringify(x.data))
      this.setState({
        lists: submit
      })
      if (
        x.data.length === 0 &&
        ['adp_supervisor', 'custom'].indexOf(this.props.match.params.cat) !== -1
      ) {
        this.setState({
          lists: [
            {
              _id: this.props.match.params.team,
              users: [{ UserName: '', Name: '', Email: '', Disabled: '' }]
            }
          ]
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  async banish(users, team) {
    try {
      await window.sch.post('/api/users/groups:banish', {
        users: users,
        team: team,
        cat: this.props.match.params.cat
      })
      await this.updateLists()
    } catch (e) {
      console.error(e)
    }
  }

  async welcome(user) {
    try {
      this.handleClose()
      await window.sch.post('/api/users/groups:welcome', {
        user: user.u_id,
        team: this.props.match.params.team,
        cat: this.props.match.params.cat
      })
      await this.updateLists()
    } catch (e) {
      console.error(e)
    }
  }

  clickedAdd() {
    this.setState({
      addModal: true
    })
  }

  handleClose() {
    this.setState({
      addModal: false
    })
  }

  clearUrl() {
    this.props.redirectToDfa('/myteams/' + this.props.match.params.cat)
  }

  async componentDidMount() {
    if (
      this.props.match.params.cat === 'org' ||
      this.props.match.params.cat === undefined
    ) {
      return this.props.redirectToDfa('/myteams/teamq')
    }
    try {
      if (this.state.lists === undefined) {
        await this.updateLists()
        this.setState({ mounting: false })
      }
    } catch (e) {
      console.error(e)
    }
  }

  async componentDidUpdate() {
    if (
      this.props.me.user === null ||
      (this.props.me.user.supervisor_of === undefined &&
        this.props.me.user.manager_of === undefined &&
        this.props.me.user.roles.indexOf('it-admin') === -1)
    ) {
      return
    }
    if (
      this.props.match.params.team &&
      (this.state.team === undefined ||
        this.state.team.match(new RegExp(this.props.match.params.team, 'i')) ===
          null)
    ) {
      this.setState({
        team: this.props.match.params.team.replace('$', '')
      })
    }
    if (this.state.lists === undefined && !this.state.mounting) {
      await this.updateLists()
      return
    }
  }

  render() {
    let { classes, me } = this.props

    if (
      _.intersection(me.user.roles, [
        'cs_supervisor',
        'cs_manager',
        'vct_super',
        'cc_admin',
        'cs_director',
        'indexing_supervisor',
        'it-admin',
        'rom'
      ]).length === 0
    ) {
      return <div>There is nothign to see here, move along. </div>
    }

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            {this.props.match.params.team !== undefined ? (
              <Button
                variant="contained"
                onClick={this.clearUrl}
                className={classes.tinybubble}>
                clear filters
              </Button>
            ) : this.props.match.params.cat ? (
              <TeamToggle cat={this.props.match.params.cat} />
            ) : (
              ''
            )}
            <div>
              {this.state.lists === undefined ? (
                <div />
              ) : (
                <div>
                  {this.state.lists.map(l => {
                    return (
                      <SelectTable
                        key={l._id}
                        data={l.users}
                        title={l._id}
                        deletefun={this.banish}
                        addfun={this.clickedAdd}
                      />
                    )
                  })}
                </div>
              )}
            </div>
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.addModal}
              onClose={this.handleClose}>
              <div>
                <UserSearch clickAction={this.welcome} />
              </div>
            </Modal>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch,
  dfaInView: stateFromStore.dfaInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      refresh_user_profile_ac,
      masterSearch_ac,
      cancel_masterSearch_ac,
      redirectToDfa: path => {
        return push(path)
      }
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyTeams))
