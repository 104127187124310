import {
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useReducer } from 'react'

import FilterTextField from './FilterTextField'
import filterFormReducer from './filterFormReducer'

const styles = theme => ({
  gridGutter: {
    padding: 8
  },
  marginDense: {
    marginTop: 8,
    marginBottom: 4
  }
})

/**
 * @example
 * <FiltersForm
 *   filters={{
 *     field1: {
 *       kind: 'text',
 *       lbl: 'Field One',
 *       placeholder: 'Enter Field One Value',
 *       helperText: 'e.g. this is field one',
 *       validator: (value = '') => {
 *         let isValid = ...
 *
 *         return {
 *           isValid,
 *           errorText: '...'
 *         }
 *       }
 *     },
 *     ...
 *   }}
 *   filterSequence={['field1', ...]}
 *   initialValues={{
 *     field1: 'default field 1',
 *     ...
 *   }}
 *   isVertical // to align form fields vertically (horizontal by default)
 *   isDisabled // flag to disable all fields and buttons of the form e.g. while fetching
 * />
 */
const FiltersForm = props => {
  const [state, dispatch] = useReducer(
    filterFormReducer,
    {
      formData: _.cloneDeep(props.initialValues || {}),
      errors: (props.filterSequence || []).reduce(
        (acc, curr) => Object.assign(acc, { [curr]: false }),
        {}
      ),
      helperTexts: (props.filterSequence || []).reduce(
        (acc, curr) => Object.assign(
          acc,
          { [curr]: props.filters[curr].helperText || '' }
        ),
        {}
      )
    }
  )

  const handleFilterChange = e => {
    const { name, value } = e.target

    dispatch({
      type: 'CHANGE_FIELD_VALUE',
      fieldName: name,
      newValue: value
    })
  }

  const renderMultiSelectValues = (fieldName, selected) => {
    return (props.filters[fieldName].options || [])
      .reduce(
        (acc, curr) => {
          if (curr && curr.code && selected.indexOf(curr.code) > -1)
            return acc.concat(curr.lbl)

          return acc
        },
        []
      )
      .join(', ')
  }

  const validateForm = () => {
    let isFormValid = true
    let newErrors = {}
    let newHelperTexts = {}

    props.filterSequence.forEach(filterKey => {
      if (props.filters[filterKey].isRequired) {
        if (props.filters[filterKey].kind === 'multi-select') {
          if (!state.formData[filterKey] || !state.formData[filterKey].length) {
            isFormValid = false
            newErrors[filterKey] = true
            newHelperTexts[filterKey] = 'Required Field!'

            return
          }

          newErrors[filterKey] = false
          newHelperTexts[filterKey] = props.filters[filterKey].helperText || ''

          return
        }

        if (state.formData[filterKey] === '') {
          isFormValid = false
          newErrors[filterKey] = true
          newHelperTexts[filterKey] = 'Required Field!'

          return
        }

        newErrors[filterKey] = false
        newHelperTexts[filterKey] = props.filters[filterKey].helperText || ''
      }

      if (typeof props.filters[filterKey].validator === 'function') {
        let { isValid, errorText } = props.filters[filterKey].validator(state.formData[filterKey]);

        if (!isValid) {
          isFormValid = false
          newErrors[filterKey] = true
          newHelperTexts[filterKey] = errorText

          return
        }

        newErrors[filterKey] = false
        newHelperTexts[filterKey] = props.filters[filterKey].helperText || ''
      }
    })

    dispatch({
      type: 'UPDATE_ERRORS',
      errors: newErrors
    })
    dispatch({
      type: 'UPDATE_HELPERTEXTS',
      helperTexts: newHelperTexts
    })

    return isFormValid
  }

  const handleSubmit = e => {
    e.preventDefault()

    let isFormValid = validateForm()

    if (!isFormValid) return // do not submit

    props.applyFilters(state.formData)
  }

  return (
    <div className={props.classes.gridGutter}>
      <Grid
        component="form"
        container
        spacing={8}
        direction={props.isVertical ? 'column' : 'row'}
        onSubmit={handleSubmit}
      >
        {props.filterSequence.map((filterKey, idx) => (
          <Grid item key={filterKey + idx}>
            {props.filters[filterKey].kind === 'text' && (
              <FilterTextField
                name={filterKey}
                label={props.filters[filterKey].lbl}
                placeholder={props.filters[filterKey].placeholder || ''}
                helperText={state.helperTexts[filterKey]}
                onChange={handleFilterChange}
                value={state.formData[filterKey]}
                error={state.errors[filterKey]}
                required={!!props.filters[filterKey].isRequired}
                disabled={!!props.isDisabled}
              />
            )}

            {props.filters[filterKey].kind === 'date' && (
              <FilterTextField
                name={filterKey}
                label={props.filters[filterKey].lbl}
                placeholder={props.filters[filterKey].placeholder || ''}
                helperText={state.helperTexts[filterKey]}
                onChange={handleFilterChange}
                value={state.formData[filterKey]}
                error={state.errors[filterKey]}
                required={!!props.filters[filterKey].isRequired}
                disabled={!!props.isDisabled}
              />
            )}

            {props.filters[filterKey].kind === 'dateTime' && (
              <FilterTextField
                name={filterKey}
                label={props.filters[filterKey].lbl}
                placeholder={props.filters[filterKey].placeholder || ''}
                helperText={state.helperTexts[filterKey]}
                onChange={handleFilterChange}
                value={state.formData[filterKey]}
                error={state.errors[filterKey]}
                required={!!props.filters[filterKey].isRequired}
                disabled={!!props.isDisabled}
              />
            )}

            {props.filters[filterKey].kind === 'select' && (
              <FilterTextField
                select
                name={filterKey}
                label={props.filters[filterKey].lbl}
                placeholder={props.filters[filterKey].placeholder || ''}
                helperText={state.helperTexts[filterKey]}
                onChange={handleFilterChange}
                value={state.formData[filterKey] || 'none'}
                error={state.errors[filterKey]}
                required={!!props.filters[filterKey].isRequired}
                disabled={!!props.isDisabled}
              >
                {!props.filters[filterKey].isRequired && (
                  <MenuItem value="none">
                    <Typography variant="inherit" color="textSecondary">
                      Select {props.filters[filterKey].lbl}
                    </Typography>
                  </MenuItem>
                )}
                {props.filters[filterKey].options.map(option => (
                  <MenuItem key={filterKey + idx + option.code} value={option.code}>
                    {option.lbl}
                  </MenuItem>
                ))}
              </FilterTextField>
            )}

            {props.filters[filterKey].kind === 'multi-select' && (
              <FilterTextField
                select
                SelectProps={{
                  multiple: true,
                  renderValue: s => renderMultiSelectValues(filterKey, s),
                  value: state.formData[filterKey]
                }}
                name={filterKey}
                label={props.filters[filterKey].lbl}
                placeholder={props.filters[filterKey].placeholder || ''}
                helperText={state.helperTexts[filterKey]}
                onChange={handleFilterChange}
                error={state.errors[filterKey]}
                required={!!props.filters[filterKey].isRequired}
                disabled={!!props.isDisabled}
              >
                {props.filters[filterKey].options.map(option => (
                  <MenuItem key={filterKey + idx + option.code} value={option.code}>
                    <Checkbox checked={state.formData[filterKey].indexOf(option.code) > -1} />
                    <ListItemText primary={option.lbl} />
                  </MenuItem>
                ))}
              </FilterTextField>
            )}
          </Grid>
        ))}
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={props.classes.marginDense}
            disabled={!!props.isDisabled}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

FiltersForm.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  filters: PropTypes.objectOf(
    PropTypes.shape({
      kind: PropTypes.oneOf(['text', 'date', 'dateTime', 'select', 'multi-select']).isRequired,
      lbl: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      validator: PropTypes.func,
      options: PropTypes.arrayOf(PropTypes.shape({
        lbl: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired
      })),
      isRequired: PropTypes.bool
    })
  ).isRequired,
  filterSequence: PropTypes.array.isRequired,
  initialValues: PropTypes.object.isRequired,
  isVertical: PropTypes.bool,
  isDisabled: PropTypes.bool
}
FiltersForm.defaultProps = {
  isVertical: false,
  isDisabled: false
}

export default withStyles(styles)(FiltersForm)
