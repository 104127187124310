import { AcitivityLogsActionTypes } from './../actions/activity_log.ac';
const initialState = {
    isFetching: false,
    params: null,
    records: null,
    pagination: null,
    tags: null,
    error: null,
}
export function activityLogInView(
    state = initialState,
    action
) {
    switch (action.type) {
        case AcitivityLogsActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
                tags: action.isFetching ? null : state.tags
            };
        case AcitivityLogsActionTypes.RECEIVED_ACTIVITY_LOG:
            return {
                ...state,
                pagination: action.pagination,
                records: action.records,
                tags: action.tags
            };

        case AcitivityLogsActionTypes.FAILED_RECEIVED_ACTIVITY_LOG:
            return {
                ...state,
                pagination: null,
                records: null,
                tags: null,
                error: action.error,
            };

        default:
            return {
                ...state
            };
    }
};
