import { open_snack_ac } from './snack.ac.js'
export const IdnShellActionTypes = {
  IDN_SHELL_RESET: 'IDN_SHELL_CREATED',
  IDN_SHELL_CREATED: 'IDN_SHELL_CREATED',
  FAILED_CREATING_IDN_SHELL: 'FAILED_CREATING_IDN_SHELL',
  SET_IS_SUBMITTING: 'SET_IS_SUBMITTING'
}

export const createIdnShell_ac = idn => dispatch => {
  dispatch(submitting(true))

  return window.sch
    .post(`/api/idn/create_shell`, { idn })
    .then(results => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'IDN Shell Created for DocFLow'
        })
      )

      dispatch({
        type: IdnShellActionTypes.IDN_SHELL_CREATED,
        idn: idn,
        idn_shell: results.idn_shell
      })

      return
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Creating IDN Shell'
        })
      )
      dispatch({
        type: IdnShellActionTypes.FAILED_CREATING_IDN_SHELL,
        error
      })
    })
    .finally(() => {
      dispatch(submitting(false))
      dispatch({ type: 'CANCEL_MASTER_SEARCH' })
      dispatch({
        type: IdnShellActionTypes.IDN_SHELL_RESET
      })
    })
}

export const submitting = isSubmitting => ({
  type: IdnShellActionTypes.SET_IS_SUBMITTING,
  isSubmitting
})
