export const actionTypes = {
  SET_IS_FETCHING_ALL_PATIENT_LETTERS_REPORT: 'SET_IS_FETCHING_ALL_PATIENT_LETTERS_REPORT',
  RECEIVED_ALL_PATIENT_LETTERS_REPORT: 'RECEIVED_ALL_PATIENT_LETTERS_REPORT',
  FAILED_ALL_PATIENT_LETTERS_REPORT: 'FAILED_ALL_PATIENT_LETTERS_REPORT',
  SET_IS_FETCHING_PATIENT_LETTERS: 'SET_IS_FETCHING_PATIENT_LETTERS',
  RECEIVED_PATIENT_LETTERS: 'RECEIVED_PATIENT_LETTERS',
  FAILED_PATIENT_LETTERS: 'FAILED_PATIENT_LETTERS'
}

/**
 * Fetch all patient letters list
 * @param {object} params Filter parameters
 */
export function fetchAllPatientLettersReport_ac(params) {
  return dispatch => {
    dispatch(setIsFetchingForAll(true))

    window.sch
      .post(`/api/patient_letters/gen/list`, params)
      .then(result => {
        dispatch({
          type: actionTypes.RECEIVED_ALL_PATIENT_LETTERS_REPORT,
          report: {
            records: result.pkg.rr,
            pagination: result.pkg.pagination,
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_ALL_PATIENT_LETTERS_REPORT,
          error: true
        })
      })
      .finally(() => {
        dispatch(setIsFetchingForAll(false))
      })
  }
}

/**
 * Fetch specified account patient letters list
 * @param {object} params Filter parameters
 * @param {string} idnStr Current Idn string
 */
export function fetchPatientLetters_ac(params, idnStr) {
  return dispatch => {
    dispatch(setIsFetchingForSpecific(true, idnStr))

    window.sch
      .post(`/api/patient_letters/account/list`, params)
      .then(result => {
        dispatch({
          type: actionTypes.RECEIVED_PATIENT_LETTERS,
          report: {
            records: result.pkg.rr,
            pagination: result.pkg.pagination,
          },
          idnStr
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_PATIENT_LETTERS,
          error: true,
          idnStr
        })
      })
      .finally(() => {
        dispatch(setIsFetchingForSpecific(false, idnStr))
      })
  }
}

function setIsFetchingForAll(isFetching) {
  return {
    type: actionTypes.SET_IS_FETCHING_ALL_PATIENT_LETTERS_REPORT,
    isFetching
  }
}

function setIsFetchingForSpecific(isFetching, idnStr) {
  return {
    type: actionTypes.SET_IS_FETCHING_PATIENT_LETTERS,
    isFetching,
    idnStr
  }
}
