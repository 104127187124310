import { MednecIssueLogActionTypes } from '../actions/mednec_issue_log.ac'

const defaultState = {
  error: null,
  isFetching: false,
  isSaving: false,
  issues: null,
  records: null,
  pargination: null
}

export const mednecIssueLog = (state = defaultState, action) => {
  switch (action.type) {
    case MednecIssueLogActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case MednecIssueLogActionTypes.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving
      }
    case MednecIssueLogActionTypes.RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES:
      return {
        ...state,
        records: action.records,
        pagination: action.pagination,
        error: null
      }
    case MednecIssueLogActionTypes.FAILED_RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES:
      return {
        ...state,
        records: null,
        pagination: null,
        error: action.error
      }
    case MednecIssueLogActionTypes.RECEIVED_MEDNEC_ISSUE_CODES:
      return {
        ...state,
        issues: action.issues,
        error: null
      }
    case MednecIssueLogActionTypes.FAILED_RECEIVING_MEDNEC_ISSUE_CODES:
      return {
        ...state,
        issues: null,
        error: action.error
      }
    default:
      return state
  }
}
