import { UserMgtLogsActionTypes } from '../actions/user_mgt_logs.ac.js';

export function userMgtLogsInView(
    state = {
        isFetching: false,
        params: null,
        records: null,
        tags: null,
        pagination: null,
        error: null,
    },
    action
) {
    switch (action.type) {
        case UserMgtLogsActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case UserMgtLogsActionTypes.RECEIVED_LOGS:
            return {
                ...state,
                pagination: action.pagination,
                records: action.records,
                tags: action.tags,
            };

        case UserMgtLogsActionTypes.FAILED_RECEIVING_LOGS:
            return {
                ...state,
                pagination: null,
                records: null,
                tags: null,
                error: action.error,
            };

        default:
            return state;
    }
};
