import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import FitScreenIcon from '@material-ui/icons/Fullscreen';
import MinimizeIcon from '@material-ui/icons/Minimize';
import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Draggable from 'react-draggable';

const O_BG_COLOR = '#FCF3CF';

const styles = () => ({
  modalWrapper: {
    width: '36%',
    minWidth: '500px',
    maxHeight: 'calc(100% - 100px)',
    padding: '0px 8px',
    borderRadius: '4px',
    margin: '90px auto',
    // backgroundColor: O_BG_COLOR,
    outline: 'unset',
    overflowY: 'auto',
  },
  windowLikeModalWrapper: {
    margin: '0px',
    width: '100%',
    minWidth: '350px',
  },
  modalStickyComponent: {
    position: 'sticky',
    // backgroundColor: O_BG_COLOR,
    zIndex: 10,
  },
  windowLikeModalHandle: {
    cursor: 'move',
  },
  windowLikeModal: {
    border: '6px solid lightseagreen',
    borderRadius: '6px',
    width: 'fit-content',
    height: 'fit-content',
    transition: 'border 1s ease-in-out',
  },
  windowLikeModalMinimized: {
    border: '3px solid blue',
  },
});

const defaultPosition = {
  x: window.innerWidth / 2 - 250,
  y: window.innerHeight / 2 - 250,
};

// To remember the position of the modal, set rememberDropPosition to true
const savedPosition = {
  x: defaultPosition.x,
  y: defaultPosition.y,
};

const FormModal = (props) => {
  const {
    classes,
    wrapperStyles,
    isModalOpen,
    onClose,
    header,
    children,
    cancelLabel,
    rememberDropPosition = true,
    closeOnSubmit = true,
    onSubmit,
    onReset,
    disabled = false,
    title,
    submitLabel = 'Submit',
    positionOffset,
    actions,
    containerStyle = {},
    backgroundColor = null, // True => O_BG_COLOR else provided will take effect
    windowLike = false,
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const overrideBG =
    backgroundColor === true ? O_BG_COLOR : backgroundColor || 'white';

  React.useEffect(() => {
    setIsOpen(isModalOpen);
  }, [isModalOpen]);

  const closeModal = () => {
    if (onClose) onClose(false);
    else setIsOpen(false);
  };

  const handleHeaderDoubleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const core = (
    <Modal
      {...(windowLike
        ? {
            disableEnforceFocus: true,
            disableAutoFocus: true,
            disableBackdropClick: true,
            hideBackdrop: true,
            disablePortal: true,
          }
        : {})}
      open={isOpen}
      onClose={closeModal}
      {...rest}
      classes={{
        root: classNames(classes.modalRoot, {
          [classes.windowLikeModal]: windowLike,
          [classes.windowLikeModalMinimized]: windowLike && isCollapsed,
        }),
      }}>
      <div
        className={classNames(classes.modalWrapper, {
          [classes.windowLikeModalWrapper]: windowLike,
        })}
        style={{
          ...(wrapperStyles || {}),
          backgroundColor: overrideBG,
        }}>
        <Grid
          container
          onDoubleClick={windowLike ? handleHeaderDoubleClick : undefined}
          className={classNames(
            classes.modalStickyComponent,
            'draggable-modal-handle',
            {
              [classes.windowLikeModalHandle]: windowLike,
            }
          )}
          style={{
            borderBottom: '1px solid #c5c5c5',
            paddingTop: '4px',
            paddingBottom: '4px',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: overrideBG,
          }}>
          <Grid
            container
            item
            style={{
              width: 'fit-content',
            }}>
            {title ? (
              <Typography
                align="left"
                color="default"
                style={{
                  padding: 8,
                  fontSize: '16px',
                  textTransform: 'uppercase',
                }}>
                {title}
              </Typography>
            ) : (
              header
            )}
          </Grid>
          <div>
            {windowLike && (
              <IconButton
                style={{ padding: 8 }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}>
                {isCollapsed ? <FitScreenIcon /> : <MinimizeIcon />}
              </IconButton>
            )}
            <IconButton style={{ padding: 8 }} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        {!isCollapsed && (
          <div style={{ padding: '12px', ...containerStyle }}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                {children}
              </Grid>
            </Grid>
            <div />
          </div>
        )}
        {!isCollapsed && (
          <Grid
            container
            justify="flex-end"
            className={classNames(
              classes.modalStickyComponent,
              'draggable-modal-handle',
              {
                [classes.windowLikeModalHandle]: windowLike,
              }
            )}
            style={{
              borderTop: '1px solid #c5c5c5',
              bottom: 0,
              alignItems: 'center',
            }}>
            {actions || (
              <>
                {' '}
                {onSubmit && (
                  <Grid item style={{ padding: 8 }}>
                    <Button
                      disabled={disabled}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        onSubmit();
                        closeOnSubmit && closeModal();
                      }}>
                      {submitLabel || 'Submit'}
                    </Button>
                  </Grid>
                )}
                {Boolean(onReset) && (
                  <Grid item style={{ padding: 8 }}>
                    <Button
                      disabled={disabled}
                      variant="contained"
                      color="default"
                      onClick={onReset}>
                      {'Reset'}
                    </Button>
                  </Grid>
                )}
                <Grid item style={{ padding: 8 }}>
                  <Button
                    disabled={disabled}
                    variant="outlined"
                    color="default"
                    onClick={closeModal}>
                    {cancelLabel || 'Cancel'}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </div>
    </Modal>
  );

  return windowLike ? (
    <Draggable
      handle=".draggable-modal-handle"
      defaultPosition={rememberDropPosition ? savedPosition : defaultPosition}
      positionOffset={positionOffset}
      bounds={positionOffset ? undefined : {
        // Restrict the modal to the window
        top: 0,
        left: 0,
        right: window.innerWidth - 350,
        bottom: window.innerHeight - 100,
      }}
      onStop={(e, data) => {
        if (rememberDropPosition) {
          savedPosition.x = data.x;
          savedPosition.y = data.y;
        }
      }}>
      {core}
    </Draggable>
  ) : (
    core
  );
};

export default withStyles(styles)(FormModal);
