import PropTypes from 'prop-types';
import React from 'react';

import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoaderRow from '../IndexingAdmin/common/LoaderRow';

const styles = () => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  stickyHeader: {
    top: 0,
    left: 0,
    Zindex: '9999',
    position: 'sticky',
    backgroundColor: 'white',
  },
  cardNode: {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardNodeTitle: {
    fontSize: 12,
    display: 'inline-block',
    color: 'darkblue',
  },
  cardNodeContent: {
    fontSize: 12,
    marginLeft: 2,
    display: 'inline-block',
    color: 'darkcyan',
  },
});

function CardList(props) {
  const { data, error, columns, classes, idKey = "_id", isLoading, onView } = props;

  const { isError, message } = error;

  const handleClick = (e, id) => {
    if (!onView) {
      return;
    }

    onView(id, e);
  };

  return (
    <div className={classes.root} style={props.baseStyle}>
      <div>
        {isLoading ? (
          <LoaderRow col={columns.length} />
        ) : data?.length ? (
          data.map((data, idx) => {
            return (
              <Card style={{ margin: '6px' }}>
                <CardContent
                  style={{
                    padding: '12px 8px 0px 8px',
                  }}>
                  {columns.map((column, cIdx) => (
                    <span className={classes.cardNode}>
                      <Typography className={classes.cardNodeTitle}>
                        {column.title + ': '}
                      </Typography>
                      <span key={cIdx} className={classes.cardNodeContent}>
                        {column['render']
                          ? column.render(data, idx)
                          : data[column.dataIndex] || 'N/A'}
                      </span>
                    </span>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    variant="outlined"
                    onClick={(e) => handleClick(e, data[idKey])}
                    color="primary">
                    View
                  </Button>
                </CardActions>
              </Card>
            );
          })
        ) : (
          <Typography
            variant="inherit"
            color={isError ? 'error' : 'textSecondary'}>
            {message}
          </Typography>
        )}
      </div>
    </div>
  );
}

CardList.propTypes = {
  data: PropTypes.array,
  error: PropTypes.object,
  sortData: PropTypes.object,
  onView: PropTypes.func,
  setSortData: PropTypes.func,
  selectedId: PropTypes.number,
  backgroundColor: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardList);
