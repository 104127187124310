import { IdnShellActionTypes } from '../actions/idn_shell.ac.js'

export const idnShellInView = (
  state = {
    isSubmitting: false,
    idn: null,
    error: null,
    idn_shell: null
  },
  action
) => {
  switch (action.type) {
    case IdnShellActionTypes.SET_IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.isSubmitting
      }

    case IdnShellActionTypes.IDN_SHELL_CREATED:
      return {
        ...state,
        idn: action.idn,
        isSubmitting: false,
        idn_shell: action.idn_shell
      }

    case IdnShellActionTypes.FAILED_CREATING_IDN_SHELL:
      return {
        ...state,
        idn: action.idn,
        idn_shell: null,
        isSubmitting: false,
        error: action.error
      }
    case IdnShellActionTypes.IDN_SHELL_RESET:
      return {
        ...state,
        idn: null,
        idn_shell: null,
        isSubmitting: false
      }

    default:
      return state
  }
}
