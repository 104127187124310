export const getPageStatement = pagination => {
  const {
    end,
    start,
    totalRecords,
    currentPageNum,
    totalPagesAvailable
  } = pagination;
  let pageEnd = totalRecords < end ? totalRecords : end;

  return [
    `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
    ` Page ${currentPageNum + 1} of ${totalPagesAvailable}`
  ].join('-');
};
