export const DATE_FORMAT = 'MM-DD-YYYY'

export const EDIT_MODE = 'edit'
export const CREATE_MODE = 'create'
export const DETAILS_MODE = 'details'

export const INITIAL_FILTERS = {
  name: '',
  addr1: '',
  city: '',
  order_type: '',
  zip: '',
  record_id:'',
}
export const INITIAL_LOCATION_FORM_DATA = {
  addr1: '',
  addr2: '',
  city: '',
  state: '',
  zip: '',

  order_type: '',
  name: '',

  phones: [],
  emails: [],
  faxes: [],
  urls: [],

  tags: [],
  meta_note: ''
}

export const FORM_MODE_MAP = {
  create: {
    submitLabel: 'create',
    successLabel: 'created',
    submitUrl: '/api/idx/admin/locations/add'
  },
  edit: {
    submitLabel: 'update',
    successLabel: 'updated',
    submitUrl: '/api/idx/admin/locations/update'
  }
}

export const MIN_TEXT_LENGTH = 3
export const MAX_NOTE_LENGTH = 500
export const MAX_CONTACT_DETAILS = 5
export const MAX_CHIP_LABEL_LENGTH = 50
export const G_PLACES_STUB_ID = 'idxLocationsPlacesStub'
