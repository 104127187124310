import { serialize } from '../util/object'

export const REQUEST_HOURLY_WOS_SUMMARY = 'REQUEST_HOURLY_WOS_SUMMARY'
export const RECEIVED_HOURLY_WOS_SUMMARY = 'RECEIVED_HOURLY_WOS_SUMMARY'
export const FAILED_HOURLY_WOS_SUMMARY = 'FAILED_HOURLY_WOS_SUMMARY'

export function fetchHourlyWosSummary_ac(params) {
  return (dispatch, _) => {
    dispatch({ type: REQUEST_HOURLY_WOS_SUMMARY })
    return window.sch
      .get(`/api/hourly_wos:list:summary?` + serialize(params))
      .then(result => {
        dispatch({
          type: RECEIVED_HOURLY_WOS_SUMMARY,
          pkg: result
        })
      })
      .catch(error => {
        dispatch({
          type: FAILED_HOURLY_WOS_SUMMARY,
          error
        })
      })
  }
}
