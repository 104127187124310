export function hdmsNotes(
  state = {
    isFetching: false,
    acctMember: null,
    error: null,
    pkg: null,
    noteHdrs: null,
    noteHdrsFetching: false
  },
  action
) {
  switch (action.type) {
    case 'CLEAR_HDMS_NOTES_FOR_REFRESH':
      return Object.assign({}, state, {
        pkg: null
      })
    case 'REQUEST_HDMS_NOTES_PKG':
      return Object.assign({}, state, {
        isFetching: true,
        acctMember: action.acctMember,
        error: null
      })
    case 'FAILED_RECEIVED_HDMS_NOTES_PKG':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    case 'RECEIVED_HDMS_NOTES_PKG':
      return Object.assign({}, state, {
        pkg: action.pkg,
        error: null,
        isFetching: false
      })

    case 'RESET_HDMS_NOTES_PKG':
      return Object.assign({}, state, {
        pkg: null,
        error: null,
        isFetching: false
      })
    case 'FETCH_NOTE_HDRS':
      return Object.assign({}, state, {
        noteHdrsFetching: true
      })
    case 'GOT_NOTE_HDRS':
      return Object.assign({}, state, {
        noteHdrs: action.hdrs,
        noteHdrsFetching: false
      })
    case 'FAILED_NOTE_HDRS':
      return Object.assign({}, state, {
        noteHdrsFetching: false,
        noteHdrs: []
      })

    default:
      return state
  }
}
