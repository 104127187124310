import {
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import {
  blue,
  blueGrey,
  green,
  orange,
  red,
  teal,
  yellow
} from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import dateUtils from '../../util/dateProcessing'

const styles = theme => ({
  row: {
    verticalAlign: 'top',
    '&.even-row': {
      backgroundColor: yellow['50']
    },
    '&>td': {
      padding: 8
    }
  },
  scrollableCellContent: {
    maxHeight: 300,
    overflowY: 'auto'
  },
  expandClosedIcon: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard
    }),
    color: theme.palette.primary.main
  },
  expandOpenIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main
  },
  link: {
    textDecoration: 'none',
    color: blue['700'],
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      color: blue['500']
    }
  },
  chip: {
    height: 24,
    borderRadius: 6,
    fontWeight: 800,
    margin: '4px 0px',
    textTransform: 'uppercase',
    '&.void': {
      backgroundColor: '#ffffff',
      color: '#000000',
      border: '1px solid #000000'
    },
    '&.initialized': {
      backgroundColor: '#ffffff',
      color: teal['500'],
      border: `1px solid ${teal['600']}`
    },
    '&.print_attempted': {
      backgroundColor: orange['600'],
      color: '#ffffff'
    },
    '&.approved': {
      backgroundColor: green['600'],
      color: '#ffffff'
    },
    '&.denied': {
      backgroundColor: red['500'],
      color: '#ffffff'
    }
  },
  infoBlock: {
    marginTop: 8
  },
  claimId: {
    padding: 4,
    backgroundColor: theme.palette.primary['50']
  },
  userName: {
    color: '#795548',
    fontWeight: 600
  },
  logItem: {
    backgroundColor: blue['50'],
    padding: 4,
    marginBottom: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    '&>.comment': {
      color: blueGrey['800']
    }
  }
})

const ExpandableAppealRow = props => {
  const { rowIdx, record, isExpandedDefault, classes } = props

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault)

  useEffect(() => {
    setIsExpanded(isExpandedDefault)
  }, [isExpandedDefault])

  return (
    <TableRow
      className={classNames(classes.row, {
        'even-row': rowIdx % 2 === 0
      })}>
      <TableCell width="1%" style={{ padding: 2 }}>
        <IconButton
          className={classNames(classes.expandClosedIcon, {
            [classes.expandOpenIcon]: isExpanded
          })}
          onClick={() => setIsExpanded(!isExpanded)}>
          <ExpandMoreIcon />
        </IconButton>
      </TableCell>

      <TableCell width="18%">
        <div className={classes.scrollableCellContent}>
          <Typography variant="body2">
            <strong>#{record._id || '_'}</strong>
          </Typography>
          <Typography variant="body2">
            <Link
              to={`/dfa/idn/${record.idn}/tabs/appeal_requests`}
              className={classes.link}>
              {record.idn}
            </Link>
          </Typography>
          <Typography variant="body2">
            <strong>A:</strong>
            {record.account}
          </Typography>

          {isExpanded && (
            <>
              <div className={classes.infoBlock}>
                <Typography variant="body2" color="primary">
                  <strong>Claim ID(s):</strong>
                </Typography>
                <div className={classes.claimId}>
                  {record.claim_ids?.length > 0 &&
                    record.claim_ids.map((clId, clIdx) => (
                      <span key={clIdx}>
                        {clId}
                        {clIdx < record.claim_ids.length - 1 ? (
                          <>,&ensp;</>
                        ) : (
                          ''
                        )}
                      </span>
                    ))}
                </div>
              </div>

              {record.msg && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="primary">
                    <strong>Message:</strong>
                  </Typography>
                  <Typography variant="body2">{record.msg}</Typography>
                </div>
              )}
            </>
          )}
        </div>
      </TableCell>

      <TableCell width="17%">
        <div className={classes.scrollableCellContent}>
          {record.which_action && (
            <div>
              <small>Dash Action:</small> <strong>{record.which_action}</strong>
            </div>
          )}

          <Typography variant="body2" className={classes.userName}>
            {record.u_nm}
          </Typography>
          <Typography variant="body2">({record.u_id})</Typography>

          {isExpanded && record.trm.u_id && record.trm.u_id !== record.u_id && (
            <div className={classes.infoBlock}>
              <Typography variant="body2" color="primary">
                <strong>Trm:</strong>
              </Typography>
              <Typography variant="body2" className={classes.userName}>
                {record.trm.u_nm}
              </Typography>
              <Typography variant="body2">({record.trm.u_id})</Typography>
            </div>
          )}
        </div>
      </TableCell>

      <TableCell width="31%">
        <div>
          {record.mergeFailed ? (
            <strong style={{ color: 'red' }}>PDF Merge Failed</strong>
          ) : record.mergedKey ? (
            <span>PDF Merge Succeeded</span>
          ) : (
            <strong style={{ color: 'orange' }}>
              PDF Merge Has Not Occurred
            </strong>
          )}
        </div>

        <div className={classes.scrollableCellContent}>
          {record.log && record.log.length ? (
            !isExpanded ? (
              <>
                <Typography variant="body2" inline>
                  {record.log[0].msg}
                </Typography>
                {record.log.length > 1 && (
                  <Typography variant="caption" color="textSecondary" inline>
                    ,<br />
                    ...and {record.log.length - 1} more
                  </Typography>
                )}
              </>
            ) : (
              record.log.map((logItem, logIdx) => (
                <Typography
                  key={logIdx}
                  variant="body2"
                  className={classes.logItem}>
                  {logItem.msg}
                </Typography>
              ))
            )
          ) : (
            <Typography variant="body2" color="textSecondary">
              N/A
            </Typography>
          )}
        </div>
      </TableCell>

      <TableCell width="19%">
        <div className={classes.scrollableCellContent}>
          {record.trm.state && (
            <Chip
              color="secondary"
              label={record.trm.state.replace(/_/g, ' ')}
              className={classNames(classes.chip, {
                [record.trm.state]: record.trm.state
              })}
            />
          )}

          {record.skip_automated_printing && (
            <div>
              <strong>SFTP AUTOMATION SKIPPED </strong>
              <span>(approval occurred prior to automation process)</span>
            </div>
          )}

          {record.tries && (
            <Typography variant="caption">
              <strong>{record.tries}</strong>
              &ensp;{+record.tries > 1 ? 'attempts' : 'attempt'}
            </Typography>
          )}

          {record.trm.pdf_ok !== undefined && (
            <div>
              <Typography inline variant="body2" color="textSecondary">
                PDF
                {record.trm.pdf_ok ? (
                  <CheckCircleIcon className={classes.trmStatusOK} />
                ) : (
                  <CancelIcon className={classes.trmStatusNotOK} />
                )}
              </Typography>
              &ensp;&ensp;
              <Typography inline variant="body2" color="textSecondary">
                SFTP
                {record.trm.sftp_ok ? (
                  <CheckCircleIcon className={classes.trmStatusOK} />
                ) : (
                  <CancelIcon className={classes.trmStatusNotOK} />
                )}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {record.trm.ts && dateUtils.formatDate(record.trm.ts)}
              </Typography>
            </div>
          )}

          {!isExpanded ? (
            <Typography variant="body2">
              {record.trm.receipt &&
                record.trm.receipt.labelIds &&
                record.trm.receipt.labelIds.length > 0 &&
                ` - ${record.trm.receipt.labelIds.join(', ')}`}
            </Typography>
          ) : (
            <>
              {record.trm.method && (
                <div className={classes.infoBlock}>
                  <Typography variant="body2" color="primary" inline>
                    <strong>Method:</strong>&ensp;
                  </Typography>
                  <Typography variant="body2" inline>
                    {record.trm.method.toUpperCase()}
                  </Typography>
                </div>
              )}
              {record.trm.receipt &&
                record.trm.receipt.labelIds &&
                record.trm.receipt.labelIds.length > 0 && (
                  <div className={classes.infoBlock}>
                    <Typography variant="body2" color="primary" inline>
                      <strong>Receipt Labels:</strong>&ensp;
                    </Typography>
                    <Typography variant="body2" inline>
                      {record.trm.receipt.labelIds.join(', ')}
                    </Typography>
                  </div>
                )}
            </>
          )}
        </div>
      </TableCell>

      <TableCell width="14%">
        <div className={classes.scrollableCellContent}>
          {record.trm.state !== 'initialized' && (
            <Typography component="p">
              Last Action: {dateUtils.formatDate(record.trm.ts)}
            </Typography>
          )}
          <Typography component="p">
            Created: {dateUtils.formatDate(record.ts)}
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(ExpandableAppealRow)
