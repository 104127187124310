import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Backspace as BackSpaceIcon } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { Component } from 'react';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 72px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  title: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: 0,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  tableScrollable: {
    minHeight: '180px',
    height: 'calc(100% - 66px)',
    overflowY: 'auto',
  },
  tableRowSelected: {
    backgroundColor: 'yellow',
  },
  tableHeader: {
    height: 40,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
    },
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
  },
});

class ReferralSearchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onClear = () => {
    this.setState({
      searchString: '',
    });
  };

  search = () => {
    const { searchString } = this.state;
    if (isNaN(this.state.searchString)) {
      return this.props.onSearch({ searchString });
    }

    this.props.onSearch({ id: searchString });
  };

  resetSearch = () => {
    this.props.resetSearch();
    this.setState({ searchString: '' });
  };

  render() {
    const { isSearching, classes } = this.props;
    return (
      <Grid container>
        <Grid item>
          <TextField
            variant="outlined"
            onChange={this.handleChange}
            placeholder="Search by Referral# or idn"
            name="searchString"
            value={this.state.searchString || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.onClear}
                    classes={{ root: classes.iconButtonRoot }}>
                    <BackSpaceIcon />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                adornedEnd: classes.adornedEnd,
              },
            }}
          />
        </Grid>
        <Grid item style={{ display: 'flex', marginLeft: 10 }}>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={this.search}
            disabled={!this.state.searchString}>
            Search
          </Button>
          {isSearching && (
            <Button
              title="Reset Search"
              variant="contained"
              onClick={this.resetSearch}
              style={{ marginLeft: 10 }}>
              <RefreshIcon />
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

export const ReferralSearch = withStyles(styles)(ReferralSearchComponent);
