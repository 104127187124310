import { open_snack_ac } from './snack.ac.js'
export const ClientAppsActionTypes = {
  CLIENT_APPLICATION_CREATED: 'CLIENT_APPLICATION_CREATED',
  CLIENT_APPLICATION_UPDATED: 'CLIENT_APPLICATION_UPDATED',
  APPLICATION_KEY_PAIR_GENERATED: 'APPLICATION_KEY_PAIR_GENERATED',
  KEY_PAIR_GENERATION_FAILED: 'KEY_PAIR_GENERATION_FAILED',
  APPLICATION_KEY_PAIR_DELETED: 'APPLICATION_KEY_PAIR_DELETED',
  SET_IS_SUBMITTING: 'SET_IS_SUBMITTING',
  SET_IS_GENERATING_KEY_PAIR: 'SET_IS_GENERATING_KEY_PAIR',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  CLIENT_APPLICATION_LIST_RECEIVED: 'CLIENT_APPLICATION_LIST_RECEIVED',
  CLIENT_APPLICATION_RECEIVING_FAILED: 'CLIENT_APPLICATION_RECEIVING_FAILED',
  UPDATE_CLIENT_APPS: 'UPDATE_CLIENT_APPS'
}

export const createApplication_ac = params => dispatch => {
  dispatch(submitting(true))
  return window.sch
    .post(`/api/comm_mgt/client_apps/create`, { ...params })
    .then(response => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'Client Application Created'
        })
      )
      dispatch({
        type: ClientAppsActionTypes.CLIENT_APPLICATION_CREATED,
        client_apps: response.client_apps
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Creating Client Application'
        })
      )
    })
    .finally(() => {
      dispatch(submitting(false))
    })
}

export const generateApplicationKeyPair_ac = params => (dispatch, getState) => {
  dispatch(generatingKeyPair(true))
  return window.sch
    .post(`/api/comm_mgt/client_apps/gen_key_pair`, { ...params })
    .then(result => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'Key Pair Generated'
        })
      )
      dispatch({
        type: ClientAppsActionTypes.APPLICATION_KEY_PAIR_GENERATED,
        pair: result.pair
      })
    })
    .catch(error => {
      let errMsg = error || 'Error Generating Application Key Pair'
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: errMsg
        })
      )
    })
    .finally(() => {
      dispatch(generatingKeyPair(false))
    })
}

export const fetchAllClientAppsList_ac = params => dispatch => {
  dispatch(fetching(true))
  return window.sch
    .post(`/api/comm_mgt/client_apps/list`, { ...params })
    .then(response => {
      dispatch({
        type: ClientAppsActionTypes.CLIENT_APPLICATION_LIST_RECEIVED,
        records: response.results
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Fetching Application List'
        })
      )
      dispatch({
        type: ClientAppsActionTypes.CLIENT_APPLICATION_RECEIVING_FAILED,
        error
      })
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const removeKeyPair_ac = params => dispatch => {
  return window.sch
    .post(`/api/comm_mgt/client_apps/delete_key_pair`, { ...params })
    .then(results => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'Selected Key Pair Removed'
        })
      )
      dispatch({
        type: ClientAppsActionTypes.APPLICATION_KEY_PAIR_DELETED,
        pair_id: params.pair_id
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Removing Key Pair'
        })
      )
    })
}

export const updateApplication_ac = params => dispatch => {
  dispatch(submitting(true))
  return window.sch
    .post(`/api/comm_mgt/client_apps/update`, { ...params })
    .then(response => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'Client Application Updated'
        })
      )
      dispatch({
        type: ClientAppsActionTypes.CLIENT_APPLICATION_UPDATED,
        _id: params._id,
        preferences: params.preferences
      })
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Updating Client Application'
        })
      )
    })
    .finally(() => {
      dispatch(submitting(false))
    })
}

export const updateSelectedClientApps_ac = app => dispatch => {
  dispatch({
    type: ClientAppsActionTypes.UPDATE_CLIENT_APPS,
    client_apps: app
  })
}

export const fetching = isFetching => ({
  type: ClientAppsActionTypes.SET_IS_FETCHING,
  isFetching
})

export const submitting = isSubmitting => ({
  type: ClientAppsActionTypes.SET_IS_SUBMITTING,
  isSubmitting
})

export const generatingKeyPair = isGenerating => ({
  type: ClientAppsActionTypes.SET_IS_GENERATING_KEY_PAIR,
  isGenerating
})
