import { serialize } from '../util/object';

export const FETCH_IDX_LOOKUPS = 'FETCH_IDX_LOOKUPS';
export const FETCH_IDX_LOOKUPS_SUCCESSFUL = 'FETCH_IDX_LOOKUPS_SUCCESSFUL';
export const FETCH_IDX_LOOKUPS_FAILED = 'FETCH_IDX_LOOKUPS_FAILED';

export const FETCH_IDX_LOOKUP_DETAIL = 'FETCH_IDX_LOOKUP_DETAIL';
export const FETCH_IDX_LOOKUP_DETAIL_SUCCESSFUL =
  'FETCH_IDX_LOOKUP_DETAIL_SUCCESSFUL';
export const FETCH_IDX_LOOKUP_DETAIL_FAILED =
  'FETCH_IDX_LOOKUP_DETAIL_FAILED_FAILED';

export const ADD_IDX_LOOKUP = 'ADD_IDX_LOOKUP';
export const ADD_IDX_LOOKUP_SUCCESSFUL = 'ADD_IDX_LOOKUP_SUCCESSFUL';
export const ADD_IDX_LOOKUP_FAILED = 'ADD_IDX_LOOKUP_FAILED';

export const REMOVE_IDX_LOOKUP = 'REMOVE_IDX_LOOKUP';
export const REMOVE_IDX_LOOKUP_SUCCESSFUL = 'REMOVE_IDX_LOOKUP_SUCCESSFUL';
export const REMOVE_IDX_LOOKUP_FAILED = 'REMOVE_IDX_LOOKUP_FAILED';

export const UPDATE_IDX_LOOKUPS_SEQUENCE = 'UPDATE_IDX_LOOKUPS_SEQUENCE';
export const UPDATE_IDX_LOOKUPS_SEQUENCE_SUCCESSFUL =
  'UPDATE_IDX_LOOKUPS_SEQUENCE_SUCCESSFUL';
export const UPDATE_IDX_LOOKUPS_SEQUENCE_FAILED =
  'UPDATE_IDX_LOOKUPS_SEQUENCE_FAILED';

export const FETCH_IDX_LOOKUPS_STRUCTURE = 'FETCH_IDX_LOOKUPS_STRUCTURE';
export const FETCH_IDX_LOOKUPS_STRUCTURE_SUCCESSFUL =
  'FETCH_IDX_LOOKUPS_STRUCTURE_SUCCESSFUL';
export const FETCH_IDX_LOOKUPS_STRUCTURE_FAILED =
  'FETCH_IDX_LOOKUPS_STRUCTURE_FAILED';

export const SET_IDX_LOOKUP_PROPS = 'SET_IDX_LOOKUP_PROPS';

export function fetchIdxLookupsStructure_ac(params) {
  return (dispatch) => {
    dispatch({ type: FETCH_IDX_LOOKUPS_STRUCTURE });
    return window.sch
      .get(`/api/idx/admin/lookups/structure?` + serialize(params))
      .then((result) => {
        dispatch({
          type: FETCH_IDX_LOOKUPS_STRUCTURE_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_IDX_LOOKUPS_STRUCTURE_FAILED,
          error,
        });
      });
  };
}

export function fetchIdxLookups_ac(params) {
  return (dispatch) => {
    dispatch({ type: FETCH_IDX_LOOKUPS });
    return window.sch
      .get(`/api/idx/admin/lookups/list?` + serialize(params))
      .then((result) => {
        dispatch({
          type: FETCH_IDX_LOOKUPS_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_IDX_LOOKUPS_FAILED,
          error,
        });
      });
  };
}

export function fetchIdxLookupDetail_ac(params) {
  return (dispatch) => {
    dispatch({ type: FETCH_IDX_LOOKUP_DETAIL });
    return window.sch
      .get(`/api/idx/admin/lookups/detail?` + serialize(params))
      .then((result) => {
        dispatch({
          type: FETCH_IDX_LOOKUP_DETAIL_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_IDX_LOOKUP_DETAIL_FAILED,
          error,
        });
      });
  };
}

export function addIdxLookup_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: ADD_IDX_LOOKUP });
    return window.sch
      .post(`/api/idx/admin/lookups/add`, params)
      .then((result) => {
        dispatch({
          type: ADD_IDX_LOOKUP_SUCCESSFUL,
          data: { ...params, _id: result.new_id, seq: result.next_seq },
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: ADD_IDX_LOOKUP_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}
export function removeIdxLookup_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: REMOVE_IDX_LOOKUP });
    return window.sch
      .post(`/api/idx/admin/lookups/remove`, params)
      .then((result) => {
        dispatch({
          type: REMOVE_IDX_LOOKUP_SUCCESSFUL,
          data: result,
          removedIndex: params.removedIndex,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: REMOVE_IDX_LOOKUP_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function updateIdxLookupsSequence_ac(
  params,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: UPDATE_IDX_LOOKUPS_SEQUENCE });
    return window.sch
      .post(`/api/idx/admin/lookups/update_sequence`, params)
      .then((result) => {
        dispatch({
          type: UPDATE_IDX_LOOKUPS_SEQUENCE_SUCCESSFUL,
          data: result,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_IDX_LOOKUPS_SEQUENCE_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function setIdxLookupProps_ac(lookup, props) {
  return {
    type: SET_IDX_LOOKUP_PROPS,
    lookup,
    props,
  };
}
