import React from 'react';

import {
  Grid,
  Input,
  Button,
  InputLabel,
  Card,
  CardContent,
} from '@material-ui/core';

import TextInfo from './TextInfo';
import { toTitleCase } from '../../../util/string';
import { KeyValuePair } from '../../common/NameLabelView';

const HourlyWosFilter = ({
  filters,
  onChange,
  onFilter,
  onReset,
  infoData,
  isloading,
}) => {
  return (
    <Grid container style={{ marginBottom: '12px' }}>
      <Grid item xs={1} style={{ maxWidth: '7.6%' }}>
        <InputLabel htmlFor="startDate">Start date</InputLabel>
        <Input
          id="startDate"
          name="startDate"
          variant="outlined"
          label="Start Date"
          type="date"
          value={filters.startDate}
          onChange={onChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={1} style={{ marginLeft: '16px', maxWidth: '7.6%' }}>
        <InputLabel htmlFor="endDate">End date</InputLabel>
        <Input
          fullWidth
          variant="outlined"
          id="endDate"
          name="endDate"
          label="End Date"
          type="date"
          value={filters.endDate}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={1} style={{ marginLeft: '16px' }}>
        <InputLabel htmlFor="size">Items per page</InputLabel>
        <Input
          fullWidth
          variant="outlined"
          id="size"
          name="size"
          label="Items per page"
          inputProps={{ min: 1 }}
          type="number"
          value={filters.size}
          onChange={onChange}
        />
      </Grid>

      <Grid
        item
        xs={2}
        style={{
          display: 'flex',
          marginLeft: '30px',
          alignItems: 'end',
        }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onFilter}
          disabled={isloading}>
          Apply{' '}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={onReset}
          style={{ marginLeft: '12px' }}>
          {' '}
          Reset{' '}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 10 }}>
          <CardContent style={{ padding: 8 }}>
            {infoData.total ? (
              <KeyValuePair
                source={infoData}
                partitions={6}
                keyCb={(key) => toTitleCase(key).replace(/Supercare.*$/i, '')}
              />
            ) : (
              <TextInfo value="No records to show" />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HourlyWosFilter;
