const possiblyEmail = (str) => str.includes('@');
const possiblyPhone = (str) => {
  const phoneRegEx =
    /^(\+1[0-9]{10}|1[0-9]{10}|1?-?(\([0-9]{3}\) [0-9]{3}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}))$/;

  return str.match(phoneRegEx) !== null;
};

export const masterSearch = async (searchStr) => {
  let postData = { searchStr };
  let target_url = `/api/master:search`;

  const searchString = searchStr.toLowerCase();
  const snPattern = searchString.substr(0, 3);
  const hdmsPattern = searchString.substr(0, 5);
  const hdmsRegEx = /^w\d{4}$|^m\d{4}$/;

  // Apply search pattern logic
  switch (true) {
    case possiblyEmail(searchString): {
      target_url = `/api/master:search_by_email`;
      return window.sch
        .post(target_url, { searchEmail: searchString })
        .then((results) => ({
          searchStr,
          kind: results.kind,
          results: results.results,
          msg: results.msg,
          warning: results.warning,
          altRes: results.altRes,
        }))
        .catch((error) => ({
          searchStr,
          error,
          msg: '',
          warning: '',
        }));
    }

    case possiblyPhone(searchString): {
      target_url = `/api/master:search_by_phone`;
      return window.sch
        .post(target_url, { phoneNumber: searchString })
        .then((results) => ({
          searchStr,
          kind: results.kind,
          results: results.results,
          msg: results.msg,
          warning: results.warning,
          altRes: results.altRes,
        }))
        .catch((error) => ({
          searchStr,
          error,
          msg: '',
          warning: '',
        }));
    }

    case searchString.search(/^- [0-9]{1,10} [a-z]/i) === 0: {
      target_url = `/api/master:search_by_addr`;
      return window.sch
        .post(target_url, { searchAddr: searchString.substr(2) })
        .then((results) => ({
          searchStr,
          kind: results.kind,
          results: results.results,
          msg: results.msg,
          warning: results.warning,
          altRes: results.altRes,
        }))
        .catch((error) => ({
          searchStr,
          error,
          msg: '',
          warning: '',
        }));
    }

    case snPattern === 'sn:': {
      let sn = searchString.substr(3);
      target_url = `/api/dt/wos/search_by_sn/${sn}`;
      return window.sch
        .get(target_url)
        .then((results) => ({
          searchStr,
          kind: results.kind,
          results: results.results,
          msg: '',
          warning: '',
          altRes: results.altRes,
        }))
        .catch((error) => ({
          searchStr,
          error,
          msg: '',
          warning: '',
        }));
    }

    case !!hdmsPattern.match(hdmsRegEx): {
      target_url = `/api/accounts_by_wos_id`;
      return window.sch
        .post(target_url, postData)
        .then((results) => ({
          searchStr,
          kind: results.kind,
          results: results.results,
          msg: results.msg,
          altRes: results.altRes,
        }))
        .catch((error) => ({
          searchStr,
          error,
          msg: '',
          warning: '',
        }));
    }

    default: {
      return window.sch
        .post(target_url, postData)
        .then((results) => ({
          searchStr,
          kind: results.kind,
          results: results.results,
          msg: results.msg,
          potentialNewIDN: results.potentialNewIDN,
          in_hdms_only: results.in_hdms_only,
          warning: results.warning,
          altRes: results.altRes,
        }))
        .catch((error) => ({
          searchStr,
          error,
          msg: '',
          warning: '',
        }));
    }
  }
};
