import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as pdfGen from '../../actions/pdfGen.ac'
import { Tooltip } from '@material-ui/core'
import { initGlobalWUAction_ac, stageDocForCopy_ac } from '../../actions/global_wu_actions.ac'
import { GLOBAL_WU_ACTIONS } from '../WorkUnits/constants'

const styles = theme => ({
  wrapper: {
    marginBottom: 8
  },
  item: {
    display: 'inline-block',
    marginRight: 8,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selected: {
    fontWeight: 'bold'
  },
  selectedDoc: {
    backgroundColor: 'yellow'
  }
})

//doc entry is a doc inside a work unit
function DocEntryDisplay({ displayType, doc }) {
  switch (displayType) {
    case 'Divider':
      return doc.faxTsMDY
    case 'Workunit':
      return doc.nm
    case 'Date':
      return doc.nm
    default:
      return ''
  }
}

class DocSetEntry extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showPages: false
    }

    this.showPages = this.showPages.bind(this)
  }

  showPages() {
    if (this.props.doc.pages.length === 1) {
      this.props.onSetClick()
      return
    }

    this.setState({
      showPages: !this.state.showPages
    })
  }

  render() {
    let classes = this.props.classes

    const isSelectedDoc =
      this.props.selectionKey === 'posOfWorkUnitDocSelected'
        ? this.props.accountInView.posOfWorkUnitDocSelected ===
            this.props.doc.posInSet &&
          this.props.accountInView.workUnitDocs.wId === this.props.doc.wId
        : this.props.accountInView.posOfDocSelected === this.props.doc.posInSet

    const hdr = {
      root: isSelectedDoc ? classes.selected : classes.notSelected
    }

    return (
      <div className={classes.wrapper}>
        <div className={isSelectedDoc ? classes.selectedDoc : ''}>
          <Typography
            classes={hdr}
            color="textSecondary"
            className={classes.item}
            onClick={this.props.onSetClick}>
            <DocEntryDisplay
              doc={this.props.doc}
              displayType={this.props.display}
            />
          </Typography>
          <Typography
            color="textSecondary"
            className={classes.item}
            onClick={this.showPages}>
            ({this.props.doc.pages.length}pgs)
          </Typography>
          <Typography
            color="textSecondary"
            className={classes.item}
            onClick={() => {
              this.props.pdfGenAddDoc(this.props.doc);
            }}>
            +PDF
          </Typography>

          <Tooltip title="Stage/Copy this doc to another Workunit">
            <Typography
              color="textSecondary"
              className={classes.item}
              style={{
                color: 'cadetblue',
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (e.shiftKey) {
                  return this.props.initGlobalWUAction_ac(
                    GLOBAL_WU_ACTIONS.COPY_DOCS,
                    {
                      docs: [
                        {
                          doc: this.props.doc,
                          faxJob: this.props.faxJob,
                        },
                      ],
                    }
                  );
                }
                this.props.stageDocForCopy_ac(
                  {
                    doc: this.props.doc,
                    faxJob: this.props.faxJob,
                  },
                  true // openStage
                );
              }}>
              {'+CPY'}
            </Typography>
          </Tooltip>
        </div>
        {/*this.state.showPages && ( <p>TODO pages</p> )*/}
      </div>
    );
  }
}

const mapStateToProps = stateFromStore => ({
  accountInView: stateFromStore.accountInView,
  docInView: stateFromStore.docInView,
  idnInView: stateFromStore.idnInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pdfGenAddDoc: pdfGen.addDoc,
      pdfGenToggleDrawer: pdfGen.toggleDrawer,
      initGlobalWUAction_ac,
      stageDocForCopy_ac,
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DocSetEntry))
)
