import { formLookupActionTypes } from '../../actions/forms';

const {
  FETCH_FORM_LOOKUPS,
  FETCH_FORM_LOOKUPS_SUCCESSFUL,
  FETCH_FORM_LOOKUPS_FAILED,
  FETCH_FORM_LOOKUP_DETAIL,
  FETCH_FORM_LOOKUP_DETAIL_SUCCESSFUL,
  FETCH_FORM_LOOKUP_DETAIL_FAILED,
  FETCH_FORM_LOOKUPS_STRUCTURE,
  FETCH_FORM_LOOKUPS_STRUCTURE_SUCCESSFUL,
  FETCH_FORM_LOOKUPS_STRUCTURE_FAILED,
  ADD_FORM_LOOKUP,
  ADD_FORM_LOOKUP_SUCCESSFUL,
  ADD_FORM_LOOKUP_FAILED,
  REMOVE_FORM_LOOKUP,
  REMOVE_FORM_LOOKUP_SUCCESSFUL,
  REMOVE_FORM_LOOKUP_FAILED,
  SET_FORM_LOOKUP_PROPS,
} = formLookupActionTypes;

export const initialState = {
  structure: {
    data: {},
    isFetching: false,
    error: null,
  },
  list: {
    data: [],
    isFetching: false,
    error: null,
  },
  detail: {
    data: null,
    isFetching: false,
    error: null,
  },
  isAddingFormLookup: false,
  isRemovingFormLookup: false,
};

export function formLookups(state = initialState, action) {
  switch (action.type) {
    // FETCH_FORM_LOOKUPS_STRUCTURE
    case FETCH_FORM_LOOKUPS_STRUCTURE:
      return Object.assign({}, state, {
        structure: {
          ...state.structure,
          isFetching: true,
        },
      });
    case FETCH_FORM_LOOKUPS_STRUCTURE_SUCCESSFUL:
      return Object.assign({}, state, {
        structure: {
          isFetching: false,
          data: action.data.pkg,
          error: null,
        },
        list: {
          ...initialState.list,
        },
        detail: {
          ...initialState.detail,
        },
      });
    case FETCH_FORM_LOOKUPS_STRUCTURE_FAILED:
      return Object.assign({}, state, {
        structure: {
          isFetching: false,
          data: {},
          error: action.error,
        },
      });

    // FETCH_FORM_LOOKUPS
    case FETCH_FORM_LOOKUPS:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          isFetching: true,
        },
      });
    case FETCH_FORM_LOOKUPS_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: action.data.rr,
          error: null,
        },
        detail: {
          ...initialState.detail,
        },
      });
    case FETCH_FORM_LOOKUPS_FAILED:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      });

    // FETCH_FORM_LOOKUP_DETAIL
    case FETCH_FORM_LOOKUP_DETAIL:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          isFetching: true,
        },
      });
    case FETCH_FORM_LOOKUP_DETAIL_SUCCESSFUL:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      });
    case FETCH_FORM_LOOKUP_DETAIL_FAILED:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      });

    // ADD_FORM_LOOKUP
    case ADD_FORM_LOOKUP:
      return Object.assign({}, state, {
        isAddingFormLookup: true,
      });
    case ADD_FORM_LOOKUP_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          data: [...state.list.data, action.data],
        },
        isAddingFormLookup: false,
      });
    case ADD_FORM_LOOKUP_FAILED:
      return Object.assign({}, state, {
        isAddingFormLookup: false,
      });

    // REMOVE_FORM_LOOKUP
    case REMOVE_FORM_LOOKUP:
      return Object.assign({}, state, {
        isRemovingFormLookup: true,
      });
    case REMOVE_FORM_LOOKUP_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          data: state.list.data.filter((_, index) =>
            action.removedIndex !== undefined
              ? index !== action.removedIndex
              : index !== action.data.removed_seq
          ),
        },
        detail: {
          ...state.detail,
          data: null,
        },
        isRemovingFormLookup: false,
      });
    case REMOVE_FORM_LOOKUP_FAILED:
      return Object.assign({}, state, {
        isRemovingFormLookup: false,
      });

    case SET_FORM_LOOKUP_PROPS:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          data: {
            ...state.detail.data,
            props: action.props,
          },
        },
      });

    default:
      return state;
  }
}
