import { actionTypes } from '../actions/ops_forms_report.ac'

export const opsFormsReport = (
  state = {
    isFetching: false,
    report: null,
    error: null
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_OPS_FORMS_REPORT:
      return {
        ...state,
        isFetching: action.isFetching
      }

    case actionTypes.RECEIVED_OPS_FORMS_REPORT:
      return {
        ...state,
        report: {
          records: action.records,
          pagination: action.pagination
        },
        error: null
      }

    case actionTypes.FAILED_OPS_FORMS_REPORT:
      return {
        ...state,
        report: null,
        error: action.error
      }

    case 'UPDATE_LOCAL_OPS_FORM_RECORDS_ON_SUCCESS_GENERAL':
      return {
        ...state,
        report: {
          ...state.report,
          records: action.records
        }
      }

    default:
      return state
  }
}
