import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Collapse from '@material-ui/core/Collapse'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchDrafts_ac } from '../../actions/mapping_tool.ac'
import formatDate from '../../util/dateProcessing'
import Loader from '../Shared/Loader'
import StyledTooltip from '../Shared/StyledTooltip'

const styles = theme => ({
  draftBtn: {
    marginTop: 5,
    marginBottom: 5
  },
  templateDraftIcon: {
    display: 'inline-block',
    cursor: 'pointer'
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.primary.main,
    transition: 'transform 0.3s'
  },
  expandedIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main,
    transition: 'transform 0.3s'
  },
  card: {
    fontSize: 11,
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
    marginRight: 10
  },
  time: {
    color: '#4a148c'
  }
})

class MappingTemplate extends React.Component {
  constructor() {
    super()
    this.state = {
      isExpanded: false
    }
    this.expand = this.expand.bind(this)
  }
  expand() {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  deploy() {
    /*
    const { template } = this.props
    const params = {
      draft_ids: draftId,
      path: templateInDraft.path,
      display_name: templateInDraft.display_name,
      id: template.id
    }
    this.props.deployDraft_ac(params)
    */
  }

  render() {
    const { isExpanded } = this.state
    const {
      classes,
      template,
      mappingToolInView,
      onSelect,
      onEdit
    } = this.props

    const { drafts } = mappingToolInView
    let draft_data = template.pages
      .map((p, pi) => {
        return drafts[template.id + '_' + pi]?.data
      })
      .filter(d => d)
      .filter(d => d.length)

    return (
      <div>
        <div
          style={{ marginBottom: 8, cursor: 'pointer', fontSize: 12 }}
          onClick={this.expand}>
          <span>
            [+/-] {template.display_name} - {template.pages.length} Page(s)
          </span>
        </div>

        <div style={{ paddingLeft: 8 }}>
          {isExpanded &&
            template.pages.map((page, page_pos) => (
              <MappingTemplatePage
                mappingToolInView={mappingToolInView}
                key={template.id + '_' + page_pos}
                tpl_id={template.id}
                page_pos={page_pos}
                classes={classes}
                fetchDrafts_ac={this.props.fetchDrafts_ac}
                templatePage={page}
                onSelect={onSelect}
                onEdit={onEdit}
              />
            ))}
          {template.pages.length === draft_data.length && (
            <div
              style={{ marginBottom: 8, cursor: 'pointer', fontSize: 12 }}
              onClick={this.expand}>
              <span>Deploy to Production</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

class MappingTemplatePage extends React.Component {
  constructor() {
    super()
    this.state = {
      isExpanded: true
    }
  }

  componentDidMount() {
    this.check()
  }

  componentDidUpdate() {
    this.check()
  }

  check() {
    const { mappingToolInView, tpl_id, page_pos } = this.props
    const { drafts } = mappingToolInView
    if (drafts[tpl_id + '_' + page_pos] !== undefined) {
      return
    }
    if (drafts[tpl_id + '_' + page_pos]?.isFetching) {
      return
    }
    return this.props.fetchDrafts_ac({
      tpl_id: this.props.tpl_id,
      page_pos: this.props.page_pos
    })
  }

  editDraft = (draft, t) => {
    this.props.onEdit(draft, t)
  }

  render() {
    const {
      classes,
      templatePage,
      tpl_id,
      page_pos,
      mappingToolInView
    } = this.props
    return (
      <div>
        <Collapse in={this.state.isExpanded}>
          {!mappingToolInView.drafts[tpl_id + '_' + page_pos]?.isFetching &&
            mappingToolInView.drafts[tpl_id + '_' + page_pos]?.data.length ===
              0 && (
              <Button
                size="small"
                color="primary"
                onClick={() =>
                  this.props.onSelect({ page_pos, tpl_id, ...templatePage })
                }>
                Create New Draft for Page {1 + page_pos}
              </Button>
            )}
          {mappingToolInView.drafts[tpl_id + '_' + page_pos]?.isFetching && (
            <Loader message="loading drafts..." />
          )}
          {!mappingToolInView.drafts[tpl_id + '_' + page_pos]?.isFetching &&
            (mappingToolInView.drafts[tpl_id + '_' + page_pos]?.data || []).map(
              (draft, i) => (
                <div key={i}>
                  <Card className={classes.card}>
                    <CardHeader
                      title={
                        <StyledTooltip
                          title="Edit Draft"
                          onClick={() => this.editDraft(draft, templatePage)}>
                          <Button
                            className={classes.draftBtn}
                            variant="contained"
                            size="small"
                            color="primary">
                            Edit Draft: {draft.draft_name} - Page {1 + page_pos}
                          </Button>
                        </StyledTooltip>
                      }
                      subheader={
                        <div style={{ fontSize: 11 }}>
                          <div>
                            Created By <strong>{draft.creator_id}</strong>{' '}
                            <span className={classes.time}>
                              {formatDate.relativeTime(draft.created_dt)}
                            </span>
                          </div>
                          {draft.last_worked_id && (
                            <span>
                              Updated By <strong>{draft.last_worked_id}</strong>{' '}
                              <span className={classes.time}>
                                {formatDate.relativeTime(draft.last_worked_ts)}
                              </span>
                            </span>
                          )}
                        </div>
                      }
                    />
                  </Card>
                </div>
              )
            )}
        </Collapse>
      </div>
    )
  }
}

MappingTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
  template: PropTypes.object.isRequired
}

MappingTemplatePage.propTypes = {
  classes: PropTypes.object.isRequired,
  templatePage: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  mappingToolInView: stateFromStore.mappingToolInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDrafts_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MappingTemplate))
)
