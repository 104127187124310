import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateParachuteReport_ac } from '../../actions/parachute_report.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import dateUtil from '../../util/dateProcessing';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '50%',
  },
  docType: { fontSize: 11, color: '#ff9100', fontWeight: 500 },
  docId: { fontSize: 11, fontWeight: 400 },
  card: {
    minWidth: 275,
  },
  cardHdrRoot: {
    backgroundColor: '#b2dfdb',
    padding: 8,
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800,
  },
  tableScrollable: {
    minHeight: '220px',
    height: 'calc(100% - 46px)',
    overflowY: 'auto',
  },
  tableHeader: {
    height: 20,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
    },
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
    verticalAlign: 'top',
  },
});

const FilesTableView = ({ docs = [], classes }) => {
  return (
    <>
      <Divider component="hr" />
      <div className={classes.fake_tableScrollable}>
        <Table padding="dense">
          <TableHead padding="dense">
            <TableRow className={classes.tableHeader}>
              <TableCell align="left" width="5%">
                #
              </TableCell>
              <TableCell align="left" width="45%">
                Status
              </TableCell>
              <TableCell align="left" width="50%">
                Parachute Doc
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.map((doc, docIndex) => (
              <TableRow key={docIndex}>
                <TableCell
                  align="left"
                  width="12%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <span style={{ fontSize: 10 }}>{docIndex + 1}</span>
                </TableCell>
                <TableCell
                  align="left"
                  width="38%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <Typography component="span" variant="body2">
                    {doc.state ? doc.state.toUpperCase() : 'Not Imported'}
                  </Typography>
                  <Typography component="span" variant="body2">
                    {doc.ds_id ? `DF Submit Ref# ${doc.ds_id}` : ''}
                  </Typography>
                  <Typography
                    component="span"
                    variant="caption"
                    color="textSecondary">
                    {dateUtil.relativeTime(doc.ts)}
                  </Typography>
                </TableCell>
                <TableCell
                  align="left"
                  width="50%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <div>
                    <span className={classes.docType}>
                      {doc.type.toUpperCase()}
                    </span>
                    <br />
                    <span className={classes.docId}>{doc.id}</span>
                  </div>
                  {(doc.type.length < 3 || doc.type.length > 40) && (
                    <small style={{ color: 'red' }}>
                      Invalid Divider (must be between 3 and 40 chars)
                    </small>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

class ParachuteImportNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloading: false,
    };
  }

  componentDidUpdate(prevProps) {
    let newState = {};
    // const orderData = this.props.parachuteReportInView.records.filter(
    //   (r) => r._id === this.props.selectedOrderId
    // )[0];

    // const prevOrderData = prevProps.parachuteReportInView.records.filter(
    //   (r) => r._id === prevProps.selectedOrderId
    // )[0];

    // if (orderData._id !== prevOrderData._id) {
    //   newState.docs = this.getNewDocs();
    // }

    // if (!prevProps.lookups.ink && this.props.lookups.ink) {
    //   newState.dividerOptions = this.getDividerOptions();
    // }

    if (!_.isEmpty(newState)) {
      this.setState({
        ...newState,
      });
    }
  }

  parseFiles = (files, user, referralId) => {
    // skip download attempted file
    return files
      .filter((file) => !file.downloads?.count)
      .map((file, idx) => ({
        type: file?.type,
        s3bucketName: file?.s3bucketName,
        s3pathAndKey: file?.s3pathAndKey,
        position: idx,
        meta: {
          autoindexRequest: `#autoindex #${this.state.idn} #${file.comment}`,
          assignToTeam: user?.team,
          assignToUser: user?.member,
          modeLabels: ['ALLSCRIPTS', 'R' + referralId.toString()],
          productLabel: user?.product,
          tmLabel: user?.rae || '',
        },
      }));
  };

  downloadReferral_v2 = (includeDone = false) => {
    const { me, open_snack_ac, updateParachuteReport_ac: update } = this.props;
    const { order_info, _id: referralId } =
      this.props.parachuteReportInView.records.filter(
        (r) => r._id === this.props.selectedOrderId
      )[0];
    const { order: referral } = order_info;
    const files = referral.documents.filter((file) => {
      if (includeDone) {
        return file.s3 && file.s3.ok;
      }

      return file.s3 && file.s3.ok && file.state !== 'done';
    });
    const s3bucketName = 'sch.integrations';
    if (files.length === 0) {
      open_snack_ac({
        variant: 'info',
        message: 'No files available to import!',
      });
    }
    this.setState({
      isDownloading: true,
    });

    let now = new moment();

    let payload = {
      assign_to: 'me',
      outputFileBasename: [
        'Merged',
        referralId,
        now.format('YYYYMMDD_hhmmss'),
      ].join('_'),
      s3keys: files.map((f) => {
        return [
          'https://s3.us-west-2.amazonaws.com',
          s3bucketName,
          f.s3.path,
        ].join('/');
      }),
    };

    return window.sch
      .post('/api/utils/merge_pdfs_on_s3', payload)
      .then((results) => {
        /* mergedKey example:
        "https://s3.us-west-2.amazonaws.com/sch.integrations/parachute/32I-KJV8-LU3J-F3/Merged_32I-KJV8-LU3J-F3_20230321_044608.pdf"
        */
        let s3pathAndKey = results.result.mergedKey
          .split(/\//g)
          .slice(-3)
          .join('/');
        const payload_for_ds_create = {
          trm_source: 'PARACHUTE',
          assign_to: 'me',
          idn: 'NON-AUTOINDEX',
          account: 'NA',
          files: [
            {
              meta: {
                trm_source: 'Parachute',
                referralId,
              },
              type: 'application/pdf',
              s3bucketName,
              s3pathAndKey,
              position: 0,
            },
          ],
          qname: 'Q-' + me.user.nm,
          triggerWithMetadata: true,
        };
        return window.sch.post(
          '/api/docsubmission/create_from_s3_copy',
          payload_for_ds_create
        );
      })
      .then((response) => {
        const markAsDownloadParams = {
          id: referralId,
          ds_id: response.doc._id,
          doc_ids: files.map((f) => f.id),
          is_v2: true,
        };
        return window.sch.post(
          `/api/parachute/mark/${referralId}`,
          markAsDownloadParams
        );
      })
      .then((response) => {
        update(response.updatedDoc);
      })
      .catch((error) => {
        console.error(error);
        open_snack_ac({
          variant: 'error',
          message: 'Error Importing Referral',
        });
      })
      .finally(() => {
        open_snack_ac({
          variant: 'info',
          message: 'Indexing job created for your import',
        });
        this.setState({
          isDownloading: false,
        });
      });
  };

  render() {
    const { classes, me, selectedOrderId } = this.props;
    const orderData = this.props.parachuteReportInView.records.filter(
      (r) => r._id === selectedOrderId
    )[0];
    const { patient, order } = orderData.order_info;
    const { documents: docs } = order;

    let nDocsToImport = docs.filter((doc) => doc.state !== 'done').length;
    const cardHdrClasses = {
      root: classes['cardHdrRoot'],
      title: classes['cardHdrContent'],
      subheader: classes['cardHdrSubHdr'],
    };

    return (
      <Card className={classes.card} elevation={0}>
        <CardHeader
          classes={cardHdrClasses}
          title={
            <span style={{ fontSize: 12 }}>
              Order ID: {orderData._id}
              {'  '}
            </span>
          }
          subheader={
            <span style={{ fontSize: 12 }}>
              {(
                patient.last_name +
                ', ' +
                patient.first_name +
                ' ' +
                dateUtil.formatDate(patient.birth_date, 'M-DD-YYYY')
              ).toUpperCase()}
            </span>
          }
          action={
            <IconButton
              style={{ marginTop: 8, marginRight: 8, padding: 8 }}
              onClick={this.props.onCancelImport}>
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <span style={{ fontSize: 11 }}>
                Created On: {dateUtil.formatDate(orderData.created_ts)}{' '}
              </span>
            </Grid>
            <Grid item xs={6}>
              <span style={{ fontSize: 11 }}>
                Last Import Attempt On:{' '}
                {dateUtil.formatDate(orderData.last_import_attempt_ts)}{' '}
              </span>
            </Grid>
          </Grid>
          <FilesTableView docs={docs} classes={classes} />
          <hr />
          <Button
            style={{ marginBottom: 10, marginTop: 10 }}
            variant="contained"
            disabled={this.state.isDownloading || nDocsToImport === 0}
            onClick={this.downloadReferral_v2}>
            Upload for V2 Indexing - Q-{me.user.nm} - Only Docs Not Attempted
          </Button>
          <hr />
          <Button
            style={{ marginBottom: 10, marginTop: 10 }}
            variant="contained"
            disabled={this.state.isDownloading}
            onClick={() => this.downloadReferral_v2(true)}>
            Upload for V2 Indexing - Q-{me.user.nm} - Include Docs Already
            Attempted
          </Button>
          <hr />
          {orderData.import_log && orderData.import_log.length && (
            <div style={{ fontSize: 10 }}>
              <h4>Import Log</h4>
              <ol style={{ margin: 4, padding: 4 }}>
                {orderData.import_log.map((log, k) => (
                  <li key={k}>
                    {log.u_nm} on {log.ts.toString()}
                  </li>
                ))}
              </ol>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

ParachuteImportNew.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  lookups: stateFromStore.lookups,
  parachuteReportInView: stateFromStore.parachuteReportInView,
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      updateParachuteReport_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ParachuteImportNew));
