export const actionTypes = {
  FETCH_SALES_OC_NOTES_SUCCESS: 'FETCH_SALES_OC_NOTES_SUCCESS',
  FETCH_SALES_OC_NOTES_ERROR: 'FETCH_SALES_OC_NOTES_ERROR',
  FETCH_SALES_OC_NOTES_PENDING: 'FETCH_SALES_OC_NOTES_PENDING',

  ADD_SALES_OC_NOTE_SUCCESS: 'ADD_SALES_OC_NOTE_SUCCESS',
  ADD_SALES_OC_NOTE_ERROR: 'ADD_SALES_OC_NOTE_ERROR',
  ADD_SALES_OC_NOTE_PENDING: 'ADD_SALES_OC_NOTE_PENDING',
};

export function fetchSalesOCNotes_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_SALES_OC_NOTES_PENDING });

    return window.sch
      .post('/api/sales/oc_notes/list', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_SALES_OC_NOTES_SUCCESS,
          data: result.notes,
        });

        if (onSuccess) {
          onSuccess(result.results);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_SALES_OC_NOTES_ERROR,
          error,
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function addSalesOCNotes_ac(params, { onSuccess, onError }) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_SALES_OC_NOTE_PENDING });

    return window.sch
      .post('/api/sales/oc_notes/add', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        const created = result.note_rec;

        dispatch({
          type: actionTypes.ADD_SALES_OC_NOTE_SUCCESS,
          data: created,
        });

        if (onSuccess) {
          onSuccess(created);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_SALES_OC_NOTE_ERROR,
          error,
        });

        if (onError) {
          onError(error);
        }
      });
  };
}
