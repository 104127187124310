import { FileBoundActionTypes } from '../actions/filebound_ac'

export const fileBound = (
  state = {
    isFetching: false,
    isFetchingDetails: false,
    isFetchingCompletedList: false,
    isSaving: false,
    pagination: null,
    error: null,
    accountingDocs: null,
    selectedAccountingDoc: null,
    accountingDocDetailsError: null,
    completedDocs: null,
    completedPagination: null,
    completedDocFetchingError: null
  },
  action
) => {
  switch (action.type) {
    case FileBoundActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }

    case FileBoundActionTypes.SET_IS_FETCHING_DETAILS:
      return {
        ...state,
        isFetchingDetails: action.isFetchingDetails
      }

    case FileBoundActionTypes.ACCOUNTING_DOCS_DETAILS_RECEIVED:
      return {
        ...state,
        selectedAccountingDoc: action.doc,
        pagination: null,
        error: null
      }

    case FileBoundActionTypes.ACCOUNTING_DOCS_RECEIVED:
      return {
        ...state,
        accountingDocs: action.accountingDocs,
        pagination: action.pagination,
        selectedAccountingDoc: null,
        error: null,
        accountingDocDetailsError: null

      }

    case FileBoundActionTypes.FAILED_RECEIVING_ACCOUNTING_DOCS:
      return {
        ...state,
        error: action.error
      }

    case FileBoundActionTypes.FAILED_RECEIVING_ACCOUNTING_DOCS_DETAILS:
      return {
        ...state,
        accountingDocDetailsError: action.error
      }

    case FileBoundActionTypes.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving
      }

    case FileBoundActionTypes.COMPLETED_ACCOUNTING_DOCS_RECEIVED:
      return {
        ...state,
        completedDocs: action.completedDocs,
        completedPagination: action.completedPagination,
        completedDocFetchingError: null,

      }

    case FileBoundActionTypes.FAILED_RECEIVING_COMPLETED_DOCS:
      return {
        ...state,
        completedDocFetchingError: action.error
      }

    case FileBoundActionTypes.SET_IS_FETCHING_COMPLETED_LIST:
      return {
        ...state,
        isFetchingCompletedList: action.isFetching
      }

    case FileBoundActionTypes.EDIT_COMPLETED_DOC:
      return {
        ...state,
        selectedAccountingDoc: action.payload,
      }

    default:
      return {
        ...state
      }
  }
}
