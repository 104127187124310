export const AcitivityLogsActionTypes = {
  RECEIVED_ACTIVITY_LOG: 'RECEIVED_ACTIVITY_LOG',
  FAILED_RECEIVED_ACTIVITY_LOG: 'FAILED_RECEIVED_ACTIVITY_LOG',
  SET_IS_FETCHING: 'SET_IS_FETCHING'
}
export function fetchActivityLogs_ac(params) {
  return dispatch => {
    dispatch(fetching(true))
    return window.sch
      .post('/api/alist/user', { ...params })
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }
        dispatch({
          type: AcitivityLogsActionTypes.RECEIVED_ACTIVITY_LOG,
          records: result.rows,
          pagination: result.pagination,
          tags: result.tagCounts
        })
      })
      .catch(error => {
        dispatch({
          type: AcitivityLogsActionTypes.FAILED_RECEIVED_ACTIVITY_LOG,
          error
        })
      })
      .finally(() => {
        dispatch(fetching(false))
      })
  }
}

export const fetching = isFetching => ({
  type: AcitivityLogsActionTypes.SET_IS_FETCHING,
  isFetching
})

export function resetReadyToIndex_ac() {
  return dispatch => {
    dispatch({
      type: AcitivityLogsActionTypes.RESET_READY_TO_INDEX
    })
  }
}
