import { FormHelperText, Switch } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import React from 'react'

const MAP_OPTION_SELECTION = {
  checkboxOpt: 'checkbox',
  radioOpt: 'radio'
}

export const FieldLevelForms = ({ handleChange, newField, errors, fields }) => {
  return (
    <FormGroup>
      <TextField
        autoFocus
        margin="dense"
        id="nm"
        name="nm"
        onChange={handleChange}
        label="NM"
        required
        error={errors.nm}
        helperText={errors.nm ? 'Please enter nm' : ''}
        value={newField.nm}
        type="text"
        fullWidth
      />
      <TextField
        margin="dense"
        id="label"
        name="label"
        required
        error={errors.label}
        helperText={errors.label ? 'Please enter label' : ''}
        onChange={handleChange}
        label="Label"
        value={newField.label}
        type="text"
        fullWidth
      />
      {newField.type && (
        <FormControl style={{ marginTop: '10px' }} error={errors.type}>
          <InputLabel>Select Type</InputLabel>
          <Select
            id="type"
            name="type"
            value={newField.type}
            onChange={handleChange}
            fullWidth>
            <MenuItem value="null">(Select Type)</MenuItem>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="checkbox">Checkbox</MenuItem>
            <MenuItem value="radio">Radio</MenuItem>
            <MenuItem value="checkboxOpt">Checkbox Option</MenuItem>
            <MenuItem value="radioOpt">Radio Option</MenuItem>
          </Select>
          <FormHelperText>
            {errors.type ? 'Please select type' : ''}
          </FormHelperText>
        </FormControl>
      )}

      {['checkboxOpt', 'radioOpt'].includes(newField.type) && (
        <FormControl style={{ marginTop: '10px' }} error={errors.groupNM}>
          <InputLabel>Select Group</InputLabel>
          <Select
            id="groupNM"
            name="groupNM"
            value={newField.groupNM}
            onChange={handleChange}
            fullWidth>
            <MenuItem value="null">(Select Group)</MenuItem>
            {fields
              .filter(
                field => field.type === MAP_OPTION_SELECTION[newField.type]
              )
              .map((option, idx) => (
                <MenuItem key={idx} value={option.nm}>
                  {option.nm}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            {errors.groupNM ? 'Please select group nm' : ''}
          </FormHelperText>
        </FormControl>
      )}

      {!['checkbox', 'radio'].includes(newField.type) && (
        <>
          {(newField.type === 'date' ||
            newField.type === 'number' ||
            newField.type === 'text') && (
            <>
              <TextField
                margin="dense"
                id="fontSize"
                name="fontSize"
                onChange={handleChange}
                label="Font Size"
                required
                error={errors.fontSize}
                value={newField.fontSize}
                helperText={
                  errors.fontSize ? 'Select font size from 8 -14' : ''
                }
                type="number"
                fullWidth
              />
              <FormControlLabel
                onChange={handleChange}
                control={
                  <Switch
                    name="fullWidth"
                    checked={newField.fullWidth}
                    onChange={handleChange}
                  />
                }
                label="Full Width"
              />
              <FormControlLabel
                onChange={handleChange}
                control={
                  <Switch
                    name="multiline"
                    checked={newField.multiline}
                    onChange={handleChange}
                  />
                }
                label="Multi Line"
              />
              {newField.multiline && (
                <TextField
                  margin="dense"
                  id="maxLines"
                  name="maxLines"
                  onChange={handleChange}
                  label="Max Lines"
                  value={newField.maxLines}
                  type="number"
                  fullWidth
                />
              )}
              <TextField
                margin="dense"
                id="max-width"
                name="maxWidth"
                onChange={handleChange}
                label="Max Width"
                value={newField.maxWidth}
                type="number"
                fullWidth
              />
              <TextField
                margin="dense"
                id="maxCharsPerLine"
                name="maxCharsPerLine"
                onChange={handleChange}
                label="Max Characters Per Line"
                value={newField.maxCharsPerLine}
                type="number"
                fullWidth
              />
            </>
          )}

          <TextField
            disabled
            margin="dense"
            id="x"
            label="X Coordinate"
            value={newField.coords.x}
            type="text"
            fullWidth
          />
          <TextField
            disabled
            margin="dense"
            id="y"
            label="Y Coordinate"
            value={newField.coords.y}
            type="text"
            fullWidth
          />
          <TextField
            disabled
            margin="dense"
            id="width"
            label="Width"
            value={newField.coords.w}
            type="text"
            fullWidth
          />
          <TextField
            disabled
            margin="dense"
            id="height"
            label="Height"
            value={newField.coords.h}
            type="text"
            fullWidth
          />
        </>
      )}
    </FormGroup>
  )
}
