import { formBackgroundActionTypes } from '../../actions/forms';

const {
  FETCH_FORM_BACKGROUNDS,
  FETCH_FORM_BACKGROUNDS_SUCCESSFUL,
  FETCH_FORM_BACKGROUNDS_FAILED,

  CREATE_FORM_BACKGROUND,
  CREATE_FORM_BACKGROUND_SUCCESSFUL,
  CREATE_FORM_BACKGROUND_FAILED,

  UPDATE_FORM_BACKGROUND,
  UPDATE_FORM_BACKGROUND_SUCCESSFUL,
  UPDATE_FORM_BACKGROUND_FAILED,

  REMOVE_FORM_BACKGROUND,
  REMOVE_FORM_BACKGROUND_SUCCESSFUL,
  REMOVE_FORM_BACKGROUND_FAILED,
} = formBackgroundActionTypes;

export const initialState = {
  list: {
    data: [],
    meta: {},
    isFetching: false,
    error: null,
  },
  detail: {
    data: null,
    isFetching: false,
    error: null,
  },
  isCreatingFormBackground: false,
  isUpdatingFormBackground: false,
  isRemovingFormBackground: false,
};

export function formBackgrounds(state = initialState, action) {
  switch (action.type) {
    // FETCH_FORM_BACKGROUNDS
    case FETCH_FORM_BACKGROUNDS:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          isFetching: true,
        },
      });
    case FETCH_FORM_BACKGROUNDS_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: action.data?.results || [],
          meta: action.data?.meta || {},
          error: null,
        },
        detail: {
          ...initialState.detail,
        },
      });
    case FETCH_FORM_BACKGROUNDS_FAILED:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: [],
          meta: {},
          error: action.error,
        },
      });

    // CREATE_FORM_BACKGROUND
    case CREATE_FORM_BACKGROUND:
      return Object.assign({}, state, {
        isCreatingFormBackground: true,
      });
    case CREATE_FORM_BACKGROUND_SUCCESSFUL:
      return Object.assign({}, state, {
        // list: {
        //   ...state.list,
        //   data: [...state.list.data, action.data],
        // },
        isCreatingFormBackground: false,
      });
    case CREATE_FORM_BACKGROUND_FAILED:
      return Object.assign({}, state, {
        isCreatingFormBackground: false,
      });

    // UPDATE_FORM_BACKGROUND
    case UPDATE_FORM_BACKGROUND:
      return Object.assign({}, state, {
        isUpdatingFormBackground: true,
      });
    case UPDATE_FORM_BACKGROUND_SUCCESSFUL:
      return Object.assign({}, state, {
        // list: {
        //   ...state.list,
        //   data: state.list.data.map((item, index) =>
        //     item._id === action.updated_id
        //       ? {
        //           ...item,
        //           ...(action.data || {}),
        //         }
        //       : item
        //   ),
        // },
        isUpdatingFormBackground: false,
      });

    case UPDATE_FORM_BACKGROUND_FAILED:
      return Object.assign({}, state, {
        isUpdatingFormBackground: false,
      });

    // REMOVE_FORM_BACKGROUND
    case REMOVE_FORM_BACKGROUND:
      return Object.assign({}, state, {
        isRemovingFormBackground: true,
      });
    case REMOVE_FORM_BACKGROUND_SUCCESSFUL:
      return Object.assign({}, state, {
        // list: {
        //   ...state.list,
        //   data: state.list.data.filter(
        //     (item) => item._id !== action.removed_id
        //   ),
        // },
        detail: {
          ...state.detail,
          data: null,
        },
        isRemovingFormBackground: false,
      });
    case REMOVE_FORM_BACKGROUND_FAILED:
      return Object.assign({}, state, {
        isRemovingFormBackground: false,
      });

    default:
      return state;
  }
}
