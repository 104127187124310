import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchAccountPAs_ac } from '../../actions/account_in_view.ac.js'
import { provideIdnDoc_ac } from '../../actions/idn.ac.js'
import { setTitle_ac } from '../../actions/me.ac.js'
import {
  fetchCcsPkg_ac,
  fetchSarDoc_ac,
  fetchUBList_ac
} from '../../actions/ub.ac.js'
import { AccountPAs } from '../Dfa/AccountPAs'
import { DfaAppBar } from '../Dfa/DfaAppBar'
import UBCard from '../UB/UBCard'
import CCSPkg from './CCSPkg'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  sarInfo: {
    fontWeight: 700,
    fontSize: 12
  },
  sarDateInfo: {
    paddingLeft: 6,
    paddingRight: 6,
    color: '#455A64',
    fontWeight: 600,
    fontSize: 12
  },
  fullHeight: {
    height: '100%'
  }
})

//const PATH_TO_PA_DOCS =  process.env.REACT_APP_WOS_API_URL + '/public';
const PATH_TO_PA_DOCS =
  process.env.REACT_APP_WOS_API_URL + '/api/unbilled/files'

const STATE_OPTS = {
  ccs: [
    'AUTHORIZED',
    'PENDING',
    '278 PROCESSED',
    'DENIED',
    'DELETED - CANCELLED - NULL',
    'NO SAR LISTED',
    'CENTER SAR HCPC',
    'NONE DATE VALID',
    'NONE HCPC VALID'
  ],
  mcal: [
    'APPROVED',
    'MODIFIED',
    'IN REVIEW',
    'DEFERRED',
    'DENIED',
    'REJECTED',
    'NONE DATE VALID',
    'NONE HCPC VALID'
  ]
}
const ACTION_OPTS = {
  AUTHORIZED: ['not_worked', 'logged', 'not_logged', 'logged_with_issue'],
  APPROVERD: ['not_worked', 'logged', 'not_logged', 'logged_with_issue'],
  MODIFIED: ['not_worked', 'logged', 'not_logged', 'logged_with_issue'],
  DENIED: ['not_worked', 'rebill', 'resubmit', 'pickup', 'bill_private'],
  DEFERRED: ['not_worked', 'rebill', 'resubmit', 'request_docs']
}
const ACTION_LBL_REF = {
  logged: 'LOGGED',
  not_logged: 'NOT LOGGED',
  not_worked: 'NOT WORKED',
  logged_with_issue: 'LOGGED WITH ISSUE',
  rebill: 'Rebill Claim to Secondary Payer',
  resubmit: 'Resubmit with Required Docs',
  request_docs: 'Request Additional Docs',
  pickup: 'Request PickUp',
  bill_private: 'Bill Private Pay'
}

class UBFilters extends React.Component {
  render() {
    const { classes } = this.props
    const pa_state_options = STATE_OPTS[this.props.selectedFilters.payer_group]
    const action_options =
      ACTION_OPTS[this.props.selectedFilters.pa_state] ||
      ACTION_OPTS['AUTHORIZED']

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5>Payer Group</h5>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="Payer Group"
            className={classes.group}
            value={this.props.selectedFilters.payer_group}
            onChange={this.props.onPayerGroupFilterChange}>
            <FormControlLabel value="ccs" label="CCS" control={<Radio />} />
            <FormControlLabel
              value="mcal"
              label="Medi-Cal"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>

        <h5>PA Status</h5>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="SAR Status"
            name="activityStateRadioGroup"
            className={classes.group}
            value={this.props.selectedFilters.pa_state}
            onChange={this.props.onStateFiltersChange}>
            {pa_state_options.map(s => (
              <FormControlLabel value={s} label={s} control={<Radio />} />
            ))}
          </RadioGroup>
        </FormControl>

        <h5>Action Status</h5>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="Action Status"
            className={classes.group}
            value={this.props.selectedFilters.action_state}
            onChange={this.props.onActionFilterChange}>
            {action_options.map((s, sidx) => (
              <FormControlLabel
                key={sidx}
                value={s}
                label={ACTION_LBL_REF[s]}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    )
  }
}

class UBLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFilters: {
        payer_group:
          _.get(this.props, 'match.params.payer_group', false) || 'ccs',
        pa_state:
          _.get(this.props, 'match.params.pa_state', false) || 'AUTHORIZED',
        action_state:
          _.get(this.props, 'match.params.action_state', false) || 'not_worked'
      },
      selectedAuthNumber: null,
      sarSearchStr: '',
      selectedMiddlePanel: 'ccs_pkg'
      //currentPage: parseInt(_.get(this.props,'match.params.page','0') ,10)
    }
    this.onLoadSar = this.onLoadSar.bind(this)
    this.actionFilterChanged = this.actionFilterChanged.bind(this)
    this.stateFiltersChanged = this.stateFiltersChanged.bind(this)
    this.payerGroupFilterChange = this.payerGroupFilterChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.onSearchBySar = this.onSearchBySar.bind(this)
    this.onSarSearchFieldChange = this.onSarSearchFieldChange.bind(this)
    this.onSarSearchClear = this.onSarSearchClear.bind(this)
    this.onCardHdrClicked = this.onCardHdrClicked.bind(this)
    this.handleSelectMiddlePanel = this.handleSelectMiddlePanel.bind(this)
  }

  componentDidMount() {
    if (this.props.match.params.pa_state) {
      return
    }

    return this.props.setTitle_ac('CCS-MCAL PAs')
  }

  componentDidUpdate(prevProps, prevState) {
    const { ubList, match } = this.props
    if (ubList.isFetching) return

    if (!match.params.pa_state) {
      let path = `/unbilled/ccs/filters/AUTHORIZED/not_worked/0`
      return this.props.changePage(path)
    }

    let params = {
      payer_group: this.props.match.params.payer_group,
      pa_state: this.props.match.params.pa_state,
      action_state: this.props.match.params.action_state,
      page: parseInt(this.props.match.params.page, 10)
    }

    let prev_params = {
      payer_group: prevProps.match.params.payer_group,
      pa_state: prevProps.match.params.pa_state,
      action_state: prevProps.match.params.action_state,
      page: parseInt(prevProps.match.params.page, 10)
    }

    let do_fetch = false
    for (let k in params) {
      if (params[k] !== prev_params[k]) {
        do_fetch = true
      }
    }
    if (ubList.records === null) do_fetch = true

    return do_fetch ? this.props.fetchUBList_ac(params) : false
  }

  fetch() {
    if (this.props.match.params.pa_state) {
      this.props.fetchUBList_ac({
        by_sar_number: this.state.sarSearchStr.trim(),
        payer_group: this.props.match.params.payer_group,
        pa_state: this.props.match.params.action_state.split(/,/g),
        action_state: this.props.match.params.action_state,
        page: this.props.match.params.page
      })

      return
    }

    if (this.props.match.params.accountNumber) {
      let sf = _.clone({ ...this.state.selectedFilters })
      sf['account'] = parseInt(this.props.match.params.accountNumber, 10)
      this.props.fetchUBList_ac(sf)
    }

    return
  }

  onSarSearchFieldChange(e) {
    this.setState({ sarSearchStr: e.target.value })
  }
  onSarSearchClear(e) {
    this.setState({ sarSearchStr: '' })
  }

  onSearchBySar() {
    this.fetch()
  }

  handleSelectMiddlePanel(e, tab) {
    this.setState({ selectedMiddlePanel: tab })
  }

  stateFiltersChanged(e, v) {
    let _selectedFilters = {
      payer_group: this.state.selectedFilters.payer_group.toString(),
      pa_state: v,
      action_state: this.state.selectedFilters.action_state.toString()
    }
    this.setState({ selectedFilters: _selectedFilters })
    let path = `/unbilled/${_selectedFilters.payer_group}/filters/${v}/${
      _selectedFilters.action_state
    }/0`
    this.props.changePage(path)
  }

  actionFilterChanged(e, v) {
    let _selectedFilters = {
      payer_group: this.state.selectedFilters.payer_group.toString(),
      action_state: v,
      pa_state: this.state.selectedFilters.pa_state.toString()
    }
    this.setState({ selectedFilters: _selectedFilters })
    let path = `/unbilled/${_selectedFilters.payer_group}/filters/${
      _selectedFilters.pa_state
    }/${v}/0`
    this.props.changePage(path)
  }

  payerGroupFilterChange(e, v) {
    let _selectedFilters = {
      payer_group: v,
      action_state: this.state.selectedFilters.action_state.toString(),
      pa_state: this.state.selectedFilters.pa_state.toString()
    }
    this.setState({ selectedFilters: _selectedFilters })
    let path = `/unbilled/${v}/filters/${_selectedFilters.pa_state}/${
      _selectedFilters.action_state
    }/0`
    this.props.changePage(path)
  }

  handlePageChange(e) {
    let path = `/unbilled/${this.state.selectedFilters.payer_group}/filters/${
      this.state.selectedFilters.pa_state
    }/${this.state.selectedFilters.action_state}/${e.target.value - 1}`
    this.props.changePage(path)
    return
  }

  onLoadSar(sarNum) {
    this.setState({ selectedAuthNumber: sarNum })
    this.props.fetchSarDoc_ac(sarNum, this.state.selectedFilters.payer_group)
    return
  }

  onCardHdrClicked(ubDoc) {
    this.props.fetchCcsPkg_ac(ubDoc._id.a)
    this.props.provideIdnDoc_ac(ubDoc.idn)
  }

  render() {
    const { classes, ubList } = this.props

    if (!this.props.match.params.pa_state) {
      return
    }

    let title = [
      'Unbilled - PA Issues',
      this.state.selectedFilters.payer_group.toUpperCase(),
      this.state.selectedFilters.pa_state.toUpperCase(),
      this.state.selectedFilters.action_state.toUpperCase()
    ].join(' - ')

    let pdfUrl
    if (this.state.selectedFilters.payer_group === 'ccs') {
      pdfUrl = this.state.selectedAuthNumber
        ? PATH_TO_PA_DOCS +
          '/ccs_sars/' +
          this.state.selectedAuthNumber +
          '.pdf'
        : false
    } else {
      pdfUrl = this.state.selectedAuthNumber
        ? PATH_TO_PA_DOCS +
          '/mcal_tars/tar_' +
          this.state.selectedAuthNumber +
          '.html'
        : false
    }

    const { sarDoc, ccsPkg } = ubList

    let pageStatement = false
    let pageSelector = ''
    if (ubList.pagination) {
      const pg = ubList.pagination
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Workunits',
        'Page ' + (pg.currentPageNum + 1) + ' of ' + pg.totalPagesAvailable
      ].join(' - ')

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                onChange={this.handlePageChange}
                displayEmpty
                value={pg.currentPageNum + 1}
                name="pageselector"
                className={classes.selectEmpty}>
                {_.range(1, pg.totalPagesAvailable + 1).map(pgNum => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        )
      }
    }

    const formGroupRow = { root: classes.formGroupRoot }
    let marginLessContent = [
      classes.content,
      classes.pagination,
      classes.fullHeight
    ].join(' ')

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={[classes.root, classes.scrollArea].join(' ')}>
            <DfaAppBar
              title={title}
              filterComponent={
                <UBFilters
                  classes={classes}
                  selectedFilters={this.state.selectedFilters}
                  onPayerGroupFilterChange={this.payerGroupFilterChange}
                  onActionFilterChange={this.actionFilterChanged}
                  onStateFiltersChange={this.stateFiltersChanged}
                />
              }
            />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Grid container spacing={8} className={classes.fullHeight}>
                {/* BEGIN TODO CARDS LIST */}
                <Grid item xs={12} sm={3} className={classes.fullHeight}>
                  {this.props.ubList.records && (
                    <main className={marginLessContent}>
                      {pageStatement && (
                        <div className={classes.pagination}>
                          <Grid container spacing={8}>
                            {/* BEGIN TODO CARDS LIST */}
                            <Grid item xs={12} sm={4}>
                              <Typography
                                className={classes.pageStatement}
                                color="textSecondary">
                                {pageStatement}
                              </Typography>
                              {pageSelector}
                            </Grid>
                            <Grid item xs={12} sm={8}>
                              <FormControl
                                required
                                component="fieldset"
                                className={classes.formControl}>
                                <Typography
                                  className={classes.pageStatement}
                                  color="textSecondary">
                                  Search by Sar#:
                                </Typography>
                                <FormGroup
                                  classes={formGroupRow}
                                  style={{ marginTop: 0, marginBottom: 4 }}>
                                  <TextField
                                    onChange={this.onSarSearchFieldChange}
                                    value={this.state.sarSearchStr}
                                    style={{ margin: '8px 8px 8px 0' }}
                                    placeholder="Search by SAR#:"
                                    fullWidth
                                    margin="normal"
                                  />

                                  <Button
                                    onClick={this.onSearchBySar}
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}>
                                    Search
                                  </Button>
                                  <Button
                                    onClick={this.onSarSearchClear}
                                    variant="outlined"
                                    color="secondary"
                                    className={classes.button}>
                                    Clear Search
                                  </Button>
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                      )}

                      <div className={classes.scrollArea}>
                        <div className={classes.root}>
                          {ubList.records.map((ubDoc, idx) => (
                            <UBCard
                              payerGroup={
                                this.state.selectedFilters.payer_group
                              }
                              posInSet={idx}
                              sarClicked={this.onLoadSar}
                              onCardHdrClicked={this.onCardHdrClicked}
                              key={idx}
                              ubDoc={ubDoc}
                            />
                          ))}
                        </div>
                      </div>
                    </main>
                  )}
                </Grid>
                {/* END TODO CARDS LIST */}

                <Grid item xs={12} sm={4} className={classes.fullHeight}>
                  <main className={marginLessContent}>
                    <div>
                      <Tabs
                        value={this.state.selectedMiddlePanel}
                        onChange={this.handleSelectMiddlePanel}
                        indicatorColor="secondary"
                        textColor="secondary">
                        <Tab
                          classes={classes.tab}
                          value="ccs_pkg"
                          label="CCS SARS"
                        />
                        <Tab
                          classes={classes.tab}
                          value="hdms_pas"
                          label="HDMS PAs"
                        />
                      </Tabs>

                      {this.state.selectedMiddlePanel === 'ccs_pkg' &&
                        (ccsPkg ? (
                          <div className={classes.pagination}>
                            <Typography
                              className={classes.pageStatement}
                              color="textSecondary">
                              Viewing CCS Details for Account: {ccsPkg.account}{' '}
                              - Policy {ccsPkg.policy_number} - CCS CIN{' '}
                              {ccsPkg.cin}
                            </Typography>
                            <br />
                            <Typography
                              className={classes.pageStatement}
                              color="textSecondary">
                              Last scraped:{' '}
                              {moment(ccsPkg.last_checked).format(
                                'MM-DD-YYYY hh:mma'
                              )}
                              - {ccsPkg.sars.length} SARS scraped
                            </Typography>
                          </div>
                        ) : (
                          <div className={classes.pagination}>
                            <Typography
                              className={classes.pageStatement}
                              color="textSecondary">
                              Click Account# from the list to load CCS Details
                            </Typography>
                          </div>
                        ))}

                      {this.state.selectedMiddlePanel === 'ccs_pkg' &&
                        ccsPkg && (
                          <CCSPkg
                            pkg={ccsPkg}
                            classes={classes}
                            sarClicked={this.onLoadSar}
                          />
                        )}
                    </div>
                    <div className={classes.scrollArea}>
                      {this.state.selectedMiddlePanel === 'hdms_pas' &&
                        this.props.idnInView.idnDoc && (
                          <AccountPAs hidePopulateBtn={true} />
                        )}
                    </div>
                  </main>
                </Grid>

                <Grid item xs={12} sm={5} className={classes.fullHeight}>
                  <main className={marginLessContent}>
                    <div>
                      <div className={classes.pagination}>
                        {sarDoc ? (
                          <Typography
                            className={classes.pageStatement}
                            color="textSecondary">
                            Viewing SAR# {sarDoc._id} - {sarDoc.lastName},{' '}
                            {sarDoc.firstName}
                          </Typography>
                        ) : (
                          <Typography
                            className={classes.pageStatement}
                            color="textSecondary">
                            Click on [SAR Button] to View SAR PDF
                          </Typography>
                        )}
                      </div>
                      {sarDoc && (
                        <div>
                          {sarDoc._id === this.state.selectedAuthNumber && (
                            <p style={{ fontSize: 12 }}>
                              {_.get(sarDoc, 'denial_notes', []).join(' - ')}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className={classes.scrollArea}>
                      {sarDoc && pdfUrl && (
                        <iframe
                          title="PDF VIEWER"
                          src={pdfUrl + '#zoom=96'}
                          marginWidth="0"
                          style={{ width: '99%', height: '94%' }}
                        />
                      )}
                    </div>
                  </main>
                </Grid>
              </Grid>
            </main>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

UBLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  accountPAsInView: stateFromStore.accountPAsInView,
  idnInView: stateFromStore.idnInView,
  ubList: stateFromStore.ubList
})

const mapDispatchToProps = (dispatch, propsOfUBLanding) =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchUBList_ac,
      fetchSarDoc_ac,
      fetchCcsPkg_ac,
      provideIdnDoc_ac,
      fetchAccountPAs_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UBLanding))
)
