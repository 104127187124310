import { actionTypes } from '../actions/pp_registrations.ac'

const initialState = {
  isFetchingReport: false,
  report: null,
  error: null
}

export const ppRegistrations = (state = { ...initialState }, action) => {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_PP_REGISTRATIONS:
      return {
        ...state,
        isFetchingReport: action.isFetching
      }

    case actionTypes.RECEIVED_PP_REGISTRATIONS:
      return {
        ...state,
        report: {
          records: action.report.records.map(rec => formatRecord(rec)),
          pagination: action.report.pagination
        },
        error: null
      }

    case actionTypes.FAILED_PP_REGISTRATIONS:
      return {
        ...state,
        error: action.error,
        report: null
      }

    default:
      return state
  }
}

const formatRecord = record => {
  if (!record) return record
  if (!record.all_fields || record.all_fields.length < 1) {
    return {
      fieldsMap: {},
      ...record
    }
  }

  try {
    let fields = record.all_fields.reduce((acc, curr) => (
      Object.assign(
        acc,
        { [curr.param_name]: curr.val }
      )
    ), {})

    return {
      fieldsMap: { ...fields },
      ...record
    }
  } catch (err) {
    console.log(err)
    return {
      fieldsMap: {},
      ...record
    }
  }
}
