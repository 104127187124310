import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { commentDocRequest_ac } from '../../actions/doc_requests.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  payerName: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 8,
    color: '#65502f',
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13),
  },
  formControl: {
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row',
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8,
  },
  paNumber: {
    fontWeight: 600,
  },
  notLogged: {
    color: '#757575',
  },
  byTabKlass: {
    minWidth: 16,
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    marginLeft: 2,
    color: '#244e91',
    fontSize: 11,
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0',
  },
  memoPara: {
    color: '#000',
    margin: '4px 4px 4px 0px',
    padding: 6,
    fontSize: 12,
    marginTop: 0,
    borderRadius: 4,
    backgroundColor: 'rgba(233, 236, 241, 0.49)',
  },
});

class DocRequestCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      inReAttempt: false,
      modeVal: '',
      commentEntry: '',
    };
    this.getDownloadUrl = this.getDownloadUrl.bind(this);
    this.onTransmitAttempt = this.onTransmitAttempt.bind(this);
    this.onMarkAsManuallyTransmitted =
      this.onMarkAsManuallyTransmitted.bind(this);
    this.onMarkAsVoid = this.onMarkAsVoid.bind(this);
    this.onMarkAsRetired = this.onMarkAsRetired.bind(this);
    this.onFaxNumberChange = this.onFaxNumberChange.bind(this);
    this.onUpdateFaxNumber = this.onUpdateFaxNumber.bind(this);
    this.toggleReAttempt = this.toggleReAttempt.bind(this);
    this.onCommentSubmit = this.onCommentSubmit.bind(this);
    this.onComment = this.onComment.bind(this);
  }

  toggleReAttempt() {
    this.setState({
      inReAttempt: !this.state.inReAttempt,
      modeVal: this.props.oDoc.mode.val,
    });
  }

  onFaxNumberChange(e) {
    this.setState({ modeVal: e.target.value });
  }

  onUpdateFaxNumber() {
    this.props.onUpdateFaxNumber(
      this.props.oDoc._id,
      this.props.oDoc.mode.kind,
      this.state.modeVal
    );
  }

  getDownloadUrl(s3key) {
    if (s3key === null) return;
    let target_url = `/api/s3/object/getsignedurl`;
    return window.sch
      .post(target_url, { s3key })
      .then((pkg) => {
        let download_url = pkg.signedUrl;
        window.open(download_url, pkg.basName);
      })
      .catch((error) => {
        console.error('error:', error);
      });
  }

  onTransmitAttempt = (oDoc_id, opts) => () => {
    return this.props.onTransmitAttempt(oDoc_id, opts);
  };
  onComment(e) {
    this.setState({ commentEntry: e.target.value });
  }
  onCommentSubmit() {
    let txt = this.state.commentEntry.toString().trim();
    if (!txt) return;
    this.props.commentDocRequest_ac(this.props.oDoc._id, txt);
    this.setState({ commentEntry: '' });
  }

  onMarkAsManuallyTransmitted() {
    return this.props.onMarkAs(this.props.oDoc._id, 'sent_manually');
  }
  onMarkAsVoid() {
    return this.props.onMarkAs(this.props.oDoc._id, 'void');
  }
  onMarkAsRetired() {
    return this.props.onMarkAs(this.props.oDoc._id, 'retired');
  }

  processAppt(appt) {
    if (appt) {
      appt.exists = true;
      let appt_moment = moment(appt.appt_date_mm_dd_yyyy, 'MM-DD-YYYY');
      appt.transmit_after = new moment(appt_moment).add(-4, 'days');

      appt.allow_schedule = false;
      let today = moment();
      let days_diff = appt.transmit_after.diff(today, 'days');
      if (days_diff >= -10) {
        appt.allow_schedule = true;
        appt.msg = `SCHEDULE FAX TO GO OUT ON ${appt.transmit_after.format(
          'MM-DD-YYYY'
        )}`;
      }
      return appt;
    }
    return { exists: false, allow_schedule: false };
  }

  render() {
    const { classes, oDoc, match } = this.props;
    const allowTransmissionAttempt =
      oDoc.trm.state === 'not_attempted' &&
      false === this.props.transmitInProgress &&
      (oDoc.mode.kind === 'fax' || oDoc.mode.kind === 'email');

    const has_concord_send = true; //(me.user.roles.indexOf("concord-send")>-1);
    const disable_btn = !this.props.lookups.isGapiValid || !has_concord_send;

    let appt = this.processAppt(_.get(oDoc, 'extras.appt', false));

    let pdfsNotvailable = false;
    if (oDoc.trm && oDoc.trm.ts) {
      pdfsNotvailable = moment(oDoc.trm.ts).isBefore(new Date(2022, 10, 1));
    }

    return (
      <ExpansionPanel defaultExpanded={this.props.defaultExpanded}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.panelHdrTxt}>
            {'#' + oDoc._id + '. ' + oDoc.u_nm} -{' '}
            <span style={{ color: 'blue' }}>{oDoc.idn}</span> - {oDoc.trm.state}{' '}
            {moment(oDoc.trm.ts).format('M-D-YY hh:mma')}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="column" justify="flex-start" wrap="nowrap">
            {match.url.search(/\/dfa\/idn/) === -1 && (
              <Grid item className={classes.branchGrid}>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={
                    '/' +
                    this.props.match.params.dfa_landing_kind +
                    '/idn/' +
                    oDoc.idn.replace(/ /g, '_') +
                    '/tabs/doc_requests'
                  }>
                  <Typography style={{ color: 'blue' }}>
                    View Account Page: {oDoc.idn}
                  </Typography>
                </Link>
              </Grid>
            )}
            <Grid item className={classes.branchGrid}>
              <Grid container direction="row" justify="flex-start" spacing={8}>
                <Grid item sm={4}>
                  <label className={classes.lbl}>Recipient Type</label>
                  <Typography className={classes.payerName}>
                    {oDoc.recipient.kind.toString().toUpperCase() +
                      ' ' +
                      oDoc.recipient.otherType.toString().toUpperCase()}
                  </Typography>
                  <Typography className={classes.payerName}>
                    Created: {moment(oDoc.ts).format('MM-DD-YYYY hh:mma')}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <label className={classes.lbl}>Transmission Mode</label>
                  <Typography className={classes.payerName}>
                    {oDoc.mode.kind.toString().toUpperCase() +
                      ':' +
                      (oDoc.mode.val
                        ? oDoc.mode.val.toString().toUpperCase()
                        : '')}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <label className={classes.lbl}>Doc Types Requested</label>
                  <Typography className={classes.payerName}>
                    {oDoc.docs.join(', ').toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl}>Internal Memo</label>
              <p className={classes.memoPara}>{oDoc.msg}</p>
            </Grid>
            {oDoc.mode.kind === 'email' && oDoc.tpl_content && (
              <Grid item className={classes.branchGrid}>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.panelHdrTxt}>
                      View Email Content
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <pre style={{ fontSize: 11, color: '#606c8b' }}>
                      {oDoc.tpl_content.msg_body}
                    </pre>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </Grid>
            )}

            {oDoc.patient_notification && (
              <Grid item className={classes.branchGrid}>
                <label className={classes.lbl}>
                  Notification to Patient:{' '}
                  {!oDoc.patient_notification.processed ? (
                    'Pending'
                  ) : oDoc.patient_notification.success ? (
                    'Sent'
                  ) : (
                    <strong style={{ color: 'red' }}>
                      Failed: {oDoc.patient_notification.result.Message}
                    </strong>
                  )}
                </label>
                {oDoc.patient_notification.emailTxt && (
                  <p className={classes.memoPara}>
                    {oDoc.patient_notification.emailTxt}
                  </p>
                )}
                {oDoc.patient_notification.smsTxt && (
                  <p className={classes.memoPara}>
                    {oDoc.patient_notification.smsTxt}
                  </p>
                )}
              </Grid>
            )}

            <Grid item className={classes.branchGrid}>
              {pdfsNotvailable ? (
                <label className={classes.lbl}>
                  Outbound Documents created Prior to Nov 11, 2022 are not
                  available for download. If SENT success, the documents would
                  have made it to recipient.
                </label>
              ) : (
                <label className={classes.lbl}>
                  Outbound Documents - Click to View
                </label>
              )}
              {pdfsNotvailable === false &&
                oDoc.s3keys.map((s, s3k) => (
                  <div key={s3k} style={{ marginRight: 12, paddingBottom: 6 }}>
                    <Button
                      variant="outlined"
                      onClick={() => this.getDownloadUrl(s)}>
                      {s
                        ? s
                            .split(/\//g)
                            .pop()
                            .replace(oDoc.hash + '.', '')
                        : 'Failed Upload'}
                    </Button>
                  </div>
                ))}
            </Grid>

            {appt.exists && (
              <Grid item className={classes.branchGrid}>
                <label className={classes.lbl}>
                  Appointment Details Found:
                </label>
                <div
                  style={{
                    padding: '4px 4px 4px 0',
                    fontSize: 11,
                    color: '#606c8b',
                  }}>
                  The CMN Branch used to populate this packet had an appointment
                  date of&nbsp;
                  <strong>{appt.appt_date_mm_dd_yyyy}</strong> associated with
                  it.
                </div>
              </Grid>
            )}

            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl}>Transmission Status</label>
              <Typography variant="h4">
                {oDoc.trm.state}{' '}
                {oDoc.trm.state_msg
                  ? ' - ' + oDoc.trm.state_msg.toLowerCase()
                  : ''}
              </Typography>
              {oDoc.trm.state.search(/sent/) > -1 && (
                <Typography className={classes.payerName}>
                  Transmission attempted using {oDoc.trm.method} by{' '}
                  {oDoc.trm.u_nm} on{' '}
                  {moment(oDoc.trm.ts).format('MM-DD-YYYY hh:mma')}
                </Typography>
              )}
              {oDoc.trm.last_ts && (
                <Typography className={classes.payerName}>
                  Last status {moment(oDoc.trm.last_ts).format('MM-DD hh:mma')}
                </Typography>
              )}

              {allowTransmissionAttempt && oDoc.mode.kind === 'fax' && (
                <div style={{ marginTop: 12 }}>
                  <Button
                    onClick={this.onTransmitAttempt(oDoc._id)}
                    disabled={disable_btn}
                    variant="contained"
                    color="secondary"
                    className={classes.button}>
                    Send this Fax NOW with ConcordFax to {oDoc.mode.val}
                  </Button>
                </div>
              )}

              {allowTransmissionAttempt &&
                appt.exists &&
                appt.allow_schedule &&
                oDoc.mode.kind === 'fax' && (
                  <div style={{ marginTop: 12 }}>
                    <Button
                      style={{ marginTop: 12 }}
                      onClick={this.onTransmitAttempt(oDoc._id, {
                        schedule: true,
                        transmit_after_utc: appt.transmit_after
                          .toDate()
                          .getTime(),
                      })}
                      disabled={disable_btn}
                      variant="contained"
                      color="primary"
                      className={classes.button}>
                      {appt.msg} to {oDoc.mode.val}
                    </Button>
                  </div>
                )}

              {true === this.props.lookups.isGapiValid &&
                allowTransmissionAttempt &&
                oDoc.mode.kind === 'email' && (
                  <div style={{ marginTop: 12 }}>
                    <Button
                      onClick={this.onTransmitAttempt(oDoc._id)}
                      disabled={disable_btn}
                      variant="contained"
                      color="secondary"
                      className={classes.button}>
                      Send this Email to {oDoc.mode.val} with my GMail Account
                    </Button>
                  </div>
                )}

              {false === this.props.lookups.isGapiValid &&
                oDoc.mode.kind === 'email' && (
                  <h5 style={{ color: 'red' }}>
                    Transmitting and emails from this tool requires granting
                    Docflow access to your GMail account. Please perform that
                    step first in DocFlow and return to this page and refresh.
                  </h5>
                )}

              {((oDoc.mode.kind === 'snailmail' &&
                oDoc.trm.state === 'not_attempted') ||
                (oDoc.mode.kind === 'fax' &&
                  ['not_attempted', 'sent_failed', 'trm_scheduled'].includes(
                    oDoc.trm.state
                  ))) && (
                <div style={{ marginTop: 24 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.onMarkAsManuallyTransmitted}
                    className={classes.button}>
                    Mark this Request as Manually Transmitted
                  </Button>
                  <div style={{ fontSize: 11, paddingTop: 6, color: 'blue' }}>
                    ONLY click this button if you have transmitted this request
                    manually (Interaction fax, .etc)
                  </div>
                </div>
              )}

              {(oDoc.trm.state === 'not_attempted' ||
                oDoc.trm.state === 'trm_scheduled') && (
                <div style={{ marginTop: 24 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.onMarkAsVoid}
                    className={classes.button}>
                    Void this Request
                  </Button>
                  <div style={{ fontSize: 11, paddingTop: 6, color: 'blue' }}>
                    Click this button only if this request is to be considered
                    cancelled and does not need transmitted.
                  </div>
                </div>
              )}

              {oDoc.mode.kind === 'fax' && oDoc.trm.state === 'sent_failed' && (
                <div style={{ marginTop: 24 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.onMarkAsRetired}
                    className={classes.button}>
                    Mark as RETIRED
                  </Button>
                  <div style={{ fontSize: 11, paddingTop: 6, color: 'blue' }}>
                    Click this button only if it is not longer required to be
                    transmitted (because requested documents have been received)
                  </div>
                </div>
              )}
            </Grid>
            <Grid item className={classes.branchGrid}>
              {(oDoc.mode.kind === 'fax' || oDoc.mode.kind === 'email') &&
                oDoc.trm.state !== 'not_attempted' && (
                  <label
                    style={{ cursor: 'pointer' }}
                    onClick={this.toggleReAttempt}
                    className={classes.lbl}>
                    Click here to Re-Attempt
                  </label>
                )}

              {/*
              (oDoc.mode.kind=='fax' || oDoc.mode.kind=='email') &&
              oDoc.trm.state==='not_attempted' &&
              <label style={{cursor:'pointer'}} onClick={this.toggleReAttempt} className={classes.lbl}>Click here to Correct {oDoc.mode.kind.toUpperCase() + ((oDoc.mode.kind==='fax') ? " #":" Address")} </label>
              */}

              {this.state.inReAttempt && oDoc.trm.state !== 'not_attempted' && (
                <FormControl
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel component="label">
                    Update {oDoc.mode.kind.toUpperCase()} if Applicable:
                  </FormLabel>
                  <FormGroup>
                    <TextField
                      onChange={this.onFaxNumberChange}
                      value={this.state.modeVal}
                      style={{ margin: '8px 8px 8px 0' }}
                      placeholder=""
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={this.onUpdateFaxNumber}
                    className={classes.button}>
                    Update {oDoc.mode.kind} and Set Status Back to
                    [not_attempted]
                  </Button>
                </FormControl>
              )}
            </Grid>
            <Grid item className={classes.branchGrid}>
              {oDoc.log
                .slice(1)
                .filter(
                  (s) => _.get(s, 'prior_trm.state', '').search(/^sent/) > -1
                ).length > 0 && (
                <label className={classes.lbl}>Past Attempts</label>
              )}
              {oDoc.log
                .slice(1)
                .filter(
                  (s) => _.get(s, 'prior_trm.state', '').search(/^sent/) > -1
                )
                .map((s, logIdx) => (
                  <div
                    key={logIdx}
                    style={{ fontSize: 10, marginRight: 12, paddingBottom: 6 }}>
                    {s.u_id} {moment(s.ts).format('MM-DD-YYYY hh:mma')} -{' '}
                    {s.prior_trm.state} {s.prior_trm.state_msg}
                  </div>
                ))}
            </Grid>
            {oDoc.comments && (
              <Grid
                item
                style={{ marginTop: 8 }}
                className={classes.branchGrid}>
                {oDoc.comments.map((n, nIdx) => {
                  let textParts = n.txt.split(/\n/g);
                  textParts = textParts.map((p, pi) => {
                    if (pi === textParts.length - 1) return p;
                    return (
                      <span key={pi}>
                        {p}
                        <br />
                      </span>
                    );
                  });
                  return (
                    <Typography
                      key={nIdx}
                      paragraph
                      className={classes.noteTxt}
                      color="textSecondary">
                      {textParts}
                      <i className={classes.noteUser}>
                        {' '}
                        - {n.u_nm} {moment(n.ts).format('M-DD-YY h:mma')}
                      </i>
                    </Typography>
                  );
                })}
              </Grid>
            )}
            <Grid item className={classes.branchGrid}>
              <TextField
                multiline
                fullWidth
                value={this.state.commentEntry}
                onChange={this.onComment}
                label="Add Comment ([Enter] for multiline)"
                className={classes.formComponent}
              />
              <div>
                <Button
                  className={classes.button}
                  style={{ float: 'right', marginTop: 8 }}
                  onClick={this.onCommentSubmit}
                  size="medium"
                  variant="contained"
                  color="primary">
                  Add Comment
                </Button>
              </div>
            </Grid>
            {/* <Grid item className={classes.branchGrid}>
              <ExpansionPanel
                disabled={oDoc.log && oDoc.log.length < 1}
                elevation={0}
                style={{
                  marginTop: 8,
                  borderBottom: '1px solid #c5c5c5'
                }}>
                <ExpansionPanelSummary
                  style={{ padding: 0 }}
                  expandIcon={<ExpandMoreIcon />}>
                  <Typography className={classes.panelHdrTxt}>
                    View Activity Log ({oDoc.log.length - 1})
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0 }}>
                  {oDoc.log.slice(1).map((log, nIdx) => (
                    <Typography
                      key={nIdx}
                      component="paragraph"
                      variant="button"
                      color="textSecondary"
                      inline>
                      {log.code} -
                      <i className={classes.noteUser}>
                        {log.u_id},&ensp;
                        {moment(log.ts).format('MM-DD-YY hh:mm a')}
                      </i>
                    </Typography>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
                  </Grid> */}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

DocRequestCard.propTypes = {
  classes: PropTypes.object.isRequired,
  oDoc: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      commentDocRequest_ac,
      open_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DocRequestCard))
);
