import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import Loader from '../Shared/Loader'
import {
  deployDraft_ac,
  fetchAllTemplates_ac,
  saveAsDraft_ac
} from './../../actions/mapping_tool.ac'
import { lookups_ac, setTitle_ac } from './../../actions/me.ac.js'
import { open_snack_ac } from './../../actions/snack.ac.js'
import DraftOnView from './DraftOnView'
import { FieldLevelForms } from './FieldLevelForms'
import { HeaderLevelForms } from './HeaderLevelForms'
import MappingTemplate from './MappingTemplate'
import SelectionTool from './SelectionTool'

const styles = theme => ({
  fullHeight: {
    height: '100%'
  },
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 2,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 38px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    height: '98%',
    overflowY: 'auto'
  },
  margin0: {
    margin: 0
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  templateBlock: {
    borderRight: '1px solid #e0e0e0',
    height: '100%'
  },
  croppedImgContainer: {
    maxHeight: 150,
    width: '100%',
    overflow: 'auto',
    backgroundColor: '#00000050',
    padding: 4,
    border: '1px solid #00000050'
  },
  croppedImg: {
    maxHeight: '100%'
  },
  deleteDraftButton: {
    float: 'right',
    maringTop: '10px',
    color: 'red'
  },
  cancelButton: {
    marginRight: '10px',
    float: 'right',
    maringTop: '10px'
  },
  saveAsDraft: {
    marginRight: '10px',
    float: 'right',
    maringTop: '10px'
  },
  informationLevelSelect: {
    maringTop: '20px',
    marginBottom: '10px'
  }
})
const defaultNewFieldForm = {
  nm: '',
  label: '',
  type: 'null',
  fontSize: 10,
  multiline: false,
  fullWidth: false,
  maxWidth: '',
  groupNM: 'null',
  maxLines: '',
  maxCharsPerLine: '',
  coords: {
    x: 0,
    y: 0,
    w: 0,
    h: 0
  }
}
const defaultHeaderLevelFieldForm = {
  id: '',
  display_name: '',
  help_text: '',
  number: ''
}
const validationFields = {
  type: false,
  nm: false,
  label: false,
  fontSize: false,
  maxCharsPerLine: false
}

const modeTitleMap = {
  edit: 'EDIT DRAFT: ',
  create: 'CREATE NEW DRAFT: '
}

class MappingToolLanding extends React.Component {
  constructor() {
    super()
    this.state = {
      selectedTab: 'mapping_job',
      selectedLeftTab: 'job_list',
      selectedExistingTpl: 'None selected',
      selected_template_img: '',
      isModalOpen: false,
      draft: null,
      draftName: '',
      isDraftRemoving: false,
      isDraftNameModalOpen: false,
      draftId: '',
      informationLevelType: 'fieldLevel',
      newField: {
        ...defaultNewFieldForm
      },
      headerField: {
        ...defaultHeaderLevelFieldForm
      },
      croppedImgURL: '',
      fields: [],
      errors: {
        ...validationFields
      },
      draftMode: '',
      isEditing: false,
      selectedFieldIndex: '',
      selectedOptionIndex: '',

      new_tpl: { id: '', display_name: '', page_count: '1', payer_address: '' }
    }
  }
  onLeftTabSelect = (e, _selectedTab) => {
    this.setState({ selectedLeftTab: _selectedTab })
    return
  }

  onTabSelect = (e, _selectedTab) => {
    this.setState({ selectedTab: _selectedTab })
    return
  }

  componentDidMount() {
    const { lookups, setTitle_ac, me, lookups_ac } = this.props
    if (!me.user) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return lookups_ac()
    }

    setTitle_ac('Mapping Tool')

    return this.fetch()
  }

  componentDidUpdate() {
    const { mappingToolInView, lookups, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    if (
      mappingToolInView.isFetching === false &&
      !mappingToolInView.error &&
      mappingToolInView.jobs === null
    ) {
      return this.fetch()
    }
  }

  editNewTplField = (f, v) => {
    console.log(f, v)
    this.setState({
      new_tpl: {
        ...this.state.new_tpl,
        [f]: v
      }
    })
  }

  handleClickOpen = () => {
    this.setState({
      isModalOpen: true
    })
  }

  handleCloseClick = () => {
    this.setState({
      isModalOpen: false,
      newField: {
        ...defaultNewFieldForm
      }
    })
  }

  handleDraftNameModalOpen = () => {
    this.setState({
      isDraftNameModalOpen: true
    })
  }

  handleDraftNameModalClose = (save = false) => {
    if (save) {
      this.saveAsDraft()
    }
    this.setState({
      isDraftNameModalOpen: false
    })
  }

  fetch() {
    let fetchCondition = {}
    this.props.fetchAllTemplates_ac(fetchCondition)
  }

  /**
   * create new draft
   * @param {string} folder
   * @param {string} img
   */
  onSelect = templatePage => {
    this.setState({
      draft_page_pos: templatePage.page_pos,
      draft_tpl_id: templatePage.tpl_id,
      draftId: '',
      draft: null,
      fields: [],
      selected_template_img: templatePage.path_to_png,
      draftMode: 'create'
    })
  }

  /**
   * update existing draft
   * @param {object} draft
   */
  onDraftEdit = (draft, templatePage) => {
    this.setState({
      draft_page_pos: draft.page_pos,
      draft_tpl_id: draft.tpl_id,
      selected_template_img: templatePage.path_to_png,
      draft: draft.tpl_as_object,
      draftId: draft._id,
      fields: draft.tpl_as_object.pages ? draft.tpl_as_object.pages.fields : [],
      draftName: draft.draft_name,
      draftMode: 'edit',
      templateInDraft: templatePage
    })
  }

  resetDraftMode = () => {
    this.setState({
      draft_page_pos: -1,
      draft_tpl_id: '',
      draftMode: '',
      selected_template_img: '',
      draft: null,
      draftId: '',
      fields: [],
      draftName: ''
    })
  }

  editFields = ({ coords, croppedImgURL }) => {
    this.setState(preState => ({
      newField: {
        ...preState.newField,
        coords: {
          x: coords.x,
          y: coords.y,
          w: coords.w,
          h: coords.h
        }
      },
      isEditing: false,
      isModalOpen: true,
      croppedImgURL
    }))
  }

  setPageImgOrientation = orientation => {
    this.setState({ orientation })
  }

  saveNewField = () => {
    let {
      fields,
      isEditing,
      informationLevelType,
      newField,
      selectedFieldIndex,
      selectedOptionIndex
    } = this.state
    if (informationLevelType === 'headerLevel') {
      this.setState({
        informationLevelType: 'fieldLevel',
        croppedImgURL: ''
      })
      return this.handleCloseClick()
    }
    const checkValidForm = this.validateForm()
    if (!checkValidForm) return

    let newPage = { ...newField }

    if (isEditing) {
      if (selectedOptionIndex === 0 || selectedOptionIndex) {
        let trimmedField = {
          nm: newPage.nm,
          label: newPage.label,
          coords: { ...newPage.coords }
        }
        fields[selectedFieldIndex].options[selectedOptionIndex] = trimmedField
      } else {
        fields[selectedFieldIndex] = newPage
      }
    } else {
      if (['checkboxOpt', 'radioOpt'].includes(newPage.type)) {
        let fieldGroupIdx = fields.findIndex(
          field => field.nm === newPage.groupNM
        )

        let optionField = {
          nm: newPage.nm,
          label: newPage.label,
          coords: { ...newPage.coords }
        }

        fields[fieldGroupIdx].options
          ? fields[fieldGroupIdx].options.push({ ...optionField })
          : (fields[fieldGroupIdx].options = [{ ...optionField }])
      } else {
        if (['checkbox', 'radio'].includes(newPage.type)) {
          newPage = {
            nm: newPage.nm,
            label: newPage.label,
            type: newPage.type,
            options: []
          }
        }
        fields = [...fields, newPage]
      }
    }

    this.setState({
      fields,
      informationLevelType: 'fieldLevel',
      newField: {
        ...defaultNewFieldForm
      },
      errors: {
        ...validationFields
      },
      isEditing: false,
      selectedOptionIndex: '',
      croppedImgURL: ''
    })

    this.handleCloseClick()
  }

  //for createing new mapping too jobs
  createMappingToolJob = () => {
    let invalid = []
    for (let k in this.state.new_tpl) {
      if (this.state.new_tpl[k].trim() === '') {
        invalid.push(k)
      }
    }
    if (isNaN(this.state.new_tpl.page_count)) {
      invalid.push('page count must be a number')
    }
    if (invalid.length) {
      this.props.open_snack_ac({
        variant: 'error',
        message: invalid.join(',') + ' fields are invalid'
      })
      return
    }

    const requestParams = {
      ...this.state.new_tpl
    }
    return window.sch
      .post('/api/mappingtool/ppls/drafts/addjob', { ...requestParams })
      .then(result => {
        this.resetDraftMode()
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Job created, refresh to view'
        })
      })
      .catch(error => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Error creating job'
        })
      })
  }

  saveAsDraft = () => {
    const {
      selected_template_img,
      draftName,
      fields,
      draftId,
      draft_tpl_id,
      draft_page_pos,
      orientation,
      headerField
    } = this.state
    const draftData = {
      draft_tpl_id,
      draft_page_pos,
      draft_name:
        draftName.split('.').slice(-1)[0] === 'json'
          ? draftName
          : draftName + '.json',
      tpl_as_object: {
        ...headerField,
        pages: {
          y_offset: 5,
          path_to_png: selected_template_img,
          fontSize: 10,
          fontFamily: 'robotomono',
          orientation: orientation,
          fields: fields
        }
      }
    }
    if (draftId) {
      draftData['draft_id'] = draftId
    }

    this.props.saveAsDraft_ac(draftData)
  }

  handleChange = (e, isHeaderLevelFieldChanged = false) => {
    let { name, value, type, checked } = e.target
    if (type === 'checkbox') {
      value = checked
    }
    if (name === 'informationLevelType') {
      return this.setState({
        informationLevelType: value
      })
    }
    if (name === 'draftName') {
      return this.setState({
        draftName: value
      })
    }
    let groupName = 'newField'
    if (isHeaderLevelFieldChanged) {
      groupName = 'headerField'
    }

    this.setState(
      pre => ({
        [groupName]: {
          ...pre[groupName],
          [name]: type === 'number' ? Number(value) : value,
          ...(['checkbox', 'radio'].includes(type) && { option: [] })
        }
      }),
      () => {
        if (this.state.errors[name]) {
          this.validateForm()
        }
      }
    )
  }

  validateForm = () => {
    let nmErr = false
    let labelErr = false
    let groupNmErr = false
    let typeErr = false
    let fontSizeErr = false
    let isValidForm = true

    const { newField } = this.state
    if (!newField['nm']) {
      nmErr = true
      isValidForm = false
    }

    if (!newField['label']) {
      labelErr = true
      isValidForm = false
    }

    if (newField['type'] === 'null') {
      typeErr = true
      isValidForm = false
    }

    if (
      newField.type === 'text' ||
      newField.type === 'number' ||
      newField.type === 'date'
    ) {
      if (
        !newField['fontSize'] ||
        !(newField['fontSize'] >= 8 && newField['fontSize'] <= 14)
      ) {
        fontSizeErr = true
        isValidForm = false
      }
    }

    if (newField.type === 'radioOpt' || newField.type === 'checkboxOpt') {
      if (newField['groupNM'] === 'null') {
        groupNmErr = true
        isValidForm = false
      }
    }

    this.setState({
      errors: {
        type: typeErr,
        nm: nmErr,
        label: labelErr,
        fontSize: fontSizeErr,
        groupNM: groupNmErr
      }
    })

    return isValidForm
  }

  /**
   * remove selected field
   * @param {number} index
   */
  removeField = index => {
    const { fields } = this.state
    fields.splice(index, 1)
    this.setState({
      fields: fields
    })
  }

  /**
   * edit selected field
   * @param {object} field
   * @param {number} fieldIndex
   */
  editDraftField = (field, fieldIndex) => {
    this.setState({
      newField: {
        ...field
      },
      isModalOpen: true,
      isEditing: true,
      selectedFieldIndex: fieldIndex
    })
  }

  /**
   * edit selected option
   * @param {object} option
   * @param {number} fieldIndex
   * @param {number} optionIndex
   */
  editFieldOption = (option, fieldIndex, optionIndex) => {
    this.setState({
      newField: {
        ...option
      },
      isModalOpen: true,
      isEditing: true,
      selectedFieldIndex: fieldIndex,
      selectedOptionIndex: optionIndex
    })
  }

  /**
   * remove selected field option
   * @param {object} field
   * @param {number} optionIndex
   */
  removeFieldOption = (field, optionIndex) => {
    const { fields } = this.state
    const selectedFields = fields.find(fieldItem => fieldItem.nm === field.nm)
    selectedFields.options.splice(optionIndex, 1)
    this.setState({
      fields: fields
    })
  }

  removeDraft = () => {
    const requestParams = {
      draft_id: this.state.draftId
    }
    this.setState({
      isDraftRemoving: true
    })
    return window.sch
      .post('/api/mappingtool/ppls/drafts/delete', { ...requestParams })
      .then(result => {
        this.resetDraftMode()
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Draft removed'
        })
      })
      .catch(error => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Error removing draft'
        })
      })
      .finally(() => {
        this.setState({
          isDraftRemoving: false
        })
      })
  }

  getExistingTpl = id => e => {
    console.log(e, id)
    return window.sch
      .get(`/api/tpl/get/appeals/${id}.appeals`)
      .then(result => {
        console.log(result)
        this.setState({
          selectedExistingTpl: JSON.stringify(result.tpl, null, '  '),
          selectedTab: 'existing_tpl'
        })
      })
      .catch(error => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Error getting TPL'
        })
      })
  }

  render() {
    const { classes, lookups, mappingToolInView } = this.props
    const {
      errors,
      newField,
      headerField,
      isDraftRemoving,
      fields
    } = this.state

    if (mappingToolInView.isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading mapping tool jobs" />
        </main>
      )
    }
    if (!lookups.ink || mappingToolInView.jobs === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      )
    }
    if (mappingToolInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>
            ....error fetching mapping tool jobs
          </h4>
        </div>
      )
    }
    if (!mappingToolInView.jobs.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No mapping tool jobs found.</h4>
        </div>
      )
    }

    let marginLessContent = [
      classes.content,
      classes.margin0,
      classes.fullHeight
    ].join(' ')

    const DialogCardContextText =
      this.state.informationLevelType === 'fieldLevel'
        ? 'Add Name, Label and Type of the choosen field.'
        : 'Add Id, Help Text, Number and Display Name of choosen field.'
    return (
      <main className={classes.content}>
        <Grid
          container
          direction="row"
          spacing={8}
          className={classes.fullHeight}>
          <Grid xs={4} item className={classes.fullHeight}>
            <Tabs
              onChange={this.onLeftTabSelect}
              value={this.state.selectedLeftTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="off">
              <Tab label="Mapping Tool Jobs" value="job_list" />
              <Tab label="Create Job" value="create_job" />
              <Tab label="Existing Tpls" value="existing_tpls_list" />
            </Tabs>

            <div className={marginLessContent}>
              {this.state.draftMode ? (
                <div className={classes.content}>
                  <div className={classes.scrollArea}>
                    <DraftOnView
                      draft={{
                        page_pos: this.state.draft_page_pos,
                        tpl_id: this.state.draft_tpl_id,
                        id: this.state.draftId,
                        name: this.state.draftName,
                        fields: this.state.fields
                      }}
                      onRemoveField={this.removeField}
                      onEditField={this.editDraftField}
                      onRemoveOption={this.removeFieldOption}
                      onEditOption={this.editFieldOption}
                    />
                  </div>
                  <div style={{ padding: 4 }}>
                    {false && this.state.draftMode === 'edit' && (
                      <div className={classes.deleteDraftButton}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="inherit"
                          disabled={isDraftRemoving}
                          onClick={this.removeDraft}>
                          Delete
                          {isDraftRemoving && (
                            <Loader type="circular" size="10" />
                          )}
                        </Button>
                      </div>
                    )}
                    <div className={classes.cancelButton}>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={this.resetDraftMode}>
                        Cancel
                      </Button>
                    </div>
                    <div className={classes.saveAsDraft}>
                      <Button
                        disabled={
                          !this.state.fields.length ||
                          mappingToolInView.isSaving
                        }
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={this.handleDraftNameModalOpen}>
                        {this.state.draftId
                          ? 'Save My Draft in Progress'
                          : 'Save As New Draft'}
                        {mappingToolInView.isSaving && (
                          <Loader type="circular" size="10" />
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={[classes.templateBlock, classes.scrollArea].join(
                    ' '
                  )}>
                  {this.state.selectedLeftTab === 'job_list' &&
                    mappingToolInView.jobs.map((template, index) => (
                      <MappingTemplate
                        deployDraft_ac={this.props.deployDraft_ac}
                        key={index}
                        template={template}
                        onSelect={this.onSelect}
                        onEdit={this.onDraftEdit}
                      />
                    ))}

                  {this.state.selectedLeftTab === 'create_job' && (
                    <div
                      style={{
                        borderTop: '1px solid lightgray',
                        paddingTop: 8,
                        marginTop: 24
                      }}>
                      <Grid container direction="row" spacing={8}>
                        <Grid item md={8}>
                          <label style={{ fontSize: 12 }}>
                            Create New Mapping Job Form
                          </label>
                          <FormControl
                            fullWidth
                            required
                            component="fieldset"
                            className={classes.formControl}>
                            <FormGroup>
                              <TextField
                                label="Template ID"
                                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                                value={this.state.new_tpl.id}
                                onChange={e => {
                                  this.editNewTplField('id', e.target.value)
                                }}
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                              <TextField
                                label="Display Name"
                                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                                value={this.state.new_tpl.display_name}
                                onChange={e => {
                                  this.editNewTplField(
                                    'display_name',
                                    e.target.value
                                  )
                                }}
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                              <TextField
                                label="Num of Pages"
                                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                                value={this.state.new_tpl.page_count}
                                onChange={e => {
                                  this.editNewTplField(
                                    'page_count',
                                    e.target.value
                                  )
                                }}
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                              <TextField
                                id="acct-search-entry"
                                label="Payer Address"
                                multiline
                                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                                value={this.state.new_tpl.payer_addr}
                                onChange={e => {
                                  this.editNewTplField(
                                    'payer_address',
                                    e.target.value
                                  )
                                }}
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </FormGroup>
                          </FormControl>
                          <FormControl
                            style={{ marginTop: 24 }}
                            required
                            component="fieldset"
                            className={classes.formControl}>
                            <Button
                              variant="contained"
                              onClick={this.createMappingToolJob}
                              color="primary"
                              size="medium">
                              Create Mapping Job
                            </Button>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.selectedLeftTab === 'existing_tpls_list' && (
                    <div
                      style={{
                        borderTop: '1px solid lightgray',
                        paddingTop: 8,
                        marginTop: 24
                      }}>
                      <label style={{ fontWeight: 600, fontSize: 12 }}>
                        Existing Templates
                      </label>
                      <Grid container direction="column" spacing={8}>
                        {mappingToolInView.existing_tpls.map(t => (
                          <Grid key={t.id} item>
                            <Button
                              onClick={this.getExistingTpl(t.id)}
                              style={{ cursor: 'pointer', fontSize: 12 }}>
                              {t.appeal_eh ? t.appeal_eh.lbl : t.display_name}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Grid>
          <Grid xs={8} item className={classes.fullHeight}>
            <Tabs
              onChange={this.onTabSelect}
              value={this.state.selectedTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="off">
              <Tab label="Mapping Job Viewer" value="mapping_job" />
              <Tab label="Existing Tpl Viewer" value="existing_tpl" />
            </Tabs>
            {this.state.selectedTab === 'existing_tpl' && (
              <div className={classes.scrollArea}>
                <pre>{this.state.selectedExistingTpl}</pre>
              </div>
            )}

            {this.state.selectedTab === 'mapping_job' && (
              <div className={marginLessContent}>
                <p style={{ marginTop: 0 }}>
                  {this.state.draftMode ? (
                    <>
                      <Typography
                        variant="inherit"
                        color="textSecondary"
                        inline>
                        <strong>{modeTitleMap[this.state.draftMode]}</strong>
                      </Typography>
                      {this.state.draft_tpl_id} Page{' '}
                      {this.state.draft_page_pos + 1}: {this.state.draftName}
                    </>
                  ) : (
                    ''
                  )}
                </p>

                <div className={classes.scrollArea}>
                  <SelectionTool
                    setPageImgOrientation={this.setPageImgOrientation}
                    imgSrc={
                      this.state.selected_template_img
                        ? process.env.REACT_APP_WOS_API_URL +
                          this.state.selected_template_img
                        : ''
                    }
                    mappedFields={this.state.fields}
                    loadDraft={this.state.draftId ? true : false}
                    returnSelectionData={this.editFields}
                  />
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        {/* Dialog for coordinate mapping */}
        <Dialog
          open={this.state.isModalOpen}
          onClose={this.handleCloseClick}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {`${this.state.isEditing ? 'Update' : 'Add'} Details`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </DialogTitle>
          <DialogContent>
            <div className={classes.croppedImgContainer}>
              <img
                src={this.state.croppedImgURL}
                alt=""
                className={classes.croppedImg}
              />
            </div>
            <div className={classes.informationLevelSelect}>
              <FormGroup style={{ marginTop: '10px' }}>
                <InputLabel>Select Information Type</InputLabel>
                <Select
                  id="informationLevelType"
                  name="informationLevelType"
                  value={this.state.informationLevelType}
                  onChange={e => this.handleChange(e)}
                  fullWidth>
                  <MenuItem value="fieldLevel">Field Level</MenuItem>
                  <MenuItem value="headerLevel">Header Level</MenuItem>
                </Select>
              </FormGroup>
            </div>

            <DialogContentText>{DialogCardContextText}</DialogContentText>
            {this.state.informationLevelType === 'fieldLevel' && (
              <FieldLevelForms
                handleChange={e => this.handleChange(e)}
                newField={newField}
                errors={errors}
                fields={fields}
              />
            )}
            {this.state.informationLevelType === 'headerLevel' && (
              <HeaderLevelForms
                handleChange={e => this.handleChange(e, 'headerLevel')}
                headerField={headerField}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.handleCloseClick}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={this.saveNewField}
              color="secondary">
              {`${this.state.isEditing ? 'Update' : 'Add'} Details`}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Draft Name */}

        <Dialog
          maxWidth="lg"
          open={this.state.isDraftNameModalOpen}
          onClose={this.handleDraftNameModalClose}
          aria-labelledby="draft-name-dialog-title">
          <DialogTitle id="draft-name-dialog-title">
            {' '}
            &nbsp;&nbsp;Draft Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="draftName"
              name="draftName"
              onChange={e => this.handleChange(e)}
              label="Draft Name"
              helperText=".json extension will be added automatically"
              value={this.state.draftName}
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleDraftNameModalClose()}
              color="primary">
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={!this.state.draftName}
              onClick={() => this.handleDraftNameModalClose(true)}
              color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    )
  }
}

MappingToolLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  mappingToolInView: stateFromStore.mappingToolInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAllTemplates_ac,
      saveAsDraft_ac,
      deployDraft_ac,
      lookups_ac,
      setTitle_ac,
      open_snack_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MappingToolLanding))
)
