import { Button, CardActions, CardContent, Collapse } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { open_snack_ac } from '../../actions/snack.ac.js';
import dateUtil from './../../util/dateProcessing';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: 10,
  },
  cardContent: {
    fontSize: '.82em',
    fontWeight: 400,
    backgroundColor: '#ffffd6',
  },
  details: {
    fontSize: 11,
    marginTop: 8,
    color: 'purple',
  },
});

class FaxDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false || props.defaultExpand,
    };
    this.onExpand = this.onExpand.bind(this);
    this.onClone = this.onClone.bind(this);
  }
  onExpand() {
    this.setState({ expanded: !this.state.expanded });
  }

  onClone = async (id) => {
    const params = {
      faxJobId: id,
    };

    try {
      await window.sch.post('/api/faxjob/clone', {
        ...params,
      });
      this.props.open_snack_ac({
        variant: 'info',
        message: 'Indexing job created',
      });
    } catch (e) {
      console.log('Error attempting clone of fax: ', e);
      this.props.open_snack_ac({
        variant: 'error',
        message: 'Failed cloning',
      });
    }

    return;
  };

  render() {
    const { classes, faxJobs } = this.props;
    return (
      <main className={classes.content}>
        <CardActions className={classes.cardContent} disableActionSpacing>
          <Button size="small" color="primary" onClick={this.onExpand}>
            Source Fax and Indexing Details
          </Button>
          <IconButton
            onClick={this.onExpand}
            aria-expanded={this.state.expanded}
            aria-label="Show Fax Details">
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.cardContent}>
            {(faxJobs || []).map((job, index) => (
              <div key={index} style={{ borderBottom: '1px solid #000' }}>
                <div className={classes.details}>
                  <Typography>Fax ID: {job._id}</Typography>{' '}
                  <a href={`/direct_download/${job._id}`} target={job._id}>
                    View asPDF
                  </a>
                </div>
                <div className={classes.details}>
                  <Typography>
                    Fax Begin from:{' '}
                    {dateUtil.formatDate(job.faxTs, 'ddd MMMM Do YYYY, h:mm a')}
                  </Typography>
                </div>
                {job.faxTsEnd && (
                  <div className={classes.details}>
                    <Typography>
                      Fax End:{' '}
                      {dateUtil.formatDate(
                        job.faxTsEnd,
                        'ddd MMMM Do YYYY, h:mm a'
                      )}
                    </Typography>
                  </div>
                )}
                <div className={classes.details}>
                  <Typography>
                    Ready for Indexing:{' '}
                    {dateUtil.formatDate(
                      job.completedTs,
                      'ddd MMMM Do YYYY, h:mm a'
                    )}
                  </Typography>
                </div>
                {job.mbox && (
                  <div className={classes.details}>
                    <Typography>
                      <strong>MBox: </strong> {job.mbox.name}
                    </Typography>
                  </div>
                )}
                {(job.mbox || job.qname) && (
                  <div className={classes.details}>
                    <Typography>
                      <strong>QName: </strong>
                      {job?.mbox?.qname || job?.qname}
                    </Typography>
                  </div>
                )}

                <div className={classes.details}>
                  <Typography>
                    <strong>Indexed by: </strong>
                    {job.indexingClaimedBy}
                  </Typography>
                </div>
                <div className={classes.details}>
                  <Typography>
                    <strong>Indexing start: </strong>
                    {dateUtil.formatDate(
                      job.indexingClaimedOn,
                      'ddd MMMM Do YYYY, h:mm a'
                    )}
                  </Typography>
                </div>
                <div className={classes.details}>
                  <Typography>
                    <strong>Indexing done: </strong>
                    {dateUtil.formatDate(
                      job.indexingCompletedOn,
                      'ddd MMMM Do YYYY, h:mm a'
                    )}
                  </Typography>
                </div>
                {this.props.allowCloning && (
                  <div className={classes.details}>
                    <Button
                      variant="contained"
                      onClick={async () => await this.onClone(job._id)}>
                      Clone this Fax as a V2 In-Progress Indexing Job assigned
                      to me
                    </Button>
                  </div>
                )}
                {false && (
                  <div className={classes.details}>
                    <Typography>
                      <strong>Indexing download: </strong>
                      {job._id} {job.pageCount} pgs
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </CardContent>
        </Collapse>
      </main>
    );
  }
}

FaxDetails.propTypes = {
  faxJobs: PropTypes.array.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FaxDetails))
);
