import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { PRODUCT_ROW_GRIDS } from '../config'
const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 4,
    borderBottom: '1px solid #000000ba'
  },
  title: {
    fontSize: 14,
    flexDirection: 'column'
  },
  content: {
    fontSize: 12,
    flexDirection: 'column'
  },
  pos: {
    textAlign: '-webkit-left'
  },
  hdr: {
    fontSize: 11
  }
})

const rowHeaders = ['Description', 'MFG/Category', 'Vendors']

const ProductRowHeader = props => {
  const { classes, isCatalogueMaintainer } = props

  return (
    <div className={classes.root}>
      <Grid container spacing={16}>
        <Grid item style={{ width: `${PRODUCT_ROW_GRIDS[0]}%` }}>
          <Typography className={classes.hdr} color="textSecondary">
            Products ({props.recordCount} items)
          </Typography>
        </Grid>

        {rowHeaders.map((header, idx) => (
          <Grid
            key={idx}
            item
            style={{ width: `${PRODUCT_ROW_GRIDS[idx + 1]}%` }}>
            <Typography className={classes.hdr} color="textSecondary">
              {header}
            </Typography>
          </Grid>
        ))}

        {isCatalogueMaintainer && (
          <Grid item style={{ width: `${PRODUCT_ROW_GRIDS[4]}%` }}>
            <Typography className={classes.hdr} color="textSecondary">
              {'Configure'}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

ProductRowHeader.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductRowHeader)
