import actionTypes from '../actionTypes'

const initialState = {
  params: {},
  args: {},
  showDialog: false,
  reasonCodes: [
    { name: 'Already Assigned', selected: false },
    { name: 'Multiple Patients', selected: false },
    { name: 'Multiple Products', selected: false },
    { name: 'Product', selected: false },
    { name: 'Payer', selected: false },
    { name: 'Mode Missing', selected: false },
    { name: 'Wrong Mode', selected: false },
    { name: 'Team/TM', selected: false },
    { name: 'Workaround', selected: false },
    { name: 'Workload', selected: false },
    { name: 'Other', selected: false }
  ],
  teamCounts: {
    inProgress: false,
    data: [],
    error: null
  },
  teamsOnly: {
    inProgress: false,
    data: [],
    error: null
  },
  do: {
    inProgress: false,
    result: null,
    error: null
  }
}

const onTeamCounts = {
  try: (state, action) => {
    return {
      ...state,
      teamCounts: {
        ...state.teamCounts,
        inProgress: true,
        data: [],
        error: null
      }
    }
  },
  success: (state, action) => {
    if (state.teamsOnly.data.length === 0) {
      setTimeout(onTeamCounts.success, 100, state, action)
      return
    }

    let team
    for (let t of state.teamsOnly.data) {
      if (t.nm === action.teamName) {
        team = t
        break
      }
    }

    return {
      ...state,
      teamCounts: {
        ...state.teamCounts,
        inProgress: false,
        data: action.data.map(user => {
          user.active =
            team.members.indexOf(user.nm) >= 0 || user.nm === 'AUTO-ASSIGN'
          return user
        })
      }
    }
  },
  fail: (state, action) => {
    return {
      ...state,
      teamCounts: {
        ...state.teamCounts,
        inProgress: false,
        error: action.error
      }
    }
  }
}

const onTeamsOnly = {
  try: (state, action) => {
    return {
      ...state,
      teamsOnly: {
        ...state.teamsOnly,
        inProgress: true,
        data: [],
        error: null
      }
    }
  },
  success: (state, action) => {
    return {
      ...state,
      teamsOnly: {
        ...state.teamsOnly,
        inProgress: false,
        data: action.data
      }
    }
  },
  fail: (state, action) => {
    return {
      ...state,
      teamsOnly: {
        ...state.teamsOnly,
        inProgress: false,
        error: action.error
      }
    }
  }
}

const onDo = {
  try: (state, action) => {
    return {
      ...state,
      args: action.args,
      do: {
        ...state.do,
        inProgress: true,
        result: null,
        error: null
      }
    }
  },
  success: (state, action) => {
    return {
      ...state,
      do: {
        ...state.do,
        inProgress: false,
        result: action.result
      }
    }
  },
  fail: (state, action) => {
    return {
      ...state,
      do: {
        ...state.do,
        inProgress: false,
        error: action.error
      }
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.reassign.teamCounts.try:
      return onTeamCounts.try(state, action)
    case actionTypes.reassign.teamCounts.success:
      return onTeamCounts.success(state, action)
    case actionTypes.reassign.teamCounts.fail:
      return onTeamCounts.fail(state, action)
    case actionTypes.reassign.teamsOnly.try:
      return onTeamsOnly.try(state, action)
    case actionTypes.reassign.teamsOnly.success:
      return onTeamsOnly.success(state, action)
    case actionTypes.reassign.teamsOnly.fail:
      return onTeamsOnly.fail(state, action)
    case actionTypes.reassign.do.try:
      return onDo.try(state, action)
    case actionTypes.reassign.do.success:
      return onDo.success(state, action)
    case actionTypes.reassign.do.fail:
      return onDo.fail(state, action)
    case actionTypes.reassign.pickReasonCode:
      return {
        ...state,
        reasonCodes: state.reasonCodes.map(rc => {
          rc.selected = action.name === rc.name
          return rc
        })
      }
    case actionTypes.reassign.pickTeam:
      return {
        ...state,
        teamsOnly: {
          ...state.teamsOnly,
          data: state.teamsOnly.data.map(team => {
            team.selected = team.nm === action.teamName
            return team
          })
        }
      }
    case actionTypes.reassign.params:
      return {
        ...state,
        params: action.params
      }
    case actionTypes.reassign.toggleDialog:
      return {
        ...state,
        showDialog: !state.showDialog,
        reasonCodes: state.reasonCodes.map(rc => {
          rc.selected = false
          return rc
        })
      }
    default:
      return state
  }
}
