import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withStyles } from '@material-ui/core/styles';

import {
  fetchIdxOrderingClinicians_ac,
  saveOrderingClinicianSorting,
} from '../../../actions/idx_admin_ordering_clinicians.ac';
import * as routes from '../../../constants/routes';
import useDebounce from '../../../hooks/useDebounce';
import { dateFormat } from '../../../util/date';
import CustomTable from '../../common/CustomTable';
import PaginationWithReset from '../common/PaginationWithReset';

const styles = () => ({
  content: {
    height: '100%',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
  },
  gridGutter4: {
    padding: 4,
  },
  stickyHeader: {
    '& > tr > th': {
      top: 0,
      left: 0,
      position: 'sticky',
      backgroundColor: 'white',
    },
  },
});

const defaultSorting = {
  ts: 1, // -1 desc , 1 asc
};

const columns = [
  {
    title: 'Name',
    dataIndex: 'first_name',
    key: 'first_name',
    sortable: true,
    render: (data) => (
      <div>
        {data.last_name}, {data.first_name}
        <br />
        <span style={{ color: 'blue' }}>NPI: {data.npi}</span>
        <br />
        <small style={{ color: 'darkgray', paddingRight: '5px' }}>
          {data._id} ({data.locations?.length} locations)
        </small>
        {data.pending_approval === true && (
          <div>
            <strong>PENDING APPROVAL</strong>
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Carve Out Reps',
    dataIndex: 'reps',
    key: 'reps',
    render: (data) => (
      <>
        {(data.reps?.length &&
          data.reps?.map((rep) => rep.display_name).join(', ')) ||
          'N/A'}
      </>
    ),
  },
  {
    title: 'Phone, Fax',
    dataIndex: 'Phone',
    key: 'Phone',
    render: (data) => (
      <>
        {data.phone?.length ? data.phone.join(', ') : 'NA'}
        <br />
        {data.fax?.length ? data.fax.map((f) => f + ' F').join(', ') : 'NA'}
      </>
    ),
  },
  {
    title: 'Last Change',
    dataIndex: 'ts',
    key: 'ts',
    sortable: true,
    render: (data) => <>{dateFormat(data.ts, 'MM-DD-YYYY hh:mm a')}</>,
  },
];

const IdxAdminOrderingCliniciansTable = (props) => {
  const {
    repMode,
    idxAdminOrderingClinicians,
    classes,
    history,
    fetchIdxOrderingClinicians_ac,
    saveOrderingClinicianSorting,
  } = props;
  const { isFetching, error, data, sorting } = idxAdminOrderingClinicians;

  const [sortData, setSortData] = useState(defaultSorting);

  const selectedId = history.location.pathname
    .replace(repMode ? '/sales/doctors/' : '/admin/idx/oc/', '')
    .split('/')[0];

  const emptyMessage = error
    ? 'Error Loading Rep Search Results!'
    : 'No reps to show';

  const handlePageChange = (newPage) => {
    fetchIdxOrderingClinicians_ac(null, newPage - 1);
  };

  const handleResetSorting = () => {
    setSortData(defaultSorting);
    saveOrderingClinicianSorting({ sorting: sortData });
  };

  const fetchOrderingClinician = () => {
    saveOrderingClinicianSorting({ sorting: sortData });

    if (sorting !== sortData) {
      fetchIdxOrderingClinicians_ac();
    }
  };

  useDebounce(fetchOrderingClinician, 1000, [sortData]);

  return (
    <div className={[classes.content, classes.gridGutter4].join(' ')}>
      {data && (
        <PaginationWithReset
          data={data?.pagination}
          isFetching={isFetching}
          handleResetSorting={handleResetSorting}
          handlePageChange={handlePageChange}
        />
      )}

      <div style={{ height: '100%', overflowY: 'auto' }}>
        <CustomTable
          columns={columns}
          data={data?.rr}
          isLoading={isFetching}
          error={{ isError: error, message: emptyMessage }}
          sortData={sortData}
          setSortData={setSortData}
          selectedId={parseInt(selectedId, 10)}
          onRowClick={(id) => {
            props.history.push(
              (repMode
                ? routes.SALES_REP_DOCTOR_VIEW
                : routes.ADMIN_IDX_OC_VIEW
              ).replace(':id', id)
            );
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  idxAdminOrderingClinicians: stateFromStore.idxAdminOrderingClinicians,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { fetchIdxOrderingClinicians_ac, saveOrderingClinicianSorting },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IdxAdminOrderingCliniciansTable));
