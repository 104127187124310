import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';
import ApplicationListLanding from './ApplicationListLanding';
import ClientAppsOnboardLanding from './OnboardLanding';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  tabsRoot: {
    minHeight: 24,
    maxHeight: 48,
  },
  tabSpecificContainer: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
});

class ClientAppsLanding extends React.Component {
  componentDidMount() {
    setTitle_ac('Client Apps');
    this.init();
  }

  componentDidUpdate() {
    this.init();
  }

  init = () => {
    const { me, lookups, lookups_ac } = this.props;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;

      return lookups_ac();
    }
  };

  onTabSelect = (e, _selectedTab) => {
    let toPath = this.props.match.path.replace(/:tabName/, _selectedTab);
    return this.props.changePage(toPath);
  };

  render() {
    const { classes, match } = this.props;
    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot,
    };
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer,
    };
    return (
      <div className={classes.content}>
        <Tabs
          classes={tabsClass}
          value={match.params.tabName}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab value="onboard" label="OnBoard Client" classes={tabClass} />
          <Tab value="apps" label="Client Apps" classes={tabClass} />
        </Tabs>
        <div className={classes.tabSpecificContainer}>
          <Switch>
            <Route
              exact
              path="/admin/comm_mgt/client_apps/onboard"
              component={ClientAppsOnboardLanding}
            />
            <Route
              path="/admin/comm_mgt/client_apps/apps"
              component={ApplicationListLanding}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

ClientAppsLanding.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  clientAppsInView: stateFromStore.clientAppsInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClientAppsLanding))
);
