import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchRecurringReport_ac } from '../../../actions/report_recurring.ac';
import { dateFormat } from '../../../util/date';
import Recurring from './Recurring';

const INITIAL_FILTERS = {
  from: moment().startOf('day').add(7, 'days').toDate(),
  to: moment().startOf('day').add(7, 'days').toDate(),
  prod_codes: [],
};

const RecurringReport = (props) => {
  const { recurringReport, fetchRecurringReport } = props;

  const [filters, setFilters] = useState({ ...INITIAL_FILTERS });

  const formatDate = useMemo(
    () => ({
      ...filters,
      from: dateFormat(filters.from),
      to: dateFormat(filters.to),
    }),
    [filters]
  );

  const handleDateChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleProdCodeChange = (value) => {
    setFilters((filters) => ({
      ...filters,
      prod_codes: value,
    }));
  };

  useEffect(() => {
    fetchRecurringReport(formatDate);
  }, [fetchRecurringReport, formatDate]);

  const handlePageChange = (newPage) => {
    fetchRecurringReport(formatDate, newPage - 1);
  };

  const applyFilters = () => {
    fetchRecurringReport(formatDate);
  };

  const handleClearFilters = () => {
    setFilters({ ...INITIAL_FILTERS });
  };

  return (
    <Recurring
      isLoading={recurringReport.isFetching}
      data={recurringReport.report}
      handleDateChange={handleDateChange}
      handleProdCodeChange={handleProdCodeChange}
      filters={filters}
      applyFilters={applyFilters}
      handlePageChange={handlePageChange}
      handleClearFilters={handleClearFilters}
    />
  );
};

const mapStateToProps = (stateFromStore) => ({
  recurringReport: stateFromStore.recurringReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRecurringReport: fetchRecurringReport_ac,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RecurringReport);
