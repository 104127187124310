import { Chip, Divider, Grid, Typography } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchFBAccountingCompletedDocs_ac,
  markEditableForSelectedDoc_ac,
  untrashSelectedDoc_ac,
} from '../../actions/filebound_ac';
import { open_snack_ac } from '../../actions/snack.ac.js';
import { PaginationWithNums } from '../PaginationNew';
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent';
import Loader from '../Shared/Loader';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    fontSize: 10,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
    '&.gridGutter': {
      padding: '4px',
    },
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  title: {
    flexGrow: 1,
  },
  pagination: {
    marginTop: 0,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  paper: {
    textAlign: 'left',
    background: '#f1f1f1',
    maxHeight: '100%',
    overflowY: 'auto',
    boxShadow: 'none',
    padding: 4,
  },
  node: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    borderRadius: '3px',
    fontSize: 10,
    padding: 0,
  },
  nodeTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    padding: 4,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px',
    padding: 3,
  },
  complete: {
    color: '#fff',
    backgroundColor: green[500],
  },
  trash: {
    backgroundColor: red[400],
  },

  editBtn: {
    float: 'right',
    cursor: 'pointer',
  },
  compDetail: {
    display: 'block',
    float: 'left',
    paddingRight: 4,
    borderRight: '2px solid #blue',
    paddingLeft: 4,
  },
});

class CompletedAccountingDocsListingComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };

    this.fetch = this.fetch.bind(this);
  }

  onPageChange = (page) => {
    const targetPage = parseInt(page, 10);
    this.setState({
      currentPage: targetPage - 1,
    });
  };

  componentDidMount() {
    return this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { fileBound } = this.props;
    const { currentPage } = this.state;
    if (
      fileBound.isFetchingCompletedList === false &&
      !fileBound.completedDocFetchingError &&
      fileBound.completedDocs === null
    ) {
      return this.fetch();
    }

    if (
      fileBound.completedPagination &&
      fileBound.isFetchingCompletedList === false &&
      fileBound.completedPagination.currentPageNum !== currentPage &&
      fileBound.completedPagination.currentPageNum !== -1
    ) {
      return this.fetch(currentPage);
    }
  }

  fetch(page = 1) {
    let params = {
      page,
    };

    return this.props.fetchFBAccountingCompletedDocs_ac(params);
  }

  makeAnEdit = (doc) => {
    this.props.markEditableForSelectedDoc_ac(doc);
  };
  untrash = (doc) => {
    this.props.untrashSelectedDoc_ac(doc);
  };

  render() {
    const { fileBound, classes } = this.props;
    const {
      completedDocs,
      isFetchingCompletedList,
      completedDocFetchingError,
      completedPagination: pg,
    } = fileBound;

    if (isFetchingCompletedList) {
      return (
        <main className={classes.content}>
          <Loader message="...loading completed docs" />
        </main>
      );
    }

    if (completedDocs === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      );
    }

    if (completedDocFetchingError) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>....error fetching completed docs</h4>
        </div>
      );
    }

    if (!completedDocs.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No completed docs found.</h4>
        </div>
      );
    }
    let pageStatement = '';
    let pageSelector = '';

    if (pg) {
      pageStatement = [
        `Displaying 
          ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
        `Page ${this.state.currentPage} of ${pg.totalPagesAvailable}`,
      ].join('-');

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.onPageChange}
          />
        );
      }
    }

    return (
      <main className={classes.content}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography className={classes.pageStatement} color="textSecondary">
              {pageStatement}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {pageSelector}
          </Grid>
        </Grid>
        <Divider component="hr" />
        <div className={`${classes.scrollArea} gridGutter`}>
          {(completedDocs || []).map((completedDoc, index) => (
            <Grid
              key={index}
              container
              spacing={8}
              style={{ borderBottom: '1px solid #000', paddingBottom: 8 }}>
              <Grid item xs={2} sm={2} md={2}>
                <Chip
                  className={classnames(
                    classes.chip,
                    completedDoc.status === 20
                      ? classes.complete
                      : classes.trash
                  )}
                  label={completedDoc.status === 20 ? 'Completed' : 'Trashed'}
                  color="default"
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <div className={classes.compDetail}>
                  {completedDoc.key &&
                    completedDoc.key.replace(/^PreFaxIn/, '')}{' '}
                </div>
                <br />
                {completedDoc.status === -100 && (
                  <div className={classes.compDetail}>
                    Moved to trash on by {completedDoc.assignee_nm} on{' '}
                    {moment(completedDoc.updated_dt).format('MM-DD-YY hh:mm a')}{' '}
                  </div>
                )}
                {completedDoc.status === 20 && (
                  <div className={classes.compDetail}>
                    Completed by {completedDoc.assignee_nm} on{' '}
                    {moment(completedDoc.complete_dt).format(
                      'MM-DD-YY hh:mm a'
                    )}
                  </div>
                )}
                {completedDoc.status === 20 && (
                  <>
                    <br />
                    <div className={classes.compDetail}>
                      {completedDoc.orig_params.dividername}:
                    </div>
                    {[
                      'vendor',
                      'po_num',
                      'po_dt',
                      'inv_num',
                      'inv_dt',
                      'credit_num',
                      'credit_dt',
                      'return_num',
                      'note',
                    ]
                      .filter(
                        (f) => completedDoc.orig_params[f].trim().length > 0
                      )
                      .map((f, index) => (
                        <div key={index} className={classes.compDetail}>
                          {completedDoc.orig_params[f]}
                        </div>
                      ))}
                  </>
                )}
              </Grid>
              <Grid item xs={2} sm={2} md={2}>
                {completedDoc.status === 20 && (
                  <div
                    className={classes.editBtn}
                    onClick={() => this.makeAnEdit(completedDoc)}>
                    <span>
                      <EditIcon fontSize="inherit" />
                      &ensp;Edit
                    </span>
                  </div>
                )}
                {completedDoc.status === -100 && (
                  <div
                    className={classes.editBtn}
                    onClick={() => this.untrash(completedDoc._id)}>
                    <span>
                      <EditIcon fontSize="inherit" />
                      &ensp;Untrash
                    </span>
                  </div>
                )}
              </Grid>
            </Grid>
          ))}
        </div>
      </main>
    );
  }
}

CompletedAccountingDocsListingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  fileBound: stateFromStore.fileBound,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFBAccountingCompletedDocs_ac,
      markEditableForSelectedDoc_ac,
      untrashSelectedDoc_ac,
      open_snack_ac,
    },
    dispatch
  );

export const CompletedcompletedDocsListing = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CompletedAccountingDocsListingComponent))
);
