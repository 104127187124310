import { actionTypes } from '../actions/appeal_requests_report.ac'

export const appealRequestsReport = (
  state = {
    isFetchingReport: false,
    report: null,
    error: null
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_APPEAL_REQUESTS_REPORT:
      return {
        ...state,
        isFetchingReport: action.isFetching
      }

    case actionTypes.RECEIVED_APPEAL_REQUESTS_REPORT:
      return {
        ...state,
        report: action.report,
        error: null
      }

    case actionTypes.FAILED_APPEAL_REQUESTS_REPORT:
      return {
        ...state,
        error: action.error,
        report: null
      }

    default:
      return state
  }
}
