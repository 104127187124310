export const actionTypes = {
  FETCHING_IDX_ADMIN_REPS: 'FETCHING_IDX_ADMIN_REPS',
  RECEIVED_IDX_ADMIN_REPS: 'RECEIVED_IDX_ADMIN_REPS',
  FAILED_FETCHING_IDX_ADMIN_REPS: 'FAILED_FETCHING_IDX_ADMIN_REPS',

  REMOVE_IDX_ADMIN_REP: 'REMOVE_IDX_ADMIN_REP',
  REMOVE_IDX_ADMIN_REP_SUCCESSFUL: 'REMOVE_IDX_ADMIN_REP_SUCCESSFUL',
  REMOVE_IDX_ADMIN_REP_PENDING: 'REMOVE_IDX_ADMIN_REP_PENDING',
  REMOVE_IDX_ADMIN_REP_FAILED: 'REMOVE_IDX_ADMIN_REP_FAILED',

  SAVE_REP_SEARCH_FILTERS: 'SAVE_REP_SEARCH_FILTERS',
  SAVE_REP_SORTING: 'SAVE_REP_SORTING'
};

export const fetchIdxAdminReps_ac = (filterData = null, page = 0) => {
  return (dispatch, getState) => {
    let params = filterData;
    const sorting = { ...getState().idxAdminRepSorting };

    if (filterData === null) {
      params = { ...getState().idxAdminRepSearchFilters };
    }

    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_REPS
    });

    window.sch
      .post('/api/idx/admin/reps/search', { ...params, ...sorting, page })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_REPS,
          reps: res.pkg
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_REPS,
          error: error
        });
      });
  };
};

export const removeIdxAdminRep_ac = (_id, { onSuccess, onError } = {}) => {
  return dispatch => {
    dispatch({
      type: actionTypes.REMOVE_IDX_ADMIN_REP_PENDING
    });

    window.sch
      .post('/api/idx/admin/reps/remove', { _id })
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_IDX_ADMIN_REP_SUCCESSFUL,
          data: _id
        });
        if (onSuccess) onSuccess();
      })
      .catch(error => {
        dispatch({
          type: actionTypes.REMOVE_IDX_ADMIN_REP_FAILED,
          error: error
        });
        if (onError) onError(error);
      });
  };
};

export const saveRepSearchFilters_ac = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_REP_SEARCH_FILTERS,
      filterData
    });
  };
};

export const saveRepSorting = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_REP_SORTING,
      filterData
    });
  };
};
