import React from 'react';

import SearchField from '../Shared/SearchField';

const GenericUserSearch = (props) => {
  const {
    label,
    placeholder,
    fullWidth = false,
    autoFocus = false,
    onUserSelect,
    disabled = false,
    baseParams = {},
  } = props;

  const searchReps = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr, ...baseParams });
  };

  const renderSuggestion = (suggestion = {}) => {
    return (
      <div>
        <div>{suggestion.nm || ''}</div>
        <div style={{ color: 'grey', fontSize: 10 }}>
          {suggestion.mail || ''}
        </div>
      </div>
    );
  };

  return (
    <SearchField
      fullWidth={fullWidth}
      clearOnSelect
      delay={500}
      minChars={3}
      autoFocus={autoFocus}
      variant="outlined"
      onCrossClick={() => onUserSelect(null)}
      resultsKey="results"
      label={label !== undefined ? label : 'Rep'}
      disabled={disabled}
      style={{ flexGrow: 1 }}
      search={searchReps}
      selectSuggestion={onUserSelect}
      apiEndpoint="/api/users"
      renderSuggestion={renderSuggestion}
      helperText="Minimum 3 characters required"
      placeholder={placeholder !== undefined ? placeholder : 'Search by Name'}
    />
  );
};

export default GenericUserSearch;
