export const AUTO_FIND_KEY = 'auto_find';

export const DISPLAY_DATE_TIME_FORMAT = 'ddd MM-DD-YYYY hh:mma';

export const MR_REQUESTS_LINK = 'https://www.google.com';

export const WU_COMPLETE = 'COMPLETE';
export const WU_CANCELLED = 'CANCELLED';

export const CS_ORDER_WU = 'CS ORDER';

export const MEDNEC_TEAM = 'MEDNEC';

export const VISIBILITY_POLICY = 'VISIBILITY';

export const GLOBAL_WU_ACTIONS = {
  COPY_DOCS: 'COPY_DOCS',
};

export const WORKUNIT_LABEL_NAME_MAP = {
  payer_type: 'Payer Group',
  prod_group: 'Product Group',
  referral: 'Referral',
  trm_source: 'Transmission Source',
  order_type: 'Order Type',
  extras: 'Extras',
};

export const CHECKBOX_STATE = {
  REQUESTED: 'Pending',
  COMPLETE: 'Complete',
  NOT_NEEDED: 'Not Needed',
};

export const PHASE_STATE = {
  UNWORKED: 'u',
  PARTIALLY_WORKED: 'pw',
  WORKED_INCOMPLETE: 'wi',
  WORKED_COMPLETE: 'wc',
};

export const PHASE_STATE_NAME = {
  [PHASE_STATE.UNWORKED]: 'Unworked',
  [PHASE_STATE.PARTIALLY_WORKED]: 'Partially Worked',
  [PHASE_STATE.WORKED_INCOMPLETE]: 'Fully worked, Incomplete',
  [PHASE_STATE.WORKED_COMPLETE]: 'Fully worked, Complete',
};

export const PHASE_STATE_AFTER = {
  [PHASE_STATE.UNWORKED]: '',
  [PHASE_STATE.PARTIALLY_WORKED]: '',
  [PHASE_STATE.WORKED_INCOMPLETE]: '✗✗',
  [PHASE_STATE.WORKED_COMPLETE]: '✓✓',
};

export const AFTER_PHASE_COMPLETE = {
  NEXT_PHASE: 'next_phase',
  NEXT_WORKUNIT: 'next_workunit',
  QUIT: 'quit',
};

export const REPEAT_KIND = {
  NONE: 'NONE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const ROLES_ALLOWED_TO_UN_ARCHIVE_WU = [
  'pcr',
  'pcr_qa',
  'cs_supervisor',
  'cs_manager',
];

export const REPEAT_KINDS = Object.values(REPEAT_KIND);

export const PHONE_LEVEL_1_ROLE = 'phone-level-1';
export const PHONE_LEVEL_2_ROLE = 'phone-level-2';
export const PAT_COM_PHASE_NAME = 'PATIENT COMMUNICATION';
export const ANY_PHASE_OWNERSHIP_ALLOWED_ROLES = [
  'csa',
  'ar_assist',
  'portal',
  PHONE_LEVEL_1_ROLE,
  PHONE_LEVEL_2_ROLE,
];
export const MULTI_CLAIM_ALLOWED_TEAMS = ['PHARMACY'];
export const ANY_PHASE_OWNERSHIP_ALLOWED_TEAMS = ['PHARMACY'];
export const PAT_COM_OWNERSHIP_ALLOWED_ROLES = [
  PHONE_LEVEL_1_ROLE,
  PHONE_LEVEL_2_ROLE,
];
export const ROLES_ALLOWED_TO_CANCEL_WU = [
  'pcr',
  'pcr_qa',
  'cs_supervisor',
  'cs_manager',
];

export const ROLES_ALLOWED_TO_PEND_WU = [
  'pcr',
  'pcr_qa',
  'cs_supervisor',
  'cs_manager',
  ...ANY_PHASE_OWNERSHIP_ALLOWED_ROLES,
];

export const ROLES_ALLOWED_TO_CLEAR_SLA = [
  ...ANY_PHASE_OWNERSHIP_ALLOWED_ROLES,
  'cs_supervisor',
  'cs_manager',
];

export const PULL_ACCESS_ROLES = ['csa', 'ar_assist'];

export const WORKUNIT_EVENT = {
  CREATION: 'CREATION',
  DOCSAPPENDED: 'DOCSAPPENDED',
  POSTCOMP: 'POSTCOMP',
  UPDATE_IDX_LABELS: 'UPDATE_IDX_LABELS',
  UPDATE_HDMS_ACCOUNT: 'UPDATE_HDMS_ACCOUNT',
  ADD_NOTE: 'ADD_NOTE',
  UPDATE_DIVIDER_NAMES: 'UPDATE_DIVIDER_NAMES',
  UNCANCEL: 'UNCANCEL',
  UPDATE_PHASE: 'UPDATE_PHASE',
  ATTACH_ACCOUNT: 'ATTACH_ACCOUNT',
  ATTACH_WOS: 'ATTACH_WOS',
  MANUAL_TEAM_REASSIGN: 'MANUAL_TEAM_REASSIGN',
  MANUAL_DOC_COPY: 'MANUAL_DOC_COPY',
  PULL: 'PULL',
  RESET: 'RESET',
  SYSTEM_EVENT: 'SYSTEM_EVENT',

  //impacts owership
  CLAIM: 'CLAIM', //legacy,
  CLAIM_NEXT: 'CLAIM_NEXT', //new
  CLAIM_SPECIFIC: 'CLAIM_SPECIFIC', //new
  ASSIGN: 'ASSIGN',
  TRANSFER: 'TRANSFER', //new
  TAKE_OVER: 'TAKE_OVER',
  RELEASE: 'RELEASE',
  FORCE_RELEASE: 'FORCE_RELEASE',
  SAVE: 'SAVE',
  COMPLETE: 'COMPLETE', //new
  FORCE_COMPLETE: 'FORCE_COMPLETE',
  UPDATE_IDX_LABELS_AND_RELEASE: 'UPDATE_IDX_LABELS_AND_RELEASE',
  CANCEL: 'CANCEL',
  RESET_AND_RELEASE: 'RESET_AND_RELEASE', //new
  RESET_AND_FORCE_RELEASE: 'RESET_AND_FORCE_RELEASE', //new
  UNKNOWN_SAVE: 'UNKNOWN_SAVE', //legacy
};

export const PHASE_EVENT = {
  UNLOCK: 'UNLOCK',
  WORKED_COMPLETE: 'WORKED_COMPLETE',
  WORKED_INCOMPLETE: 'WORKED_INCOMPLETE',
  DISCARD: 'DISCARD',
  RESET: 'RESET',
  UPDATE: 'UPDATE', // Only relevant to committed workunits. (updated_by stamps)
};

export const WE_LABEL = {
  [WORKUNIT_EVENT.CREATION]: () =>
    'Workunit Created, Beginning SLAs established',
  [WORKUNIT_EVENT.DOCSAPPENDED]: () =>
    'Docs appended from subsequent indexing job ',
  [WORKUNIT_EVENT.POSTCOMP]: () => 'Post-Complete event',
  [WORKUNIT_EVENT.UPDATE_IDX_LABELS]: () =>
    'Updated indexing labels and/or dividers',
  [WORKUNIT_EVENT.UPDATE_HDMS_ACCOUNT]: () => 'Updated HDMS accounts',
  [WORKUNIT_EVENT.ADD_NOTE]: () => 'Added a note',
  [WORKUNIT_EVENT.UPDATE_DIVIDER_NAMES]: () => 'Updated divider names',
  [WORKUNIT_EVENT.UPDATE_PHASE]: () =>
    'Changed phase selections (without commiting)',
  [WORKUNIT_EVENT.ATTACH_ACCOUNT]: () => 'Attached HDMS Account',
  [WORKUNIT_EVENT.RESET]: () => 'Reset the workunit',
  [WORKUNIT_EVENT.SYSTEM_EVENT]: () => 'System Event',
  [WORKUNIT_EVENT.MANUAL_TEAM_REASSIGN]: () => 'Reassigned the team manually',
  [WORKUNIT_EVENT.MANUAL_DOC_COPY]: () =>
    'Copied documents from another wokrunit to this workunit',
  [WORKUNIT_EVENT.PULL]: () => 'Viewed the workunit',
  [WORKUNIT_EVENT.UNCANCEL]: () => 'Uncancelled the workunit',

  //impacts ownership
  [WORKUNIT_EVENT.CLAIM]: (
    event //legacy
  ) =>
    `[OWNERSHIP BEGINS] Claimed the workunit (${
      event.props?.autoClaim ? 'Auto find' : 'From detail page'
    })`,
  [WORKUNIT_EVENT.CLAIM_NEXT]: () =>
    `[OWNERSHIP BEGINS] Claimed the workunit in Q`,
  [WORKUNIT_EVENT.CLAIM_SPECIFIC]: () =>
    `[OWNERSHIP BEGINS] Claimed from detail page (selectively)`,

  [WORKUNIT_EVENT.ASSIGN]: (event) =>
    `[OWNERSHIP BEGINS] Assigned the workunit to: ${
      event.props?.assignedTo || '-'
    }`,
  [WORKUNIT_EVENT.TRANSFER]: (event) =>
    `[OWNERSHIP ENDS AND BEGINS] Transferred ownerhip from ${
      event.props?.from || '??'
    } to: ${event.props?.assignedTo || '-'}`,
  [WORKUNIT_EVENT.TAKE_OVER]: () =>
    '[OWNERSHIP ENDS AND BEGINS] Took over the workunit',

  [WORKUNIT_EVENT.RELEASE]: () =>
    '[OWNERSHIP ENDS] Released the workunit (without updates)',
  [WORKUNIT_EVENT.FORCE_RELEASE]: () =>
    '[OWNERSHIP ENDS] Force released the workunit',

  [WORKUNIT_EVENT.SAVE]: (event) =>
    `[OWNERSHIP ENDS] Saved (commited) at ${event.phase} and ${
      event.props?.saveAndQuit ? 'exit' : 'fetched next workunit'
    }`,
  [WORKUNIT_EVENT.COMPLETE]: (event) =>
    `[OWNERSHIP ENDS] Completed at ${event.phase} and ${
      event.props?.saveAndQuit ? 'exit' : 'fetched next workunit'
    }`,
  [WORKUNIT_EVENT.FORCE_COMPLETE]: () =>
    '[OWNERSHIP ENDS] Force completed the workunit',

  [WORKUNIT_EVENT.UPDATE_IDX_LABELS_AND_RELEASE]: () =>
    '[OWNERSHIP ENDS] Updated indexing labels and/or divider, with forced released',
  [WORKUNIT_EVENT.CANCEL]: () => '[OWNERSHIP ENDS] Cancelled the workunit',

  [WORKUNIT_EVENT.RESET_AND_RELEASE]: () =>
    '[OWNERSHIP ENDS] Reset and released while workunit was owned by resetter',

  [WORKUNIT_EVENT.RESET_AND_FORCE_RELEASE]: (event) =>
    `[OWNERSHIP ENDS] Reset and released while workunit was owned by ${event.props?.from}`,

  [WORKUNIT_EVENT.UNKNOWN_SAVE]: () =>
    '[OWNERSHIP ENDS] Workunit change saved, but situation unknown',
};

export const PE_LABEL = {
  DEF: (event) => event.props?.info || false,
  [PHASE_EVENT.UNLOCK]: (event) =>
    `Unlocked ${event.phase} (${
      event.props?.autoUnlocked ? 'Completed previous phase' : 'Next click'
    })`,
  [PHASE_EVENT.WORKED_COMPLETE]: (event) =>
    `Fully worked ${event.phase} and completed`,
  [PHASE_EVENT.WORKED_INCOMPLETE]: (event) =>
    `Fully worked ${event.phase} but incomplete`,
  [PHASE_EVENT.DISCARD]: (event) =>
    `Discarded changes on ${event.phase} and went back to ${event.props?.backTo}`,
  [PHASE_EVENT.RESET]: (event) => `Reset changes on ${event.phase}`,
  [PHASE_EVENT.UPDATE]: (event) => `Updated ${event.phase}`,
};

export const DEFAULT_WU_TYPE = 'CS ORDER';

export const WU_DASH_ROOT_FILTER_ALIAS = 'wu_dash';

export const TEAMS_NOT_REQUIRING_HDMS_WO = ['PHARMACY', 'TESTING', 'HST'];

export const WU_DASH_FILTER_ALIAS = {
  OPEN_WU: `${WU_DASH_ROOT_FILTER_ALIAS}.open_wu`,
  COMPLETED_WU: `${WU_DASH_ROOT_FILTER_ALIAS}.completed_wu`,
  CANCELLED_WU: `${WU_DASH_ROOT_FILTER_ALIAS}.cancelled_wu`,
  ALL_OPEN_WU: `${WU_DASH_ROOT_FILTER_ALIAS}.all_open_wu`,
  PERIOD_REPORT: `${WU_DASH_ROOT_FILTER_ALIAS}.period_report`,
};
