export const SNLookUpsActionTypes = {
  RECEIVED_SN_LOOKUP_RECORD: 'RECEIVED_SN_LOOKUP_RECORD',
  FAILED_RECEIVING_SN_LOOKUP: 'FAILED_RECEIVING_SN_LOOKUP',
  SET_IS_FETCHING: 'SET_IS_FETCHING'
}

export const searchSNLookup_ac = params => dispatch => {
  dispatch(fetching(true))
  // dispatch({
  //     type: SNLookUpsActionTypes.RECEIVED_SN_LOOKUP_RECORD,
  //     records: [
  //         {
  //             sn_number: 3,
  //             order_id: 3433,
  //             product_code: 'Product A'

  //         },
  //         {
  //             sn_number: 3,
  //             order_id: 3445222,
  //             product_code: 'product B'

  //         },
  //         {
  //             sn_number: 3,
  //             order_id: 3433,
  //             product_code: ' Product C'

  //         },
  //         {
  //             sn_number: 3,
  //             order_id: 449499,
  //             product_code: 'Product D'

  //         }
  //     ]
  // });
  return window.sch
    .post('/api/sn_lookup', { ...params })
    .then(result => {
      if (result.error) {
        throw new Error(result.error)
      }
      dispatch({
        type: SNLookUpsActionTypes.RECEIVED_SN_LOOKUP_RECORD,
        records: result.rows
      })
    })
    .catch(error => {
      dispatch({
        type: SNLookUpsActionTypes.FAILED_RECEIVING_SN_LOOKUP,
        error
      })
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const fetching = isFetching => ({
  type: SNLookUpsActionTypes.SET_IS_FETCHING,
  isFetching
})
