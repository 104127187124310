export const salesdashActionTypes = {
  SET_IS_FETCHING_RECORDS: 'SET_IS_FETCHING_RECORDS',
  SET_IS_FETCHING_WOS_RECORDS: 'SET_IS_FETCHING_WOS_RECORDS',
  SET_IS_FETCHING_SALES_WOS_FILTERS: 'SET_IS_FETCHING_SALES_WOS_FILTERS',
  SET_IS_FETCHING_SALES_PERSONS: 'SET_IS_FETCHING_SALES_PERSONS',
  SET_IS_FETCHING_SALES_REFRESH_TS: 'SET_IS_FETCHING_SALES_REFRESH_TS',
  RECEIVED_SALES_MY_PROGRESS: 'RECEIVED_SALES_MY_PROGRESS',
  FAILED_SALES_MY_PROGRESS: 'FAILED_SALES_MY_PROGRESS',
  RECEIVED_SALES_MY_BUCKETS: 'RECEIVED_SALES_MY_BUCKETS',
  FAILED_SALES_MY_BUCKETS: 'FAILED_SALES_MY_BUCKETS',
  RECEIVED_SALES_MY_HISTORY: 'RECEIVED_SALES_MY_HISTORY',
  FAILED_SALES_MY_HISTORY: 'FAILED_SALES_MY_HISTORY',
  RECEIVED_SALES_MY_BROWN_LIST: 'RECEIVED_SALES_MY_BROWN_LIST',
  FAILED_SALES_MY_BROWN_LIST: 'FAILED_SALES_MY_BROWN_LIST',
  RECEIVED_SALES_PERSONS: 'RECEIVED_SALES_PERSONS',
  FAILED_SALES_PERSONS: 'FAILED_SALES_PERSONS',
  RECEIVED_SALES_WOS_FILTERS: 'RECEIVED_SALES_WOS_FILTERS',
  FAILED_SALES_WOS_FILTERS: 'FAILED_SALES_WOS_FILTERS',
  RECEIVED_SALES_WOS_RECORDS: 'RECEIVED_SALES_WOS_RECORDS',
  FAILED_SALES_WOS_RECORDS: 'FAILED_SALES_WOS_RECORDS',
  RECEIVED_SALES_REFRESH_TS: 'RECEIVED_SALES_REFRESH_TS',
  FAILED_SALES_REFRESH_TS: 'FAILED_SALES_REFRESH_TS',
  UPDATE_SALES_WOS_COLUMNS: 'UPDATE_SALES_WOS_COLUMNS'
};

const actionMap = {
  salespersons: {
    success: salesdashActionTypes.RECEIVED_SALES_PERSONS,
    failure: salesdashActionTypes.FAILED_SALES_PERSONS,
    fetching: salesdashActionTypes.SET_IS_FETCHING_SALES_PERSONS
  },
  my_progress: {
    success: salesdashActionTypes.RECEIVED_SALES_MY_PROGRESS,
    failure: salesdashActionTypes.FAILED_SALES_MY_PROGRESS,
    fetching: salesdashActionTypes.SET_IS_FETCHING_RECORDS
  },
  my_buckets: {
    success: salesdashActionTypes.RECEIVED_SALES_MY_BUCKETS,
    failure: salesdashActionTypes.FAILED_SALES_MY_BUCKETS,
    fetching: salesdashActionTypes.SET_IS_FETCHING_RECORDS
  },
  my_history: {
    success: salesdashActionTypes.RECEIVED_SALES_MY_HISTORY,
    failure: salesdashActionTypes.FAILED_SALES_MY_HISTORY,
    fetching: salesdashActionTypes.SET_IS_FETCHING_RECORDS
  },
  my_brown_list: {
    success: salesdashActionTypes.RECEIVED_SALES_MY_BROWN_LIST,
    failure: salesdashActionTypes.FAILED_SALES_MY_BROWN_LIST,
    fetching: salesdashActionTypes.SET_IS_FETCHING_RECORDS
  },
  wos_filters: {
    success: salesdashActionTypes.RECEIVED_SALES_WOS_FILTERS,
    failure: salesdashActionTypes.FAILED_SALES_WOS_FILTERS,
    fetching: salesdashActionTypes.SET_IS_FETCHING_SALES_WOS_FILTERS
  },
  wos_records: {
    success: salesdashActionTypes.RECEIVED_SALES_WOS_RECORDS,
    failure: salesdashActionTypes.FAILED_SALES_WOS_RECORDS,
    fetching: salesdashActionTypes.SET_IS_FETCHING_WOS_RECORDS
  },
  refresh_ts: {
    success: salesdashActionTypes.RECEIVED_SALES_REFRESH_TS,
    failure: '',
    fetching: salesdashActionTypes.SET_IS_FETCHING_SALES_REFRESH_TS
  }
};

export function fetchSalesDashRecords_ac(recordType, params) {
  return (dispatch) => {
    dispatch(fetching(actionMap[recordType].fetching, true));

    window.sch
      .post(`/api/salesdash_proxy`, params)
      .then(response => {
        if (response.error) {
          throw response.error;
        }

        dispatch({
          type: actionMap[recordType].success,
          record: response.salesdash_res.data
        })
      })
      .catch(err => {
        dispatch({
          type: actionMap[recordType].failure,
          error: true
        })
      })
      .finally(() => {
        dispatch(fetching(actionMap[recordType].fetching, false));
      })
  }
}

export function updateSalesdashWOSColumns_ac(newColumns) {
  return (dispatch) => {
    dispatch({
      type: salesdashActionTypes.UPDATE_SALES_WOS_COLUMNS,
      columns: newColumns
    })
  }
}

const fetching = (type, isFetching) => ({
  type,
  isFetching
});
