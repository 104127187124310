export function opsFormRecordsInView(
  state = {
    isFetching: false,
    idn: null,
    error: null,
    records: null,
    pagination: null,

    updateFaxNumInProgress: false,
    transmitInProgress: false,
    transmitActvityODocId: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_OPS_FORM_RECORDS':
      return Object.assign({}, state, {
        records: null,
        error: null,
        pagination: null,
        isFetching: true,
        idn: action.idn
      })
    case 'FAILED_RECEIVED_OPS_FORM_RECORDS':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        error: action.error,
        isFetching: false
      })
    case 'RECEIVED_OPS_FORM_RECORDS':
      return Object.assign({}, state, {
        records: action.records,
        pagination: action.pagination,
        error: null,
        isFetching: false
      })

    case 'RESET_OPS_FORM_RECORDS':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        error: null,
        params: null,
        idn: null,
        isFetching: false,
        transmitInProgress: false,
        transmitError: null
      })

    case 'ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: true
      })
    case 'DONE_ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: false,
        records: action.records
      })
    case 'FAILED_ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: false,
        transmitErr: action.error
      })

    case 'ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: true
      })
    case 'DONE_ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: false,
        records: action.records
      })
    case 'FAILED_ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: false,
        transmitErr: action.error
      })
    case 'UPDATE_LOCAL_OPS_FORM_RECORDS_ON_SUCCESS':
      return {
        ...state,
        records: action.records
      }

    default:
      return state
  }
}

export function opsFormRecordActivity(
  state = {
    outboundFiles: [],
    state: 'new',
    error: false,
    successPkg: null,
    qrCodeFetchInProg: false,
    qrCodeHash: null
  },
  action
) {
  switch (action.type) {
    case 'ATTACH_OPS_FORM_RECORD_FILES_TO_STORE':
      return Object.assign({}, state, {
        outboundFiles: action.outboundFiles
      })

    case 'GET_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeFetchInProg: true
      })
    case 'GOT_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeHash: action.qrCodeHash,
        qrCodeFetchInProg: false
      })
    case 'FAILED_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeHash: null,
        qrCodeFetchInProg: false
      })

    case 'UNDO_CREATE_OPS_FORM_RECORD_ACTIVITY':
      return Object.assign({}, state, {
        state: 'new'
      })
    case 'CREATE_OPS_FORM_RECORD_ACTIVITY':
      return Object.assign({}, state, {
        state: 'inprog'
      })
    case 'FAILED_OPS_FORM_RECORD_ACTIVITY':
      return Object.assign({}, state, {
        inProgress: false,
        state: 'error',
        error: action.error
      })
    case 'SUCCESS_OPS_FORM_RECORD_ACTIVITY':
      return Object.assign({}, state, {
        inProgress: false,
        state: 'success',
        successPkg: action.successPkg,
        error: false
      })
    case 'RESET_OPS_FORM_RECORD_ACTIVITY':
      return Object.assign({}, state, {
        outboundFiles: [],
        state: 'new',
        qrCodeHash: null,
        qrCodeFetchInProg: false,
        successPkg: null,
        inProgress: false,
        error: false
      })
    default:
      return state
  }
}

export function opsFormRecordFormActivity(
  state = {
    isSubmittingComment: false,
    isSubmittingVoid: false
  },
  action
) {
  switch (action.type) {
    case 'IS_SUBMITTING_OPS_RECORD_COMMENT':
      return {
        ...state,
        isSubmittingComment: action.isSubmitting
      }
    case 'IS_SUBMITTING_OPS_RECORD_VOID':
      return {
        ...state,
        isSubmittingVoid: action.isSubmitting
      }
    default:
      return state
  }
}
