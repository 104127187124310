export const fetchWorkunitOrderStatus = async (
  v_id,
  { before, after, onSuccess, onError }
) => {
  if (before) before();
  try {
    const res =
      typeof v_id === 'string' && v_id.search(/^W/) === 0
        ? await window.sch.get(`/api/df/hdms_wo/${v_id.substr(1)}`, {})
        : await window.sch.get(`/api/df/cs_workunit/${v_id}`, {});

    if (onSuccess) onSuccess(res);
  } catch (e) {
    if (onError) onError(e);
  } finally {
    if (after) after();
  }
};

export const fetchWorkOrderOrderStatus = async (
  wo_id,
  { before, after, onSuccess, onError }
) => {
  if (before) before();
  try {
    const res = await window.sch.get(`/api/df/hdms_wo/${wo_id}`, {});

    if (onSuccess) onSuccess(res);
  } catch (e) {
    if (onError) onError(e);
  } finally {
    if (after) after();
  }
};
