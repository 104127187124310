/**
 * FORMAT:
 * {
 *  [endpoint] : {
 *   [method] : NODE
 *  }
 * }
 * }
 *
 * NODE can either be a response obj or a function that takes params/queries.
 */

const {
  WORKUNIT_DETAIL,
  WORKUNIT_STATUS_SUMMARY,
} = require('./data/workunits');

module.exports = {
  list: {
    get: {
      data: [],
    },
    post: (body) => {
      return {
        created: true,
        data: body,
      };
    },
  },
  detail: {
    get: {
      data: WORKUNIT_DETAIL,
    },
  },
  df_status: {
    get: {
      data: WORKUNIT_STATUS_SUMMARY,
    },
  },
  update_labels: {
    post: (body) => {
      return body.commit
        ? {
            newWorkunit: body.workunit,
          }
        : {
            msgs: ['Changes will not impact any phases, just new labels!'],
          };
    },
  },
};
