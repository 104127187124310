import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchDCs_ac } from '../../actions/dcs.ac.js'
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js'
import {
  DiagnosisSearch,
  DoctorSearch,
  PayerSearch,
  ProviderSearch,
  SalespersonSearch
} from '../Lookups/HDMSLookupsSearch'

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {
    backgroundColor: 'red',
    color: '#fff'
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  formControl: {
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row'
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200
  },
  title: {
    flexGrow: 1
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  }
})

class LookupsLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null,
      valid: false,
      isSubmitting: false
    }
    this.onSelect = this.onSelect.bind(this)
  }

  onSelect() {
    return true
  }

  componentDidMount() {
    this.checkSituation()
  }
  componentDidUpdate() {
    this.checkSituation()
  }
  checkSituation() {
    return
  }

  render() {
    const { classes } = this.props

    return (
      <main className={classes.content}>
        <div className={classes.scrollArea} style={{ padding: 4 }}>
          <Grid container direction="row" spacing={8}>
            <Grid item xs={12} md={4}>
              <PayerSearch
                extraParams={{ excludeMediMedi: true }}
                label="Search for Payer non MediMedi"
                onSelect={this.onSelect}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PayerSearch
                label="Search for Payer including MediMedi"
                onSelect={this.onSelect}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DoctorSearch
                label="Search for Doctor"
                onSelect={this.onSelect}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DiagnosisSearch
                label="Search for Diagnosis"
                onSelect={this.onSelect}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SalespersonSearch
                label="Search for SalesPerson"
                onSelect={this.onSelect}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ProviderSearch
                label="Search for Provider"
                onSelect={this.onSelect}
              />
            </Grid>
          </Grid>
        </div>
      </main>
    )
  }
}

LookupsLanding.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  dcs: stateFromStore.dcs,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDCs_ac,
      open_snack_ac,
      close_snack_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(LookupsLanding))
)
