import { grey, orange, purple } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchClosedCounts_ac } from '../../actions/df_workunits_reports.ac'
import { lookups_ac } from '../../actions/me.ac.js'
import { open_snack_ac } from '../../actions/snack.ac'
import DateUtil from '../../util/dateProcessing'
import FilterListing from '../FilterListing'
import Loader from '../Shared/Loader'

const styles = theme => ({
  tableScrollable: {
    minHeight: '220px',
    height: 'calc(100% - 40px)',
    overflowY: 'auto'
  },
  tableHeader: {
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      boxShadow: '3px 1px 1px 1px black',
      backgroundColor: 'white'
    }
  },
  message: {
    fontSize: '1em',
    padding: '8px'
  }
})

const DEFAULT_FILTERS = {
  groupBy: {
    kind: 'radio',
    lbl: 'Grouping',
    options: [
      { lbl: 'Group By Assignee', code: 'user' },
      { lbl: 'Group By Team', code: 'team' }
    ]
  },
  filterBy: {
    kind: 'radio',
    lbl: 'Limit to',
    options: [
      { lbl: 'My Assignments', code: 'mine' },
      { lbl: 'My Teams', code: 'myteams' },
      { lbl: 'Teams I Supervise', code: 'myteams_super' },
      { lbl: 'All Teams', code: 'all' },
      { lbl: 'All Front End Teams Only', code: 'front' },
      { lbl: 'All Back End Teams Only', code: 'back' },
      { lbl: 'Nonwork Team only', code: 'nonwork' }
    ]
  },
  majorState: {
    kind: 'radio',
    lbl: 'Status',
    options: [
      { lbl: 'Any Status', code: 'ANY' },
      { lbl: 'Done (Complete)', code: 'D' },
      { lbl: 'Cancelled', code: 'C' }
    ]
  },
  datePeriod: {
    lbl: 'Date Period of Last Status',
    kind: 'labelOnly'
  },
  startDate: {
    lbl: 'From',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mm a',
    value: '',
    dirty: false
  },
  endDate: {
    lbl: 'To',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mm a',
    value: '',
    dirty: false
  }
}

const CustomPaper = props => {
  return (
    <Grid item>
      <Paper
        style={{
          display: 'inline-block',
          padding: '4px 8px',
          backgroundColor: props.backgroundColor,
          color: 'white'
        }}>
        <Typography color={props.typographyColor} component="span">
          <strong>{props.label}</strong>
        </Typography>
      </Paper>
    </Grid>
  )
}

class ClosedCounts extends React.Component {
  constructor() {
    super()

    this.state = {
      filterSequence: [
        'groupBy',
        'filterBy',
        'majorState',
        'datePeriod',
        'startDate',
        'endDate'
      ],
      filters: {
        ...DEFAULT_FILTERS,
        startDate: {
          ...DEFAULT_FILTERS.startDate,
          value: moment()
            .subtract(24, 'hours')
            .format('MM-DD-YYYY hh:mm a')
        },
        endDate: {
          ...DEFAULT_FILTERS.endDate,
          value: moment().format('MM-DD-YYYY hh:mm a')
        }
      },
      selectedFilters: {
        groupBy: 'user',
        filterBy: 'mine',
        majorState: 'ANY'
      }
    }
  }

  componentDidMount() {
    const { lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return lookups_ac()
    }

    return this.fetch()
  }

  componentDidUpdate() {
    const { dfWorkunitsReports, lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return lookups_ac()
    }

    if (
      !dfWorkunitsReports.isFetching &&
      !dfWorkunitsReports.error &&
      !dfWorkunitsReports.closedCounts
    ) {
      return this.fetch()
    }
  }

  fetch = () => {
    let params = {
      ...this.state.selectedFilters,
      slaDateFilter: {
        start: moment(
          this.state.filters.startDate.value || moment().subtract(24, 'hours')
        )
          .utc()
          .format(),
        end: moment(this.state.filters.endDate.value || {})
          .utc()
          .format()
      }
    }

    this.props.fetchClosedCounts_ac(params)
  }

  onFilterChange = fieldName => event => {
    const { value } = event.target
    let isDirty = false

    if (this.state.filters[fieldName].kind === 'dateTime') {
      isDirty = !DateUtil.isValidDateTime(value)
    }

    this.setState({
      filters: {
        ...this.state.filters,
        [fieldName]: {
          ...this.state.filters[fieldName],
          value: value,
          dirty: isDirty
        }
      }
    })
  }

  onRadioFilterChange = event => {
    const { name, value } = event.target

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [name]: value
      }
    })
  }

  onFilterClearField = fieldName => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [fieldName]: {
          ...this.state.filters[fieldName],
          value: '',
          dirty: this.state.filters[fieldName].kind === 'dateTime'
        }
      }
    })
  }

  applyFilters = () => {
    const isStartDateValid = DateUtil.isValidDateTime(
      this.state.filters.startDate.value
    )
    const isEndDateValid = DateUtil.isValidDateTime(
      this.state.filters.endDate.value
    )

    if (!(isStartDateValid && isEndDateValid)) {
      return this.props.open_snack_ac({
        variant: 'error',
        message: `'From' and 'To' fields require valid dates!`
      })
    }

    return this.fetch()
  }

  render() {
    const { classes, dfWorkunitsReports, me } = this.props
    const { closedCounts, isFetching, error } = dfWorkunitsReports

    if (isFetching) {
      return <Loader classes={{ message: classes.message }} />
    }

    if (error) {
      return (
        <Typography color="error" className={classes.message}>
          Error fetchnig report!
        </Typography>
      )
    }

    return (
      <>
        <FilterListing
          me={me}
          applyFilters={this.applyFilters}
          filters={this.state.filters}
          filterSequence={this.state.filterSequence}
          onFilterChange={this.onFilterChange}
          onRadioFilterChange={this.onRadioFilterChange}
          onFilterClearField={this.onFilterClearField}
          selected={this.state.selectedFilters}
        />
        {closedCounts && (
          <div className={classes.tableScrollable}>
            <Table padding="dense">
              <TableHead>
                <TableRow className={classes.tableHeader}>
                  <TableCell width="25%">
                    <Grid container spacing={8}>
                      <CustomPaper
                        backgroundColor={grey['600']}
                        typographyColor="inherit"
                        label={`${closedCounts.hdr.groupByField} (${
                          closedCounts.hdr.total
                        })`}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell width="28%">
                    <Grid container spacing={8}>
                      <CustomPaper
                        backgroundColor={orange.main}
                        typographyColor="textPrimary"
                        label={`${closedCounts.hdr.product[0][0]} (${
                          closedCounts.hdr.product[0][1]
                        })`}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell width="28%">
                    <Grid container spacing={8}>
                      <CustomPaper
                        backgroundColor={purple.main}
                        typographyColor="inherit"
                        label={`${closedCounts.hdr.nonGroupByField[0][0]} (${
                          closedCounts.hdr.nonGroupByField[0][1]
                        })`}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell width="19%">
                    <Grid container spacing={8}>
                      <CustomPaper
                        backgroundColor="white"
                        typographyColor="textPrimary"
                        label={closedCounts.hdr.counts[0]}
                      />
                      <CustomPaper
                        backgroundColor="white"
                        typographyColor="textPrimary"
                        label={`${closedCounts.hdr.counts[1]} PEND`}
                      />
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {closedCounts.rows.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell width="25%">
                      <Grid container spacing={8}>
                        <CustomPaper
                          backgroundColor={grey['600']}
                          typographyColor="inherit"
                          label={`${row._id} (${row.total})`}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell width="28%">
                      <Grid container spacing={8}>
                        {row.product.map((item, idx) => (
                          <CustomPaper
                            key={idx}
                            backgroundColor={orange.main}
                            typographyColor="textPrimary"
                            label={`${item[0]} (${item[1]})`}
                          />
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell width="28%">
                      <Grid container spacing={8}>
                        {row.nonGroupByField.map((item, idx) => (
                          <CustomPaper
                            key={idx}
                            backgroundColor={purple.main}
                            typographyColor="inherit"
                            label={`${item[0]} (${item[1]})`}
                          />
                        ))}
                      </Grid>
                    </TableCell>
                    <TableCell width="19%">
                      <Grid container spacing={8}>
                        {row.counts[0] ? (
                          <CustomPaper
                            backgroundColor="white"
                            typographyColor="textPrimary"
                            label={row.counts[0]}
                          />
                        ) : (
                          ''
                        )}
                        {row.counts[1] ? (
                          <CustomPaper
                            backgroundColor="white"
                            typographyColor="textPrimary"
                            label={`${row.counts[1]} PEND`}
                          />
                        ) : (
                          ''
                        )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  dfWorkunitsReports: stateFromStore.dfWorkunitsReports
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      open_snack_ac,
      fetchClosedCounts_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClosedCounts))
)
