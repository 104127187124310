export const actionTypes = {
  SET_IS_FETCHING_PP_REGISTRATIONS: 'SET_IS_FETCHING_PP_REGISTRATIONS',
  RECEIVED_PP_REGISTRATIONS: 'RECEIVED_PP_REGISTRATIONS',
  FAILED_PP_REGISTRATIONS: 'FAILED_PP_REGISTRATIONS'
}

export const fetchPPRegistrations_ac = params => {
  return dispatch => {
    dispatch(setIsFetching(true))

    window.sch
      .post('/api/pp/registrations/list', params)
      .then(result => {
        dispatch({
          type: actionTypes.RECEIVED_PP_REGISTRATIONS,
          report: {
            records: result.pkg.records,
            pagination: result.pkg.pagination
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_PP_REGISTRATIONS,
          error: true
        })
      })
      .finally(() => {
        dispatch(setIsFetching(false))
      })
  }
}

const setIsFetching = isFetching => {
  return {
    type: actionTypes.SET_IS_FETCHING_PP_REGISTRATIONS,
    isFetching
  }
}
