import {
  Button,
  Grid,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { en } from '../../../constants/lang';
import PaginationWithReset from '../../IndexingAdmin/common/PaginationWithReset';
import CustomTable from '../../common/CustomTable';
import SuperReactDatePicker from '../../common/SuperReactDatePicker';
import { getCatalogImageUrl } from '../../common/utils';

const AUTO_RECURRING_PROD_CODES = {
  ADULTRE: 'ADULT REOCCURRING',
  MILKRUN: 'MILKRUN',
  'SK - CHLA': 'CHLA RESUPPLY',
  'SK - ENT': 'SUPERKIDS RESUPPLY',
  'SK - OTHER': 'SUPERKIDS RESUPPLY',
  'SK - RESP': 'SUPERKIDS RESUPLY',
};

const RecurringLinesUnderAccounts = (props) => {
  const { classes, data, isLoading } = props;

  const columns = [
    {
      title: <label className={classes.lbl}>Product</label>,
      dataIndex: '_id',
      key: '_id',
      width: '150px',
      render: (data) => (
        <Grid item>
          <div className={classes.txtVal}>
            <b>{data.product_code}</b>{' '}
            <b
              className={classes.hcpc}
              style={{ cursor: 'pointer', color: '#FF6D00' }}>
              {data.billing_code_macro_hcpc || data.product_hcpc}
            </b>
            <br />
            {data.product_desc}
            <br />
            <span style={{ color: 'gray' }}>{data.wo_type_desc}</span>
          </div>
        </Grid>
      ),
    },
    {
      title: <label className={classes.lbl}>Qty/Price</label>,
      alignTitle: 'right',
      dataIndex: 'address',
      key: 'address',
      render: (data) => (
        <Grid item>
          <div className={classes.txtVal} style={{ textAlign: 'right' }}>
            <b>{data.qty}</b>
            <br />
            <span style={{ color: 'gray' }}>${data.unitprice}</span>
          </div>
        </Grid>
      ),
    },
    {
      title: <label className={classes.lbl}>Frequency/Hold</label>,
      dataIndex: 'group',
      key: 'group',
      render: (data) => (
        <>
          <Grid item>
            <div className={classes.txtVal}>
              EVERY MONTH
              {/* {r.f_txt} */}
              <br />
              {/* {r.hold_txt} */}
              Hold Until Made Ready
            </div>
          </Grid>
        </>
      ),
    },
    {
      title: <label className={classes.lbl}>Method</label>,
      dataIndex: 'method',
      key: 'method',
      render: (data) => (
        <>
          <Grid item>
            <div className={classes.txtVal}>
              {data.dc_nm}
              <br />
              {data.method_nm}
            </div>
          </Grid>
        </>
      ),
    },
  ];

  return (
    <Grid
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '4px',
      }}>
      <CustomTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        headerStyle={{
          height: '42px',
          background: 'lightcyan',
        }}
        error={{ message: 'This account does not have data' }}
      />
    </Grid>
  );
};

const Recurring = (props) => {
  const {
    classes,
    data,
    isLoading,
    isDisplayImage,
    filters,
    handleDateChange,
    handleProdCodeChange,
    handlePageChange,
    applyFilters,
    handleClearFilters,
  } = props;

  const [isVisibleImage, setIsVisibleImage] = useState(false);
  const [showZeroQty, setShowZeroQty] = useState(false);

  const columns = [
    {
      title: <label className={classes.lbl}>Account</label>,
      dataIndex: 'accounts',
      key: 'accounts',
      render: (data) => (
        <>
          {data.account}
          <Tooltip title={en.openLinkInNewTab} placement="top">
            <Link
              className={classes.pdfLink}
              to={`/redirect_to_patient/` + data.acctMember + `/orders`}
              variant="caption"
              target="_blank">
              <BiLinkExternal className={classes.linkIcon} />
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      title: <label className={classes.lbl}>Next Deliv</label>,
      dataIndex: 'dc',
      key: 'dc',
      render: (data) => (
        <Grid item>
          <div className={classes.txtVal}>{data.next_dt_str}</div>

          {isVisibleImage && (
            <img
              src={getCatalogImageUrl(data.product_code)}
              onError={function (e) {
                e.target.style.display = 'none';
              }}
              alt="img"
              style={{ width: '60px' }}
            />
          )}
          <div className={classes.txtVal}>
            Doc Qual: {data.team_to_assign_to}
          </div>
        </Grid>
      ),
    },
    {
      title: <label className={classes.lbl}>Lines</label>,
      dataIndex: 'lines',
      key: 'lines',
      width: '150px',
      render: (data) => (
        <Grid item>
          <RecurringLinesUnderAccounts
            data={data.lines}
            classes={classes}
            isLoading={isLoading}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Grid
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '30px',
      }}>
      <Grid>
        <Grid container spacing={24} alignItems="center">
          <Grid item>
            <SuperReactDatePicker
              selected={filters.from}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => handleDateChange('from', date)}
              customInput={
                <TextField margin="dense" label="Filter Date Range From" />
              }
            />
          </Grid>

          <Grid item>
            <SuperReactDatePicker
              selected={filters.to}
              dateFormat="MM-dd-yyyy"
              onChange={(date) => handleDateChange('to', date)}
              customInput={<TextField margin="dense" label="To" />}
            />
          </Grid>

          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={applyFilters}
              disabled={isLoading} //Add isFetching here so that while fetching APi, It will disabled
            >
              Show Records
            </Button>
          </Grid>
          <Grid item>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={handleClearFilters}
              disabled={isLoading}>
              Clear All
            </Button>
          </Grid>
          <Grid item>
            <Switch
              checked={showZeroQty}
              onChange={() => setShowZeroQty(!showZeroQty)}
              color="primary"
              disabled={isLoading}
            />
            <label>Show/Hide 0 QTYs</label>
          </Grid>

          {isDisplayImage && (
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => setIsVisibleImage((prev) => !prev)}>
                {isVisibleImage
                  ? 'Collapse Item Images'
                  : 'Display Item Images'}
              </Button>
            </Grid>
          )}

          <Grid item>
            <FormControl fullWidth>
              <InputLabel>Product Codes</InputLabel>
              <Select
                multiple
                value={filters.prod_codes}
                onChange={(event) => handleProdCodeChange(event.target.value)}>
                {Object.entries(AUTO_RECURRING_PROD_CODES).map(
                  ([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      {data?.pagination && (
        <PaginationWithReset
          sortSupport={false}
          data={data.pagination}
          isFetching={isLoading}
          handlePageChange={handlePageChange}
        />
      )}

      <CustomTable
        columns={columns}
        data={
          data.rr === undefined
            ? []
            : showZeroQty
            ? data?.rr
            : data?.rr.filter((r) => r.total_qtys > 0)
        }
        isLoading={isLoading}
        error={{ message: 'This account does not have data' }}
      />
    </Grid>
  );
};

const styles = () => ({
  txtVal: {
    fontSize: 12,
    color: '#000',
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0',
  },
  pdfLink: {
    fontSize: '12px',
    textDecoration: 'none',
  },
  linkIcon: {
    fontSize: '16px',
    marginLeft: '2px',
    cursor: 'pointer',
  },
});

export default withStyles(styles)(Recurring);
