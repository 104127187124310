export const COLLECTION_KINDS = {
  idx_lookups: "Lookup Tables",
  idx_locations: "Ordering Clinician Locations",
  idx_oc: "Ordering Clinicians",
  idx_reps: "REPS",
  idx_wut: "Workunit Types",
  idx_wr_reqs: "Workunit Req. Mappings",
  idx_div_mapping: "Divider Mapping",
  idx_sla: "SLA Rules",
  idx_ta: "Team Assignment Rules"
}

export const FILTERS = {
  collection_kind: '',
  user: ''
}
