import _ from 'lodash';

export const AllScriptsReportActionTypes = {
  RECEIVED_ALL_SCRIPTS_REPORTS: 'RECEIVED_ALL_SCRIPTS_REPORTS',
  FAILED_RECEIVING_SCRIPTS_REPORTS: 'FAILED_RECEIVING_SCRIPTS_REPORTS',
  RECEIVED_ALL_SCRIPTS_REFERRAL: 'RECEIVED_ALL_SCRIPTS_REFERRAL',
  FAILED_RECEIVING_ALL_SCRIPTS_REFERRAL:
    'FAILED_RECEIVING_ALL_SCRIPTS_REFERRAL',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_REFERRAL_DETAILS_FETCHING: 'SET_IS_REFERRAL_DETAILS_FETCHING',
  UPDATE_REFERRAL_FILES: 'UPDATE_REFERRAL_FILES',
};
export const fetchAllScriptsList_ac = (params, findBySearchString = false) => {
  return (dispatch) => {
    let target_url = `/api/allscripts/list`;

    if (findBySearchString) {
      target_url = `/api/allscripts/search`;
      if (params.id) {
        target_url = `/api/allscripts/referral`;
      }
    }
    dispatch(fetching(true));
    return window.sch
      .post(target_url, { ...params })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        if (findBySearchString) {
          result = {
            data: _.isArray(result) ? result : [result],
          };
        }
        dispatch({
          type: AllScriptsReportActionTypes.RECEIVED_ALL_SCRIPTS_REPORTS,
          records: result.data,
          pagination: result.pagination,
        });
      })
      .catch((error) => {
        dispatch({
          type: AllScriptsReportActionTypes.FAILED_RECEIVING_SCRIPTS_REPORTS,
          error,
        });
      })
      .finally(() => {
        dispatch(fetching(false));
      });
  };
};

export const fetchReferralDetails_ac = (params) => {
  return (dispatch) => {
    dispatch(fetchingReferralDetails(true));
    return window.sch
      .post(`/api/allscripts/referral_detail`, { ...params })
      .then((result) => {
        dispatch({
          type: AllScriptsReportActionTypes.RECEIVED_ALL_SCRIPTS_REFERRAL,
          referral: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: AllScriptsReportActionTypes.FAILED_RECEIVING_ALL_SCRIPTS_REFERRAL,
          referralError: error,
        });
      })
      .finally(() => {
        dispatch(fetchingReferralDetails(false));
      });
  };
};

export const markAsDownloaded_ac = (params) => {
  return (dispatch) => {
    return window.sch
      .post(`/api/allscripts/mark`, { ...params })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: AllScriptsReportActionTypes.UPDATE_REFERRAL_FILES,
          referralId: params.id,
          s3pathAndKeys: params.s3pathAndKeys,
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const fetching = (isFetching) => ({
  type: AllScriptsReportActionTypes.SET_IS_FETCHING,
  isFetching,
});

export const fetchingReferralDetails = (isFetchingReferralDetails) => ({
  type: AllScriptsReportActionTypes.SET_IS_REFERRAL_DETAILS_FETCHING,
  isFetchingReferralDetails,
});
