import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchFBAccountingCompletedDocs_ac,
  fetchFBAccountingDocs_ac,
  markAsComplete_ac,
  moveDocToTrash_ac,
  resetInProgressJob_ac,
  retryImport_ac,
  updateCompletedDoc_ac
} from '../../actions/filebound_ac'
import { open_snack_ac } from '../../actions/snack.ac.js'
import Loader from '../Shared/Loader'
import SearchSelect from '../Shared/SearchSelect'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '50%'
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  error: {
    color: red[500]
  }
})

const defaultFormFields = {
  po_num: '',
  po_dt: '',
  inv_num: '',
  inv_dt: '',
  vendor: '',
  note: '',
  tracking: '',
  credit_num: '',
  credit_dt: '',
  return_num: '',
  dividername: ''
}

const validationFields = {
  dividername: false,
  vendor: false
}

class FBAccountingDocsLabellingComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      formFields: {
        ...defaultFormFields
      },
      errors: {
        ...validationFields
      }
    }
  }

  componentDidMount() {
    const { fileBound } = this.props
    const { selectedAccountingDoc } = fileBound
    // only for completed doc
    if (selectedAccountingDoc.orig_params) {
      this.setState({
        formFields: {
          ...defaultFormFields,
          ...selectedAccountingDoc.orig_params
        }
      })
    }
  }

  handleDividerChange = e => {
    const { value } = e.target
    this.setState(
      pre => ({
        formFields: {
          ...pre.formFields,
          dividername: value
        }
      }),
      () => {
        if (this.state.errors['dividername']) {
          this.validateForm()
        }
      }
    )
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState(
      pre => ({
        formFields: {
          ...pre.formFields,
          [name]: value
        }
      }),
      () => {
        if (this.state.errors[name]) {
          this.validateForm()
        }
      }
    )
  }

  validateForm = () => {
    let dividername_err = false
    let vendor_err = false
    let is_valid_form = true

    const { formFields } = this.state

    const hasValueInFormFields = Object.values(formFields).filter(val => val)

    // if we have value less the three (one of divider name and vendor)
    // condition is to check form fields with dividername,vendor and any other fields
    if (hasValueInFormFields.length < 3) {
      is_valid_form = false
    }

    if (!formFields['dividername']) {
      dividername_err = true
      is_valid_form = false
    }

    if (!formFields['vendor']) {
      vendor_err = true
      is_valid_form = false
    }

    this.setState({
      errors: {
        dividername: dividername_err,
        vendor: vendor_err
      }
    })

    return is_valid_form
  }

  complete = (get_next_job = false) => {
    const { fileBound } = this.props

    const isValidForm = this.validateForm()
    if (!isValidForm)
      return this.props.open_snack_ac({
        variant: 'info',
        message: 'Please select any form fields'
      })
    const { formFields } = this.state
    const requestData = {
      ...formFields,
      orig_s3_key: fileBound.selectedAccountingDoc.key,
      ext: fileBound.selectedAccountingDoc.key.substr(-3).toUpperCase(),
      _id: fileBound.selectedAccountingDoc._id
    }

    this.props.markAsComplete_ac(requestData, get_next_job)
  }

  update = () => {
    const { fileBound } = this.props

    const isValidForm = this.validateForm()
    if (!isValidForm)
      return this.props.open_snack_ac({
        variant: 'info',
        message: 'Please select any form fields'
      })
    const { formFields } = this.state
    const requestData = {
      ...formFields,
      _id: fileBound.selectedAccountingDoc._id,
      orig_s3_key: fileBound.selectedAccountingDoc.key,
      ext: fileBound.selectedAccountingDoc.key.substr(-3).toUpperCase(),
      fileid: fileBound.selectedAccountingDoc.fileid,
      docid: fileBound.selectedAccountingDoc.docid
    }

    this.props.updateCompletedDoc_ac(requestData)
  }

  moveToTrash = () => {
    const { fileBound } = this.props
    const { _id } = fileBound.selectedAccountingDoc
    this.props.moveDocToTrash_ac(_id)
  }

  resetSelection = () => {
    const { fileBound } = this.props
    const { _id } = fileBound.selectedAccountingDoc
    this.props.resetInProgressJob_ac(_id)
  }

  retryImport = () => {
    const { fileBound } = this.props
    const { _id } = fileBound.selectedAccountingDoc
    this.props.retryImport_ac(_id)
  }

  backToListing = () => {
    this.props.fetchFBAccountingDocs_ac({ page: 0 })
    this.props.fetchFBAccountingCompletedDocs_ac({ page: 0 })
  }

  render() {
    const dividerOptions = [
      'CREDIT',
      'CREDIT MEMO',
      'INVOICE',
      'MCKESSON',
      'MEDTRONIC',
      'PACKING SLIPS',
      'PURCHASE ORDERS',
      'RETURN MERCHANDISE AUTHORIZATION',
      'STOCK RECEIPT VALUATION',
      'TRANSFER ORDER'
    ].map(d => ({ code: d, lbl: d }))

    const { fileBound, classes } = this.props
    const { errors } = this.state

    if (fileBound.isFetchingDetails) {
      return (
        <main className={classes.content}>
          <Loader message="...loading doc details" />
        </main>
      )
    }

    if (fileBound.accountingDocDetailsError) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>Accounting Doc Not Found</h4>
        </div>
      )
    }

    let actionButtons = fileBound.selectedAccountingDoc.isEditing ? (
      <>
        <Grid item xs={12}>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}>
            <div className={classes.flexDisplay}>
              {fileBound.isSaving && (
                <Loader type="circular" size={15} color="primary" />
              )}
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={this.update}
                className={classes.button}
                disabled={fileBound.isSaving}>
                Update - Back to Listing
              </Button>
            </div>
          </FormControl>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12}>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}>
            <div className={classes.flexDisplay}>
              {fileBound.isSaving && (
                <Loader type="circular" size={15} color="primary" />
              )}
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => this.complete()}
                className={classes.button}
                disabled={fileBound.isSaving}>
                Complete - Back to Listing
              </Button>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={() => this.complete(true)}
                className={classes.button}
                disabled={fileBound.isSaving}>
                Complete - Next Job
              </Button>
            </div>
          </FormControl>
        </Grid>
        <Divider component="hr" />
        <Grid item xs={12}>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}>
            <div className={classes.flexDisplay}>
              {fileBound.isSaving && (
                <Loader type="circular" size={15} color="primary" />
              )}
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={this.moveToTrash}
                className={classes.button}
                disabled={fileBound.isSaving}>
                Trash
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={this.resetSelection}
                className={classes.button}
                disabled={fileBound.isSaving}>
                Reset - Back to Listing
              </Button>
            </div>
          </FormControl>
        </Grid>
        <Divider component="hr" />
        <Grid item xs={12}>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}>
            <div className={classes.flexDisplay}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={this.retryImport}
                className={classes.button}
                disabled={fileBound.isSaving}>
                DELETE AND RETRY IMPORT
              </Button>
            </div>
          </FormControl>
        </Grid>
      </>
    )

    return (
      <div className={classes.content}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={this.backToListing}
            className={classes.button}>
            Back to Listing
          </Button>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}
            fullWidth>
            <FormLabel component="label">
              <strong>Divider Name</strong>
            </FormLabel>

            {dividerOptions && dividerOptions.length > 10 ? (
              <SearchSelect
                name="dividername"
                options={dividerOptions}
                value={this.state.formFields.dividername}
                placeholder="Search Divider"
                fullWidth
                scrollableParentId="fbAccountingDocLabellingParentPopper"
                onSelect={this.handleDividerChange}
              />
            ) : (
              <Select
                fullWidth
                value={this.state.formFields.dividername}
                name="dividername"
                onChange={this.handleDividerChange}>
                {(dividerOptions || []).map(d => (
                  <MenuItem
                    style={{ fontSize: 11, fontWeight: 600 }}
                    key={d.code}
                    value={d.code}>
                    {d.lbl}
                  </MenuItem>
                ))}
              </Select>
            )}
            {errors.dividername ? (
              <Typography
                component="span"
                className={classes.error}
                color="textPrimary">
                Please Select Divider Name
              </Typography>
            ) : (
              ''
            )}
            <TextField
              label="PO Number"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              required
              placeholder="PO #"
              name="po_num"
              value={this.state.formFields.po_num}
            />
            <TextField
              label="PO Date"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              required
              placeholder="MM-DD-YYYY"
              name="po_dt"
              value={this.state.formFields.po_dt}
            />
            <TextField
              label="Invoice Number"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              required
              placeholder="Invoice Number"
              name="inv_num"
              value={this.state.formFields.inv_num}
            />
            <TextField
              label="Invoice Date"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              required
              placeholder="MM-DD-YYYY"
              name="inv_dt"
              value={this.state.formFields.inv_dt}
            />
            <TextField
              label="Vendor"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              required
              error={errors.vendor}
              helperText={errors.vendor ? 'Please enter Vendor' : ''}
              placeholder="Vendor"
              name="vendor"
              value={this.state.formFields.vendor}
            />
            <TextField
              label="Note"
              fullWidth
              variant="standard"
              multiline
              rows={2}
              onChange={this.handleChange}
              placeholder="Note"
              name="note"
              value={this.state.formFields.note}
            />
            <TextField
              label="Tracking"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              placeholder="Tracking"
              name="tracking"
              value={this.state.formFields.tracking}
            />
            <TextField
              label="Credit Number"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              placeholder="Credit Number"
              name="credit_num"
              value={this.state.formFields.credit_num}
            />
            <TextField
              label="Credit Date"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              placeholder="MM-DD-YYYY"
              name="credit_dt"
              value={this.state.formFields.credit_dt}
            />
            <TextField
              label="Return Name"
              fullWidth
              variant="standard"
              onChange={this.handleChange}
              placeholder="Return Name"
              name="return_num"
              value={this.state.formFields.return_num}
            />
          </FormControl>
        </Grid>
        <Divider component="hr" />
        {actionButtons}
      </div>
    )
  }
}

FBAccountingDocsLabellingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  fileBound: stateFromStore.fileBound,
  lookups: stateFromStore.lookups
})

// might be needed in future
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCompletedDoc_ac,
      open_snack_ac,
      markAsComplete_ac,
      moveDocToTrash_ac,
      resetInProgressJob_ac,
      retryImport_ac,
      fetchFBAccountingDocs_ac,
      fetchFBAccountingCompletedDocs_ac
    },
    dispatch
  )

export const FBAccountingDocsLabelling = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBAccountingDocsLabellingComponent))
)
