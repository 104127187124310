export const actionTypes = {
  FETCHING_WORKORDERS: 'FETCHING_WORKORDERS',
  RECEIVED_WORKORDERS: 'RECEIVED_WORKORDERS',
  FAILED_FETCHING_WORKORDERS: 'FAILED_FETCHING_WORKORDERS',

  SAVE_WORKORDERS_SORTING: 'SAVE_WORKORDERS_SORTING',
  SAVE_WORKORDER_SEARCH_FILTERS: 'SAVE_WORKORDER_SEARCH_FILTERS',
};

export const fetchWorkorders_ac = (page = 0) => {
  return (dispatch, getState) => {
    const sorting = { ...getState().workorderSorting };

    const params = {
      ...getState().workorderSearchFilters,
    };

    dispatch({
      type: actionTypes.FETCHING_WORKORDERS,
    });

    window.sch
      .post('/api/wo/list', { ...params, ...sorting, page })
      .then((res) => {
        dispatch({
          type: actionTypes.RECEIVED_WORKORDERS,
          payload: res.pkg,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_WORKORDERS,
          error: error,
        });
      });
  };
};

export const saveWorkorderSearchFilters_ac = (filterData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_WORKORDER_SEARCH_FILTERS,
      filterData,
    });
  };
};

export const saveWorkorderSorting_ac = (filterData) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SAVE_WORKORDERS_SORTING,
      filterData,
    });
  };
};
