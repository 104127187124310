export const actionTypes = {
  FETCHING_IDX_ADMIN_PENDING_SLA_RULES: 'FETCHING_IDX_ADMIN_PENDING_SLA_RULES',
  RECEIVED_IDX_ADMIN_PENDING_SLA_RULES: 'RECEIVED_IDX_ADMIN_PENDING_SLA_RULES',
  FAILED_FETCHING_IDX_ADMIN_PENDING_SLA_RULES:
    'FAILED_FETCHING_IDX_ADMIN_PENDING_SLA_RULES',
  SET_IDX_ADMIN_PENDING_SLA_RULES: 'SET_IDX_ADMIN_PENDING_SLA_RULES'
};

export const fetchIdxAdminPendingSLARules_ac = (filters = {}) => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_PENDING_SLA_RULES
    });

    window.sch
      .post('/api/idx/admin/pending_sla_rule/list', filters)
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_PENDING_SLA_RULES,
          data: res.pkg
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_PENDING_SLA_RULES,
          error: error
        });
      });
  };
};

export const setIdxAdminPendingSLARules_ac = (newData = []) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_IDX_ADMIN_PENDING_SLA_RULES,
      data: newData
    });
  };
};
