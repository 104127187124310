import { IndexSummaryActionTypes } from './../actions/index_summary.ac';

export function indexSummaryInView(
    state = {
        isFetching: false,
        summary: null,
        error: null,
    },
    action
) {
    switch (action.type) {
        case IndexSummaryActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case IndexSummaryActionTypes.RECEIVED_INDEX_SUMMARY:
            return {
                ...state,
                summary: action.summary,
            };

        case IndexSummaryActionTypes.FAILED_RECEIVED_INDEX_SUMMARY:
            return {
                ...state,
                summary: null,
                error: action.error,
            };

        default:
            return state;
    }
};
