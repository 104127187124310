import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { Application } from './Application'
import OnBoardForm from './ClientAppsOnboardForm.js'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  fullHeight: {
    height: '100%'
  }
})

class ClientAppsOnboardLanding extends React.Component {
  render() {
    const { classes, clientAppsInView } = this.props

    return (
      <main className={classes.content}>
        <Grid
          container
          direction="row"
          className={classes.fullHeight}
          spacing={8}>
          <Grid item xs={12} md={6} className={classes.fullHeight}>
            <OnBoardForm />
          </Grid>
          <Grid item md={6} className={classes.fullHeight}>
            {clientAppsInView.client_apps && <Application />}
          </Grid>
        </Grid>
      </main>
    )
  }
}

ClientAppsOnboardLanding.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  clientAppsInView: stateFromStore.clientAppsInView
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClientAppsOnboardLanding))
)
