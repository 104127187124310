import { combineReducers } from 'redux';

import placesReducers, { initialStates as placesInitialStates } from './places';

import lookupsReducers, {
  initialStates as lookupsInitialStates,
} from './lookups';

import faRoutes, { initialState as faRoutesInitialStates } from './routes';

export const faAdminInitialStates = {
  ...placesInitialStates,
  ...lookupsInitialStates,
  faRoutes: faRoutesInitialStates,
};

export default combineReducers({
  ...placesReducers,
  ...lookupsReducers,
  faRoutes,
});
