export const actionTypes = {
  FETCH_FORM_BACKGROUNDS: 'FETCH_FORM_BACKGROUNDS',
  FETCH_FORM_BACKGROUNDS_SUCCESSFUL: 'FETCH_FORM_BACKGROUNDS_SUCCESSFUL',
  FETCH_FORM_BACKGROUNDS_FAILED: 'FETCH_FORM_BACKGROUNDS_FAILED',
  FETCH_FORM_BACKGROUND_DETAIL: 'FETCH_FORM_BACKGROUND_DETAIL',
  FETCH_FORM_BACKGROUND_DETAIL_SUCCESSFUL:
    'FETCH_FORM_BACKGROUND_DETAIL_SUCCESSFUL',
  FETCH_FORM_BACKGROUND_DETAIL_FAILED:
    'FETCH_FORM_BACKGROUND_DETAIL_FAILED_FAILED',
  CREATE_FORM_BACKGROUND: 'CREATE_FORM_BACKGROUND',
  CREATE_FORM_BACKGROUND_SUCCESSFUL: 'CREATE_FORM_BACKGROUND_SUCCESSFUL',
  CREATE_FORM_BACKGROUND_FAILED: 'CREATE_FORM_BACKGROUND_FAILED',
  UPDATE_FORM_BACKGROUND: 'UPDATE_FORM_BACKGROUND',
  UPDATE_FORM_BACKGROUND_SUCCESSFUL: 'UPDATE_FORM_BACKGROUND_SUCCESSFUL',
  UPDATE_FORM_BACKGROUND_FAILED: 'UPDATE_FORM_BACKGROUND_FAILED',
  REMOVE_FORM_BACKGROUND: 'REMOVE_FORM_BACKGROUND',
  REMOVE_FORM_BACKGROUND_SUCCESSFUL: 'REMOVE_FORM_BACKGROUND_SUCCESSFUL',
  REMOVE_FORM_BACKGROUND_FAILED: 'REMOVE_FORM_BACKGROUND_FAILED',
};

export function fetchFormBackgrounds_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_BACKGROUNDS });
    return window.sch
      .post('/api/forms/backgrounds/get', params)
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_BACKGROUNDS_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_BACKGROUNDS_FAILED,
          error,
        });
      });
  };
}

export function createFormBackground_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_FORM_BACKGROUND });
    return window.sch
      .post(`/api/forms/backgrounds/register`, params, true)
      .then((result) => {
        dispatch({
          type: actionTypes.CREATE_FORM_BACKGROUND_SUCCESSFUL,
          data: result.record,
        });
        if (onSuccess) {
          onSuccess(result.record);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE_FORM_BACKGROUND_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function updateFormBackground_ac(
  bgName,
  params,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FORM_BACKGROUND });
    return window.sch
      .post(`/api/forms/backgrounds/${bgName}/update`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.UPDATE_FORM_BACKGROUND_SUCCESSFUL,
          data: result.updates,
        });
        if (onSuccess) {
          onSuccess(result.updates);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_FORM_BACKGROUND_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function removeFormBackground_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_FORM_BACKGROUND });
    return window.sch
      .post(`/api/forms/backgrounds/delete`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_BACKGROUND_SUCCESSFUL,
          data: result,
          removed_id: params._id,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_BACKGROUND_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}
