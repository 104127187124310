import { actionTypes } from '../actions/report_recurring.ac';

const initialState = {
  report: {},
  error: null,
  isFetching: false,
};

export const recurringReport = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_REPORT_RECURRING:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.RECEIVED_REPORT_RECURRING:
      return {
        ...state,
        isFetching: false,
        report: action.payload,
      };

    case actionTypes.FAILED_FETCHING_REPORT_RECURRING:
      return {
        ...state,
        error: action.error,
      };

    default:
      return { ...state };
  }
};
