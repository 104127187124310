import React from 'react'
import { Card, CardContent, Chip, Grid } from '@material-ui/core'

import { TextInfo } from '.'
import { toTitleCase } from '../../../util/string'

const WosListSummary = props => {
  const {
    data: { method_name = {}, dc_nm = {} },
    setWosFilter,
    wosFilters,
  } = props

  const isEmpty =
    Object.keys(method_name).length + Object.keys(dc_nm).length === 0

  const sources = Object.entries({ method_name, dc_nm })

  const selectedSummary =
    Object.keys(wosFilters).length !== 0
      ? [...(wosFilters.dc_nm || []), ...(wosFilters.method_name || [])]
      : []

  return (
    <Card>
      <CardContent>
        {isEmpty ? (
          <TextInfo value={'No summary data to show'} />
        ) : (
          <Grid container spacing={8}>
            {sources.map(([idx, source]) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={idx}
                style={{
                  flexWrap: 'wrap',
                  display: 'flex',
                  height: 'fit-content',
                }}>
                {Object.entries(source)
                  .sort()
                  .map(([key, value]) => (
                    <Chip
                      clickable
                      key={key}
                      style={{
                        margin: 2,
                        fontSize: 12,
                      }}
                      label={
                        <span style={{ fontWeight: 600 }}>
                          {`${toTitleCase(key).replace(
                            /Supercare.*$/i,
                            ''
                          )} : `}
                          <span style={{ fontWeight: 400 }}>{value}</span>
                        </span>
                      }
                      color={
                        selectedSummary?.includes(key) ? 'primary' : 'default'
                      }
                      onClick={() => setWosFilter(idx, key)}
                    />
                  ))}
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

export default WosListSummary
