const possiblyEmail = (str) => str.includes('@');
const possiblyPhone = (str) => {
  // const phoneRegEx = /^1?-?(\([0-9]{3}\) [0-9]{3}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4})$/;

  const phoneRegEx =
    /^(\+1[0-9]{10}|1[0-9]{10}|1?-?(\([0-9]{3}\) [0-9]{3}-[0-9]{4}|[0-9]{3}-[0-9]{3}-[0-9]{4}))$/;

  return str.match(phoneRegEx) !== null;
};

export function masterSearch_ac(searchStr) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    if (searchStr.length < 3) {
      dispatch({
        type: 'REQUEST_SEARCH_RESULTS',
        isFetching: false,
        qualified: false,
        searchStr,
      });
      return;
    }

    dispatch({
      type: 'REQUEST_SEARCH_RESULTS',
      isFetching: true,
      qualified: true,
      searchStr,
    });

    //defaults:
    let postData = { searchStr };
    let target_url = `/api/master:search`;

    /* apply search pattern logic here */
    const searchString = searchStr.toLowerCase();
    const snPattern = searchString.substr(0, 3);
    const hdmsPattern = searchString.substr(0, 5);
    const hdmsRegEx = /^w\d{4}$|^m\d{4}$/;

    switch (true) {
      case possiblyEmail(searchString): {
        target_url = `/api/master:search_by_email`;
        return window.sch
          .post(target_url, { searchEmail: searchString })
          .then((results) => {
            dispatch({
              type: 'RECEIVED_SEARCH_RESULTS',
              searchStr,
              kind: results.kind,
              results: results.results,
              msg: results.msg,
              warning: results.warning,
              altRes: results.altRes,
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: 'FAILED_RECEIVED_SEARCH_RESULTS',
              searchStr,
              error,
              msg: '',
              warning: '',
            });
          });
      }
      case possiblyPhone(searchString): {
        target_url = `/api/master:search_by_phone`;
        return window.sch
          .post(target_url, { phoneNumber: searchString })
          .then((results) => {
            dispatch({
              type: 'RECEIVED_SEARCH_RESULTS',
              searchStr,
              kind: results.kind,
              results: results.results,
              msg: results.msg,
              warning: results.warning,
              altRes: results.altRes,
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: 'FAILED_RECEIVED_SEARCH_RESULTS',
              searchStr,
              error,
              msg: '',
              warning: '',
            });
          });
      }
      case searchString.search(/^- [0-9]{1,10} [a-z]/i) === 0: {
        target_url = `/api/master:search_by_addr`;
        return window.sch
          .post(target_url, { searchAddr: searchString.substr(2) })
          .then((results) => {
            dispatch({
              type: 'RECEIVED_SEARCH_RESULTS',
              searchStr,
              kind: results.kind,
              results: results.results,
              msg: results.msg,
              warning: results.warning,
              altRes: results.altRes,
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: 'FAILED_RECEIVED_SEARCH_RESULTS',
              searchStr,
              error,
              msg: '',
              warning: '',
            });
          });
      }

      case snPattern === 'sn:': {
        let sn = searchString.substr(3);
        target_url = `/api/dt/wos/search_by_sn/${sn}`;
        return window.sch
          .get(target_url)
          .then((results) => {
            dispatch({
              type: 'RECEIVED_SEARCH_RESULTS',
              searchStr,
              kind: results.kind,
              results: results.results,
              msg: '',
              warning: '',
              altRes: results.altRes,
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: 'FAILED_RECEIVED_SEARCH_RESULTS',
              searchStr,
              error,
              msg: '',
              warning: '',
            });
          });
      }
      case !!hdmsPattern.match(hdmsRegEx): {
        target_url = `/api/accounts_by_wos_id`;
        return window.sch
          .post(target_url, postData)
          .then((results) => {
            dispatch({
              type: 'RECEIVED_SEARCH_RESULTS',
              searchStr,
              kind: results.kind,
              results: results.results,
              msg: results.msg,
              altRes: results.altRes,
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: 'FAILED_RECEIVED_SEARCH_RESULTS',
              searchStr,
              error,
              msg: '',
              warning: '',
            });
          });
      }
      default:
        return window.sch
          .post(target_url, postData)
          .then((results) => {
            dispatch({
              type: 'RECEIVED_SEARCH_RESULTS',
              searchStr,
              kind: results.kind,
              results: results.results,
              msg: results.msg,
              potentialNewIDN: results.potentialNewIDN,
              in_hdms_only: results.in_hdms_only,
              warning: results.warning,
              altRes: results.altRes,
            });
          })
          .catch((error) => {
            console.error(error);
            dispatch({
              type: 'FAILED_RECEIVED_SEARCH_RESULTS',
              searchStr,
              error,
              msg: '',
              warning: '',
            });
          });
    }
  };
}

export function cancel_masterSearch_ac(searchStr) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'CANCEL_MASTER_SEARCH' });
  };
}
