export function fetchIvrInfo_ac(idnAccountPosition, acctMember) {
  return (dispatch) => { //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_IVR_INFO', acctMember, idnAccountPosition })
    let pkg = {}
    let target_url = `/api/expiring-cmn-oxy/record/get/${acctMember}`;
    return window.sch.get(target_url)
    .then( result => {
      pkg.oxy_cmn = result.pkg.oxy_cmn;
      target_url = `/api/expiring-ccs-mcal/record/get/${acctMember}`;
      return window.sch.get(target_url)
    })
    .then( result => {
      pkg.ccs_mcal_rx = result.pkg.ccs_mcal_rx;
      dispatch({ type: 'RECEIVED_IVR_INFO', pkg})
    })
    .catch( error => {
      console.error("error:",error)
      dispatch({ type: 'FAILED_RECEIVED_IVR_INFO', error})
    });
  }
}

export function updateIvrInfo_ac(params ) {
  return (dispatch) => { //params passed in by thunk middleware

    let target_url;
    switch(params.workflow){
      case 'ccsmedical_allproducts':
        target_url = `/api/expiring-ccs-mcal/record/update`;
        break;

      case 'medicare_oxygen':
      default:
        target_url = `/api/expiring-cmn-oxy/record/update`;
        break;
    }

    return window.sch.post(target_url, params)
    .then( result => {
      dispatch({ type: 'RECEIVED_IVR_INFO', pkg: result.pkg})
    })
    .catch( error => {
      console.error("error:",error)
      dispatch({ type: 'FAILED_RECEIVED_IVR_INFO', error})
    });
  }
}

export function cancelIvrActivity_ac(params) {
  /* params: cmn_tree_id, cmn_branch_id, appt_date_utc */
  return (dispatch) => { //params passed in by thunk middleware
    //dispatch({ type: 'REQUEST_IVR_INFO', acctMember: params.acctMember })

    let target_url;

    switch(params.workflow){
      case 'ccsmedical_allproducts':
        target_url = `/api/expiring-ccs-mcal/record/cancel_ivr`;
        break;

      case 'medicare_oxygen':
      default:
        target_url = `/api/expiring-cmn-oxy/record/cancel_ivr`;
        break;
    }


    return window.sch.post(target_url,params)
    .then( result => {
      dispatch({ type: 'RECEIVED_IVR_INFO', pkg: result.pkg})
    })
    .catch( error => {
      console.error("error:",error)
      dispatch({ type: 'FAILED_RECEIVED_IVR_INFO', error})
    });
  }
}
