//reducer for accountInView
export function accountInView(
  state = {
    isFetching: false,
    accountIdn: null,
    accountDocs: null,
    docListingViewType: 'Date',
    posOfDocSelected: null,

    workUnitDocs: null,
    posOfWorkUnitDocSelected: null,

    docKeySelected: null,
    fbDocs: null,
    fbDocsError: null,
    fbPathsFetching: false,
    fbDocsFetching: false,
    fbPathInView: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'SELECT_DOC_LISTING_VIEW_TYPE':
      return Object.assign({}, state, {
        docListingViewType: action.viewType,
      });

    case 'REQUEST_ACCOUNT_DOCS':
      return Object.assign({}, state, {
        isFetching: true,
        accountDocs: null,
        accountIdn: action.accountIdn,
      });
    case 'FAILED_RECEIVED_ACCOUNT_DOCS':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    case 'RECEIVED_ACCOUNT_DOCS':
      return Object.assign({}, state, {
        accountDocs: action.accountDocs,
        isFetching: false,
        error: null,
      });

    case 'ACCOUNT_DOC_IN_SET_CHANGED':
      return Object.assign({}, state, {
        accountDocs: action.accountDocs,
      });
    case 'POS_OF_DOC_SELECTED':
      return Object.assign({}, state, {
        posOfDocSelected: action.posOfDocSelected,
        docKeySelected: null,
      });
    case 'DOC_KEY_SELECTED':
      return Object.assign({}, state, {
        posOfDocSelected: null,
        docKeySelected: action.docKeySelected,
      });
    case 'ACCOUNT_AND_WORKUNIT_DOC_SELECTED': //used by dfa cards
      return Object.assign({}, state, {
        posOfWorkUnitDocSelected: action.posOfWorkUnitDocSelected,
        accountIdn: action.workUnitDocs.hdr.nm,
        workUnitDocs: action.workUnitDocs,
        wDoc: action.wDoc,
        isFetching: false,
      });
    case 'REQUEST_FB_DOCS':
      return Object.assign({}, state, {
        fbDocsFetching: true,
      });
    case 'REQUEST_FB_PATHS':
      return Object.assign({}, state, {
        fbPathsFetching: true,
      });
    case 'SET_FB_PATH':
      return Object.assign({}, state, {
        fbPathInView: action.path,
      });
    case 'RECEIVED_FB_DOCS':
      return Object.assign({}, state, {
        fbDocsFetching: false,
        fbPathsFetching: false,
        fbDocs: action.fbDocs,
        fbPathInView: null,
      });
    case 'FAILED_RECEIVED_FB_DOCS':
      return Object.assign({}, state, {
        fbDocsFetching: false,
        fbPathsFetching: false,
        fbDocsError: action.error,
      });

    case 'RESET_ACCOUNT_IN_VIEW':
      return Object.assign({}, state, {
        isFetching: false,
        accountIdn: null,
        accountDocs: null,
        workUnitDocs: null,
        posOfWorkUnitDocSelected: null,
        fbDocs: null,
        fbDocsError: null,
        fbPathsFetching: false,
        fbDocsFetching: false,
        fbPathInView: null,
        docListingViewType: 'Date',
        posOfDocSelected: null,
        docKeySelected: null,
        error: null,
      });
    default:
      return state;
  }
}

//reducer for docInView
export function docInView(
  state = {
    isFetching: false,
    docJob: null,
    docActivityInProgress: false,
    pagesAreLoading: false,
    docActivity: null,
    docJobKey: null,
  },
  action
) {
  switch (action.type) {
    case 'DOC_RESET':
      return Object.assign({}, state, {
        isFetching: false,
        posInSet: null,
        docJob: null,
        docActivityInProgress: false,
        pagesAreLoading: false,
        docActivity: null,
        docJobKey: null,
      });
    case 'DOC_SELECTED_FOR_VIEWER':
      return Object.assign({}, state, {
        pagesAreLoading: true,
        posInSet: action.posInSet,
        docJob: action.docJob,
        docJobKey: action.docJobKey,
        docActivityInProgress: false,
        docActivity: null,
        isFetching: true,
      });
    case 'PAGES_LOADING': {
      return Object.assign({}, state, {
        pagesAreLoading: true,
      });
    }
    case 'PAGES_LOADED': {
      return Object.assign({}, state, {
        pagesAreLoading: false,
      });
    }
    case 'DOC_ACTIVITY_STARTED':
      return Object.assign({}, state, {
        docActivityInProgress: true,
      });
    case 'DOC_JOB_CHANGED':
      return Object.assign({}, state, {
        docJob: action.docJob,
        docActivityInProgress: false,
        docActivity: null,
      });
    case 'DOC_ACTIVITY_UPDATED':
      return Object.assign({}, state, {
        docActivityInProgress: false,
        docActivity: action.docActivity,
      });
    default:
      return state;
  }
}

//reducer for accountInView
export function accountPAsInView(
  state = {
    isFetching: false,
    account: null,
    member: null,
    pasPkg: null,
    posOfDocSelected: null,
    highlightActivity: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'ACCOUNT_PAS_HIGHLIGHT':
      return Object.assign({}, state, {
        pasPkg: action.pasPkg,
        highlightActivity: {
          hcpc: action.hcpc,
          date: action.date,
        },
      });

    case 'REQUEST_ACCOUNT_PAS':
      return Object.assign({}, state, {
        isFetching: true,
        pasPkg: null,
        highlightActivity: null,
        account: action.account,
        member: action.member,
      });
    case 'FAILED_RECEIVED_ACCOUNT_PAS':
      return Object.assign({}, state, {
        isFetching: false,
        highlightActivity: null,
        error: action.error,
      });
    case 'RECEIVED_ACCOUNT_PAS':
      return Object.assign({}, state, {
        pasPkg: action.pasPkg,
        isFetching: false,
        highlightActivity: null,
        account: action.account || state.account,
        member: action.member || state.member,
        error: null,
      });
    case 'RESET_ACCOUNT_PAS': //used by dfa cards
      return Object.assign({}, state, {
        account: null,
        member: null,
        pasPkg: null,
        error: null,
        highlightActivity: null,
        isFetching: false,
      });

    case 'ACCOUNT_PA_BRANCH_SELECTERD':
      /* receive a pasPkg with branch marked */
      return Object.assign({}, state, {
        pasPkg: action.pasPkg,
      });
    default:
      return state;
  }
}

//reducer for accountInView
export function accountCMNsInView(
  state = {
    isFetching: false,
    account: null,
    member: null,
    cmnsPkg: null,
    posOfDocSelected: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_ACCOUNT_CMNS':
      return Object.assign({}, state, {
        isFetching: true,
        cmnsPkg: null,
        account: action.account,
        member: action.member,
      });
    case 'FAILED_RECEIVED_ACCOUNT_CMNS':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    case 'RECEIVED_ACCOUNT_CMNS':
      return Object.assign({}, state, {
        cmnsPkg: action.cmnsPkg,
        isFetching: false,
        error: null,
      });
    case 'RESET_ACCOUNT_CMNS': //used by dfa cards
      return Object.assign({}, state, {
        account: null,
        member: null,
        error: null,
        cmnsPkg: null,
        isFetching: false,
      });
    default:
      return state;
  }
}

export function accountConsent(
  state = {
    isFetching: false,
    acctMember: null,
    consentRecord: null,
    events: [],
    hdms: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'UPDATED_HDMS_FIELD_VALUE':
      return Object.assign({}, state, {
        hdms: action.hdms,
        consentRecord: action.consentRecord,
      });
    case 'RECEIVED_MODIFIED_CONSENT_RECORD':
      return Object.assign({}, state, {
        consentRecord: action.consentRecord,
        events: [...state.events, ...action.events_logged],
      });
    case 'REQUEST_ACCOUNT_CONSENT':
      return Object.assign({}, state, {
        isFetching: true,
        acctMember: null,
        consentRecord: null,
        hdms: null,
        error: null,
      });
    case 'FAILED_RECEIVED_ACCOUNT_CONSENT':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error,
      });
    case 'RECEIVED_ACCOUNT_CONSENT':
      return Object.assign({}, state, {
        reference: action.reference,
        consentRecord: action.consentRecord,
        hdms: action.hdms,
        events: action.events,
        isFetching: false,
        error: null,
      });
    case 'SILENT_RESET_OF_CONSENT_RECORD':
      return Object.assign({}, state, {
        consentRecord: null,
      });
    default:
      return state;
  }
}
