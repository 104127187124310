import {
  Button,
  Modal,
  IconButton,
  Grid,
  Typography
} from '@material-ui/core'
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'


export const MaskViewModal = (props) => {


  return (
    <Modal
      open={props.isModalOpen}
      onClose={props.handleCloseClick}
    >
      <div className={props.classes.modalWrapper} >
        <Grid
          container
          className={props.classes.modalStickyComponentBottom}
        >
          <Grid container item>
            <Typography
              align="center"
              variant="h5"
              color="primary"
              style={{ width: '100%', padding: 8 }}
            >
              <strong> {props.mask_type.toUpperCase()}</strong>
            </Typography>
            <Typography
              className={props.classes.pageStatement}
              color="textSecondary"
              paragraph={true}
            >
              {props.help_text}
            </Typography>
          </Grid>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton style={{ padding: 8 }} onClick={props.handleCloseClick}>
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        <div style={{ padding: 8 }}>
          <Grid container spacing={16}>
            <Grid item>
              <img src={props.selected_mask} alt="mask_image.png" width="100%"></img>
            </Grid>
          </Grid>
          <div />
        </div>
        <Grid
          container
          justify="flex-end"
          className={props.classes.modalStickyComponentTop}
        >
          <Grid item style={{ padding: 8 }}>
            <Button
              variant="contained"
              onClick={props.handleCloseClick}
              color="primary"
              size="small"
            >
              close
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}
