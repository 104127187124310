export function ubList(
  state={
    records: null,
    params: null,
    isFetching:false,

    activityUpdating: false,
    activityParams: null,
    posOfActivity: 0,
    sarDoc: null,
    stats: null,

    isFetchingCcsPkg: false,
    ccsAccountInView: null,
    ccsPkg: null,
    ccsPkgError:null
  },
  action
) {
  switch (action.type){
    case 'MARK_UB':
      return Object.assign({}, state, {
        activityParams: action.activityParams,
        activityUpdating: true
       })
    case 'RES_MARK_UB':
      return Object.assign({}, state, {
        activityParams: null,
        activityUpdating:false
      })

    case 'ERR_MARK_UB':
      return Object.assign({}, state, {
        activityParams: null,
        activityUpdating:false,
        error: action.error
      })


    case 'REQUEST_UB_LIST':
      return Object.assign({}, state, {
         params: action.params,
         isFetching: true
       })

    case 'RESPONSE_UB_LIST':
      return Object.assign({}, state, {
         isFetching: false,
         records: action.records,
         pagination: action.pagination
         //stats: action.stats
       })
    case 'FAILED_UB_LIST':
      return Object.assign({}, state, {
         isFetching: false,
         records: [],
         error: action.error
       })
    case 'GET_SAR_DOC':
      return Object.assign({}, state, {
        sarDoc: null
      })

    case 'GOT_SAR_DOC':
      return Object.assign({}, state, {
         sarDoc: action.sarDoc
       })

    case 'REQUEST_UB_STATS':
      return Object.assign({}, state, {
         isFetching: true
       })

    case 'RESPONSE_UB_STATS':
      return Object.assign({}, state, {
         isFetching: false,
         error:null,
         stats: action.stats
       })

    case 'FAILED_UB_STATS':
      return Object.assign({}, state, {
         isFetching: false,
         error: action.error
       })

    case 'REQUEST_CCS_PKG':
      return Object.assign({}, state, {
        ccsPkg: null,
        sarDoc:null,
        isFetchingCcsPkg: true,
         ccsAccountInView: action.account
       })

    case 'RESPONSE_CCS_PKG':
      return Object.assign({}, state, {
         isFetchingCcsPkg: false,
         ccsPkg: action.ccsPkg,
         ccsPkgError:null
       })

    case 'FAILED_CCS_PKG':
      return Object.assign({}, state, {
         isFetchingCcsPkg: false,
         ccsPkgError: action.error
       })

     case 'RESET_UB_LIST':
      return Object.assign({}, state, {
        records: null,
        error:null,
        params: null,
        isFetching:false,

        activityUpdating: false,
        activityParams: null,
        posOfActivity: 0,
        sarDoc: null,
        stats: null,
        isFetchingCcsPkg: false,
        ccsAccountInView: null,
        ccsPkg: null,
        ccsPkgError:null
      })

    default:
      return state
  }
}
