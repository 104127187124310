import React from 'react'

import { Divider, Typography } from '@material-ui/core'

import IdxOrderingCliniciansTable from './IdxOrderingCliniciansTable'

const IdxOrderingCliniciansList = props => {
  return (
    <div
      id="locationsSearchContainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'auto'
      }}>
      <div>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontSize: '12px' }}>
          <strong>Ordering Clinicians</strong>
        </Typography>
        <Divider />
      </div>
      <div style={{ height: '100%', overflowY: 'auto' }}>
        <IdxOrderingCliniciansTable {...props} />
      </div>
    </div>
  )
}

export default IdxOrderingCliniciansList
