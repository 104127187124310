import React from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

const CountViewTable = props => {
  const {
    headerList,
    categories,
    cellList,
    isCountLink,
    getCountLink,
    totalCounts
  } = props;

  const stickyHeaderStyle = {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: '#efefef',
    padding: '8px'
  };

  return (
    <Table padding="dense" style={{ borderCollapse: 'separate' }}>
      <TableHead>
        <TableRow>
          {headerList
            .map((header, idx) => (
              <TableCell
                key={idx}
                align={header !== 'Sales Category' ? 'right' : 'left'}
                style={stickyHeaderStyle}
              >
                <Typography>
                  <strong>{header}</strong>
                </Typography>
              </TableCell>
            ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {categories
          .map((category, rowIdx) => (
            <TableRow key={rowIdx}>
              <TableCell
                style={{
                  backgroundColor: '#f7f7f7',
                  borderRight: '1px solid #e5e5e5',
                  padding: '8px'
                }}
              >
                <Typography>
                  <strong>{category}</strong>
                </Typography>
              </TableCell>
              {cellList
                .map((cell, cellIdx) => (
                  cell.category === category
                    ? (
                      <TableCell
                        key={`${category}_${rowIdx}_${cellIdx}`}
                        align="right"
                        style={{ padding: '8px' }}
                      >
                        <Typography variant="body2">
                          {isCountLink(cell)
                            ? (
                              <Link to={getCountLink(cell)}>
                                {cell.count}
                              </Link>)
                            : cell.count}
                        </Typography>
                      </TableCell>
                    )
                    : null
                ))}
            </TableRow>
          ))}
        {totalCounts && (
          <TableRow style={{ backgroundColor: '#ff910010' }}>
            <TableCell
              style={{
                backgroundColor: '#f7f7f730',
                borderRight: '1px solid #e5e5e5',
                padding: '8px'
              }}
            >
              <Typography>
                <strong>All</strong>
              </Typography>
            </TableCell>
            {headerList.slice(1).map((header, hIdx) => (
              <TableCell
                key={hIdx}
                align="right"
                style={{ padding: '8px' }}
              >
                <Typography variant="body2">
                  {isCountLink(totalCounts[header]) ? (
                    <Link to={getCountLink({
                      category: 'ALL',
                      ...totalCounts[header]
                    })}>
                      {totalCounts[header].count}
                    </Link>
                  ) :
                    totalCounts[header].count}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default CountViewTable;
