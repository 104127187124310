import actionTypes from '../actionTypes'


const initialState = {
  list: [],
  showDrawer: false,
  history:[],
  preview: {
    inProgress: false,
    result: null,
    error: null
  },
  convert: {
    inProgress: false,
    result: null,
    error: null
  }
}

const onDoc = {
  add: (state, action) => ({
    ...state,
    list: state.list.concat(action.doc),
    convert: {
      // To clear drawer
      ...initialState.convert
    }
  }),
  remove: (state, action) => {
    let list
    if(action.index === 0)
      list = state.list.slice(1)
    else if(action.index === state.list.length-1)
      list = state.list.slice(0, state.list.length-1)
    else
      list = state.list.slice(0, action.index).concat(state.list.slice(action.index+1))

    return {
      ...state,
      list
    }
  },
  moveUp: (state, action) => {
    let list = state.list.slice(0)
    if(action.index === 0); // No need to do anything
    else if(action.index === 1) {
      let first = list.shift()
      let item = list.shift()
      list = [ item, first, ...list ]
    } else {
      let head = list.slice(0, action.index)
      let rest = list.slice(action.index+1)
      let swap = head.pop()
      list = [ ...head, state.list[action.index], swap, ...rest ]
    }

    return {
      ...state,
      list
    }
  },
  moveDown: (state, action) => {
    let list = state.list.slice(0)
    if(action.index === state.list.length-1); // No need to do anything
    else if(action.index === state.list.length-2) {
      let last = list.pop()
      let item = list.pop()
      list = [ ...list, last, item ]
    } else {
      let head = list.slice(0, action.index)
      let rest = list.slice(action.index+1)
      let swap = rest.shift()
      list = [ ...head, swap, state.list[action.index], ...rest]
    }

    return {
      ...state,
      list
    }
  }
}

const onConvert = {
  try: (state, action) => {
    return action.list ? {
      ...state,
      list: action.list,
      convert: {
        ...initialState.convert,
        inProgress: true
      }
    } : {
      ...state,
      convert: {
        ...initialState.convert,
        inProgress: true
      }
    }
  },
  success: (state, action) => ({
    ...state,
    list: [],
    history: state.history.concat(action.result),
    convert: {
      ...state.convert,
      inProgress: false,
      result: action.result
    }
  }),
  fail: (state, action) => ({
    ...state,
    convert: {
      ...state.convert,
      inProgress: false,
      error: action.error
    }
  })
}

const onPreview = {
  try: (state, action) => {
    return action.list ? {
      ...state,
      list: action.list,
      showDrawer: false,
      preview: {
        ...initialState.preview,
        inProgress: true
      }
    } : {
      ...state,
      showDrawer: false,
      preview: {
        ...initialState.preview,
        inProgress: true
      }
    }
  },
  success: (state, action) => ({
    ...state,
    list: [],
    history: state.history.concat(action.result),
    preview: {
      ...state.preview,
      inProgress: false,
      result: action.result
    }
  }),
  fail: (state, action) => ({
    ...state,
    preview: {
      ...state.preview,
      inProgress: false,
      error: action.error
    }
  })
}


export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.pdfGen.doc.add:
      return onDoc.add(state, action)
    case actionTypes.pdfGen.doc.remove:
      return onDoc.remove(state, action)
    case actionTypes.pdfGen.doc.moveUp:
      return onDoc.moveUp(state, action)
    case actionTypes.pdfGen.doc.moveDown:
      return onDoc.moveDown(state, action)
    case actionTypes.pdfGen.convert.try:
      return onConvert.try(state, action)
    case actionTypes.pdfGen.convert.success:
      return onConvert.success(state, action)
    case actionTypes.pdfGen.convert.fail:
      return onConvert.fail(state, action)

    case actionTypes.pdfGen.preview.try:
      return onPreview.try(state, action)
    case actionTypes.pdfGen.preview.success:
      return onPreview.success(state, action)
    case actionTypes.pdfGen.preview.fail:
      return onPreview.fail(state, action)

    case actionTypes.pdfGen.pagePicker: {
      let list = state.list.slice(0)
      list[action.listIndex].showPagePicker = !list[action.listIndex].showPagePicker
      return { ...state, list }
    }
    case actionTypes.pdfGen.page.toggle: {
      let list = state.list.slice(0)
      let page = list[action.listIndex].selectedPages[action.pageIndex]
      page.active = !page.active
      list[action.listIndex].pageSelectorsRevealed = true
      return  { ...state, list }
    }
    case actionTypes.pdfGen.clearDrawer:
      return {
        ...state,
        list: [],
        convert: { ...initialState.convert }
      }
    case actionTypes.pdfGen.toggleDrawer:
      return { ...state, showDrawer: !state.showDrawer }
    case actionTypes.pdfGen.openDrawer:
      return { ...state, showDrawer: true }
    case actionTypes.pdfGen.closeDrawer:
      return { ...state, showDrawer: false }
    default:
      return state
  }
}
