import {
  Divider,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import { searchSNLookup_ac } from '../../actions/sn_lookup.ac.js'
import { SNLookupTableView } from './SNLookup'
import { SNLookupForm } from './SNLookupForm.js'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: `0px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  fullHeight: {
    height: '100%'
  },
  errorMessage: {
    fontSize: '1em',
    padding: '8px'
  }
})
const PAGE_TITLE = 'SN Lookup'

class SNLookupLandingComponent extends Component {
  componentDidMount() {
    const { lookups, setTitle_ac } = this.props
    if (!this.props.me.user) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }
    setTitle_ac(PAGE_TITLE)
  }

  componentDidUpdate() {
    const { lookups, me, setTitle_ac } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }
    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE)
    }
  }

  find_sn_lookup = searchObj => {
    this.props.searchSNLookup_ac(searchObj)
  }

  render() {
    const { classes, snlookupsinview } = this.props

    return (
      <div className={classes.content}>
        <Typography variant="subtitle1" color="textPrimary">
          <strong>Find Serials by</strong>
        </Typography>
        <SNLookupForm
          isFetching={snlookupsinview.isFetching}
          onSubmit={this.find_sn_lookup}
        />
        <Divider component="hr" />
        <SNLookupTableView
          classes={classes}
          snlookupsinview={snlookupsinview}
        />
      </div>
    )
  }
}

SNLookupLandingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  snlookupsinview: stateFromStore.snlookupsinview
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
      searchSNLookup_ac
    },
    dispatch
  )

export const SNLookupLanding = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SNLookupLandingComponent))
)
