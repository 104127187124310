import _ from 'lodash'

export const actionTypes = {
  FETCHING_IDX_ADMIN_WORKUNIT_TYPES: 'FETCHING_IDX_ADMIN_WORKUNIT_TYPES',
  RECEIVED_IDX_ADMIN_WORKUNIT_TYPES: 'RECEIVED_IDX_ADMIN_WORKUNIT_TYPES',
  FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPES: 'FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPES',

  SET_SELECTED_WORKUNIT_TYPE: 'SET_SELECTED_WORKUNIT_TYPE',

  FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS: 'FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS',
  RECEIVED_IDX_ADMIN_WORKUNIT_TYPE_DETAILS: 'RECEIVED_IDX_ADMIN_WORKUNIT_TYPE_DETAILS',
  FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS: 'FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS'
}

export const fetchIdxAdminWorkunitTypes_ac = (page = 0) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_WORKUNIT_TYPES
    })

    window.sch
      .post('/api/idx/admin/wut/list', { page })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_WORKUNIT_TYPES,
          workunitTypes: res.pkg
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPES,
          error: error
        })
      })
  }
}

export const setSelectedWorkunitTypeId_ac = workunitTypeId => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SELECTED_WORKUNIT_TYPE,
      workunitTypeId: workunitTypeId
    })
  }
}

export const fetchWorkunitTypeDetials_ac = workunitTypeId => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS
    })

    window.sch
      .post('/api/idx/admin/wut/detail', { _id: workunitTypeId })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_WORKUNIT_TYPE_DETAILS,
          workunitType: res.workunitType,
          existingRelations: res.existing_rels
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS,
          error
        })
      })
  }
}

export const updateWorkunitTypeDetailsLocally_ac = updateObj => {
  return (dispatch, getState) => {
    if (!updateObj || !_.isObject(updateObj)) return

    let workunitTypeDetails = getState().idxAdminWorkunitTypeDetails

    if (!workunitTypeDetails.workunitType) return

    dispatch({
      type: actionTypes.RECEIVED_IDX_ADMIN_WORKUNIT_TYPE_DETAILS,
      workunitType: {
        ...workunitTypeDetails.workunitType,
        ...updateObj
      }
    })
  }
}

export const updateWorkunitTypesListLocally_ac = (workunitTypeData, remove = false) => {
  return (dispatch, getState) => {
    if (!workunitTypeData || !_.isObject(workunitTypeData)) return

    let workunitTypesState = getState().idxAdminWorkunitTypes

    if (!workunitTypesState.workunitTypes) return

    let workunitTypeIdx = workunitTypesState.workunitTypes.rr
      .findIndex(loc => loc._id === workunitTypeData._id)

    if (workunitTypeIdx < 0) return

    let updatedList

    if (remove) {
      updatedList = [
        ...workunitTypesState.workunitTypes.rr.slice(0, workunitTypeIdx),
        ...workunitTypesState.workunitTypes.rr.slice(workunitTypeIdx + 1)
      ]
    }
    else {
      updatedList = [
        ...workunitTypesState.workunitTypes.rr.slice(0, workunitTypeIdx),
        {
          ...workunitTypesState.workunitTypes.rr[workunitTypeIdx],
          ...workunitTypeData
        },
        ...workunitTypesState.workunitTypes.rr.slice(workunitTypeIdx + 1)
      ]
    }

    dispatch({
      type: actionTypes.RECEIVED_IDX_ADMIN_WORKUNIT_TYPES,
      workunitTypes: {
        ...workunitTypesState.workunitTypes,
        rr: updatedList
      }
    })
  }
}
