import * as slaWorkTime from 'sla_work_time';

const { configure, addMinutes, setSkipDays } = slaWorkTime;

const baseMinuteAddOpts = {
  toNativeDate: false,
};

configure({
  // 8AM to 6:30PM
  startAM: 8,
  endPM: 18.5,
});

// Hardcode some holidays for now
const holidays = [
  {
    startDate: '2023-05-23T00:00:00.000Z',
  },
];
setSkipDays(holidays);

const addSLAMinutes = (date, minutes, workHours) => {
  return addMinutes(date, minutes, {
    ...baseMinuteAddOpts,
    workHours,
  });
};

export { addSLAMinutes };
