import { serialize } from '../util/object';

export const actionTypes = {
  FETCH_FA_ROUTES_PENDING: 'FETCH_FA_ROUTES_PENDING',
  FETCH_FA_ROUTES_SUCCESSFUL: 'FETCH_FA_ROUTES_SUCCESSFUL',
  FETCH_FA_ROUTES_FAILED: 'FETCH_FA_ROUTES_FAILED',

  SET_FA_ROUTE_PENDING: 'SET_FA_ROUTE_PENDING',
  SET_FA_ROUTE_SUCCESSFUL: 'SET_FA_ROUTE_SUCCESSFUL',
  SET_FA_ROUTE_FAILED: 'SET_FA_ROUTE_FAILED',
};

export function fetchFARoute_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FA_ROUTES_PENDING });
    return window.sch
      .get(`/api/fa/admin/routes/get?` + serialize(params))
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FA_ROUTES_SUCCESSFUL,
          data: result.pkg,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FA_ROUTES_FAILED,
          error,
        });
      });
  };
}

export function setFARoute_ac(params, { onSuccess, onError }) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_FA_ROUTE_PENDING });
    return window.sch
      .post(`/api/fa/admin/routes/set`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.SET_FA_ROUTE_SUCCESSFUL,
          data: result.data,
          created: result.created,
        });

        if (onSuccess) {
          onSuccess(result);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_FA_ROUTE_FAILED,
          error,
        });

        if (onError) {
          onError(error);
        }
      });
  };
}
