export const actionTypes = {
  FETCHING_IDX_ORDERING_CLINICIANS: 'FETCHING_IDX_ORDERING_CLINICIANS',
  RECEIVED_IDX_ORDERING_CLINICIANS: 'RECEIVED_IDX_ORDERING_CLINICIANS',
  FAILED_FETCHING_IDX_ORDERING_CLINICIANS:
    'FAILED_FETCHING_IDX_ORDERING_CLINICIANS',

  REMOVE_IDX_ORDERING_CLINICIANS: 'REMOVE_IDX_ORDERING_CLINICIANS',
  REMOVE_IDX_ORDERING_CLINICIANS_SUCCESSFUL:
    'REMOVE_IDX_ORDERING_CLINICIANS_SUCCESSFUL',
  REMOVE_IDX_ORDERING_CLINICIANS_PENDING:
    'REMOVE_IDX_ORDERING_CLINICIANS_PENDING',
  REMOVE_IDX_ORDERING_CLINICIANS_FAILED:
    'REMOVE_IDX_ORDERING_CLINICIANS_FAILED',

  SAVE_ORDERING_CLINICIAN_SEARCH_FILTERS:
    'SAVE_ORDERING_CLINICIAN_SEARCH_FILTERS',

  SAVE_ORDERING_CLINICIAN_SORTING: 'SAVE_ORDERING_CLINICIAN_SORTING'
};

export const fetchIdxOrderingClinicians_ac = (filterData = null, page = 0) => {
  return (dispatch, getState) => {
    let params = filterData;
    const sorting = getState().idxAdminOrderingClinicians.sorting;

    if (filterData === null) {
      // for page change in ordering clinicians table
      params = { ...getState().idxAdminOrderingClinicians.filters };
    }

    dispatch({
      type: actionTypes.FETCHING_IDX_ORDERING_CLINICIANS
    });

    window.sch
      .post('/api/idx/admin/oc/search', { ...params, sorting, page })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ORDERING_CLINICIANS,
          data: res.pkg
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ORDERING_CLINICIANS,
          error: error
        });
      });
  };
};

export const removeIdxOrderingClinicians_ac = (
  _id,
  { onSuccess, onError } = {}
) => {
  return dispatch => {
    dispatch({
      type: actionTypes.REMOVE_IDX_ORDERING_CLINICIANS_PENDING
    });

    window.sch
      .post('/api/idx/admin/oc/remove', { _id })
      .then(() => {
        dispatch({
          type: actionTypes.REMOVE_IDX_ORDERING_CLINICIANS_SUCCESSFUL,
          data: _id
        });
        if (onSuccess) onSuccess();
      })
      .catch(error => {
        dispatch({
          type: actionTypes.REMOVE_IDX_ORDERING_CLINICIANS_FAILED,
          error: error
        });
        if (onError) onError(error);
      });
  };
};

export const saveOrderingCliniciansSearchFilters_ac = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_ORDERING_CLINICIAN_SEARCH_FILTERS,
      filterData
    });
  };
};

export const saveOrderingClinicianSorting = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_ORDERING_CLINICIAN_SORTING,
      filterData
    });
  };
};
