export const ParachuteReportActionTypes = {
  RECEIVED_PARACHUTE_REPORTS: 'RECEIVED_PARACHUTE_REPORTS',
  UPDATE_RECEIVED_PARACHUTE_REPORTS: 'UPDATE_RECEIVED_PARACHUTE_REPORTS',
  FAILED_RECEIVING_PARACHUTE_REPORTS: 'FAILED_RECEIVING_PARACHUTE_REPORTS',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
};
export function fetchParachuteReport_ac(params) {
  return (dispatch) => {
    dispatch(fetching(true));
    return window.sch
      .post('/api/parachute/orders/list', { ...params })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: ParachuteReportActionTypes.RECEIVED_PARACHUTE_REPORTS,
          records: result.pkg.rr,
          pagination: result.pkg.pagination,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParachuteReportActionTypes.FAILED_RECEIVING_PARACHUTE_REPORTS,
          error,
        });
      })
      .finally(() => {
        dispatch(fetching(false));
      });
  };
}
export const updateParachuteReport_ac = (updatedDoc) => ({
  type: ParachuteReportActionTypes.UPDATE_RECEIVED_PARACHUTE_REPORTS,
  updatedDoc,
});

export const fetching = (isFetching) => ({
  type: ParachuteReportActionTypes.SET_IS_FETCHING,
  isFetching,
});
