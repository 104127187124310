//reducer for dfaInView
export function masterSearch(
  state = {
    searchInProgress: false,
    isFetching: false,
    searchStr: '',
    in_hdms_only: false,
    potentialNewIDN: false,
    results: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_SEARCH_RESULTS':
      return Object.assign({}, state, {
        isFetching: action.isFetching,
        searchStr: action.searchStr,
        qualified: action.qualified,
        potentialNewIDN: false,
        searchInProgress: true,
        in_hdms_only: false,
        msg: '',
        warning: action.warning || ''
      })
    case 'FAILED_RECEIVED_SEARCH_RESULTS':
      return Object.assign({}, state, {
        isFetching: false,
        results: [],
        searchStr: action.searchStr,
        potentialNewIDN: false,
        error: action.error,
        in_hdms_only: false,
        msg: '',
        warning: ''
      })
    case 'RECEIVED_SEARCH_RESULTS':
      return Object.assign({}, state, {
        results: action.results,
        kind: action.kind,
        searchStr: action.searchStr,
        searchInProgress: true,
        potentialNewIDN: action.potentialNewIDN,
        isFetching: false,
        in_hdms_only: action.in_hdms_only,
        msg: action.msg || '',
        altRes: action.altRes,
        warning: action.warning || ''
      })
    case 'CANCEL_MASTER_SEARCH': //reset the store
      return Object.assign({}, state, {
        results: null,
        kind: null,
        qualified: false,
        searchStr: '',
        in_hdms_only: false,
        potentialNewIDN: false,
        searchInProgress: false,
        isFetching: false,
        msg: '',
        warning: ''
      })
    default:
      return state
  }
}
