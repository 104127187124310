import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { queryActivityLogAudit_ac } from '../../actions/activity_log_audit.ac';
import PaginationWithReset from '../IndexingAdmin/common/PaginationWithReset';
import { DISPLAY_DATE_TIME_FORMAT } from '../WorkUnits/constants';
import CustomTable from '../common/CustomTable';
import Filters from './Filters';

const styles = () => ({
  field: {
    fontSize: 11,
    backgroundColor: '#ffffff',
    '&:focus': {
      backgroundColor: '#ffffff',
    },
  },
  selectInput: {
    padding: 8,
    fontSize: 11,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  formContainer: {
    padding: 4,
    marginBottom: 10,
  },
  formControl: {
    flexDirection: 'column',
    marginRight: 24,
    fontSize: 11,
    width: 250,
  },
  checkbox: {
    padding: 2,
    fontSize: 11,
  },
  formLabel: {
    marginRight: 8,
    fontSize: 11,
  },
  date: {
    width: '100%',
    padding: '3px 8px',
    borderRadius: '4px',
    margin: '8px 0 4px 0',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
});

const CODES_FOR_AUDIT = ['dpdf', 'searchresult', 'vp'];
const initialFilters = {
  startDate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  user: '',
  patient: '',
  by: 'user',
  codes: CODES_FOR_AUDIT,
};

const Entry = (props) => {
  const { entry } = props;

  return (
    <div
      style={{
        marginBottom: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
      }}>
      <Typography variant="caption" style={{ fontSize: 11 }}>
        {entry.nm}
      </Typography>
      <Typography
        variant="caption"
        style={{ fontSize: 12, marginLeft: '6px', color: 'red' }}>
        {'@'}
      </Typography>
      <Typography
        variant="caption"
        style={{ fontSize: 10, marginLeft: '6px', color: 'blue' }}>
        {moment(entry.ts).format(DISPLAY_DATE_TIME_FORMAT)}
      </Typography>
    </div>
  );
};

const EntryList = (props) => {
  const { entries } = props;
  const chunk = entries.slice(0, 3);
  const rest = entries.slice(3);

  const [isShowingMore, setIsShowingMore] = React.useState(false);

  return (
    <div>
      <div
        style={{
          maxHeight: 300,
          overflow: 'auto',
          overflowX: 'hidden',
        }}>
        {chunk.map((entry, index) => {
          return <Entry key={index} entry={entry} />;
        })}
        {isShowingMore &&
          rest.map((entry, index) => {
            return <Entry key={index} entry={entry} />;
          })}
      </div>

      {Boolean(rest.length) && (
        <div style={{ marginTop: 4 }}>
          <Typography
            variant="caption"
            style={{
              fontSize: 14,
              marginTop: '4px',
              color: '#9c27b0',
              cursor: 'pointer',
            }}
            onClick={() => {
              setIsShowingMore(!isShowingMore);
            }}>
            {isShowingMore ? 'Collapse' : `Show ${rest.length} more`}
          </Typography>
        </div>
      )}
    </div>
  );
};

const columns = [
  {
    title: 'ID',
    dataIndex: '_id',
    key: '_id',
  },
  {
    title: 'Activity Code',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: 'Entries',
    dataIndex: 'entries',
    key: 'entries',
    render: (data) => {
      const entries = data?.entries || [];

      return <EntryList entries={entries} />;
    },
  },
];

const ActivityLogAudit = (props) => {
  const { activityLogAudit, classes } = props;

  const { results } = activityLogAudit;
  const { data, pagination, isFetching } = results;

  const [filters, setFilters] = React.useState({
    ...initialFilters,
    ...props.initialFilters,
  });
  const [toGoPage, setToGoPage] = React.useState(0);
  const [isExpanded, setIsExpanded] = React.useState(true);

  const handlePageChange = (newPage) => {
    if (isFetching) {
      return;
    }

    setToGoPage(newPage - 1);
  };

  React.useEffect(() => {
    props.queryActivityLogAudit_ac({
      ...filters,
      page: toGoPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toGoPage]);

  const handleSearch = () => {
    if (toGoPage !== 0) setToGoPage(0);
    else {
      props.queryActivityLogAudit_ac({
        ...filters,
        page: 0,
      });
    }
  };

  const handleReset = () => {
    setFilters(initialFilters);

    if (toGoPage !== 0) setToGoPage(0);
    else {
      props.queryActivityLogAudit_ac({
        ...initialFilters,
        page: 0,
      });
    }
  };

  const handleCSV = () => {
    const { startDate, endDate, user, patient, by } = filters;
    const path = `/api/alog/audit/query/csv/${startDate}/${endDate}/${
      user || '_'
    }/${patient || '_'}/${by}`;
    window.sch.download(
      path,
      'Activity_Audit_' + Math.random() * 10000 + '.csv'
    );
  };

  return (
    <div>
      <Filters
        classes={classes}
        filterData={filters}
        disabled={isFetching}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        setFilters={setFilters}
        onSearch={handleSearch}
        onReset={handleReset}
      />
      {Boolean(pagination?.totalRecords) && (
        <>
          <PaginationWithReset
            sortSupport={false} // For now
            currPage={toGoPage}
            data={pagination}
            isFetching={isFetching}
            handleResetSorting={null}
            handleClearAllSorting={null}
            handlePageChange={handlePageChange}
          />
          <Button variant="contained" color="primary" onClick={handleCSV}>
            Download CSV (Max 5000 Rows)
          </Button>
        </>
      )}

      <div style={{ height: isExpanded ? '72vh' : '88vh' }}>
        <CustomTable
          columns={columns}
          data={data}
          isLoading={isFetching}
          backgroundColor={false}
          sortData={{}}
          rowStyle={{
            cursor: 'default',
          }}
          setSortData={() => {}}
          error={{ message: 'No records to show!' }}
          onRowClick={() => {}}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  activityLogAudit: stateFromStore.activityLogAudit,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      queryActivityLogAudit_ac,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ActivityLogAudit));
