import { orange, purple } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import DocViewer from '../DocViewer/DocViewer'
import { DfaListWithRouter as DfaList } from './DfaList'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})

const styles = theme => ({
  root: {
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  topContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    marginTop: 40,
    height: 'calc(100% - 40px)',
    backgroundColor: '#fff'
  },
  content: {
    marginTop: 0,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  fullHeight: {
    height: '100%'
  }
})

class DocViewerDimWrapped extends React.Component {
  render() {
    return (
      <ContainerDimensions>
        <DocViewer {...this.props} />
      </ContainerDimensions>
    )
  }
}

class DfaLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      docViewerTab: 'doc_viewer'
    }
    this.switchDocViewerTab = this.switchDocViewerTab.bind(this)
  }

  switchDocViewerTab(tab) {
    console.log(tab)
    this.setState({ docViewerTab: tab })
  }

  componentDidMount() {
    if (this.props.me.user === null) {
      return
    }

    let path = `/dfa/${this.props.kind}/home/nd/1`
    if (this.props.match.url === '/' || this.props.match.url === '/alt_login') {
      if (this.props.me.user.tm_label) {
        path = `/dfa/${this.props.kind}/s:${this.props.me.user.tm_label}/nd`
      }
      return this.props.redirectToDfa(path)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.me.user === null) {
      return
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ drawerOpen: false })
    }

    let path = `/dfa/${this.props.kind}/home/nd/1`
    if (this.props.match.url === '/') {
      if (this.props.me.user.tm_label) {
        path = `/dfa/${this.props.kind}/s:${this.props.me.user.tm_label}/nd`
      }
      return this.props.redirectToDfa(path)
    }
  }

  render() {
    const { classes, me } = this.props
    /*
    let title = '...loading contents'
    const { dfaInView } = this.props
    if (dfaInView.searchStr && this.props.match.path === '/dfa/workunits') {
      let ha = _.get(dfaInView, 'accountDocs.hdr.hdmsAccts', []).map(a => {
        return 'A' + a
      })
      title =
        'Assignments: ' +
        dfaInView.searchStr.replace(/^.*:/, '') +
        ' ' +
        ha.join(',')
    }
    let tackledDfaRecord = null
    let hdmsAccounts = []

    if (dfaInView.posOfActivity !== null) {
      tackledDfaRecord = dfaInView.results.rows[dfaInView.posOfActivity]
      hdmsAccounts = _.get(tackledDfaRecord, 'hdmsAccts', [])
      title += ` - You are tackling: ${tackledDfaRecord.displayAs}`
      title += hdmsAccounts.length
        ? ' Account: ' + hdmsAccounts.map(h => 'A' + h).join(', ')
        : ''
    }
    */

    const asRows =
      _.get(me.user, 'defaults.df_list_view_mode', 'cards') === 'rows'

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={asRows ? 12 : 4} className={classes.fullHeight}>
          <main className={classes.content}>
            {/* workunit listing view  */}
            <Route
              path={`/:dfa_landing_kind/${
                this.props.kind
              }/:searchStr/:sortCode/:pageNum`}
              exact
              render={() => (
                <DfaList onSwitchDocViewerTab={this.switchDocViewerTab} />
              )}
            />
          </main>
        </Grid>
        {!asRows && (
          <Grid item xs={12} sm={8} className={classes.fullHeight}>
            <main className={classes.content}>
              <DocViewerDimWrapped
                docSelectionBy="posOfDocSelected"
                docPositionKey="posOfWorkUnitDocSelected"
                docViewerTab={this.state.docViewerTab}
                onSwitchTabWithProp={this.switchDocViewerTab}
                docsArray={this.props.accountInView.workUnitDocs}
                wDoc={this.props.accountInView.wDoc}
              />
            </main>
          </Grid>
        )}
      </Grid>
    )
  }
}

DfaLanding.propTypes = {
  classes: PropTypes.object.isRequired,
  kind: PropTypes.string.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  accountInView: stateFromStore.accountInView,
  dfaInView: stateFromStore.dfaInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirectToDfa: path => {
        console.log('redirectToDfa ran')
        return push(path)
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles(theme))(DfaLanding))
)

//export default withStyles(styles)(DfaLanding)
