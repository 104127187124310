import { actionTypes } from '../actions/idx_admin_reps.ac'

const initialState = {
  error: null,
  reps: null,
  isFetching: false,
  removeError: null,
  isRemoving: false
}

const initialFilterData = {
  zip: '',
  tags: [],
  last_name: '',
  first_name: '',
  rep_region: '',
  referral_name: '',
  payer_type_name: ''
}

export const idxAdminReps = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ADMIN_REPS:
      return {
        ...state,
        isFetching: true,
        repId: action.repId
      }

    case actionTypes.RECEIVED_IDX_ADMIN_REPS:
      return {
        ...state,
        error: null,
        isFetching: false,
        reps: action.reps
      }

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_REPS:
      return {
        ...state,
        error: true,
        reps: null,
        isFetching: false
      }

    case actionTypes.REMOVE_IDX_ADMIN_REP_PENDING:
      return {
        ...state,
        isRemoving: true
      }

    case actionTypes.REMOVE_IDX_ADMIN_REP_SUCCESSFUL:
      return {
        ...state,
        isRemoving: false,
        reps: {
          ...state.reps,
          rr: state.reps?.rr?.filter(rep => rep._id !== action.data)
        }
      }

    case actionTypes.REMOVE_IDX_ADMIN_REP_FAILED:
      return {
        ...state,
        isRemoving: false,
        removeError: action.error
      }

    default:
      return state
  }
}

export const idxAdminRepSearchFilters = (state = initialFilterData, action) => {
  switch (action.type) {
    case actionTypes.SAVE_REP_SEARCH_FILTERS:
      return { ...action.filterData }

    default:
      return state
  }
}

export const idxAdminRepSorting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SAVE_REP_SORTING:
      return { ...action.filterData }

    default:
      return state
  }
}
