import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ClaimHdr from '../ClaimViewer/ClaimHdr';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GroupIcon from '@material-ui/icons/Group';
import HealingIcon from '@material-ui/icons/Healing';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchClaim_ac } from '../../actions/claim.ac.js';
import PaErrorCard from './PaErrorCard';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    overflowX: 'auto',
    padding: 8,
    height: '100%',
  },
  listItem: {
    cursor: 'pointer',
  },
  fixedHeight: {
    height: '80vh',
    overflowY: 'auto',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
  },
  gridItem: {
    height: '100%',
    overflowY: 'auto',
  },
  gridContainer: {
    marginTop: 8,
    border: '1px solid #000',
    height: 'calc(100% - 8px)',
  },
});

class PaErrors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      selectedClaimId: null,
      payerGroup: null,
      claims: [],
      pcrListOpen: false,
      errorListOpen: false,
      hcpcListOpen: false,
      activityListOpen: true,
      filters: {
        activity: 'ALL',
        pcr: 'ALL',
        error: new Set(),
        hcpc: new Set(),
      },
    };
    this.state.filters.error.add('ALL');
    this.state.filters.hcpc.add('ALL');
    this.resetFilters = this.resetFilters.bind(this);
    this.claimClicked = this.claimClicked.bind(this);
    this.pcrFilterSelected = this.pcrFilterSelected.bind(this);
    this.activityFilterSelected = this.activityFilterSelected.bind(this);
  }

  resetFilters() {
    let error = new Set();
    error.add('ALL');
    let hcpc = new Set();
    hcpc.add('ALL');
    this.setState({
      filters: {
        activity: 'ALL',
        pcr: 'ALL',
        error,
        hcpc,
      },
    });
  }

  //arrow functions dont need binding in constructor
  handleExpandByPCR = () => {
    this.setState((state) => ({ pcrListOpen: !state.pcrListOpen }));
  };

  handleExpandByError = () => {
    this.setState((state) => ({ errorListOpen: !state.errorListOpen }));
  };

  handleExpandByHCPC = () => {
    this.setState((state) => ({ hcpcListOpen: !state.hcpcListOpen }));
  };
  handleExpandByActionState = () => {
    this.setState((state) => ({ activityListOpen: !state.activityListOpen }));
  };

  activityFilterSelected(evt, value) {
    this.setState({
      filters: Object.assign({}, this.state.filters, { activity: value }),
    });
  }
  pcrFilterSelected(evt, value) {
    this.setState({
      filters: Object.assign({}, this.state.filters, { pcr: value }),
    });
  }

  filterSelected(filterType, evt, value) {
    //let f = Object.assign({}, this.state.filters)
    let f = _.clone(this.state.filters);
    if (value === 'ALL') {
      if (evt.target.checked) {
        f[filterType].clear();
        f[filterType].add('ALL');
      } else {
        return; //don't allow for removing "ALL"
      }
    } else {
      if (evt.target.checked) {
        f[filterType].delete('ALL');
        f[filterType].add(value);
      } else {
        f[filterType].delete(value);
        if (f[filterType].size === 0) f[filterType].add('ALL'); //if last entry unchecked, set to ALL
      }
    }
    this.setState({ filters: f });
  }

  errorFilterSelected = (value) => (evt) => {
    this.filterSelected('error', evt, value);
  };

  hcpcFilterSelected = (value) => (evt) => {
    this.filterSelected('hcpc', evt, value);
  };

  claimClicked(claimId) {
    return this.props.fetchClaim_ac(claimId);
  }

  render() {
    const { classes } = this.props;

    if (this.props.paErrorsList.isFetching) {
      return (
        <div className={classes.root}>
          <Grid container spacing={16} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              <h3>..loading {this.props.paErrorsList.payerGroup} claims</h3>
            </Grid>
          </Grid>
        </div>
      );
    }

    if (this.props.paErrorsList.error) {
      return (
        <div className={classes.root}>
          <Grid container spacing={16} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              <h3>Error reaching server - inform IT</h3>
            </Grid>
          </Grid>
        </div>
      );
    }

    if (this.props.paErrorsList.records.length === 0) {
      return (
        <div className={classes.root}>
          <Grid container spacing={16} className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridItem}>
              <h3>No claims meet criteria</h3>
            </Grid>
          </Grid>
        </div>
      );
    }

    //apply filter logic
    const { by_pcr, by_error, by_hcpc } = this.props.paErrorsList.stats;
    const {
      activity: f_activity,
      pcr: f_pcr,
      error: f_error,
      hcpc: f_hcpc,
    } = this.state.filters;

    const activityCounts = {
      marked: this.props.paErrorsList.records.filter(
        (p) =>
          _.get(p, 'ua.pa_correction_' + p['LineIdx'] + '.checks', []).length >
          0
      ).length,
      unmarked: this.props.paErrorsList.records.filter(
        (p) =>
          _.get(p, 'ua.pa_correction_' + p['LineIdx'] + '.checks', [])
            .length === 0
      ).length,
    };

    let records =
      f_activity === 'ALL'
        ? [].concat(this.props.paErrorsList.records)
        : f_activity === 'marked'
        ? this.props.paErrorsList.records.filter(
            (p) =>
              _.get(p, 'ua.pa_correction_' + p['LineIdx'] + '.checks', [])
                .length > 0
          )
        : this.props.paErrorsList.records.filter(
            (p) =>
              _.get(p, 'ua.pa_correction_' + p['LineIdx'] + '.checks', [])
                .length === 0
          );

    records =
      f_pcr !== 'ALL' ? records.filter((p) => p['PCR'] === f_pcr) : records;

    records = f_error.has('ALL')
      ? records
      : records.filter((p) => f_error.has(p['PA Errors']));

    records = f_hcpc.has('ALL')
      ? records
      : records.filter((p) => f_hcpc.has(p['HCPC']));

    const _by_pcr = [['ALL', records.length]].concat(by_pcr); //make copy of full list
    const _by_error = [['ALL', records.length]].concat(by_error); //make copy of full list
    const _by_hcpc = [['ALL', records.length]].concat(by_hcpc); //make copy of full list

    return (
      <div className={classes.root}>
        <Grid container spacing={16} className={classes.gridContainer}>
          <Grid item xs={2} className={classes.gridItem}>
            <List className={classes.fixedHeight} component="nav">
              <ListItem>
                <ListItemText>
                  <span>
                    {this.props.paErrorsList.records.length} Claim Lines In
                    result set (unfiltered)
                  </span>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <span>{records.length} Claim Lines In (filtered)</span>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemText>
                  <Button
                    onClick={this.resetFilters}
                    variant="contained"
                    className={classes.button}>
                    Reset Filters
                  </Button>
                </ListItemText>
              </ListItem>

              <ListItem button onClick={this.handleExpandByActionState}>
                <ListItemIcon>
                  <AssignmentTurnedInIcon />
                </ListItemIcon>
                <ListItemText inset primary="By Work Status" />
                {this.state.activityListOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.activityListOpen}
                timeout="auto"
                unmountOnExit>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <RadioGroup
                    aria-label="Activity State"
                    name="activityStateRadioGroup"
                    className={classes.group}
                    value={this.state.filters.activity}
                    onChange={this.activityFilterSelected}>
                    <FormControlLabel
                      value="ALL"
                      label="Worked and UnWorked"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="unmarked"
                      label={'Only UnWorked ' + activityCounts.unmarked}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="marked"
                      label={'Only Worked ' + activityCounts.marked}
                      control={<Radio />}
                    />
                  </RadioGroup>
                </FormControl>
              </Collapse>

              <ListItem button onClick={this.handleExpandByPCR}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText inset primary="By PCR" />
                {this.state.pcrListOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.pcrListOpen}
                timeout="auto"
                unmountOnExit>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <RadioGroup
                    aria-label="By PCR"
                    name="pcrRadioGroup"
                    className={classes.group}
                    value={this.state.filters.pcr}
                    onChange={this.pcrFilterSelected}>
                    {this.props.paErrorsList.stats ? (
                      _by_pcr.map((p, idx) => {
                        return (
                          <FormControlLabel
                            key={p[0]}
                            value={p[0]}
                            label={p[0] + ' (' + p[1] + ')'}
                            control={<Radio />}
                          />
                        );
                      })
                    ) : (
                      <span>Loading pcr filters</span>
                    )}
                  </RadioGroup>
                </FormControl>
              </Collapse>

              <ListItem button onClick={this.handleExpandByError}>
                <ListItemIcon>
                  <ErrorIcon />
                </ListItemIcon>
                <ListItemText inset primary="By Error Type" />
                {this.state.errorListOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.errorListOpen}
                timeout="auto"
                unmountOnExit>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <FormGroup>
                    {this.props.paErrorsList.stats ? (
                      _by_error.map((p, idx) => {
                        return (
                          <FormControlLabel
                            key={p[0]}
                            control={
                              <Checkbox
                                checked={this.state.filters.error.has(p[0])}
                                onChange={this.errorFilterSelected(p[0])}
                                value={p[0]}
                              />
                            }
                            label={p[0] + ' (' + p[1] + ')'}
                          />
                        );
                      })
                    ) : (
                      <span>Loading error filters</span>
                    )}
                  </FormGroup>
                </FormControl>
              </Collapse>

              <ListItem button onClick={this.handleExpandByHCPC}>
                <ListItemIcon>
                  <HealingIcon />
                </ListItemIcon>
                <ListItemText inset primary="By HCPC" />
                {this.state.hcpcListOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse
                in={this.state.hcpcListOpen}
                timeout="auto"
                unmountOnExit>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <FormGroup>
                    {this.props.paErrorsList.stats ? (
                      _by_hcpc.map((p, idx) => {
                        return (
                          <FormControlLabel
                            key={p[0]}
                            control={
                              <Checkbox
                                checked={this.state.filters.hcpc.has(p[0])}
                                onChange={this.hcpcFilterSelected(p[0])}
                                value={p[0]}
                              />
                            }
                            label={p[0] + ' (' + p[1] + ')'}
                          />
                        );
                      })
                    ) : (
                      <span>Loading hcpc filters</span>
                    )}
                  </FormGroup>
                </FormControl>
              </Collapse>
            </List>
          </Grid>

          <Grid item xs={5} className={classes.gridItem}>
            <List className={classes.fixedHeight} component="nav">
              {records.map((rec, idx) => {
                return (
                  <PaErrorCard
                    key={rec['Claim ID'] + '-' + rec.rec_idx}
                    posInSet={rec.rec_idx}
                    claimClicked={this.claimClicked}
                  />
                );
              })}
            </List>
          </Grid>
          <Grid item xs={3} className={classes.gridItem}>
            {/*
            <ClaimHdr className={classes.fixedHeight} claimInView={this.props.claimInView}/>
            */}
          </Grid>
        </Grid>
      </div>
    );
  }
}

PaErrors.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  paErrorsList: stateFromStore.paErrorsList,
  claimInView: stateFromStore.claimInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchClaim_ac,
      changePage: (_selTab) => push('/pa_errors/' + _selTab),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaErrors));
