import actionTypes from '../actionTypes'


const initialState = {
  stateText: 'READY', // or BUSY
  showDrawer: false,
  callAttributes: {},
  idn: {
    inProgress: false,
    accounts: [],
    error: null
  }
}


export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.connect.drawer.open:
      return {
        ...state,
        showDrawer: true
      }
    case actionTypes.connect.drawer.close:
      return {
        ...state,
        showDrawer: false
      }
    case actionTypes.connect.drawer.clear:
      return {
        ...state,
        callAttributes: initialState.callAttributes,
        idn: initialState.idn
      }
    case actionTypes.connect.setAttributes:
      return {
        ...state,
        callAttributes: action.attributes
      }
    case actionTypes.connect.setState:
      return {
        ...state,
        stateText: action.newState
      }
    case actionTypes.connect.idn.try:
      return {
        ...state,
        idn: {
          ...initialState.idn,
          inProgress: true
        }
      }
    case actionTypes.connect.idn.success:
      return {
        ...state,
        idn: {
          ...state.idn,
          inProgress: false,
          accounts: action.accounts
        }
      }
    case actionTypes.connect.idn.fail:
      return {
        ...state,
        idn: {
          ...state.idn,
          inProgress: false,
          error: action.error
        }
      }
    default:
      return state
  }
}

