import { formTemplateActionTypes } from '../../actions/forms';

const {
  FETCH_FORM_TEMPLATES,
  FETCH_FORM_TEMPLATES_SUCCESSFUL,
  FETCH_FORM_TEMPLATES_FAILED,

  FETCH_FORM_TEMPLATE_DETAIL,
  FETCH_FORM_TEMPLATE_DETAIL_SUCCESSFUL,
  FETCH_FORM_TEMPLATE_DETAIL_FAILED,

  FLUSH_FORM_TEMPLATE_DETAIL,

  CREATE_FORM_TEMPLATE,
  CREATE_FORM_TEMPLATE_SUCCESSFUL,
  CREATE_FORM_TEMPLATE_FAILED,

  UPDATE_FORM_TEMPLATE,
  UPDATE_FORM_TEMPLATE_SUCCESSFUL,
  UPDATE_FORM_TEMPLATE_FAILED,

  REMOVE_FORM_TEMPLATE,
  REMOVE_FORM_TEMPLATE_SUCCESSFUL,
  REMOVE_FORM_TEMPLATE_FAILED,

  GET_BASE_FORM_TEMPLATES,
  GET_BASE_FORM_TEMPLATES_SUCCESSFUL,
  GET_BASE_FORM_TEMPLATES_FAILED,
} = formTemplateActionTypes;

export const initialState = {
  list: {
    data: [],
    meta: {},
    isFetching: false,
    error: null,
  },
  bases: {
    data: [],
    meta: {},
    isFetching: false,
    error: null,
  },
  detail: {
    data: null,
    isFetching: false,
    error: null,
  },
  isCreatingFormTemplate: false,
  isUpdatingFormTemplate: false,
  isRemovingFormTemplate: false,
};

export function formTemplates(state = initialState, action) {
  switch (action.type) {
    // FETCH_FORM_TEMPLATES
    case FETCH_FORM_TEMPLATES:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          isFetching: true,
        },
      });
    case FETCH_FORM_TEMPLATES_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: action.data?.results || [],
          meta: action.data?.meta || {},
          error: null,
        },
      });
    case FETCH_FORM_TEMPLATES_FAILED:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: [],
          meta: {},
          error: action.error,
        },
      });

    // FETCH_FORM_TEMPLATE_DETAIL
    case FETCH_FORM_TEMPLATE_DETAIL:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          isFetching: true,
        },
      });

    case FETCH_FORM_TEMPLATE_DETAIL_SUCCESSFUL:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      });

    case FETCH_FORM_TEMPLATE_DETAIL_FAILED:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      });

    // GET_BASE_FORM_TEMPLATES
    case GET_BASE_FORM_TEMPLATES:
      return Object.assign({}, state, {
        bases: {
          ...state.bases,
          isFetching: true,
        },
      });

    case GET_BASE_FORM_TEMPLATES_SUCCESSFUL:
      return Object.assign({}, state, {
        bases: {
          isFetching: false,
          data: action.data?.results || [],
          meta: action.data?.meta || {},
          error: null,
        },
      });

    case GET_BASE_FORM_TEMPLATES_FAILED:
      return Object.assign({}, state, {
        bases: {
          isFetching: false,
          data: [],
          meta: {},
          error: action.error,
        },
      });

    // CREATE_FORM_TEMPLATE
    case CREATE_FORM_TEMPLATE:
      return Object.assign({}, state, {
        isAddingFormTemplate: true,
      });
    case CREATE_FORM_TEMPLATE_SUCCESSFUL:
      return Object.assign({}, state, {
        // list: {
        //   ...state.list,
        //   data: [...state.list.data, action.data],
        // },
        isAddingFormTemplate: false,
      });
    case CREATE_FORM_TEMPLATE_FAILED:
      return Object.assign({}, state, {
        isAddingFormTemplate: false,
      });

    // UPDATE_FORM_TEMPLATE
    case UPDATE_FORM_TEMPLATE:
      return Object.assign({}, state, {
        isUpdatingFormTemplate: true,
      });
    case UPDATE_FORM_TEMPLATE_SUCCESSFUL:
      return Object.assign({}, state, {
        // list: {
        //   ...state.list,
        //   data: state.list.data.map((item, index) =>
        //     item._id === action.updated_id
        //       ? {
        //           ...item,
        //           ...(action.data || {}),
        //         }
        //       : item
        //   ),
        // },
        detail: {
          ...state.detail,
          data: {
            ...state.detail.data,
            ...(action.record || {}),
          },
        },
        isUpdatingFormTemplate: false,
      });

    case UPDATE_FORM_TEMPLATE_FAILED:
      return Object.assign({}, state, {
        isUpdatingFormTemplate: false,
      });

    // FLUSH_FORM_TEMPLATE_DETAIL
    case FLUSH_FORM_TEMPLATE_DETAIL:
      return Object.assign({}, state, {
        detail: {
          data: null,
          isFetching: false,
          error: null,
        },
      });

    // REMOVE_FORM_TEMPLATE
    case REMOVE_FORM_TEMPLATE:
      return Object.assign({}, state, {
        isRemovingFormTemplate: true,
      });
    case REMOVE_FORM_TEMPLATE_SUCCESSFUL:
      return Object.assign({}, state, {
        // list: {
        //   ...state.list,
        //   data: state.list.data.filter(
        //     (item) => item._id !== action.removed_id
        //   ),
        // },
        detail: {
          ...state.detail,
          data: null,
        },
        isRemovingFormTemplate: false,
      });
    case REMOVE_FORM_TEMPLATE_FAILED:
      return Object.assign({}, state, {
        isRemovingFormTemplate: false,
      });

    default:
      return state;
  }
}
