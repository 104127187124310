import {
  IconButton,
  InputAdornment,
  Paper,
  TextField
} from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon,
  Clear as ClearIcon
} from '@material-ui/icons'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import Autosuggest from 'react-autosuggest'
import { withStyles } from '@material-ui/core/styles'
import LocalPopper from './LocalPopper'

const styles = theme => ({
  suggestionsContainer: {
    maxHeight: '250px',
    overflowY: 'auto',
    margin: '2px 0'
  },
  suggestionsList: { paddingLeft: 0, margin: 0 },
  suggestion: {
    listStyleType: 'none',
    padding: 8,
    fontSize: 12,
    marginBottom: 2,
    cursor: 'pointer'
  },
  suggestionHighlighted: {
    backgroundColor: 'rgba(20, 20, 20, 0.20)'
  }
})

/**
 * Return correspondig lbl for provided code, if found, 
 * otherwise return the code itself
 * @param {string} code Option code
 * @param {array} options Options
 * @returns {string}
 */
const getOptionLblFromCode = (code, options) => {
  let option = options.find(opt => opt.code === code) || {};

  return option.lbl || code
}

/**
 * Filter and return array of new suggestions list according to input string
 * @param {string} value Input value
 * @param {array} options Options to be filtered
 * @returns {array}
 */
const getSuggestions = (value, options) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  if (inputLength === 0) {
    return [...options]  // Show all options for blank input
  }

  return options
    .filter(opt =>
      opt.lbl.toLowerCase().slice(0, inputLength) === inputValue
    );
};

/**
 * Custom suggestion wrapper component
 * @param {object} suggestion Suggested option
 * @returns {JSX.Element}
 */
const renderSuggestion = suggestion => (
  <div>
    {suggestion.lblNode ? suggestion.lblNode : suggestion.lbl}
  </div>
)

/**
 * Static options search field
 * @example 
 * <SearchSelect
 *   options={options.map(opt => ({
 *     code: opt,
 *     lbl: opt,
 *     lblNode: (  // optional, if provided, will be used instead of 'lbl'
 *       <span className="some_class_name">{opt}</span>
 *     )
 *   }))}
 *   name="inputName"
 *   label="Search"
 *   value={currentSelectionValue}
 *   placeholder="Search Something"
 *   fullWidth
 *   onSelect={event => setSelectionValue(event.target.value)}
 *   scrollableParentId="parentId"  // If provided, bound options list popper within the parent element
 * />
 */
const SearchSelect = props => {
  const {
    classes,
    options,
    placeholder,
    onSelect,
    fullWidth,
    name,
    label,
    value: propsValue,
    scrollableParentId,
    margin,
    shrinkLabel,
    helperText,
    disabled,
    onClear
  } = props

  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState(
    getOptionLblFromCode(propsValue, options) || ''
  )
  const popperAnchor = useRef(null)

  useEffect(() => {
    if (propsValue.length === 0) {
      setValue('')
    }

    setValue(getOptionLblFromCode(propsValue, options))
  }, [propsValue, options])

  /**
   * 'change' event handler for input field.
   * Set input value to component's `value` state
   * @param {Event} event Synthetic Event
   * @param {object} param1 Options
   */
  const onChange = (event, { newValue }) => {
    setValue(newValue)
  }

  /**
   * 'keyup' event handler for input field, only for 'Enter' key.
   * Reset input value to initial if no suggestion is matched onEnter,
   * otherwise consider the matched (typed) suggestion as
   * new and call props.onSelect
   * @param {Event} event Synthetic Event
   */
  const onKeyUp = event => {  // Only for Enter key press on the input field
    if (event && event.target && event.key === 'Enter') {
      let targetValue = event.target.value.trim()
      let lblOfValue = getOptionLblFromCode(targetValue, options)
      let matchedOption = options.find(opt => opt.lbl === lblOfValue)

      if (!matchedOption) {
        return setValue(getOptionLblFromCode(propsValue, options))
      }

      if (targetValue !== propsValue) {
        return onSelect({
          target: {
            name,
            value: matchedOption.code
          }
        })
      }
    }
  }

  /**
   * 'blur' event handler for the input field.
   * Reset input value to initial if no suggestion was highlighted onBlur,
   * otherwise consider the highlighted (clicked or traversed) suggestion as
   * new and call props.onSelect
   * @param {Event} event Synthetic event
   * @param {object} param1 Options
   */
  const onBlur = (event, { highlightedSuggestion }) => {
    if (!highlightedSuggestion) {
      return setValue(getOptionLblFromCode(propsValue, options))
    }

    if (propsValue !== highlightedSuggestion.code) {
      onSelect({
        target: {
          name,
          value: highlightedSuggestion.code
        }
      })
    }
  }

  /**
   * Return string to be rendered in the input field for selected option
   * @param {object} option Element of options list
   * @returns {string}
   */
  const getSuggestionValue = option => option.lbl

  /**
   * Return true to show all suggestions if input is blank
   * @param {string} currentValue Current input value
   * @returns {boolean}
   */
  const shouldRenderSuggestions = currentValue => {
    return currentValue.trim().length >= 0  // Show options for blank input
  }

  /**
   * Show all suggestions for input-focused event,
   * otherwise show according to current input value
   * @param {object} param0 Options
   */
  const onSuggestionsFetchRequested = ({ value, reason }) => {
    let newSuggestions;

    if (reason === 'input-focused') {
      newSuggestions = getSuggestions('', options)
      popperAnchor.current.setSelectionRange(0, value.length)
    } else {
      newSuggestions = getSuggestions(value, options)
    }

    setSuggestions([...newSuggestions])
  };

  /**
   * Hide suggestions on any focus-out or selection condition
   */
  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  /**
   * Call props.onSelect methos on selection of suggestion by pressing enter
   * @param {Event} event Synthetic Event
   * @param {object} param1 Options
   */
  const onSuggestionSelected = (event, { suggestion, method }) => {
    if (suggestion && method === 'enter') {
      onSelect({
        target: {
          name,
          value: suggestion.code
        }
      })
    }
  }

  /**
   * Custom suggestions list container
   * @param {object} param0 Options
   * @returns {JSX.Element}
   */
  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <LocalPopper
        anchorEl={popperAnchor.current}
        open={Boolean(children)}
        placement="bottom-start"
        localParentId={scrollableParentId}
        style={{
          width: popperAnchor.current
            ? popperAnchor.current.clientWidth
            : '250px',
          zIndex: 150
        }}
      >
        <Paper {...containerProps}>
          {children}
        </Paper>
      </LocalPopper>
    )
  }

  /**
   * Custom search input
   * @param {object} inputProps Arbitrary input component props
   * @returns {JSX.Element}
   */
  const renderInputComponent = inputProps => {
    const { inputRef = () => { }, ref, helperText, ...other } = inputProps

    return (
      <TextField
        fullWidth={fullWidth}
        label={label}
        inputRef={node => {
          ref(node)
          inputRef(node)
        }}
        helperText={helperText || 'Type in the input field to search'}
        {...other}
      />
    )
  }

  const inputProps = {
    placeholder: placeholder || 'Search...',
    name,
    value,
    margin,
    helperText,
    disabled,
    onChange,
    onBlur,
    onKeyUp,
    inputRef: node => { popperAnchor.current = node },
    InputLabelProps: { shrink: shrinkLabel || undefined },
    InputProps: {
      inputProps: { style: { paddingRight: 32 + (onClear ? 24 : 0) } },
      endAdornment: (
        <InputAdornment style={{ position: 'absolute', right: 0 }}>
          {onClear && (
            <IconButton style={{ padding: 2 }} onClick={onClear}>
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
          <ArrowDropDownIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        </InputAdornment>
      )
    }
  }

  return (
    <Autosuggest
      theme={classes}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputProps={inputProps}
      focusInputOnSuggestionClick={false}
      highlightFirstSuggestion={true}
    />
  )
}

SearchSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string.isRequired,
    lbl: PropTypes.string.isRequired,
    lblNode: PropTypes.element
  })).isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['none', 'normal', 'dense']),
  shrinkLabel: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  scrollableParentId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClear: PropTypes.func
}
SearchSelect.defaultProps = {
  placeholder: 'Search...',
  name: '',
  value: '',
  label: '',
  options: [],
  fullWidth: false,
  scrollableParentId: '',
  margin: 'none',
  shrinkLabel: false,
  disabled: false,
  onSelect: (v) => { }
}

export default withStyles(styles)(SearchSelect)
