import { actionTypes } from '../actions/patient_healthcall_data.ac'

export const patientHealthcallData = (
  state = {
    idnStr: null,
    isFetching: false,
    data: null,
    error: null
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_PATIENT_HEALTHCALL_DATA:
      return {
        ...state,
        idnStr: action.idnStr,
        isFetching: action.isFetching
      }

    case actionTypes.RECEIVED_PATIENT_HEALTHCALL_DATA:
      return {
        ...state,
        idnStr: action.idnStr,
        data: action.data,
        error: null
      }

    case actionTypes.FAILED_TO_RECEIVE_PATIENT_HEALTHCALL_DATA:
      return {
        ...state,
        idnStr: action.idnStr,
        data: null,
        error: action.error
      }

    default:
      return state
  }
}
