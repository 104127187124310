import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchComplianceDocs_ac } from '../../actions/compliance.ac.js'
import FetchingStatementWrap from '../FetchingStatementWrap'
import Pagination from '../Pagination'

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    cursor: 'pointer',
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  tblCell: {
    verticalAlign: 'top'
  }
})

class ComplianceReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      filterSequence: ['misc', 'reviewTag'],
      filters: {
        misc: {
          lbl: 'Needs Further Review Field',
          kind: 'text',
          placeholder: 'Enter search text to match on',
          value: ''
        },
        reviewTag: {
          lbl: 'Tags',
          options: [
            'DL-W USAGE',
            'DL-WO USAGE',
            'CP-W USAGE',
            'CP-WO USAGE',
            'DT-RESUPPLY',
            'ST-VENT SERVICE',
            'ST-NON SPECIFIC',
            'DUPLICATE',
            'REVIEWED',
            '-NO TAG-'
          ].map(t => ({ code: t, lbl: t, checked: true }))
        }
      }
    }
    this.checkSituation = this.checkSituation.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onFilterClearField = this.onFilterClearField.bind(this)
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this)
    this.onFilterToggle = this.onFilterToggle.bind(this)
    this.fetch = this.fetch.bind(this)
  }

  componentDidMount() {
    const { complianceDocs } = this.props
    if (complianceDocs.isFetching) return
    if (complianceDocs.error) return
    this.checkSituation()
    return
  }

  componentDidUpdate() {
    const { complianceDocs } = this.props
    if (complianceDocs.isFetching) return
    if (complianceDocs.error) return
    this.checkSituation()
    return
  }

  checkSituation() {
    const { complianceDocs } = this.props
    if (
      complianceDocs.pkg === null &&
      complianceDocs.error === null &&
      complianceDocs.isFetching === false
    ) {
      this.fetch()
    }
  }

  fetch(page) {
    let _f = {}
    //for (let k in this.state.filters) {
    Object.keys(this.state.filters).forEach(k => {
      switch (this.state.filters[k].kind) {
        case 'text':
          _f[k] = this.state.filters[k].value.trim()
          break
        default:
          _f[k] = this.state.filters[k].options
            .filter(o => o.checked)
            .map(o => o.code)
          break
      }
    })
    this.props.fetchComplianceDocs_ac({
      by: 'situation',
      filters: _f,
      page: page !== undefined ? page : this.state.page
    })
  }

  fetchWithPageReset() {
    let _f = {}
    //for (let k in this.state.filters) {
    Object.keys(this.state.filters).forEach(k => {
      switch (this.state.filters[k].kind) {
        case 'text':
          _f[k] = this.state.filters[k].value.trim()
          break
        default:
          _f[k] = this.state.filters[k].options
            .filter(o => o.checked)
            .map(o => o.code)
          break
      }
    })
    this.props.fetchComplianceDocs_ac({
      by: 'situation',
      filters: _f,
      page: 0
    })
    return
  }

  onFilterToggle(filterName, on) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          options: this.state.filters[filterName].options.map(o => {
            o.checked = on
            return o
          })
        }
      }
    })
  }

  onFilterChange = filterName => evt => {
    let sf = _.cloneDeep(this.state.filters[filterName])

    switch (sf.kind) {
      case 'text':
        sf.value = evt.target.value
        break
      default:
        //checkboxes
        sf.options = sf.options.map(s => {
          if (evt.target.value === s.code) {
            s.checked = evt.target.checked
          }
          return s
        })
        break
    }
    this.setState({ filters: { ...this.state.filters, [filterName]: sf } })
  }

  onFilterClearField = filterName => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          value: ''
        }
      }
    })
  }

  onPageChange(evt) {
    let page = parseInt(evt.target.value, 10)
    this.setState({ page })
    this.fetch(page)
  }

  render() {
    const { classes, complianceDocs } = this.props
    const title = 'NIV Project'
    if (complianceDocs.error) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'Something went wrong. Contact IT'}
        />
      )
    }

    if (!complianceDocs.pkg) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    if (complianceDocs.isFetching) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    const { pkg, pagination } = complianceDocs
    let subtitle = 'Compliance Documents Report'
    const startingRecordPos = pagination.start * 1
    return (
      <>
        {pkg.rr && (
          <main className={classes.content}>
            <label className={classes.label}>
              {subtitle} ({pkg.rr.length} accounts):
            </label>
            <Pagination
              onPageChange={this.onPageChange}
              page={this.state.page}
              pagination={pagination}
            />

            <div className={classes.scrollArea}>
              <Table padding="dense">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: 24, maxWidth: 48 }}>
                      #
                    </TableCell>
                    <TableCell>Patient</TableCell>
                    <TableCell>Doc</TableCell>
                    <TableCell>Review Tag / Level</TableCell>
                    <TableCell>Notes / Last Updated</TableCell>
                    <TableCell>Needs Further Review</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pkg.rr.map((r, idx) => {
                    const { compliance: doc } = r.doc
                    return (
                      <TableRow key={idx}>
                        <TableCell
                          style={{
                            minWidth: 24,
                            maxWidth: 48,
                            paddingRight: 12
                          }}>
                          {idx + startingRecordPos + 1}
                        </TableCell>
                        <TableCell variant="body">{r.idn}</TableCell>
                        <TableCell variant="body">
                          {/*
                      <Link to={'/dfa/doc_by_key/'+r._id}>
                      Document
                      </Link>
                    */}
                          {r.doc.nm}
                          <br />
                          <a
                            style={{ marginRight: 12 }}
                            href={'/dfa/doc_by_key/' + r._id}
                            target={r.idn}>
                            View Document in New Tab
                          </a>
                        </TableCell>
                        <TableCell variant="body">
                          {doc.reviewTag && <div>{doc.reviewTag}</div>}

                          {doc.level !== undefined && (
                            <div>Level {doc.level}</div>
                          )}
                        </TableCell>
                        <TableCell variant="body">
                          {doc.note}
                          <br />
                          <span style={{ fontSize: 11, color: 'purple' }}>
                            {r.log[r.log.length - 1].u_nm} on{' '}
                            {moment(r.log[r.log.length - 1].ts).format(
                              'MM-DD-YYYY hh:mma'
                            )}
                          </span>
                        </TableCell>

                        <TableCell variant="body">{doc.misc}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </div>
          </main>
        )}
      </>
    )
  }
}

ComplianceReport.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  complianceDocs: stateFromStore.complianceDocs
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchComplianceDocs_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ComplianceReport))
)
