import { actionTypes } from '../actions/idx_admin_pending_sla_rule.ac';

const initialState = {
  error: null,
  pendingSLARules: null,
  isFetching: false
};

export const idxAdminPendingSLARule = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ADMIN_PENDING_SLA_RULES:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.RECEIVED_IDX_ADMIN_PENDING_SLA_RULES:
      return {
        ...state,
        error: null,
        isFetching: false,
        pendingSLARules: action.data
      };

    case actionTypes.SET_IDX_ADMIN_PENDING_SLA_RULES:
      return {
        ...state,
        pendingSLARules: action.data
      };

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_PENDING_SLA_RULES:
      return {
        ...state,
        error: true,
        pendingSLARules: null,
        isFetching: false
      };

    default:
      return state;
  }
};
