import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

import {
  fetchIdxOrderingClinicians_ac,
  saveOrderingCliniciansSearchFilters_ac,
} from '../../../actions/idx_admin_ordering_clinicians.ac';
import TagsInput from '../../common/TagsInput';
import SearchField from '../../Shared/SearchField';

const FILTERS = {
  first_name: '',
  last_name: '',
  oc_level: '',
  npi: '',
  tags: null,
  pending_approval: '',
  record_id:'',
};

const IdxAdminLocationsFilterForm = (props) => {
  const { isFetching, repMode, authRep } = props;

  const [searchByRep, setSearchByRep] = useState(authRep || null);
  const [searchByLocation, setSearchByLocation] = useState(null);
  const [filters, setFilters] = useState({ ...FILTERS });
  const [ocLevelOptions, setOcLevelOptions] = useState([]);
  const [loadingOcLevelOptions, setLoadingOcLevelOptions] = useState(false);
  const [isOrderTypesLoadError, setIsOrderTypeOptionsLoadError] =
    useState(false);

  const pendingApprovalType = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  const setFilterState = (cb) => {
    let params = { ...filters };

    if (params.pending_approval === '') {
      delete params.pending_approval;
    }

    if (searchByRep && searchByRep._id) params.rep_id = searchByRep._id;

    if (searchByLocation && searchByLocation._id)
      params.location_id = searchByLocation._id;

    if (cb) cb(params);

    props.saveOrderingCliniciansSearchFilters_ac(params);

    return params;
  };

  useEffect(() => {
    if (repMode) {
      setFilterState((params) => {
        if (authRep?._id) {
          params.rep_id = authRep._id;
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authRep]);

  useEffect(() => {
    setLoadingOcLevelOptions(true);

    window.sch
      .post('/api/idx/admin/lookups/list', { kind: 'oc_level' })
      .then((res) => {
        setOcLevelOptions([...(res.rr || [])]);
      })
      .catch((error) => {
        setIsOrderTypeOptionsLoadError(true);
      })
      .finally(() => {
        setLoadingOcLevelOptions(false);
      });
  }, []);

  const searchReps = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr });
  };

  const searchLocations = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr });
  };

  const applyFilters = () => {
    const params = setFilterState();
    props.fetchIdxOrderingClinicians_ac({ ...params, page: 0 });
  };

  const resetFilters = () => {
    setFilters({ ...FILTERS });
    setSearchByRep(null);
    setSearchByLocation(null);
  };

  const renderRepSuggestion = (suggestion = {}) => {
    return <span>{suggestion.first_name + ' ' + suggestion.last_name}</span>;
  };

  const renderLocationSuggestion = (suggestion = {}) => {
    return (
      <>
        <span>
          {suggestion.addr1 && <>{suggestion.addr1}</>}
          {suggestion.addr2 && <>{suggestion.addr2}</>}
          {[suggestion.city || '-', suggestion.state || '-'].join(', ')}
        </span>
      </>
    );
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;

    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleTagChange = (newTags) => {
    setFilters((filters) => ({
      ...filters,
      tags: newTags.length ? newTags : null,
    }));
  };

  return (
    <div style={{ padding: 4 }}>
      <Grid container spacing={8} alignItems="baseline">
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textPrimary">
            <strong>Filter By:</strong>&ensp;
            <Typography variant="caption" inline>
              (*Note: Click the SHOW RECORDS button to update the clinicians
              table after changing search values.)
            </Typography>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <SearchField
            clearOnSelect
            delay={500}
            minChars={3}
            variant="outlined"
            resultsKey="pkg.rr"
            label="Rep"
            disabled={isFetching}
            style={{ flexGrow: 1 }}
            search={searchReps}
            onSuggestionClear={() => setSearchByRep(null)}
            selectSuggestion={(selectedRep) => {
              setSearchByLocation(null);
              setSearchByRep(selectedRep);
            }}
            apiEndpoint="/api/idx/admin/reps/search"
            scrollableParentId="idxOrderingClinicians"
            renderSuggestion={renderRepSuggestion}
            helperText="Minimum 3 characters required"
            placeholder="Search by Name"
          />
        </Grid>
        <Grid item>
          <SearchField
            clearOnSelect
            delay={500}
            minChars={3}
            variant="outlined"
            label="Locations"
            resultsKey="pkg.rr"
            disabled={isFetching}
            style={{ flexGrow: 1 }}
            search={searchLocations}
            onSuggestionClear={() => setSearchByLocation(null)}
            selectSuggestion={(selectedClinician) => {
              setSearchByRep(null);
              setSearchByLocation(selectedClinician);
            }}
            apiEndpoint="/api/idx/admin/locations/search"
            scrollableParentId="idxOrderingClinicians"
            renderSuggestion={renderLocationSuggestion}
            helperText="Minimum 3 characters required"
            placeholder="Search by Location"
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            select
            name="oc_level"
            id="oc_level"
            label="OC Level"
            value={filters.oc_level}
            onChange={handleFilterChange}
            helperText={
              loadingOcLevelOptions
                ? 'Loading...'
                : isOrderTypesLoadError
                ? 'Failed to load Order Types'
                : ' '
            }
            InputProps={{ style: { height: 28 } }}
            style={{ minWidth: 150 }}>
            <MenuItem value="">
              <Typography variant="inherit" color="textSecondary">
                None
              </Typography>
            </MenuItem>
            {ocLevelOptions.map((orderType, idx) => (
              <MenuItem key={`${orderType._id}_${idx}`} value={orderType.name}>
                {orderType.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item>
          <TextField
            margin="dense"
            name="record_id"
            id="record_id"
            label="Record Id"
            value={filters.record_id}
            onChange={handleFilterChange}
            placeholder="Record Id"
            inputProps={{ style: { height: 18 } }}
            helperText=" "
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            name="first_name"
            id="first_name"
            label="First Name"
            value={filters.first_name}
            onChange={handleFilterChange}
            placeholder="First Name"
            inputProps={{ style: { height: 18 } }}
            helperText=" "
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            name="last_name"
            id="last_name"
            label="Last Name"
            value={filters.last_name}
            onChange={handleFilterChange}
            placeholder="Last Name"
            inputProps={{ style: { height: 18 } }}
            helperText=" "
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            name="npi"
            id="npi"
            label="NPI"
            value={filters.npi}
            onChange={handleFilterChange}
            placeholder="Enter NPI"
            inputProps={{ style: { height: 18 } }}
            helperText=" "
          />
        </Grid>
        <Grid item>
          <TagsInput
            margin="dense"
            onChange={handleTagChange}
            id="tags"
            name="tags"
            placeholder="Add Tags"
            label="tags"
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            select
            name="pending_approval"
            id="pending_approval"
            label="Pending Approval"
            value={filters.pending_approval}
            onChange={handleFilterChange}
            InputProps={{ style: { height: 28 } }}
            style={{ minWidth: 150 }}>
            <MenuItem value="">
              <Typography variant="inherit" color="textSecondary">
                None
              </Typography>
            </MenuItem>
            {pendingApprovalType.map((type, idx) => (
              <MenuItem key={`${type}_${idx}`} value={type.value}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={applyFilters}>
            SHOW RECORDS
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={resetFilters}>
            CLEAR ALL
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <div style={{ margin: '8px 0px' }}>
        <Typography variant="subtitle1" color="textSecondary" inline>
          <strong>Rep.:</strong>&ensp;
          {searchByRep ? (
            <>
              {[searchByRep.first_name, searchByRep.last_name].join(' ')}
              <IconButton
                style={{ padding: 6 }}
                onClick={() => setSearchByRep(null)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <Typography variant="inherit" color="textSecondary" inline>
              Not Selected
            </Typography>
          )}
        </Typography>
        &ensp;<strong>|</strong>&ensp;
        <Typography variant="subtitle1" color="textSecondary" inline>
          <strong>Location:</strong>&ensp;
          {searchByLocation ? (
            <>
              {searchByLocation.addr1 && <>{searchByLocation.addr1}</>}
              {searchByLocation.addr2 && <>{searchByLocation.addr2}</>}
              {[
                searchByLocation.city || '-',
                searchByLocation.state || '-',
              ].join(', ')}
              <IconButton
                style={{ padding: 6 }}
                onClick={() => setSearchByLocation(null)}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <Typography variant="inherit" color="textSecondary" inline>
              Not Selected
            </Typography>
          )}
        </Typography>
      </div>
      <Divider />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchIdxOrderingClinicians_ac,
      saveOrderingCliniciansSearchFilters_ac,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(IdxAdminLocationsFilterForm);
