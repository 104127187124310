import { LOOKUP_LABEL_TYPES } from '../components/Indexing/constants';
import { getGlobalDividers } from '../components/Indexing/utils';
import { DUMMY_FAX_JOB } from '../components/IndexingAdmin/IdxAdminIndexingPractice/constants';
import { serialize } from '../util/object';

export const actionTypes = {
  FETCH_INDEXING_LABELS_SUCCESS: 'FETCH_INDEXING_LABELS_SUCCESS',
  FETCH_INDEXING_LABELS_ERROR: 'FETCH_INDEXING_LABELS_ERROR',
  FETCH_INDEXING_LABELS_PENDING: 'FETCH_INDEXING_LABELS_PENDING',

  FETCH_FAX_JOB_DETAIL_SUCCESS: 'FETCH_FAX_JOB_DETAIL_SUCCESS',
  FETCH_FAX_JOB_DETAIL_ERROR: 'FETCH_FAX_JOB_DETAIL_ERROR',
  FETCH_FAX_JOB_DETAIL_PENDING: 'FETCH_FAX_JOB_DETAIL_PENDING',

  FETCH_FAX_POTENTIAL_DUPLICATES_SUCCESS:
    'FETCH_FAX_POTENTIAL_DUPLICATES_SUCCESS',
  FETCH_FAX_POTENTIAL_DUPLICATES_ERROR: 'FETCH_FAX_POTENTIAL_DUPLICATES_ERROR',
  FETCH_FAX_POTENTIAL_DUPLICATES_PENDING:
    'FETCH_FAX_POTENTIAL_DUPLICATES_PENDING',

  RESET_INDEXING_CONTEXT_SUCCESS: 'RESET_INDEXING_CONTEXT_SUCCESS',
  RESET_INDEXING_CONTEXT_ERROR: 'RESET_INDEXING_CONTEXT_ERROR',
  RESET_INDEXING_CONTEXT_PENDING: 'RESET_INDEXING_CONTEXT_PENDING',

  TRASH_INDEXING_CONTEXT_SUCCESS: 'TRASH_INDEXING_CONTEXT_SUCCESS',
  TRASH_INDEXING_CONTEXT_ERROR: 'TRASH_INDEXING_CONTEXT_ERROR',
  TRASH_INDEXING_CONTEXT_PENDING: 'TRASH_INDEXING_CONTEXT_PENDING',

  UNTRASH_INDEXING_CONTEXT_SUCCESS: 'UNTRASH_INDEXING_CONTEXT_SUCCESS',
  UNTRASH_INDEXING_CONTEXT_ERROR: 'UNTRASH_INDEXING_CONTEXT_ERROR',
  UNTRASH_INDEXING_CONTEXT_PENDING: 'UNTRASH_INDEXING_CONTEXT_PENDING',

  CLONE_INDEXING_JOB_PENDING: 'CLONE_INDEXING_JOB_PENDING',
  CLONE_INDEXING_JOB_SUCCESS: 'CLONE_INDEXING_JOB_SUCCESS',
  CLONE_INDEXING_JOB_ERROR: 'CLONE_INDEXING_JOB_ERROR',

  SAVE_INDEXING_CONTEXT_SUCCESS: 'SAVE_INDEXING_CONTEXT_SUCCESS',
  SAVE_INDEXING_CONTEXT_ERROR: 'SAVE_INDEXING_CONTEXT_ERROR',
  SAVE_INDEXING_CONTEXT_PENDING: 'SAVE_INDEXING_CONTEXT_PENDING',

  COMPLETE_INDEXING_CONTEXT_SUCCESS: 'COMPLETE_INDEXING_CONTEXT_SUCCESS',
  COMPLETE_INDEXING_CONTEXT_ERROR: 'COMPLETE_INDEXING_CONTEXT_ERROR',
  COMPLETE_INDEXING_CONTEXT_PENDING: 'COMPLETE_INDEXING_CONTEXT_PENDING',

  SET_ROTATION_PREFS: 'SET_ROTATION_PREFS',
  SET_ACTIVE_IMAGE_INDEX: 'SET_ACTIVE_IMAGE_INDEX',

  ADD_CONTEXT_ENTRY: 'ADD_CONTEXT_ENTRY',
  REMOVE_CONTEXT_ENTRY: 'REMOVE_CONTEXT_ENTRY',

  ADD_CONTEXT_ENTRY_DOC: 'ADD_CONTEXT_ENTRY_DOC',
  REMOVE_CONTEXT_ENTRY_DOC: 'REMOVE_CONTEXT_ENTRY_DOC',

  ADD_CONTEXT_ENTRY_LABEL: 'ADD_CONTEXT_ENTRY_LABEL',
  REMOVE_CONTEXT_ENTRY_LABEL: 'REMOVE_CONTEXT_ENTRY_LABEL',

  SET_DOC_DETAIL: 'SET_DOC_DETAIL',

  LINK_IMAGE_TO_DOC: 'LINK_IMAGE_TO_DOC',
  UNLINK_IMAGE_FROM_DOC: 'UNLINK_IMAGE_FROM_DOC',

  SET_FOCUSED_STATE: 'SET_FOCUSED_STATE',

  SET_ORDERING_CLINICIAN: 'SET_ORDERING_CLINICIAN',
  SET_OC_LOCATION: 'SET_OC_LOCATION',
  SET_ZIP_CODE: 'SET_ZIP_CODE',
  SET_OC_ZIP_CODE: 'SET_OC_ZIP_CODE',

  SET_INDEXING_STATE: 'SET_INDEXING_STATE',
  POPULATE_CONTEXT_ENTRY: 'POPULATE_CONTEXT_ENTRY',
  UPDATE_CONTEXT_ENTRY: 'UPDATE_CONTEXT_ENTRY',
};

const lookupToLabels = (list) => {
  const labels = list.map((lk) => ({
    nm: lk.name,
    kind: lk.kind,
    labels: [{ kind: lk.kind, display: lk.kind?.toUpperCase() }],
  }));

  labels.push(...getGlobalDividers());

  return labels;
};

export function fetchIndexingLabels_ac(opt) {
  const params = {
    kinds: LOOKUP_LABEL_TYPES.join(','),
    ...opt,
  };
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_INDEXING_LABELS_PENDING });

    return window.sch
      .get('/api/idx/admin/lookups/list?' + serialize(params), {})
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_INDEXING_LABELS_SUCCESS,
          data: lookupToLabels(result.rr),
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_INDEXING_LABELS_ERROR,
          error,
        });
      });
  };
}

export function fetchFaxJobDetail_ac(
  faxJobId,
  readOnly = false,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FAX_JOB_DETAIL_PENDING });

    return window.sch
      .post('/api/fax/getbyid', { faxJobId, readOnly })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.FETCH_FAX_JOB_DETAIL_SUCCESS,
          faxData: result.faxDoc,
          xData: result.xDoc,
        });
        if (onSuccess) {
          onSuccess(result.faxDoc);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FAX_JOB_DETAIL_ERROR,
          error,
        });
      });
  };
}

export function fetchPotentialDuplicateFaxes_ac(faxJobId) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FAX_POTENTIAL_DUPLICATES_PENDING });

    return window.sch
      .get(`/api/faxes/${faxJobId}/potential_duplicates`, {})
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.FETCH_FAX_POTENTIAL_DUPLICATES_SUCCESS,
          faxes: result.results || [],
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FAX_POTENTIAL_DUPLICATES_ERROR,
          error,
        });
      });
  };
}

export function autoFetchNextFaxJobDetail_ac(
  qName,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FAX_JOB_DETAIL_PENDING });

    return window.sch
      .post('/api/fax/get/next', { qname: qName })
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.FETCH_FAX_JOB_DETAIL_SUCCESS,
          faxData: result.faxDoc,
          xData: result.xDoc || null,
        });
        if (onSuccess) {
          onSuccess(result.faxDoc);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FAX_JOB_DETAIL_ERROR,
          error,
        });
        if (onError) {
          onError();
        }
      });
  };
}

export function saveIndexingContext_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.SAVE_INDEXING_CONTEXT_PENDING });

    return window.sch
      .post('/api/context/save', data)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.SAVE_INDEXING_CONTEXT_SUCCESS,
          data: result,
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SAVE_INDEXING_CONTEXT_ERROR,
          error,
        });
        if (onError) onError(error);
      });
  };
}

export function resetIndexingContext_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_INDEXING_CONTEXT_PENDING });

    return window.sch
      .post('/api/fax/reset', data)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.RESET_INDEXING_CONTEXT_SUCCESS,
          data: result,
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.RESET_INDEXING_CONTEXT_ERROR,
          error,
        });
        if (onError) onError(error);
      });
  };
}

export function trashIndexingContext_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.TRASH_INDEXING_CONTEXT_PENDING });

    return window.sch
      .post('/api/faxjob/trash', data)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.TRASH_INDEXING_CONTEXT_SUCCESS,
          data: result,
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.TRASH_INDEXING_CONTEXT_ERROR,
          error,
        });
        if (onError) onError(error);
      });
  };
}

export function cloneIndexingJob_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLONE_INDEXING_JOB_PENDING });

    return window.sch
      .post('/api/faxjob/clone', data)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.CLONE_INDEXING_JOB_SUCCESS,
          data: result,
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CLONE_INDEXING_JOB_ERROR,
          error,
        });
        if (onError) onError(error);
      });
  };
}

export function untrashIndexingContext_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UNTRASH_INDEXING_CONTEXT_PENDING });

    return window.sch
      .post('/api/faxjob/untrash', data)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.UNTRASH_INDEXING_CONTEXT_SUCCESS,
          data: { ...data, result },
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UNTRASH_INDEXING_CONTEXT_ERROR,
          error,
        });
        if (onError) onError(error);
      });
  };
}

export function completeIndexingContext_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.COMPLETE_INDEXING_CONTEXT_PENDING });

    return window.sch
      .post('/api/context/complete', data)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch({
          type: actionTypes.COMPLETE_INDEXING_CONTEXT_SUCCESS,
          data: result,
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.COMPLETE_INDEXING_CONTEXT_ERROR,
          error,
        });
        if (onError) onError(error);
      });
  };
}

export const setRotationPrefs_ac = (rotationPrefs) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ROTATION_PREFS,
    data: rotationPrefs,
  });

export const setActiveImageIndex_ac = (activeImageIndex) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ACTIVE_IMAGE_INDEX,
    data: activeImageIndex,
  });

export const addContextEntry_ac = (contextEntry) => (dispatch) =>
  dispatch({
    type: actionTypes.ADD_CONTEXT_ENTRY,
    data: contextEntry,
  });

export const removeContextEntry_ac = (id) => (dispatch) =>
  dispatch({
    type: actionTypes.REMOVE_CONTEXT_ENTRY,
    data: id,
  });

export const addContextEntryDoc_ac = (contextId, doc) => (dispatch) =>
  dispatch({
    type: actionTypes.ADD_CONTEXT_ENTRY_DOC,
    data: { contextId, doc },
  });

export const removeContextEntryDoc_ac = (contextId, docId) => (dispatch) =>
  dispatch({
    type: actionTypes.REMOVE_CONTEXT_ENTRY_DOC,
    data: { contextId, docId },
  });

export const addContextEntryLabel_ac =
  (contextId, labelName, value) => (dispatch) =>
    dispatch({
      type: actionTypes.ADD_CONTEXT_ENTRY_LABEL,
      data: { contextId, labelName, value },
    });

export const removeContextEntryLabel_ac =
  (contextId, labelName, value) => (dispatch) =>
    dispatch({
      type: actionTypes.REMOVE_CONTEXT_ENTRY_LABEL,
      data: { contextId, labelName, value },
    });

export const setDocDetail_ac = (contextId, docId, detail) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_DOC_DETAIL,
    data: { contextId, docId, detail },
  });

export const linkImageToDoc_ac = (contextId, docId, imageIdx) => (dispatch) =>
  dispatch({
    type: actionTypes.LINK_IMAGE_TO_DOC,
    data: { contextId, docId, imageIdx },
  });

export const unlinkImageFromDoc_ac =
  (contextId, docId, imageIdx) => (dispatch) =>
    dispatch({
      type: actionTypes.UNLINK_IMAGE_FROM_DOC,
      data: { contextId, docId, imageIdx },
    });

export const setFocusedState_ac = (focusedState) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_FOCUSED_STATE,
    data: focusedState,
  });

export const setOrderingClinician_ac = (contextId, oc) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ORDERING_CLINICIAN,
    data: { contextId, oc },
  });

export const setOCLocation_ac = (contextId, location) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_OC_LOCATION,
    data: { contextId, location },
  });

export const setZipCode_ac = (contextId, zip) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_ZIP_CODE,
    data: { contextId, zip },
  });

export const setOCZipCode_ac = (contextId, oc_zip) => (dispatch) =>
  dispatch({
    type: actionTypes.SET_OC_ZIP_CODE,
    data: { contextId, oc_zip },
  });

export const setIndexingState_ac =
  (newState = {}) =>
  (dispatch) =>
    dispatch({
      type: actionTypes.SET_INDEXING_STATE,
      data: newState,
    });

export const setDummyIndexingJobForPractice_ac = () => (dispatch) =>
  dispatch({
    type: actionTypes.FETCH_FAX_JOB_DETAIL_SUCCESS,
    faxData: DUMMY_FAX_JOB,
    xData: null,
  });

export const populateContextEntry_ac = (contextId, changes) => (dispatch) => {
  dispatch({
    type: actionTypes.POPULATE_CONTEXT_ENTRY,
    data: { contextId, changes },
  });
};

export const updateContextEntry_ac = (contextId, updates) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_CONTEXT_ENTRY,
    data: { contextId, updates },
  });
};
