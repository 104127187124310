import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateTeamPreCCS_ac } from '../../actions/teams.ac.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto'
  },
  payerName: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 8,
    color: '#65502f'
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13)
  },
  formControl: {
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },
  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8
  },
  paNumber: {
    fontWeight: 600
  },
  notLogged: {
    color: '#757575'
  },
  byTabKlass: {
    minWidth: 16
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  activityMsg: {
    fontSize: 11,
    color: 'orange'
  },
  noteUser: {
    color: '#607D8B',
    fontWeight: 600
  },
  memoPara: {
    color: '#000',
    margin: '4px 4px 4px 0px',
    padding: 6,
    fontSize: 12,
    marginTop: 0,
    borderRadius: 4,
    backgroundColor: 'rgba(233, 236, 241, 0.49)'
  }
})

class TeamCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      inReAttempt: false,
      comment: '',
      stat_mode: 'any',

      preCcsStartTime: '',
      processed_preCcsStartTime: '',

      preCcsEndTime: '',
      processed_preCcsEndTime: '',

      preCcsEffDate: '',
      processed_preCcsEffDate: '',

      pre_stat_modes: [
        { lbl: 'NONE-DELETE RULE', code: 'delete' },
        { lbl: 'STAT AND NON-STAT', code: 'any' },
        { lbl: 'STAT ONLY', code: 'stat_only' },
        { lbl: 'NON-STAT ONLY', code: 'non_stat_only' }
      ]
    }

    this.onPreCcsEffDate = this.onPreCcsEffDate.bind(this)
    this.onPreCcsTime = this.onPreCcsTime.bind(this)
    this.onUpdatePreCcsRule = this.onUpdatePreCcsRule.bind(this)
    this.onCcsModeSelect = this.onCcsModeSelect.bind(this)
  }

  onCcsModeSelect(e) {
    this.setState({ stat_mode: e.target.value })
  }

  onUpdatePreCcsRule() {
    let rule = this.validatePreCcs()
    if (!rule.valid) return

    delete rule.valid
    this.props.updateTeamPreCCS_ac(this.props.idx, {
      team: this.props.team._id,
      rule: { ...rule, stat_mode: this.state.stat_mode }
    })
  }

  onPreCcsTime = startEnd => e => {
    let v = moment(e.target.value, 'hh:mm a')

    if (startEnd === 'start') {
      this.setState({
        preCcsStartTime: e.target.value,
        processed_preCcsStartTime: v.isValid() ? v : ''
      })
    } else {
      this.setState({
        preCcsEndTime: e.target.value,
        processed_preCcsEndTime: v.isValid() ? v : ''
      })
    }
  }

  onPreCcsEffDate(e) {
    let v = moment(e.target.value, 'MM-DD-YYYY')
    this.setState({
      preCcsEffDate: e.target.value,
      processed_preCcsEffDate: v.isValid() ? v : ''
    })
  }

  validatePreCcs() {
    if (this.state.stat_mode === 'delete') {
      return { valid: true }
    }

    if (
      typeof this.state.processed_preCcsStartTime === 'string' ||
      typeof this.state.processed_preCcsEndTime === 'string' ||
      typeof this.state.processed_preCcsEffDate === 'string'
    ) {
      return { valid: false }
    }

    let tmp = {
      start:
        this.state.processed_preCcsStartTime.hours() * 60 +
        this.state.processed_preCcsStartTime.minutes(),
      end:
        this.state.processed_preCcsEndTime.hours() * 60 +
        this.state.processed_preCcsEndTime.minutes(),
      effective_start: this.state.processed_preCcsEffDate.format('MM-DD-YYYY')
    }

    tmp.valid = tmp.start < tmp.end
    return tmp
  }

  render() {
    const { classes, team, me } = this.props
    let pre_ccs_txt = ''
    let pre_ccs_time_txt = ''
    if (team.pre_ccs_rule) {
      switch (team.pre_ccs_rule.stat_mode) {
        case 'stat_only':
          pre_ccs_txt = 'STAT ONLY'
          break
        case 'non_stat_only':
          pre_ccs_txt = 'NON-STAT ONLY'
          break
        default:
          pre_ccs_txt = 'STAT and NON-STAT'
          break
      }

      pre_ccs_time_txt = [
        moment(
          [
            Math.floor(team.pre_ccs_rule.start / 60),
            Math.floor(team.pre_ccs_rule.start % 60)
          ].join(':'),
          'h:m'
        ).format('hh:mm a'),

        moment(
          [
            Math.floor(team.pre_ccs_rule.end / 60),
            Math.floor(team.pre_ccs_rule.end % 60)
          ].join(':'),
          'h:m'
        ).format('hh:mm a')
      ].join(' to ')
    }

    let preCcsStatus = this.validatePreCcs()

    return (
      <ExpansionPanel defaultExpanded={this.props.defaultExpanded}>
        <ExpansionPanelSummary
          style={{
            backgroundColor: team.touched ? 'rgb(228, 234, 204)' : '#fff'
          }}
          expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.panelHdrTxt}>
            {team._id}&nbsp;
            <span style={{ color: 'green' }}>{team.members.length} Active</span>
            &nbsp;
            {team.cursor && (
              <span style={{ color: 'dargray' }}> - Next: {team.cursor}</span>
            )}
            {team.pre_ccs_rule && (
              <span style={{ color: 'purple' }}> - PRECCS</span>
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="column" justify="flex-start" wrap="nowrap">
            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl}>Team Type:</label>
              {team.is_special ? (
                <Typography className={classes.payerName}>BackEnd</Typography>
              ) : (
                <Typography className={classes.payerName}>FrontEnd</Typography>
              )}
            </Grid>
            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl}>Active Members:</label>
              {team.members.map(m => (
                <Typography key={m} className={classes.payerName}>
                  {m}
                </Typography>
              ))}
            </Grid>
            {team.pre_ccs_rule && (
              <Grid item className={classes.branchGrid}>
                <label className={classes.lbl}>PRE-CCS Details:</label>
                <Typography className={classes.payerName}>
                  Applicable to: {pre_ccs_txt}
                </Typography>
                <Typography className={classes.payerName}>
                  Effective Hours: {pre_ccs_time_txt}
                </Typography>
                <Typography className={classes.payerName}>
                  Effective After:{' '}
                  {moment(team.pre_ccs_rule.effective_start).format(
                    'MM-DD-YYYY'
                  )}
                </Typography>
              </Grid>
            )}

            {/*
            "pre_ccs_rule": {
              start: minOfDay,
              end: minOfDay,
              stat_mode: "any" | "stat_only" | "non_stat_only"
            }
            */}

            {!team.is_special &&
              _.intersection(me.user.roles, ['cs_manager', 'it-admin']).length >
                0 && (
                <Grid item className={classes.branchGrid}>
                  <label className={classes.lbl}>UPDATE PRE-Ccs RULE</label>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    spacing={8}>
                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <Typography className={classes.payerName}>
                          Applicable for:
                        </Typography>
                        <Select
                          onChange={this.onCcsModeSelect}
                          value={this.state.stat_mode}
                          className={classes.selectEmpty}>
                          {this.state.pre_stat_modes.map(t => (
                            <MenuItem key={t.code} value={t.code}>
                              {t.lbl}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {this.state.stat_mode !== 'delete' && (
                      <Grid item>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          spacing={8}>
                          <Grid item sm={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              label="Enter Start Time of Day (hh:mm am/pm)"
                              onChange={this.onPreCcsTime('start')}
                              value={this.state.preCcsStartTime}
                              margin="normal"
                            />
                            <FormHelperText>
                              {typeof this.state.processed_preCcsStartTime ===
                              'string'
                                ? ''
                                : this.state.processed_preCcsStartTime.format(
                                    'hh:mm a'
                                  )}
                            </FormHelperText>
                          </Grid>
                          <Grid item sm={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              label="Enter End Time of Day (hh:mm am/pm)"
                              onChange={this.onPreCcsTime('end')}
                              value={this.state.preCcsEndTime}
                              margin="normal"
                            />
                            <FormHelperText>
                              {typeof this.state.processed_preCcsEndTime ===
                              'string'
                                ? ''
                                : this.state.processed_preCcsEndTime.format(
                                    'hh:mm a'
                                  )}
                            </FormHelperText>
                          </Grid>
                          <Grid item sm={4}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              label="Enter Effective Date (MM-DD-YYYY)"
                              onChange={this.onPreCcsEffDate}
                              value={this.state.preCcsEffDate}
                              margin="normal"
                            />
                            <FormHelperText>
                              {typeof this.state.processed_preCcsEffDate ===
                              'string'
                                ? ''
                                : this.state.processed_preCcsEffDate.format(
                                    'ddd MM-DD-YYYY'
                                  )}
                            </FormHelperText>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    <Grid item>
                      <FormControl className={classes.formControl}>
                        <FormGroup className={classes.formGroupRoot}>
                          <Button
                            variant="outlined"
                            disabled={!preCcsStatus.valid}
                            onClick={this.onUpdatePreCcsRule}>
                            Update Pre-Ccs Rule
                          </Button>
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTeamPreCCS_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamCard))
