import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { accountAndWorkUnitDocSelected_ac } from '../../actions/account_in_view.ac.js';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
//import ReassignTrigger from '../Reassign/ReassignTrigger'
function ellipse(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '..' : str;
}
// const HDR_TEMPLATES = {
//   'FR - FINANCIAL RESPONSIBILITY': 'eligibility',
//   'CREDIT/DEBIT ADJUSTMENT REQUEST': 'adjustment_request'
// }
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    //overflowX: 'auto'
  },
  linkTo: {
    textDecoration: 'none',
    fontSize: '.82em',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  linkToNoLeftPad: {
    paddingLeft: 0,
  },
  linkToMissing: {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'purple',
      color: 'yellow',
    },
  },
  linkToPast: {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'red',
      color: 'yellow',
    },
  },
  linkToPending: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: 'yellow',
    },
  },
  linkToDone: {
    '&:hover': {
      backgroundColor: 'orange',
      color: 'yellow',
    },
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
    border: '1px solid #cfd8dc',
    backgroundColor: '#fafefff0',
  },

  /*

    new,
    pending,
    past-sla (new or pending)

    done,
    cancelled
  */

  cardHdrRoot: { cursor: 'pointer', padding: '8px 8px 8px 8px' },
  cardHdrContent: { fontSize: '.82em', fontWeight: 800 },
  cardHdrSubHdr: { fontSize: '.70em', color: '#000' },

  cardHdrRootMissing: {
    cursor: 'pointer',
    backgroundColor: 'purple',
    padding: '8px 8px 8px 8px',
    color: '#000',
  },
  cardHdrContentMissing: { fontSize: '.82em', fontWeight: 800, color: '#fff' },
  cardHdrSubHdrMissing: { color: '#fff' },

  cardHdrRootPast: {
    cursor: 'pointer',
    backgroundColor: 'red',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentPast: { fontSize: '.82em', fontWeight: 800, color: '#fff' },
  cardHdrSubHdrPast: { fontWeight: 700, color: '#fff' },

  cardHdrRootPending: {
    cursor: 'pointer',
    backgroundColor: 'orange',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentPending: { fontSize: '.82em', fontWeight: 800, color: '#000' },
  cardHdrSubHdrPending: { color: '#000' },

  cardHdrRootDone: {
    cursor: 'pointer',
    backgroundColor: '#56de93',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentDone: { fontSize: '.82em', fontWeight: 800, color: '#000' },
  cardHdrSubHdrDone: { color: '#000' },

  cardHdrRootCancelled: {
    cursor: 'pointer',
    backgroundColor: 'lightgray',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentCancelled: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },
  cardHdrSubHdrCancelled: { color: '#000' },

  cardHdrRootDone_Closed: {
    cursor: 'pointer',
    backgroundColor: '#cbc9dc',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentDone_Closed: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },
  cardHdrSubHdrDone_Closed: { fontSize: '.80em', color: '#000' },

  cardHdrRootCancelled_Closed: {
    cursor: 'pointer',
    backgroundColor: 'lightgray',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentCancelled_Closed: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },
  cardHdrSubHdrCancelled_Closed: { fontSize: '.80em', color: '#000' },

  cardContentRoot: { cursor: 'pointer', padding: 8 },
  cardContentRootPast: {},
  cardContentRootPending: {},
  cardContentRootDone: {},
  cardContentRootCancelled: { backgroundColor: '#d3d3d35c' },

  cardContentRootTackled: {
    fontSize: '.82em',
    fontWeight: 400,
    backgroundColor: '#ffffd6',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chip: {
    height: 26,
    maxWidth: 140,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 3,
  },

  chipLabel: {
    padding: 3,
  },

  mode: { backgroundColor: '#1DE9B6', fontWeight: 400, color: '#000' },
  modeSmall: { backgroundColor: '#1DE9B6', fontSize: 8, color: '#000' },

  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#000' },
  product: { backgroundColor: '#80DEEA', fontWeight: 400, color: '#000' },
  situationChipP: { backgroundColor: 'orange', fontWeight: 400, color: '#000' },
  situationChipD: {
    backgroundColor: '#56de93',
    fontWeight: 400,
    color: '#000',
  },
  situationChipC: {
    backgroundColor: '#d3d3d35c',
    fontWeight: 400,
    color: '#000',
  },

  owner: { color: '#7f8486' },
  wo_attached: { color: '#000', fontWeight: 700 },
  conf: { backgroundColor: '#80DEEA' },
  tm: { backgroundColor: '#689F38' },
  pre_ccs: {
    //backgroundColor: '#fff',
    color: '#000',
    height: 22,
    margin: '2px 2px 2px 0px',
    padding: 2,
  },
  payerChip: { backgroundColor: '#FFA726' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  hdmsAcctMissing: { backgroundColor: '#FF6E40', color: '#fff' },
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  // IDEA: k
  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  slaStart: {
    color: '#008AE0',
  },
  slaPasses: {
    color: 'red',
  },
  actions: {
    display: 'flex',
    maxHeight: 40,
  },
  actionsWithoutMaxHeight: {
    display: 'flex',
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteBlock: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
    fontSize: 11,
  },
  noteTxt: {
    color: '#4e6c7a',
    fontSize: 11,
    marginBottom: 4,
  },
  noteUser: {
    color: '#244e91',
  },
  docSetRefLbl: {
    paddingRight: 4,
  },
  wosLbl: {
    fontSize: 11,
  },
  wuLbl: {
    paddingRight: 4,
    fontSize: 11,
  },
  docSetLenLbl: {
    color: 'orange',
    float: 'right',
  },

  formControl: {
    margin: theme.spacing.unit * 1,
  },
  'PRE-CCS-LOBBY': {
    backgroundColor: '#1768AC',
    display: 'inline',
    borderRadius: 4,
    fontSize: 10,
    color: '#fff',
    padding: 4,
    fontWeight: 600,
  },
  'PRE-CCS-INPROG': {
    backgroundColor: '#1768AC',
    borderRadius: 4,
    fontSize: 10,
    display: 'inline',
    color: '#fff',
    padding: 4,
    fontWeight: 600,
  },
  'PRE-CCS-DONE': {
    backgroundColor: '#49107d',
    display: 'inline',
    fontSize: 10,
    color: '#fff',
    borderRadius: 4,
    padding: 4,
    fontWeight: 600,
  },
  attn_me: {
    backgroundColor: '#fff',
    border: '1px solid #000',
    display: 'inline',
    borderRadius: 4,
    fontSize: 10,
    color: '#673AB7',
    padding: 4,
    fontWeight: 400,
  },
  referral: {
    fontSize: 11,
    fontWeight: 600,
    color: '#C2185B',
  },
  preccs_warn: {
    borderRadius: 4,
    color: '#000',
    padding: 4,
    display: 'inline',
    fontSize: 10,
    backgroundColor: '#ff7600',
  },
  preccs_danger: {
    borderRadius: 4,
    color: '#fff',
    display: 'inline',
    padding: 4,
    backgroundColor: '#d40c0c',
    fontSize: 10,
  },
  preccs_safe: {
    display: 'inline',
    color: '#000',
    backgroundColor: 'green',
    borderRadius: 4,
    padding: 4,
    fontSize: 10,
  },
});

class DfaCardNoTackle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      docsExpanded: false,
      raLogInCardExpanded: false,
      docsFetching: false,
      docsLoaded: false,
      workUnitDocs: {
        docs: [],
      },

      notesExpanded: false,
      notesFetching: false,
      notesLoaded: false,
      notes: [],
      selectedNoteHdr: -1,
      newNoteTxt: '',
      formExpanded: false,
    };

    this.handleFormExpandClick = this.handleFormExpandClick.bind(this);
    this.handleReassignLogExpandClick =
      this.handleReassignLogExpandClick.bind(this); //in card only

    this.handleNotesExpandClick = this.handleNotesExpandClick.bind(this);

    this.addDFNote = this.addDFNote.bind(this);
    this.onNoteEntry = this.onNoteEntry.bind(this);

    this.onLocalUpdate = this.onLocalUpdate.bind(this);
    this.onNoteHdrSelect = this.onNoteHdrSelect.bind(this);
    this.showRAs = this.showRAs.bind(this);
    this.onUserSelectedForAttnMe = this.onUserSelectedForAttnMe.bind(this);
    this.onUpdateAccountInViewWDoc = this.onUpdateAccountInViewWDoc.bind(this);
    this.onTakeOwnership = this.onTakeOwnership.bind(this);
  }

  showRAs() {
    this.onLocalUpdate({
      ...this.props.wDoc,
      displayRA: !this.props.wDoc.displayRA,
    });
  }

  onUserSelectedForAttnMe(userPkg) {
    this.setState({
      newNoteTxt: userPkg.mail + ' ' + this.state.newNoteTxt,
    });
  }

  onUpdateAccountInViewWDoc(pkgToUpdateWDoc) {
    this.props.accountAndWorkUnitDocSelected_ac(
      this.state.workUnitDocs,
      this.props.accountInView.posOfWorkUnitDocSelected,
      { ...this.props.wDoc, ...pkgToUpdateWDoc }
    );
    return;
  }

  handleFormExpandClick() {
    this.setState({ formExpanded: !this.state.formExpanded });
    return;
  }

  handleReassignLogExpandClick() {
    this.setState({ raLogInCardExpanded: !this.state.raLogInCardExpanded });
    return;
  }

  handleNotesExpandClick() {
    if (this.state.notesLoaded) {
      this.setState({ notesExpanded: !this.state.notesExpanded });
      return;
    }

    this.setState({
      notesExpanded: !this.state.notesExpanded,
      notesFetching: true,
    });

    let target_url = `/api/workitem:getallnotes`;
    let postData = { wId: this.props.wDoc._id };
    return window.sch
      .post(target_url, postData)
      .then((results) => {
        console.log('got results', results);
        this.setState({
          notesFetching: false,
          notesLoaded: true,
          notes: results.wDoc.notes,
        });
        return;
      })
      .catch((error) => {
        console.error('error:', error);
      });
  }

  async onNoteHdrSelect(e) {
    let noteHdrId = e.target.value;
    let r;
    if (noteHdrId === -1) {
      r = { newnotedefault: '', id: -1 };
    } else {
      r = this.props.noteHdrs.filter((n) => n.id === noteHdrId)[0];
    }
    /*
    if (HDR_TEMPLATES[r.masternoteheading]) {
      let _tpl = await this.loadTplJson(HDR_TEMPLATES[r.masternoteheading])
      this.setState({
        selectedNoteHdr: r.id,
        noteText: r.newnotedefault.toString().trim(),
        tplsLoaded: {
          ...this.state.tplsLoaded,
          [HDR_TEMPLATES[r.masternoteheading]]: _tpl
        },
        useTpl: HDR_TEMPLATES[r.masternoteheading]
      })
      return
    }
    */

    this.setState({
      selectedNoteHdr: r.id,
      newNoteTxt: r.newnotedefault.toString().trim(),
      useTpl: false,
    });
  }

  addDFNote() {
    this.setState({ noteIsSaving: true });
    if (!this.state.notesExpanded || this.state.newNoteTxt.trim() === '')
      return;

    let { wDoc: dfaRecord } = this.props;

    let postData = {
      wId: dfaRecord._id,
      txt: this.state.newNoteTxt,
      nm: dfaRecord.nm,
    };

    let dfSaveResults;
    return window.sch
      .post(`/api/workitem:addnote`, postData)
      .then((results) => {
        dfSaveResults = results;
        if (this.state.selectedNoteHdr !== -1) {
          let hdmsPostData = {
            acctMember: dfaRecord.hdmsAccts[0],
            noteLibId: this.state.selectedNoteHdr,
            txt: postData.txt,
          };

          return window.sch.post(`/api/notes:hdms:add`, hdmsPostData);
        }
      })
      .then(() => {
        this.setState({
          noteIsSaving: false,
          newNoteTxt: '',
          notes: [dfSaveResults.newNote, ...this.state.notes],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onNoteEntry(e) {
    this.setState({ newNoteTxt: e.target.value });
  }

  onLocalUpdate(pkg) {
    this.props.onLocalUpdate(this.props.posInSet, pkg);
  }

  onTakeOwnership() {
    const { wDoc, toTeam, toUser } = this.props;
    let target_url = `/api/workitem:reassign:diffteam`;
    let params = {
      wId: wDoc._id,
      product: wDoc.assignments[0][1][0].product,
      product_bn: wDoc.assignments[0][1][0].product_bn,
      fromTeam: wDoc.assignments[0][1][0].team,
      fromUser: wDoc.assignments[0][1][0].user,
      toTeam,
      toUser,
    };

    return window.sch
      .post(target_url, params)
      .then((results) => {
        //console.log(results)
        this.props.open_snack_ac({
          variant: 'info',
          message: `The workunit is now assigned to ${toUser}@${toTeam}`,
          duration: 5000,
        });
        this.props.onWDocUpdate(wDoc._id);
      })
      .catch((err) => {
        console.error(err);
        this.props.open_snack_ac({
          variant: 'error',
          message: `Something went wrong. Contact IT, send this this message: Take ownership failed with params: ${JSON.stringify(
            params
          )}`,
          duration: 25000,
        });
        return;
      });
  }

  render() {
    const { classes } = this.props;
    const { wDoc } = this.props;
    const chipLabel = {
      label: classes.chipLabel,
    };

    const oa = wDoc.assignments.length
      ? wDoc.assignments[0][1][0]
      : wDoc.pastAssignments[0][1][0];

    wDoc.is_open = wDoc.assignments.length ? true : false;

    const haChip = wDoc.hdmsAccts.length ? (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.hdmsAcct)}
        label={'A' + wDoc.hdmsAccts[0]}
        color="primary"
      />
    ) : (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.hdmsAcctMissing)}
        label={'ACCOUNT# MISSING'}
        color="primary"
      />
    );
    const tmChip = wDoc.labels.tm.length ? (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.tm)}
        label={ellipse(wDoc.labels.tm[0], 15)}
        color="primary"
      />
    ) : (
      ''
    );
    const payerChip = wDoc.labels.payer.length ? (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.payerChip)}
        label={wDoc.labels.payer[0]}
      />
    ) : (
      ''
    );

    let pChips = _.get(oa, 'sit', []).map((sit, sitIdx) => {
      return (
        <Chip
          key={'k' + sit.txt + sitIdx}
          classes={chipLabel}
          className={classNames(
            classes.chip,
            classes['situationChip' + sit.gc]
          )}
          label={sit.txt}
          color="primary"
        />
      );
    });
    let mChips = _.get(wDoc.labels, 'mode', []).map((m, mIdx) => {
      return (
        <Chip
          key={'k' + m + mIdx}
          title={m}
          className={classNames(
            classes.chip,
            m.length > 8 ? classes.modeSmall : classes.mode
          )}
          classes={chipLabel}
          label={m.length > 12 ? m.substr(0, 12) + '...' : m}
        />
      );
    });

    let preCcsChip = !oa.ps ? (
      ''
    ) : (
      <div className={classes[oa.ps.state]}>
        {ellipse(oa.ps.u_nm, 8) + '@' + oa.ps.state}{' '}
        {oa.ps.state !== 'DONE' && oa.ps_age_minutes
          ? oa.ps_age_minutes + ' min.'
          : ''}
      </div>
    );

    let attnMe = _.get(wDoc, 'attn', []);
    let attnMeChip = '';
    if (attnMe.length > 0) {
      attnMeChip = (
        <div className={classes.attn_me}>ATTN: {attnMe.join(', ')}</div>
      );
    }
    /*
    new,
    pending,
    past-sla (new or pending)

    done,
    cancelled
  */
    const sitCode = _.get(oa, 'sit.0.gc', 'N');
    let st;
    switch (sitCode) {
      case 'N': //no situations tagged, assume new
        st =
          undefined === oa.slaPkg
            ? 'Missing'
            : oa.slaPkg.isPastDue
            ? 'Past'
            : '';
        break;
      case 'P':
        st =
          undefined === oa.slaPkg
            ? 'Missing'
            : oa.slaPkg.isPastDue
            ? 'Past'
            : 'Pending';
        break;
      case 'D':
      case 'DC':
        st = wDoc.is_open ? 'Done' : 'Done_Closed';
        break;
      case 'C':
        st = wDoc.is_open ? 'Cancelled' : 'Cancelled_Closed';
        break;
      default:
        break;
    }

    const cardHdrClasses = {
      root: classes['cardHdrRoot' + st],
      title: classes['cardHdrContent' + st],
      subheader: classNames(
        classes['cardHdrSubHdr' + st],
        classes.cardHdrSubHdr
      ),
    };
    const cardContentClasses = {
      root: classes['cardContentRoot' + st],
    };

    let cTime = moment(wDoc.m.created.ts).format('M-DD-YY h:mma');
    let indexingStatement = [wDoc.m.created.u_id, cTime].join(' ');

    let noteCount = this.state.notesLoaded
      ? this.state.notes.length
      : _.get(wDoc, 'notes', []).length + wDoc.moreNotesCount;

    let subTitleTxt = wDoc.is_open
      ? 'Assigned to:' + oa.user + '@' + oa.team
      : 'Closed by:' + oa.user + '@' + oa.team;

    if (_.get(wDoc, 'todo_ids', []).length) {
      subTitleTxt += ' , LinkedTasks: ' + wDoc.todo_ids.join(',');
    }
    let isConfWU = false;
    if (_.get(wDoc, 'ownersArr.0.product_bn', '') === 'WO-CONF') {
      isConfWU = _.get(wDoc, 'ownersArr.0.product', false);
    }

    const hdrTitle = (
      <div>
        <span className={classNames(classes['linkTo' + st], classes.linkTo)}>
          {wDoc.displayAs} {wDoc.hdmsAccts.map((a) => 'A' + a).join(' ')}
        </span>
        <span
          title={'Time Assigned'}
          style={{ fontSize: '.82em', marginLeft: 4, marginRight: 4 }}>
          Rtd {moment(wDoc.m.created.ts).format('M-D-YY h:mma')}
        </span>

        <span style={{ fontSize: '.82em' }}>
          {st === 'Past' ? ' PAST SLA' : ''}
        </span>
        <span style={{ marginLeft: 4, fontSize: '.82em' }}>{oa.product}</span>
      </div>
    );

    const slaStart = oa.slaPkg ? (
      <Typography
        title={
          oa.slaStart
            ? 'Actual SLA start: ' + moment(oa.slaStart).format('d MM-DD-YYYY')
            : ''
        }
        className={classes.slaStart}
        color="textSecondary">
        SLA Started: {oa.slaPkg.effStartStr}
      </Typography>
    ) : (
      ''
    );

    const slaPass = oa.slaPkg ? (
      <Typography className={classes.slaPasses} color="textSecondary">
        SLA Passes: {oa.slaPkg.dueDtStr}
      </Typography>
    ) : (
      ''
    );
    const slaPend = oa.lastPend ? (
      <Typography className={classes.slaStart} color="textSecondary">
        Last Pend: {moment(oa.lastPend).format('M-DD-YY ddd h:mma')}
      </Typography>
    ) : (
      ''
    );

    return (
      <Card className={classes.card}>
        {
          <CardHeader
            title={hdrTitle}
            subheader={
              <div>
                <div style={{ marginBottom: 4 }}>
                  <span style={{ marginRight: 8 }}>{subTitleTxt}</span>
                  {isConfWU && (
                    <span style={{ marginRight: 8 }}>{isConfWU}</span>
                  )}
                </div>
                {preCcsChip} {attnMeChip}
              </div>
            }
            classes={cardHdrClasses}
          />
        }

        <>
          <CardContent classes={cardContentClasses}>
            <Chip
              classes={chipLabel}
              className={classNames(classes.chip, classes.product)}
              label={oa.product}
              color="primary"
            />
            {mChips}
            {haChip}
            {tmChip}
            {payerChip}
            {pChips}

            <Grid container direction="row" spacing={8}>
              <Grid item sm={6}>
                <Typography className={classes.owner} color="primary">
                  Indexed by: {indexingStatement}
                </Typography>
                {_.get(wDoc.m, 'docsappended', []).map((da) => (
                  <Typography
                    className={classes.owner}
                    key={da.ts}
                    color="primary">
                    Docs added:{' '}
                    {da.u_id + ' ' + moment(da.ts).format('M-DD-YY h:mma')}
                  </Typography>
                ))}
                {_.get(oa, 'wos', []).length > 0 && (
                  <Typography className={classes.wo_attached} color="primary">
                    Work orders attached:{' '}
                    {oa.wos.map((w) => 'W' + w.id).join(', ')}
                  </Typography>
                )}

                {wDoc.referral && (
                  <Typography className={classes.referral} color="primary">
                    Referral: {wDoc.referral.last} {wDoc.referral.first} NPI:{' '}
                    {wDoc.referral.npi}
                  </Typography>
                )}

                {wDoc.payer && (
                  <Typography className={classes.referral} color="primary">
                    Payer: {wDoc.payer.id} {wDoc.payer.name}{' '}
                    {wDoc.payer.payertypename}
                  </Typography>
                )}
              </Grid>

              {/* <ReassignTrigger
                wId={wDoc._id}
                displayAs={wDoc.displayAs}
                product={wDoc.assignments[0][1][0].product}
                product_bn={wDoc.assignments[0][1][0].product_bn}
                team={wDoc.assignments[0][1][0].team}
                user={wDoc.assignments[0][1][0].user}
                onComplete={() => {
                  setTimeout(window.location.reload.bind(window.location), 1000)
                }}>
                <Typography>Reassign</Typography>
              </ReassignTrigger> */}

              <Grid item sm={6}>
                {slaStart}
                {slaPass}
                {slaPend}
              </Grid>
            </Grid>
          </CardContent>

          <Divider component="hr" />
          <CardContent classes={cardContentClasses}>
            <FormControl component="fieldset" className={classes.formControl}>
              {wDoc.assignments[0][1][0].user === this.props.toUser ? (
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  style={{ fontSize: 11, cursor: 'pointer' }}>
                  You own this workunit
                </Button>
              ) : (
                <Button
                  onClick={this.onTakeOwnership}
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{ fontSize: 11, cursor: 'pointer' }}>
                  Assign to ME: {this.props.toUser}@{this.props.toTeam}
                </Button>
              )}
            </FormControl>
          </CardContent>
          <Divider component="hr" />

          {((wDoc.ra_log && wDoc.ra_log.length > 0) ||
            (wDoc.pastOwnersArr && wDoc.pastOwnersArr.length > 0)) && (
            <Divider component="hr" />
          )}
          {((wDoc.ra_log && wDoc.ra_log.length > 0) ||
            (wDoc.pastOwnersArr && wDoc.pastOwnersArr.length > 0)) && (
            <CardActions
              classes={cardContentClasses}
              className={classes.actions}
              disableActionSpacing>
              <Button
                size="small"
                color="primary"
                onClick={this.handleReassignLogExpandClick}>
                Reassigns ({_.get(wDoc, 'ra_log', []).length}) and Closed
                Workunits ({_.get(wDoc, 'pastOwnersArr', []).length})
              </Button>
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: this.state.raLogInCardExpanded,
                })}
                onClick={this.handleReassignLogExpandClick}
                aria-expanded={this.state.raLogInCardExpanded}
                aria-label="Show Reassignment Log">
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          )}
          <Collapse
            in={this.state.raLogInCardExpanded}
            timeout="auto"
            unmountOnExit>
            <CardContent classes={cardContentClasses}>
              {wDoc.pastOwnersArr && wDoc.pastOwnersArr.length > 0 && (
                <div style={{ fontSize: 11, fontWeight: 200 }}>
                  <div
                    style={{ fontWeight: 500, padding: 2 }}
                    onClick={this.showRAs}>
                    {wDoc.pastOwnersArr.length} Closed Workunits:
                  </div>
                  {wDoc.pastOwnersArr.map((poa, ri) => (
                    <div key={ri} style={{ padding: 2 }}>
                      {poa.product_bn}
                      {' -> '}
                      {poa.sit && poa.sit.length > 0
                        ? [
                            poa.sit.map((s) => s.txt).join(','),
                            'by',
                            `${poa.user}@${poa.team}`,
                            moment(poa.sit[0].ts).format('M-D-YY h:mma'),
                          ].join(' ')
                        : 'N/A'}
                    </div>
                  ))}
                </div>
              )}

              {wDoc.ra_log && wDoc.ra_log.length > 0 && (
                <div style={{ fontSize: 11, fontWeight: 200 }}>
                  <div
                    style={{ fontWeight: 500, padding: 2 }}
                    onClick={this.showRAs}>
                    {wDoc.ra_log.length} Reassigns
                  </div>
                  {wDoc.ra_log.map((ra, ri) => (
                    <div key={ri} style={{ padding: 2 }}>
                      from {ra.lastUser}@{ra.lastTeam} by {ra.u_id} {ra.ts}
                    </div>
                  ))}
                </div>
              )}
            </CardContent>
          </Collapse>

          <Divider component="hr" />

          <CardActions
            classes={cardContentClasses}
            className={classes.actions}
            disableActionSpacing>
            <Button
              size="small"
              color="primary"
              onClick={this.handleNotesExpandClick}>
              Notes ({noteCount})
            </Button>
            <IconButton
              className={classes.expand}
              onClick={this.handleNotesExpandClick}
              aria-expanded={this.state.notesExpanded}
              aria-label="Show Notes">
              <SpeakerNotesIcon />
            </IconButton>
          </CardActions>

          <Collapse in={this.state.notesExpanded} timeout="auto" unmountOnExit>
            <CardContent classes={cardContentClasses}>
              {this.state.notes.map((n, nIdx) => (
                <div
                  key={wDoc._id + '-' + nIdx}
                  component="paragraph"
                  className={classes.noteBlock}
                  color="textSecondary">
                  {(typeof n.txt === 'string' ? n.txt.split(/\n/g) : n.txt).map(
                    (t, tidx) => (
                      <div key={tidx} className={classes.noteTxt}>
                        {t}
                      </div>
                    )
                  )}

                  <i className={classes.noteUser}>
                    {n.nm} - {moment(n.ts).format('M-D-YY h:mma')}
                  </i>
                </div>
              ))}
            </CardContent>
          </Collapse>
        </>
      </Card>
    );
  }
}

DfaCardNoTackle.propTypes = {
  classes: PropTypes.object.isRequired,
  wDoc: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  lookups: stateFromStore.lookups,
  accountInView: stateFromStore.accountInView,
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      accountAndWorkUnitDocSelected_ac,
      open_snack_ac,
      close_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DfaCardNoTackle))
);

//export default withStyles(styles)(DfaCardNoTackle);
