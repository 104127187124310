import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserSearch from '../Users/UserSearch'
import { updateDC_ac } from '../../actions/dcs.ac.js'
import { withStyles } from '@material-ui/core/styles'
import { open_snack_ac } from '../../actions/snack.ac.js'
import { Button, Grid, Typography } from '@material-ui/core'

const styles = theme => ({
  formControl: {
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(14)
  },
  labelTypography: {
    color: 'grey',
    fontSize: '12px'
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10
  },
  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8
  },
  gridLabel: {
    display: 'flex',
    justifyContent: 'left'
  },
  panelValue: {},
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  lblAction: {
    fontSize: 11,
    color: '#9c27b0',
    cursor: 'pointer'
  }
})

const USER_FIELDS = ['rom', 'supervisor', 'dispatcher']

const toPostData = formState => _.pick(formState, USER_FIELDS)

const DCMaintenance = props => {
  const { classes, dc, dcs, idx, updateDC_ac, open_snack_ac } = props

  const [formState, setFormState] = React.useState({})

  React.useEffect(() => {
    setFormState({ ...dc })
  }, [dc])

  const handleSave = () => {
    updateDC_ac(idx, toPostData(formState), {
      onSuccess: () => {
        open_snack_ac({
          message: `DC: ${dc.dc_id} updated successfully`,
          variant: 'success'
        })
      },
      onError: error => {
        open_snack_ac({
          message: `Failed to update the DC: ${dc.dc_id}`,
          variant: 'success'
        })
      }
    })
  }

  const handleReset = () => {
    setFormState({ ...dc })
  }

  const handleUserSelect = (key, user) => {
    setFormState({
      ...formState,
      [key]: {
        display: user.nm.trim(),
        nm: user.orig_nm.trim(),
        email: user.email.trim()
      }
    })
  }

  return (
    <Grid container spacing={8} className={classes.formGroupRoot}>
      <Grid item sm={12}>
        {' '}
        <Typography className={classes.panelHdrTxt}>
          {dc.dc_nm.trim()}&nbsp;
          <span style={{ color: 'green' }}>
            #{dc.num} - ID: {dc.dc_id}
          </span>
        </Typography>
      </Grid>
      {USER_FIELDS.map((item, index) => (
        <Grid item key={index} sm={12} style={{ marginTop: '12px' }}>
          <div className={classes.gridLabel}>
            <Typography className={classes.panelHdrTxt}>
              {item.toUpperCase()}
            </Typography>
            <span
              style={{
                margin: '0px 6px',
                position: 'relative',
                top: '-4px',
                fontWeight: 'bold'
              }}>
              {' : '}
            </span>
            <Typography className={classes.panelValue}>
              {formState[item] ? formState[item].nm : 'Not Assigned'}
            </Typography>
          </div>
          <UserSearch onSelect={user => handleUserSelect(item, user)} />
        </Grid>
      ))}
      <Grid item xs={12} sm={6}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleSave}
          disabled={dcs.isUpdating}>
          Save
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{ marginLeft: '8px' }}
          onClick={handleReset}
          disabled={dcs.isUpdating}>
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = stateFromStore => ({
  dcs: stateFromStore.dcs
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDC_ac,
      open_snack_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DCMaintenance))
