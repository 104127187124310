/* eslint no-unused-vars: "off" */
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { orange, purple } from '@material-ui/core/colors';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { PaginationWithNums } from '../PaginationNew.js';
import Loader from '../Shared/Loader.js';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange,
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
});
const styles = (theme) => ({
  title: {
    flexGrow: 1,
  },
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 0.25,
    height: '100%',
    backgroundColor: '#fbf8f896',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  fullHeight: {
    height: '100%',
  },
  tableHeader: {
    height: 40,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
    },
  },
  scrollable: {
    height: '100%',
    overflowY: 'auto',
    '& .table': {
      minHeight: '180px',
    },
  },
  tableRowSelected: {
    backgroundColor: 'yellow',
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
  },
});

const getpath = (n) => {
  return (
    (Math.floor(n / 1000) * 1000).toString().padStart(8).replace(/ /g, '0') +
    '/' +
    n
  );
};

class FBAccountingSearchResult extends React.Component {
  /* temporary DIRTY fix to let user download TIFS from S3 */
  downloadTif(e) {
    let proms = e.pages.map((p) => {
      return new Promise((resolve, reject) => {
        const s3key = [
          'https://s3.us-west-2.amazonaws.com',
          'sch.filebound',
          getpath(p.doc_id) + '.' + p.ext,
        ].join('/');
        let params = {
          s3key,
        };
        return window.sch
          .post('/api/s3/object/getsignedurl', params)
          .then((result) => {
            window.open(result.signedUrl, '_' + p.doc_id + '.' + p.ext);
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            return reject();
          });
      });
    });

    return Promise.all(proms)
      .then(() => {
        console.log('completed');
      })
      .catch((e) => console.error(e));
  }

  changePage = (val) => {
    console.log('val is >>', val);
  };

  render() {
    const { classes, fbSearchInView } = this.props;
    const { result, isFetching, error, pagination: pg } = fbSearchInView;

    if (isFetching) {
      return (
        <div className={classes.root}>
          <Loader message="...loading" />
        </div>
      );
    }

    if (!result) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      );
    }
    if (error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>....error searching</h4>
        </div>
      );
    }
    if (!result.files.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No search result found.</h4>
        </div>
      );
    }
    const tblRowClass = {
      root: classes['tableRow'],
      selected: classes['tableRowSelected'],
    };

    const pageStatement = `${result.files.length} matches in Filebound ${result.ver} for ${result.search_entry}`;

    let pageSelector = '';

    if (pg) {
      // const tsStartStatement = filters.tsStart
      //   ? `from ${moment(filters.tsStart).format('MM-DD-YYYY')} `
      //   : null
      // pageStatement = [
      //   `Displaying
      //     ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
      //   `Page ${this.state.currentPage} of ${pg.totalPagesAvailable
      //   } Currently filtered ${tsStartStatement}to ${moment(
      //     filters.tsEnd
      //   ).format('MM-DD-YYYY')}`
      // ].join('-')

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.props.onPageChange}
          />
        );
      }
    }

    return (
      <Grid container spacing={8} className={classes.scrollable}>
        <Grid item xs={12} sm={12} className={classes.fullHeight}>
          <main className={classes.content}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={12} md={6}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {pageStatement}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {pageSelector}
              </Grid>
            </Grid>
            <div className={`${classes.scrollable} table`}>
              <Table padding="dense">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell align="left" width="5%">
                      #
                    </TableCell>
                    <TableCell align="left" width="10%">
                      Invoice
                    </TableCell>
                    <TableCell align="left" width="10%">
                      PO
                    </TableCell>
                    <TableCell align="left" width="15%">
                      Vendor
                    </TableCell>
                    <TableCell align="left" width="20%">
                      Credit#
                    </TableCell>
                    <TableCell align="left" width="20%">
                      Return#
                    </TableCell>
                    <TableCell align="left" width="20%">
                      Docs
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(result.files || []).map((item, index) => {
                    return (
                      <TableRow key={index} classes={tblRowClass}>
                        <TableCell
                          align="left"
                          width="5%"
                          variant="body"
                          style={{ padding: '0px' }}
                          className={classes.tableBodyCell}>
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          width="10%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          <div>
                            <p>{item.inv_num} </p>
                            <p>{item.inv_dt} </p>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          width="10%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          <div>
                            <p>{item.po_num} </p>
                            <p>{item.po_dt} </p>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          width="15%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          {item.vendor}
                        </TableCell>
                        <TableCell
                          align="left"
                          width="20%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          {item.credit_num}
                        </TableCell>
                        <TableCell
                          align="left"
                          width="20%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          {item.return_num}
                        </TableCell>
                        <TableCell
                          align="left"
                          width="20%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          {(item.docs_for_display || []).map((d, di) => (
                            <div
                              style={{ cursor: 'pointer' }}
                              key={di}
                              onClick={() => this.downloadTif(d)}>
                              <span>
                                {d.divname} ({d.pages.length}pgs)
                              </span>
                            </div>
                          ))}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </main>
        </Grid>
      </Grid>
    );
  }
}

FBAccountingSearchResult.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBAccountingSearchResult))
);
