import React from 'react';
import classnames from 'classnames';
import { serialize } from '../../util/object';
import { nmToId } from '../../util/string';

import {
  ALL_COLOR_MAP,
  ALL_LABEL_TYPES,
  SINGULAR_LOOKUP_KINDS,
  VERTICAL_SUGGESTION_KINDS,
} from './constants';

const globalDividers = process.env.REACT_APP_GLOBAL_DIVIDERS || '';

const baseLabels = {
  // Fallback labels: Will need to remove when the BE label logic is refactored.
  team: [],
  mode: [],
  payer: [],
  tm: [],
  user: [],
  hdms: [],
  product: [],
};

export const buildPatientContext = (nm, src) => ({
  kind: 'patient',
  src,
  id: nmToId(nm),
  nm: nm,
  displayAs: nm,
  pageCount: 0,
  current: 'curr',
  labels: ALL_LABEL_TYPES.reduce((acc, kind) => {
    acc[kind] = [];
    return acc;
  }, baseLabels),
  docs: [],
  oc: null,
  ocLocation: null,
  zip: '',
  oc_zip: '',
});

export const canAppendLabel = (ctx, label) =>
  !SINGULAR_LOOKUP_KINDS.includes(label) ||
  (ctx.labels[label] || []).length < 1;

export const buildDividerContext = (cnm, dnm, src, iter) => {
  const dividerDisplay = `${cnm}:${dnm}${iter ? `:${iter}` : ''}`;
  return {
    kind: 'divider',
    global: src.global,
    id: nmToId(dividerDisplay),
    nm: dnm,
    displayAs: dividerDisplay,
    current: '',
    dt: '',
    pages: [],
    detail: {},
  };
};

export const cloneDivider = (divider, cnm) => {
  const dividerDisplay = `${cnm}:${divider.nm}`;
  return {
    ...divider,
    id: nmToId(dividerDisplay),
    displayAs: dividerDisplay,
  };
};

export const getGlobalDividers = () =>
  globalDividers.split(',').map((nm) => ({
    nm,
    kind: 'divider',
    global: true,
    labels: [
      { kind: 'divider', display: 'DIVIDER' },
      { kind: 'divider', display: 'GLOBAL' },
    ],
  }));

export const findDivider = (ces, nm) => {
  const cesWd = []; // ces which have the 'div' as their divider
  const cesWOd = []; // vice versa
  let first = null;
  for (let i = 0; i < ces.length; i++) {
    const ce = ces[i];
    let div = null;
    for (let j = 0; j < ce.docs.length; j++) {
      const doc = ce.docs[j];
      if (doc.nm === nm) {
        if (!first) first = doc;
        div = doc;
        break;
      }
    }
    if (div) cesWd.push({ id: ce.id, nm: ce.nm, div: div });
    else cesWOd.push({ id: ce.id, nm: ce.nm });
  }

  return { first, cesWd, cesWOd };
};

export const displayOCLoc = (loc) => {
  return `${loc.addr1 || ''} ${loc.addr2 || ''} ${loc.city || '-'}, ${
    loc.state || '-'
  } ${loc.zip || ''}`;
};

export const searchUsers = async (searchStr, signal) => {
  const res = await window.sch.post('/api/indexer:search', {
    searchStr: searchStr.toLowerCase(),
  }, false, signal);

  if (res.error) throw new Error(res.error);

  return res.results;
};

export const searchLabels = (searchStr, signal, labelSrc) => {
  return labelSrc.filter((label) =>
    label.nm.toLowerCase().includes(searchStr.toLowerCase())
  );
};

export const searchOCS = async (searchStr, signal) => {
  const res = await window.sch.post('/api/idx/admin/oc/search', {
    searchStr,
    sorting: {},
    isIdxSearch: true,
  }, false, signal);

  if (res.error) throw new Error(res.error);

  return (
    res.pkg?.rr?.map((oc) => ({
      nm: [oc.first_name || '', oc.last_name || ''].join(' '),
      kind: 'oc',
      info: oc.npi && `NPI #${oc.npi}`,
      labels: [{ kind: 'oc', display: 'OC' }],
      original: oc,
    })) || []
  );
};

export const searchHDMSWorkOrders = async (hdmsWId) => {
  const res = await window.sch.post('/api/hdms/search/wo', {
    hdmsWId,
  });

  if (res.error) {
    console.error(res.error);
    return [];
  }

  return res.results || [];
};

export const searchTeamLookup = async (searchStr, signal) => {
  let res;

  const params = {
    kind: 'team',
  };

  if (Boolean(searchStr)) {
    params.searchStr = searchStr;
  }

  try {
    res = await window.sch.get(
      `/api/idx/admin/lookups/list?${serialize(params)}`,
      undefined,
      signal
    );

    if (res.error) {
      console.error(res.error);
      return [];
    }
  } catch (err) {
    console.log(err);
    return [];
  }

  return (res?.rr || []).map((r) => ({
    nm: r.name,
    kind: 'team',
    labels: [{ kind: 'team', display: 'Team' }],
  }));
};

export const didRotationChange = (oldRotations, newRotations) => {
  for (let i = 0; i < oldRotations.length; i++) {
    if (oldRotations[i] !== newRotations[i]) return true;
  }

  return false;
};

export const displayUserName = (user, fromEmail = true) => {
  return fromEmail
    ? (user.mail || user.email).split('@')[0]
    : user.nm
    ? user.nm.includes(' ')
      ? user.nm.split(' ')[1]
      : user.nm
    : '';
};

export const findDocsWithPage = (ces, idx, accessor = false) => {
  const docList = [];
  for (let i = 0; i < ces.length; i++) {
    const ce = ces[i];
    for (let j = 0; j < ce.docs.length; j++) {
      const doc = ce.docs[j];
      if (doc.pages?.includes(idx)) {
        docList.push(accessor ? doc[accessor] : doc);
        break;
      }
    }
  }

  return docList;
};

export async function fetchInsuranceInfo(data, { onSuccess, onError } = {}) {
  try {
    const response = await window.sch.post(
      '/api/context/patient_insurance',
      data
    );

    if (response.error) {
      throw new Error(response.error);
    }

    if (onSuccess) onSuccess(response);
  } catch (err) {
    if (onError) onError(err);
  }
}

const baseLabelStyles = {
  padding: '2px 4px',
  borderRadius: '4px',
  margin: '0px 6px 10px 0px',
  fontWeight: 'normal',
  fontSize: 10,
  boxShadow:
    '0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 0 0 0 rgb(0 0 0 / 20%)',
};

export const renderSearchSuggestion = (suggestion = {}, classes = {}) => {
  const shouldVerticalAlign = VERTICAL_SUGGESTION_KINDS.includes(
    suggestion.kind
  );
  const labels = suggestion.labels?.length ? suggestion.labels : [];
  return (
    <div className={classes.suggestionNodeContainer}>
      <div
        className={
          classes[
            shouldVerticalAlign ? 'verticalSuggestionNode' : 'suggestionNode'
          ]
        }>
        <div
          className={
            classes[
              shouldVerticalAlign
                ? 'verticalSuggestionLabel'
                : 'suggestionLabel'
            ]
          }>
          {/* truncate(suggestion.nm, shouldVerticalAlign ? 75 : 30) || ''*/}
          {suggestion.nm}
          {suggestion.a && (
            <>
              <br />
              <span className={classes.suggestionAddr}>{suggestion.a}</span>
            </>
          )}
        </div>
        {labels.map((label, key) => (
          <span
            key={key}
            className={classnames(`${label.kind}-label`, {
              [classes.suggestionBadge]: shouldVerticalAlign,
            })}
            style={{ ...baseLabelStyles, ...ALL_COLOR_MAP[label.kind] }}>
            {label && label.display}
          </span>
        ))}
      </div>
      {!shouldVerticalAlign && suggestion.info && (
        <span
          className={
            classes[
              shouldVerticalAlign ? 'verticalSuggestionInfo' : 'suggestionInfo'
            ]
          }>
          {suggestion.info}
        </span>
      )}
    </div>
  );
};
