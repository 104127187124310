export const actionTypes = {
  FETCHING_IDX_ADMIN_TEAM_ASSIGNMENT: 'FETCHING_IDX_ADMIN_TEAM_ASSIGNMENT',
  RECEIVED_IDX_ADMIN_TEAM_ASSIGNMENT: 'RECEIVED_IDX_ADMIN_TEAM_ASSIGNMENT',
  FAILED_FETCHING_IDX_ADMIN_TEAM_ASSIGNMENT:
    'FAILED_FETCHING_IDX_ADMIN_TEAM_ASSIGNMENT',
  SET_IDX_ADMIN_TEAM_ASSIGNMENT: 'SET_IDX_ADMIN_TEAM_ASSIGNMENT'
}

export const fetchIdxAdminTeamAssignment_ac = (filters = {}) => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_TEAM_ASSIGNMENT
    })

    window.sch
      .post('/api/idx/admin/ta/list', filters)
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_TEAM_ASSIGNMENT,
          data: res.pkg
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_TEAM_ASSIGNMENT,
          error: error
        })
      })
  }
}

export const setIdxAdminTeamAssignment_ac = (newData = []) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_IDX_ADMIN_TEAM_ASSIGNMENT,
      data: newData
    })
  }
}
