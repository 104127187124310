import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { bindActionCreators } from 'redux';

import FiltersContainer from './FiltersContainer';

import { Divider } from '@material-ui/core';
import { setTitle_ac } from '../../actions/me.ac';
import OxygenCountsTableList from './OxygenCountsTableList';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit * 0.5,
    overflowY: 'auto',
  },
});

const PAGE_TITLE = 'Equipment Counts';

const OxygenCountsLanding = (props) => {
  const { classes, pageTitle, setTitle_ac } = props;

  useEffect(() => {
    if (pageTitle !== PAGE_TITLE) setTitle_ac(PAGE_TITLE);
  }, [pageTitle, setTitle_ac]);

  return (
    <div className={classes.content}>
      <FiltersContainer />
      <Divider style={{ margin: '8px 0px' }} />
      <OxygenCountsTableList />
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  pageTitle: stateFromStore.me.title,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setTitle_ac,
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OxygenCountsLanding))
);
