import { Button, Grid, TextField } from '@material-ui/core';
import React, { useState } from 'react';

const CreateMailboxes = (props) => {
  const { classes, open_snack_ac } = props;

  const [formVals, setFormVals] = useState({
    email: '',
    name: '',
    concord_mailbox_id: '',
    concord_pw: '',
    concord_faxnumber: '',
    ringcentral_ext: '',
    ringcentral_faxnumber: '',
  });

  const handleChange = (event) => {
    setFormVals({ ...formVals, [event.target.name]: event.target.value });
  };
  const createConcord = () => {
    if (
      !formVals.email ||
      !formVals.name ||
      !formVals.concord_mailbox_id ||
      !formVals.concord_pw ||
      !formVals.concord_faxnumber
    ) {
      props.open_snack_ac({
        variant: 'error',
        message: 'Missing fields',
      });
      return;
    }

    return window.sch
      .post('/api/mailbox:concord:add', {
        faxnumber: formVals.concord_faxnumber,
        mailboxId: formVals.concord_mailbox_id,
        pw: formVals.concord_pw,
        email: formVals.email,
        name: formVals.name,
      })
      .then((res) => {
        open_snack_ac({
          variant: 'fino',
          message: 'Concord mailbox created',
        });
        return;
      })
      .catch(() => {
        open_snack_ac({
          variant: 'error',
          message: 'Failed creating Concord mailbox',
        });
      });
  };

  const createRC = () => {
    if (
      !formVals.email ||
      !formVals.name ||
      !formVals.ringcentral_ext ||
      !formVals.ringcentral_faxnumber
    ) {
      props.open_snack_ac({
        variant: 'error',
        message: 'Missing fields',
      });
      return;
    }

    return window.sch
      .post('/api/mailbox:rc:add', {
        faxnumber: formVals.ringcentral_faxnumber,
        ext: formVals.ringcentral_ext,
        email: formVals.email,
        name: formVals.name,
      })
      .then((res) => {
        open_snack_ac({
          variant: 'fino',
          message: 'RC mailbox created',
        });
        return;
      })
      .catch(() => {
        open_snack_ac({
          variant: 'error',
          message: 'Failed creating RC mailbox',
        });
        return;
      });
  };

  return (
    <form className={classes.formRoot} noValidate autoComplete="off">
      <Grid container spacing={8}>
        <Grid item xs={3} className={classes.leftColumn}>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            onChange={handleChange}
          />
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={3} className={classes.rightColumn}>
          <TextField
            label="Concord Mailbox ID"
            variant="outlined"
            name="concord_mailbox_id"
            onChange={handleChange}
          />
          <TextField
            label="Concord PW"
            variant="outlined"
            name="concord_pw"
            onChange={handleChange}
          />
          <TextField
            label="Concord Fax Number"
            variant="outlined"
            name="concord_faxnumber"
            onChange={handleChange}
          />
          <Button onClick={createConcord} color="primary" variant="outlined">
            Create Concord
          </Button>
        </Grid>
        <Grid item xs={3} className={classes.rightColumn}>
          <TextField
            label="RingCentral Ext"
            variant="outlined"
            name="ringcentral_ext"
            onChange={handleChange}
          />
          <TextField
            label="RingCentral Fax Number"
            variant="outlined"
            name="ringcentral_faxnumber"
            onChange={handleChange}
          />
          <Button onClick={createRC} color="primary" variant="outlined">
            Create RingCentral
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateMailboxes;
