import Button from '@material-ui/core/Button'
import CheckBox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import GetAppIcon from '@material-ui/icons/GetApp'
import { push } from 'connected-react-router'
import moment from 'moment'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchSalesDashRecords_ac,
  updateSalesdashWOSColumns_ac
} from '../../actions/salesdash.ac'
import { open_snack_ac } from '../../actions/snack.ac'
import Loader from '../Shared/Loader'
import StyledToolTip from '../Shared/StyledTooltip'
import CustomSelect from './CustomSelect'
import WOSTable from './WOSTable'

const styles = () => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  columnsModalWrapper: {
    width: '40%',
    minWidth: '500px',
    maxHeight: 'calc(100% - 80px)',
    padding: '0px 8px',
    margin: '40px auto',
    backgroundColor: 'white',
    outline: 'unset',
    overflowY: 'auto'
  },
  modalStickyComponent: {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 10
  },
  salesdashContainer: {
    height: '100%',
    padding: '8px',
    width: 'calc(100vw - 48px)'
  },
  tableContainer: {
    height: 'calc(100% - 222px)'
  },
  marginBottom16: {
    marginBottom: 16
  }
})

const ColumnsUpdateModal = props => {
  const { classes, columns, openColumnsModal, onClose, updateColumns } = props

  const [isOpen, setIsOpen] = useState(openColumnsModal)
  const [columnOptions, changeOptionSelections] = useState(columns)

  const closeModal = () => {
    setIsOpen(false)
    onClose(false)
  }

  const handleColumnSelection = event => {
    const { name, checked } = event.target

    let newColumnOptions = columnOptions.map(option => {
      if (option.accessor !== name) {
        return option
      }

      return {
        ...option,
        visible: checked
      }
    })

    changeOptionSelections(newColumnOptions)
  }

  const handleBulkSelection = button => {
    let newCheckedState = false

    if (button === 'selectAll') {
      newCheckedState = true
    }

    let newColumnOptions = columnOptions.map(option => {
      return {
        ...option,
        visible: newCheckedState
      }
    })

    changeOptionSelections(newColumnOptions)
  }

  const handleSubmission = () => {
    updateColumns(columnOptions)
    closeModal()
  }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <div className={classes.columnsModalWrapper}>
        <Grid
          container
          className={classes.modalStickyComponent}
          style={{ borderBottom: '1px solid #c5c5c5', top: 0 }}>
          <Grid container item>
            <Typography
              align="center"
              variant="h5"
              color="secondary"
              style={{ width: '100%', padding: 8 }}>
              <strong>Show / Hide Columns</strong>
            </Typography>
          </Grid>
          <div style={{ position: 'absolute', right: 0, top: 0 }}>
            <IconButton style={{ padding: 8 }} onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        <div style={{ padding: 8 }}>
          <Grid container spacing={16}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleBulkSelection('selectAll')}>
                Select All
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleBulkSelection('selectNone')}>
                Select None
              </Button>
            </Grid>
          </Grid>
          <div>
            {columnOptions &&
              columnOptions.map(option => (
                <div key={option.accessor}>
                  <FormControlLabel
                    label={
                      <Typography variant="body1">{option.Header}</Typography>
                    }
                    name={option.accessor}
                    checked={option.visible}
                    control={<CheckBox />}
                    onChange={handleColumnSelection}
                  />
                </div>
              ))}
          </div>
        </div>
        <Grid
          container
          justify="flex-end"
          className={classes.modalStickyComponent}
          style={{ borderTop: '1px solid #c5c5c5', bottom: 0 }}>
          <Grid item style={{ padding: 8 }}>
            <Button variant="outlined" color="secondary" onClick={closeModal}>
              Cancel
            </Button>
          </Grid>
          <Grid item style={{ padding: 8 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmission}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

class SalesDashWOS extends React.Component {
  constructor(props) {
    super(props)

    const pathParams = this.getPathParamsAsState(props.match.params)

    this.state = {
      ...pathParams,
      openColumnsModal: false,
      rowsPerPage: 10,
      isDownloading: false
    }
  }

  componentDidMount() {
    const { lookups, me, lookups_ac } = this.props

    if (!me.user) return

    this.props.fetchSalesDashRecords_ac('refresh_ts', {
      endpoint: '/sales/refresh_ts/'
    })

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    this.fetchWOSFilters()
    this.fetchSalesPersons(this.state.selection.region)
    return this.fetch(this.state.selection)
  }

  componentDidUpdate(prevProps) {
    const {
      salesdashWOSRecords,
      salesdashWOSFilters,
      salesdashSalesPersons,
      lookups,
      me,
      lookups_ac
    } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (
      !salesdashWOSFilters.isFetchingWOSFilters &&
      !salesdashWOSFilters.wosFilterError &&
      !salesdashWOSFilters.wosFilters
    ) {
      this.fetchWOSFilters()
    }

    const isSalesPersonEmpty =
      !salesdashSalesPersons.isFetchingSalesPersons &&
      !salesdashSalesPersons.salesPersonsError &&
      !salesdashSalesPersons.salesPersons

    const isNewRegion =
      this.props.match.params.region !== prevProps.match.params.region

    if (isSalesPersonEmpty || isNewRegion) {
      this.fetchSalesPersons(this.props.match.params.region)
    }

    const isRecordEmpty =
      !salesdashWOSRecords.isFetchingWOSRecords &&
      !salesdashWOSRecords.wosRecordError &&
      !salesdashWOSRecords.wosRecords

    const pathIsChanged =
      prevProps.location.pathname !== this.props.location.pathname

    if (isRecordEmpty || pathIsChanged) {
      const {
        region,
        salesperson,
        workBucket,
        category
      } = this.props.match.params

      if (pathIsChanged) {
        const pathParams = this.getPathParamsAsState(this.props.match.params)

        this.setState((state, props) => ({
          ...state,
          ...pathParams
        }))
      }

      return this.fetch({
        region,
        salesperson,
        workBucket,
        category
      })
    }
  }

  getPathParamsAsState = params => {
    return {
      year: params.year,
      month: params.month,
      day: params.day,
      selection: {
        region: params.region,
        salesperson: params.salesperson,
        workBucket: params.workBucket,
        category: params.category
      }
    }
  }

  fetchWOSFilters = () => {
    const endpoint = '/sales/wos/dd/'

    return this.props.fetchSalesDashRecords_ac('wos_filters', { endpoint })
  }

  fetchSalesPersons = region => {
    const endpoint = `/sales/person/list/${region}/`

    return this.props.fetchSalesDashRecords_ac('salespersons', { endpoint })
  }

  fetch = ({ region, salesperson, workBucket, category }) => {
    const { year, month, day } = this.state
    const endpoint =
      [
        '/sales/wos',
        region,
        salesperson,
        workBucket,
        category,
        year,
        month,
        day
      ].join('/') + '/0/'

    return this.props.fetchSalesDashRecords_ac('wos_records', { endpoint })
  }

  handleFilterChange = event => {
    const { name, value } = event.target
    let changeObject = {}

    if (name === 'region' && value !== this.state.selection.region) {
      changeObject.region = value
      changeObject.salesperson = '0'
    } else {
      changeObject[name] = value
    }

    const matchParams = {
      ...this.props.match.params,
      ...changeObject
    }

    const newPath =
      [
        '/salesdash/wos',
        matchParams.region,
        matchParams.salesperson,
        matchParams.workBucket,
        matchParams.category,
        matchParams.year,
        matchParams.month,
        matchParams.day
      ].join('/') + '/'

    this.props.changePath(newPath)
  }

  getQuarterHeader = () => {
    const today = new Date()
    const monthYearStr = today.toLocaleDateString('default', {
      month: 'long',
      year: 'numeric'
    })
    const quarter = parseInt(today.getMonth() / 3) + 1

    return `${monthYearStr}, Q${quarter}`
  }

  handleModalState = openState => {
    this.setState({
      openColumnsModal: openState
    })
  }

  handleRowsPerPageChange = event => {
    const { value } = event.target

    this.setState({
      rowsPerPage: value || 10
    })
  }

  updateColumns = newColumnOptions => {
    const newColumns = [...newColumnOptions]

    this.props.updateSalesdashWOSColumns_ac(newColumns)
  }

  redirectToHomePage = () => {
    return this.props.changePath(
      `/salesdash/home/${window.prevSalesdashHomeTab || 'my_progress'}`
    )
  }

  downloadCSV = () => {
    const { year, month, day } = this.state
    const { region, salesperson, workBucket, category } = this.state.selection

    const endpoint =
      [
        '/sales/wos',
        region,
        salesperson,
        workBucket,
        category,
        year,
        month,
        day,
        '1'
      ].join('/') + '/'

    this.setState({
      isDownloading: true
    })

    window.sch
      .post('/api/salesdash_proxy', { endpoint })
      .then(response => {
        const { salesdash_res } = response

        const url = URL.createObjectURL(
          new Blob([salesdash_res], { type: 'text/plain;charset=utf-8' })
        )
        const downloadLink = document.createElement('a')
        const fileName =
          [
            region,
            salesperson,
            workBucket,
            category,
            `${year}-${month}-${day}`
          ].join('__') + '.csv'

        downloadLink.href = url
        downloadLink.download = fileName
        downloadLink.style.display = 'none'
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      })
      .catch(err => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Error downloading WOS CSV file'
        })
      })
      .finally(() => {
        this.setState({
          isDownloading: false
        })
      })
  }

  render() {
    const { selection } = this.state
    const {
      classes,
      salesdashWOSRecords,
      salesdashWOSFilters,
      salesdashRefreshTS,
      salesdashSalesPersons
    } = this.props
    const {
      isFetchingWOSRecords,
      wosRecords,
      wosRecordError
    } = salesdashWOSRecords
    const {
      isFetchingWOSFilters,
      wosFilters,
      wosFilterError
    } = salesdashWOSFilters
    const {
      salesPersons,
      salesPersonsError,
      isFetchingSalesPersons
    } = salesdashSalesPersons
    const { refreshTS } = salesdashRefreshTS

    let filtersContent = (
      <>
        <Grid container spacing={16} className={classes.marginBottom16}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4">{this.getQuarterHeader()}</Typography>
            {refreshTS && moment(refreshTS).isValid() ? (
              <Typography variant="subtitle2" color="textSecondary">
                HDMS Data Refreshed as of{' '}
                {moment(refreshTS).format('MMMM DD, YYYY, hh:mma')}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            justify="flex-end"
            alignItems="center">
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.redirectToHomePage}>
              <ArrowBackIcon fontSize="inherit" />
              &ensp;
              <span>
                Back to{' '}
                {window.prevSalesdashHomeTab
                  ? window.prevSalesdashHomeTab.replace(/_/g, ' ')
                  : 'Salesdash home'}
              </span>
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={16}
          justify="center"
          className={classes.marginBottom16}>
          <Grid item xs={3} md={2}>
            <CustomSelect
              name="region"
              label="Region"
              value={selection.region}
              options={[
                { label: 'ALL', value: 'ALL' },
                { label: 'NORCAL', value: 'NORCAL' },
                { label: 'SOCAL', value: 'SOCAL' }
              ]}
              onChange={this.handleFilterChange}
              disabled={isFetchingWOSRecords}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <CustomSelect
              name="salesperson"
              value={selection.salesperson}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Salesperson</div>
                  {isFetchingSalesPersons && (
                    <Loader type="circular" size="15" />
                  )}
                  {salesPersonsError && (
                    <StyledToolTip title="Error Fetching Sales Persons!">
                      <ErrorOutlineIcon color="error" fontSize="small" />
                    </StyledToolTip>
                  )}
                </div>
              }
              options={[
                { label: 'ALL', value: '0' },
                ...(salesPersons && salesPersons.length
                  ? salesPersons.map(person => ({
                      label: person.name,
                      value: `${person.id}`
                    }))
                  : [])
              ]}
              onChange={this.handleFilterChange}
              disabled={isFetchingSalesPersons || isFetchingWOSRecords}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <CustomSelect
              name="workBucket"
              value={selection.workBucket}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Work Bucket</div>
                  {isFetchingWOSFilters && <Loader type="circular" size="15" />}
                  {wosFilterError && (
                    <StyledToolTip title="Error Fetching Work Buckets!">
                      <ErrorOutlineIcon color="error" fontSize="small" />
                    </StyledToolTip>
                  )}
                </div>
              }
              options={[
                ...(wosFilters &&
                wosFilters.work_buckets &&
                wosFilters.work_buckets.length
                  ? wosFilters.work_buckets.map(work_bucket => ({
                      label: work_bucket,
                      value: work_bucket.replace(/ /g, '_')
                    }))
                  : [])
              ]}
              onChange={this.handleFilterChange}
              disabled={isFetchingWOSRecords || isFetchingWOSFilters}
            />
          </Grid>
          <Grid item xs={3} md={2}>
            <CustomSelect
              name="category"
              value={selection.category}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Sales Category</div>
                  {isFetchingWOSFilters && <Loader type="circular" size="15" />}
                  {wosFilterError && (
                    <StyledToolTip title="Error Fetching Sales Categories!">
                      <ErrorOutlineIcon color="error" fontSize="small" />
                    </StyledToolTip>
                  )}
                </div>
              }
              options={[
                { label: 'ALL', value: 'ALL' },
                ...(wosFilters &&
                wosFilters.categories &&
                wosFilters.categories.length
                  ? wosFilters.categories.map(category => ({
                      label: category,
                      value: category.replace(/ /g, '_')
                    }))
                  : [])
              ]}
              onChange={this.handleFilterChange}
              disabled={isFetchingWOSRecords || isFetchingWOSFilters}
            />
          </Grid>
        </Grid>
      </>
    )

    if (isFetchingWOSRecords) {
      return (
        <div className={classes.salesdashContainer}>
          {filtersContent}
          <Loader classes={{ message: classes.message }} />
        </div>
      )
    }

    if (wosRecordError) {
      return (
        <div className={classes.salesdashContainer}>
          {filtersContent}
          <Typography color="error" className={classes.message}>
            Error fetchnig records!
          </Typography>
        </div>
      )
    }

    if (!wosRecords) {
      return (
        <div className={classes.salesdashContainer}>
          {filtersContent}
          <div className={classes.message}>...</div>
        </div>
      )
    }

    const visibleColumns = wosRecords.cols.filter(col => col.visible)
    const visibleRows = wosRecords.wos.filter(row => row.visible)

    return (
      <>
        {this.state.openColumnsModal ? (
          <ColumnsUpdateModal
            openColumnsModal={true}
            classes={classes}
            columns={wosRecords.cols}
            onClose={() => this.handleModalState(false)}
            updateColumns={this.updateColumns}
          />
        ) : (
          ''
        )}

        <div className={classes.salesdashContainer}>
          {filtersContent}
          <Grid container direction="row" justify="space-between">
            <Grid container item xs={6} direction="row" alignItems="center">
              <Typography
                variant="body1"
                component="div"
                style={{ marginRight: 4 }}>
                Show
              </Typography>
              <CustomSelect
                name="rowsPerPage"
                label=""
                value={this.state.rowsPerPage}
                options={[
                  { label: '10', value: 10 },
                  { label: '20', value: 20 },
                  { label: '50', value: 50 },
                  { label: '100', value: 100 },
                  { label: 'ALL', value: visibleRows.length }
                ]}
                fullWidth={false}
                onChange={this.handleRowsPerPageChange}
              />
              <Typography
                variant="body1"
                component="div"
                style={{ marginLeft: 4 }}>
                entries
              </Typography>
            </Grid>
            <Grid
              container
              item
              xs={6}
              direction="row"
              alignItems="center"
              justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleModalState(true)}
                disabled={isFetchingWOSRecords}
                style={{ marginRight: 16 }}>
                Show / Hide Columns
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={this.downloadCSV}
                disabled={this.state.isDownloading}>
                <GetAppIcon />
                <span>
                  {!this.state.isDownloading ? 'CSV' : 'Preparing download...'}
                </span>
              </Button>
            </Grid>
          </Grid>
          <div className={classes.tableContainer}>
            <WOSTable
              columns={visibleColumns}
              rows={visibleRows}
              rowsPerPage={this.state.rowsPerPage}
            />
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  salesdashRefreshTS: stateFromStore.salesdashRefreshTS,
  salesdashWOSRecords: stateFromStore.salesdashWOSRecords,
  salesdashWOSFilters: stateFromStore.salesdashWOSFilters,
  salesdashSalesPersons: stateFromStore.salesdashSalesPersons
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      open_snack_ac,
      fetchSalesDashRecords_ac,
      updateSalesdashWOSColumns_ac,
      changePath: path => {
        return push(path)
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SalesDashWOS))
)
