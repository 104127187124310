export const onboardedReportActionTypes = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  RECEIVED_ONBOARDED_REPORT: 'RECEIVED_ONBOARDED_REPORT',
  FAILED_FETCHING_ONBOARDED_REPORT: 'FAILED_FETCHING_ONBOARDED_REPORT'
};

export function fetchOnboardedReport_ac(params) {
  return (dispatch) => {
    dispatch(setIsFetching(true));

    return window.sch.post('/api/onboardedpatients/list', params)
      .then(response => {
        if (response.error) {
          throw response.error;
        }

        dispatch({
          type: onboardedReportActionTypes.RECEIVED_ONBOARDED_REPORT,
          report: response
        });
      })
      .catch(error => {
        dispatch({
          type: onboardedReportActionTypes.FAILED_FETCHING_ONBOARDED_REPORT,
          error: true
        });
      })
      .finally(() => {
        dispatch(setIsFetching(false));
      })
  }
}

const setIsFetching = (isFetching) => ({
  type: onboardedReportActionTypes.SET_IS_FETCHING,
  isFetching
});
