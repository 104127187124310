import React from 'react';
import classNames from 'classnames';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ALL_COLOR_MAP, NAME_LABEL_MAP } from '../../../Indexing/constants';

const styles = () => ({
  contextNode: {
    marginTop: '8px',
  },
  contextNodeTitle: {
    color: 'indigo',
    margin: '0px 0px',
    fontWeight: 'bold',
    marginBottom: '6px',
  },
  contextNodeItem: {
    borderRadius: '4px',
    border: '1px solid #9c27b0',
    marginRight: '6px',
    padding: '4px 6px',
    display: 'inline-block',
    cursor: 'pointer',
    opacity: '0.9',
    '&:hover': {
      opacity: '1',
    },
  },
  contextNodeLabel: {
    color: 'darkgreen',
    fontSize: '11px',
    marginLeft: '6px',
  },
  labelSpan: {
    color: 'darkgreen',
    fontSize: '11px',
    marginLeft: '6px',
  },
});

export const IdxLabel = withStyles(styles)(
  ({ classes, labelType, label, handleNodeClick, border = true }) => (
    <Typography
      onClick={handleNodeClick && ((e) => handleNodeClick(e, labelType, label))}
      style={ALL_COLOR_MAP[labelType]}
      className={classNames({
        [classes.contextNodeItem]: border,
        [classes.cursorDefault]: handleNodeClick,
      })}
      component="span"
      color="secondary">
      {labelType === 'account'
        ? 'A' + label
        : labelType === 'wo_id'
        ? 'W' + label
        : label}
    </Typography>
  )
);

const LabelsNode = ({ labelType, labels, classes, handleNodeClick }) =>
  Boolean(labels[labelType]?.length) && (
    <div className={classes.contextNode}>
      <Typography
        className={classes.contextNodeTitle}
        component="div"
        color="secondary">
        {NAME_LABEL_MAP[labelType]}
      </Typography>
      {labels[labelType].map((label, idx) => (
        <IdxLabel
          key={idx}
          labelType={labelType}
          label={label}
          handleNodeClick={handleNodeClick}
        />
      ))}
    </div>
  );

export default withStyles(styles)(LabelsNode);
