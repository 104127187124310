export function expiringPAsReport(
  state={
    isFetching:false,
    error:null,
    pkg: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_EXPIRING_PA_REPORT':
      return Object.assign({}, state, {
         isFetching: true
       })
    case 'RECEIVED_EXPIRING_PA_REPORT':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: action.pkg,
         error:null
       })
    case 'FAILED_EXPIRING_PA_REPORT':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: null,
         error: action.error
       })
    default:
      return state
  }
}

export function expiringPAs(
  state={
    isFetching:false,
    isFetchingStats:false,
    records:null,
    error:null,
    stats: null,
    statsError:null,
    activityUpdating: false,
    posOfActivity: 0
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_EXPIRING_PA_STATS':
      return Object.assign({}, state, {
         isFetchingStats: true
       })
    case 'RECEIVED_EXPIRING_PA_STATS':
      return Object.assign({}, state, {
         isFetchingStats: false,
         stats: action.stats
       })
    case 'FAILED_EXPIRING_PA_STATS':
      return Object.assign({}, state, {
         isFetchingStats: false,
         stats: null,
         statsError: action.error
       })


    case 'REQUEST_EXPIRING_PAS_LIST':
      return Object.assign({}, state, {
         isFetching: true
       })

    case 'RECEIVED_EXPIRING_PAS_LIST':
      return Object.assign({}, state, {
         isFetching: false,
         records: action.records,
         pagination: action.pagination
       })
    case 'FAILED_RECEIVED_EXPIRING_PAS_LIST':
      return Object.assign({}, state, {
         isFetching: false,
         records: [],
         error: action.error
       })
    case 'EXPIRING_PA_ACTIVITY':
      return Object.assign({}, state, {
         activityUpdating:true,
         posOfActivity: action.posOfActivity,
       })
    case 'EXPIRING_PA_ACTIVITY_DONE':
      return Object.assign({}, state, {
         records: action.records,
         activityUpdating:false,
         posOfActivity: null
       })

    default:
      return state
  }
}
