import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import Loader from '../Shared/Loader'
import OpenCountsTable from './OpenCountsTable'
import { fetchOpenCountsByEmail_ac } from '../../actions/open_counts_by_email.ac'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit,
    overflowY: 'auto'
  },
  infoBlock: {
    marginBottom: 16
  }
})

class OpenCountsByEmail extends React.Component {
  constructor() {
    super()

    this.state = {}
  }

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate(prevProps) {
    this.checkSituation(prevProps)
  }

  checkSituation = (prevProps) => {
    const {
      userEmail,
      openCountsByEmail,
      fetchOpenCountsByEmail_ac
    } = this.props

    if (
      !openCountsByEmail.record &&
      !openCountsByEmail.error &&
      !openCountsByEmail.isFetching
    ) {
      return fetchOpenCountsByEmail_ac({ email: userEmail })
    }

    if ((prevProps && prevProps.userEmail !== userEmail) ||
      (openCountsByEmail.email !== userEmail)) {
      return fetchOpenCountsByEmail_ac({ email: userEmail })
    }
  }

  filterAndSortRecords = () => {
    const { reps, userName } = this.props.openCountsByEmail.record
    let result = {
      user: [],
      teamMembers: []
    }

    if (!userName || !reps) return result

    reps.forEach(rep => {
      if (rep.nm === userName) return result.user.push(rep)

      result.teamMembers.push(rep)
    })
    result.teamMembers.sort((a, b) => a.nm < b.nm ? -1 : 1)

    return result
  }

  render() {
    const { userEmail, classes, openCountsByEmail } = this.props
    const { record, error, isFetching } = openCountsByEmail

    if (isFetching) {
      return <Loader classes={{ message: classes.message }} />
    }

    if (error) {
      return (
        <Typography color="error" className={classes.message}>
          Error fetching report
        </Typography>
      )
    }

    if (!record) {
      return (
        <div className={classes.message}>
          ...
        </div>
      )
    }

    const filteredRecords = this.filterAndSortRecords()

    return (
      <div className={classes.content}>
        <div className={classes.infoBlock}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={4}>
              <Typography component="label" variant="body2" color="primary">
                EMAIL
              </Typography>
              <Typography component="span" variant="body2">
                {userEmail}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography component="label" variant="body2" color="primary">
                DF TEAMS:
              </Typography>
              <Typography component="span" variant="body2">
                  {record.df_teams && record.df_teams.length
                  ? record.df_teams.join(', ')
                  : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography component="label" variant="body2" color="primary">
                TASK TEAMS:
              </Typography>
              <Typography component="span" variant="body2">
                  {record.task_teams && record.task_teams.length
                  ? record.task_teams.join(', ')
                  : 'N/A'}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.infoBlock}>
          <OpenCountsTable
            reps={filteredRecords.user}
            userName={record.userName || 'User'}
            isForUser
           />
        </div>
        <div className={classes.infoBlock}>
          <OpenCountsTable
            reps={filteredRecords.teamMembers}
            userName={record.userName || 'User'}
          />
        </div>
      </div>
    )
  }
}

OpenCountsByEmail.propTypes = {
  classes: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired
}

const mapStateToProps = stateFromStore => ({
  openCountsByEmail: stateFromStore.openCountsByEmail
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOpenCountsByEmail_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OpenCountsByEmail))
