import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import IdnWoEntry from '../Dfa/IdnWoEntry'
import Loader from '../Shared/Loader'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  }
})

class WorkOrderDetailsComponent extends React.Component {
  render() {
    const { workOrdersReportInView, classes } = this.props

    if (workOrdersReportInView.isFetchingWorkOrder) {
      return (
        <main className={classes.content}>
          <Loader message="...loading work order" />
        </main>
      )
    }

    if (workOrdersReportInView.wo_error) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>....error fetching work order</h4>
        </div>
      )
    }

    if (workOrdersReportInView.wo === null) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>
            Click on [View Card] to view details.
          </h4>
        </div>
      )
    }

    if (!workOrdersReportInView.wo) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>Work Order Not Found</h4>
        </div>
      )
    }

    return (
      <IdnWoEntry
        wo={workOrdersReportInView.wo}
        isExpanded={true}
        fromWorkOrder={true}
      />
    )
  }
}

WorkOrderDetailsComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  workOrdersReportInView: stateFromStore.workOrdersReportInView
})

// might be needed in future
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export const WorkOrderDetails = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(WorkOrderDetailsComponent))
)
