import React from 'react';

import { truncate } from '../../util/string';
import SearchField from '../Shared/SearchField';

const LocationsSearchField = (props) => {
  const { label, placeholder, selectLocation, disabled = false } = props;

  const searchLocations = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr });
  };

  const renderLocationSuggestion = (suggestion = {}) => {
    return (
      <div>
        <span
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#000',
            marginTop: '3px',
            display: 'block',
          }}>
          {suggestion.addr1 && <>{suggestion.addr1}</>}
          {suggestion.addr2 && <>{' ' + suggestion.addr2}</>}
          {[
            suggestion.city || '-',
            suggestion.state || '-',
            suggestion.zip || '-',
          ].join(', ')}
        </span>
        <span
          style={{
            fontSize: '10px',
            display: 'block',
          }}>
          {truncate(suggestion.name, 40, suggestion.zip || '')}
        </span>
      </div>
    );
  };

  return (
    <SearchField
      clearOnSelect
      delay={500}
      minChars={3}
      variant="outlined"
      label={label !== undefined ? label : 'Location'}
      resultsKey="pkg.rr"
      disabled={disabled}
      style={{ flexGrow: 1 }}
      search={searchLocations}
      selectSuggestion={selectLocation}
      apiEndpoint="/api/idx/admin/locations/search"
      scrollableParentId="idxOrderingClinicians"
      renderSuggestion={renderLocationSuggestion}
      helperText="Minimum 3 characters required"
      placeholder={
        placeholder !== undefined ? placeholder : 'Search by Location name'
      }
      fullWidth={true}
    />
  );
};

export default LocationsSearchField;
