/*
   claim..ac.js
   action creators for claimInView in the master store
 */

function requestClaim_ac(claimId) {
  return {
    type: 'REQUEST_CLAIM',
    isFetching: true,
    claimId
  }
}

function receivedClaim_ac(claimDoc) {
  return {
    type: 'RECEIVED_CLAIM',
    isFetching: false,
    claimDoc
  }
}

function failed_receivedClaim_ac(claimId, error) {
  return {
    type: 'FAILED_RECEIVED_CLAIM',
    isFetching: false,
    claimId,
    error
  }
}

export function fetchClaim_ac(claimId) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    let storeClaim = getStore().claimInView
    //if storeClaim matches claim attempting to fetch, no actual fetch needed
    //later apply stale check logic here
    if (storeClaim.claimDoc.claim_id === claimId) {
      return dispatch(receivedClaim_ac(storeClaim))
    }

    dispatch(requestClaim_ac(claimId))
    return window.sch.get(`/api/claim/${claimId}`).then(
      result => {
        dispatch(receivedClaim_ac(result.claimDoc))
      },
      error => {
        dispatch(failed_receivedClaim_ac(claimId, error))
      }
    )
  }
}
