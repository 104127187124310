import { actionTypes } from '../actions/react_health.ac';

export const initialState = {
  patient: {
    data: null,
    error: null,
    isFetching: false,
  },
  clinicians: {
    data: null,
    error: null,
    isFetching: false,
  },
  providers: {
    data: null,
    error: null,
    isFetching: false,
  },
  isFetchingPTReport: false,
  isOnboardingPatient: false,
};

export const reactHealth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REACT_HEALTH_PATIENT_PENDING:
      return {
        ...state,
        patient: {
          ...state.patient,
          isFetching: true,
        },
      };

    case actionTypes.FETCH_REACT_HEALTH_PATIENT_SUCCESSFUL:
      return {
        ...state,
        patient: {
          ...state.patient,
          data: action.data,
          isFetching: false,
        },
      };

    case actionTypes.FETCH_REACT_HEALTH_PATIENT_FAILED:
      return {
        ...state,
        patient: {
          ...state.patient,
          error: action.error,
          isFetching: false,
        },
      };

    case actionTypes.FLUSH_REACT_HEALTH_PATIENT:
      return {
        ...state,
        patient: {
          ...state.patient,
          data: null,
          error: null,
          isFetching: false,
        },
      };

    case actionTypes.FETCH_REACT_HEALTH_CLINICIANS_PENDING:
      return {
        ...state,
        clinicians: {
          ...state.clinicians,
          isFetching: true,
        },
      };

    case actionTypes.FETCH_REACT_HEALTH_CLINICIANS_SUCCESSFUL:
      return {
        ...state,
        clinicians: {
          ...state.clinicians,
          data: action.data,
          isFetching: false,
        },
      };

    case actionTypes.FETCH_REACT_HEALTH_CLINICIANS_FAILED:
      return {
        ...state,
        clinicians: {
          ...state.clinicians,
          error: action.error,
          isFetching: false,
        },
      };

    case actionTypes.FIND_REACT_HEALTH_PROVIDERS_PENDING:
      return {
        ...state,
        providers: {
          ...state.providers,
          isFetching: true,
        },
      };

    case actionTypes.FIND_REACT_HEALTH_PROVIDERS_SUCCESSFUL:
      return {
        ...state,
        providers: {
          ...state.providers,
          data: action.data,
          isFetching: false,
        },
      };

    case actionTypes.FIND_REACT_HEALTH_PROVIDERS_FAILED:
      return {
        ...state,
        providers: {
          ...state.providers,
          error: action.error,
          isFetching: false,
        },
      };

    case actionTypes.ONBOARD_PATIENT_REACT_HEALTH_PENDING:
      return {
        ...state,
        isOnboardingPatient: true,
      };

    case actionTypes.ONBOARD_PATIENT_REACT_HEALTH_SUCCESSFUL:
      return {
        ...state,
        isOnboardingPatient: false,
      };

    case actionTypes.ONBOARD_PATIENT_REACT_HEALTH_FAILED:
      return {
        ...state,
        isOnboardingPatient: false,
      };

    case actionTypes.FETCH_REACT_HEALTH_PT_REPORT_PENDING:
      return {
        ...state,
        isFetchingPTReport: true,
      };

    case actionTypes.FETCH_REACT_HEALTH_PT_REPORT_SUCCESSFUL:
    case actionTypes.FETCH_REACT_HEALTH_PT_REPORT_FAILED:
      return {
        ...state,
        isFetchingPTReport: false,
      };

    default:
      return state;
  }
};
