import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
import {
  fetchTodos_ac,
  locallyUpdateTodoDoc_ac,
  toggleAllCards_ac,
} from '../../actions/todos.ac.js';
import { DoctorSearch, PayerSearch } from '../Lookups/HDMSLookupsSearch';
import PatientNotifForm from '../PatientNotifForm';
import SearchSelect from '../Shared/SearchSelect';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    //overflowX: 'auto'
  },
  acctBtnwarn: {
    backgroundColor: 'red',
    color: '#fff',
  },
  acctBtnsuccess: {
    backgroundColor: 'purple',
    color: '#fff',
  },
  menuItem: {
    fontSize: 11,
    padding: '2px 6px 2px 6px',
  },
  menuItemActive: {
    fontSize: 11,
    padding: '2px 6px 2px 6px',
    color: 'blue',
    fontWeight: 'bold',
  },
  linkTo: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  woHdr: {
    fontSize: 11,
    fontWeight: 700,
  },
  woSubHdr: {
    fontSize: 11,
    fontWeight: 300,
  },
  woLine: {
    fontSize: 10,
    color: '#4c5a4d',
  },
  /*

    new,
    pending,
    past-sla (new or pending)

    done,
    cancelled
  */

  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },

  product: { backgroundColor: '#80DEEA', fontWeight: 400, color: '#000' },

  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#000' },
  situationChipP: { backgroundColor: 'orange', fontWeight: 400, color: '#000' },
  situationChipD: {
    backgroundColor: '#56de93',
    fontWeight: 400,
    color: '#000',
  },
  situationChipC: {
    backgroundColor: '#d3d3d35c',
    fontWeight: 400,
    color: '#000',
  },

  owner: { color: '#7f8486' },
  tm: { backgroundColor: '#689F38' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  // IDEA: k
  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  slaStart: {
    marginTop: 6,
    color: '#008AE0',
  },
  slaPasses: {
    color: '#E51400',
    marginBottom: 6,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteTxt: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    color: '#244e91',
  },
  docSetRefLbl: {
    paddingRight: 4,
  },
  woControlLabel: {
    alignItems: 'start',
    marginBottom: 8,
  },
  sitSwitch: {
    height: 28,
  },
  woSwitch: {
    height: 14,
  },
  wuLbl: {
    paddingRight: 4,
    fontSize: 11,
  },
  docSetLenLbl: {
    color: 'orange',
    float: 'right',
  },

  formControl: {
    margin: theme.spacing.unit * 1,
    marginLeft: 0,
  },
  formControlFloatRight: {
    float: 'right',
    margin: theme.spacing.unit * 1,
    marginLeft: 0,
  },
  oaSituationOn: {
    color: '#000',
    fontWeight: 'bold',
    backgroundColor: 'green',
  },
  oaSituationOff: {
    color: '#000',
    backgroundColor: '#fff',
  },
  instruction: {
    fontSize: 11,
    marginTop: 8,
    color: 'purple',
  },
  alertUser: {
    borderRadius: 4,
    backgroundColor: 'orange',
    padding: 8,
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 4,
    cursor: 'pointer',
    marginTop: 12,
    color: '#fff',
  },
  alertUserGreen: {
    borderRadius: 4,
    backgroundColor: 'lightgreen',
    padding: 8,
    fontSize: 12,
    fontWeight: 800,
    marginBottom: 24,
    cursor: 'pointer',
    marginTop: 12,
    color: '#000',
  },
  showMoreWosBtn: {
    cursor: 'pointer',
    fontSize: 11,
    marginTop: 8,
    fontWeight: 800,
    color: 'purple',
  },
});

const CANCEL_REASONS = [
  { code: 'REF', txt: 'Patient Refused', gc: 'C' },
  { code: 'DUP', txt: 'Duplicate', gc: 'C' },
  { code: 'SAM', txt: 'Same/Similar equip already', gc: 'C' },
  { code: 'NI', txt: 'No Insurance/Not contracted', gc: 'C' },
  { code: 'DA', txt: 'Denied authorization', gc: 'C' },
  { code: 'EXP', txt: 'Patient Expired', gc: 'C' },
  { code: 'OSA', txt: 'Outside of Service Area', gc: 'C' },
  { code: 'SNF', txt: 'SNF/LTC/Hospice', gc: 'C' },
  { code: 'FH', txt: 'Financial Hardship', gc: 'C' },
  { code: 'UC', txt: 'Unable to Contact', gc: 'C' },
  { code: 'WP', txt: 'Wrong Product Category', gc: 'C' },
  { code: 'ID', txt: 'Invalid Docs', gc: 'C' },
  { code: 'RFC', txt: 'Pt refused due to copay', gc: 'C' },
  { code: 'IDFT', txt: 'Sent to IDFT', gc: 'C' },
].sort((a, b) => {
  if (a.txt > b.txt) return 1;
  else return -1;
});

const PENDING_TYPES = [
  { code: 'PDO', txt: 'Pending Docs', gc: 'P' },
  { code: 'PDI', txt: 'Pending Discharge', gc: 'P' },
  { code: 'PA', txt: 'Pending Auth', gc: 'P' },
  { code: 'PC', txt: 'Pending Callback', gc: 'P' },
  { code: 'PHR', txt: 'Pending HST Results', gc: 'P' },
  { code: 'PO', txt: 'Pending Other', gc: 'P' },
  { code: 'PSR', txt: 'Pending CCS SAR', gc: 'P' },
  { code: 'PCR', txt: 'Pending Clinical Review', gc: 'P' },
  { code: 'PWO', txt: 'Pending WO Creation', gc: 'P' },
  { code: 'PHW', txt: 'Pending-EPP HWT', gc: 'P' },
].sort((a, b) => {
  if (a.txt > b.txt) return 1;
  else return -1;
});

const DONE_TYPES = [{ code: 'DONE', txt: 'PCR DONE', gc: 'D' }];

class TeamAssignmentPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamOfMembersLastFetched: '',
      memberList: [],
      selectedTeam: props.oa.team || '',
      selectedMember: '',
      isFetchingMemberCount: false,
    };
    this.onTeamSelected = this.onTeamSelected.bind(this);
    this.onMemberSelected = this.onMemberSelected.bind(this);
    this.onAction = this.onAction.bind(this);
    this.allowedToReAssign = this.allowedToReAssign.bind(this);
  }

  fetchMemberCounts() {
    this.setState({ isFetchingMemberCount: true, selectedMember: '' });

    let postData = { teamName: this.state.selectedTeam };
    return window.sch
      .post(`/api/team:counts:get`, postData)
      .then((results) => {
        this.setState({
          memberList: results.all_members,
          teamOfMembersLastFetched: this.state.selectedTeam,
          isFetchingMemberCount: false,
        });
      })
      .catch((error) => {
        this.setState({
          memberList: [],
          teamOfMembersLastFetched: this.state.selectedTeam,
          isFetchingMemberCount: false,
        });
      });
  }

  componentDidMount() {
    if (
      this.state.teamOfMembersLastFetched !== this.state.selectedTeam &&
      this.state.isFetchingMemberCount === false
    ) {
      return this.fetchMemberCounts();
    }
  }

  componentDidUpdate() {
    if (
      this.state.teamOfMembersLastFetched !== this.state.selectedTeam &&
      this.state.isFetchingMemberCount === false
    ) {
      return this.fetchMemberCounts();
    }
  }

  onTeamSelected(e) {
    this.setState({ selectedTeam: e.target.value });
  }

  onMemberSelected(e) {
    this.setState({ selectedMember: e.target.value });
  }

  allowedToReAssign() {
    const { me, oa } = this.props;

    return (
      _.intersection(
        [
          'pcr', //added 8-18-2021, per Angelina
          'allowed_to_route',
          'cs_supervisor',
          'cs_manager',
          'backend-indexer',
          'indexer',
        ],
        me.user.roles
      ).length > 0 ||
      (_.intersection(['pcr_qa'], me.user.roles).length > 0 &&
        oa.user === me.user.nm) ||
      (oa.user === 'CONFIRMATION' && oa.product_bn === 'WO-CONF')
    );
  }

  onAction() {
    this.props.onReAssign({
      team: this.state.selectedTeam,
      member: this.state.selectedMember,
    });
  }

  getMemberSelectLabel = (m) => {
    let memberName = m.nm || '';
    let countLabel = m.n !== undefined ? `(${m.n})` : '';
    let activeLabel = m.is_active ? '- Active' : '';
    let label = [memberName, countLabel, activeLabel].join(' ');

    return label;
  };

  render() {
    const { classes, lookups } = this.props;
    const formGroupRow = { root: classes.formGroupRoot };
    const teamHash = _.get(lookups, 'ink.k.TEAM_MEMBERS', {});
    let teamList = _.keys(teamHash);

    const allowedToReAssign = this.allowedToReAssign();

    if (!allowedToReAssign && !this.props.allowAllForRecall) {
      return (
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            You do not have DF permissions to Re-Assign this Workunit:
          </FormLabel>
        </FormControl>
      );
    }

    if (this.props.allowAllForRecall && !allowedToReAssign) {
      //must be alloweAllForRecall
      teamList = teamList.filter((t) => t.search(/^RECALL/) === 0);
    }

    if (
      this.props.allowAllForRecall &&
      this.props.wDoc &&
      (this.props.wDoc.referral === undefined ||
        this.props.wDoc.payer === undefined)
    ) {
      teamList = teamList.filter((t) => t.search(/^RECALL/) === -1);
    }

    if (teamList.length === 0) {
      return (
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            Referral and Payer must be attached to re-assign to RECALL teams
          </FormLabel>
        </FormControl>
      );
    }

    return (
      <>
        <div>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Re-Assign this Workunit:
            </FormLabel>
          </FormControl>
        </div>

        {this.props.takeOwnershipParams && (
          <FormControl component="fieldset" className={classes.formControl}>
            <Button
              onClick={() => {
                this.props.onReAssign(this.props.takeOwnershipParams);
              }}
              size="small"
              color="primary"
              variant="contained"
              style={{ fontSize: 11, cursor: 'pointer' }}>
              Assign to {this.props.takeOwnershipParams.member}@
              {this.props.takeOwnershipParams.team}
            </Button>
          </FormControl>
        )}

        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel style={{ fontSize: 10 }} component="label">
            Select a Team:
          </FormLabel>
          <FormGroup classes={formGroupRow}>
            {teamList.length > 10 ? (
              <SearchSelect
                name="team"
                options={teamList.map((t) => ({ code: t, lbl: t }))}
                value={this.state.selectedTeam}
                placeholder="Search Team"
                fullWidth
                scrollableParentId="dfaListPopperParent"
                onSelect={this.onTeamSelected}
              />
            ) : (
              <Select
                className={classes.selectElement}
                input={<Input name="team" />}
                onChange={this.onTeamSelected}
                value={this.state.selectedTeam}>
                {teamList.map((teamName) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={teamName}
                    value={teamName}>
                    {teamName}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormGroup>
        </FormControl>
        {this.state.selectedTeam !== '' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel style={{ fontSize: 10 }} component="label">
              Select Member:
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              {this.state.memberList.length > 10 ? (
                <SearchSelect
                  name="member"
                  options={(this.props.oa.team !== this.state.selectedTeam
                    ? [{ nm: 'AUTO-ASSIGN' }, ...this.state.memberList]
                    : this.state.memberList
                  ).map((m) => ({
                    code: m.nm,
                    lbl: this.getMemberSelectLabel(m),
                    lblNode: (
                      <span
                        className={
                          classes['menuItem' + (m.is_active ? 'Active' : '')]
                        }>
                        {this.getMemberSelectLabel(m)}
                      </span>
                    ),
                  }))}
                  value={this.state.selectedMember}
                  placeholder="Search Member"
                  fullWidth
                  scrollableParentId="dfaListPopperParent"
                  onSelect={this.onMemberSelected}
                />
              ) : (
                <Select
                  className={classes.selectElement}
                  input={<Input name="member" />}
                  onChange={this.onMemberSelected}
                  value={this.state.selectedMember}>
                  {this.state.memberList.length > 0 &&
                    this.props.oa.team !== this.state.selectedTeam && (
                      <MenuItem
                        className={classes['menuItem']}
                        value="AUTO-ASSIGN">
                        AUTO-ASSIGN
                      </MenuItem>
                    )}
                  {this.state.memberList.map((m) => (
                    <MenuItem
                      className={
                        classes['menuItem' + (m.is_active ? 'Active' : '')]
                      }
                      key={m.u_id}
                      value={m.nm}>
                      {this.getMemberSelectLabel(m)}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormGroup>
          </FormControl>
        )}

        <FormControl component="fieldset" className={classes.formControl}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={
              this.state.selectedTeam === '' || this.state.selectedMember === ''
            }
            onClick={this.onAction}>
            Re-Assign
          </Button>
        </FormControl>
      </>
    );
  }
}

class AccountSearchInTackle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      account_search_str: '',
      account_search_in_progress: false,
      account_search_results: [],
    };
    this.doAccountSearch = this.doAccountSearch.bind(this);
    this.onSearchAccount = this.onSearchAccount.bind(this);
    this.onAttachAccount = this.onAttachAccount.bind(this);
    this.onProbAcctNumberTapped = this.onProbAcctNumberTapped.bind(this);
  }

  onSearchAccount(e) {
    if (this.account_search_timer) {
      clearTimeout(this.account_search_timer);
    }

    this.setState({
      account_search_str: e.target.value,
    });
    this.account_search_timer = setTimeout(this.doAccountSearch, 500);
  }

  doAccountSearch() {
    if (this.state.account_search_in_progress) return;

    if (this.state.account_search_str.length === 0) {
      this.setState({
        account_search_in_progress: false,
        account_search_results: [],
      });
      return;
    }

    this.setState({
      account_search_in_progress: true,
      account_search_results: [],
    });

    let postData = {
      hdmsAcct: this.state.account_search_str.replace(/^A/g, ''),
      idn: this.props.wDoc.nm,
    };

    return window.sch
      .post_ink(`/api/hdms:search:acct:idn`, postData)
      .then((results) => {
        this.setState({
          account_search_in_progress: false,
          account_search_results: results.results,
        });
        if (
          this.state.account_search_str.replace(/^A/g, '') !== postData.hdmsAcct
        ) {
          //changed since last lookup
          return this.doAccountSearch();
        }
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          account_search_in_progress: false,
          account_search_results: [],
        });
      });
  }

  onAttachAccount() {
    this.props.onAttachAccount(this.state.account_search_results[0].hdms_acct);
    this.setState({
      account_search_in_progress: false,
      account_search_results: [],
      account_search_str: '',
    });
  }

  onProbAcctNumberTapped = (a) => () => {
    this.setState({ account_search_str: a.replace(/-[0-9]$/, '') });
    if (this.account_search_timer) {
      clearTimeout(this.account_search_timer);
    }
    this.account_search_timer = setTimeout(this.doAccountSearch, 500);
  };

  render() {
    const { classes } = this.props;
    const { wDoc } = this.props;
    const oa = wDoc.assignments.length
      ? wDoc.assignments[0][1][0]
      : { wos: [] };
    const allowed = _.get(oa, 'wos', []).length === 0;

    let acctSearchEntry = null;
    if (this.state.account_search_results.length > 0) {
      acctSearchEntry = this.state.account_search_results[0];
    }

    return (
      <FormControl
        fullWidth
        required
        component="fieldset"
        className={classes.formControl}>
        {allowed ? (
          <FormLabel className={classes.instruction} component="label">
            Attach HDMS Account to this Document Set
            {wDoc.probableIdnAccts ? ' - Probable Accounts: ' : ''}
          </FormLabel>
        ) : (
          <FormLabel className={classes.instruction} component="label">
            You must detach the work orders before you can link this workunit to
            different account
          </FormLabel>
        )}

        {allowed &&
          wDoc.probableIdnAccts &&
          wDoc.probableIdnAccts.map((p) => (
            <span
              key={p}
              style={{ cursor: 'pointer', fontSize: 11 }}
              onClick={this.onProbAcctNumberTapped(p)}>
              {p}
            </span>
          ))}

        {allowed && (
          <FormGroup>
            <TextField
              id="acct-search-entry"
              onChange={this.onSearchAccount}
              placeholder="Type account # here"
              style={{ fontSize: 10, margin: '8px 0 8px 0' }}
              value={this.state.account_search_str}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        )}
        {acctSearchEntry ? (
          <Button
            variant="contained"
            className={classes['acctBtn' + acctSearchEntry.labels[0].kind]}
            size="small"
            onClick={this.onAttachAccount}>
            {acctSearchEntry.nm} - {acctSearchEntry.labels[0].display}
          </Button>
        ) : (
          <Button variant="contained" color="primary" size="small" disabled>
            No Matching Accounts
          </Button>
        )}
      </FormControl>
    );
  }
}

class WoEntry extends React.Component {
  render() {
    const { wo, classes } = this.props;
    return (
      <>
        <Typography className={classes.woHdr} color="primary">
          W{wo.hdms_wId} - Dropped {wo.release_dt_str} by {wo.pcr_nm}
        </Typography>
        <Typography className={classes.woSubHdr} color="primary">
          Requested: {wo.req_dt_str}
        </Typography>
        {wo.lines.map((d, dIdx) => (
          <Typography
            key={dIdx + '-' + d.product_code}
            className={classes.woLine}
            color="textSecondary">
            {dIdx + 1}. [QTY {d.qty_ordered}]{' '}
            <span className={classes.productCode}>{d.product_code}</span>{' '}
            {d.product_desc}
          </Typography>
        ))}
      </>
    );
  }
}

class TackleBase extends React.Component {
  constructor(props) {
    super(props);
    const { wDoc } = this.props;
    const hasAcctsAttached = _.get(wDoc, 'hdmsAccts', []).length;
    this.state = {
      selectedLoggerTeam: 'AR LOGGER MEDICARE',
      error: null,
      initialized: false,
      initializedError: false,
      work_order_entry_str: '',
      account_search_str: '',
      account_search_in_progress: false,
      account_search_results: [],
      accountDocs: {
        docs: [],
      },
      formExpanded: false,
      selectedAltAction: !hasAcctsAttached ? 'account_attachment' : 'none',
      altActions: {
        create_conf_wu: {
          btnLabel: 'Create Confirmation WorkUnit',
          action: 'onConvertToConfWorkUnit',
        },
        apply_custom_label: {
          btnLabel: 'Add Custom Label',
          action: 'onApplyCustomLabel',
        },
        apply_rae_label: {
          btnLabel: 'Add RAE Label',
          action: 'onApplyRaeLabel',
        },
        replace_product: {
          btnLabel: 'Replace Product',
          action: 'onReplaceProduct',
        },
        create_newdocs_wu: {
          btnLabel: 'Create New Docs WorkUnit',
          action: 'onCreateNewDocsWorkunit',
        },
        create_ar_wu: {
          btnLabel: 'Create AR Logger WorkUnit',
          action: 'onCreateARLoggerWorkunit',
        },
        referral_attachment: {
          btnLabel: 'Attach Referral Source',
          action: 'onAttachReferral',
        },
        payer_attachment: {
          btnLabel: 'Attach Payer',
          action: 'onAttachPayer',
        },
        convert_to_wo_required: {
          btnLabel: 'Convert to WO-Required',
          action: 'onConvertToWoRequired',
        },
        convert_to_newdocs: {
          btnLabel: 'Convert to New Document Work Unit',
          action: 'onConvertToNewDocs',
        },
      },
    };
    this.onCreateConfWUWorkOrderEntry =
      this.onCreateConfWUWorkOrderEntry.bind(this);
    this.onCreateARLoggerWorkunit = this.onCreateARLoggerWorkunit.bind(this);
    this.onCreateNewDocsWorkunit = this.onCreateNewDocsWorkunit.bind(this);
    this.onLoggerTeamSelect = this.onLoggerTeamSelect.bind(this);
    this.onConvertToConfWorkUnit = this.onConvertToConfWorkUnit.bind(this);
    this.handleFormExpandClick = this.handleFormExpandClick.bind(this);
    this.onReAssign = this.onReAssign.bind(this);
    this.onRemoveFromQ = this.onRemoveFromQ.bind(this);
    this.onConvertToNewDocs = this.onConvertToNewDocs.bind(this);
    this.onConvertToWoRequired = this.onConvertToWoRequired.bind(this);
    this.onAltActionSelected = this.onAltActionSelected.bind(this);
    this.onAttachAccount = this.onAttachAccount.bind(this);
    this.onSituationUpdate = this.onSituationUpdate.bind(this);
    this.prepRenderVars = this.prepRenderVars.bind(this);
    this.initWorkitem = this.initWorkitem.bind(this);
    this.onEnterCustomLabel = this.onEnterCustomLabel.bind(this);
    this.onApplyCustomLabel = this.onApplyCustomLabel.bind(this);
    // this.fetchNotifPkg = this.fetchNotifPkg.bind(this);
    this.initWorkitem();
  }

  initWorkitem(force) {
    if (
      (this.state.initialized && force !== true) ||
      this.state.initializedError
    ) {
      return;
    }

    let postData = { wId: this.props.wDoc._id };
    return (
      window.sch
        // .post_ink(`/api/workitem:init`, postData)
        .post(`/api/workitem:init`, postData)
        .then((results) => {
          console.log('workitem init');
          this.setState({ initialized: true });

          let todo_ids = results.workItem.todo_ids || [];
          let todo_ids_closed = results.workItem.todo_ids_closed || [];

          this.props.onLocalUpdate({
            ...this.props.wDoc,
            probableIdnAccts: results.workItem.idnAccts || [],
            faxJobs: results.workItem.faxJobs,
            m: results.workItem.m,
            todo_ids,
            todo_ids_closed,
          });

          //preload todos linked
          if (todo_ids.length || todo_ids_closed.length) {
            //update the wDoc record in accountInView (which controls the display of the Task tab in DocViewer)

            this.props.fetchTodos_ac({
              //by: 'idn',
              //idn: results.workItem.nm

              by: 'ids',
              ids: [...todo_ids, ...todo_ids_closed],
            });

            setTimeout(() => {
              this.props.onUpdateAccountInViewWDoc({
                todo_ids,
                todo_ids_closed,
              });
            }, 2000);
          }
        })
        // .then(this.fetchNotifPkg)
        .catch((error) => {
          console.error(error);
          this.setState({ initializedError: true });
        })
    );
  }

  // fetchNotifPkg() {
  //   //params: { accounts: [ {Account: 168009, Member: 2}, .. ] }
  //   //params passed in by thunk middleware

  //   const target_url = '/api/notifs:patient:lookup';
  //   let proms = this.props.wDoc.hdmsAccts.map((am) => {
  //     let [Account, Member] = am.split('-').map((j) => parseInt(j, 10));
  //     return window.sch.post_ink(target_url, { Account, Member });
  //   });

  //   return Promise.all(proms)
  //     .then((arr) => {
  //       arr = _.flatten(arr.map((a) => a.Users));
  //       let notif_contacts = [];
  //       let notif_contact_keys = [];
  //       let notif_history = [];
  //       try {
  //         arr.forEach((u) => {
  //           let pos = notif_contact_keys.indexOf(u.Email + u.SMS);
  //           if (-1 === pos) {
  //             notif_contact_keys.push(u.Email + u.SMS);
  //             u.acctMembers = [[u.Account, u.Member].join('-')];
  //             notif_contacts.push(u);
  //           } else {
  //             notif_contacts[pos].acctMembers.push(
  //               [u.Account, u.Member].join('-')
  //             );
  //           }
  //           notif_history = notif_history.concat(u.notif_history);
  //         });
  //       } catch (err) {
  //         console.error(err);
  //       }
  //       //consolidate notifcation preferences across members
  //       this.props.onLocalUpdate({
  //         ...this.props.wDoc,
  //         notif_contacts,
  //         notif_history,
  //       });
  //     })
  //     .catch((error) => {
  //       this.props.onLocalUpdate({
  //         ...this.props.wDoc,
  //         notif_contacts: [],
  //         notif_history: [],
  //       });
  //     });
  // }

  onLoggerTeamSelect(e) {
    this.setState({
      selectedLoggerTeam: e.target.value,
    });
  }

  onNewDocsTeamSelected(e) {
    this.setState({
      selectedNewDocsTeam: e.target.value,
    });
  }

  onCreateNewDocsWorkunit() {
    const { wDoc, me } = this.props;
    let postData = {
      wId: wDoc._id,
      email: me.user.mail,
      team: this.state.selectedNewDocsTeam,
      product: 'NEWDOCS',
    };
    return window.sch
      .post_ink(`/api/workitem:createworkunit`, postData)
      .then(() => {
        this.props.open_snack_ac({
          variant: 'info',
          message: `NEWDOCS workunit created`,
        });
        this.props.onNewWorkunitCreated();
      })
      .catch((error) => {
        console.error(error);
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed creating AR Logger workunit',
        });
      });
  }

  onCreateARLoggerWorkunit() {
    const { wDoc } = this.props;
    let postData = {
      wId: wDoc._id,
      email: 'AUTO-ASSIGN',
      team: this.state.selectedLoggerTeam,
      product: 'NEWDOCS',
    };
    return window.sch
      .post_ink(`/api/workitem:createworkunit`, postData)
      .then(() => {
        this.props.open_snack_ac({
          variant: 'info',
          message: `${this.state.selectedLoggerTeam} workunit created`,
        });
        this.props.onNewWorkunitCreated();
      })
      .catch((error) => {
        console.error(error);
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed creating AR Logger workunit',
        });
      });
  }

  onCreateConfWUWorkOrderEntry(e) {
    if (e.target.value.length && e.target.value.search(/[^0-9]/) > -1) return;
    this.setState({ work_order_entry_str: e.target.value });
  }

  onAltActionSelected(e) {
    this.setState({
      selectedAltAction: e.target.value,
    });
  }

  onConvertToConfWorkUnit() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let wo_id = parseInt(this.state.work_order_entry_str, 10);
    if (oa.sit && oa.sit.length > 0) {
      this.props.open_snack_ac({
        variant: 'error',
        message: 'Remove all pending or cancellation statuses first',
      });
      return;
    }
    if (isNaN(wo_id) || wo_id < 100000) {
      this.props.open_snack_ac({
        variant: 'error',
        message: 'Work order # required',
      });
      return;
    }

    let params = {
      wId: wDoc._id,
      labelAction: 'replaceWithConf',
      label: { kind: 'product', nm: wo_id },
      nm: wDoc.nm,
      idxOfProductToReplace: wDoc.idxInOa + 1,
      //because sc-ink workitem-product replace expects a user to enter a non zero-indexed 1 to indicate array entry 0
    };
    this.props.onTackleActivity({
      kind: 'update_label',
      params,
      oa,
    });
  }

  onConvertToWoRequired() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      wId: wDoc._id,
      labelAction: 'replace',
      label: { kind: 'product', nm: 'WO-REQUIRED' }, //either NEWDOCS or WO-REQUIRED
      nm: wDoc.nm,
      idxOfProductToReplace: wDoc.idxInOa + 1, //because sc-ink workitem-product replace expects a user to enter a non zero-indexed 1 to indicate array entry 0
    };
    this.props.onTackleActivity({
      kind: 'convert_to_wo_required',
      params,
      oa,
    });
  }

  onConvertToNewDocs() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];

    if (oa.sit && oa.sit.length > 0) {
      this.props.open_snack_ac({
        variant: 'error',
        message: 'Remove all pending or cancellation statuses first',
      });
      return;
    }

    let params = {
      wId: wDoc._id,
      labelAction: 'replace',
      label: { kind: 'product', nm: 'NEWDOCS' }, //either NEWDOCS or WO-REQUIRED
      nm: wDoc.nm,
      idxOfProductToReplace: wDoc.idxInOa + 1, //because sc-ink workitem-product replace expects a user to enter a non zero-indexed 1 to indicate array entry 0
    };
    this.props.onTackleActivity({
      kind: 'convert_to_newdocs',
      params,
      oa,
    });
  }

  onReAssign(raParams) {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      fromUser: oa.user,
      fromTeam: oa.team,

      toUser: raParams.member,
      toTeam: raParams.team,

      wId: wDoc._id,
      product: oa.product,
      product_bn: oa.product_bn,
    };
    this.props.onTackleActivity({
      kind: 'reassign',
      params,
      oa,
      stayTackled: raParams.stayTackled === true,
    });
    return;
  }

  onRemoveFromQ() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      wId: wDoc._id,
      product: oa.product,
      product_bn: oa.product_bn,
      user: oa.user,
      team: oa.team,
    };
    this.props.onTackleActivity({
      kind: 'unfollow',
      params,
      oa,
      modifiedWDoc: {
        ...wDoc,
        assignments: [],
        pastAssignments: wDoc.assignments,
      },
    });
  }

  onAttachAccount(acctMember) {
    const { wDoc } = this.props;

    let postData = {
      wId: wDoc._id,
      nm: wDoc.nm,
      hdmsAcct: acctMember, // "12345-1"
    };

    return (
      window.sch
        .post_ink(`/api/workitem:attachdms:account`, postData)
        .then((result) => {
          this.props.onLocalUpdate({
            ...result.modifiedWDoc,
            inTackle: true,
            expanded: true,
            assignments: wDoc.assignments,
            ownersArr: wDoc.ownersArr,
            pastOwnersArr: wDoc.pastOwnersArr,
            moreNotesCount: wDoc.moreNotesCount,
          });
          //if(this.fetchRecentWos){ this.fetchRecentWos( ) }
        })
        // .then(this.fetchNotifPkg)
        .catch((error) => {
          console.log(error);
          console.log('GOT ERROR FROM /api/workitem:attachdms:account');
        })
    );
  }

  onSituationUpdate(e) {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    const { value: sitCodeTapped } = e.target;
    const sitEntry = CANCEL_REASONS.concat(PENDING_TYPES)
      .concat(DONE_TYPES)
      .filter((s) => {
        return s.code === sitCodeTapped;
      })
      .pop();

    let sitAction = sitEntry.checked ? 'remove' : 'add';

    let params = {
      wId: wDoc._id,
      idxInOa: wDoc.idxInOa,
      product: oa.product,
      product_bn: oa.product_bn,
      sitPkg: {
        code: sitEntry.code,
        txt: sitEntry.txt,
        gc: sitEntry.gc,
      },
      sitAction,
    };

    this.props.onTackleActivity({
      kind: 'prod_situation_update',
      params,
      oa,
      stayTackled: true,
    });
  }

  handleFormExpandClick() {
    this.setState({ formExpanded: !this.state.formExpanded });
    return;
  }

  prepRenderVars() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    const hasAcctsAttached = _.get(wDoc, 'hdmsAccts', []).length;
    const sits = _.get(oa, 'sit', []);
    const allowRemove =
      sits.filter((s) => s.gc === 'C' || s.gc === 'D').length > 0;
    const inDoneState = sits.filter((s) => s.gc === 'D').length > 0;
    const sit_codes_selected = sits.map((s) => s.code);
    const pending_switches = PENDING_TYPES.map((pt) => {
      pt.checked = sit_codes_selected.indexOf(pt.code) > -1;
      return pt;
    });

    const cancel_switches = CANCEL_REASONS.map((pt) => {
      pt.checked = sit_codes_selected.indexOf(pt.code) > -1;
      return pt;
    });

    const done_switches = DONE_TYPES.map((pt) => {
      pt.checked = sit_codes_selected.indexOf(pt.code) > -1;
      return pt;
    });

    return {
      wDoc,
      oa,
      hasAcctsAttached,
      sits,
      allowRemove,
      inDoneState,
      sit_codes_selected,
      pending_switches,
      cancel_switches,
      done_switches,
    };
  }

  onEnterCustomLabel(e) {
    this.setState({
      newModeStickerTxt: e.target.value,
    });
  }

  onApplyCustomLabel() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      wId: wDoc._id,
      nm: wDoc.nm,
      labelAction: 'add',
      label: { kind: 'mode', nm: this.state.newModeStickerTxt },
      idxOfProductToReplace: wDoc.idxInOa + 1, //because sc-ink workitem-product replace expects a user to enter a non zero-indexed 1 to indicate array entry 0
    };
    this.props.onTackleActivity({
      kind: 'update_label',
      params,
      stayTackled: true,
      oa,
    });
  }

  render() {
    const { oa } = this.prepRenderVars();

    return (
      <>
        <h5>
          Workunit Type: {oa.product_bn} is not workable in this version of DF+.
          You can re-assign this workunit using the feature below.
        </h5>
      </>
    );
  }
}

class TackleForClosedWorkunit extends TackleBase {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectedNewDocsTeam:
        _.get(props.me.user, 'teamq', []).length > 0
          ? props.me.user.teamq[0].toString()
          : '',
      selectedAltAction: 'create_ar_wu',
    };
  }

  render() {
    const { classes, me, wDoc } = this.props;
    const formGroupRow = { root: classes.formGroupRoot };

    return (
      <>
        <h5>
          This workunit is closed. You can create a new workunit from this
          document set using the feature below.
        </h5>

        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            Other Actions to Take:
          </FormLabel>
          <FormGroup classes={formGroupRow}>
            <Select
              className={classes.selectElement}
              input={<Input name="alt_action" />}
              onChange={this.onAltActionSelected}
              value={this.state.selectedAltAction}>
              <MenuItem value="none">Select an Action</MenuItem>
              <MenuItem value="account_attachment">Account Attachment</MenuItem>
              <MenuItem value="create_ar_wu">
                Create AR Logger work unit
              </MenuItem>
              <MenuItem value="create_newdocs_wu">
                Create New Docs work unit assigned to me
              </MenuItem>
            </Select>
          </FormGroup>
        </FormControl>

        {this.state.selectedAltAction === 'create_conf_wu' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Enter a workorder # for the confirmation workunit
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <TextField
                id="create-conf-wu"
                onChange={this.onCreateConfWUWorkOrderEntry}
                placeholder="Workorder #"
                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                value={this.state.work_order_entry_str}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'create_newdocs_wu' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Select Team
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              {me.user.teamq.length > 10 ? (
                <SearchSelect
                  name="ar_logger_menu"
                  options={me.user.teamq.map((tq) => ({ code: tq, lbl: tq }))}
                  value={this.state.selectedNewDocsTeam}
                  placeholder="Search Team"
                  fullWidth
                  scrollableParentId="dfaListPopperParent"
                  onSelect={this.onTeamSelected}
                />
              ) : (
                <Select
                  className={classes.selectElement}
                  input={<Input name="ar_logger_menu" />}
                  onChange={this.onLoggerTeamSelect}
                  value={this.state.selectedNewDocsTeam}>
                  {me.user.teamq.map((t, ti) => (
                    <MenuItem key={ti} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'create_ar_wu' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Select an AR Logger Team to create workunit for:
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <Select
                className={classes.selectElement}
                input={<Input name="ar_logger_menu" />}
                onChange={this.onLoggerTeamSelect}
                value={this.state.selectedLoggerTeam}>
                <MenuItem value="AR LOGGER MEDICARE">Medicare</MenuItem>
                <MenuItem value="AR LOGGER MEDICAL">MediCal-CCS</MenuItem>
                <MenuItem value="AR LOGGER MCO">MCO</MenuItem>
              </Select>
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'account_attachment' && (
          <AccountSearchInTackle
            wDoc={wDoc}
            classes={classes}
            onAttachAccount={this.onAttachAccount}
          />
        )}

        {this.state.selectedAltAction !== 'none' &&
          this.state.selectedAltAction !== 'account_attachment' && (
            <FormControl component="fieldset" className={classes.formControl}>
              <Button
                disabled={this.props.dfaInView.isUpdating}
                variant="contained"
                color="primary"
                size="small"
                onClick={
                  undefined !==
                  this.state.altActions[this.state.selectedAltAction]
                    ? this[
                        this.state.altActions[this.state.selectedAltAction]
                          .action
                      ]
                    : () => {
                        console.log('do nothing');
                      }
                }>
                {this.state.altActions[this.state.selectedAltAction].btnLabel}
              </Button>
            </FormControl>
          )}
      </>
    );
  }
}

class TackleForConfirmation extends TackleBase {
  constructor(props) {
    super(props);
    this.onMarkCancelled = this.onMarkCancelled.bind(this);
    this.onMarkConfirmed = this.onMarkConfirmed.bind(this);
  }
  componentDidUpdate() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];

    if (wDoc.check_assist_results || this.state.isFetchingCheckAssist) return;

    if (oa.user === 'CONFIRMATION') return;

    this.setState({ isFetchingCheckAssist: true });

    //const attached_wos = _.get(oa, 'wos', [])
    let postData = {
      wId: wDoc._id,
      hdmsWId: oa.product,
      rps: [],
    };

    return (
      window.sch
        //.post_ink(`/api/workitem:check:assist`, postData)
        .post(`/api/workitem:check:assist`, postData)
        .then((check_assist_results) => {
          this.props.onLocalUpdate({
            ...wDoc,
            check_assist_results,
          });
          this.setState({ isFetchingCheckAssist: false });
        })
        .catch((error) => {
          this.setState({
            isFetchingCheckAssist: false,
          });
          this.props.onLocalUpdate({
            ...wDoc,
            check_assist_results: {
              error: true,
            },
          });
        })
    );
  }

  onSituationUpdate(status) {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];

    let params = {
      wId: wDoc._id,
      idxInOa: wDoc.idxInOa,
      product: oa.product,
      product_bn: oa.product_bn,
      sitPkg: {
        code: [],
        txt: status === 'CONFIRMED' ? 'CONFIRMED' : 'CANCELLED',
        lbl:
          status === 'CONFIRMED'
            ? 'WORKORDER CONFIRMED IN HDMS'
            : 'WORKORDER TO BE CANCELLED',
        gc: status === 'CONFIRMED' ? 'D' : 'C',
      },
      sitAction: 'replace',
    };

    this.props.onTackleActivity({
      kind: 'prod_situation_update',
      params,
      oa,
      stayTackled: true,
    });
  }

  onMarkConfirmed() {
    this.onSituationUpdate('CONFIRMED');
  }

  onMarkCancelled() {
    this.onSituationUpdate('CANCELLED');
  }

  render() {
    const { classes, lookups, me } = this.props;
    const { wDoc, oa } = this.prepRenderVars();
    const formGroupRow = { root: classes.formGroupRoot };

    const { check_assist_results: ca } = wDoc;

    if (oa.user === 'CONFIRMATION') {
      return (
        <div style={{ marginBottom: 12 }}>
          <h5>
            This confirmation workunit is still assigned to {oa.user}@{oa.team}
          </h5>
          <div style={{ marginBottom: 6 }}>
            {false && (
              <Button
                onClick={() => {
                  this.onReAssign({
                    member: me.user.nm,
                    team: 'CONFIRMATION',
                    stayTackled: true,
                  });
                }}
                size="small"
                color="primary"
                variant="contained"
                style={{ fontSize: 11, cursor: 'pointer' }}>
                Take Ownership
              </Button>
            )}
          </div>
          <TeamAssignmentPicker
            takeOwnershipParams={{
              member: me.user.nm,
              team: 'CONFIRMATION',
              stayTackled: true,
            }}
            me={me}
            classes={classes}
            lookups={lookups}
            onReAssign={this.onReAssign}
            oa={oa}
          />
        </div>
      );
    }

    if (this.state.isFetchingCheckAssist || ca === undefined) {
      return (
        <div style={{ marginBottom: 12 }}>
          <h5>...loading CONFIRMATION work unit details</h5>
        </div>
      );
    }

    if (ca && ca.error) {
      return <h5>FAILED loading CONFIRMATION work unit details.</h5>;
    }

    const sits = _.get(oa, 'sit', []);
    const allowRemove =
      sits.filter((s) => s.gc === 'C' || s.gc === 'D').length > 0;

    return (
      <>
        <div style={{ marginBottom: 24 }}>
          <div style={{ marginBottom: 12 }}>
            <div>
              Workorder {ca.hwo.hdms_wId} - A{ca.hwo.account}
            </div>
            <div style={{ marginBottom: 6 }}>
              DC {ca.hwo.dc_id} {ca.hwo.dc_nm}
            </div>

            {ca.driver_comment && (
              <div style={{ marginBottom: 6, color: '#37474F' }}>
                Driver comment: {ca.driver_comment}
              </div>
            )}

            {ca.same_or_similar.map((ss, ssi) => (
              <div key={ssi} style={{ paddingBottom: 3 }}>
                <strong style={{ color: 'red' }}>
                  WARNING - SAME OR SIMILAR:
                </strong>{' '}
                {ss}
              </div>
            ))}
          </div>

          <div style={{ marginBottom: 6 }}>
            {ca.serials.map((sn, sni) => (
              <div key={sni} style={{ paddingBottom: 3, color: '#3F51B5' }}>
                Line {sn.num} - {sn.product_code} - SN: {sn.serial_no}
                {sn.line_note && (
                  <div style={{ fontSize: 10 }}>
                    Note: {sn.line_note.trim()}
                  </div>
                )}
              </div>
            ))}
          </div>

          <div style={{ marginBottom: 6 }}>
            <Button
              onClick={() => {
                this.onReAssign({
                  member: ca.hwo.rom_nm,
                  team: ca.hwo.rom_team,
                });
              }}
              size="small"
              color="secondary"
              variant="contained"
              style={{ fontSize: 11, cursor: 'pointer' }}>
              Re-assign to ROM: {ca.hwo.rom_nm}@{ca.hwo.rom_team}
            </Button>{' '}
          </div>

          {ca.hwo.pcr_id && (
            <div style={{ marginBottom: 6 }}>
              <Button
                onClick={() => {
                  this.onReAssign({
                    member: ca.hwo.pcr_nm,
                    team: ca.hwo.pcr_team,
                  });
                }}
                size="small"
                color="secondary"
                variant="contained"
                style={{ fontSize: 11, cursor: 'pointer' }}>
                Re-assign to PCR: {ca.hwo.pcr_nm}@{ca.hwo.pcr_team}
              </Button>{' '}
            </div>
          )}

          <div
            style={{
              marginTop: 16,
              paddingTop: 16,
              borderTop: '1px solid #78909C',
            }}>
            <label>Current status of workunit:</label>
            {oa.sit === undefined ||
            (_.isArray(oa.sit) && oa.sit.length === 0) ? (
              <strong>UNWORKED</strong>
            ) : (
              <strong>{oa.sit[0].txt}</strong>
            )}
          </div>
          <div
            style={{
              marginTop: 16,
              paddingTop: 16,
              borderTop: '1px solid #78909C',
            }}>
            <Button
              onClick={this.onMarkConfirmed}
              size="small"
              variant="contained"
              color="primary"
              style={{ fontSize: 11, cursor: 'pointer' }}>
              WO CONFIRMED in HDMS
            </Button>

            <Button
              onClick={this.onMarkCancelled}
              size="small"
              variant="contained"
              color="secondary"
              style={{ marginLeft: 12, fontSize: 11, cursor: 'pointer' }}>
              WO CANCELLED TO BE CANCELLED
            </Button>
          </div>
        </div>

        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            Other Actions to Take:
          </FormLabel>
          <FormGroup classes={formGroupRow}>
            <Select
              className={classes.selectElement}
              input={<Input name="alt_action" />}
              onChange={this.onAltActionSelected}
              value={this.state.selectedAltAction}>
              <MenuItem value="none">Select an Action</MenuItem>
              <MenuItem value="account_attachment">Account Attachment</MenuItem>
              <MenuItem value="apply_custom_label">Add Custom Label</MenuItem>
              <MenuItem value="convert_to_newdocs">
                Convert to NEWDOCS work unit
              </MenuItem>
              <MenuItem value="convert_to_wo_required">
                Convert to WO-REQUIRED work unit
              </MenuItem>
            </Select>
          </FormGroup>
        </FormControl>

        {this.state.selectedAltAction === 'account_attachment' && (
          <AccountSearchInTackle
            wDoc={wDoc}
            classes={classes}
            onAttachAccount={this.onAttachAccount}
          />
        )}

        {this.state.selectedAltAction === 'apply_custom_label' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Enter a custom label (# not required)
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <TextField
                id="add-custom-label"
                onChange={this.onEnterCustomLabel}
                placeholder="Examples my-label"
                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                value={this.state.newModeStickerTxt}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction !== 'none' &&
          this.state.selectedAltAction !== 'account_attachment' && (
            <FormControl component="fieldset" className={classes.formControl}>
              <Button
                disabled={this.props.dfaInView.isUpdating}
                variant="contained"
                color="primary"
                size="small"
                onClick={
                  this[
                    this.state.altActions[this.state.selectedAltAction].action
                  ]
                }>
                {this.state.altActions[this.state.selectedAltAction].btnLabel}
              </Button>
            </FormControl>
          )}

        <TeamAssignmentPicker
          me={this.props.me}
          classes={classes}
          lookups={lookups}
          onReAssign={this.onReAssign}
          oa={oa}
        />

        <div
          style={{
            marginTop: 16,
            paddingTop: 16,
          }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!allowRemove}
            size="small"
            onClick={this.onRemoveFromQ}>
            Remove from Q
          </Button>
        </div>
      </>
    );
  }
}

class TackleForProducts extends TackleBase {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectedReplacementProduct: 'na',
      selectedRaeLabel: 'na',
      isFetchingRecentWos: false,
      showAllRecentWos: false,
      selectedReferral: null,
      lastAccountOfRecentWos: [],
      isPreCCSUpdating: false,
      allowPreCcsStatusChange: true,
    };

    this.onWoAttached = this.onWoAttached.bind(this);
    this.showMoreWos = this.showMoreWos.bind(this);
    this.fetchRecentWos = this.fetchRecentWos.bind(this);
    this.checkSituation = this.checkSituation.bind(this);

    this.onReplacementProductSelected =
      this.onReplacementProductSelected.bind(this);
    this.onReplaceProduct = this.onReplaceProduct.bind(this);
    this.onRaeSelected = this.onRaeSelected.bind(this);
    this.onApplyRaeLabel = this.onApplyRaeLabel.bind(this);
    this.onAttachReferral = this.onAttachReferral.bind(this);
    this.onAttachPayer = this.onAttachPayer.bind(this);
    this.onSelectedReferral = this.onSelectedReferral.bind(this);
    this.onSelectedPayer = this.onSelectedPayer.bind(this);
    this.onPreCCSUpdate = this.onPreCCSUpdate.bind(this);
    this.onCreateClinicalReviewTask =
      this.onCreateClinicalReviewTask.bind(this);
    this.onAllowPreCcsStatusChangeToggle =
      this.onAllowPreCcsStatusChangeToggle.bind(this);
  }
  onAllowPreCcsStatusChangeToggle() {
    this.setState({
      allowPreCcsStatusChange: !this.state.allowPreCcsStatusChange,
    });
  }

  componentDidMount() {
    this.checkSituation();
  }

  componentDidUpdate() {
    this.checkSituation();
  }

  checkSituation() {
    const { wDoc } = this.props;
    if (
      _.get(wDoc, 'hdmsAccts', []).length === 0 ||
      wDoc.recentWos !== undefined ||
      this.state.isFetchingRecentWos
    ) {
      return;
    }

    if (
      this.state.lastAccountOfRecentWos.sort().join('') ===
      _.get(wDoc, 'hdmsAccts', []).sort().join('')
    ) {
      return;
    }

    //setTimeout(this.fetchRecentWos, 1000)
  }

  onSelectedReferral(referral) {
    this.setState({ selectedReferral: referral });
    return;
  }
  onSelectedPayer(payer) {
    this.setState({ selectedPayer: payer });
    return;
  }

  onCreateClinicalReviewTask() {
    let pkg = {
      idn: this.props.wDoc.nm,
      code: 'cr',
      txt: 'Task created from DF Workunit',
      df_w_id: this.props.wDoc._id,
      helper_nms: [this.props.me.user.nm],
      helper_ids: [this.props.me.user.mail],
      extra_fields: {
        sub_category: 'VENT APPROVAL',
      },
    };
    if (this.props.wDoc.labels.tm) {
      pkg.tm_labels = [...this.props.wDoc.labels.tm];
    }

    if (this.props.wDoc.hdmsAccts.length) {
      let [a, m] = this.props.wDoc.hdmsAccts[0]
        .split(/-/)
        .map((a) => parseInt(a, 10));
      pkg.account = a;
      pkg.member = m;
    }

    return window.sch
      .post('/api/todos/create', pkg)
      .then((result) => {
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Task created',
        });
        return this.initWorkitem(true); //true===force
      })
      .catch((error) => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Create Task failed',
        });
      });
  }

  onAttachPayer() {
    if (this.state.selectedPayer === null) {
      this.props.open_snack_ac({
        variant: 'error',
        message: 'Select a payer drop down matches',
      });
      return;
    }

    const { wDoc } = this.props;
    let postData = {
      wId: wDoc._id,
      payer: this.state.selectedPayer,
    };

    return window.sch
      .post_ink(`/api/workitem:payer:update`, postData)
      .then((result) => {
        this.props.onLocalUpdate({
          ...wDoc,
          payer: result.modifiedWDoc.payer,
        });
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Payer attached',
        });
      })
      .catch((error) => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed attaching payer',
        });
        return;
      });
  }

  onAttachReferral() {
    if (this.state.selectedReferral === null) {
      this.props.open_snack_ac({
        variant: 'error',
        message: 'Select a referral source first',
      });
      return;
    }

    const { wDoc } = this.props;
    let postData = {
      wId: wDoc._id,
      referral: this.state.selectedReferral,
    };

    return window.sch
      .post_ink(`/api/workitem:referral:update`, postData)
      .then((result) => {
        this.props.onLocalUpdate({
          ...wDoc,
          referral: result.modifiedWDoc.referral,
        });
        this.props.open_snack_ac({
          variant: 'info',
          message: 'Referral attached',
        });
      })
      .catch((error) => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed attaching referral source',
        });
        return;
      });
  }

  onPreCCSUpdate() {
    if (this.state.isPreCCSUpdating) return;
    this.setState({ isPreCCSUpdating: true });
    /* 
 { fromUser: 'NICOLE ROJAS',
    fromTeam: 'POP NORTH',
    fromPs:
     { state: 'PRE-CCS-LOBBY',
       u_nm: '',
       u_id: '',
       ts: '2020-08-18T22:49:56.971Z' },
    wId: '5f3c5b14ccf18e61c7bd777f',
    product: 'BIPAP',
    product_bn: 'BIPAP',
    toPsState: 'PRE-CCS-INPROG',
    action: 'workitem:pre-ccs:update',
    apiVersion: 1 } 
    */
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];

    let postData = {
      fromUser: oa.user,
      fromTeam: oa.team,
      fromPs: oa.ps,
      wId: wDoc._id,
      product: oa.product,
      product_bn: oa.product_bn,
      toPsState:
        oa.ps.state === 'PRE-CCS-LOBBY' ? 'PRE-CCS-INPROG' : 'PRE-CCS-DONE',
    };

    return window.sch
      .post_ink(`/api/workitem:pre-ccs:update`, postData)
      .then((result) => {
        //we have to simulate an unwind in ownersArr and assignments
        let { modifiedWDoc: mo } = result;

        // 1) extract only the relevant entry
        //let origSlaPkg = [wDoc.ownersArr[wDoc.idxInOa]].slaPkg
        mo.ownersArr = [mo.ownersArr[wDoc.idxInOa]];
        mo.ownersArr[0].ps = result.newPs;

        // 2) construct the assignments
        mo.assignments = _.toPairs(_.groupBy(mo.ownersArr, 'user'));

        let replacementWDoc = {
          ...wDoc,
          ownersArr: mo.ownersArr,
          assignments: mo.assignments,
        };
        this.props.onLocalUpdate(replacementWDoc);
        this.props.open_snack_ac({
          variant: 'info',
          message: 'PRE CCS Status updated',
        });
        this.setState({ isPreCCSUpdating: false });
      })
      .catch((error) => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed updating PRE-CCS status',
        });
        return;
      });
  }

  onWoAttached(e) {
    const { wDoc } = this.props;
    let { value: tappedWosID } = e.target;
    tappedWosID = parseInt(tappedWosID, 10);

    const oa = wDoc.assignments[0][1][0];
    let attach = true;
    if (_.get(oa, 'wos', []).filter((w) => w.id === tappedWosID).length > 0) {
      attach = false; //because it's already on the oa.wos
    }

    //do not allow detachment if situaton is DONE and there are no other WOS attached
    if (!attach) {
      const sits = _.get(oa, 'sit', []);
      const inDoneState = sits.filter((s) => s.gc === 'D').length > 0;
      if (inDoneState && _.get(oa, 'wos', []).length < 2) {
        console.log('not allowed, in done state');
        this.props.open_snack_ac({
          variant: 'error',
          message:
            "At least 1 work order but be attached when in 'DONE' status.",
        });
        return;
      }
    }

    let postData = {
      wId: this.props.wDoc._id,
      product: oa.product,
      hdmsWId: tappedWosID,
    };

    let target_url = attach
      ? `/api/workitem:attachdms:workorder`
      : `/api/workitem:detachhdms:workorder`;

    return window.sch
      .post(target_url, postData)
      .then((result) => {
        //we have to simulate an unwind in ownersArr and assignments
        let { modifiedWDoc: mo } = result;

        // 1) extract only the relevant entry
        //let origSlaPkg = [wDoc.ownersArr[wDoc.idxInOa]].slaPkg
        mo.ownersArr = [mo.ownersArr[wDoc.idxInOa]];
        //mo.ownersArr[0].slaPkg = { ...wDoc.ownersArr[wDoc.idxInOa].slaPkg }

        // 2) construct the assignments
        mo.assignments = _.toPairs(_.groupBy(mo.ownersArr, 'user'));

        let replacementWDoc = {
          ...wDoc,
          ownersArr: mo.ownersArr,
          assignments: mo.assignments,
        };
        this.props.onLocalUpdate(replacementWDoc);
        this.props.open_snack_ac({
          variant: 'info',
          message: 'WO ' + tappedWosID + (attach ? ' Attached' : ' Detached'),
        });
      })
      .catch((error) => {
        console.error(error);
        this.props.open_snack_ac({
          variant: 'error',
          message:
            'Failed ' +
            (attach ? 'Attaching' : 'Detaching') +
            ' work order: ' +
            tappedWosID,
        });
        return;
      });
  }

  onReplacementProductSelected(e) {
    this.setState({
      selectedReplacementProduct: e.target.value,
    });
  }

  onReplaceProduct() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    if (
      this.state.selectedReplacementProduct === 'na' ||
      oa.product === this.state.selectedReplacementProduct
    )
      return;
    let params = {
      wId: wDoc._id,
      labelAction: 'replace',
      label: { kind: 'product', nm: this.state.selectedReplacementProduct },
      nm: wDoc.nm,
      idxOfProductToReplace: wDoc.idxInOa + 1, //because sc-ink workitem-product replace expects a user to enter a non zero-indexed 1 to indicate array entry 0
    };
    this.props.onTackleActivity({
      kind: 'update_label',
      params,
      oa,
    });
  }

  onRaeSelected(e) {
    this.setState({
      selectedRaeLabel: e.target.value,
    });
  }

  onApplyRaeLabel() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    if (
      this.state.selectedRaeLabel === 'na' ||
      wDoc.labels.tm
        .filter((lbl) => lbl.kind === 'tm')
        .indexOf(this.state.selectedRaeLabel) !== -1
    )
      return;

    let params = {
      wId: wDoc._id,
      labelAction: 'add',
      label: { kind: 'tm', nm: this.state.selectedRaeLabel },
      nm: wDoc.nm,
    };
    this.props.onTackleActivity({
      kind: 'update_label',
      params,
      oa,
    });
  }

  fetchRecentWos() {
    this.setState({ isFetchingRecentWos: true });
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    //const attached_wos = _.get(oa, 'wos', [])
    let postData = {
      workItemId: wDoc._id,
      hdmsAccts: wDoc.hdmsAccts,
      product: oa.product,
    };

    return window.sch
      .post(`/api/hdms/recentwos`, postData)
      .then((recentWos) => {
        this.props.onLocalUpdate({
          ...wDoc,
          recentWos,
        });
        this.setState({ isFetchingRecentWos: false });
      })
      .catch((error) => {
        this.setState({
          isFetchingRecentWos: false,
          lastAccountOfRecentWos: wDoc.hdmsAccts,
        });
        console.log(error);
        console.log('GOT ERROR FROM /api/hdms:recentwos');
        this.props.onLocalUpdate({
          ...wDoc,
          recentWos: {
            error: true,
            wos: [],
            wids: [],
          },
        });
      });
  }

  showMoreWos() {
    this.setState({ showAllRecentWos: true });
  }

  render() {
    const { me, classes, lookups } = this.props;
    const {
      wDoc,
      oa,
      hasAcctsAttached,
      //hasAcctsAttached, sits, sit_codes_selected,
      allowRemove,
      pending_switches,
      cancel_switches,
      done_switches,
    } = this.prepRenderVars();
    const formGroupRow = { root: classes.formGroupRoot };
    const { recentWos } = wDoc;
    const recentWosLabel = recentWos
      ? recentWos.error
        ? 'Error fetching recent orders'
        : recentWos.wos.length + ' recent work order(s) found'
      : this.state.isFetchingRecentWos
      ? '...loading recent work orders'
      : 'HDMS Account must be attached for Recent Workorders to be listed.';

    let woRequired = true;
    if (
      ['RESP MEDS', 'PAP SETTING CHANGES'].includes(oa.product) ||
      _.intersection(_.get(wDoc, 'labels.mode', []), ['HWT', 'CLOSET']).length >
        0
    ) {
      woRequired = false;
    }

    let open_todo_ids = _.get(wDoc, 'todo_ids', []);
    let closed_todo_ids = _.get(wDoc, 'todo_ids_closed', []);

    /* to pass as a prop to PatientNotification, simulating store.idnAccountInfo */
    let idnAccountInfoSimulatedPkg = null;
    if (wDoc.hdmsAccts && wDoc.hdmsAccts.length > 0) {
      let [_a, _m] = wDoc.hdmsAccts[0].split(/-/).map((j) => parseInt(j, 10));
      idnAccountInfoSimulatedPkg = {
        isFetching: false,
        pkg: {
          accounts: [{ account: _a, member: _m }],
          notif_contacts: wDoc.notif_contacts,
        },
      };
    }

    if (oa.ps && oa.ps.state !== 'PRE-CCS-DONE') {
      return (
        <>
          <FormLabel className={classes.instruction} component="label">
            PRE-CCS SECTION:
          </FormLabel>

          <AccountSearchInTackle
            wDoc={wDoc}
            classes={classes}
            onAttachAccount={this.onAttachAccount}
          />

          {false && (
            <FormGroup direction="column" className={classes.formControl}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.allowPreCcsStatusChange}
                    classes={{ switchBase: classes.sitSwitch }}
                    onChange={this.onAllowPreCcsStatusChangeToggle}
                    value={'not_used'}
                  />
                }
                label={'REVEAL PRE-CCS Status Button'}
              />
            </FormGroup>
          )}

          {this.state.allowPreCcsStatusChange && (
            <FormControl
              component="fieldset"
              className={
                classes[
                  oa.ps.state === 'PRE-CCS-INPROG'
                    ? 'formControlFloatRight'
                    : 'formControl'
                ]
              }>
              <Button
                disabled={this.state.isPreCCSUpdating}
                variant="contained"
                color="primary"
                size="small"
                onClick={this.onPreCCSUpdate}>
                Move to{' '}
                {oa.ps.state === 'PRE-CCS-LOBBY'
                  ? 'PRE-CCS-INPROG'
                  : 'PRE-CCS-DONE'}
              </Button>
            </FormControl>
          )}
        </>
      );
    }

    return (
      <>
        {/* OTHER ACTIONS */}
        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            Other Actions to Take:
          </FormLabel>
          <FormGroup classes={formGroupRow}>
            <Select
              className={classes.selectElement}
              input={<Input name="alt_action" />}
              onChange={this.onAltActionSelected}
              value={this.state.selectedAltAction}>
              <MenuItem value="none">Select an Action</MenuItem>
              <MenuItem value="account_attachment">Account Attachment</MenuItem>
              <MenuItem value="referral_attachment">
                Attach Referral Source
              </MenuItem>
              <MenuItem value="payer_attachment">Attach Payer</MenuItem>
              <MenuItem value="replace_product">Replace Product</MenuItem>
              <MenuItem value="convert_to_newdocs">
                Convert to NEWDOCS work unit
              </MenuItem>
              <MenuItem value="create_conf_wu">
                Convert to Confirmation work unit
              </MenuItem>
              <MenuItem value="create_ar_wu">
                Create AR Logger work unit
              </MenuItem>
              <MenuItem value="apply_rae_label">Add/Replace RAE Label</MenuItem>
              <MenuItem value="apply_custom_label">Add Custom Label</MenuItem>
            </Select>
          </FormGroup>
        </FormControl>
        {this.state.selectedAltAction === 'apply_rae_label' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Select RAE
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              {/* Used only this component as lookups.ink.k.TMS_TO_PCRS_HASH 
              has values more than 10 */}
              <SearchSelect
                name="alt_action"
                placeholder="Select RAE"
                options={_.keys(lookups.ink.k.TMS_TO_PCRS_HASH).map((tm) => ({
                  code: tm,
                  lbl: tm + ' ' + lookups.ink.k.TMS_TO_PCRS_HASH[tm].join(', '),
                  lblNode: (
                    <>
                      <span style={{ color: '#000', marginRight: 4 }}>
                        {tm}
                      </span>
                      <span style={{ color: '#607D8B' }}>
                        {lookups.ink.k.TMS_TO_PCRS_HASH[tm].join(', ')}
                      </span>
                    </>
                  ),
                }))}
                value={
                  this.state.selectedRaeLabel === 'na'
                    ? ''
                    : this.state.selectedRaeLabel
                }
                fullWidth
                scrollableParentId="dfaListPopperParent"
                onSelect={this.onRaeSelected}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'apply_custom_label' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Enter a custom label (# not required)
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <TextField
                id="add-custom-label"
                onChange={this.onEnterCustomLabel}
                placeholder="Examples #my-label"
                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                value={this.state.newModeStickerTxt}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'replace_product' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Select Product to replace {oa.product}
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <SearchSelect
                name="replace_product_input"
                placeholder="Search product"
                options={lookups.ink.products.map((p) => ({
                  code: p,
                  lbl: p,
                }))}
                value={
                  this.state.selectedReplacementProduct === 'na'
                    ? ''
                    : this.state.selectedReplacementProduct
                }
                fullWidth
                scrollableParentId="dfaListPopperParent"
                onSelect={this.onReplacementProductSelected}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'create_ar_wu' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Select an AR Logger Team to create workunit for:
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <Select
                className={classes.selectElement}
                input={<Input name="alt_action" />}
                onChange={this.onLoggerTeamSelect}
                value={this.state.selectedLoggerTeam}>
                <MenuItem value="AR LOGGER MEDICARE">Medicare</MenuItem>
                <MenuItem value="AR LOGGER MEDICAL">MediCal-CCS</MenuItem>
                <MenuItem value="AR LOGGER MCO">MCO</MenuItem>
              </Select>
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'create_conf_wu' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Enter a workorder # for the confirmation workunit
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <TextField
                id="create-conf-wu"
                onChange={this.onCreateConfWUWorkOrderEntry}
                placeholder="Workorder #"
                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                value={this.state.work_order_entry_str}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction === 'account_attachment' && (
          <AccountSearchInTackle
            wDoc={wDoc}
            classes={classes}
            onAttachAccount={this.onAttachAccount}
          />
        )}

        {this.state.selectedAltAction === 'referral_attachment' && (
          <DoctorSearch
            label="Search for Doctor"
            onSelect={this.onSelectedReferral}
          />
        )}
        {this.state.selectedReferral && (
          <div style={{ color: '#000', fontSize: 11 }}>
            <span>Selected Referral:</span>
            <span style={{ fontWeight: 'bold' }}>
              {this.state.selectedReferral.last},{' '}
              {this.state.selectedReferral.first} NPI:{' '}
              {this.state.selectedReferral.npi}
            </span>
          </div>
        )}

        {this.state.selectedAltAction === 'payer_attachment' && (
          <PayerSearch
            label="Search for Payer"
            onSelect={this.onSelectedPayer}
          />
        )}
        {this.state.selectedPayer && (
          <div style={{ color: '#000', fontSize: 11 }}>
            <span>Selected Payer:</span>
            <span style={{ fontWeight: 'bold' }}>
              {this.state.selectedPayer.id}, {this.state.selectedPayer.name},{' '}
              {this.state.selectedPayer.payertypename}
            </span>
          </div>
        )}

        {this.state.selectedAltAction !== 'none' &&
          this.state.selectedAltAction !== 'account_attachment' && (
            <FormControl component="fieldset" className={classes.formControl}>
              <Button
                disabled={this.props.dfaInView.isUpdating}
                variant="contained"
                color="primary"
                size="small"
                onClick={
                  this[
                    this.state.altActions[this.state.selectedAltAction].action
                  ]
                }>
                {this.state.altActions[this.state.selectedAltAction].btnLabel}
              </Button>
            </FormControl>
          )}
        <div />

        <TeamAssignmentPicker
          me={this.props.me}
          classes={classes}
          lookups={lookups}
          onReAssign={this.onReAssign}
          allowAllForRecall
          wDoc={wDoc}
          oa={oa}
        />

        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <Grid container spacing={8} direction="row">
            {open_todo_ids.length > 0 && (
              <Grid item>
                <FormLabel
                  onClick={() =>
                    this.props.onSwitchDocViewerTab('todos_linked_w_doc')
                  }
                  className={classes.alertUser}
                  component="div">
                  Open DocFlow Tasks linked to this workunits:
                  {open_todo_ids.join(', ')}
                </FormLabel>
              </Grid>
            )}

            {closed_todo_ids.length > 0 && (
              <Grid item>
                <FormLabel
                  onClick={() =>
                    this.props.onSwitchDocViewerTab('todos_linked_w_doc')
                  }
                  className={classes.alertUserGreen}
                  component="div">
                  Closed DocFlow Tasks linked to this workunits:{' '}
                  {closed_todo_ids.join(', ')}
                </FormLabel>
              </Grid>
            )}

            {open_todo_ids.length === 0 &&
              _.get(oa, 'sit', []).filter((sit) => sit.code === 'PCR').length >
                0 && (
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={oa.user !== me.user.nm || !hasAcctsAttached}
                    size="small"
                    onClick={this.onCreateClinicalReviewTask}
                    component="label">
                    Create Clinical Review Task for Vent Approval
                  </Button>
                </Grid>
              )}
          </Grid>
        </FormControl>

        {/* PENDING AND CANCEL REASONS */}
        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          {oa.user === me.user.nm ? (
            <Grid container spacing={8} direction="row">
              <Grid item>
                <FormLabel className={classes.instruction} component="label">
                  Pending Situations:
                </FormLabel>
                <FormGroup direction="column" className={classes.formControl}>
                  {pending_switches.map((ps) => (
                    <FormControlLabel
                      key={ps.code}
                      control={
                        <Switch
                          checked={ps.checked}
                          classes={{ switchBase: classes.sitSwitch }}
                          onChange={this.onSituationUpdate}
                          value={ps.code}
                        />
                      }
                      label={ps.txt}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item>
                <FormLabel className={classes.instruction} component="label">
                  Cancellation Reasons:
                </FormLabel>
                <FormGroup direction="column" className={classes.formControl}>
                  {cancel_switches.map((ps) => (
                    <FormControlLabel
                      key={ps.code}
                      control={
                        <Switch
                          checked={ps.checked}
                          classes={{ switchBase: classes.sitSwitch }}
                          onChange={this.onSituationUpdate}
                          value={ps.code}
                        />
                      }
                      label={ps.txt}
                    />
                  ))}
                </FormGroup>
              </Grid>
              {(woRequired === false || _.get(oa, 'wos', []).length > 0) && (
                <Grid item>
                  <FormLabel className={classes.instruction} component="label">
                    Workunit Completion - WOS attached:{' '}
                    {_.get(oa, 'wos', [])
                      .map((w) => w.id)
                      .join(', ')}{' '}
                    {open_todo_ids.length > 0 && (
                      <strong style={{ color: 'red' }}>
                        LINKED TASKS MUST BE CLOSED - COMPLETION NOT ALLOWED
                      </strong>
                    )}
                  </FormLabel>
                  <FormGroup direction="column" className={classes.formControl}>
                    {done_switches.map((ps) => (
                      <FormControlLabel
                        key={ps.code}
                        control={
                          <Switch
                            checked={ps.checked}
                            disabled={open_todo_ids.length > 0}
                            classes={{ switchBase: classes.sitSwitch }}
                            onChange={this.onSituationUpdate}
                            value={ps.code}
                          />
                        }
                        label={ps.txt}
                      />
                    ))}
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={8} direction="row">
              <Grid item>
                <FormLabel className={classes.instruction} component="label">
                  You must be the assignee of this workunit to update status
                </FormLabel>
              </Grid>
            </Grid>
          )}
        </FormControl>

        {
          /*  RECENT WOS LISTING */
          true === this.state.isFetchingRecentWos ? (
            <FormControl
              fullWidth
              required
              component="fieldset"
              className={classes.formControl}>
              <FormLabel
                onClick={this.fetchRecentWos}
                className={classes.instruction}
                style={{ cursor: 'pointer' }}
                component="label">
                ....refreshing WO list
              </FormLabel>
            </FormControl>
          ) : (
            <FormControl
              fullWidth
              required
              component="fieldset"
              className={classes.formControl}>
              {hasAcctsAttached ? (
                <FormLabel
                  onClick={this.fetchRecentWos}
                  className={classes.instruction}
                  style={{ cursor: 'pointer', fontWeight: 800 }}
                  component="label">
                  {recentWosLabel} - [Refresh WO List]
                </FormLabel>
              ) : (
                <FormLabel
                  className={classes.instruction}
                  style={{ cursor: 'pointer' }}
                  component="label">
                  WO list not shown until account attached.
                </FormLabel>
              )}
              <FormGroup direction="column" className={classes.formControl}>
                {recentWos &&
                  recentWos.wos.slice(0, 3).map((wo) => (
                    <FormControlLabel
                      classes={{ root: classes.woControlLabel }}
                      key={wo.hdms_wId}
                      control={
                        <Switch
                          checked={
                            _.get(oa, 'wos', [])
                              .map((w) => w.id)
                              .indexOf(wo.hdms_wId) !== -1
                          }
                          classes={{ switchBase: classes.woSwitch }}
                          onChange={this.onWoAttached}
                          value={wo.hdms_wId}
                        />
                      }
                      label={<WoEntry classes={classes} wo={wo} />}
                    />
                  ))}
              </FormGroup>
              {recentWos &&
                recentWos.wos.length > 3 &&
                this.state.showAllRecentWos === false && (
                  <FormLabel
                    onClick={this.showMoreWos}
                    className={classes.showMoreWosBtn}
                    component="label">
                    Show {recentWos.wos.length - 3} more Work Orders
                  </FormLabel>
                )}
              {this.state.showAllRecentWos &&
                recentWos &&
                recentWos.wos.length > 3 && (
                  <FormGroup direction="column" className={classes.formControl}>
                    {recentWos.wos.slice(3).map((wo) => (
                      <FormControlLabel
                        classes={{ root: classes.woControlLabel }}
                        key={wo.hdms_wId}
                        control={
                          <Switch
                            checked={
                              _.get(oa, 'sit.wos', []).indexOf(wo.hdms_wId) !==
                              -1
                            }
                            classes={{ switchBase: classes.woSwitch }}
                            onChange={this.onWoAttached}
                            value={wo.hdms_wId}
                          />
                        }
                        label={<WoEntry classes={classes} wo={wo} />}
                      />
                    ))}
                  </FormGroup>
                )}
            </FormControl>
          )
        }
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel
            className={classes.instruction}
            style={{ marginBottom: 8 }}
            component="label">
            You must link a work order or select a cancel reason to remove from
            your queue. Do not remove if you need it routed - re-assign instead.
          </FormLabel>
          <Button
            variant="contained"
            color="primary"
            disabled={!allowRemove}
            size="small"
            onClick={this.onRemoveFromQ}>
            Remove from Q
          </Button>
        </FormControl>

        {process.env.REACT_APP_IS_DEV && idnAccountInfoSimulatedPkg && (
          <PatientNotifForm
            idnAccountInfo={idnAccountInfoSimulatedPkg}
            onRefreshNotifHistory={this.onRefreshNotifHistory}
            enableSendButton
            stepNumber={1}
            hdmsAccountPos={0}
            selectedRecipientType={'patient'}
            eventLabel="This notification will be sent to the"
          />
        )}
      </>
    );
  }
}

class TackleForNewDocs extends TackleBase {
  //inherit from base tackle comp
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      error: null,
      accountDocs: {
        docs: [],
      },
      formExpanded: false,
    };
    this.onNewDocsReviewSituationUpdate =
      this.onNewDocsReviewSituationUpdate.bind(this);
  }

  onNewDocsReviewSituationUpdate() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let sitAction = _.get(oa, 'sit', []).length > 0 ? 'remove' : 'add';
    let params = {
      wId: wDoc._id,
      idxInOa: wDoc.idxInOa,
      product: oa.product,
      product_bn: oa.product_bn,
      sitPkg: { code: 'NDR', txt: 'DOCS REVIEWED', gc: 'D' },
      sitAction,
    };
    this.props.onTackleActivity({
      kind: 'prod_situation_update',
      params,
      oa,
      stayTackled: true,
    });
  }

  render() {
    const { classes, lookups } = this.props;
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    const formGroupRow = { root: classes.formGroupRoot };
    const situationState = _.get(oa, 'sit', []).length > 0 ? 'On' : 'Off';
    return (
      <>
        <FormControl
          fullWidth
          required
          component="fieldset"
          className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            Other Actions to Take:
          </FormLabel>
          <FormGroup classes={formGroupRow}>
            <Select
              className={classes.selectElement}
              input={<Input name="alt_action" />}
              onChange={this.onAltActionSelected}
              value={this.state.selectedAltAction}>
              <MenuItem value="none">Select an Action</MenuItem>
              <MenuItem value="account_attachment">Account Attachment</MenuItem>
              <MenuItem value="create_conf_wu">
                Convert to Confirmation Workunit
              </MenuItem>
            </Select>
          </FormGroup>
        </FormControl>

        {this.state.selectedAltAction === 'account_attachment' && (
          <AccountSearchInTackle
            wDoc={wDoc}
            classes={classes}
            onAttachAccount={this.onAttachAccount}
          />
        )}

        {this.state.selectedAltAction === 'create_conf_wu' && (
          <FormControl
            fullWidth
            required
            component="fieldset"
            className={classes.formControl}>
            <FormLabel className={classes.instruction} component="label">
              Enter a workorder # for the confirmation workunit
            </FormLabel>
            <FormGroup classes={formGroupRow}>
              <TextField
                id="create-conf-wu"
                onChange={this.onCreateConfWUWorkOrderEntry}
                placeholder="Workorder #"
                style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                value={this.state.work_order_entry_str}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormGroup>
          </FormControl>
        )}

        {this.state.selectedAltAction !== 'none' &&
          this.state.selectedAltAction !== 'account_attachment' && (
            <FormControl component="fieldset" className={classes.formControl}>
              <Button
                disabled={this.props.dfaInView.isUpdating}
                variant="contained"
                color="primary"
                size="small"
                onClick={
                  this[
                    this.state.altActions[this.state.selectedAltAction].action
                  ]
                }>
                {this.state.altActions[this.state.selectedAltAction].btnLabel}
              </Button>
            </FormControl>
          )}

        <FormGroup row className={classes.formControl}>
          <FormLabel className={classes.instruction} component="label">
            Switch the status of this work unit to [DONE], then Remove from Q
          </FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={situationState === 'On'}
                onChange={this.onNewDocsReviewSituationUpdate}
                value="situation_state"
              />
            }
            label="I AM DONE WITH THIS NEW DOCUMENT REVIEW"
          />
        </FormGroup>

        {situationState === 'On' && (
          <FormControl component="fieldset" className={classes.formControl}>
            <Button
              variant="contained"
              disabled={situationState === 'Off'}
              color="primary"
              size="small"
              onClick={this.onRemoveFromQ}>
              Remove from Q
            </Button>
          </FormControl>
        )}

        {situationState === 'Off' && (
          <TeamAssignmentPicker
            classes={classes}
            lookups={lookups}
            me={this.props.me}
            onReAssign={this.onReAssign}
            oa={oa}
          />
        )}
      </>
    );
  }
}

class TackleForConfHelp extends TackleBase {
  constructor(props) {
    super(props);
    this.onSendBack = this.onSendBack.bind(this);
  }

  onSendBack() {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      fromUser: oa.user,
      fromTeam: oa.team,
      toUser: 'CONFIRMATION',
      toTeam: 'CONFIRMATION',
      wId: wDoc._id,
      product: oa.product,
      product_bn: oa.product_bn,
    };
    this.props.onTackleActivity({
      kind: 'reassign',
      params,
      oa,
    });
    return;
  }

  render() {
    const { classes } = this.props;
    //const { wDoc } = this.props
    //const oa = wDoc.assignments[0][1][0]
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={this.onSendBack}>
          Send Back
        </Button>
      </FormControl>
    );
  }
}

class Tackle extends React.Component {
  render() {
    const { wDoc } = this.props;
    if (wDoc.assignments.length === 0) {
      return <TackleForClosedWorkunit {...this.props} />;
    }

    const oa = wDoc.assignments[0][1][0];
    switch (oa.product_bn) {
      case 'NEWDOCS':
        return <TackleForNewDocs {...this.props} />;
      case 'WO-CONF':
        switch (oa.team) {
          case 'CONF-HELP':
          case 'ROMS':
            return <TackleForConfHelp {...this.props} />;
          case 'CONFIRMATION':
            return <TackleForConfirmation {...this.props} />;
          default:
            return <TackleForNewDocs {...this.props} />;
          //return <TackleBase {...this.props} />
        }

      case 'WO-REQUIRED':
        return <TackleForProducts {...this.props} />;
      default:
        return <TackleForProducts {...this.props} />;
    }
  }
}

Tackle.propTypes = {
  classes: PropTypes.object.isRequired,
  wDoc: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  accountInView: stateFromStore.accountInView,
  lookups: stateFromStore.lookups,
  dfaInView: stateFromStore.dfaInView,
  todosInView: stateFromStore.todosInView,
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      close_snack_ac,
      fetchTodos_ac,

      locallyUpdateTodoDoc_ac,
      toggleAllCards_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Tackle))
);

//export default withStyles(styles)(Tackle);
