import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  attachGenSubmissionFilesToStore_ac,
  resetGenSubmissionFilesToStore_ac,
} from '../actions/generalUploadActivity.ac';
import { open_snack_ac } from '../actions/snack.ac.js';
import UploadToS3V2 from './DocRequest/UploadToS3_V2';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    width: '100%',
    overflowY: 'hidden',
  },
  button: {
    marginTop: 24,
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    padding: 12,
    paddingLeft: 0,
  },
});

const PRESUBMISSION_BUCKET_BASE_FOLDER = 'uploads_to_merge';

const DEFAULT_ASSIGN_TO = 'me';
const ASSIGN_TO_OPTS = [
  {
    label: 'Myself',
    value: 'me',
  },
  {
    label: 'Unassigned',
    value: 'unassigned',
  },
];

class MyUploads extends React.Component {
  constructor(props) {
    super(props);
    this.handleCreateDocSubmission = this.handleCreateDocSubmission.bind(this);
    this.state = {
      submissionInProgress: false,
      trmSource: '',
      assignTo: DEFAULT_ASSIGN_TO,
    };
  }

  componentDidMount() {
    return this.checkSituation();
  }

  componentDidUpdate() {
    return this.checkSituation();
  }

  checkSituation() {
    return;
  }
  handleCreateDocSubmission() {
    const { generalUploadActivity: activity, me } = this.props;

    let now = new moment();
    this.setState({ submissionInProgress: true });

    let payload = {
      //trm_source: this.state.trmSource,
      assign_to: this.state.assignTo,
      outputFileBasename: ['Merged_', now.format('YYYYMMDD_hhmmss')].join('_'),
      s3keys: activity.submissionFiles.map((f) => {
        return f.s3key;
      }),
    };
    return window.sch
      .post('/api/utils/merge_pdfs_on_s3', payload)
      .then((results) => {
        console.log(results);

        let s3pathAndKey = results.result.mergedKey
          .split(/\//g)
          .slice(4)
          .join('/');

        const payload_for_ds_create = {
          trm_source: this.state.trmSource,
          assign_to: this.state.assignTo,
          idn: 'NON-AUTOINDEX',
          account: 'NA',
          files: [
            {
              meta: {
                /*
                autoindexRequest:
                  '#autoindex #CASSAR, TEST 1-20-1996 #BLANK-MISC PAGE',
                accountNumber: '1472-3',
                */
                trm_source: this.state.trmSource,
              },
              type: 'application/pdf',
              s3pathAndKey,
              position: 0,
            },
          ],
          qname:
            this.state.assignTo === 'unassigned'
              ? 'DIRECT-V2'
              : 'Q-' + me.user.nm,
          triggerWithMetadata: true,
        };

        return window.sch.post(
          '/api/docsubmission/create_from_s3_copy',
          payload_for_ds_create
        );
      })
      .then((results) => {
        console.log(results);
        this.setState({ submissionInProgress: false });
        this.props.open_snack_ac({
          variant: 'info',
          message: `Files submitted`,
        });

        return this.props.resetGenSubmissionFilesToStore_ac();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ submissionInProgress: false });
        this.props.open_snack_ac({
          variant: 'error',
          message: `Something went wrong`,
        });
        return this.props.resetGenSubmissionFilesToStore_ac();
      });

    /*
      
      */
  }

  render() {
    const {
      classes,
      me,
      generalUploadActivity,
      attachGenSubmissionFilesToStore_ac,
    } = this.props;
    const filesSuccessfullyUploaded =
      generalUploadActivity.submissionFiles.filter((f) => {
        return f.progressPercent === 100;
      }).length;
    const okToSubmit =
      generalUploadActivity.submissionFiles.length > 0 &&
      generalUploadActivity.submissionFiles.length ===
        filesSuccessfullyUploaded;

    return (
      <div className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.gridItem}
            style={{ marginBottom: '8px' }}>
            <div className={classes.pagination}>
              Pick a transmission source:
            </div>
            <Select
              fullWidth
              displayEmpty
              value={this.state.trmSource || ''}
              name="label_type"
              onChange={(e) => {
                if (e.target.value)
                  this.setState({ trmSource: e.target.value });
              }}>
              <MenuItem value={''} style={{ color: 'grey' }}>
                {'Choose here'}
              </MenuItem>
              {(this.props.lookups?.ink?.k?.TRM_SOURCES || []).map(
                (label, idx) => (
                  <MenuItem
                    style={{ fontSize: 11, fontWeight: 600 }}
                    key={idx}
                    value={label}>
                    {label}
                  </MenuItem>
                )
              )}
            </Select>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.gridItem}
            style={{ marginBottom: '8px' }}>
            <div className={classes.pagination}>Assign Indexing Job to:</div>
            <Select
              fullWidth
              displayEmpty
              value={this.state.assignTo}
              name="assign_to"
              onChange={(e) => {
                if (e.target.value) this.setState({ assignTo: e.target.value });
              }}>
              {ASSIGN_TO_OPTS.map((obj, idx) => (
                <MenuItem
                  style={{ fontSize: 11, fontWeight: 600 }}
                  key={idx}
                  value={obj.value}>
                  {obj.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
            <div className={classes.pagination}>Upload for V2 Indexing</div>
            <UploadToS3V2
              disabled={!Boolean(this.state.trmSource)}
              me={me}
              s3KeyPrefix={PRESUBMISSION_BUCKET_BASE_FOLDER}
              activityStore={this.props.generalUploadActivity}
              purpose="submission"
              withDividerNames={false}
              onFilesChanged={attachGenSubmissionFilesToStore_ac}
              beginUploadFiles={() => {
                console.log('n/a');
              }}
              additionalSigningParams={{ purpose: 'submission' }}
              autoUpload={true}
              idn={me.user.nm.replace(/ /g, '_')}
            />

            <Button
              disabled={
                !okToSubmit ||
                this.state.submissionInProgress ||
                !Boolean(this.state.trmSource)
              }
              onClick={this.handleCreateDocSubmission}
              variant="contained"
              color="primary"
              className={classes.button}>
              SUBMIT FOR V2 INDEXING ({filesSuccessfullyUploaded} Files
              Attached)
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MyUploads.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  generalUploadActivity: stateFromStore.generalUploadActivity,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetGenSubmissionFilesToStore_ac,
      attachGenSubmissionFilesToStore_ac,
      changePage: (path) => push(path),
      open_snack_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyUploads));
