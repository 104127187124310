import { combineReducers } from 'redux';

import listingReducers, {
  initialStates as listingInitialStates,
} from './workunitsListing';

export const workunitsInitialStates = {
  ...listingInitialStates,
};

export default combineReducers({
  ...listingReducers,
});
