import React from 'react';
import DatePicker from 'react-datepicker';
import { withStyles } from '@material-ui/core/styles';
import { BiSearch as SearchIcon } from 'react-icons/bi';
import { Button, Grid, TextField } from '@material-ui/core';

const styles = () => ({
  '@global': {
    '.react-datepicker__close-icon::after': {
      backgroundColor: 'transparent',
      color: '#000000',
      fontSize: 18,
    },
    '.react-datepicker-wrapper': {
      width: '100%',
    },
  },
});

const Filters = (props) => {
  const {
    filters,
    isFetching,
    applyFilters,
    onFilterChange,
    handleClearFilters,
  } = props;
  return (
    <Grid
      container
      spacing={16}
      style={{ alignItems: 'end', paddingBottom: 10 }}>
      <Grid item xs={2}>
        <DatePicker
          isClearable
          dateFormat="MM-dd-yyyy"
          selected={filters.start_date}
          onChange={(date) => onFilterChange('start_date', date)}
          customInput={
            <TextField
              margin="dense"
              label="Start Date"
              style={{ width: '100%' }}
            />
          }
        />
      </Grid>
      <Grid item xs={2}>
        <DatePicker
          isClearable
          dateFormat="MM-dd-yyyy"
          selected={filters.end_date}
          onChange={(date) => onFilterChange('end_date', date)}
          customInput={
            <TextField
              margin="dense"
              label="End Date"
              style={{ width: '100%' }}
            />
          }
        />
      </Grid>

      <Grid item xs={3}>
        <TextField
          fullWidth
          id="title"
          name="title"
          margin="dense"
          placeholder="Search Title"
          value={filters.title || ''}
          disabled={isFetching}
          InputProps={{
            startAdornment: <SearchIcon size={20} color="#0000008a" />,
            style: { height: '24px' },
          }}
          onChange={(e) => {
            const { name, value } = e.target;
            onFilterChange(name, value);
          }}
        />
      </Grid>

      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={applyFilters}
          disabled={isFetching}>
          Show Records
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={handleClearFilters}
          disabled={isFetching}>
          Clear All
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Filters);
