import { open_snack_ac } from './snack.ac.js'
import moment from 'moment'
import { DEFAULT_FREQUENCY_LIST, TUBING_ITEMS_HCPC_CODES } from '../constants/PAPFrequencyConstant.js'

export const PAPCalculatorActionTypes = {
  SET_FREQUENCY_IDENTIFIERS: 'SET_FREQUENCY_IDENTIFIERS',
  ITEMS_SELECTED: 'ITEMS_SELECTED',
  RESET_ITEMS_SELECTED: 'RESET_ITEMS_SELECTED',
  ITEMS_DOS_CHANGED: 'ITEMS_DOS_CHANGED',
  LOAD_FREQUENCY_LIST: 'LOAD_FREQUENCY_LIST',
  SET_IS_SAVING: 'SET_IS_SAVING',
  SET_IS_FETCHING: 'SET_IS_FETCHING'
}

const MAP_FREQUENCY_FROM_PAYER = {
  medicare: {
    A7030: {
      frequency: '3',
      quantity: 1
    },
    A7031: {
      frequency: '3',
      quantity: 3
    },
    A7034: {
      frequency: '3',
      quantity: 1
    },
    A7032: {
      frequency: '3',
      quantity: 6
    },
    A7033: {
      frequency: '3',
      quantity: 6
    },
    A7035: {
      frequency: '6',
      quantity: 1
    },
    A7036: {
      frequency: '6',
      quantity: 1
    },
    A7037: {
      frequency: '3',
      quantity: 1
    },
    A7038: {
      frequency: '3',
      quantity: 1
    },
    A7039: {
      frequency: '6',
      quantity: 1
    },
    A7046: {
      frequency: '6',
      quantity: 1
    },
    A4604: {
      frequency: '3',
      quantity: 1
    }
  },
  'medi-cal': {
    A7030: {
      frequency: '12',
      quantity: 1
    },
    A7031: {
      frequency: '12',
      quantity: 3
    },
    A7034: {
      frequency: '6',
      quantity: 1
    },
    A7032: {
      frequency: '3',
      quantity: 3
    },
    A7033: {
      frequency: '3',
      quantity: 6
    },
    A7035: {
      frequency: '6',
      quantity: 1
    },
    A7036: {
      frequency: '6',
      quantity: 1
    },
    A7037: {
      frequency: '6',
      quantity: 1
    },
    A7038: {
      frequency: '3',
      quantity: 6
    },
    A7039: {
      frequency: '6',
      quantity: 1
    },
    A7046: {
      frequency: '6',
      quantity: 1
    },
    A4604: {
      frequency: '6',
      quantity: 1
    },
  }
}

export const addHDMSNotes_ac = (params) => dispatch => {
  dispatch(saving(true))
  return window.sch
    .post(`/api/notes/save`, params)
    .then(response => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: 'HDMS notes saved'
        })
      )
      dispatch(resetSelectedItems_ac());
    })
    .catch(error => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Failed Saving Notes'
        })
      )
    })
    .finally(() => {
      dispatch(saving(false));
    })
}

export const setFrequencyIdentifiers_ac = (params) => dispatch => {
  dispatch({
    type: PAPCalculatorActionTypes.SET_FREQUENCY_IDENTIFIERS,
    payload: params
  })
}

// const getFrequencyBasedOnPayer = (payer, hcpc_code) => {
//   return MAP_FREQUENCY_FROM_PAYER[payer] ? MAP_FREQUENCY_FROM_PAYER[payer][hcpc_code].frequency : null
// }

// const getQuantityBasedOnPayer = (payer, hcpc_code) => {
//   return MAP_FREQUENCY_FROM_PAYER[payer] ? MAP_FREQUENCY_FROM_PAYER[payer][hcpc_code].quantity : null
// }

const getFilteredList = (payer, selected_dos) => {
  return selected_dos
    ? DEFAULT_FREQUENCY_LIST.map((item, index) => ({
      ...item,
      frequency: MAP_FREQUENCY_FROM_PAYER[payer] ? MAP_FREQUENCY_FROM_PAYER[payer][item.hcpc_code].frequency : null,
      quantity: MAP_FREQUENCY_FROM_PAYER[payer] ? MAP_FREQUENCY_FROM_PAYER[payer][item.hcpc_code].quantity : null,
      dos: selected_dos,
      eligible: MAP_FREQUENCY_FROM_PAYER[payer] ? moment(new Date(selected_dos)).add(MAP_FREQUENCY_FROM_PAYER[payer][item.hcpc_code].frequency, 'months') : null,
    }))
    : []
}

export const loadFrequencyList_ac = (params) => (dispatch, getState) => {
  let selectedPayer = getState().papCalculator.payer || 'medi-cal'
  let selected_dos = getState().papCalculator.last_date_of_service

  dispatch({
    type: PAPCalculatorActionTypes.LOAD_FREQUENCY_LIST,
    list: getFilteredList(selectedPayer, selected_dos)
  })
}

export const selectItems_ac = (hcpc_code, checked) => (dispatch, getState) => {
  let list = getState().papCalculator.frequencyList

  if (TUBING_ITEMS_HCPC_CODES.includes(hcpc_code) && checked) {
    // check for tubing selection and allow to unselect the checked tubing as well
    let tubingSelectedRowExists = list.find(item => TUBING_ITEMS_HCPC_CODES.includes(item.hcpc_code) && item.isChecked)
    if (tubingSelectedRowExists) {
      return dispatch(open_snack_ac({
        variant: 'info',
        message: 'Select only one type of tubing.'
      }))
    }
  }

  dispatch({
    type: PAPCalculatorActionTypes.ITEMS_SELECTED,
    selected_hcpc_code: hcpc_code,
    checked: checked
  })
}

export const resetSelectedItems_ac = () => dispatch => {
  dispatch({
    type: PAPCalculatorActionTypes.RESET_ITEMS_SELECTED
  })
}

export const itemsDOSChanged_ac = (hcpc_code, dos) => dispatch => {
  dispatch({
    type: PAPCalculatorActionTypes.ITEMS_DOS_CHANGED,
    selected_hcpc_code: hcpc_code,
    dos: dos
  })
}

export const fetching = isFetching => ({
  type: PAPCalculatorActionTypes.SET_IS_FETCHING,
  isFetching
})

export const saving = isSaving => ({
  type: PAPCalculatorActionTypes.SET_IS_SAVING,
  isSaving
})



// medi-cal ===> 1 for every 3 months
// medicare ==> 2 for every 3 month