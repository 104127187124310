export function expiringCCSMCalReport(
  state={
    isFetching:false,
    error:null,
    pkg: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_EXPIRING_CCS_MCAL_REPORT':
      return Object.assign({}, state, {
         isFetching: true
       })
    case 'RECEIVED_EXPIRING_CCS_MCAL_REPORT':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: action.pkg,
         error:null
       })
    case 'FAILED_EXPIRING_CCS_MCAL_REPORT':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: null,
         error: action.error
       })
    default:
      return state
  }
}
