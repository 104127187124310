/* web worker handling */
import { open_notif_snack_ac } from './snack.ac.js';
export function intel_worker_init(kind, pkg, cb) {
  return (dispatch, getState) => {
    let worker = new Worker('/workers/Intel.js');
    return window.sch
      .sa_check()
      .then((saPkg) => {
        worker.onmessage = (e) => {
          switch (e.data.from) {
            case 'getDownloadURL_get':
              break;
            default:
              break;
          }

          try {
            //console.log('calling web intel_worker_init callback')
            //console.log(e.data)
            cb(e.data);
          } catch (cbErr) {}

          if (e.data.terminate === true) {
            //console.log('terminating worker', e.data.page_pos)
            worker.terminate();
          }
        };
        worker.postMessage({
          kind,
          pkg,
          auth: {
            baseUrl: process.env.REACT_APP_WOS_API_URL,
            'x-client-code': '38ab1cd216b0643c1.' + new Date().getTime(),
            access_token: saPkg.access_token,
          },
        });
      })
      .catch((err) => {
        console.error('failed sa_check for worker', err);
        throw err;
      });
  };
}

export function notif_worker_init(kind, pkg) {
  return (dispatch, getState) => {
    const _notifWebWorker = getState().notifWebWorker;
    if (_notifWebWorker.worker) {
      //console.log('notif web worker already initated')
      return;
    }

    let worker = new Worker('/workers/Notif.js');
    //console.log(worker)
    return window.sch
      .sa_check()
      .then((saPkg) => {
        worker.onmessage = (e) => {
          if (e.data.terminate === true) {
            //console.log('terminating notif worker', e.data.page_pos)
            worker.terminate();
          }

          switch (e.data.kind) {
            case 'INIT_NOTIF_WORKER':
              dispatch({
                type: e.data.kind,
                worker: worker,
                ...e.data,
              });
              break;
            case 'FAILED_NOTIF_WORKER_RECORDS':
              dispatch({
                type: e.data.kind,
                error: e.data.error,
              });
              break;
            case 'RECEIVED_NOTIF_WORKER_RECORDS':
              //console.log('RECEIVED_NOTIF_WORKER_RECORDS', e.data)
              dispatch({
                type: e.data.kind,
                records: e.data.records,
              });
              if (e.data.records.length > 0) {
                dispatch(
                  open_notif_snack_ac({
                    anchor: 'top',
                    variant: 'notif',
                    duration: 5000,
                    message: `You have ${e.data.records.length} workunits!`,
                  })
                );
              }
              break;
            default:
              break;
          }
          return;
        };

        worker.postMessage({
          kind,
          pkg,
          auth: {
            baseUrl: process.env.REACT_APP_WOS_API_URL,
            'x-client-code': '38ab1cd216b0643c1.' + new Date().getTime(),
            access_token: saPkg.access_token,
          },
        });
      })
      .catch((err) => {
        console.error('failed sa_check for worker', err);
        throw err;
      });
  };
}

export function updateNotifWorkerRecords_ac(records) {
  return (dispatch, getState) => {
    dispatch({
      type: 'UPDATE_NOTIF_WORKER_RECORDS',
      records: records,
    });
  };
}
