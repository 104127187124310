import React, { useState } from 'react'

import { Avatar, CircularProgress } from '@material-ui/core'

import ImageViewer from './ImageViewer'
import useDebounce from '../../hooks/useDebounce'
import { isValidHttpUrl } from '../../util/string'

const SmartAvatar = props => {
  const {
    children,
    size = 50,
    borderRadius = '50%',
    src,
    originalSrc,
    imageClassName,
    ...rest
  } = props
  const [blobURL, setBlobURL] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [isViewingOriginal, setIsViewingOriginal] = useState(false)
  const updateBlob = async () => {
    if (!isValidHttpUrl(src)) {
      return
    }
    setLoading(true)
    setBlobURL(undefined) // So that the loading children element can be shown.
    const imageData = await fetch(src)
    setBlobURL(URL.createObjectURL(await imageData.blob()))
    setLoading(false)
  }

  useDebounce(updateBlob, 200, [src])

  const loadingSize = (size * 2) / 5

  return (
    <>
      {' '}
      <Avatar
        src={blobURL}
        imgProps={{
          onError: () => setBlobURL(undefined),
          className: imageClassName
        }}
        style={{
          width: size,
          height: size,
          borderRadius,
          cursor: originalSrc ? 'pointer' : 'default'
        }}
        onClick={() => originalSrc && setIsViewingOriginal(true)}
        {...rest}>
        {loading ? (
          <CircularProgress
            color="primary"
            style={{ width: loadingSize, height: loadingSize }}
          />
        ) : (
          children
        )}
      </Avatar>
      {originalSrc && (
        <ImageViewer
          visible={isViewingOriginal}
          onClose={() => setIsViewingOriginal(false)}
          image={{
            src: originalSrc,
            alt: rest.alt
          }}
        />
      )}
    </>
  )
}

export default SmartAvatar
