export const actionTypes = {
  FETCH_ACTIVE_PATIENTS_SUCCESS: 'FETCH_ACTIVE_PATIENTS_SUCCESS',
  FETCH_ACTIVE_PATIENTS_ERROR: 'FETCH_ACTIVE_PATIENTS_ERROR',
  FETCH_ACTIVE_PATIENTS_PENDING: 'FETCH_ACTIVE_PATIENTS_PENDING',
};

export function fetchActivePatients_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_ACTIVE_PATIENTS_PENDING });

    return window.sch
      .post(`/api/active_patients`, params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.FETCH_ACTIVE_PATIENTS_SUCCESS,
          data: result.results,
          pagination: result.pagination,
        });
        if (onSuccess) {
          onSuccess(result.workunits);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.FETCH_ACTIVE_PATIENTS_ERROR,
          error,
        });
      });
  };
}
