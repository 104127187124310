import { reportActionTypes } from '../actions/df_workunits_reports.ac'

export function dfWorkunitsReports(
  state = {
    openCounts: null,
    closedCounts: null,
    flowReport: null,
    isFetching: false,
    error: null
  },
  action
) {
  switch (action.type) {
    case reportActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case reportActionTypes.RECEIVED_OPEN_COUNTS:
      return {
        ...state,
        openCounts: action.report,
        error: null
      };
    case reportActionTypes.FAILED_OPEN_COUNTS:
      return {
        ...state,
        openCounts: null,
        error: action.error
      }
    case reportActionTypes.RECEIVED_CLOSED_COUNTS:
      return {
        ...state,
        closedCounts: action.report,
        error: null
      };
    case reportActionTypes.FAILED_CLOSED_COUNTS:
      return {
        ...state,
        closedCounts: null,
        error: action.error
      }
    case reportActionTypes.RECEIVED_FLOW_REPORT:
      return {
        ...state,
        flowReport: action.report,
        error: null
      }
    case reportActionTypes.FAILED_FLOW_REPORT:
      return {
        ...state,
        flowReport: null,
        error: action.error
      }
    default:
      return state;
  }
} 
