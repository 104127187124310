import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import { grey, orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'

const styles = theme => ({
  cardContainer: {
    marginBottom: 4,
    '&>.item-header': {
      padding: 4,
      borderBottom: `1px solid ${orange[500]}40`,
      backgroundColor: `${orange[500]}20`
    },
    '&>.item-body': {
      padding: 4,
      border: `1px solid ${orange[500]}40`
    }
  },
  undefinedLabel: {
    color: grey[500]
  }
})

const EncounterRow = props => {
  const { type, encounter } = props

  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">{type} Encounter</Typography>
      </TableCell>
      <TableCell>
        {encounter && encounter.transactionName ? (
          <>
            <Typography variant="body2">{encounter.transactionName}</Typography>
            <Typography variant="body2" color="textSecondary">
              {encounter.dateScheduled &&
                moment(encounter.dateScheduled).format('MM-DD-YYYY') + ' '}
              {encounter.dateMarkedComplete &&
                '-- ' +
                  moment(encounter.dateMarkedComplete).format('MM-DD-YYYY')}
            </Typography>
          </>
        ) : (
          <Typography variant="body2" color="textSecondary">
            N/A
          </Typography>
        )}
      </TableCell>
    </TableRow>
  )
}

const HealthCallProgramCard = props => {
  const { ptProgram, classes } = props

  const getPatientProgramStatus = (startDate, endDate) => {
    let today = moment()
    let startDateMoment = moment(startDate)
    let endDateMoment = moment(endDate)

    if (today.isBefore(startDateMoment)) {
      return 'Not Started'
    }

    if (
      today.isAfter(startDateMoment) &&
      (endDate === null || today.isBefore(endDateMoment))
    ) {
      return 'Ongoing'
    }

    if (today.isAfter(endDateMoment)) {
      return 'Ended'
    }

    return ''
  }

  return (
    <div className={classes.cardContainer}>
      <Card>
        <CardHeader
          title={
            ptProgram.program && ptProgram.program.name ? (
              <Typography variant="body1" color="primary" gutterBottom>
                {ptProgram.program.name}
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span">
                  {ptProgram && ptProgram.dateStart
                    ? moment(ptProgram.dateStart).format('MM-DD-YYYY')
                    : 'N/A'}
                  &ensp;to&ensp;
                  {ptProgram && ptProgram.dateEnd
                    ? moment(ptProgram.dateEnd).format('MM-DD-YYYY')
                    : 'N/A'}
                </Typography>
              </Typography>
            ) : (
              <Typography variant="body1" color="textSecondary">
                N/A
              </Typography>
            )
          }
        />

        <CardContent>
          <div className="item-body">
            <Table padding="dense">
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">Start of Care Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {ptProgram && ptProgram.dateStart
                        ? moment(ptProgram.dateStart).format('MM-DD-YYYY')
                        : 'N/A'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">Follow Up Program</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">
                      {ptProgram.program && ptProgram.program.name ? (
                        <>
                          {ptProgram.program.name}&ensp;
                          <strong>
                            {getPatientProgramStatus(
                              ptProgram.dateStart,
                              ptProgram.dateEnd
                            )}
                          </strong>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <EncounterRow
                  type="Most Recent"
                  encounter={ptProgram.recentEncounter}
                />
                <EncounterRow
                  type="Current"
                  encounter={ptProgram.currentEncounter}
                />
                <EncounterRow type="Next" encounter={ptProgram.nextEncounter} />
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}

export default withStyles(styles)(HealthCallProgramCard)
