import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Autosuggest from 'react-autosuggest';

import { IconButton, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Clear as ClearIcon, Error as ErrorIcon } from '@material-ui/icons';
import { defaultTheme } from 'react-autosuggest/dist/theme';

import Loader from './Loader';
import LocalPopper from './LocalPopper';

const styles = (theme) => ({
  searchIndicator: {
    height: 20,
    width: 20,
  },
  smallIconButton: {
    padding: 4,
  },
  suggestionsContainerOpen: {
    maxHeight: '250px',
    overflowY: 'auto',
    margin: '2px 0',
  },
  suggestionsList: { paddingLeft: 0, margin: 0 },
  suggestion: {
    listStyleType: 'none',
    padding: 8,
    fontSize: 12,
    marginBottom: 2,
    cursor: 'pointer',
  },
  suggestionHighlighted: {
    backgroundColor: 'rgba(20, 20, 20, 0.20)',
  },
});

/**
 * Advanced search for generic purpose lookups.
 *
 * @param {object} props props
 * @param {React.CSSProperties} props.style style
 * @returns
 */
const GenericSearch = (props) => {
  const {
    delay,
    style,
    inputRef,
    label,
    overrideClasses = {},
    shouldFocus,
    classes,
    isAsync,
    disabled,
    minChars,
    startAdornment,
    autoFocus,
    fullWidth,
    helperText,
    onCrossClick,
    placeholder,
    clearOnSelect,
    renderSuggestion,
    commandConfig,
    commandHandler,
    selectSuggestion,
    scrollableParentId,
    responseInterceptor,
    search: propSearch,
  } = props;

  const popperAnchor = useRef(null);
  const [searchStr, setSearchStr] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [isSearching, setIsSearching] = useState({
    value: false,
    controller: null,
  });
  const [searchResults, setSearchResults] = useState([]);
  const delaySearch = useCallback(
    _.debounce((s) => {
      search(s);
    }, delay),
    [propSearch]
  );

  const escapeCommands = commandConfig && [
    ...commandConfig.action,
    ...commandConfig.input,
  ];

  useEffect(() => {
    if (searchResults.length > 0 && searchStr.length < minChars) {
      return setSearchResults([]);
    }
  }, [searchResults, searchStr, minChars]);

  const search = (searchStrArg = '', customSearchFcn, isCustomAsync = true) => {
    if (!customSearchFcn && searchStrArg.length < minChars) return;

    const searchFcn = customSearchFcn || propSearch;

    if (customSearchFcn ? !isCustomAsync : !isAsync)
      return setSearchResults(searchFcn(searchStrArg));

    if (isSearching.controller) {
      try {
        isSearching.controller.abort('aborting previous search');
      } catch (e) {
        console.log(e);
      }
    }

    const controller = new AbortController();
    const signal = controller.signal;
    setIsSearching({ value: true, controller });
    searchFcn(searchStrArg, signal)
      .then((res) => {
        const results = responseInterceptor
          ? responseInterceptor(searchStrArg, res)
          : res;

        setSearchResults(results);
        setSearchError(false);
      })
      .catch((err) => {
        setSearchError(true);
      })
      .finally(() => {
        setIsSearching({ value: false, controller: null });
      });
  };

  const getSearchStateIndicator = () => {
    let indicator = '';

    if (isSearching.value) indicator = <Loader type="circular" size="15" />;
    else if (searchError)
      indicator = <ErrorIcon fontSize="small" color="error" />;

    return <span className={classes.searchIndicator}>{indicator}</span>;
  };

  const handleChange = (event, { newValue }) => {
    if (commandConfig && commandConfig.action.includes(newValue))
      return commandHandler(newValue);
    setSearchStr(newValue);
  };

  const handleSuggestionSelect = (event, { suggestion, method }) => {
    if (suggestion && (method === 'enter' || method === 'click')) {
      selectSuggestion(suggestion);

      if (clearOnSelect) {
        setSearchStr('');
        handleSuggestionsClear();
      }
    }
  };

  const handleSuggestionsClear = () => {
    setSearchResults([]);
    setSearchError(false);
  };

  const renderInputComponent = (inputProps) => {
    const { inputRef = () => {}, ref, ...other } = inputProps;

    return (
      <TextField
        margin="dense"
        inputRef={(node) => {
          ref(node);
          inputRef(node);
        }}
        {...other}
      />
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <LocalPopper
        anchorEl={popperAnchor.current}
        open={true}
        className={overrideClasses.suggestionsContainer}
        placement="bottom-start"
        localParentId={scrollableParentId || ''}
        style={{
          width: popperAnchor.current
            ? popperAnchor.current.clientWidth
            : '250px',
          zIndex: 1500,
        }}>
        <Paper elevation={4} {...containerProps}>
          {children}
        </Paper>
      </LocalPopper>
    );
  };

  const shouldRenderSuggestions = (currentSearchStr) => {
    return currentSearchStr.trim().length >= minChars;
  };

  const handleFetchRequest = ({ value, reason }) => {
    if (escapeCommands && escapeCommands.includes(value.charAt(0))) return;
    setSearchResults([]);
    delaySearch(value || '');
  };

  const handleKeyDown = (e) => {
    if (
      e.key === 'Enter' &&
      searchStr.length > 1 &&
      commandConfig &&
      commandConfig.input.includes(searchStr.charAt(0))
    ) {
      commandHandler(
        searchStr.charAt(0),
        searchStr.slice(1),
        ({ searchFcn, keyword } = {}) => {
          if (searchFcn) {
            search(keyword, searchFcn);
          } else setSearchStr('');
        }
      );
    }
  };

  return (
    <div
      style={{ display: fullWidth ? 'block' : 'inline-block', ...style }}
      onKeyDown={handleKeyDown}>
      <Autosuggest
        theme={{
          ...defaultTheme,
          ...classes,
          ...overrideClasses,
          suggestionsContainerOpen:
            overrideClasses.suggestionsContainerOpen ||
            classes.suggestionsContainerOpen,
        }}
        suggestions={searchResults}
        onSuggestionsFetchRequested={handleFetchRequest}
        onSuggestionsClearRequested={handleSuggestionsClear}
        onSuggestionSelected={handleSuggestionSelect}
        getSuggestionValue={() => searchStr}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        shouldRenderSuggestions={shouldRenderSuggestions}
        inputProps={{
          autoFocus,
          fullWidth,
          // ref: props.ref,
          value: searchStr,
          placeholder,
          label,
          inputRef: (node) => {
            popperAnchor.current = node;
            if (inputRef) inputRef.current = node;
          },
          helperText: helperText,
          disabled: disabled,
          InputProps: {
            startAdornment,
            id: props.inputId || 'generic-search-input',
            endAdornment: (
              <>
                {getSearchStateIndicator()}
                <IconButton
                  onClick={() => {
                    setSearchStr('');
                    if (onCrossClick) onCrossClick();
                  }}
                  disabled={isSearching.value}
                  className={
                    classes.smallIconButton +
                    (overrideClasses?.crossIconButton
                      ? ` ${overrideClasses?.crossIconButton}`
                      : '')
                  }>
                  <ClearIcon
                    fontSize="small"
                    className={overrideClasses?.clearIcon}
                  />
                </IconButton>
              </>
            ),
          },
          onChange: handleChange,
        }}
        focusInputOnSuggestionClick={shouldFocus}
        hightlightFirstSuggestion={true}
      />
    </div>
  );
};

GenericSearch.propTypes = {
  search: PropTypes.func,
  label: PropTypes.string,
  delay: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  minChars: PropTypes.number,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  clearOnSelect: PropTypes.bool,
  scrollableParentId: PropTypes.string,
  renderSuggestion: PropTypes.func.isRequired,
  selectSuggestion: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

GenericSearch.defaultProps = {
  style: {},
  delay: 1000,
  minChars: 2,
  label: null,
  isAsync: false,
  disabled: false,
  autoFocus: false,
  fullWidth: false,
  clearOnSelect: false,
  scrollableParentId: '',
  placeholder: 'Search...',
};

export default withStyles(styles)(GenericSearch);
