export const AUTO_CLAIM_KEY = 'auto_claim';
export const DELIVERY_TICKET_NM = 'DELIVERY TICKET';
export const READ_ONLY_LABELS = ['wo_id', 'account'];
export const CONTEXT_ENTRY_EXCLUDE_IDS = ['untaggged-stub'];
export const VERTICAL_SUGGESTION_KINDS = ['hdms'];

export const IDN_REGEX =
  /^(([A-Z-'()]{1,30})(?: [A-Z-'()]{1,30}){0,4}[.]{0,1}, ([A-Z-'.()]{1,30})(?: [A-Z-'.()]{1,30}){0,4}(?: ([1-9]|1[0-2])-([1-9]|[12][0-9]|3[01])-([1-9][0-9]{3}))?)$/;

export const IDN_DATE =
  /([1-9]|1[0-2])-([1-9]|[12][0-9]|3[01])-([1-9][0-9]{3})$/;

export const STAT_MODE_NAME = 'STAT';

export const DIVIDER_DETAIL_COLOR_MAP = {
  wo_id: { background: 'brown', color: '#fff' },
};

export const LABEL_COLOR_MAP = {
  account: { background: 'brown', color: '#fff' },
  mode: { background: '#3B99EE', color: '#fff' },
  team: { background: '#CFE8A9', color: '#000' },
};

export const NAME_LABEL_MAP = {
  acct: 'Account',
  oc: 'Ordering Clinician',
  zip: 'Service Zip Code',
  oc_zip: 'Clinician Zip Code',
  divider: 'Divider',
  payer_type: 'Payer Group',
  order_type: 'Order Type',
  prod_group: 'Product Group',
  referral: 'Referral',
  trm_source: 'Transmission Source',
  wo_id: 'Confirmation Workunit',
  account: 'Account',
  team: 'Assigned Team',
};

export const LOOKUP_COLOR_MAP = {
  divider: { background: '#FFFF00', color: '#000' },
  payer_type: { background: '#FFA726', color: '#000' },
  order_type: { background: '#1DE9B6', color: '#000' },
  prod_group: { background: '#80DEEA', color: '#000' },
  referral: { background: '#FFCC80', color: '#000' },
  trm_source: { background: '#FF7F11', color: '#000' },
};

export const KIND_COLOR_MAP = {
  acct: { background: '#fff', color: '#000' },
  zip: { background: '#fff', color: '#000' },
  oc_zip: { background: '#fff', color: '#000' },
  oc: { background: '#fff', color: 'darkmagenta' },
  success: { background: '#3B99EE', color: '#fff' },
  hdms: { background: '#E2F2F1', color: '#000' },
  ...LOOKUP_COLOR_MAP,
};

export const ALL_COLOR_MAP = {
  ...LOOKUP_COLOR_MAP,
  ...KIND_COLOR_MAP,
  ...LABEL_COLOR_MAP,
  ...DIVIDER_DETAIL_COLOR_MAP,
};

export const SINGULAR_LOOKUP_KINDS = [
  'order_type',
  'payer_type',
  'referral',
  'trm_source',
];

export const LOOKUP_LABEL_TYPES = Object.keys(LOOKUP_COLOR_MAP);

export const ALL_LABEL_TYPES = [
  ...LOOKUP_LABEL_TYPES,
  ...Object.keys(LABEL_COLOR_MAP),
];

export const DOC_LABEL_TYPES = Object.keys(DIVIDER_DETAIL_COLOR_MAP);
