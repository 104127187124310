import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import InputBase from '@material-ui/core/InputBase'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import { push } from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import UserSearch from './UserSearch.js'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto'
  },
  superbubble: {
    width: '100%',
    marginLeft: '2vh',
    marginRight: '2vh',
    marginTop: '2vh',
    marginBottom: '2vh',
    paddingTop: '6vh'
  },
  bubble: {
    width: '100%',
    marginLeft: '4vh',
    marginRight: '4vh',
    marginTop: '4vh',
    marginBottom: '4vh'
  },
  topContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    backgroundColor: '#fff'
  },
  weakbubble: {
    width: '100%',
    marginLeft: '6vh',
    marginRight: '6vh'
  },
  tinybubble: {
    marginLeft: '1vh',
    marginBottom: '1vh'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    margin: 'auto'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 60,
      paddingLeft: theme.spacing.unit * 5
    },
    [theme.breakpoints.up('sm')]: {
      width: 120,
      paddingLeft: theme.spacing.unit * 5
    }
  },
  middle: {
    margin: 'auto'
  },
  center: {
    margin: 'auto',
    width: '30%',
    paddingTop: '20vh'
  }
})

class TeamToggle extends React.Component {
  constructor() {
    super()
    this.state = {
      Search: '',
      opts: [],
      cats: {},
      cat: '',
      boolModal: false,
      groupName: ''
    }
    this.route = this.route.bind(this)
    this.getOpts = this.getOpts.bind(this)
    this.selectCat = this.selectCat.bind(this)
    this.handleModalOpen = this.handleModalOpen.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.nameGroup = this.nameGroup.bind(this)
    this.createGroup = this.createGroup.bind(this)
    this.createSupervisor = this.createSupervisor.bind(this)
  }

  handleModalOpen() {
    this.setState({
      boolModal: true
    })
  }

  handleModalClose() {
    this.setState({
      boolModal: false
    })
  }

  createGroup(e) {
    if (e.which !== 13) return
    if (this.state.groupName.length <= 3) return
    let ncats = JSON.parse(JSON.stringify(this.state.cats))
    ncats[this.state.cat].push(this.state.groupName.toLowerCase())
    this.setState({
      cats: ncats,
      boolModal: false
    })
  }

  createSupervisor(e) {
    let ncats = JSON.parse(JSON.stringify(this.state.cats))
    ncats[this.state.cat].push(e.u_id)
    this.setState({
      cats: ncats,
      boolModal: false
    })
  }

  route(opt, cat) {
    let x = '/myteams/' + cat + '/' + opt
    this.props.redirectToDfa(x)
  }

  selectCat(category) {
    this.props.redirectToDfa('/myteams/' + category)
    this.setState({
      cat: category
    })
  }

  async getOpts() {
    try {
      let res = await window.sch.post('/api/users/categories', {})
      let pcat =
        Object.keys(res.data).indexOf(this.props.cat) === -1
          ? 'teamq'
          : this.props.cat
      this.setState({ cats: res.data, cat: pcat })
    } catch (e) {
      console.error(e)
    }
  }

  nameGroup(e) {
    if (e.target.value.includes('/')) {
      e.target.value.replace('/', '')
    }
    this.setState({
      groupName: e.target.value.toString()
    })
  }

  async componentDidMount() {
    try {
      await this.getOpts()
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    let { classes } = this.props

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div>
            {Object.keys(this.state.cats).map(cat => {
              return (
                <Button
                  variant="contained"
                  key={cat}
                  onClick={e => {
                    this.selectCat(cat)
                  }}
                  color={cat === this.state.cat ? 'primary' : 'default'}
                  className={classes.tinybubble}>
                  {cat}
                </Button>
              )
            })}
            <Divider />
            {this.state.cat === '' ? (
              <div className={classes.topContent} />
            ) : (
              <div className={classes.topContent}>
                <h2>{this.state.cat}</h2>
                <Grid container spacing={0}>
                  {this.state.cats[this.state.cat].map(opt => {
                    return (
                      <Grid item key={opt} xs={2}>
                        <Button
                          variant="outlined"
                          className={classes.tinybubble}
                          onClick={e => {
                            opt === 'add grouping'
                              ? this.handleModalOpen()
                              : this.route(opt, this.state.cat)
                          }}>
                          {opt === 'add grouping' ? (
                            <div>
                              <AddIcon />
                            </div>
                          ) : (
                            opt
                          )}
                        </Button>
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
            )}
          </div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.boolModal}
            className={classes.center}
            onClose={this.handleModalClose}>
            <Paper className={classes.paper}>
              <p className={classes.middle}>Add a new Group</p>
              {this.state.cat === 'adp_supervisor' ? (
                <UserSearch clickAction={this.createSupervisor} />
              ) : (
                <InputBase
                  onChange={this.nameGroup}
                  onKeyUp={this.createGroup}
                  value={this.state.groupName}
                  placeholder="Group Name"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
              )}
            </Paper>
          </Modal>
        </MuiThemeProvider>
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({})

const mapDispatchToProps = (dispatch, propsOfTodoForm) =>
  bindActionCreators(
    {
      redirectToDfa: path => {
        return push(path)
      }
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TeamToggle))
