export const ADMIN_IDX_OC = '/admin/idx/oc';
export const ADMIN_IDX_OC_CREATE = '/admin/idx/oc/create';
export const ADMIN_IDX_OC_VIEW = '/admin/idx/oc/:id';
export const ADMIN_IDX_OC_UPDATE = '/admin/idx/oc/:id/update';

export const ADMIN_IDX_REPS = '/admin/idx/reps';
export const ADMIN_IDX_REPS_CREATE = '/admin/idx/reps/create';
export const ADMIN_IDX_REPS_VIEW = '/admin/idx/reps/:id';
export const ADMIN_IDX_REPS_UPDATE = '/admin/idx/reps/:id/update';

export const SALES_REP_DOCTOR = '/sales/doctors';
export const SALES_REP_DOCTOR_CREATE = '/sales/doctors/create';
export const SALES_REP_DOCTOR_VIEW = '/sales/doctors/:id';
export const SALES_REP_DOCTOR_UPDATE = '/sales/doctors/:id/update';
