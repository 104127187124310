import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import PropTypes from 'prop-types'
import React from 'react'
import StyledTooltip from '../../Shared/StyledTooltip'
import MfgNode from './MfgNode'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  node: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    borderRadius: '3px',
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      background: '#97a9d273'
    }
  },
  nodeTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: 4
  },
  icon: {
    borderRadius: '3px',
    display: 'flex',
    float: 'right',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#97a9d273'
    }
  },
  iconImg: {
    fontSize: '1em',
    verticalAlign: 'middle'
  }
})

class CategoryNode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.onToggle = this.onToggle.bind(this)
    this.onCatClick = this.onCatClick.bind(this)
    this.onMfgClick = this.onMfgClick.bind(this)
  }

  onToggle() {
    this.setState({ expanded: !this.state.expanded })
    this.props.onExpand(this.props.category.cat_id)
  }

  onCatClick() {
    this.setState({ expanded: true })
    this.props.onExpand(this.props.category.cat_id)
    this.props.onSelect(this.props.categorycat_id, null)
  }

  onMfgClick(mfg_nm, is_macro) {
    this.props.onSelect(this.props.category.cat_id, mfg_nm, is_macro)
  }

  render() {
    const { category: cat, classes } = this.props
    const { mfgs } = cat

    return (
      <Grid item className={classes.root} key={cat.cat_id}>
        <Paper
          style={{ marginBottom: 3 }}
          elevation={2}
          onClick={this.onToggle}>
          <div className={classes.nodeTitle}>
            <StyledTooltip title={`${cat.cat_nm} (${cat.n_items})`}>
              <div
                className={classes.node}
                onClick={() => this.props.onSelect(cat.cat_id, null)}>
                {`${cat.cat_nm || 'NO CATEGORY'} (${cat.n_items})`}
              </div>
            </StyledTooltip>
            <div className={classes.icon}>
              <span onClick={this.onToggle}>
                {this.state.expanded ? (
                  <KeyboardArrowUpIcon fontSize="small" color="secondary" />
                ) : (
                    <KeyboardArrowDownIcon fontSize="small" color="primary" />
                  )}
              </span>
            </div>
          </div>
        </Paper>
        <Grid container direction="column">
          <Grid item style={{ paddingLeft: 16 }}>
            {this.state.expanded &&
              mfgs &&
              mfgs.map(mfg => (
                <MfgNode key={mfg.mfg_nm} mfg={mfg} onClick={this.onMfgClick} />
              ))}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

CategoryNode.propTypes = {
  category: PropTypes.object.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}
export default withStyles(styles)(CategoryNode)
