import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SuperReactDatePicker from '../common/SuperReactDatePicker';

import {
  fetchOxygenCounts_ac,
  saveOxygenCountsFilters_ac,
} from '../../actions/oxygen_counts.ac';
import SearchSelect from '../Shared/SearchSelect';

const styles = (theme) => ({
  datePickerPopper: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  '& .react-datepicker__week': {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const GROUP_BY_OPTIONS = [
  { value: 'dc', label: 'Distribution Center' },
  { value: 'driver', label: 'Driver' },
];
const PROD_GROUPING_OPTIONS = [
  { value: 'pap', label: 'PAP' },
  { value: 'poc', label: 'POC' },
  { value: 'oxygen', label: 'Oxygen' },
  { value: 'tanks', label: 'Tanks' },
  { value: 'vent', label: 'Vents' },
  { value: 'vest', label: 'Vests' },
];

const FiltersContainer = (props) => {
  const { isFetchingCounts, fetchOxygenCounts_ac, saveOxygenCountsFilters_ac } =
    props;

  const [dcs, setDcs] = useState({
    dcOptions: [],
    driverOptions: [],
    isFetching: false,
    error: false,
  });

  const [filterData, setFilterData] = useState({
    prod_grouping: 'oxygen',
    groupBy: 'dc',
    dc_id: null,
    driver_id: null,
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    setDcs((dcs) => ({
      ...dcs,
      isFetching: true,
    }));
    window.sch
      .post('/api/dt/dcs/list', {})
      .then((res) => {
        setDcs({
          error: false,
          isFetching: false,
          dcOptions: (res.pkg && res.pkg.dcs) || [],
          driverOptions: (res.pkg && res.pkg.drivers) || [],
        });
      })
      .catch((error) => {
        setDcs({
          error: true,
          isFetching: false,
          dcOptions: [],
        });
      });
  }, []);

  const handleDateFieldChange = (name, date) => {
    setFilterData((filterData) => ({
      ...filterData,
      [name]: date,
    }));

    if (name === 'start' && moment(date).isAfter(moment(filterData.end)))
      setFilterData((filterData) => ({
        ...filterData,
        end: date,
      }));
  };

  const handleFormFieldChange = (event) => {
    const { name, value } = event.target;

    setFilterData((filterData) => ({
      ...filterData,
      [name]: value,
    }));
  };

  const handleFilterApply = () => {
    saveOxygenCountsFilters_ac(filterData);
    fetchOxygenCounts_ac(filterData);
  };

  const dcSelectHelperText = dcs.isFetching
    ? 'Loading...'
    : dcs.error
    ? 'Failed to load'
    : undefined;

  return (
    <Grid container spacing={8} alignItems="baseline">
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          <strong>Filters:</strong>
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          select
          margin="dense"
          id="groupBy"
          name="groupBy"
          label="Group By"
          value={filterData.groupBy || ''}
          onChange={handleFormFieldChange}
          disabled={isFetchingCounts}
          style={{ minWidth: 200 }}>
          {GROUP_BY_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          select
          margin="dense"
          id="prod_grouping"
          name="prod_grouping"
          label="Product Grouping"
          value={filterData.prod_grouping || ''}
          onChange={handleFormFieldChange}
          disabled={isFetchingCounts}
          style={{ minWidth: 200 }}>
          {PROD_GROUPING_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item>
        {filterData.groupBy === 'driver' ? (
          <SearchSelect
            name="driver_id"
            margin="dense"
            options={dcs.driverOptions.map((driver) => ({
              code: `${driver.driver_id}`,
              lbl: driver.driver_nm,
            }))}
            value={filterData.driver_id || ''}
            placeholder="Search Driver"
            label="Driver"
            onSelect={handleFormFieldChange}
            disabled={dcs.isFetching || isFetchingCounts}
            helperText={dcSelectHelperText}
            onClear={() =>
              handleFormFieldChange({
                target: {
                  name: 'driver_id',
                  value: null,
                },
              })
            }
          />
        ) : (
          <SearchSelect
            name="dc_id"
            margin="dense"
            options={dcs.dcOptions.map((dc) => ({
              code: `${dc.dc_id}`,
              lbl: dc.dc_nm,
            }))}
            value={filterData.dc_id || ''}
            placeholder="Search Distribution Center"
            label="Distribution Center"
            onSelect={handleFormFieldChange}
            disabled={dcs.isFetching || isFetchingCounts}
            helperText={dcSelectHelperText}
            onClear={() =>
              handleFormFieldChange({
                target: {
                  name: 'dc_id',
                  value: null,
                },
              })
            }
          />
        )}
      </Grid>
      <Grid item id="dateRangeContainer">
        <SuperReactDatePicker
          selected={filterData.start}
          dateFormat="MM-dd-yyyy"
          onChange={(date) => handleDateFieldChange('start', date)}
          selectsStart
          startDate={filterData.start}
          endDate={filterData.end}
          customInput={<TextField margin="dense" label="Scheduled From" />}
        />
      </Grid>
      <Grid item>
        <SuperReactDatePicker
          selected={filterData.end}
          dateFormat="MM-dd-yyyy"
          onChange={(date) => handleDateFieldChange('end', date)}
          selectsEnd
          startDate={filterData.start}
          endDate={filterData.end}
          minDate={filterData.start}
          customInput={<TextField margin="dense" label="Scheduled To" />}
        />
      </Grid>
      <Grid item>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={handleFilterApply}
          disabled={isFetchingCounts}>
          Apply
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (stateFromStore) => ({
  isFetchingCounts: stateFromStore.oxygenCountsState.isFetching,
  filterData: stateFromStore.oxygenCountsState.filters,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchOxygenCounts_ac,
      saveOxygenCountsFilters_ac,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FiltersContainer));
