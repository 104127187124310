import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import GetAppIcon from '@material-ui/icons/GetApp'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import WOSTable from './WOSTable'
import Loader from '../Shared/Loader'
import CustomSelect from './CustomSelect'
import { fetchSalesDashRecords_ac } from '../../actions/salesdash.ac'
import { open_snack_ac } from '../../actions/snack.ac'

const styles = () => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  tableContainer: {
    height: 'calc(100% - 160px)'
  }
});

class BrownList extends React.Component {
  constructor() {
    super();

    this.state = {
      rowsPerPage: 10,
      isDownloading: false
    };
  }

  componentDidMount() {
    return this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.region !== this.props.region
      || prevProps.salesPerson !== this.props.salesPerson) {
      return this.fetch();
    }
  }

  fetch = () => {
    let endpoint = [
      '/sales/brown_list',
      this.props.region,
      this.props.salesPerson,
      '0'
    ].join('/') + '/';

    return this.props.fetchSalesDashRecords_ac(
      'my_brown_list',
      { endpoint }
    );
  }

  handleRowsPerPageChange = event => {
    const { value } = event.target;

    this.setState({
      rowsPerPage: value
    });
  }

  downloadCSV = () => {
    const { region, salesPerson } = this.props;

    const endpoint = [
      '/sales/brown_list',
      region,
      salesPerson,
      '1'
    ].join('/') + '/';

    this.setState({
      isDownloading: true
    });

    window.sch.post('/api/salesdash_proxy', { endpoint })
      .then(response => {
        const { salesdash_res } = response;

        const url = URL.createObjectURL(
          new Blob([salesdash_res], { type: 'text/plain;charset=utf-8' })
        );
        const downloadLink = document.createElement('a');
        const fileName = [
          region,
          salesPerson,
          'Brown_List'
        ].join('__') + '.csv';

        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      })
      .catch(err => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Error downloading Brown List CSV file'
        });
      })
      .finally(() => {
        this.setState({
          isDownloading: false
        });
      });
  }

  render() {
    const { classes, salesdashRecords } = this.props;
    const { isFetchingRecords, recordError, myBrownList } = salesdashRecords;

    if (isFetchingRecords) {
      return (
        <Loader classes={{ message: classes.message }} />
      );
    }

    if (recordError) {
      return (
        <Typography
          color="error"
          className={classes.message}
        >
          Error fetchnig records!
        </Typography>
      );
    }

    if (!myBrownList) {
      return (
        <div className={classes.message}>
          ...
        </div>
      );
    }

    const visibleColumns = myBrownList.cols.filter(col => col.visible);
    const visibleRows = myBrownList.wos.filter(row => row.visible);

    return (
      <>
        <Grid
          container
          direction="row"
          justify="space-between"
        >
          <Grid
            container
            item
            xs={6}
            sm={4}
            md={2}
            direction="row"
            alignItems="center"
          >
            <Typography
              variant="body1"
              component="div"
              style={{ marginRight: 4 }}
            >
              Show
          </Typography>
            <CustomSelect
              name="rowsPerPage"
              label=""
              value={this.state.rowsPerPage}
              options={[
                { label: '10', value: 10 },
                { label: '20', value: 20 },
                { label: '50', value: 50 },
                { label: '100', value: 100 },
                { label: 'ALL', value: visibleRows.length }
              ]}
              fullWidth={false}
              onChange={this.handleRowsPerPageChange}
            />
            <Typography
              variant="body1"
              component="div"
              style={{ marginLeft: 4 }}
            >
              entries
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={6}
            sm={4}
            md={2}
            direction="row"
            alignItems="center"
            justify="flex-end"
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={this.downloadCSV}
              disabled={this.state.isDownloading}
            >
              <GetAppIcon />
              <span>
                {!this.state.isDownloading
                  ? 'CSV'
                  : 'Preparing download...'}
              </span>
            </Button>
          </Grid>
        </Grid>
        <div className={classes.tableContainer}>
          <WOSTable
            columns={visibleColumns}
            rows={visibleRows}
            rowsPerPage={this.state.rowsPerPage}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = stateFromStore => ({
  salesdashRecords: stateFromStore.salesdashRecords
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      open_snack_ac,
      fetchSalesDashRecords_ac
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(BrownList))
);
