import { FormControlLabel, Grid, TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac } from '../../../actions/me.ac.js'
import BaseLandingTemplate from '../../Reports/BaseLandingTemplate.Parent'
import {
  addToCart_ac,
  changeQuantity_ac,
  draftEmail_ac,
  fetchCartItems_ac,
  removeCart_ac,
  removeItemFromCart_ac,
  updateCart_ac
} from './../../../actions/shopping_cart.ac.js'
import util from './../../../util'
import Loader from './../../Shared/Loader'
import TableView from './CartTableView'

const NON_SPECIFIC_PATIENT = 'NON-SPECIFIC-PATIENT'
const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  description: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  table: {
    /* minWidth: 700 */
  },
  removeBtn: {
    display: 'inline-block',
    paddingLeft: 5
  },
  denseCell: {
    padding: 4
  },
  vendorName: {
    color: 'blue',
    fontWeight: 'bold'
  },
  fullHeight: {
    height: '100%'
  }
})

const STATE_COST_RULE = {
  new: 1.7,
  used: 1.3
}

class ShoppingCartComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props)
    this.state = {
      draftEmail: ''
    }
    this.fetch = this.fetch.bind(this)
  }

  componentDidMount() {
    const { lookups, me } = this.props
    if (!me.user) {
      return
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }
    this.fetch()
  }

  componentDidUpdate() {
    const { shoppingCartInView, lookups, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return this.props.lookups_ac()
    }
    if (
      shoppingCartInView.isFetching === false &&
      !shoppingCartInView.error &&
      shoppingCartInView.items === null
    ) {
      return this.fetch()
    }
  }

  /**
   *  remove item from shopping cart
   * @param {number} cart_item_id
   */
  removeItem(cart_item_id) {
    this.resetDraftEmail()
    if (this.props.shoppingCartInView.cart.items.length === 1) {
      return this.props.removeCart_ac()
    }
    this.props.removeItemFromCart_ac(cart_item_id)
  }

  removeCart() {
    this.resetDraftEmail()
    this.props.removeCart_ac()
  }

  /**
   * @param {number} cart_item_id
   * @param {number} quantity
   */
  onQuantityChange(cart_item_id, quantity) {
    this.resetDraftEmail()
    this.props.changeQuantity_ac(cart_item_id, parseInt(quantity, 10))
  }

  /**
   * @param {Object} item
   */
  getItemTotal(item) {
    return item.unitCost * item.uomcount * STATE_COST_RULE[item.state]
  }

  fetch() {
    let fetchCondition = {
      idn: this.props.match.params.selectedIdn || NON_SPECIFIC_PATIENT
    }

    return this.props.fetchCartItems_ac(fetchCondition)
  }

  /**
   * update cart state
   * @param {string} itemId
   * @param {boolean} isUsed
   */
  changeState(itemId, isUsed) {
    this.props.updateCart_ac(itemId, isUsed)
  }

  resetDraftEmail() {
    this.setState({
      draftEmail: ''
    })
  }

  draftEmail = () => {
    const { cart, total } = this.props.shoppingCartInView
    let table = TableView(STATE_COST_RULE, cart.items, total)

    this.setState(
      () => ({
        draftEmail: table
      }),
      () => {
        this.sendDraftEmail()
      }
    )
  }

  sendDraftEmail = () => {
    let content = document.getElementById('draftEmailContainer').innerHTML
    let params = {
      to: this.props.me.user.mail,
      subject: 'SuperCare Cash QUOTE',
      body: content
    }
    this.props.draftEmail_ac(params)
  }

  render() {
    const { shoppingCartInView, classes } = this.props

    if (shoppingCartInView.isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading records" />
        </main>
      )
    }

    if (shoppingCartInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.description}>....error fetching items</h4>
        </div>
      )
    }
    if (
      shoppingCartInView.cart &&
      shoppingCartInView.cart.items &&
      !shoppingCartInView.cart.items.length
    ) {
      return (
        <div className={classes.root}>
          <h4 className={classes.description}>....No Items in the Cart</h4>
        </div>
      )
    }

    return (
      <Grid container spacing={0} className={classes.fullHeight}>
        <Grid item xs={12} className={classes.fullHeight}>
          <div className={classes.scrollArea}>
            <div className={classes.root}>
              <Table
                padding="dense"
                className={classes.table}
                aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell width="5%" className={classes.denseCell}>
                      {shoppingCartInView.cart &&
                        shoppingCartInView.cart.items &&
                        shoppingCartInView.cart.items.length > 1 && (
                          <Tooltip title="remove all items">
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => this.removeCart()}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      #
                    </TableCell>
                    <TableCell width="20%" className={classes.denseCell}>
                      Item
                    </TableCell>
                    <TableCell
                      width="5%"
                      className={classes.denseCell}
                      align="right">
                      Qty
                    </TableCell>
                    <TableCell
                      width="10%"
                      className={classes.denseCell}
                      align="right">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(shoppingCartInView.cart.items || []).map((cartItem, i) => (
                    <TableRow key={i}>
                      <TableCell width="5%" className={classes.denseCell}>
                        <Tooltip title="remove item">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() =>
                              this.removeItem(cartItem.prod_code_v_id)
                            }>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>

                        <div className={classes.removeBtn}>#{i + 1}</div>
                      </TableCell>
                      <TableCell width="20%" className={classes.denseCell}>
                        <div>
                          {cartItem.prod_code_v_id &&
                            cartItem.prod_code_v_id.split('-')[0].trim()}
                        </div>
                        <div>{cartItem.part_desc}</div>
                        <div className={classes.vendorName}>{cartItem.nm}</div>
                        <div>
                          $
                          {(
                            cartItem.unitCost * STATE_COST_RULE[cartItem.state]
                          ).toFixed(2)}{' '}
                          per {cartItem.uom}
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={classes.denseCell}
                        width="5%">
                        <TextField
                          inputProps={{
                            style: {
                              width: 40,
                              fontSize: 12,
                              padding: 4,
                              textAlign: 'right'
                            }
                          }}
                          id="outlined-basic"
                          variant="outlined"
                          type="number"
                          required
                          onChange={e =>
                            this.onQuantityChange(
                              cartItem.prod_code_v_id,
                              e.target.value
                            )
                          }
                          value={cartItem.uomcount}
                        />
                        <FormControlLabel
                          style={{ fontSize: 10 }}
                          control={
                            <Switch
                              size="small"
                              checked={cartItem.isUsed}
                              onChange={() =>
                                this.changeState(
                                  cartItem.prod_code_v_id,
                                  !cartItem.isUsed
                                )
                              }
                              value={cartItem.isUsed}
                            />
                          }
                          label={cartItem.isUsed ? 'Used' : 'New'}
                          labelPlacement="start"
                        />
                      </TableCell>
                      <TableCell
                        width="10%"
                        align="right"
                        className={classes.denseCell}>
                        {util.formatCurrency(this.getItemTotal(cartItem))}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell className={classes.denseCell} width="5%" />
                    <TableCell
                      className={classes.denseCell}
                      width="15%"
                      colSpan={2}>
                      Total
                    </TableCell>
                    <TableCell
                      className={classes.denseCell}
                      width="10%"
                      align="right">
                      {util.formatCurrency(shoppingCartInView.total)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div>
                {shoppingCartInView.cart &&
                  shoppingCartInView.cart.items &&
                  shoppingCartInView.cart.items.length && (
                    <Button
                      style={{ marginTop: 10 }}
                      variant="contained"
                      size="small"
                      onClick={this.draftEmail}>
                      Draft Email
                    </Button>
                  )}

                <Grid item>
                  {this.state.draftEmail && (
                    <div id="draftEmailContainer">{this.state.draftEmail}</div>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

ShoppingCartComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  shoppingCartInView: stateFromStore.shoppingCartInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      addToCart_ac,
      removeCart_ac,
      updateCart_ac,
      changeQuantity_ac,
      fetchCartItems_ac,
      draftEmail_ac,
      removeItemFromCart_ac
    },
    dispatch
  )

export const ShoppingCart = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ShoppingCartComponent))
)
