/* eslint no-unused-vars: "off" */
import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import FetchingStatementWrap from '../FetchingStatementWrap'
import BaseLandingTemplate from './BaseLandingTemplate.Parent'
import { PaginationWithNums } from '../PaginationNew'
//import ReactDataGrid from 'react-data-grid'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  title: {
    flexGrow: 1
  },
  formControl: {
    paddingBottom: 32
  },
  tableRow: {
    fontSize: 11
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  logEntry: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 11
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2'
  },
  noteUser: {
    marginLeft: 4,
    color: '#244e91',
    fontSize: '.98em'
  },
  fullHeight: {
    height: '100%'
  },
  pageSelector: {
    paddingTop: 10,
    paddingBottom: 20
  }
})

const HEXES = [
  '#E91E63',
  '#673AB7',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#CDDC39',
  '#FFC107',
  '#FF5722',
  '#9E9E9E'
]

class KilledItems extends BaseLandingTemplate {
  constructor(props) {
    super(props)
    this.state = {
      records: null,
      pagination: {},
      selectedPageNum: 0,
      posInEdit: null,
      itemOnEdit: {},
      page: 0,
      filterSequence: ['filter_name'],
      filters: {
        replac_this_with_filter_name: {
          lbl: 'Replace with Filter Label',
          kind: 'checkbox',
          options: [
            { code: 'opt1', checked: true },
            { code: 'opt2', checked: false }
          ]
        }
      }
    }
    this.edit = this.edit.bind(this)
    this.clsoeEdit = this.closeEdit.bind(this)
    this.updateItem = this.updateItem.bind(this)
  }

  fetch(pageNum = 0) {
    this.setState({ isFetching: true, records: [] })
    let params = {
      page: pageNum
    }
    return window.sch
      .post('/api/killed_items/report', params)
      .then(results => {
        this.setState({
          isFetching: false,
          records: results.records,
          pagination: results.pagination || {}
        })
        return
      })
      .catch(err => {
        this.setState({
          isFetching: false,
          records: [],
          pagination: {}
        })
      })
  }

  updateItem() {
    this.setState({ isUpdating: true })
    let params = {
      item_details: { ...this.state.itemOnEdit }
    }
    return window.sch
      .post('/api/killed_items/upsert', params)
      .then(result => {
        let records = [...this.state.records]
        records[this.state.posInEdit] = result.updatedDoc
        this.setState({
          isUpdating: false,
          records,
          itemOnEdit: {
            ...this.state.itemOnEdit,
            log: result.updatedDoc.log
          }
        })
        return
      })
      .catch(err => {
        this.setState({ isUpdating: false })
      })
  }

  onItemChange = k => e => {
    this.setState({
      itemOnEdit: {
        ...this.state.itemOnEdit,
        [k]: e.target.value
      }
    })
  }

  edit = posInEdit => () => {
    this.setState({
      isEditing: true,
      posInEdit,
      itemOnEdit: {
        ...this.state.records[posInEdit],
        instruction: ''
      }
    })
  }

  closeEdit() {
    this.setState({ isEditing: false, posInEdit: null, itemOnEdit: {} })
  }

  handlePageChange = newPage => {
    let targetPage = parseInt(newPage, 10)

    this.setState({
      selectedPageNum: targetPage - 1
    })

    this.fetch(targetPage - 1)
  }

  getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${this.state.selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }


  render() {
    const { classes, me } = this.props
    const { records, pagination: pg } = this.state
    const title = 'Items on Kill Status'

    if (this.state.isFetching) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    if (null === records) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    let allowUpdate =
      _.get(me.user, 'cfi_routes', []).indexOf('McKesson & Independence') !== -1


    let pageStatement = ''
    if (pg) {
      pageStatement = this.getPageStatement(pg)
    }

    let pageSelector = ''

    if (pg.totalPagesAvailable > 1) {
      pageSelector = (
        <div className={classes.pageSelector}>
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.handlePageChange}
          />
        </div>
      )
    }
    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={8} className={classes.fullHeight}>
          <main className={classes.content}>
            <label className={classes.label}>
              Items on Kill Status - {records.length} Items - Click on
              instruction to view history
            </label>
            {pageSelector}
            <div className={classes.scrollArea} style={{ padding: 4 }}>
              <Grid container direction="column" spacing={8}>
                <Grid item className={classes.tableRow}>
                  <Grid container direction="row" spacing={8}>
                    <Grid item xs={4}>
                      Product Code/Description
                    </Grid>
                    <Grid item xs={2}>
                      Vendor/Part Number
                    </Grid>
                    <Grid item xs={1}>
                      Status
                    </Grid>
                    <Grid item xs={1}>
                      Last Updated
                    </Grid>
                    <Grid item xs={4}>
                      Instruction
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.tableRow}>
                  {records.map((r, ri) => (
                    <Grid key={ri} container direction="row" spacing={8}>
                      <Grid item xs={4}>
                        {r.product_code}
                        <br />
                        {r.product_desc}
                      </Grid>
                      <Grid item xs={2}>
                        {r.vendorname || 'No Vendor'}
                        <br />
                        {r.vendorpartnum || ''}
                      </Grid>
                      <Grid item xs={1}>
                        {r.state}
                      </Grid>
                      <Grid item xs={1}>
                        {r.u_nm}
                        <br />
                        {moment(r.last_updated).format('MM-DD-YYYY hh:mma')}
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        onClick={this.edit(ri)}
                        style={{ cursor: 'pointer' }}>
                        {r.instruction}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </div>
          </main>
        </Grid>

        <Grid item xs={12} sm={4} className={classes.fullHeight}>
          {this.state.posInEdit !== null && (
            <main className={classes.content}>
              <div>
                <label className={classes.label}>
                  Update instructions for this Item:
                  {this.state.itemOnEdit.product_code}
                </label>
                <FormControl
                  component="fieldset"
                  style={{ marginLeft: 0 }}
                  className={classes.formControl}
                  fullWidth>
                  <TextField
                    fullWidth
                    style={{ fontSize: 11 }}
                    disabled={!allowUpdate}
                    onChange={this.onItemChange('instruction')}
                    placeholder={
                      'Enter new instructions for ' +
                      this.state.itemOnEdit.product_code +
                      ' here'
                    }
                    value={this.state.itemOnEdit.instruction}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormLabel style={{ fontSize: 10 }} component="label">
                    Select New Status
                  </FormLabel>
                  <Select
                    disabled={!allowUpdate}
                    className={classes.selectElement}
                    input={<Input name="member" />}
                    onChange={this.onItemChange('state')}
                    value={this.state.itemOnEdit.state}>
                    {['EFFECTIVE', 'AVAILABLE'].map(m => (
                      <MenuItem key={m} value={m}>
                        {m}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ height: 16 }} />
                <span style={{ fontSize: 12, fontWeight: '600' }}>
                  Note: This will send a email to subscribers of the{' '}
                  <u>Killed Order Status</u> subscription group.
                </span>
                <div style={{ height: 16 }} />
                <Button
                  variant="contained"
                  disabled={!allowUpdate}
                  color="primary"
                  onClick={this.updateItem}
                  className={classes.button}>
                  Save Changes
                </Button>
              </div>
              <div className={classes.scrollArea}>
                <div style={{ height: 16 }} />
                <label className={classes.label}>
                  Item Kill Status History:
                </label>
                {this.state.itemOnEdit.log.map((e, ei) => (
                  <div key={ei} className={classes.logEntry}>
                    <div className={classes.noteUser}>
                      {e.u_nm} on {moment(e.ts).format('MM-DD-YYYY hh:mma')}:
                    </div>
                    <div className={classes.noteTxt}>
                      Status set to: {e.state}
                      <br />
                      Instruction: {e.instruction}
                    </div>
                  </div>
                ))}
              </div>
            </main>
          )}
        </Grid>
      </Grid>
    )
  }
}

KilledItems.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(KilledItems))
)
