import React, { useEffect } from 'react';

import Loader from '../../Shared/Loader';
import { isValidString } from '../../../util/string';

import { Button, TextField, MenuItem, Typography } from '@material-ui/core';

const validate = (state = {}) => {
  let isValid = true;
  let message = '';

  if (!isValidString(state.name, 2)) {
    isValid = false;
    message = "Queue name isn't valid!";
  } else if (!isValidString(state.ARN, 12)) {
    isValid = false;
    message = "ARN isn't valid!";
  }

  return [isValid, message];
};

const EditQueue = props => {
  const {
    classes,
    queue = {},
    groups,
    onSave,
    onDelete,
    onCancel,
    saving,
    deleting,
    ...rest
  } = props;

  const [formState, setFormState] = React.useState({});

  useEffect(() => {
    setFormState({ ...queue });
  }, [queue]);

  const handleChange = (key, value) => {
    setFormState(state => ({ ...state, [key]: value }));
  };

  const handleSubmit = () => {
    const [isValid, message] = validate(formState);

    if (!isValid) {
      return rest.openSnack({
        variant: 'error',
        message
      });
    }

    onSave(formState);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    rest.openConfirmationDialog({
      title: 'Are you sure?',
      content: `The deletion is permanent, proceed?`,
      actions: [
        {
          label: 'Yes, Delete',
          action: () => {
            onDelete(queue);
            rest.closeConfirmationDialog();
            return true;
          }
        }
      ]
    });
  };

  const handleFormChange = event => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.formHeader}>
        <span className={classes.formTitle} style={{ fontSize: '16px' }}>
          {'Queue details'}
        </span>
      </div>
      <div className={classes.formContent}>
        <TextField
          required
          id="queue_name"
          name="name"
          label="Name"
          disabled={saving}
          style={{ width: '100%' }}
          variant="standard"
          value={formState.name || ''}
          helperText={'At least 2 characters required'}
          onChange={handleFormChange}
        />

        <TextField
          multiline
          required
          rows={2}
          id="queue_ARN"
          name="ARN"
          label="ARN"
          disabled={saving}
          style={{ width: '100%', marginTop: '8px' }}
          variant="standard"
          value={formState.ARN || ''}
          helperText="Must be a valid AWS ARN."
          onChange={handleFormChange}
        />

        <TextField
          select
          required
          variant="standard"
          margin="dense"
          name="group"
          label="Queue Group"
          disabled={saving}
          value={formState?.group?._id || ''}
          onChange={e => {
            const { name, value } = e.target;
            handleChange(name, { _id: value });
          }}
          style={{ width: '100%', marginTop: '8px' }}
        >
          <MenuItem value="">
            <Typography variant="inherit" color="textSecondary">
              Select
            </Typography>
          </MenuItem>
          {groups.map((kind, idx) => (
            <MenuItem key={idx} value={kind._id}>
              {kind.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.formButtons} style={{ marginTop: '30px' }}>
        <Button
          disabled={saving}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleSubmit}
          style={{ float: 'left' }}
        >
          {saving ? <Loader /> : queue._id ? 'Update' : 'Create'}
        </Button>
        <Button
          disabled={saving || deleting}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleCancel}
          style={{ float: 'left', marginLeft: '8px' }}
        >
          Cancel
        </Button>
        {queue._id && (
          <Button
            disabled={deleting}
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleDelete}
            style={{ float: 'right' }}
          >
            {deleting ? <Loader /> : 'Delete'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditQueue;
