import { push } from 'connected-react-router';
import _ from 'lodash';
import io from 'socket.io-client';
import {
  addAdjustmentHDMSNote_ac,
  getAdjustmentRequestDetail_ac,
} from './adjustment_requests.ac';
import {
  attemptMassPrint_ac,
  getAppealPacketDetail_ac,
  markTransmissionMan_ac,
} from './appeal_requests.ac';
import { createTodo_ac } from './todos.ac';
const ALLOWED_TASK_CODES = [
  'pickup_no_bounce',
  'pickup_bounce_back',
  'vent_vest_pickup',
  'pickup_exp',
  'post_pickup_exp',
];

export function setup_main_socket_ac() {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    dispatch({ type: 'SETUP_MAIN_SOCKET' });
    let em = getStore().me.user.mail;
    const main_socket = io.connect(process.env.REACT_APP_SOCK_URL + '/main', {
      query: {
        source: 'df',
        user: em,
      },
    });
    window.main_socket = main_socket;

    main_socket.on('disconnect', (msg) => {
      console.debug('main socket event - disconnect');
      dispatch({ type: 'UPDATE_MAIN_SOCKET_STATE', status: 'disconnected' });
      return;
    });

    main_socket.on('connect', function () {
      console.debug('main socket event - connect');
      dispatch({
        type: 'UPDATE_MAIN_SOCKET_STATE',
        status: 'connected_not_in_room',
        socket: main_socket,
      });
    });

    main_socket.on('reconnect', function () {
      console.log('main socket event - reconnect');
      dispatch({
        type: 'UPDATE_MAIN_SOCKET_STATE',
        status: 'reconnect',
        socket: main_socket,
      });
    });

    main_socket.on('error', function () {
      console.log('main socket event - error');
      dispatch({ type: 'UPDATE_MAIN_SOCKET_STATE', status: 'error' });
    });
  };
}

export function unpair_with_dash_ac() {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    getStore().room.socket.emit('leave_room', {});
  };
}

export function force_dash_to_unpair_ac() {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    /*
    let msg = {
      state: 'force_leave',
      command: 'leave_room',
      command_params:{}
    }
    no msg necessary, server handles it
    */

    getStore().room.socket.emit('force_leave', {}, () => {
      console.log('server acknowledged');
    });
  };
}

export function emit_df_activity_ac(location) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    let _s = getStore().room.socket;

    if (_s) {
      if (_s.location === 'location') {
        console.log('location already emitted to room');
        return;
      }

      //console.log('emitting', location)
      dispatch({ type: 'STORE_LAST_ROOM_LOCATION', location });
      _s.emit('df_activity', {
        kind: 'location_selected',
        df_ver: process.env.REACT_APP_VER,
        location,
      });
    }
  };
}

const DASH_NONCE = [];
const check_dash_nonce = (n) => {
  if (DASH_NONCE.includes(n)) {
    return false;
  }

  DASH_NONCE.push(n);
  return true;
};

export function attempt_pair_with_dash_ac() {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware

    dispatch({ type: 'ATTEMPT_PAIR_DASH' });

    getStore()
      .room.socket.on('room_event', (room_event) => {
        console.log('socket room event', room_event);
        dispatch({ type: 'SOCKET_GOT_ROOM_EVENT', room_event });
        switch (room_event.state) {
          case 'force_leave':
            //special case when user from another df+ tab forces all connections to leave
            getStore().room.socket.emit('leave_room', {});
            break;
          case 'connected_not_in_room':
            getStore().room.socket.off('room_event').off('ub_msg');
            break;
          case 'roommate_absent':
          case 'roommate_present':
          case 'rejected':
          default:
            break;
        }

        return;
      })
      .on('ub_msg', (msg) => {
        console.log('Got ub_msg', msg);
        dispatch({ type: 'SOCKET_GOT_UB_MSG', msg });
        switch (msg.kind) {
          case 'account_selected':
            dispatch(push('/dfa/account_docs/' + msg.account.toString()));
            break;
          case 'appeal':
            dispatch(
              push(
                '/redirect_to_patient/' +
                  msg.account.toString() +
                  '/appeal_requests'
              )
            );
            break;
          case 'adjustment':
          case 'adjustment_review':
            console.log('dispatching push to adjustment tab');
            dispatch(
              push(
                '/redirect_to_patient/' +
                  msg.account.toString() +
                  '/adjustments'
              )
            );
            break;
          case 'adjustment_add_note':
            if (msg.adjustment_request_id) {
              addAdjustmentHDMSNote_ac({ _id: msg.adjustment_request_id })(
                dispatch,
                getStore
              );
            }
            break;
          case 'get_adjustment_detail':
            if (msg.adjustment_request_id) {
              getAdjustmentRequestDetail_ac({ _id: msg.adjustment_request_id })(
                dispatch,
                getStore
              );
            } else if (msg.account) {
              getAdjustmentRequestDetail_ac({ account: msg.account })(
                dispatch,
                getStore
              );
            }
            break;
          case 'get_appeal_detail':
            getAppealPacketDetail_ac(msg.appeal_packet_id)(dispatch, getStore);
            break;
          case 'mark_appeal_packet':
            markTransmissionMan_ac(msg.appeal_packet_id, msg.mark_status)(
              dispatch,
              getStore
            );
            break;
          case 'print_appeal':
            attemptMassPrint_ac(msg.appeal_packet_ids)(dispatch, getStore);
            break;
          case 'create_patient_letter':
            let target_url = `/api/patient_letter/create`;
            if (
              _.intersection(_.keys(msg), ['account', 'claim_ids', 'details'])
                .length !== 3
            ) {
              console.error(
                'Missing params from mr dash create_patient_letter call',
                msg
              );
              return;
            }
            console.log('calling /api/patient_letter/create', msg);
            return window.sch
              .post(target_url, msg)
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });

          case 'task_create':
            if (msg.nonce) {
              if (!check_dash_nonce(msg.nonce)) {
                console.log('nonce check failed, assumed duplicate');
                return;
              }
            }
            /*
              { kind: "task_create", 
                  code: "pickup_no_bounce" || "pickup_bounce_back" || "vent_vest_pickup" || "pickup_exp" || "post_pickup_xp", 
                  claim_line_ids: [ .... ], 
                  account:..., member: ..., 
                  comment: ...the comment the user enters in MR dash for the action 
              }
              attemptMassPrint_ac(msg.appeal_packet_ids)(dispatch, getStore)
            */
            if (!ALLOWED_TASK_CODES.includes(msg.code)) {
              console.error('TASK CREATION NOT ALLOWED:' + msg.code.toString());
              return;
            }
            if (_.get(msg, 'claim_line_ids', []).length === 0) {
              console.error('claim_line_ids missing');
              return;
            }

            if (_.get(msg, 'issues', []).length === 0) {
              console.error('dash_issue_txt missing');
              return;
            }

            let pkg = {};
            switch (msg.code) {
              case 'vent_vest_pickup': {
                pkg = {
                  code: 'cr',
                  account: msg.account,
                  txt:
                    `Task created from MR Dash action\nSelected issues: ${msg.issues.join(
                      '\n'
                    )}, 
                    Involving the claim lines listed below\n` +
                    (msg.comment || ''),
                  hdms_note_category: null,
                  extra_fields: {
                    sub_category: 'VENT-VEST PICK UP',
                    issues: msg.issues,
                    claim_line_ids: msg.claim_line_ids,
                  },
                };
                break;
              }
              default: {
                pkg = {
                  code: msg.code,
                  hdms_note_category: null,
                  account: msg.account,
                  txt:
                    `Task created from MR Dash action\nSelected issues: ${msg.issues.join(
                      '\n'
                    )}, 
                    Involving the claim lines listed below\n` +
                    (msg.comment || ''),
                  extra_fields: {
                    claim_line_ids: msg.claim_line_ids,
                    issues: msg.issues,
                    assignee: {
                      by: 'teamq',
                      teamq: ['PICKUP'],
                    },
                  },
                };
                break;
              }
            }
            createTodo_ac(pkg)(dispatch, getStore);
            break;
          case 'get_paired_host':
            getStore().room.socket.emit('df_msg', {
              kind: 'host_info',
              host: window.location.host,
            });
            break;
          default:
            break;
        }

        /*
      if (msg.kind==='account_selected'){
        dispatch( push("/dfa/account_docs/"+msg.account.toString()) );
      }
      */
        return;
      })
      .emit('join_room', {});
    /*
      let room =  getStore().room;
      if (room.last_df_msg && room.last_df_msg_sent===false){
        room.socket.emit("df_msg", room.last_df_msg, ()=>{
          console.log('server acknowledged')
          dispatch({ type: 'SENT_DF_MSG' })
        })
      }
    */
  };
}

window.attempt_pair_with_dash_ac = attempt_pair_with_dash_ac;
