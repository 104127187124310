import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { FiUpload } from 'react-icons/fi';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { configureCatalogue_ac } from '../../../actions/catalog.ac';
import { open_snack_ac } from '../../../actions/snack.ac.js';
import { DropImageInput } from '../../common';
import { URLList, URLSelect } from '../../common/URLSelect';
import { uploadImage } from './utils';

const styles = () => ({
  textField: {
    padding: '10px 8px',
  },
});

const CONFIGS = [
  { code: 'sn_required', lbl: 'SN Required' },
  { code: 'aob_required', lbl: 'AOB Required if Delivery' },
  { code: 'allow_pickup', lbl: 'Allow Pickup' },
  { code: 'pap_vent_alert', lbl: 'Alert RAE when Scheduled' },
  { code: 'req_wheelchair_form', lbl: 'Req. Wheelchair Assessment' },
];

const ConfigView = (props) => {
  const {
    product,
    catalog,
    open_snack_ac,
    trackingGroups,
    configureCatalogue_ac,
  } = props;

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [formState, setFormState] = React.useState({
    desc_for_patient: product.config?.desc_for_patient || '',
    urls: product.config?.urls || [],
    fa_tracking_group: product.config?.fa_tracking_group || '',
  });

  const configureCatalog = (value, kind = 'config') => {
    return configureCatalogue_ac(
      {
        _id: product._id,
        kind,
        value,
      },
      {
        onSuccess: () => {
          open_snack_ac({
            message: `Catalog: ${product._id} configured successfully`,
            variant: 'success',
          });
        },
        onError: (error) => {
          open_snack_ac({
            message: `Failed to configure the Catalog: ${product._id}. ${
              error.message || ''
            }`,
            variant: 'error',
          });
        },
      }
    );
  };

  const handleToggle = (label) => {
    return configureCatalog(!(product.config && product.config[label]), label);
  };

  const handleCountGroupChange = (value) => {
    console.log(value);
    return configureCatalog(value, 'count_group');
  };

  const handleImageUpload = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setUploading(true);
    try {
      const { urls } = await uploadImage(
        selectedImage,
        product._id,
        selectedImage.type
      );

      await configureCatalog(urls);

      open_snack_ac({
        message: `Image uploaded successfully`,
        variant: 'success',
      });
    } catch (err) {
      console.log(err);
      open_snack_ac({
        message: `Failed to upload the image`,
        variant: 'error',
      });
    }
    setUploading(false);
  };

  const handleImageSelect = (selectedImage) => {
    setSelectedImage(selectedImage);
  };

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = () => {
    configureCatalog(formState);
  };

  const handleUrlAdd = (url) => {
    setFormState({
      ...formState,
      urls: [...formState.urls, url],
    });
  };

  const handleUrlDelete = (index) => {
    const urls = [...formState.urls];
    urls.splice(index, 1);
    setFormState({
      ...formState,
      urls,
    });
  };
  const countGroups = ['pap', 'poc', 'oxygen', 'tanks', 'vent', 'vest'];

  return (
    <Grid container style={{ padding: '0px 12px' }}>
      <Grid item xs={5}>
        <div>
          {CONFIGS.map((c) => (
            <div key={c.code}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ padding: 2 }}
                    disabled={catalog.isUpdating}
                    onChange={() => handleToggle(c.code)}
                    checked={product.config ? product.config[c.code] : false}
                  />
                }
                label={<Typography color="textSecondary">{c.lbl}</Typography>}
              />
            </div>
          ))}
        </div>

        <div style={{ marginTop: '8px' }}>
          <TextField
            select
            fullWidth
            name="count_group"
            label="Count Group"
            value={product.config?.count_group || ''}
            helperText={'Select a count group'}
            onChange={(e) => handleCountGroupChange(e.target.value)}
            disabled={catalog.isUpdating}>
            <MenuItem value="">
              <Typography color="textSecondary">None</Typography>
            </MenuItem>
            {countGroups.map((tg) => (
              <MenuItem key={tg} value={tg}>
                {tg}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <DropImageInput
          disabled={uploading}
          onSelect={handleImageSelect}
          actionButton={
            <IconButton
              style={{ padding: '8px' }}
              disabled={uploading || !selectedImage}
              variant="outlined"
              size="small"
              color="primary"
              onClick={handleImageUpload}>
              <FiUpload size={18} />
            </IconButton>
          }
          text={uploading && 'Uploading the image...'}
        />
      </Grid>
      <Grid item xs={7} style={{ padding: '0px 6px' }}>
        <FormControl style={{ marginLeft: 0 }} fullWidth>
          <FormLabel component="label">Description for patient:</FormLabel>
          <TextField
            style={{ marginTop: '4px' }}
            fullWidth
            multiline
            disabled={catalog.isUpdating}
            onChange={(e) => handleChange('desc_for_patient', e.target.value)}
            value={formState.desc_for_patient}
          />
        </FormControl>
        <FormControl style={{ marginLeft: 0, marginTop: '16px' }} fullWidth>
          <FormLabel component="label">URLs:</FormLabel>
          <div style={{ marginTop: '4px' }}>
            <URLList list={formState.urls} onDelete={handleUrlDelete} />
            <URLSelect onAdd={handleUrlAdd} />
          </div>
        </FormControl>
        <div style={{ marginTop: '8px' }}>
          <TextField
            select
            fullWidth
            name="fa_tracking_group"
            label="Select a Tracking Group"
            value={formState.fa_tracking_group || ''}
            helperText={
              trackingGroups.isLoading
                ? 'Loading tracking groups...'
                : trackingGroups.error
                ? 'Failed to load tracking groups!'
                : 'Select one tracking group to enable FA Tracking'
            }
            onChange={(e) => handleChange('fa_tracking_group', e.target.value)}
            disabled={trackingGroups.isLoading || catalog.isUpdating}>
            <MenuItem value="">
              <Typography color="textSecondary">None</Typography>
            </MenuItem>
            {trackingGroups.data &&
              trackingGroups.data.length &&
              trackingGroups.data.map((tg) => (
                <MenuItem key={tg.name} value={tg.name}>
                  {tg.name}
                </MenuItem>
              ))}
          </TextField>
        </div>
        <div style={{ marginTop: '8px' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSubmit}
            disabled={catalog.isUpdating}>
            {catalog.isUpdating ? '......' : 'Update'}
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (stateFromStore) => ({
  catalog: stateFromStore.catalog,
  trackingGroups: stateFromStore.faAdminReducers.faLookups.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      configureCatalogue_ac,
      open_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfigView))
);
