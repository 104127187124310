import React from 'react'
import { Link } from 'react-router-dom'
import {
  Grid,
  SvgIcon,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ROUTE_GROUPS } from './groupedRoutes'
import NestedPageList from './NestedPageList'

const styles = theme => ({
  linkDiv: {
    marginBottom: '4px',
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto',
    padding: 32
  },
  group: {
    backgroundColor: '#fafafa',
    width: '100%'
  },
  groupHeader: {
    padding: 8,
    display: 'flex',
    alignItems: 'flex-end',
    backgroundColor: 'white',
    fontSize: 18
  },
  headerIcon: {
    fontSize: 32
  },
  link: {
    textDecoration: 'none',
    color: '#6784d2',
  },
  groupContainer: {
    padding: '16px 8px 8px 72px',
    overflowY: 'auto',
  }
})

const PageList = props => {
  const { pages, classes } = props

  return (
    <Grid container>
      {pages.map(page => (
        <Grid item key={page.label} xs={12} md={4} lg={3}>
          {page.subPages
            ? (
              <div className={classes.linkDiv}>
                <NestedPageList pages={page.subPages} primaryPageLabel={page.label} />
              </div>
            )
            : (
              <div className={classes.linkDiv}>
                <Link to={page.path} className={classes.link}>
                  <Typography
                    inline
                    variant="body1"
                    color="inherit"
                    component="span"
                  >
                    <strong>{page.label}</strong>
                  </Typography>
                </Link>
              </div>
            )}
        </Grid>
      ))}
    </Grid>
  )
}

class DefaultViewSiteMap extends React.Component {

  render() {
    const { classes, icons } = this.props

    return (
      <div className={classes.scrollArea}>
        {ROUTE_GROUPS.map(group => (
          <div key={group.groupLabel} className={classes.group}>
            <Typography
              variant="subtitle1"
              className={classes.groupHeader}
            >
              {group.groupIcon && (
                <>
                  <SvgIcon component={icons[group.groupIcon]} className={classes.headerIcon}>
                    {''}
                  </SvgIcon>
                  <span>&ensp;</span>
                </>
              )}
              <span>
                <strong>{group.groupLabel}</strong>
              </span>
            </Typography>
            <div className={classes.groupContainer}>
              <PageList pages={group.pages} classes={classes} />
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default withStyles(styles)(DefaultViewSiteMap);
