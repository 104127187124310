import React, { useEffect } from 'react';

import Loader from '../../Shared/Loader';
import { isValidString } from '../../../util/string';

import { Button, TextField } from '@material-ui/core';

const validate = (state = {}) => {
  let isValid = true;
  let message = '';

  if (!isValidString(state.name, 2)) {
    isValid = false;
    message = "Group name isn't valid!";
  }

  return [isValid, message];
};

const EditGroup = props => {
  const {
    classes,
    group = {},
    onSave,
    onDelete,
    onCancel,
    saving,
    deleting,
    ...rest
  } = props;

  const [formState, setFormState] = React.useState({});

  useEffect(() => {
    setFormState({ ...group });
  }, [group]);

  const handleChange = (key, value) => {
    setFormState(state => ({ ...state, [key]: value }));
  };

  const handleSubmit = () => {
    const [isValid, message] = validate(formState);

    if (!isValid) {
      return rest.openSnack({
        variant: 'error',
        message
      });
    }

    onSave(formState);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleDelete = () => {
    rest.openConfirmationDialog({
      title: 'Are you sure?',
      content: `The deletion is permanent, proceed?`,
      actions: [
        {
          label: 'Yes, Delete',
          action: () => {
            onDelete(group);
            rest.closeConfirmationDialog();
            return true;
          }
        }
      ]
    });
  };

  const handleFormChange = event => {
    const { name, value } = event.target;
    handleChange(name, value);
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.formHeader}>
        <span className={classes.formTitle} style={{ fontSize: '16px' }}>
          {'Queue group details'}
        </span>
      </div>
      <div className={classes.formContent}>
        <TextField
          required
          id="group_name"
          name="name"
          label="Name"
          disabled={saving}
          style={{ width: '100%' }}
          variant="standard"
          value={formState.name || ''}
          helperText="At least 2 characters required"
          onChange={handleFormChange}
        />
        <TextField
          multiline
          rows={4}
          id="group_description"
          name="description"
          label="Description"
          disabled={saving}
          style={{ width: '100%', marginTop: '8px' }}
          variant="standard"
          value={formState['description'] || ''}
          helperText="optional..."
          onChange={handleFormChange}
        />
      </div>
      <div className={classes.formButtons} style={{ marginTop: '30px' }}>
        <Button
          disabled={saving}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleSubmit}
          style={{ float: 'left' }}
        >
          {saving ? <Loader /> : group._id ? 'Update' : 'Create'}
        </Button>
        <Button
          disabled={saving || deleting}
          variant="outlined"
          size="small"
          color="primary"
          onClick={handleCancel}
          style={{ float: 'left', marginLeft: '8px' }}
        >
          Cancel
        </Button>
        {group._id && (
          <Button
            disabled={deleting}
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleDelete}
            style={{ float: 'right' }}
          >
            {deleting ? <Loader /> : 'Delete'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditGroup;
