import {
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  paginationButton: {
    borderRadius: 'unset',
    padding: '4px',
    minWidth: 'unset',
    margin: '2px 1px',
    lineHeight: 1.15,
    border: `1px solid ${theme.palette.primary.main}80`,
  },
  hiddenPages: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  pageNumForm: {
    margin: '2px 4px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
  },
  pageNumInput: {
    fontSize: 12,
    padding: '4px 0px',
    width: 48,
  },
  inputLabel: {
    fontSize: 12,
    marginRight: 4,
  },
});

const MAX_PAGE_NUMS_TO_SHOW = 7; // Maximum number of page buttons to show
const CURR_PAGE_OFFSET = 3; // Position of current page in the group of middle page numbers

/**
 * Pagination component with input field only
 */
class UPagination extends React.Component {
  constructor(props) {
    super(props);

    this.pageNumRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentPageNum !== this.props.currentPageNum &&
      this.pageNumRef.current
    ) {
      this.pageNumRef.current.value = this.props.currentPageNum + 1;
    }
  }

  handlePageNumberSubmit = (e) => {
    e.preventDefault();

    const { totalPagesAvailable } = this.props;
    const newPageNum = document.getElementsByName('pageNumber')[0].value;

    if (isNaN(newPageNum)) {
      return this.props.onPageChange(1);
    }

    if (newPageNum < 1) {
      return this.props.onPageChange(1);
    }

    if (newPageNum > totalPagesAvailable) {
      return this.props.onPageChange(totalPagesAvailable);
    }

    return this.props.onPageChange(newPageNum);
  };

  handleNextPageClick = () => {
    const { totalPagesAvailable, currentPageNum } = this.props;
    let newPage = currentPageNum + 1 + 1;

    if (newPage > totalPagesAvailable) {
      return this.props.onPageChange(totalPagesAvailable);
    }

    this.props.onPageChange(newPage);
  };

  handlePrevPageClick = () => {
    const { currentPageNum } = this.props;

    let newPage = currentPageNum + 1 - 1;
    if (newPage < 1) {
      return this.props.onPageChange(1);
    }

    this.props.onPageChange(newPage);
  };

  render() {
    const {
      classes,
      currentPageNum = 0,
      totalPagesAvailable,
      onPageChange,
      disableAll,
    } = this.props;

    const currPage = currentPageNum + 1;
    const firstAndPrevDisabled = currPage === 1;
    const nextAndLastDisabled = totalPagesAvailable === currPage;

    return (
      <Grid container alignItems="flex-end">
        <div>
          <Button
            variant="outlined"
            color="primary"
            value={1}
            onClick={() => onPageChange(1)}
            className={classes.paginationButton}
            disabled={disableAll || firstAndPrevDisabled}>
            <strong>&lt;&lt;</strong>
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handlePrevPageClick}
            disabled={firstAndPrevDisabled || disableAll}
            className={classes.paginationButton}>
            <strong>&lt;</strong>
          </Button>
        </div>
        <form
          onSubmit={this.handlePageNumberSubmit}
          className={classes.pageNumForm}>
          <InputLabel htmlFor="pageNumber" className={classes.inputLabel}>
            Page
          </InputLabel>
          <TextField
            id="pageNumber"
            name="pageNumber"
            type="number"
            defaultValue={currPage}
            inputRef={this.pageNumRef}
            inputProps={{
              min: 1,
              max: totalPagesAvailable,
              className: classes.pageNumInput,
            }}
            disabled={disableAll}
          />
        </form>
        <div>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleNextPageClick}
            disabled={nextAndLastDisabled || disableAll}
            className={classes.paginationButton}>
            <strong>&gt;</strong>
          </Button>
          <Button
            variant="outlined"
            color="primary"
            value={totalPagesAvailable}
            onClick={() => onPageChange(totalPagesAvailable)}
            className={classes.paginationButton}
            disabled={disableAll || nextAndLastDisabled}>
            <strong>&gt;&gt;</strong>
          </Button>
        </div>
      </Grid>
    );
  }
}

UPagination.propTypes = {
  classes: PropTypes.object.isRequired,
  currentPageNum: PropTypes.number.isRequired,
  totalPagesAvailable: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  disableAll: PropTypes.bool,
};

/**
 * Pagination component which extends that with input field
 * and overrides render() method to include page number buttons
 */
class UPaginationWithNums extends UPagination {
  render() {
    const {
      classes,
      currentPageNum = 0,
      totalPagesAvailable,
      onPageChange,
      disableAll,
    } = this.props;

    const currPage = currentPageNum + 1;
    const currPageLeftSideGap = CURR_PAGE_OFFSET - 1;
    const currPageRightSideGap = MAX_PAGE_NUMS_TO_SHOW - CURR_PAGE_OFFSET - 2;
    const firstAndPrevDisabled = currPage === 1;
    const nextAndLastDisabled = totalPagesAvailable === currPage;
    const showFirstDots =
      currPage > CURR_PAGE_OFFSET + 1 &&
      totalPagesAvailable > MAX_PAGE_NUMS_TO_SHOW;
    const showLastDots =
      currPage < totalPagesAvailable - currPageRightSideGap - 1 &&
      totalPagesAvailable > MAX_PAGE_NUMS_TO_SHOW;
    let pagesArray = [];

    if (showLastDots && showFirstDots) {
      for (
        let i = currPage - currPageLeftSideGap;
        i <= currPage + currPageRightSideGap;
        ++i
      ) {
        pagesArray.push(i);
      }
    } else if (showFirstDots && !showLastDots) {
      for (
        let i = totalPagesAvailable - MAX_PAGE_NUMS_TO_SHOW + 2;
        i <= totalPagesAvailable - 1;
        ++i
      ) {
        pagesArray.push(i);
      }
    } else if (!showFirstDots && showLastDots) {
      for (let i = 2; i <= MAX_PAGE_NUMS_TO_SHOW - 1; ++i) {
        pagesArray.push(i);
      }
    } else {
      for (let i = 2; i <= totalPagesAvailable - 1; ++i) {
        pagesArray.push(i);
      }
    }

    return (
      <Grid container alignItems="flex-end">
        <div style={this.props.style}>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handlePrevPageClick}
            disabled={firstAndPrevDisabled || disableAll}
            className={classes.paginationButton}>
            <strong>&lt;</strong>
          </Button>
          <Button
            variant={1 === currPage ? 'contained' : 'outlined'}
            color="primary"
            value={1}
            onClick={() => onPageChange(1)}
            className={classes.paginationButton}
            disabled={disableAll}>
            {1 === currPage ? <strong>1</strong> : 1}
          </Button>
          {showFirstDots ? (
            <Typography color="textSecondary" inline>
              &ensp;...&ensp;
            </Typography>
          ) : (
            ''
          )}
          {pagesArray.map((pageNum) => (
            <Button
              key={pageNum}
              variant={pageNum === currPage ? 'contained' : 'outlined'}
              color="primary"
              value={pageNum}
              onClick={() => onPageChange(pageNum)}
              className={classes.paginationButton}
              disabled={disableAll}>
              {pageNum === currPage ? <strong>{pageNum}</strong> : pageNum}
            </Button>
          ))}
          {showLastDots ? (
            <Typography color="textSecondary" inline>
              &ensp;...&ensp;
            </Typography>
          ) : (
            ''
          )}
          {totalPagesAvailable > 1 && (
            <Button
              variant={
                totalPagesAvailable === currPage ? 'contained' : 'outlined'
              }
              color="primary"
              value={totalPagesAvailable}
              onClick={() => onPageChange(totalPagesAvailable)}
              className={classes.paginationButton}
              disabled={disableAll}>
              {totalPagesAvailable === currPage ? (
                <strong>{totalPagesAvailable}</strong>
              ) : (
                totalPagesAvailable
              )}
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleNextPageClick}
            disabled={nextAndLastDisabled || disableAll}
            className={classes.paginationButton}>
            <strong>&gt;</strong>
          </Button>
        </div>
        {totalPagesAvailable > MAX_PAGE_NUMS_TO_SHOW && (
          <form
            onSubmit={this.handlePageNumberSubmit}
            className={classes.pageNumForm}>
            <InputLabel htmlFor="pageNumber" className={classes.inputLabel}>
              Go to Page
            </InputLabel>
            <TextField
              id="pageNumber"
              name="pageNumber"
              type="number"
              defaultValue={currPage}
              inputRef={this.pageNumRef}
              inputProps={{
                min: 1,
                max: totalPagesAvailable,
                className: classes.pageNumInput,
              }}
              disabled={disableAll}
            />
          </form>
        )}
      </Grid>
    );
  }
}

UPaginationWithNums.propTypes = {
  classes: PropTypes.object.isRequired,
  // currentPageNum: PropTypes.number.isRequired,
  // totalPagesAvailable: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  disableAll: PropTypes.bool,
};

export const Pagination = withStyles(styles)(UPagination);
export const PaginationWithNums = withStyles(styles)(UPaginationWithNums);
