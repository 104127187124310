import actionTypes from '../../actionTypes'

const initialState = {
  files: [],
  filesRead: 0,
  internals: null,
  data: {
    controlsExternal: {},
    controlsInternal: {},
    notifications: {},
    ivr: {},
    contact: {}
  },
  error: null
}

export const notify_files = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.notify_files.internals.SUCCESS: {
      return {
        ...state,
        internals: action.data
      }
    }
    case actionTypes.notify_files.internals.FAIL: {
      return {
        ...state,
        error: action.error
      }
    }
    case actionTypes.notify_files.list.SUCCESS: {
      return {
        ...state,
        files: action.data
      }
    }
    case actionTypes.notify_files.list.FAIL: {
      return {
        ...state,
        error: action.error
      }
    }
    case actionTypes.notify_files.read.SUCCESS: {
      let newData

      if (
        action.data.type === 'controlsExternal' ||
        action.data.type === 'controlsInternal'
      ) {
        newData = {}
        newData[action.data.type] = {
          ...state.data[action.data.type]
        }
        newData[action.data.type][action.data.time] = action.data.data
      } else if (action.data.type === 'notifications') {
        newData = {
          notifications: {
            // Existing notifications data
            ...state.data.notifications
          }
        }
        newData.notifications[action.data.subtype] = {
          // Existing dates
          ...state.data.notifications[action.data.subtype]
        }
        // Append new time data
        newData.notifications[action.data.subtype][action.data.time] =
          action.data.data
      } else if (action.data.type === 'ivr') {
        newData = {
          ivr: {
            // Existing IVR data
            ...state.data.ivr
          }
        }
        newData.ivr[action.data.subtype] = {
          // Existing dates
          ...state.data.ivr[action.data.subtype]
        }
        // Append new time data
        newData.ivr[action.data.subtype][action.data.time] = action.data.data
      } else if (action.data.type === 'contact') {
        newData = {
          contact: {
            // Existing contact data
            ...state.data.contact
          }
        }
        action.data.data.sort((a, b) => {
          return a.id < b.id ? -1 : 1
        })
        if (action.data.subtype === 'dynamoNoHDMS') {
          newData.contact[action.data.subtype] = action.data.data
        } else {
          newData.contact[action.data.subtype] = {
            // Existing device
            ...newData.contact[action.data.subtype]
          }
          newData.contact[action.data.subtype][action.data.device] =
            action.data.data
        }
      } else {
        console.log('Unhandled type', action.data)
        return state
      }

      return {
        ...state,
        filesRead: state.filesRead + 1,
        data: {
          ...state.data,
          ...newData
        }
      }
    }
    case actionTypes.notify_files.read.FAIL: {
      return {
        ...state,
        error: action.error
      }
    }
    default: {
      return state
    }
  }
}
