import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac'
import { fetchOpsFormsReport_ac } from '../../actions/ops_forms_report.ac'
import FilterListing from '../FilterListing'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'
import OpsFormsReport from './OpsFormsReport'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  }
})

const PAGE_TITLE = 'Ops Forms Report'
const DEFAULT_FILTERS = {
  start: {
    lbl: 'From',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mma',
    value: '',
    dirty: false,
    error: false
  },
  end: {
    lbl: 'To',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mma',
    value: '',
    dirty: false,
    error: false
  }
}

class OpsFormsReportLanding extends BaseLandingTemplate {
  constructor() {
    super()

    this.state = {
      filters: { ...DEFAULT_FILTERS },
      filterSequence: ['start', 'end'],
      selectedPageNum: 0
    }

    this.fetch = this.fetch.bind(this)
  }

  checkSituation = () => {
    const { me, lookups, lookups_ac, setTitle_ac, opsFormsReport } = this.props

    if (!me.user) return
    if (lookups.ink === null) {
      return lookups_ac()
    }

    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE)
    }

    if (
      !opsFormsReport.report &&
      !opsFormsReport.error &&
      !opsFormsReport.isFetching
    ) {
      return this.fetch()
    }
  }

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  fetch(page = 0) {
    let params = { page }

    this.props.fetchOpsFormsReport_ac(params)
  }

  applyFilters = () => {
    this.setState({
      selectedPageNum: 0
    })

    return this.fetch()
  }

  handlePageChange = newPage => {
    let targetPage = parseInt(newPage, 10)

    this.setState({
      selectedPageNum: targetPage - 1
    })

    this.fetch(targetPage - 1)
  }

  render() {
    const { classes, lookups, me, opsFormsReport } = this.props
    const { filters, filterSequence, selectedPageNum } = this.state

    if (opsFormsReport.isFetching) {
      return <Loader classes={{ message: classes.message }} />
    }

    let filtersComponent = (
      <FilterListing
        me={me}
        filters={filters}
        onFilterChange={this.onFilterChange}
        filterSequence={filterSequence}
        applyFilters={this.applyFilters}
        onFilterClearField={this.onFilterClearField}
      />
    )

    if (opsFormsReport.error) {
      return (
        <>
          {filtersComponent}
          <Typography color="error" className={classes.message}>
            Error fetching report
          </Typography>
        </>
      )
    }

    if (!opsFormsReport.report || !lookups) {
      return (
        <>
          {filtersComponent}
          <div className={classes.message}>...</div>
        </>
      )
    }

    return (
      <>
        {filtersComponent}
        <OpsFormsReport
          report={opsFormsReport.report}
          selectedPageNum={selectedPageNum}
          currentUser={{ ...me.user }}
          handlePageChange={this.handlePageChange}
        />
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  opsFormsReport: stateFromStore.opsFormsReport
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac,
      fetchOpsFormsReport_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OpsFormsReportLanding))
)
