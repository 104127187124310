import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Clear as ClearIcon, Done as DoneIcon } from '@material-ui/icons';

import {
  addSalesOCNotes_ac,
  fetchSalesOCNotes_ac,
} from '../../../actions/sales_reps.ac';
import { getErrorMessage } from '../../../util/string';
import Loader from '../../Shared/Loader';

const styles = () => ({
  label: {
    fontSize: 13,
  },
  formNode: {
    marginTop: '4px',
  },
});

const TAGS = ['in-person', 'in-service', 'email', 'phone', 'non-interaction'];

const SalesOCNotes = (props) => {
  const { classes, salesReps, oc_id, ...rest } = props;

  const {
    ocNotes: { data: notes, isFetching },
    isAddingOCNote: isAdding,
  } = salesReps;

  const [note, setNote] = useState('');
  const [tags, setTags] = useState([TAGS[0]]);

  useEffect(() => {
    if (oc_id) props.fetchSalesOCNotes({ oc_id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oc_id]);

  const handleAddClick = () => {
    rest.addSalesOCNotes(
      { oc_id, note, tags },
      {
        onSuccess: () => {
          rest.openSnack({
            variant: 'success',
            message: `Sales OC Note added successfully!`,
          });
          setNote('');
        },
        onError: (err) => {
          rest.openSnack({
            variant: 'error',
            message: getErrorMessage(err, 'Failed to add the note!'),
          });
        },
      }
    );
  };

  return isFetching ? (
    <Loader type="circular" />
  ) : (
    <div>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: '12px' }}>
        {'Sales Notes: '}
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={9}>
          <div className={classes.formNode}>
            <label className={classes.label}>{'Note tag:'}</label>
            <RadioGroup
              name={'oc_note_tag'}
              value={tags[0]}
              style={{
                flexDirection: 'row',
                marginTop: '4px',
                paddingLeft: '6px',
              }}
              onChange={(event) => {
                console.log(event.target);
                setTags(
                  Boolean(event.target.value) ? [event.target.value] : []
                );
              }}>
              {TAGS.map((t) => (
                <FormControlLabel
                  key={t}
                  control={
                    <Radio
                      style={{ padding: '4px' }}
                      onClick={() => {
                        if (tags[0] === t) {
                          setTags([]);
                        }
                      }}
                    />
                  }
                  label={t}
                  value={t}
                />
              ))}
            </RadioGroup>
          </div>
          <TextField
            placeholder="Enter notes here (3 character minimum)"
            multiline
            variant="standard"
            size="small"
            rows="5"
            fullWidth
            disabled={isAdding}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  <IconButton
                    style={{ padding: 8 }}
                    onClick={handleAddClick}
                    disabled={isAdding || note.length < 3}>
                    <DoneIcon fontSize="small" />
                  </IconButton>

                  <IconButton
                    style={{ padding: 8 }}
                    disabled={isAdding || note.length === 0}
                    onClick={() => setNote('')}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </>
              ),
            }}
          />
          <Button
            variant="outlined"
            disabled={isAdding || note.length < 3}
            className={classes.button}
            style={{ borderColor: 'blue', marginTop: 8, marginBottom: '8px' }}
            color="primary"
            onClick={handleAddClick}>
            Submit Note
          </Button>
          <div
            style={{
              marginTop: '16px',
              overflow: 'hidden scroll',
              maxHeight: '38vh',
              paddingRight: '10px',
            }}>
            {' '}
            {notes.map((note, nIdx) => {
              let textParts = note.note.split(/\n/g);
              textParts = textParts.map((p, pi) => {
                if (pi === textParts.length - 1) return p;
                return (
                  <span key={pi}>
                    {p}
                    <br />
                  </span>
                );
              });
              return (
                <Typography
                  key={nIdx}
                  paragraph
                  component="div"
                  style={{
                    color: 'firebrick',
                    borderRadius: 4,
                    marginBottom: 4,
                    padding: 4,
                    backgroundColor: '#ccdbe2',
                  }}
                  color="textSecondary">
                  {textParts}
                  {note.tags?.length ? (
                    <div style={{ margin: '4px 0px' }}>
                      <span style={{ color: 'lightseagreen' }}>{'Tag: '}</span>
                      {note.tags.map((tag, idx) => (
                        <span
                          key={idx}
                          style={{
                            marginRight: '3px',
                            color: 'darkslategray',
                            fontSize: '1em',
                          }}>
                          {tag}
                        </span>
                      ))}
                    </div>
                  ) : null}
                  <div
                    style={{
                      marginLeft: 4,
                      color: '#244e91',
                      fontSize: '.98em',
                    }}>
                    {' '}
                    - {note.u_nm}{' '}
                    <Moment format="M-DD-YY h:mma">{note.ts}</Moment>
                  </div>
                </Typography>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  salesReps: state.salesReps,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSalesOCNotes: fetchSalesOCNotes_ac,
      addSalesOCNotes: addSalesOCNotes_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SalesOCNotes));
