export function idnAccountInfo(
  state = {
    isFetching: false,
    isFetchingNotifPref: false,
    idn: null,
    error: null,
    activityPkg: null,
    pkg: null,
    isAccountCreateTabOpen: false,
    stateData: null,
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_IDN_ACCOUNT_INFO':
      return Object.assign({}, state, {
        pkg: null,
        error: null,
        isFetching: true,
        idn: action.idn,
      });
    case 'FAILED_RECEIVED_IDN_ACCOUNT_INFO':
      return Object.assign({}, state, {
        pkg: null,
        error: action.error,
        isFetching: false,
      });
    case 'RECEIVED_IDN_ACCOUNT_INFO':
      return Object.assign({}, state, {
        pkg: action.pkg,
        error: null,
        isFetching: false,
      });

    case 'REQUEST_IDN_NOTIF_PREF':
      return Object.assign({}, state, {
        error: null,
        isFetchingNotifPref: true,
      });
    case 'FAILED_RECEIVED_IDN_NOTIF_PREF':
      return Object.assign({}, state, {
        error: action.error,
        isFetchingNotifPref: false,
      });
    case 'RECEIVED_IDN_NOTIF_PREF':
      return Object.assign({}, state, {
        pkg: action.pkg,
        error: null,
        isFetchingNotifPref: false,
      });

    case 'SET_ACCOUNT_CREATE_TAB_OPEN':
      return {
        ...state,
        isAccountCreateTabOpen: action.isOpen,
      };

    case 'SET_ACCOUNT_CREATION_DATA':
      return {
        ...state,
        stateData: action.data,
      };

    case 'CLEAR_IDN_ACCOUNT_INFO_ACTIVITY_PKG':
      return Object.assign({}, state, {
        activityPkg: null,
      });
    case 'UPDATE_IDN_ACCOUNT_INFO_ACTIVITY_PKG':
      return Object.assign({}, state, {
        activityPkg: action.activityPkg,
      });
    case 'RESET_IDN_ACCOUNT_INFO':
      return Object.assign({}, state, {
        pkg: null,
        activityPkg: null,
        error: null,
        idn: null,
        isFetching: false,
      });
    default:
      return state;
  }
}
