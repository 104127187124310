import { serialize } from '../../util/object';

export const actionTypes = {
  FETCH_FORM_LOOKUPS: 'FETCH_FORM_LOOKUPS',
  FETCH_FORM_LOOKUPS_SUCCESSFUL: 'FETCH_FORM_LOOKUPS_SUCCESSFUL',
  FETCH_FORM_LOOKUPS_FAILED: 'FETCH_FORM_LOOKUPS_FAILED',
  FETCH_FORM_LOOKUP_DETAIL: 'FETCH_FORM_LOOKUP_DETAIL',
  FETCH_FORM_LOOKUP_DETAIL_SUCCESSFUL: 'FETCH_FORM_LOOKUP_DETAIL_SUCCESSFUL',
  FETCH_FORM_LOOKUP_DETAIL_FAILED: 'FETCH_FORM_LOOKUP_DETAIL_FAILED_FAILED',
  ADD_FORM_LOOKUP: 'ADD_FORM_LOOKUP',
  ADD_FORM_LOOKUP_SUCCESSFUL: 'ADD_FORM_LOOKUP_SUCCESSFUL',
  ADD_FORM_LOOKUP_FAILED: 'ADD_FORM_LOOKUP_FAILED',
  REMOVE_FORM_LOOKUP: 'REMOVE_FORM_LOOKUP',
  REMOVE_FORM_LOOKUP_SUCCESSFUL: 'REMOVE_FORM_LOOKUP_SUCCESSFUL',
  REMOVE_FORM_LOOKUP_FAILED: 'REMOVE_FORM_LOOKUP_FAILED',
  FETCH_FORM_LOOKUPS_STRUCTURE: 'FETCH_FORM_LOOKUPS_STRUCTURE',
  FETCH_FORM_LOOKUPS_STRUCTURE_SUCCESSFUL:
    'FETCH_FORM_LOOKUPS_STRUCTURE_SUCCESSFUL',
  FETCH_FORM_LOOKUPS_STRUCTURE_FAILED: 'FETCH_FORM_LOOKUPS_STRUCTURE_FAILED',
  SET_FORM_LOOKUP_PROPS: 'SET_FORM_LOOKUP_PROPS',
};

export function fetchFormLookupsStructure_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_LOOKUPS_STRUCTURE });
    return window.sch
      .get(`/api/forms/lookups/structure?` + serialize(params))
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_LOOKUPS_STRUCTURE_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_LOOKUPS_STRUCTURE_FAILED,
          error,
        });
      });
  };
}

export function fetchFormLookups_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_LOOKUPS });
    return window.sch
      .get(`/api/forms/lookups/list?` + serialize(params))
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_LOOKUPS_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_LOOKUPS_FAILED,
          error,
        });
      });
  };
}

export function fetchFormLookupDetail_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_LOOKUP_DETAIL });
    return window.sch
      .post(`/api/forms/lookups/detail`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_LOOKUP_DETAIL_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_LOOKUP_DETAIL_FAILED,
          error,
        });
      });
  };
}

export function addFormLookup_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_FORM_LOOKUP });
    return window.sch
      .post(`/api/forms/lookups/add`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.ADD_FORM_LOOKUP_SUCCESSFUL,
          data: { ...params, _id: result.new_id, seq: result.next_seq },
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_FORM_LOOKUP_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}
export function removeFormLookup_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_FORM_LOOKUP });
    return window.sch
      .post(`/api/forms/lookups/remove`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_LOOKUP_SUCCESSFUL,
          data: result,
          removedIndex: params.removedIndex,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_LOOKUP_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function setFormLookupProps_ac(lookup, props) {
  return {
    type: actionTypes.SET_FORM_LOOKUP_PROPS,
    lookup,
    props,
  };
}
