import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';

import { DataRow, EmptyRow } from './OxygenCountRows';

const OxygenCountsTable = (props) => {
  const { groupedCount } = props;
  const hasRecords =
    groupedCount && groupedCount.entries && groupedCount.entries.length > 0;

  let totals = {
    deliveryQty: 0,
    pickupQty: 0,
    exQty: 0,
    lines: 0,
    wos: 0,
    wids: [],
  };

  return (
    <Table padding="dense">
      <TableHead>
        <TableRow>
          <TableCell width="20%">Product</TableCell>
          <TableCell width="10%" align="right">
            Deliver
          </TableCell>
          <TableCell width="10%" align="right">
            Pickup
          </TableCell>
          <TableCell width="10%" align="right">
            Exchang
          </TableCell>
          <TableCell width="10%" align="right">
            No. of lines
          </TableCell>
          <TableCell width="35%">Workorders</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!hasRecords ? (
          <EmptyRow colSpan={4} />
        ) : (
          <>
            {groupedCount.entries.map((count, idx) => {
              totals.deliveryQty += count.qty_delivered || 0;
              totals.pickupQty += count.qty_pickup || 0;
              totals.exQty += count.qty_exchange || 0;
              totals.lines += count.line_count || 0;
              totals.wos += (count.wos_ids || []).length;
              totals.wids = totals.wids.concat(count.wos_ids || []);

              return <DataRow key={`${count._id}_${idx}`} countData={count} />;
            })}
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell>Total</TableCell>
              <TableCell align="right">
                <strong>{totals.deliveryQty}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.pickupQty}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.exQty}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totals.lines}</strong>
              </TableCell>
              <TableCell>
                <strong>{totals.wos}</strong> Orders
                <br />
                {totals.wos && (
                  <Link
                    href={
                      groupedCount.entries[0].dtrackWosLink +
                      ',' +
                      totals.wids.join(',')
                    }
                    target="_blank"
                    rel="noopener"
                    style={{ color: '#1976d2' }}>
                    <strong>View in DeliveryTrack: </strong>
                  </Link>
                )}
              </TableCell>
            </TableRow>
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default OxygenCountsTable;
