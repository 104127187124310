import { refresh_user_profile_ac } from './me.ac.js'
import { open_snack_ac } from './snack.ac.js'
export function resetTodoCreationActivity_ac() {
  return dispatch => {
    dispatch({ type: 'RESET_TODO_CREATION_ACTIVITY' })
    dispatch({ type: 'CLEAR_IDN_WOS_ACTIVITY_PKG' })
  }
}

//mutates todosInView
export function fetchTodos_ac(params) {
  return (dispatch, getState) => {
    dispatch({
      type: 'REQUEST_TODOS',
      params
    })

    return window.sch
      .post('/api/todos/get', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }

        dispatch({
          type: 'RECEIVED_TODOS',
          records: result.records,
          pagination: result.pagination
        })

        if (result.filter_pkg) {
          dispatch(refresh_user_profile_ac())
          /*
        let _u = getState().me.user;
        if ( _u.filters ){
          _u.filters = {
            ..._u.filters,
            [result.filter_pkg.k] : result.filter_pkg.v
          }
        }
        dispatch({
          type: 'GOT_UPDATED_USER',
          user: _u
        })
      */
        }
      })
      .catch((error, res) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: error.message
          })
        )

        dispatch({
          type: 'FAILED_RECEIVED_TODOS',
          error
        })
      })
  }
}

export function locallyUpdateTodoDoc_ac(pos, modifiedDoc) {
  return (dispatch, getState) => {
    let tiv = getState().todosInView
    let _records = tiv.records
    _records[pos] = modifiedDoc
    dispatch({
      type: 'RECEIVED_TODOS',
      records: _records,
      pagination: tiv.pagination
    })
  }
}

export function toggleAllCards_ac(expand) {
  return (dispatch, getState) => {
    let tiv = getState().todosInView
    let _records = tiv.records.map(r => {
      r.expanded = expand
      return r
    })
    dispatch({
      type: 'RECEIVED_TODOS',
      records: _records,
      pagination: tiv.pagination
    })
  }
}

export function resetTodos_ac() {
  return dispatch => {
    dispatch({
      type: 'RESET_TODOS'
    })
  }
}

//mutates todosInView
export function fetchTodosSummary_ac() {
  return dispatch => {
    dispatch({
      type: 'REQUEST_TODOS_SUMMARY'
    })

    return window.sch
      .get('/api/todos/summary')
      .then(result => {
        dispatch({
          type: 'RECEIVED_TODOS_SUMMARY',
          summary: result.summary
        })
      })
      .catch(error => {
        dispatch({
          type: 'FAILED_RECEIVED_TODOS_SUMMARY',
          error
        })
      })
  }
}

export function fetchTodosSlicing_ac(params) {
  return dispatch => {
    dispatch({
      type: 'REQUEST_TODOS_SLICING'
    })

    return window.sch
      .post('/api/todos/slicing', params)
      .then(result => {
        dispatch({
          type: 'RECEIVED_TODOS_SLICING',
          slicing: result.slicing,
          abc: result.abc
        })
      })
      .catch(error => {
        dispatch({
          type: 'FAILED_RECEIVED_TODOS_SLICING',
          error
        })
      })
  }
}

export function openSnack_ac(params) {
  return (dispatch, getStore) => {
    dispatch(
      open_snack_ac({
        variant: params.variant || 'info',
        message: params.message
      })
    )
  }
}

//mutates todosInView
export function updateTodo_ac(posInSet, pkg) {
  return (dispatch, getStore) => {
    dispatch({
      type: 'UPDATE_TODO',
      pkg: pkg
    })

    let u = getStore().me.user
    let postData = {
      ...pkg,
      u_id: u.mail,
      u_nm: u.nm
    }

    return window.sch
      .post('/api/todos/update', postData)
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }

        let _rr = [].concat(getStore().todosInView.records)
        _rr[posInSet] = result.updatedTodoDoc
        //update the entire record set
        dispatch({
          type: 'RECEIVED_TODOS',
          records: _rr
        })
        //clear the inprog status
        dispatch({ type: 'RESPONSE_UPDATE_TODO' })
        if (pkg.snackMsg) {
          dispatch(
            open_snack_ac({
              variant: 'info',
              message: pkg.snackMsg
            })
          )
        }
      })
      .catch(error => {
        console.log('Error', error.message)
        dispatch(
          open_snack_ac({
            variant: 'error',
            message:
              typeof error.message === 'string'
                ? error.message
                : 'Update failed'
          })
        )
        dispatch({
          type: 'FAILED_UPDATE_TODO',
          error
        })
      })
  }
}

//mutates store.todoCreationActivity
export function createTodo_ac(pkg) {
  return (dispatch, getStore) => {
    dispatch({
      type: 'CREATE_TODO'
    })

    let u = getStore().me.user
    let postData = {
      ...pkg,
      u_id: u.mail,
      u_nm: u.nm
    }

    return window.sch
      .post('/api/todos/create', postData)
      .then(result => {
        dispatch({ type: 'RESPONSE_CREATE_TODO', resultPkg: result.pkg })
        dispatch({ type: 'RESET_TODOS' }) //so that a new fetch happens
      })
      .catch(error => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Create Task failed'
          })
        )
        dispatch({
          type: 'FAILED_CREATE_TODO',
          error
        })
      })
  }
}
