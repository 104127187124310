export const pdfDetails = {
  //Get all pdf dtail
  GETALL_PDFDETAIL: 'GETALL_PDFDETAIL',
  GETALL_PDFDETAIL_SUCCESS: 'GETALL_PDFDETAIL_SUCCESS',

  // Post pdf detail
  POST_PDFDETAIL: 'POST_PDFDETAIL',
  POST_PDFDETAIL_SUCCESS: 'POST_PDFDETAIL_SUCCESS',

  // pdf request failed
  PDFDETAIL_REQUEST_FAILED: 'PDFDETAIL_REQUEST_FAILED',
};
