export const actionTypes = {
  FETCH_FORM_SUBMISSIONS: 'FETCH_FORM_SUBMISSIONS',
  FETCH_FORM_SUBMISSIONS_SUCCESSFUL: 'FETCH_FORM_SUBMISSIONS_SUCCESSFUL',
  FETCH_FORM_SUBMISSIONS_FAILED: 'FETCH_FORM_SUBMISSIONS_FAILED',

  FETCH_FORM_SUBMISSION_PDF: 'FETCH_FORM_SUBMISSION_PDF',
  FETCH_FORM_SUBMISSION_PDF_SUCCESSFUL: 'FETCH_FORM_SUBMISSION_PDF_SUCCESSFUL',
  FETCH_FORM_SUBMISSION_PDF_FAILED: 'FETCH_FORM_SUBMISSION_PDF_FAILED_FAILED',

  FLUSH_FORM_SUBMISSION_PDF: 'FLUSH_FORM_SUBMISSION_PDF',

  CREATE_FORM_SUBMISSION: 'CREATE_FORM_SUBMISSION',
  CREATE_FORM_SUBMISSION_SUCCESSFUL: 'CREATE_FORM_SUBMISSION_SUCCESSFUL',
  CREATE_FORM_SUBMISSION_FAILED: 'CREATE_FORM_SUBMISSION_FAILED',
  UPDATE_FORM_SUBMISSION: 'UPDATE_FORM_SUBMISSION',
  UPDATE_FORM_SUBMISSION_SUCCESSFUL: 'UPDATE_FORM_SUBMISSION_SUCCESSFUL',
  UPDATE_FORM_SUBMISSION_FAILED: 'UPDATE_FORM_SUBMISSION_FAILED',
  REMOVE_FORM_SUBMISSION: 'REMOVE_FORM_SUBMISSION',
  REMOVE_FORM_SUBMISSION_SUCCESSFUL: 'REMOVE_FORM_SUBMISSION_SUCCESSFUL',
  REMOVE_FORM_SUBMISSION_FAILED: 'REMOVE_FORM_SUBMISSION_FAILED',

  ADD_COMMENT_TO_SUBMISSION: 'ADD_COMMENT_TO_SUBMISSION',
  ADD_COMMENT_TO_SUBMISSION_SUCCESSFUL: 'ADD_COMMENT_TO_SUBMISSION_SUCCESSFUL',
  ADD_COMMENT_TO_SUBMISSION_FAILED: 'ADD_COMMENT_TO_SUBMISSION_FAILED',
};

export function fetchFormSubmissions_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_SUBMISSIONS });
    return window.sch
      .post('/api/forms/submissions/get', params)
      .then((result) => {
        dispatch({
          type: actionTypes.FETCH_FORM_SUBMISSIONS_SUCCESSFUL,
          data: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_SUBMISSIONS_FAILED,
          error,
        });
      });
  };
}

export function fetchFormSubmissionPDF_ac(subId, params = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_FORM_SUBMISSION_PDF });

    return window.sch
      .fetchFileBlob(`/api/forms/submissions/${subId}/pdf`, {
        ...params,
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        dispatch({
          type: actionTypes.FETCH_FORM_SUBMISSION_PDF_SUCCESSFUL,
          data: url,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_FORM_SUBMISSION_PDF_FAILED,
          error,
        });
      });
  };
}

export function flushFormSubmissionPDF_ac() {
  return (dispatch) => {
    dispatch({ type: actionTypes.FLUSH_FORM_SUBMISSION_PDF });
  };
}

export function createFormSubmission_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_FORM_SUBMISSION });
    return window.sch
      .post(`/api/forms/submissions/create`, params)
      .then((result) => {
        if (result.success === false) {
          const err = new Error(result.error || result.message || '');
          if (onError) {
            onError(err, result.msgs);
          }
          return dispatch({
            type: actionTypes.CREATE_FORM_SUBMISSION_FAILED,
            error: err,
          });
        }
        dispatch({
          type: actionTypes.CREATE_FORM_SUBMISSION_SUCCESSFUL,
          data: { ...params, _id: result.new_id, seq: result.next_seq },
        });
        if (onSuccess) {
          onSuccess(result.new_id);
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE_FORM_SUBMISSION_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function updateFormSubmission_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_FORM_SUBMISSION });
    return window.sch
      .post(`/api/forms/submissions/update`, params)
      .then((result) => {
        if (result.success === false) {
          const err = new Error(result.error || result.message || '');
          if (onError) {
            onError(err, result.msgs);
          }
          return dispatch({
            type: actionTypes.UPDATE_FORM_SUBMISSION_FAILED,
            error: err,
          });
        }
        dispatch({
          type: actionTypes.UPDATE_FORM_SUBMISSION_SUCCESSFUL,
          data: params,
          updated_id: params._id,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_FORM_SUBMISSION_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function removeFormSubmission_ac(params, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.REMOVE_FORM_SUBMISSION });
    return window.sch
      .post(`/api/forms/submissions/delete`, params)
      .then((result) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_SUBMISSION_SUCCESSFUL,
          data: result,
          removed_id: params._id,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.REMOVE_FORM_SUBMISSION_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}

export function addCommentToSubmission_ac(
  subId,
  comment,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ADD_COMMENT_TO_SUBMISSION });
    return window.sch
      .post(`/api/forms/submissions/${subId}/comments`, {
        comment,
      })
      .then((result) => {
        dispatch({
          type: actionTypes.ADD_COMMENT_TO_SUBMISSION_SUCCESSFUL,
          data: result,
          updated_id: subId,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_COMMENT_TO_SUBMISSION_FAILED,
          error,
        });
        if (onError) {
          onError(error);
        }
      });
  };
}
