import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import { addHDMSNotes_ac } from '../../actions/pap_calculator.ac'
import { PAPFrequencyIdentifier } from './PAPFrequencyIdentifier.js'
import { PAPFrequencyListing } from './PAPFrequencyListing'
import { NotesGenerationForm } from './GenerateNotesModal.js'
import { MASK_KIT_HELP_TEXT } from './../../constants/PAPFrequencyConstant'
import fullFaceMask from './../../pngs/full_face.png'
import nasalCushionMask from './../../pngs/nasal_cushion.png'
import nasalPillowMask from './../../pngs/nasal_pillow.png'
import { open_snack_ac } from '../../actions/snack.ac.js'
import { MaskViewModal } from './MaskViewModal.js'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100%)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  fullHeight: {
    height: '100%'
  },
  pdfviewer: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflowY: 'auto'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  inlineBlock: {
    display: 'inline-block'
  },
  mt10: {
    marginTop: '10px'
  },
  tableScrollable: {
    width: '100%',
    overflow: 'auto'
  },
  tableHeader: {
    height: 20,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff'
    }
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white'
  },
  modalWrapper: {
    width: '40%',
    minWidth: '500px',
    maxHeight: 'calc(100% - 80px)',
    padding: '0px 8px',
    margin: '40px auto',
    backgroundColor: 'white',
    outline: 'unset',
    overflowY: 'auto'
  },
  modalStickyComponentTop: {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 10,
    borderTop: '1px solid #c5c5c5',
    bottom: 0
  },
  modalStickyComponentBottom: {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 10,
    borderBottom: '1px solid #c5c5c5',
    top: 0
  },
  button: {
    marginLeft: '10px'
  }
})

class PAPCalculatorLandingComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
      isMaskViewModalOpen: false
    }
  }

  componentDidMount() {
    const { setTitle_ac } = this.props
    setTitle_ac('PAP Frequency Calculator')
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation() {
    const { lookups, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }
  }

  handleModalOpenClick = () => {
    this.setState({
      isModalOpen: true
    })
  }

  handleMaskModalOpenClick = () => {
    this.setState({
      isMaskViewModalOpen: true
    })
  }

  handleMaskModalCloseClick = () => {
    this.setState({
      isMaskViewModalOpen: false
    })
  }

  handleCloseClick = () => {
    this.setState({
      isModalOpen: false
    })
  }

  saveNotesToHDMS = (data) => {
    let accountMember = this.props.idnInView?.idnDoc?.aa[0]
    if (!accountMember) {
      return this.props.open_snack_ac({
        variant: 'error',
        message: 'Account information required to save notes '
      })
    }

    this.setState({
      isModalOpen: false
    })

    const notes = {
      ...data
    }
    notes.itemsReplacement = notes.itemsReplacement.map(item => {
      return {
        item_description: item.description,
        reason: item.reason
      }
    })
    notes.newItemsOrdered = notes.newItemsOrdered.map(item => {
      return {
        item_description: item.description,
        quantity: item.qty,
        size: item.size,
        option: item.option
      }
    })

    const requestData = {
      notes
    }

    let accMemberToArray = accountMember.split('-')

    requestData.account = accMemberToArray[0]
    requestData.member = accMemberToArray[1]

    this.props.addHDMSNotes_ac(requestData)
  }

  render() {
    const { classes, papCalculator } = this.props
    const { mask_type, frequencyList, selectedItems } = papCalculator;
    let selected_mask;
    switch (mask_type) {
      case 'fullface':
        selected_mask = fullFaceMask
        break;
      case 'nasal-cushion':
        selected_mask = nasalCushionMask
        break;
      case 'nasal-pillow':
        selected_mask = nasalPillowMask
        break;
      default:
        break;
    }

    return (
      <main className={classes.content}>
        <Grid container direction="row" spacing={8} className={classes.fullHeight}>
          <Grid xs={3} item className={classes.fullHeight}>
            <Typography
              className={classes.pageStatement}
              color="textSecondary"
            >
              Identifiers
            </Typography>

            <PAPFrequencyIdentifier
              handleModalOpenClick={this.handleModalOpenClick}
              accountId={this.props.idnInView?.idnDoc?.aa[0]}
            ></PAPFrequencyIdentifier>

            {frequencyList && frequencyList.length > 0 && (
              <>
                <Typography
                  className={classes.pageStatement}
                  color="primary"
                  variant="h2"
                >
                  Selected Mask
                </Typography>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary"
                  paragraph={true}
                >
                  {MASK_KIT_HELP_TEXT}
                </Typography>
                <img onClick={this.handleMaskModalOpenClick} src={selected_mask} alt="mask_image.png" width="150px"></img>
              </>
            )}
          </Grid>
          <Grid xs={9} item className={classes.fullHeight}>
            <Typography
              className={classes.pageStatement}
              color="textSecondary"
            >
              Frequency List
            </Typography>
            <PAPFrequencyListing />
          </Grid>
        </Grid>
        {/* Modal for generating notes */}
        <NotesGenerationForm
          isModalOpen={this.state.isModalOpen}
          handleCloseClick={this.handleCloseClick}
          saveNotes={this.saveNotesToHDMS}
          classes={classes}
          selectedItems={selectedItems}
          isSaving={papCalculator.isSaving}
          open_snack_ac={this.props.open_snack_ac}
          selectedMaskType={mask_type}
        />
        {/* Modal for generating notes */}
        <MaskViewModal
          isModalOpen={this.state.isMaskViewModalOpen}
          handleCloseClick={this.handleMaskModalCloseClick}
          classes={classes}
          selected_mask={selected_mask}
          mask_type={mask_type}
          help_text={MASK_KIT_HELP_TEXT}
        />
      </main>
    )
  }
}

PAPCalculatorLandingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  papCalculator: stateFromStore.papCalculator
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
      addHDMSNotes_ac,
      open_snack_ac
    },
    dispatch
  )

export const PAPCalculatorLanding = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PAPCalculatorLandingComponent))
)
