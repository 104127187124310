export const actionTypes = {
  QUERY_ACTIVITY_LOG_AUDIT: 'QUERY_ACTIVITY_LOG_AUDIT',
  QUERY_ACTIVITY_LOG_AUDIT_SUCCESS: 'QUERY_ACTIVITY_LOG_AUDIT_SUCCESS',
  QUERY_ACTIVITY_LOG_AUDIT_FAILURE: 'QUERY_ACTIVITY_LOG_AUDIT_FAILURE',
};

export function queryActivityLogAudit_ac(params) {
  return (dispatch) => {
    dispatch({ type: actionTypes.QUERY_ACTIVITY_LOG_AUDIT });
    return window.sch
      .post('/api/alog/audit/query', params)
      .then((result) => {
        dispatch({
          type: actionTypes.QUERY_ACTIVITY_LOG_AUDIT_SUCCESS,
          data: result?.pkg?.rr || [],
          pagination: result?.pkg?.pagination || {},
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.QUERY_ACTIVITY_LOG_AUDIT_FAILURE,
          error,
        });
      });
  };
}
