import actionTypes from '../actionTypes'

export function listFiles() {
  return async dispatch => {
    try {
      let response = await window.sch.get('/api/ns_proxy/files')

      dispatch({
        type: actionTypes.notify_files.list.SUCCESS,
        data: response.data
      })
    } catch (error) {
      console.error('actions/files/listFiles', error)

      dispatch({
        type: actionTypes.notify_files.list.FAIL,
        error
      })
    }
  }
}

export function readFile(file) {
  return async dispatch => {
    try {
      let response = await window.sch.get('/api/ns_proxy/' + file)

      let parts = file.split('_')
      let time = parts.pop().replace('.json', '')
      let type = parts.shift()
      let subtype
      if (parts.length > 0) subtype = parts[0]

      let data = {
        type,
        subtype,
        data: response.data
      }

      if (type === 'contact') {
        if (time === 'dynamoNoHDMS') data.subtype = time
        else data.device = time
      } else {
        data.time = time
      }

      dispatch({
        type: actionTypes.notify_files.read.SUCCESS,
        data
      })
    } catch (error) {
      console.error('actions/files/readFile', error)

      dispatch({
        type: actionTypes.notify_files.read.FAIL,
        error
      })
    }
  }
}

export function internalsFile() {
  return async dispatch => {
    try {
      let response = await window.sch.get('/api/ns_proxy/internals.json')

      dispatch({
        type: actionTypes.notify_files.internals.SUCCESS,
        data: response.data
      })
    } catch (error) {
      console.error('actions/files/internalsFile', error)

      dispatch({
        type: actionTypes.notify_files.internals.FAIL,
        error
      })
    }
  }
}

export function readAllFiles() {
  return async (dispatch, getState) => {
    await dispatch(listFiles())

    let files = getState().notify_files.files
    for (let i = 0; i < files.length; i++) await dispatch(readFile(files[i]))
  }
}

export function setCategory(category) {
  return dispatch => {
    dispatch({
      type: actionTypes.notify_view.category.SET,
      category
    })
  }
}

export function setSubcategory(subcategory) {
  return dispatch => {
    dispatch({
      type: actionTypes.notify_view.subcategory.SET,
      subcategory
    })
  }
}

export function setSubsubcategory(subsubcategory) {
  return dispatch => {
    dispatch({
      type: actionTypes.notify_view.subsubcategory.SET,
      subsubcategory
    })
  }
}
