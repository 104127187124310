export const actionTypes = {
  FETCH_REACT_HEALTH_PATIENT_PENDING: 'FETCH_REACT_HEALTH_PATIENT_PENDING',
  FETCH_REACT_HEALTH_PATIENT_SUCCESSFUL:
    'FETCH_REACT_HEALTH_PATIENT_SUCCESSFUL',
  FETCH_REACT_HEALTH_PATIENT_FAILED: 'FETCH_REACT_HEALTH_PATIENT_FAILED',

  FLUSH_REACT_HEALTH_PATIENT: 'FLUSH_REACT_HEALTH_PATIENT',

  FLUSH_REACT_HEALTH_PROVIDERS: 'FLUSH_REACT_HEALTH_PROVIDERS',

  FETCH_REACT_HEALTH_PT_REPORT_PENDING: 'FETCH_REACT_HEALTH_PT_REPORT_PENDING',
  FETCH_REACT_HEALTH_PT_REPORT_SUCCESSFUL:
    'FETCH_REACT_HEALTH_PT_REPORT_SUCCESSFUL',
  FETCH_REACT_HEALTH_PT_REPORT_FAILED: 'FETCH_REACT_HEALTH_PT_REPORT_FAILED',

  FETCH_REACT_HEALTH_CLINICIANS_PENDING:
    'FETCH_REACT_HEALTH_CLINICIANS_PENDING',
  FETCH_REACT_HEALTH_CLINICIANS_SUCCESSFUL:
    'FETCH_REACT_HEALTH_CLINICIANS_SUCCESSFUL',
  FETCH_REACT_HEALTH_CLINICIANS_FAILED: 'FETCH_REACT_HEALTH_CLINICIANS_FAILED',

  FIND_REACT_HEALTH_PROVIDERS_PENDING: 'FIND_REACT_HEALTH_PROVIDERS_PENDING',
  FIND_REACT_HEALTH_PROVIDERS_SUCCESSFUL:
    'FIND_REACT_HEALTH_PROVIDERS_SUCCESSFUL',
  FIND_REACT_HEALTH_PROVIDERS_FAILED: 'FIND_REACT_HEALTH_PROVIDERS_FAILED',

  ONBOARD_PATIENT_REACT_HEALTH_PENDING: 'ONBOARD_PATIENT_REACT_HEALTH_PENDING',
  ONBOARD_PATIENT_REACT_HEALTH_SUCCESSFUL:
    'ONBOARD_PATIENT_REACT_HEALTH_SUCCESSFUL',
  ONBOARD_PATIENT_REACT_HEALTH_FAILED: 'ONBOARD_PATIENT_REACT_HEALTH_FAILED',
};

export function fetchReactHealthPatient_ac(acct) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_REACT_HEALTH_PATIENT_PENDING });
    return window.sch
      .post(`/api/react_health/get_patient`, {
        acct,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.FETCH_REACT_HEALTH_PATIENT_SUCCESSFUL,
          data: res.patient,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_REACT_HEALTH_PATIENT_FAILED,
          error,
        });
      });
  };
}

export function flushReactHealthPatient_ac() {
  return {
    type: actionTypes.FLUSH_REACT_HEALTH_PATIENT,
  };
}

export function flushReactHealthProviders_ac() {
  return {
    type: actionTypes.FLUSH_REACT_HEALTH_PROVIDERS,
  };
}

export function fetchReactHealthClinicians_ac() {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_REACT_HEALTH_CLINICIANS_PENDING });
    return window.sch
      .post(`/api/react_health/list_clinicians`, {})
      .then((res) => {
        dispatch({
          type: actionTypes.FETCH_REACT_HEALTH_CLINICIANS_SUCCESSFUL,
          data: Boolean(res.results) ? res.results : [],
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_REACT_HEALTH_CLINICIANS_FAILED,
          error,
        });
      });
  };
}

export function fetchReactHealthProviders_ac(npi) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FIND_REACT_HEALTH_PROVIDERS_PENDING });
    return window.sch
      .post(`/api/react_health/search_providers`, {
        npi,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.FIND_REACT_HEALTH_PROVIDERS_SUCCESSFUL,
          data: res.results || [],
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FIND_REACT_HEALTH_PROVIDERS_FAILED,
          error,
        });
      });
  };
}

export function onboardPatientReactHealth_ac(
  data,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ONBOARD_PATIENT_REACT_HEALTH_PENDING });
    return window.sch
      .post(`/api/react_health/onboard_patient`, data)
      .then((res) => {
        if (!res.success) {
          dispatch({
            type: actionTypes.ONBOARD_PATIENT_REACT_HEALTH_FAILED,
            error: res.error,
          });

          if (onError) {
            onError(res.messages || []);
          }

          return;
        }
        dispatch({
          type: actionTypes.ONBOARD_PATIENT_REACT_HEALTH_SUCCESSFUL,
          data: res.result,
        });

        if (onSuccess) {
          onSuccess(res.data);
        }
      })

      .catch((error) => {
        dispatch({
          type: actionTypes.ONBOARD_PATIENT_REACT_HEALTH_FAILED,
          error,
        });

        if (onError) {
          onError(error);
        }
      });
  };
}

export function downloadReactHealthReport_ac(
  ptId,
  params = {},
  { onSuccess, onError }
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_REACT_HEALTH_PT_REPORT_PENDING });

    return window.sch
      .fetchFileBlob(`/api/react_health/pt_report`, {
        rh_patient_id: ptId,
        ...params,
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob, {
          type: 'application/pdf',
        });
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ReactHealthReport-${ptId}.pdf`);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();

        if (onSuccess) {
          onSuccess();
        }

        dispatch({ type: actionTypes.FETCH_REACT_HEALTH_PT_REPORT_SUCCESSFUL });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_REACT_HEALTH_PT_REPORT_FAILED,
          error,
        });

        if (onError) {
          onError();
        }

        return error;
      });
  };
}
