import _ from 'lodash';

export const actionTypes = {
  FETCHING_IDX_ADMIN_LOCATIONS: 'FETCHING_IDX_ADMIN_LOCATIONS',
  RECEIVED_IDX_ADMIN_LOCATIONS: 'RECEIVED_IDX_ADMIN_LOCATIONS',
  FAILED_FETCHING_IDX_ADMIN_LOCATIONS: 'FAILED_FETCHING_IDX_ADMIN_LOCATIONS',

  SET_SELECTED_LOCATION_ID: 'SET_SELECTED_LOCATION_ID',

  FETCHING_IDX_ADMIN_LOCATION_DETAILS: 'FETCHING_IDX_ADMIN_LOCATION_DETAILS',
  RECEIVED_IDX_ADMIN_LOCATION_DETAILS: 'RECEIVED_IDX_ADMIN_LOCATION_DETAILS',
  FAILED_FETCHING_IDX_ADMIN_LOCATION_DETAILS:
    'FAILED_FETCHING_IDX_ADMIN_LOCATION_DETAILS',

  SAVE_LOCATION_SEARCH_FILTERS: 'SAVE_LOCATION_SEARCH_FILTERS',
  SAVE_LOCATION_SORTING: 'SAVE_LOCATION_SORTING'
};

export const fetchIdxAdminLocations_ac = (filterData = null, page = 0) => {
  return (dispatch, getState) => {
    let params = filterData;

    const sorting = { ...getState().idxAdminLocationSorting };

    if (filterData === null) {
      params = {
        ...getState().idxAdminLocationSearchFilters
      };
    }

    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_LOCATIONS
    });

    window.sch
      .post('/api/idx/admin/locations/search', { ...params, ...sorting, page })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_LOCATIONS,
          locations: res.pkg
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_LOCATIONS,
          error: error
        });
      });
  };
};

export const setSelectedLocationId_ac = locationId => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_SELECTED_LOCATION_ID,
      locationId: locationId || null
    });
  };
};

export const fetchLocationDetials_ac = locationId => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_LOCATION_DETAILS
    });

    window.sch
      .post('/api/idx/admin/locations/detail', { _id: locationId })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_LOCATION_DETAILS,
          location: res.loc,
          existingRelations: res.existing_rels
        });
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_LOCATION_DETAILS,
          error
        });
      });
  };
};

export const updateLocationDetailsLocally_ac = updateObj => {
  return (dispatch, getState) => {
    if (!updateObj || !_.isObject(updateObj)) return;

    let locationDetials = getState().idxAdminLocationDetails;

    if (!locationDetials.location) return;

    dispatch({
      type: actionTypes.RECEIVED_IDX_ADMIN_LOCATION_DETAILS,
      location: {
        ...locationDetials.location,
        ...updateObj
      }
    });
  };
};

export const updateLocationsListLocally_ac = (locationData, remove = false) => {
  return (dispatch, getState) => {
    if (!locationData || !_.isObject(locationData)) return;

    let locationsListState = getState().idxAdminLocations;

    if (!locationsListState.locations) return;

    let locationIdx = locationsListState.locations.rr.findIndex(
      loc => loc._id === locationData._id
    );

    if (locationIdx < 0) return;

    let updatedList;

    if (remove) {
      updatedList = [
        ...locationsListState.locations.rr.slice(0, locationIdx),
        ...locationsListState.locations.rr.slice(locationIdx + 1)
      ];
    } else {
      updatedList = [
        ...locationsListState.locations.rr.slice(0, locationIdx),
        {
          ...locationsListState.locations.rr[locationIdx],
          ...locationData
        },
        ...locationsListState.locations.rr.slice(locationIdx + 1)
      ];
    }

    dispatch({
      type: actionTypes.RECEIVED_IDX_ADMIN_LOCATIONS,
      locations: {
        ...locationsListState.locations,
        rr: updatedList
      }
    });
  };
};

export const saveLocationSearchFilters_ac = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_LOCATION_SEARCH_FILTERS,
      filterData
    });
  };
};

export const saveLocationSorting = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_LOCATION_SORTING,
      filterData
    });
  };
};
