import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { FormModal } from '../common';
import GenericUserSearch from '../common/GenericUserSearch';

const styles = () => ({
  coreForm: {
    width: '100%',
  },
});

const MailboxEmailPatchModal = (props) => {
  const {
    classes,
    open = true,
    disabled = false,
    email,
    onClose,
    onSubmit,
  } = props;

  const [processing, setProcessing] = useState(false);
  const [changed, setChanged] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const handleCancel = () => {
    onClose();
  };

  return (
    <FormModal
      isModalOpen={open}
      disableBackdropClick={changed}
      onClose={handleCancel}
      closeOnSubmit={false}
      disabled={disabled || processing || !selectedUser}
      wrapperStyles={{
        width: '40%',
        maxWidth: '600px',
      }}
      submitLabel={'Submit'}
      onSubmit={() => {
        setProcessing(true);
        onSubmit(selectedUser?.mail, () => {
          setProcessing(false);
          onClose();
        });
      }}
      title={'Change contact email!'}>
      <div className={classes.coreForm}>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            fontSize: '14px',
            color: 'blue',
            textAlign: 'left',
            width: '100%',
            marginBottom: '4px',
            opacity: selectedUser ? 0.6 : 1,
          }}>
          {`Current email: ${email || 'Unset'}`}
        </Typography>

        {selectedUser && (
          <Typography
            variant="caption"
            color="textSecondary"
            style={{
              fontSize: '14px',
              color: 'green',
              textAlign: 'left',
              width: '100%',
              marginBottom: '12px',
            }}>
            {`New email: ${selectedUser.mail || 'Invalid'}`}
          </Typography>
        )}

        <GenericUserSearch
          label={null}
          fullWidth={true}
          autoFocus={true}
          disabled={disabled || processing}
          placeholder={
            selectedUser ? selectedUser.nm : `Search for a user here!`
          }
          onUserSelect={(user) => {
            setSelectedUser(user);
            setChanged(true);
          }}
          baseParams={{}}
        />
      </div>
    </FormModal>
  );
};

export default withStyles(styles)(MailboxEmailPatchModal);
