export async function readFileAsBlob(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.onerror = err => {
      reject(err)
    }
    reader.readAsDataURL(file)
  })
}

export async function uploadToS3(url, blob, type) {
  const res = fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': type || 'image/png'
    },
    body: blob
  })

  let result = { success: true }
  try {
    result = await res.json()
  } catch (err) {
    console.log(err)
  }

  return result
}
