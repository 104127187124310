import { Button, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchActivePatients_ac } from '../../actions/active_patients.ac';
import useDebounce from '../../hooks/useDebounce';
import PaginationWithReset from '../IndexingAdmin/common/PaginationWithReset';
import { Accordion } from '../common';
import CardList from '../common/CardList';
import CustomTable from '../common/CustomTable';
import Filters from './Filters';
import columns from './columns';

const styles = () => ({
  container: {
    backgroundColor: '#f5f5f5',
    margin: '-4px -16px',
    padding: 16,
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  sort: {
    marginBottom: 2,
    fontWeight: 'bold',
  },
});

const SORT_ORDER_LABEL = {
  nm: 'Patient name',
  account: 'Account#',
  last_hdms_wo_date: 'Date of last HDMS work order',
  // email: 'Email',
  // phone: 'Phone',
};

const defaultSorting = {
  nm: 1,
};

// Last 3 months
const defaultFilters = {
  start_date: moment().subtract(3, 'month').toDate(),
  end_date: moment().toDate(),
};

const normalizeFilters = (filters) => {
  const clean = { ...filters };

  if ([null, undefined, ''].includes(clean.grouping)) {
    delete clean.grouping;
  } else {
    clean.grouping = _.trim(clean.grouping);
  }

  if ([null, undefined, '', 0].includes(clean.acct_start)) {
    delete clean.acct_start;
  } else {
    clean.acct_start = Number(clean.acct_start);
  }

  if ([null, undefined, '', 0].includes(clean.acct_end)) {
    delete clean.acct_end;
  } else {
    clean.acct_end = Number(clean.acct_end);
  }

  clean.start_date = moment(clean.start_date).format('YYYY-MM-DD');
  clean.end_date = moment(clean.end_date).format('YYYY-MM-DD');

  return clean;
};

const ActivePatients = (props) => {
  const { cardView = false, classes, activePatients } = props;

  const listSrc = activePatients;

  const records = listSrc?.results || [];
  const pagination = listSrc?.pagination || {};

  const [sortData, setSortData] = useState(null);
  const [toGoPage, setToGoPage] = useState(false);
  const [filterData, setFilterData] = useState(defaultFilters);
  const [searchLocked, setSearchLocked] = useState(true);

  const emptyMessage = listSrc?.error
    ? 'Error loading active patients!'
    : searchLocked
    ? 'Please click REFRESH LISTING to load the list.'
    : !records.length
    ? 'No patients match the filter.'
    : 'Please click REFRESH LISTING to load the list.';

  const submitFilter = (filters, ext) => {
    if (listSrc?.isFetching) return;

    if (searchLocked) {
      setSearchLocked(false);
    }

    setToGoPage(false);
    props.fetchActivePatients_ac({
      ...normalizeFilters(filters || filterData),
      has_email: true,
      sort: sortData || defaultSorting,
      pageIndex: 0,
      ...(ext || {}), // for pagination
    });
  };

  const handlePageChange = (newPage) => {
    if (listSrc?.isFetching) {
      return;
    }

    setToGoPage(newPage - 1);
  };

  const handleResetSorting = () => {
    setSortData(defaultSorting);
  };

  const handleClearAllSorting = () => {
    setSortData({});
  };

  const sortOrder = useMemo(() => {
    const sort = Object.entries(sortData || defaultSorting)
      .map(([key, val]) =>
        [SORT_ORDER_LABEL[key] || key, val === 1 ? 'asc' : 'desc'].join(':')
      )
      .join(',');

    return sort;

    // eslint-disable-next-line
  }, [sortData, defaultSorting]);

  const [isExpanded, setIsExpanded] = React.useState(true);

  const hasRecords = Boolean(pagination?.totalRecords);

  const handleClearFilters = () => {
    setFilterData(defaultFilters);

    submitFilter(defaultFilters);
  };

  useDebounce(
    () => {
      if (listSrc?.isFetching) return;

      if (searchLocked) {
        return;
      }

      if (sortData) {
        submitFilter();
      }
    },
    500,
    [sortData]
  );

  // pagination effect
  useEffect(() => {
    if (listSrc?.isFetching) return;
    if (searchLocked) return;
    if (_.isNumber(toGoPage)) {
      submitFilter(undefined, { pageIndex: toGoPage });
    }

    // eslint-disable-next-line
  }, [toGoPage]);

  const handleFilterChange = (event, name) => {
    if (event.target) {
      const { name, value } = event.target;

      setFilterData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    if (!event.target) {
      setFilterData((prev) => ({
        ...prev,
        [name]: event,
      }));
    }
  };

  const handleRowClick = (account, e) => {
    let path = `/redirect_to_patient/${account}/account_info`;
    if (e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      window.open(path, '_blank');
      return;
    }
    props.history.push(path);
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Accordion
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          includeHeaderClick={true}
          header={
            <span
              style={{
                fontWeight: 'normal',
                fontSize: '14px',
              }}>
              {'Filters:'}
            </span>
          }>
          <Filters
            disabled={listSrc?.isFetching || false}
            filterData={filterData}
            onFilterChange={handleFilterChange}
            onFilterSubmit={() => {
              submitFilter();
            }}
            handleClearFilters={handleClearFilters}
            hideApplyFilterButton={false}
          />
        </Accordion>
        <PaginationWithReset
          sortSupport={!cardView} // For now
          currPage={toGoPage}
          disabled={!hasRecords}
          data={hasRecords && pagination}
          isFetching={listSrc?.isFetching || false}
          handleResetSorting={handleResetSorting}
          handleClearAllSorting={handleClearAllSorting}
          handlePageChange={handlePageChange}>
          <Grid item>
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={listSrc?.isFetching}
              onClick={() => {
                submitFilter();
              }}>
              REFRESH LISTING
            </Button>
          </Grid>
        </PaginationWithReset>
        {!cardView && (
          <Typography className={classes.sort} color="textSecondary">
            Sort Order:{sortOrder}
          </Typography>
        )}
        <div
          style={{ height: isExpanded ? '76vh' : cardView ? '80vh' : '94vh' }}>
          {cardView ? (
            <CardList
              columns={columns}
              idKey={'idn'}
              data={records}
              isLoading={listSrc?.isFetching}
              error={{ isError: listSrc?.error, message: emptyMessage }}
              onView={handleRowClick}
            />
          ) : (
            <CustomTable
              columns={columns}
              data={records}
              idKey={'account'}
              isLoading={listSrc?.isFetching}
              error={{ isError: listSrc?.error, message: emptyMessage }}
              sortData={sortData}
              defaultSortData={defaultSorting}
              setSortData={(cb) => setSortData(cb)}
              onRowClick={handleRowClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activePatients: state.activePatients,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchActivePatients_ac,
      changePage: (path) => {
        return push(path);
      },
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ActivePatients));
