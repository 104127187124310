import { actionTypes } from '../actions/idx_admin_divider_mapping.ac'

const initialState = {
  error: null,
  dividerMapping: null,
  isFetching: false
}

export const idxAdminDividerMapping = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ADMIN_DIVIDER_MAPPING:
      return {
        ...state,
        isFetching: true
      }

    case actionTypes.RECEIVED_IDX_ADMIN_DIVIDER_MAPPING:
      return {
        ...state,
        error: null,
        isFetching: false,
        dividerMapping: action.data
      }

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_DIVIDER_MAPPING:
      return {
        ...state,
        error: true,
        dividerMapping: null,
        isFetching: false
      }

    default:
      return state
  }
}
