export function toggleCatalogDiscontinued_ac(kind, params) {
  return (dispatch, getState) => {
    dispatch({
      type: 'TOGGLE_CAT_DISCONTINUED',
      includeDiscontinued: !getState().catalog.includeDiscontinued
    })
  }
}
export function configureCatalogue_ac(params, { onError, onSuccess }) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CONFIGURE_CATALOGUE_PENDING'
    })

    let target_url = `/api/catalog/admin/configure_item`
    return window.sch
      .post(target_url, params) // params = { _id, kind, value }
      .then(result => {
        let _pkg = getState().catalog.pkg
        let idx = _pkg.items.findIndex(c => c._id === params._id)
        const configAddition =
          params.kind === 'config'
            ? params.value
            : {
                [params.kind]: params.value
              }
        _pkg.items[idx] = {
          ..._pkg.items[idx],
          config: {
            ...(_pkg.items[idx] && _pkg.items[idx].config),
            ...configAddition
          }
        }
        if (onSuccess) {
          onSuccess(result)
        }
        dispatch({
          type: 'CONFIGURE_CATALOGUE_SUCCESS',
          pkg: _pkg
        })
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
        dispatch({ type: 'CONFIGURE_CATALOGUE_ERROR', error })
      })
  }
}
export function fetchCatalog_ac(kind, params) {
  return (dispatch, getState) => {
    switch (kind) {
      case 'cat': {
        //fetch categories
        dispatch({ type: 'REQUEST_CATALOG' })
        let target_url = `/api/catalog/cat/list`
        return window.sch
          .post(target_url, params)
          .then(result => {
            dispatch({
              type: 'RECEIVED_CATALOG',
              pkg: result.pkg
            })
          })
          .catch(error => {
            console.error('error:', error)
            dispatch({ type: 'FAILED_RECEIVED_CATALOG', error })
          })
      }
      case 'mfg': {
        //fetch mfg
        let _pkg = getState().catalog.pkg
        let idx = _pkg.categories.findIndex(c => c.cat_id === params.cat_id)
        if (_pkg.categories[idx].mfgs) {
          return
        }

        let target_url = `/api/catalog/mfg/list`
        return window.sch
          .post(target_url, params)
          .then(result => {
            _pkg.categories[idx].mfgs = result.mfgs

            dispatch({
              type: 'RECEIVED_CATALOG',
              pkg: _pkg
            })
          })
          .catch(error => {
            console.error('error:', error)
            dispatch({ type: 'FAILED_RECEIVED_CATALOG', error })
          })
      }
      case 'items': {
        //fetch mfg
        dispatch({ type: 'REQUEST_CATALOG_ITEMS', lastParams: { ...params } })
        let _pkg = getState().catalog.pkg
        let target_url = `/api/catalog/items/list`
        if (params.is_macro) {
          target_url = `/api/catalog/macroitems/list`
        }

        return window.sch
          .post(target_url, params)
          .then(result => {
            _pkg.items = result.items
            _pkg.pagination = result.pagination
            dispatch({
              type: 'RECEIVED_CATALOG',
              lastMethod: params.lastMethod,
              pkg: _pkg
            })
          })
          .catch(error => {
            console.error('error:', error)
            dispatch({
              type: 'FAILED_RECEIVED_CATALOG',
              error
            })
          })
      }
      default:
        break
    }
  }
}
