import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';

import { Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import {
  fetchQueues_ac,
  removeQueue_ac,
  addQueue_ac,
  updateQueue_ac,
  fetchQueueGroups_ac,
  removeQueueGroup_ac,
  addQueueGroup_ac,
  updateQueueGroup_ac
} from '../../actions/connect_holds.ac';
import Loader from '../Shared/Loader';
import { getErrorMessage } from '../../util/string';
import { open_snack_ac } from '../../actions/snack.ac';
import {
  close_confirmation_dialog_ac,
  open_confirmation_dialog_ac
} from '../../actions/dialog.ac.js';

import { QueueList, EditGroup, EditQueue } from './components';
import { justId } from '../../util/object';

const styles = () => ({
  main: {},
  fullScopeCard: {
    width: '70%',
    marginLeft: '10px',
    backgroundColor: '#fff',
    boxShadow: ' 0px 2px 4px rgba(179, 179, 179, 0.25)',
    borderRadius: '4px',
    margin: '30px 0',
    position: 'relative'
  },
  cardHeader: {
    padding: '12px',
    borderBottom: '2px solid lightgrey'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  fallbackInfo: {
    width: '100%',
    textAlign: 'center',
    margin: 'auto',
    color: '#b3b3b3',
    fontWeight: '600',
    marginTop: '100px'
  },
  queueRow: {},
  addButton: {
    position: 'relative',
    background: 'white',
    color: '#29b6f6',
    border: '2px solid #29b6f6',
    boxShadow: 'none',
    transition: '0.1s ease',
    padding: '0px',

    '&.active': {
      background: '#29b6f6',
      color: 'white'
    },

    '&:hover.active': {
      backgroundColor: '#29b6f6'
    },
    '&:hover:not(.active)': {
      backgroundColor: 'rgba(255, 145, 0, 0.08)'
    }
  },
  queueNode: {
    position: 'relative',
    margin: '6px 6px 6px 0px',
    display: 'inline-block'
  },
  queueItem: {
    background: '#f6f6f6',
    borderRadius: '91px',
    fontSize: '12px',
    fontWeight: 'bold',
    padding: '6px 12px',
    letterSpacing: '0.5px',
    cursor: 'pointer',
    color: '#737373',
    whiteSpace: 'nowrap',
    transition: '0.1s ease',

    '&:hover:not(.queueItemActive)': {
      color: ' #29b6f6'
    },

    '&.queueItemActive': {
      background: '#29b6f6',
      color: 'white'
    }
  }
});

const Queues = props => {
  const { classes, connectHolds, ...rest } = props;

  const {
    queues: { isFetching: isFetchingQueues, data: queues },
    queueGroups: { isFetching: isFetchingQueueGroups, data: queueGroups },
    ...loadingState
  } = connectHolds;

  const [activeQueue, setActiveQueue] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);

  const handleQueueCancel = () => {
    setActiveQueue(null);
  };

  const handleGroupCancel = () => {
    setActiveGroup(null);
  };

  const handleQueueEdit = (queue, group = null) => {
    if (activeGroup) {
      handleGroupCancel();
    }

    if (queue) {
      setActiveQueue(queue);
    } else {
      const newQueue = {
        name: '',
        ARN: '',
        group: justId(group)
      };

      setActiveQueue(newQueue);
    }
  };

  const handleGroupEdit = group => {
    if (activeQueue) {
      handleQueueCancel();
    }

    if (group) {
      setActiveGroup(group);
    } else {
      const newGroup = {
        name: '',
        description: '',
        config: {}
      };
      setActiveGroup(newGroup);
    }
  };

  const handleQueueSave = (queue = {}) => {
    const isUpdating = Boolean(queue._id);

    const postData = { ...queue, group: justId(queue.group) };
    props[isUpdating ? 'updateQueue' : 'addQueue'](postData, {
      onSuccess: queue => {
        handleQueueEdit(queue);
        props.openSnack({
          variant: 'success',
          message: `Queue: ${queue.name} ${
            isUpdating ? 'updated' : 'added'
          } successfully!`
        });
      },
      onError: err => {
        props.openSnack({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to  ${isUpdating ? 'update' : 'add'} the Queue: ${
              queue.name
            }!`
          )
        });
      }
    });
  };

  const handleQueueDelete = queue => {
    const postData = justId(queue);
    props.removeQueue(postData, {
      onSuccess: () => {
        handleQueueCancel();

        props.openSnack({
          variant: 'success',
          message: `Queue: ${queue.name} removed successfully!`
        });
      },
      onError: err => {
        props.openSnack({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to remove the Queue: ${queue.name}!`
          )
        });
      }
    });
  };

  const handleGroupSave = (group = {}) => {
    const isUpdating = Boolean(group._id);

    const postData = {
      ...group,
      description: Boolean(group.description) ? group.description : undefined
    };

    props[isUpdating ? 'updateQueueGroup' : 'addQueueGroup'](postData, {
      onSuccess: group => {
        handleGroupEdit(group);
        props.openSnack({
          variant: 'success',
          message: `Group: ${group.name} ${
            isUpdating ? 'updated' : 'added'
          } successfully!`
        });
      },
      onError: err => {
        props.openSnack({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to  ${isUpdating ? 'update' : 'add'} the Group: ${
              group.name
            }!`
          )
        });
      }
    });
  };

  const handleGroupDelete = group => {
    props.removeQueueGroup(group, {
      onSuccess: () => {
        handleGroupCancel();

        props.openSnack({
          variant: 'success',
          message: `Group: ${group.name} removed successfully!`
        });
      },
      onError: err => {
        props.openSnack({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to remove the Group: ${group.name}!`
          )
        });
      }
    });
  };

  useEffect(() => {
    Promise.all([props.fetchQueues(), props.fetchQueueGroups()]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isFetchingQueues || isFetchingQueueGroups;

  return (
    <div className={classes['main']}>
      <div className={classes['container']}>
        <div className={classes['fullScopeCard']}>
          <Grid container className={classes['cardHeader']}>
            <Grid item xs={2} className={classes['flexRow']}>
              <span style={{ fontSize: '18px' }}>Queues</span>
            </Grid>

            <Grid
              item
              xs={10}
              className={classes['flexRow']}
              style={{ justifyContent: 'right' }}
            >
              <Button
                disabled={false}
                variant="outlined"
                size="small"
                color="primary"
                onClick={() => handleGroupEdit()}
              >
                Add group
              </Button>
              <Button
                disabled={false}
                variant="outlined"
                size="small"
                color="primary"
                style={{ marginLeft: '8px' }}
                onClick={() => handleQueueEdit()}
              >
                Add Queue
              </Button>
            </Grid>
          </Grid>
          <Grid container style={{ minHeight: '300px' }}>
            {isLoading ? (
              <Loader type="circular" />
            ) : (
              <>
                <Grid
                  item
                  xs={8}
                  style={{ borderRight: '2px solid lightgrey' }}
                >
                  <QueueList
                    classes={classes}
                    queueGroups={queueGroups}
                    queues={queues}
                    activeQueue={activeQueue}
                    onGroupClick={handleGroupEdit}
                    onQueueClick={handleQueueEdit}
                    onQueueAdd={handleQueueEdit}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: '20px' }}>
                  {activeQueue && (
                    <EditQueue
                      classes={classes}
                      queue={activeQueue}
                      groups={queueGroups}
                      onSave={handleQueueSave}
                      onDelete={handleQueueDelete}
                      onCancel={handleQueueCancel}
                      saving={
                        loadingState.isAddingQueue ||
                        loadingState.isUpdatingQueue
                      }
                      deleting={loadingState.isRemovingQueue}
                      {...rest}
                    />
                  )}
                  {activeGroup && (
                    <EditGroup
                      classes={classes}
                      group={activeGroup}
                      onSave={handleGroupSave}
                      onDelete={handleGroupDelete}
                      onCancel={handleGroupCancel}
                      saving={
                        loadingState.isAddingQueueGroup ||
                        loadingState.isUpdatingQueueGroup
                      }
                      deleting={loadingState.isRemovingQueueGroup}
                      {...rest}
                    />
                  )}
                  {!activeQueue && !activeGroup && (
                    <div className={classes['fallbackInfo']}>
                      {'Click on any of the queue to view details here'}
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  me: state.me,
  connectHolds: state.connectHolds
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      redirect: push,
      fetchQueues: fetchQueues_ac,
      removeQueue: removeQueue_ac,
      addQueue: addQueue_ac,
      updateQueue: updateQueue_ac,
      fetchQueueGroups: fetchQueueGroups_ac,
      removeQueueGroup: removeQueueGroup_ac,
      addQueueGroup: addQueueGroup_ac,
      updateQueueGroup: updateQueueGroup_ac,
      openSnack: open_snack_ac,
      openConfirmationDialog: open_confirmation_dialog_ac,
      closeConfirmationDialog: close_confirmation_dialog_ac
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Queues))
);
