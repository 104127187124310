import TextField from '@material-ui/core/TextField'
import React from 'react'
import Autosuggest from 'react-autosuggest'

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.nm

// Use your imagination to render suggestions.

class HDMSLookupSearch extends React.Component {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/stub/search`, //replace this
      value: '',
      suggestions: []
    }
    this.renderInputComponent = this.renderInputComponent.bind(this)
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
  }
  renderSuggestion = s => {
    return <div>{s.name || 'field name?'}</div>
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
  }

  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    if (this.props.onSelect) {
      this.props.onSelect(suggestion)
    }
    this.setState({ value: '' })
  }

  onSuggestionsFetchRequested = ({ value }) => {
    if (value.trim().length < 2) return

    if (this.timer) {
      clearTimeout(this.timer)
    }

    let searchStr = value.trim()

    let page = false
    let pg_ind_pos = searchStr.search(/:[0-9]{1,5}$/)
    if (pg_ind_pos > -1) {
      page = parseInt(searchStr.substr(pg_ind_pos + 1), 10)
      searchStr = searchStr.substr(0, pg_ind_pos)
    }

    let postData = {
      searchStr,
      discontinued: this.props.includeDiscontinued || false,
      page
    }

    if (this.props.extraParams) {
      postData = {
        ...postData,
        ...this.props.extraParams
      }
    }

    this.timer = setTimeout(() => {
      return window.sch
        .post(this.state.apiEndpoint, postData)
        .then(result => {
          if (result.error) {
            throw new Error(result.error)
          }
          this.setState({
            suggestions: result.rr.slice(0, 50)
          })
        })
        .catch(error => {
          console.error(error)
          this.setState({
            suggestions: []
          })
        })
    }, 800)
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  renderInputComponent(inputProps) {
    return (
      <TextField {...inputProps} label={this.props.label || 'Search for...'} />
    )
  }

  shouldRenderSuggestions(value) {
    return value.trim().length > 2
  }

  render() {
    const { value, suggestions } = this.state

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange
    }

    // Finally, render it!
    return (
      <Autosuggest
        theme={{
          input: { width: '100%', fontSize: 12, padding: 3 },
          suggestionsContainer: {
            backgroundColor: 'rgba(218, 226, 226, 0.54)'
          },
          suggestionsList: { paddingLeft: 16, marginTop: 1 },
          suggestion: {
            listStyleType: 'none',
            padding: '4px 4px',
            fontSize: 12,
            marginBottom: 2,
            cursor: 'pointer'
          }
        }}
        shouldRenderSuggestions={this.shouldRenderSuggestions}
        suggestions={suggestions}
        focusInputOnSuggestionClick={false}
        highlightFirstSuggestion
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
      />
    )
  }
}

class DoctorSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/doctors/search`, //replace this
      value: '',
      suggestions: []
    }
  }

  renderSuggestion = s => {
    if (s.disabled) {
      return (
        <div style={{ color: 'red' }}>
          <div>
            ID:{s.id} {s.last}, {s.first}
            <span style={{ fontWeight: 'bold' }}>
              NPI: {s.npi === '0000000000' ? 'NONE' : s.npi}
            </span>
          </div>
          <div style={{ fontSize: '.88em' }}>DISCONTINUED</div>
          <div style={{ fontSize: '.88em' }}>
            {s.addr_1}, {s.city} {s.zip}
          </div>
          <div style={{ fontSize: '.88em' }}>
            Ph:{s.ph} Fax:{s.fax}
          </div>
          <div style={{ fontSize: '.88em' }}>Sales: {s.salesperson}</div>
        </div>
      )
    }
    return (
      <div>
        <div>
          {s.id} {s.last}, {s.first}{' '}
          <span style={{ fontWeight: 'bold' }}>
            NPI: {s.npi === '0000000000' ? 'NONE' : s.npi}
          </span>
        </div>
        <div style={{ fontSize: '.88em' }}>
          {s.addr_1}, {s.city} {s.zip}
        </div>
        <div style={{ fontSize: '.88em' }}>
          Ph:{s.ph} Fax:{s.fax}
        </div>
        {s.salesperson && (
          <div style={{ fontSize: '.88em' }}>Sales: {s.salesperson}</div>
        )}
      </div>
    )
  }
}

class PayerSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/payers/search`, //replace this
      value: '',
      suggestions: []
    }
  }

  renderSuggestion = s => {
    return (
      <div>
        <div>
          {s.name} {s.id}
        </div>
        <div style={{ fontSize: '.88em' }}>PayerType: {s.payertypename}</div>
        <div style={{ fontSize: '.88em' }}>
          {s.addr_1}, {s.city} {s.zip}
        </div>
      </div>
    )
  }
}

class MCOPlanSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/mcoplans/search`, //replace this
      value: '',
      suggestions: []
    }
  }
  renderSuggestion = s => {
    return (
      <div>
        <div>{s.name}</div>
        <div style={{ fontSize: '.88em' }}>
          {s.addr_1}, {s.city} {s.zip}
        </div>
      </div>
    )
  }
}

class MCOMedGroupSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/mcomedgroups/search`, //replace this
      value: '',
      suggestions: []
    }
  }
}

class ProviderSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/providers/search`, //replace this
      value: '',
      suggestions: []
    }
  }
}

class SalespersonSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/salespersons/search`, //replace this
      value: '',
      suggestions: []
    }
  }
}

class DiagnosisSearch extends HDMSLookupSearch {
  constructor() {
    super()
    this.state = {
      apiEndpoint: `/api/hdms/diagnosis/search`,
      value: '',
      suggestions: []
    }
  }
  renderSuggestion = s => {
    return (
      <div>
        <div>
          {s.code}
          {s.icd10 && (
            <span style={{ color: 'blue', paddingLeft: 10, fontSize: '.88em' }}>
              ICD10
            </span>
          )}
        </div>
        <div style={{ fontSize: '.88em' }}>{s.description}</div>
      </div>
    )
  }
}

export {
  DiagnosisSearch,
  SalespersonSearch,
  PayerSearch,
  DoctorSearch,
  MCOPlanSearch,
  MCOMedGroupSearch,
  ProviderSearch
}
