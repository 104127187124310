import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

const useStyles = makeStyles(theme => ({
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const Pagination = props => {
  const { pageCount, page, handlePrevious, handleNext, disabled } = props
  const classes = useStyles()
  const isFirst = page === 0
  const isLast = page === pageCount - 1
  return (
    <div className={classes.pagination}>
      <Typography variant="body1">{`Page: ${page + 1} of ${pageCount ||
        1}`}</Typography>
      <IconButton disabled={disabled || isFirst} onClick={handlePrevious}>
        <ChevronLeftIcon />
      </IconButton>
      <IconButton disabled={disabled || isLast} onClick={handleNext}>
        <ChevronRightIcon />
      </IconButton>
    </div>
  )
}

export default Pagination
