import React, { useState } from 'react';
import { Typography, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { FormModal } from '../common';
import GenericUserSearch from '../common/GenericUserSearch';

const styles = () => ({
  coreForm: {
    width: '100%',
  },
});

const MailboxNotifyCCPatchModal = (props) => {
  const {
    classes,
    open = true,
    disabled = false,
    emails,
    onClose,
    onSubmit,
  } = props;

  const [processing, setProcessing] = useState(false);
  const [changed, setChanged] = useState(false);

  const [selectedEmails, setSelectedEmails] = useState(emails || []);

  const handleCancel = () => {
    onClose();
  };

  return (
    <FormModal
      isModalOpen={open}
      disableBackdropClick={changed}
      onClose={handleCancel}
      closeOnSubmit={false}
      disabled={disabled || processing}
      wrapperStyles={{
        width: '60%',
      }}
      submitLabel={'Submit'}
      onSubmit={() => {
        setProcessing(true);
        onSubmit(selectedEmails, () => {
          setProcessing(false);
          onClose();
        });
      }}
      title={'Change Notify CC emails'}>
      <div className={classes.coreForm}>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            fontSize: '14px',
            color: 'blue',
            textAlign: 'left',
            width: '100%',
            marginBottom: '8px',
          }}>
          {`Selected emails:`}
        </Typography>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '12px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '4px',
          }}>
          {selectedEmails.length ? (
            selectedEmails.map((e, i) => (
              <Chip
                key={i}
                label={e}
                style={{
                  margin: '4px',
                }}
                onDelete={() => {
                  setChanged(true);
                  setSelectedEmails(selectedEmails.filter((se) => se !== e));
                }}
              />
            ))
          ) : (
            <Typography
              style={{
                fontSize: '10px',
                color: 'grey',
                textAlign: 'center',
                width: '100%',
                paddingTop: '12px',
                paddingBottom: '12px',
              }}>
              No emails added!
            </Typography>
          )}
        </div>

        <GenericUserSearch
          label={null}
          fullWidth={true}
          autoFocus={true}
          disabled={disabled || processing}
          placeholder={`Add emails by searching for users here...`}
          onUserSelect={(user) => {
            if (user) {
              setChanged(true);
              setSelectedEmails([...selectedEmails, user.mail]);
            }
          }}
          baseParams={{}}
        />
      </div>
    </FormModal>
  );
};

export default withStyles(styles)(MailboxNotifyCCPatchModal);
