import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import { WorkOrderDetails } from './WorkOrderDetails'
import { WorkOrdersReport } from './WorkOrdersReport'

const styles = theme => ({
  tableRow: {
    fontSize: 12
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  fullHeight: {
    height: '100%'
  }
})

class WorkOrdersReportsLanding extends React.Component {
  componentDidMount() {
    this.props.setTitle_ac('Work Orders')
    this.init()
  }

  componentDidUpdate() {
    this.init()
  }

  init = () => {
    const { me, lookups, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }
  }

  render() {
    const { classes } = this.props
    return (
      <main className={classes.content}>
        <Grid
          container
          direction="row"
          className={classes.fullHeight}
          spacing={8}>
          <Grid item xs={8} className={classes.fullHeight}>
            <WorkOrdersReport />
          </Grid>
          <Grid item xs={4} className={classes.fullHeight}>
            <WorkOrderDetails />
          </Grid>
        </Grid>
      </main>
    )
  }
}

WorkOrdersReportsLanding.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(WorkOrdersReportsLanding))
)
