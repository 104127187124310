import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { PaginationWithNums } from '../PaginationNew'
import ExpandablePatientLetterRow from './ExpandablePatientLetterRow'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit * 0.5,
    overflowY: 'auto'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  },
  pagination: {
    padding: 8,
    backgroundColor: '#00000005',
    borderBottom: '1px solid #00000030'
  }
})

const PatientLettersReportTable = props => {
  const { report, classes, fetch } = props
  const [allExpanded, setAllExpanded] = useState(false)
  const [selectedPageNum, setSelectedPageNum] = useState(0)

  const handlePageChange = newPage => {
    let targetPage = parseInt(newPage, 10)

    setSelectedPageNum(targetPage - 1)
    return fetch(targetPage - 1)
  }

  const getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  return (
    <div className={classes.content}>
      <div className={classes.pagination}>
        <Grid container spacing={16} alignItems="flex-end">
          <Grid item xs={12} sm={6}>
            <Typography
              inline
              color="textSecondary"
              onClick={() => setAllExpanded(!allExpanded)}
              style={{ cursor: 'pointer' }}>
              <strong>
                {allExpanded ? 'Collapse' : 'Expand'}
                &ensp;All&ensp;[+/-]
              </strong>
            </Typography>
            <Typography color="textSecondary">
              {getPageStatement(report.pagination)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {report.pagination.totalPagesAvailable > 1 && (
              <PaginationWithNums
                currentPageNum={selectedPageNum}
                totalPagesAvailable={report.pagination.totalPagesAvailable}
                onPageChange={handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      </div>

      <div className={classes.scrollArea}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell width="1%" />
              <TableCell width="20%" style={{ padding: 8 }}>
                Patient
              </TableCell>
              <TableCell width="20%" style={{ padding: 8 }}>
                Letter Status
              </TableCell>
              <TableCell width="15%" style={{ padding: 8 }}>
                User
              </TableCell>
              <TableCell width="32%" style={{ padding: 8 }}>
                Plan
              </TableCell>
              <TableCell width="12%" style={{ padding: 8 }}>
                Created on
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {report.records &&
              report.records.length > 0 &&
              report.records.map((record, idx) => (
                <ExpandablePatientLetterRow
                  key={idx}
                  rowIdx={idx}
                  record={record}
                  isExpandedDefault={allExpanded}
                />
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default withStyles(styles)(PatientLettersReportTable)
