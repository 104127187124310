import moment from 'moment';

/**
 * format number to currency
 * @param {number} num
 */
const formatCurrency = (num) => {
  return `$${(num || 0).toFixed(2)}`;
};

/**
 * prepare idn
 * @param {object} [record]
 */
export const toIdn = (record = {}) => {
  if (!(record.last_name && record.first_name && record.dob)) {
    return null;
  }
  let idnStr = [
    record.last_name + ',_',
    record.first_name + '_',
    moment(record.dob).format('M-D-YYYY'),
  ].join('');
  console.log('idnStr is >>', idnStr);
  return decodeURI(idnStr).toUpperCase();
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export default {
  formatCurrency,
  toIdn,
};
