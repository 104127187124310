export const actionTypes = {
  FETCHING_IDX_ADMIN_DIVIDER_MAPPING: 'FETCHING_IDX_ADMIN_DIVIDER_MAPPING',
  RECEIVED_IDX_ADMIN_DIVIDER_MAPPING: 'RECEIVED_IDX_ADMIN_DIVIDER_MAPPING',
  FAILED_FETCHING_IDX_ADMIN_DIVIDER_MAPPING:
    'FAILED_FETCHING_IDX_ADMIN_DIVIDER_MAPPING'
}

export const fetchIdxAdminDividerMapping_ac = (page = 0) => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_DIVIDER_MAPPING
    })

    window.sch
      .post('/api/idx/admin/div_mapping/list', { page })
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_DIVIDER_MAPPING,
          data: res.pkg
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_DIVIDER_MAPPING,
          error: error
        })
      })
  }
}
