import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ChatBubbleOutline, ExitToApp } from '@material-ui/icons';

import {
  fetchWorkorders_ac,
  saveWorkorderSearchFilters_ac,
  saveWorkorderSorting_ac,
} from '../../actions/workorders.ac';
import { dateFormat } from '../../util/date';
import { cleanObject } from '../../util/object';
import CustomTable from '../common/CustomTable';
import ReactSelect from '../common/ReactSelect';
import CustomSelect from '../common/CustomSelect';
import useDebounce from '../../hooks/useDebounce';
import SuperReactDatePicker from '../common/SuperReactDatePicker';
import { decodeWoDisplay, wo_display } from '../../util/deliveryTrack';
import PaginationWithReset from '../IndexingAdmin/common/PaginationWithReset';

const NoteChip = ({ text, icon: Icon, onClick }) => {
  return (
    <Chip
      label={
        <Grid
          style={{
            height: 21,
          }}>
          <Typography
            inline
            style={{
              paddingRight: 5,
              color: 'white',
              fontWeight: 'bold',
            }}>
            {text}
          </Typography>
          <Icon fontSize="inherit" />
        </Grid>
      }
      style={{
        width: 40,
        height: 18,
        fontSize: 13,
        marginLeft: 5,
        color: 'white',
        background: '#F8AC59',
      }}
      onClick={onClick}
    />
  );
};

const LineTypeChip = ({ text, icon: Icon, bgColor, style, onClick }) => {
  return (
    <Chip
      label={
        <Grid
          style={{
            height: 24,
          }}>
          <Icon fontSize="inherit" />
          <Typography
            inline
            style={{
              paddingLeft: 5,
              color: 'white',
              fontWeight: 'bold',
            }}>
            {text}
          </Typography>
        </Grid>
      }
      style={{
        width: 40,
        height: 18,
        fontSize: 14,
        marginLeft: 5,
        color: 'white',
        background: bgColor,
        ...style,
      }}
      onClick={onClick}
    />
  );
};

const OrderList = ({ items }) => {
  return (
    <List component="ol">
      {items.map((item, index) => (
        <ListItem key={index} component="li" style={{ padding: 0 }}>
          <ListItemText
            primary={
              <>
                <Typography inline variant="subtitle2">
                  {`${index + 1}. ${item.product_desc} ${item.qty_ordered}`}
                </Typography>

                <Typography
                  inline
                  variant="subtitle2"
                  style={{ fontWeight: 'bold' }}>
                  {`${item.uom} ${item.wo_type_str}`}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

const Thread = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <div key={index}>
          <Typography variant="subtitle2">{item.txt}</Typography>
          <Typography
            variant="caption"
            style={{
              fontSize: 11,
              color: '#777777',
            }}>{`- ${item.u_id} ${dateFormat(
            item.ts,
            'MMM D h:mmA'
          )}`}</Typography>
        </div>
      ))}
    </>
  );
};

const defaultSorting = {
  dc_nm: 1, // -1 desc , 1 asc
};

const INITIAL_FILTERS = {
  dc_id: [],
  pcr_id: [],
  method_id: [],
  driver_id: [],
  special_filters: [],
  req_dt_type: 'any',
  req_end_dt: null,
  sched_dt_type: 'un',
  sched_end_dt: null,
  req_dt: new Date(),
  sched_dt: new Date(),
  print_status: 'b',
  search: '',
};

const PRINT_TYPE = [
  {
    id: 1,
    value: 'b',
    label: 'Printed and Printed and UnPrinted',
  },
  {
    id: 2,
    value: 'n',
    label: 'UnPrinted',
  },
  {
    id: 3,
    value: 'y',
    label: 'Printed',
  },
];

const SCHEDULE_TYPE = [
  {
    id: 1,
    value: 'un',
    label: 'UnScheduled',
  },
  {
    id: 2,
    value: 'any',
    label: 'Scheduled (All Dates)',
  },
  {
    id: 3,
    value: 'any_or_un',
    label: 'Scheduled (All Dates) or Unscheduled',
  },
  {
    id: 4,
    value: 'on',
    label: 'Sched.Dt on',
  },
  {
    id: 5,
    value: 'on_or_before',
    label: 'Sched.Dt on or before',
  },
  {
    id: 6,
    value: 'on_or_after',
    label: 'Sched.Dt on or after',
  },
  {
    id: 7,
    value: 'between',
    label: 'Sched.Dt between',
  },
  {
    id: 8,
    value: 'pr',
    label: 'Pending Reschedule',
  },
  {
    id: 9,
    value: 'pc',
    label: 'Pending Cancellation',
  },
];

const REQUEST_TYPE = [
  {
    id: 1,
    value: 'any',
    label: 'All Request Dates',
  },
  {
    id: 2,
    value: 'on',
    label: 'Req.Dt on',
  },
  {
    id: 3,
    value: 'on_or_before',
    label: 'Req.Dt on or before',
  },
  {
    id: 4,
    value: 'on_or_after',
    label: 'Req.Dt on or after',
  },
  {
    id: 5,
    value: 'between',
    label: 'Req.Dt between',
  },
];

const SPECIAL_FILERS = [
  { value: 'telecare', label: 'Telecare Clone' },
  { value: 'allgood', label: 'All Good' },
  { value: 'allbad', label: 'All Bad' },
];

const Dt = (props) => {
  const {
    classes,
    workordersList,
    fetchWorkorders,
    saveSorting,
    saveWorkorderSearchFilters,
  } = props;

  const { isFetching, workorders } = workordersList;
  const [filters, setFilters] = useState({ ...INITIAL_FILTERS });
  const [isSavingToGoogleSheet, setSavingToGoogleSheet] = useState(false);
  const [pcr, setPcr] = useState([]);
  const [method, setMethod] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [location, setLocation] = useState([]);
  const [showThread, setShowThread] = useState({});
  const [showLineItems, setShowLineItems] = useState({});
  const [specialFilters] = useState([...SPECIAL_FILERS]);
  const [sortData, setSortData] = useState(defaultSorting);

  const showeDtFilter = ['on', 'on_or_before', 'on_or_after', 'between'];

  const showLines = (rowId) => {
    setShowLineItems((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };
  const showThreads = (rowId) => {
    setShowThread((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  const fetchWorkOrderList = () => {
    saveSorting({ sorting: sortData });

    fetchWorkorders();
  };

  const handlePageChange = (newPage) => {
    fetchWorkorders(newPage - 1);
  };

  const handleResetSorting = () => {
    setSortData(defaultSorting);
    saveSorting({ sorting: sortData });
  };

  useDebounce(fetchWorkOrderList, 1000, [sortData]);

  const applyFilters = () => {
    const page = 0;

    fetchWorkorders(page);
  };

  const handleFilterFieldChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({ ...INITIAL_FILTERS });
  };

  const handleDateChange = (name, value) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const fetchLocation = async () => {
    try {
      const { place } = await window.sch.get(`/api/dt/places`);
      const data = place.map((loc) => ({ value: loc.dc_id, label: loc.dc_nm }));
      setLocation(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMethod = async () => {
    try {
      const { rr } = await window.sch.get(`/api/dt/method_list`);
      let data = rr.map((method) => ({
        label: method.label,
        value: parseInt(method.value),
      }));
      setMethod(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPcr = async () => {
    try {
      const { rr } = await window.sch.get(`/api/wo/pcr`);
      const data = rr.map((pcr) => ({
        value: pcr.hdms_id,
        label: pcr.nm,
      }));
      setPcr(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchDrivers = async () => {
    try {
      const { rr } = await window.sch.get(`/api/wo/drivers`);
      const data = rr.map((driver) => ({
        value: driver.driver_id,
        label: driver.driver_nm,
      }));
      setDrivers(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchLocation();
    fetchMethod();
    fetchPcr();
    fetchDrivers();
  }, []);

  const decodeSearchValues = () => {
    if (!filters.search) return '';

    const splitVal = filters.search
      .split(/[, ]+/)
      .map((val) => val.trim())
      .filter((val) => val.length > 0);
    return splitVal.map((val) => decodeWoDisplay(val));
  };

  useEffect(() => {
    const search = decodeSearchValues();
    const cleanfilterparams = cleanObject({ ...filters, search });

    saveWorkorderSearchFilters(cleanfilterparams);
  }, [filters, saveWorkorderSearchFilters]);

  const saveToGoogleSheet = () => {
    setSavingToGoogleSheet(true);
    const path = '/api/wo/list';
    return window.sch
      .post(path, { ...filters, to_google_sheets: true })
      .then((result) => {
        setSavingToGoogleSheet(false);
        if (result.error) {
          throw new Error(result.error);
        }
        window.open(result.sheet.spreadsheetUrl, result.sheet.properties.title);
      })
      .catch((error) => {
        setSavingToGoogleSheet(false);
        console.error(error);
        return this.props.open_snack_ac({
          variant: 'error',
          message:
            'Failed creating google sheet ' + (typeof error.error === 'string')
              ? error.error
              : '',
        });
      });
  };

  const columns = [
    {
      title: 'Location',
      dataIndex: 'dc_nm',
      key: 'dc_nm',
      width: 120,
      sortable: true,
      render: (data) => (
        <>
          {data.dc_nm}
          <br />
          <Typography style={{ color: '#428bca' }}>
            {`Staged:${dateFormat(data.staged_ts, 'MMM D h:mmA')}`}
          </Typography>
          <Typography
            style={{
              fontWeight: 'bold',
              color: 'red',
              textTransform: 'capitalize',
            }}>
            {`Not Your DC!!!`}
          </Typography>
        </>
      ),
    },
    {
      title: (
        <>
          <Typography inline>WO# Patient Acct</Typography>
          <LineTypeChip
            text="Delivery"
            icon={ExitToApp}
            bgColor="#1d84c6"
            style={{ width: 80 }}
          />
          <LineTypeChip
            text="Pickup"
            icon={ExitToApp}
            bgColor="#24c6c8"
            style={{ width: 80 }}
          />
        </>
      ),
      dataIndex: '_id',
      key: '_id',
      width: 200,
      render: (data) => {
        return (
          <>
            <Typography inline style={{ fontWeight: 'bold', marginRight: 3 }}>
              {wo_display(data._id)}
            </Typography>
            <Typography inline style={{ marginRight: 3 }}>
              {data.patient_nm}
            </Typography>
            <Typography inline style={{ color: '#428bca' }}>
              {data.acct}
            </Typography>
            -
            <Typography inline style={{ color: '#428bca' }} component="div">
              View DF
              <NoteChip
                text={data.thread ? data.thread.length : '+'}
                icon={ChatBubbleOutline}
                onClick={() => showThreads(data._id)}
              />
            </Typography>
            {data.wo_types?.Delivery && (
              <LineTypeChip
                icon={ExitToApp}
                bgColor="#1d84c6"
                text={data.wo_types.Delivery}
                onClick={() => showLines(data._id)}
              />
            )}
            {data.wo_types?.Pickup && (
              <LineTypeChip
                icon={ExitToApp}
                bgColor="#24c6c8"
                text={data.wo_types.Pickup}
                onClick={() => showLines(data._id)}
              />
            )}
            <br />
            <Typography
              inline
              style={{ marginRight: 3 }}>{`[PS: ${data.ps_id}]`}</Typography>
            <Typography
              inline
              style={{
                fontWeight: 'bold',
                color: '#428bca',
              }}>{`${data.pcr_nm} ${data.phone}`}</Typography>
            {showThread[data._id] && data.thread && (
              <Thread items={data.thread} />
            )}
            {showLineItems[data._id] && <OrderList items={data.lines} />}
          </>
        );
      },
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (data) => (
        <>
          {/*data.addr_1*/}
          {/* <br /> */}
          {`${data.city} ${data.state}`}
          <br />
          {`ph: ${data.phone}`}
        </>
      ),
    },
    // {
    //   title: 'Milkrun Grouping',
    //   dataIndex: 'group',
    //   key: 'group',
    //   render: (data) => <>unknown</>,
    // },
    {
      title: 'Delivery Method',
      dataIndex: 'dmethod',
      key: 'dmethod',
      render: (data) => <>{data.method_nm}</>,
    },
    {
      title: 'Assigned to',
      dataIndex: 'assigned',
      key: 'assigned',
      render: (data) => <>{data.driver_nm}</>,
    },
    {
      title: 'Request Date',
      dataIndex: 'req_dt_utc',
      key: 'req_dt_utc',
      sortable: true,
      render: (data) => <>{dateFormat(data.req_dt_utc)}</>,
    },
    {
      title: 'Schedule Date',
      dataIndex: 'sched_dt_utc',
      key: 'sched_dt',
      sortable: true,
      render: (data) => <>{dateFormat(data.sched_dt_utc)}</>,
    },
    {
      title: 'Status',
      dataIndex: 'print_status',
      key: 'print_status',
      render: (data) => {
        let status_msg = '';
        if (data.trip) {
          status_msg = data.trip.status;
        }

        return (
          <>
            <div>
              <strong>{status_msg}</strong>
              <br />
              {data.print_status === 'y' ? 'Printed' : 'UnPrinted'}
              <br />
              {dateFormat(data.lastprint_dt_utc)}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ padding: '20px', alignItems: 'end' }}>
        <Grid
          container
          spacing={16}
          alignItems="center"
          style={{ padding: '10px' }}>
          <Grid item xs={2}>
            <CustomSelect
              name="print_status"
              options={PRINT_TYPE}
              label="Print Status"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.print_status}
            />
          </Grid>

          <Grid item xs={2}>
            <CustomSelect
              name="sched_dt_type"
              options={SCHEDULE_TYPE}
              label="Schedule Date"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.sched_dt_type}
            />
          </Grid>

          {showeDtFilter.includes(filters.sched_dt_type) && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.sched_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('sched_dt', date)}
                customInput={
                  <TextField margin="dense" label="Scheduled From" />
                }
              />
            </Grid>
          )}

          {filters.sched_dt_type === 'between' && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.sched_end_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('sched_end_dt', date)}
                customInput={<TextField margin="dense" label="Scheduled To" />}
              />
            </Grid>
          )}

          <Grid item xs={2}>
            <CustomSelect
              name="req_dt_type"
              options={REQUEST_TYPE}
              label="Request Date"
              onChange={(e) => {
                const { name, value } = e.target;
                handleFilterFieldChange(name, value);
              }}
              value={filters.req_dt_type}
            />
          </Grid>

          {showeDtFilter.includes(filters.req_dt_type) && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.req_dt}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('req_dt', date)}
                customInput={<TextField margin="dense" label="Request From" />}
              />
            </Grid>
          )}

          {filters.req_dt_type === 'between' && (
            <Grid item xs={1}>
              <SuperReactDatePicker
                selected={filters.req_end_dt || new Date()}
                dateFormat="MM-dd-yyyy"
                onChange={(date) => handleDateChange('req_end_dt', date)}
                customInput={<TextField margin="dense" label="Request To" />}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="special_filters"
            label="Custom Filters"
            options={specialFilters}
            placeholder="Select filters"
            value={filters.special_filters}
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>

        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="dc_id"
            label="Location"
            options={location}
            value={filters.dc_id}
            placeholder="Select Location"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="method_id"
            options={method}
            label="Suggested Method"
            value={filters.method_id}
            placeholder="Select Method"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            name="driver_id"
            options={drivers}
            label="Assigned Drivers"
            value={filters.driver_id}
            placeholder="Select Drivers"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <ReactSelect
            isMulti
            label="PCR"
            name="pcr_id"
            options={pcr}
            value={filters.pcr_id}
            placeholder="Select PCR"
            onChange={(value, e) => {
              const name = e.name;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={16}
        alignItems="center"
        style={{ paddingLeft: '20px', alignItems: 'end' }}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            id="search"
            name="search"
            margin="dense"
            label="Acccounts or WO"
            palceholder="Accounts or WO"
            value={filters.search}
            disabled={isFetching}
            className={classes.account}
            onChange={(e) => {
              const { name, value } = e.target;
              handleFilterFieldChange(name, value);
            }}
          />
        </Grid>

        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={applyFilters}
            disabled={isFetching}>
            Show Records
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleClearFilters}
            disabled={isFetching}>
            Clear All
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={saveToGoogleSheet}
            disabled={
              isSavingToGoogleSheet || isFetching || !workorders?.wos?.length
            }>
            Save to Googlesheet
          </Button>
        </Grid>
      </Grid>

      <div className={classes.content}>
        {!!workorders?.pagination?.totalRecords && (
          <PaginationWithReset
            data={workorders?.pagination}
            isFetching={isFetching}
            handleResetSorting={handleResetSorting}
            handlePageChange={handlePageChange}
          />
        )}

        <CustomTable
          classes={classes}
          columns={columns}
          sortData={sortData}
          data={workorders?.wos}
          isLoading={isFetching}
          backgroundColor={false}
          setSortData={setSortData}
          error={{ isError: 'error', message: 'There is no work orders' }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (stateFromStore) => ({
  workordersList: stateFromStore.workorders,
  workorderSorting: stateFromStore.saveWorkorderSorting,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchWorkorders: fetchWorkorders_ac,
      saveSorting: saveWorkorderSorting_ac,
      saveWorkorderSearchFilters: saveWorkorderSearchFilters_ac,
    },
    dispatch
  );
};

const styles = () => ({
  content: {
    height: '80vh',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    padding: 20,
  },
  account: {
    marginBottom: 0,
  },
  root: {
    height: '80%',
    '&::-webkit-scrollbar': {
      width: '0.3em',
    },
    '&::-webkit-scrollbar-thumb': {
      outline: 'none',
    },
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Dt));
