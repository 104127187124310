import { serialize } from '../util/object'

export const FETCH_FA_LOOKUPS = 'FETCH_FA_LOOKUPS'
export const FETCH_FA_LOOKUPS_SUCCESSFUL = 'FETCH_FA_LOOKUPS_SUCCESSFUL'
export const FETCH_FA_LOOKUPS_FAILED = 'FETCH_FA_LOOKUPS_FAILED'

export const FETCH_FA_LOOKUP_DETAIL = 'FETCH_FA_LOOKUP_DETAIL'
export const FETCH_FA_LOOKUP_DETAIL_SUCCESSFUL =
  'FETCH_FA_LOOKUP_DETAIL_SUCCESSFUL'
export const FETCH_FA_LOOKUP_DETAIL_FAILED =
  'FETCH_FA_LOOKUP_DETAIL_FAILED_FAILED'

export const ADD_FA_LOOKUP = 'ADD_FA_LOOKUP'
export const ADD_FA_LOOKUP_SUCCESSFUL = 'ADD_FA_LOOKUP_SUCCESSFUL'
export const ADD_FA_LOOKUP_FAILED = 'ADD_FA_LOOKUP_FAILED'

export const REMOVE_FA_LOOKUP = 'REMOVE_FA_LOOKUP'
export const REMOVE_FA_LOOKUP_SUCCESSFUL = 'REMOVE_FA_LOOKUP_SUCCESSFUL'
export const REMOVE_FA_LOOKUP_FAILED = 'REMOVE_FA_LOOKUP_FAILED'

export const UPDATE_FA_LOOKUPS_SEQUENCE = 'UPDATE_FA_LOOKUPS_SEQUENCE'
export const UPDATE_FA_LOOKUPS_SEQUENCE_SUCCESSFUL =
  'UPDATE_FA_LOOKUPS_SEQUENCE_SUCCESSFUL'
export const UPDATE_FA_LOOKUPS_SEQUENCE_FAILED =
  'UPDATE_FA_LOOKUPS_SEQUENCE_FAILED'

export const FETCH_FA_LOOKUPS_STRUCTURE = 'FETCH_FA_LOOKUPS_STRUCTURE'
export const FETCH_FA_LOOKUPS_STRUCTURE_SUCCESSFUL =
  'FETCH_FA_LOOKUPS_STRUCTURE_SUCCESSFUL'
export const FETCH_FA_LOOKUPS_STRUCTURE_FAILED =
  'FETCH_FA_LOOKUPS_STRUCTURE_FAILED'

export function fetchFALookupsStructure_ac(params) {
  return dispatch => {
    dispatch({ type: FETCH_FA_LOOKUPS_STRUCTURE })
    return window.sch
      .get(`/api/fa/admin/lookups/structure?` + serialize(params))
      .then(result => {
        dispatch({
          type: FETCH_FA_LOOKUPS_STRUCTURE_SUCCESSFUL,
          data: result
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_FA_LOOKUPS_STRUCTURE_FAILED,
          error
        })
      })
  }
}

export function fetchFALookups_ac(params) {
  return dispatch => {
    dispatch({ type: FETCH_FA_LOOKUPS })
    return window.sch
      .get(`/api/fa/admin/lookups/list?` + serialize(params))
      .then(result => {
        dispatch({
          type: FETCH_FA_LOOKUPS_SUCCESSFUL,
          data: result
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_FA_LOOKUPS_FAILED,
          error
        })
      })
  }
}

export function fetchFALookupDetail_ac(params) {
  return dispatch => {
    dispatch({ type: FETCH_FA_LOOKUP_DETAIL })
    return window.sch
      .get(`/api/fa/admin/lookups/detail?` + serialize(params))
      .then(result => {
        dispatch({
          type: FETCH_FA_LOOKUP_DETAIL_SUCCESSFUL,
          data: result
        })
      })
      .catch(error => {
        dispatch({
          type: FETCH_FA_LOOKUP_DETAIL_FAILED,
          error
        })
      })
  }
}

export function addFALookup_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: ADD_FA_LOOKUP })
    return window.sch
      .post(`/api/fa/admin/lookups/add`, params)
      .then(result => {
        dispatch({
          type: ADD_FA_LOOKUP_SUCCESSFUL,
          data: { ...params, _id: result.new_id, seq: result.next_seq }
        })
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(error => {
        dispatch({
          type: ADD_FA_LOOKUP_FAILED,
          error
        })
        if (onError) {
          onError(error)
        }
      })
  }
}
export function removeFALookup_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: REMOVE_FA_LOOKUP })
    return window.sch
      .post(`/api/fa/admin/lookups/remove`, params)
      .then(result => {
        dispatch({
          type: REMOVE_FA_LOOKUP_SUCCESSFUL,
          data: result,
          removedIndex: params.removedIndex
        })
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(error => {
        dispatch({
          type: REMOVE_FA_LOOKUP_FAILED,
          error
        })
        if (onError) {
          onError(error)
        }
      })
  }
}

export function updateFALookupsSequence_ac(params, { onSuccess, onError }) {
  return dispatch => {
    dispatch({ type: UPDATE_FA_LOOKUPS_SEQUENCE })
    return window.sch
      .post(`/api/fa/admin/lookups/update_sequence`, params)
      .then(result => {
        dispatch({
          type: UPDATE_FA_LOOKUPS_SEQUENCE_SUCCESSFUL,
          data: result
        })
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_FA_LOOKUPS_SEQUENCE_FAILED,
          error
        })
        if (onError) {
          onError(error)
        }
      })
  }
}
