import React from 'react';
import TableCore from './TableCore';

const RBACTable = () => {
  return (
    <>
      <TableCore />
    </>
  );
};

export default RBACTable;
