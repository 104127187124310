import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  closeNotifsPanel_ac,
  close_notif_snack_ac,
  close_snack_ac,
  openNotifsPanel_ac,
} from '../actions/snack.ac.js';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  notif: InfoIcon,
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  notif: {
    marginTop: 40,
    color: '#000',
    backgroundColor: 'orange',
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    additionalActions = [],
    ...other
  } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        ...additionalActions,
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'notif'])
    .isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
});

class _DFSnackBar extends React.Component {
  handleClick = () => {
    this.setState({ open: true });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.close_snack_ac();
  };

  render() {
    const { snack } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: snack.anchor ? snack.anchor : 'bottom',
            horizontal: 'right',
          }}
          open={snack.open}
          autoHideDuration={snack.duration ? snack.duration : 10000}
          onClose={this.handleClose}>
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={snack.variant}
            message={snack.message}
            additionalActions={snack.additionalActions}
          />
        </Snackbar>
      </div>
    );
  }
}

_DFSnackBar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'notif'])
    .isRequired,
};

class _NotifSnackBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { openNotifsPanel_ac } = this.props;
    openNotifsPanel_ac();
  }

  render() {
    const { snack } = this.props;
    return (
      <div style={{ cursor: 'pointer' }} onClick={this.handleClick}>
        <Snackbar
          anchorOrigin={{
            vertical: snack.notif_anchor ? snack.notif_anchor : 'bottom',
            horizontal: 'right',
          }}
          open={snack.notif_open}
          autoHideDuration={snack.duration ? snack.notif_duration : 5000}
          onClose={this.handleClose}>
          <MySnackbarContentWrapper
            variant={snack.notif_variant}
            message={snack.notif_message}
          />
        </Snackbar>
      </div>
    );
  }
}

_NotifSnackBar.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info', 'notif'])
    .isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  snack: stateFromStore.snack,
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      close_snack_ac,
      close_notif_snack_ac,
      closeNotifsPanel_ac,
      openNotifsPanel_ac,
    },
    dispatch
  );

/*
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles2)(DFSnackBar))
*/

export const DFSnackBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles2)(_DFSnackBar));

export const NotifSnackBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles2)(_NotifSnackBar));
