import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { myHistory_ac } from '../actions/my_history.ac.js';

const styles = () => ({
  resultItem: {
    marginTop: 8,
    marginBottom: 4,
    paddingBottom: 4,
    borderBottom: '1px solid #90A4AE',
  },
  idn: {
    display: 'block',
    padding: 0,
    paddingTop: 4,
    marginTop: 4,
    cursor: 'pointer',
    fontSize: 12,
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    width: '100%',
    overflowY: 'hidden',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    padding: 12,
    paddingLeft: 0,
  },
  patientLbl: {
    fontSize: 12,
    margin: '2px 8px 0 4px',
    fontWeight: 600,
    color: '#00675b',
    padding: 2,
  },
  pageStatement: {
    paddingTop: 8,
    fontSize: 11,
  },
  accountLbl: {
    fontSize: 12,
    padding: '3px',
    margin: '2px 8px 8px 4px',
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#BBDEFB',
    borderBottom: '1px solid #000',
  },
  gridContainer: {
    paddingTop: 0,
    paddingRight: 0,
  },
  grid: {
    paddingRight: 40,
  },
  card: {
    minHeight: 40,
  },
  cardContent: {
    root: {
      padding: 10,
    },
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#fff' },
  owner: { color: '#7f8486' },
  tm: { backgroundColor: '#689F38' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  exactHdmsAcct: {},
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  listItem: {
    paddingLeft: 2,
  },
  listItemInvalid: {
    paddingLeft: 2,
    backgroundColor: 'lightgray',
  },
  listItemText: {
    fontSize: 12,
    color: 'blue',
  },
  link: {
    color: 'blue',
    border: 'none',
    cursor: 'pointer',
    paddingRight: 4,
    paddingLeft: 4,
    fontSize: 10,
    borderRight: '1px solid #7aa9ff',
  },
  docEntry: {
    cursor: 'pointer',
    marginLeft: 12,
    padding: 8,
  },
});

class MyHistory extends React.Component {
  constructor(props) {
    super(props);
    this.gotoIdn = this.gotoIdn.bind(this);
  }

  componentDidMount() {
    return this.checkSituation();
  }

  componentDidUpdate() {
    return this.checkSituation();
  }

  checkSituation() {
    const { myHistory, myHistory_ac, open } = this.props;
    if (myHistory.isFetching || myHistory.error || false === open) return;

    if (myHistory.lastChecked === null) {
      return myHistory_ac();
    }

    let lastCheckedSecondsAgo = new moment().diff(
      moment(myHistory.lastChecked),
      'seconds'
    );
    if (lastCheckedSecondsAgo > 60) {
      return myHistory_ac();
    }
    return;
  }

  gotoIdn = (idn, tab) => () => {
    this.props.changePage(
      `/dfa/idn/${idn.replace(/ /g, '_').replace(/\//g, '%2F')}/tabs/${tab}`
    );
    return;
  };

  render() {
    const { classes, myHistory } = this.props;

    if (myHistory.isFetching || myHistory.results === null) {
      return (
        <div className={classes.root}>
          <div className={classes.pagination}>
            <Typography className={classes.pageStatement} color="textSecondary">
              ...loading history
            </Typography>
          </div>
        </div>
      );
    }

    if (myHistory.error) {
      return (
        <div className={classes.root}>
          <div className={classes.pagination}>
            <Typography className={classes.pageStatement} color="textSecondary">
              Error loading history.
            </Typography>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2} sm={12} md={6} className={classes.gridItem}>
            <div className={classes.pagination}>
              {myHistory.msg && (
                <div style={{ color: 'blue', fontSize: 12 }}>
                  {myHistory.msg}
                </div>
              )}

              {myHistory.results.hh.length === 0 && (
                <div style={{ color: 'blue', fontSize: 12 }}>
                  You do not have any accounts in your history.
                </div>
              )}
              {myHistory.results.hh.length > 0 && (
                <div style={{ color: 'blue', fontSize: 12 }}>
                  Patients Recently Viewed:
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        <div className={classes.scrollArea}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
              <List component="nav" className={classes.list}>
                {myHistory.results.hh.map((h) => (
                  <div className={classes.resultItem} key={h.idn}>
                    <div
                      onClick={this.gotoIdn(h.idn, 'doclisting')}
                      className={classes.idn}>
                      {h.idn}
                    </div>
                    <div className={classes.linksContainer}>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'workunits')}>
                        Work V1
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'V2_workunits')}>
                        Work V2
                      </button>
                      {_.get(h, 'aa', []).length > 0 && (
                        <button
                          className={classes.link}
                          onClick={this.gotoIdn(h.idn, 'account_info')}>
                          {h.aa.map((a) => 'A' + a).join(' ')}
                        </button>
                      )}
                      {_.get(h, 'aa', []).length > 0 && (
                        <button
                          className={classes.link}
                          onClick={this.gotoIdn(h.idn, 'orders')}>
                          Orders
                        </button>
                      )}
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'doclisting')}>
                        Docs
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'doc_submissions')}>
                        Submit
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'doc_requests')}>
                        MR Req
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'ops_form')}>
                        Ops
                      </button>
                      {_.get(h, 'aa', []).length > 0 && (
                        <>
                          <button
                            className={classes.link}
                            onClick={this.gotoIdn(h.idn, 'cmns')}>
                            {' '}
                            CMNS{' '}
                          </button>
                          <button
                            className={classes.link}
                            onClick={this.gotoIdn(h.idn, 'pas')}>
                            {' '}
                            PAS
                          </button>
                          <button
                            className={classes.link}
                            onClick={this.gotoIdn(h.idn, 'appeal_requests')}>
                            {' '}
                            Appeals
                          </button>
                          <button
                            className={classes.link}
                            onClick={this.gotoIdn(h.idn, 'adjustments')}>
                            {' '}
                            ADJ
                          </button>
                          <button
                            className={classes.link}
                            onClick={this.gotoIdn(h.idn, 'letters')}>
                            {' '}
                            Letters
                          </button>
                        </>
                      )}
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn(h.idn, 'todos')}>
                        Tasks
                      </button>
                    </div>
                  </div>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

MyHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  myHistory: stateFromStore.myHistory,
  idnInView: stateFromStore.idnInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      myHistory_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyHistory));
