import { actionTypes } from './../actions/active_patients.ac';

export const initialState = {
  isFetching: false,
  results: null,
  pagination: null,
  error: null,
};

export function activePatients(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_ACTIVE_PATIENTS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.FETCH_ACTIVE_PATIENTS_SUCCESS:
      return {
        ...state,
        pagination: action.pagination,
        results: action.data,
        isFetching: false,
      };

    case actionTypes.FETCH_ACTIVE_PATIENTS_ERROR:
      return {
        ...state,
        pagination: null,
        results: null,
        error: action.error,
        isFetching: false,
      };

    default:
      return {
        ...state,
      };
  }
}
