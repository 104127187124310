export function appealRequestsInView(
  state = {
    isFetching: false,
    idn: null,
    error: null,
    records: null,
    pagination: null,
    savingAppealODocId: null,
    updateFaxNumInProgress: false,
    transmitInProgress: false,
    transmitActvityODocId: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_APPEAL_REQUESTS':
      return Object.assign({}, state, {
        records: null,
        error: null,
        pagination: null,
        isFetching: true,
        idn: action.idn
      })
    case 'FAILED_RECEIVED_APPEAL_REQUESTS':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        error: action.error,
        isFetching: false
      })
    case 'RECEIVED_APPEAL_REQUESTS':
      return Object.assign({}, state, {
        records: action.records,
        pagination: action.pagination,
        error: null,
        isFetching: false
      })

    case 'RESET_APPEAL_REQUESTS':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        error: null,
        params: null,
        idn: null,
        isFetching: false,
        transmitInProgress: false,
        transmitError: null
      })

    case 'ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: true
      })
    case 'DONE_ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: false,
        records: action.records
      })
    case 'FAILED_ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: false,
        transmitErr: action.error
      })

    case 'ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: true
      })
    case 'DONE_ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: false,
        records: action.records
      })
    case 'FAILED_ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: false,
        transmitErr: action.error
      })
    case 'SAVING_APPEAL_PDF_ORDER':
      return Object.assign({}, state, {
        savingAppealODocId: action.savingAppealODocId
      })
    case 'RESORTED_APPEAL_PDFS':
      return Object.assign({}, state, {
        records: state.records.map(record => {
          if (record._id !== action.params._id) return record

          return {
            ...record,
            s3keys: [...action.params.s3keys]
          }
        })
      })

    default:
      return state
  }
}

export function appealRequestActivity(
  state = {
    appealsFiles: [],
    state: 'new',
    error: false,
    successPkg: null,
    qrCodeFetchInProg: false,
    qrCodeHash: null,
    enabledTpls: [],
    isFetchingDataSet: false,
    dataSet: null,
    dataSetError: null
  },
  action
) {
  switch (action.type) {
    case 'ATTACH_APPEAL_REQ_FILES_TO_STORE':
      return Object.assign({}, state, {
        appealsFiles: action.appealsFiles
      })

    case 'GET_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeFetchInProg: true
      })
    case 'GOT_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeHash: action.qrCodeHash,
        qrCodeFetchInProg: false
      })
    case 'FAILED_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeHash: null,
        qrCodeFetchInProg: false
      })

    case 'GET_APPEALS_DATA_SET':
      return Object.assign({}, state, {
        isFetchingDataSet: true
      })
    case 'GOT_APPEALS_DATA_SET':
      return Object.assign({}, state, {
        isFetchingDataSet: false,
        dataSet: action.dataSet
      })
    case 'GOT_APPEALS_ENABLED_TPL':
      return Object.assign({}, state, {
        enabledTpls: action.enabledTpls
      })
    case 'FAILED_APPEALS_DATA_SET':
      return Object.assign({}, state, {
        isFetchingDataSet: false,
        dataSet: null,
        dataSetError: action.error
      })

    case 'CREATE_APPEAL_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        state: 'inprog'
      })
    case 'UNDO_APPEAL_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        state: 'new'
      })
    case 'FAILED_APPEAL_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        inProgress: false,
        state: 'error',
        error: action.error
      })
    case 'SUCCESS_APPEAL_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        inProgress: false,
        state: 'success',
        successPkg: action.successPkg,
        error: false
      })
    case 'RESET_APPEAL_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        appealsFiles: [],
        state: 'new',
        qrCodeHash: null,
        qrCodeFetchInProg: false,
        successPkg: null,
        isFetchingDataSet: false,
        dataSet: null,
        dataSetError: null,
        inProgress: false,
        error: false
      })
    default:
      return state
  }
}
