import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { close_confirmation_dialog_ac } from '../../actions/dialog.ac'

/**
 * Custom Dialog Component for showing confirmation dialog to user with different actions.
 *
 * @component
 * @example
 * Step1: import and load the '<ConfirmationDialog />' component first
 * Step2: map the dispatch function for the dialog action in the component to be used
 * Step3: trigger the dispatch action with the necessary params as shown below:
 *
 * ### Each item in actions array defines following keys: ###
 *  1. name => name of the action button
 *  2. shouldCloseDialog => designates if the action should close the dialog box
 *  3. action => calls the actual action functionality upon clicking the action button.
 *
 * ######################
 *
 * const title="sample title"
 * const content="sample content of the dialog box"
 * const actions = [
 *  {
 *    label: "ActionOne",
 *    shouldCloseDialog: true
 *    action: () => {
 *      alert("Hey ActionOne");
 *    }
 *  },
 *  {
 *    label: "ActionTwo",
 *    action: () => {
 *      alert("Hey ActionTwo");
 *    }
 *  }
 * ];
 *
 * this.props.open_confirmation_dialog_ac({
 *    title:title,
 *    content:content,
 *    actions:actions
 * })
 *
 * ###### OR Directly trigger the action ########
 * dispatch({
 *    type: 'OPEN_CONFIRMATION_DIALOG',
 *    title: 'Sample Confirmation Dialog',
 *    content: 'Sample confirmation content',
 *    actions: [...]
 * })
 */
class ConfirmationDialog extends React.Component {
  constructor(props) {
    super(props)
    this.handleActionClick = this.handleActionClick.bind(this)
  }

  handleActionClick(currentAction) {
    currentAction.action()
    if (currentAction.shouldCloseDialog) {
      this.props.close_confirmation_dialog_ac()
    }
  }

  render() {
    const {
      title,
      content,
      actions,
      open,
      onClose,
      showCloseButton = true
    } = this.props.confirmationDialog
    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions.map((currentAction, index) => {
            return (
              <Button
                key={index}
                onClick={() => this.handleActionClick(currentAction)}
                color="primary"
                {...(currentAction.buttonProps || {})}>
                {currentAction.label}
              </Button>
            )
          })}
          {showCloseButton && (
            <Button
            onClick={() => {
                this.props.close_confirmation_dialog_ac();
                if (onClose) onClose()
              }
            }
            color="primary">
            Close
          </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  confirmationDialog: stateFromStore.confirmationDialog
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      close_confirmation_dialog_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationDialog)
