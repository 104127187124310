export default {
  connect: {
    drawer: {
      open: 'CONNECT_DRAWER_OPEN',
      close: 'CONNECT_DRAWER_CLOSE',
      clear: 'CONNECT_DRAWER_CLEAR'
    },
    idn: {
      try: 'CONNECT_IDN_TRY',
      success: 'CONNECT_IDN_SUCCESS',
      fail: 'CONNECT_IDN_FAIL'
    },
    setAttributes: 'CONNECT_ATTRIBUTES_SET',
    setState: 'CONNECT_STATE_SET'
  },
  formCreation: {
    jsonSend: {
      try: 'FORMCREATION_SEND_TRY',
      success: 'FORMCREATION_SEND_SUCCESS',
      fail: 'FORMCREATION_SEND_FAIL'
    }
  },
  pdfGen: {
    doc: {
      add: 'PDFGEN_DOC_ADD',
      remove: 'PDFGEN_DOC_REMOVE',
      moveUp: 'PDFGEN_DOC_MOVE_UP',
      moveDown: 'PDFGEN_DOC_MOVE_DOWN'
    },
    page: {
      toggle: 'PDFGEN_PAGE_TOGGLE'
    },
    pagePicker: 'PDFGEN_PAGEPICKER',
    clearDrawer: 'PDFGEN_DRAWER_CLEAR',
    toggleDrawer: 'PDFGEN_DRAWER_TOGGLE',
    openDrawer: 'PDFGEN_DRAWER_OPEN',
    closeDrawer: 'PDFGEN_DRAWER_CLOSE',
    convert: {
      try: 'PDFGEN_CONVERT_TRY',
      success: 'PDFGEN_CONVERT_SUCCESS',
      fail: 'PDFGEN_CONVERT_FAIL'
    },
    preview: {
      try: 'PDFGEN_PREVIEW_TRY',
      success: 'PDFGEN_PREVIEW_SUCCESS',
      fail: 'PDFGEN_PREVIEW_FAIL'
    }
  },
  reassign: {
    pickReasonCode: 'REASSIGN_PICK_REASON_CODE',
    pickTeam: 'REASSIGN_PICK_TEAM',
    params: 'REASSIGN_PARAMS',
    toggleDialog: 'REASSIGN_TOGGLE_DIALOG',
    teamCounts: {
      try: 'REASSIGN_TEAM_COUNTS_TRY',
      success: 'REASSIGN_TEAM_COUNTS_SUCCESS',
      fail: 'REASSIGN_TEAM_COUNTS_FAIL'
    },
    teamsOnly: {
      try: 'REASSIGN_TEAMS_ONLY_TRY',
      success: 'REASSIGN_TEAMS_ONLY_SUCCESS',
      fail: 'REASSIGN_TEAMS_ONLY_FAIL'
    },
    do: {
      try: 'REASSIGN_DO_TRY',
      success: 'REASSIGN_DO_SUCCESS',
      fail: 'REASSIGN_DO_FAIL'
    }
  },

  notify_files: {
    internals: {
      SUCCESS: 'FILES_INTERNALS_SUCCESS',
      FAIL: 'FILES_INTERNALS_FAIL'
    },
    list: {
      SUCCESS: 'FILES_LIST_SUCCESS',
      FAIL: 'FILES_LIST_FAIL'
    },
    read: {
      SUCCESS: 'FILES_READ_SUCCESS',
      FAIL: 'FILES_READ_FAIL'
    }
  },
  notify_view: {
    category: {
      SET: 'VIEW_CATEGORY_SET'
    },
    subcategory: {
      SET: 'VIEW_SUBCATEGORY_SET'
    },
    subsubcategory: {
      SET: 'VIEW_SUBSUBCATEGORY_SET'
    }
  }
}
