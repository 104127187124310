import {
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Clear as ClearIcon,
  KeyboardReturn as KeyboardReturnIcon,
  Save as SaveIcon,
} from '@material-ui/icons';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchAllWorkunitsForIdn_ac,
  locallyUpdateDfaWorkUnit_ac,
  saveToGoogleSheet_ac,
  searchStrSelected_ac,
  tackleActivity_ac,
  tackleDfaWorkUnit_ac,
  toggleAllCards_ac,
  untackleDfaWorkUnit_ac,
} from '../../actions/dfa.ac.js';
import { fetchHdmsNoteHdrs_ac } from '../../actions/hdms_notes.ac.js';
import { toggleUserPref_ac } from '../../actions/me.ac';
import { open_snack_ac } from '../../actions/snack.ac.js';
import dateUtil from '../../util/dateProcessing';
import FetchingStatement from '../FetchingStatement';
import { Pagination } from '../PaginationNew';
import DfaCard from './DfaCard';

const styles = (theme) => ({
  sitSwitch: {
    height: 20,
    paddingBottom: 8,
  },
  root: {
    flexGrow: 1,
    width: '100%',
    //backgroundColor: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  patientLbl: {
    fontSize: 12,
    margin: '2px 8px 0 4px',
    fontWeight: 600,
    color: '#00675b',
    padding: 2,
  },
  accountLbl: {
    fontSize: 12,
    padding: '3px',
    margin: '2px 8px 8px 4px',
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#BBDEFB',
    borderBottom: '1px solid #000',
  },
  docEntry: {
    cursor: 'pointer',
    marginLeft: 12,
    padding: 8,
  },
  pagination: {
    marginTop: 0,
    flexDirection: 'row',
  },
  sortBtn: {
    cursor: 'pointer',
    marginLeft: 2,
    color: '#5E35B1',
  },
  toggleAllBtn: {
    cursor: 'pointer',
    marginLeft: 2,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  flexContainer: {
    display: 'flex',
  },
  tabLabelContainer: {
    padding: '3px 12px 3px 12px',
  },
  tabRoot: {
    minWidth: 16,
    minHeight: 24,
  },
  tabsRoot: {
    minHeight: 24,
    maxHeight: 26,
  },
  filtersContainer: {
    paddingBottom: 8,
    flexWrap: 'inherit',
    borderBottom: '1px solid #00000010',
  },
  pageSelectMenu: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingRight: 24,
    fontSize: 12,
  },
  pageSelectIcon: {
    fontSize: 22,
  },
  dateFilterLbl: {
    fontSize: '0.75rem',
    textAlign: 'right',
    marginRight: 4,
    marginLeft: 4,
  },
  smallTextFieldInput: {
    padding: 4,
    fontSize: 12,
    width: 85,
  },
  horizFormControl: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateFilterAdornment: {
    position: 'absolute',
    right: 0,
  },
  fullHeight: {
    height: '100%',
  },
  tabSpecificContainer: {
    height: 'calc(100% - 26px)',
  },
});

class DfaList extends React.Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleDfaListPageChangeInIdn =
      this.handleDfaListPageChangeInIdn.bind(this);
    this.onLocalUpdate = this.onLocalUpdate.bind(this);
    this.onToggleCards = this.onToggleCards.bind(this);
    this.searchSubmitClickTimer = 0;
    this.state = {
      landingSearchStr: 'home',
      selectedTab: 'homelist',
      tabbedIdns: [],
      autoRefreshOn: false,
      idnPage: 1,
      dfaFilterStr: this.getFilterStrFromPath(),
      lastToggleAllCards: false,
      lastSortBy: 'Docs',
      sortCreated: {
        isUp: true,
        up: 'nc',
        down: 'oc',
        upLbl: 'NC',
        upTitle: 'Sort from Newest Created',
        downLbl: 'OC',
        downTitle: 'Sort from Oldest Created',
      },
      sortDocs: {
        isUp: true,
        up: 'nd',
        down: 'od',
        upLbl: 'ND',
        upTitle: 'Sort from Newest Documents Added',
        downLbl: 'OD',
        downTitle: 'Sort from Oldest Documents Added',
      },
      sortAssigned: {
        isUp: true,
        up: 'ns',
        down: 'os',
        upLbl: 'NA',
        upTitle: 'Sort from Newest Assigned',
        downLbl: 'OA',
        downTitle: 'Sort from Oldest Assigned',
      },
      sortAlpha: {
        isUp: true,
        up: 'az',
        down: 'za',
        upLbl: 'A-Z',
        upTitle: 'Sort from A to Z by LastName',
        downLbl: 'Z-A',
        downTitle: 'Sort from Z-A by LastName',
      },
      dateFilters: {
        startDate: {
          value: this.getDatesFromPath('f'),
          error: false,
        },
        endDate: {
          value: this.getDatesFromPath('g'),
          error: false,
        },
      },
    };
    this.onToggleView = this.onToggleView.bind(this);
    this.onApplyDfaFilterStr = this.onApplyDfaFilterStr.bind(this);
    this.onStrFilterSubmit = this.onStrFilterSubmit.bind(this);
    this.onFilterStrChange = this.onFilterStrChange.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSort = this.onSort.bind(this);
    this.checkSituation = this.checkSituation.bind(this);
    this.onTabSelect = this.onTabSelect.bind(this);
    this.onOpenAsTab = this.onOpenAsTab.bind(this);
    this.onCloseTab = this.onCloseTab.bind(this);
    this.onConfDateFilterChange = this.onConfDateFilterChange.bind(this);
    this.clearDateFilters = this.clearDateFilters.bind(this);
    this.onForceRefresh = this.onForceRefresh.bind(this);
    this.onAutoRefresh = this.onAutoRefresh.bind(this);
    this.onToggleAutoRefresh = this.onToggleAutoRefresh.bind(this);
    this.saveToGoogleSheet = this.saveToGoogleSheet.bind(this);
    this.onSearchSubmitClickHandler =
      this.onSearchSubmitClickHandler.bind(this);
  }

  saveToGoogleSheet() {
    const { searchStr, sortCode, pageNum } = this.props.match.params;
    this.props.saveToGoogleSheet_ac(searchStr, sortCode, pageNum);
  }

  /**
   * Extract filter string of dates only, from route/path.
   *
   * @param {('f'|'g')} dateCode Date filter code. f -> start_date, g -> end_date.
   *
   * @returns {string} Date string for provided dateCode, if found.
   */
  getDatesFromPath(dateCode) {
    let dateRegExp = new RegExp(`${dateCode}:\\d\\d-\\d\\d-\\d\\d\\d\\d`);
    let dateStr = this.props.match.params.searchStr.match(dateRegExp);

    if (!dateStr || !dateStr[0]) return '';

    let dateOnly = dateStr[0].slice(2);

    if (!dateUtil.isValidDate(dateOnly)) return '';

    return dateOnly;
  }

  /**
   * Extract filter string, other than date filters, from route/path.
   *
   * @returns {string} Filter string with separator ';'.
   */
  getFilterStrFromPath() {
    const { searchStr = '' } = this.props.match.params;
    let filterStr = searchStr.slice(2);

    if (searchStr.slice(0, 2) !== 'z:' || !filterStr) return '';

    let filterStrList = filterStr
      .split(';')
      .filter((str) => !['f:', 'g:'].includes(str.slice(0, 2)));

    return filterStrList.join(';');
  }

  onTabSelect(e, selectedTab) {
    this.setState({ selectedTab });
  }

  onClearSearch() {
    this.setState({ dfaFilterStr: '' });
  }

  onSort = (kind) => () => {
    let newSortCode = this.state['sort' + kind].isUp
      ? this.state['sort' + kind].up
      : this.state['sort' + kind].down;
    console.log(newSortCode);

    this.setState({
      ['sort' + kind]: {
        ...this.state['sort' + kind],
        isUp: !this.state['sort' + kind].isUp,
      },
      lastSortBy: kind,
    });
    this.props.applyFilter(
      this.state.dfaFilterStr || this.props.match.params.searchStr,
      newSortCode
    ); //use current sort code
  };

  onFilterStrChange(e) {
    this.setState({ dfaFilterStr: e.target.value });
  }
  onApplyDfaFilterStr() {}

  onConfDateFilterChange(event) {
    const { name, value } = event.target;

    this.setState((state) => ({
      dateFilters: {
        ...state.dateFilters,
        [name]: {
          ...state.dateFilters[name],
          value: value,
        },
      },
    }));
  }

  clearDateFilters(filterName) {
    this.setState((state) => ({
      dateFilters: {
        ...state.dateFilters,
        [filterName]: {
          ...state.dateFilters[filterName],
          value: '',
          error: false,
        },
      },
    }));
  }

  onStrFilterSubmit(isForceRefresh = false) {
    const searchStr = this.state.dfaFilterStr;
    const { startDate, endDate } = this.state.dateFilters;
    const {
      searchStr: prevSearchStr,
      sortCode,
      pageNum,
    } = this.props.match.params;
    const landingSearchStr = this.state.landingSearchStr;

    const hasFilterValue = searchStr || startDate.value || endDate.value;

    if (!hasFilterValue && isForceRefresh) {
      this.props.searchStrSelected_ac(landingSearchStr, sortCode, pageNum);
      return this.props.applyFilter(landingSearchStr, sortCode);
    }

    if (!hasFilterValue) {
      return this.props.applyFilter(landingSearchStr, sortCode);
    }

    let newSearchStr = 'z:' + searchStr.replace(/#/g, '~');

    if (!this.validateDates()) return;

    if (startDate.value) {
      newSearchStr += `;f:${startDate.value}`;
    }

    if (endDate.value) {
      newSearchStr += `;g:${endDate.value}`;
    }

    newSearchStr = newSearchStr.replace(/z:[;]*/g, 'z:');
    newSearchStr = newSearchStr.replace(/;[;]+/, ';');

    if (newSearchStr === prevSearchStr && isForceRefresh) {
      this.props.searchStrSelected_ac(searchStr, sortCode, pageNum);
      return;
    }
    return this.props.applyFilter(newSearchStr, sortCode);
  }

  validateDates() {
    const { startDate, endDate } = this.state.dateFilters;

    let errorObj = {
      startDate: false,
      endDate: false,
    };

    if (startDate.value && !dateUtil.isValidDate(startDate.value)) {
      errorObj.startDate = true;
    }

    if (endDate.value && !dateUtil.isValidDate(endDate.value)) {
      errorObj.endDate = true;
    }

    this.setState((state) => ({
      dateFilters: {
        ...state.dateFilters,
        startDate: {
          ...state.dateFilters.startDate,
          error: errorObj.startDate,
        },
        endDate: {
          ...state.dateFilters.endDate,
          error: errorObj.endDate,
        },
      },
    }));

    return !(errorObj.startDate || errorObj.endDate);
  }

  componentDidMount() {
    this.checkSituation();
  }
  componentDidUpdate(prevProps) {
    this.checkSituation(prevProps);
  }
  componentWillUnmount() {
    console.log('clearing out autorefresh timer');
    clearInterval(this.state.timerId);
  }

  onToggleAutoRefresh() {
    if (this.state.autoRefreshOn) {
      //turn it off
      clearInterval(this.state.timerId);
      this.setState({
        autoRefreshOn: false,
        timerId: null,
      });
      return;
    }

    //turn it on
    //let timerId = setInterval(this.onAutoRefresh, 10000 + Math.random() * 10000)
    let timerId = setInterval(this.onAutoRefresh, 10000);
    this.setState({
      autoRefreshOn: true,
      timerId,
    });
  }

  onAutoRefresh() {
    const { searchStr, sortCode, pageNum } = this.props.match.params;

    const asRows = this.props.forceCardView
      ? false
      : _.get(this.props.me.user, 'defaults.df_list_view_mode', 'cards') ===
        'rows';

    console.log(
      'CHECK',
      _.get(this.props.dfaInView, 'results.rows', []).length
    );

    if (!asRows) {
      console.log('skipping auto refresh, not in rows view');
      return;
    }

    /*
    if (_.get(this.props.dfaInView, 'results.rows', []).length > 0) {
      console.log('skipping auto refresh, non-zero results')
      return
    }*/

    if (this.state.selectedTab !== 'homelist') {
      console.log('skipping auto refresh, not viewing home list');
      return;
    }

    this.props.open_snack_ac({
      variant: 'info',
      message: `...auto refreshing`,
    });

    if (this.props.allWorkUnits) {
      this.props.fetchAllWorkunitsForIdn_ac(searchStr, sortCode, pageNum);
    } else {
      this.props.searchStrSelected_ac(searchStr, sortCode, pageNum);
    }
  }

  onForceRefresh() {
    const { sortCode, pageNum } = this.props.match.params;
    const searchStr = this.state.dfaFilterStr;

    if (this.props.allWorkUnits) {
      this.props.fetchAllWorkunitsForIdn_ac(searchStr, sortCode, pageNum);
      return;
    }
    this.onStrFilterSubmit(true);
  }

  onSearchSubmitClickHandler = (event) => {
    clearTimeout(this.searchSubmitClickTimer);
    if (event.detail === 1) {
      this.searchSubmitClickTimer = setTimeout(this.onStrFilterSubmit, 200);
    } else if (event.detail === 2) {
      this.onForceRefresh();
    }
  };

  checkSituation(prevProps = null) {
    //prevProps will be available in case of componentDidUpdate only
    const { dfaInView } = this.props;
    const searchStr = this.props.match.params.searchStr;
    if (
      !searchStr.startsWith('z:') &&
      searchStr !== this.state.landingSearchStr
    ) {
      this.setState({ landingSearchStr: searchStr });
    }

    const hasSearchStrChanged = !(
      prevProps && prevProps.match.params.searchStr === searchStr
    );
    let pageNum = 1;
    let sortCode = (this.props.match.params.sortCode || 'nc').toString();
    if (undefined !== this.props.match.params.pageNum) {
      if (!isNaN(this.props.match.params.pageNum)) {
        pageNum = parseInt(this.props.match.params.pageNum);
      }
    }

    if (!['nc', 'oc', 'nd', 'od', 'az', 'za', 'ns', 'os'].includes(sortCode)) {
      sortCode = 'nc';
    }

    if (!searchStr) {
      return;
    }
    if (dfaInView.error && !hasSearchStrChanged) {
      return;
    }
    if (dfaInView.isFetching) {
      return;
    }

    if (
      prevProps &&
      prevProps.match.params.searchStr !== this.props.match.params.searchStr
    ) {
      this.setState({
        dfaFilterStr: this.getFilterStrFromPath(),
        dateFilters: {
          startDate: {
            value: this.getDatesFromPath('f'),
            error: false,
          },
          endDate: {
            value: this.getDatesFromPath('g'),
            error: false,
          },
        },
      });
    }

    if (this.props.idnInView) {
      if (
        dfaInView.results === null ||
        dfaInView.searchStr !== searchStr ||
        dfaInView.pageNum !== this.state.idnPage
      ) {
        this.props.fetchAllWorkunitsForIdn_ac(
          searchStr,
          'nc',
          this.state.idnPage
        );
      }
    } else if (
      dfaInView.results === null ||
      dfaInView.searchStr !== searchStr ||
      dfaInView.sortCode !== sortCode ||
      dfaInView.pageNum !== pageNum
    ) {
      //handle change
      if (this.props.allWorkUnits) {
        this.props.fetchAllWorkunitsForIdn_ac(searchStr, sortCode, pageNum);
      } else {
        this.props.searchStrSelected_ac(searchStr, sortCode, pageNum);
      }
    }

    if (this.props.hdmsNotes.noteHdrsFetching) return;
    if (
      this.props.hdmsNotes.noteHdrs === null &&
      this.props.hdmsNotes.noteHdrsFetching === false
    ) {
      this.props.fetchHdmsNoteHdrs_ac();
      return;
    }
    return;
  }

  handleDfaListPageChangeInIdn(targetPage) {
    this.setState({
      idnPage: targetPage,
    });
  }

  handlePageChange(newPage) {
    let targetPage = parseInt(newPage, 10);

    if (this.props.idnInView) {
      return this.handleDfaListPageChangeInIdn(targetPage);
    }

    this.props.changePage(targetPage);
    return;
  }

  onLocalUpdate(posInSet, pkg) {
    this.props.locallyUpdateDfaWorkUnit_ac(posInSet, pkg);
  }

  onToggleCards() {
    this.props.toggleAllCards_ac(!this.state.lastToggleAllCards);
    this.setState({ lastToggleAllCards: !this.state.lastToggleAllCards });
  }

  onToggleView() {
    const asRows =
      _.get(this.props.me.user, 'defaults.df_list_view_mode', 'cards') ===
      'rows';
    this.props.toggleUserPref_ac({
      prefName: 'df_list_view_mode',
      prefValue: asRows ? 'cards' : 'rows',
    });
  }

  onOpenAsTab =
    ({ idxInOa, nm, _id }) =>
    () => {
      const tab = {
        nm,
        _id,
        indexInOa: idxInOa,
      };
      if (this.state.tabbedIdns.find((tab) => tab.nm === nm)) return;

      this.setState({
        tabbedIdns: [...this.state.tabbedIdns, tab],
        selectedTab: nm,
      });
    };
  onCloseTab = (idn) => () => {
    if (!this.state.tabbedIdns.find((tab) => tab.nm === idn)) return;

    this.setState({
      tabbedIdns: [...this.state.tabbedIdns.filter((ti) => ti.nm !== idn)],
      selectedTab: 'homelist',
    });
  };

  render() {
    const { classes, dfaInView, me, location } = this.props;
    const { results } = dfaInView;
    const { dateFilters } = this.state;
    const queryParams = location.search
      ? queryString.parse(location.search)
      : {};

    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot,
    };
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer,
    };
    const allowSaveToGoogleSheet = _.intersection(
      ['it-admin', 'cs_director', 'executive'],
      me.user.roles
    ).length;

    const searchStr = this.props.match.params.searchStr || 'myq';
    const asRows = this.props.forceCardView
      ? false
      : _.get(me.user, 'defaults.df_list_view_mode', 'cards') === 'rows';
    const filterTextField = (
      <div className={classes.pagination}>
        <TextField
          fullWidth
          id="acct-search-entry"
          onChange={this.onFilterStrChange}
          onKeyPress={(e) =>
            e.key === 'Enter' ? this.onStrFilterSubmit() : ''
          }
          placeholder="Filter: Last, First; aAccount#; wOrder#; @PCRName; t:TeamName; p:Payer; s:TMName; -Product; #label; v:Divider; d:M-D-YYYY"
          value={this.state.dfaFilterStr}
          margin="dense"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 4 }}
                  onClick={this.onSearchSubmitClickHandler}
                  color="inherit">
                  <KeyboardReturnIcon fontSize="small" />
                </IconButton>
                <IconButton
                  style={{ padding: 4 }}
                  onClick={this.onClearSearch}
                  color="inherit">
                  <ClearIcon fontSize="small" />
                </IconButton>
                {!!allowSaveToGoogleSheet && (
                  <IconButton
                    style={{ padding: 4 }}
                    onClick={this.saveToGoogleSheet}
                    color="inherit">
                    <SaveIcon fontSize="small" />
                  </IconButton>
                )}
                {asRows && (
                  <Switch
                    title={
                      'Turn on/off auto refresh when (only when viewing as ROWS)'
                    }
                    checked={this.state.autoRefreshOn}
                    classes={{ switchBase: classes.sitSwitch }}
                    onChange={this.onToggleAutoRefresh}
                    value={'not_used'}
                  />
                )}
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: { fontSize: 12, padding: '4px 0' },
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    );

    let secondFilterRow = this.props.noSearchDfaSearchBar ? null : (
      <Grid container alignItems="flex-end">
        <FormControl
          className={classes.horizFormControl}
          style={{ width: 135 }}>
          <FormLabel className={classes.dateFilterLbl} title="Assigned From">
            From:
          </FormLabel>
          <TextField
            title="Assigned From Date"
            margin="dense"
            variant="outlined"
            name="startDate"
            placeholder="mm-dd-yyyy"
            value={dateFilters.startDate.value}
            error={dateFilters.startDate.error}
            onChange={this.onConfDateFilterChange}
            onKeyPress={(e) =>
              e.key === 'Enter' ? this.onStrFilterSubmit() : ''
            }
            InputProps={{
              style: { padding: 0 },
              endAdornment: (
                <InputAdornment
                  className={classes.dateFilterAdornment}
                  position="end">
                  <IconButton
                    style={{ padding: 4 }}
                    onClick={() => this.clearDateFilters('startDate')}
                    disabled={!dateFilters.startDate.value}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: classes.smallTextFieldInput,
            }}
          />
        </FormControl>
        <FormControl
          className={classes.horizFormControl}
          style={{ marginRight: 8, width: 115 }}>
          <FormLabel className={classes.dateFilterLbl}>to:</FormLabel>
          <TextField
            margin="dense"
            variant="outlined"
            name="endDate"
            title="Assigned TO Date"
            placeholder="mm-dd-yyyy"
            value={dateFilters.endDate.value}
            error={dateFilters.endDate.error}
            onChange={this.onConfDateFilterChange}
            onKeyPress={(e) =>
              e.key === 'Enter' ? this.onStrFilterSubmit() : ''
            }
            InputProps={{
              style: { padding: 0 },
              endAdornment: (
                <InputAdornment
                  className={classes.dateFilterAdornment}
                  position="end">
                  <IconButton
                    style={{ padding: 4 }}
                    onClick={() => this.clearDateFilters('endDate')}
                    disabled={!dateFilters.endDate.value}>
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: classes.smallTextFieldInput,
            }}
          />
        </FormControl>

        <div
          style={{ minWidth: 140, width: 'calc(100% - 258px)', flexGrow: 1 }}>
          {filterTextField}
        </div>
      </Grid>
    );

    // shouldn't be necessary
    if (!searchStr) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>Select Filter</h4>
        </div>
      );
    }

    if (dfaInView.isFetching) {
      return (
        <FetchingStatement>
          ...loading {this.props.dfaInView.searchStr}
        </FetchingStatement>
      );
    }

    if (dfaInView.error) {
      return (
        <>
          <FetchingStatement>Error fetching records.</FetchingStatement>
          {secondFilterRow}
        </>
      );
    }

    if (null === dfaInView.results || dfaInView.searchStr !== searchStr) {
      //handle change

      return (
        <FetchingStatement>
          ...loading {this.props.dfaInView.searchStr}
        </FetchingStatement>
      );
    }

    let pg = {
      start: 0,
      end: 0,
      totalRecords: 0,
      currentPageNum: 0,
      totalPagesAvailable: 0,
    };

    /*
    if (dfaInView.results.rows.length === 0) {
      return (
        <>
          <FetchingStatement>No results matching {searchStr}</FetchingStatement>
          {secondFilterRow}
        </>
      )
    } */

    if (dfaInView.results.rows.length > 0) {
      pg = results.pagination;
    }

    let pageStatement = [
      pg.start +
        1 +
        '-' +
        pg.end +
        ' of ' +
        pg.totalRecords +
        ' - Pg ' +
        (pg.currentPageNum + 1) +
        ' of ' +
        pg.totalPagesAvailable,
    ].join(' - ');

    /*
    let tacklingTitle = (dfaInView.posOfActivity!==null) ? `Tackling Card #${dfaInView.posOfActivity+1}` : 'None Are being Tackled'
    */

    let pageSelector = '';
    if (pg.totalPagesAvailable > 1) {
      pageSelector = (
        <Pagination
          currentPageNum={pg.currentPageNum}
          totalPagesAvailable={pg.totalPagesAvailable}
          onPageChange={this.handlePageChange}
        />
      );
    }

    let workTabs = [];
    if (asRows) {
      workTabs = (
        <Tabs
          classes={tabsClass}
          value={this.state.selectedTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={this.onTabSelect}
          variant="scrollable"
          scrollButtons="off">
          <Tab value="homelist" label="Listing" classes={tabClass} />
          {this.state.tabbedIdns.map((tabbedIdn) => (
            <Tab
              key={tabbedIdn.nm}
              value={tabbedIdn.nm}
              label={tabbedIdn.nm}
              classes={tabClass}
              onDoubleClick={this.onCloseTab(tabbedIdn.nm)}
            />
          ))}
        </Tabs>
      );
    }

    let firstFiltersRow = (
      <Grid
        container
        className={classes.testRie10}
        spacing={8}
        direction="row"
        justify="space-between"
        alignItems="flex-end">
        <Grid item xs style={{ minWidth: 100 }}>
          {this.props.noSearchDfaSearchBar ? (
            <Typography className={classes.pageStatement} color="textSecondary">
              {pageStatement}
            </Typography>
          ) : (
            <Typography className={classes.pageStatement} color="textSecondary">
              {pageStatement} - by{' '}
              {this.state['sort' + this.state.lastSortBy].isUp
                ? this.state['sort' + this.state.lastSortBy].downLbl
                : this.state['sort' + this.state.lastSortBy].upLbl}{' '}
            </Typography>
          )}
        </Grid>
        {pageSelector && (
          <Grid item xs style={{ minWidth: 232 }}>
            {pageSelector}
          </Grid>
        )}
        <Grid item xs style={{ minWidth: 64 }}>
          {this.props.noSearchDfaSearchBar ? null : (
            <Grid container direction="row-reverse">
              {this.props.forceCardView !== true && (
                <Typography
                  className={classes.toggleAllBtn}
                  color="textSecondary"
                  onClick={this.onToggleView}>
                  [{asRows ? 'Cards' : 'Rows'}]
                </Typography>
              )}
              <Typography
                className={classes.toggleAllBtn}
                color="textSecondary"
                onClick={this.onToggleCards}>
                [+/-]
              </Typography>

              <Typography
                className={classes.sortBtn}
                color="textSecondary"
                title={
                  this.state.sortAlpha.isUp
                    ? this.state.sortAlpha.upTitle
                    : this.state.sortAlpha.downTitle
                }
                onClick={this.onSort('Alpha')}>
                [
                {this.state.sortAlpha.isUp
                  ? this.state.sortAlpha.upLbl
                  : this.state.sortAlpha.downLbl}
                ]
              </Typography>
              <Typography
                className={classes.sortBtn}
                color="textSecondary"
                title={
                  this.state.sortAssigned.isUp
                    ? this.state.sortAssigned.upTitle
                    : this.state.sortAssigned.downTitle
                }
                onClick={this.onSort('Assigned')}>
                [
                {this.state.sortAssigned.isUp
                  ? this.state.sortAssigned.upLbl
                  : this.state.sortAssigned.downLbl}
                ]
              </Typography>

              <Typography
                className={classes.sortBtn}
                color="textSecondary"
                title={
                  this.state.sortDocs.isUp
                    ? this.state.sortDocs.upTitle
                    : this.state.sortDocs.downTitle
                }
                onClick={this.onSort('Docs')}>
                [
                {this.state.sortDocs.isUp
                  ? this.state.sortDocs.upLbl
                  : this.state.sortDocs.downLbl}
                ]
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );

    let homeList =
      this.state.selectedTab === 'homelist' ? (
        <div className={classes.content}>
          <Grid
            container
            spacing={8}
            direction={asRows ? 'row-reverse' : 'column'}
            justify="space-between"
            className={classes.filtersContainer}>
            <Grid item xs={12} md={asRows ? 5 : 12}>
              {firstFiltersRow}
            </Grid>
            <Grid item xs={12} md={asRows ? 6 : 12}>
              {!this.props.noSearchDfaSearchBar && secondFilterRow}
            </Grid>
          </Grid>

          <div
            id="dfaListPopperParent"
            className={classes.scrollArea}
            style={{ marginTop: 4, padding: 4 }}>
            {results.rows.map((r, posInSet) => {
              return (
                <DfaCard
                  asRow={asRows}
                  onOpenAsTab={this.onOpenAsTab}
                  onSwitchDocViewerTab={this.props.onSwitchDocViewerTab}
                  noteHdrs={this.props.hdmsNotes.noteHdrs}
                  key={r._id + '-' + posInSet}
                  posInSet={posInSet}
                  expanded={
                    r.expanded === undefined
                      ? queryParams.idxInOa === posInSet &&
                        queryParams._id === r._id
                      : r.expanded
                  }
                  inTackle={
                    r.inTackle === undefined
                      ? queryParams.idxInOa === posInSet &&
                        queryParams._id === r._id
                      : r.inTackle
                  }
                  wDoc={r}
                  onLocalUpdate={this.onLocalUpdate}
                  onTackleActivity={this.props.tackleActivity_ac}
                  onTackle={this.props.tackleDfaWorkUnit_ac}
                  onUnTackle={this.props.untackleDfaWorkUnit_ac}
                  onForceRefresh={this.onForceRefresh}
                />
              );
            })}
          </div>
        </div>
      ) : null;

    let tabbedWorkunits = this.state.tabbedIdns.map((tIdn) => (
      <iframe
        key={tIdn.nm}
        title="in_workunit"
        src={`/dfa_no_app_bar/idn/${tIdn.nm.replace(
          / /g,
          '_'
        )}/tabs/workunits?idxInOa=${tIdn.indexInOa}&_id=${tIdn._id}`}
        marginWidth="0"
        style={
          this.state.selectedTab === tIdn.nm
            ? { width: '99%', height: '99%', border: 0, display: 'block' }
            : { width: 0, height: 0, border: 0, display: 'none' }
        }
      />
    ));
    return (
      <>
        {workTabs}
        <div
          className={
            asRows ? classes.tabSpecificContainer : classes.fullHeight
          }>
          {homeList}
          {tabbedWorkunits}
        </div>
      </>
    );
  }
}

DfaList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  dfaInView: stateFromStore.dfaInView,
  hdmsNotes: stateFromStore.hdmsNotes,
});

const mapDispatchToProps = (dispatch, propsOfDfaList) =>
  bindActionCreators(
    {
      toggleUserPref_ac,
      searchStrSelected_ac,
      tackleDfaWorkUnit_ac,
      untackleDfaWorkUnit_ac,
      fetchAllWorkunitsForIdn_ac,
      fetchHdmsNoteHdrs_ac,
      open_snack_ac,
      tackleActivity_ac,
      locallyUpdateDfaWorkUnit_ac,
      toggleAllCards_ac,
      saveToGoogleSheet_ac,
      applyFilter: (str, sortCode) => {
        return push(`/dfa/workunits/${str}/${sortCode}/1`);
      },
      changePage: (pageNum) => {
        const p = propsOfDfaList.match.params;
        return push(`/dfa/workunits/${p.searchStr}/${p.sortCode}/${pageNum}`);
      },
    },
    dispatch
  );

export const DfaListWithRouter = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps //this provides DfaList with props.docSelected_ac
  )(withStyles(styles)(DfaList))
);

export default connect(
  mapStateToProps,
  mapDispatchToProps //this provides DfaList with props.docSelected_ac
)(withStyles(styles)(DfaList));
