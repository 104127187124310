import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BiBullseye } from 'react-icons/bi';
import { CgChevronDoubleRightR } from 'react-icons/cg';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { accountAndWorkUnitDocSelected_ac } from '../../actions/account_in_view.ac.js';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
import UserSearch from '../Users/UserSearch';
import DocSetEntry from './DocSetEntry';
import FaxDetails from './FaxDetails';
import Tackle from './Tackle';

const LABELS_V2 = ['payer_type', 'order_type', 'referral', 'trm_source'];

function ellipse(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '..' : str;
}
// const HDR_TEMPLATES = {
//   'FR - FINANCIAL RESPONSIBILITY': 'eligibility',
//   'CREDIT/DEBIT ADJUSTMENT REQUEST': 'adjustment_request'
// }
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    //overflowX: 'auto'
  },
  linkTo: {
    textDecoration: 'none',
    fontSize: '.82em',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  linkToNoLeftPad: {
    paddingLeft: 0,
  },
  linkToMissing: {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'purple',
      color: 'yellow',
    },
  },
  linkToPast: {
    color: '#fff',
    '&:hover': {
      backgroundColor: 'red',
      color: 'yellow',
    },
  },
  linkToPending: {
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
      color: 'yellow',
    },
  },
  linkToDone: {
    '&:hover': {
      backgroundColor: 'orange',
      color: 'yellow',
    },
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
    border: '1px solid #cfd8dc',
    backgroundColor: '#fafefff0',
  },

  /*

    new,
    pending,
    past-sla (new or pending)

    done,
    cancelled
  */

  cardHdrRoot: { cursor: 'pointer', padding: '8px 8px 8px 8px' },
  cardHdrContent: { fontSize: '.82em', fontWeight: 800 },
  cardHdrSubHdr: { fontSize: '.70em', color: '#000' },

  cardHdrRootMissing: {
    cursor: 'pointer',
    backgroundColor: 'purple',
    padding: '8px 8px 8px 8px',
    color: '#000',
  },
  cardHdrContentMissing: { fontSize: '.82em', fontWeight: 800, color: '#fff' },
  cardHdrSubHdrMissing: { color: '#fff' },

  cardHdrRootPast: {
    cursor: 'pointer',
    backgroundColor: 'red',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentPast: { fontSize: '.82em', fontWeight: 800, color: '#fff' },
  cardHdrSubHdrPast: { fontWeight: 700, color: '#fff' },

  cardHdrRootPending: {
    cursor: 'pointer',
    backgroundColor: 'orange',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentPending: { fontSize: '.82em', fontWeight: 800, color: '#000' },
  cardHdrSubHdrPending: { color: '#000' },

  cardHdrRootDone: {
    cursor: 'pointer',
    backgroundColor: '#56de93',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentDone: { fontSize: '.82em', fontWeight: 800, color: '#000' },
  cardHdrSubHdrDone: { color: '#000' },

  cardHdrRootCancelled: {
    cursor: 'pointer',
    backgroundColor: 'lightgray',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentCancelled: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },
  cardHdrSubHdrCancelled: { color: '#000' },

  cardHdrRootDone_Closed: {
    cursor: 'pointer',
    backgroundColor: '#cbc9dc',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentDone_Closed: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },
  cardHdrSubHdrDone_Closed: { fontSize: '.80em', color: '#000' },

  cardHdrRootCancelled_Closed: {
    cursor: 'pointer',
    backgroundColor: 'lightgray',
    padding: '8px 8px 8px 8px',
  },
  cardHdrContentCancelled_Closed: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },
  cardHdrSubHdrCancelled_Closed: { fontSize: '.80em', color: '#000' },

  cardContentRoot: { cursor: 'pointer', padding: 8 },
  cardContentRootPast: {},
  cardContentRootPending: {},
  cardContentRootDone: {},
  cardContentRootCancelled: { backgroundColor: '#d3d3d35c' },

  cardContentRootTackled: {
    fontSize: '.82em',
    fontWeight: 400,
    backgroundColor: '#ffffd6',
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chip: {
    height: 26,
    maxWidth: 140,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 3,
  },

  chipLabel: {
    padding: 3,
  },
  v2lbl: { fontSize: 10, fontWeight: 400 },
  mode: { backgroundColor: '#1DE9B6', fontWeight: 400, color: '#000' },
  modeSmall: { backgroundColor: '#1DE9B6', fontSize: 8, color: '#000' },

  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#000' },
  product: { backgroundColor: '#80DEEA', fontWeight: 400, color: '#000' },
  situationChipP: { backgroundColor: 'orange', fontWeight: 400, color: '#000' },
  situationChipD: {
    backgroundColor: '#56de93',
    fontWeight: 400,
    color: '#000',
  },
  situationChipC: {
    backgroundColor: '#d3d3d35c',
    fontWeight: 400,
    color: '#000',
  },

  owner: { color: '#7f8486' },
  wo_attached: { color: '#000', fontWeight: 700 },
  conf: { backgroundColor: '#80DEEA' },
  tm: { backgroundColor: '#689F38' },
  pre_ccs: {
    //backgroundColor: '#fff',
    color: '#000',
    height: 22,
    margin: '2px 2px 2px 0px',
    padding: 2,
  },
  payerChip: { backgroundColor: '#FFA726' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  hdmsAcctMissing: { backgroundColor: '#FF6E40', color: '#fff' },
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  // IDEA: k
  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  slaStart: {
    color: '#008AE0',
  },
  slaPasses: {
    color: 'red',
  },
  actions: {
    display: 'flex',
    maxHeight: 40,
  },
  actionsWithoutMaxHeight: {
    display: 'flex',
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteBlock: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
    fontSize: 11,
  },
  noteTxt: {
    color: '#4e6c7a',
    fontSize: 11,
    marginBottom: 4,
  },
  noteUser: {
    color: '#244e91',
  },
  docSetRefLbl: {
    paddingRight: 4,
  },
  wosLbl: {
    fontSize: 11,
  },
  wuLbl: {
    paddingRight: 4,
    fontSize: 11,
  },
  docSetLenLbl: {
    color: 'orange',
    float: 'right',
  },

  formControl: {
    margin: theme.spacing.unit * 1,
  },
  'PRE-CCS-LOBBY': {
    backgroundColor: '#1768AC',
    display: 'inline',
    borderRadius: 4,
    fontSize: 10,
    color: '#fff',
    padding: 4,
    fontWeight: 600,
  },
  'PRE-CCS-INPROG': {
    backgroundColor: '#1768AC',
    borderRadius: 4,
    fontSize: 10,
    display: 'inline',
    color: '#fff',
    padding: 4,
    fontWeight: 600,
  },
  'PRE-CCS-DONE': {
    backgroundColor: '#49107d',
    display: 'inline',
    fontSize: 10,
    color: '#fff',
    borderRadius: 4,
    padding: 4,
    fontWeight: 600,
  },
  ooUnits: {
    backgroundColor: 'yellow',
    border: '1px solid #000',
    display: 'block',
    borderRadius: 4,
    fontSize: 10,
    color: '#673AB7',
    padding: 4,
    fontWeight: 400,
  },
  attn_me: {
    backgroundColor: '#fff',
    border: '1px solid #000',
    display: 'inline',
    borderRadius: 4,
    fontSize: 10,
    color: '#673AB7',
    padding: 4,
    fontWeight: 400,
  },
  referral: {
    fontSize: 11,
    fontWeight: 600,
    color: '#C2185B',
  },
  preccs_warn: {
    borderRadius: 4,
    color: '#000',
    padding: 4,
    display: 'inline',
    fontSize: 10,
    backgroundColor: '#ff7600',
  },
  preccs_danger: {
    borderRadius: 4,
    color: '#fff',
    display: 'inline',
    padding: 4,
    backgroundColor: '#d40c0c',
    fontSize: 10,
  },
  preccs_safe: {
    display: 'inline',
    color: '#000',
    backgroundColor: 'green',
    borderRadius: 4,
    padding: 4,
    fontSize: 10,
  },
});
const MODES_NOT_ALLOWED_FOR_DELETE = ['E-SIGNED'];

class DfaCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      docsExpanded: false,
      raLogInCardExpanded: false,
      docsFetching: false,
      docsLoaded: false,
      workUnitDocs: {
        docs: [],
      },

      notesExpanded: false,
      notesFetching: false,
      notesLoaded: false,
      notes: [],
      selectedNoteHdr: -1,
      newNoteTxt: '',
      formExpanded: false,
      actionOpts: [
        {
          code: 'a',
          lbl: 'Attached correct Auth shell to claim',
          checked: false,
        },
        { code: 'b', lbl: 'Added HCPC to Auth Shell', checked: false },
        {
          code: 'c',
          lbl: 'Auth File updated with correct span date',
          checked: false,
        },
        {
          code: 'd',
          lbl: 'Corrected payer information, attached correct shell',
          checked: false,
        },
      ],
    };

    this.handleFormExpandClick = this.handleFormExpandClick.bind(this);
    this.handleDocSelected = this.handleDocSelected.bind(this);
    this.handleReassignLogExpandClick =
      this.handleReassignLogExpandClick.bind(this); //in card only
    this.handleDocsExpandClick = this.handleDocsExpandClick.bind(this);
    this.handleNotesExpandClick = this.handleNotesExpandClick.bind(this);
    this.handleTackleExpand = this.handleTackleExpand.bind(this);
    this.addDFNote = this.addDFNote.bind(this);
    this.onNoteEntry = this.onNoteEntry.bind(this);
    this.onSituationChipDelete = this.onSituationChipDelete.bind(this);
    this.onTackleActivity = this.onTackleActivity.bind(this);
    this.onLocalUpdate = this.onLocalUpdate.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.onNoteHdrSelect = this.onNoteHdrSelect.bind(this);
    this.showRAs = this.showRAs.bind(this);
    this.onUserSelectedForAttnMe = this.onUserSelectedForAttnMe.bind(this);
    this.onRemoveAttnMe = this.onRemoveAttnMe.bind(this);
    this.onUpdateAccountInViewWDoc = this.onUpdateAccountInViewWDoc.bind(this);
    this.onNewWorkunitCreated = this.onNewWorkunitCreated.bind(this);
  }

  showRAs() {
    this.onLocalUpdate({
      ...this.props.wDoc,
      displayRA: !this.props.wDoc.displayRA,
    });
  }

  toggleExpand() {
    this.onLocalUpdate({
      ...this.props.wDoc,
      expanded: !this.props.expanded,
    });
  }

  onUserSelectedForAttnMe(userPkg) {
    this.setState({
      newNoteTxt: userPkg.mail + ' ' + this.state.newNoteTxt,
    });
  }

  handleDocSelected(posOfDocSelected) {
    this.props.accountAndWorkUnitDocSelected_ac(
      this.state.workUnitDocs,
      posOfDocSelected,
      this.props.wDoc
    );
    return;
  }

  onUpdateAccountInViewWDoc(pkgToUpdateWDoc) {
    this.props.accountAndWorkUnitDocSelected_ac(
      this.state.workUnitDocs,
      this.props.accountInView.posOfWorkUnitDocSelected,
      { ...this.props.wDoc, ...pkgToUpdateWDoc }
    );
    return;
  }

  handleTackleExpand() {
    if (this.props.inTackle) {
      this.props.onUnTackle(this.props.posInSet);
    } else {
      this.props.onTackle(this.props.posInSet);
      if (!this.state.docsLoaded) {
        this.handleDocsExpandClick();
      } else {
        this.handleDocSelected(0);
      }
    }
    return;
  }

  onNewWorkunitCreated() {
    this.handleTackleExpand();
    setTimeout(this.props.onForceRefresh, 750);
  }

  handleFormExpandClick() {
    this.setState({ formExpanded: !this.state.formExpanded });
    return;
  }

  handleReassignLogExpandClick() {
    this.setState({ raLogInCardExpanded: !this.state.raLogInCardExpanded });
    return;
  }
  handleDocsExpandClick() {
    if (this.state.docsLoaded) {
      this.setState({ docsExpanded: !this.state.docsExpanded });
      return;
    }

    this.setState({
      docsExpanded: !this.state.docsExpanded,
      docsFetching: true,
    });

    let target_url = `/api/workitems:search`;
    let postData = {
      nm: this.props.wDoc.nm,
      extras: { sort: 'r', wId: this.props.wDoc._id },
    };
    return window.sch
      .post(target_url, postData)
      .then((results) => {
        results.wId = this.props.wDoc._id.toString(); //attach wId
        this.setState({
          docsFetching: false,
          docsLoaded: true,
          workUnitDocs: results,
        });
        this.handleDocSelected(0);
        return;
      })
      .catch((error) => {
        console.log('GOT ERROR FROM get docs');
        console.error('error:', error);
      });
  }

  handleNotesExpandClick() {
    if (this.state.notesLoaded) {
      this.setState({ notesExpanded: !this.state.notesExpanded });
      return;
    }

    this.setState({
      notesExpanded: !this.state.notesExpanded,
      notesFetching: true,
    });

    let target_url = `/api/workitem:getallnotes`;
    let postData = { wId: this.props.wDoc._id };
    return window.sch
      .post(target_url, postData)
      .then((results) => {
        console.log('got results', results);
        this.setState({
          notesFetching: false,
          notesLoaded: true,
          notes: results.wDoc.notes,
        });
        return;
      })
      .catch((error) => {
        console.error('error:', error);
      });
  }

  async onNoteHdrSelect(e) {
    let noteHdrId = e.target.value;
    let r;
    if (noteHdrId === -1) {
      r = { newnotedefault: '', id: -1 };
    } else {
      r = this.props.noteHdrs.filter((n) => n.id === noteHdrId)[0];
    }
    /*
    if (HDR_TEMPLATES[r.masternoteheading]) {
      let _tpl = await this.loadTplJson(HDR_TEMPLATES[r.masternoteheading])
      this.setState({
        selectedNoteHdr: r.id,
        noteText: r.newnotedefault.toString().trim(),
        tplsLoaded: {
          ...this.state.tplsLoaded,
          [HDR_TEMPLATES[r.masternoteheading]]: _tpl
        },
        useTpl: HDR_TEMPLATES[r.masternoteheading]
      })
      return
    }
    */

    this.setState({
      selectedNoteHdr: r.id,
      newNoteTxt: r.newnotedefault.toString().trim(),
      useTpl: false,
    });
  }

  addDFNote() {
    this.setState({ noteIsSaving: true });
    if (!this.state.notesExpanded || this.state.newNoteTxt.trim() === '')
      return;

    let { wDoc: dfaRecord } = this.props;

    let postData = {
      wId: dfaRecord._id,
      txt: this.state.newNoteTxt,
      nm: dfaRecord.nm,
    };

    let dfSaveResults;
    return window.sch
      .post(`/api/workitem:addnote`, postData)
      .then((results) => {
        dfSaveResults = results;
        if (this.state.selectedNoteHdr !== -1) {
          let hdmsPostData = {
            acctMember: dfaRecord.hdmsAccts[0],
            noteLibId: this.state.selectedNoteHdr,
            txt: postData.txt,
          };

          return window.sch.post(`/api/notes:hdms:add`, hdmsPostData);
        }
      })
      .then(() => {
        this.setState({
          noteIsSaving: false,
          newNoteTxt: '',
          notes: [dfSaveResults.newNote, ...this.state.notes],
        });
      })
      .catch(() => {
        console.log('GOT ERROR FROM workitems:search');
      });
  }

  onNoteEntry(e) {
    this.setState({ newNoteTxt: e.target.value });
  }

  onRemoveAttnMe() {
    let { wDoc: dfaRecord } = this.props;
    let my_email_prefix = this.props.me.user.mail.replace(/@.*/, '');
    let attn = dfaRecord.attn.filter((a) => a !== my_email_prefix);

    let postData = {
      wId: dfaRecord._id,
    };

    return window.sch
      .post(`/api/workitem:attn:remove`, postData)
      .then(() => {
        this.onLocalUpdate({
          ...this.props.wDoc,
          attn,
        });
      })
      .catch(() => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Error removing from your ATTN ME',
        });
        return;
      });
  }

  renderForm() {
    const { classes } = this.props;
    return (
      <CardActions>
        <FormControl
          required
          component="fieldset"
          className={classes.formControl}>
          {/*<FormLabel component="legend">Check all applicable</FormLabel> */}
          <FormHelperText>Check all applicable</FormHelperText>
          <FormGroup>
            {this.state.actionOpts.map((a, pos) => (
              <FormControlLabel
                key={a.code}
                control={<Checkbox checked={a.checked} value={a.code} />}
                label={a.lbl}
              />
            ))}
            <TextField
              id="outlined-full-width"
              label="PA Number"
              style={{ margin: '8px 8px 8px 0' }}
              placeholder="Entery PA# Here"
              helperText="Select document containing PA#"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormGroup>
        </FormControl>
      </CardActions>
    );
  }

  onLocalUpdate(pkg) {
    this.props.onLocalUpdate(this.props.posInSet, pkg);
  }

  renderWorkUnit_EntryPkt(cTime, doc, dIdx) {
    const { classes } = this.props;
    let workOrderLbl = null;
    if (doc.firstDocInDateSet) {
      let poa_kind = _.get(doc, 'wParent.poa.0.product_bn', false);
      if (poa_kind === 'WO-CONF') {
        workOrderLbl = _.get(doc, 'wParent.poa.0.product', '');
      }

      let pcr_attached_wos = _.get(doc, 'hdmsWIds', []);
      if (pcr_attached_wos.length > 0) {
        workOrderLbl = 'W' + pcr_attached_wos.join(', W');
      }
    }

    return (
      <div key={doc.wId + '-' + doc.docIdx} className={classes.docSetEntry}>
        {doc.firstDocInDateSet && (
          <span className={classes.wuLbl}>Received: {doc.faxTsMDY}</span>
        )}

        <DocSetEntry
          doc={doc}
          faxJob={(this.props.accountInView?.workUnitDocs?.faxJobs || [])[doc.faxJobIdx]}
          display="Date"
          selectionKey="posOfWorkUnitDocSelected"
          onSetClick={this.handleDocSelected.bind(this, dIdx)}
        />
        {workOrderLbl && <span className={classes.wosLbl}>{workOrderLbl}</span>}
      </div>
    );
  }

  /*
    pkg: {
      kind: 'the kind of activity'
      params: the activity specific params
    }
  */
  onTackleActivity(pkg) {
    this.props.onTackleActivity({ ...pkg, posInSet: this.props.posInSet });
  }

  onSituationChipDelete = (sit, sitIdx) => (e) => {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      wId: wDoc._id,
      idxInOa: wDoc.idxInOa,
      product: oa.product,
      product_bn: oa.product_bn,
      sitPkg: sit,
      sitAction: 'remove',
    };
    this.props.onTackleActivity({
      kind: 'prod_situation_update',
      params,
      oa,
      stayTackled: true,
      posInSet: this.props.posInSet,
    });
  };

  onModeStickerDelete = (modeLabel) => (e) => {
    const { wDoc } = this.props;
    const oa = wDoc.assignments[0][1][0];
    let params = {
      wId: wDoc._id,
      nm: wDoc.nm,
      labelAction: 'remove',
      label: { kind: 'mode', nm: modeLabel },
      idxOfProductToReplace: wDoc.idxInOa + 1, //because sc-ink workitem-product replace expects a user to enter a non zero-indexed 1 to indicate array entry 0
    };
    this.props.onTackleActivity({
      kind: 'update_label',
      params,
      oa,
      stayTackled: true,
      posInSet: this.props.posInSet,
    });
  };

  render() {
    const { classes } = this.props;
    const { wDoc, expanded, inTackle } = this.props;
    const chipLabel = {
      label: classes.chipLabel,
    };

    let oa;
    if (wDoc.assignments.length) {
      oa = wDoc.assignments[0][1][0];
    } else if (wDoc.pastAssignments && wDoc.pastAssignments.length) {
      oa = wDoc.pastAssignments[0][1][0];
    } else if (wDoc.pastOwners && wDoc.pastOwnersArr.lenght) {
      oa = _.toPairs(_.groupBy(wDoc.pastOwnersArr, 'user'))[0][1][0];
    } else {
      return <div>..error loading card</div>;
    }

    wDoc.is_open = wDoc.assignments.length ? true : false;
    if (wDoc.hdmsAccts === undefined) {
      wDoc.hdmsAccts = [];
    }
    const haChip = wDoc.hdmsAccts.length ? (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.hdmsAcct)}
        label={'A' + wDoc.hdmsAccts[0]}
        color="primary"
      />
    ) : (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.hdmsAcctMissing)}
        label={'ACCOUNT# MISSING'}
        color="primary"
      />
    );
    const tmChip = wDoc.labels.tm.length ? (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.tm)}
        label={ellipse(wDoc.labels.tm[0], 15)}
        color="primary"
      />
    ) : (
      ''
    );
    const payerChip = wDoc.labels.payer.length ? (
      <Chip
        classes={chipLabel}
        className={classNames(classes.chip, classes.payerChip)}
        label={wDoc.labels.payer[0]}
      />
    ) : (
      ''
    );

    let pChips = _.get(oa, 'sit', []).map((sit, sitIdx) => {
      return inTackle ? (
        <Chip
          key={'k' + sit.txt + sitIdx}
          classes={chipLabel}
          className={classNames(
            classes.chip,
            classes['situationChip' + sit.gc]
          )}
          onDelete={this.onSituationChipDelete(sit, sitIdx)}
          label={sit.txt}
          color="primary"
        />
      ) : (
        <Chip
          key={'k' + sit.txt + sitIdx}
          classes={chipLabel}
          className={classNames(
            classes.chip,
            classes['situationChip' + sit.gc]
          )}
          label={sit.txt}
          color="primary"
        />
      );
    });
    let mChips = _.get(wDoc.labels, 'mode', []).map((m, mIdx) => {
      return this.props.inTackle ? (
        <Chip
          key={'k' + m + mIdx}
          title={m}
          className={classNames(
            classes.chip,
            m.length > 8 ? classes.modeSmall : classes.mode
          )}
          classes={chipLabel}
          onDelete={
            !this.props.inTackle
              ? () => {}
              : MODES_NOT_ALLOWED_FOR_DELETE.includes(m)
              ? () => {}
              : this.onModeStickerDelete(m)
          }
          label={m.length > 12 ? m.substr(0, 12) + '...' : m}
        />
      ) : (
        <Chip
          key={'k' + m + mIdx}
          title={m}
          className={classNames(
            classes.chip,
            m.length > 8 ? classes.modeSmall : classes.mode
          )}
          classes={chipLabel}
          label={m.length > 12 ? m.substr(0, 12) + '...' : m}
        />
      );
    });

    let confChips = _.get(wDoc.labels, 'hdms', []).map((m, mIdx) => (
      <Chip
        key={'k' + m + mIdx}
        className={classNames(classes.chip, classes.conf)}
        classes={chipLabel}
        label={'CONF:' + m}
      />
    ));

    let productChip = (
      <Chip
        className={classNames(classes.chip, classes.product)}
        classes={chipLabel}
        label={oa.product}
      />
    );

    let preCcsChip = !oa.ps ? (
      ''
    ) : (
      <div className={classes[oa.ps.state]}>
        {ellipse(oa.ps.u_nm, 8) + '@' + oa.ps.state}{' '}
        {oa.ps.state !== 'DONE' && oa.ps_age_minutes
          ? oa.ps_age_minutes + ' min.'
          : ''}
      </div>
    );

    let psAge = oa.ps_age_minutes ? (
      <div className={classes['preccs_' + oa.ps_klass]}>
        PRE-CCS AGE: {oa.ps_age_minutes}
      </div>
    ) : (
      ''
    );

    let labelsV2Chip = '';
    if (wDoc.labels_v2) {
      labelsV2Chip = LABELS_V2.filter((k) => {
        return (
          wDoc.labels_v2 &&
          wDoc.labels_v2[k]?.length &&
          wDoc.labels_v2[k][0] !== 'OTHER'
        );
      }).map((k) => {
        return (
          <div key={k} title={k} className={classes.v2lbl}>
            V2:{wDoc.labels_v2[k][0]}
          </div>
        );
      });
    }

    let attnMe = _.get(wDoc, 'attn', []);
    let attnMeChip = '';
    if (attnMe.length > 0) {
      attnMeChip = (
        <div className={classes.attn_me}>ATTN: {attnMe.join(', ')}</div>
      );
    }

    /*
    new,
    pending,
    past-sla (new or pending)

    done,
    cancelled
  */
    const sitCode = _.get(oa, 'sit.0.gc', 'N');
    let st;
    switch (sitCode) {
      case 'N': //no situations tagged, assume new
        st =
          undefined === oa.slaPkg
            ? 'Missing'
            : oa.slaPkg.isPastDue
            ? 'Past'
            : '';
        break;
      case 'P':
        st =
          undefined === oa.slaPkg
            ? 'Missing'
            : oa.slaPkg.isPastDue
            ? 'Past'
            : 'Pending';
        break;
      case 'D':
      case 'DC':
        st = wDoc.is_open ? 'Done' : 'Done_Closed';
        break;
      case 'C':
        st = wDoc.is_open ? 'Cancelled' : 'Cancelled_Closed';
        break;
      default:
        break;
    }

    const cardHdrClasses = {
      root: classes['cardHdrRoot' + st],
      title: classes['cardHdrContent' + st],
      subheader: classNames(
        classes['cardHdrSubHdr' + st],
        classes.cardHdrSubHdr
      ),
    };
    const cardContentClasses = {
      root: classes['cardContentRoot' + (inTackle ? 'Tackled' : st)],
    };

    let oouChipColor = st === 'Past' || st === 'Pending' ? '#fff' : '#000';
    let oouChip = '';
    let oou_df = _.get(wDoc, 'oou.df_entries', []);
    if (oou_df.length) {
      let oouHover = `${oou_df.length} other open workunit(s) ${oou_df
        .map((e) => e.user + '@' + e.team + '/' + e.product)
        .join(', ')}`;
      oouChip = (
        <IconButton
          aria-label={oouHover}
          title={oouHover}
          style={{ padding: 2 }}>
          <CgChevronDoubleRightR
            style={{ width: 24, height: 24, color: oouChipColor }}
          />
        </IconButton>
      );
    }

    let oouWosChip = '';
    let oou_wos = _.get(wDoc, 'oou.wos_entries', []);
    if (oou_wos.length) {
      let oou_wos_hover = ` ${oou_wos.length} open HDMS WO(s): ${oou_wos
        .map((e) => 'W' + e.hdms_wId)
        .join(', ')} `;
      //oouWosChip = <BiBullseye title={oou_wos_hover} />
      oouWosChip = (
        <IconButton
          aria-label={oou_wos_hover}
          title={oou_wos_hover}
          style={{ padding: 2 }}>
          <BiBullseye style={{ height: 24, width: 24, color: oouChipColor }} />
        </IconButton>
      );
    }

    let cTime = moment(wDoc.m.created.ts).format('M-DD-YY h:mma');
    let indexingStatement = [wDoc.m.created.u_id, cTime].join(' ');

    let extraSection = '';
    let extraDiv = '';
    let noteCount = this.state.notesLoaded
      ? this.state.notes.length
      : _.get(wDoc, 'notes', []).length + wDoc.moreNotesCount;

    extraSection = (
      <CardActions
        classes={cardContentClasses}
        className={classes.actions}
        disableActionSpacing>
        <Button
          size="small"
          color="primary"
          onClick={this.handleNotesExpandClick}>
          Notes ({noteCount})
        </Button>
        <IconButton
          className={classes.expand}
          onClick={this.handleNotesExpandClick}
          aria-expanded={this.state.notesExpanded}
          aria-label="Show Notes">
          <SpeakerNotesIcon />
        </IconButton>
      </CardActions>
    );
    extraDiv = <Divider component="hr" />;

    let subTitleTxt = wDoc.is_open
      ? 'Assigned to:' + oa.user + '@' + oa.team
      : 'Closed by:' + oa.user + '@' + oa.team;

    if (_.get(wDoc, 'todo_ids', []).length) {
      subTitleTxt += ' , LinkedTasks: ' + wDoc.todo_ids.join(',');
    }
    let isConfWU = false;
    if (_.get(wDoc, 'ownersArr.0.product_bn', '') === 'WO-CONF') {
      isConfWU = _.get(wDoc, 'ownersArr.0.product', false);
    }

    const hdrTitle = (
      <div>
        <Link
          to={
            '/' +
            this.props.match.params.dfa_landing_kind +
            '/idn/' +
            wDoc.nm.replace(/ /g, '_') +
            '/tabs/doclisting'
          }
          className={classNames(classes['linkTo' + st], classes.linkTo)}>
          {wDoc.displayAs} {wDoc.hdmsAccts.map((a) => 'A' + a).join(' ')}
        </Link>
        <span
          title={'Time Assigned'}
          style={{ fontSize: '.82em', marginLeft: 4, marginRight: 4 }}>
          Rtd {moment(wDoc.m.created.ts).format('M-D-YY h:mma')}{' '}
          {wDoc.idxVersion ? '(V2)' : ''}
        </span>
        <span style={{ fontSize: '.82em' }}>
          {st === 'Past' ? ' PAST SLA' : ''}
        </span>
        <span style={{ marginLeft: 4, fontSize: '.82em' }}>{oa.product}</span>
        <div style={{ float: 'right' }}>
          {oouChip} {oouWosChip}
        </div>
      </div>
    );

    const slaStart = oa.slaPkg ? (
      <Typography
        title={
          oa.slaStart
            ? 'Actual SLA start: ' + moment(oa.slaStart).format('d MM-DD-YYYY')
            : ''
        }
        className={classes.slaStart}
        color="textSecondary">
        SLA Started: {oa.slaPkg.effStartStr}
      </Typography>
    ) : (
      ''
    );

    const slaPass = oa.slaPkg ? (
      <Typography className={classes.slaPasses} color="textSecondary">
        SLA Passes: {oa.slaPkg.dueDtStr}
      </Typography>
    ) : (
      ''
    );
    const slaPend = oa.lastPend ? (
      <Typography className={classes.slaStart} color="textSecondary">
        Last Pend: {moment(oa.lastPend).format('M-DD-YY ddd h:mma')}
      </Typography>
    ) : (
      ''
    );

    const noteForm = (
      <FormControl
        fullWidth
        required
        component="fieldset"
        className={classes.formControl}>
        {/*<FormLabel component="legend">Check all applicable</FormLabel> */}
        <FormGroup>
          <Select
            fullWidth
            value={this.state.selectedNoteHdr}
            onChange={this.onNoteHdrSelect}
            inputProps={{
              name: 'noteHdrId',
              id: 'noteHdrId',
            }}>
            <MenuItem value={-1}>
              Apply Note to this DocFlow Workunit Only (NOT HDMS)
            </MenuItem>
            {_.get(wDoc, 'hdmsAccts', []).length > 0 &&
              _.isArray(this.props['noteHdrs']) &&
              this.props['noteHdrs'].map((n) => (
                <MenuItem key={n.id} value={n.id}>
                  {n.masternoteheading}
                </MenuItem>
              ))}
          </Select>
          <TextField
            id="note-entry"
            onChange={this.onNoteEntry}
            placeholder="Type in new notes here"
            style={{ fontSize: 10, margin: '8px 0 8px 0' }}
            value={this.state.newNoteTxt}
            multiline
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <UserSearch
            placeholder="Search TeamMember for ATTN ME"
            onSelect={this.onUserSelectedForAttnMe}
          />
        </FormGroup>
        <Button
          size="small"
          color="primary"
          style={{ marginTop: 16 }}
          variant="outlined"
          onClick={this.addDFNote}>
          {this.state.selectedNoteHdr === -1
            ? 'Save as DF Note Only'
            : 'Save as HDMS Note and DF Note'}
        </Button>
      </FormControl>
    );

    return this.props.asRow ? (
      <Grid
        container
        spacing={8}
        style={{ borderBottom: '1px solid rgb(49.1%, 46.8%, 46.8%)' }}>
        <Grid item xs={3}>
          <Button
            size="small"
            onClick={this.props.onOpenAsTab(wDoc)}
            className={classNames(
              classes['linkTo'],
              classes.linkTo,
              classes.linkToNoLeftPad
            )}>
            {wDoc.displayAs} {wDoc.hdmsAccts.map((a) => 'A' + a).join(' ')}{' '}
          </Button>
          <div style={{ fontSize: 10 }}>
            <div style={{ fontWeight: 'bold' }}>
              Assigned {moment(wDoc.m.created.ts).format('M-D-YY h:mma')}{' '}
              {wDoc.idxVersion ? '(V2)' : ''}
            </div>
            {wDoc.docs.map((d) => (
              <span key={d} style={{ paddingRight: 8 }}>
                {d}
              </span>
            ))}
          </div>
          <div style={{ fontSize: 10 }}>
            <Link
              to={
                '/' +
                this.props.match.params.dfa_landing_kind +
                '/idn/' +
                wDoc.nm.replace(/ /g, '_') +
                '/tabs/doclisting'
              }
              className={classNames(classes.linkTo)}>
              Go to Account
            </Link>
            <a
              style={{ marginLeft: 10 }}
              href={
                '/' +
                this.props.match.params.dfa_landing_kind +
                '/idn/' +
                wDoc.nm.replace(/ /g, '_') +
                '/tabs/doclisting'
              }
              target={'Tab_' + wDoc.nm.replace(/ /g, '_')}
              className={classNames(classes.linkTo)}>
              New Chrome Tab
            </a>
          </div>
          {(oouChip || oouWosChip) && (
            <div style={{ backgroundColor: 'lightgray' }}>
              {oouChip} {oouWosChip}
            </div>
          )}
        </Grid>
        <Grid item xs={3}>
          {productChip}
          {mChips}
          {haChip}
          {tmChip}
          {payerChip}

          {wDoc.referral && (
            <div>
              <Typography className={classes.referral} color="primary">
                Referral: {wDoc.referral.last} {wDoc.referral.first} NPI:{' '}
                {wDoc.referral.npi}
              </Typography>
            </div>
          )}

          {wDoc.payer && (
            <div>
              <Typography className={classes.referral} color="primary">
                Payer: {wDoc.payer.id} {wDoc.payer.name}{' '}
                {wDoc.payer.payertypename}
              </Typography>
            </div>
          )}

          <div>
            {_.get(wDoc, 'notes', []).length > 0 && (
              <div
                component="paragraph"
                className={classes.noteBlock}
                color="textSecondary">
                {(typeof wDoc.notes[0].txt === 'string'
                  ? wDoc.notes[0].txt.split(/\n/g)
                  : wDoc.notes[0].txt
                ).map((t, tidx) => (
                  <div key={tidx} className={classes.noteTxt}>
                    {t}
                  </div>
                ))}

                <i className={classes.noteUser}>
                  {wDoc.notes[0].nm} -{' '}
                  {moment(wDoc.notes[0].ts).format('M-D-YY h:mma')}
                </i>
                {noteCount > 1 && (
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.handleNotesExpandClick}>
                    [+/-{' '}
                    {this.state.notesExpanded
                      ? 'Hide'
                      : 'Show ' + (noteCount - 1) + ' More'}
                    ]
                  </Button>
                )}
              </div>
            )}
            {this.state.notesExpanded &&
              this.state.notes.slice(1).map((n, nIdx) => (
                <div
                  key={wDoc._id + '-' + nIdx}
                  component="paragraph"
                  className={classes.noteBlock}
                  color="textSecondary">
                  {(typeof n.txt === 'string' ? n.txt.split(/\n/g) : n.txt).map(
                    (t, tidx) => (
                      <div key={tidx} className={classes.noteTxt}>
                        {t}
                      </div>
                    )
                  )}

                  <i className={classes.noteUser}>
                    {n.nm} - {moment(n.ts).format('M-D-YY h:mma')}
                  </i>
                </div>
              ))}
          </div>
        </Grid>
        <Grid item xs={2}>
          <div style={{ fontWeight: 'bold', fontSize: 11 }}>
            {oa.user}@{oa.team}
          </div>
          {slaStart}
          {slaPass}
          {slaPend}
          {psAge}

          {_.get(wDoc, 'todo_ids', []).length > 0 && (
            <div style={{ fontWeight: 'bold', fontSize: 11 }}>
              LinkedTasks: {wDoc.todo_ids.join(',')}
            </div>
          )}
        </Grid>
        <Grid item xs={2}>
          {pChips}
          {confChips}
          {oa.dt_pkg && (
            <div style={{ fontSize: 11, fontWeight: 500 }}>
              HDMS WO: {oa.dt_pkg.sched_dt_str}
              {oa.dt_pkg.dc_nm}
              <br />
              {oa.dt_pkg.cancel_dt && ' CANCELLED ' + oa.dt_pkg.cancel_dt_str}
              {oa.dt_pkg.confirm_dt && ' CONFIRMED ' + oa.dt_pkg.confirm_dt_str}
            </div>
          )}
          {wDoc.ra_log && wDoc.ra_log.length > 0 && (
            <div style={{ fontSize: 11, fontWeight: 200 }}>
              <div
                style={{ cursor: 'pointer', padding: 2 }}
                onClick={this.showRAs}>
                {wDoc.ra_log.length} Reassigns
              </div>
              {wDoc.displayRA &&
                wDoc.ra_log.map((ra, ri) => (
                  <div key={ri} style={{ padding: 2 }}>
                    from {ra.lastUser}@{ra.lastTeam} by {ra.u_id} {ra.ts}
                  </div>
                ))}
            </div>
          )}
          <div>
            {preCcsChip} {attnMeChip} {labelsV2Chip}
          </div>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.slaStart} color="textSecondary">
            Indexed by {wDoc.m.created.u_id}{' '}
            {moment(wDoc.m.created.ts).format('M-D-YY h:mma')}
          </Typography>
          {}
        </Grid>
      </Grid>
    ) : (
      <Card className={classes.card}>
        {
          <CardHeader
            onClick={this.toggleExpand}
            title={hdrTitle}
            subheader={
              <div>
                <div style={{ marginBottom: 4 }}>
                  <span style={{ marginRight: 8 }}>{subTitleTxt}</span>
                  {isConfWU && (
                    <span style={{ marginRight: 8 }}>{isConfWU}</span>
                  )}
                  <span
                    style={{
                      fontWeight: expanded ? 400 : 900,
                      cursor: 'pointer',
                    }}>
                    [+/-]
                  </span>
                </div>
                {preCcsChip} {attnMeChip}
              </div>
            }
            classes={cardHdrClasses}
          />
        }
        {expanded && (
          <>
            <CardContent classes={cardContentClasses}>
              <Chip
                classes={chipLabel}
                className={classNames(classes.chip, classes.product)}
                label={oa.product}
                color="primary"
              />
              {mChips}
              {haChip}
              {tmChip}
              {payerChip}
              {pChips}

              <Grid container direction="row" spacing={8}>
                <Grid item sm={6}>
                  <Typography className={classes.owner} color="primary">
                    Indexed by: {indexingStatement}
                  </Typography>
                  {_.get(wDoc.m, 'docsappended', []).map((da) => (
                    <Typography
                      className={classes.owner}
                      key={da.ts}
                      color="primary">
                      Docs added:{' '}
                      {da.u_id + ' ' + moment(da.ts).format('M-DD-YY h:mma')}
                    </Typography>
                  ))}
                  {_.get(oa, 'wos', []).length > 0 && (
                    <Typography className={classes.wo_attached} color="primary">
                      Work orders attached:{' '}
                      {oa.wos.map((w) => 'W' + w.id).join(', ')}
                    </Typography>
                  )}

                  {wDoc.referral && (
                    <Typography className={classes.referral} color="primary">
                      Referral: {wDoc.referral.last} {wDoc.referral.first} NPI:{' '}
                      {wDoc.referral.npi}
                    </Typography>
                  )}

                  {wDoc.payer && (
                    <Typography className={classes.referral} color="primary">
                      Payer: {wDoc.payer.id} {wDoc.payer.name}{' '}
                      {wDoc.payer.payertypename}
                    </Typography>
                  )}
                  {labelsV2Chip && (
                    <Typography className={classes.referral} color="primary">
                      {labelsV2Chip}
                    </Typography>
                  )}

                  {_.get(wDoc, 'attn', []).includes(
                    this.props.me.user.mail.replace(/@.*/, '')
                  ) && (
                    <Typography
                      style={{
                        cursor: 'pointer',
                        color: 'blue',
                        fontWeight: 'bold',
                        fontSize: 11,
                      }}
                      onClick={this.onRemoveAttnMe}
                      color="primary">
                      [REMOVE ATTN ME]
                    </Typography>
                  )}
                </Grid>
                {/*
              <ReassignTrigger
                wId={wDoc._id}
                displayAs={wDoc.displayAs}
                product={wDoc.assignments[0][1][0].product}
                product_bn={wDoc.assignments[0][1][0].product_bn}
                team={wDoc.assignments[0][1][0].team}
                user={wDoc.assignments[0][1][0].user}
                onComplete={() => { setTimeout(window.location.reload.bind(window.location), 1000) }}>
                <Typography>Reassign</Typography>
              </ReassignTrigger>
              */}
                <Grid item sm={6}>
                  <Typography
                    className={classes.slaStart}
                    color="textSecondary">
                    Workunit Type: {oa.product_bn}
                  </Typography>

                  {slaStart}
                  {slaPass}
                  {slaPend}
                </Grid>
              </Grid>
            </CardContent>

            <Divider component="hr" />

            <CardActions
              classes={cardContentClasses}
              className={classes.actions}
              disableActionSpacing>
              <Button
                size="small"
                color="primary"
                onClick={this.handleDocsExpandClick}>
                Document Set (
                {this.state.docsExpanded
                  ? this.state.workUnitDocs?.docs?.length || 0
                  : wDoc.doc_count}
                )
              </Button>
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: this.state.docsExpanded,
                })}
                onClick={this.handleDocsExpandClick}
                aria-expanded={this.state.docsExpanded}
                aria-label="Show Document Set">
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>

            <Collapse in={this.state.docsExpanded} timeout="auto" unmountOnExit>
              <CardContent classes={cardContentClasses}>
                {this.state.workUnitDocs.docs.map((doc, dIdx) => {
                  return this.renderWorkUnit_EntryPkt(cTime, doc, dIdx);
                })}
                <Link
                  to={
                    '/' +
                    this.props.match.params.dfa_landing_kind +
                    '/idn/' +
                    wDoc.nm.replace(/ /g, '_') +
                    '/tabs/doclisting'
                  }
                  className={classes.linkTo}>
                  <Typography className={classes.posVA} color="textSecondary">
                    Go to Patient Document listing
                  </Typography>
                </Link>
              </CardContent>
              {/*
            <CardContent>
              <Typography className={classes.pos} color="textSecondary">
                {docList} - also commented out above
              </Typography>
            </CardContent>
            */}
            </Collapse>

            {((wDoc.ra_log && wDoc.ra_log.length > 0) ||
              (wDoc.pastOwnersArr && wDoc.pastOwnersArr.length > 0)) && (
              <Divider component="hr" />
            )}
            {((wDoc.ra_log && wDoc.ra_log.length > 0) ||
              (wDoc.pastOwnersArr && wDoc.pastOwnersArr.length > 0)) && (
              <CardActions
                classes={cardContentClasses}
                className={classes.actions}
                disableActionSpacing>
                <Button
                  size="small"
                  color="primary"
                  onClick={this.handleReassignLogExpandClick}>
                  Reassigns ({_.get(wDoc, 'ra_log', []).length}) and Closed
                  Workunits ({_.get(wDoc, 'pastOwnersArr', []).length})
                </Button>
                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: this.state.raLogInCardExpanded,
                  })}
                  onClick={this.handleReassignLogExpandClick}
                  aria-expanded={this.state.raLogInCardExpanded}
                  aria-label="Show Reassignment Log">
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            )}
            <Collapse
              in={this.state.raLogInCardExpanded}
              timeout="auto"
              unmountOnExit>
              <CardContent classes={cardContentClasses}>
                {wDoc.pastOwnersArr && wDoc.pastOwnersArr.length > 0 && (
                  <div style={{ fontSize: 11, fontWeight: 200 }}>
                    <div
                      style={{ fontWeight: 500, padding: 2 }}
                      onClick={this.showRAs}>
                      {wDoc.pastOwnersArr.length} Closed Workunits:
                    </div>
                    {wDoc.pastOwnersArr.map((poa, ri) => (
                      <div key={ri} style={{ padding: 2 }}>
                        {poa.product_bn}
                        {' -> '}
                        {poa.sit && poa.sit.length > 0
                          ? [
                              poa.sit.map((s) => s.txt).join(','),
                              'by',
                              `${poa.user}@${poa.team}`,
                              moment(poa.sit[0].ts).format('M-D-YY h:mma'),
                            ].join(' ')
                          : 'N/A'}
                      </div>
                    ))}
                  </div>
                )}

                {wDoc.ra_log && wDoc.ra_log.length > 0 && (
                  <div style={{ fontSize: 11, fontWeight: 200 }}>
                    <div
                      style={{ fontWeight: 500, padding: 2 }}
                      onClick={this.showRAs}>
                      {wDoc.ra_log.length} Reassigns
                    </div>
                    {wDoc.ra_log.map((ra, ri) => (
                      <div key={ri} style={{ padding: 2 }}>
                        from {ra.lastUser}@{ra.lastTeam} by {ra.u_id} {ra.ts}
                      </div>
                    ))}
                  </div>
                )}
              </CardContent>
            </Collapse>

            {extraDiv}
            {extraSection}
            <Collapse
              in={this.state.notesExpanded}
              timeout="auto"
              unmountOnExit>
              <CardActions
                classes={cardContentClasses}
                className={classes.actionsWithoutMaxHeight}
                disableActionSpacing>
                {noteForm}
              </CardActions>

              <CardContent classes={cardContentClasses}>
                {this.state.notes.map((n, nIdx) => (
                  <div
                    key={wDoc._id + '-' + nIdx}
                    component="paragraph"
                    className={classes.noteBlock}
                    color="textSecondary">
                    {(typeof n.txt === 'string'
                      ? n.txt.split(/\n/g)
                      : n.txt
                    ).map((t, tidx) => (
                      <div key={tidx} className={classes.noteTxt}>
                        {t}
                      </div>
                    ))}

                    <i className={classes.noteUser}>
                      {n.nm} - {moment(n.ts).format('M-D-YY h:mma')}
                    </i>
                  </div>
                ))}
              </CardContent>
            </Collapse>
            {wDoc.disable_until_refresh === true ? (
              <>
                <Divider component="hr" />
                <CardActions
                  classes={cardContentClasses}
                  className={classes.actions}
                  disableActionSpacing>
                  <Button size="small" color="primary">
                    {wDoc.is_open
                      ? 'TACKLE DISABLED UNTIL REFRESH'
                      : 'THIS WORK UNIT IS CLOSED'}
                  </Button>
                </CardActions>
              </>
            ) : (
              <>
                <Divider component="hr" />
                <CardActions
                  classes={cardContentClasses}
                  className={classes.actions}
                  disableActionSpacing>
                  <Button
                    size="small"
                    color="primary"
                    onClick={
                      //wDoc.is_open ? this.handleTackleExpand : this.doNothing
                      this.handleTackleExpand
                    }>
                    {!wDoc.is_open
                      ? 'TACKLE - THIS WORK UNIT IS CLOSED - LIMITED ACTIONS'
                      : inTackle
                      ? 'CLICK TO UNTACKLE'
                      : 'TACKLE'}
                  </Button>
                  {wDoc.is_open && (
                    <IconButton
                      className={classNames(classes.expand, {
                        [classes.expandOpen]: this.props.inTackle,
                      })}
                      onClick={this.handleTackleExpand}
                      aria-expanded={inTackle}
                      aria-label="Tackle Workunit">
                      <ExpandMoreIcon />
                    </IconButton>
                  )}
                </CardActions>
                <Collapse in={inTackle} timeout="auto" unmountOnExit>
                  <CardContent classes={cardContentClasses}>
                    <Tackle
                      onNewWorkunitCreated={this.onNewWorkunitCreated}
                      onUpdateAccountInViewWDoc={this.onUpdateAccountInViewWDoc}
                      classes={cardContentClasses}
                      onSwitchDocViewerTab={this.props.onSwitchDocViewerTab}
                      wDoc={wDoc}
                      noteHdrs={this.props.noteHdrs}
                      onTackleActivity={this.onTackleActivity}
                      onLocalUpdate={this.onLocalUpdate}
                    />
                  </CardContent>
                </Collapse>

                {this.props.wDoc.faxJobs && (
                  <>
                    <Divider component="hr" />
                    <FaxDetails allowCloning faxJobs={wDoc.faxJobs} />
                  </>
                )}
              </>
            )}
            {/*
          <Divider component='hr'/>
          <CardActions className={classes.actions} disableActionSpacing>
            <Button size="small" color="primary"
              onClick={this.handleFormExpandClick}
            >
              VALIDATION CHECKS
            </Button>
            <IconButton
              className={classNames(classes.expand, {
                [classes.expandOpen]: this.state.formExpanded,
              })}
              onClick={this.handleFormExpandClick}
              aria-expanded={this.state.formExpanded}
              aria-label="Show Validation Checks"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={this.state.formExpanded} timeout="auto" unmountOnExit>
            {this.renderForm()}
          </Collapse>
          */}
          </>
        )}
      </Card>
    );
  }
}

DfaCard.propTypes = {
  classes: PropTypes.object.isRequired,
  wDoc: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  lookups: stateFromStore.lookups,
  accountInView: stateFromStore.accountInView,
  todosInView: stateFromStore.todosInView,
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      accountAndWorkUnitDocSelected_ac,
      open_snack_ac,
      close_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DfaCard))
);

//export default withStyles(styles)(DfaCard);
