import Badge from '@material-ui/core/Badge'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import { green } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import SmsIcon from '@material-ui/icons/Sms'
import StarIcon from '@material-ui/icons/Star'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac } from '../../actions/me.ac.js'
import { fetchOnboardedReport_ac } from '../../actions/onboarded_report.ac'
import FilterListing from '../../components/FilterListing'
import Loader from '../Shared/Loader'
import StyledTooltip from '../Shared/StyledTooltip'
import BaseLandingTemplate from './BaseLandingTemplate.Parent'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  noBorderBottom: {
    borderBottom: 'unset'
  },
  collapsableRowContent: {
    backgroundColor: 'rgb(0, 0, 0, 0.04)',
    boxShadow: '0px 0px 5px grey inset'
  },
  collapsableCellSpacing: {
    padding: '4px',
    margin: '4px 0px'
  },
  collapsableCellScroll: {
    maxHeight: '250px',
    overflowY: 'auto'
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.primary.main,
    transition: 'transform 0.3s'
  },
  expandedIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main,
    transition: 'transform 0.3s'
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3
  },
  verifiedChip: {
    backgroundColor: green['300']
  },
  unverifiedChip: {
    backgroundColor: theme.palette.secondary['300']
  },
  itemContainer: {
    margin: '4px',
    padding: 4,
    backgroundColor: `${theme.palette.secondary.main}10`
  },
  customBadge: {
    right: -5
  }
})

const DEFAULT_FILTERS = {
  state: {
    kind: 'checkbox',
    lbl: 'Status',
    options: [
      { lbl: 'Verified', code: 'VERIFIED', checked: false },
      { lbl: 'Unverified', code: 'UNVERIFIED', checked: false }
    ]
  },
  sortBy: {
    kind: 'radio',
    lbl: 'Sort By',
    options: [
      { lbl: 'Account', code: 'ACCOUNT' },
      { lbl: 'Name', code: 'NAME' },
      { lbl: 'Onboarded Date', code: 'DATE' }
    ]
  },
  sortOrder: {
    kind: 'radio',
    lbl: 'Sort Order',
    options: [{ lbl: 'Ascending', code: 'A' }, { lbl: 'Descending', code: 'D' }]
  },
  startDate: {
    lbl: 'From',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mm a',
    value: '',
    dirty: false,
    error: false
  },
  endDate: {
    lbl: 'To',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mm a',
    value: '',
    dirty: false,
    error: false
  }
}

const languageMap = {
  en: 'English'
}

const NOTIFICATION_KEYS = [
  'ConnectQueueHold',
  'PatientOrderStatus',
  'PatientMessageSimple',
  'InternalErrors',
  'PaperlessDone',
  'DocFlowFaxDrive'
]

const LabelValueSet = props => {
  return (
    <Typography component="div" gutterBottom>
      <Typography color="primary">
        <strong>{props.label}:</strong>&ensp;
      </Typography>
      <div>{props.value}</div>
    </Typography>
  )
}

const ExpandableRow = props => {
  const { record, classes, allExpanded } = props

  const [isExpanded, setIsExpanded] = useState(allExpanded)

  useEffect(() => {
    return setIsExpanded(allExpanded)
  }, [allExpanded])

  return (
    <>
      <TableRow>
        <TableCell
          width="1%"
          style={{ padding: 0 }}
          className={classes.noBorderBottom}>
          <IconButton
            className={isExpanded ? classes.expandedIcon : classes.expandIcon}
            onClick={() => setIsExpanded(!isExpanded)}>
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
        <TableCell width="22%" className={classes.noBorderBottom}>
          <div
            style={{ fontWeight: 600, cursor: 'pointer' }}
            onClick={() => {
              props.changePage(
                '/redirect_to_patient/' +
                  record.Account +
                  '-' +
                  record.Member +
                  '/account_info'
              )
            }}>
            A{record.Account}-{record.Member}
          </div>
          {moment(record.Timestamps.Verified).isValid() ? (
            <Chip
              className={classes.chip + ' ' + classes.verifiedChip}
              label="VERIFIED"
            />
          ) : (
            <Chip
              className={classes.chip + ' ' + classes.unverifiedChip}
              label="UNVERIFIED"
            />
          )}
        </TableCell>
        <TableCell width="22%" className={classes.noBorderBottom}>
          <StyledTooltip title={record.IsMaster ? 'Master User' : ''}>
            <Badge
              badgeContent={
                record.IsMaster && (
                  <StarIcon color="secondary" style={{ fontSize: '16px' }} />
                )
              }
              classes={{
                badge: classes.customBadge
              }}>
              <Typography color="primary" inline>
                <strong>{[record.FirstName, record.LastName].join(' ')}</strong>
              </Typography>
            </Badge>
          </StyledTooltip>
        </TableCell>
        <TableCell width="24%" className={classes.noBorderBottom}>
          <Typography color="textPrimary">
            Language:{' '}
            {languageMap[record.Preferences.Language] ||
              record.Preferences.Language}
          </Typography>
          <Typography color="textSecondary">
            and{' '}
            {
              NOTIFICATION_KEYS.filter(
                notif =>
                  record.Preferences.Notifications[notif].Email ||
                  record.Preferences.Notifications[notif].SMS
              ).length
            }{' '}
            more on Notifications
          </Typography>
        </TableCell>
        <TableCell width="16%" className={classes.noBorderBottom}>
          {record.Email !== 'UNKNOWN' ? (
            <StyledTooltip placement="left" title="Email">
              <Typography color="textPrimary">
                <MailOutlineIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginRight: 4 }}
                />
                {record.Email}
              </Typography>
            </StyledTooltip>
          ) : (
            ''
          )}
          {record.SMS !== 'UNKNOWN' ? (
            <StyledTooltip placement="left" title="SMS">
              <Typography color="textPrimary">
                <SmsIcon
                  fontSize="small"
                  color="primary"
                  style={{ marginRight: 4 }}
                />
                {record.SMS}
              </Typography>
            </StyledTooltip>
          ) : (
            ''
          )}
        </TableCell>
        <TableCell width="15%" className={classes.noBorderBottom}>
          <Typography color="textPrimary">
            Created at:{' '}
            {moment(Number(record.TimestampsUTC.Created)).format(
              'MM-DD-YYYY hh:mm a'
            )}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: 0 }}>
        <TableCell width="1%" style={{ padding: 0 }} />
        <TableCell
          colSpan={5}
          style={{ padding: 0 }}
          width="99%"
          className={classes.collapsableRowContent}>
          <Collapse in={isExpanded}>
            <Table padding="dense">
              <TableBody>
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell width="22.22%">
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Account ID"
                        value={record.Account + '-' + record.Member}
                      />
                      <LabelValueSet
                        label="Status"
                        value={
                          moment(record.Timestamps.Verified).isValid()
                            ? 'VERIFIED'
                            : 'UNVERIFIED'
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell width="22.22%">
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Name"
                        value={
                          <>
                            {[record.FirstName, record.LastName].join(' ')}
                            {record.IsMaster && (
                              <Chip
                                color="secondary"
                                variant="outlined"
                                label="Master User"
                                className={classes.chip}
                                style={{ margin: '0px 0px 0px 4px' }}
                              />
                            )}
                          </>
                        }
                      />
                      <LabelValueSet
                        label="Onboarding Name"
                        value={record.OnboardingName}
                      />
                      <LabelValueSet
                        label="Onboarding Relationship"
                        value={record.OnboardingRelationship.toUpperCase()}
                      />
                      {record.LinkedUsers && (
                        <LabelValueSet
                          label="Linked Users (Account-Member)"
                          value={
                            record.LinkedUsers && record.LinkedUsers.length
                              ? record.LinkedUsers.map((linkedUser, idxLU) => (
                                  <div key={idxLU}>
                                    {linkedUser}
                                    <br />
                                  </div>
                                ))
                              : ''
                          }
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="24.24%">
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Language"
                        value={
                          languageMap[record.Preferences.Language] ||
                          record.Preferences.Language
                        }
                      />
                      <Typography color="primary">
                        <strong>Notifications:</strong>
                      </Typography>
                      {NOTIFICATION_KEYS.map((notif, notifIdx) =>
                        record.Preferences.Notifications[notif].Email ||
                        record.Preferences.Notifications[notif].SMS ? (
                          <div key={notifIdx} className={classes.itemContainer}>
                            <Typography>
                              <strong>{notif}</strong>
                            </Typography>
                            <div>
                              {record.Preferences.Notifications[notif]
                                .Email && (
                                <Chip
                                  color="primary"
                                  label="Email"
                                  className={classes.chip}
                                />
                              )}
                              {record.Preferences.Notifications[notif].SMS && (
                                <Chip
                                  color="primary"
                                  label="SMS"
                                  className={classes.chip}
                                />
                              )}
                            </div>
                            <Typography>
                              <strong>Settings:</strong>
                            </Typography>
                            <span>
                              {record.Preferences.Notifications[notif]
                                .Settings || 'N/A'}
                            </span>
                          </div>
                        ) : (
                          ''
                        )
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="16.16%">
                    <div className={classes.collapsableCellScroll}>
                      {record.Email !== 'UNKNOWN' ? (
                        <div>
                          <LabelValueSet label="Email" value={record.Email} />
                          <LabelValueSet
                            label="Updated Email"
                            value={record.EmailUpdated || 'N/A'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      {record.SMS !== 'UNKNOWN' ? (
                        <div>
                          <LabelValueSet label="SMS" value={record.SMS} />
                          <LabelValueSet
                            label="Updated SMS"
                            value={record.SMSUpdated || 'N/A'}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </TableCell>
                  <TableCell width="15.15%">
                    <div className={classes.collapsableCellScroll}>
                      <LabelValueSet
                        label="Created"
                        value={moment(
                          Number(record.TimestampsUTC.Created)
                        ).format('MM-DD-YYYY hh:mm a')}
                      />
                      <LabelValueSet
                        label="Notified"
                        value={
                          record.TimestampsUTC.Notified ? (
                            moment(
                              Number(record.TimestampsUTC.Notified)
                            ).format('MM-DD-YYYY hh:mm a')
                          ) : (
                            <Typography color="textSecondary">
                              Not Notified
                            </Typography>
                          )
                        }
                      />
                      <LabelValueSet
                        label="Verified"
                        value={
                          record.TimestampsUTC.Verified ? (
                            moment(
                              Number(record.TimestampsUTC.Verified)
                            ).format('MM-DD-YYYY hh:mm a')
                          ) : (
                            <Typography color="textSecondary">
                              Not Verified
                            </Typography>
                          )
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

class OnboardedReport extends BaseLandingTemplate {
  constructor() {
    super()

    this.state = {
      filters: { ...DEFAULT_FILTERS },
      filterSequence: ['state', 'sortBy', 'sortOrder', 'startDate', 'endDate'],
      selectedFilters: {
        sortBy: 'ACCOUNT',
        sortOrder: 'A'
      },
      selectedPageNum: 0,
      allExpanded: false
    }

    this.fetch = this.fetch.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
  }

  componentDidMount() {
    const { lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    return this.fetch(this.state.selectedPageNum)
  }

  componentDidUpdate() {
    const { onboardedReport, lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (
      !onboardedReport.isFetching &&
      !onboardedReport.error &&
      !onboardedReport.records
    ) {
      return this.fetch(this.state.selectedPageNum)
    }
  }

  fetch(pageNum = 0) {
    const { filters, selectedFilters } = this.state
    let params = {
      status: filters.state.options
        .filter(opt => opt.checked)
        .map(opt => opt.code),
      sort: {
        by: selectedFilters.sortBy,
        order: selectedFilters.sortOrder
      },
      page: pageNum
    }

    if (filters.startDate.value) {
      params.start = moment(filters.startDate.value).valueOf()
    }

    if (filters.endDate.value) {
      params.end = moment(filters.endDate.value).valueOf()
    }

    this.props.fetchOnboardedReport_ac(params)
  }

  applyFilters = () => {
    this.setState({
      selectedPageNum: 0
    })

    return this.fetch()
  }

  onFilterClearField = fieldName => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [fieldName]: {
          ...this.state.filters[fieldName],
          value: '',
          dirty: false,
          error: false
        }
      }
    })
  }

  onRadioFilterChange = event => {
    const { name, value } = event.target

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [name]: value
      }
    })
  }

  handleAllCollapseToggle = () => {
    this.setState({
      allExpanded: !this.state.allExpanded
    })
  }

  onPageSelection = event => {
    const { value } = event.target

    this.setState({
      selectedPageNum: value
    })

    return this.fetch(value)
  }

  getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${this.state.selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  render() {
    const { classes, onboardedReport, me } = this.props
    const { pagination, records } = onboardedReport

    if (onboardedReport.isFetching) {
      return <Loader classes={{ message: classes.message }} />
    }

    let filterListing = (
      <FilterListing
        me={me}
        filters={this.state.filters}
        onFilterChange={this.onFilterChange}
        selected={this.state.selectedFilters}
        filterSequence={this.state.filterSequence}
        applyFilters={this.applyFilters}
        onFilterClearField={this.onFilterClearField}
        onRadioFilterChange={this.onRadioFilterChange}
      />
    )

    if (onboardedReport.error) {
      return (
        <>
          {filterListing}
          <Typography color="error" className={classes.message}>
            Error fetchnig report!
          </Typography>
        </>
      )
    }

    if (!(records && pagination)) {
      return <div className={classes.message}>...</div>
    }

    return (
      <>
        {filterListing}
        <Grid container style={{ padding: 8 }}>
          <Grid item xs={6}>
            <Typography
              inline
              color="textSecondary"
              style={{ cursor: 'pointer' }}
              onClick={this.handleAllCollapseToggle}>
              {this.state.allExpanded ? 'Collapse All' : 'Expand All'}
              &ensp;[+/-]
            </Typography>
            <Typography color="textSecondary">
              {this.getPageStatement(pagination)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Select
              value={this.state.selectedPageNum}
              name="pageSelector"
              onChange={this.onPageSelection}>
              {pagination.totalPagesAvailable > 0 ? (
                _.range(0, pagination.totalPagesAvailable).map(pgNum => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="0" disabled>
                  <Typography color="textSecondary">No Pages</Typography>
                </MenuItem>
              )}
            </Select>
          </Grid>
        </Grid>
        <Grid container style={{ height: 'calc(100% - 36px)' }}>
          <Grid item xs={12} style={{ height: '100%', overflowY: 'auto' }}>
            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell width="1%" />
                  <TableCell width="22%">Account</TableCell>
                  <TableCell width="22%">User</TableCell>
                  <TableCell width="24%">Preferences</TableCell>
                  <TableCell width="16%">Contact</TableCell>
                  <TableCell width="15%">Timestamps</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records.length ? (
                  records.map((record, idx) => (
                    <ExpandableRow
                      key={idx}
                      changePage={this.props.changePage}
                      record={record}
                      classes={classes}
                      allExpanded={this.state.allExpanded}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      className={classes.collapsableRowContent}>
                      <Typography variant="subtitle1" color="textSecondary">
                        No Records Found!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </>
    )
  }
}

OnboardedReport.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = statesFromStore => ({
  me: statesFromStore.me,
  lookups: statesFromStore.lookups,
  onboardedReport: statesFromStore.onboardedReport
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      changePage: path => {
        return push(path)
      },
      fetchOnboardedReport_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OnboardedReport))
)
