import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchWorkOrdersReports_ac,
  fetchWorkOrder_ac
} from '../../actions/work_orders_report_ac.js'
import dateUtil from '../../util/dateProcessing'
import FilterListing from '../FilterListing'
import { PaginationWithNums } from '../PaginationNew'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #e0e0e0'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  noBorderBottom: {
    borderBottom: 'unset',
    verticalAlign: 'top',
    padding: 4
  },
  completedTime: {
    color: '#4a148c'
  },
  launchIcon: {
    marginLeft: 5
  },
  stickyTableHead: {
    '& > tr > th': {
      position: 'sticky',
      top: 0,
      zIndex: 100,
      backgroundColor: '#b2dfdb'
    }
  }
})

const DEFAULT_FILTERS = {
  start: {
    kind: 'date',
    lbl: 'From',
    placeholder: 'MM-DD-YYYY',
    value: ''
  },
  end: {
    kind: 'date',
    lbl: 'To',
    placeholder: 'MM-DD-YYYY',
    value: ''
  }
}

class WorkOrderReportComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      filters: {
        ...DEFAULT_FILTERS
      },
      filterSequence: ['start', 'end'],
      selectedWorOrder: ''
    }
  }

  onPageChange = newPage => {
    let targetPage = parseInt(newPage, 10)

    this.setState({
      selectedPageNum: targetPage - 1
    })

    this.fetch(targetPage - 1)
  }

  componentDidMount() {
    return this.fetch()
  }

  componentDidUpdate() {
    const { workOrdersReportInView } = this.props
    if (
      workOrdersReportInView.isFetching === false &&
      !workOrdersReportInView.error &&
      workOrdersReportInView.records === null
    ) {
      return this.fetch()
    }

    if (
      workOrdersReportInView.pagination &&
      workOrdersReportInView.isFetching === false &&
      workOrdersReportInView.pagination.currentPageNum !== this.state.page &&
      workOrdersReportInView.pagination.currentPageNum !== -1
    ) {
      return this.fetch()
    }
  }

  fetch(pageNum = 0) {
    const { filters } = this.state

    let params = {
      page: pageNum
    }

    if (filters.start.value && dateUtil.isValidDate(filters.start.value)) {
      params.start = moment(filters.start.value).format('YYYY-MM-DD')
    }

    if (filters.end.value && dateUtil.isValidDate(filters.end.value)) {
      params.end = moment(filters.end.value).format('YYYY-MM-DD')
    }

    return this.props.fetchWorkOrdersReports_ac(params)
  }

  applyFilters = () => {
    this.fetch()
  }

  loadOrder = workOrder => {
    this.setState({
      selectedWorOrder: workOrder._id
    })
    const requestParams = {
      hdmsAccts: [[workOrder.account, workOrder.member].join('-')],
      idn: ''
    }
    this.props.fetchWorkOrder_ac(requestParams)
  }

  goToOrderPage = workOrder => {
    let path = `/redirect_to_patient/${workOrder.account}/orders`
    this.props.changePage(path)
    return
  }

  render() {
    const { workOrdersReportInView, classes } = this.props

    if (workOrdersReportInView.isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading records" />
        </main>
      )
    }

    let filterListing = (
      <FilterListing
        filters={this.state.filters}
        filterSequence={this.state.filterSequence}
        applyFilters={this.applyFilters}
        onFilterChange={this.onFilterChange}
        onFilterClearField={this.onFilterClearField}
      />
    )

    if (workOrdersReportInView.error) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>....error fetching records</h4>
        </div>
      )
    }

    if (workOrdersReportInView.records === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      )
    }

    if (!workOrdersReportInView.records.length) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>No records found.</h4>
        </div>
      )
    }

    const { pagination: pg } = workOrdersReportInView

    let pageSelector = ''
    let pageStatement = ''
    if (pg) {
      pageStatement = [
        `Displaying 
          ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
        `Page ${this.state.page + 1} of ${pg.totalPagesAvailable}`
      ].join('-')

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.onPageChange}
          />
        )
      }
    }

    return (
      <>
        {filterListing}
        <main className={classes.content}>
          <div className={classes.pagination}>
            <Grid container spacing={8}>
              <Grid item xs={8} sm={8}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {pageStatement}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4}>
                {pageSelector}
              </Grid>
            </Grid>
          </div>
          <div className={[classes.root, classes.scrollArea].join(' ')}>
            <Table padding="dense" style={{ borderCollapse: 'separate' }}>
              <TableHead className={classes.stickyTableHead} padding="dense">
                <TableRow>
                  <TableCell align="left" width="13%">
                    WO#
                  </TableCell>
                  <TableCell align="left" width="12%">
                    Account
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Name
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Location / PCR
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Release Dt / Schedule Dt
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workOrdersReportInView.records.map((item, index) => {
                  let isSelected = this.state.selectedWorOrder === item._id
                  return (
                    <TableRow key={index} selected={isSelected}>
                      <TableCell
                        align="left"
                        width="13%"
                        variant="body"
                        className={classes.noBorderBottom}>
                        {item._id}
                        <br />
                        {item.po ? 'PO' + item.po_num : ''}
                      </TableCell>
                      <TableCell
                        align="left"
                        width="12%"
                        variant="body"
                        className={classes.noBorderBottom}>
                        {item.account}
                      </TableCell>
                      <TableCell
                        align="left"
                        width="25%"
                        variant="body"
                        className={classes.noBorderBottom}>
                        <div>
                          <span>
                            {[
                              item.last_name,
                              ', ',
                              item.first_name,
                              ' ',
                              moment(item.dob).format('M-D-YYYY')
                            ].join('')}
                          </span>
                        </div>
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => this.goToOrderPage(item)}>
                          <span className={classes.launchIcon}>Go to Page</span>
                        </Button>
                        <Button
                          size="small"
                          color="primary"
                          onClick={() => this.loadOrder(item)}>
                          <span className={classes.launchIcon}>View Card</span>
                        </Button>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="25%"
                        variant="body"
                        className={classes.noBorderBottom}>
                        {item.dc_nm}
                        <br />
                        {item.pcr_nm}
                      </TableCell>
                      <TableCell
                        align="left"
                        width="25%"
                        variant="body"
                        className={classes.noBorderBottom}>
                        <div className={classes.completedTime}>
                          {dateUtil.formatDate(item.release_dt)}
                        </div>
                        <div className={classes.completedTime}>
                          {item.sched_dt
                            ? moment(item.sched_dt).format('MM-DD-YYYY')
                            : 'Unscheduled'}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        </main>
      </>
    )
  }
}

WorkOrderReportComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  workOrdersReportInView: stateFromStore.workOrdersReportInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWorkOrdersReports_ac,
      fetchWorkOrder_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export const WorkOrdersReport = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(WorkOrderReportComponent))
)
