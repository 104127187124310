import types from '../types';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GETALL_PDFDETAIL:
    case types.POST_PDFDETAIL:
      return {
        ...state,
        isLoading: true,
      };

    case types.GETALL_PDFDETAIL_SUCCESS:
    case types.POST_PDFDETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case types.CONTACTS_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    default:
      return { ...state };
  }
};
