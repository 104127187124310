import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import lightBlue from '@material-ui/core/colors/lightBlue';
import { Icon, withStyles, Typography } from '@material-ui/core';

import * as pdfGen from '../../actions/pdfGen.ac';

const styles = (theme) => ({
  wrapper: {
    marginTop: 15,
    marginBottom: 15,
  },
  commandBlock: {
    display: 'inline-block',
  },
  textBlock: {
    marginLeft: 10,
    display: 'inline-block',
  },
  index: {
    display: 'inline-block',
    height: 20,
    width: 20,
    textAlign: 'center',
    verticalAlign: 'top',
  },
  command: {
    height: 20,
    width: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  clickable: {
    '& span': {
      color: lightBlue[700],
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pageNumActive: {
    display: 'inline-block',
    padding: '4px 8px',
    margin: 3,
    backgroundColor: 'rgba(5, 170, 245, 0.25)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pageNum: {
    display: 'inline-block',
    padding: '4px 8px',
    margin: 3,
    '&:hover': {
      cursor: 'pointer',
    },
  },

  pdfLink: {
    paddingLeft: '50px',
  },
});

class PDFGenDrawerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.moveItem = this.moveItem.bind(this);
  }

  moveItem(direction) {
    if (direction === 'UP') this.props.pdfGenMoveUp(this.props.index - 1);
    else if (direction === 'DOWN')
      this.props.pdfGenMoveDown(this.props.index - 1);
    else if (direction === 'REMOVE')
      this.props.pdfGenRemove(this.props.index - 1);
  }

  render() {
    let classes = this.props.classes;
    let selectedPages = this.props.item.selectedPages.reduce((acc, elem) => {
      return elem.active ? acc + 1 : acc;
    }, 0);

    return (
      <div className={classes.wrapper}>
        <div>
          <div className={classes.commandBlock}>
            <div>
              <div className={classes.index}>
                <span>{this.props.index}</span>
              </div>
              <Icon
                className={classes.command}
                fontSize="small"
                onClick={() => {
                  this.moveItem('UP');
                }}>
                arrow_upward
              </Icon>
            </div>
            <div>
              <Icon
                className={classes.command}
                fontSize="small"
                onClick={() => {
                  this.moveItem('REMOVE');
                }}>
                clear
              </Icon>
              <Icon
                className={classes.command}
                fontSize="small"
                onClick={() => {
                  this.moveItem('DOWN');
                }}>
                arrow_downward
              </Icon>
            </div>
          </div>
          <div className={classes.textBlock}>
            <Typography>{this.props.item.patientIdn}</Typography>
            <Typography>{this.props.item.nm}</Typography>
            <Typography>FAX: {this.props.item.faxTsMDY}</Typography>
            <Typography
              className={classes.clickable}
              onClick={() => {
                this.props.pdfGenTogglePagePicker(this.props.index - 1);
              }}>
              PAGES: {selectedPages} OUT OF
              {this.props.item.selectedPages.length}
              <span> (EDIT)</span>
            </Typography>
          </div>
        </div>
        <div>
          {this.props.item.showPagePicker &&
            this.props.item.selectedPages.map((page, i) => {
              return (
                <Typography
                  key={i}
                  className={
                    page.active ? classes.pageNumActive : classes.pageNum
                  }
                  onClick={() => {
                    this.props.pdfGenTogglePage(this.props.index - 1, i);
                  }}>
                  {page.txt}
                </Typography>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pdfGenMoveDown: pdfGen.moveDownDoc,
      pdfGenMoveUp: pdfGen.moveUpDoc,
      pdfGenRemove: pdfGen.removeDoc,
      pdfGenTogglePage: pdfGen.togglePage,
      pdfGenTogglePagePicker: pdfGen.togglePagePicker,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PDFGenDrawerItem))
);
