import { MailBoxesActionTypes } from '../actions/mail_boxes.ac.js';

export function mailBoxesInView(
  state = {
    isFetching: false,
    params: null,
    records: null,
    tags: null,
    pagination: null,
    error: null,

    isUpdating: false,
  },
  action
) {
  switch (action.type) {
    case MailBoxesActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case MailBoxesActionTypes.RECEIVED_MAIL_BOXES_DATA:
      return {
        ...state,
        pagination: action.pagination,
        records: action.records,
        tags: action.tags,
      };

    case MailBoxesActionTypes.FORWARDING_STATUS_CHANGED:
      state.records.forEach((item) => {
        if (item._id === action.mailboxId) {
          item.forwardToEmail = action.forwardToEmail;
        }
      });

      return {
        ...state,
      };

    case MailBoxesActionTypes.FAILED_RECEIVING_MAIL_BOXES_DATA:
      return {
        ...state,
        pagination: null,
        records: null,
        tags: null,
        error: action.error,
      };

    case MailBoxesActionTypes.POLLING_STATUS_CHANGED:
      state.records.forEach((item) => {
        if (item._id === action.mailboxId) {
          item.monitor = action.monitor;
        }
      });

      return {
        ...state,
      };

    case MailBoxesActionTypes.MAILBOX_EMAIL_PATCH_PENDING:
      return {
        ...state,
        isUpdating: true,
      };

    case MailBoxesActionTypes.MAILBOX_EMAIL_PATCH_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        records: (state.records || []).map((item) => {
          if (item._id === action.mailboxId) {
            item.email = action.email;
          }
          return item;
        }),
      };

    case MailBoxesActionTypes.MAILBOX_EMAIL_PATCH_FAILED:
      return {
        ...state,
        isUpdating: false,
        updateError: action.error,
      };

    case MailBoxesActionTypes.MAILBOX_NOTIFY_CC_PATCH_PENDING:
      return {
        ...state,
        isUpdating: true,
      };

    case MailBoxesActionTypes.MAILBOX_NOTIFY_CC_PATCH_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        records: (state.records || []).map((item) => {
          if (item._id === action.mailboxId) {
            if (!item.notify) {
              item.notify = {};
            }
            item.notify.cc = action.emails;
          }
          return item;
        }),
      };

    case MailBoxesActionTypes.MAILBOX_NOTIFY_CC_PATCH_FAILED:
      return {
        ...state,
        isUpdating: false,
        updateError: action.error,
      };

    default:
      return state;
  }
}
