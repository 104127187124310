export const reportActionTypes = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  RECEIVED_OPEN_COUNTS: 'RECEIVED_OPEN_COUNTS',
  FAILED_OPEN_COUNTS: 'FAILED_OPEN_COUNTS',
  RECEIVED_CLOSED_COUNTS: 'RECEIVED_CLOSED_COUNTS',
  FAILED_CLOSED_COUNTS: 'FAILED_CLOSED_COUNTS',
  RECEIVED_FLOW_REPORT: 'RECEIVED_FLOW_REPORT',
  FAILED_FLOW_REPORT: 'FAILED_FLOW_REPORT',
};

export function fetchOpenCounts_ac(params) {
  return (dispatch) => {
    dispatch(fetching(true));

    window.sch
      .post(`/api/workitems:report:open`, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }

        dispatch({
          type: reportActionTypes.RECEIVED_OPEN_COUNTS,
          report: response,
        });
        dispatch({
          type: 'CHECK_USER_FILTER_CHANGE',
          filterPkg: response.filterPkg,
        });
      })
      .catch((err) => {
        dispatch({
          type: reportActionTypes.FAILED_OPEN_COUNTS,
          error: true,
        });
      })
      .finally(() => {
        dispatch(fetching(false));
      });
  };
}

export function fetchClosedCounts_ac(params) {
  return (dispatch) => {
    dispatch(fetching(true));

    window.sch
      .post(`/api/workitems:report:closed`, params)
      .then((response) => {
        if (response.error) {
          throw response.error;
        }

        dispatch({
          type: reportActionTypes.RECEIVED_CLOSED_COUNTS,
          report: response,
        });
      })
      .catch((err) => {
        dispatch({
          type: reportActionTypes.FAILED_CLOSED_COUNTS,
          error: true,
        });
      })
      .finally(() => {
        dispatch(fetching(false));
      });
  };
}

export function fetchFlowReport_ac(params) {
  return (dispatch) => {
    return new Error('Disabled feature');
  };
}

const fetching = (isFetching) => ({
  type: reportActionTypes.SET_IS_FETCHING,
  isFetching,
});
