import { refresh_user_profile_ac } from './me.ac';

export const actionTypes = {
  FETCH_USER_PROFILES_PENDING: 'FETCH_USER_PROFILES_PENDING',
  FETCH_USER_PROFILES_SUCCESSFUL: 'FETCH_USER_PROFILES_SUCCESSFUL',
  FETCH_USER_PROFILES_FAILED: 'FETCH_USER_PROFILES_FAILED',

  CREATE_USER_PROFILE_PENDING: 'CREATE_USER_PROFILE_PENDING',
  CREATE_USER_PROFILE_SUCCESSFUL: 'CREATE_USER_PROFILE_SUCCESSFUL',
  CREATE_USER_PROFILE_FAILED: 'CREATE_USER_PROFILE_FAILED',

  COPY_USER_PROFILE_PENDING: 'COPY_USER_PROFILE_PENDING',
  COPY_USER_PROFILE_SUCCESSFUL: 'COPY_USER_PROFILE_SUCCESSFUL',
  COPY_USER_PROFILE_FAILED: 'COPY_USER_PROFILE_FAILED',

  UPDATE_USER_PROFILE_PENDING: 'UPDATE_USER_PROFILE_PENDING',
  UPDATE_USER_PROFILE_SUCCESSFUL: 'UPDATE_USER_PROFILE_SUCCESSFUL',
  UPDATE_USER_PROFILE_FAILED: 'UPDATE_USER_PROFILE_FAILED',

  DELETE_USER_PROFILE_PENDING: 'DELETE_USER_PROFILE_PENDING',
  DELETE_USER_PROFILE_SUCCESSFUL: 'DELETE_USER_PROFILE_SUCCESSFUL',
  DELETE_USER_PROFILE_FAILED: 'DELETE_USER_PROFILE_FAILED',

  ACTIVATE_USER_PROFILE_PENDING: 'ACTIVATE_USER_PROFILE_PENDING',
  ACTIVATE_USER_PROFILE_SUCCESSFUL: 'ACTIVATE_USER_PROFILE_SUCCESSFUL',
  ACTIVATE_USER_PROFILE_FAILED: 'ACTIVATE_USER_PROFILE_FAILED',
};

export function fetchUserProfiles_ac(optionOnly = false) {
  return (dispatch) => {
    dispatch({ type: actionTypes.FETCH_USER_PROFILES_PENDING });
    return window.sch
      .post(
        `/api/user_profiles/get`,
        optionOnly ? { keys: ['_id', 'name', 'copied_from_profile_id'] } : {}
      )
      .then((res) => {
        dispatch({
          type: actionTypes.FETCH_USER_PROFILES_SUCCESSFUL,
          optionOnly,
          data: res.result,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FETCH_USER_PROFILES_FAILED,
          error,
        });
      });
  };
}

export function createUserProfile_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.CREATE_USER_PROFILE_PENDING });
    return window.sch
      .post(`/api/user_profiles/create`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.CREATE_USER_PROFILE_SUCCESSFUL,
          data: res.result,
        });
        if (onSuccess) onSuccess(res.result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.CREATE_USER_PROFILE_FAILED,
          error,
        });
        if (onError) onError();
      });
  };
}

export function copyUserProfile_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.COPY_USER_PROFILE_PENDING });
    return window.sch
      .post(`/api/user_profiles/copy`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.COPY_USER_PROFILE_SUCCESSFUL,
          data: res.result,
        });
        if (onSuccess) onSuccess(res.result);
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.COPY_USER_PROFILE_FAILED,
          error,
        });
        if (onError) onError();
      });
  };
}

export function updateUserProfile_ac(data, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.UPDATE_USER_PROFILE_PENDING });
    return window.sch
      .post(`/api/user_profiles/update`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_USER_PROFILE_SUCCESSFUL,
          data: {
            ...data,
            ...(res.updates || {}),
          },
        });
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.UPDATE_USER_PROFILE_FAILED,
          error,
        });
        if (onError) onError();
      });
  };
}

export function deleteUserProfile_ac(_id, { onSuccess, onError } = {}) {
  return (dispatch) => {
    dispatch({ type: actionTypes.DELETE_USER_PROFILE_PENDING });
    return window.sch
      .post(`/api/user_profiles/delete`, { _id })
      .then((res) => {
        dispatch({
          type: actionTypes.DELETE_USER_PROFILE_SUCCESSFUL,
          data: { _id },
        });

        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.DELETE_USER_PROFILE_FAILED,
          error,
        });
        if (onError) onError();
      });
  };
}

export function activateUserProfile_ac(
  profile_id,
  { onSuccess, onError } = {}
) {
  return (dispatch) => {
    dispatch({ type: actionTypes.ACTIVATE_USER_PROFILE_PENDING });
    return window.sch
      .post(`/api/user_profiles/activate`, { profile_id })
      .then((res) => {
        dispatch({
          type: actionTypes.ACTIVATE_USER_PROFILE_SUCCESSFUL,
          data: profile_id,
        });

        dispatch(refresh_user_profile_ac());

        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ACTIVATE_USER_PROFILE_FAILED,
          error,
        });

        if (onError) onError();
      });
  };
}
