import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Loader from '../Shared/Loader'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { generateApplicationKeyPair_ac, removeKeyPair_ac } from '../../actions/client_apps.ac';
import { KeyValuePairsTableView } from './KeyPairTableVIew'

const styles = theme => ({
    root: {
        width: '100%',
        overflowY: 'auto',
        overflowX: 'auto'
    },
    content: {
        marginTop: 12,
        flexGrow: 1,
        padding: theme.spacing.unit * 0.5,
        height: 'calc(100% - 12px)',
        backgroundColor: '#fbf8f896',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    details: {
        fontSize: '.92em',
        margin: '2px 8px 2px 0',
        padding: '2px 8px 2px 0'
    },
    scrollArea: {
        overflowY: 'auto',
        '-webkitOverflowScrolling': 'touch',
        height: '50%'
    },
    card: {
        minWidth: 275,
        marginBottom: 20,
        marginTop: 10
    },
    cardHdrRoot: {
        backgroundColor: '#e0f7fa',
        paddingBottom: 8
    },
    cardHdrSubHdr: {
        fontSize: '.92em'
    },
    cardHdrContent: {
        fontSize: '.96em',
        fontWeight: 800
    },

    cardContent: {
        '-webkitOverflowScrolling': 'touch',
        overflowY: 'auto'
    },
    tableScrollable: {
        minHeight: '220px',
        height: 'calc(100% - 46px)',
        overflowY: 'auto'
    },
    tableHeader: {
        height: 20,
        '& > th': {
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 100,
            backgroundColor: '#fff'
        }
    },
    tableBodyCell: {
        borderBottom: 'unset',
        boxShadow: '0px 1px 2px 1px white'
    },
    formControl: {
        margin: theme.spacing.unit * 2,
        display: 'block',
        minWidth: 200
    },
    flexDisplay: {
        display: 'flex',
        alignItems: 'center'
    }
})

class ClientAppComponent extends React.Component {

    constructor() {
        super()

        this.state = {
            label: '',
            isGeneratingKeyPair: false,
            errors: {
                label: false
            }
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.errors[name]) {
                this.validateForm();
            }
        })
    }

    proceedKeyPairGeneration = () => {
        this.setState({
            isGeneratingKeyPair: !this.state.isGeneratingKeyPair
        })
    }

    validateForm = () => {
        let labelErr = false;
        if (!this.state.label) {
            labelErr = true;
        }
        this.setState({
            errors: {
                label: labelErr
            }
        })

        return labelErr;
    }

    generateKeyPair = e => {
        const hasError = this.validateForm();
        if (hasError) return;

        const { label } = this.state;
        const { clientAppsInView } = this.props;
        this.props.generateApplicationKeyPair_ac({
            client_id: clientAppsInView.client_apps._id,
            label
        })
        this.setState({
            label: ''
        })
    }

    removeNameValue = (pair) => {
        const { clientAppsInView } = this.props;
        const requestParams = {
            client_id: clientAppsInView.client_apps._id,
            pair_id: pair.id
        }
        this.props.removeKeyPair_ac(requestParams)
    }

    render() {
        const { clientAppsInView, classes } = this.props
        const { client_apps } = clientAppsInView
        const { errors } = this.state;

        const cardHdrClasses = {
            root: classes['cardHdrRoot'],
            title: classes['cardHdrContent'],
            subheader: classes['cardHdrSubHdr']
        }
        return (
            <Card className={classes.card}>
                <CardHeader
                    classes={cardHdrClasses}
                    title={
                        <>
                            <strong>{client_apps.nm} &ensp;</strong>
                        </>
                    }
                    subheader={
                        <span style={{ fontSize: 12 }}>
                            {client_apps.description}
                        </span>
                    }
                />
                <CardContent>
                    <Grid container className={classes.cardContent}>
                        <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => this.proceedKeyPairGeneration()}
                            >
                                Add Key Pair
                            </Button>
                            {
                                this.state.isGeneratingKeyPair && (
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl
                                                style={{ marginLeft: 0 }}
                                                className={classes.formControl}
                                                fullWidth
                                            >
                                                <FormLabel component="label">
                                                    <strong>Label</strong>
                                                </FormLabel>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={this.handleChange}
                                                    required
                                                    error={errors.label}
                                                    helperText={errors.label ? 'Please enter label' : ''}
                                                    placeholder="Label to describe Key Pair"
                                                    name="label"
                                                    value={this.state.label}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Divider component="hr" />
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl
                                                style={{ marginLeft: 0 }}
                                                className={classes.formControl}
                                            >
                                                <div className={classes.flexDisplay}>
                                                    <Button
                                                        variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        onClick={() => this.generateKeyPair()}
                                                        disabled={clientAppsInView.isGenerating}
                                                    >
                                                        Generate
                                                    </Button>
                                                    {clientAppsInView.isGenerating && (
                                                        <Loader type="circular" size={15} color="primary" />
                                                    )}
                                                </div>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <KeyValuePairsTableView
                                keyPairs={client_apps.keyPairs}
                                classes={classes}
                                removeNameValue={this.removeNameValue}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

ClientAppComponent.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
    clientAppsInView: stateFromStore.clientAppsInView
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            generateApplicationKeyPair_ac,
            removeKeyPair_ac
        },
        dispatch
    )

export const Application = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(ClientAppComponent))
)
