import moment from 'moment';
import React, { useState } from 'react';

import {
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon,
  Clear as ClearIcon,
  Done as DoneIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

import { truncate } from '../../util/string';
import IdxAdminLocationEditForm from '../IndexingAdmin/IndexingLocations/IdxAdminLocationEditForm';
import LocationsSearchField from './LocationsSearchField';

const AddButton = (props) => {
  const { disabled = false, onAdd } = props;

  return (
    <IconButton disabled={disabled} onClick={onAdd} style={{ padding: 8 }}>
      <AddCircleIcon fontSize="small" />
    </IconButton>
  );
};

const DoneButton = (props) => {
  const { disabled = false, onDone } = props;

  return (
    <IconButton disabled={disabled} onClick={onDone} style={{ padding: 8 }}>
      <DoneIcon fontSize="small" />
    </IconButton>
  );
};

const CancelButton = (props) => {
  const { disabled = false, onCancel } = props;

  return (
    <IconButton disabled={disabled} onClick={onCancel} style={{ padding: 8 }}>
      <CancelIcon fontSize="small" />
    </IconButton>
  );
};

export const LocationsField = (props) => {
  const DATE_FORMAT = 'MM-DD-YYYY';

  const { disabled, locations, children, editMode = true, metaInfo } = props;

  const [editLocationIdx, setEditLocationIdx] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isCreating, setIsCreating] = useState(false);

  const handleLocationAdd = () => {
    if (!isFormValid()) return;

    let loc = {
      ...selectedLocation,
      loc_id: selectedLocation._id,
      s: selectedLocation.startDate || null,
      e: selectedLocation.endDate || null,
    };

    props.onLocationAdd(loc, () => {
      setSelectedLocation(null);
    });
  };

  const handleLocationEdit = (loc, locIdx) => {
    setEditLocationIdx(locIdx);
    handleLocationSelect(loc);
  };

  const handleLocationEditSave = () => {
    if (editLocationIdx === null || selectedLocation === null) return;

    if (!isFormValid()) return;

    let newLoc = {
      ...selectedLocation,
      loc_id: selectedLocation.loc_id || selectedLocation._id,
      s: selectedLocation.startDate || null,
      e: selectedLocation.endDate || null,
    };

    props.onLocationUpdate(newLoc, editLocationIdx);

    setSelectedLocation(null);
    setEditLocationIdx(null);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;

    if (name === 'startDate')
      setSelectedLocation((loc) => ({
        ...loc,
        startDate: value || null,
      }));
    else
      setSelectedLocation((loc) => ({
        ...loc,
        endDate: value || null,
      }));
  };

  const isFormValid = () => {
    if (
      selectedLocation.startDate !== null &&
      !moment(selectedLocation.startDate, DATE_FORMAT).isValid()
    )
      return false;

    if (
      selectedLocation.endDate !== null &&
      !moment(selectedLocation.endDate, DATE_FORMAT).isValid()
    )
      return false;

    return true;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (editLocationIdx === null) return handleLocationAdd();

      handleLocationEditSave();
    }
  };

  const handleLocationSelect = (loc) => {
    setSelectedLocation({
      loc_id: loc._id || loc.loc_id,
      startDate: loc.s ? moment(loc.s).format(DATE_FORMAT) : null,
      endDate: loc.e ? moment(loc.e).format(DATE_FORMAT) : null,
      ...loc,
    });
  };

  const handleLocationCancel = () => {
    setEditLocationIdx(null);
    setSelectedLocation(null);
  };

  const getLocLabel = (loc) => (
    <div style={{ color: '#000', fontSize: '10px', margin: 0 }}>
      {loc.addr1 && <>{loc.addr1}</>} {loc.addr2 && <> {loc.addr2}</>}
      <br />
      {[loc.city || '', loc.state || ''].join(', ')}
      <strong style={{ color: 'blue' }}> {loc.zip}</strong>
    </div>
  );

  const getLocTitle = (loc) => (
    <div style={{ fontWeight: 'bold', fontSize: '12px', margin: 0 }}>
      {truncate(loc.name, 40)}
    </div>
  );

  return (
    <div style={{ width: '100%' }}>
      <Grid container>
        {locations?.length === 0 && (
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ margin: '12px 0px' }}>
            {'No locations added yet'}
          </Typography>
        )}
        {locations &&
          locations.length > 0 &&
          locations.map((loc, idx) => (
            <Card
              key={idx}
              elevation={4}
              hidden={loc.loc_id === -1}
              style={{ margin: 4, backgroundColor: 'lightblue' }}>
              <CardHeader
                titleTypographyProps={{
                  variant: 'body2',
                }}
                subheaderTypographyProps={{
                  variant: 'caption',
                }}
                action={
                  editMode ? (
                    <>
                      <IconButton
                        style={{ padding: 8 }}
                        disabled={editLocationIdx === idx}
                        onClick={() => handleLocationEdit(loc, idx)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        style={{ padding: 8 }}
                        disabled={editLocationIdx === idx}
                        onClick={() => props.onLocationDelete(loc, idx)}>
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : null
                }
                title={getLocTitle(loc)}
                subheader={
                  <div>
                    {getLocLabel(loc)}
                    {loc.s
                      ? `From ${moment(loc.s).format(DATE_FORMAT)} `
                      : null}
                    {loc.e ? ` To ${moment(loc.e).format(DATE_FORMAT)}` : null}
                  </div>
                }
              />
            </Card>
          ))}
      </Grid>
      {editMode &&
        (selectedLocation ? (
          <Grid container alignItems="baseline">
            <Typography variant="body2">
              {getLocTitle(selectedLocation)}
              &ensp;
            </Typography>
            <TextField
              margin="dense"
              name="startDate"
              disabled={disabled}
              label="Start Date"
              placeholder={DATE_FORMAT}
              onKeyDown={handleKeyDown}
              onChange={handleDateChange}
              value={selectedLocation.startDate || ''}
            />
            &ensp;
            <TextField
              margin="dense"
              name="endDate"
              disabled={disabled}
              label="End Date"
              placeholder={DATE_FORMAT}
              onKeyDown={handleKeyDown}
              onChange={handleDateChange}
              value={selectedLocation.endDate || ''}
            />
            &ensp;
            {editLocationIdx === null ? (
              <AddButton disabled={!isFormValid()} onAdd={handleLocationAdd} />
            ) : (
              <DoneButton
                disabled={!isFormValid()}
                onDone={handleLocationEditSave}
              />
            )}
            <CancelButton onCancel={handleLocationCancel} />
          </Grid>
        ) : (
          <LocationsSearchField
            label={null}
            disabled={disabled}
            placeholder={props.placeholder || 'Search a new Location here'}
            selectLocation={(loc) => handleLocationSelect(loc)}
          />
        ))}
      <div>
        {editMode && (
          <Button
            size="small"
            color="primary"
            disabled={disabled}
            variant="outlined"
            style={{
              marginTop: '12px',
              marginLeft: '4px',
              margin: '12px 8px 0px 0px',
            }}
            onClick={() => setIsCreating(!isCreating)}>
            {isCreating ? 'Cancel creation' : 'Create a new location'}
          </Button>
        )}
        {isCreating && (
          <div
            style={{
              padding: '4px',
              border: '2px dotted grey',
              borderRadius: '6px',
              marginTop: '8px',
            }}>
            <IdxAdminLocationEditForm
              formMode={'create'}
              onCancel={() => setIsCreating(false)}
              widgetMode={true}
              metaInfo={metaInfo}
              onSuccess={(loc) => {
                handleLocationSelect(loc);
                setIsCreating(false);
              }}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
