import { Chip, Divider, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { KeyboardArrowRight } from '@material-ui/icons'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  createJobForSelectedDoc_ac,
  fetchFBAccountingDocsDetails_ac,
  fetchFBAccountingDocs_ac
} from '../../actions/filebound_ac'
import { open_snack_ac } from '../../actions/snack.ac.js'
import { PaginationWithNums } from '../PaginationNew'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    border: '1px solid #000',
    overflowY: 'hidden'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  title: {
    flexGrow: 1
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  paper: {
    textAlign: 'left',
    background: '#f1f1f1',
    maxHeight: '100%',
    overflowY: 'auto',
    boxShadow: 'none',
    padding: 4
  },
  node: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    borderRadius: '3px',
    fontSize: 10,
    cursor: 'pointer',
    padding: 0,
    '&:hover': {
      background: '#97a9d273'
    }
  },
  nodeTitle: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #000',
    padding: 4
  },
  icon: {
    borderRadius: '3px',
    display: 'flex',
    float: 'right',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#97a9d273'
    }
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px',
    padding: 3
  }
})

class FBAccountingDocListingComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props)

    this.state = {
      currentPage: 0
    }

    this.fetch = this.fetch.bind(this)
  }

  onPageChange = page => {
    const targetPage = parseInt(page, 10)
    this.setState({
      currentPage: targetPage - 1
    })
  }

  viewDetails = doc => {
    const { _id, assignee_id, status } = doc
    const { me } = this.props
    const currentUser = me.user.mail
    if (status === 10 && assignee_id !== currentUser) {
      return this.props.open_snack_ac({
        variant: 'info',
        message: 'Selected Job is assigned to another user'
      })
    }
    const params = {
      _id
    }
    this.props.fetchFBAccountingDocsDetails_ac(params)

    // create job for new docs only
    if (doc.status === 0) {
      this.props.createJobForSelectedDoc_ac(_id)
    }
  }

  componentDidMount() {
    return this.fetch()
  }

  componentDidUpdate(prevProps) {
    const { fileBound } = this.props
    const { currentPage } = this.state
    if (
      fileBound.isFetching === false &&
      !fileBound.error &&
      fileBound.accountingDocs === null
    ) {
      return this.fetch()
    }

    if (
      fileBound.pagination &&
      fileBound.isFetching === false &&
      fileBound.pagination.currentPageNum !== currentPage &&
      fileBound.pagination.currentPageNum !== -1
    ) {
      return this.fetch(currentPage)
    }
  }

  fetch(page = 1) {
    let params = {
      page
    }

    return this.props.fetchFBAccountingDocs_ac(params)
  }

  render() {
    const { fileBound, classes } = this.props
    const { accountingDocs, isFetching, error, pagination: pg } = fileBound

    if (isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading accounting docs" />
        </main>
      )
    }

    if (accountingDocs === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      )
    }

    if (error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>....error fetching docs</h4>
        </div>
      )
    }

    if (!accountingDocs.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No Accounting docs found.</h4>
        </div>
      )
    }
    let pageStatement = ''
    let pageSelector = ''

    if (pg) {
      pageStatement = [
        `Displaying 
          ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
        `Page ${this.state.currentPage} of ${pg.totalPagesAvailable}`
      ].join('-')

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.onPageChange}
          />
        )
      }
    }

    return (
      <main className={classes.content}>
        <Grid container spacing={8}>
          <Grid item xs={12} sm={12} md={6}>
            <Typography className={classes.pageStatement} color="textSecondary">
              {pageStatement}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            {pageSelector}
          </Grid>
        </Grid>
        <Divider component="hr" />
        <div className={classes.scrollArea}>
          {(accountingDocs || []).map((adocs, index) => (
            <div key={index} className="paper">
              <div
                className={classes.nodeTitle}
                onClick={() => this.viewDetails(adocs)}>
                <div className={classes.node}>
                  <Chip
                    className={classes.chip}
                    label={adocs.status === 10 ? 'In Progress' : 'New'}
                    color={adocs.status === 10 ? 'secondary' : 'primary'}
                  />
                  {adocs.key && adocs.key.replace(/^PreFaxIn/, '')}{' '}
                  {moment(adocs.ts).format('MM-DD-YY hh:mm a')}
                  {adocs.status === 10 && (
                    <div style={{ paddingLeft: 8 }}>
                      Job taken by {adocs.assignee_nm} on{' '}
                      {moment(adocs.updated_dt).format('MM-DD-YY hh:mm a')}
                    </div>
                  )}
                </div>
                <div className={classes.icon}>
                  <span>
                    <KeyboardArrowRight fontSize="small" color="primary" />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    )
  }
}

FBAccountingDocListingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  fileBound: stateFromStore.fileBound
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchFBAccountingDocs_ac,
      createJobForSelectedDoc_ac,
      fetchFBAccountingDocsDetails_ac,
      open_snack_ac
    },
    dispatch
  )

export const FBAccountingDocListing = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBAccountingDocListingComponent))
)
