/*
   account_in_view.ac.js
   action creators for docs in the master store
 */
import _ from 'lodash';
import moment from 'moment';
import { open_snack_ac } from './snack.ac.js';

export function selectDocListingViewType_ac(viewType) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({
      type: 'SELECT_DOC_LISTING_VIEW_TYPE',
      viewType,
    });
  };
}

export function docReset_ac() {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({
      type: 'DOC_RESET',
    });
  };
}

export function loadingPages_ac() {
  return (dispatch) => {
    dispatch({ type: 'PAGES_LOADING' });
  };
}

export function pagesLoaded_ac() {
  return (dispatch) => {
    dispatch({ type: 'PAGES_LOADED' });
  };
}

export function docSelected_ac(posInSet, docJob, docJobKey) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    dispatch({
      type: 'DOC_SELECTED_FOR_VIEWER',
      isFetching: true,
      posInSet,
      docJob,
      docJobKey,
    });

    let idnInView = getStore().idnInView;
    if (idnInView.idnDoc === null || idnInView.idnDoc === undefined) return; //skip the AR dash stuff below
    if (idnInView.idnDoc.aa_num === undefined) return;
    if (_.get(idnInView, 'idnDoc.aa_num', []).length === 0) return;

    let msg = {
      kind: 'document_selected',
      doc_id: docJobKey,
      account: idnInView.idnDoc.aa_num,
      non_unique_doc_name: docJob.displayAs,
    };

    let room = getStore().room;
    if (room.status === 'roommate_present' && room.socket) {
      dispatch({ type: 'SET_DF_MSG', msg });
      room.socket.emit('df_msg', msg, () => {
        dispatch({ type: 'SENT_DF_MSG', msg });
      });
    }
  };
}

function requestAccountDocs_ac(accountIdn) {
  //console.log("requestAccountDocs_ac fired",accountIdn)
  return {
    type: 'REQUEST_ACCOUNT_DOCS',
    accountIdn,
  };
}

function failed_receivedAccountDocs_ac(accountIdn, error) {
  return {
    type: 'FAILED_RECEIVED_ACCOUNT_DOCS',
    accountIdn,
    error,
  };
}

export function receivedAccountDocs_ac(accountIdn, accountDocs) {
  return {
    type: 'RECEIVED_ACCOUNT_DOCS',
    accountIdn,
    accountDocs,
  };
}

export function posOfDocSelected_ac(posOfDocSelected) {
  return {
    type: 'POS_OF_DOC_SELECTED',
    posOfDocSelected,
  };
}

export function docKeySelected_ac(docKeySelected) {
  return {
    type: 'DOC_KEY_SELECTED',
    docKeySelected,
  };
}

export function accountAndWorkUnitDocSelected_ac(
  workUnitDocs,
  posOfWorkUnitDocSelected,
  wDoc
) {
  return {
    type: 'ACCOUNT_AND_WORKUNIT_DOC_SELECTED',
    workUnitDocs,
    posOfWorkUnitDocSelected,
    wDoc,
  };
}

export function updateAccountDocDivider_ac(params) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware

    let path = `/api/divider/update`;
    let postData = params;
    let posInSet = getState().docInView.posInSet;

    return window.sch
      .post(path, postData)
      .then((result) => {
        let _accountDocs = getState().accountInView.accountDocs;
        _accountDocs.docs[posInSet] = {
          ..._accountDocs.docs[posInSet],
          nm: result.newDividerName,
          id: result.newDividerId,
          displayAs: result.newDividerDisplayAs,
        };

        dispatch({
          type: 'ACCOUNT_DOC_IN_SET_CHANGED',
          accountDocs: _accountDocs,
        });

        let _docJob = getState().docInView.docJob;
        _docJob['displayAs'] = result.newDividerDisplayAs;
        dispatch({
          type: 'DOC_JOB_CHANGED',
          docJob: _docJob,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function updateDocDetails_ac(params) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'DOC_ACTIVITY_STARTED' });

    let path = `/api/doc_details/update`;
    let postData = params;
    let posInSet = getState().docInView.posInSet;

    return window.sch
      .post(path, postData)
      .then((result) => {
        let _accountDocs = getState().accountInView.accountDocs;
        _accountDocs.docs[posInSet] = {
          ..._accountDocs.docs[posInSet],
          details: result.updatedDoc.details,
        };

        dispatch({
          type: 'ACCOUNT_DOC_IN_SET_CHANGED',
          accountDocs: _accountDocs,
        });
        dispatch({
          type: 'DOC_ACTIVITY_UPDATED',
          docActivity: { msg: result.msg },
        });
        dispatch(
          open_snack_ac({
            variant: 'info',
            message: 'Details saved',
          })
        );
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Error saving details',
          })
        );
        dispatch({
          type: 'DOC_ACTIVITY_UPDATED',
          docActivity: { msg: 'Failed to update details' },
        });
      });
  };
}

export function updateDocCompliance_ac(params) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'DOC_ACTIVITY_STARTED' });

    let path = `/api/doc_compliance/update`;
    let postData = params;
    let posInSet = getState().docInView.posInSet;

    return window.sch
      .post(path, postData)
      .then((result) => {
        let _accountDocs = getState().accountInView.accountDocs;
        _accountDocs.docs[posInSet] = {
          ..._accountDocs.docs[posInSet],
          compliance: result.updatedDoc.compliance,
        };

        dispatch({
          type: 'ACCOUNT_DOC_IN_SET_CHANGED',
          accountDocs: _accountDocs,
        });
        dispatch({
          type: 'DOC_ACTIVITY_UPDATED',
          docActivity: { msg: result.msg },
        });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({
          type: 'DOC_ACTIVITY_UPDATED',
          docActivity: { msg: 'Failed to update compliance activity' },
        });
      });
  };
}

export function createWorkUnit_ac(params) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'DOC_ACTIVITY_STARTED' });

    //let path = `/api/wdocs/create`
    let path = `/api/v2/create`;
    let postData = params;
    let accountIdn = getState().accountInView.accountIdn;
    return window.sch
      .post(path, postData)
      .then(() => {
        let path = `/api/workitems:search`;
        let postData = { nm: accountIdn, extras: { sort: 'r' } };
        if (accountIdn.replace(/^a/i, '').search(/[0-9]{3,}/) === 0) {
          path = `/api/workitems:search:byacct`;
          postData = { acct: accountIdn, extras: { sort: 'r' } };
        }

        return window.sch.post(path, postData);
      })
      .then((accountDocs) => {
        dispatch(receivedAccountDocs_ac(accountIdn, accountDocs));
        dispatch({
          type: 'DOC_ACTIVITY_UPDATED',
          docActivity: { msg: 'Work unit created' },
        });
      })
      .catch((error) => {
        dispatch({
          type: 'DOC_ACTIVITY_UPDATED',
          docActivity: { msg: 'Failed to create work unit' },
          error,
        });
      });
  };
}

export function accountSelected_ac(accountIdn) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch(requestAccountDocs_ac(accountIdn));

    let path = `/api/workitems:search`;
    let postData = { nm: accountIdn, extras: { sort: 'r' } };
    if (accountIdn.replace(/^a/i, '').search(/[0-9]{3,}/) === 0) {
      path = `/api/workitems:search:byacct`;
      postData = { acct: accountIdn, extras: { sort: 'r' } };
    }

    return window.sch
      .post(path, postData)
      .then((accountDocs) => {
        dispatch(receivedAccountDocs_ac(accountIdn, accountDocs));
      })
      .catch((error) => {
        console.error(error);
        dispatch(failed_receivedAccountDocs_ac(accountIdn, error));
      });
  };
}

export function setAccountPAsToEmpty_ac() {
  //used when no hdms accounts for idnInView found
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_ACCOUNT_PAS', account: -1, member: -1 });
    dispatch({
      type: 'RECEIVED_ACCOUNT_PAS',
      pasPkg: {
        pa_trees: [],
        n_pa_trees: 0,
        n_pa_branches: 0,
      },
    });
  };
}

export function fetchFBDocs_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_FB_DOCS' });
    let postData = {
      fb_source: 'fb_db',
      hdmsAccts: getState().idnInView.idnDoc.aa,
      idn: getState().idnInView.idnStr.replace(/_/g, ' '),
    };
    return window.sch
      .post(`/api/fb_mongo/patients/search`, postData)
      .then((result) => {
        result.idn = postData.idn.toString().replace(/_/g, ' ');
        dispatch({
          type: 'RECEIVED_FB_DOCS',
          fbDocs: result,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'FAILED_RECEIVED_FB_DOCS',
          error,
        });
      });
  };
}

export function setFBDocInPath_ac({
  version,
  patientIdx,
  fileIdx,
  fileId,
  tag,
  path,
}) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    dispatch({ type: 'SET_FB_PATH', path });
    const docJobKey = [
      'fb',
      getStore().accountInView.accountIdn,
      version,
      patientIdx,
      fileIdx,
      fileId,
      tag,
    ].join('|');
    //room msg
    let msg = {
      kind: 'document_selected',
      df_ver: process.env.REACT_APP_VER,
      doc_id: docJobKey,
      account: getStore().idnInView.idnDoc.aa_num,
      non_unique_doc_name: tag + '-' + fileId,
    };
    dispatch({ type: 'SET_DF_MSG', msg });
    let room = getStore().room;
    if (room.status === 'roommate_present' && room.socket) {
      room.socket.emit('df_msg', msg, () => {
        dispatch({ type: 'SENT_DF_MSG', msg });
      });
    }
  };
}

/* FOR handing PA Branch Highlighting */
function is_date_valid(billing_date, start, dur, exp) {
  if (dur === 99) return true;
  if (dur === 0 && exp === null) return false;
  if (exp === null) {
    exp = moment(start).add(dur, 'months');
  }

  let s = moment(billing_date, 'MM-DD-YYYY').isBetween(
    moment(start, 'YYYY-MM-DD'),
    moment(exp, 'YYYY-MM-DD'),
    'day',
    []
  );
  return s;
}

export function highlightPABranches_ac(hcpc, date) {
  return (dispatch, getStore) => {
    const { accountPAsInView } = getStore();
    if (accountPAsInView.pasPkg === null) return;
    const { pasPkg } = accountPAsInView;

    let branches = [];
    pasPkg.pa_trees.forEach((tree) => {
      tree.branches = tree.branches.map((b) => {
        b.date_valid = is_date_valid(
          date,
          b.pa_branch_date,
          b.pa_branch_dur,
          b.pa_branch_exp_date
        );
        b.hcpc_valid = b.hcpcs_list.includes(hcpc.substr(0, 5));
        b.tree_info = { ..._.omit(tree, 'branches') };
        b.rank = 1;
        b.rank += b.date_valid ? 2 : 0;
        b.rank += b.hcpc_valid ? 1 : 0;
        return b;
      });
      branches.push(tree.branches);
      return;
    });

    pasPkg.merged_branches = _.flatten(branches);
    pasPkg.merged_branches.sort((a, b) => {
      if (a.rank < b.rank) return 1;
      return -1;
    });
    dispatch({ type: 'ACCOUNT_PAS_HIGHLIGHT', pasPkg, hcpc, date });
  };
}

export function toggleTreeExpansion_ac(tIdx) {
  return (dispatch, getStore) => {
    let _pasPkg = getStore().accountPAsInView.pasPkg;
    _pasPkg.pa_trees[tIdx] = {
      ..._pasPkg.pa_trees[tIdx],
      expanded: !_pasPkg.pa_trees[tIdx].expanded,
    };

    dispatch({
      type: 'RECEIVED_ACCOUNT_PAS',
      pasPkg: _pasPkg,
    });
  };
}

export function expandCollapseAllTrees_ac(direction) {
  return (dispatch, getStore) => {
    let _pasPkg = getStore().accountPAsInView.pasPkg;

    _pasPkg.pa_trees = _pasPkg.pa_trees.map((tree) => {
      tree.expanded = direction; //true || false
      return tree;
    });

    dispatch({
      type: 'RECEIVED_ACCOUNT_PAS',
      pasPkg: _pasPkg,
    });
  };
}

export function fetchAccountPAs_ac(account, member, validateRecurring) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_ACCOUNT_PAS', account, member });
    return window.sch
      .get(`/api/account/${account}/${member}`)
      .then((result) => {
        let pasPkg = result.pkg;
        pasPkg.selectedIdxs = { treeIdx: -1, branchIdx: -1 };

        pasPkg.pa_trees = pasPkg.pa_trees.map((tree) => {
          tree.expanded = true;
          return tree;
        });

        //pas were loaded in recurring screen, do hcpc validation on each recurring item
        if (validateRecurring === true) {
          //get copy of the account in idnWos.pkg.accounts
          let idnWosPkg = getStore().idnWos.pkg;
          let accountPos = idnWosPkg.accounts.findIndex((a) => {
            return a.account === account && a.member === member;
          });

          let _idnWosPkgAccount = {
            ...idnWosPkg.accounts[accountPos],
          };

          _idnWosPkgAccount.recurr = _idnWosPkgAccount.recurr.map((item) => {
            item.date_valid_branches = [];
            item.hcpc_valid_branches = [];
            item.valid_branches = [];

            let hcpc = item.billing_code_macro_hcpc || item.product_hcpc;
            if (!hcpc || hcpc === null || hcpc === '') {
              return item;
            }

            pasPkg.pa_trees.forEach((tree) => {
              if (!tree.branches) return;

              tree.branches.forEach((b) => {
                if (!b.pa_branch_number) return;

                let date_valid = false;
                let hcpc_valid = false;
                if (
                  is_date_valid(
                    item.next_dt_str,
                    b.pa_branch_date,
                    b.pa_branch_dur,
                    b.pa_branch_exp_date
                  )
                ) {
                  date_valid = true;
                  item.date_valid_branches.push({
                    branch_id: b.pa_branch_id,
                    tree_nm: tree.pa_tree_name,
                    payer_name: tree.pa_tree_payer_name,
                    branch_date: moment(b.pa_branch_date, 'YYYY-MM-DD').format(
                      'MM-DD-YYYY'
                    ),
                  });
                }

                if (b.hcpcs_list.includes(hcpc.substr(0, 5))) {
                  hcpc_valid = true;
                  item.hcpc_valid_branches.push({
                    branch_id: b.pa_branch_id,
                    tree_nm: tree.pa_tree_name,
                    payer_name: tree.pa_tree_payer_name,
                    branch_date: moment(b.pa_branch_date, 'YYYY-MM-DD').format(
                      'MM-DD-YYYY'
                    ),
                  });
                }

                if (date_valid && hcpc_valid) {
                  item.valid_branches.push({
                    branch_id: b.pa_branch_id,
                    tree_nm: tree.pa_tree_name,
                    payer_name: tree.pa_tree_payer_name,
                    branch_date: moment(b.pa_branch_date, 'YYYY-MM-DD').format(
                      'MM-DD-YYYY'
                    ),
                  });
                }
              });
            });

            return item;
          });

          idnWosPkg.accounts[accountPos] = _idnWosPkgAccount;
          idnWosPkg.accounts[accountPos].pa_validation_done = true;
          dispatch({
            type: 'UPDATE_IDN_WOS_PKG',
            pkg: idnWosPkg,
          });
        }

        dispatch({
          type: 'RECEIVED_ACCOUNT_PAS',
          pasPkg,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'FAILED_RECEIVED_ACCOUNT_PAS',
          error,
        });
      });
  };
}

export function accountPABranchMarkAsSelected_ac(tIdx, bIdx) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    let _pasPkg = getStore().accountPAsInView.pasPkg;
    _pasPkg.selectedIdxs = {
      treeIdx: tIdx,
      branchIdx: bIdx,
    };
    dispatch({ type: 'ACCOUNT_PA_BRANCH_SELECTERD', pasPkg: _pasPkg });
  };
}

//CMN s
export function setAccountCMNsToEmpty_ac() {
  //used when no hdms accounts for idnInView found
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_ACCOUNT_CMNS', account: -1, member: -1 });
    dispatch({
      type: 'RESET_ACCOUNT_CMNS',
      cmnsPkg: {
        cmn_trees: [],
      },
    });
  };
}

export function fetchAccountCMNs_ac(account, member) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_ACCOUNT_CMNS', account, member });
    return window.sch
      .get(`/api/account/${account}/${member}`)
      .then((result) => {
        dispatch({
          type: 'RECEIVED_ACCOUNT_CMNS',
          cmnsPkg: result.pkg,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'FAILED_RECEIVED_ACCOUNT_CMNS',
          error,
        });
      });
  };
}

export function updateCmnAppointment_ac(params) {
  /* params: cmn_tree_id, cmn_branch_id, appt_date_utc */
  return (dispatch) => {
    //params passed in by thunk middleware
    //dispatch({ type: 'REQUEST_IVR_INFO', acctMember: params.acctMember })

    let target_url = `/api/cmn_appointments/update`;
    return window.sch
      .post(target_url, params)
      .then((result) => {
        dispatch({ type: 'RECEIVED_ACCOUNT_CMNS', cmnsPkg: result.pkg });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: 'FAILED_RECEIVED_IVR_INFO', error });
      });
  };
}

export function fetchAccountConsent_ac(acctMember) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_ACCOUNT_CONSENT', acctMember });
    return window.sch
      .get(`/api/consent/fetch/${acctMember}`)
      .then((result) => {
        dispatch({
          type: 'RECEIVED_ACCOUNT_CONSENT',
          consentRecord: result.consent_record,
          events: result.events,
          reference: {
            ...result.reference,
            CONSENT_STATES_BY_STATE_NAME: _.keyBy(
              result.reference.CONSENT_STATES,
              'name'
            ),
            VF_BY_NAME: _.keyBy(result.reference.VERIFIED_FLAVORS, 'name'),
          },
          hdms: result.hdms,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: 'FAILED_RECEIVED_ACCOUNT_CONSENT',
          error,
        });
      });
  };
}

export function confirmAccountConsent_ac(payload, onComplete) {
  return (dispatch, getState) => {
    const { consentRecord: pre_modify_version } = getState().accountConsent;

    //params passed in by thunk middleware
    dispatch({
      type: 'SILENT_RESET_OF_CONSENT_RECORD',
    });
    return window.sch
      .post(`/api/consent/modify`, payload)
      .then((result) => {
        dispatch({
          type: 'RECEIVED_MODIFIED_CONSENT_RECORD',
          consentRecord: result.consent_record,
          events_logged: result.events_logged,
        });
        dispatch(
          open_snack_ac({
            variant: 'info',
            message: 'Consent status updated',
          })
        );
        if (onComplete) {
          onComplete();
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          open_snack_ac({
            variant: 'error',
            message:
              'Failed to update consent status - this may be because the consent record was modified by another user. Please click RESTORE to see the latest version.',
          })
        );
        //if fails, restore the original consent record
        dispatch({
          type: 'RECEIVED_MODIFIED_CONSENT_RECORD',
          consentRecord: pre_modify_version,
          events_logged: [],
        });
      });
  };
}

export function updateHDMSField_ac(params, onComplete) {
  const { acctMember, field_name, new_value, mode } = params;
  return (dispatch, getState) => {
    const { hdms, consentRecord } = getState().accountConsent;

    //params passed in by thunk middleware
    // dispatch({ type: 'REQUEST_ACCOUNT_CONSENT', acctMember });
    return window.sch
      .post(`/api/hitlayer/accounts/update_field`, {
        acctMember,
        field_name,
        new_value,
      })
      .then((result) => {
        dispatch({
          type: 'UPDATED_HDMS_FIELD_VALUE',
          hdms: {
            ...hdms,
            [result.field_name]: result.new_value,
          },
          consentRecord: {
            ...consentRecord,
            [mode]: {
              ...consentRecord[mode],
              value: result.new_value,
            },
          },
        });
      })
      .finally(() => {
        if (onComplete) {
          onComplete();
        }

        dispatch(
          open_snack_ac({
            variant: 'info',
            message: 'HDMS Field Updated',
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Failed to Update HDMS Field',
          })
        );
        dispatch({
          type: 'FAILED_RECEIVED_ACCOUNT_CONSENT',
          error,
        });
      });
  };
}
