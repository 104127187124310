import moment from 'moment';
import React from 'react';

export default [
  {
    title: 'Account#',
    dataIndex: 'account',
    key: 'account',
    sortable: true,
  },
  {
    title: 'Last Confirmed Work Order',
    dataIndex: 'last_hdms_wo_date',
    key: 'last_hdms_wo_date',
    sortable: true,
    render: (data) => <>{moment(data.last_hdms_wo_date).format('llll')}</>,
  },
  {
    title: 'HDMS Email / Mobilephone',
    dataIndex: 'hdms_email_mobilephone',
    key: 'hdms_email_mobilephone',
    sortable: false,
    render: (data) => (
      <>
        <div>
          <span>Email: {data.email}</span>
          <br />
          <span>Mobilephone: {data.mobilephone}</span>
        </div>
      </>
    ),
  },
  {
    title: 'Legacy Onboarded Status',
    dataIndex: 'legacy_onboarded_status',
    key: 'legacy_onboarded_status',
    sortable: false,
    render: (data) => {
      if (data.legacy_onboarding_contact === null) {
        return <span>Not Onboarded</span>;
      }
      return (
        <div>
          <span>
            Onboarded{' '}
            {moment(data.legacy_onboarding_contact.Timestamps.Created).format(
              'MM-DD-YYYY'
            )}
          </span>
          {data.legacy_onboarding_contact.Timestamps.Verified !==
          'UNVERIFIED' ? (
            <p>
              Verified{' '}
              {moment(
                data.legacy_onboarding_contact.Timestamps.Verified
              ).format('MM-DD-YYYY')}
            </p>
          ) : (
            <p>Unverified</p>
          )}
        </div>
      );
    },
  },
  {
    title: 'Legacy Onboarding',
    dataIndex: 'legacy_onboarded_status',
    key: 'legacy_onboarded_status',
    sortable: false,
    render: (data) => {
      if (data.legacy_onboarding_contact === null) {
        return <span></span>;
      }
      return (
        <div>
          Email:{' '}
          {data.legacy_onboarding_contact.Email === 'UNKNOWN'
            ? ''
            : data.legacy_onboarding_contact.Email}
          <br />
          SMS:{' '}
          {data.legacy_onboarding_contact.SMS === 'UNKNOWN'
            ? ''
            : data.legacy_onboarding_contact.SMS}
        </div>
      );
    },
  },
];
