import { TEN_BILLION } from '../constants/numeric';

const startsWith = (str, prefix) => {
  return str.slice(0, prefix.length) === prefix;
};

const endsWith = (str, suffix) => {
  return str.slice(-suffix.length) === suffix;
};

export const wo_display = (wo_id) => {
  if (wo_id < TEN_BILLION) {
    return 'W' + wo_id;
  } else if (wo_id > 2 * TEN_BILLION) {
    return 'M' + (wo_id - 2 * TEN_BILLION);
  } else {
    return wo_id - TEN_BILLION + 'P';
  }
};

export const decodeWoDisplay = (encodedId) => {
  if (startsWith(encodedId, 'W')) {
    return parseInt(encodedId.slice(1));
  } else if (startsWith(encodedId, 'M')) {
    return parseInt(encodedId.slice(1)) + 2 * TEN_BILLION;
  } else if (endsWith(encodedId, 'P')) {
    return parseInt(encodedId.slice(0, -1)) + TEN_BILLION;
  } else {
    return encodedId;
  }
};
