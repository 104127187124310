export function fetchSingleWo_ac(idn,hdmsAccts,wo_id) {
  return (dispatch, getState) => { //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_SINGLE_WO', wo_id })
    let target_url = `/api/hdms:recentwos:prime`
    let postData = {hdmsAccts, idn, wo_id}
    return window.sch.post(target_url,postData)
    .then( pkg => {
      //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
      if (pkg.wos.length === 0 ){
        dispatch({ type: 'RECEIVED_SINGLE_WO', wo: null })
      }
      else{
        dispatch({ type: 'RECEIVED_SINGLE_WO', wo: pkg.wos[0] })
      }
    })
    .catch( error => {
      console.error("error:",error)
      dispatch({ type: 'FAILED_RECEIVED_SINGLE_WO', error})
    });
  }
}
