export function complianceDocs(
  state =  {
    isFetching:false,
    params: null,
    pkg: null,
    pagination: null,
    error:null
  },
  action
){
  switch (action.type) {
    case 'RESET_COMPLIANCE_DOCS':
      return Object.assign({}, state, {
        isFetching:false,
        params: null,
        pkg: null,
        pagination: null,
        error:null
      })
    case 'REQUEST_COMPLIANCE_DOCS':
      return Object.assign({}, state, {
        isFetching: true,
        params: action.params
      })

    case 'RECEIVED_COMPLIANCE_DOCS':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: action.pkg,
         pagination: action.pagination
       })
    case 'FAILED_RECEIVED_TODOS':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: null,
         pagination: null,
         error: action.error
       })
    default:
      return state;
  }
}
