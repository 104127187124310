import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import ProductChipsItem from './ProductChipsItem';

const styles = (theme) => ({
  card: {
    minWidth: 175,
    margin: '10px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: '#76ccea8c',
  },
  typoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
});

const ProductCardItem = (props) => {
  const { classes, product } = props;

  const productColor = (colorCode) => {
    switch (colorCode) {
      case 0:
        return { backgroundColor: '#00ee0088' };
      case 1:
        return { backgroundColor: '#5588ee' };
      case 2:
        return { backgroundColor: '#88ddee' };
      default:
        return { backgroundColor: '#ee0088' };
    }
  };
  const d = new Date(product.ts);
  const date = `${d.getMonth()}-${d.getDate()}-${d.getFullYear()}`;
  const backColor = productColor(product.color_code);
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
            P
          </Avatar>
        }
        title={product._id}
        subheader={date}
      />
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        />
        <div className={classes.typoContainer}>
          <Typography component="h2">
            Category:&ensp;
            {product.cat}
          </Typography>
          <Typography component="h2">
            id: &ensp;
            {product.cat}
          </Typography>
        </div>
        <div className={classes.typoContainer}>
          <Typography component="h2">
            HCPC:&ensp;
            {product.hcpc}
          </Typography>
          <Typography component="h2">
            comments:&ensp;
            {product.comments}
          </Typography>
        </div>
        <Typography component="h2">
          Manufacture:
          {product.mfg_nm}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {product.description}
        </Typography>
        <div className={classes.typoContainer}>
          <Typography component="p">
            code:&ensp;
            {product.prod_code}
          </Typography>
          <Typography component="p">
            id:&ensp;
            {product.prod_id}
          </Typography>
          {/* <Typography component="p">
            weight:&ensp;
            {product.unitweight}
          </Typography> */}
          <Typography component="p">
            per unity:&ensp;
            {product.perunitqty}
          </Typography>
        </div>
        <div className={classes.typoContainer}>
          <Typography component="p">
            type:&ensp;
            {product.uom}
          </Typography>
          <Typography component="p">
            cost:&ensp;
            {product.uomcost}
          </Typography>
          <Typography component="h2">
            count:&ensp;
            {product.uomcount}
          </Typography>
          <Typography component="h2">
            upc3:&ensp;
            {product.upc3}
          </Typography>
        </div>
        <div
          className={classes.typoContainer}
          style={{ justifyContent: 'left', alignItems: 'center' }}>
          <Typography component="p">color:&ensp;</Typography>
          <div style={{ width: '30px', height: '15px', ...backColor }}>
            TEST
          </div>
        </div>
      </CardContent>
      <Typography component="h2" style={{ margin: '0 5px 0 10px' }}>
        Vendors:&ensp;
      </Typography>
      <CardActions>
        <ProductChipsItem vendors={product.vendors} />
      </CardActions>
    </Card>
  );
};

ProductCardItem.propTypes = {
  product: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductCardItem);
