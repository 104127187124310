import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import ProductCardItem from './ProductCardItem'
import ProductRowItem from './ProductRowItem'

const styles = {
  root: {}
}

const ProductItem = props => {
  const { classes } = props
  const {
    display_as,
    product,
    rowColorIndex,
    isMaintainance,
    isCatalogueMaintainer,
    ...rest
  } = props

  return (
    <Grid className={classes.root} item xs={display_as === 'card' ? 4 : 12}>
      {display_as === 'card' ? (
        <ProductCardItem
          product={product}
          isCatalogueMaintainer={isCatalogueMaintainer}
          {...rest}
        />
      ) : (
        <ProductRowItem
          product={product}
          isCatalogueMaintainer={isCatalogueMaintainer}
          rowColorIndex={rowColorIndex}
          isMaintainance={isMaintainance}
          {...rest}
        />
      )}
    </Grid>
  )
}

ProductItem.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ProductItem)
