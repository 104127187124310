import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { push, replace } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import { AllScriptsReport } from './AllScriptReport.js'
import { ReferralDetails } from './ReferralDetails.js'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  fullHeight: {
    height: '100%'
  }
})

const PAGE_TITLE = 'All Scripts Report'
const BASE_PATH = '/reports/allscripts'

class AllScriptsLanding extends React.Component {
  componentDidMount() {
    this.props.setTitle_ac(PAGE_TITLE)
    this.init()
  }
  componentDidUpdate() {
    this.init()
  }

  init = () => {
    const { me, lookups, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    const { match, redirectToPage } = this.props
    if (isNaN(match.params.pageNum)) {
      redirectToPage(1)
    }
  }

  render() {
    const { classes, match, changePageNum } = this.props

    return (
      <main id="teamPcrSelectorPopperParent" className={classes.content}>
        <Grid
          container
          direction="row"
          className={classes.fullHeight}
          spacing={8}>
          <Grid item xs={12} md={6} className={classes.fullHeight}>
            <AllScriptsReport
              currentPage={parseInt(match.params.pageNum, 10) || 1}
              changePage={changePageNum}
            />
          </Grid>
          <Grid item md={6} className={classes.fullHeight}>
            <ReferralDetails />
          </Grid>
        </Grid>
      </main>
    )
  }
}

AllScriptsLanding.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac,
      changePageNum: newPageNum => push(`${BASE_PATH}/${newPageNum}`),
      redirectToPage: newPageNum => replace(`${BASE_PATH}/${newPageNum}`)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AllScriptsLanding))
)
