import _ from 'lodash'
import moment from 'moment'

export const actionTypes = {
  FETCHING_OXYGEN_COUNTS: 'FETCHING_OXYGEN_COUNTS',
  RECEIVED_OXYGEN_COUNTS: 'RECEIVED_OXYGEN_COUNTS',
  FAILED_FETCHING_OXYGEN_COUNTS: 'FAILED_FETCHING_OXYGEN_COUNTS',

  SAVE_OXYGEN_COUNTS_FILTERS: 'SAVE_OXYGEN_COUNTS_FILTERS'
}

const GROUP_BY_TARGET_URL_MAP = {
  dc: '/api/oxygen_counts',
  driver: '/api/oxygen_counts_by_driver'
}
const ANTI_GROUP_FILTER_MAP = {
  dc: 'driver_id',
  driver: 'dc_id'
}

export const fetchOxygenCounts_ac = (filterData) => {
  return dispatch => {

    let { groupBy, ...params } = { ...filterData }

    params = _.pickBy(params, param => param !== null)

    if (params.dc_id)
      params.dc_id = parseInt(params.dc_id, 10)
    if (params.driver_id)
      params.driver_id = parseInt(params.driver_id, 10)
    params.start = moment(params.start).format('MM-DD-YYYY')
    params.end = moment(params.end).format('MM-DD-YYYY')

    delete params[ANTI_GROUP_FILTER_MAP[groupBy]]

    dispatch({
      type: actionTypes.FETCHING_OXYGEN_COUNTS
    })

    window.sch
      .post(GROUP_BY_TARGET_URL_MAP[groupBy], params)
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_OXYGEN_COUNTS,
          data: res.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_OXYGEN_COUNTS
        })
      })
  }
}

export const saveOxygenCountsFilters_ac = filterData => {
  return dispatch => {
    dispatch({
      type: actionTypes.SAVE_OXYGEN_COUNTS_FILTERS,
      filterData
    })
  }
}
