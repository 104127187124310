import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import CustomTable from '../../common/CustomTable';
import { getFullName } from '../../../util/string';
import dateUtils from '../../../util/dateProcessing';
import { PaginationWithNums } from '../../PaginationNew';
import { DEFAULT_DATE_FORMAT } from '../../../constants/date';

const statusColorMap = {
  success: '#4caf50',
  danger: '#f44336',
  warning: '#d49100',
};

const formatDate = (date) => dateUtils.formatDate(date, DEFAULT_DATE_FORMAT);

const getStatus = (data) => {
  const completedAt = data.completed_dt ? formatDate(data.completed_dt) : null;
  const cancelledAt = data.cancel_dt ? formatDate(data.cancel_dt) : null;
  const scheduledAt = data.sched_dt ? formatDate(data.sched_dt) : null;
  const releasedAt = data.release_dt ? formatDate(data.release_dt) : null;

  const getOrderStatus = () => {
    if (completedAt) {
      return { text: `Completed at ${completedAt}`, type: 'success' };
    }
    if (cancelledAt) {
      return { text: `Cancelled at ${cancelledAt}`, type: 'danger' };
    }
    return { text: 'Open order', type: 'warning' };
  };
  const orderStatus = getOrderStatus();

  const getScheduleStatus = () => {
    if (orderStatus.text !== 'Open order') return null;

    if (!scheduledAt) {
      return { text: 'UNSCHEDULED', type: 'danger' };
    }
    switch (scheduledAt) {
      case '09-09-2099':
        return { text: 'PENDING RESCHEDULE', type: 'warning' };
      case '08-08-1988':
      case '07-07-1977':
        return { text: 'PENDING CANCELLATION', type: 'warning' };
      default:
        return { text: `Scheduled for ${scheduledAt}`, type: 'success' };
    }
  };

  const scheduleStatus = getScheduleStatus();

  const getReleaseStatus = () => {
    if (!releasedAt) {
      return { text: 'UNRELEASED', type: 'danger' };
    }
    return { text: `Released at ${releasedAt}`, type: 'success' };
  };
  const releaseStatus = getReleaseStatus();

  return { releaseStatus, orderStatus, scheduleStatus };
};

const HourlyWosList = ({
  meta,
  isLoading,
  wosRecordMap,
  onWosIdClick,
  onPageChange,
}) => {
  const data = wosRecordMap;
  const pageCount = meta && Math.ceil(meta.total / meta.size);

  const columns = [
    {
      title: 'Workorder Id',
      dataIndex: '_id',
      key: '_id',
      render: (data) => `W${data._id}`,
    },
    {
      title: 'Account Id',
      dataIndex: 'account',
      key: 'account',
      render: (data) => `A${data.account}`,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (data) => `${getFullName(data)} ${formatDate(data.dob)}`,
    },
    {
      title: 'DC',
      dataIndex: 'dc_nm',
      key: 'dc_nm',
    },
    {
      title: 'Method',
      dataIndex: 'method_name',
      key: 'method_name',
    },

    {
      title: 'Conf Log',
      dataIndex: 'cong_log',
      key: 'cong_log',
      render: (data) => data.confirmation_entry?.state,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (data) => {
        const { releaseStatus, orderStatus, scheduleStatus } = getStatus(data);
        const statuses = [releaseStatus, orderStatus, scheduleStatus].filter(
          (status) => status !== null
        );

        return statuses.map((status, index) => (
          <Typography
            key={index}
            style={{
              color: statusColorMap[status.type],
              fontWeight: '600',
            }}>
            {status.text}
          </Typography>
        ));
      },
    },
  ];

  return (
    <Grid>
      <Grid>
        <CustomTable
          data={data}
          columns={columns}
          isLoading={isLoading}
          error={{ message: 'Workorder not Found! or Select WOS group' }}
          onRowClick={(id) => {
            onWosIdClick(id);
          }}
        />
      </Grid>

      {meta && (
        <Grid style={{ marginTop: '12px' }}>
          <PaginationWithNums
            style={{ width: 'auto', margin: 'auto' }}
            currentPageNum={meta.page ? meta.page : 0}
            totalPagesAvailable={pageCount}
            onPageChange={onPageChange}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default HourlyWosList;
