import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchExpiringCMNsReport_ac } from '../../actions/expiringCMNs.ac.js'
import { setTitle_ac } from '../../actions/me.ac.js'
import { CMNIvrActivityPanel } from '../CMNIvrActivityCard'
import FetchingStatementWrap from '../FetchingStatementWrap'
import FilterListing from '../FilterListing'

const styles = theme => ({
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  tblCell: {
    verticalAlign: 'top'
  },
  fullHeight: {
    height: '100%'
  }
})

class ExpiringCMNsReportBySituation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      filterSequence: ['automation_status'],
      filters: {
        automation_status: {
          lbl: 'Automation Status',
          options: [
            { code: 'ivr_happened', lbl: 'IVR Occurred', checked: true },
            { code: 'has_appt', lbl: 'Appointment Obtained', checked: true }
          ]
        }
      }
    }
    this.checkSituation = this.checkSituation.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this)
    this.fetch = this.fetch.bind(this)
  }

  componentDidMount() {
    const { expiringCMNsReport } = this.props
    if (expiringCMNsReport.isFetching) return
    if (expiringCMNsReport.error) return
    this.props.setTitle_ac('Expiring CMNs Report')
    this.checkSituation()
    return
  }

  componentDidUpdate() {
    const { expiringCMNsReport } = this.props
    if (expiringCMNsReport.isFetching) return
    if (expiringCMNsReport.error) return
    this.checkSituation()
    return
  }

  checkSituation() {
    const { expiringCMNsReport } = this.props
    if (
      expiringCMNsReport.pkg === null &&
      expiringCMNsReport.error === null &&
      expiringCMNsReport.isFetching === false
    ) {
      this.fetch()
    }
  }

  fetch() {
    let _f = {}
    Object.keys(this.state.filters).forEach(k => {
      _f[k] = this.state.filters[k].options
        .filter(o => o.checked)
        .map(o => o.code)
    })
    this.props.fetchExpiringCMNsReport_ac({
      by: 'situation',
      filters: _f
    })
  }

  fetchWithPageReset() {
    let _f = {}
    Object.keys(this.state.filters).forEach(k => {
      _f[k] = this.state.filters[k].options
        .filter(o => o.checked)
        .map(o => o.code)
    })
    this.props.fetchExpiringCMNsReport_ac({
      by: 'situation',
      filters: _f,
      page: 0
    })
    return
  }

  onFilterChange = filterName => evt => {
    let sf = _.cloneDeep(this.state.filters[filterName])
    sf.options = sf.options.map(s => {
      if (evt.target.value === s.code) {
        s.checked = evt.target.checked
      }
      return s
    })
    this.setState({ filters: { ...this.state.filters, [filterName]: sf } })
  }

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) })
  }

  render() {
    const { match, classes, me, expiringCMNsReport } = this.props
    const title = 'Expiring Oxygen Medicare CMNs'
    if (expiringCMNsReport.error) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'Something went wrong. Contact IT'}
        />
      )
    }

    if (!expiringCMNsReport.pkg) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    if (expiringCMNsReport.isFetching) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    const { pkg } = expiringCMNsReport
    let subtitle = 'MediCare - O2 Expiring CMNs '
    if (match.params.days) {
      subtitle += ' - Expiring in ' + match.params.days + ' Days'
      if (match.params.days_end) {
        subtitle += ' to  ' + match.params.days_end + ' Days'
      }
    }

    return (
      <Grid container className={classes.fullHeight}>
        <Grid item xs={12} sm={12} className={classes.fullHeight}>
          <FilterListing
            me={me}
            filters={this.state.filters}
            filterSequence={this.state.filterSequence}
            onFilterChange={this.onFilterChange}
            applyFilters={this.fetchWithPageReset}
          />
          {pkg.rr && (
            <main className={classes.content}>
              <label className={classes.label}>
                {subtitle} ({pkg.rr.length} accounts):
              </label>
              <div className={classes.scrollArea}>
                <Table padding="dense">
                  <TableHead>
                    <TableRow>
                      <TableCell>Patient</TableCell>
                      <TableCell>Item/DOS</TableCell>
                      <TableCell>CMN Dates</TableCell>
                      <TableCell>CMN Return/WOPD Return</TableCell>
                      <TableCell>Doctor/Payer</TableCell>
                      <TableCell style={{ maxWidth: '25%' }}>
                        Automation
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pkg.rr.map((r, idx) => (
                      <TableRow key={idx}>
                        <TableCell variant="body">
                          {idx + 1}) {r.Last_Name}-{r.First_Name}
                          <br />
                          <Link to={'/dfa/account_docs/' + r.Account}>
                            {r.Account}-{r.Member}
                          </Link>
                        </TableCell>
                        <TableCell variant="body">
                          <div>{r.BillingCode}</div>
                          <div>{r.BillingCodeCategoryDescription}</div>
                          <div>
                            {moment(r.DOS.substr(0, 10), 'YYYY-MM-DD').format(
                              'MM-DD-YYYY'
                            )}
                          </div>
                        </TableCell>

                        <TableCell variant="body">
                          {moment(
                            r.MyCMN_EffectiveDate.substr(0, 10),
                            'YYYY-MM-DD'
                          ).format('MM-DD-YYYY')}
                          &nbsp;to&nbsp;
                          <strong>
                            {moment(
                              r.MyCMN_ExpirationDate.substr(0, 10),
                              'YYYY-MM-DD'
                            ).format('MM-DD-YYYY')}
                          </strong>
                          <br />
                          Days to Expiration: {r.daysAway}
                          <br />
                          {r.appt && (
                            <strong style={{ color: 'blue' }}>
                              Appointment Date: {r.appt.appt_date_mm_dd_yyyy}
                            </strong>
                          )}
                        </TableCell>
                        <TableCell variant="body">
                          {r.MyCMN_CMNReturnDate
                            ? moment(
                                r.MyCMN_CMNReturnDate.substr(0, 10),
                                'YYYY-MM-DD'
                              ).format('MM-DD-YYYY')
                            : 'None'}
                          <br />
                          {r.MyCMN_WrittenOrderReturnDate
                            ? moment(
                                r.MyCMN_WrittenOrderReturnDate.substr(0, 10),
                                'YYYY-MM-DD'
                              ).format('MM-DD-YYYY')
                            : 'None'}
                        </TableCell>
                        <TableCell variant="body">
                          Dr. {r.CMN_MD_Last_Name}, {r.CMN_MD_First_Name}
                          <br />
                        </TableCell>

                        <TableCell style={{ width: '25%' }}>
                          {!_.isEmpty(r.ivr_info) && (
                            <CMNIvrActivityPanel oxyCmnPkg={r.ivr_info} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </main>
          )}
        </Grid>
      </Grid>
    )
  }
}

ExpiringCMNsReportBySituation.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  expiringCMNsReport: stateFromStore.expiringCMNsReport
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchExpiringCMNsReport_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExpiringCMNsReportBySituation))
)
