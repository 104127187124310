import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  changePolling_ac,
  change_forwarding_ac,
  fetchMailBoxes_ac,
  patchMailboxEmail_ac,
  patchMailboxNotifyCC_ac,
} from '../../actions/mail_boxes.ac.js';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import FilterListing from '../FilterListing';
import { PaginationWithNums } from '../PaginationNew';
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent';
import Loader from '../Shared/Loader';
import MailboxEmailPatchModal from './MailboxEmailPatchModal.js';
import MailboxNotifyCCPatchModal from './MailboxNotifyCCPatchModal.js';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  completedTime: {
    color: '#4a148c',
  },
  dc: {
    paddingLeft: 10,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
  },
  patientNm: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  title: {
    flexGrow: 1,
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    padding: 8,
    backgroundColor: '#00000005',
    borderBottom: '1px solid #00000030',
  },
  switch: {
    paddingLeft: 5,
  },
  forwarding: {
    backgroundColor: '#ffee00',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  spacer: {
    height: 40,
  },
  fullHeight: {
    height: '100%',
  },
});

const MAP_FILTER = {
  qname: 'QNames',
  sp: 'Source',
  monitor: 'Poll/NotPoll',
};
const MAP_REQUEST_PARAMS_STATE = {
  ringcentral: {
    filterBy: 'sp',
    filterKey: 'ringcentral',
  },
  concord: {
    filterBy: 'sp',
    filterKey: 'concord',
  },
  gmail: {
    filterBy: 'sp',
    filterKey: 'windstream',
  },
  scanner: {
    filterBy: 'sp',
    filterKey: 'sc-scanner',
  },
  pilot: {
    filterBy: 'qname',
    filterKey: 'PCR_INDEXER_POP_PILOT',
  },
  sc_gso: {
    filterBy: 'sp',
    filterKey: 'sc-gso',
  },
};
const MAP_COMPONENT_TITLE = {
  ringcentral: 'Ring Central',
  concord: 'Concord',
  gmail: 'Gmail',
  scanner: 'Scanner',
  pilot: 'Pilot',
  sc_gso: 'Sc Gso',
};
const DEFAULT_FILTERS = {
  qname: {
    lbl: 'QNames',
    options: [{ lbl: 'ALL', code: 'all', checked: false }],
  },
  sp: {
    lbl: 'Source',
    options: [{ lbl: 'ALL', code: 'all', checked: false }],
  },
  monitor: {
    lbl: 'Poll/NotPoll',
    options: [{ lbl: 'ALL', code: 'all', checked: false }],
  },
};

/**
 * @param {object} tags
 * @returns Object
 */
function filterBuilder(tags) {
  if (!tags) return {};
  return Object.keys(tags).reduce((acc, item) => {
    acc[item] = {
      lbl: MAP_FILTER[item],
      options: _buildOptions(tags[item]),
    };
    return acc;
  }, {});

  function _buildOptions(options) {
    return options.map((item) => ({
      code: item._id,
      lbl: item._id ? `${item._id}(${item.n})` : `(${item.n})`,
      checked: true,
    }));
  }
}

class MailBoxesComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props);
    this.state = {
      requestedPageNum: 0,
      filterSequence: ['qname', 'sp', 'monitor'],
      filterChangeInProgress: false,
      filters: { ...DEFAULT_FILTERS },
      filtersSet: false,
      filterMsg: '',
      activeFilterBy: 'filters',
      mailBoxEmailPatchModalOpenFor: false,
      mailBoxNotifyCCPatchModalOpenFor: false,
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this);
    this.getFetchParams = this.getFetchParams.bind(this);
  }

  handlePageChange = (newPageNum) => {
    let targetPage = parseInt(newPageNum, 10) - 1;

    this.setState({
      requestedPageNum: targetPage,
    });
    this.fetch(targetPage);
  };

  getPageStatement = (pagination) => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination;
    let pageEnd = totalRecords < end ? totalRecords : end;

    return [
      `${MAP_COMPONENT_TITLE[this.props.match.params.tab_name]} - Displaying
      ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${this.state.requestedPageNum + 1} of ${totalPagesAvailable}`,
    ].join('-');
  };

  componentDidMount() {
    const { lookups, setTitle_ac, me, lookups_ac, match } = this.props;
    if (!me.user) {
      return;
    }
    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return lookups_ac();
    }

    setTitle_ac(MAP_COMPONENT_TITLE[match.params.tab_name]);

    return this.fetch();
  }

  componentDidUpdate(prevProps) {
    const { mailBoxesInView, lookups, me, match } = this.props;
    if (!me.user) return;
    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return this.props.lookups_ac();
    }

    if (mailBoxesInView.tags && this.state.filtersSet === false) {
      const filters = filterBuilder(mailBoxesInView.tags);
      let _default_filters = {
        ...DEFAULT_FILTERS,
        ...filters,
      };
      this.setState({
        filters: _default_filters,
        filtersSet: true,
      });
      return;
    }

    if (
      mailBoxesInView.isFetching === false &&
      !mailBoxesInView.error &&
      mailBoxesInView.records === null
    ) {
      return this.fetch();
    }

    if (prevProps.match.params.tab_name !== match.params.tab_name) {
      return this.fetch();
    }
  }

  /**
   * @param {array} records
   */
  buildDownloadingItemStatus(records) {
    const downloadItemList = (records || []).reduce((acc, item) => {
      acc[item._id] = {
        isDownloading: false,
      };
      return acc;
    }, {});
    this.setState({
      downloadItemList,
    });
  }

  getFetchParams() {
    return {
      tagFilters: {
        qname: this.state.filters.qname.options
          .filter((s) => s.checked)
          .map((s) => s.code),
        sp: this.state.filters.sp.options
          .filter((s) => s.checked)
          .map((s) => s.code),
        monitor: this.state.filters.monitor.options
          .filter((s) => s.checked)
          .map((s) => s.code),
      },
    };
  }

  /**
   * @param {string} mailboxId
   * @param {boolean} status
   */
  changeForwarding(mailboxId, status) {
    this.props.change_forwarding_ac({
      mailboxId,
      forwardToEmail: status,
    });
  }

  fetch(pageNum = 0) {
    let params = {
      ...MAP_REQUEST_PARAMS_STATE[this.props.match.params.tab_name],
      requestedPageNum: pageNum,
    };
    this.props.fetchMailBoxes_ac(params);
    return;
  }

  fetchWithPageReset() {
    this.props.fetchMailBoxes_ac({
      ...MAP_REQUEST_PARAMS_STATE[this.props.match.params.tab_name],
      ...this.getFetchParams(),
      requestedPageNum: 0,
    });
    this.setState({ requestedPageNum: 0 });
    return;
  }

  /**
   * @param {string} mailboxId
   * @param {boolean} status
   */
  changePollingStatus(mailboxId, status) {
    this.props.changePolling_ac({
      mailboxId,
      monitor: status,
    });
  }

  render() {
    const { mailBoxesInView, classes, me, lookups } = this.props;

    if (mailBoxesInView.isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading records" />
        </main>
      );
    }
    if (!lookups.ink || mailBoxesInView.records === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>...</h4>
        </div>
      );
    }
    if (this.state.filtersSet === false) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....filters not set</h4>
        </div>
      );
    }

    if (mailBoxesInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching Jobs</h4>
        </div>
      );
    }

    if (!mailBoxesInView.records.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>No records found.</h4>
        </div>
      );
    }

    const { pagination: pg } = mailBoxesInView;
    let pageStatement = '';
    if (pg) {
      pageStatement = this.getPageStatement(pg);
    }

    let pageSelector = '';
    if (pg.totalPagesAvailable > 1) {
      pageSelector = (
        <PaginationWithNums
          currentPageNum={pg.currentPageNum}
          totalPagesAvailable={pg.totalPagesAvailable}
          onPageChange={this.handlePageChange}
        />
      );
    }

    return (
      <Grid container className={classes.fullHeight}>
        <Grid container spacing={0} item className={classes.fullHeight}>
          <FilterListing
            me={me}
            applyFilters={this.fetchWithPageReset}
            filters={this.state.filters}
            filterSequence={this.state.filterSequence}
            onFilterChange={this.onFilterChange}
            onFilterToggle={this.onFilterToggle}
          />
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Grid container spacing={8}>
                <Grid item xs={6} sm={6}>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    {pageStatement}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {pageSelector}
                </Grid>
              </Grid>
            </div>

            <div className={classes.scrollArea}>
              <div className={classes.root}>
                <Table padding="dense">
                  <TableBody>
                    {mailBoxesInView.records.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell variant="body">
                          <div style={{ flex: 1 }}>
                            {item.mbox ? `${item.mbox.name}` : ''}
                            <span className={classes.pageCount}>
                              {item.pages ? `${item.pages.length} pgs` : ''}
                            </span>
                          </div>
                          {item.monitor && (
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              onClick={() =>
                                this.changePollingStatus(item._id, false)
                              }>
                              Polling Into DocMgt
                            </Button>
                          )}
                          {!item.monitor && (
                            <Button
                              variant="contained"
                              size="small"
                              color="default"
                              onClick={() =>
                                this.changePollingStatus(item._id, true)
                              }>
                              Not Polling
                            </Button>
                          )}
                          <div>
                            #{index + 1} in Result Set - State:{item.state}
                          </div>
                        </TableCell>
                        <TableCell variant="body">
                          <div>
                            <strong>{item.name}</strong>
                          </div>
                          <div>
                            MailboxID: {item._id}
                            <span className={classes.dc}>DC:</span>
                          </div>
                          <div>{item.extId && `ExtId: ${item.extId}`}</div>
                          <div
                            style={{
                              textDecoration: 'underline',
                              color: 'blue',
                              cursor: 'pointer',
                              marginTop: '4px',
                            }}
                            onClick={() => {
                              this.setState({
                                mailBoxEmailPatchModalOpenFor: item._id,
                              });
                            }}>
                            Contact Email: {item.email || item.contact_email}
                          </div>
                          <div
                            style={{
                              textDecoration: 'underline',
                              color: 'crimson',
                              cursor: 'pointer',
                              margin: '4px 0',
                            }}
                            onClick={() => {
                              this.setState({
                                mailBoxNotifyCCPatchModalOpenFor: item._id,
                              });
                            }}>
                            CC:{' '}
                            {item.notify?.cc ? item.notify.cc.join(', ') : ''}
                          </div>

                          <div>
                            <span className={classes.forwarding}>
                              {' '}
                              {item.forwardToEmail &&
                                `IF POLLING=TRUE,Forwarding via DFAssist to ${item.email}`}
                            </span>
                            <span>
                              {!item.forwardToEmail &&
                                `Not Forwarding via DFAssist`}
                            </span>
                            <span className={classes.switch}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={item.forwardToEmail}
                                    onChange={() =>
                                      this.changeForwarding(
                                        item._id,
                                        !item.forwardToEmail
                                      )
                                    }
                                    value={item.forwardToEmail}
                                  />
                                }
                              />
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <h3>{item.stateMsg}</h3>
                          {/* <div>
                            Last ask: {formatDate.relativeTime(item.lastAsk)}
                          </div>
                          <div>
                            Last cutoff:{' '}
                            {formatDate.relativeTime(item.lastAskCutoff)}
                          </div> */}
                          <div>
                            Last Received a Fax: <br />
                            {item.lastGotMsgs
                              ? moment(item.lastGotMsgs).format(
                                  'MM/DD/YYYY hh:mm:ss'
                                )
                              : '-'}
                          </div>
                          {/* <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="default">
                              {item.msgCountSinceFirstPoll || '-'}
                            </Button>
                          </div> */}
                        </TableCell>
                        <TableCell variant="body">
                          <div>
                            {(item.phonenumbers || []).map((phonenumber, i) => (
                              <p key={i}> {phonenumber}</p>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell variant="body">
                          <Button
                            disabled={false} // TODO
                            variant="contained"
                            size="medium"
                            color="secondary">
                            Q:{item.qname}
                          </Button>
                          <br />
                        </TableCell>
                        <TableCell variant="body">
                          <Button
                            disabled={false} // TODO
                            variant="contained"
                            size="medium"
                            color="default">
                            Service:{item.sp}
                          </Button>
                          <br />
                        </TableCell>

                        {this.state.mailBoxEmailPatchModalOpenFor ===
                          item._id && (
                          <MailboxEmailPatchModal
                            open={true}
                            email={item.email || item.contact_email}
                            disabled={mailBoxesInView.isUpdating || false}
                            onClose={() => {
                              this.setState({
                                mailBoxEmailPatchModalOpenFor: null,
                              });
                            }}
                            onSubmit={(email, cb) => {
                              this.props.patchMailboxEmail_ac(
                                {
                                  mailboxId: item._id,
                                  email,
                                },
                                {
                                  onSuccess: () => {
                                    this.props.open_snack_ac({
                                      message: 'Email updated',
                                      variant: 'success',
                                    });
                                    cb();
                                  },
                                  onError: (error) => {
                                    this.props.open_snack_ac({
                                      message: 'Error updating email',
                                      variant: 'error',
                                    });
                                  },
                                }
                              );
                            }}
                          />
                        )}

                        {this.state.mailBoxNotifyCCPatchModalOpenFor ===
                          item._id && (
                          <MailboxNotifyCCPatchModal
                            open={true}
                            emails={item.notify?.cc || []}
                            disabled={mailBoxesInView.isUpdating || false}
                            onClose={() => {
                              this.setState({
                                mailBoxNotifyCCPatchModalOpenFor: null,
                              });
                            }}
                            onSubmit={(emails, cb) => {
                              this.props.patchMailboxNotifyCC_ac(
                                {
                                  mailboxId: item._id,
                                  emails,
                                },
                                {
                                  onSuccess: () => {
                                    this.props.open_snack_ac({
                                      message: 'Notify CC updated',
                                      variant: 'success',
                                    });
                                    cb();
                                  },
                                  onError: (error) => {
                                    this.props.open_snack_ac({
                                      message: 'Error updating notify CC',
                                      variant: 'error',
                                    });
                                  },
                                }
                              );
                            }}
                          />
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
    );
  }
}

MailBoxesComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  mailBoxesInView: stateFromStore.mailBoxesInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchMailBoxes_ac,
      change_forwarding_ac,
      changePolling_ac,
      patchMailboxNotifyCC_ac,
      patchMailboxEmail_ac,
      open_snack_ac,
      lookups_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export const MailBoxes = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MailBoxesComponent))
);
