import { actionTypes } from '../actions/idx_admin_ordering_clinicians.ac';

const initialFilterData = {
  first_name: '',
  last_name: '',
  oc_level: '',
  npi: '',
  tags: null
};

const initialState = {
  error: null,
  data: null,
  isFetching: false,
  removeError: null,
  isRemoving: false,
  filters: initialFilterData,
  sorting: null
};

export const idxAdminOrderingClinicians = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ORDERING_CLINICIANS:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.RECEIVED_IDX_ORDERING_CLINICIANS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.data
      };

    case actionTypes.FAILED_FETCHING_IDX_ORDERING_CLINICIANS:
      return {
        ...state,
        error: true,
        data: null,
        isFetching: false
      };

    case actionTypes.REMOVE_IDX_ORDERING_CLINICIANS_PENDING:
      return {
        ...state,
        isRemoving: true
      };

    case actionTypes.REMOVE_IDX_ORDERING_CLINICIANS_SUCCESSFUL:
      return {
        ...state,
        isRemoving: false,
        data: {
          ...state.data,
          rr: state.data?.rr?.filter(oc => oc._id !== action.data)
        }
      };

    case actionTypes.REMOVE_IDX_ORDERING_CLINICIANS_FAILED:
      return {
        ...state,
        isRemoving: false,
        removeError: action.error
      };

    case actionTypes.SAVE_ORDERING_CLINICIAN_SEARCH_FILTERS:
      return { ...state, filters: action.filterData };

    case actionTypes.SAVE_ORDERING_CLINICIAN_SORTING:
      return { ...state, sorting: action.filterData.sorting };

    default:
      return { ...state };
  }
};
