import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AllScriptsLanding from './AllScriptsReportLanding'

const AllScriptsReportRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/reports/allscripts/:pageNum"
        component={AllScriptsLanding}
      />
      <Redirect to="/reports/allscripts/1" />
    </Switch>
  )
}

export default AllScriptsReportRouter;
