import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Tab, Tabs } from '@material-ui/core'
import { push } from 'connected-react-router'
import { withStyles } from '@material-ui/core/styles'
import { Route, Switch, withRouter } from 'react-router-dom'

import SalesDashWOS from './SalesDashWOS'
import SalesDashHome from './SalesDashHome'
import { setTitle_ac } from '../../actions/me.ac.js'

const styles = () => ({
    filterRoot: {
        paddingLeft: 16
    }
})

class SalesDashLanding extends React.Component {
    constructor() {
        super();

        this.state = {}
    }

    onTabSelect = (e, _selectedTab) => {
        if (this.props.match.params.page === 'home') {
            let toPath = `/salesdash/home/${_selectedTab}`;
            return this.props.changePage(toPath);
        }
    }

    render() {
        const { classes, match } = this.props
        const tabsClass = {
            flexContainer: classes.flexContainer,
            root: classes.tabsRoot
        }
        const tabClass = {
            root: classes.tabRoot,
            labelContainer: classes.tabLabelContainer
        }

        return (
            <>
                {match.params.page === 'home'
                    && (
                        <Tabs
                            classes={tabsClass}
                            value={match.params.tabName}
                            onChange={this.onTabSelect}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="off">
                            <Tab value="my_progress" label="My Progress" classes={tabClass} />
                            <Tab value="my_buckets" label="My Buckets" classes={tabClass} />
                            <Tab value="my_history" label="My History" classes={tabClass} />
                            <Tab value="my_brown_list" label="My Brown List" classes={tabClass} />
                        </Tabs>
                    )}
                <Switch>
                    <Route path="/salesdash/home/:tabName" component={SalesDashHome} />
                    <Route path="/salesdash/wos/:region/:salesperson/:workBucket/:category/:year/:month/:day" component={SalesDashWOS} />
                </Switch>
            </>
        )
    }
}

SalesDashLanding.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
    me: stateFromStore.me,
    lookups: stateFromStore.lookups,
    masterSearch: stateFromStore.masterSearch
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setTitle_ac,
            changePage: path => {
                return push(path.replace(/:tabParam/, ''))
            }
        },
        dispatch
    )

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(SalesDashLanding))
)
