import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

const ZebraPrint = ({ deviceList, refreshPage, print, wo }) => {
  const [busy, setBusy] = useState(false);

  const printing = async () => {
    setBusy(true);
    try {
      await print();
    } catch (e) {
      setBusy(false);
    }
  };

  return (
    <div>
      {deviceList.length === 0 ? (
        <div>
          <Typography variant="h4" color="textPrimary">
            Sorry, Printer's not Found!
          </Typography>
          <Grid spacing={8}>
            <Button variant="contained" color="primary" onClick={refreshPage}>
              Refresh Page
            </Button>
          </Grid>
        </div>
      ) : (
        <div>
          <div>
            <Typography variant="h4" color="textPrimary">
              Press label for order {wo._id}
            </Typography>
            <Button
              disabled={busy}
              variant="contained"
              color="primary"
              onClick={printing}>
              print labels
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ZebraPrint;
