import { Chip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const SmallChip = withStyles({
  root: {
    height: 24,
    borderRadius: 2,
    margin: 2
  },
  deleteIcon: {
    height: '80%',
    fontSize: 20,
    margin: '0 2px 0 -10px'
  }
})(Chip)

export default SmallChip
