import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TablePagination from '@material-ui/core/TablePagination'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { withStyles } from '@material-ui/core/styles'

import StyledTooltip from '../Shared/StyledTooltip'

const tableStyles = () => ({
  selectRoot: {
    display: 'none'
  },
  toolbar: {
    justifyContent: 'space-between'
  },
  spacer: {
    flex: '0 0'
  },
  alternateRow: {
    '&:nth-child(even)': {
      backgroundColor: '#f1f1f180'
    }
  },
  headerCell: {
    backgroundColor: '#efefef',
    padding: '8px',
    border: '1px solid #e0e0e0',
    borderBottom: '3px solid #c5c5c5',
    position: 'sticky',
    top: 0,
    zIndex: 100
  },
  rowCell: {
    padding: '8px',
    border: '1px solid #e0e0e0'
  },
  scrollableTableContainer: {
    maxHeight: 'calc(100% - 56px)',
    width: '100%',
    minHeight: '200px',
    overflow: 'auto'
  },
  ascendingIcon: {
    transform: 'rotate(-90deg)',
    transition: 'transform 0.3s'
  },
  descendingIcon: {
    transform: 'rotate(-270deg)',
    transition: 'transform 0.3s'
  }
});

const tablePaginationStyles = () => ({
  paginationButton: {
    border: '1px solid #e5e5e5',
    '&:first-child': {
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px'
    },
    '&:last-child': {
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px'
    },
    '& > button': {
      padding: '4px 8px',
      minWidth: 'unset',
      borderRadius: 'unset'
    }
  },
  hiddenPages: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
});

const TablePaginationActions = props => {
  const { classes, count, page, rowsPerPage } = props;

  const totalPages = Math.ceil(count / rowsPerPage);
  let pagesArray = [];

  const firstAndPrevDisabled = page === 0;
  const nextAndLastDisabled = totalPages - 1 === page;
  const showFirstDots = (page > 2) && (totalPages > 5);
  const showLastDots = (page < totalPages - 3) && (totalPages > 5);

  if (showLastDots && showFirstDots) {
    for (let i = page - 1; i <= page + 2; ++i) {
      pagesArray.push(i);
    }
  } else if (showFirstDots && !showLastDots) {
    for (let i = totalPages - 4; i <= totalPages - 1; ++i) {
      pagesArray.push(i);
    }
  } else if (!showFirstDots && showLastDots) {
    for (let i = 0; i <= 3; ++i) {
      pagesArray.push(i);
    }
  } else {
    for (let i = 0; i < totalPages; ++i) {
      pagesArray.push(i);
    }
  }

  const handelFirstClick = event => {
    props.onChangePage(event, 0);
  }

  const handlePreviousClick = event => {
    props.onChangePage(event, page - 1);
  }

  const handleNextClick = event => {
    props.onChangePage(event, page + 1);
  }

  const handleLastClick = event => {
    props.onChangePage(event, totalPages - 1);
  }

  const handlePageClick = (event, page) => {
    props.onChangePage(event, page);
  }

  return (
    <Grid
      container
      justify="flex-end"
      style={{ marginLeft: 20 }}
    >
      <Grid item className={classes.paginationButton}>
        <Button
          color="primary"
          onClick={handelFirstClick}
          disabled={firstAndPrevDisabled}
        >
          First
        </Button>
      </Grid>
      <Grid item className={classes.paginationButton}>
        <Button
          color="primary"
          onClick={handlePreviousClick}
          disabled={firstAndPrevDisabled}
        >
          Previous
        </Button>
      </Grid>
      {showFirstDots
        ? (
          <Grid
            item
            className={classes.paginationButton + ' ' + classes.hiddenPages}>
            <Typography color="textSecondary">
              &ensp;...&ensp;
            </Typography>
          </Grid>
        )
        : ''}
      {pagesArray.map(pageNum => (
        <Grid key={pageNum} item className={classes.paginationButton}>
          <Button
            variant={pageNum === page ? 'contained' : 'text'}
            color="primary"
            value={pageNum}
            onClick={(e) => handlePageClick(e, pageNum)}
          >
            {pageNum + 1}
          </Button>
        </Grid>
      ))}
      {showLastDots
        ? (
          <Grid
            item
            className={classes.paginationButton + ' ' + classes.hiddenPages}>
            <Typography color="textSecondary">
              &ensp;...&ensp;
            </Typography>
          </Grid>
        )
        : ''}
      <Grid item className={classes.paginationButton}>
        <Button
          color="primary"
          onClick={handleNextClick}
          disabled={nextAndLastDisabled}
        >
          Next
        </Button>
      </Grid>
      <Grid item className={classes.paginationButton}>
        <Button
          color="primary"
          onClick={handleLastClick}
          disabled={nextAndLastDisabled}
        >
          Last
        </Button>
      </Grid>
    </Grid>
  );
}

const sortRowsByDateTime = (rows, sortingCol, isAscendingOrder) => {
  if (!sortingCol) {
    return rows;
  }

  const order = isAscendingOrder ? -1 : 1;

  return rows.sort((rowA, rowB) => {
    const rowADateTime = rowA[sortingCol] ? new Date(rowA[sortingCol]) : '';
    const rowBDateTime = rowB[sortingCol] ? new Date(rowB[sortingCol]) : '';

    if (rowADateTime < rowBDateTime) {
      return order;
    } else if (rowADateTime > rowBDateTime) {
      return (-1 * order);
    }

    return 0;
  })
}

const sortRowsByString = (rows, sortingCol, isAscendingOrder) => {
  if (!sortingCol) {
    return rows;
  }

  const order = isAscendingOrder ? -1 : 1;

  return rows.sort((rowA, rowB) => {
    const rowAString = rowA[sortingCol]
      ? `${rowA[sortingCol]}`.toLowerCase()
      : '';
    const rowBString = rowB[sortingCol]
      ? `${rowB[sortingCol]}`.toLowerCase()
      : '';

    if (rowAString < rowBString) {
      return order;
    } else if (rowAString > rowBString) {
      return (-1 * order);
    }

    return 0;
  })
}

const TablePaginationActionsWrapper = withStyles(
  tablePaginationStyles
)(TablePaginationActions);

const WOSTable = props => {
  const { columns, rows, rowsPerPage, classes } = props;

  const customClass = {
    narrow_col: {
      minWidth: '120px'
    },
    extra_narrow_col: {
      minWidth: '75px'
    },
    other_cell: {
      minWidth: '95px'
    }
  };

  const [page, changePageNumber] = useState(0);
  const [sortBy, changeSortColumn] = useState('');
  const [isAscendingOrder, changeSortOrder] = useState(true);
  const [sortedRows, changeSortedRows] = useState(rows);

  const updateSortedRows = useCallback((sortingCol, orderFlag, sortType) => {
    let newSortedRows = null;

    if (sortType === 'datetime') {
      newSortedRows = sortRowsByDateTime(rows, sortingCol, orderFlag);
    } else {
      newSortedRows = sortRowsByString(rows, sortingCol, orderFlag);
    }

    if (newSortedRows && newSortedRows.length) {
      changeSortedRows([...newSortedRows]);
    } else {
      changeSortedRows([...rows]);
    }
  }, [rows])

  useEffect(() => {
    changePageNumber(0);
  }, [rowsPerPage]);

  useEffect(() => {
    const sortingColExists = columns
      .some(column => column.accessor === sortBy);

    if (sortBy && !sortingColExists) {
      changeSortColumn('');
      updateSortedRows('', true, '');
    }
  }, [columns, rows, rowsPerPage, sortBy, updateSortedRows]);

  const handleSort = (colAccessor, sortType) => {
    const isSortingByCol = sortBy === colAccessor;
    const newSortOrderFlag = isSortingByCol
      ? !isAscendingOrder
      : true;

    if (!isSortingByCol) {
      changeSortColumn(colAccessor);
    }
    changeSortOrder(newSortOrderFlag);
    updateSortedRows(colAccessor, newSortOrderFlag, sortType);
  }

  return (
    <>
      <div className={classes.scrollableTableContainer}>
        <Table padding="dense" style={{ borderCollapse: 'separate' }}>
          <TableHead>
            <TableRow>
              {columns.map(col => (
                <TableCell
                  key={'head_' + col.accessor}
                  align="center"
                  className={classes.headerCell}
                  style={{
                    ...(col.className
                      ? customClass[col.className]
                      : customClass.narrow_col)
                  }}
                >
                  <StyledTooltip
                    title={isAscendingOrder ? 'Ascending' : 'Descending'}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                        paddingRight: 24
                      }}
                      onClick={() => handleSort(col.accessor, col.sortType || '')}
                    >
                      <strong>{col.Header}</strong>
                      {sortBy === col.accessor
                        && (
                          <IconButton
                            className={isAscendingOrder
                              ? classes.ascendingIcon
                              : classes.descendingIcon}
                            style={{
                              padding: 0,
                              position: 'absolute',
                              top: 'calc(50% - 12px)',
                              right: 0
                            }}
                          >
                            <TableSortLabel
                              active={true}
                              direction={isAscendingOrder ? 'asc' : 'desc'}
                              IconComponent={() => <ArrowRightAltIcon />}
                            />
                          </IconButton>
                        )}
                      {sortBy !== col.accessor
                        && (
                          <TableSortLabel
                            active={true}
                            style={{
                              position: 'absolute',
                              top: 'calc(50% - 12px)',
                              right: 0
                            }}
                            IconComponent={() => <SwapVertIcon color="disabled" />}
                          />
                        )}
                    </Typography>
                  </StyledTooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.line_id} className={classes.alternateRow}>
                  {columns.map(col => (
                    <TableCell
                      key={'row_' + col.accessor}
                      className={classes.rowCell}
                    >
                      <Typography variant="body2">
                        {row[col.accessor] || '-'}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 20, 30]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage=""
        labelDisplayedRows={({ from, to, count }) => (
          <Typography variant="body1" color="textSecondary">
            Showing&ensp;
            <strong>{from}</strong>
                &ensp;to&ensp;
            <strong>{to}</strong>
                &ensp;of&ensp;
            <strong>{count}</strong>&ensp;entries
          </Typography>
        )}
        classes={{
          toolbar: classes.toolbar,
          spacer: classes.spacer,
          selectRoot: classes.selectRoot
        }}
        onChangePage={(e, page) => changePageNumber(page)}
        ActionsComponent={TablePaginationActionsWrapper}
      />
    </>
  );
}

export default withStyles(tableStyles)(WOSTable);
