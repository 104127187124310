export const IndexSummaryActionTypes = {
  RECEIVED_INDEX_SUMMARY: 'RECEIVED_INDEX_SUMMARY',
  FAILED_RECEIVED_INDEX_SUMMARY: 'FAILED_RECEIVED_INDEX_SUMMARY',
  SET_IS_FETCHING: 'SET_IS_FETCHING'
}
export function fetchIndexSummary_ac(params) {
  return dispatch => {
    dispatch(fetching(true))
    return window.sch
      .post('/api/idx:report', { ...params })
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }
        dispatch({
          type: IndexSummaryActionTypes.RECEIVED_INDEX_SUMMARY,
          summary: result
        })
      })
      .catch(error => {
        dispatch({
          type: IndexSummaryActionTypes.FAILED_RECEIVED_INDEX_SUMMARY,
          error
        })
      })
      .finally(() => {
        dispatch(fetching(false))
      })
  }
}

export const fetching = isFetching => ({
  type: IndexSummaryActionTypes.SET_IS_FETCHING,
  isFetching
})
