import React from 'react'
import Popper from '@material-ui/core/Popper'
import PropTypes from 'prop-types'

/**
 * Bound the popper within the parent element if id is provided, 
 * otherwise return general Popper
 */
const LocalPopper = props => {
  const { localParentId = '', children, modifiers = {}, ...otherProps } = props

  if (localParentId.length === 0) {
    return (
      <Popper modifiers={{ ...modifiers }} {...otherProps}>
        {children}
      </Popper>
    )
  }

  const parentElement = document.getElementById(localParentId)

  return (
    <Popper
      modifiers={{
        ...modifiers,
        preventOverflow: {
          enabled: true,
          boundariesElement: parentElement
        },
        hide: {
          enabled: true
        }
      }}
      {...otherProps}
    >
      {children}
    </Popper>
  )
}

LocalPopper.propTypes = {
  localParentId: PropTypes.string.isRequired,
  ...Popper.propTypes
}

export default LocalPopper
