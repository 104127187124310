export const peekWorkunit = async (
  vId,
  fields = [],
  { before, after, onSuccess, onError }
) => {
  if (before) before();
  try {
    const response = await window.sch.post('/api/df/workunits/peek', {
      vId,
      fields,
    });

    if (response.error) {
      throw new Error(response.error);
    }

    const wu = response.workunit || null;

    if (onSuccess) onSuccess(wu);
    if (after) after();
    return wu;
  } catch (err) {
    if (onError) onError(err);
    console.log(`Failed to peek workunit: ${vId}`, err);
    if (after) after();
  }
};
