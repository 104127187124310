import { Collapse, Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { searchFBAccounting_ac } from '../../actions/fb_search.ac'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  sectionLbl: {
    fontSize: 11,
    color: '#9c27b0',
    fontWeight: 700,
    paddingTop: 12,
    marginBottom: 12
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  fullHeight: {
    height: '100%'
  },
  searchButton: {
    margin: '8px 0px 4px 0px'
  }
})

const searchTips = `
                Search by any combination of PO#, PO Date, Invoice#, Invoice Date, or Vendor
                Apply trailing wildcard with *

                To search by PO#, prefix the PO# with a "p"
                Example: p00013366*  will match against POs 000133660 to 000133669, for any vendor

                To search by INV#, prefix the Invoice# with an "i"
                Example: i349323* will match against Invoices 349323, 3493231, 3493225, etc..

                To search by Credit#, prefix the Credit# with an "c"
                Example: c14848900 

                To search by PO Date, prefix a date in MM-DD-YYYY format with a "p"
                Example: p04-03-2015 will match against POs dated April 3, 2015

                To search by Invoice Date, prefix a date in MM-DD-YYYY format with a "p"
                Example: i04-03-2015 will match against Invoices dated April 3, 2015

                Combine filters by separating them with ";" semicolon

                Example:
                vMcKesson; p00013366*
               `

class FBAccountingSearch extends Component {
  constructor() {
    super()
    this.state = {
      searchStr: '',
      toggleSearchTips: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.search();
    }
  }


  handleSearchTipsToggle = () => {
    this.setState({
      toggleSearchTips: !this.state.toggleSearchTips
    })
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({
      searchStr: value
    })
  }

  clear = () => {
    this.setState({
      searchStr: ''
    })
  }

  search = (ver = 4) => {
    this.props.searchFBAccounting_ac({
      ver,
      searchStr: this.state.searchStr,
      page: this.props.page || 0
    })
  }

  render() {
    const { classes, fbSearchInView } = this.props

    return (
      <Grid container spacing={16} item>
        <Grid container item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            onChange={this.handleChange}
            placeholder="Search by Invoice#, PO#, Date, etc..(see search tips)"
            name="searchStr"
            value={this.state.searchStr || ''}
            style={{ flexGrow: 1 }}
          />
        </Grid>
        <Grid container item xs={12} md={6} alignItems="stretch">
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.search(6)}
            disabled={!this.state.searchStr || fbSearchInView.isFetching}
            className={classes.searchButton}>
            Search FB Accounting
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={this.clear}
            className={classes.searchButton}
            disabled={!this.state.searchStr}
            style={{ marginLeft: '5px' }}>
            Clear
          </Button>
        </Grid>
        <Divider component="hr" />
        <Grid item xs={12}>
          <Typography
            inline
            color="textSecondary"
            style={{ cursor: 'pointer' }}
            onClick={this.handleSearchTipsToggle}>
            View Search Tips [+/-]
          </Typography>
          <Collapse in={this.state.toggleSearchTips}>
            <pre
              style={{
                padding: 6,
                backgroundColor: '#CFD8DC',
                fontSize: 10
              }}>
              {searchTips}
            </pre>
          </Collapse>
        </Grid>
      </Grid>
    )
  }
}

FBAccountingSearch.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchFBAccounting_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBAccountingSearch))
)
