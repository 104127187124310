//reducer for dfaInView
export function generalUploadActivity(
  state = {
    successPkg: null,
    submissionFiles: [],
    state: 'new', //inprog, error, readyForUpload(ready for submission), uploadInProgress
    error: false,
  },
  action
) {
  switch (action.type) {
    case 'ATTACH_GEN_UPLOAD_FILES_TO_STORE':
      return Object.assign({}, state, {
        submissionFiles: action.submissionFiles,
      });
    case 'RESET_GEN_UPLOAD_ACTIVITY':
      return Object.assign({}, state, {
        submissionFiles: [],
        state: 'new',
        successPkg: null,
        error: false,
      });
    case 'BEGIN_UPLOAD_FILES_FOR_GEN_SUBMISSION':
      return Object.assign({}, state, {
        state: 'uploadsInProgress',
      });

    case 'COMPLETED_UPLOAD_FILES_FOR_GEN_SUBMISSION':
      return Object.assign({}, state, {
        state: 'uploadsComplete',
      });

    case 'FAILED_UPLOAD_FILES_FOR_GEN_SUBMISSION':
      return Object.assign({}, state, {
        state: 'uploadsFailed',
      });

    default:
      return state;
  }
}
