import {
  Button,
  Collapse,
  Drawer,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  ArrowRight as ArrowRightIcon,
  Cached as CachedIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { bindActionCreators } from 'redux';
import { fetchTodosSlicing_ac } from '../../actions/todos.ac';
import Loader from '../Shared/Loader';
import StyledTooltip from '../Shared/StyledTooltip';

const styles = () => ({
  drawerWrapper: {
    position: 'relative',
    height: '100%',
    marginRight: 24,
  },
  drawerFabHolder: {
    position: 'absolute',
    top: 60,
    right: '-18px',
    zIndex: 1201,
  },
  drawer: {
    flexShrink: 0,
    height: '100%',
    borderRight: '1px solid #00000050',
  },
  drawerSpacer: {
    minHeight: 56,
    width: '100%',
  },
  drawerContent: {
    height: 'calc(100% - 56px)',
    overflowY: 'auto',
  },
});

const ExpandableTreeNode = (props) => {
  const [isExpanded, setIsExpanded] = useState(props.isExpanded ? true : false);

  const handleNodeClick = (isExpandable) => {
    if (isExpandable) {
      setIsExpanded(!isExpanded);
    }

    if (props.applyFilters) {
      props.applyFilters();
    }
  };

  if (!props.isLeaf) {
    return (
      <div className={props.classes.expandableNode}>
        <Typography
          inline
          variant="body1"
          onClick={() => handleNodeClick(true)}
          className={props.classes[`${props.level}_level_text`]}
          style={{ cursor: 'pointer' }}>
          <ArrowRightIcon
            className={props.classes.arrow}
            color={!isExpanded ? 'primary' : 'secondary'}
            style={{
              transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
            }}
          />
          <strong>{props.header}</strong>
          <Typography inline component="span" color="textSecondary">
            &ensp;&bull;&ensp;{props.count}
          </Typography>
        </Typography>

        <Collapse in={isExpanded} unmountOnExit={true}>
          {props.children}
        </Collapse>
      </div>
    );
  }

  return (
    <div className={props.classes.leaf}>
      <Typography
        variant="body2"
        onClick={() => handleNodeClick(false)}
        className={props.classes.c_level_text}
        style={{ cursor: 'pointer' }}>
        <strong>{props.header}</strong>
        &ensp;&bull;&ensp;{props.count}
      </Typography>
    </div>
  );
};

const StyledTreeNode = withStyles(() => ({
  expandableNode: {
    position: 'relative',
    paddingLeft: 24,
  },
  arrow: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  leaf: {
    padding: 2,
    borderLeft: '1px dashed #00000060',
    borderBottom: '1px dashed #00000060',
  },
  root_level_text: {
    '&:hover': {
      color: 'grey',
    },
  },
  a_level_text: {
    color: '#0089f6',
    '&:hover': {
      color: '#0089f6af',
    },
  },
  b_level_text: {
    color: '#00b007',
    '&:hover': {
      color: '#00b007af',
    },
  },
  c_level_text: {
    color: '#7f7f7f',
    '&:hover': {
      color: '#7f7f7faf',
    },
  },
}))(ExpandableTreeNode);

const DrawerWrapper = (props) => {
  const { isOpen, drawerWidth, setIsOpen, classes } = props;

  const closedStyle = {
    width: '12px',
    transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  };
  const openStyle = {
    width: `${drawerWidth}px`,
    transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  };

  return (
    <div className={classes.drawerWrapper}>
      <div className={classes.drawerFabHolder}>
        <Fab size="small" color="secondary" onClick={setIsOpen}>
          <KeyboardArrowLeftIcon
            fontSize="small"
            style={{
              transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </Fab>
      </div>
      <Drawer
        variant="persistent"
        open={isOpen}
        PaperProps={{
          style: {
            width: drawerWidth,
            padding: 8,
            border: 'none',
          },
        }}
        className={classes.drawer}
        style={{
          ...(!isOpen ? closedStyle : openStyle),
        }}>
        <div className={classes.drawerSpacer} />
        {props.children}
      </Drawer>
    </div>
  );
};

const SlicingOrderPanel = (props) => {
  const { slicingOrder, applyOrderChange } = props;
  const orderLabels = ['a', 'b', 'c'];
  const fieldLabelMap = {
    $code: 'Category',
    $state: 'Status',
    $assignee_nm: 'Assignee',
  };

  const [isChanged, setIsChanged] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [newOrder, setNewOrder] = useState([...slicingOrder]);

  const onOrderChange = (changedOrder) => {
    if (changedOrder.every((order) => typeof order === 'string')) {
      if (!_.isEqual(slicingOrder, changedOrder)) {
        setIsChanged(true);
      } else {
        setIsChanged(false);
      }

      setNewOrder([...changedOrder]);
    }
  };

  const onSave = () => {
    applyOrderChange(newOrder);
    setIsExpanded(false);
  };

  const handleExpandChange = (e, expand) => {
    if (expand) {
      setIsChanged(false);
      setNewOrder([...slicingOrder]); // Reset on expandsion
    }

    setIsExpanded(expand);
  };

  return (
    <ExpansionPanel
      elevation={0}
      expanded={isExpanded}
      onChange={handleExpandChange}
      style={{
        borderBottom: '1px solid #00000050',
        marginBottom: 8,
      }}>
      <ExpansionPanelSummary
        style={{ padding: '0px 8px', backgroundColor: '#00000010' }}
        expandIcon={
          <StyledTooltip
            title={isExpanded ? 'Cancel the change' : 'Change the order'}>
            <CachedIcon fontSize="small" />
          </StyledTooltip>
        }>
        <Typography variant="subtitle1">
          <strong>Current slicing Order:</strong>
          <Typography
            variant="subtitle2"
            component="span"
            color="textSecondary">
            <strong>
              {slicingOrder.map((order, idx) => (
                <span key={idx}>
                  {fieldLabelMap[order]}({orderLabels[idx]})&ensp;
                  {idx < slicingOrder.length - 1 ? <>&gt;&ensp;</> : null}
                </span>
              ))}
            </strong>
          </Typography>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
        <Typography variant="body2" gutterBottom>
          <u>New order:</u>&ensp;
          <Typography component="span" variant="caption" color="textSecondary">
            (Drag and drop within the list to re-order.)
          </Typography>
        </Typography>
        <ReactSortable
          list={[...newOrder]}
          setList={onOrderChange}
          direction="vertical">
          {newOrder.map((field, idx) => (
            <div key={idx} style={{ paddingLeft: 8, marginBottom: 4 }}>
              <Typography inline component="span" color="textSecondary">
                ({orderLabels[idx]})&ensp;
              </Typography>
              <Button variant="outlined">{fieldLabelMap[field]}</Button>
            </div>
          ))}
        </ReactSortable>
      </ExpansionPanelDetails>
      <ExpansionPanelActions style={{ backgroundColor: '#00000010' }}>
        <Button color="secondary" onClick={() => setIsExpanded(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          disabled={!isChanged}>
          Save
        </Button>
      </ExpansionPanelActions>
    </ExpansionPanel>
  );
};

class TodosSummaryTree extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: true,
      slicingOrder: {
        a: {
          label: 'a',
          field: '$code',
        },
        b: {
          label: 'b',
          field: '$state',
        },
        c: {
          label: 'c',
          field: '$assignee_nm',
        },
      },
      filterKeyMap: {
        $code: 'code',
        $state: 'state',
        $assignee_nm: 'assignee_nm',
      },
    };
  }

  componentDidMount() {
    const { slicingState } = this.props;

    if (
      !slicingState.isFetching &&
      !slicingState.error &&
      !slicingState.slicing
    ) {
      return this.props.fetchTodosSlicing_ac({
        abc: this.state.slicingOrder,
      });
    }
  }

  componentDidUpdate() {
    const { slicingState } = this.props;

    if (
      !slicingState.isFetching &&
      !slicingState.error &&
      !slicingState.slicing
    ) {
      return this.props.fetchTodosSlicing_ac({
        abc: this.state.slicingOrder,
      });
    }
  }

  setIsOpen = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  };

  applyOrderChange = (newOrder) => {
    let slicingOrderFields = Object.values(this.state.slicingOrder).map(
      (value) => value.field
    );

    if (!_.isEqual(newOrder, slicingOrderFields)) {
      let orderLabels = ['a', 'b', 'c'];

      let newOrderObject = newOrder.reduce(
        (obj, curr, currIdx) =>
          Object.assign(obj, {
            [orderLabels[currIdx]]: {
              label: orderLabels[currIdx],
              field: curr,
            },
          }),
        {}
      );

      this.props.fetchTodosSlicing_ac({
        abc: newOrderObject,
      });

      this.setState({
        slicingOrder: { ...newOrderObject },
      });
    }
  };

  render() {
    const { isOpen, filterKeyMap, slicingOrder } = this.state;
    const { classes, slicingState, todoStateRefMap, applyFilters } = this.props;
    const drawerWidth = this.props.width
      ? parseInt(this.props.width) - 24
      : 240;

    if (slicingState.isFetching) {
      return (
        <DrawerWrapper
          isOpen={isOpen}
          drawerWidth={drawerWidth}
          setIsOpen={this.setIsOpen}
          classes={classes}>
          <Loader message="...loading tree data" />
        </DrawerWrapper>
      );
    }

    if (slicingState.error) {
      return (
        <DrawerWrapper
          isOpen={isOpen}
          drawerWidth={drawerWidth}
          setIsOpen={this.setIsOpen}
          classes={classes}>
          <Typography variant="h6" color="error">
            ...error fetching task summary tree
          </Typography>
        </DrawerWrapper>
      );
    }

    if (!slicingState.slicing || !todoStateRefMap) {
      return (
        <DrawerWrapper
          isOpen={isOpen}
          drawerWidth={drawerWidth}
          setIsOpen={this.setIsOpen}
          classes={classes}>
          <Typography variant="h6">...</Typography>
        </DrawerWrapper>
      );
    }

    let slicingOrderFields = Object.values(slicingOrder).map(
      (value) => value.field
    );

    return (
      <DrawerWrapper
        isOpen={isOpen}
        drawerWidth={drawerWidth}
        setIsOpen={this.setIsOpen}
        classes={classes}>
        <SlicingOrderPanel
          slicingOrder={slicingOrderFields || []}
          applyOrderChange={this.applyOrderChange}
        />
        <div className={classes.drawerContent}>
          {slicingState.slicing &&
            Object.keys(slicingState.slicing).map((_root) => (
              <StyledTreeNode
                key={_root}
                level="root"
                header={_root.toUpperCase()}
                count={slicingState.slicing[_root].total}>
                {slicingState.slicing[_root].records.map((levelA) => (
                  <StyledTreeNode
                    key={levelA.a}
                    level="a"
                    header={
                      slicingOrder.a.field === '$code'
                        ? todoStateRefMap[levelA.a].lbl
                        : levelA.a
                    }
                    count={levelA.a_total}
                    applyFilters={() =>
                      applyFilters({
                        ...slicingState.slicing[_root].default_crit,
                        [filterKeyMap[slicingOrder.a.field]]: [levelA.a],
                      })
                    }>
                    {levelA.b.map((levelB) => (
                      <StyledTreeNode
                        key={levelB.b}
                        level="b"
                        header={
                          slicingOrder.b.field === '$code'
                            ? todoStateRefMap[levelB.b].lbl
                            : levelB.b
                        }
                        count={levelB.b_total}
                        applyFilters={() =>
                          applyFilters({
                            ...slicingState.slicing[_root].default_crit,
                            [filterKeyMap[slicingOrder.a.field]]: [levelA.a],
                            [filterKeyMap[slicingOrder.b.field]]: [levelB.b],
                          })
                        }>
                        {levelB.c.map((levelC) => (
                          <StyledTreeNode
                            key={levelC.nm}
                            isLeaf
                            level="c"
                            header={
                              slicingOrder.c.field === '$code'
                                ? todoStateRefMap[levelC.nm].lbl
                                : levelC.nm
                            }
                            count={levelC.n}
                            applyFilters={() =>
                              applyFilters({
                                ...slicingState.slicing[_root].default_crit,
                                [filterKeyMap[slicingOrder.a.field]]: [
                                  levelA.a,
                                ],
                                [filterKeyMap[slicingOrder.b.field]]: [
                                  levelB.b,
                                ],
                                [filterKeyMap[slicingOrder.c.field]]: [
                                  levelC.nm,
                                ],
                              })
                            }
                          />
                        ))}
                      </StyledTreeNode>
                    ))}
                  </StyledTreeNode>
                ))}
              </StyledTreeNode>
            ))}
        </div>
      </DrawerWrapper>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  slicingState: stateFromStore.todosSummarySlicing,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTodosSlicing_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TodosSummaryTree));
