import {
  Button,
  Collapse,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'

const DEFAULT_FORM_DATA = {
  accountNo: '',
  productCode: '',
  serialNo: '',
  workOrderId: ''
}
const FIELDS = {
  accountNo: {
    label: 'Account No.(s)',
    type: 'number',
    placeholder: 'e.g. 1234, 682873, ...'
  },
  productCode: {
    label: 'Product Code(s)',
    type: 'text',
    placeholder: 'e.g. INHF, VXT4, ...'
  },
  serialNo: {
    label: 'Serial No.(s)',
    type: 'text',
    placeholder: 'e.g. SN310, SN410, ...'
  },
  workOrderId: {
    label: 'Work Order ID(s)',
    type: 'number',
    placeholder: 'e.g. 231412, 346346, ...'
  }
}
const FIELD_NAMES = Object.keys(FIELDS)
const SEARCH_HINTS = `
Hints:
- Atleast one field is required at a time
- Each field can accept multiple values separated by comma
- Any value of a field less than 3 characters or digits will be ignored
- Account No and Work Order ID fields accept numeric values only`

export const SNLookupForm = props => {
  const { isFetching, onSubmit } = props
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA)
  const [isHintShown, setIsHintShown] = useState(true)

  const isFormValid = () => {
    const { accountNo, productCode, serialNo, workOrderId } = formData

    return !(
      accountNo === '' &&
      productCode === '' &&
      serialNo === '' &&
      workOrderId === ''
    )
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!isFormValid()) return

    onSubmit(formData)
  }

  const handleChange = e => {
    const { name, value } = e.target
    const newField = {
      [name]: value
    }

    setFormData(formData => ({ ...formData, ...newField }))
  }

  return (
    <div style={{ padding: 4 }}>
      <Grid
        container
        spacing={8}
        component="form"
        role="form"
        onSubmit={handleSubmit}
      >
        {FIELD_NAMES.map(field => (
          <Grid item key={field}>
            <TextField
              margin="dense"
              variant="outlined"
              onChange={handleChange}
              placeholder={FIELDS[field].placeholder}
              name={field}
              value={formData[field]}
              label={<strong>{FIELDS[field].label}</strong>}
              disabled={isFetching}
              InputLabelProps={{
                shrink: true
              }}
              style={{ minWidth: 200 }}
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            disabled={isFetching || !isFormValid()}
          >
            Search
          </Button>
          <Button
            size="medium"
            variant="outlined"
            onClick={() => setIsHintShown(!isHintShown)}
            style={{ marginLeft: 8 }}
          >
            {isHintShown ? 'Hide Hints' : 'Show Hints'}
          </Button>
        </Grid>
      </Grid>
      <Collapse in={isHintShown}>
        <Typography variant="caption" color="textPrimary" gutterBottom>
          <pre>
            {SEARCH_HINTS}
          </pre>
        </Typography>
      </Collapse>
    </div>
  )
}
