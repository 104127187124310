import { actionTypes } from '../../actions/fa_routes.ac';

export const initialState = {
  list: {
    isFetching: false,
    error: null,
    data: [],
    meta: {},
  },
  isSettingRoute: false,
  routeSetError: null,
};

const faRoutes = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_FA_ROUTES_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: true,
          error: null,
        },
      };
    case actionTypes.FETCH_FA_ROUTES_SUCCESSFUL:
      const { rr: list, ...rest } = action.data;
      return {
        ...state,
        list: {
          isFetching: false,
          error: null,
          data: list,
          meta: rest,
        },
      };

    case actionTypes.FETCH_FA_ROUTES_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          isFetching: false,
          error: action.error,
        },
      };

    case actionTypes.SET_FA_ROUTE_PENDING:
      return {
        ...state,
        isSettingRoute: true,
        routeSetError: null,
      };

    case actionTypes.SET_FA_ROUTE_SUCCESSFUL:
      return {
        ...state,
        isSettingRoute: false,
        routeSetError: null,
        list: {
          ...state.list,
          data: action.created
            ? [...state.list.data, action.data]
            : state.list.data.map((route) => {
                if (route._id === action.data._id) {
                  return action.data;
                }
                return route;
              }),
        },
      };

    case actionTypes.SET_FA_ROUTE_FAILED:
      return {
        ...state,
        isSettingRoute: false,
        routeSetError: action.error,
      };

    default:
      return state;
  }
};

export default faRoutes;
