import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: '3vh 4px 4px 4px'
  },
  table: {
    minWidth: 650
  },
  paper: {
    width: '100%'
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}))

const isNumber = x => {
  return Number(Number.parseFloat(x)) === x
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy)
}

const EnhancedTableHead = prop => {
  const {
    numSelected,
    onSelectAllClick,
    order,
    orderBy,
    onRequestSort,
    rowCount,
    headRows
  } = prop
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow key="headers">
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all desserts' }}
          />
        </TableCell>
        {headRows.map(rr => (
          <TableCell
            key={rr.id}
            align={rr.numeric ? 'right' : 'left'}
            padding={rr.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === rr.id ? order : false}>
            <TableSortLabel
              active={orderBy === rr.id}
              direction={order}
              onClick={createSortHandler(rr.id)}>
              {rr.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  headRows: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired
}

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '1vh',
    paddingRight: '1vh'
  },
  spacer: {
    flex: '1 1 100%'
  },
  title: {
    flex: '0 0 auto'
  }
}))

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles()
  const { numSelected } = props

  const handleDeleteClick = (event, s, t) => {
    props.setSelected([])
    props.deletefun(s, t)
  }

  const handleAddClick = () => {
    props.setSelected([])
    props.addfun()
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {props.team}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip
            title="Delete"
            onClick={event =>
              handleDeleteClick(event, props.selected, props.team)
            }>
            <IconButton aria-label="Delete">
              Remove from Group
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Add" onClick={event => handleAddClick()}>
            <IconButton aria-label="Add">
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
}

const SelectTable = props => {
  const classes = useStyles()
  const [order, setOrder] = React.useState('asc')
  const [selected, setSelected] = React.useState([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const tableHeaders = Object.keys(props.data[0]).map((l, j) => {
    let header = {}
    header.id = l
    header.label = l
    if (j === 0) {
      header.disablePadding = true
    } else {
      header.disablePadding = false
    }
    header.numeric = isNumber(props.data[0][l])
    return header
  })

  const [orderBy, setOrderBy] = React.useState(tableHeaders[0].id)

  const isSelected = name => selected.indexOf(name) !== -1

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  function handleSelectAllClick(event, key) {
    if (event.target.checked) {
      const newSelecteds = props.data.map(n => {
        return n[key]
      })
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, props.data.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          team={props.title}
          deletefun={props.deletefun}
          addfun={props.addfun}
          setSelected={setSelected}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} size="small">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              onSelectAllClick={event =>
                handleSelectAllClick(event, tableHeaders[0].id)
              }
              headRows={tableHeaders}
              rowCount={props.data.length}
            />
            <TableBody>
              {stableSort(props.data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((dd, index) => {
                  const isItemSelected = isSelected(dd[tableHeaders[0].id])
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      key={dd[tableHeaders[0].id]}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      onClick={event =>
                        handleClick(event, dd[tableHeaders[0].id])
                      }
                      tabIndex={-1}
                      selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {tableHeaders.map((h, i) => {
                        if (i === 0) {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={dd[tableHeaders[0].id] + '-' + h.id}>
                              {dd[h.id]}
                            </TableCell>
                          )
                        }
                        return (
                          <TableCell
                            align="left"
                            key={dd[tableHeaders[0].id] + '-' + h.id}>
                            {dd[h.id]}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page'
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page'
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return { dailyData: state.dailyData }
}

export default withRouter(connect(mapStateToProps)(SelectTable))
