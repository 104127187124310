/*
export function intel_web_worker(
  state = {
    worker: null,
    callback: e => {
      console.log('in webworker callback')
      console.log(e)
      return
    }
  },
  action
) {
  switch (action.type) {
    case 'INIT_WORKER':
      return Object.assign({}, state, {
        worker: action.worker
      })
    case 'SET_WORKER_CALLBACK':
      return Object.assign({}, state, {
        callback: action.callback
      })
    default:
      return state
  }
}
*/

export function notifWebWorker(
  state = {
    initiated: false,
    worker: null
  },
  action
) {
  switch (action.type) {
    case 'INIT_NOTIF_WORKER':
      return Object.assign({}, state, {
        initiated: true,
        worker: action.worker
      })
    case 'FAILED_NOTIF_WORKER_RECORDS':
      return Object.assign({}, state, {
        error: action.error
      })
    case 'RECEIVED_NOTIF_WORKER_RECORDS':
      return Object.assign({}, state, {
        records: action.records
      })
    case 'UPDATE_NOTIF_WORKER_RECORDS':
      return Object.assign({}, state, {
        records: action.records
      })
    default:
      return state
  }
}
