export const UserMgtLogsActionTypes = {
    RECEIVED_LOGS: 'RECEIVED_LOGS',
    FAILED_RECEIVING_LOGS: 'FAILED_RECEIVING_LOGS',
    SET_IS_FETCHING: 'SET_IS_FETCHING',
};
export function fetchUserMgtLogs_ac(params) {
    return (dispatch) => {
        dispatch(fetching(true));
        return window.sch.post('/api/alist/user', { ...params })
            .then(result => {
                if (result.error) {
                    throw new Error(result.error)
                }
                dispatch({
                    type: UserMgtLogsActionTypes.RECEIVED_LOGS,
                    records: result.rows,
                    tags: result.tagCounts,
                    pagination: result.pagination
                });
            })
            .catch(error => {
                dispatch({
                    type: UserMgtLogsActionTypes.FAILED_RECEIVING_LOGS,
                    error
                });
            })
            .finally(() => {
                dispatch(fetching(false));
            });
    }
}

export const fetching = isFetching => ({
    type: UserMgtLogsActionTypes.SET_IS_FETCHING,
    isFetching
})