export const WorkUnitActionTypes = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  RECEIVED_BY_DATE_BY_HOURS_REPORT: 'RECEIVED_BY_DATE_BY_HOURS_REPORT',
  FAILED_RECEIVING_REPORT: 'FAILED_RECEIVING_REPORT'
}

export const fetchWorkUnitReport_ac = params => dispatch => {
  dispatch(setIsFetching(true))
  window.sch
    .post('/api/reports_new/by_hours', params)
    .then(response => {
      dispatch({
        type: WorkUnitActionTypes.RECEIVED_BY_DATE_BY_HOURS_REPORT,
        records: response.records,
        bkt_label_hash: response.bkt_label_hash,
        pagination: response.pagination
      })
    })
    .catch(error => {
      dispatch({
        error: true,
        type: WorkUnitActionTypes.FAILED_RECEIVING_REPORT
      })
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

const setIsFetching = isFetching => ({
  type: WorkUnitActionTypes.SET_IS_FETCHING,
  isFetching
})
