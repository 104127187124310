import { Button, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  closeStagedDocsView_ac,
  openStagedDocsView_ac,
  flushDocsForCopy_ac,
  unstageDocForCopy_ac,
  initGlobalWUAction_ac,
} from '../../../actions/global_wu_actions.ac.js';
import { FileCopy } from '@material-ui/icons';
import { GLOBAL_WU_ACTIONS } from '../constants.js';

const styles = (theme) => ({
  empty: {
    color: '#fff',
  },
  nonEmpty: {
    color: '#00e5ff',
  },
  inProgress: {
    color: '#ffc107',
  },
  success: {
    color: '#76ff03',
  },
  error: {
    color: '#d50000',
  },

  popover: {
    position: 'absolute',
    left: '-100px',
    width: '450px',
    maxHeight: '450px',
    overflow: 'auto',
    overflowX: 'hidden',
    padding: '12px',
    backgroundColor: '#fff',
    zIndex: 999,
    borderRadius: '4px',
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bodyEmpty: {
    textAlign: 'center',
    marginTop: 30,
  },
  historyItem: {
    margin: '15px 10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pdfLink: {
    fontSize: '12px',
    textDecoration: 'none',
  },
  linkIcon: {
    fontSize: '16px',
    marginLeft: '2px',
    cursor: 'pointer',
  },

  noDocs: {
    width: '100%',
    textAlign: 'center',
    padding: '28px 0px',
    color: 'grey',
    fontSize: 12,
  },

  docList: {
    border: '1px solid #ccc',
    padding: '6px',
    borderRadius: '4px',
    paddingBottom: 0,
    marginTop: '12px',
  },

  tblHeaderNode: {
    padding: '4px 0',
  },

  tblHeaderNodeTxt: {
    fontWeight: 'bold',
    fontSize: '11px',
  },

  tblNode: {
    padding: '2px 0',
    marginBottom: '4px',
  },

  tblNodeTxt: {
    color: 'blue',
    fontSize: '10px',
  },
});

const WUDocCopyStage = (props) => {
  const { classes, globalWUAction } = props;

  const { showStagedDocsView, stagedDocs, isCopyingDocsToWU } = globalWUAction;
  const hasDocs = stagedDocs.length > 0;

  let cName = classes.empty;

  if (isCopyingDocsToWU) cName = classes.inProgress;
  else if (hasDocs) cName = classes.nonEmpty;

  return (
    <div
      style={{
        position: 'relative',
      }}>
      <Tooltip title={'Copy docs into a workunit'} placement="bottom">
        <IconButton
          disabled={isCopyingDocsToWU}
          onClick={() => {
            if (showStagedDocsView) {
              props.closeStagedDocsView_ac();
            } else {
              props.openStagedDocsView_ac();
            }
          }}>
          <FileCopy fontSize="small" className={cName}></FileCopy>
        </IconButton>
      </Tooltip>

      {showStagedDocsView && (
        <div className={classes.popover}>
          <div className={classes.buttons}>
            <div>
              <Button
                style={{
                  color: 'orange',
                  borderColor: 'orange',
                }}
                size="small"
                variant="outlined"
                onClick={() => {
                  props.closeStagedDocsView_ac();
                }}>
                {'Close'}
              </Button>

              <Button
                variant="outlined"
                size="small"
                disabled={!hasDocs}
                style={{ marginLeft: 8, color: 'red', borderColor: 'red' }}
                onClick={() => {
                  props.flushDocsForCopy_ac();
                }}>
                {'Clear'}
              </Button>
            </div>

            <Button
              size="small"
              variant="outlined"
              disabled={!hasDocs}
              color="primary"
              onClick={() => {
                props.closeStagedDocsView_ac();
                props.initGlobalWUAction_ac(
                  GLOBAL_WU_ACTIONS.COPY_DOCS,
                  {
                    docs: stagedDocs,
                  },
                  () => {
                    props.flushDocsForCopy_ac();
                  }
                );
              }}>
              {'Proceed'}
            </Button>
          </div>

          {hasDocs ? (
            <Grid className={classes.docList} container>
              <Grid item xs={8} className={classes.tblHeaderNode}>
                <Typography className={classes.tblHeaderNodeTxt}>
                  {'Document'}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.tblHeaderNode}>
                <Typography className={classes.tblHeaderNodeTxt}>
                  {'Origin Fax ID'}
                </Typography>
              </Grid>
              <Divider component={'hr'} />
              {stagedDocs.map((rcr, idx) => (
                <React.Fragment key={idx}>
                  <Grid item xs={8} className={classes.tblNode}>
                    <Typography className={classes.tblNodeTxt}>
                      <span
                        style={{
                          color: 'red',
                          marginRight: '4px',
                          cursor: 'pointer',
                          fontSize: '12px',
                        }}
                        onClick={() => {
                          props.unstageDocForCopy_ac(rcr);
                        }}>
                        {'[X]'}
                      </span>
                      {rcr.doc?.displayAs
                        ? `${rcr.doc?.displayAs} [${
                            rcr.doc?.pages?.length || 0
                          } pages]`
                        : 'Invalid Doc'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.tblNode}>
                    <Typography className={classes.tblNodeTxt}>
                      {rcr.faxJob?._id || 'Invalid Fax'}
                    </Typography>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          ) : (
            <Typography className={classes.noDocs}>
              {'No docs staged for copy'} <br />
              {'Click on the copy icon next to any document to stage it'}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  globalWUAction: state.globalWUAction,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeStagedDocsView_ac,
      openStagedDocsView_ac,
      flushDocsForCopy_ac,
      unstageDocForCopy_ac,
      initGlobalWUAction_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(WUDocCopyStage))
);
