import actionTypes from '../actionTypes'


const initialState = {
  jsonSend: {
    inProgress: false,
    result: null,
    statusMsg: '',
    error: null
  }
}


export default (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.formCreation.jsonSend.try:
      return {
        ...state,
        jsonSend: {
          ...initialState.jsonSend,
          statusMsg: 'Sending JSON to test server...',
          inProgress: true
        }
      }
    case actionTypes.formCreation.jsonSend.success:
      return {
        ...state,
        jsonSend: {
          ...state.jsonSend,
          inProgress: false,
          statusMsg: 'JSON in test server has been updated',
          result: action.result
        }
      }
    case actionTypes.formCreation.jsonSend.fail:
      return {
        ...state,
        jsonSend: {
          ...state.jsonSend,
          inProgress: false,
          statusMsg: action.error.message,
          error: action.error
        }
      }
    default:
      return state
  }
}

