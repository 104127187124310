import { Button, Card, CardContent, TextField } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

const styles = (theme) => ({
  wFull: {
    width: '100%',
  },
  maxW2xl: {
    maxWidth: '42rem',
  },
  mxAuto: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  bgWhite: {
    backgroundColor: 'white',
  },
  shadowLg: {
    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  },
  p8: {
    padding: '2rem',
  },
  text2xl: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
  fontBold: {
    fontWeight: 700,
  },
  mb6: {
    marginBottom: '1.5rem',
  },
  textGray800: {
    color: '#2d3748',
  },
  textSm: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  fontMedium: {
    fontWeight: 500,
  },
  mb8: {
    marginBottom: '2rem',
  },
  textGray500: {
    color: '#a0aec0',
  },
  spaceY6: {
    '& > * + *': {
      marginTop: '1.5rem',
    },
  },
  spaceY4: {
    '& > * + *': {
      marginTop: '1rem',
    },
  },
  block: {
    display: 'block',
  },
  textGray700: {
    color: '#4a5568',
  },
  mb1: {
    marginBottom: '0.25rem',
  },
  grid: {
    display: 'grid',
  },
  gridCols6: {
    gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
  },
  gap4: {
    gap: '1rem',
  },
  colSpan3: {
    gridColumn: 'span 3 / span 3',
  },
  colSpan2: {
    gridColumn: 'span 2 / span 2',
  },
  pt6: {
    paddingTop: '1.5rem',
  },
  borderT: {
    borderTopWidth: '1px',
  },
  borderGray200: {
    borderColor: '#edf2f7',
  },
  flex: {
    display: 'flex',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  justifyBetween: {
    justifyContent: 'space-between',
  },
  spaceX2: {
    '& > * + *': {
      marginLeft: '0.5rem',
    },
  },
  // Custom component styles
  card: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },
  input: {
    width: '90%',
    padding: 4,
    // padding: '0.Grid5rem 0.75rem',
    // border: '1px solid #e2e8f0',
    // borderRadius: '0.25rem',
    fontSize: '0.825rem',
    '&:focus': {
      outline: 'none',
      borderColor: '#4299e1',
      boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    },
  },
  button: {
    padding: '0.5rem 1rem',
    borderRadius: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 500,
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
  buttonDefault: {
    backgroundColor: '#4299e1',
    color: 'white',
  },
  buttonOutline: {
    backgroundColor: 'white',
    color: '#4299e1',
    border: '1px solid #4299e1',
  },
});

const ShippingForm = ({
  wos_doc,
  upBox,
  downBox,
  updateWoField,
  validatePosInt,
  classes,
}) => {
  const [pkgs, setPkgs] = useState([{}]);

  // const upBox = () => setPkgs([...pkgs, {}]);
  // const downBox = () => setPkgs(pkgs.slice(0, -1));

  return (
    <div>
      <div className={classes.header}>Shipping Details</div>
      <div className={classes.subHeader}>
        WO#: {wos_doc._id} | {wos_doc.patient_nm} | Account: {wos_doc.acct}
      </div>
      <form className={classes.form}>
        <div>
          <TextField
            label="Address 1"
            defaultValue={wos_doc.addr_1}
            onChange={(e) => updateWoField(e, 'addr_1')}
            className={classes.input}
            placeholder="Enter street address"
          />
        </div>
        <div>
          <TextField
            label="Adddress 2"
            defaultValue={wos_doc.addr_2}
            onChange={(e) => updateWoField(e, 'addr_2')}
            className={classes.input}
            placeholder="Apartment, suite, unit, etc. (optional)"
          />
        </div>
        <div className={classes.grid}>
          <div className={classes.flexContainer}>
            <TextField
              label="City"
              style={{ marginRight: 8 }}
              defaultValue={wos_doc.city}
              onChange={(e) => updateWoField(e, 'city')}
              className={classes.flexItem}
              placeholder="Enter city"
            />
            <TextField
              label="State"
              style={{ marginRight: 8 }}
              defaultValue={wos_doc.state}
              onChange={(e) => updateWoField(e, 'state')}
              className={classes.flexItem}
              placeholder="Enter state"
            />
            <TextField
              label="Zip"
              defaultValue={wos_doc.zip}
              onChange={(e) => {
                e.zipcode = true;
                validatePosInt(e);
                updateWoField(e, 'zip');
              }}
              className={classes.flexItem}
              placeholder="ZIP code"
            />
          </div>
        </div>
        <div>
          <TextField
            multiline
            style={{ height: 100 }}
            label="Shipping Instructions"
            id="shippingInstructions"
            defaultValue={wos_doc.deliv_note}
            onChange={(e) => updateWoField(e, 'deliv_note')}
            className={classes.input}
            placeholder="Add any special instructions"
          />
        </div>
        <div style={{ marginTop: 8 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
            }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={downBox}
              style={{ marginRight: 8 }}
              disabled={pkgs.length <= 1}>
              -
            </Button>
            <span style={{ fontWeight: 500, marginRight: 8 }}>
              {pkgs.length} package{pkgs.length !== 1 ? 's' : ''}
            </span>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={upBox}>
              +
            </Button>
          </div>
          {/* <Button variant="contained" className={classes.saveButton}>
              Save Changes
            </Button> */}
        </div>
      </form>
    </div>
  );
};

export default withStyles(styles)(ShippingForm);
