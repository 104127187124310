import { Tab, Tabs } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setTitle_ac } from '../../actions/me.ac.js'
import { IndexLogs } from './IndexLogs.js'
import { TeamsGrid } from './TeamsGrid.js'
import { UserMgtLogs } from './UserMgtLogs.js'

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
})

class TeamsGridLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0
    }
  }

  onTabSelect = (e, _selectedTab) => {
    let toPath = this.props.match.path.replace(/:tabName/, _selectedTab)
    this.props.changePage(toPath)
    return
  }

  render() {
    const { classes, match } = this.props
    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot
    }
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer
    }

    return (
      <div className={classes.content}>
        <Tabs
          classes={tabsClass}
          value={match.params.tabName}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab value="front" label="Front End Teams" classes={tabClass} />
          <Tab value="back" label="Back End Teams" classes={tabClass} />
          <Tab value="all" label="All Teams" classes={tabClass} />
          <Tab value="mineOnly" label="My Teams" classes={tabClass} />
          <Tab value="idx_log" label="Indexing Log" classes={tabClass} />
          <Tab value="user_mgt_log" label="User Mgt Log" classes={tabClass} />
        </Tabs>
        <Switch>
          <Route exact path="/teamsgrid/user_mgt_log" component={UserMgtLogs} />
          <Route exact path="/teamsgrid/idx_log" component={IndexLogs} />
          <Route path="/teamsgrid/:tab_name" component={TeamsGrid} />
        </Switch>
      </div>
    )
  }
}

TeamsGridLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      changePage: path => {
        return push(path.replace(/:tabParam/, ''))
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(TeamsGridLanding))
)
