import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

const CustomSelect = props => {
  return (
    <>
      {props.label
        ? (
          <FormLabel component="label">
            <Typography variant="body1" component="div">
              {props.label}
            </Typography>
          </FormLabel>
        )
        : ''}
      <TextField
        select
        name={props.name}
        value={props.value}
        fullWidth={props.fullWidth === undefined
          ? true
          : props.fullWidth}
        margin="dense"
        variant="outlined"
        onChange={props.onChange}
        disabled={props.disabled}
      >
        {props.options.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}

export default CustomSelect;
