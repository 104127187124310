import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { addSLAMinutes } from '../../../util/sla_work_minutes';
import { nmToId, snakeCaseToTitleCase, toString } from '../../../util/string';
import { IDN_DATE } from '../../Indexing/constants';
import {
  CHECKBOX_STATE,
  PHASE_STATE,
  ROLES_ALLOWED_TO_CANCEL_WU,
  ROLES_ALLOWED_TO_PEND_WU,
  ROLES_ALLOWED_TO_UN_ARCHIVE_WU,
  WORKUNIT_LABEL_NAME_MAP,
} from '../constants';
import { IdxLabel } from './components/utils';

export const validateCheckboxes = (cbs = [], lockedDownBy) => {
  const state = {
    untouched: false, // no checkboxes have been touched
    allComplete: true, // all checkboxes are complete or not needed
    somePending: false, // some checkboxes are pending
    someMissing: false, // some checkboxes are missing or without reason if pending
  };

  let unsetCount = 0;
  for (let i = 0; i < cbs.length; i++) {
    const cb = cbs[i];
    if (!Boolean(cb.status)) {
      if (lockedDownBy) continue; // If any special checkbox is a blocker to pends
      state.someMissing = true;
      state.allComplete = false;
      unsetCount++;
    } else if (cb.status === CHECKBOX_STATE.REQUESTED) {
      if (!cb.needs_pending_reason || cb.pending_reason.length)
        state.somePending = true;
      else state.someMissing = true;
      state.allComplete = false;
    } else if (
      cb.status === CHECKBOX_STATE.COMPLETE &&
      cb.needs_complete_label &&
      (typeof cb.complete_label === 'string'
        ? !Boolean(cb.complete_label)
        : !cb.complete_label.length)
    ) {
      state.someMissing = true;
      state.allComplete = false;
    }
  }

  if (unsetCount === cbs.length) state.untouched = true;

  return state;
};

// The order of 'IF's matters here.
export const derivePhaseStatus = (cbState = {}) => {
  const { untouched, allComplete, somePending, someMissing } = cbState;

  if (untouched) return PHASE_STATE.UNWORKED;
  if (someMissing) return PHASE_STATE.PARTIALLY_WORKED;
  if (allComplete) return PHASE_STATE.WORKED_COMPLETE;
  if (somePending) return PHASE_STATE.WORKED_INCOMPLETE;
};

export const parseCheckboxes = (checkboxes) => {
  let pr = {},
    cl = {},
    rsm = {};
  checkboxes.forEach((cbObj) => {
    pr[cbObj.name] = (cbObj.pending_labels || []).map((pl) => pl.name);
    cl[cbObj.name] = (cbObj.complete_labels || []).map((cl) => cl.name);
    rsm[cbObj.name] = (cbObj.pending_labels || []).reduce((acc, curr) => {
      acc[curr.name] = curr.sla || {};
      return acc;
    }, {});
  });

  return [pr, cl, rsm];
};

export const parseDQCheckboxes = (critArray) => {
  let pr = {},
    cl = {},
    rsm = {};
  critArray.forEach((critObj) => {
    pr[critObj.wur] = critObj.pending_labels || [];
    cl[critObj.wur] = critObj.complete_labels || [];
    rsm[critObj.wur] = critObj.pending_sla || {};
  });

  return [pr, cl, rsm];
};

export const buildLabelInfos = (
  labels = {},
  filter = true,
  props = {},
  base = {}
) => {
  let uiLabels = Object.keys(labels).map((key) => ({
    label: WORKUNIT_LABEL_NAME_MAP[key] || snakeCaseToTitleCase(key),
    value: toString(labels[key], (val) => (
      <IdxLabel key={key + '-' + val} label={val} labelType={key} {...props} />
    )),
  }));

  if (filter) {
    uiLabels = uiLabels.filter((info) => Boolean(info.value));
  }

  return {
    grid: true,
    id: 'labels',
    count: uiLabels.length,
    list: uiLabels,
    ...base,
  };
};

export const getHDMSAcctUpdates = (originalState, newIDN, hdmsAccts = []) => {
  const oldIdn = originalState.nm;
  const changes = {
    nm: oldIdn,
    hdmsAccts,
  };

  if (!newIDN) return changes;

  const oldDocId = nmToId(oldIdn);
  const newDocId = nmToId(newIDN);

  const newChanges = {
    ...changes,
    nm: newIDN,
    displayAs: newIDN,
    docs: originalState.docs.map((doc) => ({
      ...doc,
      id: doc.id.replace(oldDocId, newDocId),
      displayAs: doc.displayAs.replace(oldIdn, newIDN),
    })),
  };

  return newChanges;
};

export const fetchRecentWos = async (
  params,
  { before, onSuccess, onError, after }
) => {
  if (before) before();
  let out;
  try {
    const res = await window.sch.post(`/api/hdms/recentwos`, params);
    out = {
      error: false,
      ...res,
    };

    if (onSuccess) onSuccess(out);
  } catch (error) {
    out = {
      error: error?.response?.data?.error || 'Error fetching recent orders',
      wids: [],
      wos: [],
    };
    if (onError) onError(error, out);
  }

  if (after) after();
  return out;
};

export const stageDeadlines = (
  phase_state = [],
  fromPhase,
  unset = false,
  saveMode = true
) => {
  let deadlineInheritanceMode = false;
  let baseDate = moment().toISOString();

  for (let i = 0; i < phase_state.length; i++) {
    const phase = phase_state[i];

    if (unset) {
      delete phase.st_deadline;
      continue;
    }

    if (phase.status === PHASE_STATE.WORKED_COMPLETE) {
      continue;
    }

    const needsRecomputation = Boolean(phase.should_sla_recompute);

    if (needsRecomputation || deadlineInheritanceMode) {
      phase.st_deadline = addSLAMinutes(
        baseDate,
        (deadlineInheritanceMode
          ? phase.sla.minutes
          : phase.sla.extended_minutes) || 0,
        phase.sla.eff_hours === 's'
      ).toISOString();

      if (deadlineInheritanceMode) {
        baseDate = phase.st_deadline;
      }
    }

    if (
      saveMode &&
      phase.phase === fromPhase &&
      phase.status === PHASE_STATE.WORKED_INCOMPLETE
    ) {
      baseDate = phase.st_deadline || phase.deadline;
      deadlineInheritanceMode = true;
    }
  }

  return phase_state;
};

export const getInvolvedTeams = (user) => {
  return _.union(
    user.teamq || [],
    user.teamq_n || [],
    user.manager_of || [],
    user.supervisor_of || []
  );
}

export const isOnTeam = (user, lookupTeam, config = {}) => {
  const {
    direct = true, // On teamq or teamq_n
    managerial = true, // On manager_of or supervisor_of
  } = config;

  return _.union(
    (direct && user.teamq) || [],
    (direct && user.teamq_n) || [],
    (managerial && user.manager_of) || [],
    (managerial && user.supervisor_of) || []
  ).includes(lookupTeam);
};

export const isAllowedToCancelWU = (user) => {
  return _.intersection(user.roles, ROLES_ALLOWED_TO_CANCEL_WU).length > 0;
};

export const isAllowedToUnArchiveWU = (user) => {
  return _.intersection(user.roles, ROLES_ALLOWED_TO_UN_ARCHIVE_WU).length > 0;
};

export const isAllowedToPendWithSLA = (user) => {
  return _.intersection(user.roles, ROLES_ALLOWED_TO_PEND_WU).length > 0;
};

export const docID = (doc) => doc && doc.id + '_' + doc.faxJobIdx;

export const patchNewDivider = (doc, newDivider) => {
  const newDisplayAs = doc.displayAs.replace(doc.nm, newDivider);

  return {
    ...doc,
    nm: newDivider,
    displayAs: newDisplayAs,
    id: newDisplayAs.toLowerCase().replace(/ /g, '-'),
  };
};

export const mergeDuplicateDividers = (docs) => {
  const result = [docs[0]];
  const indexTrack = {
    [docID(docs[0])]: 0,
  };
  let curResIdx = 0;
  for (let i = 1; i < docs.length; i++) {
    const doc = docs[i];
    const docId = docID(doc);

    if (indexTrack[docId]) {
      const already = result[indexTrack[docId]];

      already.pages = [...already.pages, ...doc.pages];
      already.rotation = [...already.rotation, ...doc.rotation];
    } else {
      result.push(doc);
      curResIdx++;
      indexTrack[docId] = curResIdx;
    }
  }

  return result;
};

const labelValidator = (labelName) => (state) =>
  Boolean(state.labels[labelName]?.length);

const FF_SCHEMA = {
  account: (state) => Boolean(state.hdmsAccts?.length),
  work_order_id: (state) => Boolean(state.hdmsWIds?.length),
  oc_id: (state) => Boolean(state.oc?._id),
  patient_dob: (state) => IDN_DATE.test(state.nm),
  patient_zip: (state) => Boolean(state.zip),
  patient_nm: (state) => Boolean(state.nm),
  order_type: labelValidator('order_type'),
  payer_type: labelValidator('payer_type'),
  referral: labelValidator('referral'),
  trm_source: labelValidator('trm_source'),
  prod_group: labelValidator('prod_group'),
};

export const phaseFFValidate = (ff_req = [], state) => {
  const invalidFields = [];

  for (let i = 0; i < ff_req.length; i++) {
    const validator = FF_SCHEMA[ff_req[i]];

    if (validator && !validator(state)) {
      invalidFields.push(ff_req[i]);
    }
  }

  return invalidFields;
};

export const fetchLookups = async (
  kinds,
  { before, after, onSuccess, onError } = {}
) => {
  if (before) {
    before();
  }
  try {
    const res = await window.sch.get(
      `/api/idx/admin/lookups/list?kinds=${kinds.join(',')}`
    );

    if (onSuccess) {
      onSuccess(res?.rr || []);
    }
  } catch (err) {
    console.log(err);

    if (onError) {
      onError(err);
    }
  }

  if (after) {
    after();
  }
};

export const buildPendingStateSummary = (phase_state) => {
  return (phase_state || []).reduce((acc, curr) => {
    if (curr.status !== PHASE_STATE.WORKED_INCOMPLETE) {
      return acc;
    }

    const hasDqState = curr.dq_state && curr.dq_state.length > 0;

    acc[curr.phase] = hasDqState
      ? _.flatten(
          curr.dq_state.map((dq) =>
            _.flatten(
              (dq.checkbox_state || [])
                .filter((cb) => cb.status === CHECKBOX_STATE.REQUESTED)
                .map((cb) => cb.pending_reason || [])
            )
          )
        )
      : _.flatten(
          (curr.checkbox_state || [])
            .filter((cb) => cb.status === CHECKBOX_STATE.REQUESTED)
            .map((cb) => cb.pending_reason || [])
        );

    return acc;
  }, {});
};

export const buildPendingStateMap = (phase_state) => {
  return (phase_state || []).reduce((acc, curr) => {
    if (curr.status !== PHASE_STATE.WORKED_INCOMPLETE) {
      return acc;
    }

    const hasDqState = curr.dq_state && curr.dq_state.length > 0;

    // To object map

    acc[curr.phase] = hasDqState
      ? curr.dq_state.reduce((acc, dq) => {
          acc[dq.nm] = (dq.checkbox_state || []).reduce((acc, cb) => {
            if (cb.status === CHECKBOX_STATE.REQUESTED) {
              acc[cb.nm] = cb.pending_reason || [];
            }

            return acc;
          }, {});

          return acc;
        }, {})
      : (curr.checkbox_state || []).reduce((acc, cb) => {
          if (cb.status === CHECKBOX_STATE.REQUESTED) {
            acc[cb.nm] = cb.pending_reason || [];
          }

          return acc;
        }, {});

    // Flag if has dq state
    acc[curr.phase].__is_dq__ = hasDqState;

    return acc;
  }, {});
};
