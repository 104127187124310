export function singleWo(
  state={
    isFetching: false,
    idn: null,
    wo: null,
    wo_id: null,
    error:false
  },
  action
){
  switch (action.type) {
    case 'REQUEST_SINGLE_WO':
      return Object.assign({}, state, {
        isFetching: true,
        wo_id: action.wo_id,
        wo: null,
        error: null
      })
    case 'FAILED_RECEIVED_SINGLE_WO':
      return Object.assign({}, state, {
        isFetching: false,
        wo: null,
        error: action.error
      })
    case 'RECEIVED_SINGLE_WO':
      return Object.assign({}, state, {
        isFetching: false,
        wo: action.wo,
        error: null
       })
    default:
      return state
  }
}
