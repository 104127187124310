import React, { Component } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import * as pdfDetail from '../../actions/Pdf_details.ac';
import * as pdfGen from '../../actions/pdfGen.ac';
import { en } from '../../constants/lang';
import CopyToClipboardButton from '../common/CopyToClipboard';
import PDFGenDrawerItem from './PDFGenDrawerItem';

const styles = () => ({
  wrapper: {
    position: 'absolute',
    top: 55,
    right: 0,
    width: 400,
    minHeight: 200,
    padding: 10,
    zIndex: 1201,
  },
  title: {
    float: 'right',
  },
  buttons: {
    textAlign: 'center',
  },
  bodyEmpty: {
    textAlign: 'center',
    marginTop: 30,
  },
  historyItem: {
    margin: '15px 10px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  pdfLink: {
    fontSize: '12px',
    textDecoration: 'none',
  },
  linkIcon: {
    fontSize: '16px',
    marginLeft: '2px',
    cursor: 'pointer',
  },
});

class PDFGenDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistory: false,
      isPdfGenerateButtonVisible: true,
    };

    this.convert = this.convert.bind(this);
    this.preview = this.preview.bind(this);
    this.clear = this.clear.bind(this);
    this.openPDF = this.openPDF.bind(this);
    this.generate = this.generate.bind(this);
  }

  setIsPdfGenerateButtonVisible(boolean) {
    this.setState({ isPdfGenerateButtonVisible: boolean });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.pdfGen.list !== this.props.pdfGen.list) {
      this.setIsPdfGenerateButtonVisible(true);
    }
  }

  convert() {
    if (this.props.pdfGen.list.length === 0) {
      console.log('Nothing to convert');
      return;
    }
    if (this.props.pdfGen.convert.inProgress) {
      console.log('Conversion in progress');
      return;
    }

    this.props.pdfGenConvert(this.props.pdfGen.list);
  }

  generate() {
    if (this.props.pdfGen.list.length === 0) {
      console.log('Nothing to generate');
      return;
    }

    this.props.addPdfFiles(this.props.pdfGen.list);
    this.setState({ isPdfGenerateButtonVisible: false });
  }

  preview() {
    if (this.props.pdfGen.list.length === 0) {
      console.log('No docs selected for preview.');
      return;
    }
    if (this.props.pdfGen.preview.inProgress) {
      console.log('Prreview in progress');
      return;
    }
    this.props.pdfGenPreview(this.props.pdfGen.list);
  }

  clear() {
    if (this.props.pdfGen.convert.inProgress) {
      console.log('Conversion in progress');
      return;
    }

    this.props.pdfGenClearDrawer();
  }

  openPDF() {
    this.props.pdfGenClearDrawer();
    window.open(this.props.pdfGen.convert.result.link, '_blank');
  }

  renderContent() {
    const { pdfDetail, classes, pdfGen } = this.props;

    const pdfDetailId = pdfDetail.data.id;

    const pdfLink = `https://${window.location.host}/pdf_packets/${pdfDetailId}`;

    if (this.state.showHistory) {
      return pdfGen.history.length > 0 ? (
        pdfGen.history.map((item, key) => {
          return (
            <Typography
              key={key}
              className={classes.historyItem}
              onClick={() => {
                window.open(item.link, '_blank');
              }}>
              {item.name}
            </Typography>
          );
        })
      ) : (
        <div className={classes.bodyEmpty}>
          <Typography>You haven not converted any yet!</Typography>
        </div>
      );
    } else {
      return pdfGen.list.length > 0 ? (
        <>
          {pdfGen.list.map((item, key) => {
            return <PDFGenDrawerItem key={key} index={key + 1} item={item} />;
          })}

          {pdfDetailId && (
            <>
              <Link
                className={classes.pdfLink}
                to={`/pdf_packets/${pdfDetailId}`}
                variant="caption"
                target="_blank">
                {pdfLink}
              </Link>

              <div>
                <Tooltip title={en.openPdfInNewTab} placement="top">
                  <Link
                    className={classes.pdfLink}
                    to={`/pdf_packets/${pdfDetailId}`}
                    variant="caption"
                    target="_blank">
                    <BiLinkExternal className={classes.linkIcon} />
                  </Link>
                </Tooltip>
                <CopyToClipboardButton text={pdfLink} />
              </div>
            </>
          )}
        </>
      ) : (
        <div className={classes.bodyEmpty}>
          <Typography>You haven not added any docs yet!</Typography>
        </div>
      );
    }
  }

  render() {
    const { classes, idnInView, pdfGenToggleDrawer, pdfGen } = this.props;

    const { showHistory, isPdfGenerateButtonVisible } = this.state;

    const showAppBar = this.props.location.pathname.search(/no_app_bar/) === -1;

    return pdfGen.showDrawer ? (
      <Card className={classes.wrapper} style={{ top: !showAppBar && 0 }}>
        <div>
          <IconButton onClick={pdfGenToggleDrawer}>
            <ChevronRightIcon />
          </IconButton>
          <span
            style={{ fontSize: 11, fontWeight: 'bold', textAlign: 'right' }}>
            Click on MR Requests Tab to Get Preview Button
          </span>
        </div>
        <div>
          <div className={classes.buttons}>
            <Button
              style={{ marginRight: 4 }}
              size="small"
              variant="outlined"
              onClick={() => {
                this.setState({ showHistory: !showHistory });
              }}>
              {showHistory ? 'Current list' : 'History'}
            </Button>

            {/* <Button
              variant="outlined"
              size="small"
              style={{ marginRight: 4 }}
              onClick={this.convert}
            >
              {pdfGen.convert.inProgress ? 'Converting' : 'Convert'}
            </Button> */}

            {(idnInView.selectedTab === 'doc_requests' ||
              idnInView.selectedTab === 'appeal_requests') && (
              <Button
                size="small"
                color="secondary"
                style={{ marginRight: 4 }}
                variant="contained"
                onClick={this.preview}>
                Preview
              </Button>
            )}
            <Button size="small" variant="outlined" onClick={this.clear}>
              Clear
            </Button>

            {isPdfGenerateButtonVisible && (
              <Button
                size="small"
                variant="contained"
                onClick={this.generate}
                style={{ marginLeft: 4, backgroundColor: '#ff9100' }}>
                {en.generatePdf}
              </Button>
            )}
          </div>
          {showHistory ? null : pdfGen.convert.inProgress ? (
            <div className={classes.bodyEmpty}>
              <Typography>Converting...</Typography>
            </div>
          ) : pdfGen.convert.error ? (
            <div className={classes.bodyEmpty}>
              <Typography>
                {pdfGen.convert.error.message
                  ? pdfGen.convert.error.message
                  : 'Oops! Conversion failed'}
              </Typography>
            </div>
          ) : pdfGen.convert.result ? (
            <div className={classes.bodyEmpty}>
              <Typography className={classes.clickable} onClick={this.openPDF}>
                Open PDF in a new tab
              </Typography>
            </div>
          ) : null}
          {this.renderContent()}
        </div>
      </Card>
    ) : null;
  }
}

const mapStateToProps = (stateFromStore) => ({
  pdfGen: stateFromStore.pdfGen,
  idnInView: stateFromStore.idnInView,
  pdfDetail: stateFromStore.pdfDetails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pdfGenPreview: pdfGen.preview,
      pdfGenConvert: pdfGen.convert,
      pdfGenClearDrawer: pdfGen.clearDrawer,
      pdfGenToggleDrawer: pdfGen.toggleDrawer,
      addPdfFiles: pdfDetail.addSelectedPdfFiles,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PDFGenDrawer))
);
