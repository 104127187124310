import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import imgTools from '../../util/imageProcessing'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  }
})

class CareplansLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      isImporting: false,
      err: null,
      importErr: null,
      pdfUri: null,
      payload: null,
      pkg: null,
      msg: null
    }
    this.genPdf = this.genPdf.bind(this)
    this.getPayload = this.getPayload.bind(this)
    this.upload = this.upload.bind(this)
  }

  componentDidMount() {
    this.checkSituation()
  }
  componentDidUpdate() {
    this.checkSituation()
  }
  checkSituation() {
    return
  }

  getPayload() {
    this.setState({ isFetching: true, msg: null })
    return window.sch
      .get('/api/careplan:get')
      .then(res => {
        this.setState({
          payload: res.payload,
          isFetching: false
        })

        return setTimeout(this.genPdf, 2000)
      })
      .catch(err => {
        this.setState({
          err: err,
          isFetching: false
        })
      })
  }

  genPdf() {
    return imgTools
      .generatePdfFromExistingDocs([this.state.payload])
      .then(pdfUri => {
        this.setState({ pdfUri, isFetching: false })
        return setTimeout(this.upload, 2000)
      })
      .catch(error => {
        console.error('error:', error)
        return
      })
  }

  upload() {
    this.setState({ isUploading: true })
    const lead = 'data:application/pdf;filename=generated.pdf;base64,'
    let params = {
      doc_id: this.state.payload._id,
      base64: this.state.pdfUri.substr(lead.length)
    }

    return window.sch
      .post('/api/careplan:update', params)
      .then(res => {
        this.setState({
          payload: null,
          pdfUri: null,
          msg: res.msg
        })

        setTimeout(this.getPayload, 1000)
      })
      .catch(err => {
        this.setState({
          err: err
        })
      })
  }

  render() {
    const { classes } = this.props

    const { pdfUri, payload, msg } = this.state

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          {/* <div className={classes.root}> */}
          <main className={classes.content}>
            {/* <div style={{ height: 38 }} /> */}
            <div className={classes.scrollArea} style={{ padding: 4 }}>
              <Grid container direction="row" spacing={8}>
                <Grid item xs={2} sm={2}>
                  <Button onClick={this.getPayload}>Load Next</Button>
                  <br />
                  <Button onClick={this.genPdf}>Load PDF</Button>
                  <br />
                  {pdfUri && <Button onClick={this.upload}>Upload PDF</Button>}
                  {msg && <b>msg</b>}
                </Grid>

                <Grid item xs={10} sm={10}>
                  {payload && (
                    <div>
                      A{payload.account} - {payload._id}
                    </div>
                  )}
                  <br />
                  {pdfUri && <b>PDF ready for upload: {pdfUri.length}Bytes</b>
                  /*
                pdfUri &&
                <embed type="application/pdf"
                  src={pdfUri+'#zoom=50'}
                  marginWidth="0" style={{width:'99%',height:1200}}
                />
                */
                  }
                </Grid>
              </Grid>
            </div>
          </main>
          {/* </div> */}
        </MuiThemeProvider>
      </>
    )
  }
}

CareplansLanding.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CareplansLanding))
)
