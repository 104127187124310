import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  attemptTransmission_ac,
  fetchDocRequests_ac,
  markTransmissionMan_ac,
  updateTrmFaxNumber_ac
} from '../../actions/doc_requests.ac.js'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js'
import DocRequestCard from '../DocRequest/DocRequestCard'
import FetchingStatementWrap from '../FetchingStatementWrap'
import FilterListing from '../FilterListing'
import Pagination from '../Pagination'

//import { fetchDocRequests_ac,fetchDocRequestsSummary_ac } from '../../actions/doc_requests.ac.js'

const styles = theme => ({
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

class DocRequestsLanding extends React.Component {
  constructor(props) {
    super(props)
    const { me } = props
    this.state = {
      page: 0,
      filterSequence: [
        'trm_state',
        'u_id',
        'trm_u_id',
        'mode_kind',
        'recipient_kind',
        'docs'
      ],
      filters: {
        trm_state: {
          lbl: 'Transmission Status',
          kind: 'checkbox',
          options: [
            { code: 'not_attempted', checked: true },
            { code: 'trm_scheduled', checked: false },
            { code: 'sent', checked: false },
            { code: 'sent_manually', checked: false },
            { code: 'sent_ok', checked: false },
            { code: 'sent_inprogress', checked: true },
            { code: 'sent_failed', checked: true },
            { code: 'emailed', checked: false },
            { code: 'void', checked: false }
          ]
        },

        u_id: {
          lbl: 'Created By',
          kind: 'users',
          values: [{ nm: me.user.nm, email: me.user.mail }]
        },
        trm_u_id: {
          lbl: 'Transmitted By',
          kind: 'users',
          values: [{ nm: me.user.nm, email: me.user.mail }]
        },

        /*
        u_id: {
          lbl: 'Created By',
          options: [{ code: 'mine_only', checked: true, lbl: 'Only Me' }]
        },
        trm_u_id: {
          lbl: 'Transmitted By',
          options: [{ code: 'mine_only', checked: false, lbl: 'Only Me' }]
        },
        */
        mode_kind: {
          lbl: 'Transmission Type',
          kind: 'checkbox',
          options: [
            { code: 'fax', lbl: 'Fax' },
            { code: 'email', lbl: 'Email' },
            { code: 'voice', lbl: 'Voice Call/Msg' },
            { code: 'runner', lbl: 'Printout-Runner' },
            { code: 'snailmail', lbl: 'Printout-SnailMail' },
            { code: 'portal', lbl: 'Upload thru Portal' }
          ].map(a => {
            a.checked = true
            return a
          })
        },
        recipient_kind: {
          lbl: 'Recipient Type',
          kind: 'checkbox',
          options: [
            { code: 'doctor', lbl: 'Doctor' },
            { code: 'payer', lbl: 'Insurer/Payer' },
            { code: 'auth', lbl: 'Patient' },
            { code: 'other', lbl: 'Other' }
          ].map(a => {
            a.checked = true
            return a
          })
        },
        docs: {
          lbl: 'Documents Requested',
          kind: 'checkbox',
          options: [
            { code: 'cmn-oxy', lbl: 'CMN-Oxygen' },
            { code: 'cmn-other', lbl: 'CMN-Other' },
            { code: 'rx', lbl: 'RX' },
            { code: 're-auth', lbl: 'Re-Authorization' },
            { code: 'auth', lbl: 'Authorization' },
            { code: 'cn_current', lbl: 'Current Chart Notes (within 1 Year)' },
            { code: 'cn_prior', lbl: 'Chart Notes Prior to Sleep Study' },
            { code: 'ss', lbl: 'Sleep Study' },
            { code: 'other', lbl: 'Other' }
          ].map(a => {
            a.checked = true
            return a
          })
        }
      }
    }
    this.onFilterClearField = this.onFilterClearField.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
    this.onPageChange = this.onPageChange.bind(this)
    this.onFilterToggle = this.onFilterToggle.bind(this)
    this.fetch = this.fetch.bind(this)
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this)
    this.onUserFilterUpdated = this.onUserFilterUpdated.bind(this)
  }

  componentDidMount() {
    const { docRequestsInView, setTitle_ac } = this.props

    if (this.props.me.user === null) {
      return
    }

    setTitle_ac('MR Request Listing')
    if (
      docRequestsInView.isFetching === false &&
      docRequestsInView.records === null &&
      !docRequestsInView.error
    ) {
      return this.fetch()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { docRequestsInView } = this.props
    if (this.props.me.user === null) {
      return
    }
    if (
      docRequestsInView.isFetching === false &&
      docRequestsInView.records === null &&
      !docRequestsInView.error
    ) {
      return this.fetch()
    }

    if (prevState.page !== this.state.page) {
      return this.fetch()
    }
  }

  fetch() {
    //construct payload based on filters
    let selectedFilters = {}
    this.state.filterSequence.forEach(fName => {
      const f = this.state.filters[fName]
      switch (f.kind) {
        case 'checkbox':
          selectedFilters[fName] = f.options
            .filter(o => o.checked)
            .map(o => o.code)
          break
        case 'users':
          if (f.values.length > 0) {
            selectedFilters[fName] = f.values.map(v => v.email)
          }
          break
        default:
          break
      }
    })

    this.props.fetchDocRequests_ac({
      by: 'filters',
      val: selectedFilters,
      page: this.state.page
    })
    return
  }

  fetchWithPageReset() {
    //construct payload based on filters
    let selectedFilters = {}
    this.state.filterSequence.forEach(fName => {
      const f = this.state.filters[fName]
      switch (f.kind) {
        case 'checkbox':
          selectedFilters[fName] = f.options
            .filter(o => o.checked)
            .map(o => o.code)
          break
        case 'users':
          if (f.values.length > 0) {
            selectedFilters[fName] = f.values.map(v => v.email)
          }
          break
        default:
          break
      }
    })

    this.props.fetchDocRequests_ac({
      by: 'filters',
      val: selectedFilters,
      page: 0
    })
    return
  }

  onUserFilterUpdated(filterName, values) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          values
        }
      }
    })
  }

  onFilterChange = filterName => evt => {
    let sf = _.cloneDeep(this.state.filters[filterName])
    sf.options = sf.options.map(s => {
      if (evt.target.value === s.code) {
        s.checked = evt.target.checked
      }
      return s
    })
    this.setState({ filters: { ...this.state.filters, [filterName]: sf } })
  }

  onFilterClearField = filterName => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          value: ''
        }
      }
    })
  }

  //only applies to checkbox filters
  onFilterToggle(filterName, on) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          options: this.state.filters[filterName].options.map(o => {
            o.checked = on
            return o
          })
        }
      }
    })
  }

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) })
  }

  render() {
    const { classes, me, docRequestsInView } = this.props
    const title = 'MR Requests'
    if (docRequestsInView.records === null) {
      return <FetchingStatementWrap title={title} />
    }

    if (docRequestsInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      )
    }

    /*
    let assignmentFiltersAllowed = ['Assigned to Me', 'Created by Me']
    if (me.user.roles.indexOf('vct_super') > -1) {
      assignmentFiltersAllowed = ['Assigned to Me', 'Created by Me', 'All']
    }
    */
    let summary = false
    //let pageSelector = ''
    const pg = docRequestsInView.pagination
    let pageStatement = ''
    if (pg) {
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Records matching filters:',
        'Page ' + (pg.currentPageNum + 1) + ' of ' + pg.totalPagesAvailable
      ].join(' - ')

      /*
      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                onChange={this.onPageChange}
                displayEmpty
                value={this.state.page}
                name="age"
                className={classes.selectEmpty}>
                {_.range(0, pg.totalPagesAvailable).map(pgNum => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        )
      }
      */
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={6} className={classes.fullHeight}>
          <FilterListing
            me={me}
            onUserFilterUpdated={this.onUserFilterUpdated}
            onFilterToggle={this.onFilterToggle}
            filters={this.state.filters}
            onFilterClearField={this.onFilterClearField}
            filterSequence={this.state.filterSequence}
            onFilterChange={this.onFilterChange}
            applyFilters={this.fetchWithPageReset}
          />
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Grid container spacing={8}>
                <Grid item xs={8} sm={8}>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    {pageStatement}
                  </Typography>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    Status:{' '}
                    {this.state.filters.trm_state.options
                      .filter(s => s.checked)
                      .map(s => s.code)
                      .join(',')}
                  </Typography>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    Created By:{' '}
                    {this.state.filters.u_id.values.map(v => v.nm).join(',')}-
                    Transmitted By:{' '}
                    {this.state.filters.trm_u_id.values
                      .map(v => v.nm)
                      .join(',')}
                  </Typography>
                </Grid>
                <Grid item xs={8} sm={8}>
                  <Pagination
                    onPageChange={this.onPageChange}
                    page={this.state.page}
                    pagination={docRequestsInView.pagination}
                  />
                </Grid>
              </Grid>
            </div>

            <div className={classes.scrollArea}>
              <div className={classes.root}>
                {docRequestsInView.records.map((oDoc, posInSet) => {
                  return (
                    <DocRequestCard
                      key={oDoc._id + '-' + posInSet}
                      oDoc={oDoc}
                      posInSet={posInSet}
                      onTransmitAttempt={this.props.attemptTransmission_ac}
                      onUpdateFaxNumber={this.props.updateTrmFaxNumber_ac}
                      onMarkAs={this.props.markTransmissionMan_ac}
                      transmitInProgress={
                        this.props.docRequestsInView.transmitInProgress
                      }
                    />
                  )
                })}
              </div>
            </div>
          </main>
        </Grid>
        {/* END TODO CARDS LIST */}

        <Grid item xs={12} sm={6} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Typography
                className={classes.pageStatement}
                color="textSecondary">
                Summary
              </Typography>
            </div>

            <div className={classes.scrollArea}>
              <h5>
                To create a document request, search for patient and click on MR
                Requests tab.
              </h5>
              <div className={classes.root}>
                {summary && (
                  <div>
                    <label className={classes.label}>Open Task Summary:</label>
                    <hr />

                    <label className={classes.label}>By Status</label>
                    {summary.by_state.map((r, kIdx) => (
                      <div key={kIdx} className={classes.summaryEntryWrap}>
                        <span className={classes.summaryEntry}>
                          {r.k} ({r.n})
                        </span>
                        <div className={classes.summaryCountWrap} />
                      </div>
                    ))}

                    <div className={classes.spacer} />

                    <label className={classes.label}>By Assignee</label>
                    {summary.by_owner.map((r, kIdx) => (
                      <div key={kIdx} className={classes.summaryEntryWrap}>
                        <span className={classes.summaryEntry}>
                          {r.k} ({r.n})
                        </span>
                        <div className={classes.summaryCountWrap} />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
    )
  }
}

DocRequestsLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  docRequestsInView: stateFromStore.docRequestsInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      fetchDocRequests_ac,
      markTransmissionMan_ac,
      attemptTransmission_ac,
      updateTrmFaxNumber_ac,
      //fetchDocRequestsSummary_ac,
      lookups_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DocRequestsLanding))
)
