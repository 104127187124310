import { Collapse, Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { searchFBPatient_ac } from '../../actions/fb_search.ac'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  sectionLbl: {
    fontSize: 11,
    color: '#9c27b0',
    fontWeight: 700,
    paddingTop: 12,
    marginBottom: 12
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  fullHeight: {
    height: '100%'
  },
  searchButton: {
    margin: '8px 0px 4px 0px'
  }
})

const searchTips = `
              Search using name format  LAST, FIRST
              Apply trailing wildcard with * -
              Example: Flor*, Mar*  will match against Flores Maria AND Flora Mark

              Add any combination of filters using semicolon (;) as separator:
              Add Account#$ filter using format a12345
              Add DOB filter using format mm-dd-yyyy
              Add WorkOrder# filter using format w12345
              Add Service date filter using format smm-dd-yyyy  - Example:  s03-19-1953

              Examples:
              name + account:
              flores, maria; A98703

              name + dob:
              flores, maria; 06/10/1963

              name + servie date:
              flores, maria; A98703; s02-17-2009

              account + service date:
              A98703; s02-17-2009

              name with trailing wildcard:
              flores, mar*

              name with trailing wildcard + service date:
              flores, mar*; s11-01-2007
            `

class FBPatientSearch extends Component {
  constructor() {
    super()
    this.state = {
      searchStr: '',
      toggleSearchTips: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page !== this.props.page) {
      this.search();
    }
  }

  handleSearchTipsToggle = () => {
    this.setState({
      toggleSearchTips: !this.state.toggleSearchTips
    })
  }

  handleChange = e => {
    const { value } = e.target
    this.setState({
      searchStr: value
    })
  }

  clear = () => {
    this.setState({
      searchStr: ''
    })
  }

  search = (ver = 6) => {
    this.props.searchFBPatient_ac({
      ver,
      searchStr: this.state.searchStr,
      page: this.props.page || 0
    })
  }

  render() {
    const { classes, fbSearchInView } = this.props

    return (
      <Grid container spacing={16} item>
        <Grid container item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            onChange={this.handleChange}
            placeholder="Search for FB Patient by Name, DOB, etc. (see search tips)"
            name="searchStr"
            value={this.state.searchStr || ''}
            style={{ flexGrow: 1 }}
          />
        </Grid>
        <Grid container item xs={12} md={6} alignItems="stretch">
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.search(6)}
            disabled={!this.state.searchStr || fbSearchInView.isFetching}
            className={classes.searchButton}>
            Search FB 6
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.search(4)}
            disabled={
              true || !this.state.searchStr || fbSearchInView.isFetching
            }
            className={classes.searchButton}
            style={{ marginLeft: 8 }}>
            Search FB 4
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={this.clear}
            className={classes.searchButton}
            disabled={!this.state.searchStr}
            style={{ marginLeft: '5px' }}>
            Clear
          </Button>
        </Grid>
        <Divider component="hr" />
        <Grid item xs={12}>
          <Typography
            inline
            color="textSecondary"
            style={{ cursor: 'pointer' }}
            onClick={this.handleSearchTipsToggle}>
            View Search Tips [+/-]
          </Typography>
          <Collapse in={this.state.toggleSearchTips}>
            <pre
              style={{
                padding: 6,
                backgroundColor: '#CFD8DC',
                fontSize: 10
              }}>
              {searchTips}
            </pre>
          </Collapse>
        </Grid>
      </Grid>
    )
  }
}

FBPatientSearch.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchFBPatient_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FBPatientSearch))
)
