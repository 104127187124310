import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Fallback from '../Shared/Loader'
import OxygenCountsTable from './OxygenCountsTable'
import { fetchOxygenCounts_ac } from '../../actions/oxygen_counts.ac'
import { bindActionCreators } from 'redux'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit * 0.5,
    overflowY: 'auto'
  },
  stickyHeader: {
    '& > tr > th': {
      top: 0,
      left: 0,
      position: 'sticky',
      backgroundColor: 'white'
    }
  },
  scrollable: {
    height: '100%',
    overflowY: 'auto',
    padding: 4
  },
  tableCard: {
    marginBottom: 16
  },
  fallbackText: {
    fontSize: theme.typography.body2.fontSize,
    '&.error': {
      color: theme.palette.error.main
    }
  }
})

const GROUP_BY_MAP = {
  dc: 'Distribution Center',
  driver: 'Driver'
}

const OxygenCountsTableList = props => {
  const { oxygenCounts, classes, fetchOxygenCounts_ac } = props
  const { isFetching, error, counts, filterData } = oxygenCounts
  const hasRecords = counts && counts.records && counts.records.length > 0

  /* eslint-disable */
  useEffect(() => {
    fetchOxygenCounts_ac(filterData)
  }, [])
  /* eslint-enable */

  const getScheduledDateLabel = () => {
    if (moment(filterData.start).isSame(moment(filterData.end)))
      return moment(filterData.start).format('ddd MM-DD-YYYY')

    return `${moment(filterData.start).format('ddd MM-DD-YYYY')
      } to ${moment(filterData.end).format('ddd MM-DD-YYYY')}`
  }

  return (
    <div className={classes.content}>
      <Typography variant="subtitle1" color="secondary" gutterBottom>
        <strong>
          {`Oxygen Delivery Counts By ${GROUP_BY_MAP[filterData.groupBy || '']
            }: ${getScheduledDateLabel()}`}
        </strong>
      </Typography>
      {isFetching ? (
          <Fallback
            message="Loading..."
            classes={{ message: classes.fallbackText }}
          />
        ) : error ? (
          <Fallback
            message="Error Loading Oxygen Counts!"
            classes={{ message: `${classes.fallbackText} error` }}
          />
        ) : !hasRecords ? (
          <Fallback
            message="No Records Found"
            classes={{ message: classes.fallbackText }}
          />
        ) : (
              <div className={classes.scrollable}>
                {counts.records.map((record, idx) => (
                  <Card key={`${idx}_${record._id}`} className={classes.tableCard}>
                    <CardHeader
                      title={record.group_name}
                      titleTypographyProps={{
                        variant: 'subtitle1',
                        color: 'primary',
                        style: { fontWeight: 'bold' }
                      }}
                      style={{ backgroundColor: '#f3e5f5' }}
                    />
                    <CardContent>
                      <OxygenCountsTable groupedCount={record} />
                    </CardContent>
                  </Card>
                ))}
              </div>
      )}
    </div>
  )
}

const mapStateToProps = stateFromStore => ({
  oxygenCounts: stateFromStore.oxygenCountsState
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { fetchOxygenCounts_ac },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OxygenCountsTableList))
