import { actionTypes } from '../actions/global_wu_actions.ac';
import _ from 'lodash';
export const initialState = {
  activeAction: null,
  props: null,
  callback: null,

  isCopyingDocsToWU: false,
  copyDocsToWUError: null,

  stagedDocs: [],
  showStagedDocsView: false,
};

export function globalWUAction(state = initialState, action) {
  switch (action.type) {
    // Actions
    case actionTypes.INIT_ACTION:
      return {
        ...state,
        activeAction: action.actionName,
        props: action.props,
        callback: action.callback,
      };

    case actionTypes.FLUSH_ACTION:
      return {
        ...state,
        activeAction: null,
        props: null,
      };

    // Copy docs
    case actionTypes.COPY_DOCS_TO_WU_PENDING:
      return {
        ...state,
        isCopyingDocsToWU: true,
        copyDocsToWUError: null,
      };

    case actionTypes.COPY_DOCS_TO_WU_SUCCESS:
      return {
        ...state,
        isCopyingDocsToWU: false,
        copyDocsToWUError: null,
      };

    case actionTypes.COPY_DOCS_TO_WU_ERROR:
      return {
        ...state,
        isCopyingDocsToWU: false,
        copyDocsToWUError: action.error,
      };

    // Staged docs
    case actionTypes.OPEN_STAGED_DOCS_VIEW:
      return {
        ...state,
        showStagedDocsView: true,
      };

    case actionTypes.CLOSE_STAGED_DOCS_VIEW:
      return {
        ...state,
        showStagedDocsView: false,
      };

    case actionTypes.STAGE_DOC_FOR_COPY:
      const existingDoc = state.stagedDocs.find(
        (dc) =>
          dc.doc.id === action.data.doc.id &&
          dc.faxJob._id === action.data.faxJob._id
      );

      if (existingDoc) {
        console.log('Doc already staged for copy!');
        return state;
      }

      return {
        ...state,
        stagedDocs: [...state.stagedDocs, action.data],
        showStagedDocsView: _.isBoolean(action.openStage)
          ? action.openStage
          : state.showStagedDocsView,
      };

    case actionTypes.UNSTAGE_DOC_FOR_COPY:
      return {
        ...state,
        stagedDocs: state.stagedDocs.filter(
          (dc) =>
            !(
              dc.doc.id === action.data.doc.id &&
              dc.faxJob._id === action.data.faxJob._id
            )
        ),
      };

    case actionTypes.FLUSH_DOCS_FOR_COPY:
      return {
        ...state,
        stagedDocs: [],
        showStagedDocsView: false,
      };

    // ... other actions

    default:
      return state;
  }
}
