/**
 * FORMAT:
 * {
 *  [endpoint] : {
 *   [method] : NODE
 *  }
 * }
 * }
 *
 * NODE can either be a response obj or a function that takes params/queries.
 */

const { DELIVERY_UPDATES, ORDER_DETAILS } = require('./data/parachute');

let accepted_at = null;

module.exports = {
  fulfillment_statuses: {
    get: {
      data: DELIVERY_UPDATES,
    },
  },
  order_details: {
    get: {
      order: {
        accepted_at,
        ...ORDER_DETAILS
      },
    },
  },
  comments: {
    post: (body) => {
      console.log('POST parachute.comments.body', body);
      return {
        success: true,
      };
    },
  },
  acceptOrder: {
    post: (body) => {
      console.log('POST parachute.acceptOrder.body', body);
      accepted_at = new Date().toISOString();
      return {
        success: true,
      };
    },
  },
  delivery_update: {
    post: (body) => {
      console.log('POST parachute.delivery_update.body', body);
      return {
        success: true,
      };
    },
  },
};
