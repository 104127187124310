import { grey, orange, purple, red } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { push } from 'connected-react-router'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchOpenCounts_ac } from '../../actions/df_workunits_reports.ac'
import { lookups_ac } from '../../actions/me.ac.js'
import FilterListing from '../FilterListing'
import Loader from '../Shared/Loader'

const styles = theme => ({
  tableScrollable: {
    minHeight: '220px',
    height: 'calc(100% - 96px)',
    overflowY: 'auto'
  },
  viewSwitchContainer: {
    padding: '0px 8px',
    maxHeight: '48px',
    textAlign: 'right'
  },
  tableHeader: {
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      boxShadow: '3px 1px 1px 1px black',
      backgroundColor: 'white'
    }
  },
  redTableCell: {
    backgroundColor: red[50]
  },
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  clickablePaper: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#00000020'
    }
  }
})

const DEFAULT_FILTERS = {
  groupBy: {
    kind: 'radio',
    lbl: 'Grouping',
    options: [
      { lbl: 'Group By Assignee', code: 'user' },
      { lbl: 'Group By Team', code: 'team' }
    ]
  },
  filterBy: {
    kind: 'radio',
    lbl: 'Limit to',
    options: [
      { lbl: 'My Assignments', code: 'mine' },
      { lbl: 'My Teams', code: 'myteams' },
      { lbl: 'Teams I Supervise', code: 'myteams_super' },
      { lbl: 'All Teams', code: 'all' },
      { lbl: 'All Front End Teams Only', code: 'front' },
      { lbl: 'All Back End Teams Only', code: 'back' },
      { lbl: 'Nonwork Team only', code: 'nonwork' }
    ]
  },
  workunitType: {
    kind: 'radio',
    lbl: 'Limit Workunit Type to',
    options: [
      { lbl: 'Products Only', code: 'PRODUCT' },
      { lbl: 'New Docs Only', code: 'NEWDOCS' },
      { lbl: 'WO-Required Only', code: 'WO-REQUIRED' },
      { lbl: 'WO-Conf Only', code: 'WO-CONF' }
    ]
  }
}

const CustomPaper = props => {
  return (
    <Grid item>
      <Paper
        style={{
          display: 'inline-block',
          backgroundColor: props.backgroundColor,
          color: 'white'
        }}
        onClick={props.onClick || (() => {})}>
        <div
          style={{
            padding: '4px 8px',
            borderRadius: 'inherit'
          }}
          className={props.clickablePaper || ''}>
          <Typography color={props.typographyColor} component="span">
            <strong>{props.label}</strong>
          </Typography>
        </div>
      </Paper>
    </Grid>
  )
}

class OpenCounts extends React.Component {
  constructor(props) {
    super(props)
    const { me } = props
    let selectedFilters = _.get(me, 'user.filters.open_counts', {
      groupBy: 'user',
      filterBy: 'mine',
      workunitType: 'PRODUCT'
    })

    this.state = {
      filterSequence: ['groupBy', 'filterBy', 'workunitType'],
      filters: { ...DEFAULT_FILTERS },
      selectedFilters,
      isDetailedView: false
    }
  }

  componentDidMount() {
    const { lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return lookups_ac()
    }

    return this.fetch()
  }

  componentDidUpdate() {
    const { dfWorkunitsReports, lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return lookups_ac()
    }

    if (
      !dfWorkunitsReports.isFetching &&
      !dfWorkunitsReports.error &&
      !dfWorkunitsReports.openCounts
    ) {
      return this.fetch()
    }
  }

  fetch = () => {
    this.props.fetchOpenCounts_ac(this.state.selectedFilters)
  }

  onRadioFilterChange = event => {
    const { name, value } = event.target

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [name]: value
      }
    })
  }

  applyFilters = () => {
    return this.fetch()
  }

  changeReportView = () => {
    this.setState({
      isDetailedView: !this.state.isDetailedView
    })
  }

  handlePaperClick = (groupByKlass, params) => {
    const { paperType, rowId, paperLabel } = params
    const filterCombo = groupByKlass + '-' + paperType
    let path = '/dfa/workunits/'

    switch (filterCombo) {
      case 'user-user':
        path += `z:@${rowId}$`
        break
      case 'team-team':
        path += `t:${rowId}$`
        break
      case 'user-team':
        path += `z:@${rowId}$;t:${paperLabel}$`
        break
      case 'team-user':
        path += `z:@${paperLabel}$;t:${rowId}$`
        break
      case 'user-product':
        path += `z:@${rowId}$;-${paperLabel}$`
        break
      case 'team-product':
        path += `t:${rowId}$;-${paperLabel}$`
        break
      case 'sw-sw':
        path += `z:sw${paperLabel}:${rowId}`
        break
      default:
        path += 'home'
    }
    path += '/nd/1'

    return window.open(path, '_simplewids')
    //return this.props.redirectToDfa(path)
  }

  render() {
    const { classes, dfWorkunitsReports } = this.props
    const { openCounts, isFetching, error } = dfWorkunitsReports

    if (isFetching) {
      return <Loader classes={{ message: classes.message }} />
    }

    if (error) {
      return (
        <Typography color="error" className={classes.message}>
          Error fetchnig report!
        </Typography>
      )
    }

    return (
      <>
        <FilterListing
          me={this.props.me}
          applyFilters={this.applyFilters}
          filters={this.state.filters}
          filterSequence={this.state.filterSequence}
          onRadioFilterChange={this.onRadioFilterChange}
          selected={this.state.selectedFilters}
        />
        {openCounts && (
          <>
            <Grid container>
              <Grid item xs={9}>
                <div style={{ padding: 8, fontSize: 12 }}>
                  Grouping:{' '}
                  {
                    DEFAULT_FILTERS.groupBy.options.filter(
                      f => f.code === this.state.selectedFilters.groupBy
                    )[0].lbl
                  }
                  <span style={{ marginLeft: 12, color: 'blue' }}>
                    Limit To:{' '}
                    {
                      DEFAULT_FILTERS.filterBy.options.filter(
                        f => f.code === this.state.selectedFilters.filterBy
                      )[0].lbl
                    }
                  </span>
                  <span style={{ marginLeft: 12 }}>
                    Limit Workunit Type To:{' '}
                    {
                      DEFAULT_FILTERS.workunitType.options.filter(
                        f => f.code === this.state.selectedFilters.workunitType
                      )[0].lbl
                    }
                  </span>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.viewSwitchContainer}>
                  <strong>Simple</strong>
                  <Switch
                    name="reportViewToggle"
                    checked={this.state.isDetailedView}
                    onChange={this.changeReportView}
                  />
                  <strong>Detailed</strong>
                </div>
              </Grid>
            </Grid>
            <div className={classes.tableScrollable}>
              <Table padding="dense">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell width="18%">
                      <Grid container spacing={8}>
                        <CustomPaper
                          backgroundColor={grey['600']}
                          typographyColor="inherit"
                          label={`${openCounts.hdr.groupByField} (${
                            openCounts.hdr.total
                          })`}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell
                      width={this.state.isDetailedView ? '18%' : '28%'}>
                      <Grid container spacing={8}>
                        <CustomPaper
                          backgroundColor={orange.main}
                          typographyColor="textPrimary"
                          label={`${openCounts.hdr.product[0][0]} (${
                            openCounts.hdr.product[0][1]
                          })`}
                        />
                      </Grid>
                    </TableCell>
                    <TableCell
                      width={this.state.isDetailedView ? '16%' : '24%'}>
                      <Grid container spacing={8}>
                        <CustomPaper
                          backgroundColor={purple.main}
                          typographyColor="inherit"
                          label={`${openCounts.hdr.nonGroupByField[0][0]} (${
                            openCounts.hdr.nonGroupByField[0][1]
                          })`}
                        />
                      </Grid>
                    </TableCell>
                    {this.state.isDetailedView && (
                      <>
                        <TableCell width="12%">
                          <Grid container spacing={8}>
                            <CustomPaper
                              backgroundColor="white"
                              typographyColor="textPrimary"
                              label={openCounts.hdr.counts[0]}
                            />
                            <CustomPaper
                              backgroundColor="white"
                              typographyColor="textPrimary"
                              label={`${openCounts.hdr.counts[1]} PEND`}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell width="12%">
                          <Grid container spacing={8}>
                            <CustomPaper
                              backgroundColor={red['500']}
                              typographyColor="inherit"
                              label={openCounts.hdr.counts[2]}
                            />
                            <CustomPaper
                              backgroundColor={red['500']}
                              typographyColor="inherit"
                              label={`${openCounts.hdr.counts[3]} PEND`}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: red[50] }}
                          width="12%">
                          <Grid container spacing={8}>
                            <CustomPaper
                              backgroundColor={red[50]}
                              typographyColor="textPrimary"
                              label={openCounts.hdr.counts[4]}
                            />
                            <CustomPaper
                              backgroundColor={red[50]}
                              typographyColor="textPrimary"
                              label={`${openCounts.hdr.counts[5]} PEND`}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: red[50] }}
                          width="12%">
                          <Grid container spacing={8}>
                            <CustomPaper
                              backgroundColor={red['500']}
                              typographyColor="inherit"
                              label={openCounts.hdr.counts[6]}
                            />
                            <CustomPaper
                              backgroundColor={red['500']}
                              typographyColor="inherit"
                              label={`${openCounts.hdr.counts[7]} PEND`}
                            />
                          </Grid>
                        </TableCell>
                      </>
                    )}
                    {!this.state.isDetailedView && (
                      <>
                        <TableCell width="15%">
                          <Grid container spacing={8}>
                            <CustomPaper
                              backgroundColor="white"
                              typographyColor="textPrimary"
                              label={openCounts.hdr.simple_counts[0]}
                            />
                            <CustomPaper
                              backgroundColor="white"
                              typographyColor="textPrimary"
                              label={`${openCounts.hdr.simple_counts[1]} PEND`}
                            />
                          </Grid>
                        </TableCell>
                        <TableCell width="15%">
                          <Grid container spacing={8}>
                            <CustomPaper
                              backgroundColor={red['500']}
                              typographyColor="inherit"
                              label={openCounts.hdr.simple_counts[2]}
                            />
                          </Grid>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {openCounts.rows.map((row, idx) => (
                    <TableRow key={idx}>
                      <TableCell width="18%">
                        <Grid container spacing={8}>
                          <CustomPaper
                            backgroundColor={grey['600']}
                            typographyColor="inherit"
                            label={`${row._id} (${row.total})`}
                            onClick={() =>
                              this.handlePaperClick(row.groupByKlass, {
                                paperType: row.groupByKlass,
                                rowId: row._id,
                                paperLabel: row._id
                              })
                            }
                            clickablePaper={classes.clickablePaper}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell
                        width={this.state.isDetailedView ? '18%' : '28%'}>
                        <Grid container spacing={8}>
                          {row.product.map((item, idx) => (
                            <CustomPaper
                              key={idx}
                              backgroundColor={orange.main}
                              typographyColor="textPrimary"
                              label={`${item[0]} (${item[1]})`}
                              onClick={() =>
                                this.handlePaperClick(row.groupByKlass, {
                                  paperType: 'product',
                                  rowId: row._id,
                                  paperLabel: item[0]
                                })
                              }
                              clickablePaper={classes.clickablePaper}
                            />
                          ))}
                        </Grid>
                      </TableCell>
                      <TableCell
                        width={this.state.isDetailedView ? '16%' : '24%'}>
                        <Grid container spacing={8}>
                          {row.nonGroupByField.map((item, idx) => (
                            <CustomPaper
                              key={idx}
                              backgroundColor={purple.main}
                              typographyColor="inherit"
                              label={`${item[0]} (${item[1]})`}
                              onClick={() =>
                                this.handlePaperClick(row.groupByKlass, {
                                  paperType: row.nonGroupByKlass,
                                  rowId: row._id,
                                  paperLabel: item[0]
                                })
                              }
                              clickablePaper={classes.clickablePaper}
                            />
                          ))}
                        </Grid>
                      </TableCell>
                      {this.state.isDetailedView && (
                        <>
                          <TableCell width="12%">
                            <Grid container spacing={8}>
                              {row.counts[0] ? (
                                <CustomPaper
                                  backgroundColor="white"
                                  typographyColor="textPrimary"
                                  label={row.counts[0]}
                                />
                              ) : (
                                ''
                              )}
                              {row.counts[1] ? (
                                <CustomPaper
                                  backgroundColor="white"
                                  typographyColor="textPrimary"
                                  label={`${row.counts[1]} PEND`}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell width="12%">
                            <Grid container spacing={8}>
                              {row.counts[2] ? (
                                <CustomPaper
                                  backgroundColor={red['500']}
                                  typographyColor="inherit"
                                  label={row.counts[2]}
                                />
                              ) : (
                                ''
                              )}
                              {row.counts[3] ? (
                                <CustomPaper
                                  backgroundColor={red['500']}
                                  typographyColor="inherit"
                                  label={`${row.counts[3]} PEND`}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell
                            className={classes.redTableCell}
                            width="12%">
                            <Grid container spacing={8}>
                              {row.counts[4] ? (
                                <CustomPaper
                                  backgroundColor={red[50]}
                                  typographyColor="textPrimary"
                                  label={row.counts[4]}
                                />
                              ) : (
                                ''
                              )}
                              {row.counts[5] ? (
                                <CustomPaper
                                  backgroundColor={red[50]}
                                  typographyColor="textPrimary"
                                  label={`${row.counts[5]} PEND`}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell
                            className={classes.redTableCell}
                            width="12%">
                            <Grid container spacing={8}>
                              {row.counts[6] ? (
                                <CustomPaper
                                  backgroundColor={red['500']}
                                  typographyColor="inherit"
                                  label={row.counts[6]}
                                />
                              ) : (
                                ''
                              )}
                              {row.counts[7] ? (
                                <CustomPaper
                                  backgroundColor={red['500']}
                                  typographyColor="inherit"
                                  label={`${row.counts[7]} PEND`}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </TableCell>
                        </>
                      )}
                      {!this.state.isDetailedView && (
                        <>
                          <TableCell width="15%">
                            <Grid container spacing={8}>
                              {row.simple_counts[0] ? (
                                <CustomPaper
                                  onClick={() =>
                                    this.handlePaperClick('sw', {
                                      paperType: 'sw',
                                      rowId: row._id,
                                      paperLabel: 'n' //new
                                    })
                                  }
                                  clickablePaper={classes.clickablePaper}
                                  backgroundColor="white"
                                  typographyColor="textPrimary"
                                  label={row.simple_counts[0]}
                                />
                              ) : (
                                ''
                              )}
                              {row.simple_counts[1] ? (
                                <CustomPaper
                                  onClick={() =>
                                    this.handlePaperClick('sw', {
                                      paperType: 'sw',
                                      rowId: row._id,
                                      paperLabel: 'p' //pending
                                    })
                                  }
                                  clickablePaper={classes.clickablePaper}
                                  backgroundColor="white"
                                  typographyColor="textPrimary"
                                  label={`${row.simple_counts[1]} PEND`}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </TableCell>
                          <TableCell width="15%">
                            <Grid container spacing={8}>
                              {row.simple_counts[2] ? (
                                <CustomPaper
                                  onClick={() =>
                                    this.handlePaperClick('sw', {
                                      paperType: 'sw',
                                      rowId: row._id,
                                      paperLabel: 's' //past sla
                                    })
                                  }
                                  clickablePaper={classes.clickablePaper}
                                  backgroundColor={red['500']}
                                  typographyColor="inherit"
                                  label={row.simple_counts[2]}
                                />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  dfWorkunitsReports: stateFromStore.dfWorkunitsReports
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      fetchOpenCounts_ac,
      redirectToDfa: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OpenCounts))
)
