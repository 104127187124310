import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  createApplication_ac,
  updateSelectedClientApps_ac
} from '../../actions/client_apps.ac'
import { open_snack_ac } from '../../actions/snack.ac.js'
import Loader from '../Shared/Loader'

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center'
  }
})

const preferenceSettings = [
  {
    label: 'Voice',
    name: 'voice',
    isChecked: false
  },
  {
    label: 'SMS',
    name: 'sms',
    isChecked: false
  },
  {
    label: 'Email',
    name: 'email',
    isChecked: false
  },
  {
    label: 'Letter',
    name: 'letter',
    isChecked: false
  }
]

const defaultFormFields = {
  name: '',
  description: '',
  preferences: [
    {
      nm: '',
      methods: preferenceSettings.map(settings => ({ ...settings }))
    }
  ]
}
const validationFields = {
  name: false,
  description: false
}
class ClientAppsOnboardForm extends React.Component {
  constructor() {
    super()
    this.state = {
      formFields: {
        ...defaultFormFields
      },
      errors: {
        ...validationFields
      }
    }
  }

  componentDidMount() {
    // reset the client_apps selection
    this.props.updateSelectedClientApps_ac(null)
  }

  handleChange = e => {
    const { name, value } = e.target

    this.setState(
      pre => ({
        formFields: {
          ...pre.formFields,
          [name]: value
        }
      }),
      () => {
        if (this.state.errors[name]) {
          this.validateForm()
        }
      }
    )
  }

  handlePreferenceChange = (e, preferenceIndex) => {
    const { type, name, value, checked } = e.target
    const { formFields } = this.state
    const { preferences } = formFields
    if (type === 'text') {
      preferences[preferenceIndex][name] = value
    }
    if (type === 'checkbox') {
      preferences[preferenceIndex].methods.find(
        option => option.name === name
      ).isChecked = checked
    }

    this.setState(pre => ({
      formFields: {
        ...pre.formFields,
        preferences
      }
    }))
  }

  addPreferences = () => {
    const { formFields } = this.state
    const methods = preferenceSettings.map(settings => ({ ...settings }))

    formFields.preferences.push({
      nm: '',
      methods
    })
    this.setState({
      formFields
    })
  }

  /**
   *
   * @param {number} index
   */
  removePreferene = index => {
    const { formFields } = this.state
    formFields.preferences.splice(index, 1)
    this.setState({
      formFields
    })
  }

  validateForm = () => {
    let nameErr = false
    let descriptionErr = false
    let isValidForm = true

    const { formFields } = this.state

    if (!formFields['name']) {
      nameErr = true
      isValidForm = false
    }

    if (!formFields['description']) {
      descriptionErr = true
      isValidForm = false
    }

    this.setState({
      errors: {
        name: nameErr,
        description: descriptionErr
      }
    })

    return isValidForm
  }

  createApplication = () => {
    const isValidForm = this.validateForm()
    if (!isValidForm) return
    const { formFields } = this.state
    const preferences = formFields.preferences.map(preference => ({
      nm: preference.nm,
      methods: _getMethods(preference.methods)
    }))
    const preferenceExists = preferences[0].nm && preferences[0].methods.length
    if (!preferenceExists) {
      return this.props.open_snack_ac({
        variant: 'info',
        message: 'Please add preferences for client application'
      })
    }
    formFields.preferences = preferences
    this.props.createApplication_ac(formFields)
    const methods = preferenceSettings.map(settings => ({ ...settings }))
    this.setState({
      formFields: {
        ...defaultFormFields,
        preferences: [
          {
            nm: '',
            methods
          }
        ]
      }
    })
    function _getMethods(methods) {
      return methods.reduce((acc, method) => {
        method.isChecked && acc.push(method.name)
        return acc
      }, [])
    }
  }

  render() {
    const { classes, clientAppsInView } = this.props
    const { errors } = this.state
    return (
      <>
        <Grid item xs={12}>
          <h2>Onboard Client</h2>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}
            fullWidth>
            <FormLabel component="label">
              <strong>Name</strong>
            </FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              required
              error={errors.name}
              helperText={errors.name ? 'Please enter name' : ''}
              placeholder="Name of Client Application"
              name="name"
              value={this.state.formFields.name}
            />
            <FormLabel component="label">
              <strong>Description</strong>
            </FormLabel>
            <TextField
              fullWidth
              variant="outlined"
              onChange={this.handleChange}
              required
              error={errors.description}
              helperText={errors.description ? 'Please enter description' : ''}
              placeholder="Description"
              name="description"
              value={this.state.formFields.description}
            />
            <FormLabel component="label">
              <h3>Preferences</h3>
            </FormLabel>

            {this.state.formFields.preferences.map((preference, index) => (
              <FormGroup key={index}>
                <FormLabel component="label">
                  <strong>Preference Name</strong>
                  {index >= 1 && (
                    <Tooltip title="Remove Preference">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => this.removePreferene(index)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormLabel>
                <TextField
                  style={{ marginTop: '5px' }}
                  fullWidth
                  variant="outlined"
                  onChange={e => this.handlePreferenceChange(e, index)}
                  required
                  placeholder="Name Of Preferences"
                  name="nm"
                />
                {preference.methods.map((pref, optionIndex) => (
                  <FormControlLabel
                    key={optionIndex}
                    label={
                      <Typography variant="body1">{pref.label}</Typography>
                    }
                    name={pref.name}
                    control={
                      <Checkbox
                        className={classes.inlineBlock}
                        checked={pref.isChecked || false}
                        onChange={e => this.handlePreferenceChange(e, index)}
                      />
                    }
                  />
                ))}
              </FormGroup>
            ))}
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => this.addPreferences()}>
              Add Preferences
              <AddIcon />
            </Button>
          </FormControl>
        </Grid>
        <Divider component="hr" />
        <Grid item xs={12}>
          <FormControl
            style={{ marginLeft: 0 }}
            className={classes.formControl}>
            <div className={classes.flexDisplay}>
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={this.createApplication}
                disabled={clientAppsInView.isSubmitting}>
                Create App
              </Button>
              {clientAppsInView.isSubmitting && (
                <Loader type="circular" size={15} color="primary" />
              )}
            </div>
          </FormControl>
        </Grid>
      </>
    )
  }
}

ClientAppsOnboardForm.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  clientAppsInView: stateFromStore.clientAppsInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createApplication_ac,
      updateSelectedClientApps_ac,
      open_snack_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClientAppsOnboardForm))
)
