const filterFormReducer = ((state, action) => {
  switch (action.type) {
    case 'CHANGE_FIELD_VALUE':
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.fieldName]: action.newValue
        }
      }

    case 'UPDATE_ERRORS':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.errors
        }
      }

    case 'UPDATE_HELPERTEXTS':
      return {
        ...state,
        helperTexts: {
          ...state.helperTexts,
          ...action.helperTexts
        }
      }

    default:
      return state
  }
})

export default filterFormReducer
