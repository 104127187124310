import { open_snack_ac } from './snack.ac.js';

const getSearchParams = (str) => {
  let params = {};
  var strToArr = str.split(';');
  strToArr.forEach((item) => {
    let splitedStr = item.substr(0, 2);
    if (splitedStr === 'b:') {
      params.teams = item.substring(2).split(/,/g);
    }
    if (splitedStr === 'e:') {
      params.bucket = item.substring(2);
    }
    if (splitedStr === 'd:') {
      params.date = item.substring(2);
    }
  });
  return params;
};

function requestDfaList_ac(searchStr, sortCode, pageNum) {
  return {
    type: 'REQUEST_DOCFLOW_ASSIGNMENTS',
    sortCode,
    searchStr,
    pageNum,
  };
}

function receivedDfaList_ac(searchStr, pageNum, results) {
  return {
    type: 'RECEIVED_DOCFLOW_ASSIGNMENTS',
    searchStr,
    pageNum,
    results,
  };
}

function failed_receivedDfaList_ac(searchStr, pageNum, error) {
  return {
    type: 'FAILED_RECEIVED_DOCFLOW_ASSIGNMENTS',
    searchStr,
    pageNum,
    error,
  };
}

function transformPostData(searchStr, sortCode, pageNum) {
  let sch_method = 'post';
  let target_url = `/api/workitems:list`;
  let postData = { bucketName: 'openqa', sort: sortCode, recordsPerPage: 100 };
  if (pageNum !== 1) {
    postData.requestedPageNum = pageNum - 1; //zer index it to api
  }
  switch (searchStr) {
    case 'myq':
    case 'myqp':
    case 'myq_stat':
    case 'tm_q':
    case 'tm_pq':
    case 'attn_me':
      postData.bucketName = searchStr;
      break;
    case 'myclosed':
      target_url = `/api/workitems:closed:list`;
      postData.bucketName = searchStr;
      break;
    case 'tm_closed':
      target_url = `/api/workitems:closed:list`;
      postData.bucketName = searchStr;
      break;
    case 'home':
      postData.bucketName = 'home';
      break;
    default:
      let selectedSubstr = searchStr.substr(0, 2);
      switch (selectedSubstr) {
        case 'z:':
          postData.srch = searchStr
            .substr(2)
            .replace(/_/g, ' ')
            .replace(/~/g, '#');
          if (postData.srch.startsWith('closed;')) {
            postData.bucketName = 'closedqa';
            target_url = '/api/workitems:closed:list';
            postData.srch = postData.srch.replace(/^closed;/, '').trim();
          }
          break;
        case 'b:':
          sch_method = 'post_ink';
          target_url = '/api/workunits:by_hours';
          postData = {
            ...postData,
            ...getSearchParams(searchStr),
          };
          break;

        default:
          postData.srch = searchStr.replace(/_/g, ' ');
          postData.exactSrch = true;
          break;
      }

    /*
        if (searchStr.search(/:/)===-1){
          postData.srch="^"+searchStr.replace(/_/g," ");
        }
        else{
          postData.srch=searchStr.replace(/_/g," ");
        }
        break;
        */
  }
  return { sch_method, target_url, postData };
}

export function saveToGoogleSheet_ac(searchStr, sortCode, pageNum) {
  return (dispatch, getState) => {
    dispatch(
      open_snack_ac({
        variant: 'info',
        message: '...saving to Googlesheet, give it a few seconds',
      })
    );
    const { sch_method, target_url, postData } = transformPostData(
      searchStr,
      sortCode,
      pageNum
    );
    postData.saveToGoogleSheet = true;
    return window.sch[sch_method](target_url, postData)
      .then((results) => {
        window.open(results.sheetPkg.spreadsheetUrl, '_tabDfuReport');
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Failed saving to Googlesheet',
          })
        );
      });
  };
}

export function searchStrSelected_ac(searchStr, sortCode, pageNum) {
  return (dispatch, getState) => {
    dispatch(requestDfaList_ac(searchStr, sortCode, pageNum));
    /*
    inputs:
      bucketName: { required:true },
      groupBy: { required:false},
      tagFilters: { required:false},
      sort: { required:false},
      recordsPerPage: { required:false},
      srch: { required:false},
    */

    const { sch_method, target_url, postData } = transformPostData(
      searchStr,
      sortCode,
      pageNum
    );
    return window.sch[sch_method](target_url, postData)
      .then((results) => {
        /*
        bucketName, pagination, rows
      */

        dispatch({ type: 'SET_TITLE', title: 'WORKUNITS' });
        //dispatch(close_snack_ac())
        dispatch(receivedDfaList_ac(searchStr, pageNum, results));
      })
      .catch((error) => {
        console.error('error:', error);
        //dispatch(close_snack_ac())
        dispatch(failed_receivedDfaList_ac(searchStr, pageNum, error));
      });
  };
}

export function fetchAllWorkunitsForIdn_ac(idn, sortCode, pageNum) {
  return (dispatch, getState) => {
    dispatch(requestDfaList_ac(idn, sortCode, pageNum));
    let target_url = `/api/workitems:idn:list`;
    let postData = {
      idn: idn.replace(/_/g, ' '),
      sort: sortCode,
      recordsPerPage: 250,
    };
    if (pageNum !== 1) {
      postData.requestedPageNum = pageNum - 1; //zer index it to api
    }

    return window.sch
      .post(target_url, postData)
      .then((results) => {
        dispatch({ type: 'SET_TITLE', title: 'WORKUNITS' });
        dispatch(receivedDfaList_ac(idn, pageNum, results));
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch(failed_receivedDfaList_ac(idn, pageNum, error));
      });
  };
}

export function locallyUpdateDfaWorkUnit_ac(posOfActivity, modifiedWDoc) {
  return (dispatch, getState) => {
    let _results = getState().dfaInView.results;
    _results.rows[posOfActivity] = modifiedWDoc;
    dispatch({ type: 'LOCAL_DFA_RECORD_UPDATE', results: _results });
  };
}

export function insertIntoDfaList_ac(position, wDoc) {
  return (dispatch, getState) => {
    wDoc.idxInSet = position * 1;
    wDoc.idxInPage = position * 1;
    wDoc.oou.df_entries = [];

    let _results = getState().dfaInView.results;
    _results.rows = [
      ..._results.rows.slice(0, position),
      wDoc,
      ..._results.rows.slice(position).map((a) => {
        a.idxInSet++;
        a.idxInPage++;
        return a;
      }),
    ];
    dispatch({ type: 'LOCAL_DFA_RECORD_UPDATE', results: _results });
  };
}

export function toggleAllCards_ac(expand) {
  return (dispatch, getState) => {
    let _results = getState().dfaInView.results;
    _results.rows = _results.rows.map((r) => {
      r.expanded = expand;
      return r;
    });
    dispatch({ type: 'LOCAL_DFA_RECORD_UPDATE', results: _results });
  };
}

export function tackleDfaWorkUnit_ac(posOfActivity) {
  return (dispatch, getState) => {
    let _results = getState().dfaInView.results;
    let _lastPos = getState().dfaInView.posOfActivity;
    if (_lastPos !== null) {
      delete _results.rows[_lastPos].inTackle;
    }

    _results.rows[posOfActivity].inTackle = true;
    dispatch({ type: 'DFA_TACKLED', posOfActivity, results: _results });
  };
}

export function untackleDfaWorkUnit_ac(posOfActivity) {
  return (dispatch, getState) => {
    let _results = getState().dfaInView.results;
    _results.rows[posOfActivity].inTackle = false;
    dispatch({ type: 'DFA_UNTACKLED', results: _results });
  };
}

/*
ink apis for work units:

/api/workitem:reassign:diffteam

*/

export function tackleActivity_ac(params) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_DFA_WORKUNIT' });
    let target_url;
    let block_sibling = false;
    switch (params.kind) {
      case 'createworkunit':
        target_url = `/api/workitem:createworkunit`;
        block_sibling = true;
        break;
      case 'unfollow':
        target_url = `/api/workitem:unfollow`;
        block_sibling = true;
        break;
      case 'reassign':
        target_url = `/api/workitem:reassign:diffteam`;
        break;
      case 'prod_situation_update':
        target_url = `/api/workitem:product:situation`;
        break;
      case 'update_label':
      case 'convert_to_newdocs':
      case 'convert_to_wo_required':
        target_url = `/api/workitem:updatelabel`;
        break;
      default:
        dispatch({ type: 'ERROR_UPDATE_DFA_WORKUNIT' });
        return;
    }

    return window.sch
      .post_ink(target_url, params.params)
      .then((results) => {
        let _results = getState().dfaInView.results;
        _results.rows[params.posInSet] = params.modifiedWDoc
          ? {
              ...params.modifiedWDoc,
            }
          : {
              ..._results.rows[params.posInSet], //pass along existing copy
              ...results.modifiedWDoc, //allow overwrite
              /*
              moreNotesCount: _results.rows[params.posInSet].moreNotesCount,
              recentWos: _results.rows[params.posInSet].recentWos,
              idxInOa: _results.rows[params.posInSet].idxInOa
              */
            };

        /*find siblings ownersArr entries matching wDoc._id, to prevent tackling until refresh*/
        if (block_sibling) {
          _results.rows = _results.rows.map((r, rIdx) => {
            if (
              r._id === _results.rows[params.posInSet]._id &&
              rIdx !== params.posInSet
            ) {
              r.disable_until_refresh = true;
            }
            return r;
          });
        }

        if (params.stayTackled) {
          _results.rows[params.posInSet].inTackle = true;
          _results.rows[params.posInSet].expanded = true;
          dispatch({
            type: 'RESPONSE_UPDATE_DFA_WORKUNIT',
            updatedResults: _results,
          });
        } else {
          delete _results.rows[params.posInSet].inTackle;
          dispatch({
            type: 'DFA_UNTACKLED',
            posOfActivity: params.posInSet,
            results: _results,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          open_snack_ac({
            variant: 'error',
            message:
              'Update failed' + (error.message ? ': ' + error.message : ''),
          })
        );
        dispatch({ type: 'ERROR_UPDATE_DFA_WORKUNIT' });
      });
  };
}

/*
not used as of 1/1/2020
*/
export function updateDfaWorkUnit_ac({
  idn,
  pageNum,
  posOfRecord,
  action,
  postData,
}) {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_DFA_WORKUNIT' });
    let target_url = `/api/${action}`;

    return window.sch
      .post_ink(target_url, postData)
      .then((results) => {
        dispatch({
          type: 'RESPONSE_UPDATE_DFA_WORKUNIT',
          updatedResults: results.updatedResults,
        });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch(failed_receivedDfaList_ac(idn, pageNum, error));
      });
  };
}

export function getNavList_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_DFA_NAV_LIST' });
    let target_url = `/api/dfa:getnavlist`;
    let postData = {};

    return window.sch
      .post(target_url, postData)
      .then((results) => {
        dispatch({
          type: 'RECEIVED_DFA_NAV_LIST',
          menus: results.menus,
          baseItems: results.baseItems,
          secondaryBaseItems: results.secondaryBaseItems,
        });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_RECEIVED_DFA_NAV_LIST', error });
      });
  };
}
