export const OpenOrdersActionTypes = {
  REPORT_FETCHED: 'REPORT_FETCHED',
  REPORT_FETCHING_FAILED: 'REPORT_FETCHING_FAILED',
  SET_IS_FETCHING: 'SET_IS_FETCHING'
}

export function fetchOpenOrders_ac(params) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_DF_OPEN_ORDERS', params })

    return window.sch
      .post(`/api/openorders`, params)
      .then(result => {
        dispatch({
          type: 'RESPONSE_DF_OPEN_ORDERS',
          pkg: result.pkg
        })
      })
      .catch(error => {
        console.log('**FAIL******', error)
        dispatch({
          type: 'FAILED_DF_OPEN_ORDERS',
          error
        })
      })
  }
}

export const fetchOpenOrdersTracking_ac = params => dispatch => {
  dispatch(fetching(true))
  return window.sch
    .post(`/api/dynamic_reports`, params)
    .then(result => {
      dispatch({
        type: OpenOrdersActionTypes.REPORT_FETCHED,
        html: result.html
      })
    })
    .catch(error => {
      console.log('**FAIL******', error)
      dispatch({
        type: OpenOrdersActionTypes.REPORT_FETCHING_FAILED,
        trackingError: error
      })
    })
    .finally(() => {
      dispatch(fetching(false))
    })
}

export const fetching = isFetching => ({
  type: OpenOrdersActionTypes.SET_IS_FETCHING,
  isFetching
})
