import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac'
import { fetchPPRegistrations_ac } from '../../actions/pp_registrations.ac'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'
import RegistrationRow from './RegistrationRow'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit * 0.5,
    overflowY: 'auto'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  },
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  pagination: {
    padding: 8,
    backgroundColor: '#00000005',
    borderBottom: '1px solid #00000030'
  }
})

const PAGE_TITLE = 'Patient Portal Registrations'

class PatientPortalRegistrations extends BaseLandingTemplate {
  constructor() {
    super()

    this.state = {
      selectedPageNum: 0
    }

    this.fetch = this.fetch.bind(this)
  }

  checkSituation = () => {
    const { ppRegistrations, me, lookups, lookups_ac, setTitle_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE)
    }

    if (
      !ppRegistrations.report &&
      !ppRegistrations.error &&
      !ppRegistrations.isFetchingReport
    ) {
      this.fetch()
    }
  }

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  fetch(pageNum = 0) {
    let params = {
      page: pageNum
    }

    this.props.fetchPPRegistrations_ac(params)
  }

  handlePageChange = e => {
    let targetPage = parseInt(e.target.value, 10)

    this.setState({
      selectedPageNum: targetPage - 1
    })

    this.fetch(targetPage - 1)
  }

  getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${this.state.selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  render() {
    const { classes, ppRegistrations } = this.props
    const { report, isFetchingReport, error } = ppRegistrations

    if (isFetchingReport) {
      return <Loader classes={{ message: classes.message }} />
    }

    if (error) {
      return (
        <Typography color="error" className={classes.message}>
          Error fetching list
        </Typography>
      )
    }

    if (!report) {
      return <div className={classes.message}>...</div>
    }

    const { pagination: pg, records } = report
    let pageStatement = ''
    if (pg) {
      pageStatement = this.getPageStatement(pg)
    }

    let pageSelector = ''
    if (pg.totalPagesAvailable > 1) {
      pageSelector = (
        <form autoComplete="off">
          <FormControl>
            <Select
              margin="dense"
              onChange={this.handlePageChange}
              displayEmpty
              value={pg.currentPageNum + 1}
              name="page">
              {_.range(1, pg.totalPagesAvailable + 1).map(pgNum => (
                <MenuItem key={pgNum} value={pgNum}>
                  Page {pgNum}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      )
    }

    return (
      <div className={classes.content}>
        <div className={classes.pagination}>
          <Grid container spacing={16} alignItems="flex-end">
            <Grid item xs={12} sm={6}>
              <Typography color="textSecondary">{pageStatement}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {pageSelector}
            </Grid>
          </Grid>
        </div>

        <div className={classes.scrollArea}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell width="18%" style={{ padding: 8 }}>
                  Patient
                </TableCell>
                <TableCell width="17%" style={{ padding: 8 }}>
                  Contact
                </TableCell>
                <TableCell width="16%" style={{ padding: 8 }}>
                  Billing Address
                </TableCell>
                <TableCell width="20%" style={{ padding: 8 }}>
                  Shipping Address
                </TableCell>
                <TableCell width="13%" style={{ padding: 8 }}>
                  Doctor
                </TableCell>
                <TableCell width="16%" style={{ padding: 8 }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records &&
                records.length > 0 &&
                records.map(
                  (record, idx) =>
                    record && (
                      <RegistrationRow
                        key={idx}
                        isEven={idx % 2 === 0}
                        record={record}
                      />
                    )
                )}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  ppRegistrations: stateFromStore.ppRegistrations
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      setTitle_ac,
      fetchPPRegistrations_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PatientPortalRegistrations))
)
