import { ShoppingCartActionTypes } from './../actions/shopping_cart.ac'
const initialState = {
    cart: {},
    total: 0,
    isFetching: false,
    error: null
}
const STATE_COST_RULE = {
    new: 1.7,
    used: 1.3
}
export const shoppingCartInView = (state = initialState, action) => {
    switch (action.type) {
        case ShoppingCartActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case ShoppingCartActionTypes.RECEIVED_SHOPPING_CART_ITEMS:
            (action.cart.items || []).forEach(item => {
                item.state = 'new'
                item.isUsed = false
            });
            return {
                ...state,
                cart: action.cart,
                total: getTotal(action.cart)
            }
        case ShoppingCartActionTypes.FAILED_RECEIVING_ITEMS:
            return {
                ...state,
                cart: {},
                error: action.error,
            };

        case ShoppingCartActionTypes.ADD_TO_CART:
            if (!state.cart.items) {
                state.cart.items = [];
            }
            if (!state.cart._id) {
                state.cart._id = action.cart_id;
            }
            state.cart.items.push(action.item);
            return {
                ...state,
                total: getTotal(state.cart)
            }
        case ShoppingCartActionTypes.ADD_MACRO_ITEMS_TO_CART:
            if (!state.cart.items) {
                state.cart.items = [];
            }
            if (!state.cart._id) {
                state.cart._id = action.cart_id;
            }
            state.cart.items = action.items
            return {
                ...state,
                total: getTotal(state.cart)
            }

        case ShoppingCartActionTypes.REMOVE_ITEM_FROM_CART:
            state.cart.items = action.items;
            return {
                ...state,
                total: getTotal(state.cart)
            }

        case ShoppingCartActionTypes.REMOVE_CART:
            return {
                ...state,
                cart: {},
                total: 0
            }

        case ShoppingCartActionTypes.CHANGE_QUANTITY:
            state.cart.items = action.items;
            return {
                ...state,
                total: getTotal(state.cart)
            }

        case ShoppingCartActionTypes.UPDATE_CART:
            state.cart.items = action.items;
            return {
                ...state,
                total: getTotal(state.cart)
            }

        default:
            return {
                ...state
            }
    }
}

function getTotal(cart = {}) {
    return (cart.items || []).reduce((acc, item) => {
        let aggregateSum = acc + item.unitCost * item.uomcount * STATE_COST_RULE[item.state];
        return aggregateSum;
    }, 0)
}
