import moment from 'moment'

const parseHoursMinutes = (v, nm) => {
  let [hours, minutes] = v
    .split(/hrs/)
    .map(p => p.replace(/[^0-9]/g, '').trim())

  if (isNaN(hours)) {
    return {
      [nm]: null
    }
  }

  hours = parseInt(hours, 10)
  if (minutes !== '' && !isNaN(minutes)) {
    hours += parseInt(minutes, 10) / 60
  }

  return {
    [nm]: hours
  }
}

const parsePercentage = (v, nm) => {
  let a = v.replace(/[^0-9.]/g, '').trim()
  if (a === '' || isNaN(a)) {
    return {
      [nm]: null
    }
  }
  return {
    [nm]: parseFloat(a, 10)
  }
}

const parseDays = (v, nm) => {
  let a = v.replace(/[^0-9.]/g, '').trim()
  if (a === '' || isNaN(a)) {
    return {
      [nm]: null
    }
  }
  return {
    [nm]: parseInt(a, 10)
  }
}

const TABLE_RULES = [
  {
    btnLbl: 'Save Vent Data Download',
    minimum_required: 3,
    extras: {
      kind: 'vent_data_download'
    },
    already_saved: currentDetails => {
      if (currentDetails.kind === 'vent_data_download') {
        return true
      }
    },
    fields_of_interest: {
      'Date Range': {
        get: v => {
          let [start, end] = v
            .split(/-/)
            .map(part => part.trim())
            .map(p => moment(p, 'M/D/YYYY'))

          if (
            start === undefined ||
            end === undefined ||
            !start._isValid ||
            !end._isValid
          ) {
            return { 'Date Range': null }
          }

          let r = {
            'Date Range': v,
            start_dt: start.format('MM-DD-YYYY'),
            end_dt: end.format('MM-DD-YYYY')
          }
          return r
        }
      },
      'Days with Device Usage': {
        nm: 'Days with Usage',
        get: v => {
          return parseDays(v, 'Days with Usage')
        }
      },
      'Days without Device Usage': {
        nm: 'Days without Usage',
        get: v => {
          return parseDays(v, 'Days without Usage')
        }
      },
      'Percent Days Usage': {
        get: v => {
          return parsePercentage(v, 'Percent Days Usage')
        }
      },
      'Cumulative Usage': {
        nm: 'Cumulative Usage Hours',
        get: v => {
          return parseHoursMinutes(v, 'Cumulative Usage Hours')
        }
      },
      'Maximum Usage (1 day)': {
        nm: 'Maximum Usage Hrs (1 Day)',
        get: v => {
          return parseHoursMinutes(v, 'Maximum Usage Hrs (1 Day)')
        }
      },
      'Average Usage (All Days)': {
        nm: 'Average Usage Hrs (All Days)',
        get: v => {
          return parseHoursMinutes(v, 'Average Usage Hrs (All Days)')
        }
      },
      'Average Usage (Days Used)': {
        nm: 'Average Usage Hrs (Days Used)',
        get: v => {
          return parseHoursMinutes(v, 'Average Usage Hrs (Days Used)')
        }
      },
      'Minimum Usage (1 Day)': {
        nm: 'Minimum Usage Hrs (1 Day)',
        get: v => {
          return parseHoursMinutes(v, 'Minimum Usage Hrs (1 Day)')
        }
      },

      'Percent of Days with Usage >= 4 Hours': {
        nm: 'Percent of Days With Usage >= 4 Hrs',
        get: v => {
          return parsePercentage(v, 'Percent of Days With Usage >= 4 Hrs')
        }
      },
      'Percent of Days with Usage < 4 Hours': {
        nm: 'Percent of Days WIth Usage < 4 Hrs',
        get: v => {
          return parsePercentage(v, 'Percent of Days WIth Usage < 4 Hrs')
        }
      }
    }
  }
]

export { TABLE_RULES }
