import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Switch from '@material-ui/core/Switch'
import { Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  AccountBox,
  Assessment,
  Build,
  Dashboard,
  List,
  Mail,
  MoreHoriz,
  PeopleOutline,
  SupervisorAccount
} from '@material-ui/icons'

import NotLoggedIn from '../NotLoggedIn'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac'
import CardViewSiteMap from './CardViewSiteMap'
import DefaultViewSiteMap from './DefaultViewSiteMap'

const styles = theme => ({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  header: {
    padding: 4,
    borderBottom: '2px solid #00000050',
    backgroundColor: 'white'
  },
  toggleButton: {
    height: '24px',
    marginRight: '8px',
    float: 'right',
  },
  label: {
    textTransform: 'capitalize',
    fontWeight:'bold',
    fontSize:'14px'
  },
  tooltip: {
    fontSize:'14px'
  }


})

const PAGE_TITLE = "DocFlow Site-Map"

const icons = {
  assessment: Assessment,
  supervisor_account: SupervisorAccount,
  account_box: AccountBox,
  list: List,
  build: Build,
  people_outline: PeopleOutline,
  dashboard: Dashboard,
  more_horiz: MoreHoriz,
  mail: Mail
}

class SiteMapLanding extends React.Component {

  state = {
    toggleOn: false,
    toggleLabel: 'DefautView'
  };

  handleToggleChange = name => event => {
    this.setState({ [name]: event.target.checked, toggleLabel: event.target.checked ? 'CardView' : 'DefaultView' });

  };

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation = () => {
    const { me, lookups, lookups_ac, setTitle_ac } = this.props

    if (!me.user) return

    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE)
    }

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }
  }

  render() {
    const { classes, me, lookups } = this.props
    const tooltipTitle = this.state.toggleOn ? 'Switch to DefaultView' : 'Switch to CardView'

    if (!me.user) {
      return <NotLoggedIn />
    }

    if (!lookups.ink) {
      return (
        <Typography variant="body1">
          ...
        </Typography>
      )
    }

    return (
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography
            inline
            variant="h6"
            color="textSecondary"
          >
            <strong>List of Pages</strong>
          </Typography>

          <FormControlLabel
            className={classes.toggleButton}
            classes = {{label: classes.label}}
            control={
              <Tooltip classes = {{tooltip: classes.tooltip}} title={tooltipTitle} placement="bottom">
              <Switch
                checked={this.state.toggleOn}
                onChange={this.handleToggleChange('toggleOn')}
                value="toggleOn"
              />
              </Tooltip>
            }
            label={this.state.toggleLabel}
            color="default"
          />
        </div>

        {this.state.toggleOn ? < CardViewSiteMap icons={icons} /> :  <DefaultViewSiteMap icons={icons}/> }

      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    lookups_ac,
    setTitle_ac
  },
  dispatch
)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SiteMapLanding))
)
