import _ from 'lodash';
import React from 'react';

import { Button, Typography } from '@material-ui/core';
import { TextInfo } from '../../HourlyWos/components';

const RelationshipView = ({
  relationships = [],
  onRemove,
  isRemoving,
  children,
}) => {
  const totalRelations = React.useMemo(() => {
    return _.sumBy(relationships, 'n');
  }, [relationships]);

  const handleRemoveClick = () => {
    onRemove();
  };

  return (
    <div style={{ margin: '16px 0px' }}>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: '12px' }}>
        {'Relationships: '}
      </Typography>
      <div style={{ marginTop: '6px' }}>
        {totalRelations ? (
          <div>
            {relationships.map((rel, index) => {
              return (
                <Typography key={index}>
                  <span style={{ fontWeight: 'bold', fontSize: '10px' }}>
                    {rel.display_name || rel.class_name}
                    {' : '}
                  </span>
                  <span>{rel.n}</span>
                </Typography>
              );
            })}
            <div style={{ marginTop: '8px' }}> {children} </div>
          </div>
        ) : (
          <>
            <TextInfo
              value={`No relationships with other records. Removal allowed.`}
              type="info"
              variant="h6"
              style={{ marginBottom: '12px' }}
            />
            <Button
              disabled={isRemoving}
              variant="outlined"
              style={{ marginRight: '8px' }}
              size="small"
              color="primary"
              onClick={handleRemoveClick}>
              {'Remove'}
            </Button>
            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default RelationshipView;
