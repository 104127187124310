export function idnWosLines(
  state = {
    isLoading: false,
    account: null,
    member: null,
    error: null,
    pkg: null,
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_IDN_WOS_LINES':
      return Object.assign({}, state, {
        pkg: null,
        error: null,
        isLoading: true,
        account: action.account,
        member: action.member,
      });
    case 'FAILED_RECEIVED_IDN_WOS_LINES':
      return Object.assign({}, state, {
        pkg: null,
        error: action.error,
        isLoading: false,
      });
    case 'RECEIVED_IDN_WOS_LINES':
      return Object.assign({}, state, {
        pkg: action.pkg,
        error: null,
        isLoading: false,
      });
    case 'RESET_IDN_WOS_LINES':
      return Object.assign({}, state, {
        pkg: null,
        error: null,
        account: null,
        member: null,
        isLoading: false,
      });

    default:
      return state;
  }
}
