import { actionTypes } from '../actions/idx_admin_workunittypes.ac'

const initialState = {
  error: null,
  workunitTypes: null,
  isFetching: false
}

const initialWorkunitTypeData = {
  workunitTypeId: null,
  workunitType: null,
  isFetching: false,
  error: null
}

export const idxAdminWorkunitTypes = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ADMIN_WORKUNIT_TYPES:
      return {
        ...state,
        isFetching: true,
      }

    case actionTypes.RECEIVED_IDX_ADMIN_WORKUNIT_TYPES:
      return {
        ...state,
        error: null,
        isFetching: false,
        workunitTypes: action.workunitTypes,
      }

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPES:
      return {
        ...state,
        error: true,
        workunitTypes: null,
        isFetching: false
      }

    default:
      return { ...state }
  }
}

export const idxAdminWorkunitTypeDetails = (state = initialWorkunitTypeData, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_WORKUNIT_TYPE:
      return {
        ...state,
        workunitTypeId: action.workunitTypeId
      }

    case actionTypes.FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS:
      return {
        ...state,
        isFetching: true
      }

    case actionTypes.RECEIVED_IDX_ADMIN_WORKUNIT_TYPE_DETAILS:
      return {
        ...state,
        isFetching: false,
        workunitType: {
          ...action.workunitType,
          existingRelations: action.existingRelations || []
        },
        error: null
      }

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_WORKUNIT_TYPE_DETAILS:
      return {
        ...state,
        isFetching: false,
        workunitType: null,
        error: true
      }

    default:
      return { ...state }
  }
}
