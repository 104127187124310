import { CommunicationPreferenceActionTypes } from './../actions/communication_preference.ac';

export const communicationPreferenceInView = (
  state = {
    result: null,
    error: null,
    preferences: null,
    preference: null,
    pending: null,
    isLoading: false,
    isFetching: false,
  },
  action
) => {
  switch (action.type) {
    case CommunicationPreferenceActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case CommunicationPreferenceActionTypes.COMMUNICATION_PREFERENCE_ADDED:
      const { preferences } = state;
      preferences.push(action.preference);
      return {
        ...state,
        preferences,
      };
    case CommunicationPreferenceActionTypes.COMMUNICATION_PREFERENCE_UPDATED:
      console.log(action.result);
      return {
        ...state,
        result: action.result,
        preference: action.result.preference,
      };
    case CommunicationPreferenceActionTypes.COMMUNICATION_PREFERENCE_RECEIVED:
      return {
        ...state,
        error: null,
        preferences: action.preferences,
      };
    case CommunicationPreferenceActionTypes.FAILED_RECEIVING_COMMUNICATION_PREFERENCE:
      return {
        ...state,
        error: action.error,
      };
    case CommunicationPreferenceActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case CommunicationPreferenceActionTypes.SET_SELECTED_PREFERENCE:
      return {
        ...state,
        preference: action.preference,
      };
    default:
      return {
        ...state,
      };
  }
};
