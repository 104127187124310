import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { green as greenColor } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {
  AddCircle as AddCircleIcon,
  Add as AddIcon,
  Cancel as CancelIcon,
  DeleteOutlined as DeleteOutlinedIcon,
  Done as DoneIcon,
  LocalShipping as LocalShippingIcon,
  Remove as SubtractIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateWOLineItemsLocally_ac } from '../../actions/idn_wos.ac';
import { open_snack_ac } from '../../actions/snack.ac';
import { MednecIssueLogEntryForm } from '../MednecIssueLog/MednecIssueLogEntryForm';
import { MednecIssueLogView } from '../MednecIssueLog/MednecIssueLogView';
import Loader from '../Shared/Loader';
import SearchField from '../Shared/SearchField';
import OrderStatus from './V2Workunits/OrderStatus';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  linkTo: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#fff' },
  owner: { color: '#7f8486' },
  tm: { backgroundColor: '#689F38' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  // IDEA: k
  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  scheduled: { color: '#4c5a4d' },
  cancelled: { color: '#f50808' },
  confirmed: { color: 'rgb(25.4%, 37.8%, 0%)', fontWeight: 800 },

  cardHdrRoot_unscheduled: {
    backgroundColor: '#ffb74d',
    paddingBottom: 8,
  },
  cardHdrSubHdr_unscheduled: {
    fontSize: '.92em',
    color: '#000',
  },
  cardHdrContent_unscheduled: {
    fontSize: '.96em',
    fontWeight: 800,
    color: '#000',
  },

  cardHdrRoot_scheduled: {
    backgroundColor: '#b2dfdb',
    paddingBottom: 8,
  },
  cardHdrSubHdr_scheduled: {
    fontSize: '.92em',
  },
  cardHdrContent_scheduled: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  cardHdrRoot_cancelled: {
    backgroundColor: 'red',
    paddingBottom: 8,
  },
  cardHdrSubHdr_cancelled: {
    fontSize: '.92em',
  },
  cardHdrContent_cancelled: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  cardHdrRoot_confirmed: {
    backgroundColor: '#81c784',
    paddingBottom: 8,
    cursor: 'pointer',
  },
  cardHdrSubHdr_confirmed: {
    fontSize: '.92em',
  },
  cardHdrContent_confirmed: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  hdrText_scheduled: {},
  hdrText_confirmed: {},
  hdrText_cancelled: { color: '#fff', fontWeight: 'bold' },

  slaStart: {
    marginTop: 6,
    color: '#008AE0',
  },
  slaPasses: {
    color: '#E51400',
    marginBottom: 6,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteTxt: {
    color: '#4e6c7a',
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    color: '#244e91',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
  },
  productCode: {
    color: '#303f9f',
  },
  ppStatus: {
    fontSize: 11,
    textDecoration: 'italics',
    color: 'rgb(20.5%, 0%, 37.8%)',
  },
  dtrackStatus: {
    paddingLeft: 4,
    color: '#006064',
    fontWeight: 600,
  },
  smallBadge: {
    height: 8,
    minWidth: 8,
  },
  newLineIndicator: {
    backgroundColor: greenColor['800'],
    color: 'white',
    padding: 4,
    marginLeft: 4,
    borderRadius: 2,
  },
  linesListContainer: {
    padding: '0 0 0 12px',
    margin: 0,
    '& > li': {
      padding: '0 0 4px 4px',
      fontSize: 11,
      marginBottom: 4,
    },
  },
  smallButton: {
    minWidth: 30,
    margin: '2px 4px 2px 0px',
  },
  quantityInputField: {
    margin: 0,
    maxWidth: 40,
  },
});

const DISPATCH_DELIVERY_METHOD_IDS = [1, 2, 3, 5, 6, 8, 14, 17, 18, 19, 20];
const WO_ACTIVITY_TYPES = [
  { code: 1, lbl: 'Delivery' },
  { code: 2, lbl: 'Rental' },
  { code: 10, lbl: 'Pickup' },
  { code: 20, lbl: 'Exchange' },
];
const MIN_PRODUCT_QTY = 0;
const MAX_PRODUCT_QTY = 100;

const PA_REQ_CODES = {
  0: 'PA Not Required',
  1: 'PA Required if Primary Payer',
  2: 'PA Required if Medicare NOT Primary',
  3: 'PA Required if Primary or if Secondary with Medicare Primary',
  4: 'PA Required',
};

class DtrackDetails extends React.Component {
  render() {
    const { classes, dtrack } = this.props;
    return (
      <CardContent>
        {dtrack.trip && (
          <>
            {[
              'routed',
              'dispatched',
              'deployed',
              'arrived',
              'departed',
              'clean_up',
            ]
              .filter((s) => _.get(dtrack.trip, s, false))
              .map((s, sidx) => {
                let t = _.get(dtrack.trip, s);
                return (
                  <Typography
                    component="span"
                    key={'WO' + sidx}
                    className={classes.inline}
                    color="textPrimary">
                    <Moment format="ddd MM-DD-YYYY hh:mma">{t.ts}</Moment>
                    <span className={classes.dtrackStatus}>
                      {s.toUpperCase()} by {t.u_id}
                    </span>
                  </Typography>
                );
              })}
            {dtrack.trip.driver_comment && (
              <Typography
                component="span"
                className={classes.inline}
                color="textPrimary">
                <span>Driver comment:</span>
                <span className={classes.dtrackStatus}>
                  {dtrack.trip.driver_comment}
                </span>
              </Typography>
            )}

            {_.get(dtrack.ppl, 'df_faxJobId', false) && (
              <Typography
                component="span"
                className={classes.inline}
                color="textPrimary">
                <span>E-SIGNED, IN Docflow</span>
              </Typography>
            )}
          </>
        )}
        {!dtrack.trip && (
          <Typography
            component="span"
            className={classes.inline}
            color="textPrimary">
            No trip data
          </Typography>
        )}
        {dtrack.thread && (
          <div>
            <div style={{ marginTop: 4, fontSize: 10 }}>Comments:</div>
            {dtrack.thread.map((th, sidx) => (
              <Typography
                component="span"
                key={sidx}
                className={classes.inline}
                color="textPrimary">
                <Moment format="ddd MM-DD-YYYY hh:mma">{th.ts}</Moment>
                <span className={classes.dtrackStatus}>
                  {th.txt} - {th.u_id}
                </span>
              </Typography>
            ))}
          </div>
        )}
      </CardContent>
    );
  }
}

const WOLinesContainer = (props) => {
  const {
    wo_id,
    acctMember,
    allowModification,
    lines = [],
    classes,
    openSnack,
    updateWOLocally,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSerialMode, setIsSerialMode] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [productLines, setProductLines] = useState(_.cloneDeep(lines));
  const [noteEditingLineIdx, setNoteEditingLineIdx] = useState(-1);
  const [serialNoEditingLineIdx, setSerialNoEditingLineIdx] = useState(-1);
  const [changeMsgEditingLineIdx, setChangeMsgEditingLineIdx] = useState(-1);
  const [woActivityTypeAnchor, setWoActivityTypeAnchor] = useState(null);
  const noteFieldRef = useRef(null);
  const serialNoFieldRef = useRef(null);
  const changeMsgFieldRef = useRef(null);
  const extraNoteFieldRef = useRef(null);

  useEffect(() => {
    setProductLines(_.cloneDeep(lines));
    setIsEditMode(false);
    setIsSerialMode(false);
  }, [lines]);

  const toggleEditMode = () => {
    if (isEditMode) setProductLines(_.cloneDeep(lines)); // reset if cancelled

    setIsEditMode(!isEditMode);

    if (isAddMode) setIsAddMode(false);
    if (isSerialMode) setIsSerialMode(false);
  };

  const toggleSerialMode = () => {
    if (isSerialMode) {
      // reset if cancelled
      setProductLines(_.cloneDeep(lines));
      setSerialNoEditingLineIdx(-1);
      setChangeMsgEditingLineIdx(-1);
    }

    setIsSerialMode(!isSerialMode);

    if (isEditMode) setIsEditMode(false);
  };

  const toggleSerialNoEditMode = (lineIdx) => {
    setSerialNoEditingLineIdx(
      serialNoEditingLineIdx === lineIdx ? -1 : lineIdx
    );
  };

  const toggleChangeMsgEditMode = (lineIdx) => {
    setChangeMsgEditingLineIdx(
      changeMsgEditingLineIdx === lineIdx ? -1 : lineIdx
    );
  };

  const handleLinesUpdateSubmit = () => {
    // validation
    let newLines = productLines.filter((line) => line.isNewLine);
    let isValid = true;

    if (newLines.length > 0) {
      isValid = newLines.every((line) => {
        let activityTypeIndex = WO_ACTIVITY_TYPES.findIndex(
          (activityType) =>
            activityType.code === line.wo_type_code &&
            activityType.lbl === line.wo_type_str
        );

        return activityTypeIndex > -1;
      });
    }

    if (isValid) updateWOLineItems();
    else
      openSnack({
        variant: 'error',
        message: 'Please select Activity Type for newly added product lines!',
      });
  };

  const handleQuantityChange = (idx, value) => {
    let newProductlines = [...productLines];
    let newQuantity = parseInt(value, 10);

    if (isNaN(newQuantity)) newQuantity = 0;
    else if (newQuantity < MIN_PRODUCT_QTY) newQuantity = MIN_PRODUCT_QTY;
    else if (newQuantity > MAX_PRODUCT_QTY) newQuantity = MAX_PRODUCT_QTY;

    newProductlines[idx] = {
      ...newProductlines[idx],
      qty_ordered: newQuantity,
    };

    setProductLines(newProductlines);
  };

  const searchProducts = (endPoint, { searchStr }) => {
    return window.sch.post(endPoint, {
      acctMember,
      str: searchStr,
      lastMethod: 'search',
      discontinued: false,
    });
  };

  const renderProductSuggestion = (suggestion = {}) => {
    return (
      <>
        <span className={classes.productCode}>
          {suggestion.prod_code || ''}
        </span>
        &ensp;
        {suggestion.description || ''}
      </>
    );
  };

  const selectSuggestion = (suggestion = {}) => {
    console.log(suggestion);
    let newLine = {
      bc: suggestion.billing_code || null,
      bc_desc: suggestion.billing_code_desc || null,
      hdms_wId: (productLines[0] || {})['hdms_wId'] || null,
      product_code: suggestion.prod_code || null,
      product_desc: suggestion.description || null,
      qty_ordered: 1,
      cat_id: suggestion.cat_id || null,
      prod_id: suggestion.prod_id || null,
      uom: suggestion.uom || null,
      isNewLine: true,
    };

    setProductLines(productLines.concat(newLine));
    setIsAddMode(false);
  };

  const handleNewLineRemoval = (lineIdx) => {
    let newProductLines = [].concat(
      productLines.slice(0, lineIdx),
      productLines.slice(lineIdx + 1)
    );

    setProductLines(newProductLines);
    setNoteEditingLineIdx(-1);
  };

  const handleLineNoteSave = (lineIdx) => {
    let newProductLines = [...productLines];

    newProductLines[lineIdx] = {
      ...newProductLines[lineIdx],
      line_note: noteFieldRef.current.value,
    };

    setProductLines(newProductLines);
    setNoteEditingLineIdx(-1);
  };

  const handleActivityTypeSelection = (lineIdx, activityType) => {
    let newProductLines = [...productLines];

    newProductLines[lineIdx] = {
      ...newProductLines[lineIdx],
      wo_type_code: activityType.code,
      wo_type_str: activityType.lbl,
    };

    setProductLines(newProductLines);
    setWoActivityTypeAnchor(null);
  };

  const handleSerialNoSave = (e, lineIdx) => {
    if (e.key === 'Enter') {
      let newProductLines = [...productLines];

      newProductLines[lineIdx] = {
        ...newProductLines[lineIdx],
        serial_no: serialNoFieldRef.current.value,
      };

      setProductLines(newProductLines);
      setSerialNoEditingLineIdx(-1);
    }
  };

  const handleChangeMsgSave = (e, lineIdx) => {
    if (e.key === 'Enter') {
      let newProductLines = [...productLines];

      newProductLines[lineIdx] = {
        ...newProductLines[lineIdx],
        change_msg: changeMsgFieldRef.current.value,
      };

      setProductLines(newProductLines);
      setChangeMsgEditingLineIdx(-1);
    }
  };

  const updateWOLineItems = () => {
    let updatedLines = productLines.map((line) => {
      let { isNewLine, ...other } = line;

      return other;
    });

    if ((isEditMode && isSerialMode) || !(isEditMode || isSerialMode)) return;

    let targetURL = '';
    let payload = {
      lines: updatedLines,
    };

    if (isEditMode) {
      targetURL = '/api/dtmobile/proxy/modify_lines';
      payload.wo_id = wo_id;
    } else {
      targetURL = '/api/dtrack/sn_update';
      payload.wo_id = `${wo_id}`;
      payload.extra_note = extraNoteFieldRef.current.value || '';
    }

    setIsUpdating(true);
    window.sch
      .post(targetURL, payload)
      .then((res) => {
        if (isEditMode && (res.err || !res.success)) {
          return openSnack({
            variant: 'error',
            message: `Failed to update the lines${
              res.message ? ': ' + res.message + '!' : '!'
            }`,
          });
        }

        openSnack({
          variant: 'success',
          message: `Success${res.message ? ': ' + res.message + '!' : '!'}`,
        });
        //updateWOLocally(updatedLines)
        updateWOLocally(res.updated_lines, res.orig_lines);
        // exit the edit mode and update the wo lines in parent state
      })
      .catch((err) => {
        openSnack({
          variant: 'error',
          message: 'Failed to update the lines!',
        });
        // do not exit the edit mode
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <CardContent>
      {(isEditMode || isSerialMode) && (
        <>
          <Typography variant="subtitle1" color="textPrimary">
            <strong>{isSerialMode ? 'Serial No.' : ''} Edit Mode is ON</strong>
          </Typography>
          {isEditMode && (
            <Typography variant="caption" color="textSecondary">
              Maximum allowed value for quantity is 100.
            </Typography>
          )}
        </>
      )}
      <ol className={classes.linesListContainer}>
        {productLines.map((d, dIdx) => {
          let effective_pa_req_code =
            d.pa_required !== null && d.pa_required !== undefined
              ? d.pa_required
              : d.bmac_pa_required !== null && d.bmac_pa_required !== undefined
              ? d.bmac_pa_required
              : null;
          let pa_msg = 'PA requirement unknown';
          if (effective_pa_req_code !== null) {
            pa_msg = _.get(PA_REQ_CODES, effective_pa_req_code.toString());
          }

          let hcpc_msg =
            d.hcpc !== null
              ? d.hcpc
              : d.bmac_hcpc !== null
              ? d.bmac_hcpc
              : 'HCPC Unknown';

          return (
            <li
              style={{ marginTop: isSerialMode ? 12 : 0 }}
              className={classes.scheduled}
              key={dIdx + '-' + d.product_code}>
              <span>[QTY&ensp;</span>
              {isEditMode && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.smallButton}
                  disabled={d.qty_ordered <= MIN_PRODUCT_QTY || isUpdating}
                  onClick={() => handleQuantityChange(dIdx, d.qty_ordered - 1)}>
                  <SubtractIcon fontSize="inherit" />
                </Button>
              )}
              {isEditMode ? (
                <TextField
                  margin="dense"
                  type="number"
                  value={`${d.qty_ordered}`}
                  onChange={(e) => handleQuantityChange(dIdx, e.target.value)}
                  inputProps={{ min: MIN_PRODUCT_QTY, max: MAX_PRODUCT_QTY }}
                  className={classes.quantityInputField}
                  disabled={isUpdating}
                />
              ) : (
                <strong>{d.qty_ordered}</strong>
              )}
              {isEditMode && (
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  className={classes.smallButton}
                  disabled={d.qty_ordered >= MAX_PRODUCT_QTY || isUpdating}
                  onClick={() => handleQuantityChange(dIdx, d.qty_ordered + 1)}>
                  <AddIcon fontSize="inherit" />
                </Button>
              )}
              <span>]&ensp;</span>
              <span className={classes.productCode}>{d.product_code}</span>{' '}
              {d.product_desc} - [{d.wo_type_str}]
              <div style={{ fontSize: '.92em', fontWeight: 700 }}>
                {hcpc_msg} - {pa_msg}
              </div>
              {d.computed_msg && (
                <div style={{ fontSize: '.92em', fontWeight: 700 }}>
                  {d.computed_msg}
                </div>
              )}
              {d.serial_no && (
                <div style={{ fontSize: '.92em', fontWeight: 700 }}>
                  SN#: {d.serial_no}
                </div>
              )}
              {d.change_msg && (
                <div style={{ fontSize: '.92em', fontWeight: 700 }}>
                  Change Msg: {d.change_msg}
                </div>
              )}
              {isEditMode && !isSerialMode && d.isNewLine && (
                <>
                  {/* 
                rie, temporarily disabling 
                <Button
                  title={
                    noteEditingLineIdx === dIdx
                      ? d.line_note
                        ? 'Cancel note edition'
                        : 'Cancel note addition'
                      : d.line_note
                      ? 'Edit note'
                      : 'Add note'
                  }
                  size="small"
                  className={classes.smallButton}
                  onClick={() =>
                    setNoteEditingLineIdx(
                      noteEditingLineIdx === dIdx ? -1 : dIdx
                    )
                  }
                  disabled={isUpdating}>
                  {noteEditingLineIdx === dIdx ? (
                    <Icon fontSize="small">
                      <CancelNoteIcon />
                    </Icon>
                  ) : (
                    <NoteAddIcon fontSize="small" />
                  )}
                </Button>*/}

                  <Button
                    id={`menuAnchor_${dIdx}`}
                    title="Select Activity Type - Required"
                    size="small"
                    className={classes.smallButton}
                    onClick={(e) => setWoActivityTypeAnchor(e.currentTarget)}
                    disabled={isUpdating}>
                    <Badge
                      color="error"
                      classes={{ badge: classes.smallBadge }}>
                      <LocalShippingIcon fontSize="small" />
                    </Badge>
                  </Button>

                  <Menu
                    anchorEl={woActivityTypeAnchor}
                    open={
                      !!woActivityTypeAnchor &&
                      `menuAnchor_${dIdx}` === woActivityTypeAnchor.id
                    }
                    onClose={() => setWoActivityTypeAnchor(null)}
                    PaperProps={{
                      style: {
                        maxHeight: 216,
                        width: 200,
                      },
                    }}>
                    <MenuItem disabled>Select Activity Type</MenuItem>
                    {WO_ACTIVITY_TYPES.map((activityType) => (
                      <MenuItem
                        key={activityType.code}
                        selected={activityType.code === d.wo_type_code}
                        onClick={() =>
                          handleActivityTypeSelection(dIdx, activityType)
                        }>
                        {activityType.lbl}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    title="Remove"
                    size="small"
                    className={classes.smallButton}
                    onClick={() => handleNewLineRemoval(dIdx)}
                    disabled={isUpdating}>
                    <DeleteOutlinedIcon fontSize="small" color="error" />
                  </Button>

                  {/* temp disabled by rie, <Typography
                  variant="inherit"
                  color="textPrimary"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden'
                  }}
                  title={d.line_note}>
                  <strong>Note: </strong>
                  {d.line_note || (
                    <Typography variant="inherit" color="textSecondary" inline>
                      Not Added
                    </Typography>
                  )}
                  </Typography> */}

                  <Typography variant="inherit" color="textPrimary">
                    <strong>Activity Type: </strong>
                    {d.wo_type_str || d.wo_type_code || (
                      <Typography
                        variant="inherit"
                        color="textSecondary"
                        inline>
                        Not Selected
                      </Typography>
                    )}
                  </Typography>
                  {noteEditingLineIdx === dIdx && (
                    <TextField
                      autoFocus
                      fullWidth
                      margin="dense"
                      placeholder="Enter Line Note"
                      multiline
                      defaultValue={d.line_note || ''}
                      inputProps={{ maxLength: 240 }}
                      inputRef={noteFieldRef}
                      helperText={
                        <>
                          Maximum 240 characters.&ensp; Click&ensp;'
                          <DoneIcon fontSize="inherit" />
                          '&ensp;to save.
                        </>
                      }
                      InputProps={{
                        endAdornment: (
                          <Button
                            size="small"
                            className={classes.smallButton}
                            onClick={() =>
                              handleLineNoteSave(dIdx, d.prod_code)
                            }>
                            <DoneIcon fontSize="small" />
                          </Button>
                        ),
                      }}
                      disabled={isUpdating}
                    />
                  )}
                </>
              )}
              {isSerialMode && !isEditMode && (
                <>
                  {/*<Button
                  title={
                    serialNoEditingLineIdx === dIdx
                      ? 'Cancel Editing Serial No.'
                      : 'Enter Serial No.'
                  }
                  size="small"
                  className={classes.smallButton}
                  onClick={() => toggleSerialNoEditMode(dIdx)}
                  disabled={isUpdating}>
                  <NumberIcon size={20} />
                </Button>
                <Button
                  title={
                    changeMsgEditingLineIdx === dIdx
                      ? 'Cancel Line Correction Message'
                      : 'Enter Line Correction Message'
                  }
                  size="small"
                  className={classes.smallButton}
                  onClick={() => toggleChangeMsgEditMode(dIdx)}
                  disabled={isUpdating}>
                  {changeMsgEditingLineIdx === dIdx ? (
                    <CancelMessageIcon size={20} />
                  ) : (
                    <ChangeMessageIcon size={20} />
                  )}
                </Button>
*/}

                  {/* SERIAL NUMBER SECTION */}
                  <Typography
                    title={
                      serialNoEditingLineIdx === dIdx
                        ? 'Cancel Editing Serial No.'
                        : 'Enter Serial No.'
                    }
                    onClick={() => toggleSerialNoEditMode(dIdx)}
                    disabled={isUpdating}
                    style={{ marginTop: 12, cursor: 'pointer' }}
                    variant="inherit"
                    color="textPrimary">
                    <strong>
                      {serialNoEditingLineIdx === dIdx
                        ? 'Click here to cancel SN entry'
                        : 'Serial No. (click to update):'}
                    </strong>

                    {serialNoEditingLineIdx !== dIdx &&
                      (d.serial_no ? (
                        <Typography
                          variant="inherit"
                          color="textPrimary"
                          inline>
                          {d.serial_no}
                        </Typography>
                      ) : (
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          inline>
                          [none entered]
                        </Typography>
                      ))}
                  </Typography>
                  {serialNoEditingLineIdx === dIdx && (
                    <TextField
                      autoFocus
                      fullWidth
                      margin="dense"
                      placeholder={'Enter Serial No. for Line ' + (dIdx + 1)}
                      defaultValue={d.serial_no || ''}
                      inputRef={serialNoFieldRef}
                      onKeyDown={(e) => handleSerialNoSave(e, dIdx)}
                      helperText={
                        "You must press 'Enter' to add this SN for Line " +
                        (dIdx + 1)
                      }
                    />
                  )}

                  {/* CHANGE MESSAGE SECTION */}
                  <Typography
                    title={
                      changeMsgEditingLineIdx === dIdx
                        ? 'Cancel Line Correction Message Edit'
                        : 'Enter Line Correction Message'
                    }
                    onClick={() => toggleChangeMsgEditMode(dIdx)}
                    disabled={isUpdating}
                    style={{ marginTop: 12, cursor: 'pointer' }}
                    variant="inherit"
                    color="textPrimary">
                    <strong>
                      {changeMsgEditingLineIdx === dIdx
                        ? 'Click here to cancel change message entry'
                        : 'Change Msg: (click to update):'}
                    </strong>

                    {changeMsgEditingLineIdx !== dIdx &&
                      (d.change_msg ? (
                        <Typography
                          variant="inherit"
                          color="textPrimary"
                          inline>
                          {d.change_msg}
                        </Typography>
                      ) : (
                        <Typography
                          variant="inherit"
                          color="textSecondary"
                          inline>
                          [none entered]
                        </Typography>
                      ))}
                  </Typography>
                  {changeMsgEditingLineIdx === dIdx && (
                    <TextField
                      autoFocus
                      fullWidth
                      margin="dense"
                      placeholder="Enter line change message"
                      defaultValue={d.change_msg || ''}
                      inputRef={changeMsgFieldRef}
                      onKeyDown={(e) => handleChangeMsgSave(e, dIdx)}
                      helperText="You must press 'Enter' to add this note"
                    />
                  )}
                </>
              )}
            </li>
          );
        })}
      </ol>
      {allowModification && isEditMode && !isSerialMode && (
        <Grid container alignItems="flex-start">
          <Button
            title={isAddMode ? 'Cancle addition' : 'Add new line'}
            className={classes.smallButton}
            onClick={() => setIsAddMode(!isAddMode)}
            disabled={isUpdating}>
            {isAddMode ? (
              <CancelIcon fontSize="small" />
            ) : (
              <AddCircleIcon fontSize="small" />
            )}
          </Button>
          {isAddMode && (
            <SearchField
              fullWidth
              delay={500}
              minChars={3}
              resultsKey="items"
              disabled={isUpdating}
              style={{ flexGrow: 1 }}
              search={searchProducts}
              selectSuggestion={selectSuggestion}
              apiEndpoint="/api/catalog/items/list"
              scrollableParentId="woCardsContainer"
              renderSuggestion={renderProductSuggestion}
              helperText="Minimum 3 characters required"
              placeholder="Search by product code or description"
            />
          )}
        </Grid>
      )}

      {allowModification && isSerialMode && !isEditMode && (
        <TextField
          style={{
            marginTop: 16,
            paddingTop: 8,
            borderTop: '1px dashed rgb(5.5%, 20%, 50.3%)',
          }}
          fullWidth
          margin="dense"
          name="serialExtraNote"
          placeholder="Enter work order level thread comment here"
          helperText="Use this extra field when you add item(Mask, etc). The text you enter here will get added as a thread comment"
          inputRef={extraNoteFieldRef}
        />
      )}
      {allowModification && (
        <CardActions>
          {!isSerialMode && (
            <Button
              variant="contained"
              color={isEditMode ? 'secondary' : 'primary'}
              size="small"
              onClick={toggleEditMode}
              disabled={isUpdating}>
              {isEditMode ? 'Cancel' : 'Modify Line Qtys and/or Add Lines'}
            </Button>
          )}
          {!isEditMode && (
            <Button
              variant="contained"
              color={isSerialMode ? 'secondary' : 'primary'}
              size="small"
              onClick={toggleSerialMode}
              disabled={isUpdating}>
              {isSerialMode ? 'Cancel' : 'Enter Serial No. and/or Message'}
            </Button>
          )}
          {isEditMode || isSerialMode ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleLinesUpdateSubmit}
              disabled={isUpdating}>
              {isUpdating ? (
                <>
                  Saving Changes <Loader type="circular" size="15" />
                </>
              ) : (
                'Save Changes'
              )}
            </Button>
          ) : (
            ''
          )}
        </CardActions>
      )}
    </CardContent>
  );
};

class IdnWoEntry extends React.Component {
  constructor(props) {
    super(props);

    const { expanded = false } = props;

    this.state = {
      error: null,
      mednecIssueExpanded: false,
      timelineExpanded: false,
      woLinesExpanded: true,
      workOrderExpanded: expanded,
      dtrackExpanded: false,
      shipmentExpanded: false,
      toolsExpanded: false,
      notesFetching: false,
      notesLoaded: false,
      notes: [],
      activityLines: props.wo.lines.map((line, line_idx) => {
        return {
          ...line,
          line_idx,
          checked: false,
        };
      }),
    };
    this.handleTimelineExpandClick = this.handleTimelineExpandClick.bind(this);
    this.handleMednecIssueExpandClick =
      this.handleMednecIssueExpandClick.bind(this);
    this.handleWoLinesExpandClick = this.handleWoLinesExpandClick.bind(this);
    this.handleDtrackExpandClick = this.handleDtrackExpandClick.bind(this);
    this.handleShipmentExpandClick = this.handleShipmentExpandClick.bind(this);
    this.handleToolsExpandClick = this.handleToolsExpandClick.bind(this);
    this.onCreateLineFixTask = this.onCreateLineFixTask.bind(this);
    this.activityLineChecked = this.activityLineChecked.bind(this);
    this.closeTools = this.closeTools.bind(this);
    this.hasDiff = this.hasDiff.bind(this);
  }

  activityLineChecked(e) {
    let selectedLineIdx = parseInt(e.target.value, 10);
    this.setState({
      activityLines: this.state.activityLines.map((d, dIdx) => {
        if (dIdx === selectedLineIdx) {
          d.checked = !d.checked;
        }
        return d;
      }),
    });
  }
  closeTools() {
    this.setState({
      toolsExpanded: false,
      activityLines: this.props.wo.lines.map((line, line_idx) => {
        return {
          ...line,
          line_idx,
          checked: false,
        };
      }),
    });
  }

  handleToolsExpandClick() {
    this.setState({
      woLinesExpanded: true,
      toolsExpanded: !this.state.toolsExpanded,
    });
    return;
  }

  handleTimelineExpandClick() {
    this.setState({
      timelineExpanded: !this.state.timelineExpanded,
    });
    return;
  }

  handleMednecIssueExpandClick() {
    this.setState({ mednecIssueExpanded: !this.state.mednecIssueExpanded });
  }

  handleWoLinesExpandClick() {
    this.setState({ woLinesExpanded: !this.state.woLinesExpanded });
    return;
  }

  handleWorkOrderExpandClick = () => {
    return this.setState({
      workOrderExpanded: !this.state.workOrderExpanded,
    });
  };

  handleDtrackExpandClick() {
    this.setState({ dtrackExpanded: !this.state.dtrackExpanded });
    return;
  }
  handleShipmentExpandClick() {
    this.setState({ shipmentExpanded: !this.state.shipmentExpanded });
    return;
  }

  onCreateLineFixTask() {
    const { wo } = this.props;
    let todoFormParams = {
      wo_id: wo.hdms_wId,
      wo_pcr_nm: wo.pcr_nm,
      assignee: {
        by: 'hdms_id',
        hdms_id: wo.pcr_id,
        msg: 'Task will be assigned to ' + wo.pcr_nm,
      },
      lines: this.state.activityLines.filter((line) => line.checked),
    };

    this.props.onCreateLineFixTask({
      posInSet: this.props.posInSet,
      kind: 'killed_order',
      todoFormParams,
      memo:
        `Regarding Work Order #${wo.hdms_wId}\n` +
        `Released by ${wo.pcr_nm}\n\n` +
        this.state.activityLines
          .filter((line) => line.checked)
          .map((line) => {
            return [
              `Line ${line.line_idx + 1} ProdCode: ${line.product_code} - QTY ${
                line.qty_ordered
              }`,
              `Description: ${line.product_desc}`,
              `Instructions: `,
            ].join('\n');
          })
          .join('\n--------\n\n') +
        `\n--------\n\nAdditional Instructions for this WorkOrder: `,
    });

    this.closeTools();
  }

  componentDidUpdate(prevProps) {
    if (this.props.expandAllCards !== prevProps.expandAllCards) {
      return this.setState({
        workOrderExpanded: this.props.expandAllCards,
      });
    }
  }

  hasDiff() {
    const { wo } = this.props;
    if (!wo.dtrack) return false;
    if (wo.dtrack.lines.length !== wo.lines.length) return true;
  }

  render() {
    const { classes, wo } = this.props;

    let status = 'unscheduled';
    let statusTxt = 'Unscheduled';
    let dtsLbls = [];

    if (wo.req_dt) {
      status = 'requested';
      statusTxt = 'Requested';
      dtsLbls.push({ status, statusTxt: 'Request Date', val: wo.req_dt_str });
    }

    if (wo.sched_dt) {
      status = 'scheduled';
      statusTxt = 'Scheduled';
      dtsLbls.push({ status, statusTxt: 'Scheduled', val: wo.sched_dt_str });
      statusTxt = wo.effective_dt;
    }
    if (wo.cancel_dt_str) {
      status = 'cancelled';
      statusTxt = 'Cancelled in HDMS';
      dtsLbls.push({ status, statusTxt, val: wo.cancel_dt_str });
    }
    if (wo.completed_dt_str) {
      status = 'confirmed';
      statusTxt = 'Confirmed in HDMS';
      dtsLbls.push({ status, statusTxt, val: wo.completed_dt_str });
    }

    const cardHdrClasses = {
      root: classes['cardHdrRoot_' + status],
      title: classes['cardHdrContent_' + status],
      subheader: classes['cardHdrSubHdr_' + status],
    };

    let dtrack = _.get(wo, 'dtrack', null);

    let trPairs = _.get(wo, 'shipments.tr_pairs', false);
    let shipmentDetails = null;
    if (trPairs && trPairs.length) {
      shipmentDetails = (
        <>
          {trPairs.map((t, trIdx) => {
            let lines = _.get(t, 'lines', []).map((d, dIdx) => (
              <Typography
                key={d.item + ' ' + dIdx}
                className={classes.scheduled}
                color="textSecondary">
                {dIdx + 1}. [QTY {d.qty} {d.uom}] {d.item}
              </Typography>
            ));
            if (lines.length === 0) lines = '';

            if (t.tr.html_post) {
              return (
                <div
                  key={trIdx}
                  dangerouslySetInnerHTML={{ __html: t.tr.html_post }}
                />
              );
            }

            return (
              <div key={trIdx}>
                <a target={t.tr.txt} href={t.tr.url} key={trIdx}>
                  <Typography
                    key={trIdx}
                    component="span"
                    className={classes.inline}
                    color="textPrimary">
                    Tracking: {t.tr.txt}
                  </Typography>
                </a>
                {lines}
              </div>
            );
          })}
        </>
      );
    }

    const allowModification =
      wo.cancel_dt_str === null &&
      wo.completed_dt === null &&
      this.props.me &&
      (this.props.me.user.roles.includes('dispatch') ||
        this.props.me.user.roles.includes('warehouse')) &&
      DISPATCH_DELIVERY_METHOD_IDS.includes(wo.method_id) &&
      wo.dtrack &&
      DISPATCH_DELIVERY_METHOD_IDS.includes(wo.dtrack.method_id) &&
      !(wo.dtrack.trip && wo.dtrack.trip.deployed);

    const useDtrackLines =
      wo.cancel_dt_str === null &&
      wo.completed_dt === null &&
      DISPATCH_DELIVERY_METHOD_IDS.includes(wo.method_id) &&
      wo.dtrack;

    /*
    let hasDiff=false;
    if (useDtrackLines===false ){
      //determine if there's a significant diff between dtrack lines and hdms lines
      hasDiff = this.hasDiff()
    }
    */

    return (
      <Card className={classes.card}>
        <CardHeader
          classes={cardHdrClasses}
          title={
            <Grid container spacing={8} className={classes.gridContainer}>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography
                  style={{ fontSize: 12, fontWeight: 600 }}
                  className={classes['hdrText_' + status]}>
                  {'W' + wo.hdms_wId} {wo.po_num ? 'PO#:' + wo.po_num : ''}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography
                  component="span"
                  className={classes['hdrText_' + status]}>
                  {statusTxt}
                </Typography>
              </Grid>
            </Grid>
          }
          subheader={
            <Grid container spacing={8} className={classes.gridContainer}>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography
                  component="span"
                  className={classes['hdrText_' + status]}>
                  A{wo.acct_member} {wo.method_nm}
                </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography
                  component="span"
                  className={classes['hdrText_' + status]}>
                  {(wo.dc_nm || '').replace(/SUPERCARE.*/, '')}{' '}
                  {wo.sched_dt_str}
                </Typography>
              </Grid>
            </Grid>
          }
          onClick={this.handleWorkOrderExpandClick}
        />
        <Collapse
          in={this.state.workOrderExpanded || this.props.isExpanded}
          timeout="auto"
          unmountOnExit>
          <>
            <CardContent>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    className={classes['scheduled']}
                    color="textSecondary">
                    Released by {wo.pcr_nm}
                  </Typography>
                </Grid>
                {dtsLbls.map((d, dIdx) => (
                  <Grid key={dIdx} item xs={6}>
                    <Typography
                      key={dIdx + '-' + d.val}
                      className={classes[d.status]}
                      color="textSecondary">
                      {d.statusTxt}: {d.val}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} title="Dispalyed on Patient Portal as">
                <Typography className={classes['ppStatus']}>
                  {wo.pp_status || 'PP status unknown'}
                </Typography>
                {wo.s_addr1 ? (
                  <p
                    style={{ paddingBottom: 0, marginBottom: 0, fontSize: 11 }}
                    title="ShipTo on WO">
                    WO ShipTo: {wo.s_care_of} / {wo.s_addr1}
                    {wo.s_addr2 ? `, ${wo.s_addr2}` : ''}, {wo.s_city},{' '}
                    {wo.s_state} {wo.s_zip}
                  </p>
                ) : wo.a_addr1 ? (
                  <p
                    style={{ paddingBottom: 0, marginBottom: 0, fontSize: 11 }}
                    title="No ShipTo address on WO, using Account ShipTo:">
                    Account ShipTo: {wo.a_care_of} @ {wo.a_addr1}
                    {wo.a_addr2 ? `, ${wo.a_addr2}` : ''}, {wo.a_city},{' '}
                    {wo.a_state} {wo.a_zip}
                  </p>
                ) : (
                  <p
                    style={{ paddingBottom: 0, marginBottom: 0, fontSize: 11 }}
                    title="No ShipTo address on WO or Account, using BillTo:">
                    Account BillTo: {wo.b_addr1}
                    {wo.b_addr2 ? `, ${wo.b_addr2}` : ''}, {wo.b_city},{' '}
                    {wo.b_state} {wo.b_zip}
                  </p>
                )}
              </Grid>
            </CardContent>

            <Divider component="hr" />
            <CardActions
              className={classes.actions}
              disableActionSpacing
              style={{ cursor: 'pointer' }}
              onClick={this.handleWoLinesExpandClick}>
              <Button size="small" color="primary">
                {this.state.woLinesExpanded ? 'Hide' : 'Show'} {wo.lines.length}{' '}
                Line Item(s) from {useDtrackLines ? 'DeliveryTrack' : 'HDMS'}
              </Button>
            </CardActions>
            <Collapse
              in={this.state.woLinesExpanded}
              timeout="auto"
              unmountOnExit>
              {this.state.toolsExpanded === false && (
                <WOLinesContainer
                  classes={classes}
                  acctMember={wo.acct_member}
                  wo_id={wo.hdms_wId}
                  lines={
                    useDtrackLines
                      ? _.get(wo, ['dtrack', 'lines'], [])
                      : wo.lines || []
                  }
                  orig_lines={
                    useDtrackLines
                      ? _.get(
                          wo,
                          ['dtrack', 'orig_lines'],
                          _.get(wo, ['dtrack', 'lines'], [])
                        )
                      : wo.lines || []
                  }
                  allowModification={allowModification}
                  openSnack={this.props.open_snack_ac}
                  updateWOLocally={(lines, orig_lines) =>
                    this.props.updateWOLineItemsLocally_ac(
                      this.props.posInSet,
                      lines,
                      orig_lines
                    )
                  }
                />
              )}

              {this.state.toolsExpanded && (
                <CardActions>
                  <FormControl
                    required
                    component="fieldset"
                    className={classes.formControl}>
                    <FormGroup>
                      {this.state.activityLines.map((d, dIdx) => (
                        <FormControlLabel
                          key={dIdx}
                          control={
                            <Checkbox
                              style={{ padding: 2 }}
                              onChange={this.activityLineChecked}
                              checked={d.checked}
                              value={dIdx.toString()}
                            />
                          }
                          label={
                            <Typography
                              key={dIdx + '-' + d.product_code}
                              className={classes.scheduled}
                              color="textSecondary">
                              {dIdx + 1}. [QTY {d.qty_ordered}]{' '}
                              <span className={classes.productCode}>
                                {d.product_code}
                              </span>{' '}
                              {d.product_desc}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </CardActions>
              )}
            </Collapse>

            {shipmentDetails && (
              <>
                <Divider component="hr" />
                <CardActions
                  className={classes.actions}
                  disableActionSpacing
                  onClick={this.handleShipmentExpandClick}>
                  <Button size="small" color="primary">
                    {this.state.shipmentExpanded ? 'Hide' : 'Show'} Shipment
                    Tracking
                  </Button>
                </CardActions>
                <Collapse
                  in={this.state.shipmentExpanded}
                  timeout="auto"
                  unmountOnExit>
                  <CardContent>{shipmentDetails}</CardContent>
                </Collapse>
              </>
            )}

            {wo.dtrack && (
              <>
                <Divider component="hr" />
                <CardActions
                  className={classes.actions}
                  disableActionSpacing
                  onClick={this.handleDtrackExpandClick}>
                  <Button size="small" color="primary">
                    {this.state.dtrackExpanded ? 'Hide' : 'Show'} DeliveryTrack
                    Status
                  </Button>
                </CardActions>
                <Collapse
                  in={this.state.dtrackExpanded}
                  timeout="auto"
                  unmountOnExit>
                  <DtrackDetails classes={classes} dtrack={dtrack} />
                </Collapse>
              </>
            )}

            <>
              <Divider component="hr" />
              <CardActions
                className={classes.actions}
                disableActionSpacing
                onClick={this.handleMednecIssueExpandClick}>
                <Button size="small" color="primary">
                  {this.state.mednecIssueExpanded ? 'Hide' : 'Show'} MEDNEC
                  ISSUE LOG{' '}
                  {wo.mednec && `(${wo.mednec.mednec_issue_log.length})`}
                </Button>
              </CardActions>
              <Collapse
                in={this.state.mednecIssueExpanded}
                timeout="auto"
                unmountOnExit>
                {wo.mednec &&
                  wo.mednec.mednec_issue_log.map((iEntry, iK) => (
                    <MednecIssueLogView key={iK} issue={iEntry} />
                  ))}

                <MednecIssueLogEntryForm
                  wo={wo}
                  wo_id={wo.hdms_wId}
                  account={wo.account}
                  fromIdnWoEntry
                  closeMednecLog={this.handleMednecIssueExpandClick}
                />
              </Collapse>
            </>
            {/* Create a Divider, CardActions, and Collapse for the OrderStatus component below */}
            <>
              <Divider component="hr" />
              <CardActions
                className={classes.actions}
                disableActionSpacing
                onClick={this.handleTimelineExpandClick}>
                <Button size="small" color="primary">
                  {this.state.timelineExpanded ? 'Hide' : 'Show'} Order Status
                  Timeline
                </Button>
              </CardActions>
              <Collapse
                in={this.state.timelineExpanded}
                timeout="auto"
                unmountOnExit>
                <OrderStatus workunitId={'W' + wo.hdms_wId} />
              </Collapse>
            </>

            {!this.props.fromWorkOrder && !this.props.readOnly && (
              <>
                <Divider component="hr" />
                <CardActions
                  className={classes.actions}
                  disableActionSpacing
                  onClick={this.handleToolsExpandClick}>
                  <Button size="small" color="primary">
                    {this.state.toolsExpanded ? 'Hide' : 'Show'} Tools
                  </Button>
                </CardActions>
                <Collapse
                  in={this.state.toolsExpanded}
                  timeout="auto"
                  unmountOnExit>
                  <CardContent>
                    <Typography
                      component="span"
                      className={classes.inline}
                      color="textPrimary">
                      Select lines involved with the task.
                    </Typography>
                    <Button
                      size="medium"
                      disabled={
                        this.state.activityLines.filter((line) => line.checked)
                          .length === 0
                      }
                      color="primary"
                      variant="outlined"
                      onClick={this.onCreateLineFixTask}>
                      Populate [WO Line Fix] Task Template
                    </Button>
                  </CardContent>
                </Collapse>
              </>
            )}
          </>
        </Collapse>
      </Card>
    );
  }
}

IdnWoEntry.propTypes = {
  classes: PropTypes.object.isRequired,
  wo: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
});

const mapDispatchToProps = (dispatch, propsOfIdnWoEntry) =>
  bindActionCreators(
    {
      open_snack_ac,
      updateWOLineItemsLocally_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IdnWoEntry))
);

//export default withStyles(styles)(IdnWoEntry);
