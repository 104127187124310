import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Button, Typography } from '@material-ui/core';
import DraggableGridRow from './DraggableGridRow';
import { withStyles } from '@material-ui/styles';
import {
  close_confirmation_dialog_ac,
  open_confirmation_dialog_ac,
} from '../../actions/dialog.ac';
import { open_snack_ac } from '../../actions/snack.ac';
import RuleTester from './RuleTester';
import { createACL, deleteACL, fetchACL, updateACL } from './api';
import Loader from '../Shared/Loader';

const styles = () => ({
  headerLabel: {
    textAlign: 'center',
    fontSize: 14,
    marginBottom: 4,
  },
  textFieldContainer: {
    padding: '0px 12px',
    textAlign: 'center',
  },
});

const DraggableGrid = (props) => {
  const { classes } = props;
  const [origRows, setOrigRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchACL({
      before: () => setFetching(true),
      after: () => setFetching(false),
      onSuccess: (rawRules) => {
        const rules = rawRules.map((rule) => ({
          ...rule,
          changed: false,
          id: rule._id,
        }));
        setOrigRows(_.cloneDeep(rules));
        setRows(_.cloneDeep(rules));
      },
      onError: (e) => {
        props.openSnack({
          message: e.message || 'Failed to fetch RBAC rules!',
          variant: 'error',
        });
      },
    });
  }, []);

  const handleCellChange = (id, field, value) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, changed: true, [field]: value } : row
    );
    setRows(updatedRows);
  };

  const addRow = () => {
    const maxPrecedence = Math.max(...rows.map((row) => row.precedence));
    const newRow = {
      _id: null,
      id: Date.now(),
      changed: true,
      precedence: maxPrecedence + 10,
      role: '',
      route: '',
      action: 'allow',
    };
    setRows([...rows, newRow]);
  };

  const sortList = () => {
    const sortedRows = _.sortBy(rows, ['precedence']);

    setRows(sortedRows);
  };

  // Sort based on precedence and check for duplicate precedence
  const validateList = () => {
    const precedenceMap = {};
    const roleMap = {};
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.precedence in precedenceMap) {
        props.openSnack({
          message: `Duplicate precedence ${row.precedence} at row ${i + 1}`,
          variant: 'error',
        });

        return false;
      }

      const roleSlug = `${row.role}-${row.route}`;

      if (roleSlug in roleMap) {
        props.openSnack({
          message: `Duplicate role ${row.role} and route ${row.route} at row ${
            i + 1
          }`,
          variant: 'error',
        });

        return false;
      }

      // Potential more validation here.

      precedenceMap[row.precedence] = true;
      roleMap[roleSlug] = true;
    }

    sortList();
    return true;
  };

  const resetList = (e) => {
    const shift = e.shiftKey;

    if (!shift) {
      const newOnes = rows.filter((row) => !row._id);

      setRows(_.sortBy([..._.cloneDeep(origRows), ...newOnes], ['precedence']));
    } else {
      setRows(_.cloneDeep(origRows));
    }
  };

  const _createRow = (row, callback) => {
    createACL(row, {
      onSuccess: (newRow) => {
        props.openSnack({
          message: `Rule: ${newRow.alias || newRow._id} created successfully!`,
          variant: 'success',
        });
        setRows(
          _.sortBy(
            rows.map((r) =>
              r.id === row.id
                ? {
                    ...r,
                    _id: newRow._id,
                    changed: false,
                  }
                : r
            ),
            ['precedence']
          )
        );
        setOrigRows(
          // Keep track of original rows
          _.sortBy(
            [
              ...origRows,
              {
                ...row,
                _id: newRow._id,
                changed: false,
              },
            ],
            ['precedence']
          )
        );
        callback();
      },
      onError: (e) => {
        props.openSnack({
          message: e.message || 'Failed to create RBAC rule!',
          variant: 'error',
        });
      },
    });
  };

  const _updateRow = (_id, row, callback) => {
    updateACL(_id, row, {
      before: () => setProcessing(true),
      after: () => setProcessing(false),
      onSuccess: () => {
        props.openSnack({
          message: `Rule: ${row.alias || row._id} updated successfully!`,
          variant: 'success',
        });
        setOrigRows(
          _.sortBy(
            origRows.map((r) =>
              r.id === row.id
                ? {
                    ...r,
                    ...row,
                    changed: false,
                  }
                : r
            ),
            ['precedence']
          )
        );
        setRows(
          _.sortBy(
            rows.map((r) =>
              r.id === row.id
                ? {
                    ...r,
                    changed: false,
                  }
                : r
            ),
            ['precedence']
          )
        );
        callback();
      },
      onError: (e) => {
        props.openSnack({
          message: e.message || 'Failed to update RBAC rule!',
          variant: 'error',
        });
      },
    });
  };

  const handleSave = (toSaveRowOrig, callback) => {
    const toSaveRow = _.cloneDeep(toSaveRowOrig);
    const result = validateList();

    if (!result) {
      return;
    }

    if (!toSaveRow.changed) {
      callback();
      return;
    }

    toSaveRow.actionKind = toSaveRow.action; // API requires different name
    delete toSaveRow.action;
    delete toSaveRow.changed;

    if (toSaveRow._id) {
      _updateRow(toSaveRow._id, toSaveRow, callback);
    } else {
      _createRow(toSaveRow, callback);
    }
  };

  const _confirmDelete = (row) => {
    if (!row._id) {
      setRows(rows.filter((r) => r.id !== row.id));
      return;
    }

    deleteACL(row._id, {
      before: () => setProcessing(true),
      after: () => setProcessing(false),
      onSuccess: () => {
        props.openSnack({
          message: `Rule: ${row.alias || row._id} deleted successfully!`,
          variant: 'success',
        });
        setRows(rows.filter((r) => r.id !== row.id));
        setOrigRows(origRows.filter((r) => r.id !== row.id));
      },
      onError: (e) => {
        props.openSnack({
          message: e.message || 'Failed to delete rule!',
          variant: 'error',
        });
      },
    });
  };

  const handleDelete = (row) => {
    if (row._id) {
      props.openConfirmationDialog({
        title: `Are you sure about deleting rule: ${row.role}:${row.route}:${row.action}?`,
        content: `The action cannot be undone!`,
        actions: [
          {
            label: 'Yes, Delete',
            action: () => {
              _confirmDelete(row);
              props.closeConfirmationDialog();
              return true;
            },
          },
        ],
      });

      return;
    }

    _confirmDelete(row);
  };

  return (
    <div style={{}}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          // alignItems: 'center',
        }}>
        <Grid
          container
          spacing={2}
          style={{
            padding: '20px',
            marginTop: '10px',
            width: '70%',
          }}>
          {/* Header Row */}
          <Grid item xs={1}>
            <Typography className={classes.headerLabel}>Precedence</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.headerLabel}>Alias</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.headerLabel}>Role</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.headerLabel}>Route</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.headerLabel}>Action</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.headerLabel}>Controls</Typography>
          </Grid>

          <div
            style={{
              width: '100%',
              border: '1px solid lightgray',
              height: '70vh',
              overflow: 'auto',
              padding: '12px',
            }}>
            {fetching ? (
              <Loader type="circular" />
            ) : rows.length ? (
              rows.map((row, index) => (
                <DraggableGridRow
                  classes={classes}
                  disabled={processing}
                  key={row.id}
                  row={row}
                  onSave={handleSave}
                  onDelete={handleDelete}
                  index={index}
                  handleCellChange={handleCellChange}
                />
              ))
            ) : (
              <Typography
                style={{
                  fontSize: 14,
                  color: 'gray',
                  textAlign: 'center',
                  paddingTop: '20px',
                }}>
                No rules found
              </Typography>
            )}
          </div>
        </Grid>

        <Grid
          container
          style={{
            padding: '20px',
            marginTop: '54px',
            height: '70vh',
            width: '30%',
            border: '1px solid lightgray',
          }}>
          <RuleTester openSnack={props.openSnack} />
        </Grid>
      </div>
      <div
        style={{
          marginTop: '10px',
          paddingLeft: '20px',
          width: '95%',
        }}>
        <Button
          onClick={resetList}
          disabled={processing || fetching}
          style={{
            marginRight: '10px',
            border: '1px solid red',
          }}
          color="primary"
          variant="outlined">
          Reset
        </Button>
        <Button
          onClick={validateList}
          disabled={processing || fetching}
          color="primary"
          variant="outlined">
          Validate List
        </Button>
        <Button
          onClick={addRow}
          color="primary"
          disabled={processing || fetching}
          variant="contained"
          style={{ marginLeft: '10px' }}>
          Add New Rule
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      openSnack: open_snack_ac,
      openConfirmationDialog: open_confirmation_dialog_ac,
      closeConfirmationDialog: close_confirmation_dialog_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DraggableGrid));
