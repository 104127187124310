import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
import FetchingStatementWrap from '../FetchingStatementWrap';

const theme = createMuiTheme({});
const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {
    backgroundColor: 'red',
    color: '#fff',
  },
  lbl: {
    fontSize: 12,
    color: '#9c27b0',
  },
  formControl: {
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row',
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
});

class IdnCreateLanding extends React.Component {
  constructor(props) {
    super(props);
    this.validate = this.validate.bind(this);
    this.state = {
      isFetching: false,
      isSubmitting: false,
      proposedIdn: null,
      err: null,
      msg: null,
      createSuccess: null,
      attachSuccess: null,
      valid: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onAttach = this.onAttach.bind(this);
    this.reset = this.reset.bind(this);
    this.gotoCurrentIdn = this.gotoCurrentIdn.bind(this);
    this.gotoProposedIdn = this.gotoProposedIdn.bind(this);
  }

  reset() {
    this.setState({
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null,
      valid: false,
      isSubmitting: false,
      createSuccess: null,
    });
  }

  validate() {
    let invalid_fields = _.keys(this.state.ticket)
      .filter((f) => f !== 'addr_2')
      .filter((f) => {
        return this.state.ticket[f] === -1 || this.state.ticket[f] === '';
      });

    return invalid_fields.length === 0;
  }

  onChange = (field) => (e) => {
    this.setState({
      ticket: {
        ...this.state.ticket,
        [field]: e.target.value,
      },
      valid: this.validate(),
    });
  };

  onSubmit() {
    const { match } = this.props;
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });

    this.setState({
      isSubmitting: true,
    });
    return window.sch
      .post('/api/idn:create', {
        acctMember: match.params.acctMember,
        create: this.state.proposedIdn._id,
      })
      .then((res) => {
        this.setState({
          isSubmitting: false,
          ...res.pkg,
        });
      })
      .catch(() => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed idn creation',
        });
      });
  }

  onAttach() {
    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });

    this.setState({
      isSubmitting: true,
    });
    return window.sch
      .post('/api/idn:attachaccount', {
        acctMember: this.state.proposedIdn.aa[0],
        existing_idn: this.state.proposedIdn._id,
      })
      .then((res) => {
        this.setState({
          isSubmitting: false,
          ...res.pkg,
        });
      })
      .catch(() => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Failed idn creation',
        });
      });
  }

  gotoProposedIdn() {
    this.props.changePage(
      `/dfa/idn/${this.state.proposedIdn._id.replace(
        / /g,
        '_'
      )}/tabs/account_info`
    );
  }
  gotoCurrentIdn() {
    this.props.changePage(
      `/dfa/idn/${this.state.currentIdn._id.replace(
        / /g,
        '_'
      )}/tabs/account_info`
    );
  }

  componentDidMount() {
    this.checkSituation();
  }

  componentDidUpdate() {
    this.checkSituation();
  }
  checkSituation() {
    const { match } = this.props;
    if (undefined === match.params.acctMember) return;
    if (
      this.state.isFetching ||
      this.state.isSubmitting ||
      (this.state.pkg !== null &&
        this.state.lastIdnChecked === match.params.acctMember)
    ) {
      return;
    }

    this.setState({
      isFetching: true,
      lastIdnChecked: match.params.acctMember,
    });
    return window.sch
      .post('/api/idn:create', {
        acctMember: match.params.acctMember,
      })
      .then((res) => {
        this.setState({
          isFetching: false,
          ...res.pkg,
        });
      })
      .catch(() => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Could not establish DelieryTrack session - contact IT',
        });
      });
  }

  render() {
    const { classes, match } = this.props;
    if (
      this.state.isFetching ||
      this.state.isSubmitting ||
      this.state.proposedIdn === null ||
      this.state.lastIdnChecked !== match.params.acctMember
    ) {
      return (
        <FetchingStatementWrap title={'IDN Creation'} msg={'...loading page'} />
      );
    }

    return (
      <main className={classes.content}>
        <div className={classes.scrollArea}>
          <Grid container direction="row" spacing={8}>
            {this.state.currentIdn && (
              <Grid item xs={12}>
                <div className={classes.lbl}>
                  There is already a DocFlow Patient shell for this account:
                </div>

                <div
                  style={{
                    padding: '12px 0',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}>
                  Existing DocFlow Patient Record: {this.state.currentIdn._id}-
                  Account(s): {this.state.currentIdn.aa.join(', ')}
                </div>

                <div
                  style={{
                    padding: '12px 0',
                    fontSize: 12,
                    fontWeight: 'bold',
                  }}>
                  Proposed DocFlow Patient Identifier:{' '}
                  {this.state.proposedIdn._id}- Account(s):{' '}
                  {this.state.proposedIdn.aa.join(', ')}
                </div>

                <div className={classes.lbl}>
                  The Proposed identifier is based on the HDMS Account's
                  LastName, FirstName and DateOfBirth fields.
                </div>

                {this.state.msg && (
                  <div
                    style={{
                      color: 'red',
                      padding: '12px 0',
                      fontSize: 12,
                      fontWeight: 'bold',
                    }}>
                    {this.state.msg}
                  </div>
                )}

                <div className={classes.lbl}>
                  Use the ACCOUNTS tab on that shell to fix the account mapping:
                </div>

                <div>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 12, fontSize: 12, color: 'blue' }}
                    onClick={this.gotoCurrentIdn}>
                    Go to {this.state.currentIdn._id}
                  </Button>
                </div>
              </Grid>
            )}

            {null === this.state.currentIdn && (
              <Grid item xs={12} md={4}>
                <FormControl
                  fullWidth
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <FormGroup>
                    <TextField
                      id="acct-search-entry"
                      label={'DOCFLOW PATIENT IDENTIFIER'}
                      style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                      value={this.state.proposedIdn._id}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      id="acct-search-entry"
                      label={'ACCOUNT-MEMBER'}
                      style={{ fontSize: 10, margin: '8px 0 8px 0' }}
                      value={this.state.proposedIdn.aa.join(' ')}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>
                </FormControl>
                <div className={classes.lbl}>{this.state.msg}</div>
                <FormControl
                  style={{ marginTop: 24 }}
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <Button
                    variant="contained"
                    disabled={
                      this.state.currentIdn !== null ||
                      this.state.createSuccess ||
                      this.state.attachSuccess ||
                      this.state.isSubmitting ||
                      null === this.state.proposedIdn
                    }
                    color="primary"
                    size="medium"
                    onClick={this.onSubmit}>
                    Create New DocFlow Patient Shell [
                    {this.state.proposedIdn._id}]
                  </Button>
                </FormControl>
                <FormControl
                  style={{ marginTop: 24 }}
                  required
                  component="fieldset"
                  className={classes.formControl}>
                  <Button
                    variant="contained"
                    disabled={
                      this.state.currentIdn !== null ||
                      this.state.createSuccess ||
                      this.state.attachSuccess ||
                      this.state.isSubmitting ||
                      null === this.state.proposedIdn
                    }
                    color="primary"
                    size="medium"
                    onClick={this.onAttach}>
                    Attach {this.state.proposedIdn.aa.join(' ')} to existing DF
                    Shell [{this.state.proposedIdn._id}]
                  </Button>
                </FormControl>
              </Grid>
            )}
            {this.state.createSuccess && (
              <Grid item xs={12}>
                <div style={{ marginTop: 24 }}>
                  <h5>DocFlow Patient Shell CREATED</h5>
                  <h5>
                    {this.state.proposedIdn._id} - AccountMember:{' '}
                    {this.state.proposedIdn.aa.join(' ')}
                  </h5>
                  <button
                    style={{
                      fontSize: 12,
                      color: 'blue',
                      outline: 'unset',
                      border: 'unset',
                      backgroundColor: 'unset',
                    }}
                    onClick={this.gotoProposedIdn}>
                    Go to Patient Landing Page
                  </button>
                </div>
              </Grid>
            )}

            {this.state.attachSuccess && (
              <Grid item xs={12}>
                <div style={{ marginTop: 24 }}>
                  <h5>Account attached (added) to DocFlow Patient Shell </h5>
                  <h5>
                    {this.state.proposedIdn._id} - AccountMember(s):{' '}
                    {this.state.updatedIdn.aa.join(', ')}
                  </h5>
                  <button
                    style={{
                      fontSize: 12,
                      color: 'blue',
                      outline: 'unset',
                      border: 'unset',
                      backgroundColor: 'unset',
                    }}
                    onClick={this.gotoProposedIdn}>
                    Go to Patient Landing Page
                  </button>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </main>
    );
  }
}

IdnCreateLanding.propTypes = { classes: PropTypes.object.isRequired };

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      close_snack_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles(theme))(IdnCreateLanding))
);
