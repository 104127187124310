import { actionTypes } from '../actions/dialog.ac'

const defaultConfirmationDialogState = {
  open: false,
  title: '',
  content: '',
  actions: [],
}

export function confirmationDialog(
  state = defaultConfirmationDialogState,
  action
) {
  switch (action.type) {
    case actionTypes.OPEN_CONFIRMATION_DIALOG:
      return {
        ...state,
        open: true,
        ...action.params
      }
    case actionTypes.CLOSE_CONFIRMATION_DIALOG:
      return {
        ...state,
        open: false
      }
    default:
      return state
  }
}
