import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import * as pdfGen from '../../actions/pdfGen.ac'

const styles = theme => ({
  empty: {
    color: '#fff'
  },
  nonEmpty: {
    color: '#00e5ff'
  },
  inProgress: {
    color: '#ffc107'
  },
  success: {
    color: '#76ff03'
  },
  error: {
    color: '#d50000'
  }
})

class PDFGenIcon extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let classes = this.props.classes

    let color = classes.empty
    if (this.props.pdfGen.convert.inProgress) color = classes.inProgress
    else if (this.props.pdfGen.convert.error) color = classes.error
    else if (this.props.pdfGen.convert.result) color = classes.success
    else if (this.props.pdfGen.list.length > 0) color = classes.nonEmpty

    return (
      <IconButton onClick={this.props.pdfGenToggleDrawer}>
        <Icon className={color}>picture_as_pdf</Icon>
      </IconButton>
    )
  }
}

const mapStateToProps = state => state

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pdfGenToggleDrawer: pdfGen.toggleDrawer
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PDFGenIcon))
)
