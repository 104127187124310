export function paErrorsList(
  state = {
    kind: 'initials',
    isFetching: false,
    payerGroup: null,
    records: [],
    stats: null,

    activityUpdating: false,
    posOfActivity: 0
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_PA_ERRORS_LIST':
      return Object.assign({}, state, {
        payerGroup: action.payerGroup,
        kind: action.kind,
        isFetching: true
      })

    case 'RECEIVED_PA_ERRORS_LIST':
      return Object.assign({}, state, {
        isFetching: false,
        records: action.records,
        stats: action.stats
      })
    case 'FAILED_RECEIVED_PA_ERRORS_LIST':
      return Object.assign({}, state, {
        isFetching: false,
        records: [],
        error: action.error
      })

    case 'REQUEST_PA_ERR_STATS':
      return Object.assign({}, state, {
        payerGroup: action.payerGroup,
        kind: action.kind
      })

    case 'RECEIVED_PA_ERR_STATS':
      return Object.assign({}, state, {
        isFetching: false,
        stats: action.stats
      })
    case 'FAILED_RECEIVED_PA_ERR_STATS':
      return Object.assign({}, state, {
        isFetching: false,
        stats: null,
        error: action.error
      })

    case 'REQUEST_CLAIM_ACTIVITY':
      return Object.assign({}, state, {
        posOfActivity: action.posOfActivity,
        activityUpdating: true
      })
    case 'RECEIVED_CLAIM_ACTIVITY':
      return Object.assign({}, state, {
        posOfActivity: action.posOfActivity,
        activityUpdating: false
      })
    case 'FAILED_RECEIVED_CLAIM_ACTIVITY':
      return Object.assign({}, state, {
        posOfActivity: action.posOfActivity,
        activityUpdating: false,
        error: action.error
      })

    case 'REQUEST_CLAIM_LINE_ACTIVITY':
      return Object.assign({}, state, {
        posOfActivity: action.posOfActivity,
        activityUpdating: true
      })
    case 'RECEIVED_CLAIM_LINE_ACTIVITY':
      return Object.assign({}, state, {
        posOfActivity: action.posOfActivity,
        activityUpdating: false
      })
    case 'FAILED_RECEIVED_CLAIM_LINE_ACTIVITY':
      return Object.assign({}, state, {
        posOfActivity: action.posOfActivity,
        activityUpdating: false,
        error: action.error
      })

    case 'VALIDATE_CLAIM_LINE_FIX':
      return Object.assign({}, state, {
        isValidating: true
      })
    case 'RESPONSE_VALIDATE_CLAIM_LINE_FIX':
      return Object.assign({}, state, {
        records: action.records,
        isValidating: false
      })
    case 'FAILED_VALIDATE_CLAIM_LINE_FIX':
      return Object.assign({}, state, {
        isValidating: false,
        error: action.error
      })

    default:
      return state
  }
}

export function claimInView(
  state = { isFetching: false, claim: null },
  action
) {
  switch (action.type) {
    case 'REQUEST_CLAIM':
      return Object.assign({}, state, {
        isFetching: true,
        claimId: action.claimId
      })

    case 'RECEIVED_CLAIM':
      return Object.assign({}, state, {
        isFetching: false,
        claimDoc: action.claimDoc
      })
    case 'FAILED_RECEIVED_CLAIM':
      return Object.assign({}, state, {
        isFetching: false,
        claimDoc: null,
        error: action.error
      })
    default:
      return state
  }
}
