import _ from 'lodash';

export function fetchIdnWos_ac(idn, hdmsAccts) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_IDN_WOS', idn });
    let target_url = `/api/hdms:recentwos:prime`;
    let postData = { idn, hdmsAccts };
    let pkg = {};
    return window.sch
      .post(target_url, postData)
      .then((wosPkg) => {
        pkg = { ...wosPkg };

        let target_url = '/api/hdms:acct:prime';
        let postData = { idn, hdmsAccts };
        return window.sch.post(target_url, postData);
      })
      .then((acctPkg) => {
        pkg = { ...pkg, accounts: acctPkg.rr };

        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        dispatch({ type: 'RECEIVED_IDN_WOS', pkg: pkg });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_RECEIVED_IDN_WOS', error });
      });
  };
}

export function selectIdnWosForToolsActivity_ac(params) {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'UPDATE_IDN_WOS_ACTIVITY_PKG', activityPkg: params });
  };
}
export function clearIdnWosForToolsActivity_ac() {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'CLEAR_IDN_WOS_ACTIVITY_PKG', activityPkg: null });
  };
}

export const updateWOLineItemsLocally_ac = (woPostInSet, lines, orig_lines) => {
  return (dispatch, getState) => {
    let pkg = _.cloneDeep(getState().idnWos.pkg);

    if (!_.get(pkg, ['wos', woPostInSet, 'dtrack', 'lines'], false)) return;

    pkg.wos[woPostInSet].dtrack.lines = _.cloneDeep(lines);
    pkg.wos[woPostInSet].dtrack.orig_lines = _.cloneDeep(orig_lines);

    dispatch({
      type: 'UPDATE_IDN_WOS_PKG',
      pkg,
    });
  };
};
