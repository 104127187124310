import { Collapse } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateTodo_ac } from '../../actions/todos.ac.js';
import UserSearch from '../Users/UserSearch';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  linkTo: {
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      color: '#000',
      fontWeight: 500,
    },
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
    backgroundColor: '#FBFCFF',
    border: '1px solid #1abc9c',
  },
  woIdToAttach: {
    paddingBottom: 8,
    marginBottom: 8,
  },
  cardHdrRoot: {
    padding: '8px 8px 8px 16px',
    cursor: 'pointer',
  },
  cardHdrSubHdr: {
    fontSize: '.72em',
    fontWeight: 500,
    color: '#000',
  },
  cardHdrContent: {
    fontSize: '.82em',
    fontWeight: 800,
  },

  cardHdrRootPending: {
    cursor: 'pointer',
    backgroundColor: 'orange',
    padding: '8px 8px 8px 16px',
  },
  cardHdrSubHdrPending: {
    fontSize: '.72em',
    fontWeight: 500,
    color: '#000',
  },
  cardHdrContentPending: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#000',
  },

  cardHdrRootClosed: {
    cursor: 'pointer',
    backgroundColor: '#4db6ac',
    padding: '8px 8px 8px 16px',
  },
  cardHdrSubHdrClosed: {
    fontSize: '.72em',
    fontWeight: 500,
    color: '#fff',
  },
  cardHdrContentClosed: {
    fontSize: '.82em',
    fontWeight: 800,
    color: '#fff',
  },

  cardContentRoot: {
    paddingBottom: 0,
  },

  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  tagChip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    backgroundColor: 'rgba(187, 239, 46, 0.93)',
  },

  panelBtn: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    paddingTop: 2,
    paddingBottom: 0,
  },

  panelRoot: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'start',
  },

  btnContainer: {
    marginTop: 12,
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  radioGroupRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  radioGroupRowItem: {
    //border: '1px solid #000'
  },
  checkboxGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  checkboxGroupRowItem: {
    flex: '1 180px',
    //border: '1px solid #000',
    marginLeft: 0,
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    marginLeft: 4,
    color: '#244e91',
    fontSize: '.98em',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  callOutIcon: {
    backgroundColor: '#ffb74d',
  },
  avatar: {
    backgroundColor: 'blue',
  },
  mData: {
    color: '#232225',
    fontSize: 12,
    fontWeight: 400,
  },
  listRoot: {
    paddingTop: 0,
  },
  list: {
    width: '100%',
    maxWidth: 360,
  },
  listItem: {
    paddingLeft: 0,
  },
  listItemParaPre: {
    padding: 6,
    marginTop: 0,
    fontSize: 11,
    borderRadius: 4,
    margin: '4px 4px 4px 0px',
    color: '#000',
    backgroundColor: 'rgba(233, 236, 241, 0.49)',
  },
  efPara: {
    padding: 6,
    marginTop: 0,
    fontSize: 12,
    borderRadius: 4,
    margin: '4px 4px 4px 0px',
    color: '#000',
    backgroundColor: '#efefff',
  },
});

class TodoCard extends React.Component {
  constructor(props) {
    super(props);
    let _state = {
      error: null,
      tmp_helpers: [],
      recentWosPanelExpanded: false,
      assignmentPanelExpanded: false,
      actionPanelExpanded: false,
      commentsPanelExpanded: false,
      logPanelExpanded: false,
      selectedTodoState: null,
      checkedTags: new Set(),
      stateFieldValues: {},
      comment: '',
      isFetchingRecentWos: false,
      recentWosFetched: false,
      recentWos: [],
    };
    this.state = _state;
    this.getLabel = this.getLabel.bind(this);

    this.addHelper = this.addHelper.bind(this);
    this.removeHelper = this.removeHelper.bind(this);
    this.changeAssignee = this.changeAssignee.bind(this);

    this.handleTaskActionToggle = this.handleTaskActionToggle.bind(this);
    this.handleLogPanelToggle = this.handleLogPanelToggle.bind(this);
    this.todoStateSelected = this.todoStateSelected.bind(this);
    this.handleTagCheckbox = this.handleTagCheckbox.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
    this.handleCommentInput = this.handleCommentInput.bind(this);
    this.handleCommentsToggle = this.handleCommentsToggle.bind(this);
    this.handleHelpersToggle = this.handleHelpersToggle.bind(this);
    this.handleAssignmentToggle = this.handleAssignmentToggle.bind(this);
    this.takeOwnership = this.takeOwnership.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.fetchRecentWos = this.fetchRecentWos.bind(this);
    this.handleRecentWosToggle = this.handleRecentWosToggle.bind(this);
    this.attachWorkOrder = this.attachWorkOrder.bind(this);
    this.onStateTextFieldChanged = this.onStateTextFieldChanged.bind(this);
    this.onStateCheckboxChanged = this.onStateCheckboxChanged.bind(this);
  }

  toggleExpand() {
    if (this.props.doNotAllowCollapse) return;

    this.props.onLocalUpdate(this.props.posInSet, {
      ...this.props.todoDoc,
      expanded: !this.props.expanded,
    });
  }

  todoStateSelected(evt) {
    this.setState({
      selectedTodoState: evt.target.value,
      checkedTags: new Set(),
      stateFieldValues: {},
    });
  }

  fetchRecentWos() {
    const { todoDoc } = this.props;
    this.setState({ isFetchingRecentWos: true });

    let postData = {
      idn: todoDoc.idn,
      hdmsAccts: ['A' + todoDoc.account + '-' + todoDoc.member],
    };

    if (todoDoc.code.search(/^PICK UP/) !== -1) {
      postData.exclude_shipment_methods = true;
    }

    return window.sch
      .post(`/api/hdms:recentwos:prime`, postData)
      .then((result) => {
        this.setState({
          recentWos: result.wos,
          isFetchingRecentWos: false,
          recentWosFetched: true,
        });
      })
      .catch((error) => {});
  }

  handleTagCheckbox = (stateRef) => (evt) => {
    if (evt.target.checked === true) {
      if (stateRef.maxTags) {
        if (this.state.checkedTags.size + 1 > stateRef.maxTags) {
          return;
        }
      }
    }
    let _checkedTags = new Set(this.state.checkedTags);
    evt.target.checked
      ? _checkedTags.add(evt.target.value)
      : _checkedTags.delete(evt.target.value);
    this.setState({
      checkedTags: _checkedTags,
    });
  };

  onStateTextFieldChanged = (stateField) => (e) => {
    this.setState({
      stateFieldValues: {
        ...this.state.stateFieldValues,
        [stateField.nm]: e.target.value,
      },
    });
  };

  onStateCheckboxChanged = (stateRef, stateField) => (evt) => {
    if (evt.target.checked === true) {
      if (stateField.max) {
        if (this.state.stateFieldValues[stateField.nm]) {
          if (
            this.state.stateFieldValues[stateField.nm].size + 1 >
            stateField.max
          ) {
            return;
          }
        }
      }
    }

    let _checked = this.state.stateFieldValues[stateField.nm]
      ? new Set(this.state.stateFieldValues[stateField.nm])
      : new Set();

    evt.target.checked
      ? _checked.add(evt.target.value)
      : _checked.delete(evt.target.value);
    this.setState({
      stateFieldValues: {
        ...this.state.stateFieldValues,
        [stateField.nm]: _checked,
      },
    });
  };

  getLabel(code) {
    return _.keyBy(this.props.lookups.ink.k.TODO_STATE_REFERENCE, 'code')[code]
      .lbl;
  }

  takeOwnership() {
    const { todoDoc, lookups, me } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.assignee_id === me.user.mail) {
      return; //already owner
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states.filter(
        (s) => s.nm === todoDoc.state
      )[0];
      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'take_ownership',
      current_assignee_id: todoDoc.assignee_id,
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      snackMsg: 'Ownership updated',
    };
    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  attachWorkOrder = (wo_id) => () => {
    const { todoDoc, lookups, me } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.assignee_id !== me.user.mail) {
      return; //not owner
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states.filter(
        (s) => s.nm === todoDoc.state
      )[0];
      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'attach_wo',
      wo_id,
      snackMsg: `Work order ${wo_id} attached`,
    };
    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  };

  changeAssignee(item) {
    const { todoDoc, lookups } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.assignee_id === item.email) {
      return; //already an helper
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states
        .filter((a) => {
          if (a.cond === undefined) {
            return true;
          }
          return (
            a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1
          );
        })
        .filter((s) => s.nm === todoDoc.state)[0];
      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'change_assignee',
      assignee_id: item.email,
      assignee_nm: item.orig_nm,
      current_assignee_id: todoDoc.assignee_id,
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      snackMsg: 'Ownership updated',
    };

    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  addHelper(item) {
    const { todoDoc, lookups } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.helper_ids.indexOf(item.email) > -1) {
      return; //already an helper
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states
        .filter((a) => {
          if (a.cond === undefined) {
            return true;
          }
          return (
            a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1
          );
        })
        .filter((s) => s.nm === todoDoc.state)[0];
      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'add_helper',
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      helper_id: item.email,
      helper_nm: item.orig_nm,
    };

    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  removeHelper = (helper) => () => {
    //helper is nm|mail
    const { todoDoc } = this.props;
    let o = helper.split(/\|/);
    let pkg = {
      todoId: todoDoc._id,
      kind: 'remove_helper',
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      helper_id: o[1],
      helper_nm: o[0],
    };

    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  };

  handleRecentWosToggle() {
    this.setState({
      recentWosPanelExpanded: !this.state.recentWosPanelExpanded,
    });
  }
  handleTaskActionToggle() {
    this.setState({ actionPanelExpanded: !this.state.actionPanelExpanded });
  }
  handleLogPanelToggle() {
    this.setState({ logPanelExpanded: !this.state.logPanelExpanded });
  }
  handleCommentsToggle() {
    this.setState({ commentsPanelExpanded: !this.state.commentsPanelExpanded });
  }
  handleHelpersToggle() {
    this.setState({ helpersPanelExpanded: !this.state.helpersPanelExpanded });
  }
  handleAssignmentToggle() {
    this.setState({
      assignmentPanelExpanded: !this.state.assignmentPanelExpanded,
    });
  }

  /* update status of task */
  handleUpdateStatus() {
    if (null === this.state.selectedTodoState) return;

    const { todoDoc, lookups } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    const applicableStateRef = applicableTodoCategoryRef.states
      .filter((a) => {
        if (a.cond === undefined) {
          return true;
        }
        return a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1;
      })
      .filter((s) => s.nm === this.state.selectedTodoState)[0];

    let sf = { ...this.state.stateFieldValues };
    for (let k in sf) {
      if (sf[k] instanceof Set) {
        sf[k] = Array.from(sf[k]);
      }
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'status_update',
      new_state: this.state.selectedTodoState,
      new_tags: [...this.state.checkedTags.values()],
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      state_fields: sf,
      stateRef: applicableStateRef,
      final: applicableStateRef.final,
      snackMsg: 'Status updated',
    };
    this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  /* add comment */
  handleCommentInput(evt) {
    this.setState({ comment: evt.target.value });
  }
  handleCommentSubmit() {
    let pkg = {
      todoId: this.props.todoDoc._id,
      kind: 'comment_only',
      current_last_update: moment(this.props.todoDoc.last_update)
        .toDate()
        .getTime(),
      comment: this.state.comment.toString(),
    };
    this.props.updateTodo_ac(this.props.posInSet, pkg);
    this.setState({ comment: '' });
  }

  componentDidUpdate() {
    const { lookups, todoDoc } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];
    const allow_workorder_attachment =
      true === applicableTodoCategoryRef.allow_workorder_attachment;

    if (
      allow_workorder_attachment === false ||
      this.state.isFetchingRecentWos ||
      this.state.recentWosFetched
    )
      return;

    if (this.state.recentWosPanelExpanded) {
      return this.fetchRecentWos();
    }
  }

  render() {
    const { classes, todoDoc, lookups, me, todosInView, expanded } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const { activity } = todosInView;
    const isOwner = me.user.mail === todoDoc.assignee_id;

    const cardHdrClasses =
      todoDoc.state === 'NEW'
        ? {
            root: classes.cardHdrRoot,
            title: classes.cardHdrContent,
            subheader: classes.cardHdrSubHdr,
          }
        : todoDoc.state.substr(0, 7).toUpperCase() === 'PENDING'
        ? {
            root: classes.cardHdrRootPending,
            title: classes.cardHdrContentPending,
            subheader: classes.cardHdrSubHdrPending,
          }
        : {
            root: classes.cardHdrRootClosed,
            title: classes.cardHdrContentClosed,
            subheader: classes.cardHdrSubHdrClosed,
          };

    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    const allow_workorder_attachment =
      true === applicableTodoCategoryRef.allow_workorder_attachment;

    const userAllowedToUpdateToFinalStatus = true; //(_.intersection(['vct','vct_super'], me.user.roles).length > 0)

    let allowStatusChange = false;
    let isFinal = false;
    let isPending = false;
    if (todoDoc.state === 'NEW') {
      allowStatusChange = true;
    } else {
      let currentStateRef = applicableTodoCategoryRef.states.filter(
        (s) => s.nm === todoDoc.state
      )[0];
      allowStatusChange = !currentStateRef.final;
      isFinal = currentStateRef.final;
      isPending = !currentStateRef.final;
    }

    let applicableTags = [];
    let readyForStatusUpdate = false;
    let applicableStateRef = false;
    let statusBtnLabel = 'STATUS not available for update';

    if (this.state.selectedTodoState) {
      applicableStateRef = applicableTodoCategoryRef.states
        .filter((a) => {
          if (a.cond === undefined) {
            return true;
          }
          return (
            a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1
          );
        })
        .filter((s) => s.nm === this.state.selectedTodoState)[0];

      applicableTags = applicableStateRef.tags.map((t) =>
        typeof t === 'object'
          ? {
              ...t,
              checked: this.state.checkedTags.has(t.tag),
            }
          : {
              checked: this.state.checkedTags.has(t),
              tag: t,
              msg: '',
            }
      );

      readyForStatusUpdate =
        applicableTags.length === 0 || this.state.checkedTags.size > 0;
      statusBtnLabel = applicableStateRef.final
        ? 'Close this Task with Status: ' + this.state.selectedTodoState
        : 'Update Status to ' + this.state.selectedTodoState;

      if (
        this.state.selectedTodoState === todoDoc.state &&
        applicableTags.length === 0 &&
        todoDoc.major_state !== 'pending'
      ) {
        readyForStatusUpdate = false;
      }

      if (applicableStateRef.snooze) {
        statusBtnLabel += '-Snooze ' + applicableStateRef.snooze.hours + ' Hrs';
      }

      if (activity.inprog) {
        readyForStatusUpdate = false;
        statusBtnLabel = '...updating';
      }

      //if there is a required state field, check for it
      if (readyForStatusUpdate) {
        let missing_required_state_fields = _.get(
          applicableStateRef,
          'fields',
          []
        ).filter((f) => {
          return f.required && !this.state.stateFieldValues[f.nm];
        }).length;
        if (missing_required_state_fields) {
          readyForStatusUpdate = false;
        }
      }
    }

    /*
    const allowReAssignment = (
      !isFinal
      &&
      (
        me.user.roles.indexOf('vct_super')>-1
        ||
        todoDoc.code==='cfi'
      )
    )
    */
    let allowReAssignment = true;
    if (applicableTodoCategoryRef.allow_reassign_if) {
      if (
        todoDoc.assignee_id !==
        applicableTodoCategoryRef.allow_reassign_if.assignee_id
      ) {
        allowReAssignment = false;
      }
    }
    if (todoDoc.major_state === 'closed') {
      allowReAssignment = false;
    }

    let assigneeChip = (
      <Chip
        label={todoDoc.assignee_nm}
        className={classes.chip}
        color="primary"
      />
    );

    let helperChips = todoDoc.helpers
      .concat(this.state.tmp_helpers)
      .map((helper) => (
        <Chip
          key={helper}
          label={helper.split(/\|/)[0]}
          onDelete={this.removeHelper(helper)}
          className={classes.chip}
          color="secondary"
        />
      ));

    let snoozeMsg = '';
    if (todoDoc.snooze.hours) {
      let nowTs = new Date().getTime();
      let endMoment = moment(todoDoc.snooze.end);
      if (
        moment(todoDoc.snooze.start).toDate().getTime() < nowTs &&
        endMoment.toDate().getTime() > nowTs
      ) {
        snoozeMsg = ' - SNOOZED UNTIL ' + endMoment.format('MM-DD-YY hh:mma');
      }
    }

    let cardSubTitle = (
      <div style={{ color: '#000' }} onClick={this.toggleExpand}>
        Task #{todoDoc._id}
        {todoDoc.wo_id ? ' W' + todoDoc.wo_id.toString() + ' ' : ''}-{' '}
        <span style={{ fontWeight: 900, fontSize: '.98em', color: '#1A237E' }}>
          STATUS:{todoDoc.state}
        </span>
        - Assigned to: {todoDoc.assignee_nm} - Last:{' '}
        {moment(todoDoc.last_update).format('MM-DD-YY hh:mma')}
        {snoozeMsg}
      </div>
    );

    return (
      <Card className={classes.card}>
        <CardHeader
          title={
            <div>
              <span
                onClick={() => {
                  this.props.changePage(
                    '/dfa/idn/' + todoDoc.idn.replace(/ /g, '_') + '/tabs/todos'
                  );
                }}
                className={classes.linkTo}>
                {todoDoc.idn +
                  (todoDoc.account
                    ? ' A' + todoDoc.account + '-' + todoDoc.member
                    : '')}
              </span>
              <strong style={{ paddingLeft: 8 }} onClick={this.toggleExpand}>
                {this.getLabel(todoDoc.code)}
                {_.get(todoDoc, 'ef.sub_category', false)
                  ? ' - ' + todoDoc.ef.sub_category
                  : ''}
              </strong>
              {this.props.doNotAllowCollapse !== true && (
                <span onClick={this.toggleExpand} style={{ float: 'right' }}>
                  [+/-]
                </span>
              )}
            </div>
          }
          subheader={cardSubTitle}
          classes={cardHdrClasses}
        />

        {todoDoc.code.search(/^pat_out/) > -1 &&
          todoDoc.major_state !== 'closed' &&
          todoDoc.log.length > 2 && (
            <CardHeader
              onClick={this.toggleExpand}
              title={
                <div style={{ backgroundColor: 'red', color: '#000' }}>
                  <strong style={{ paddingLeft: 8 }}>
                    WARNING - This patient may have already been called
                    recently. Please View Task Activity Log and Comments before
                    calling
                  </strong>
                </div>
              }
              classes={cardHdrClasses}
            />
          )}

        {expanded && (
          <>
            {/* BEGIN PRIMARY PANEL */}
            <CardContent className={classes.panelRoot}>
              {todoDoc.wo_id && (
                <div>
                  <strong style={{ fontSize: 12 }}>
                    Work order attached: {todoDoc.wo_id}
                  </strong>
                </div>
              )}
              {todoDoc.df_w_id && (
                <div>
                  <strong style={{ fontSize: 12 }}>
                    A DF Workunit is linked: {todoDoc.df_w_id}
                  </strong>
                </div>
              )}

              {!this.props.inModalView && (
                <>
                  <div>
                    <span className={classes.mData}>
                      Created{' '}
                      {moment(todoDoc.created_dt).format(
                        'ddd MM-DD-YYYY hh:mma'
                      )}{' '}
                      by {todoDoc.creator_nm}
                    </span>
                  </div>
                  {isPending && (
                    <div>
                      <span className={classes.mData}>
                        Last Marked Pending{' '}
                        {moment(todoDoc.last_pending_dt).format(
                          'ddd MM-DD-YYYY hh:mma'
                        )}{' '}
                        by {todoDoc.last_pending_nm}
                      </span>
                    </div>
                  )}
                  {isFinal && (
                    <div>
                      <span className={classes.mData}>
                        Closed{' '}
                        {moment(todoDoc.closed_dt).format(
                          'ddd MM-DD-YYYY hh:mma'
                        )}{' '}
                        by {todoDoc.closed_nm}
                      </span>
                    </div>
                  )}
                  {todoDoc.tags.length > 0 && (
                    <>
                      <div>
                        <span className={classes.mData}>Tags:</span>
                      </div>
                      <div>
                        {todoDoc.tags.map((nm) => (
                          <Chip
                            key={nm}
                            label={nm}
                            className={classes.tagChip}
                          />
                        ))}
                      </div>
                    </>
                  )}

                  <div>
                    <span className={classes.mData}>Assigned to:</span>
                  </div>
                  <div>
                    {assigneeChip}
                    {helperChips}
                  </div>

                  <div className={classes.listItemParaPre}>
                    {todoDoc.log[0].txt.split(/\n/).map((t, ti) => (
                      <span key={ti}>
                        {t}
                        <br />
                      </span>
                    ))}
                  </div>
                </>
              )}

              {applicableTodoCategoryRef.extra_fields && (
                <div className={classes.efPara}>
                  {applicableTodoCategoryRef.extra_fields
                    .filter((ef) => ef.assignee === undefined)
                    .map((ef) => (
                      <div key={ef.nm}>
                        <span>{ef.lbl}</span>:
                        <strong>
                          {_.get(todoDoc, 'ef', { [ef.nm]: '' })[ef.nm]}
                        </strong>
                      </div>
                    ))}
                </div>
              )}

              {_.get(todoDoc, 'ef.claim_line_ids', []).length > 0 && (
                <div className={classes.efPara}>
                  <span>Claim Lines:</span>:
                  {todoDoc.ef.claim_line_ids.map((cli) => (
                    <div key={cli}>
                      <strong>{cli}</strong>
                    </div>
                  ))}
                </div>
              )}

              {todoDoc.sf && !_.isEmpty(todoDoc.sf) && (
                <div className={classes.efPara}>
                  {applicableTodoCategoryRef.states
                    .filter((a) => {
                      if (a.cond === undefined) {
                        return true;
                      }
                      return (
                        a.cond.value.indexOf(
                          _.get(todoDoc, a.cond.source, false)
                        ) > -1
                      );
                    })
                    .filter((a) => {
                      return a.nm === todoDoc.state;
                    })[0]
                    .fields.map((sf) => (
                      <div key={sf.nm}>
                        <span>{sf.lbl}</span>:
                        <strong>
                          {typeof _.get(todoDoc, 'sf', { [sf.nm]: '' })[
                            sf.nm
                          ] === 'string'
                            ? _.get(todoDoc, 'sf', { [sf.nm]: '' })[sf.nm]
                            : _.get(todoDoc, 'sf', { [sf.nm]: '' })[sf.nm].join(
                                ', '
                              )}
                        </strong>
                      </div>
                    ))}
                </div>
              )}
            </CardContent>
            {/* END PRIMARY PANEL */}

            {/* BEGIN REASSIGNMENT PANEL */}
            {allowReAssignment && (
              <CardActions className={classes.panelBtn}>
                <Button
                  size="small"
                  color="primary"
                  onClick={this.handleAssignmentToggle}>
                  Change Primary Owner
                </Button>
                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: this.state.assignmentPanelExpanded,
                  })}
                  onClick={this.handleAssignmentToggle}
                  aria-expanded={this.state.assignmentPanelExpanded}
                  aria-label="Show Assignment Panel">
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            )}

            {allowReAssignment && (
              <Collapse
                in={this.state.assignmentPanelExpanded}
                timeout="auto"
                unmountOnExit>
                <CardContent className={classes.panelRoot}>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center">
                    <Grid item xs={5}>
                      <UserSearch onSelect={this.changeAssignee} />
                    </Grid>
                    <Grid item xs={2}>
                      <div style={{ textAlign: 'center' }}>OR</div>
                    </Grid>
                    <Grid item xs={5}>
                      <Button
                        size="medium"
                        color="primary"
                        onClick={this.takeOwnership}>
                        Take Ownership
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Collapse>
            )}

            {/* BEGIN HELPERS PANEL */}
            <CardActions className={classes.panelBtn}>
              <Button
                size="small"
                color="primary"
                onClick={this.handleHelpersToggle}>
                Add Other Members
              </Button>
              <IconButton
                className={classNames(classes.expand, {
                  [classes.expandOpen]: this.state.helpersPanelExpanded,
                })}
                onClick={this.handleHelpersToggle}
                aria-expanded={this.state.helpersPanelExpanded}
                aria-label="Show Assignment Panel">
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse
              in={this.state.helpersPanelExpanded}
              timeout="auto"
              unmountOnExit>
              <CardContent className={classes.panelRoot}>
                <div>
                  <UserSearch onSelect={this.addHelper} />
                </div>
              </CardContent>
            </Collapse>

            {/* BEGIN RECENT WOS PANEL */}
            {allow_workorder_attachment && todoDoc.major_state !== 'closed' && (
              <>
                <CardActions className={classes.panelBtn}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.handleRecentWosToggle}>
                    Recent HDMS Work Orders
                  </Button>
                  <IconButton
                    className={classNames(classes.expand, {
                      [classes.expandOpen]: this.state.helpersPanelExpanded,
                    })}
                    onClick={this.handleRecentWosToggle}
                    aria-expanded={this.state.recentWosPanelExpanded}
                    aria-label="Show Recent Work Orders Panel">
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse
                  in={this.state.recentWosPanelExpanded}
                  timeout="auto"
                  unmountOnExit>
                  <CardContent className={classes.panelRoot}>
                    {this.state.recentWosFetched &&
                      false === this.state.isFetchingRecentWos &&
                      this.state.recentWos.map((wo, wIdx) => (
                        <div key={wo.hdms_wId} className={classes.woIdToAttach}>
                          <Typography className={classes.woHdr} color="primary">
                            W{wo.hdms_wId} - Dropped by {wo.pcr_nm} -{' '}
                            {wo.effective_dt} - {wo.method_nm}{' '}
                            {wo.release_dt_str}
                          </Typography>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            disabled={!isOwner}
                            onClick={this.attachWorkOrder(wo.hdms_wId)}>
                            Attach this Work Order: W{wo.hdms_wId}
                          </Button>
                        </div>
                      ))}
                  </CardContent>
                </Collapse>
              </>
            )}

            {/* BEGIN STATUS UPDATE PANEL */}
            {allowStatusChange && !isOwner && (
              <CardActions className={classes.panelBtn}>
                <Button size="small" color="primary" disabled>
                  You must be the owner to update status
                </Button>
                <IconButton
                  disabled
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: false,
                  })}
                  aria-expanded={this.state.actionPanelExpanded}
                  aria-label="Show Task Actions">
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            )}

            {allowStatusChange && isOwner && (
              <CardActions className={classes.panelBtn}>
                <Button
                  size="small"
                  color="primary"
                  onClick={this.handleTaskActionToggle}>
                  Update the Status of this Task
                </Button>
                <IconButton
                  className={classNames(classes.expand, {
                    [classes.expandOpen]: this.state.actionPanelExpanded,
                  })}
                  onClick={this.handleTaskActionToggle}
                  aria-expanded={this.state.actionPanelExpanded}
                  aria-label="Show Task Actions">
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
            )}

            {allowStatusChange && isOwner && (
              <Collapse
                in={this.state.actionPanelExpanded}
                timeout="auto"
                unmountOnExit>
                <CardContent className={classes.panelRoot}>
                  <div>
                    <RadioGroup
                      className={classes.radioGroupRow}
                      onChange={this.todoStateSelected}
                      value={this.state.selectedTodoState}>
                      {applicableTodoCategoryRef.states
                        .filter(
                          (a) => a.legacy !== true && a.automation_only !== true
                        )
                        .filter((a) => {
                          if (a.cond === undefined) {
                            return true;
                          }
                          return (
                            a.cond.value.indexOf(
                              _.get(todoDoc, a.cond.source, false)
                            ) > -1
                          );
                        })
                        .filter((a) => {
                          if (!_.isArray(a.current_state_cond)) {
                            return true;
                          }

                          return a.current_state_cond.includes(todoDoc.state);
                        })
                        .map((a) => {
                          return a;
                          /*
                    if(a.maxPendings===undefined){ return a };
                    let times_pended = _.get(todoDoc,'times_pended',0);
                    if ( a.maxPendings === times_pended ) {
                      a.pendingsExhausted=true;
                      a.help_text = "Pendings Used Up";
                    }
                    return a;
                    */
                        })
                        .map((a) => (
                          <FormControlLabel
                            className={classes.radioGroupRowItem}
                            labelPlacement="end"
                            disabled={
                              (a.final && !userAllowedToUpdateToFinalStatus) ||
                              (a.wo_required === true &&
                                undefined === todoDoc.wo_id) ||
                              a.pendingsExhausted === true
                            }
                            key={a.nm}
                            value={a.nm}
                            label={
                              a.help_text ? (
                                <div style={{ textAlign: 'left' }}>
                                  <Typography>
                                    {a.nm}
                                    {a.final ? ' [Final]' : ''}
                                  </Typography>
                                  <Typography
                                    style={{
                                      maxWidth: 500,
                                      textAlign: 'left',
                                      fontSize: 10,
                                      color: '#37474F',
                                    }}>
                                    {a.help_text}
                                  </Typography>
                                </div>
                              ) : (
                                <Typography>
                                  {a.nm}
                                  {a.final ? ' [Final]' : ''}
                                </Typography>
                              )
                            }
                            control={<Radio />}
                          />
                        ))}
                    </RadioGroup>
                  </div>

                  {applicableTags.length > 0 && (
                    <div style={{ marginTop: 48 }}>
                      <Button size="small" color="primary">
                        {applicableStateRef.maxTags
                          ? `Check up to ${applicableStateRef.maxTags} of the `
                          : `Check all applicable `}
                        <strong style={{ marginLeft: 4, marginRight: 4 }}>
                          {this.state.selectedTodoState}
                        </strong>{' '}
                        situations:
                      </Button>

                      <FormGroup className={classes.checkboxGroupRow}>
                        {applicableTags.map((t) => (
                          <FormControlLabel
                            className={classes.checkboxGroupRowItem}
                            key={t.tag}
                            control={
                              <Checkbox
                                checked={t.checked}
                                onChange={this.handleTagCheckbox(
                                  applicableStateRef
                                )}
                                value={t.tag}
                              />
                            }
                            label={t.tag}
                          />
                        ))}
                      </FormGroup>
                      {applicableTags
                        .filter((t) => t.msg && t.checked)
                        .map((t) => (
                          <div
                            style={{
                              paddingLeft: 12,
                              fontSize: 12,
                              fontWeight: 700,
                            }}
                            key={t.tag}>
                            {t.msg}
                          </div>
                        ))}
                    </div>
                  )}

                  {applicableStateRef &&
                    _.get(applicableStateRef, 'fields', []).map((f, f_idx) => {
                      switch (f.kind) {
                        case 'checkboxes':
                          return (
                            <div key={f.lbl} style={{ marginTop: 48 }}>
                              <Button size="small" color="primary">
                                {f.max
                                  ? `Check up to ${f.max} of the `
                                  : `Check all applicable `}
                                <strong
                                  style={{ marginLeft: 4, marginRight: 4 }}>
                                  {f.lbl}
                                </strong>{' '}
                              </Button>

                              <FormGroup className={classes.checkboxGroupRow}>
                                {f.checkboxes.map((t) => (
                                  <FormControlLabel
                                    className={classes.checkboxGroupRowItem}
                                    key={t.nm}
                                    control={
                                      <Checkbox
                                        checked={
                                          this.state.stateFieldValues[f.nm]
                                            ? this.state.stateFieldValues[
                                                f.nm
                                              ].has(t.nm)
                                            : false
                                        }
                                        onChange={this.onStateCheckboxChanged(
                                          applicableStateRef,
                                          f
                                        )}
                                        value={t.nm}
                                      />
                                    }
                                    label={t.nm}
                                  />
                                ))}
                              </FormGroup>
                            </div>
                          );
                        case 'text':
                          return (
                            <FormGroup
                              key={f.lbl}
                              className={classes.formGroupRoot}>
                              <TextField
                                required={f.required}
                                label={(f.required ? 'REQUIRED ' : '') + f.lbl}
                                margin="normal"
                                multiline={f.multiline}
                                className={classes.memoField}
                                onChange={this.onStateTextFieldChanged(f)}
                                value={this.state.stateFieldValues[f.nm] || ''}
                              />
                            </FormGroup>
                          );
                        default:
                          return '';
                      }
                    })}

                  <div
                    style={{ marginTop: 48 }}
                    className={classes.btnContainer}>
                    <Button
                      disabled={!readyForStatusUpdate}
                      onClick={this.handleUpdateStatus}
                      variant="contained"
                      color="primary">
                      {statusBtnLabel}
                    </Button>
                  </div>
                </CardContent>
              </Collapse>
            )}
            {/* END STATUS UPDATE PANEL */}

            {!this.props.inModalView && (
              <>
                {/* BEGIN COMMENT PANEL */}
                <CardActions className={classes.panelBtn}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.handleCommentsToggle}>
                    Comments ({todoDoc.comments.length})
                  </Button>
                  <IconButton
                    className={classNames(classes.expand, {
                      [classes.expandOpen]: this.state.commentsPanelExpanded,
                    })}
                    onClick={this.handleCommentsToggle}
                    aria-expanded={this.state.commentsPanelExpanded}
                    aria-label="Show Comments">
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>

                <Collapse
                  in={this.state.commentsPanelExpanded}
                  timeout="auto"
                  unmountOnExit>
                  <CardContent className={classes.panelRoot}>
                    <div>
                      {todoDoc.comments.map((n, nIdx) => {
                        let textParts = n.txt.split(/\n/g);
                        textParts = textParts.map((p, pi) => {
                          if (pi === textParts.length - 1) return p;
                          return (
                            <span key={pi}>
                              {p}
                              <br />
                            </span>
                          );
                        });
                        return (
                          <Typography
                            key={nIdx}
                            paragraph
                            className={classes.noteTxt}
                            color="textSecondary">
                            {textParts}
                            <i className={classes.noteUser}>
                              {' '}
                              - {n.u_nm}{' '}
                              <Moment format="M-DD-YY h:mma">{n.ts}</Moment>
                            </i>
                          </Typography>
                        );
                      })}
                    </div>

                    <TextField
                      multiline
                      value={this.state.comment}
                      onChange={this.handleCommentInput}
                      label="Add Comment ([Enter] for multiline)"
                      className={classes.formComponent}
                    />
                    <div className={classes.btnContainer}>
                      <Button
                        className={classes.button}
                        onClick={this.handleCommentSubmit}
                        variant="contained"
                        color="primary">
                        Add Comment
                      </Button>
                    </div>
                  </CardContent>
                </Collapse>
                {/* END COMMENT PANEL */}

                {/* BEGIN ACTIVITY LOG PANEL */}
                <CardActions className={classes.panelBtn}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.handleLogPanelToggle}>
                    View Task Activity Log ({todoDoc.log.length})
                  </Button>
                  <IconButton
                    className={classNames(classes.expand, {
                      [classes.expandOpen]: this.state.logPanelExpanded,
                    })}
                    onClick={this.handleLogPanelToggle}
                    aria-expanded={this.state.logPanelExpanded}
                    aria-label="Show Activity Log">
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>
                <Collapse
                  in={this.state.logPanelExpanded}
                  timeout="auto"
                  unmountOnExit>
                  <CardContent className={classes.panelRoot}>
                    <Typography
                      paragraph
                      className={classes.logTxt}
                      color="textSecondary">
                      Assigned to {todoDoc.log[0].assignee_nm}
                    </Typography>
                    {todoDoc.log.slice(1).map((log, nIdx) => (
                      <Typography
                        key={nIdx}
                        paragraph
                        className={classes.logTxt}
                        color="textSecondary">
                        {log.txt.split(/\n/g).join(' - ')} -
                        <i className={classes.noteUser}>
                          {log.u_nm}{' '}
                          <Moment format="M-DD-YY h:mma">{log.ts}</Moment>
                        </i>
                      </Typography>
                    ))}
                  </CardContent>
                </Collapse>
                {/* END ACTIVITY LOG PANEL */}
              </>
            )}
          </>
        )}
      </Card>
    );
  }
}

TodoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  todoDoc: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  idnInView: stateFromStore.idnInView,
  todosInView: stateFromStore.todosInView,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { updateTodo_ac, changePage: (path) => push(path) },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TodoCard))
);
