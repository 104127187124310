import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { fetchIdxAdminLogs_ac } from '../../../actions/idx_admin_log.ac';
import { removeIdxOrderingClinicians_ac } from '../../../actions/idx_admin_ordering_clinicians.ac';
import { open_snack_ac } from '../../../actions/snack.ac';
import * as routes from '../../../constants/routes';
import { formatPhoneNumber, getErrorMessage } from '../../../util/string';
import { KeyValuePair } from '../../common/NameLabelView';
import Loader from '../../Shared/Loader';
import LogList from '../common/LogList';
import RelationshipView from '../common/RelationshipView';
import LocationsView from './LocationsView';
import NotesView from './NotesView';
import RepsView from './RepsView';
import SalesOCNotes from './SalesOCNotes';

const gridStyle = {
  overflowY: 'scroll',
  overflowX: 'hidden',
  maxHeight: '60vh',
};

const COMM_RESTRICTIONS = {
  do_not_call: 'Do Not Call',
  do_not_fax: 'Do Not Fax',
  hospitalist:"Hospitalist"
};

const Info = ({
  repMode = false,
  widgetMode = false,
  indexingData = false,
  showMetaNote = false,
  history,
  data,
  relationships = [],
  isFetching,
  onCancel,
  onUpdate,
  metaInfo,
  ...rest
}) => {
  const [isAddingNote, setIsAddingNote] = React.useState(false);
  const [metaNote, setMetaNote] = React.useState('');
  const [restrictions, setRestrictions] = React.useState({});

  useEffect(() => {
    setRestrictions({
      do_not_call: data.do_not_call,
      do_not_fax: data.do_not_fax,
      hospitalist: data.hospitalist,
    });
  }, [data]);

  if (isFetching) {
    return <Loader />;
  } else if (!data._id) {
    return <div />;
  }

  const processedData = {
    RecordID: data._id,
    First: data.first_name,
    Last: data.last_name,
    'Phone numbers': data.phone
      ?.map((n) => formatPhoneNumber(n) || n)
      .join(','),
    Emails: data.email?.join(','),
    Faxes: data.fax?.map((n) => formatPhoneNumber(n) || n).join(','),
    NPI: data.npi,
    License: data.license_num,
    LicenseExpDt: data.license_exp_dt,
    'OC Level': data.oc_level,
    'In Pecos': data.in_pecos ? 'Yes' : 'No',
    Tags: data.tags?.join(', '),
    'Pending Approval': data.pending_approval
      ? 'Needs Administrator Approval'
      : 'No',
  };

  const onCommunicationChecked = (restrictions) => {
    window.sch
      .post('/api/idx/admin/oc/comm_restrictions', {
        _id: data._id,
        ...restrictions,
      })
      .then((res) => {
        rest.open_snack_ac({
          variant: 'success',
          message: `Restriction updated successfully.`,
        });
      })
      .catch((err) => {
        rest.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(
            err,
            'Failed to Update Communication Restrictions!'
          ),
        });
      });
  };

  const onNoteAdd = (note, cb) => {
    setIsAddingNote(true);
    window.sch
      .post('/api/idx/admin/oc/append_note', { _id: data._id, note })
      .then((res) => {
        onUpdate({ ...data, notes: res.notes });
        cb();
        setIsAddingNote(false);
        rest.open_snack_ac({
          variant: 'success',
          message: `Note added successfully.`,
        });
      })
      .catch((err) => {
        rest.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(err, 'Failed to add the note!'),
        });
      })
      .finally(() => {
        setIsAddingNote(false);
      });
  };

  const handleOCRemove = () => {
    rest.removeIdxOrderingClinicians_ac(data._id, {
      onSuccess: () => {
        rest.open_snack_ac({
          variant: 'success',
          message: `${processedData.Last}, ${processedData.First} removed successfully!`,
        });
        onCancel();
      },
      onError: (error) => {
        rest.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(
            error,
            `Failed to remove ${processedData.Last}, ${processedData.First}`
          ),
        });
      },
    });
  };

  const handleCommunicationChange = (e) => {
    setRestrictions((prev) => ({ ...prev, [e.target.name]: e.target.checked }));
    const comm_data = { [e.target.name]: e.target.checked };
    onCommunicationChecked({ comm_data });
  };

  return (
    <Grid container style={{ padding: '12px' }}>
      <Grid item xs={repMode ? 6 : 12} style={gridStyle}>
        <KeyValuePair source={processedData} font={12} />
        {!widgetMode && (
          <div style={{ marginTop: '10px' }}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                history.push(
                  (repMode
                    ? routes.SALES_REP_DOCTOR_UPDATE
                    : routes.ADMIN_IDX_OC_UPDATE
                  ).replace(':id', data._id)
                );
              }}>
              Edit Info
            </Button>
          </div>
        )}

        <div>
          <FormControlLabel
            label={
              <Typography variant="body1">
                {COMM_RESTRICTIONS.do_not_call}
              </Typography>
            }
            name="do_not_call"
            control={
              <Checkbox
                checked={restrictions.do_not_call}
                onChange={handleCommunicationChange}
              />
            }
          />

          <FormControlLabel
            label={
              <Typography variant="body1">
                {COMM_RESTRICTIONS.do_not_fax}
              </Typography>
            }
            name={'do_not_fax'}
            control={
              <Checkbox
                checked={restrictions.do_not_fax}
                onChange={handleCommunicationChange}
              />
            }
          />

          <FormControlLabel
            label={
              <Typography variant="body1">
                {COMM_RESTRICTIONS.hospitalist}
              </Typography>
            }
            name="hospitalist"
            control={
              <Checkbox
                checked={restrictions.hospitalist}
                onChange={handleCommunicationChange}
              />
            }
          />
        </div>

        {data.meta_note && (
          <Typography style={{ fontSize: 12, color: 'grey', marginTop: 14 }}>
            <b style={{ color: 'black' }}>{'Meta note: '}</b> {data.meta_note}
          </Typography>
        )}
        <RepsView
          readonly={widgetMode}
          reps={data.reps}
          oc={data}
          onUpdate={onUpdate}
        />
        <NotesView
          notes={data.notes}
          onAdd={onNoteAdd}
          isAddingNote={isAddingNote}
        />
        {showMetaNote && (
          <Grid item style={{ marginTop: '-16px', marginBottom: '16px' }}>
            <TextField
              multiline
              fullWidth
              id="meta_note"
              name="meta_note"
              label="Additional Note"
              variant="standard"
              value={metaNote}
              placeholder="Jot down the note here!"
              helperText={
                'This note will be notified to concerned authorities!'
              }
              onChange={(e) => {
                setMetaNote(e.target.value);
              }}
            />
          </Grid>
        )}
        <LocationsView
          indexingData={indexingData}
          locations={data.locations}
          oc={data}
          onUpdate={onUpdate}
          metaInfo={metaInfo}
          metaNote={metaNote}
        />
        {!widgetMode && (
          <RelationshipView
            relationships={relationships}
            onRemove={handleOCRemove}
            isRemoving={rest.idxAdminOrderingClinicians.isRemoving}
          />
        )}
      </Grid>
      {repMode && !isNaN(data._id) ? (
        <Grid item xs={6} style={{ paddingLeft: '10px' }}>
          <SalesOCNotes oc_id={data._id} openSnack={rest.open_snack_ac} />
        </Grid>
      ) : null}
    </Grid>
  );
};

const IdxOrderingCliniciansInfo = (props) => {
  const [selectedTab, setSelectedTab] = useState('detail');

  const { data, idxAdminLogs, fetchIdxAdminLogs_ac } = props;
  const { logs, isFetching } = idxAdminLogs;

  const tabClass = {
    labelContainer: 'labelContainer',
  };

  useEffect(() => {
    if (selectedTab === 'logs') {
      fetchIdxAdminLogs_ac({ c: 'idx_oc', id: data._id });
    }
  }, [selectedTab, data._id, fetchIdxAdminLogs_ac]);

  const onTabSelect = (e, _selectedTab) => {
    setSelectedTab(_selectedTab);
  };

  useEffect(() => {
    setSelectedTab('detail');
  }, [data._id]);

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={onTabSelect}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="off">
        <Tab label="Detail" value="detail" classes={tabClass} />
        <Tab label="logs" value="logs" classes={tabClass} />
      </Tabs>

      {selectedTab === 'detail' && (
        <div>
          <Info {...props} />
        </div>
      )}

      {selectedTab === 'logs' && (
        <LogList logs={logs?.rr} isLoading={isFetching} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  idxAdminOrderingClinicians: state.idxAdminOrderingClinicians,
  idxAdminLogs: state.idxAdminLogs,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { open_snack_ac, removeIdxOrderingClinicians_ac, fetchIdxAdminLogs_ac },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles({})(IdxOrderingCliniciansInfo));
