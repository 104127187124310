import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateApplication_ac } from '../../actions/client_apps.ac';
import Loader from '../Shared/Loader';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
  },
  cardHdrRoot: {
    backgroundColor: '#e0f7fa',
    paddingBottom: 8,
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  cardContent: {
    '-webkitOverflowScrolling': 'touch',
    overflowY: 'auto',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200,
  },
  inlineBlock: {
    display: 'inline-block',
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center',
  },
});
/*
const MAP_LABEL = {
  email: 'Email',
  sms: 'SMS',
  voice: 'Voice',
  letter: 'Letter'
}
*/

const preferenceSettings = [
  {
    label: 'Voice',
    name: 'voice',
    isChecked: false,
  },
  {
    label: 'SMS',
    name: 'sms',
    isChecked: false,
  },
  {
    label: 'Email',
    name: 'email',
    isChecked: false,
  },
  {
    label: 'Letter',
    name: 'letter',
    isChecked: false,
  },
];

const defaultFormFields = {
  preferences: [
    {
      nm: '',
      methods: preferenceSettings.map((settings) => ({ ...settings })),
    },
  ],
};

class ClientAppPreferences extends React.Component {
  constructor() {
    super();
    this.state = {
      ...defaultFormFields,
      preferenceUpdated: false,
    };
  }

  componentDidMount() {
    this.populatePreferences();
  }

  componentDidUpdate(preProps) {
    const { client_apps: previousClientApps } = preProps.clientAppsInView;
    const { client_apps } = this.props.clientAppsInView;
    if (!client_apps) return;
    const sameApp =
      previousClientApps && previousClientApps._id === client_apps._id;
    if (sameApp) return;
    this.populatePreferences();
  }

  populatePreferences = () => {
    const { clientAppsInView } = this.props;
    const { client_apps } = clientAppsInView;
    const selectedPreferences = client_apps.preferences.map((pref) => ({
      nm: pref.nm,
      methods: _populateMethods(pref.methods),
    }));

    function _populateMethods(methods) {
      return preferenceSettings.map((settings) => ({
        ...settings,
        isChecked: methods.includes(settings.name),
      }));
    }
    this.setState({
      preferences: selectedPreferences,
      preferenceUpdated: false,
    });
  };

  updateApplication = () => {
    const { clientAppsInView } = this.props;
    const { client_apps } = clientAppsInView;

    const { preferences } = this.state;
    const updatedPreferences = preferences.map((preference) => ({
      nm: preference.nm,
      methods: _getMethods(preference.methods),
    }));

    // TODO modify the component if nm,description needs to be updated and send in the request params API already ready to update whole document.
    const requestParams = {
      _id: client_apps._id,
      preferences: updatedPreferences,
    };
    this.props.updateApplication_ac(requestParams);

    function _getMethods(methods) {
      return methods.reduce((acc, method) => {
        method.isChecked && acc.push(method.name);
        return acc;
      }, []);
    }
  };

  addPreferences = () => {
    const { preferences } = this.state;
    const methods = preferenceSettings.map((settings) => ({ ...settings }));

    preferences.push({
      nm: '',
      methods,
    });
    this.setState({
      preferences,
      preferenceUpdated: true,
    });
  };

  /**
   *
   * @param {number} index
   */
  removePreferene = (index) => {
    const { preferences } = this.state;
    preferences.splice(index, 1);
    this.setState({
      preferences,
      preferenceUpdated: true,
    });
  };

  handlePreferenceChange = (e, preferenceIndex) => {
    const { type, name, value, checked } = e.target;
    const { preferences } = this.state;
    if (type === 'text') {
      preferences[preferenceIndex][name] = value;
    }
    if (type === 'checkbox') {
      preferences[preferenceIndex].methods.find(
        (option) => option.name === name
      ).isChecked = checked;
    }

    this.setState({
      preferences,
      preferenceUpdated: true,
    });
  };

  render() {
    const { clientAppsInView, classes } = this.props;
    const { client_apps } = clientAppsInView;
    const isDisabled =
      !this.state.preferenceUpdated || clientAppsInView.isSubmitting;
    const cardHdrClasses = {
      root: classes['cardHdrRoot'],
      title: classes['cardHdrContent'],
      subheader: classes['cardHdrSubHdr'],
    };
    return (
      <Card className={classes.content}>
        <CardHeader
          classes={cardHdrClasses}
          title={
            <>
              <strong>{client_apps.nm} &ensp;</strong>
            </>
          }
          subheader={
            <span style={{ fontSize: 12 }}>{client_apps.description}</span>
          }
        />
        <CardContent>
          <Grid container className={classes.cardContent}>
            <Grid item xs={12}>
              <FormControl
                style={{ marginLeft: 0 }}
                className={classes.formControl}
                fullWidth>
                <FormLabel component="label">
                  <h3>Preferences for </h3>
                </FormLabel>

                {this.state.preferences.map((preference, index) => (
                  <FormGroup key={index}>
                    <FormLabel component="label">
                      <strong>Preference Name</strong>
                      {index >= 1 && (
                        <Tooltip title="Remove Preference">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => this.removePreferene(index)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </FormLabel>
                    <TextField
                      style={{ marginTop: '5px' }}
                      fullWidth
                      variant="outlined"
                      onChange={(e) => this.handlePreferenceChange(e, index)}
                      required
                      placeholder="Name Of Preferences"
                      value={preference.nm}
                      name="nm"
                    />
                    {preference.methods.map((pref, optionIndex) => (
                      <FormControlLabel
                        key={optionIndex}
                        label={
                          <Typography variant="body1">{pref.label}</Typography>
                        }
                        name={pref.name}
                        control={
                          <Checkbox
                            className={classes.inlineBlock}
                            checked={pref.isChecked || false}
                            onChange={(e) =>
                              this.handlePreferenceChange(e, index)
                            }
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                ))}
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() => this.addPreferences()}>
                  Add Preferences
                  <AddIcon />
                </Button>
              </FormControl>
            </Grid>
            <Divider component="hr" />
            <Grid item xs={12}>
              <FormControl
                style={{ marginLeft: 0 }}
                className={classes.formControl}>
                <div className={classes.flexDisplay}>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={this.updateApplication}
                    disabled={isDisabled}>
                    Update App
                  </Button>
                  {clientAppsInView.isSubmitting && (
                    <Loader type="circular" size={15} color="primary" />
                  )}
                </div>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

ClientAppPreferences.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  clientAppsInView: stateFromStore.clientAppsInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateApplication_ac,
    },
    dispatch
  );

export const AppPreferences = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ClientAppPreferences))
);
