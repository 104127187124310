/* eslint-disable no-useless-escape */
import { Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ReactS3Uploader from './ReactS3Uploader';

function MyDropZone(props) {
  const { activityStoreState, onFilesAccepted, disabled } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (activityStoreState !== 'new') {
        console.log('drop zone deactivated when activityStoreState not new');
      }

      let invalidTypes = acceptedFiles
        .map((f) => f.type)
        .filter((t) => t !== 'application/pdf');

      if (invalidTypes.length) {
        console.log('INVALID FILE TYPE');
        return;
      }

      onFilesAccepted(acceptedFiles);
      return;
    },
    [activityStoreState, onFilesAccepted]
  );

  const { getRootProps } = useDropzone({ onDrop, disabled });

  return (
    <div style={{ width: '98%' }} {...getRootProps()}>
      {props.activityStoreState === 'new' && (
        <p
          style={{
            border: '2px dashed #4CAF50',
            color: '#4CAF50',
            width: '100%',
            height: '200px',
            padding: 20,
          }}>
          Drag and drop files here. Must be in PDF format
        </p>
      )}
    </div>
  );
}

class ReactS3UploaderSC extends ReactS3Uploader {
  componentDidMount() {
    if (this.props.activityStoreState === 'readyForUploads') {
      this.uploadFiles(this.state.files);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activityStoreState === 'readyForUploads' &&
      prevProps.activityStoreState !== 'readyForUploads'
    ) {
      this.props.beginUploadFiles();
      this.uploadFiles(this.state.files);
    }
  }

  render() {
    return (
      <MyDropZone
        disabled={this.props.disabled}
        activityStoreState={this.props.activityStoreState}
        onFilesAccepted={this.onFilesAttached}
      />
    );
  }
}
const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  progress: {
    margin: 4,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 280,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  chipWithSelect: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
    marginLeft: 0,
  },
});

class UploadToS3V2 extends React.Component {
  constructor(props) {
    super(props);
    this.filesAttached = this.filesAttached.bind(this);
    this.onUploadProgress = this.onUploadProgress.bind(this);
    this.onUploadError = this.onUploadError.bind(this);
    this.onUploadFinish = this.onUploadFinish.bind(this);
    this.docTypeSelectedForFile = this.docTypeSelectedForFile.bind(this);
    this.state = {
      fileDocType: [],
    };
  }

  filesAttached(files) {
    //console.log(this)
    let n = this.props.activityStore[this.props.purpose + 'Files'].length * 1;
    let ff = [...this.props.activityStore[this.props.purpose + 'Files']].concat(
      files.map((f, fIdx) => {
        if (this.props.activityStore.qrCodeHash) {
          f.objectName = f.name
            .replace(/[^\w\d_\-.]+/gi, '')
            .replace(/[,\-%\\\/]/g, '')
            .replace(
              /\.pdf$/i,
              '.' + this.props.activityStore.qrCodeHash + '.pdf'
            );
        } else {
          f.objectName =
            moment().format('YYYY-MM-DD-hh-mm-ss') +
            '_' +
            f.name.replace(/[^\w\d_\-.]+/gi, '').replace(/[,\-%\\\/]/g, '');
        }

        let s3KeyPrefix = this.props.s3KeyPrefix || 'uploads';
        //f.s3pathAndKey = "uploads/"+this.props.idn.replace(/[\-\/\\;, ()]/g, "_")+"/"+ f.objectName;
        f.s3pathAndKey =
          s3KeyPrefix +
          '/' +
          this.props.idn.replace(/[^0-9a-zA-Z]/g, '_') +
          '/' +
          f.objectName;
        f.position = n + fIdx;
        f.progressPercent = 0;
        f.docType = '';
        return f;
      })
    );

    console.log('ff to state', ff);
    this.setState({ fileDocType: ff.map((f) => f.docType) });
    this.props.onFilesChanged(ff);
  }

  onSignedUrl() {
    //console.log('on signed',a)
  }

  onUploadFinish(signPkg, file) {
    file.s3key = signPkg.signedUrl.split(/\?/)[0];
    let updatedFiles = this.props.activityStore[
      this.props.purpose + 'Files'
    ].map((f, fIdx) => {
      if (file.position === fIdx) return file;
      return f;
    });
    this.props.onFilesChanged(updatedFiles);
    //console.log('onfinish',updatedFiles)
  }

  onUploadProgress(p, msg, file) {
    file.progressPercent = p;
    let updatedFiles = this.props.activityStore[
      this.props.purpose + 'Files'
    ].map((f, fIdx) => {
      if (file.position === fIdx) return file;
      return f;
    });
    this.props.onFilesChanged(updatedFiles);
  }

  onUploadError(err, file) {
    console.log('on err', err, file);
    file.err = err;
    let updatedFiles = this.props.activityStore[
      this.props.purpose + 'Files'
    ].map((f, fIdx) => {
      if (file.position === fIdx) return file;
      return f;
    });
    this.props.onFilesChanged(updatedFiles);
  }

  handleDelete(posOfFileToRemove) {
    let nInProg =
      this.props.activityStore[this.props.purpose + 'Files'].filter(
        (f) => !f.err && f.progressPercent < 100
      ).length * 1;

    if (nInProg.length) {
      console.log(
        'can not remove files when some are in upload progress (and are not in error)'
      );
      return;
    }

    let tmpFiles = [].concat(
      this.props.activityStore[this.props.purpose + 'Files']
    );
    tmpFiles.splice(posOfFileToRemove, 1);
    this.props.onFilesChanged(
      tmpFiles.map((f, newFidX) => {
        f.position = 1 * newFidX;
        return f;
      })
    );
  }

  docTypeSelectedForFile(val, fIdx) {
    let changed = false;
    let updatedFiles = this.props.activityStore[
      this.props.purpose + 'Files'
    ].map((f) => {
      if (f.position === fIdx && f.docType !== val) {
        f.docType = val;
        changed = true;
      }
      return f;
    });
    if (changed) {
      this.props.onFilesChanged(updatedFiles);
    }
  }

  render() {
    const { idn, classes, activityStore } = this.props;
    if (!idn) return '<></>';

    let baseS3Path = 'uploads/' + idn.replace(/ |\//g, '_') + '/';

    let netProgressPercent = 0;

    if (activityStore.state === 'uploadsInProgress') {
      let totalProg = activityStore.submissionFiles.reduce((accum, value) => {
        return accum + value.progressPercent;
      }, 0);
      netProgressPercent = Math.floor(
        totalProg / activityStore.submissionFiles.length
      );
    }

    return (
      <>
        {(activityStore.state === 'uploadsInProgress' ||
          activityStore.state === 'inprog' ||
          activityStore.state === 'readyForUploads') && (
          <div className={classes.progressBar}>
            <h5>Attempting to upload the documents ....</h5>
            <LinearProgress variant="determinate" value={netProgressPercent} />
          </div>
        )}
        <ReactS3UploaderSC
          onFilesAttached={this.filesAttached}
          //signingUrl="/api/getsignedurl"
          //server={process.env.REACT_APP_INK_API_URL}
          signingUrl="/api/s3/object/getsignedurl_for_upload"
          server={process.env.REACT_APP_WOS_API_URL}
          signingUrlMethod="GET"
          disabled={this.props.disabled}
          accept="*/*"
          s3path={baseS3Path}
          onProgress={this.onUploadProgress}
          onError={this.onUploadError}
          onFinish={this.onUploadFinish}
          beginUploadFiles={this.props.beginUploadFiles}
          signingUrlHeaders={{
            Authorization: 'Bearer ' + this.props.me.saToken,
            'X-SCH-LVNSN': 'jIg1_i6t8kCHKm45_dJE3gnD_vx3rCs.4.6.0',
          }}
          signingUrlQueryParams={this.props.additionalSigningParams}
          signingUrlWithCredentials={false} // in case when need to pass authentication credentials via CORS
          uploadRequestHeaders={{ 'x-amz-acl': 'bucket-owner-full-control' }} // this is the default
          contentDisposition="attachment"
          inputRef={(cmp) => (this.uploadInput = cmp)}
          autoUpload={this.props.autoUpload}
          activityStoreState={activityStore.state}
        />
        {activityStore[this.props.purpose + 'Files'].length > 0 && (
          <div
            style={{
              border: '1px solid rgba(0, 0, 0, 0.54)',
              borderRadius: 3,
              padding: 10,
              width: '96%',
            }}>
            {activityStore[this.props.purpose + 'Files'].map((f, fIdx) => (
              <div key={fIdx} style={{ flexGrow: 1 }}>
                <Button
                  className={classes.chip}
                  color={f.progressPercent === 100 ? 'secondary' : 'primary'}
                  onDoubleClick={this.handleDelete.bind(this, fIdx)}
                  variant="contained">
                  {!f.err
                    ? f.name + ' ' + f.progressPercent + '% uploaded'
                    : f.name + ' FAILED UPLOAD - remove and try again'}
                </Button>
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UploadToS3V2))
);
