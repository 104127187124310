import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchAccountCMNs_ac,
  setAccountCMNsToEmpty_ac,
  updateCmnAppointment_ac
} from '../../actions/account_in_view.ac.js'
import { updateIvrInfo_ac } from '../../actions/ivr_info.ac.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto'
  },
  payerName: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 8,
    color: '#65502f'
  },
  treeName: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13)
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200
  },

  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8
  },
  emph: {
    color: '#0b5277',
    fontWeight: 600,
    marginBottom: 4
  },
  paNumber: {
    fontWeight: 600
  },
  notLogged: {
    color: '#757575'
  },
  tabLabelContainer: { padding: '3px 16px 3px 0' },
  byTabKlass: {
    minWidth: 12,
    minHeight: 24
  }
})

const CERT = { O: 'Original', R: 'Recert', S: 'Revised' }

class CMNBranch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnStatus: 'enabled'
    }
  }
  onCmnBranchSelectedForPreviewData = branch => () => {
    this.setState({ btnStatus: 'disabled' })
    this.props.onSelect(branch.id_cmn, branch.cmn_branch_id, branch)
  }

  render() {
    const { branch, hasIvrActivity, classes } = this.props
    branch.hcpcs.sort((a, b) => (a.hcpc > b.hcpc ? 1 : -1))
    return (
      <>
        <Grid container direction="row" justify="flex-start" spacing={8}>
          <Grid item sm={1}>
            <Typography>B{this.props.bIdx + 1})</Typography>
          </Grid>
          <Grid item sm={6}>
            <div>
              <Typography style={{ fontWeight: 'bold' }}>
                Branch: {this.props.branch.cmn_branch_id}
              </Typography>

              <Typography style={{ fontWeight: 'bold' }}>
                Eff: {moment(branch.cmn_branch_eff_dt).format('MM-DD-YYYY')} -
                Dur: {branch.cmn_branch_dur}{' '}
                <strong>{CERT[branch.certtype]}</strong>
              </Typography>
            </div>
            <div>
              {branch.cmn_branch_return_dt && (
                <Typography>
                  CMN Return:{' '}
                  {moment(branch.cmn_branch_return_dt).format('MM-DD-YYYY')}
                </Typography>
              )}
            </div>
            <div>
              {branch.writtenorderreturndate && (
                <Typography>
                  WOPD Return:{' '}
                  {moment(branch.writtenorderreturndate).format('MM-DD-YYYY')}
                </Typography>
              )}

              {branch.appt && (
                <Typography style={{ color: 'blue', fontWeight: 700 }}>
                  Appointment:{' '}
                  {moment(branch.appt.appt_date_utc).format('MM-DD-YYYY')}
                </Typography>
              )}
            </div>
            {'function' === typeof this.props.onSelect && (
              <div>
                <Button
                  onClick={this.onCmnBranchSelectedForPreviewData(branch)}
                  variant="contained"
                  size="small"
                  disabled={this.state.btnStatus === 'disabled'}
                  color="secondary">
                  Populate Form
                </Button>
              </div>
            )}
            {hasIvrActivity && (
              <div>
                <Typography
                  style={{ fontSize: 12, fontWeight: 'bold', color: 'red' }}>
                  Automated {hasIvrActivity} IVR actvity occurred for this
                  branch.
                </Typography>
              </div>
            )}
          </Grid>
          <Grid item sm={5}>
            <div>
              <Typography>
                {branch.hcpcs
                  .filter(h => h.trim().length)
                  .sort()
                  .join(',')}
              </Typography>
            </div>
            <div>
              <Typography style={{ color: 'purple' }}>
                {branch.mods.join(',')}
              </Typography>
            </div>
            <div>
              <Typography style={{ color: 'darkgray' }}>
                DX:{' '}
                {branch.dxs
                  .filter(d => d)
                  .sort()
                  .join(',')}
              </Typography>
            </div>
          </Grid>
        </Grid>
        {this.props.enableApptForm && hasIvrActivity && (
          <ApptForm
            workflow={hasIvrActivity}
            onUpdateCmnAppt={this.props.onUpdateCmnAppt}
            onUpdateIvrInfo={this.props.onUpdateIvrInfo}
            branch={branch}
            tree={this.props.tree}
            classes={classes}
          />
        )}
      </>
    )
  }
}

class CMNTree extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      btnStatus: 'enabled'
    }
  }

  onCmnTreeSelectedForPreviewData = treeId => () => {
    this.setState({ btnStatus: 'disabled' })
    this.props.onSelect(treeId)
  }

  render() {
    const {
      tree,
      expMedicareOxyCmnInfo,
      expCCSMCalRxInfo,
      classes
    } = this.props
    let expBranchInfo_oxyMedicare = { ID_MyBranch: -1 }
    let ccsRx_PaRxPairs = []

    if (!_.isEmpty(expMedicareOxyCmnInfo)) {
      if (expMedicareOxyCmnInfo.hdmsValues.ID_CMNShell === tree.cmn_tree_id) {
        expBranchInfo_oxyMedicare = expMedicareOxyCmnInfo.hdmsValues
      }
    } else if (!_.isEmpty(expCCSMCalRxInfo)) {
      if (expCCSMCalRxInfo.hdmsValues.cmn_tree_id === tree.cmn_tree_id) {
        ccsRx_PaRxPairs = expCCSMCalRxInfo.hdmsValues.pa_cmn_pairs.map(r => r)
      }
    }

    return (
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          style={{ margin: '6px 0' }}
          expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.payerName}>
            T{this.props.tIdx + 1}) Dr. {tree.dr_last_name},{' '}
            {tree.dr_first_name} ({tree.dr_type}):
          </Typography>
          <Typography className={classes.treeName}>
            {tree.cmn_tree_name} - ID{tree.cmn_tree_id}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="column" flex="grow">
            {tree.effective_method && (
              <Grid item>
                <Typography className={classes.emph}>
                  Dr. Preferred Contact:{' '}
                  {tree.effective_method.method +
                    ' ' +
                    tree.effective_method.val}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <Typography className={classes.payerName}>
                Phone: {tree.dr_phone} / Fax: {tree.dr_fax} / NPI: {tree.dr_npi}
              </Typography>
            </Grid>

            <Grid item>
              {tree.branches.map((branch, bIdx) => (
                <CMNBranch
                  tree={tree}
                  enableApptForm={this.props.enableApptForm}
                  onUpdateCmnAppt={this.props.onUpdateCmnAppt}
                  onUpdateIvrInfo={this.props.onUpdateIvrInfo}
                  key={branch.cmn_branch_id}
                  bIdx={bIdx}
                  hasIvrActivity={
                    branch.cmn_branch_id ===
                    expBranchInfo_oxyMedicare.ID_MyBranch
                      ? 'medicare_oxygen'
                      : ccsRx_PaRxPairs.filter(r => {
                          return branch.cmn_branch_id === r.cmn_branch_id
                        }).length > 0
                      ? 'ccsmedical_allproducts'
                      : false
                  }
                  classes={classes}
                  onSelect={this.props.onSelect}
                  branch={branch}
                />
              ))}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

class ApptForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      display: false,
      dateValid: false,
      apptDateStr: '',
      apptDateMM_DD_YYYY: ''
    }
    this.setApptDate = this.setApptDate.bind(this)
    this.updateApptDate = this.updateApptDate.bind(this)
    this.toggleDisplay = this.toggleDisplay.bind(this)
  }
  toggleDisplay() {
    this.setState({ display: !this.state.display })
  }
  updateApptDate() {
    let params = {
      cmn_branch_id: this.props.branch.cmn_branch_id,
      cmn_tree_id: this.props.tree.cmn_tree_id,
      account: this.props.tree.account,
      member: this.props.tree.member,
      within_range: true,
      workflow: this.props.workflow,
      appt_date_utc: moment(this.state.apptDateMM_DD_YYYY, 'MM-DD-YYYY')
        .toDate()
        .getTime()
    }

    console.log(params)
    this.props.onUpdateCmnAppt(params)

    let paramsForIvr = {
      acctMember: [this.props.tree.account, this.props.tree.member].join('-'),
      workflow: this.props.workflow,
      appt_date_utc: params.appt_date_utc
    }
    this.props.onUpdateIvrInfo(paramsForIvr)
    this.setState({ display: false })
    return
  }
  setApptDate(e) {
    let str = e.target.value
    let m = moment(str.replace(/[^0-9]/), 'MMDDYYYY')
    let valid = false
    if (m.isValid()) {
      let daysAway = m.diff(
        new moment()
          .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0),
        'days'
      )
      if (daysAway > -180 && daysAway < 180) {
        valid = true
      }
    }
    this.setState({
      apptDateStr: str,
      apptDateMM_DD_YYYY: m.format('MM-DD-YYYY'),
      dateValid: valid
    })
  }
  render() {
    const { classes } = this.props
    return (
      <main className={classes.content}>
        <FormControl
          style={{ marginLeft: 0 }}
          className={classes.formControl}
          fullWidth>
          <FormLabel
            onClick={this.toggleDisplay}
            style={{
              fontSize: 11,
              color: 'blue',
              fontWeight: 'bold',
              cursor: 'pointer'
            }}
            component="label">
            Click here to add/update appointment date associated with this
            branch [+/-]
          </FormLabel>
        </FormControl>

        {this.state.display && (
          <>
            <FormControl
              style={{ marginLeft: 0 }}
              className={classes.formControl}
              fullWidth>
              <FormLabel onClick={this.toggleDisplay} component="label">
                Update Appointment Date (MM-DD-YYYY):
              </FormLabel>
              <FormHelperText>
                Ensure that this appointment is with the Doctor associated with
                this CMN Tree AND WITHIN RANGE!!!!
              </FormHelperText>
              <TextField
                fullWidth
                multiline
                onChange={this.setApptDate}
                value={this.state.apptDate}
              />
            </FormControl>
            <FormControl
              style={{ marginLeft: 0 }}
              className={classes.formControl}>
              <Button
                variant="contained"
                disabled={!this.state.dateValid}
                size="small"
                color="secondary"
                onClick={this.updateApptDate}>
                Save Appointment Date as {this.state.apptDateMM_DD_YYYY}
              </Button>
            </FormControl>
          </>
        )}
      </main>
    )
  }
}

class AccountCMNs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAccountPosition: 0
    }
    this.handleSelectAccount = this.handleSelectAccount.bind(this)
    this.onSelect = this.onSelect.bind(this)
  }

  handleSelectAccount(e, _pos) {
    const { idnInView } = this.props
    this.setState({ selectedAccountPosition: _pos })

    const [account, member] = idnInView.idnDoc.aa[_pos].split(/-/)
    if (typeof this.props.onAccountPositionSelected === 'function') {
      this.props.onAccountPositionSelected(_pos)
    }
    return this.props.fetchAccountCMNs_ac(
      parseInt(account, 10),
      parseInt(member, 10)
    )
  }

  componentDidMount() {
    const { idnInView, accountCMNsInView } = this.props

    if (idnInView.idnDoc === null) return
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) return

    if (accountCMNsInView.cmnsPkg === null) {
      if (accountCMNsInView.isFetching) return
      if (accountCMNsInView.error) return
      if (_.get(idnInView.idnDoc, 'aa', []).length === 0) {
        this.props.setAccountCMNsToEmpty_ac()
        return
      }

      const [account, member] = idnInView.idnDoc.aa[
        this.state.selectedAccountPosition
      ].split(/-/)
      return this.props.fetchAccountCMNs_ac(
        parseInt(account, 10),
        parseInt(member, 10)
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { idnInView, accountCMNsInView } = this.props
    if (idnInView.idnDoc === null) return
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) return

    if (
      idnInView.idnStr !== prevProps.idnInView.idnStr ||
      accountCMNsInView.cmnsPkg === null ||
      accountCMNsInView.account !== prevProps.accountCMNsInView.account ||
      accountCMNsInView.member !== prevProps.accountCMNsInView.member
    ) {
      if (accountCMNsInView.isFetching) return
      if (accountCMNsInView.error) return
      if (_.get(idnInView.idnDoc, 'aa', []).length === 0) {
        this.props.setAccountCMNsToEmpty_ac()
        return
      }

      const [account, member] = idnInView.idnDoc.aa[
        this.state.selectedAccountPosition
      ].split(/-/)
      return this.props.fetchAccountCMNs_ac(
        parseInt(account, 10),
        parseInt(member, 10)
      )
    }
  }

  onSelect(treeId, branchId, branch) {
    this.props.onCmnTreeSelected(
      treeId,
      branchId,
      this.state.selectedAccountPosition,
      branch
    )
  }

  render() {
    const { classes, idnInView, accountCMNsInView } = this.props
    const { cmnsPkg, isFetching } = accountCMNsInView
    const byTabKlass = {
      root: classes.byTabKlass
    }
    if (0 === _.get(idnInView.idnDoc, 'aa', []).length) {
      return <div className={classes.card}>NO HDMS Account is attached</div>
    }
    return (
      <div className={classes.card}>
        <Tabs
          classes={byTabKlass}
          value={this.state.selectedAccountPosition}
          onChange={this.handleSelectAccount}
          indicatorColor="secondary"
          textColor="secondary">
          {_.get(idnInView.idnDoc, 'aa', []).map((act, pos) => (
            <Tab
              classes={byTabKlass}
              key={act}
              value={pos}
              label={'A' + act + ' CMNS'}
            />
          ))}
        </Tabs>
        {isFetching && <div>...loading</div>}
        {cmnsPkg && (
          <>
            {cmnsPkg.cmn_trees.length === 0 && (
              <Typography className={classes.payerName}>
                No CMN Trees (shells) have been created for HDMS Account: A
                {accountCMNsInView.account}-{accountCMNsInView.member}
              </Typography>
            )}

            {cmnsPkg.cmn_trees.map((tree, tIdx) => (
              <CMNTree
                onUpdateCmnAppt={this.props.updateCmnAppointment_ac}
                onUpdateIvrInfo={this.props.updateIvrInfo_ac}
                expMedicareOxyCmnInfo={this.props.expMedicareOxyCmnInfo}
                expCCSMCalRxInfo={this.props.expCCSMCalRxInfo}
                providedFaxNumber={
                  this.props.providedFaxNumber
                    ? this.props.providedFaxNumber
                    : ''
                }
                onSelect={
                  this.props.enableRenderButtons ? this.onSelect : false
                }
                enableApptForm={this.props.enableApptForm}
                tIdx={tIdx}
                key={tree.cmn_tree_id}
                classes={classes}
                tree={tree}
              />
            ))}
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  accountCMNsInView: stateFromStore.accountCMNsInView,
  ivrInfo: stateFromStore.ivrInfo,
  idnInView: stateFromStore.idnInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAccountCMNs_ac,
      setAccountCMNsToEmpty_ac,
      updateCmnAppointment_ac,
      updateIvrInfo_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountCMNs))
)
