import {
  FETCH_FA_LOOKUPS,
  FETCH_FA_LOOKUPS_SUCCESSFUL,
  FETCH_FA_LOOKUPS_FAILED,
  FETCH_FA_LOOKUP_DETAIL,
  FETCH_FA_LOOKUP_DETAIL_SUCCESSFUL,
  FETCH_FA_LOOKUP_DETAIL_FAILED,
  FETCH_FA_LOOKUPS_STRUCTURE,
  FETCH_FA_LOOKUPS_STRUCTURE_SUCCESSFUL,
  FETCH_FA_LOOKUPS_STRUCTURE_FAILED,
  ADD_FA_LOOKUP,
  ADD_FA_LOOKUP_SUCCESSFUL,
  ADD_FA_LOOKUP_FAILED,
  REMOVE_FA_LOOKUP,
  REMOVE_FA_LOOKUP_SUCCESSFUL,
  REMOVE_FA_LOOKUP_FAILED,
  UPDATE_FA_LOOKUPS_SEQUENCE,
  UPDATE_FA_LOOKUPS_SEQUENCE_SUCCESSFUL,
  UPDATE_FA_LOOKUPS_SEQUENCE_FAILED
} from '../../actions/fa_lookups.ac'

export const initialStates = {
  faLookups: {
    structure: {
      data: {},
      isFetching: false,
      error: null
    },
    list: {
      data: [],
      isFetching: false,
      error: null
    },
    detail: {
      data: null,
      isFetching: false,
      error: null
    },
    isAddingFALookup: false,
    isRemovingFALookup: false,
    isUpdatingFALookupsSequence: false
  }
}

const faLookups = (state = initialStates.faLookups, action) => {
  switch (action.type) {
    // FETCH_FA_LOOKUPS_STRUCTURE
    case FETCH_FA_LOOKUPS_STRUCTURE:
      return Object.assign({}, state, {
        structure: {
          ...state.structure,
          isFetching: true
        }
      })
    case FETCH_FA_LOOKUPS_STRUCTURE_SUCCESSFUL:
      return Object.assign({}, state, {
        structure: {
          isFetching: false,
          data: action.data.pkg,
          error: null
        },
        list: {
          ...state.list
        },
        detail: {
          ...state.detail
        }
      })
    case FETCH_FA_LOOKUPS_STRUCTURE_FAILED:
      return Object.assign({}, state, {
        structure: {
          isFetching: false,
          data: {},
          error: action.error
        }
      })

    // FETCH_FA_LOOKUPS
    case FETCH_FA_LOOKUPS:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          isFetching: true
        }
      })
    case FETCH_FA_LOOKUPS_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: action.data.rr,
          error: null
        },
        detail: {
          ...state.detail
        }
      })
    case FETCH_FA_LOOKUPS_FAILED:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: [],
          error: action.error
        }
      })

    // FETCH_FA_LOOKUP_DETAIL
    case FETCH_FA_LOOKUP_DETAIL:
      return Object.assign({}, state, {
        detail: {
          ...state.detail,
          isFetching: true
        }
      })
    case FETCH_FA_LOOKUP_DETAIL_SUCCESSFUL:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: action.data,
          error: null
        }
      })
    case FETCH_FA_LOOKUP_DETAIL_FAILED:
      return Object.assign({}, state, {
        detail: {
          isFetching: false,
          data: null,
          error: action.error
        }
      })

    // ADD_FA_LOOKUP
    case ADD_FA_LOOKUP:
      return Object.assign({}, state, {
        isAddingFALookup: true
      })
    case ADD_FA_LOOKUP_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          data: [...state.list.data, action.data]
        },
        isAddingFALookup: false
      })
    case ADD_FA_LOOKUP_FAILED:
      return Object.assign({}, state, {
        isAddingFALookup: false
      })

    // REMOVE_FA_LOOKUP
    case REMOVE_FA_LOOKUP:
      return Object.assign({}, state, {
        isRemovingFALookup: true
      })
    case REMOVE_FA_LOOKUP_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          data: state.list.data.filter(
            (_, index) => action.removedIndex !== undefined
              ? index !== action.removedIndex
              : index !== action.data.removed_seq
          )
        },
        detail: {
          ...state.detail,
          data: null
        },
        isRemovingFALookup: false
      })
    case REMOVE_FA_LOOKUP_FAILED:
      return Object.assign({}, state, {
        isRemovingFALookup: false
      })

    // UPDATE_FA_LOOKUPS_SEQUENCE
    case UPDATE_FA_LOOKUPS_SEQUENCE:
      return Object.assign({}, state, {
        isUpdatingFALookupsSequence: true
      })
    case UPDATE_FA_LOOKUPS_SEQUENCE_SUCCESSFUL:
    case UPDATE_FA_LOOKUPS_SEQUENCE_FAILED:
      return Object.assign({}, state, {
        isUpdatingFALookupsSequence: false
      })

    default:
      return state
  }
}

export default { faLookups }
