import { IndexLogsActionTypes } from '../actions/index_logs.ac.js';

export function indexLogsInView(
    state = {
        isFetching: false,
        params: null,
        records: null,
        tags: null,
        pagination: null,
        error: null,
    },
    action
) {
    switch (action.type) {
        case IndexLogsActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case IndexLogsActionTypes.RECEIVED_LOGS:
            return {
                ...state,
                pagination: action.pagination,
                records: action.records,
                tags: action.tags,
            };

        case IndexLogsActionTypes.FAILED_RECEIVING_LOGS:
            return {
                ...state,
                pagination: null,
                records: null,
                tags: null,
                error: action.error,
            };

        default:
            return state;
    }
};
