import { TaskActionTypes } from '../actions/care_connect_tasks.ac.js';

export function careConnectTasksInView(
    state = {
        isFetching: false,
        params: null,
        records: null,
        error: null,
        pagination: null,
        tasks: null,
        persons: null,
        isFetchingDetails: null,
        taskDetailsError: null,
        tags: null
    },
    action
) {
    switch (action.type) {
        case TaskActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case TaskActionTypes.RECEIVED_ALL_TASKS:
            return {
                ...state,
                records: action.records,
            };

        case TaskActionTypes.FAILED_RECEIVING_ALL_TASKS:
            return {
                ...state,
                records: null,
                error: action.error,
            };

        case TaskActionTypes.SET_IS_FETCHING_DETAILS:
            return {
                ...state,
                isFetchingDetails: action.isFetchingDetails,
            };
        case TaskActionTypes.RECEIVED_TASK_DETAILS:
            return {
                ...state,
                tasks: action.tasks,
                pagination: action.pagination,
                tags: action.tags,
                persons: action.persons,
                selectedTaskName: action.selectedTaskName
            };

        case TaskActionTypes.FAILED_RECEIVING_TASK_DETAILS:
            return {
                ...state,
                tasks: null,
                pagination: null,
                taskDetailsError: action.error,
            };
        default:
            return state;
    }
};
