import React from 'react'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import CatalogLanding from '../PlansOfService/CatalogLanding'

const styles = theme => ({
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  fullHeight: {
    height: '100%'
  }
})

const CatalogMaintainance = props => {
  const { classes, me } = props

  const isCatalogueMaintainer = me?.user?.roles?.includes('catalog-maintenance')

  return (
    <Grid container direction="row" spacing={8} className={classes.scrollArea}>
      <Grid
        item
        xs={isCatalogueMaintainer ? 12 : 8}
        className={classes.fullHeight}>
        <CatalogLanding
          isMaintainance={true}
          isCatalogueMaintainer={isCatalogueMaintainer}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me
})

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(withStyles(styles)(CatalogMaintainance))
)
