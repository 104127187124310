import _ from 'lodash'
import moment from 'moment'
import { PAPCalculatorActionTypes } from '../actions/pap_calculator.ac'
import DateUtil from './../util/dateProcessing'
import {
  FULL_FACE_CODES,
  NASAL_PILLOW_CODES,
  NASAL_CUSHION_CODES
} from './../constants/PAPFrequencyConstant'

const getFilteredList = (list, mask_type) => {
  let hcpc_codes_to_remove = [];
  switch (mask_type) {
    case 'fullface':
      hcpc_codes_to_remove = [
        ...NASAL_CUSHION_CODES,
        ...NASAL_PILLOW_CODES
      ]
      break;

    case 'nasal-cushion':
      hcpc_codes_to_remove = [
        ...FULL_FACE_CODES,
        'A7033'
      ]
      break;

    case 'nasal-pillow':
      hcpc_codes_to_remove = [
        ...FULL_FACE_CODES,
        'A7032'
      ]
      break;

    default:
      hcpc_codes_to_remove = [];
      break;
  }

  const itemsToBeRemovedPerMaskTypes = list.filter(item => hcpc_codes_to_remove.includes(item.hcpc_code));

  return _.pullAllBy([...list], itemsToBeRemovedPerMaskTypes, 'hcpc_code');
}

export const papCalculator = (
  state = {
    isFetching: false,
    isSaving: false,
    frequencyList: [],
    pagination: null,
    error: null,
    mask_type: 'nasal-cushion',
    payer: 'medi-cal',
    last_date_of_service: null,
    selectedItems: []
  },
  action
) => {
  switch (action.type) {
    case PAPCalculatorActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }

    case PAPCalculatorActionTypes.SET_IS_SAVING:
      return {
        ...state,
        isFetching: action.isSaving
      }

    case PAPCalculatorActionTypes.SET_FREQUENCY_IDENTIFIERS:
      const { frequencyList: fLists, mask_type } = state;

      let updatedState = {
        mask_type: action.payload.mask_type,
        payer: action.payload.payer,
        last_date_of_service: action.payload.last_date_of_service,
      }

      if (action.payload.mask_type !== mask_type) {
        const filteredFrequencyList = getFilteredList(fLists, action.payload.mask_type);
        updatedState['frequencyList'] = [...filteredFrequencyList]
      }

      return {
        ...state,
        ...updatedState
      }

    case PAPCalculatorActionTypes.LOAD_FREQUENCY_LIST:
      return {
        ...state,
        frequencyList: getFilteredList(action.list, state.mask_type)
      }

    case PAPCalculatorActionTypes.ITEMS_SELECTED:
      const { frequencyList } = state;
      frequencyList
        .find((item) => item.hcpc_code === action.selected_hcpc_code)
        .isChecked = action.checked;

      const selectedItems = frequencyList.filter(item => item.isChecked);

      return {
        ...state,
        frequencyList: [...frequencyList],
        selectedItems: [...selectedItems]
      }

    case PAPCalculatorActionTypes.ITEMS_DOS_CHANGED:
      const { frequencyList: list } = state;
      const selectedItem = list
        .find((item) => item.hcpc_code === action.selected_hcpc_code)
      selectedItem.dos = action.dos
      if (DateUtil.isValidDate(action.dos)) {
        selectedItem.eligible = selectedItem.dos ? moment(selectedItem.dos).add(selectedItem.frequency, 'months') : null
      }

      return {
        ...state,
        frequencyList: [...list],
      }

    case PAPCalculatorActionTypes.RESET_ITEMS_SELECTED:
      const { frequencyList: frequencyLists } = state;
      frequencyLists.forEach(item => {
        item.isChecked = false
      })
      return {
        ...state,
        selectedItems: [],
        frequencyList: [...frequencyLists]
      }

    default:
      return {
        ...state
      }
  }
}
