import { dateFormat } from '../util/date';
import { actionTypes } from '../actions/workorders.ac';

const initialState = {
  error: null,
  workorders: null,
  isFetching: true,
};

export const workorders = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_WORKORDERS:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.RECEIVED_WORKORDERS:
      return {
        ...state,
        isFetching: false,
        workorders: action.payload,
      };

    case actionTypes.FAILED_FETCHING_WORKORDERS:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return { ...state };
  }
};

export const workorderSorting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SAVE_WORKORDERS_SORTING:
      return { ...action.filterData };

    default:
      return { ...state };
  }
};

export const workorderSearchFilters = (state, action) => {
  switch (action.type) {
    case actionTypes.SAVE_WORKORDER_SEARCH_FILTERS:
      return {
        ...action.filterData,
        req_dt: dateFormat(action.filterData.req_dt),
        sched_dt: dateFormat(action.filterData.sched_dt),
        sched_end_dt: dateFormat(action.filterData.sched_end_dt),
        req_end_dt: dateFormat(action.filterData.req_end_dt),
      };

    default:
      return { ...state };
  }
};
