import { open_snack_ac } from './snack.ac.js'

export const NotificationPreferenceActionTypes = {
    NOTIFICATION_PREFERENCE_UPDATED: 'NOTIFICATION_PREFERENCE_UPDATED',
    FAILED_UPDATING_NETWORK_PREFERENCE: 'FAILED_UPDATING_NETWORK_PREFERENCE',
    SET_IS_LOADING: 'SET_IS_LOADING'
};
export const update_notification_preference_ac = (params) => dispatch => {
    const url = '/api/legacy:portal:dfproxy';
    const path = '/patient/record/preferences/notifications';
    dispatch(loading(true));
    return window.sch.post(url, { path, payload: { ...params } })
        .then(result => {
            if (result.error) {
                throw new Error(result.error)
            }
            dispatch(
                open_snack_ac({
                    variant: 'success',
                    message: 'Notification Preferences Updated'
                })
            )
            dispatch({
                type: NotificationPreferenceActionTypes.NOTIFICATION_PREFERENCE_UPDATED,
                result: result
            });
        })
        .catch(error => {
            dispatch(
                open_snack_ac({
                    variant: 'error',
                    message: 'Failed to update notification preference'
                })
            )
            dispatch({
                type: NotificationPreferenceActionTypes.FAILED_UPDATING_NETWORK_PREFERENCE,
                error
            });
        })
        .finally(() => {
            dispatch(loading(false));
        });
}

export const loading = isLoading => ({
    type: NotificationPreferenceActionTypes.SET_IS_LOADING,
    isLoading
})