import { actionTypes } from '../actions/oxygen_counts.ac';

const initialState = {
  counts: null,
  isFetching: false,
  error: null,
  filterData: {
    groupBy: 'dc',
    dc_id: null,
    driver_id: null,
    start: new Date(),
    end: new Date(),
    prod_grouping: 'oxygen',
  },
};

export const oxygenCountsState = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_OXYGEN_COUNTS:
      return {
        ...state,
        isFetching: true,
      };

    case actionTypes.RECEIVED_OXYGEN_COUNTS:
      return {
        ...state,
        isFetching: false,
        counts: action.data,
        error: null,
      };

    case actionTypes.FAILED_FETCHING_OXYGEN_COUNTS:
      return {
        ...state,
        isFetching: false,
        counts: null,
        error: true,
      };

    case actionTypes.SAVE_OXYGEN_COUNTS_FILTERS:
      return {
        ...state,
        filterData: { ...action.filterData },
      };

    default:
      return { ...state };
  }
};
