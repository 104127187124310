export const actionTypes = {
  SET_IS_FETCHING_NEW_PATIENT_RECORD: 'SET_IS_FETCHING_NEW_PATIENT_RECORD',
  RECEIVED_NEW_PATIENT_RECORD: 'RECEIVED_NEW_PATIENT_RECORD',
  FAILED_NEW_PATIENT_RECORD: 'FAILED_NEW_PATIENT_RECORD',
  SET_SELECTED_VALUE: 'SET_SELECTED_VALUE',
  SET_SELECTED_PROPERTY: 'SET_SELECTED_PROPERTY'
}

const recordTypeMap = {
  allscripts: {
    api: '/api/allscripts/referral_detail',
    formatRecord: data => prepareFormDataFromAllscripts(data),
    responseParameter: 'parsedXML'
  },
  parachute: {
    api: '/api/parachute/orders/list',
    formatRecord: data => prepareFormDataFromParachutes(data),
    responseParameter: 'pkg'
  }
}

/**
 * Using the mapping above, fetch patient record in different recordType to
 * use same redux state.
 * @param {object} params Parameters
 * @param {string} params.id Record id
 * @param {string} params.recordType Record type
 * @param {function} callback Function to prepare form data in form component, on success
 * @returns {function}
 */
export const fetchNewPatientRecord_ac = (params, callback) => dispatch => {
  const { id, recordType = 'allscripts' } = params
  const { api, formatRecord, responseParameter } = recordTypeMap[recordType]
  let requestParams = {}
  if (recordType === 'allscripts') {
    requestParams['id'] = id
  }
  if (recordType === 'parachute') {
    requestParams['searchStr'] = id
  }
  dispatch(setIsFetching(true))
  window.sch
    .post(api, requestParams)
    .then(response => {
      const formattedRecord = formatRecord(response[responseParameter] || [])
      if (formattedRecord.err) {
        dispatch({
          type: 'OPEN_SNACK',
          variant: 'error',
          message: 'Failed to parse response!'
        })
      }

      const actionPayload = {
        type: actionTypes.RECEIVED_NEW_PATIENT_RECORD,
        record: {
          ...formattedRecord
        }
      }
      if (recordType === 'allscripts') {
        actionPayload['allScriptsReferral'] = id
      }

      dispatch(actionPayload)

      callback()
    })
    .catch(error => {
      dispatch({
        type: 'OPEN_SNACK',
        variant: 'error',
        message: 'Failed to load patient data!'
      })

      dispatch({
        type: actionTypes.FAILED_NEW_PATIENT_RECORD,
        error: true
      })
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

const setIsFetching = isFetching => ({
  type: actionTypes.SET_IS_FETCHING_NEW_PATIENT_RECORD,
  isFetching
})

const getShippingAddress = Patient => {
  if (Patient.HomeAddress) {
    const homeAddress = Patient.HomeAddress.Address || {}
    return {
      line1: homeAddress.Address1 || '',
      line2: homeAddress.Address2 || '',
      city: homeAddress.City || '',
      state: homeAddress.State?._Attributes?.Code || '',
      zip: homeAddress.ZipCode?._Attributes?.Full || ''
    }
  }
  if (
    Patient.PatientAdmission &&
    Patient.PatientAdmission.DMEGeneralInfo &&
    Patient.PatientAdmission.DMEGeneralInfo.DeliveryAddress
  ) {
    const deliveryAddress =
      Patient.PatientAdmission?.DMEGeneralInfo?.DeliveryAddress?.Address || {}
    return {
      line1: deliveryAddress.Address1 || '',
      line2: deliveryAddress.Address2 || '',
      city: deliveryAddress.City || '',
      state: deliveryAddress.State?._Attributes?.Code || '',
      zip: deliveryAddress.ZipCode?._Attributes?.Full || ''
    }
  }
}

/**
 * Prepare and return data suitable for AccountCreate form from allscripts
 * parsed-XML patient data
 * @param {object} parsedXML All-scripts patient parsed-XML data
 * @returns {object}
 */
const prepareFormDataFromAllscripts = parsedXML => {
  if (
    !parsedXML.length ||
    !parsedXML[0].OutboundDataFeed ||
    !parsedXML[0].OutboundDataFeed.Patient
  ) {
    return {}
  }
  const { Patient } = parsedXML[0]?.OutboundDataFeed
  let primaryDoc
  let refDoc
  (Patient.PatientAdmission?.Physicians?.Physician || []).forEach(p => {
    if (p.Role?.Code === 'PRIMARY_CARE_PHYSICIAN') {
      primaryDoc = { ...p }
    }

    if (p.Role?.Code === 'ATTENDING_PHYSICIAN') {
      refDoc = { ...p }
    }
  })

  return {
    Sex: Patient.Gender?.Code || '',
    Phone: Patient.HomePhoneNumber?.PhoneNumber?.Number,
    AltPhone:
      Patient.EmergencyContacts?.Contact?.HomePhone?.PhoneNumber?.Number,
    ID_Default_Referring_Doctor: primaryDoc
      ? {
        value: {
          first: primaryDoc.FirstName || '',
          last: primaryDoc.LastName || ''
        },
        valueTxt: [
          primaryDoc.LastName || '',
          primaryDoc.FirstName || '',
          (primaryDoc.NPI && primaryDoc.NPI) || ''
        ].join(' ')
      }
      : null,
    ID_Referral_Source: refDoc
      ? {
        value: {
          first: refDoc.FirstName || '',
          last: refDoc.LastName || ''
        },
        valueTxt: [
          refDoc.LastName || '',
          refDoc.FirstName || '',
          (refDoc.NPI && refDoc.NPI) || ''
        ].join(' ')
      }
      : null,
    dxs: [
      Patient.PatientAdmission?.PrimaryDiagnosis?.toUpperCase(),
      Patient.PatientAdmission?.SecondaryDiagnosis?.toUpperCase()
    ],
    delivery_address: getShippingAddress(Patient)
  }
}

/**
 * Prepare and return data suitable for AccountCreate form from parachutes
 * patient data
 * @param {object} pkg Parachute patient data
 * @returns {object}
 */
const prepareFormDataFromParachutes = pkg => {
  const Patient = pkg.rr[0]?.order_info?.patient

  return {
    Sex: Patient.sex.substring(0, 1).toUpperCase(),
    Phone: Patient.billing_phone_number,
    AltPhone: Patient.delivery_phone_number,
    dob: Patient.birth_date,
    Height: Patient.height_in,
    Weight: Patient.weight_lb,
    billing_address: {
      ...Patient.billing_address
    },
    delivery_address: {
      ...Patient.delivery_address
    }
  }
}

export const setSelectedValueFromReferral_ac = (val) => (dispatch, getState) => {
  let { record, selected_property, selected_field } = getState().newPatientRecord
  // update the selected field
  selected_field
    ? record[selected_field]
      ? record[selected_field][selected_property] = val
      : record[selected_field] = {
        [selected_property]: val
      }
    : record[selected_property] = val

  dispatch({
    type: actionTypes.SET_SELECTED_VALUE,
    selected_value: val,
    record
  })
}

export const setSelectedPropertyToBeLoadedFromReferral_ac = (property_name, selected_field) => dispatch => {
  dispatch({
    type: actionTypes.SET_SELECTED_PROPERTY,
    property: property_name,
    selected_field
  })
}
