import {
    Paper,
    Popper,
    MenuList,
    MenuItem,
    Typography
} from '@material-ui/core'
import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export const DocMenuItems = ({ open, anchorEl, handleMenuClose, selectedDoc, docs }) => {
    return (
        <Popper open={open} anchorEl={anchorEl} id="menu-list-grow">
            <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                    <MenuList>
                        {(docs || []).map((doc, docIndex) => (
                            <MenuItem key={docIndex} onClick={handleMenuClose}>
                                <Typography
                                    color="textSecondary"
                                    onClick={() => selectedDoc(doc)}
                                >
                                    {doc.nm} {(doc.docIdx)}
                                </Typography>
                            </MenuItem>
                        ))}
                    </MenuList>
                </ClickAwayListener>
            </Paper>
        </Popper>
    )
}
