import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchExpiringPAStats_ac,
  fetchExpiringPAs_ac,
} from '../../actions/expiringPAs.ac.js';
import { lookups_ac, setTitle_ac } from '../../actions/me.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import FetchingStatementWrap from '../FetchingStatementWrap';
import FilterListing from '../FilterListing';
import ExpiringPaCard from './ExpiringPaCard';

const styles = (theme) => ({
  button: {},
  title: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  labelDarkGray: {
    color: 'rgb(142, 58, 76)',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontStyle: 'italics',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    cursor: 'pointer',
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  fullHeight: {
    height: '100%',
  },
  padding4: {
    padding: 4,
  },
});

function MyPASummary({
  classes,
  stats,
  total_in_my_selected_qs,
  fetch,
  saveToGoogleSheet,
  savingToGoogleSheet,
}) {
  return (
    <main className={classes.content}>
      <div className={classes.pagination}>
        <Typography
          className={classes.pageStatement}
          style={{ color: 'blue' }}
          color="textSecondary">
          My Activity - BatchDate Range: {stats.staging_info.date_range_str}
        </Typography>
      </div>

      <div className={classes.scrollArea}>
        <div className={classes.padding4}>
          {stats && (
            <Grid container spacing={8} direction="column">
              <Grid item>
                <label className={classes.label}>
                  My Activity - Last Action
                </label>
                {stats.my_activity.map((r, kIdx) => (
                  <div key={kIdx} className={classes.summaryEntryWrap}>
                    <span className={classes.summaryEntry}>
                      {r._id.toUpperCase()} {r.n}
                    </span>
                    <div className={classes.summaryCountWrap} />
                  </div>
                ))}
                <hr />
                <label className={classes.label}>
                  Total Workunits in My Selected Qs
                </label>
                {stats.my_qs_by_q.map((r, kIdx) => (
                  <div key={kIdx} className={classes.summaryEntryWrap}>
                    <span className={classes.summaryEntry}>
                      {r._id.toUpperCase()} {r.n}
                    </span>
                    <div className={classes.summaryCountWrap} />
                  </div>
                ))}
                <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={fetch}>
                  Refresh List With My Selected Qs
                </Button>
                <hr />
                <Button
                  color="primary"
                  size="small"
                  disabled={savingToGoogleSheet}
                  variant="outlined"
                  onClick={saveToGoogleSheet}>
                  Save to Google Sheet ({total_in_my_selected_qs} records)
                </Button>
                <hr />
                <label className={classes.label}>
                  Workunits in My Q By Doctor
                </label>
                {stats.my_qs_by_doc.map((r, kIdx) => (
                  <div key={kIdx} className={classes.summaryEntryWrap}>
                    <span className={classes.summaryEntry}>
                      {r._id.toUpperCase()} {r.n}
                    </span>
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </main>
  );
}

class ExpiringPAsLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      last_stats_ts: null,
      lastFilterBy: 'filters',
      lastFilterParams: { by: 'filters', val: null }, //val gets populated after first default fetch
      filterSequence: [],
      filters: {},
      savingToGoogleSheet: false,
    };

    this.payer_types = props.match.params.payer_types;
    this.onFilterClearField = this.onFilterClearField.bind(this);
    this.onFilterToggle = this.onFilterToggle.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.fetch = this.fetch.bind(this);
    this.fetchStats = this.fetchStats.bind(this);
    this.fetchByUA_nm = this.fetchByUA_nm.bind(this);
    this.saveToGoogleSheet = this.saveToGoogleSheet.bind(this);
  }

  //only applies to checkbox filters
  onFilterToggle(filterName, on) {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          options: this.state.filters[filterName].options.map((o) => {
            o.checked = on;
            return o;
          }),
        },
      },
    });
  }

  componentDidMount() {
    const { expiringPAs } = this.props;
    if (this.props.me.user === null) {
      return;
    }

    if (
      expiringPAs.isFetchingStats === false &&
      expiringPAs.stats === null &&
      expiringPAs.statsError === null
    ) {
      return this.fetchStats();
    }

    if (expiringPAs.stats === null) return;

    this.props.setTitle_ac('Expiring PAs');
    if (
      expiringPAs.isFetching === false &&
      expiringPAs.records === null &&
      !expiringPAs.error
    ) {
      return this.fetch();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { expiringPAs } = this.props;
    if (this.props.me.user === null) {
      return;
    }

    if (
      expiringPAs.isFetchingStats === false &&
      expiringPAs.stats === null &&
      expiringPAs.statsError === null
    ) {
      return this.fetchStats();
    }

    if (expiringPAs.stats === null) return;

    /* update state filters with filters from server */
    if (expiringPAs.stats.last_stats_ts !== this.state.last_stats_ts) {
      let _filters = {
        ...expiringPAs.stats.filters,
      };
      let _filterSequence = [..._.keys(_filters)];

      this.setState({
        filters: _filters,
        filterSequence: _filterSequence,
        last_stats_ts: expiringPAs.stats.last_stats_ts,
      });
      return;
    }

    if (
      expiringPAs.isFetching === false &&
      expiringPAs.records === null &&
      !expiringPAs.error
    ) {
      return this.fetch();
    }

    if (prevState.page !== this.state.page) {
      switch (this.state.lastFilterBy) {
        case 'qname':
        case 'ua_action':
        case 'u_nm': {
          return this.fetchByUA_nm(
            this.state.lastFilterBy,
            this.state.lastFilterParams.val,
            this.state.page
          )();
        }
        default:
          return this.fetch();
      }
    }
  }

  fetchStats() {
    //construct payload based on filters
    this.props.fetchExpiringPAStats_ac(this.payer_types);
    return;
  }

  fetch() {
    if (this.props.expiringPAs.stats === null) {
      console.error('stats not available yet, aborting fetch()');
      return;
    }
    //construct payload based on filters
    let selectedFilters = {};
    this.state.filterSequence.forEach((fName) => {
      const f = this.state.filters[fName];
      if (f.options) {
        selectedFilters[fName] = f.options
          .filter((o) => o.checked)
          .map((o) => o.code);
      } else {
        selectedFilters[fName] = f.value.toString();
      }
    });
    this.props.fetchExpiringPAs_ac({
      payer_types: this.payer_types,
      by: 'filters',
      val: selectedFilters,
      page: this.state.page,
    });
    this.setState({
      lastFilterBy: 'filters',
      lastFilterParams: { by: 'filters', val: selectedFilters },
    });
    return;
  }

  fetchByUA_nm = (by, val, page) => (evt) => {
    this.props.fetchExpiringPAs_ac({
      payer_types: this.payer_types,
      by,
      val: { [by]: val },
      page,
    });
    this.setState({
      lastFilterBy: by, //'u_nm',
      lastFilterParams: { by, val },
    });
    return;
  };

  downloadCSV() {
    const path = '/api/expiring-pas-mco/csv/user';
    window.sch.download_as_csv(path, 'myExpiring.csv');
  }

  saveToGoogleSheet() {
    const path = `/api/expiring-pas-mco/csv/${this.payer_types}/user`;

    this.setState({ savingToGoogleSheet: true });
    return window.sch
      .get(path)
      .then((result) => {
        this.setState({ savingToGoogleSheet: false });
        if (result.error) {
          throw new Error(result.error);
        }
        window.open(result.sheet.spreadsheetUrl, result.sheet.properties.title);
      })
      .catch((error) => {
        this.setState({ savingToGoogleSheet: false });
        console.error(error);
        return this.props.open_snack_ac({
          variant: 'error',
          message:
            'Failed creating google sheet ' + (typeof error.error === 'string')
              ? error.error
              : '',
        });
      });
  }

  onFilterChange = (filterName) => (evt) => {
    let sf = _.cloneDeep(this.state.filters[filterName]);
    switch (sf.kind) {
      case 'text':
        sf.value = evt.target.value;
        break;
      default:
        if (sf.options) {
          sf.options = sf.options.map((s) => {
            if (evt.target.value === s.code) {
              s.checked = evt.target.checked;
            }
            return s;
          });
        }
        break;
    }

    this.setState({ filters: { ...this.state.filters, [filterName]: sf } });
  };

  onFilterClearField() {}

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) });
  }

  render() {
    const { classes, expiringPAs } = this.props;
    const title = 'Expiring PAs Productivity Report';

    if (expiringPAs.stats === null || expiringPAs.records === null) {
      return <FetchingStatementWrap title={title} />;
    }

    if (expiringPAs.error) {
      return (
        <div className={classes.padding4}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      );
    }

    const { stats } = expiringPAs;
    const total_in_my_selected_qs = stats.my_qs_by_q.reduce((acc, cv) => {
      acc += cv.n;
      return acc;
    }, 0);

    let pageSelector = '';
    const pg = expiringPAs.pagination;
    let pageStatement = '';

    if (pg) {
      let selFiltersStatement;
      switch (this.state.lastFilterBy) {
        case 'filters':
          selFiltersStatement =
            'Qs:' +
            _.get(this.state, 'lastFilterParams.val.qname', ['???']).join(',');
          break;
        case 'u_nm':
          selFiltersStatement = 'Worked by: ' + this.state.lastFilterParams.val;
          break;
        default:
          selFiltersStatement = '??';
          break;
      }

      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Records',
        'Page ' + (this.state.page + 1) + ' of ' + pg.totalPagesAvailable,
        'Selected Filters:' + selFiltersStatement,
      ].join(' - ');

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form className={classes.padding4} autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                onChange={this.onPageChange}
                displayEmpty
                value={this.state.page}
                name="age"
                className={classes.selectEmpty}>
                {_.range(0, pg.totalPagesAvailable).map((pgNum) => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        );
      }
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        {/* BEGIN TODO CARDS LIST */}
        <Grid item xs={12} sm={6} className={classes.fullHeight}>
          <FilterListing
            onFilterToggle={this.onFilterToggle}
            filters={this.state.filters}
            filterSequence={this.state.filterSequence}
            onFilterClearField={this.onFilterClearField}
            onFilterChange={this.onFilterChange}
            applyFilters={this.fetch}
          />
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Grid container spacing={8}>
                <Grid item xs={8} sm={8}>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    {pageStatement}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {pageSelector}
                </Grid>
                <Grid item xs={6} sm={6} />
              </Grid>
            </div>

            <div className={classes.scrollArea}>
              <div className={classes.padding4}>
                {expiringPAs.records.map((r, posInSet) => {
                  return (
                    <ExpiringPaCard
                      payer_types={this.payer_types}
                      expPaDoc={r}
                      posInSet={posInSet}
                      key={posInSet}
                    />
                  );
                })}
              </div>
            </div>
          </main>
        </Grid>

        <Grid item xs={12} sm={3} className={classes.fullHeight}>
          <MyPASummary
            classes={classes}
            savingToGoogleSheet={this.state.savingToGoogleSheet}
            stats={stats}
            fetch={this.fetch}
            saveToGoogleSheet={this.saveToGoogleSheet}
            total_in_my_selected_qs={total_in_my_selected_qs}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Typography
                className={classes.pageStatement}
                style={{ color: 'blue' }}
                color="textSecondary">
                All User Activity - Batch Date Range:{' '}
                {stats.staging_info.date_range_str}
              </Typography>
            </div>

            <div className={classes.scrollArea}>
              <div className={classes.padding4}>
                {stats && (
                  <Grid container spacing={8} direction="column">
                    <Grid item>
                      <label className={classes.label}>
                        Totals this Period
                      </label>
                      <div className={classes.summaryEntryWrap}>
                        <span className={classes.summaryEntry}>
                          PAs Newly Expiring this Date Range:{' '}
                          {stats.staging_info.total_new}
                        </span>
                      </div>
                      <div className={classes.summaryEntryWrap}>
                        <span className={classes.summaryEntry}>
                          Totals PAs Expiring: {stats.staging_info.n_id_pa}
                        </span>
                      </div>
                      <hr />
                      <label className={classes.label}>
                        Workunits Worked, By Rep, Expiring this Period
                      </label>
                      <br />
                      <label className={classes.labelDarkGray}>
                        Click on Name to Filter
                      </label>
                      {stats.by_user.map((r, kIdx) => (
                        <div key={kIdx} className={classes.summaryEntryWrap}>
                          <span
                            onClick={this.fetchByUA_nm(
                              'u_nm',
                              r._id.toUpperCase(),
                              0
                            )}
                            className={classes.summaryEntry}>
                            {r._id.toUpperCase()} {r.n}
                          </span>
                          <div className={classes.summaryCountWrap} />
                        </div>
                      ))}
                      <hr />
                      <label className={classes.label}>
                        All Reps - Last Action
                      </label>
                      {stats.by_action.map((r, kIdx) => (
                        <div key={kIdx} className={classes.summaryEntryWrap}>
                          <span
                            className={classes.summaryEntry}
                            onClick={this.fetchByUA_nm(
                              'ua_action',
                              r._id.toUpperCase(),
                              0
                            )}>
                            {r._id.toUpperCase()} {r.n}
                          </span>
                          <div className={classes.summaryCountWrap} />
                        </div>
                      ))}
                      <hr />
                      <label className={classes.label}>
                        Total Workunits By QName
                      </label>
                      {stats.by_q.map((r, kIdx) => (
                        <div key={kIdx} className={classes.summaryEntryWrap}>
                          <span
                            className={classes.summaryEntry}
                            onClick={this.fetchByUA_nm(
                              'qname',
                              r._id.toUpperCase(),
                              0
                            )}>
                            {r._id.toUpperCase()} {r.n}
                          </span>
                          <div className={classes.summaryCountWrap} />
                        </div>
                      ))}
                      <hr />
                      <label className={classes.label}>
                        Total Unworked Workunits By QName
                      </label>
                      {stats.unworked_by_q.map((r, kIdx) => (
                        <div key={kIdx} className={classes.summaryEntryWrap}>
                          <span
                            className={classes.summaryEntry}
                            style={{ color: 'red' }}
                            onClick={this.fetchByUA_nm(
                              'qname_unworked',
                              r._id.toUpperCase(),
                              0
                            )}>
                            {r._id.toUpperCase()} {r.n}
                          </span>
                          <div className={classes.summaryCountWrap} />
                        </div>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
    );
  }
}

ExpiringPAsLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  expiringPAs: stateFromStore.expiringPAs,
});

const mapDispatchToProps = (dispatch, propsOfExpiringPAsLanding) =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
      fetchExpiringPAStats_ac,
      open_snack_ac,
      fetchExpiringPAs_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExpiringPAsLanding))
);
