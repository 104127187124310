import { Button, Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import ReactHealthOnboardForm from './ReactHealthOnboardForm';

const convert_to_pst = (dt) => {
  //dt is already in PST, moment thinks it's in UTC, needs proper conversion
  //obtain the utcOffset of the current system date, then apply it to the date

  let offset = moment(dt).utcOffset();
  return moment(dt).add(-1 * offset, 'minutes');
};

const COLOR_MAP = {
  error: 'red',
  success: 'green',
  warning: 'red',
  info: 'blue',
};

const validatePatientState = (patient) => {
  const requiredFields = [
    'sn',
    'pin',
    'assigned_id',
    'first_name',
    'last_name',
    'birth_date',
    'sex',
    'city',
    'state',
    'zip',
    'clinician_id',
    'clinician_username',
    'therapy_start',
  ];

  const absentFields = requiredFields.filter((f) => !patient[f]);

  if (absentFields.length > 0) {
    return `Missing required fields: ${absentFields.join(', ')}`;
  }
};

const REACTHEALTH_PRODUCT_CODES = [
  'TBLG2A00',
  'TBLG3500',
  'TBLG3600',
  'TBLG3700',
  'TBLG3800',
];

const ReactHealthOnboard = (props) => {
  const { idnData, idnAccountInfo, idnWos, reactHealth, me } = props;
  const {
    clinicians: {
      data: clinicians,
      error: cliniciansError,
      isFetching: cliniciansFetching,
    },
    providers: {
      data: providers,
      error: providersError,
      isFetching: providersFetching,
    },
    isOnboardingPatient = false,
  } = reactHealth || {};

  const [rhPatientState, setRhPatientState] = React.useState({});
  const [logs, setLogs] = React.useState([]);
  const [logsShown, setLogsShown] = React.useState(true);

  // const { isFetching: rentalsFetching, records: activeRentalsRecords } =
  //   activeRentals;
  // const activeRentalRecord = activeRentalsRecords?.[0] || null;
  // const lineItems = activeRentalRecord?.lines;

  const { isFetching: idnWosFetching, pkg: idnWosPkg } = idnWos;
  const lineItems = idnWosPkg?.wos
    ? idnWosPkg.wos
        .filter((w) => w.dtrack)
        .map((w) => w.dtrack.lines)
        .flat()
    : [];

  const { isFetching: idnAccountInfoFetching } = idnAccountInfo || {};

  const ptAccount = idnWosPkg?.accounts?.[0] || null;

  const userMail = me?.user?.mail || '';

  const pcpNPI = ptAccount?.pcp_npi;

  useEffect(() => {
    if (!Boolean(clinicians)) {
      setLogs((logs) => [
        ...logs,
        {
          message: 'Fetching all clinicians from ReactHealth',
          kind: 'info',
        },
      ]);

      props.fetchReactHealthClinicians_ac();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicians]);

  useEffect(() => {
    if (idnData?.idnDoc) {
      setLogs((logs) => [
        ...logs,
        {
          message: `Fetching IDN account detail for ${idnData.idnDoc._id}`,
          kind: 'info',
        },
      ]);

      props.fetchIdnAccountInfo_ac(
        idnData.idnDoc._id,
        idnData.idnDoc.aa.map((a) => 'A' + a),
        true
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idnData]);

  useEffect(() => {
    if (idnData?.idnDoc) {
      setLogs((logs) => [
        ...logs,
        {
          // message: `Fetching active rentals for acct: ${idnData.idnDoc.aa[0]}`,
          message: `Fetching work order lines for acct: ${idnData.idnDoc.aa[0]}`,
          kind: 'info',
        },
      ]);

      props.fetchIdnWos_ac(
        idnData.idnDoc._id,
        idnData.idnDoc.aa.map((a) => 'A' + a)
      );

      // props.fetchActiveRentalsList_ac({
      //   page: 0,
      //   attach_dtrack_lines: true,
      //   account: idnData.idnDoc.aa[0],
      // });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idnData]);

  useEffect(() => {
    if (pcpNPI && !providersFetching) {
      setLogs((logs) => [
        ...logs,
        {
          message: `Searching for provider with NPI: ${pcpNPI} in ReactHealth`,
          kind: 'info',
        },
      ]);
      props.fetchReactHealthProviders_ac(pcpNPI);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pcpNPI]);

  useEffect(() => {
    if (!clinicians || !userMail) return;

    const cc =
      clinicians.find((c) => c.clinician_username === userMail) || null;

    setLogs((logs) => [
      ...logs,
      {
        message: `Loaded ${clinicians.length} clinicians from ReactHealth`,
        kind: 'info',
      },
      {
        message: cc
          ? `Auth user matched clinician: ${cc.clinician_id} (${cc.first_name} ${cc.last_name})`
          : `No clinician match for user: ${userMail}`,
        kind: cc ? 'success' : 'warning',
      },
    ]);

    if (cc) {
      setRhPatientState((st) => ({
        ...st,
        clinician_id: cc.clinician_id,
        clinician_username: cc.clinician_username,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMail, clinicians?.length]);

  useEffect(() => {
    if (providers === null || !pcpNPI) return;

    const noProviders = providers.length === 0;

    if (noProviders) {
      setLogs((logs) => [
        ...logs,
        {
          message: `No providers found in ReactHealth for NPI: ${pcpNPI}`,
          kind: 'error',
        },
      ]);

      return;
    }

    const effProv = providers[0];

    setLogs((logs) => [
      ...logs,
      {
        message: `Found ${providers.length} providers in ReactHealth for NPI: ${pcpNPI}`,
        kind: 'success',
      },
      {
        message: `Auto-selected provider: ${effProv.provider_first_name} ${effProv.provider_last_name} (${effProv.org_name})`,
        kind: 'info',
      },
    ]);

    setRhPatientState((st) => ({
      ...st,
      provider_id: effProv.provider_id,
      provider_last: effProv.provider_last_name,
      NPI: effProv.npi,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers]);

  useEffect(() => {
    if (!ptAccount) return;

    setLogs((logs) => [
      ...logs,
      {
        message: `Loaded patient info for account: ${ptAccount.account}`,
        kind: 'success',
      },
    ]);

    const ptLoad = {
      assigned_id: ptAccount.account,
      first_name: ptAccount.first_name,
      last_name: ptAccount.last_name,
      birth_date: convert_to_pst(ptAccount.dob).format('YYYY-MM-DD'),
      sex: ptAccount.sex,
      city: ptAccount.city,
      state: ptAccount.state,
      zip: ptAccount.zip,
    };

    // Inject optional fields if they exist
    if (ptAccount.emailaddress) ptLoad.email = ptAccount.emailaddress;
    if (ptAccount.addr_1) ptLoad.address1 = ptAccount.addr_1;
    if (ptAccount.addr_2) ptLoad.address2 = ptAccount.addr_2;
    if (ptAccount.zip) ptLoad.zip = ptAccount.zip.substring(0, 5);
    if (ptAccount.ph) ptLoad.phone = ptAccount.ph; // TODO: Verify format
    if (ptAccount.alt_ph) ptLoad.mobile_phone = ptAccount.alt_ph; // TODO: Verify format
    if (ptAccount.country) ptLoad.country = ptAccount.country;

    setRhPatientState((st) => ({
      ...st,
      ...ptLoad,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ptAccount?.account]);

  useEffect(() => {
    if (providersError) {
      setLogs((logs) => [
        ...logs,
        {
          message: `Error loading providers: ${
            providersError.message || ' Unknown error occurred!'
          }`,
          kind: 'error',
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providersError]);

  useEffect(() => {
    if (cliniciansError) {
      setLogs((logs) => [
        ...logs,
        {
          message: `Error loading clinicians: ${
            cliniciansError.message || ' Unknown error occurred!'
          }`,
          kind: 'error',
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliniciansError]);

  useEffect(() => {
    if (!Boolean(lineItems)) return;

    const noLineItems = lineItems.length === 0;

    if (noLineItems) {
      setLogs((logs) => [
        ...logs,
        {
          message: `No product line items found`,
          kind: 'warning',
        },
      ]);

      return;
    }

    // const lineItemsWithSN = lineItems.filter((li) => Boolean(li.serial_number));
    const linesWithReactProduct = lineItems.filter((li) =>
      REACTHEALTH_PRODUCT_CODES.includes(li.product_code)
    );
    const lineItemsWithSN = linesWithReactProduct.filter((li) =>
      Boolean(li.serial_no)
    );

    if (lineItemsWithSN.length === 0) {
      setLogs((logs) => [
        ...logs,
        {
          message: `No product line items found with serial numbers`,
          kind: 'warning',
        },
      ]);

      return;
    }

    //sort lineItemsWithSN by sched_dt descending
    lineItemsWithSN.sort((a, b) => {
      return new Date(b.sched_dt) - new Date(a.sched_dt);
    });

    const effLineItem = lineItemsWithSN[0];
    const sched_dt = effLineItem.sched_dt
      ? moment(effLineItem.sched_dt.substr(0, 10), 'YYYY-MM-DD').format(
          'YYYY-MM-DD'
        )
      : moment().format('YYYY-MM-DD');

    console.log({ sched_dt, effLineItem });

    setLogs((logs) => [
      ...logs,
      {
        message: `Found ${lineItems.length} product line items. ${lineItemsWithSN.length} have serial numbers`,
        kind: 'success',
      },
      {
        message: `Auto-selected line item: ${effLineItem.product_desc} (${effLineItem.product_code}) with schedule date: ${sched_dt}`,
        kind: 'info',
      },
    ]);

    let serial_no_text = effLineItem.serial_no || '';
    //serial_no_text, if not empty, is expected to be in 2 possible formats:
    // First format:  "A3122BB0461 Pin 11919"
    // Second format: "A3122BB0461 11919"
    // Remove all consecutive spaces and replace with a single space
    serial_no_text = serial_no_text.replace(/\s+/g, ' ');
    let [sn, pin] = serial_no_text.split(' Pin ');
    if (!pin) {
      [sn, pin] = serial_no_text.split(' ');
    }

    // let [sn, pin] = effLineItem.serial_no
    //   ? effLineItem.serial_no.split(' ')
    //   : ['', '']; // TODO: Validate this logic.
    // if (!pin) {
    //   //try to get sn and pin from dtrack_line.serial_no.  Typically looks like this: "A3122BB0461 Pin 11919"
    //   if (effLineItem.dtrack_line) {
    //     [sn, pin] = effLineItem.dtrack_line.serial_no.split(' Pin ');
    //   }
    // }

    setRhPatientState((st) => ({
      ...st,
      sn,
      pin,
      // therapy_start: moment().format('YYYY-MM-DD'),
      therapy_start: sched_dt,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems?.length]);

  const overallLoading =
    providersFetching ||
    cliniciansFetching ||
    idnWosFetching ||
    // rentalsFetching ||
    idnAccountInfoFetching;

  const onboardPatient = () => {
    const errMsg = validatePatientState(rhPatientState);

    if (errMsg) {
      setLogs((logs) => [
        ...logs,
        {
          message: errMsg,
          kind: 'error',
        },
      ]);

      props.open_snack_ac({
        message: errMsg,
        variant: 'error',
      });

      return;
    }

    // Proceed
    props.onboardPatientReactHealth_ac(rhPatientState, {
      onSuccess: () => {
        props.open_snack_ac({
          message: 'Patient onboarding complete!',
          variant: 'success',
        });

        props.fetchReactHealthPatient_ac(rhPatientState.assigned_id);
      },
      onError: (msgs = []) => {
        setLogs((logs) =>
          logs.concat(msgs.map((m) => ({ message: m, kind: 'error' })))
        );
        props.open_snack_ac({
          message: `Patient onboarding failed: ${
            msgs?.join(', ') || 'Unknown error'
          }`,
          variant: 'error',
        });
      },
    });
  };

  console.log(rhPatientState);

  return (
    <div
      style={{
        padding: 12,
        width: '100%',
        border: '2px solid grey',
        minHeight: '80vh',
      }}>
      <div
        style={{
          marginBottom: 6,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <span
          style={{
            fontSize: 14,
            color: 'red',
          }}>
          This patient does not have a record on ReactHealth. Use the form below
          to onboard the patient to ReactHealth.
        </span>

        <span
          style={{
            fontSize: 12,
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'purple',
          }}
          onClick={() => setLogsShown(!logsShown)}>
          {logsShown ? 'Hide Logs' : 'Show logs'}
        </span>
      </div>
      <Grid
        container
        style={
          {
            // overflowX: 'hidden',
            // flexWrap: 'nowrap',
          }
        }>
        <Grid
          item
          xs={logsShown ? 8 : 12}
          style={{
            borderRight: '1px solid grey',
            paddingRight: 12,
            // minWidth: logsShown ? '66%' : '100%',
            // transition: 'all 0.5s ease',
            // maxHeight: '70vh',
            // overflowY: 'auto',
          }}>
          <div
            style={{
              marginTop: 12,
            }}>
            <ReactHealthOnboardForm
              clinicians={clinicians}
              providers={providers}
              lineItems={lineItems}
              state={rhPatientState}
              setState={setRhPatientState}
              disabled={overallLoading || isOnboardingPatient}
            />
          </div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              disabled={overallLoading || isOnboardingPatient}
              onClick={onboardPatient}
              style={{ marginTop: 12 }}>
              {isOnboardingPatient ? 'Processing' : 'Onboard'}
            </Button>
          </div>
        </Grid>
        {logsShown && (
          <Grid
            item
            xs={4}
            style={{
              borderLeft: '1px solid grey',
              paddingLeft: 12,
              paddingTop: 12,
              fontWeight: '500',
              maxHeight: '700px',
              overflowY: 'auto',
              // minWidth: '33%',
              // transition: 'display 2s ease',
            }}>
            {logs.map((lg, idx) => (
              <div
                key={idx}
                style={{
                  fontSize: 12,
                  color: COLOR_MAP[lg.kind] || 'black',
                  marginBottom: 8,
                }}>
                {idx + 1} {'. '} {lg.message}
              </div>
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ReactHealthOnboard;
