import _ from 'lodash';
function fetchAppealRequests(param) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    let target_url = `/api/appeals/list`;
    let postData;
    if (typeof param == 'string') {
      postData = { by: 'idn', val: param.replace(/_/g, ' '), page: 0 };
      dispatch({ type: 'REQUEST_APPEAL_REQUESTS', idn: param });
    } else {
      dispatch({ type: 'REQUEST_APPEAL_REQUESTS', idn: null });
      postData = param;
    }

    return window.sch
      .post(target_url, postData)
      .then((pkg) => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        dispatch({
          type: 'RECEIVED_APPEAL_REQUESTS',
          records: pkg.rr,
          pagination: pkg.pagination,
        });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_RECEIVED_APPEAL_REQUESTS', error });
      });
  };
}

export function updateTrmFaxNumber_ac(oDoc_id, mode_kind, mode_val) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_UPDATE_FAXNUM' });
    let target_url = `/api/appeals/update_trm_fax_number`;
    return window.sch
      .post(target_url, { oDoc_id, mode_kind, mode_val })
      .then((pkg) => {
        let _records = getState().appealRequestsInView.records.map((r) => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc;
          }
          return r;
        });
        dispatch({ type: 'DONE_ATTEMPT_UPDATE_FAXNUM', records: _records });
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_ATTEMPT_UPDATE_FAXNUM', error });
      });
  };
}

export function attemptPrint_ac(oDoc_id) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_TRANSMIT' });
    let target_url = `/api/appeals/print`;
    return window.sch
      .post(target_url, { oDoc_id })
      .then((pkg) => {
        let av = getState().appealRequestsInView;
        if (!av.records) {
          dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: av.records });
          return;
        }

        let _records = av.records.map((r) => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc;
          }
          return r;
        });
        dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: _records });
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_ATTEMPT_TRANSMIT', error });
      });
  };
}

export function attemptMassPrint_ac(oDoc_ids) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    let target_url = `/api/appeals/massprint`;
    alert('Got mass print request for Packet(s)#: ' + oDoc_ids.join(','));
    return window.sch
      .post(target_url, { oDoc_ids })
      .then((pkg) => {
        //console.log(pkg)
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function markTransmissionMan_ac(appeal_packet_id, mark_state) {
  return (dispatch, getState) => {
    dispatch({ type: 'ATTEMPT_TRANSMIT' });
    let target_url = `/api/appeals/mark`;
    return window.sch
      .post(target_url, { appeal_packet_id, mark_state })
      .then((pkg) => {
        let av = getState().appealRequestsInView;
        if (!av.records) {
          dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: av.records });
          return;
        }

        let _records = av.records.map((r) => {
          if (r._id === pkg.appeal_packet._id) {
            return pkg.appeal_packet;
          }
          return r;
        });
        dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: _records });
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_ATTEMPT_TRANSMIT', error });
      });
  };
}

export function getAppealPacketDetail_ac(p_id) {
  return (dispatch, getStore) => {
    let room = getStore().room;
    dispatch({ type: 'ATTEMPT_TRANSMIT' });
    let target_url = `/api/appeals/get/${p_id}`;
    return window.sch
      .get(target_url)
      .then((res) => {
        let msg = {};
        if (res.packet === null) {
          msg = {
            kind: 'get_appeal_detail_response',
            success: false,
            msg: `Appeal packet ${p_id} not found`,
          };
        } else {
          msg = {
            kind: 'get_appeal_detail_response',
            success: true,
            msg: `Appeal packet ${p_id} found`,
            packet: res.packet,
          };
        }
        room.socket.emit('df_msg', msg, () => {
          dispatch({ type: 'SENT_DF_MSG', msg });
        });
      })
      .catch((error) => {
        console.error(error);
        let msg = {
          kind: 'get_appeal_detail_response',
          success: false,
          msg: `DF+ could not fetch appeal packet. System error`,
        };
        room.socket.emit('df_msg', msg, () => {
          dispatch({ type: 'SENT_DF_MSG', msg });
        });
      });
  };
}

export const resortAppealPdfs_ac = (params) => (dispatch) => {
  dispatch({
    type: 'SAVING_APPEAL_PDF_ORDER',
    savingAppealODocId: params._id,
  });

  window.sch
    .post('/api/appeals/resort_pdfs', params)
    .then((response) => {
      dispatch({
        type: 'OPEN_SNACK',
        variant: 'success',
        message: 'Successfully resorted the PDFs.',
      });
      dispatch({
        type: 'RESORTED_APPEAL_PDFS',
        params,
      });
    })
    .catch((error) => {
      dispatch({
        type: 'OPEN_SNACK',
        variant: 'error',
        message: 'Failed to resort the PDFs.',
      });
    })
    .finally(() => {
      dispatch({
        type: 'SAVING_APPEAL_PDF_ORDER',
        savingAppealODocId: null,
      });
    });
};

export const fetchAppealRequests_ac = fetchAppealRequests;

// DOC REQUEST ACTIVITY RELATED
export function attachFilesToStore_ac(appealsFiles) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTACH_APPEAL_REQ_FILES_TO_STORE', appealsFiles });
  };
}

export function resetFilesToStore_ac(appealsFiles) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTACH_APPEAL_REQ_FILES_TO_STORE', appealsFiles: [] });
  };
}

export function resetAppealRequestActivity() {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'RESET_APPEAL_REQUEST_ACTIVITY' });
    dispatch({ type: 'ATTACH_APPEAL_REQ_FILES_TO_STORE', appealsFiles: [] });
  };
}

export function pre_createAppealRequest_ac() {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'CREATE_APPEAL_REQUEST_ACTIVITY' });
  };
}

export function undo_createAppealRequest_ac() {
  return (dispatch) => {
    //params passed in by thunk middleware
    dispatch({ type: 'UNDO_APPEAL_REQUEST_ACTIVITY' });
  };
}

export function createAppealRequest_ac(postData) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    dispatch({ type: 'CREATE_APPEAL_REQUEST_ACTIVITY' });
    let target_url = `/api/appeals/create`;
    //let postData = { ...payload };
    postData.idn = postData.idn.replace(/ /g, ' ');
    return window.sch
      .post(target_url, postData)
      .then((pkg) => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}

        let msg = {
          kind: 'appeal_packet_created',
          appeal_packet_id: pkg.doc._id,
          accountMember: pkg.doc.account,
          account: parseInt(pkg.doc.account.split(/-/)[0], 10),
          member: parseInt(pkg.doc.account.split(/-/)[1], 10),
          claim_ids: pkg.doc.claim_ids,
        };

        let room = getStore().room;
        if (room.status === 'roommate_present' && room.socket) {
          dispatch({ type: 'SET_DF_MSG', msg });
          room.socket.emit('df_msg', msg, () => {
            dispatch({ type: 'SENT_DF_MSG', msg });
          });
        }

        fetchAppealRequests(pkg.idn);
        dispatch({ type: 'SUCCESS_APPEAL_REQUEST_ACTIVITY', successPkg: pkg });
        dispatch({ type: 'RESET_APPEAL_REQUESTS' });

        return Promise.resolve({ success: true });

        /* DO THIS ON SERVER INSTEAD
        if (postData.pulse_initiated) {
          let pulse_params = {
            AppealId: postData.pulse_code,
            DocflowAppealPacketID: pkg.doc._id,
            StatusCode: 1, //'uploaded',  waiting ot hear back from Pulse about what the codes should be
            StatusMessage: `Appeal packet created in Docflow, DocFlowPacketID: ${pkg.doc._id}`,
            //StatusMessage should be either 0: failed or 1:created
          };
          return window.sch.post('/api/pulse/update_status', { pulse_params });
        } else {
          //TODO: I need to send statuscode 0 if failed
          //fake it
          return Promise.resolve({ success: true });
        }
        */
      })
      .then((pulseResponse) => {
        //pulseResponse: { "success" }
        if (pulseResponse.success) {
          console.log('Pulse response:', pulseResponse);
        }
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_APPEAL_REQUEST_ACTIVITY' });
      });
  };
}

export function getQrCodeHash_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'GET_QR_CODE_HASH' });
    let target_url = `/api/hash:generate:enc/8`;
    return window.sch
      .get(target_url)
      .then((pkg) => {
        dispatch({ type: 'GOT_QR_CODE_HASH', qrCodeHash: pkg.hash });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_QR_CODE_HASH', qrCodeHash: null });
      });
  };
}

export function getAppealsDataSet_ac(forceClaimNums) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    const { room, idnInView } = getState();
    let claim_nums = [-1];
    let which_action = 'APPEALED';
    if (forceClaimNums) {
      claim_nums = [].concat(forceClaimNums);
    } else {
      if (room.ub_msg) {
        if (room.ub_msg.kind === 'appeal') {
          claim_nums = _.uniq(room.ub_msg.claims_json.map((c) => c.claim_id));
          which_action = room.ub_msg.which_action || which_action;
        }
      }
    }

    dispatch({ type: 'GET_APPEALS_DATA_SET' });
    let target_url = `/api/claims/for_appeal/${claim_nums.join(',')}`;
    let payload = {
      accountMembers: _.get(idnInView.idnDoc, 'aa', []),
    };
    //let target_url = `/api/tpl_data_sample/get/appeals`;
    if (getState().appealRequestActivity.enabledTpls.length === 0) {
      payload.getEnabledTpls = true;
    }

    return window.sch
      .post(target_url, payload)
      .then((pkg) => {
        if (payload.getEnabledTpls === true) {
          dispatch({
            type: 'GOT_APPEALS_ENABLED_TPL',
            enabledTpls: pkg.enabledTpls,
          });
        }
        dispatch({
          type: 'GOT_APPEALS_DATA_SET',
          dataSet: {
            ...pkg.dataSet,
            which_action,
          },
        });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_APPEALS_DATA_SET', error });
      });
  };
}
