export function idnTabSelected_ac(selectedTab) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'IDN_TAB_SELECTED', selectedTab })
  }
}
export function fetchIdnDoc_ac(idnStr) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware

    //reset landing
    dispatch({ type: 'RESET_IDN_IN_VIEW' })
    dispatch({ type: 'RESET_ACCOUNT_IN_VIEW' })
    dispatch({ type: 'RESET_HDMS_NOTES_PKG' })
    dispatch({ type: 'RESET_IDN_WOS' })
    dispatch({ type: 'RESET_IDN_ACCOUNT_INFO' })
    dispatch({ type: 'DOC_RESET' })
    dispatch({ type: 'RESET_DOC_REQUESTS' })
    dispatch({ type: 'RESET_DOC_REQUEST_ACTIVITY' })
    dispatch({ type: 'RESET_DOC_SUBMISSIONS' })
    dispatch({ type: 'RESET_DOC_SUBMISSION_ACTIVITY' })
    dispatch({ type: 'RESET_ACCOUNT_PAS' })
    dispatch({ type: 'RESET_ACCOUNT_CMNS' })
    dispatch({ type: 'RESET_IVR_INFO' })
    dispatch({ type: 'RESET_APPEAL_REQUESTS' })
    dispatch({ type: 'RESET_TODOS' })
    dispatch({ type: 'RESET_TODO_CREATION_ACTIVITY' })
    dispatch({ type: 'RESET_APPEAL_REQUEST_ACTIVITY' })

    dispatch({ type: 'REQUEST_IDN_DOC', idnStr })

    let target_url = `/api/idn/get`
    let postData = { idn: idnStr.replace(/_/g, ' ') }

    return window.sch
      .post(target_url, postData)
      .then(results => {
        if (results.idnDoc.aa === undefined) results.idnDoc.aa = []
        dispatch({ type: 'SET_TITLE', title: results.idnDoc._id })
        dispatch({ type: 'RECEIVED_IDN_DOC', idnDoc: results.idnDoc })
      })
      .catch(error => {
        console.error('error:', error)
        dispatch({ type: 'FAILED_RECEIVED_IDN_DOC', error })
      })
  }
}

export function mergeIdnSetup_ac(params) {
  return (dispatch, getState) => {
    dispatch({ type: 'MERGE_IDN_SETUP', ...params })
  }
}

export function mergeIdnExecute_ac() {
  return (dispatch, getState) => {
    const { idnDoc, good_idn } = getState().idnInView
    let postData = {
      bad_idn: idnDoc._id,
      good_idn: good_idn.nm,
      hdmsAcct: good_idn.labels[0].display.replace(/A/, '')
    }

    return window.sch
      .post(`/api/idn/merge`, postData)
      .then(results => {
        if (results.goodIdn.aa === undefined) results.idnDoc.aa = []
        dispatch({ type: 'SET_TITLE', title: results.goodIdn._id })
        dispatch({
          type: 'RECEIVED_IDN_DOC',
          idnDoc: results.goodIdn,
          preMergedIdn: postData.bad_idn
        })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_RECEIVED_IDN_DOC', error })
      })
  }
}

export function idnFixMode_ac(onOrOff) {
  return (dispatch, getState) => {
    dispatch({ type: onOrOff ? 'IDN_FIX_MODE' : 'CLEAR_IDN_FIX_MODE' })
  }
}

export function provideIdnDoc_ac(idnDoc) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    //reset landing
    dispatch({ type: 'RESET_IDN_IN_VIEW' })
    dispatch({ type: 'RESET_ACCOUNT_IN_VIEW' })
    dispatch({ type: 'RESET_HDMS_NOTES_PKG' })
    dispatch({ type: 'RESET_IDN_WOS' })
    dispatch({ type: 'RESET_IDN_ACCOUNT_INFO' })
    dispatch({ type: 'DOC_RESET' })
    dispatch({ type: 'RESET_DOC_REQUESTS' })
    dispatch({ type: 'RESET_DOC_REQUEST_ACTIVITY' })
    dispatch({ type: 'RESET_DOC_SUBMISSIONS' })
    dispatch({ type: 'RESET_DOC_SUBMISSION_ACTIVITY' })
    dispatch({ type: 'RESET_ACCOUNT_PAS' })
    dispatch({ type: 'RESET_ACCOUNT_CMNS' })
    dispatch({ type: 'RESET_IVR_INFO' })
    dispatch({ type: 'RESET_APPEAL_REQUESTS' })
    dispatch({ type: 'RESET_TODOS' })
    dispatch({ type: 'RESET_TODO_CREATION_ACTIVITY' })
    dispatch({ type: 'RESET_APPEAL_REQUEST_ACTIVITY' })
    dispatch({ type: 'REQUEST_IDN_DOC', idnStr: idnDoc._id })
    dispatch({ type: 'RECEIVED_IDN_DOC', idnDoc: idnDoc })
  }
}
