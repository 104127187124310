export const actionTypes = {
  FETCHING_IDX_ADMIN_DOC_QUALS: 'FETCHING_IDX_ADMIN_DOC_QUALS',
  RECEIVED_IDX_ADMIN_DOC_QUALS: 'RECEIVED_IDX_ADMIN_DOC_QUALS',
  FAILED_FETCHING_IDX_ADMIN_DOC_QUALS: 'FAILED_FETCHING_IDX_ADMIN_DOC_QUALS',
  SET_IDX_ADMIN_DOC_QUALS: 'SET_IDX_ADMIN_DOC_QUALS'
}

export const fetchIdxAdminDocQuals_ac = (filters = {}) => {
  return dispatch => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_DOC_QUALS
    })

    window.sch
      .post('/api/idx/admin/wur_map/list', filters)
      .then(res => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_DOC_QUALS,
          data: res.pkg
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_DOC_QUALS,
          error: error
        })
      })
  }
}

export const setIdxAdminDocQuals_ac = (newData = []) => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_IDX_ADMIN_DOC_QUALS,
      data: newData
    })
  }
}
