import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ParachuteLandingNew from './ParachuteLandingNew';

const ParachuteReportRouter = () => {
  return (
    <Switch>
      <Route
        exact
        path="/reports/parachute/:pageNum"
        component={ParachuteLandingNew}
      />
      <Route
        exact
        path="/reports/parachute_new/:pageNum"
        component={ParachuteLandingNew}
      />
      <Redirect to="/reports/parachute_new/1" />
    </Switch>
  );
};

export default ParachuteReportRouter;
