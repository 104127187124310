import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bindActionCreators } from 'redux'
import { fetchAppealRequestsReport_ac } from '../../actions/appeal_requests_report.ac'
import { lookups_ac, setTitle_ac } from '../../actions/me.ac'
import dateUtils from '../../util/dateProcessing'
import FilterListing from '../FilterListing'
import { PaginationWithNums } from '../PaginationNew'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'
import ExpandableAppealRow from './ExpandableAppealRow'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit * 0.5,
    overflowY: 'auto'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  },
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  pagination: {
    padding: 8,
    backgroundColor: '#00000005',
    borderBottom: '1px solid #00000030'
  }
})

const PAGE_TITLE = 'Appeal Requests Report'
const DEFAULT_FILTERS = {
  state: {
    kind: 'checkbox',
    lbl: 'State',
    options: [
      { lbl: 'Void', code: 'void', checked: false },
      { lbl: 'Initialized', code: 'initialized', checked: false },
      { lbl: 'Print Attempted', code: 'print_attempted', checked: false },
      { lbl: 'Approved', code: 'approved', checked: false },
      { lbl: 'Denied', code: 'denied', checked: false }
    ]
  },
  start: {
    kind: 'dateTime',
    lbl: 'From',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false
  },
  end: {
    kind: 'dateTime',
    lbl: 'To',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false
  }
}

class AppealRequestsReport extends BaseLandingTemplate {
  constructor() {
    super()

    this.state = {
      filters: { ...DEFAULT_FILTERS },
      filterSequence: ['state', 'start', 'end'],
      selectedPageNum: 0,
      allExpanded: false
    }

    this.fetch = this.fetch.bind(this)
  }

  checkSituation = () => {
    const {
      appealRequestsReport,
      me,
      lookups,
      lookups_ac,
      setTitle_ac
    } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (me.title !== PAGE_TITLE) {
      setTitle_ac(PAGE_TITLE)
    }

    if (
      !appealRequestsReport.report &&
      !appealRequestsReport.error &&
      !appealRequestsReport.isFetchingReport
    ) {
      this.fetch()
    }
  }

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  fetch(pageNum = 0) {
    const { filters } = this.state
    let params = {
      page: pageNum
    }

    let state = filters.state.options
      .filter(opt => opt.checked)
      .map(opt => opt.code)

    if (state.length > 0) {
      params.state = [...state]
    }

    if (filters.start.value) {
      params.start = dateUtils.formatDate(filters.start.value, 'ISO')
    }

    if (filters.end.value) {
      params.end = dateUtils.formatDate(filters.end.value, 'ISO')
    }

    this.props.fetchAppealRequestsReport_ac(params)
  }

  applyFilters = () => {
    this.setState({
      selectedPageNum: 0
    })

    return this.fetch()
  }

  handlePageChange = newPage => {
    let targetPage = parseInt(newPage, 10)

    this.setState({
      selectedPageNum: targetPage - 1
    })

    this.fetch(targetPage - 1)
  }

  toggleAllExpandedState = () => {
    this.setState(state => ({
      allExpanded: !state.allExpanded
    }))
  }

  getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${this.state.selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  render() {
    const { classes, appealRequestsReport, me } = this.props
    const { filters, filterSequence, allExpanded } = this.state
    const { report, isFetchingReport, error } = appealRequestsReport

    if (isFetchingReport) {
      return <Loader classes={{ message: classes.message }} />
    }

    let filterListing = (
      <FilterListing
        me={me}
        filters={filters}
        onFilterChange={this.onFilterChange}
        filterSequence={filterSequence}
        applyFilters={this.applyFilters}
        onFilterClearField={this.onFilterClearField}
      />
    )

    if (error) {
      return (
        <>
          {filterListing}
          <Typography color="error" classes={{ message: classes.message }}>
            Error fetching report
          </Typography>
        </>
      )
    }

    if (!report) {
      return (
        <>
          {filterListing}
          <div classes={{ message: classes.message }}>...</div>
        </>
      )
    }

    const { pagination: pg, records } = report
    let pageStatement = ''
    if (pg) {
      pageStatement = this.getPageStatement(pg)
    }

    let pageSelector = ''
    if (pg.totalPagesAvailable > 1) {
      pageSelector = (
        <PaginationWithNums
          currentPageNum={pg.currentPageNum}
          totalPagesAvailable={pg.totalPagesAvailable}
          onPageChange={this.handlePageChange}
        />
      )
    }

    return (
      <>
        {filterListing}
        <div className={classes.content}>
          <div className={classes.pagination}>
            <Grid container spacing={16} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <Typography
                  inline
                  color="textSecondary"
                  onClick={this.toggleAllExpandedState}
                  style={{ cursor: 'pointer' }}>
                  <strong>
                    {allExpanded ? 'Collapse' : 'Expand'}
                    &ensp;All&ensp;[+/-]
                  </strong>
                </Typography>
                <Typography color="textSecondary">{pageStatement}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {pageSelector}
              </Grid>
            </Grid>
          </div>

          <div className={classes.scrollArea}>
            <Table padding="dense">
              <TableHead>
                <TableRow>
                  <TableCell width="1%" />
                  <TableCell width="18%" style={{ padding: 8 }}>
                    Appeal
                  </TableCell>
                  <TableCell width="17%" style={{ padding: 8 }}>
                    User(s)
                  </TableCell>
                  <TableCell width="31%" style={{ padding: 8 }}>
                    Log(s)
                  </TableCell>
                  <TableCell width="19%" style={{ padding: 8 }}>
                    Trm
                  </TableCell>
                  <TableCell width="14%" style={{ padding: 8 }}>
                    Timestamps
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {records &&
                  records.length > 0 &&
                  records.map((record, idx) => (
                    <ExpandableAppealRow
                      key={idx}
                      rowIdx={idx}
                      record={record}
                      isExpandedDefault={allExpanded}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  appealRequestsReport: stateFromStore.appealRequestsReport
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      fetchAppealRequestsReport_ac,
      setTitle_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AppealRequestsReport))
)
