export function catalog(
  state = {
    isFetching: false,
    isFetchingItems: false,
    includeDiscontinued: false,
    lastParams: null,
    lastMethod: 'select',
    error: null,
    isUpdating: false,
    updateError: null,
    pkg: null
  },
  action
) {
  switch (action.type) {
    case 'RESET_CATALOG':
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingItems: false,
        includeDiscontinued: false,
        pkg: null,
        error: null
      })
    case 'TOGGLE_CAT_DISCONTINUED':
      return Object.assign({}, state, {
        includeDiscontinued: action.includeDiscontinued
      })
    case 'REQUEST_CATALOG':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'REQUEST_CATALOG_ITEMS':
      return Object.assign({}, state, {
        lastParams: action.lastParams,
        isFetchingItems: true
      })
    case 'RECEIVED_CATALOG':
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingItems: false,
        lastMethod: action.lastMethod,
        pkg: action.pkg
      })
    case 'FAILED_RECEIVED_CATALOG':
      return Object.assign({}, state, {
        isFetching: false,
        isFetchingItems: false,
        error: action.error,
        pkg: null
      })

    case 'CONFIGURE_CATALOGUE_PENDING':
      return Object.assign({}, state, {
        isUpdating: true
      })

    case 'CONFIGURE_CATALOGUE_SUCCESS':
      return Object.assign({}, state, {
        isUpdating: false,
        pkg: action.pkg
      })

    case 'CONFIGURE_CATALOGUE_ERROR':
      return Object.assign({}, state, {
        isUpdating: false,
        updateError: action.error
      })

    default:
      return state
  }
}
