import {
  AppBar,
  Button,
  Drawer,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Backspace as BackSpaceIcon,
  FilterList as FilterListIcon,
  History as HistoryIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
  StarHalf as StarHalfIcon,
  SwapCalls as SwapCallsIcons,
  CloudUpload as UploadIcon,
} from '@material-ui/icons';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  cancel_masterSearch_ac,
  masterSearch_ac,
} from '../../actions/masterSearch.ac.js';
import {
  logout_ac,
  lookups_ac,
  refresh_user_profile_ac,
  toggleFilter_ac,
} from '../../actions/me.ac.js';
import {
  attempt_pair_with_dash_ac,
  force_dash_to_unpair_ac,
  unpair_with_dash_ac,
} from '../../actions/room.ac.js';
import {
  closeNotifsPanel_ac,
  openNotifsPanel_ac,
  open_snack_ac,
} from '../../actions/snack.ac.js';
//import ConnectIcon from '../Connect/ConnectIcon';
import MasterSearchResults from '../MasterSearchResults';
import MyHistory from '../MyHistory';
import MyNotifs from '../MyNotifs';
import MyUploads from '../MyUploads';
import PDFGenIcon from '../PDFGen/PDFGenIcon';
import ConfirmationDialog from '../Shared/ConfirmationDialog.js';
import { DFSnackBar, NotifSnackBar } from '../SnackBar';
import GlobalWUAction from '../WorkUnits/common/GlobalWUAction.js';
import WUDocCopyStage from '../WorkUnits/common/WUDocCopyStage.js';
import Axiom from '../common/Axiom.js';
import DfaNavList from './DfaNavList';
import ProfileControl from './ProfileControl.js';

const drawerWidth = 280;
const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    padding: 0,
    paddingRight: 16,
  },
  closeMenu: {
    fontSize: 12,
    paddingTop: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: { flexGrow: 1 },
  hiddenIfSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginLeft: 6,
    marginRight: 3,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    marginLeft: 6,
    fontWeight: 'bold',
  },
  title_alert: {
    flexGrow: 1,
    marginLeft: 6,
    fontWeight: 'bold',
    color: 'yellow',
  },
  pairMsg: {
    marginRight: 6,
  },
  searchDivInDrawer: {
    padding: 4,
    width: '100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
      '&:hover': {
        width: 'auto',
      },
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 5,
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchInputRoot: {
    color: 'inherit',
    width: '100%',
  },
  searchInputInput: {
    padding: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  searchBtn: {
    marginLeft: 16,
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: 60,
      paddingLeft: theme.spacing.unit * 5,
      '&:focus': {
        width: 200,
      },
    },
    [theme.breakpoints.up('sm')]: {
      width: 120,
      paddingLeft: theme.spacing.unit * 5,
      '&:focus': {
        width: 200,
      },
    },
  },
  searchDrawerPaper: {
    whiteSpace: 'nowrap',
    width: drawerWidth + 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: '2px 16px',
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth + 40,
      padding: '2px 8px',
    },
  },
  drawerPaperWrapOk: {
    //whiteSpace: 'nowrap',
    width: drawerWidth + 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: '2px 16px',
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth + 40,
      padding: '2px 8px',
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: {
    /*...theme.mixins.toolbar, */
    minHeight: 38,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    overflow: 'auto',
    '-webkitOverflowScrolling': 'touch',
  },
  contentNoOverflow: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  versionNum: {
    display: 'inline',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    ...theme.mixins.toolbar,
    fontSize: '.9em',
    fontWeight: 600,
    color: 'darkgray',
  },
});

class NotifsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterSearchStr: '',
      timer: null,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="temporary"
        id="searchDrawer"
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
        classes={{
          paper: classNames(classes.searchDrawerPaper),
        }}>
        <MyNotifs open={this.props.open} />
      </Drawer>
    );
  }
}

class HistoryDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterSearchStr: '',
      timer: null,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="temporary"
        id="searchDrawer"
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
        classes={{
          paper: classNames(classes.searchDrawerPaper),
        }}>
        <MyHistory open={this.props.open} />
      </Drawer>
    );
  }
}

class AxiomDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterSearchStr: '',
      timer: null,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="temporary"
        id="axiomDrawer"
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
        classes={{
          paper: classNames(classes.drawerPaperWrapOk),
        }}>
        <Axiom open={this.props.open} />
      </Drawer>
    );
  }
}

class UploadsDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterSearchStr: '',
      timer: null,
    };
  }
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="temporary"
        id="uploadsDrawer"
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
        classes={{
          paper: classNames(classes.searchDrawerPaper),
        }}>
        <MyUploads open={this.props.open} />
      </Drawer>
    );
  }
}

class SearchDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      masterSearchStr: '',
      timer: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.onClear = this.onClear.bind(this);
  }
  onClear() {
    this.setState({ masterSearchStr: '' });
    this.props.cancel_masterSearch_ac();
  }

  executeSearch = () => {
    console.log('executing search');
    if (this.state.masterSearchStr.length < 4) return;
    let str = this.state.masterSearchStr.toString();
    if (str.search(/[0-9]*-[123]$|[0-9]*$/) === 0) str = 'a' + str;
    //always remove leading zero
    this.props.masterSearch_ac(str.replace(/_/g, ' '));
  };
  handleSearch = (e) => {
    this.setState({ masterSearchStr: e.target.value });
    if (e.target.value.length > 0) {
      clearTimeout(this.state.timer);
      this.setState({ timer: setTimeout(this.executeSearch, 1500) });
    } else {
      this.props.cancel_masterSearch_ac();
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Drawer
        variant="temporary"
        id="searchDrawer"
        anchor="right"
        open={this.props.open}
        onClose={this.props.onClose}
        classes={{
          paper: classNames(classes.searchDrawerPaper),
        }}>
        <div className={classes.searchDivInDrawer}>
          <TextField
            onChange={this.handleSearch}
            autoFocus
            value={this.state.masterSearchStr}
            margin="normal"
            variant="outlined"
            placeholder="Search for Patients, Zip links to Reps/OCs"
            classes={{
              root: classes.searchInputRoot,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={this.onClear}>
                    <BackSpaceIcon />
                  </IconButton>
                </InputAdornment>
              ),
              classes: {
                input: classes.searchInputInput,
              },
            }}
          />
        </div>
        <MasterSearchResults />
      </Drawer>
    );
  }
}

class DfaAppBar_ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      searchDrawerOpen: false,
      historyDrawerOpen: false,
      uploadDrawerOpen: false,
      axiomOpen: false,
      masterSearchStr: '',
      timer: null,
      singlePurposeToken: '',
      tokenErrorMsg: '',
    };

    this.handleFilterDrawerOpen = this.handleFilterDrawerOpen.bind(this);
    this.handleFilterDrawerClose = this.handleFilterDrawerClose.bind(this);
    this.onDFClicked = this.onDFClicked.bind(this);

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.genToken = this.genToken.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.goto = this.goto.bind(this);

    this.connectToDash = this.connectToDash.bind(this);
    this.onDTClicked = this.onDTClicked.bind(this);
    this.onSearchFieldFocused = this.onSearchFieldFocused.bind(this);
    this.onSearchDrawerClose = this.onSearchDrawerClose.bind(this);
    this.onHistoryDrawerClose = this.onHistoryDrawerClose.bind(this);
    this.onUploadDrawerClose = this.onUploadDrawerClose.bind(this);
    this.onNotifsDrawerClose = this.onNotifsDrawerClose.bind(this);
    this.onHistoryIconClicked = this.onHistoryIconClicked.bind(this);
    this.onUploadIconClicked = this.onUploadIconClicked.bind(this);
    this.onAxiomClicked = this.onAxiomClicked.bind(this);
    this.onAxiomClosed = this.onAxiomClosed.bind(this);
  }

  onSearchFieldFocused() {
    this.setState({ searchDrawerOpen: true });
  }
  onHistoryIconClicked() {
    this.setState({ historyDrawerOpen: true });
  }
  onUploadIconClicked() {
    this.setState({ uploadDrawerOpen: true });
  }

  onSearchDrawerClose() {
    this.setState({ searchDrawerOpen: false });
  }
  onUploadDrawerClose() {
    this.setState({ uploadDrawerOpen: false });
  }
  onHistoryDrawerClose() {
    this.setState({ historyDrawerOpen: false });
  }
  onNotifsDrawerClose() {
    this.props.closeNotifsPanel_ac();
  }
  onAxiomClicked() {
    this.setState({ axiomOpen: true });
  }
  onAxiomClosed() {
    this.setState({ axiomOpen: false });
  }

  onARAllegianceClick() {
    window.open('https://cp.arallegiance.com', 'AR Allegiance');
  }

  onHelp() {
    window.open(
      'https://sites.google.com/supercare.com/docflow-resources/home',
      'DF Help'
    );
  }
  goto = (path) => () => {
    this.props.changePage(path);
  };

  onDTClicked() {
    return window.sch
      .get('/api/dt/loginlink')
      .then((res) => {
        window.open(res.url, 'DeliveryTrack');
      })
      .catch(() => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Could not establish DelieryTrack session - contact IT',
        });
      });
  }

  onDFClicked() {
    window.open('https://docflow-v1.supercaredev.com', 'DFV1');
    return;
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleFilterDrawerOpen = () => {
    this.props.toggleFilter_ac();
  };

  handleFilterDrawerClose = () => {
    this.setState({ filterOpen: false });
  };

  copyToClipboard() {
    console.log(this.textArea);
    this.textArea.select();
    document.execCommand('copy');
  }

  genToken() {
    return window.sch
      .get(`/api/tokens:generate/pap_calc`)
      .then((result) => {
        this.setState({ tokenErrorMsg: '', singlePurposeToken: result.token });
        return;
      })
      .catch(() => {
        this.setState({
          tokenErrorMsg: 'Failed generating token',
          singlePurposeToken: '',
        });
        return;
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.me.user === null) {
      return;
    }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ drawerOpen: false });
    }
  }

  applyFilters() {
    this.props.applyFilters();
    this.setState({ filterOpen: false });
  }

  connectToDash() {
    this.props.attempt_pair_with_dash_ac();
  }

  render() {
    const { classes, me, room } = this.props;
    let v2_workunits_route = '/df';
    let home_route = '/dfa/workunits/home/nd/1';
    let prog_route = '';
    if (me.user.roles.includes('pre-ccs')) {
      home_route = '/dfa/workunits/z:m:PRE-CCS-LOBBY/nd/1';
      prog_route = '/dfa/workunits/z:m:PRE-CCS-INPROG/nd/1';
    }
    if (me.user.roles.includes('warehouse')) {
      home_route = '/dtrack/wo';
    }

    let showFilterBtn = true;
    if (window.location.pathname.substr(0, 4) === '/dfa') {
      showFilterBtn = false;
    }

    const isMobile = window.screen.width < 768;

    return (
      <>
        <AppBar
          position="fixed"
          color={room.status === 'roommate_present' ? 'secondary' : 'primary'}
          className={classNames(
            classes.appBar,
            this.state.drawerOpen && classes.appBarShift
          )}>
          <Toolbar
            disableGutters={!this.state.open}
            variant="dense"
            className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden
              )}>
              <MenuIcon />
            </IconButton>
            {showFilterBtn && (
              <IconButton
                color="inherit"
                aria-label="Open Filter Drawer"
                onClick={() => this.props.toggleFilter_ac(true)}
                className={classNames(
                  classes.menuButton,
                  this.state.filterOpen && classes.menuButtonHidden
                )}>
                <FilterListIcon />
              </IconButton>
            )}

            <IconButton
              color="inherit"
              aria-label="Home"
              onClick={this.goto(home_route)}
              title="My Q - V1 Workuntis"
              className={classNames(
                classes.menuButton,
                this.state.filterOpen && classes.menuButtonHidden
              )}>
              <HomeIcon />
            </IconButton>

            {!isMobile && (
              <IconButton
                color="inherit"
                aria-label="Home"
                title="V2 Workunits Dashboard"
                onClick={this.goto(v2_workunits_route)}
                className={classNames(
                  classes.menuButton,
                  this.state.filterOpen && classes.menuButtonHidden
                )}>
                V2W
              </IconButton>
            )}

            {prog_route && (
              <IconButton
                color="inherit"
                aria-label="PRECCS In Progress"
                onClick={this.goto(prog_route)}
                className={classNames(
                  classes.menuButton,
                  this.state.filterOpen && classes.menuButtonHidden
                )}>
                <StarHalfIcon />
              </IconButton>
            )}

            <Typography
              component="h5"
              color="inherit"
              noWrap
              className={classNames(classes.title, classes.hiddenIfSmall)}>
              {me.title}
            </Typography>
            <div className={classes.grow} />

            {me.uv_api_env !== 'PROD' && (
              <Typography
                component="h5"
                noWrap
                className={classNames(
                  classes.title_alert,
                  classes.hiddenIfSmall
                )}>
                {me.ink_api_env !== me.uv_api_env
                  ? 'MIXED ENVIRONMENT: INK:' +
                    me.ink_api_env +
                    ' UVA:' +
                    me.uv_api_env
                  : `${me.uv_api_env} ENVIRONMENT`}
              </Typography>
            )}

            {room.status === 'connected' && (
              <Typography
                component="h5"
                color="inherit"
                noWrap
                className={classNames(classes.pairMsg, classes.hiddenIfSmall)}>
                {_.get(room, 'room_event.state', '') === 'roommate_present'
                  ? 'Paired with Dash'
                  : 'Waiting for Pairing in Dash'}
              </Typography>
            )}

            <ProfileControl user={me?.user} mobileView={isMobile} />

            {/*<IconButton
              color="inherit"
              onClick={this.props.openNotifsPanel_ac}
              className={classNames(classes.hiddenIfSmall)}>
              {notifWebWorker.records.length > 0 ? (
                <Badge
                  badgeContent={notifWebWorker.records.length}
                  color="secondary">
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
              </IconButton>*/}

            <IconButton
              onClick={this.props.refresh_user_profile_ac}
              style={{ color: '#fff' }}
              className={classNames(classes.hiddenIfSmall)}>
              V{process.env.REACT_APP_VER}
            </IconButton>

            <IconButton
              onClick={this.onHelp}
              style={{ color: '#fff' }}
              className={classNames(classes.hiddenIfSmall)}>
              ?
            </IconButton>

            {_.intersection(
              ['dtrack', 'pcr', 'cs_supervisor', 'cs_manager'],
              me.user.roles
            ).length > 0 && (
              <IconButton
                onClick={this.onDTClicked}
                style={{ color: '#fff' }}
                className={classNames(classes.hiddenIfSmall)}>
                DT
              </IconButton>
            )}

            {/*<IconButton
              onClick={this.onDFClicked}
              style={{ color: '#fff' }}
              className={classNames(classes.hiddenIfSmall)}>
              DFV1
            </IconButton>*/}

            <IconButton className={classNames(classes.hiddenIfSmall)}>
              <NavLink to="/todos">
                <Icon style={{ color: '#fff', verticalAlign: 'middle' }}>
                  playlist_add_check
                </Icon>
              </NavLink>
            </IconButton>

            <PDFGenIcon className={classes.hiddenIfSmall} />

            <WUDocCopyStage className={classes.hiddenIfSmall} />

            {/* <ConnectIcon /> */}

            {room.status === 'connected_not_in_room' && (
              <Button
                onClick={this.connectToDash}
                variant="contained"
                color="primary"
                className={classNames(classes.button, classes.hiddenIfSmall)}>
                <SwapCallsIcons />
              </Button>
            )}

            {(room.status === 'roommate_absent' ||
              room.status === 'roommate_present') && (
              <Button
                onClick={this.props.unpair_with_dash_ac}
                variant="contained"
                color="secondary"
                className={classNames(classes.button, classes.hiddenIfSmall)}>
                <SwapCallsIcons />
              </Button>
            )}

            <Button
              onClick={this.onUploadIconClicked}
              variant="contained"
              color="primary"
              className={classNames(classes.searchBtn, classes.hiddenIfSmall)}>
              <UploadIcon />
            </Button>

            <Button
              onClick={this.onAxiomClicked}
              variant="contained"
              color="primary"
              className={classNames(classes.searchBtn, classes.hiddenIfSmall)}>
              AX
            </Button>

            <Button
              onClick={this.onARAllegianceClick}
              variant="contained"
              color="primary"
              className={classNames(classes.searchBtn, classes.hiddenIfSmall)}>
              Collect+
            </Button>

            <Button
              onClick={this.onHistoryIconClicked}
              variant="contained"
              color="primary"
              className={classNames(classes.searchBtn, classes.hiddenIfSmall)}>
              <HistoryIcon />
            </Button>

            <Button
              onClick={this.onSearchFieldFocused}
              variant="contained"
              color="primary"
              className={classes.searchBtn}>
              <SearchIcon />
            </Button>
          </Toolbar>
        </AppBar>

        <SearchDrawer
          classes={classes}
          open={this.state.searchDrawerOpen}
          onClose={this.onSearchDrawerClose}
          masterSearch={this.props.masterSearch}
          masterSearch_ac={this.props.masterSearch_ac}
          cancel_masterSearch_ac={this.props.cancel_masterSearch_ac}
        />

        <HistoryDrawer
          classes={classes}
          open={this.state.historyDrawerOpen}
          onClose={this.onHistoryDrawerClose}
        />

        <UploadsDrawer
          classes={classes}
          open={this.state.uploadDrawerOpen}
          onClose={this.onUploadDrawerClose}
        />

        <AxiomDrawer
          classes={classes}
          open={this.state.axiomOpen}
          onClose={this.onAxiomClosed}
        />

        <NotifsDrawer
          classes={classes}
          open={this.props.snack.notifsPanelOpen}
          onClose={this.onNotifsDrawerClose}
        />

        <Drawer
          variant="temporary"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.drawerOpen && classes.drawerPaperClose
            ),
          }}
          onClose={this.handleDrawerClose}
          open={this.state.drawerOpen}>
          <Button
            onClick={this.handleDrawerClose}
            variant="contained"
            color="default"
            className={classes.button}>
            Close Menu
          </Button>

          <DfaNavList
            onDTClicked={this.onDTClicked}
            onDFClicked={this.onDFClicked}
            onRefreshMyProfile={this.props.refresh_user_profile_ac}
            onGenToken={this.genToken}
            close={this.handleDrawerClose}
          />

          {this.state.singlePurposeToken && (
            <Grid
              direction="row"
              alignItems="flex-start"
              container
              spacing={8}
              style={{ marginLeft: 16, marginBottom: 16 }}>
              <Grid item>
                <FormControl fullwidth>
                  <TextField
                    label="Copy this to token slot"
                    margin="normal"
                    variant="outlined"
                    style={{ fontSize: 11 }}
                    value={this.state.singlePurposeToken}
                    inputRef={(textarea) => (this.textArea = textarea)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <div style={{ fontSize: 10 }}>
                  <Button
                    onClick={this.copyToClipboard}
                    variant="contained"
                    size="small"
                    color="secondary"
                    className={classes.button}>
                    Copy to Clipboard
                  </Button>
                </div>
              </Grid>
              {this.state.tokenErrorMsg && (
                <Grid item>
                  <div style={{ fontSize: 10 }}>
                    <strong> {this.state.tokenErrorMsg}</strong>
                  </div>
                </Grid>
              )}
            </Grid>
          )}

          <Grid
            direction="row"
            alignItems="flex-start"
            container
            spacing={8}
            style={{ marginLeft: 16 }}>
            {room.status === 'connected_not_in_room' && (
              <Grid item>
                <Button
                  onClick={this.connectToDash}
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}>
                  Connect to AR Dash
                </Button>
              </Grid>
            )}

            {(room.status === 'roommate_absent' ||
              room.status === 'roommate_present') && (
              <Grid item>
                <Button
                  onClick={this.props.unpair_with_dash_ac}
                  variant="contained"
                  size="small"
                  color="secondary"
                  className={classes.button}>
                  Unpair with Dash
                </Button>
              </Grid>
            )}
            {room.status === 'rejected' && (
              <Grid item>
                <Button
                  disabled
                  style={{ fontSize: 12 }}
                  variant="contained"
                  color="secondary"
                  size="small"
                  className={classes.button}>
                  <SwapCallsIcons />
                  Another Tab is Already Paired
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                onClick={this.props.force_dash_to_unpair_ac}
                size="small"
                variant="contained"
                color="secondary"
                className={classes.button}>
                Force Unpair All Tabs
              </Button>
            </Grid>
            <Grid item style={{ marginTop: 12, fontSize: 11 }}>
              <div>{room.status} </div>
              <div>{room.socket ? room.socket.id : 'no socket'}</div>
            </Grid>
          </Grid>

          <Grid
            direction="row"
            alignItems="flex-start"
            container
            spacing={8}
            style={{ marginLeft: 16 }}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                style={{ backgroundColor: 'red', color: '#fff' }}
                onClick={this.props.logout_ac}
                variant="contained"
                size="small"
                className={classes.button}>
                Log Me Out
              </Button>
            </Grid>
            <Grid xs={12} item style={{ fontSize: 11 }}>
              Version: {process.env.REACT_APP_VER}
              <br />
              {me.user.mail}
            </Grid>
          </Grid>
        </Drawer>
        <DFSnackBar variant="info" />
        <NotifSnackBar variant="info" />
        <ConfirmationDialog />

        <GlobalWUAction />
      </>
    );
  }
}

class MobileDfaAppBar_ extends DfaAppBar_ {
  render() {
    const { classes, me, room } = this.props;
    return (
      <>
        <AppBar
          position="fixed"
          color={room.status === 'roommate_present' ? 'secondary' : 'primary'}
          className={classNames(
            classes.appBar,
            this.state.drawerOpen && classes.appBarShift
          )}>
          <Toolbar
            disableGutters={!this.state.open}
            variant="dense"
            className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.menuButtonHidden
              )}>
              <MenuIcon />
            </IconButton>
            <Typography
              component="h5"
              color="inherit"
              noWrap
              className={classNames(classes.title)}>
              {me.title}
            </Typography>
            <div className={classes.grow} />

            {me.uv_api_env !== 'PROD' && (
              <Typography
                component="h5"
                noWrap
                className={classNames(
                  classes.title_alert,
                  classes.hiddenIfSmall
                )}>
                {me.ink_api_env !== me.uv_api_env
                  ? 'MIXED ENVIRONMENT: INK:' +
                    me.ink_api_env +
                    ' UVA:' +
                    me.uv_api_env
                  : `${me.uv_api_env} ENVIRONMENT`}
              </Typography>
            )}

            <Button
              onClick={this.onSearchFieldFocused}
              variant="contained"
              color="primary"
              className={classes.searchBtn}>
              <SearchIcon />
            </Button>
          </Toolbar>
        </AppBar>

        <SearchDrawer
          classes={classes}
          open={this.state.searchDrawerOpen}
          onClose={this.onSearchDrawerClose}
          masterSearch={this.props.masterSearch}
          masterSearch_ac={this.props.masterSearch_ac}
          cancel_masterSearch_ac={this.props.cancel_masterSearch_ac}
        />

        <Drawer
          variant="temporary"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.drawerOpen && classes.drawerPaperClose
            ),
          }}
          onClose={this.handleDrawerClose}
          open={this.state.drawerOpen}>
          <Button
            onClick={this.handleDrawerClose}
            variant="contained"
            color="default"
            className={classes.button}>
            Close Menu
          </Button>

          <DfaNavList
            onRefreshMyProfile={this.props.refresh_user_profile_ac}
            mobile
            close={this.handleDrawerClose}
          />

          <Grid
            direction="row"
            alignItems="flex-start"
            container
            spacing={8}
            style={{ marginLeft: 16 }}>
            <Grid item style={{ marginTop: 16 }}>
              <Button
                style={{ backgroundColor: 'red', color: '#fff' }}
                onClick={this.props.logout_ac}
                variant="contained"
                size="small"
                className={classes.button}>
                Log Me Out
              </Button>
            </Grid>
            <Grid xs={12} item style={{ fontSize: 11 }}>
              Version: {process.env.REACT_APP_VER}
              <br />
              {me.user.mail}
            </Grid>
          </Grid>
        </Drawer>
        <DFSnackBar variant="info" />
        <NotifSnackBar variant="info" />
      </>
    );
  }
}

DfaAppBar_.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  notifWebWorker: stateFromStore.notifWebWorker,
  room: stateFromStore.room,
  snack: stateFromStore.snack,
  masterSearch: stateFromStore.masterSearch,
  idnInView: stateFromStore.idnInView,
});

const mapDispatchToProps = (dispatch, propsOfIdnLanding) =>
  bindActionCreators(
    {
      toggleFilter_ac,
      lookups_ac,
      refresh_user_profile_ac,
      logout_ac,
      masterSearch_ac,
      cancel_masterSearch_ac,
      attempt_pair_with_dash_ac,
      unpair_with_dash_ac,
      force_dash_to_unpair_ac,
      open_snack_ac,
      openNotifsPanel_ac,
      closeNotifsPanel_ac,
      changePage: (path) => {
        console.log('change path', path);
        return push(path);
      },
    },
    dispatch
  );

const DfaAppBar = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DfaAppBar_))
);
const MobileDfaAppBar = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MobileDfaAppBar_))
);

export { DfaAppBar, MobileDfaAppBar };
