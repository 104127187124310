import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  ExpandMore as ExpandMoreIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  addOpsFormRecordComment_ac,
  attemptTransmission_ac,
  fetchOpsFormRecords_ac,
  markTransmissionMan_ac,
  updateTrmFaxNumber_ac,
  voidOpsFormRecord_ac,
} from '../../actions/ops_forms.ac.js';
import { open_snack_ac } from '../../actions/snack.ac';
import imgTools from '../../util/imageProcessing';
import OpsForm from '../DocRequest/OpsForm';
import IdnHealthCall from '../HealthCall/IdnHealthCall';
import ReactHealthView from './ReactHealthView.js';

const styles = (theme) => ({
  root: {},
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    flexGrow: 1,
    padding: 0, //theme.spacing.unit * 2,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    marginLeft: 4,
    color: '#244e91',
    fontSize: '.98em',
  },
  byTabKlass: {
    minWidth: 12,
    minHeight: 24,
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13),
  },
  tabLabelContainer: { padding: '3px 16px 3px 0' },
  opsRecordPDFViewer: {
    [theme.breakpoints.up('md')]: {
      borderLeft: '2px dashed #00000020',
    },
    [theme.breakpoints.down('sm')]: {
      borderTop: '2px dashed #00000020',
    },
  },
  chip: {
    borderRadius: 8,
    margin: 4,
  },
  fullHeight: {
    height: '100%',
  },
});

const isEmailAddress = (n) => {
  return (
    0 ===
    n
      .toString()
      .trim()
      .search(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
  );
};
const isFaxNumber = (n) => {
  n = n
    .toString()
    .trim()
    .replace(/[^0-9]/g, '');
  if (n.length === 11) {
    return n.substr(0, 1) === '1';
  }
  return n.length === 10;
};

class OpsRecordCardFormWithoutStore extends React.Component {
  constructor() {
    super();

    this.state = {
      comment: '',
      voidTxt: '',
      voidTxtErr: '',
    };
  }

  handleTextFieldChange = (e) => {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
    });
  };

  submitComment = () => {
    const { comment } = this.state;
    const { record, addOpsFormRecordComment_ac, isGeneral } = this.props;

    addOpsFormRecordComment_ac(
      { _id: record._id, txt: comment || '' },
      () => {
        //callback to reset comment field on success
        this.setState({
          comment: '',
        });
      },
      isGeneral
    );
  };

  submitVoid = () => {
    const { voidTxt } = this.state;
    const { currentUser, record, voidOpsFormRecord_ac, isGeneral } = this.props;

    if (voidTxt !== 'VOID') {
      return this.setState({
        voidTxtErr: true,
      });
    }

    if (!record.void && currentUser._id === record.u_id) {
      voidOpsFormRecord_ac(record._id, isGeneral);
    }

    this.setState({
      voidTxtErr: false,
    });
  };

  render() {
    const { comment, voidTxt, voidTxtErr } = this.state;
    const { currentUser, record, opsFormRecordFormActivity } = this.props;
    const { isSubmittingComment, isSubmittingVoid } = opsFormRecordFormActivity;
    const formStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 16,
    };
    const buttonStyle = {
      margin: '4px 0',
    };

    return (
      <>
        <div style={{ ...formStyle }}>
          <TextField
            multiline
            name="comment"
            label="New Comment"
            value={comment}
            inputProps={{
              cols: 50,
            }}
            onChange={this.handleTextFieldChange}
            disabled={isSubmittingComment}
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={this.submitComment}
            disabled={isSubmittingComment}
            style={{ ...buttonStyle }}>
            {!isSubmittingComment ? 'Add Comment' : 'Adding Comment...'}
          </Button>
        </div>
        {!record.void && currentUser._id === record.u_id && (
          <div style={{ ...formStyle }}>
            <TextField
              name="voidTxt"
              label="Void"
              value={voidTxt}
              onChange={this.handleTextFieldChange}
              error={voidTxtErr ? true : false}
              helperText="Type 'VOID' to proceed voiding"
              disabled={isSubmittingVoid}
            />
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={this.submitVoid}
              disabled={voidTxt !== 'VOID' || isSubmittingVoid}
              style={{ ...buttonStyle }}>
              {!isSubmittingVoid ? 'Confirm Void' : 'Conforming Void...'}
            </Button>
          </div>
        )}
      </>
    );
  }
}

const OpsRecordCardForm = connect(
  (storeState) => ({
    //mapStateToProps
    opsFormRecordFormActivity: storeState.opsFormRecordFormActivity,
  }),
  (dispatch) =>
    bindActionCreators(
      //mapDispatchToProps
      {
        voidOpsFormRecord_ac,
        addOpsFormRecordComment_ac,
      },
      dispatch
    )
)(OpsRecordCardFormWithoutStore);

const ContactInfoDisplay = (props) => {
  const { contactInfo = {} } = props;

  if (
    contactInfo === null ||
    Boolean(Object.keys(contactInfo).length) === false
  ) {
    return null;
  }

  const {
    responsible_for_care = '',
    emergency_contact = {},
    secondary_emergency_contact = {},
  } = contactInfo;

  const hasRFC = Boolean(responsible_for_care);

  const {
    name: ec_name,
    phone: ec_phone,
    email: ec_email,
    address: ec_address,
  } = emergency_contact;

  const hasEC = Boolean(ec_name || ec_phone || ec_email || ec_address);

  const {
    name: sec_name,
    phone: sec_phone,
    email: sec_email,
    address: sec_address,
  } = secondary_emergency_contact;

  const hasSEC = Boolean(sec_name || sec_phone || sec_email || sec_address);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {hasRFC && (
          <Typography variant="body2" gutterBottom>
            <strong>Responsible for Care:</strong>
            <br />
            {responsible_for_care}
          </Typography>
        )}
        {hasEC && (
          <Typography variant="body2" gutterBottom>
            <strong>Primary Emergency Contact:</strong>
            <br />
            <strong>Name:</strong> {ec_name || '-'} <strong>{' | '}</strong>
            <strong>Address:</strong> {ec_address || '-'}
            <br />
            <strong>Phone:</strong> {ec_phone || '-'} <strong>{' | '}</strong>
            <strong>Email:</strong> {ec_email || '-'}
          </Typography>
        )}

        {hasSEC && (
          <Typography variant="body2" gutterBottom>
            <strong>Secondary Emergency Contact:</strong>
            <br />
            <strong>Name:</strong> {sec_name || '-'} <strong>{' | '}</strong>
            <strong>Address:</strong> {sec_address || '-'}
            <br />
            <strong>Phone:</strong> {sec_phone || '-'} <strong>{' | '}</strong>
            <strong>Email:</strong> {sec_email || '-'}
          </Typography>
        )}
      </div>
    </>
  );
};

export class OpsRecordCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isExpanded: props.defaultExpanded,
    };
    this.parseForms = this.parseForms.bind(this);
  }

  parseForms() {
    const { classes, ops_record } = this.props;
    let forms = ops_record.forms.map((_form, formIdx) => {
      const { pages } = _form;

      let pageBlocks = pages.map((p, pIdx) => {
        let fields = p.fields.map((f, ffi) => {
          if (f.type === 'table') {
            let rows = f.fields[0].row_values.map((rv, rvi) => {
              return (
                <div key={rvi}>
                  <span style={{ fontSize: 12, color: '#455A64' }}>
                    {f.label + ' ' + (rvi + 1)}:
                  </span>
                  <div style={{ fontSize: 12, padding: 8 }}>
                    {f.fields
                      .filter((tf) => tf.row_values[rvi] !== '')
                      .map((tf, tfi) => (
                        <div key={tfi}>
                          <span style={{ fontWeight: 500, color: '#607D8B' }}>
                            {tf.label}:{' '}
                          </span>
                          <span>{tf.row_values[rvi]}</span>
                        </div>
                      ))}
                  </div>
                </div>
              );
            });
            return <div key={ffi}>{rows}</div>;
          }

          return (
            <div key={ffi} style={{ fontSize: 12 }}>
              <span style={{ fontWeight: 500, color: '#607D8B' }}>
                {f.label}:{' '}
              </span>
              <span>{_.isArray(f.value) ? f.value.join(', ') : f.value}</span>
            </div>
          );
        });
        return (
          <div key={pIdx}>
            <strong style={{ fontSize: 12 }}>Page {pIdx + 1}</strong>
            <div>{fields}</div>
          </div>
        );
      });

      return (
        <div
          className={classes.branchGrid}
          key={formIdx}
          style={{ marginBottom: 8 }}>
          <Typography style={{ color: 'blue' }}>
            FORM: {_form.display_name}
          </Typography>
          {pageBlocks}
        </div>
      );
    });

    return (
      <>
        <div>
          {ops_record.memo &&
            ops_record.memo.split(/\n/g).map((n, nIdx) => {
              let textParts = n.split(/\n/g);
              textParts = textParts.map((p, pi) => {
                if (pi === textParts.length - 1) return p;
                return (
                  <span key={pi}>
                    {p}
                    <br />
                  </span>
                );
              });
              return (
                <Typography
                  key={nIdx}
                  paragraph
                  className={classes.noteTxt}
                  color="textSecondary">
                  {textParts}
                </Typography>
              );
            })}
        </div>
        <Typography style={{ fontWeight: 700 }}>
          Work Order #s: {ops_record.wos_ids || 'NO workorder#s attached'}
        </Typography>

        {forms}
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultExpanded !== this.props.defaultExpanded) {
      this.handlePanelChange(null, this.props.defaultExpanded);
    }
  }

  handlePanelChange = (e, expanded) => {
    this.setState({
      isExpanded: expanded,
    });
  };

  render() {
    const {
      classes,
      ops_record,
      isGeneral = false,
      isFetchingPDF,
    } = this.props;
    const { isExpanded } = this.state;

    return (
      <ExpansionPanel expanded={isExpanded} onChange={this.handlePanelChange}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography component="div" className={classes.panelHdrTxt}>
            {[
              '#',
              ops_record._id,
              '. ',
              ops_record.wos_ids.length
                ? ops_record.wos_ids.join(', ')
                : '[MISSING WO#]',
              '-',
              ops_record.forms.length,
              ' Forms Submitted by ',
              ops_record.u_nm,
            ].join(' ')}
            <span style={{ color: 'green' }}>
              {' on ' + moment(ops_record.ts).format('MM-DD-YYYY hh:mma')}
            </span>
            {isGeneral && (
              <span>
                &ensp;|&ensp;
                <Link
                  to={`/dfa/idn/${ops_record.idn.replace(
                    / /g,
                    '_'
                  )}/tabs/ops_form`}
                  className={classes.link}>
                  {ops_record.idn}
                </Link>
                &ensp;-&ensp;
                {ops_record.account && `A${ops_record.account}`}
              </span>
            )}
            {ops_record.void && (
              <Chip
                label="VOID"
                variant="outlined"
                color="primary"
                className={classes.chip}
              />
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="row" justify="flex-start" spacing={8}>
            <Grid item xs={12} className={classes.branchGrid}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.props.onViewPDF(ops_record, 'standard');
                }}
                disabled={isFetchingPDF}>
                View as Standard PDF&ensp;
                <VisibilityIcon fontSize="small" />
              </Button>
              {_.get(ops_record, ['forms', '0', 'id'], null) ===
              'wheelchair_home_assessment' ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.props.onViewPDF(
                      ops_record,
                      'wheelchair_home_assessment'
                    );
                  }}
                  disabled={isFetchingPDF}>
                  View as Wheelchair PDF&ensp;
                  <VisibilityIcon fontSize="small" />
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    this.props.onViewPDF(ops_record, 'rx');
                  }}
                  disabled={isFetchingPDF}>
                  View as RX PDF&ensp;
                  <VisibilityIcon fontSize="small" />
                </Button>
              )}
              {this.parseForms()}

              <ContactInfoDisplay
                classes={classes}
                contactInfo={ops_record?.contactInfo}
              />

              <div style={{ margin: '16px 0' }}>
                <Typography variant="body2" gutterBottom>
                  <strong>Comments:</strong>
                </Typography>
                {ops_record.comments && ops_record.comments.length > 0 ? (
                  ops_record.comments.map((comment, nIdx) => {
                    let textParts = comment.txt.split(/\n/g);
                    textParts = textParts.map((p, pi) => {
                      if (pi === textParts.length - 1) return p;
                      return (
                        <span key={pi}>
                          {p}
                          <br />
                        </span>
                      );
                    });
                    return (
                      <Typography
                        key={nIdx}
                        paragraph
                        className={classes.noteTxt}
                        color="textSecondary">
                        {textParts}
                        <i className={classes.noteUser}>
                          {' '}
                          - {comment.u_id}{' '}
                          {moment(comment.ts).format('M-DD-YY h:mma')}
                        </i>
                      </Typography>
                    );
                  })
                ) : (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    gutterBottom>
                    No comments
                  </Typography>
                )}
              </div>
              <OpsRecordCardForm
                record={ops_record}
                currentUser={this.props.currentUser}
                isGeneral={isGeneral}
              />
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

export const OpsRecordPdfViewer = (props) => {
  const { isFetchingPDF, recordOnView, pdfError, pdfURI } = props;

  if (isFetchingPDF) {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        Loading PDF for&ensp;
        <strong>#{recordOnView._id || '_'}</strong>, please wait...
      </Typography>
    );
  }

  if (pdfError) {
    return (
      <Typography variant="body2" color="error" align="center">
        Failed to load PDF for&ensp;
        <strong>#{recordOnView._id || '_'}</strong>
      </Typography>
    );
  }

  if (pdfURI) {
    return (
      <>
        <Typography variant="body2">
          Ops Record <strong>#{recordOnView._id || '_'}</strong> PDF:
        </Typography>
        <div style={{ height: '98%' }}>
          <embed
            type="application/pdf"
            src={pdfURI + '#zoom=90'}
            marginWidth="0"
            style={{ width: '99%', height: '100%' }}
          />
        </div>
      </>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Click 'VIEW AS PDF' button to load PDF file
    </Typography>
  );
};

class OpsRecordsListing extends React.Component {
  constructor() {
    super();

    this.state = {
      pdfURI: '',
      pdfError: null,
      isFetchingPDF: false,
      recordOnView: null,
    };
    this.fetchOpsRecordPDF = this.fetchOpsRecordPDF.bind(this);
  }

  fetchOpsRecordPDF(opsRecord, kind) {
    this.setState({
      isFetchingPDF: true,
      recordOnView: { ...opsRecord },
    });
    window.sch
      .get(`/api/ops_form/pdf_uri/${opsRecord._id}/${kind}`)
      .then((result) => {
        this.setState({
          pdfURI: result.datauri,
          pdfError: null,
        });

        if (this.props.isMobileView && this.state.recordOnView) {
          this.openPdfInNewPage(result.datauri);
        }
      })
      .catch((error) => {
        this.setState({
          pdfURI: '',
          pdfError: true,
        });

        if (this.props.isMobileView) {
          this.props.openSnack({
            variant: 'error',
            message: `Failed to load PDF for #${this.state.recordOnView._id}`,
          });
        }
      })
      .finally(() => {
        this.setState({ isFetchingPDF: false });
      });
  }

  openPdfInNewPage = (dataURL) => {
    const { _id } = this.state.recordOnView;
    let pdfWindow = window.open('about:blank', `ops_form_pdf_${_id}`);
    pdfWindow.document.open();
    pdfWindow.document.write(`
      <html>
        <head>
          <title>Ops Record #${_id} - PDF</title>
        </head>
        <body style="margin: 0px;">
          <iframe
            src="${dataURL}"
            height="100%"
            width="100%"
            style="border: unset;"
          ></iframe>
        </body>
      </html>
    `);
    pdfWindow.document.close();
  };

  render() {
    const { classes, opsFormRecordsInView, isMobileView } = this.props;

    if (opsFormRecordsInView.error) {
      return (
        <div className={classes.pagination}>
          <Typography className={classes.pageStatement} color="textSecondary">
            Error fetching past requests
          </Typography>
        </div>
      );
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid
          item
          xs={12}
          md={!isMobileView ? 6 : 12}
          className={classes.fullHeight}>
          <div className={classes.content}>
            <div className={classes.pagination}>
              <Typography
                className={classes.pageStatement}
                color="textSecondary">
                {opsFormRecordsInView.records.length} Submitted Forms found for{' '}
                {opsFormRecordsInView.idn.replace(/_/g, ' ')}
              </Typography>
            </div>

            <div className={classes.scrollArea}>
              <div className={classes.root} style={{ padding: 4 }}>
                {opsFormRecordsInView.records.map((r, posInSet) => {
                  return (
                    <OpsRecordCard
                      key={posInSet}
                      classes={classes}
                      ops_record={r}
                      defaultExpanded={false}
                      currentUser={{ ...this.props.me.user }}
                      onViewPDF={this.fetchOpsRecordPDF}
                      isFetchingPDF={
                        this.state.recordOnView &&
                        this.state.recordOnView._id === r._id &&
                        this.state.isFetchingPDF
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Grid>
        {!isMobileView && (
          <Grid item xs={12} md={6} className={classes.fullHeight}>
            <div
              className={[classes.content, classes.opsRecordPDFViewer].join(
                ' '
              )}>
              <OpsRecordPdfViewer {...this.state} />
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

/*
class PreviewTabContent extends React.Component {
  render() {
    return (
      <>
        {this.props.status === 'NEW' && (
          <div>
            <h5>Preview for: {this.props.previewTabId}</h5>
            <CircularProgress
              value={50}
              color="primary"
              variant="indeterminate"
              thickness={4}
            />
            <label>
              ...pdf preview rendering in progress, this should take a few
              seconds.
            </label>
          </div>
        )}

        {this.props.status === 'RENDERED' && (
          <>
            <div>
              <span style={{ fontSize: 12 }}>
                If PDF is not viewable below, try downloading it directly by
                clicking here:{' '}
                <a
                  style={{ fontSize: 11 }}
                  href={this.props.pdfUri}
                  download="file.pdf">
                  Download
                </a>
              </span>
            </div>
            <br />
            <embed
              type="application/pdf"
              src={this.props.pdfUri + '#zoom=50'}
              marginWidth="0"
              style={{ width: '99%', height: '94%' }}
            />
          </>
        )}
      </>
    )
  }
}
*/

class IdnTabOpsForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRightPanel: props.subTab || 'opsRecordFormTab',
      selectedCmnBranch: null,
      previewTabs: [],
      faxNumber: '',
      allowMessagTemplates: false,
      transmissionDestination: '',
      transmissionDestinationIsValid: false,
      tplsLoaded: {},
      qrCodeHash: null,
      hidePAPopulateBtn: false,
      lastSelectedPA: null,
    };

    this.handlePanelChange = this.handlePanelChange.bind(this);
    this.handleRightPanelChange = this.handleRightPanelChange.bind(this);
    this.createPdfFromTpl = this.createPdfFromTpl.bind(this);

    this.updatePacketStatus = this.updatePacketStatus.bind(this);
    this.overrideFaxNumber = this.overrideFaxNumber.bind(this);
    this.onOpsFormTplChange = this.onOpsFormTplChange.bind(this);
    this.loadTplJson = this.loadTplJson.bind(this);

    this.preUploadSelectedPreviewFiles =
      this.preUploadSelectedPreviewFiles.bind(this);
    this.onMessageTemplatePopulate = this.onMessageTemplatePopulate.bind(this);
    this.onMessageTplSelected = this.onMessageTplSelected.bind(this);
    this.onRevalidateDestination = this.onRevalidateDestination.bind(this);
  }

  handlePanelChange(e, _tab) {
    this.setState({ selectedLeftPanelTab: _tab });
  }
  handleRightPanelChange(e, _tab) {
    this.setState({ selectedRightPanel: _tab });
  }

  componentDidMount() {
    const { opsFormRecordsInView, match } = this.props;
    if (this.props.me.user === null) {
      return;
    }
    if (opsFormRecordsInView.idn !== match.params.selectedIdn) {
      this.props.fetchOpsFormRecords_ac(match.params.selectedIdn);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    const { opsFormRecordsInView, opsFormRecordActivity, match } = this.props;
    if (this.props.me.user === null) {
      return;
    }

    if (opsFormRecordsInView.idn !== match.params.selectedIdn) {
      this.props.fetchOpsFormRecords_ac(match.params.selectedIdn);
      return;
    }

    if (this.props.subTab && this.props.subTab !== prevProps.subTab) {
      this.setState({
        selectedRightPanel: this.props.subTab,
      });
    }

    if (opsFormRecordActivity.state === 'new') {
      if (this.props.pdfGen.preview.result === null) {
        return;
      }
    }

    /* when user clicks RESET FORM in post-create doc request */
    if (
      opsFormRecordActivity.state === 'success' &&
      prevProps.opsFormRecordActivity.state === 'inprog'
    ) {
      //reset to initial state
      this.setState({
        selectedRightPanel: 'opsRecordFormTab',
        selectedCmnBranch: null,
        previewTabs: [],
        faxNumber: '',
        allowCMNSelect: false,
        allowPASelect: true,
        allowMessagTemplates: false,
        transmissionDestination: '',
        transmissionDestinationIsValid: false,
        tplsLoaded: {},
        qrCodeHash: null,
        hidePAPopulateBtn: false,
        lastSelectedPA: null,
      });
    }
  }

  async onOpsFormTplChange(docTypesToBeTransmitted) {
    let tplsToLoad = [];
    let tplsToUnLoad = [];
    docTypesToBeTransmitted.forEach((d) => {
      if (
        d.checked === true &&
        d.tplId &&
        this.state.tplsLoaded[d.tplId] ===
          undefined /*don't reload an already loaded tpl */
      ) {
        tplsToLoad.push(d.tplId);
      }

      if (
        d.checked === false &&
        d.tplId &&
        this.state.tplsLoaded[d.tplId] !==
          undefined /*mark those already loaded for unloading*/
      ) {
        tplsToUnLoad.push(d.tplId);
      }
    });

    let tplsDataFromServer = [];
    if (tplsToLoad.length > 0) {
      tplsDataFromServer = await Promise.all(tplsToLoad.map(this.loadTplJson));
    }
    let _tpls = _.cloneDeep(this.state.tplsLoaded);
    tplsDataFromServer
      .filter((t) => t)
      .forEach((t) => {
        //t is an object { tplid: ..., tpl: ...}
        _tpls[t.tplId] = t;
      });

    let docTypesSelected = docTypesToBeTransmitted
      .filter((d) => d.checked)
      .map((d) => d.code);
    let _s = {
      allowMessagTemplates:
        _.intersection(docTypesSelected, ['email', 'fax-cover']).length > 0,
    };

    if (tplsToUnLoad.length > 0) {
      tplsToUnLoad.forEach((tplId) => {
        delete _tpls[tplId];
        _s['previewTabs'] = this.state.previewTabs.filter(
          (pt) => pt.previewTabId !== tplId
        );
      });
    }

    _s['tplsLoaded'] = _tpls;
    this.setState(_s);
  }

  loadTplJson(tplId) {
    if (this.state.tplsLoaded[tplId] !== undefined) {
      return Promise.resolve({ ...this.state.tplsLoaded[tplId] });
    }
    return window.sch
      .get(`/api/tpl/get/ops_forms/${tplId}.ops_forms`)
      .then((results) => {
        return { tplId, tpl: results.tpl };
      })
      .catch((error) => {
        console.error('error:', error);
      });
  }

  onChangeTplTextFieldValue = (tplId, field) => (evt) => {
    let _loadedTpls = { ...this.state.tplsLoaded };
    _loadedTpls[tplId].tpl.pages[field.page].fields[field.fPos].value =
      evt.target.value.toString();
    let _s = { tplsLoaded: _loadedTpls };
    this.setState(_s);
  };

  onRevalidateDestination() {
    let _loadedTpls = { ...this.state.tplsLoaded };

    let _s = { tplsLoaded: _loadedTpls };

    for (let tplId in _loadedTpls) {
      _loadedTpls[tplId].tpl.pages.forEach((page) => {
        page.fields.forEach((field) => {
          if (['to_fax_number', 'drfax'].indexOf(field.nm) > -1) {
            _s['transmissionDestinationIsValid'] = isFaxNumber(field.value);
          } else if ('to_email' === field.nm) {
            _s['transmissionDestinationIsValid'] = isEmailAddress(field.value);
          }
        });
      });
    }
    console.log('>>>', _s['transmissionDestinationIsValid']);

    this.setState(_s);
  }

  onChangeTplCheckboxFieldValue = (tplId, field) => (evt) => {
    let _loadedTpls = { ...this.state.tplsLoaded };

    _loadedTpls[tplId].tpl.pages[field.page].fields[field.fPos].options =
      _loadedTpls[tplId].tpl.pages[field.page].fields[field.fPos].options.map(
        (o) => {
          if (o.nm === evt.target.value) {
            o.checked = !o.checked;
          }
          return o;
        }
      );

    //apply the selected opts as an array for the value property
    _loadedTpls[tplId].tpl.pages[field.page].fields[field.fPos].value =
      _loadedTpls[tplId].tpl.pages[field.page].fields[field.fPos].options
        .filter((o) => o.checked)
        .map((o) => o.nm);

    let _s = { tplsLoaded: _loadedTpls };
    this.setState(_s);
  };

  onChangeTplTextFieldValue_TableRowField =
    (tplId, par_field_page, par_field_pos, row_position, tbl_field) =>
    (evt) => {
      let _loadedTpls = { ...this.state.tplsLoaded };
      _loadedTpls[tplId].tpl.pages[par_field_page].fields[par_field_pos].fields[
        tbl_field.pos
      ].row_values[row_position] = evt.target.value.toString();

      let _s = { tplsLoaded: _loadedTpls };
      this.setState(_s);
    };

  updatePacketStatus(previewTabId, newStatus, pdfDataUri) {
    const _previewTabs = [].concat(this.state.previewTabs).map((p) => {
      if (p.previewTabId === previewTabId) {
        p.status = newStatus;
        p.pdfUri = pdfDataUri;
      }
      return p;
    });
    this.setState({ previewTabs: _previewTabs });
  }

  async preUploadSelectedPreviewFiles() {
    const baseS3Path =
      'uploads/' +
      this.props.idnInView.idnStr.replace(/[^A-Za-z0-9]/g, '_') +
      '/';
    const api_path = `/api/upload-data-uri`;
    let toUploadUsingDataURI = [].concat(
      this.state.previewTabs
        .filter((p) => p.checked)
        .map((p) => {
          p.s3pathAndKey =
            baseS3Path +
            p.previewTabId.replace(/[^A-Za-z0-9]/, '_') +
            '.' +
            this.props.opsFormRecordActivity.qrCodeHash +
            '.pdf';
          return p;
        })
    );

    if (toUploadUsingDataURI.length === 0) {
      return console.log('none to upload over dataURI');
    }

    let proms = toUploadUsingDataURI.map((p) => {
      let postData = {
        s3pathAndKey: p.s3pathAndKey,
        dataURI: p.pdfUri,
      };

      return window.sch.post(api_path, postData);
    });

    let uploadResults = await Promise.all(proms);
    console.log('results of datauri upload', uploadResults);

    if (
      uploadResults.filter((u) => {
        return u.error !== undefined;
      }).length
    ) {
      throw new Error('Atleast one upload of PDF failed');
    }

    //we only care about keeping the tabs that got uploaded
    this.setState({ previewTabs: toUploadUsingDataURI });
  }

  /*
  checkboxes representing user's selection of a preview tab to be included
  as one of the docrequest documents
  */
  previewFileSelected = (previewTabId) => () => {
    const _previewTabs = [].concat(this.state.previewTabs).map((p) => {
      if (p.previewTabId === previewTabId) {
        p.checked = !p.checked;
      }
      return p;
    });
    this.setState({ previewTabs: _previewTabs });
  };

  onMessageTplSelected() {
    return;
    /*
    this.setState({
      hidePAPopulateBtn: (_.get(topicTpl,'enable_panels',[]).indexOf('pas') === -1)
    })
    */
  }

  onMessageTemplatePopulate(fieldName, arrOfLinesText) {
    let _tpls = _.cloneDeep(this.state.tplsLoaded);
    //tpl must be either fax-cover-sheet or email
    let tpl_name =
      undefined === _tpls['fax_cover_sheet'] ? 'email' : 'fax_cover_sheet';
    let msg_body_field_pos = _tpls[tpl_name].tpl.pages[0].fields.findIndex(
      (f) => f.nm === fieldName
    );
    _tpls[tpl_name].tpl.pages[0].fields[msg_body_field_pos].value =
      arrOfLinesText.join('\n');
    this.setState({ tplsLoaded: _tpls });
  }

  // pdfURIString is passed in in case of dynamic forms
  createPdfFromTpl(tplId, pdfURIString) {
    const _previewTabs = [].concat(this.state.previewTabs);
    const { tpl } = this.state.tplsLoaded[tplId];
    const PREVIEW_TAB_ID = tpl.id.toString();

    /* this might be a re-create attempt, so check for existing preview tab */
    let existingPreviewIdx = _previewTabs.findIndex(
      (p) => p.previewTabId === PREVIEW_TAB_ID
    );
    if (existingPreviewIdx > -1) {
      _previewTabs[existingPreviewIdx].pages = tpl.pages;
      _previewTabs[existingPreviewIdx].status = 'NEW';
    } else {
      _previewTabs.push({
        previewTabId: PREVIEW_TAB_ID,
        pages: tpl.pages,
        status: 'NEW', //same as what PPL sets initial status to
        checked: false, //will be presented as check box in docrequest form, default to Unchecked
      });
    }

    if (tplId === 'scrx' && process.env.REACT_APP_IS_DEV === 'yes') {
      // TODO SCRX
      this.setState(
        {
          previewTabs: _previewTabs,
        },
        () => {
          this.updatePacketStatus(tplId, 'RENDERED', pdfURIString);
        }
      );
      return;
    }

    this.setState({
      previewTabs: _previewTabs,
      selectedRightPanel: PREVIEW_TAB_ID,
    });

    return imgTools
      .generatePdfFromInPageForm(
        tpl.pages,
        this.props.opsFormRecordActivity.qrCodeHash
      )
      .then((pdfUri) => {
        //console.log(pdfUri)
        this.updatePacketStatus(PREVIEW_TAB_ID, 'RENDERED', pdfUri);
        return;
      })
      .catch((error) => {
        console.error('error:', error);
        return;
      });
  }

  /*
    sc-ink -> fax:download:get_map"  -> payloads as SAMPLE_PICKED above
  */
  createPdfFromExistingDocflowDocs(payloads) {
    const _previewTabs = [].concat(this.state.previewTabs);
    const PREVIEW_TAB_ID = 'SelectedExistingDocs';

    /* this might be a re-create attempt, so check for existing preview tab */
    let existingPreviewIdx = _previewTabs.findIndex(
      (p) => p.previewTabId === PREVIEW_TAB_ID
    );
    if (existingPreviewIdx > -1) {
      //_previewTabs[existingPreviewIdx].pages = tpl.pages;
      _previewTabs[existingPreviewIdx].status = 'NEW';
    } else {
      _previewTabs.push({
        previewTabId: PREVIEW_TAB_ID,
        //pages: tpl.pages,
        status: 'NEW', //same as what PPL sets initial status to
        checked: false, //will be presented as check box in docrequest form, default to Unchecked
      });
    }

    this.setState({
      previewTabs: _previewTabs,
      selectedRightPanel: PREVIEW_TAB_ID,
    });

    return imgTools
      .generatePdfFromExistingDocs(payloads)
      .then((pdfUri) => {
        this.updatePacketStatus(PREVIEW_TAB_ID, 'RENDERED', pdfUri);
        return;
      })
      .catch((error) => {
        console.error('error:', error);
        return;
      });
  }

  overrideFaxNumber(faxNum) {
    this.setState({ faxNumber: faxNum });
  }

  render() {
    const { classes, idnInView, opsFormRecordsInView, match } = this.props;
    const isMobileView = match && match.url && match.url.includes('/mobile');

    if (!idnInView.idnDoc || null === opsFormRecordsInView.idn) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....</h4>
        </div>
      );
    }
    if (opsFormRecordsInView.isFetching) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....fetching</h4>
        </div>
      );
    }
    const byTabKlass = {
      root: classes.byTabKlass,
      labelContainer: classes.tabLabelContainer,
    };

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={12} className={classes.fullHeight}>
          <main className={classes.content}>
            <Tabs
              value={this.state.selectedRightPanel}
              onChange={this.handleRightPanelChange}
              indicatorColor="secondary"
              textColor="secondary"
              scrollButtons="off"
              variant="scrollable">
              <Tab
                classes={byTabKlass}
                value="opsRecordFormTab"
                label="Fill Out Form"
              />
              {this.state.previewTabs.map((pt) => (
                <Tab
                  key={pt.previewTabId}
                  classes={byTabKlass}
                  value={pt.previewTabId}
                  label={`PREVIEW:${pt.previewTabId}`}
                />
              ))}
              <Tab
                classes={byTabKlass}
                value="opsRecordListingTab"
                label="Submitted Forms"
              />
              <Tab
                classes={byTabKlass}
                value="healthcallTab"
                label="Healthcall"
              />
              <Tab
                classes={byTabKlass}
                value="reacthealth"
                label="REACTHEALTH"
              />
            </Tabs>

            <div className={classes.content} style={{ padding: 4 }}>
              <OpsForm
                extras={this.props.match.params.extras}
                tplsLoaded={this.state.tplsLoaded}
                isVisible={this.state.selectedRightPanel === 'opsRecordFormTab'}
                onChangeTplTextFieldValue={this.onChangeTplTextFieldValue}
                onChangeTplCheckboxFieldValue={
                  this.onChangeTplCheckboxFieldValue
                }
                selectedCmnBranch={this.state.selectedCmnBranch}
                onChangeTplTextFieldValue_TableRowField={
                  this.onChangeTplTextFieldValue_TableRowField
                }
                onChangeTplCheckboxFieldValue_TableRowField={
                  this.onChangeTplCheckboxFieldValue_TableRowField
                }
                onOpsFormTplChange={this.onOpsFormTplChange}
                onTemplateRenderPreview={this.createPdfFromTpl}
                templateData={this.state.templateData}
                previewTabs={this.state.previewTabs}
                previewFileSelected={this.previewFileSelected}
                preUploadSelectedPreviewFiles={
                  this.preUploadSelectedPreviewFiles
                }
                transmissionDestination={this.state.transmissionDestination}
                transmissionDestinationIsValid={
                  this.state.transmissionDestinationIsValid
                }
                qrCodeHash={this.props.opsFormRecordActivity.qrCodeHash}
              />
              {this.state.selectedRightPanel === 'opsRecordListingTab' && (
                <OpsRecordsListing
                  {...this.props}
                  isMobileView={isMobileView}
                  openSnack={this.props.open_snack_ac}
                />
              )}

              {this.state.selectedRightPanel === 'healthcallTab' && (
                <IdnHealthCall idnData={this.props.idnInView} />
              )}

              {this.state.selectedRightPanel === 'reacthealth' && (
                <ReactHealthView idnData={this.props.idnInView} />
              )}
            </div>
          </main>
        </Grid>
      </Grid>
    );
  }
}

IdnTabOpsForms.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  idnInView: stateFromStore.idnInView,
  idnAccountInfo: stateFromStore.idnAccountInfo,
  opsFormRecordsInView: stateFromStore.opsFormRecordsInView,
  opsFormRecordActivity: stateFromStore.opsFormRecordActivity,
  pdfGen: stateFromStore.pdfGen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchOpsFormRecords_ac,
      open_snack_ac,
      attemptTransmission_ac,
      updateTrmFaxNumber_ac,
      markTransmissionMan_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IdnTabOpsForms))
);

//export default withStyles(styles)(IdnTabOpsForms)
