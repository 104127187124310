import React from 'react'

import SearchField from '../Shared/SearchField'

const RepSearchField = props => {
  const {
    label,
    placeholder,
    fullWidth = false,
    selectRep,
    disabled = false,
    baseParams = {},
  } = props

  const searchReps = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr, ...baseParams })
  }

  const renderRepSuggestion = (suggestion = {}) => {
    return (
      <span>
        {suggestion.display_name || ''}
      </span>
    )
  }

  return (
    <SearchField
      fullWidth={fullWidth}
      clearOnSelect
      delay={500}
      minChars={3}
      variant="outlined"
      onCrossClick={() => selectRep(null)}
      resultsKey="pkg.rr"
      label={label !== undefined ? label : 'Rep'}
      disabled={disabled}
      style={{ flexGrow: 1 }}
      search={searchReps}
      selectSuggestion={selectRep}
      apiEndpoint="/api/idx/admin/reps/search"
      scrollableParentId="idxAdminLocationsContianer"
      renderSuggestion={renderRepSuggestion}
      helperText="Minimum 3 characters required"
      placeholder={placeholder !== undefined ? placeholder : 'Search by Name'}
    />
  )
}

export default RepSearchField
