import { FBSearchActionTypes } from '../actions/fb_search.ac'

export const fbSearchInView = (
  state = {
    params: null,
    isFetching: false,
    error: null,
    result: null,
    pagination: null
  },
  action
) => {
  switch (action.type) {
    case FBSearchActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }

    case FBSearchActionTypes.SEARCH_RESULT_RECEIVED:
      return {
        ...state,
        result: action.result,
        pagination: action.pagination,
        error: null
      }

    case FBSearchActionTypes.FAILED_SEARCHING:
      return {
        ...state,
        error: action.error
      }

    case FBSearchActionTypes.RESET_SEARCH:
      return {
        ...state,
        isFetching: false,
        error: null,
        result: null,
        pagination: null
      }

    default:
      return {
        ...state
      }
  }
}
