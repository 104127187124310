export function fetchTeams_ac(){
  return (dispatch, getState) => {
    dispatch({type:'REQUEST_TEAMS'})
    return window.sch.get(`/api/teams/list`)
    .then( result => {
      dispatch({
        type: 'RECEIVED_TEAMS',
        pkg: result.pkg
      })
    })
    .catch(error=> {
      dispatch({
        type: 'FAILED_TEAMS',
        error
      })
    });
  }
}


export function updateTeamPreCCS_ac(recordIdx, params){
  return (dispatch, getState) => {
    return window.sch.post(`/api/teams/preccs/update`,params)
    .then( result => {
      let _pkg = getState().teams.pkg;

      _pkg.teams[ recordIdx ] = result.updatedTeam;

      dispatch({
        type: 'RECEIVED_TEAMS',
        pkg: _pkg
      })
    })
    .catch(error=> {
      dispatch({
        type: 'FAILED_TEAMS',
        error
      })
    });
  }
}
