import _ from 'lodash';
import { setTitle_ac } from './me.ac';

// const attach_legacy_notif_prefs = async (pkg) => {
//   //get notif prefs
//   const notif_target_url = '/api/notifs:patient:lookup';
//   let proms = pkg.accounts.map((_p) => {
//     return window.sch.post(notif_target_url, {
//       Account: _p.account,
//       Member: _p.member,
//     });
//   });

//   let arr = await Promise.all(proms);
//   arr = _.flatten(arr.map((a) => a.Users));
//   let notif_contacts = [];
//   let notif_contact_keys = [];
//   let notif_history = [];
//   try {
//     arr.forEach((u) => {
//       let pos = notif_contact_keys.indexOf(u.Email + u.SMS);
//       if (-1 === pos) {
//         notif_contact_keys.push(u.Email + u.SMS);
//         u.acctMembers = [[u.Account, u.Member].join('-')];
//         notif_contacts.push(u);
//       } else {
//         notif_contacts[pos].acctMembers.push([u.Account, u.Member].join('-'));
//       }
//       notif_history = notif_history.concat(u.notif_history);
//     });
//   } catch (err) {
//     console.error(err);
//   }

//   pkg.notif_contacts = notif_contacts;
//   pkg.notif_history = notif_history;
//   return pkg;
// };

export function fetchIdnAccountInfo_ac(idn, hdmsAccts, skipProms = false) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_IDN_ACCOUNT_INFO', idn });
    let pkg = {};

    let target_url = '/api/hdms:acct:prime';
    let postData = { idn, hdmsAccts };
    let title = '...';
    return window.sch
      .post(target_url, postData)
      .then((acctPkg) => {
        pkg = { ...pkg, accounts: acctPkg.rr };
        title =
          idn.replace(/_/g, ' ') +
          ' - ' +
          pkg.accounts
            .map((a) => {
              return [
                a.df_am,
                _.get(a, 'flags', []).length
                  ? ' ***' + a.flags.join(' ') + '***'
                  : '',
              ].join('');
            })
            .join(', ');

        if (skipProms) {
          return [];
        }

        pkg.notif_contacts = [];
        pkg.notif_history = [];
        // return attach_legacy_notif_prefs(pkg);
        return Promise.resolve(pkg); //when we don't need legacy notifs anymore
      })
      .then((pkg) => {
        setTitle_ac(title)(dispatch);
        dispatch({ type: 'RECEIVED_IDN_ACCOUNT_INFO', pkg: pkg });
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_RECEIVED_IDN_ACCOUNT_INFO', error });
      });
  };
}

export function fetchIdnAccountNotifPref_ac(params) {
  //params: { accounts: [ {Account: 168009, Member: 2}, .. ] }
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    const target_url = '/api/notifs:patient:lookup';
    let _a = getState().idnAccountInfo;
    let proms = params.accounts.map((_p) => {
      return window.sch.post(target_url, _p);
    });

    dispatch({ type: 'REQUEST_IDN_NOTIF_PREF' });
    return Promise.all(proms)
      .then((arr) => {
        arr = _.flatten(arr.map((a) => a.Users));
        let notif_contacts = [];
        let notif_contact_keys = [];
        let notif_history = [];
        try {
          arr.forEach((u) => {
            let pos = notif_contact_keys.indexOf(u.Email + u.SMS);
            if (-1 === pos) {
              notif_contact_keys.push(u.Email + u.SMS);
              u.acctMembers = [[u.Account, u.Member].join('-')];
              notif_contacts.push(u);
            } else {
              notif_contacts[pos].acctMembers.push(
                [u.Account, u.Member].join('-')
              );
            }
            notif_history = notif_history.concat(u.notif_history);
          });
        } catch (err) {
          console.error(err);
        }
        //consolidate notifcation preferences across members
        _a.pkg.notif_contacts = notif_contacts;
        _a.pkg.notif_history = notif_history;
        dispatch({ type: 'RECEIVED_IDN_NOTIF_PREF', pkg: _a.pkg });
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_RECEIVED_NOTIF_PREF', error });
      });
  };
}

export const setAccountCreateTabOpen_ac = (isOpen) => (dispatch) => {
  dispatch({
    type: 'SET_ACCOUNT_CREATE_TAB_OPEN',
    isOpen,
  });
};

export const saveFromData_ac = (data) => (dispatch) => {
  dispatch({
    type: 'SET_ACCOUNT_CREATION_DATA',
    data,
  });
};

export function onboardNotifs_ac(params) {
  //params: { accounts: [ {Account: 168009, Member: 2}, .. ] }
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'INIT_ONBOARD_NOTIFS' });
    const target_url = `/api/notifs:patient:onboard`;
    return window.sch
      .post(target_url, params)
      .then((result) => {
        dispatch({ type: 'SUCCESS_ONBOARD_NOTIFS', result });
      })
      .catch((error) => {
        dispatch({ type: 'FAILED_ONBOARD_NOTIFS', error });
      });
  };
}
