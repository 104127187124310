import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateTodo_ac } from '../../actions/todos.ac.js';
import UserSearch from '../Users/UserSearch';
import TodoCard from './TodoCard';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  linkTo: {
    textDecoration: 'none',
    fontWeight: 500,
    '&:hover': {
      color: '#000',
      fontWeight: 500,
    },
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  tagChip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    backgroundColor: 'rgba(187, 239, 46, 0.93)',
  },
  panelBtn: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    paddingTop: 2,
    paddingBottom: 0,
  },
  panelRoot: {
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'start',
  },
  btnContainer: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  radioGroupRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  radioGroupRowItem: {
    //border: '1px solid #000'
  },
  checkboxGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  checkboxGroupRowItem: {
    flex: '1 180px',
    //border: '1px solid #000',
    marginLeft: 0,
  },
  expandClosedIcon: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.primary.main,
  },
  expandOpenIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main,
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2',
  },
  noteUser: {
    marginLeft: 0,
    color: '#244e91',
    fontSize: '.98em',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
  },
  listItemParaPre: {
    padding: 6,
    marginTop: 0,
    fontSize: 11,
    borderRadius: 4,
    margin: '4px 4px 4px 0px',
    color: '#000',
    backgroundColor: 'rgba(233, 236, 241, 0.49)',
  },
  efPara: {
    padding: 6,
    marginTop: 0,
    fontSize: 12,
    borderRadius: 4,
    margin: '4px 4px 4px 0px',
    color: '#000',
    backgroundColor: '#efefff',
  },
  closedTaskPrimaryChip: {
    color: '#fff',
    backgroundColor: '#4db6ac',
  },
  newTaskPrimaryChip: {
    color: '#4db6ac',
    border: '1px solid #4db6ac',
  },
  modalWrapper: {
    width: '550px',
    maxWidth: 'calc(100% - 120px)',
    maxHeight: 'calc(100% - 80px)',
    overflowY: 'auto',
    padding: '0px 16px',
    marginTop: '40px',
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: 'white',
    outline: 'none',
  },
  modalHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: 'inherit',
  },
  borderlessCell: {
    padding: '8px',
  },
  expandedCell: {
    /*boxShadow: '0px 0px 5px grey inset', */

    borderRadius: 4,
    backgroundColor: '#FAFAFA',
  },
  scrollableCellContent: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
});

const LabelValueSet = (props) => {
  return (
    <Typography component="div" gutterBottom>
      <strong>{props.label}:</strong>&ensp;
      <span>{props.value}</span>
    </Typography>
  );
};

class ExpandableTodoRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      error: null,
      tmp_helpers: [],
      stateFieldValues: {},
      checkedTags: new Set(),
      selectedTodoState: null,
      openStatusUpdateModal: false,
      isCollapseOpen: props.expanded,
      openAssignmentUpdateModal: false,
    };

    this.getLabel = this.getLabel.bind(this);
    this.addHelper = this.addHelper.bind(this);
    this.removeHelper = this.removeHelper.bind(this);
    this.takeOwnership = this.takeOwnership.bind(this);
    this.changeAssignee = this.changeAssignee.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.todoStateSelected = this.todoStateSelected.bind(this);
    this.handleTagCheckbox = this.handleTagCheckbox.bind(this);
    this.handleCommentInput = this.handleCommentInput.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
    this.onStateFieldChanged = this.onStateFieldChanged.bind(this);
    this.handelCollapseToggle = this.handelCollapseToggle.bind(this);
    this.handleUpdateButtonClick = this.handleUpdateButtonClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        isCollapseOpen: this.props.expanded,
      });
    }
  }

  handelCollapseToggle() {
    this.setState({
      isCollapseOpen: !this.state.isCollapseOpen,
    });
  }

  handleUpdateButtonClick = (modalStateToUpdate) => () => {
    this.setState({
      [modalStateToUpdate]: true,
    });
  };

  handleModalClose() {
    this.setState({
      openStatusUpdateModal: false,
      openAssignmentUpdateModal: false,
    });
  }

  todoStateSelected(evt) {
    this.setState({
      selectedTodoState: evt.target.value,
      checkedTags: new Set(),
      stateFieldValues: {},
    });
  }

  getLabel(code) {
    return _.keyBy(this.props.lookups.ink.k.TODO_STATE_REFERENCE, 'code')[code]
      .lbl;
  }

  handleCommentInput(event) {
    const comment = event.target.value;

    this.setState({
      comment: comment,
    });
  }

  handleCommentSubmit() {
    let pkg = {
      todoId: this.props.todoDoc._id,
      kind: 'comment_only',
      current_last_update: moment(this.props.todoDoc.last_update)
        .toDate()
        .getTime(),
      comment: this.state.comment.toString(),
    };
    this.props.updateTodo_ac(this.props.posInSet, pkg);
    this.setState({ comment: '' });
  }

  handleTagCheckbox = (stateRef) => (evt) => {
    if (evt.target.checked === true) {
      if (stateRef.maxTags) {
        if (this.state.checkedTags.size + 1 > stateRef.maxTags) {
          return;
        }
      }
    }
    let _checkedTags = new Set(this.state.checkedTags);
    evt.target.checked
      ? _checkedTags.add(evt.target.value)
      : _checkedTags.delete(evt.target.value);
    this.setState({
      checkedTags: _checkedTags,
    });
  };

  onStateFieldChanged = (stateField) => (e) => {
    this.setState({
      stateFieldValues: {
        ...this.state.stateFieldValues,
        [stateField.nm]: e.target.value,
      },
    });
  };

  takeOwnership() {
    const { todoDoc, lookups, me } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.assignee_id === me.user.mail) {
      return; //already owner
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states.filter(
        (s) => s.nm === todoDoc.state
      )[0];

      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'take_ownership',
      current_assignee_id: todoDoc.assignee_id,
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      snackMsg: 'Ownership updated',
    };
    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  onLocalUpdate(posInSet, todoDoc) {
    this.props.locallyUpdateTodoDoc_ac(posInSet, todoDoc);
  }
  changeAssignee(item) {
    const { todoDoc, lookups } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.assignee_id === item.email) {
      return; //already an helper
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states
        .filter((a) => {
          if (a.cond === undefined) {
            return true;
          }
          return (
            a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1
          );
        })
        .filter((s) => s.nm === todoDoc.state)[0];
      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'change_assignee',
      assignee_id: item.email,
      assignee_nm: item.orig_nm,
      current_assignee_id: todoDoc.assignee_id,
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      snackMsg: 'Ownership updated',
    };

    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  addHelper(item) {
    const { todoDoc, lookups } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    if (todoDoc.helper_ids.indexOf(item.email) > -1) {
      return; //already an helper
    }

    if (todoDoc.state !== 'NEW') {
      const applicableStateRef = applicableTodoCategoryRef.states
        .filter((a) => {
          if (a.cond === undefined) {
            return true;
          }
          return (
            a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1
          );
        })
        .filter((s) => s.nm === todoDoc.state)[0];
      if (applicableStateRef.final) return;
    }

    let pkg = {
      todoId: todoDoc._id,
      kind: 'add_helper',
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      helper_id: item.email,
      helper_nm: item.orig_nm,
    };

    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  removeHelper = (helper) => () => {
    //helper is nm|mail
    const { todoDoc } = this.props;
    let o = helper.split(/\|/);
    let pkg = {
      todoId: todoDoc._id,
      kind: 'remove_helper',
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      helper_id: o[1],
      helper_nm: o[0],
    };

    return this.props.updateTodo_ac(this.props.posInSet, pkg);
  };

  handleUpdateStatus() {
    if (null === this.state.selectedTodoState) return;

    const { todoDoc, lookups } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    const applicableStateRef = applicableTodoCategoryRef.states
      .filter((a) => {
        if (a.cond === undefined) {
          return true;
        }
        return a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1;
      })
      .filter((s) => s.nm === this.state.selectedTodoState)[0];

    let pkg = {
      todoId: todoDoc._id,
      kind: 'status_update',
      new_state: this.state.selectedTodoState,
      new_tags: [...this.state.checkedTags.values()],
      current_last_update: moment(todoDoc.last_update).toDate().getTime(),
      state_fields: this.state.stateFieldValues,
      stateRef: applicableStateRef,
      final: applicableStateRef.final,
      snackMsg: 'Status updated',
    };
    this.props.updateTodo_ac(this.props.posInSet, pkg);
  }

  render() {
    const { isCollapseOpen, openAssignmentUpdateModal, openStatusUpdateModal } =
      this.state;
    const { classes, todoDoc, lookups, me, todosInView } = this.props;
    const { TODO_STATE_REFERENCE: TODO_STATE_REF } = lookups.ink.k;
    const { activity } = todosInView;
    const isOwner = me.user.mail === todoDoc.assignee_id;

    const taskStatusChipClass =
      todoDoc.state === 'NEW'
        ? {
            variant: 'outlined',
            color: 'primary',
            classes: {
              outlinedPrimary: classes.newTaskPrimaryChip,
            },
          }
        : todoDoc.state.substr(0, 7).toUpperCase() === 'PENDING'
        ? {
            variant: 'outlined',
            color: 'secondary',
          }
        : {
            variant: 'default',
            color: 'primary',
            classes: {
              colorPrimary: classes.closedTaskPrimaryChip,
            },
          };

    const applicableTodoCategoryRef = TODO_STATE_REF.filter(
      (c) => c.code === todoDoc.code
    )[0];

    let allowStatusChange = false;
    let isFinal = false;
    let isPending = false;
    if (todoDoc.state === 'NEW') {
      allowStatusChange = true;
    } else {
      let currentStateRef = applicableTodoCategoryRef.states.filter(
        (s) => s.nm === todoDoc.state
      )[0];

      allowStatusChange = !currentStateRef.final;
      isFinal = currentStateRef.final;
      isPending = !currentStateRef.final;
    }

    let applicableTags = [];
    let readyForStatusUpdate = false;
    let applicableStateRef = false;

    /* eslint-disable no-unused-vars */
    let statusBtnLabel = 'STATUS not available for update';
    const userAllowedToUpdateToFinalStatus = true; //(_.intersection(['vct','vct_super'], me.user.roles).length > 0)
    /* eslint-enable no-unused-vars */

    if (this.state.selectedTodoState) {
      applicableStateRef = applicableTodoCategoryRef.states
        .filter((a) => {
          if (a.cond === undefined) {
            return true;
          }
          return (
            a.cond.value.indexOf(_.get(todoDoc, a.cond.source, false)) > -1
          );
        })
        .filter((s) => s.nm === this.state.selectedTodoState)[0];

      applicableTags = applicableStateRef.tags.map((t) =>
        typeof t === 'object'
          ? {
              ...t,
              checked: this.state.checkedTags.has(t.tag),
            }
          : {
              checked: this.state.checkedTags.has(t),
              tag: t,
              msg: '',
            }
      );

      readyForStatusUpdate =
        applicableTags.length === 0 || this.state.checkedTags.size > 0;
      statusBtnLabel = applicableStateRef.final
        ? 'Close this Task with Status: ' + this.state.selectedTodoState
        : 'Update Status to ' + this.state.selectedTodoState;

      if (
        this.state.selectedTodoState === todoDoc.state &&
        applicableTags.length === 0 &&
        todoDoc.major_state !== 'pending'
      ) {
        readyForStatusUpdate = false;
      }

      if (applicableStateRef.snooze) {
        statusBtnLabel += '-Snooze ' + applicableStateRef.snooze.hours + ' Hrs';
      }

      if (activity.inprog) {
        readyForStatusUpdate = false;
        statusBtnLabel = '...updating';
      }

      //if there is a required state field, check for it
      if (readyForStatusUpdate) {
        let missing_required_state_fields = _.get(
          applicableStateRef,
          'fields',
          []
        ).filter((f) => {
          return f.required && !this.state.stateFieldValues[f.nm];
        }).length;
        if (missing_required_state_fields) {
          readyForStatusUpdate = false;
        }
      }
    }

    /*
    const allowReAssignment = (
      !isFinal
      &&
      (
        me.user.roles.indexOf('vct_super')>-1
        ||
        todoDoc.code==='cfi'
      )
    )
    */
    let allowReAssignment = true;
    if (applicableTodoCategoryRef.allow_reassign_if) {
      if (
        todoDoc.assignee_id !==
        applicableTodoCategoryRef.allow_reassign_if.assignee_id
      ) {
        allowReAssignment = false;
      }
    }

    let snoozeLabel = '';
    if (todoDoc.snooze.hours) {
      let nowTs = new Date().getTime();
      let endMoment = moment(todoDoc.snooze.end);
      if (
        moment(todoDoc.snooze.start).toDate().getTime() < nowTs &&
        endMoment.toDate().getTime() > nowTs
      ) {
        snoozeLabel += 'SNOOZED UNTIL ' + endMoment.format('MM-DD-YY hh:mma');
      }
    }

    const reversedLogs = [...todoDoc.log]
      .reverse()
      .filter((r) => r.kind !== 'comment_only');
    const firstLogEntry = reversedLogs.pop();
    const reversedComments = [...todoDoc.comments].reverse();

    let modalHeaderComponent = (
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.modalHeader}>
        <Grid item>
          <Typography variant="h6" color="secondary">
            <strong>
              {openAssignmentUpdateModal &&
                !openStatusUpdateModal &&
                `Update the Assignments for Task #${todoDoc._id}`}
              {!openAssignmentUpdateModal &&
                openStatusUpdateModal &&
                `Update the Status of Task #${todoDoc._id}`}
            </strong>
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={this.handleModalClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    );

    return (
      <>
        {
          <Modal open={openStatusUpdateModal} onClose={this.handleModalClose}>
            <div className={classes.modalWrapper}>
              {modalHeaderComponent}
              <TodoCard
                expanded
                inModalView
                todoDoc={todoDoc}
                onLocalUpdate={this.onLocalUpdate}
                posInSet={this.props.posInSet}
              />
            </div>
          </Modal>
        }
        {
          <Modal
            open={openAssignmentUpdateModal}
            onClose={this.handleModalClose}>
            <div className={classes.modalWrapper}>
              {modalHeaderComponent}
              <div>
                {allowReAssignment && (
                  <div style={{ marginBottom: '24px' }}>
                    <Typography variant="subtitle1">
                      <strong>Change Primary Owner</strong>
                    </Typography>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="flex-start">
                      <Grid item xs={7}>
                        <UserSearch onSelect={this.changeAssignee} />
                      </Grid>
                      <Grid
                        container
                        item
                        xs={5}
                        direction="row"
                        justify="space-around"
                        alignItems="center">
                        <Grid item xs={3}>
                          <div style={{ textAlign: 'center' }}>OR</div>
                        </Grid>
                        <Grid item xs={9}>
                          <Button
                            size="medium"
                            color="primary"
                            onClick={this.takeOwnership}>
                            Take Ownership
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {/* BEGIN HELPERS PANEL */}
                <div style={{ marginBottom: '24px' }}>
                  <Typography variant="subtitle1">
                    <strong>Add Other Members</strong>
                  </Typography>
                  <UserSearch onSelect={this.addHelper} />
                </div>
              </div>
            </div>
          </Modal>
        }
        <TableRow style={{ verticalAlign: 'top' }}>
          <TableCell width="1%" style={{ padding: '2px' }}>
            <IconButton
              className={classNames(classes.expandClosedIcon, {
                [classes.expandOpenIcon]: isCollapseOpen,
              })}
              onClick={this.handelCollapseToggle}
              aria-expanded={isCollapseOpen}
              aria-label="Show Task Details">
              <ExpandMoreIcon />
            </IconButton>
          </TableCell>
          <TableCell width="26%" className={classes.borderlessCell}>
            <div>
              <Link
                to={
                  '/dfa/idn/' + todoDoc.idn.replace(/ /g, '_') + '/tabs/todos'
                }
                className={classes.linkTo}>
                {todoDoc.idn +
                  (todoDoc.account
                    ? ' A' + todoDoc.account + '-' + todoDoc.member
                    : '')}
              </Link>
            </div>
            <Typography>
              <strong>
                {this.getLabel(todoDoc.code)} {todoDoc._id}
              </strong>
            </Typography>
            <Typography>
              <em>{snoozeLabel}</em>
            </Typography>
            <Chip
              variant={taskStatusChipClass.variant}
              color={taskStatusChipClass.color}
              classes={taskStatusChipClass.classes || {}}
              className={classes.chip}
              label={<strong>STATUS:{todoDoc.state}</strong>}
            />
            {/* Extra column content */}
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <div className={classes.scrollableCellContent}>
                <LabelValueSet
                  label="Major State"
                  value={todoDoc.major_state.toUpperCase()}
                />
                {todoDoc.major_state !== 'closed' && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginBottom: '8px' }}
                    onClick={this.handleUpdateButtonClick(
                      'openStatusUpdateModal'
                    )}
                    disabled={!(allowStatusChange && isOwner)}>
                    {!(allowStatusChange && isOwner)
                      ? 'You must be the owner to update status'
                      : 'update status'}
                  </Button>
                )}
                {todoDoc.wo_id && (
                  <div>
                    <strong style={{ fontSize: 11 }}>
                      Work order attached: {todoDoc.wo_id}
                    </strong>
                  </div>
                )}
                {todoDoc.tags.length > 0 && (
                  <Typography component="div" gutterBottom>
                    <Typography color="primary">
                      <strong>Tags:</strong>
                    </Typography>
                    <Grid container spacing={8} style={{ width: '100%' }}>
                      {todoDoc.tags.map((nm) => (
                        <Grid key={nm} item>
                          <Chip label={nm} className={classes.tagChip} />
                        </Grid>
                      ))}
                    </Grid>
                  </Typography>
                )}
                <div className={classes.listItemParaPre}>
                  Task Description:{' '}
                  {firstLogEntry.txt
                    .trim()
                    .split(/\n/)
                    .map((t, ti) => (
                      <span key={ti}>
                        {t}
                        <br />
                      </span>
                    ))}
                </div>
                {applicableTodoCategoryRef.extra_fields && (
                  <div className={classes.efPara}>
                    {applicableTodoCategoryRef.extra_fields.map((ef) => (
                      <div key={ef.nm}>
                        <Typography color="primary" inline>
                          {ef.lbl}:
                        </Typography>
                        &ensp;
                        <strong>
                          {_.get(todoDoc, 'ef', { [ef.nm]: '' })[ef.nm]}
                        </strong>
                      </div>
                    ))}
                  </div>
                )}
                {_.get(todoDoc, 'ef.claim_line_ids', []).length ? (
                  <div className={classes.efPara}>
                    <Typography color="primary" inline>
                      <strong>Claim Lines:</strong>
                    </Typography>
                    &ensp;
                    {todoDoc.ef.claim_line_ids.map((cli) => (
                      <div key={cli}>
                        <strong>{cli}</strong>
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}
                {todoDoc.sf && !_.isEmpty(todoDoc.sf) && (
                  <div className={classes.efPara}>
                    {applicableTodoCategoryRef.states
                      .filter((a) => {
                        if (a.cond === undefined) {
                          return true;
                        }
                        return (
                          a.cond.value.indexOf(
                            _.get(todoDoc, a.cond.source, false)
                          ) > -1
                        );
                      })
                      .filter((a) => {
                        return a.nm === todoDoc.state;
                      })[0]
                      .fields.map((sf) => (
                        <div key={sf.nm}>
                          <span>{sf.lbl}</span>:
                          <strong>
                            {_.get(todoDoc, 'sf', { [sf.nm]: '' })[sf.nm]}
                          </strong>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </Collapse>
          </TableCell>
          <TableCell width="18%" className={classes.borderlessCell}>
            <Chip
              color="primary"
              label={todoDoc.assignee_nm}
              className={classes.chip}
            />
            {todoDoc.major_state !== 'closed' && (
              <div
                style={{ cursor: 'pointer', marginTop: '8px' }}
                onClick={this.handleUpdateButtonClick(
                  'openAssignmentUpdateModal'
                )}>
                <span>[Change Ownership]</span>
              </div>
            )}
            {/* Extra column content */}
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <LabelValueSet label="Email" value={todoDoc.assignee_id} />
              {(todoDoc.helpers.length > 0 ||
                this.state.tmp_helpers.length > 0) && (
                <Typography component="div" gutterBottom>
                  <Typography color="primary">
                    <strong>Helpers:</strong>
                  </Typography>
                  <Grid container spacing={8} style={{ width: '100%' }}>
                    {todoDoc.helpers
                      .concat(this.state.tmp_helpers)
                      .map((helper, hIdx) => (
                        <Grid key={hIdx} item>
                          <Chip
                            label={helper.split(/\|/)[0]}
                            onDelete={this.removeHelper(helper)}
                            className={classes.chip}
                            color="secondary"
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Typography>
              )}
            </Collapse>
          </TableCell>
          <TableCell width="21%" className={classes.borderlessCell}>
            {reversedLogs.length > 0 && (
              <Typography paragraph>
                <i className={classes.noteUser}>
                  <Moment format="M-DD-YY h:mma">{reversedLogs[0].ts}</Moment>{' '}
                  {reversedLogs[0].u_nm}
                </i>{' '}
                {reversedLogs[0].txt.replace(/\n/g, '-')}
              </Typography>
            )}
            {/* Extra column content */}
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <div className={classes.scrollableCellContent}>
                {reversedLogs.slice(1).map((log, logIdx) => (
                  <Typography key={logIdx} paragraph>
                    <i className={classes.noteUser}>
                      <Moment format="M-DD-YY h:mma">{log.ts}</Moment>{' '}
                      {log.u_nm}{' '}
                    </i>
                    {log.txt.replace(/\n/g, '-')} -
                  </Typography>
                ))}
              </div>
            </Collapse>
          </TableCell>
          <TableCell width="21%" className={classes.borderlessCell}>
            {reversedComments.slice(0, 1).map((comment, commIdx) => {
              let textParts = comment.txt.split(/\n/g);
              textParts = textParts.map((p, pi) => {
                if (pi === textParts.length - 1) return p;
                return (
                  <span key={pi}>
                    {p}
                    <br />
                  </span>
                );
              });
              return (
                <Typography
                  key={commIdx}
                  paragraph
                  className={classes.noteTxt}
                  color="textSecondary">
                  <i className={classes.noteUser}>
                    <Moment format="M-DD-YY h:mma">{comment.ts}</Moment> -{' '}
                    {comment.u_nm}{' '}
                  </i>{' '}
                  {textParts}
                </Typography>
              );
            })}
            {/* Extra column content */}
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <div className={classes.scrollableCellContent}>
                {reversedComments.length > 1 &&
                  reversedComments.slice(1).map((comment, commIdx) => {
                    let textParts = comment.txt.split(/\n/g);
                    textParts = textParts.map((p, pi) => {
                      if (pi === textParts.length - 1) return p;
                      return (
                        <span key={pi}>
                          {p}
                          <br />
                        </span>
                      );
                    });
                    return (
                      <Typography
                        key={commIdx}
                        paragraph
                        className={classes.noteTxt}
                        color="textSecondary">
                        <i className={classes.noteUser}>
                          <Moment format="M-DD-YY h:mma">{comment.ts}</Moment> -{' '}
                          {comment.u_nm}{' '}
                        </i>
                        {textParts}
                      </Typography>
                    );
                  })}
                <TextField
                  multiline
                  value={this.state.comment}
                  onChange={this.handleCommentInput}
                  label="Add Comment"
                  style={{ width: '100%' }}
                  helperText="Press 'Enter' for multiline"
                />
                <div className={classes.btnContainer}>
                  <Button
                    size="small"
                    onClick={this.handleCommentSubmit}
                    variant="contained"
                    color="primary">
                    Add Comment
                  </Button>
                </div>
              </div>
            </Collapse>
          </TableCell>
          <TableCell width="13%" className={classes.borderlessCell}>
            {isFinal ? (
              <div>
                <i className={classes.noteUser}>
                  <Moment format="M-DD-YY h:mma">{todoDoc.closed_dt}</Moment> -{' '}
                  {todoDoc.closed_nm}
                </i>{' '}
                Last Pended
              </div>
            ) : isPending ? (
              <div>
                <i className={classes.noteUser}>
                  <Moment format="M-DD-YY h:mma">
                    {todoDoc.last_pending_dt}
                  </Moment>{' '}
                  - {todoDoc.last_pending_nm}
                </i>{' '}
                Last Pended
              </div>
            ) : (
              <div>
                <i className={classes.noteUser}>
                  <Moment format="M-DD-YY h:mma">{todoDoc.created_dt}</Moment> -{' '}
                  {todoDoc.creator_nm}
                </i>{' '}
                Created Task
              </div>
            )}
            {/* Extra column content */}
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              <div className={classes.scrollableCellContent}>
                {!isFinal && isPending && (
                  <div className={classes.activity}>
                    <i className={classes.noteUser}>
                      <Moment format="M-DD-YY h:mma">
                        {todoDoc.last_pending_dt}
                      </Moment>{' '}
                      - {todoDoc.last_pending_nm}
                    </i>{' '}
                    Last Pended
                  </div>
                )}
                {(isFinal || isPending) && (
                  <div>
                    <i className={classes.noteUser}>
                      <Moment format="M-DD-YY h:mma">
                        {todoDoc.created_dt}
                      </Moment>{' '}
                      - {todoDoc.creator_nm}
                    </i>{' '}
                    Created Task
                  </div>
                )}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

ExpandableTodoRow.propTypes = {
  classes: PropTypes.object.isRequired,
  todoDoc: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  idnInView: stateFromStore.idnInView,
  todosInView: stateFromStore.todosInView,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateTodo_ac }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExpandableTodoRow))
);
