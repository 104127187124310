import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { Grid, Typography } from '@material-ui/core'
import { orange, purple } from '@material-ui/core/colors'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'

import DCCard from './DCCard'
import DCMaintenance from './DCMaintenance.js'
import { fetchDCs_ac } from '../../actions/dcs.ac.js'
import FetchingStatementWrap from '../FetchingStatementWrap'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  hLink: {
    cursor: 'pointer',
    color: 'green'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  dash: {
    marginRight: 4,
    color: 'grey'
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

const collapseDCs = (dcs = [], collapsed = true) => {
  if (!dcs) return {}

  const obj = {}
  dcs.forEach(dc => {
    obj[dc.dc_id] = collapsed
  })
  return obj
}

const DCsLanding = props => {
  const checkSituation = () => {
    const { dcs } = props
    if (dcs.isFetching) return
    if (dcs.error) return
    if (dcs.pkg === null) {
      props.fetchDCs_ac()
    }
  }
  React.useEffect(() => {
    checkSituation()

  // eslint-disable-next-line
  }, [])

  const [collapseMap, setCollapseMap] = React.useState(
    collapseDCs(props.dcs?.pkg?.dcs, false)
  )

  const [selectedDC, setSelectedDC] = React.useState(null)

  const { classes, dcs } = props
  let title = 'DCs Management'

  const handleCardChange = (dc_id, value) => {
    setCollapseMap({
      ...collapseMap,
      [dc_id]: value
    })
  }

  const selectedIdx = React.useMemo(() => {
    if (!selectedDC) return null
    return dcs?.pkg?.dcs?.findIndex(dc => dc.dc_id === selectedDC.dc_id)
    // eslint-disable-next-line
  }, [selectedDC])

  const handleCollapseAll = () => {
    setCollapseMap(collapseDCs(dcs?.pkg?.dcs, false))
  }

  const handleExpandAll = () => {
    setCollapseMap(collapseDCs(dcs?.pkg?.dcs, true))
  }

  const handleCardEdit = dc => {
    setSelectedDC(selectedDC?.dc_id === dc.dc_id ? null : dc)
  }

  const { pkg } = dcs
  let marginLessContent = [
    classes.content,
    classes.pagination,
    classes.fullHeight
  ].join(' ')

  if (dcs.error) {
    return (
      <FetchingStatementWrap
        title={title}
        msg={'Something went wrong. Contact IT'}
      />
    )
  }

  if (!dcs.pkg || dcs.isFetching) {
    return (
      <FetchingStatementWrap
        title={title}
        msg={'This should not take more than 10 seconds'}
      />
    )
  }

  return (
    <main className={classes.content}>
      {/* <div className={classes.appBarSpacer} /> */}
      <Grid container spacing={8} className={classes.fullHeight}>
        {/* BEGIN STATS */}
        <Grid item xs={4} sm={4} className={classes.fullHeight}>
          <main className={marginLessContent}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label className={classes.label}>All DCs:</label>
              <div>
                <label
                  className={`${classes.label} ${classes.hLink}`}
                  onClick={handleCollapseAll}>
                  Collapse All
                </label>
                <span className={classes.dash}>{' | '}</span>
                <label
                  className={`${classes.label} ${classes.hLink}`}
                  onClick={handleExpandAll}>
                  Expand All
                </label>
              </div>
            </div>
            <div className={classes.scrollArea}>
              {pkg.dcs.map((dc, idx) => (
                <DCCard
                  dc={dc}
                  key={idx}
                  idx={idx}
                  expanded={collapseMap[dc.dc_id]}
                  disabled={dcs.isUpdating}
                  isSelected={selectedDC?.dc_id === dc.dc_id}
                  onChange={handleCardChange}
                  onEditClick={handleCardEdit}
                />
              ))}
            </div>
          </main>
        </Grid>

        <Grid item xs={4} sm={4} className={classes.fullHeight}>
          <main className={marginLessContent}>
            <label className={classes.label}>DC Maintenance:</label>
            <div className={classes.scrollArea}>
              {!selectedDC ? (
                <Typography className={classes.labelTypography}>
                  {'Click on Edit button to re-assign users on a DC'}
                </Typography>
              ) : (
                <DCMaintenance dc={selectedDC} idx={selectedIdx} />
              )}
            </div>
          </main>
        </Grid>
      </Grid>
    </main>
  )
}

DCsLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  dcs: stateFromStore.dcs
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDCs_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles(theme))(DCsLanding))
)
