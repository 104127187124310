import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import * as pdfGen from '../../actions/pdfGen.ac';
import * as pdfDetails from '../../actions/Pdf_details.ac';
import imgTools from '../../util/imageProcessing';

import PreviewPdfContent from '../common/PreviewPdfContent.js';
import Loading from '../HourlyWos/components/Loading';

const PdfViewer = props => {
  const {
    history,
    pdfGenPreview,
    pdfGen,
    pdfDetails,
    getPdfDetailById
  } = props;

  const [previewTabs, setPreviewTabs] = useState();
  const [isPdfLoading, setIsPdfLoading] = useState(true);

  const packetId = history.location.pathname.split('/').pop();

  const packetInfo = pdfDetails.data.docs;

  const updatePacketStatus = (newStatus, pdfDataUri) => {
    const _previewTabs = {
      status: newStatus,
      pdfUri: pdfDataUri
    };

    setPreviewTabs(_previewTabs);
  };

  const createPdfFromExistingDocflowDocs = async payloads => {
    try {
      const pdfUri = await imgTools.generatePdfFromExistingDocs(payloads);
      updatePacketStatus('RENDERED', pdfUri);
    } catch (error) {
      console.error('error:', error);
    } finally {
      setIsPdfLoading(false);
    }
  };

  useEffect(() => {
    getPdfDetailById(packetId);
  }, [packetId, getPdfDetailById]);

  useEffect(() => {
    if (packetInfo) {
      pdfGenPreview(packetInfo);
    }
  }, [packetInfo, pdfGenPreview]);

  useEffect(() => {
    if (pdfGen.preview.result) {
      createPdfFromExistingDocflowDocs(pdfGen.preview.result.payloads);
    }

    // eslint-disable-next-line
  }, [pdfGen.preview.result]);

  return isPdfLoading ? (
    <Loading />
  ) : (
    <PreviewPdfContent
      status={previewTabs?.status}
      pdfUri={previewTabs?.pdfUri}
    />
  );
};

const mapStateToProps = stateFromStore => ({
  pdfGen: stateFromStore.pdfGen,
  pdfDetails: stateFromStore.pdfDetails
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pdfGenPreview: pdfGen.preview,
      getPdfDetailById: pdfDetails.getPdfFiles
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PdfViewer)
);
