import { Grid, Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import forge from 'node-forge';
import PropTypes from 'prop-types';
import React from 'react';
import ContainerDimensions from 'react-container-dimensions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  docSelected_ac,
  loadingPages_ac,
  pagesLoaded_ac,
} from '../../actions/account_in_view.ac.js';
import { insertIntoDfaList_ac } from '../../actions/dfa.ac.js';
import {
  close_confirmation_dialog_ac,
  open_confirmation_dialog_ac,
} from '../../actions/dialog.ac.js';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';
import { locallyUpdateTodoDoc_ac } from '../../actions/todos.ac.js';
import { intel_worker_init } from '../../actions/web_workers.ac.js';
import imgTools from '../../util/imageProcessing';
import { ReferralXML } from '../AllScriptsReport/ReferralXML.js';
import DfaCardNoTackle from '../Dfa/DfaCardNoTackle';
import IdnWoEntry from '../Dfa/IdnWoEntry.js';
import FetchingStatement from '../FetchingStatement';
import TodoCard from '../Todos/TodoCard';
import ConfirmationLogLanding from './../ConfirmationLog/ConfirmationLogLanding';
import { AnalysisLanding } from './Analysis/AnalysisLanding';
import { DocMenuItems } from './DocMenuItems.js';
import ImgHolder from './ImgHolder';

export const checkIfStringHasIntegersFollowedByW = (str) => {
  const firstLetter = str.substring(0, 1);
  const remainingLetter = str.substring(1);
  return firstLetter === 'W' && !isNaN(remainingLetter);
};

const ALLOW_ANALYSIS = true;
const AUTO_ANALYSIS = false;

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    flexGrow: 1,
    padding: '0px 4px',
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  kevin: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    //backgroundColor: theme.palette.background.paper,
    overflowY: 'auto',
  },
  ul: {
    padding: 2,
  },
  byTabKlass: {
    minWidth: 12,
    minHeight: 24,
  },
  tabLabelContainer: { padding: '3px 16px 3px 0' },
  li: {
    margin: 12,
    fontSize: 12,
    float: 'left',
  },
  tabDiv: {
    padding: 0,
  },
  docTitle: {
    fontSize: 12,
    fontWeight: 700,
  },
  loadingSign: {
    padding: 10,
    fontSize: 10,
    backgroundColor: '#fafefff0',
  },
});

class RelatedWorkUnits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded_df_entries: [],
      isFetching: false,
      fetchErr: null,
    };
    this.onWDocUpdate = this.onWDocUpdate.bind(this);
  }

  componentDidMount() {
    this.checkSituation();
  }

  componentDidUpdate() {
    this.checkSituation();
  }

  checkSituation() {
    if (this.state.isFetching || this.state.fetchErr) return;
    const { df_entries } = this.props.wDocInView.oou;

    if (
      _.difference(
        df_entries.map((d) => d._id),
        this.state.loaded_df_entries.map((d) => d._id)
      ).length === 0
    ) {
      return;
    }
    if (df_entries.length === 0) {
      return;
    }
    return this.fetchOOU(df_entries.map((d) => d._id));
  }

  fetchOOU(ids) {
    this.setState({ isFetching: true });
    let params = {
      bucketName: 'openqa',
      recordsPerPage: ids.length,
      sort: 'nd',
      srch: 'x:' + ids.join(','),
    };
    return window.sch
      .post(`/api/workitems:list`, params)
      .then((results) => {
        this.setState({
          loaded_df_entries: results.rows,
          isFetching: false,
          fetchErr:
            results.rows.length === 0
              ? new Error('Failed fetching rows by id')
              : null,
        });
        return;
      })
      .catch((err) => {
        this.setState({ isFetching: false, fetchErr: err });
      });
  }

  onWDocUpdate(wDocID_to_insert) {
    return this.fetchOOU(this.props.wDocInView.oou.df_entries.map((d) => d._id))
      .then(() => {
        //do nothing else if the wDoc to insert is ALREADY on the list
        if (
          this.props.dfaInView.results.rows.filter((r) => {
            return r._id === wDocID_to_insert;
          }).length
        ) {
          console.log('already on list');
          return;
        }

        //loaded_df_entries should now have updated wDoc, insert that into current dfa list, before the currently open one
        let targetPos = this.props.wDocInView.idxInPage;
        return this.props.insertIntoDfaList_ac(
          targetPos,
          this.state.loaded_df_entries.filter(
            (d) => d._id === wDocID_to_insert
          )[0]
        );
      })
      .catch((err) => {
        console.error(err);
        return;
      });
  }

  render() {
    const { classes } = this.props;
    if (this.state.isFetching) {
      return (
        <div className={classes.loadingSign}>
          ...fetching other open workunits loading
        </div>
      );
    }

    if (this.state.loaded_df_entries.length === 0) {
      return (
        <div className={classes.loadingSign}>
          No matching 'other open workunits' found
        </div>
      );
    }
    if (this.state.fetchErr) {
      return (
        <div className={classes.loadingSign}>
          Something went wrong fetching the work units. Check the patients work
          unit listing on the patient landing page.
        </div>
      );
    }

    return (
      <>
        {this.state.loaded_df_entries.map((wDoc, wi) => (
          <DfaCardNoTackle
            key={wi}
            wDoc={wDoc}
            onWDocUpdate={this.onWDocUpdate}
            expanded
            toTeam={this.props.toTeam}
            toUser={this.props.toUser}
          />
        ))}
      </>
    );
  }
}

class RelatedWorkOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded_wos_entries: [],
      isFetching: false,
      fetchErr: null,
    };
  }

  componentDidMount() {
    this.checkSituation();
  }

  componentDidUpdate() {
    this.checkSituation();
  }

  checkSituation() {
    if (this.state.isFetching || this.state.fetchErr) return;
    const { wos_entries } = this.props.wDocInView.oou;

    if (
      _.difference(
        wos_entries.map((d) => d.hdms_wId),
        this.state.loaded_wos_entries.map((d) => d.hdms_wId)
      ).length === 0
    ) {
      return;
    }
    if (wos_entries.length === 0) {
      return;
    }
    return this.fetchWOS(wos_entries);
  }

  fetchWOS(wos_entries) {
    this.setState({ isFetching: true });
    let params = {
      wo_ids: wos_entries.map((w) => w.hdms_wId),
      idn: this.props.wDocInView.nm,
      hdmsAccts: _.uniq(
        wos_entries.map((w) => ['A', w.account, '-', w.member].join(''))
      ),
    };

    return window.sch
      .post(`/api/hdms:recentwos:prime`, params)
      .then((results) => {
        this.setState({
          loaded_wos_entries: results.wos,
          isFetching: false,
          fetchErr:
            results.wos.length === 0 ? new Error('Failed related wos') : null,
        });
        return;
      })
      .catch((err) => {
        this.setState({ isFetching: false, fetchErr: err });
      });
  }

  render() {
    const { classes } = this.props;
    if (this.state.isFetching) {
      return (
        <div className={classes.loadingSign}>
          ...fetching open HDMS workorders.
        </div>
      );
    }

    if (this.state.loaded_wos_entries.length === 0) {
      return (
        <div className={classes.loadingSign}>
          No matching open HDMS work orders found.
        </div>
      );
    }
    if (this.state.fetchErr) {
      return (
        <div className={classes.loadingSign}>
          Something went wrong fetching the work orders. Check the patients work
          orders listing on the patient landing page.
        </div>
      );
    }

    return (
      <>
        {this.state.loaded_wos_entries.map((wo, idx) => (
          <IdnWoEntry
            key={wo.hdms_wId + '-' + idx}
            posInSet={idx}
            keepExpanded
            hideMednecLog
            fromWorkOrder
            wo={wo}
            onCreateLineFixTask={() => {
              return;
            }}
          />
        ))}
      </>
    );
  }
}

class DocViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docJobKey: null,
      allPagesReady: false,
      pagesReady: 0,
      pages: [],
      comprehend_source_pages: [],
      comprehend_pages: {}, //keyed by page pos stringified
      selectedTab: this.props.docViewerTab || 'doc_viewer',
      isRenderingPdf: false,
      lastPdfDocKey: null,
      open: false,
      anchorEl: null,
    };

    /*
    this.state.worker.onmessage = e => {
      console.log('message from worker')
      console.log(e.data)
    }
    */

    this.current = {
      iv: null,
      pw: null,
      pagesToLoad: [],
      pagesLoaded: [],
    };
    this.onTabSelected = this.onTabSelected.bind(this);
    this.buildPfRenderingPayloadsFromDocJob =
      this.buildPfRenderingPayloadsFromDocJob.bind(this);
    this.comprehendCallback = this.comprehendCallback.bind(this);
    this.onHasSaveableTableData = this.onHasSaveableTableData.bind(this);
    this.onInitComprehendThisPageOnly =
      this.onInitComprehendThisPageOnly.bind(this);
    this.onLocalUpdate = this.onLocalUpdate.bind(this);
  }
  onLocalUpdate(posInSet, todoDoc) {
    this.props.locallyUpdateTodoDoc_ac(posInSet, todoDoc);
  }

  onTabSelected(e, _tab) {
    if (_tab === 'docs') return;
    if (typeof this.props.onSwitchTabWithProp === 'function') {
      this.props.onSwitchTabWithProp(_tab);
      return;
    }

    this.setState({ selectedTab: _tab });
    if (_tab === 'pdf_viewer') return this.buildPfRenderingPayloadsFromDocJob();
  }

  buildPfRenderingPayloadsFromDocJob() {
    const { docJob } = this.props.docInView;
    if (!docJob) return;

    if (this.state.lastPdfDocKey === this.props.docInView.docJobKey) {
      return;
    }

    if (this.state.isRenderingPdf) return;

    this.setState({
      pdfUri: null,
      isRenderingPdf: true,
    });

    return imgTools
      .generatePdfFromExistingDocs([
        {
          keys: docJob.keys,
          pageCount: docJob.pageCount,
          rotation: docJob.pages.map((pg) => {
            return pg['rotation-pref'] * 1;
          }),
          pages: docJob.pages.map((pg) => {
            return pg.idxInFax * 1;
          }),
          path: [
            docJob.s3bucketPath,
            'faxes',
            docJob._id + '-' + docJob.pageCount + 'pgs.tif',
          ].join('/'),
        },
      ])
      .then((pdfUri) => {
        this.setState({
          pdfUri,
          isRenderingPdf: false,
          lastPdfDocKey: this.props.docInView.docJobKey,
        });
        return;
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          pdfUri: null,
          isRenderingPdf: false,
          lastPdfDocKey: null,
        });
      });
  }

  decrypt(pw, iv, encryptedBase64) {
    var encodedAsBytes = forge.util.decode64(encryptedBase64);

    /* parameter to decipher.update must be in forge buffer format */
    var encResultForgeBuf = forge.util.createBuffer(encodedAsBytes);
    var key = forge.pkcs5.pbkdf2(this.current.pw, '', 1, 16);
    var decipher = forge.cipher.createDecipher('AES-CBC', key);

    decipher.start({ iv: this.current.iv });
    decipher.update(encResultForgeBuf);
    decipher.finish();
    return forge.util.encode64(decipher.output.bytes());
  }

  prep() {
    const { docJob } = this.props.docInView;
    if (!docJob) return;

    let pngbaseid = docJob.clonedFromFaxId
      ? docJob.clonedFromFaxId.toString()
      : docJob._id.toString();

    let baseUrl = [
      'https://s3-us-west-2.amazonaws.com/com.supercaremed.privatestatic',
      docJob.s3bucketPath,
      'pngs',
      pngbaseid + '-',
      //this.job._id.toString()+ "-"
    ].join('/');

    let iv = forge.util.decode64(docJob.keys[1]);
    let pw = docJob.keys[0];
    let pagesToLoad = [].concat(docJob.pages);
    pagesToLoad.reverse();
    this.current = {
      iv,
      pw,
      totalPageCount: docJob.pages.length,
      pagesToLoad,
      baseUrl,
      pagesLoaded: [],
    };
    this.fetchPage();
  }

  comprehendCallback(data) {
    console.log(
      'Got comprehend page callback for page: ',
      data.page_pos + ' ' + data.kind
    );
    switch (data.kind) {
      case 'top_of_get_comprehend_json':
      case 'started_comprehend_job':
        this.setState({
          comprehend_pages: {
            ...this.state.comprehend_pages,
            [data.page_pos.toString()]: { state: 'started' },
          },
        });
        break;
      case 'attempting_get_download_url':
        console.log('attempting_get_download_url - page ', data.page_pos);
        break;
      case 'unrecoverable':
        this.setState({
          comprehend_pages: {
            ...this.state.comprehend_pages,
            [data.page_pos.toString()]: {
              state: 'unrecoverable',
              from: data.from,
            },
          },
        });
        break;
      case 'download_attempts_exhausted':
        this.setState({
          comprehend_pages: {
            ...this.state.comprehend_pages,
            [data.page_pos.toString()]: { state: 'exhausted' },
          },
        });
        break;
      case 'got_comprehend_json':
        this.setState({
          comprehend_pages: {
            ...this.state.comprehend_pages,
            [data.page_pos.toString()]: {
              state: 'ready',
              json: data.json,
            },
          },
        });
        break;
      default:
        break;
    }
  }

  //page specific initializing
  onInitComprehendThisPageOnly(pageIdx) {
    if (
      _.intersection(this.props.me.user.roles, ['intel-tagger', 'intel-viewer'])
        .length > 0
    ) {
      let compPayload = {
        ...this.state.comprehend_source_pages[pageIdx],
      };
      this.props.intel_worker_init('getComprehendJSON', compPayload, (data) => {
        return this.comprehendCallback(data);
      });
    }
  }

  fetchPage(alt_baseUrl) {
    if (this.current.pagesToLoad.length === 0) {
      return this.allPagesReady();
    }

    let page = this.current.pagesToLoad.pop();
    let page_url = this.current.baseUrl + page.k;

    return fetch(page_url, {
      mode: 'cors',
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.text();
      })
      .then((encryptedBase64) => {
        let decrypted_png = this.decrypt(
          this.current.pw,
          this.current.iv,
          encryptedBase64
        );
        page.imgSrc = 'data:image/png;base64,' + decrypted_png;
        let page_pos = this.current.pagesLoaded.length * 1;
        this.setState({
          pagesReady: this.current.pagesLoaded.push(page),
          docJobKey: this.props.docInView.docJobKey.toString(),
          comprehend_source_pages: [
            ...this.state.comprehend_source_pages,
            {
              page_url,
              base64: page.imgSrc,
              page_k: page.k,
              page_pos,
              tries: 0,
            },
          ],
        });

        if (
          ALLOW_ANALYSIS &&
          AUTO_ANALYSIS &&
          _.intersection(this.props.me.user.roles, [
            'intel-tagger',
            'intel-viewer',
          ]).length > 0
        ) {
          this.props.intel_worker_init(
            'getComprehendJSON',
            {
              page_url,
              base64: page.imgSrc,
              page_k: page.k,
              page_pos,
              tries: 0,
            },
            (data) => {
              return this.comprehendCallback(data);
            }
          );
        }
        return this.fetchPage();
      })
      .catch((err) => {
        if (err.message === 'Forbidden') {
          if (this.current.baseUrl.search(/direct-from-user/) > -1) {
            let p = this.current.baseUrl.split(/\//g);
            let [y, m, d] = moment([p[5], p[6], p[7]].join(''), 'YYYYMMDD')
              .add(-1, 'day')
              .format('YYYY-MM-DD')
              .split(/-/g);
            p[5] = y;
            p[6] = m;
            p[7] = d;
            let alt_page_url = p.join('/') + page.k;
            return this.altFetch(alt_page_url, page);
          }
        }
      });
  }

  altFetch(alt_page_url, page) {
    return fetch(alt_page_url, {
      mode: 'cors',
    })
      .then((res) => {
        if (!res.ok) throw new Error(res.statusText);
        return res.text();
      })
      .then((encryptedBase64) => {
        let page_pos = this.current.pagesLoaded.length * 1;
        page.imgSrc =
          'data:image/png;base64,' +
          this.decrypt(this.current.pw, this.current.iv, encryptedBase64);
        this.setState({
          pagesReady: this.current.pagesLoaded.push(page),
          docJobKey: this.props.docInView.docJobKey.toString(),
          comprehend_source_pages: [
            ...this.state.comprehend_source_pages,
            {
              alt_page_url,
              base64: page.imgSrc,
              page_k: page.k,
              page_pos,
              tries: 0,
            },
          ],
        });
        if (
          ALLOW_ANALYSIS &&
          AUTO_ANALYSIS &&
          _.intersection(this.props.me.user.roles, [
            'intel-tagger',
            'intel-viewer',
          ]).length > 0
        ) {
          this.props.intel_worker_init(
            'getComprehendJSON',
            {
              alt_page_url,
              base64: page.imgSrc,
              page_k: page.k,
              page_pos,
              tries: 0,
            },
            (data) => {
              return this.comprehendCallback(data);
            }
          );
        }
        return this.fetchPage();
      })
      .catch((err) => {
        page.imgSrc = 'data:image/png;base64,stub';
        this.setState({
          pagesReady: this.current.pagesLoaded.push(page),
          docJobKey: this.props.docInView.docJobKey.toString(),
        });
        return this.fetchPage();
      });
  }

  allPagesReady() {
    this.setState({
      allPagesReady: true,
    });
  }

  _buildPageJobFromDoc(doc) {
    const { faxJobs } = this.props.docsArray;
    let tmpJob = _.cloneDeep(faxJobs[doc.faxJobIdx]);
    tmpJob.wId_docIdx = doc.wId + '-' + doc.docIdx;
    tmpJob.displayAs = doc.displayAs;
    tmpJob.pages = doc.pages.map((page, rotIdx) => {
      page['rotation-pref'] = doc.rotation[rotIdx] * 1;
      return page;
    });
    return tmpJob;
  }

  docSelected(posOfDocSelected) {
    this.setState({
      selectedTab: 'doc_viewer',
      comprehend_source_pages: [],
      comprehend_pages: {}, //keyed by page pos stringified
    });

    if (typeof this.props.onSwitchTabWithProp === 'function') {
      this.props.onSwitchTabWithProp('doc_viewer');
      this.setState({
        comprehend_source_pages: [],
        comprehend_pages: {}, //keyed by page pos stringified
      });
    } else {
      this.setState({
        selectedTab: 'doc_viewer',
        comprehend_source_pages: [],
        comprehend_pages: {}, //keyed by page pos stringified
      });
    }

    let tmpJob = this._buildPageJobFromDoc(
      this.props.docsArray.docs[posOfDocSelected]
    );
    //this.props.changePage();
    this.props.docSelected_ac(posOfDocSelected, tmpJob, tmpJob.wId_docIdx);
  }

  docSelectedByDocKey(keyOfDocSelected) {
    this.setState({
      selectedTab: 'doc_viewer',
      comprehend_source_pages: [],
      comprehend_pages: {}, //keyed by page pos stringified
    });
    //find the posOfDocSelected based on keyOfDocSelected
    let _posOfDocSelected = this.props.docsArray.docs.findIndex((d) => {
      return d.wId + '-' + d.docIdx === keyOfDocSelected;
    });

    if (_posOfDocSelected === -1) _posOfDocSelected = 0;

    let tmpJob = this._buildPageJobFromDoc(
      this.props.docsArray.docs[_posOfDocSelected]
    );
    //this.props.changePage();
    this.props.docSelected_ac(_posOfDocSelected, tmpJob, tmpJob.wId_docIdx);
  }

  componentDidMount() {
    this.checkSituation(false);
  }

  componentDidUpdate(prevProps) {
    this.checkSituation(prevProps);
  }

  checkSituation(prevProps) {
    if (this.props.docSelectionBy === 'posOfDocSelected') {
      return this.checkSituationByPosInDocSet(prevProps);
    } else {
      return this.checkSituationByDocKey(prevProps);
    }
  }

  checkSituationByDocKey(prevProps) {
    if (this.props.docsArray === null) {
      return;
    }

    if (this.props.docsArray.hdr === false) {
      return;
    }

    if (this.props.accountInView.docKeySelected === null) {
      return;
    }

    const docKeySelected = this.props.accountInView.docKeySelected;

    if (
      this.props.docInView.docJobKey === null ||
      docKeySelected !== this.props.docInView.docJobKey
    ) {
      this.docSelectedByDocKey(docKeySelected);
      return;
    }

    //if ( this.props.docInView.docJobKey !== this.state.docJobKey ){
    if (
      !prevProps ||
      this.props.docInView.docJobKey !== prevProps.docInView.docJobKey
    ) {
      this.prep();
      return;
    }

    if (
      prevProps.accountInView.accountIdn !== this.props.accountInView.accountIdn
    ) {
      this.docSelectedByDocKey(docKeySelected);
    }
    if (
      typeof this.props.onSwitchTabWithProp === 'function' &&
      (prevProps.docViewerTab !== this.props.docViewerTab ||
        this.props.docViewerTab !== this.state.selectedTab)
    ) {
      this.setState({ selectedTab: this.props.docViewerTab });
      if (this.props.docViewerTab === 'pdf_viewer')
        return this.buildPfRenderingPayloadsFromDocJob();
    }
  }

  checkIfSnackShouldFire(prevProps) {
    const { wDoc } = this.props;
    const { wDoc: prev_wDoc } = prevProps;
    if (undefined === wDoc) {
      return;
    }

    if (
      wDoc.oou &&
      wDoc.oou.df_entries.length === 0 &&
      wDoc.oou.wos_entries.length === 0
    ) {
      return;
    }

    if (prev_wDoc && prev_wDoc.oou && prev_wDoc.oou.df_entries.length) {
      if (
        _.difference(
          prev_wDoc.oou.df_entries.map((e) => e._id),
          wDoc.oou.df_entries.map((e) => e._id)
        ).length === 0
      ) {
        //it's the same open workunits we already alered in previous snack, don't fire snack
        return;
      }
    }

    if (prev_wDoc && prev_wDoc.oou && prev_wDoc.oou.wos_entries.length) {
      if (
        _.difference(
          prev_wDoc.oou.wos_entries.map((e) => e.hdms_wId),
          wDoc.oou.wos_entries.map((e) => e.hdms_wId)
        ).length === 0
      ) {
        //it's the same open workunits we already alered in previous snack, don't fire snack
        return;
      }
    }

    let content = '';
    if (_.get(wDoc, ['oou', 'df_entries'], []).length) {
      content = `This account has OTHER OPEN WORKUNITS`;
      if (_.get(wDoc, ['oou', 'wos_entries'], []).length) {
        content += `and OPEN HDMS WORKORDERS`;
      }
    } else if (_.get(wDoc, ['oou', 'wos_entries'], []).length) {
      content = `This account has OPEN HDMS WORKORDERS`;
    }

    if (content) {
      this.props.open_confirmation_dialog_ac({
        title: 'ALERT',
        content,
        actions: [
          {
            label: 'View',
            action: () => {
              this.onTabSelected(null, 'oou_tab');
              this.props.close_confirmation_dialog_ac();
              return true;
            },
          },
        ],
      });
    }
  }

  checkSituationByPosInDocSet(prevProps) {
    if (this.props.docsArray === null) {
      return;
    }

    if (this.props.docsArray.hdr === false) {
      return;
    }

    if (this.props.accountInView[this.props.docPositionKey] === null) {
      return;
    }

    const posInSet = this.props.accountInView[this.props.docPositionKey];
    this.checkIfSnackShouldFire(prevProps);

    if (this.props.docInView.docJobKey === null) {
      if (this.props.docsArray.docs.length < posInSet) {
        return;
      }
      this.docSelected(posInSet);
      return;
    }

    if (posInSet !== this.props.docInView.posInSet) {
      this.docSelected(posInSet);
      return;
    }

    if (
      !prevProps ||
      this.props.docInView.docJobKey !== prevProps.docInView.docJobKey
    ) {
      this.prep();
      return;
    }

    if (
      prevProps.accountInView.accountIdn !== this.props.accountInView.accountIdn
    ) {
      return this.docSelected(posInSet);
    }

    if (
      typeof this.props.onSwitchTabWithProp === 'function' &&
      (prevProps.docViewerTab !== this.props.docViewerTab ||
        this.props.docViewerTab !== this.state.selectedTab)
    ) {
      this.setState({ selectedTab: this.props.docViewerTab });
      if (this.props.docViewerTab === 'pdf_viewer')
        return this.buildPfRenderingPayloadsFromDocJob();
    }
  }

  //called by Entities Analysis if page has a saveable table
  onHasSaveableTableData(pageOfDocument) {
    this.setState({
      comprehend_pages: {
        ...this.state.comprehend_pages,
        [pageOfDocument]: {
          ...this.state.comprehend_pages[pageOfDocument],
          hasSaveableDataTable: true,
        },
      },
    });
  }

  selectedDoc = (doc) => {
    const { docJob } = this.props.docInView;
    if (!docJob) return;

    let pngbaseid =
      doc.pages && doc.pages.length
        ? doc.pages[0].origFaxJobId.toString()
        : docJob._id.toString();

    let baseUrl = [
      'https://s3-us-west-2.amazonaws.com/com.supercaremed.privatestatic',
      docJob.s3bucketPath,
      'pngs',
      pngbaseid + '-',
    ].join('/');
    let iv = forge.util.decode64(docJob.keys[1]);
    let pw = docJob.keys[0];
    let pagesToLoad = [].concat(doc.pages);
    pagesToLoad.reverse();
    this.current = {
      iv,
      pw,
      totalPageCount: doc.pages.length,
      pagesToLoad,
      baseUrl,
      pagesLoaded: [],
    };
    this.fetchPage();
  };

  handleMenuOpen = (event) => {
    const { currentTarget } = event;
    this.setState({
      open: true,
      anchorEl: currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    });
  };

  render() {
    const {
      classes,
      docInView,
      me,
      wDoc,
      allScriptsReferral,
      formAcctCreation,
      accountInView,
    } = this.props;
    const { accountDocs } = accountInView;
    const docs = accountDocs && accountDocs.docs;
    const { anchorEl, open } = this.state;

    if (this.props.docsArray === null) {
      return (
        <FetchingStatement>Click on document title to view.</FetchingStatement>
      );
    }

    if (this.props.docsArray.hdr === false) {
      return (
        <FetchingStatement>
          No valid account has been selected.
        </FetchingStatement>
      );
    }

    if (
      this.props.accountInView[
        this.props.docSelectionBy === 'docKeySelected'
          ? 'docKeySelected'
          : this.props.docPositionKey
      ] === null
    ) {
      return (
        <FetchingStatement>
          No document from {this.props.docsArray.hdr.nm} account doc list has
          been selected.
        </FetchingStatement>
      );
    }

    const indicator =
      this.props.accountInView[
        this.props.docPositionKey === 'posOfWorkUnitDocSelected'
          ? 'posOfWorkUnitDocSelected'
          : this.props.docSelectionBy
      ];

    const docInViewIndicator =
      docInView[
        this.props.docSelectionBy === 'posOfDocSelected'
          ? 'posInSet'
          : 'docJobKey'
      ];

    if (docInView.docJobKey === null) {
      return (
        <FetchingStatement>
          ....dispatching {indicator} {docInView.docJobKey}
        </FetchingStatement>
      );
    }

    if (indicator !== docInViewIndicator) {
      return (
        <FetchingStatement>
          ....change of doc, dispatching {indicator} -{docInViewIndicator}-
        </FetchingStatement>
      );
    }

    /*
    if ( docInViewIndicator!== this.state.docJobKey ){
      return (
        <FetchingStatement>
        ....loading {docInViewIndicator}
        </FetchingStatement>
      )
    }
    */

    let pages = this.current.pagesLoaded;
    let wrapStyle = { height: this.props.height }; //
    const byTabKlass = {
      root: classes.byTabKlass,
      labelContainer: classes.tabLabelContainer,
    };

    const { docJob, docJobKey } = docInView;
    const { pdfUri } = this.state;

    let comprehend_msg = [];
    for (let pgPos in this.state.comprehend_pages) {
      comprehend_msg.push(parseInt(pgPos, 10));
    }

    const is_confirmer =
      [
        ..._.get(me, 'user.teamq', []),
        ..._.get(me, 'user.supervisor_of', []),
        ..._.get(me, 'user.manager_of', []),
      ].includes('CONFIRMATION') || me.user.nm === 'RAYMOND IE';
    const isConfirmable =
      wDoc &&
      wDoc.hdmsAccts.length > 0 &&
      ((wDoc.ownersArr &&
        wDoc.ownersArr.length > 0 &&
        wDoc.ownersArr[0].product_bn === 'WO-CONF' &&
        checkIfStringHasIntegersFollowedByW(wDoc.ownersArr[0].product)) ||
        (wDoc.pastOwnersArr &&
          wDoc.pastOwnersArr.length > 0 &&
          wDoc.pastOwnersArr[0].product_bn === 'WO-CONF' &&
          checkIfStringHasIntegersFollowedByW(wDoc.pastOwnersArr[0].product)));

    let applicableOA = null;
    if (wDoc && wDoc.ownersArr && wDoc.ownersArr.length > 0) {
      applicableOA = { user: me.user.nm, team: wDoc.ownersArr[0].team };

      let teams_involved = [
        ...(me.user.teamq || []),
        ...(me.user.manager_of || []),
        ...(me.user.supervisor_of || []),
      ];

      if (!teams_involved.includes(wDoc.ownersArr[0].team)) {
        //user is not a member, supervisor, or manager of the team that the workunit is assigned to
        applicableOA.team = teams_involved.length
          ? teams_involved[0]
          : applicableOA.team;
      }
    }

    const has_todo_ids =
      _.get(wDoc, 'todo_ids', []).length ||
      _.get(wDoc, 'todo_ids_closed', []).length;

    let all_scripts_document = false;
    let referralId = false;
    if (allScriptsReferral) {
      referralId = allScriptsReferral;
      all_scripts_document = true;
    } else {
      if (wDoc) {
        all_scripts_document = _.get(wDoc, 'labels.mode', []).includes(
          'ALLSCRIPTS'
        );
        if (all_scripts_document) {
          referralId = wDoc.labels.mode.filter(
            (v) => v.search(/^R[0-9]{3,}/) === 0
          );
          if (referralId.length) referralId = referralId[0].substr(1);
          else referralId = false;
        }
      }
    }

    return (
      <div className={classes.content}>
        <div className={classes.tabDiv}>
          <Tabs
            style={{ minHeight: 28 }}
            value={this.state.selectedTab}
            onChange={this.onTabSelected}
            indicatorColor="secondary"
            textColor="secondary"
            scrollButtons="off">
            <Tab classes={byTabKlass} value="doc_viewer" label="ImageViewer" />
            <Tab classes={byTabKlass} value="pdf_viewer" label="PDFViewer" />
            {wDoc &&
              wDoc.oou &&
              (wDoc.oou.df_entries.length || wDoc.oou.wos_entries.length) && (
                <Tab
                  classes={byTabKlass}
                  value="oou_tab"
                  label="Other Open Workunits-Workorders"
                />
              )}
            {all_scripts_document && referralId && (
              <Tab
                classes={byTabKlass}
                value="all_scripts_referral"
                label="AllScriptsReferral"
              />
            )}
            {has_todo_ids && (
              <Tab
                classes={byTabKlass}
                value="todos_linked_w_doc"
                label="Linked DF Tasks"
              />
            )}
            {is_confirmer && isConfirmable && (
              <Tab
                classes={byTabKlass}
                value="confirmation_log"
                label="Confirmation-Log"
              />
            )}
            <Tab classes={byTabKlass} value="wDoc_json" label="IT TAB" />
            {ALLOW_ANALYSIS &&
              !_.isEmpty(this.state.comprehend_source_pages) &&
              _.intersection(me.user.roles, ['intel-tagger', 'intel-viewer'])
                .length > 0 && (
                <Tab
                  classes={byTabKlass}
                  value="analysis"
                  label={
                    comprehend_msg.length
                      ? 'Analysis'
                      : '...' + comprehend_msg.join(',')
                  }
                />
              )}
            {this.state.selectedTab === 'doc_viewer' && formAcctCreation && (
              <Tab
                onClick={this.handleMenuOpen.bind(this)}
                classes={byTabKlass}
                label="Docs"
                value="docs"
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup={'true'}
              />
            )}
          </Tabs>
        </div>
        {['doc_viewer', 'pdf_viewer'].includes(this.state.selectedTab) && (
          <div className={classes.docTitle}>
            {docJob.displayAs} ({this.state.pagesReady} of{' '}
            {this.current.totalPageCount} pgs loaded) -{' '}
            <span style={{ color: 'blue' }}>
              Received: {moment(docInView.docJob.faxTs).format('MM-DD-YYYY')}
            </span>
            - {docJobKey}
          </div>
        )}
        {this.state.selectedTab === 'doc_viewer' && (
          <div style={wrapStyle} className={classes.kevin}>
            {pages.length === 0 && <strong>...loading pages</strong>}
            {pages.map((page, pageIdx) => (
              <ContainerDimensions key={pageIdx}>
                <ImgHolder
                  allowAnalyze
                  onAnalyze={(pageIdx) =>
                    this.onInitComprehendThisPageOnly(pageIdx)
                  }
                  page={page}
                  pageCount={pages.length}
                  pageIdx={pageIdx}
                />
              </ContainerDimensions>
            ))}
          </div>
        )}

        {this.state.selectedTab === 'pdf_viewer' && (
          <div style={wrapStyle} className={classes.kevin}>
            {this.state.isRenderingPdf && <strong>...rendering pdf...</strong>}

            {pdfUri && (
              <embed
                type="application/pdf"
                src={pdfUri + '#zoom=50'}
                marginWidth="0"
                style={{ width: '99%', height: 'calc(100% - 8px)' }}
              />
            )}
          </div>
        )}
        {this.state.selectedTab === 'analysis' && (
          <div style={wrapStyle} className={classes.kevin}>
            <AnalysisLanding
              onHasSaveableTableData={this.onHasSaveableTableData}
              docJobKey={docJobKey}
              docInView={docInView}
              currentDetails={
                this.props.docsArray.docs[docInView.posInSet].details
              }
              jsonData={this.state.comprehend_pages}
            />
          </div>
        )}

        {this.state.selectedTab === 'oou_tab' &&
          wDoc &&
          wDoc.oou &&
          applicableOA && (
            <div style={wrapStyle} className={classes.kevin}>
              <Grid container direction="row">
                <Grid item md={6}>
                  <RelatedWorkUnits
                    dfaInView={this.props.dfaInView}
                    insertIntoDfaList_ac={this.props.insertIntoDfaList_ac}
                    wDocInView={wDoc}
                    toUser={applicableOA.user}
                    toTeam={applicableOA.team}
                    classes={this.props.classes}
                  />
                </Grid>
                <Grid item md={6}>
                  <RelatedWorkOrders
                    classes={this.props.classes}
                    dfaInView={this.props.dfaInView}
                    wDocInView={wDoc}
                  />
                </Grid>
              </Grid>
            </div>
          )}

        {this.state.selectedTab === 'todos_linked_w_doc' && has_todo_ids && (
          <div style={wrapStyle} className={classes.kevin}>
            {this.props.todosInView.records === null && (
              <strong>
                [Tackle the workunit to view the linked tasks here]
              </strong>
            )}

            {this.props.todosInView.records !== null &&
              this.props.todosInView.records.map((todoDoc, posInSet) => {
                return (
                  <TodoCard
                    key={todoDoc._id + '-' + posInSet}
                    todoDoc={todoDoc}
                    onLocalUpdate={this.onLocalUpdate}
                    posInSet={posInSet}
                    doNotAllowCollapse
                    expanded
                  />
                );
              })}
          </div>
        )}

        {wDoc &&
          me.user.roles.includes('it-admin') &&
          this.state.selectedTab === 'wDoc_json' && (
            <div style={wrapStyle} className={classes.kevin}>
              <textarea
                value={JSON.stringify(wDoc, null, '  ')}
                style={{
                  fontSize: 11,
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          )}

        {this.state.selectedTab === 'confirmation_log' && (
          <div style={wrapStyle} className={classes.kevin}>
            <ConfirmationLogLanding wDoc={wDoc} />
          </div>
        )}
        {this.state.selectedTab === 'all_scripts_referral' && (
          <div style={wrapStyle} className={classes.kevin}>
            <ReferralXML
              referralId={referralId}
              autoExpanded={true}
              isClickable={allScriptsReferral ? true : false}
            />
          </div>
        )}
        {this.state.selectedTab === 'doc_viewer' && (
          <DocMenuItems
            open={open}
            anchorEl={anchorEl}
            handleMenuClose={this.handleMenuClose}
            docs={docs}
            selectedDoc={this.selectedDoc}
          />
        )}
      </div>
    );
  }
}

DocViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  accountInView: PropTypes.object.isRequired,
  //docsArray: PropTypes.object.isRequired,
  docPositionKey: PropTypes.string.isRequired,
  docInView: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired, //requires ContainerDimensions wrapper in parent
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  todosInView: stateFromStore.todosInView,
  accountInView: stateFromStore.accountInView,
  docInView: stateFromStore.docInView,
  dfaInView: stateFromStore.dfaInView,
});

const mapDispatchToProps = (dispatch, propsOfDocViewer) =>
  bindActionCreators(
    {
      open_confirmation_dialog_ac,
      close_confirmation_dialog_ac,
      insertIntoDfaList_ac,
      open_snack_ac,
      close_snack_ac,
      docSelected_ac,
      loadingPages_ac,
      pagesLoaded_ac,
      locallyUpdateTodoDoc_ac,
      intel_worker_init,
    },
    dispatch
  );

export const DocViewerWithoutConnect = withStyles(styles)(DocViewer);

export const DocViewerWithRouter = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DocViewer))
);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  withRef: true,
})(withStyles(styles)(DocViewer));
