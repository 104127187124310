import {
  Grid,
  Button,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  flushGlobalWUAction_ac,
  copyDocsToWU_ac,
} from '../../../actions/global_wu_actions.ac.js';
import { open_snack_ac } from '../../../actions/snack.ac.js';
import {
  GLOBAL_WU_ACTIONS,
  WORKUNIT_EVENT,
  WU_COMPLETE,
} from '../constants.js';
import { getErrorMessage, nmToId } from '../../../util/string.js';
import useDebounce from '../../../hooks/useDebounce.js';
import { peekWorkunit } from '../utils.js';
import FormModal from '../../common/FormModal.js';

const styles = (theme) => ({
  content: {
    padding: '8px 0',
  },

  coreForm: {
    width: '100%',
  },

  tblHeaderNode: {
    padding: '4px 0',
  },

  tblHeaderNodeTxt: {
    fontWeight: 'bold',
    fontSize: '12px',
  },

  tblNode: {
    padding: '4px 0',
    marginBottom: '6px',
  },

  tblNodeTxt: {
    color: 'crimson',
    fontSize: '11px',
  },

  activeTblNodeTxt: {
    color: 'green',
  },
});

const DocsList = (props) => {
  const { classes, data = {}, setData, wu = {} } = props;

  const { docs = [] } = data;

  const hasWULoaded = Boolean(wu?._id);

  useEffect(() => {
    if (wu?._id) {
      setData({
        ...data,
        docs: (data.docs || []).map((rcr) => {
          const newDisplayAs = `${wu.nm}:${rcr.doc?.nm}`;
          return {
            faxJob: _.omit(rcr.faxJob || {}, ['pages', 'rotation', 'info']),
            doc: {
              ..._.omit(rcr.doc || {}, [
                'wId',
                'wParent',
                'origFaxJobIdx',
                'faxJobIdx',
                'docIdx',
              ]),
              faxJobId: rcr.faxJob?._id,
              manually_copied: true,
              displayAs: newDisplayAs,
              id: nmToId(newDisplayAs),
            },
          };
        }),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wu]);

  return (
    <Grid
      container
      style={{
        border: '1px solid #ccc',
        padding: '6px',
        paddingBottom: 0,
      }}>
      <Grid item xs={8} className={classes.tblHeaderNode}>
        <Typography className={classes.tblHeaderNodeTxt}>
          {`Document (${hasWULoaded ? 'Modified' : 'Original'})`}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.tblHeaderNode}>
        <Typography className={classes.tblHeaderNodeTxt}>
          {'Origin Fax ID'}
        </Typography>
      </Grid>
      <Divider component={'hr'} />

      {docs.map((rcr, idx) => (
        <React.Fragment key={idx}>
          <Grid item xs={8} className={classes.tblNode}>
            <Typography
              className={classNames(classes.tblNodeTxt, {
                [classes.activeTblNodeTxt]: hasWULoaded,
              })}>
              {rcr.doc?.displayAs
                ? `${rcr.doc?.displayAs} [${rcr.doc?.pages?.length || 0} pages]`
                : 'Invalid Doc'}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.tblNode}>
            <Typography
              className={classNames(classes.tblNodeTxt, {
                [classes.activeTblNodeTxt]: hasWULoaded,
              })}>
              {rcr.faxJob?._id || 'Invalid Fax'}
            </Typography>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

const ACTIONS = {
  [GLOBAL_WU_ACTIONS.COPY_DOCS]: {
    title: 'Copy Docs to an open workunit',
    submitLabel: 'Copy documents',
    warning: `WARNING: THIS ACTION CANNOT BE UNDONE. PLEASE MAKE SURE YOU HAVE SELECTED THE CORRECT WORKUNIT.`,

    Content: DocsList,

    submit: (props, actionProps = {}, { before, after }) => {
      props.copyDocsToWU(
        {
          ...actionProps,
          eventParams: {
            wuAction: WORKUNIT_EVENT.MANUAL_DOC_COPY,
          },
        },
        {
          before,
          after,
          onSuccess: () => {
            props.openSnack({
              variant: 'success',
              message: `Docs copied to workunit: ${actionProps.vId} successfully.`,
            });
          },
          onError: (error) => {
            props.openSnack({
              variant: 'error',
              message: getErrorMessage(
                error,
                `Failed to copy the docs to to workunit: ${actionProps.vId}`
              ),
            });
          },
        }
      );
    },
  },
};

const GlobalWUAction = (props) => {
  const { classes, globalWUAction } = props;

  const {
    activeAction,
    props: initialActionProps = {},
    callback,
  } = globalWUAction;

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [actionProps, setActionProps] = React.useState(null);

  useEffect(() => {
    setActionProps(initialActionProps);
  }, [initialActionProps]);

  const [vId, setVId] = React.useState(0);

  const [isPeekingWorkunit, setIsPeekingWorkunit] = React.useState(false);
  const [peekedWorkunit, setPeekedWorkunit] = React.useState(null);

  const isActionActive = !!activeAction;

  const handleClose = () => {
    setVId(0);
    setPeekedWorkunit(null);
    props.flushGlobalWUAction();
  };

  const actionConfig = isActionActive ? ACTIONS[activeAction] : null;

  const handleSubmit = () => {
    actionConfig.submit(
      props,
      {
        ...actionProps,
        vId: peekedWorkunit._id,
      },
      {
        before: () => {
          setIsProcessing(true);
        },
        after: (success) => {
          setIsProcessing(false);
          if (success) {
            handleClose();

            if (callback) {
              callback();
            }
          }
        },
      }
    );
  };

  useDebounce(
    () => {
      if (vId) {
        peekWorkunit(+vId, ['claimed_by'], {
          before: () => {
            setIsPeekingWorkunit(true);
          },
          after: () => {
            setIsPeekingWorkunit(false);
          },
          onSuccess: (result) => {
            setPeekedWorkunit(result || null);

            if (!result) {
              setActionProps(initialActionProps);
            }
          },
          onError: (error) => {
            props.openSnack({
              variant: 'error',
              message: getErrorMessage(
                error,
                `Failed to peek workunit: ${vId}`
              ),
            });
          },
        });
      }
    },
    1000,
    [vId]
  );

  const isWorkunitOpen =
    peekedWorkunit &&
    peekedWorkunit.is_active &&
    peekedWorkunit.bu_state !== WU_COMPLETE;

  return (
    <FormModal
      isModalOpen={isActionActive}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      windowLike={true}
      disabled={isProcessing || isPeekingWorkunit}
      onClose={handleClose}
      title={actionConfig?.title}
      actions={
        <>
          <Grid item style={{ padding: 8 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                isProcessing || isPeekingWorkunit || !vId || !isWorkunitOpen
              }
              onClick={handleSubmit}>
              {isProcessing
                ? 'Processing...'
                : isPeekingWorkunit
                ? 'Peeking...'
                : actionConfig?.submitLabel || 'Submit'}
            </Button>
          </Grid>
          <Grid item style={{ padding: 8 }}>
            <Button
              variant="outlined"
              color="default"
              onClick={handleClose}
              disabled={isPeekingWorkunit || isProcessing}>
              Cancel
            </Button>
          </Grid>
        </>
      }>
      {actionConfig?.Content && (
        <div className={classes.content}>
          <actionConfig.Content
            data={actionProps}
            setData={setActionProps}
            classes={classes}
            wu={peekedWorkunit}
          />
        </div>
      )}

      <Divider
        component={'hr'}
        style={{
          margin: '8px 0',
          color: 'rgba(0, 0, 0, 0.12)',
        }}
      />
      <div className={classes.coreForm}>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              type="number"
              variant="standard"
              margin="dense"
              autoFocus
              label="Workunit ID"
              placeholder="Workunit ID to copy docs to"
              name={'vId'}
              fullWidth
              onChange={(e) => {
                setVId(e.target.value);
              }}
              value={vId || ''}
            />
          </Grid>
          {peekedWorkunit?._id && (
            <Grid
              item
              xs={8}
              style={{
                paddingLeft: '24px',
              }}>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{
                  fontSize: '10px',
                  color: 'blue',
                  width: 'fit-content',
                  border: '1px solid blue',
                  padding: '4px 8px',
                  textAlign: 'left',
                }}>
                <strong>{'Copy to workunit: '}</strong> {peekedWorkunit._id}
                <br />
                <strong>{'Status: '}</strong>
                {`${peekedWorkunit.bu_state} [${peekedWorkunit.assigned_team}]`}
                <br />
                <strong>{'Claimed by: '}</strong>
                {peekedWorkunit.claimed_by?.u_id || 'Unclaimed'}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Typography
          variant="caption"
          color="textSecondary"
          style={{
            fontSize: '12px',
            marginTop: '12px',
            color: 'red',
            textAlign: 'center',
            width: '100%',
          }}>
          {peekedWorkunit
            ? isWorkunitOpen
              ? actionConfig?.warning || ''
              : `Cannot copy docs to workunit: ${vId} as it is not open.`
            : 'Please provide a valid workunit ID.'}
        </Typography>
      </div>
    </FormModal>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  globalWUAction: stateFromStore.globalWUAction,
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      flushGlobalWUAction: flushGlobalWUAction_ac,
      copyDocsToWU: copyDocsToWU_ac,
      openSnack: open_snack_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(GlobalWUAction));
