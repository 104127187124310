import {
  REQUEST_HOURLY_WOS_GROUP,
  RECEIVED_HOURLY_WOS_GROUP,
  FAILED_HOURLY_WOS_GROUP
} from '../actions/hourly_wos_group.ac'

export function hourlyWosGroup(
  state = {
    isFetching: false,
    error: null,
    data: {}
  },
  action
) {
  switch (action.type) {
    case REQUEST_HOURLY_WOS_GROUP:
      return Object.assign({}, state, {
        isFetching: true
      })
    case RECEIVED_HOURLY_WOS_GROUP:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.pkg.data,
        error: null
      })
    case FAILED_HOURLY_WOS_GROUP:
      return Object.assign({}, state, {
        isFetching: false,
        data: {},
        error: action.error
      })
    default:
      return state
  }
}
