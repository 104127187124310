import React from 'react'
import { Button } from '@material-ui/core'

export const getActionButton = (label, handleClick) => {
  return (
    <Button key="hc_snack_view_button" onClick={() => handleClick()}>
      <strong>{label}</strong>
    </Button>
  )
}
