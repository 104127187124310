import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
    //backgroundColor: theme.palette.background.paper,
  },
  appBarSpacer: {
    /*...theme.mixins.toolbar, */
    minHeight: 34
  },
  scrollArea: {
    overflowY: 'auto',
    height: '92vh'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  }
})

class FetchingStatementWrap extends React.Component {
  render() {
    return (
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12}>
          {this.props.isError ? (
            <h5 style={{ color: 'red' }}>
              Error occurred: {this.props.title}
            </h5>
          ) : (
              <h5>....loading content for {this.props.title}</h5>
            )}

          {this.props.msg && <h5>{this.props.msg}</h5>}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  masterSearch: stateFromStore.masterSearch
})

const mapDispatchToProps = (dispatch, propsOfDocRequestsLanding) =>
  bindActionCreators({}, dispatch)

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(FetchingStatementWrap))
)
