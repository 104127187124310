import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';

const styles = () => ({
  root: {
    margin: 0,
    padding: 0,
    lineHeight: '1.42857143rem',
    boxSizing: 'border-box',
    '&:after, &:before': {
      boxSizing: 'border-box',
    },
    '&:after': {
      clear: 'both',
    },
    overflowY: 'auto',
  },
  row: {
    '&:before, &:after': {
      display: 'table',
      content: '" "',
    },
  },
  timeline: {
    minWidth: '400px',
    minHeight: '100px',
    position: 'relative',
    padding: '40px 20px',
    width: '85%',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      background: '#2196f3',
    },
    '&:before': {
      height: '10px',
      width: '10px',
      borderRadius: '50%',
    },
    '&:after': {
      bottom: 0,
      width: '2px',
    },
  },
  mobileTimeline: {
    padding: '30px 15px',
    minWidth: '100%',
    '&:before, &:after': {
      left: '3%',
    },
  },
  timelineItem: {
    width: '100%',
    fontSize: '12px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    '& > *': {
      flex: '100px',
    },
    '&:last-child:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      background: '#2196f3',
      height: '10px',
      width: '10px',
      borderRadius: '50%',
    },
  },
  eventContent: {
    color: '#424242',
    padding: '8px 6px',
    border: '1px solid grey',
    borderRadius: '6px',
  },
  timelineItemTitle: {
    fontSize: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: 'navy',
  },
  timelineItemContent: { fontSize: '12px', marginTop: '6px', color: 'purple' },
  timelineItemFooter: {},
  timelineItemInfo: {
    color: '#b3b3b3',
  },
  timelineItemDate: {
    color: 'blue',
    textAlign: 'right',
    display: 'block',
  },
  timelineItemUser: {
    color: 'grey',
    fontSize: '10px',
    textAlign: 'right',
    display: 'block',
  },
  timelineItemSelector: {
    flex: '0 0 6px',
    padding: '6px',
    border: '1px solid #2196f3',
    borderRadius: '50%',
    margin: '0px 20px',
    background: '#fff',
    zIndex: 2,
    '&:hover': {
      transition: 'all 0.3s',
      background: '#2196f3',
      cursor: 'pointer',
    },
  },
  timelineBullets: {
    padding: 2,
    margin: '0 0 0 12px',
    fontSize: 11,
  },
  fireCount: {
    color: 'crimson',
    fontSize: '14px',
    marginRight: '4px',
  },
  noEvents: {
    color: 'grey',
    textAlign: 'center',
    fontSize: '14px',
  },
});

const TimelineItem = (props) => {
  const {
    item,
    index,
    renderTitle,
    renderDetail,
    classes,
    mobileView = false,
  } = props;

  const timelineInfo = (
    <div
      className={classes.timelineItemInfo}
      style={
        mobileView
          ? {
              borderBottom: '1px solid #b3b3b3',
              paddingBottom: '4px',
              marginBottom: '4px',
            }
          : {}
      }>
      {renderTitle && renderTitle(item, index)}
    </div>
  );

  const timelineDetail = (
    <div
      className={classes.eventContent}
      style={
        mobileView
          ? {
              marginLeft: '14px',
            }
          : {}
      }>
      {mobileView && timelineInfo}
      {renderDetail && renderDetail(item, index)}
    </div>
  );

  return (
    <li className={classnames(classes.row, classes.timelineItem)}>
      {!mobileView && timelineInfo}
      <div
        className={classes.timelineItemSelector}
        style={
          mobileView
            ? {
                marginLeft: '0px',
                position: 'absolute',
                left: '1%',
              }
            : {}
        }></div>
      {timelineDetail}
    </li>
  );
};

const Timeline = (props) => {
  const {
    items,
    classes: classesProp,
    overrideClasses,
    timelineStyle,
    collapsed = false,
    renderTitle,
    renderDetail,
    // mobileView: defaultMobileView,
  } = props;

  // const [mobileView, setMobileView] = React.useState(
  //   defaultMobileView || false
  // );

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(max-width: 768px)');
  //   const handleMediaQueryChange = (e) => setMobileView(e.matches);

  //   setMobileView(mediaQuery.matches);

  //   mediaQuery.addEventListener('change', handleMediaQueryChange);

  //   return () => {
  //     mediaQuery.removeEventListener('change', handleMediaQueryChange);
  //   };
  // }, []);

  const classes = {
    ...classesProp,
    ...(overrideClasses || {}),
  };

  const components = items.map((item, index) => (
    <TimelineItem
      key={index}
      index={index}
      classes={classes}
      item={item}
      mobileView={collapsed}
      renderTitle={renderTitle}
      renderDetail={renderDetail}
    />
  ));

  return items.length ? (
    <ul
      className={classnames(classes.timeline, {
        [classes.mobileTimeline]: collapsed,
      })}
      style={timelineStyle}>
      {components}
    </ul>
  ) : (
    <Typography className={classes.noEvents}>{'No items to show!'}</Typography>
  );
};

export default withStyles(styles)(Timeline);
