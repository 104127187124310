export function adjustmentRequestsInView(
  state = {
    isFetching: false,
    idn: null,
    disableAdjustment: false,
    error: null,
    records: null,
    claimIds: [],
    pagination: null
  },
  action
) {
  switch (action.type) {
    case 'FETCH_CLAIMS_FOR_ADJ':
      return Object.assign({}, state, {
        idn: action.idn,
        disableAdjustment: false,
        records: null,
        error: null,
        isFetching: true,
        claimIds: action.claimIds
      })
    case 'FAILED_CLAIMS_FOR_ADJ':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        disableAdjustment: false,
        error: action.error,
        isFetching: false
      })
    case 'GOT_CLAIMS_FOR_ADJ':
      return Object.assign({}, state, {
        records: action.records,
        disableAdjustment: action.disableAdjustment,
        error: null,
        isFetching: false
      })

    case 'RESET_CLAIMS_FOR_ADJ':
      return Object.assign({}, state, {
        disableAdjustment: false,
        claimIds: [],
        records: null,
        pagination: null,
        error: null,
        params: null,
        idn: null,
        isFetching: false
      })

    default:
      return state
  }
}
