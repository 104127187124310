import { withStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
/* file level imports */
import Tabs from '@material-ui/core/Tabs'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { docReset_ac } from '../../actions/account_in_view.ac.js'
import { fetchIdnDoc_ac, idnTabSelected_ac } from '../../actions/idn.ac.js'
import { setTitle_ac } from '../../actions/me.ac.js'
import { IdnTabCfiTodos } from './IdnTabTodos'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  topContent: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    marginTop: 10,
    height: '100vh',
    backgroundColor: '#fff'
  },
  grow: { flexGrow: 1 },
  title: {
    flexGrow: 1
  },
  appBarSpacer: {
    /*...theme.mixins.toolbar, */
    minHeight: 36
  },
  flexContainer: {
    display: 'block'
  },
  loadingMsg: {
    padding: 20
  },
  tabLabelContainer: {
    padding: '3px 12px 3px 12px'
  },
  tabRoot: {
    minWidth: 16,
    minHeight: 24
  },
  tabsRoot: {
    minHeight: 24
  }
})

class GeneralLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpen: false,
      effectiveTab: 'doclisting',
      selectedTab:
        this.props.match.params.tab || this.props.idnInView.selectedTab,
      selectedTabParam: this.props.match.params.tabParam || null,
      masterSearchStr: ''
    }
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    this.handleDrawerClose = this.handleDrawerClose.bind(this)
    this.handleTabSelect = this.handleTabSelect.bind(this)
  }

  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true })
  }

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false })
  }

  handleTabSelect = (e, _selectedTab) => {
    console.log(_selectedTab)
    let toPath
    if (_selectedTab.search(/hdms_notes/) > -1) {
      const [selectedTab, selectedTabParam] = _selectedTab.split(/\//)
      console.log(selectedTab, selectedTabParam)

      toPath = this.props.match.path.toString()
      toPath = toPath.replace(/:idnStr/, this.props.match.params.idnStr)
      toPath = toPath.replace(/:tab/, selectedTab)

      if (toPath.search(new RegExp(selectedTabParam + '$')) === -1) {
        toPath += '/' + selectedTabParam
      }

      this.props.changePage(toPath)
      return
    }

    toPath = this.props.match.path
      .replace(/:idnStr/, this.props.match.params.idnStr)
      .replace(/:tab/, _selectedTab)

    this.props.changePage(toPath)
    return
  }

  componentDidMount() {
    if (this.props.me.user === null) {
      return
    }
    const { idnInView, match } = this.props

    if (match.params.idnStr !== idnInView.idnStr) {
      return this.props.fetchIdnDoc_ac(match.params.idnStr)
    }

    switch (this.props.match.path) {
      case '/dfa/gen/:idnStr':
        let toPath = this.props.match.url + '/tabs/doclisting'
        return this.props.changePage(toPath)
      default:
        break
    }

    let tabToApply = match.params.tab
    let tabParamToApply = match.params.tabParam || null
    let effectiveTab = tabParamToApply
      ? tabToApply + '/' + tabParamToApply
      : tabToApply
    if (tabToApply === 'account_docs_dockey') tabToApply = 'doclisting'
    this.setState({
      selectedTab: tabToApply,
      seelctedTabParam: tabParamToApply,
      effectiveTab
    })
    this.props.idnTabSelected_ac(tabToApply)
    return
  }

  componentDidUpdate(prevProps) {
    if (this.props.me.user === null) {
      return
    }

    const { idnInView, match } = this.props
    const { match: prevMatch } = prevProps

    if (match.params.idnStr !== idnInView.idnStr) {
      return this.props.fetchIdnDoc_ac(match.params.idnStr)
    }

    let toPath = ''
    switch (this.props.match.path) {
      case '/dfa/idn/:idnStr':
        toPath = this.props.match.url + '/tabs/doclisting'
        return this.props.changePage(toPath)
      default:
        break
    }

    let tabToApply = match.params.tab
    let tabParamToApply = match.params.tabParam || null
    let effectiveTab = tabParamToApply
      ? tabToApply + '/' + tabParamToApply
      : tabToApply
    if (tabToApply === 'single_wo') {
      effectiveTab = 'single_wo'
    } else {
      effectiveTab = tabParamToApply
        ? tabToApply + '/' + tabParamToApply
        : tabToApply
    }
    //console.log(this.state.effectiveTab)
    if (
      this.state.effectiveTab !== effectiveTab ||
      prevMatch.params.tab !== tabToApply ||
      (prevMatch.params.tabParam && //must not be undefined
        prevMatch.params.tabParam !== tabParamToApply)
    ) {
      if (tabToApply === 'account_docs_dockey') tabToApply = 'doclisting'
      this.setState({
        selectedTab: tabToApply,
        seelctedTabParam: tabParamToApply,
        effectiveTab
      })
      this.props.idnTabSelected_ac(tabToApply)
      return
    }

    if (match.params.idnStr !== prevMatch.params.idnStr) {
      this.props.docReset_ac()
    }
  }

  render() {
    const { classes, idnInView } = this.props

    let loadingMsg = null
    if (idnInView.error) {
      loadingMsg = `failed loading ${this.props.idnInView.idnStr}`
    }

    if (
      idnInView.isFetching ||
      !idnInView.idnDoc ||
      idnInView.idnStr !== this.props.match.params.idnStr
    ) {
      loadingMsg = `...loading ${this.props.idnInView.idnStr}`
    }

    if (loadingMsg) {
      return (
        <div className={classes.appBarSpacer}>
          <h4 className={classes.loadingMsg}>
            ...loading {this.props.match.params.idnStr}
          </h4>
        </div>
      )
    }

    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot
    }
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer
    }

    return (
      <>
        <Tabs
          classes={tabsClass}
          value={this.state.effectiveTab}
          onChange={this.handleTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab value="cfi_todos" label="CFI TASKS" classes={tabClass} />
        </Tabs>

        <Route
          path="/dfa/gen/:selectedIdn/tabs/cfi_todos"
          component={IdnTabCfiTodos}
        />
      </>
    )
  }
}

GeneralLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  masterSearch: stateFromStore.masterSearch,
  idnInView: stateFromStore.idnInView
})

const mapDispatchToProps = (dispatch, propsOfGeneralLanding) =>
  bindActionCreators(
    {
      setTitle_ac,
      docReset_ac,
      fetchIdnDoc_ac,
      idnTabSelected_ac,
      changePage: path => {
        return push(
          path
            .replace(/:tabParam/, '')
            .replace(/:posInSet\//, '')
            .replace(/:docName/, '')
            .replace(/\/$/, '')
            .replace(/\/\//, '/')
        )
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(GeneralLanding))
)

//export default withStyles(styles)(GeneralLanding)
