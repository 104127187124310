import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';

const WoSearch = ({ checkWo, validatePosInt }) => {
  return (
    <Grid align="center">
      <Typography variant="h" color="textPrimary">
        Create GSO Shipment for Work Order
      </Typography>
      <Grid>
        <Grid container direction="column">
          <Grid item xs={12}>
            <TextField
              id="wo"
              label="Work Order Number"
              margin="dense"
              onKeyUp={checkWo}
              onChange={validatePosInt}
              placeholder="Work Order Number"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WoSearch;
