import { connectRouter, routerMiddleware } from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import {
  accountCMNsInView,
  accountConsent,
  accountInView,
  accountPAsInView,
  docInView,
} from './reducers/account_in_view.red.js';
import { activeRentals } from './reducers/active_rentals.red';
import { activityLogInView } from './reducers/activity_log.red.js';
import {
  activityLogAudit,
  initialState as activityLogAuditInitialState,
} from './reducers/activity_log_audit.red.js';
import { adjustmentRequestsInView } from './reducers/adjustment_requests.red.js';
import { adjRequestsReport } from './reducers/adjustment_requests_report.red';
import { allScriptsReportInView } from './reducers/all_scripts_report.red';
import {
  appealRequestActivity,
  appealRequestsInView,
} from './reducers/appeal_requests.red.js';
import { appealRequestsReport } from './reducers/appeal_requests_report.red';
import { careConnectTasksInView } from './reducers/care_connect_tasks.red.js';
import { catalog } from './reducers/catalog.red.js';
import { clientAppsInView } from './reducers/client_apps.red';
import { communicationPreferenceInView } from './reducers/communication_preference.red';
import { complianceDocs } from './reducers/compliance.red.js';
import {
  confirmationEntryForm,
  confirmationLog,
} from './reducers/confirmation_log.red';
import connect from './reducers/connect.red';
import {
  connectHolds,
  initialState as connectHoldsInitialState,
} from './reducers/connect_holds.red.js';
import { accountDocs, counter } from './reducers/counter.red.js';
import { dcs } from './reducers/dcs.red';
import { dfSnapshots } from './reducers/df_snapshots.red';
import {
  dfWorkunit,
  initialState as dfWorkunitInitialState,
} from './reducers/df_workunits.red.js';
import { dfWorkunitsReports } from './reducers/df_workunits_reports.red.js';
import { dfaInView, navList } from './reducers/dfa.red.js';
import { workUnitReportsInView } from './reducers/dfworkunits_report_bydate_byhours.red';
import { confirmationDialog } from './reducers/dialog.red.js';
import {
  docRequestActivity,
  docRequestsInView,
} from './reducers/doc_requests.red.js';
import {
  docSubmissionActivity,
  docSubmissionsInView,
} from './reducers/doc_submissions.red.js';
import { expiringCCSMCalReport } from './reducers/expiringCCSMCal.red.js';
import { expiringCMNsReport } from './reducers/expiringCMNs.red.js';
import { expiringPAs, expiringPAsReport } from './reducers/expiringPAs.red.js';
import { fbSearchInView } from './reducers/fb_search.red';
import { fileBound } from './reducers/filebound.red';
import formCreation from './reducers/form_creation_helper.red';
import formReducer, {
  initialState as formsInitialState,
} from './reducers/forms';
import { generalUploadActivity } from './reducers/generalUploadActivity.red';
import {
  globalWUAction,
  initialState as globalWUActionInitialState,
} from './reducers/global_wu_actions.red.js';
import { hdmsNotes } from './reducers/hdms_notes.red';
import { hourlyWos } from './reducers/hourly_wos.red';
import { hourlyWosGroup } from './reducers/hourly_wos_group.red';
import { hourlyWosSummary } from './reducers/hourly_wos_summary.red';
import { idnInView } from './reducers/idn.red.js';
import { idnAccountInfo } from './reducers/idn_account_info.red';
import { hdmsAccountInsurance } from './reducers/idn_account_insurance.red';
import { idnShellInView } from './reducers/idn_shell.red';
import { idnWos } from './reducers/idn_wos.red';
import { idnWosLines } from './reducers/idn_wos_lines.red';
import { idxAdminDividerMapping } from './reducers/idx_admin_divider_mapping.red';
import { idxAdminDocQual } from './reducers/idx_admin_doc_qual.red';
import {
  idxAdminLocationDetails,
  idxAdminLocations,
  idxAdminLocationSearchFilters,
  idxAdminLocationSorting,
} from './reducers/idx_admin_locations.red';
import { idxAdminLogs } from './reducers/idx_admin_log.red.js';
import { idxAdminOrderingClinicians } from './reducers/idx_admin_ordering_clinicians.red';
import { idxAdminPendingSLARule } from './reducers/idx_admin_pending_sla_rule.red';
import {
  idxAdminReps,
  idxAdminRepSearchFilters,
  idxAdminRepSorting,
} from './reducers/idx_admin_reps.red';
import { idxAdminSLARule } from './reducers/idx_admin_sla_rule.red';
import { idxAdminTeamAssignment } from './reducers/idx_admin_team_assignment.red';
import {
  idxAdminWorkunitTypeDetails,
  idxAdminWorkunitTypes,
} from './reducers/idx_admin_workunittypes.red';
import { idxLookups } from './reducers/idx_lookups.red';
import { indexLogsInView } from './reducers/index_logs.red.js';
import { indexSummaryInView } from './reducers/index_summary.red.js';
import { indexingDetailsInView } from './reducers/indexing_details.red.js';
import {
  indexingJob,
  initialState as indexingJobInitialState,
} from './reducers/indexing_job.red.js';
import { ivrInfo } from './reducers/ivr_info.red.js';
import { lookups } from './reducers/lookups.red.js';
import { mailBoxesInView } from './reducers/mail_boxes.red.js';
import { mappingToolInView } from './reducers/mapping_tool.red';
import { masterSearch } from './reducers/masterSearch.red.js';
import { me } from './reducers/me.red.js';
import { mednecIssueLog } from './reducers/mednec_issue_log.red';
import { myHistory } from './reducers/my_history.red.js';
import { newPatientRecord } from './reducers/new_patient_record.red';
import { notificationPreferenceInView } from './reducers/notificaion_preference.red';
import { notify_files } from './reducers/notify/files';
import { notify_view } from './reducers/notify/view';
import { onboardedReport } from './reducers/onboarded_report.red';
import { openCountsByEmail } from './reducers/open_counts_by_email.red';
import { openOrders } from './reducers/open_orders.red';
import {
  salesReps,
  initialState as salesRepsInitialState,
} from './reducers/sales_reps.red.js';

import userProfiles, {
  initialState as userProfilesInitialState,
} from './reducers/user_profiles.red.js';

import {
  opsFormRecordActivity,
  opsFormRecordFormActivity,
  opsFormRecordsInView,
} from './reducers/ops_forms.red.js';
import { opsFormsReport } from './reducers/ops_forms_report.red';
import { oxygenCountsState } from './reducers/oxygen_counts.red';
import { claimInView, paErrorsList } from './reducers/pa_errors.red.js';
import { papCalculator } from './reducers/pap_calculator.red';
import { parachuteReportInView } from './reducers/parachute_report.red.js';
import { patientHealthcallData } from './reducers/patient_healthcall_data.red';
import {
  allPatientLettersReport,
  patientLettersReport,
} from './reducers/patient_letters_report.red';
import pdfGen from './reducers/pdfGen.red';
import { ppRegistrations } from './reducers/pp_registrations.red';
import reassign from './reducers/reassign.red';
import { room } from './reducers/room.red.js';
import {
  salesdashRecords,
  salesdashRefreshTS,
  salesdashSalesPersons,
  salesdashWOSFilters,
  salesdashWOSRecords,
} from './reducers/salesdash.red';
import { shoppingCartInView } from './reducers/shopping_cart.red.js';
import { singleWo } from './reducers/single_wo.red';
import { snlookupsinview } from './reducers/sn_lookup.red';
import { snack } from './reducers/snack.red.js';
import { teams } from './reducers/teams.red';
import { teamsGridInView } from './reducers/teams_grid.red.js';
import {
  todoCreationActivity,
  todosInView,
  todosSummarySlicing,
} from './reducers/todos.red.js';
import { ubList } from './reducers/ub.red.js';
import { userMgtLogsInView } from './reducers/user_mgt_logs.red.js';
import { notifWebWorker } from './reducers/web_workers.red';
import { workOrdersReportInView } from './reducers/work_order_reports.red';
import { workOrderInView } from './reducers/workorder.red.js';

import faAdminReducers, {
  faAdminInitialStates,
} from './reducers/faAdminReducers';
import pdfDetails from './reducers/Pdf_details.red';
import workunitsReducers, {
  workunitsInitialStates,
} from './reducers/workunitsReducers';

import {
  workorders,
  workorderSearchFilters,
  workorderSorting,
} from './reducers/workorders.red';

import {
  activePatients,
  initialState as activePatientsInitialState,
} from './reducers/active_patients.red.js';
import { axiom } from './reducers/axiom.red';
import {
  reactHealth,
  initialState as reactHealthInitialState,
} from './reducers/react_health.red.js';
import { recurringReport } from './reducers/report_recurring.red';

const createHistory = require('history').createBrowserHistory;

const rootReducer = combineReducers({
  me,
  userProfiles,
  notifWebWorker,
  snack,
  room,
  lookups,
  masterSearch,
  dfSnapshots,
  openOrders,
  counter,
  idnWos,
  idnWosLines,
  idnAccountInfo,
  singleWo,
  hdmsAccountInsurance,
  docRequestsInView,
  docRequestActivity,
  opsFormRecordActivity,
  opsFormRecordsInView,
  opsFormRecordFormActivity,
  opsFormsReport,
  ivrInfo,
  complianceDocs,

  activePatients,
  reactHealth,

  // New forms module
  forms: formReducer,

  appealRequestsInView,
  appealRequestActivity,
  adjustmentRequestsInView,
  allPatientLettersReport,
  patientLettersReport,
  adjRequestsReport,
  appealRequestsReport,
  idxLookups,
  docSubmissionsInView,
  docSubmissionActivity,
  generalUploadActivity,
  todosInView,
  todosSummarySlicing,
  todoCreationActivity,
  ubList,
  teams,
  dcs,
  myHistory,
  catalog,
  hdmsNotes,
  accountDocs,
  paErrorsList,
  claimInView,
  accountInView,
  accountPAsInView,
  accountCMNsInView,
  accountConsent,
  docInView,
  navList,
  hourlyWos,
  hourlyWosSummary,
  hourlyWosGroup,
  expiringPAs,
  expiringPAsReport,
  expiringCCSMCalReport,
  expiringCMNsReport,
  dfaInView,
  idnInView,
  connect,
  pdfGen,
  formCreation,
  reassign,
  indexSummaryInView,
  activityLogInView,
  indexingDetailsInView,
  indexingJob,
  dfWorkunit,
  globalWUAction,
  salesReps,
  activityLogAudit,
  connectHolds,
  teamsGridInView,
  userMgtLogsInView,
  indexLogsInView,
  mailBoxesInView,
  parachuteReportInView,
  careConnectTasksInView,
  dfWorkunitsReports,
  shoppingCartInView,
  salesdashRecords,
  salesdashRefreshTS,
  salesdashSalesPersons,
  salesdashWOSRecords,
  salesdashWOSFilters,
  onboardedReport,
  confirmationLog,
  confirmationEntryForm,
  activeRentals,
  workOrderInView,
  workUnitReportsInView,
  notificationPreferenceInView,
  openCountsByEmail,
  mappingToolInView,
  ppRegistrations,
  snlookupsinview,
  workOrdersReportInView,
  idnShellInView,
  allScriptsReportInView,
  newPatientRecord,
  communicationPreferenceInView,
  clientAppsInView,
  patientHealthcallData,
  fbSearchInView,
  fileBound,
  mednecIssueLog,
  confirmationDialog,
  papCalculator,

  oxygenCountsState,

  idxAdminLocations,
  idxAdminLocationDetails,
  idxAdminLocationSearchFilters,
  idxAdminLocationSorting,
  idxAdminOrderingClinicians,
  idxAdminReps,
  idxAdminRepSearchFilters,
  idxAdminRepSorting,
  idxAdminWorkunitTypes,
  idxAdminDividerMapping,
  idxAdminTeamAssignment,
  idxAdminSLARule,
  idxAdminPendingSLARule,
  idxAdminWorkunitTypeDetails,
  idxAdminDocQual,
  idxAdminLogs,
  notify_files,
  notify_view,
  faAdminReducers,
  pdfDetails,
  workunitsReducers,
  workorders,
  workorderSorting,
  workorderSearchFilters,
  recurringReport,
  axiom,
});

export const history = createHistory();

const initialState = {
  me: {
    //sessionId:null,
    user: null,
    isFetching: false,
    landingChecked: false,
    isLoggedIn: false,
    error: null,
    errorFrom: null,
    title: 'Docflow',
    filterOpen: false,
  },
  userProfiles: userProfilesInitialState,
  hourlyWos: {
    data: [],
    meta: {},
    isFetching: false,
    error: null,
    wosList: [],
  },
  activityLogAudit: activityLogAuditInitialState,
  hourlyWosSummary: {
    data: {},
    isFetching: false,
    error: null,
  },
  hourlyWosGroup: {
    data: {},
    isFetching: false,
    error: null,
  },
  idxLookups: {
    structure: {
      data: {},
      isFetching: false,
      error: null,
    },
    list: {
      data: [],
      isFetching: false,
      error: null,
    },
    detail: {
      data: null,
      isFetching: false,
      error: null,
    },
  },
  myHistory: {
    isFetching: false,
    results: null,
    lastChecked: null,
    msg: '',
  },
  snack: {
    open: false,
    variant: 'info',
    message: '',
    notif_open: false,
    notif_variant: 'notif',
    notif_message: '',
    notifsPanelOpen: false,
    handleClick: () => {},
    additionalActions: [],
  },
  room: {
    status: 'disconnected',
    socket: null,
    hotel_msg: null,
    ub_msg: null,
    last_location: null,
    last_df_msg: null,
    last_df_msg_sent: false,
  },
  lookups: {
    isFetching: false,
    ink: null,
    error: null,
    payerSets: null,
    isGapiValidating: false,
    isGapiValid: false,
  },

  activePatients: activePatientsInitialState,
  reactHealth: reactHealthInitialState,

  catalog: {
    isFetching: false,
    isFetchingItems: false,
    includeDiscontinued: false,
    lastMethod: 'select',
    lastParams: null,
    error: null,
    isUpdating: false,
    updateError: null,
    pkg: null,
  },
  //reports
  dfSnapshots: {
    params: null,
    isFetching: false,
    pkg: null,
    error: false,
  },
  //reports
  openOrders: {
    params: null,
    isFetching: false,
    pkg: null,
    error: false,
    ifFetchingTrackingReport: false,
    html: null,
    trackingError: false,
  },

  teams: {
    isFetching: false,
    pkg: null,
    error: false,
  },
  dcs: {
    isFetching: false,
    error: null,
    pkg: null,
  },

  //todos
  todosInView: {
    params: null,
    isFetching: false,
    records: null,
    pagination: null,
    error: false,

    isFetchingSummary: false,
    summary: null,
    summaryError: null,

    //update activity details
    activity: {
      todoId: null,
      inprog: false,
      error: null,
    },
  },

  // New forms module
  forms: formsInitialState,

  todosSummarySlicing: {
    isFetching: false,
    slicing: null,
    error: null,
    abc: null,
  },

  // indexing view
  indexingDetailsInView: {
    params: null,
    isFetching: false,
    records: null,
    tags: null,
    pagination: null,
    error: false,
    isTransferringOwnership: false,
    transferOwnershipError: null,
  },

  indexingJob: indexingJobInitialState,
  dfWorkunit: dfWorkunitInitialState,

  globalWUAction: globalWUActionInitialState,

  connectHolds: connectHoldsInitialState,
  salesReps: salesRepsInitialState,

  indexSummaryInView: {
    isFetching: false,
    summary: null,
    error: false,
  },
  activityLogInView: {
    params: null,
    isFetching: false,
    records: null,
    pagination: null,
    tags: null,
    error: false,
  },

  /* only used for new ToDo record creation by todoForm */
  todoCreationActivity: {
    idn: null,
    resultPkg: null,
    state: 'new', //inprog, done, error
    error: false,
  },

  //IDN LANDING RELATED
  idnInView: {
    idnStr: '',
    idnDoc: null, //distinguish from results:[]  (empty result set) vs null: not initiated
    selectedTab: 'doclisting',
    isFetching: false,
    mode: null,
    error: false,
  },

  singleWo: {
    isFetching: false,
    idn: null,
    wo: null,
    error: false,
  },

  idnWos: {
    isFetching: false,
    account: null,
    member: null,
    pkg: null,
    activityPkg: null,
    error: false,
  },
  idnWosLines: {
    isLoading: false,
    idn: null,
    pkg: null,
    error: false,
  },
  idnAccountInfo: {
    isFetching: false,
    idn: null,
    pkg: null,
    activityPkg: null,
    error: false,
    isAccountCreateTabOpen: false,
    stateData: null,
  },
  hdmsAccountInsurance: {
    idn: null,
    titleMsg: null,
    isFetching: false,
    accounts: null,
    error: null,
  },
  accountConsent: {
    isFetching: false,
    acctMember: null,
    hdms: null,
    consentRecord: null,
    events: [],
    error: null,
  },
  accountInView: {
    isFetching: false,
    accountIdn: null,
    accountDocs: null,
    posOfDocSelected: null,

    workUnitDocs: null,
    posOfWorkUnitDocSelected: null,
    wDoc: null,

    fbDocs: null,
    fbDocsError: null,
    fbPathsFetching: false,
    fbDocsFetching: false,
    fbPathInView: null,
    docListingViewType: 'Date',
    docKeySelected: null,
    error: null,
  },
  accountPAsInView: {
    account: null,
    member: null,
    isFetching: false,
    pasPkg: null,
    error: null,
  },
  accountCMNsInView: {
    account: null,
    member: null,
    isFetching: false,
    cmnsPkg: null,
    error: null,
  },
  docInView: {
    isFetching: false,
    pagesAreLoading: false,
    posInSet: null,
    docJob: null,
    docActivityInProgress: false,
    docActivity: null,
    docJobKey: null, //workunit id + '-' + index of document in workunit
  },

  appealRequestsInView: {
    //a tab of idn landing
    records: null,
    params: null,
    idn: null,
    isFetching: false,
    error: false,
    savingAppealODocId: null,
    transmitInProgress: false,
    transmitError: null,
  },
  appealRequestActivity: {
    //coupled in with appealRequestsInView
    enabledTpls: [],
    appealsFiles: [],
    state: 'new', //inprog, error, success,
    error: false,
    successPkg: null,
    isFetchingDataSet: false,
    dataSet: null,
    dataSetError: null,
    qrCodeHash: null,
    qrCodeFetchInProg: false,
  },

  adjustmentRequestsInView: {
    //a tab of idn landing
    claimIds: [],
    records: null,
    params: null,
    idn: null,
    isFetching: false,
    error: null,
  },
  adjRequestsReport: {
    isFetchingReport: false,
    report: null,
    error: null,
  },
  appealRequestsReport: {
    isFetchingReport: false,
    report: null,
    error: null,
  },

  opsFormRecordsInView: {
    //a tab of idn landing
    records: null,
    params: null,
    idn: null,
    isFetching: false,
    error: false,
    transmitInProgress: false,
    transmitError: null,
  },
  opsFormRecordActivity: {
    //coupled in with docRequestsInView
    outboundFiles: [],
    state: 'new', //inprog, error, success,
    error: false,
    successPkg: null,
    qrCodeHash: null,
    qrCodeFetchInProg: false,
  },
  opsFormRecordFormActivity: {
    isSubmittingComment: false,
    isSubmittingVoid: false,
  },

  docRequestsInView: {
    //a tab of idn landing
    records: null,
    params: null,
    idn: null,
    isFetching: false,
    error: false,
    transmitInProgress: false,
    transmitError: null,
  },
  docRequestActivity: {
    //coupled in with docRequestsInView
    outboundFiles: [],
    state: 'new', //inprog, error, success,
    error: false,
    successPkg: null,
    qrCodeHash: null,
    qrCodeFetchInProg: false,
  },

  //a tab of idn landing
  docSubmissionsInView: {
    records: null,
    idn: null,
    isFetching: false,
    error: false,
  },
  docSubmissionActivity: {
    successPkg: null,
    submissionFiles: [],
    state: 'new', //inprog, error, readyForUpload(ready for submission), uploadInProgress
    error: false,
  },

  hdmsNotes: {
    //a tab of idn landing
    isFetching: false,
    acctMember: null,
    pkg: null,
    noteHdrsFetching: false,
    noteHdrs: null,
    error: false,
  },

  ivrInfo: {
    //a tab of idn landing
    isFetching: false,
    acctMember: null,
    pkg: null,
    error: null,
  },

  //used by idn landing components and dfalanding
  dfaInView: {
    isFetching: false,
    searchStr: '',
    pageNum: 1,
    sortCode: 'nd',
    navList: null,
    results: null,
    //when tackling, put the  record in focus
    posOfActivity: null, //when not null, it's in focus
    isUpdating: false,
    wIdn: null,
  },
  navList: {
    isFetching: false,
    loaded: false,
    baseItems: [{ nm: 'Home', searchPath: 'home' }],
    secondaryBaseItems: [{ nm: 'Home', searchPath: 'home' }],
    menus: [],
  },

  generalUploadActivity: {
    successPkg: null,
    submissionFiles: [],
    state: 'new', //inprog, error, readyForUpload(ready for submission), uploadInProgress
    error: false,
  },

  masterSearch: {
    qualified: false, //enough characters
    searchInProgress: false,
    results: null,
    kind: null,
    searchStr: '',
    isFetching: false,
  },

  allPatientLettersReport: {
    isFetchingReport: false,
    report: null,
    error: null,
  },

  patientLettersReport: {
    isFetchingReport: false,
    report: null,
    error: null,
    idnStr: '',
  },

  //NON PATIENT STUFF
  expiringPAsReport: {
    isFetching: false,
    error: null,
    pkg: null,
  },
  expiringCMNsReport: {
    isFetching: false,
    error: null,
    pkg: null,
  },
  expiringCCSMCalReport: {
    isFetching: false,
    error: null,
    pkg: null,
  },

  expiringPAs: {
    records: null,
    pagination: null,
    error: null,
    stats: null,
    statsError: null,
    isFetching: false,
    isFetchingStats: false,
    activityUpdating: false,
    posOfActivity: null,
  },
  paErrorsList: {
    records: [],
    stats: null,
    kind: 'initials',
    payerGroup: 'ccs',
    isFetching: false,
    activityUpdating: false,
    posOfActivity: 0,
    isValidating: false,
    validationError: false,
  },
  ubList: {
    records: null,
    params: null,
    isFetching: false,
    activityUpdating: false,
    activityParams: null,
    posOfActivity: 0,
    sarDoc: null,
    stats: null,
    isFetchingCcsPkg: false,
    ccsAccountInView: null,
    ccsPkg: null,
    ccsPkgError: null,
  },
  claimInView: {
    isFetching: false,
    claimDoc: null,
  },
  teamsGridInView: {
    params: null,
    isFetching: false,
    teamsLoading: {},
    cardsLoading: {},
    records: null,
    error: false,
  },
  userMgtLogsInView: {
    params: null,
    isFetching: false,
    records: null,
    tags: null,
    pagination: null,
    error: false,
  },
  indexLogsInView: {
    params: null,
    isFetching: false,
    records: null,
    tags: null,
    pagination: null,
    error: false,
  },
  mailBoxesInView: {
    params: null,
    isFetching: false,
    records: null,
    tags: null,
    pagination: null,
    error: false,
  },
  parachuteReportInView: {
    params: null,
    isFetching: false,
    records: null,
    pagination: null,
  },
  careConnectTasksInView: {
    params: null,
    isFetching: false,
    records: null,
    error: false,
    pagination: null,
    tasks: null,
    persons: null,
    selectedTaskName: null,
    isFetchingDetails: null,
    taskDetailsError: null,
    tags: null,
  },
  dfWorkunitsReports: {
    openCounts: null,
    closedCounts: null,
    flowReport: null,
    isFetching: false,
    error: null,
  },
  shoppingCartInView: {
    cart: {},
    total: 0,
    isFetching: false,
    error: null,
  },
  salesdashRecords: {
    myProgress: null,
    myBuckets: null,
    myHistory: null,
    myBrownList: null,
    isFetchingRecords: false,
    recordError: null,
  },
  salesdashRefreshTS: {
    isFetchingRefreshTS: false,
    refreshTS: null,
  },
  salesdashSalesPersons: {
    salesPersons: null,
    isFetchingSalesPersons: false,
    salesPersonsError: null,
  },
  salesdashWOSRecords: {
    wosRecords: null,
    isFetchingWOSRecords: false,
    wosRecordError: null,
  },
  salesdashWOSFilters: {
    wosFilters: null,
    isFetchingWOSFilters: false,
    wosFilterError: null,
  },
  onboardedReport: {
    records: null,
    pagination: null,
    error: null,
    isFetching: false,
  },
  confirmationLog: {
    records: null,
    pagination: null,
    error: null,
    isFetching: false,
    isSaving: false,
  },
  confirmationEntryForm: {
    issues: null,
    issuesError: null,
    isFetchingIssues: false,
    formData: null,
  },
  activeRentals: {
    isFetching: false,
    records: null,
    accountNums: null,
    pagination: null,
    error: {
      all: null,
    },
    idn: null,
  },
  notifWebWorker: {
    initiated: false,
    isFetching: false,
    error: null,
    records: [],
  },
  workOrderInView: {
    isFetching: false,
    isUpdating: false,
    records: null,
    error: null,
  },
  workUnitReportsInView: {
    isFetching: false,
    records: null,
    pagination: null,
    error: null,
  },
  notificationPreferenceInView: {
    result: null,
    error: null,
    isLoading: false,
  },
  openCountsByEmail: {
    error: null,
    record: null,
  },
  mappingToolInView: {
    jobs: null,
    existing_tpls: [],
    drafts: {},
    error: null,
    isFetching: false,
    isFetchingDraft: false,
    isSaving: false,
  },
  ppRegistrations: {
    isFetchingReport: false,
    report: null,
    error: null,
  },
  snlookupsinview: {
    isFetching: false,
    records: null,
    error: null,
  },
  workOrdersReportInView: {
    isFetching: false,
    pagination: null,
    records: null,
    error: null,
    wo_error: null,
    isFetchingWorkOrder: false,
    wo: null,
  },
  idnShellInView: {
    isSubmitting: false,
    idn: null,
    error: null,
    idn_shell: null,
  },
  opsFormsReport: {
    isFetching: false,
    report: null,
    error: null,
  },
  allScriptsReportInView: {
    isFetching: false,
    records: null,
    pagination: null,
    error: null,
    referral: null,
    referralError: null,
    isFetchingReferralDetails: false,
  },
  newPatientRecord: {
    record: null,
    isFetching: false,
    error: null,
    allScriptsReferral: null,
    selected_property: null,
    selected_value: null,
    selected_field: null,
  },
  communicationPreferenceInView: {
    result: null,
    error: null,
    isLoading: false,
    isFetching: false,
    preferences: null,
    preference: null,
  },
  clientAppsInView: {
    isSubmitting: false,
    isGenerating: false,
    isFetching: false,
    error: null,
    client_apps: null,
    records: null,
  },
  patientHealthcallData: {
    idnStr: null,
    isFetching: false,
    data: null,
    error: null,
  },
  fbSearchInView: {
    params: null,
    isFetching: false,
    pagination: null,
    error: null,
    result: null,
  },
  fileBound: {
    isFetching: false,
    isSaving: false,
    isFetchingDetails: false,
    pagination: null,
    error: null,
    accountingDocs: null,
    selectedAccountingDoc: null,
    accountingDocDetailsError: null,
    completedDocs: null,
    completedPagination: null,
    completedDocFetchingError: null,
    isFetchingCompletedList: false,
  },
  mednecIssueLog: {
    error: null,
    isFetching: false,
    isSaving: false,
    issues: null,
    records: null,
  },
  confirmationDialog: {
    open: false,
    title: '',
    content: '',
    actions: [],
  },
  papCalculator: {
    isFetching: false,
    isSaving: false,
    frequencyList: [],
    pagination: null,
    error: null,
    mask_type: 'nasal-cushion',
    payer: 'medi-cal',
    last_date_of_service: null,
    selectedItems: [],
  },
  oxygenCountsState: {
    counts: null,
    isFetching: false,
    error: null,
    filterData: {
      groupBy: 'dc',
      dc_id: null,
      driver_id: null,
      start: new Date(),
      end: new Date(),
      prod_grouping: 'oxygen',
    },
  },

  idxAdminLocations: {
    error: null,
    locations: null,
    isFetching: true,
  },
  idxAdminLocationDetails: {
    locationId: null,
    location: null,
    isFetching: false,
    error: null,
  },
  idxAdminLocationSearchFilters: {
    name: '',
    addr1: '',
    city: '',
    order_type: '',
    zip: '',
    rep_id: '',
    oc_id: '',
    record_id: '',
  },
  idxAdminOrderingClinicians: {
    error: null,
    data: null,
    isFetching: true,
    isRemoving: false,
    filters: {
      first_name: '',
      last_name: '',
      oc_level: '',
      npi: '',
      tags: null,
      record_id: '',
    },
    sorting: null,
  },
  idxAdminReps: {
    error: null,
    reps: null,
    isFetching: true,
    removeError: null,
    isRemoving: false,
  },
  idxAdminRepSearchFilters: {
    zip: '',
    tags: [],
    last_name: '',
    first_name: '',
    rep_region: '',
    record_id: '',
  },
  idxAdminWorkunitTypes: {
    error: null,
    workunitTypes: null,
    isFetching: false,
  },
  idxAdminDividerMapping: {
    error: null,
    dividerMapping: null,
    isFetching: false,
  },
  idxAdminTeamAssignment: {
    error: null,
    teamAssignment: null,
    isFetching: true,
  },
  idxAdminWorkunitTypeDetails: {
    workunitTypeId: null,
    workunitType: null,
    isFetching: false,
    error: null,
  },
  idxAdminSLARule: {
    error: null,
    slaRules: null,
    isFetching: true,
  },
  idxAdminPendingSLARule: {
    error: null,
    pendingSLARules: null,
    isFetching: true,
  },
  idxAdminDocQual: {
    error: null,
    docQuals: null,
    isFetching: true,
  },

  idxAdminLogs: {
    error: null,
    logs: null,
    isFetching: false,
  },
  notify_files: {
    files: [],
    filesRead: 0,
    internals: null,
    data: {
      controlsExternal: {},
      controlsInternal: {},
      notifications: {},
      ivr: {},
      contact: {},
    },
    error: null,
  },
  notify_view: {
    category: 'IVR',
    subcategory: 'MONTHLY',
    subsubcategory: 'ExpiringOxygenCMN',
  },
  faAdminReducers: {
    ...faAdminInitialStates,
  },

  workunitsReducers: { ...workunitsInitialStates },

  axiom: {
    suggestions: [],
    selectedContract: null,
  },
};

const enhancers = [];

const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
);
