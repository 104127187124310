import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from '@material-ui/core/styles'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import classNames from 'classnames'
import { push } from 'connected-react-router'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggleFilter_ac } from '../actions/me.ac.js'
import DateUtil from '../util/dateProcessing'
import UserSearch from './Users/UserSearch'
const styles = theme => ({
  root: {
    display: 'flex'
  },
  label: {
    color: theme.palette.primary.main,
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  filterHeader: {
    width: '100%',
    borderBottom: '2px solid #00000020'
  },
  filterRoot: {
    paddingLeft: 16
  },
  checkboxGroupRow: {},
  checkboxGroupRowItem: {},
  drawerPaper: {},
  drawerPaperClose: {},
  toolbarIcon: {},
  pagination: {
    marginTop: 0,
    padding: '8px'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  }
})

class FilterListing extends React.Component {
  constructor(props) {
    super(props)
    this.toggleSelectAll = this.toggleSelectAll.bind(this)

    let _t = {}
    let _textValues = {}
    if (props.filterSequence) {
      props.filterSequence.forEach(f => {
        switch (f.kind) {
          case 'text':
            //_textValues[f]="";
            break
          default:
            _t[f] = true
            break
        }
      })
    }

    this.state = {
      toggle: _t,
      textValues: _textValues
    }
    this.toggleSelectAll = this.toggleSelectAll.bind(this)
    this.onUserSelected = this.onUserSelected.bind(this)
    this.onUserRemove = this.onUserRemove.bind(this)
    this.onUserRemoveAll = this.onUserRemoveAll.bind(this)
    this.isInvalidForm = this.isInvalidForm.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
  }

  toggleSelectAll = fname => e => {
    if (typeof this.props.onFilterToggle !== 'function') return

    this.setState({
      toggle: { ...this.state.toggle, [fname]: !this.state.toggle[fname] }
    })
    this.props.onFilterToggle(fname, !this.state.toggle[fname])
  }
  isInvalidForm() {
    return Object.keys(this.props.filters).some(
      item => this.props.filters[item].error
    )
  }

  onUserRemoveAll = filterName => () => {
    this.props.onUserFilterUpdated(filterName, [])
  }

  onUserRemove = (filterName, email) => () => {
    let idx = this.props.filters[filterName].values.findIndex(
      v => v.email === email
    )
    if (idx === -1) return
    let _values = [...this.props.filters[filterName].values]
    _values.splice(idx, 1)
    this.props.onUserFilterUpdated(filterName, _values)
    return
  }

  onUserSelected = filterName => e => {
    if (
      this.props.filters[filterName].values.findIndex(
        v => v.email === e.email
      ) > -1
    ) {
      return //already listed
    }

    let _values = [
      ...this.props.filters[filterName].values,
      { nm: e.orig_nm, email: e.email }
    ]
    this.props.onUserFilterUpdated(filterName, _values)
  }
  open() {
    this.props.toggleFilter_ac(true)
  }
  close() {
    this.props.toggleFilter_ac(false)
  }

  /**
   * @param {string} name
   * @returns boolean
   */
  isCheckBoxDisabled(name) {
    const checkdItemsLength = this.props.filters[name].options.filter(
      option => option.checked
    ).length
    return checkdItemsLength >= this.props.filters[name].maxChecked
  }

  renderFilter() {
    const { classes } = this.props
    const { filters, filterSequence } = this.props

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.filterRoot}>
          {filterSequence.map((fName, fidx) => {
            const f = filters[fName]

            switch (f.kind) {
              case 'text':
                return (
                  <div key={fidx} style={{ marginBottom: 48 }}>
                    <label
                      onClick={this.props.onFilterClearField(fName)}
                      className={classes.label}>
                      {f.lbl} [Clear]
                    </label>
                    <FormGroup className={classes.checkboxGroupRow}>
                      <TextField
                        id="outlined-full-width-text"
                        style={{ margin: '8px 8px 8px 0' }}
                        placeholder={f.placeholder || ''}
                        fullWidth
                        margin="normal"
                        value={f.value}
                        onChange={this.props.onFilterChange(fName)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormGroup>
                  </div>
                )
              case 'users':
                return (
                  <div key={fidx} style={{ marginBottom: 48 }}>
                    <label
                      onClick={this.onUserRemoveAll(fName)}
                      className={classes.label}>
                      {f.lbl} [Clear]
                    </label>
                    <FormGroup className={classes.checkboxGroupRow}>
                      <div>
                        {f.values.map(v => (
                          <div
                            onClick={this.onUserRemove(fName, v.email)}
                            style={{ cursor: 'pointer', fontSize: 11 }}
                            key={v.email}>
                            [-] {v.nm}
                          </div>
                        ))}
                      </div>
                      <UserSearch
                        onSelect={this.onUserSelected(fName)}
                        includeDisabled
                      />
                    </FormGroup>
                  </div>
                )
              case 'date':
                return (
                  <div key={fidx} style={{ marginBottom: 48 }}>
                    <label
                      onClick={this.props.onFilterClearField(fName)}
                      className={classes.label}>
                      {f.lbl} [Clear]
                    </label>
                    <FormGroup className={classes.checkboxGroupRow}>
                      <TextField
                        id="outlined-full-width-date"
                        style={{ margin: '4px 4px 4px 0' }}
                        error={f.dirty && !DateUtil.isValidDate(f.value)}
                        placeholder={f.placeholder || ''}
                        fullWidth
                        margin="normal"
                        value={f.value}
                        onChange={this.props.onFilterChange(fName)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormGroup>
                  </div>
                )
              case 'dateTime':
                return (
                  <div key={fidx} style={{ marginBottom: 48 }}>
                    <label
                      onClick={this.props.onFilterClearField(fName)}
                      className={classes.label}>
                      {f.lbl} [Clear]
                    </label>
                    <FormGroup className={classes.checkboxGroupRow}>
                      <TextField
                        id="outlined-full-width-dateTime"
                        style={{ margin: '4px 4px 4px 0' }}
                        error={f.dirty && !DateUtil.isValidDateTime(f.value)}
                        placeholder={f.placeholder || ''}
                        fullWidth
                        margin="normal"
                        value={f.value}
                        onChange={this.props.onFilterChange(fName)}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    </FormGroup>
                  </div>
                )
              case 'radio':
                return (
                  <div key={fidx} style={{ marginBottom: '24px' }}>
                    <label className={classes.label}>{f.lbl}</label>
                    <RadioGroup
                      name={fName}
                      value={this.props.selected[fName]}
                      onChange={this.props.onRadioFilterChange}>
                      {f.options.map((t, pos) => (
                        <FormControlLabel
                          key={t.code}
                          control={<Radio />}
                          label={t.lbl}
                          value={t.code}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                )
              case 'labelOnly':
                return (
                  <div key={fidx} style={{ marginBottom: '8px' }}>
                    <label className={classes.label}>{f.lbl}</label>
                  </div>
                )
              default:
                return (
                  <div key={fidx} style={{ marginBottom: 48 }}>
                    {typeof this.props.onFilterToggle !== 'function' ? (
                      <label className={classes.label}>{f.lbl}</label>
                    ) : (
                      <label
                        onClick={this.toggleSelectAll(fName)}
                        style={{ cursor: 'pointer' }}
                        className={classes.label}>
                        {f.lbl} [+/-]
                      </label>
                    )}
                    <FormGroup className={classes.checkboxGroupRow}>
                      {f.options.map((t, pos) => (
                        <FormControlLabel
                          className={classes.checkboxGroupRowItem}
                          key={t.code}
                          control={
                            <Checkbox
                              checked={t.checked}
                              onChange={this.props.onFilterChange(fName)}
                              value={t.code}
                              disabled={
                                !t.checked && this.isCheckBoxDisabled(fName)
                              }
                            />
                          }
                          label={t.lbl ? t.lbl : t.code}
                        />
                      ))}
                    </FormGroup>
                  </div>
                )
            }
          })}
        </div>
        {/*--end root div */}
      </div>
    )
  }

  render() {
    const { classes, me } = this.props
    return (
      <SwipeableDrawer
        variant="temporary"
        anchor="left"
        onOpen={this.open}
        onClose={this.close}
        classes={{
          paper: classNames(
            classes.drawerPaper,
            !me.filterOpen && classes.drawerPaperClose
          )
        }}
        open={me.filterOpen}>
        <div className={classes.filterHeader}>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div">
                <div className={classes.toolbarIcon}>
                  Filter Menu
                  <IconButton onClick={this.close}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
              </ListSubheader>
            }
          />
          {this.props.applyFilters && (
            <div style={{ marginLeft: 12 }} className={classes.pagination}>
              <Typography
                className={classes.pageStatement}
                color="textSecondary">
                Listing Filters
              </Typography>
              <Button
                onClick={this.props.applyFilters}
                variant="outlined"
                disabled={this.isInvalidForm()}>
                Apply Filters
              </Button>
            </div>
          )}
        </div>
        <div style={{ padding: 12 }} className={classes.scrollArea}>
          {this.renderFilter()}
        </div>
      </SwipeableDrawer>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me
})

const mapDispatchToProps = (dispatch, propsOfIdnLanding) =>
  bindActionCreators(
    {
      toggleFilter_ac,
      changePage: path => {
        return push(path)
      }
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterListing))
