import { ParachuteReportActionTypes } from '../actions/parachute_report.ac';

export function parachuteReportInView(
  state = {
    isFetching: false,
    params: null,
    records: null,
    pagination: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    case ParachuteReportActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching,
      };
    case ParachuteReportActionTypes.UPDATE_RECEIVED_PARACHUTE_REPORTS:
      return {
        ...state,
        records: state.records.map((r) => {
          if (r._id === action.updatedDoc._id) {
            return action.updatedDoc;
          }
          return r;
        }),
      };
    case ParachuteReportActionTypes.RECEIVED_PARACHUTE_REPORTS:
      return {
        ...state,
        pagination: action.pagination,
        records: action.records,
        error: null,
      };

    case ParachuteReportActionTypes.FAILED_RECEIVING_PARACHUTE_REPORTS:
      return {
        ...state,
        pagination: null,
        records: null,
        error: action.error,
      };

    default:
      return state;
  }
}
