import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  addFormDataInStore_ac,
  fetchIssues_ac,
  saveConfirmation_ac
} from '../../actions/confirmation_log.ac'
import { open_snack_ac } from '../../actions/snack.ac'
import Loader from '../Shared/Loader'
import UserSearch from '../Users/UserSearch'

const defaultFormData = {
  wo_id: '',
  account: '',
  comment: '',
  issues: [],
  state: 'none',
  pcr: ''
}

const styles = theme => ({
  issuesList: {
    maxHeight: 300,
    overflowY: 'auto',
    flexWrap: 'nowrap',
    marginTop: 16,
    padding: '4px 12px',
    backgroundColor: '#f1f1f1'
  },
  selectedIssuesList: {
    maxHeight: 150,
    overflowY: 'auto',
    marginTop: 16,
    padding: 8,
    backgroundColor: `${theme.palette.secondary.main}10`
  },
  formHeader: {
    top: '-8px',
    zIndex: 200,
    position: 'sticky',
    backgroundColor: '#fff'
  }
})

const SmallSwitch = withStyles({
  root: {
    width: 46
  },
  switchBase: {
    width: 32,
    height: 32
  },
  icon: {
    width: 15,
    height: 15
  },
  bar: {
    width: 26,
    height: 10,
    marginTop: 0,
    marginLeft: 0,
    top: 'calc(50% - 5px)',
    left: 'calc(50% - 13px)'
  }
})(Switch)

const SmallChip = withStyles({
  root: {
    height: 24,
    borderRadius: 12
  },
  deleteIcon: {
    height: '80%',
    fontSize: 20,
    margin: '0 2px 0 -10px'
  }
})(Chip)

class ConfirmationEntryForm extends React.Component {
  constructor() {
    super()

    this.commentEntryTimerID = null
    this.state = {
      formData: {
        ...defaultFormData
      },
      errors: {
        issues: false,
        state: false,
        pcr: false,
        wo_id: false,
        account: false
      },
      issueIds: [],
      issuesMap: null,
      isSearchOn: true
    }
    this.handleCommentEntry = this.handleCommentEntry.bind(this)
    this.clearPCR = this.clearPCR.bind(this)
  }

  componentDidMount() {
    const { confirmationEntryForm } = this.props

    if (
      !confirmationEntryForm.isFetchingIssues &&
      !confirmationEntryForm.issuesError &&
      !confirmationEntryForm.issues
    ) {
      return this.props.fetchIssues_ac()
    }

    this.setIssues(confirmationEntryForm.issues)
  }

  componentDidUpdate(prevProps) {
    const { confirmationEntryForm, wo_id, account, wo } = this.props

    if (
      confirmationEntryForm.issues &&
      confirmationEntryForm.issues.length &&
      !this.state.issueIds.length
    ) {
      return this.setIssues(confirmationEntryForm.issues)
    }

    if (
      prevProps &&
      ((prevProps.wo && prevProps.wo._id !== wo._id) ||
        prevProps.wo_id !== wo_id)
    ) {
      this.setFormData(
        wo_id,
        account,
        _.get(wo.confirmation_entry, 'pcr', _.get(wo, 'pcr', null)),
        _.get(wo.confirmation_entry, 'state', '')
      )
      return
    }
  }

  componentWillUnmount() {
    if (this.props.hasSingleEntry)
      this.props.addFormDataInStore_ac(this.state.formData)
  }

  setIssues = issues => {
    let issuesMap = {}
    let issueIds = issues.map(issue => {
      issuesMap[`${issue._id}`] = {
        ...issue,
        selected: false
      }

      return `${issue._id}`
    })

    this.setState(
      {
        issueIds: issueIds,
        issuesMap: issuesMap
      },
      () => {
        if (this.props.wo_id) {
          this.setFormData(
            this.props.wo_id,
            this.props.account,
            _.get(this.props.wo.confirmation_entry, 'pcr', null),
            _.get(this.props.wo.confirmation_entry, 'state', '')
          )
        }
      }
    )
  }

  setFormData = (wo_id, account, pcr, state) => {
    let newIssuesMap = { ...this.state.issuesMap }
    const { formData } = this.props.confirmationEntryForm
    const { hasSingleEntry } = this.props

    this.state.issueIds.forEach(issueId => {
      newIssuesMap[issueId].selected = false
    })

    // map existing selected issues
    if (formData && formData.wo_id === `${wo_id}`) {
      formData.issues.forEach(issue => {
        newIssuesMap[issue].selected = true
      })
    }

    return this.setState(currState => ({
      formData: {
        ...defaultFormData,
        wo_id: wo_id ? `${wo_id}` : '',
        account: account ? `${account}` : '',
        pcr: pcr || { nm: '', mail: '' },
        state: state || '',
        ...(formData && formData.wo_id === `${wo_id}` && hasSingleEntry // Load formData from redux, only for idn-specific entry form
          ? this.props.confirmationEntryForm.formData
          : {})
      },
      errors: {
        issues: false,
        state: false,
        pcr: false,
        wo_id: false,
        account: false
      },
      issuesMap: { ...newIssuesMap }
    }))
  }

  handleFieldChange = event => {
    const { name, value } = event.target
    clearTimeout(this.commentEntryTimerID)
    this.setState(
      (state, props) => ({
        formData: {
          ...state.formData,
          [name]: value
        }
      }),
      () => {
        if (this.state.errors[name]) {
          this.validateForm('onChange')
        }
      }
    )
  }

  handleCommentEntry(e) {
    clearTimeout(this.commentEntryTimerID)
    this.setState({
      formData: {
        ...this.state.formData,
        comment: e.target.value
      }
    })
  }
  clearPCR() {
    this.setState({
      formData: {
        ...this.state.formData,
        pcr: { nm: '', mail: '' }
      }
    })
  }

  handleIssueSelection = issueId => {
    let selectedIssues = []

    if (this.state.issuesMap[issueId].selected) {
      selectedIssues = this.state.formData.issues.filter(
        issue => issue !== issueId
      )
    } else {
      selectedIssues = [...this.state.formData.issues]
      selectedIssues.push(issueId)
    }

    this.setState(
      (state, props) => ({
        formData: {
          ...state.formData,
          issues: [...selectedIssues]
        },
        issuesMap: {
          ...state.issuesMap,
          [issueId]: {
            ...state.issuesMap[issueId],
            selected: !state.issuesMap[issueId].selected
          }
        }
      }),
      () => {
        if (this.state.errors.issues) {
          this.validateForm('onChange')
        }
      }
    )
  }

  handleBulkIssuesUnSelect = () => {
    const newIssuesMap = { ...this.state.issuesMap }

    this.state.formData.issues.forEach(issue => {
      newIssuesMap[issue] = {
        ...newIssuesMap[issue],
        selected: false
      }
    })

    this.setState((state, props) => ({
      issuesMap: { ...newIssuesMap },
      formData: {
        ...state.formData,
        issues: []
      }
    }))
  }

  handleCancellation = () => {
    let issuesMap = { ...this.state.issuesMap }
    this.state.issueIds.forEach(issueId => {
      issuesMap[issueId] = {
        ...issuesMap[issueId],
        selected: false
      }
    })

    this.setState(state => ({
      formData: {
        ...state.formData,
        wo_id: this.props.wo_id ? `${this.props.wo_id}` : '',
        account: this.props.account ? `${this.props.account}` : '',
        comment: '',
        issues: [],
        state: 'none',
        pcr: { nm: '', mail: '' }
      },
      errors: {
        issues: false,
        state: false,
        pcr: false,
        wo_id: false,
        account: false
      },
      issuesMap: { ...issuesMap }
    }))

    this.props.closeEditForm()
  }

  toggleSearch = () => {
    this.setState(state => ({
      isSearchOn: !state.isSearchOn
    }))
  }

  handleEntrySave = () => {
    const isFormValid = this.validateForm('onSave')

    if (!isFormValid) {
      return
    }

    let entry = this.getEntryPayload()

    this.props.saveConfirmation_ac(entry)
  }

  validateForm = action => {
    const { formData } = this.state

    let issuesError = false
    let stateError = false
    let pcrError = false
    let woIdError = false
    let accountError = false
    let isValid = true

    if (formData.state === 'none') {
      stateError = true
      isValid = false
    }

    if (!formData.pcr && formData.state !== 'CONFIRMED') {
      pcrError = true
      isValid = false
    }

    if (!formData.wo_id || isNaN(formData.wo_id)) {
      woIdError = true
      isValid = false
    }

    if (!formData.account || isNaN(formData.account.replace(/-[1-9]$/, ''))) {
      accountError = true
      isValid = false
    }

    this.setState({
      errors: {
        issues: issuesError,
        state: stateError,
        pcr: pcrError,
        wo_id: woIdError,
        account: accountError
      }
    })

    if (action === 'onSave' && formData.issues.length > 10) {
      this.props.open_snack_ac({
        variant: 'error',
        message: 'At most 10 issues are allowed'
      })
      isValid = false
    }

    return isValid
  }

  getEntryPayload = () => {
    const { formData, issuesMap } = this.state
    return {
      account: parseInt(formData.account.replace(/-[0-9]$/, '')),
      comment: formData.comment,
      issues: formData.issues.map(issueId => ({
        _id: parseInt(issueId),
        lbl: issuesMap[issueId].lbl
      })),
      pcr: formData.pcr,
      state: formData.state,
      wo_id: parseInt(formData.wo_id)
    }
  }

  render() {
    const {
      classes,
      confirmationEntryForm,
      wo_id,
      account,
      isSaving
    } = this.props
    const { formData, issueIds, issuesMap, errors } = this.state

    const { issuesError, isFetchingIssues } = confirmationEntryForm
    const isCommentRequired =
      formData.state === 'NOT CONFIRMED' || formData.state === 'CANCELLED'
    const isSaveDisabled =
      isFetchingIssues ||
      isSaving ||
      !this.state.formData.account ||
      (isCommentRequired && formData.comment.trim() === '')

    return (
      <>
        <Typography variant="h5" className={classes.formHeader}>
          <strong>Edit Work Order {wo_id && `#${wo_id}`}</strong>
        </Typography>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="dense"
              name="wo_id"
              label="Work Order Id"
              value={formData.wo_id}
              required
              error={errors.wo_id}
              helperText={errors.wo_id ? 'Please enter non-empty number' : ''}
              disabled={wo_id ? true : false}
              onChange={this.handleFieldChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="dense"
              name="account"
              label="Account No."
              value={formData.account}
              required
              error={errors.account}
              helperText={errors.account ? 'Please enter non-empty number' : ''}
              disabled={account ? true : false}
              onChange={this.handleFieldChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TextField
              select
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="dense"
              name="state"
              label="State"
              value={formData.state}
              onChange={this.handleFieldChange}
              error={errors.state}
              helperText={errors.state ? 'Please select a state' : ''}
              required>
              {formData.state === 'none' && (
                <MenuItem value="none" disabled>
                  <Typography variant="inherit" color="textSecondary">
                    Select State
                  </Typography>
                </MenuItem>
              )}
              <MenuItem value="CANCELLED">Cancelled</MenuItem>
              <MenuItem value="CONFIRMED">Confirmed</MenuItem>
              <MenuItem value="NOT CONFIRMED">Not Confirmed</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TextField
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              margin="dense"
              name="pcr"
              label="PCR"
              value={formData.pcr.nm || ''}
              error={errors.pcr}
              helperText={errors.pcr ? 'Please specify a PCR' : ''}
              required
              InputProps={{
                endAdornment: (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={this.clearPCR}>
                    [X]
                  </Button>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <Collapse in={this.state.isSearchOn} unmountOnExit>
              <UserSearch
                placeholder="Search for PCR"
                onSelect={user => {
                  this.handleFieldChange({
                    target: {
                      name: 'pcr',
                      value: { nm: user.orig_nm, mail: user.mail }
                    }
                  })
                }}
              />
            </Collapse>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              required={isCommentRequired}
              name="comment"
              label={isCommentRequired ? 'Comment - REQUIRED' : 'Comment'}
              value={formData.comment}
              onChange={this.handleCommentEntry}
            />
          </Grid>
        </Grid>
        <Grid container spacing={16} direction="row" justify="space-between">
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              onClick={this.handleCancellation}
              disabled={isFetchingIssues || isSaving}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleEntrySave}
              disabled={isSaveDisabled}>
              Save
            </Button>
          </Grid>
        </Grid>
        {formData.issues.length ? (
          <>
            <div className={classes.selectedIssuesList}>
              <Grid container spacing={8}>
                {formData.issues.map(issue => (
                  <Grid key={issue} item>
                    <SmallChip
                      color="secondary"
                      label={issuesMap[issue].lbl}
                      onDelete={() => this.handleIssueSelection(issue)}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
            <Grid container spacing={8} direction="row" justify="space-between">
              <Grid item>
                <FormHelperText color="primary">
                  {formData.issues.length}
                  {formData.issues.length !== 1 ? ' issues ' : ' issue '}
                  selected
                </FormHelperText>
              </Grid>
              <Grid item style={{ marginTop: 4, marginBottom: '-8px' }}>
                <Button
                  fullWidth={false}
                  onClick={this.handleBulkIssuesUnSelect}
                  style={{ padding: '3px 4px' }}>
                  <Typography variant="caption" color="secondary">
                    Unselect All
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          ''
        )}
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <FormControl style={{ marginTop: 8, display: 'flex' }}>
              <InputLabel
                shrink
                style={{
                  backgroundColor: '#fff',
                  padding: '8px 0',
                  top: '-8px',
                  zIndex: 100
                }}
                required
                error={errors.issues}>
                Issues
              </InputLabel>
              {issueIds.length ? (
                <FormGroup className={classes.issuesList}>
                  <Grid container spacing={8}>
                    {issueIds.map(issueId => (
                      <Grid key={issueId} item xs={12} md={6}>
                        <FormControlLabel
                          id={issueId}
                          control={
                            <SmallSwitch
                              value={issueId}
                              checked={issuesMap[issueId].selected}
                              onChange={() =>
                                this.handleIssueSelection(issueId)
                              }
                            />
                          }
                          label={
                            <Typography variant="caption">
                              {issuesMap[issueId].lbl}
                            </Typography>
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </FormGroup>
              ) : (
                <div className={classes.issuesList}>
                  {isFetchingIssues && <Loader type="circular" size="15" />}
                  {issuesError && (
                    <Typography variant="caption" color="error">
                      Error fetching issues!
                    </Typography>
                  )}
                </div>
              )}
              {errors.issues && (
                <FormHelperText error>
                  Please select at least one issue
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  confirmationEntryForm: stateFromStore.confirmationEntryForm
})

const mapDispatchtoProps = dispatch =>
  bindActionCreators(
    {
      fetchIssues_ac,
      open_snack_ac,
      addFormDataInStore_ac,
      saveConfirmation_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(withStyles(styles)(ConfirmationEntryForm))
