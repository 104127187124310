import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import ApplicationList from './ApplicationList'
import { Application } from './Application'
import { AppPreferences } from './ClientAppsPreferences'

const styles = theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: 4,
        height: '100%',
        backgroundColor: '#fbf8f896',
        overflowY: 'auto'
    },
    fullHeight: {
        height: '100%'
    },
    tableScrollable: {
        minHeight: '220px',
        height: 'calc(100% - 46px)',
        overflowY: 'auto'
    },
    tableHeader: {
        height: 20,
        '& > th': {
            position: 'sticky',
            top: 0,
            left: 0,
            zIndex: 100,
            backgroundColor: '#fff'
        }
    },
    tableBodyCell: {
        borderBottom: 'unset',
        boxShadow: '0px 1px 2px 1px white'
    },
})

class ApplicationListLandingComponent extends React.Component {
    constructor() {
        super()
        this.state = {
            viewMode: null
        }
    }

    selectMode = (mode) => {
        this.setState({
            viewMode: mode
        })
    }

    render() {
        const { classes, clientAppsInView } = this.props
        const { viewMode } = this.state;

        return (
            <main className={classes.content}>
                <Grid container direction="row" className={classes.fullHeight} spacing={8}>
                    <Grid item xs={12} md={6} className={classes.fullHeight}>
                        <ApplicationList selectMode={this.selectMode} />
                    </Grid>
                    <Grid item md={6} className={classes.fullHeight}>
                        {
                            clientAppsInView.client_apps && viewMode === 'key_pairs' && (
                                <Application />
                            )
                        }
                        {
                            clientAppsInView.client_apps && viewMode === 'preferences' && (
                                <AppPreferences />
                            )
                        }
                    </Grid>
                </Grid>
            </main>
        )
    }
}

ApplicationListLandingComponent.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
    clientAppsInView: stateFromStore.clientAppsInView
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    )

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(ApplicationListLandingComponent))
)
