import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchTodos_ac,
  locallyUpdateTodoDoc_ac,
  toggleAllCards_ac,
} from '../../actions/todos.ac.js';
import TodoCard from '../Todos/TodoCard';
import TodoForm from '../Todos/TodoForm';

const styles = (theme) => ({
  root: {},
  title: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  fullHeight: {
    height: '100%',
  },
});

class _IdnTabTodos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Task',
      default_categories: [],
      default_selected_category: false,
      page: 0,
      lastToggleAllCards: true,
    };
    this.onLocalUpdate = this.onLocalUpdate.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onToggleCards = this.onToggleCards.bind(this);
  }

  onLocalUpdate(posInSet, todoDoc) {
    this.props.locallyUpdateTodoDoc_ac(posInSet, todoDoc);
  }

  componentDidMount() {
    const { todosInView, match } = this.props;
    if (this.props.me.user === null) {
      return;
    }
    if (
      todosInView.params === null ||
      _.get(todosInView.params, 'idn', null) !==
        decodeURIComponent(match.params.selectedIdn)
    ) {
      this.props.fetchTodos_ac({
        by: 'idn',
        idn: decodeURIComponent(match.params.selectedIdn),
      });
      return;
    }
  }

  componentDidUpdate() {
    const { todosInView, match } = this.props;
    if (this.props.me.user === null) {
      return;
    }
    if (
      todosInView.params === null ||
      _.get(todosInView.params, 'idn', null) !==
        decodeURIComponent(match.params.selectedIdn)
    ) {
      this.props.fetchTodos_ac({
        by: 'idn',
        idn: decodeURIComponent(match.params.selectedIdn),
      });
      return;
    }
  }

  onToggleCards() {
    this.props.toggleAllCards_ac(!this.state.lastToggleAllCards);
    this.setState({ lastToggleAllCards: !this.state.lastToggleAllCards });
  }

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) });
  }

  render() {
    const { classes, lookups, idnInView, todosInView } = this.props;

    if (
      !idnInView.idnDoc ||
      !lookups.ink ||
      null === todosInView.params ||
      todosInView.records === null
    ) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....</h4>
        </div>
      );
    }

    if (todosInView.isFetching) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....fetching</h4>
        </div>
      );
    }

    if (todosInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      );
    }

    let working_set = todosInView.records;
    if (this.state.force_filter_by_category) {
      working_set = todosInView.records.filter((r) => {
        if (this.state.default_categories.length === 0) {
          return lookups.ink.k.TODO_CODES_ENABLED.indexOf(r.code) > -1;
        } else {
          return this.state.default_categories.indexOf(r.code) > -1;
        }
      });
    }

    const { pagination: pg } = todosInView;
    let pageSelector = '';
    let pageStatement = '';

    if (pg) {
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Records',
        'Page ' + (this.state.page + 1) + ' of ' + pg.totalPagesAvailable,
        'From Selected Filters',
      ].join(' - ');

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <form className={classes.root} autoComplete="off">
            <FormControl>
              <Select
                onChange={this.onPageChange}
                displayEmpty
                value={this.state.page}
                name="page">
                {_.range(0, pg.totalPagesAvailable).map((pgNum) => (
                  <MenuItem key={pgNum} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        );
      }
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={6} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Grid container spacing={8} alignItems="flex-end">
                <Grid item xs={9} sm={10}>
                  <Typography
                    inline
                    style={{ cursor: 'pointer' }}
                    color="textSecondary"
                    onClick={this.onToggleCards}>
                    [Expand/Collapse All +/-]
                  </Typography>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    {working_set.length} {this.state.title} found for{' '}
                    {idnInView.idnStr.replace(/_/g, ' ')}
                  </Typography>
                  <Typography
                    className={classes.pageStatement}
                    color="textSecondary">
                    {pageStatement}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={2}>
                  {pageSelector}
                </Grid>
              </Grid>
            </div>

            <div className={classes.scrollArea}>
              <Route path="/:dfa_landing_kind/idn/:selectedIdn/tabs/:todo_kind">
                <div className={classes.root}>
                  {working_set.map((todoDoc, posInSet) => {
                    return (
                      <TodoCard
                        key={todoDoc._id + '-' + posInSet}
                        todoDoc={todoDoc}
                        posInSet={posInSet}
                        expanded={
                          todoDoc.expanded === undefined || todoDoc.expanded
                        }
                        onLocalUpdate={this.onLocalUpdate}
                      />
                    );
                  })}
                </div>
              </Route>
            </div>
          </main>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.scrollArea}>
              <Route path="/:dfa_landing_kind/idn/:selectedIdn/tabs/:todo_kind">
                <TodoForm
                  selectedCategory={this.state.default_selected_category}
                  title={this.state.title}
                  descTitle={this.state.descTitle}
                  showTestModeMsg={false}
                  categories={
                    this.state.default_categories.length
                      ? this.state.default_categories
                      : lookups.ink.k.TODO_CODES_ENABLED
                  }
                />
              </Route>
            </div>
          </main>
        </Grid>
      </Grid>
    );
  }
}

class _IdnTabTodosMobile extends _IdnTabTodos {
  render() {
    const { classes, lookups, idnInView, todosInView } = this.props;

    if (
      !idnInView.idnDoc ||
      !lookups.ink ||
      null === todosInView.params ||
      todosInView.records === null
    ) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....</h4>
        </div>
      );
    }

    if (todosInView.isFetching) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....fetching</h4>
        </div>
      );
    }

    if (todosInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.patientNm}>....error fetching todos</h4>
        </div>
      );
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={12} className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.scrollArea}>
              <Route path="/:dfa_landing_kind/idn/:selectedIdn/tabs/:todo_kind">
                <TodoForm
                  selectedCategory={this.state.default_selected_category}
                  title={this.state.title}
                  descTitle={this.state.descTitle}
                  showTestModeMsg={false}
                  categories={lookups.ink.k.TODO_CODES_ENABLED}
                />
              </Route>
            </div>
          </main>
        </Grid>
      </Grid>
    );
  }
}

class IdnTabTodosForCFI extends _IdnTabTodos {
  constructor(props) {
    super(props);
    this.state = {
      title: 'CFI Task',
      descTitle: 'Detailed Description of Complaint',
      default_categories: ['cfi'],
      default_selected_category: 'cfi',
      force_filter_by_category: true,
    };
  }
}

_IdnTabTodos.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  idnInView: stateFromStore.idnInView,
  todosInView: stateFromStore.todosInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchTodos_ac,
      locallyUpdateTodoDoc_ac,
      toggleAllCards_ac,
    },
    dispatch
  );

export const IdnTabCfiTodos = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IdnTabTodosForCFI))
);

export const IdnTabTodosMobile = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(_IdnTabTodosMobile))
);

export const IdnTabTodos = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(_IdnTabTodos))
);

/*
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IdnTabTodos))
)

*/
