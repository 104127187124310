import actionTypes from '../../actionTypes'

const initialState = {
  category: 'IVR',
  subcategory: 'MONTHLY',
  subsubcategory: 'ExpiringOxygenCMN'
}

export const notify_view = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.notify_view.category.SET: {
      return {
        ...state,
        category: action.category
      }
    }
    case actionTypes.notify_view.subcategory.SET: {
      return {
        ...state,
        subcategory: action.subcategory
      }
    }
    case actionTypes.notify_view.subsubcategory.SET: {
      return {
        ...state,
        subsubcategory: action.subsubcategory
      }
    }
    default: {
      return state
    }
  }
}
