import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { mergeIdnSetup_ac } from '../actions/idn.ac.js';
import { createIdnShell_ac } from '../actions/idn_shell.ac.js';
import {
  cancel_masterSearch_ac,
  masterSearch_ac,
} from '../actions/masterSearch.ac.js';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    width: '100%',
    overflowY: 'hidden',
  },
  link: {
    color: 'blue',
    border: 'none',
    cursor: 'pointer',
    paddingRight: 4,
    paddingLeft: 4,
    fontSize: 10,
    borderRight: '1px solid #7aa9ff',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    padding: 12,
    paddingLeft: 0,
  },
  patientLbl: {
    fontSize: 12,
    margin: '2px 8px 0 4px',
    fontWeight: 600,
    color: '#00675b',
    padding: 2,
  },
  pageStatement: {
    paddingTop: 8,
    fontSize: 11,
  },
  accountLbl: {
    fontSize: 12,
    padding: '3px',
    margin: '2px 8px 8px 4px',
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#BBDEFB',
    borderBottom: '1px solid #000',
  },
  gridContainer: {
    paddingTop: 0,
    paddingRight: 0,
  },
  grid: {
    paddingRight: 40,
  },
  card: {
    minHeight: 40,
  },
  cardContent: {
    root: {
      padding: 10,
    },
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#fff' },
  owner: { color: '#7f8486' },
  tm: { backgroundColor: '#689F38' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  exactHdmsAcct: {},
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  listItem: {
    paddingLeft: 2,
  },
  listItemInvalid: {
    paddingLeft: 2,
    backgroundColor: 'lightgray',
  },
  listItemText: {
    fontSize: 12,
    color: 'blue',
  },
  docEntry: {
    cursor: 'pointer',
    marginLeft: 12,
    padding: 8,
  },
  title: {
    display: 'flex',
    alignItems: 'left',
  },
  titleMeta: {
    fontSize: 10,
    color: '#7f8486',
    marginLeft: '4px',
  },
});

const mobile_view_supports = [
  'doclisting',
  'orders',
  'ops_form',
  'account_info',
  'todos',
];

class IdnResultEntry extends React.Component {
  constructor(props) {
    super(props);
    this.gotoIdn = this.gotoIdn.bind(this);

    let idn_str = props.entryRecord.nm.replace(/ /g, '_').replace(/\//g, '%2F');

    this.basePath = `/dfa/idn/${idn_str}/tabs/`;
    this.mobileBasePath = `/mobile/idn/${idn_str}/tabs/`;
    this.mobileView = window.screen.width < 768;
  }
  gotoIdn = (tab) => {
    return () => {
      if (this.props.entryRecord?.unlinked) return;

      const hasMobileView =
        this.mobileView && mobile_view_supports.includes(tab);
      this.props.changePage(
        (hasMobileView ? this.mobileBasePath : this.basePath) + tab
      );
      return;
    };
  };

  gotoV = (vId) => {
    return () => {
      if (this.props.entryRecord?.unlinked) return;
      this.props.changePage(`/df/workunits/${vId.replace(/^V/, '')}`);
      return;
    };
  };
  render() {
    const { classes, entryRecord } = this.props;
    const listItem = {
      button: classes.listItem,
      root: classes.listItem,
    };
    const listItemText = { primary: classes.listItemText };
    return (
      <ListItem divider classes={listItem} button>
        <ListItemText
          classes={listItemText}
          primary={
            <div>
              <div className={classes.title}>
                <strong onClick={this.props.onClick.bind(this, entryRecord.nm)}>
                  {entryRecord.nm}{' '}
                  {entryRecord.meta && (
                    <span className={classes.titleMeta}>
                      {entryRecord.meta}
                    </span>
                  )}
                  {entryRecord.match_on && (
                    <span className={classes.titleMeta}>
                      {entryRecord.match_on.join(', ')}
                    </span>
                  )}
                </strong>
              </div>
              <div>
                {entryRecord.labels.map((lbl, i) => (
                  <Chip
                    key={i + lbl.display}
                    className={classNames(
                      classes.chip,
                      lbl.exact ? classes.exactHdmsAcct : classes.hdmsAcct
                    )}
                    onClick={
                      lbl.kind === 'vid'
                        ? this.gotoV(lbl.display)
                        : this.gotoIdn('account_info')
                    }
                    label={lbl.display + (lbl.exact ? '*' : '')}
                    color={lbl.exact ? 'secondary' : 'primary'}
                  />
                ))}
              </div>
              {entryRecord.unlinked !== true && (
                <div>
                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('workunits')}>
                    Work V1
                  </button>

                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('V2_workunits')}>
                    Work V2
                  </button>

                  {_.get(entryRecord, 'labels', []).length > 0 && (
                    <button
                      className={classes.link}
                      onClick={this.gotoIdn('orders')}>
                      Orders
                    </button>
                  )}
                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('doclisting')}>
                    Docs
                  </button>
                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('doc_submissions')}>
                    Submit
                  </button>
                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('doc_requests')}>
                    MR Req
                  </button>
                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('ops_form')}>
                    Ops
                  </button>
                  {_.get(entryRecord, 'labels', []).length > 0 && (
                    <>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn('cmns')}>
                        {' '}
                        CMNS{' '}
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn('pas')}>
                        {' '}
                        PAS
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn('appeal_requests')}>
                        {' '}
                        Appeals
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn('adjustments')}>
                        {' '}
                        ADJ
                      </button>
                      <button
                        className={classes.link}
                        onClick={this.gotoIdn('letters')}>
                        {' '}
                        Letters
                      </button>
                    </>
                  )}
                  <button
                    className={classes.link}
                    onClick={this.gotoIdn('todos')}>
                    Tasks
                  </button>
                </div>
              )}
            </div>
          }
        />
      </ListItem>
    );
  }
}

class IdnReplacementtEntry extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.entryRecord.labels.length === 0) return;
    let params = {
      good_idn: this.props.entryRecord,
    };
    this.props.mergeIdnSetup_ac(params);
  }

  render() {
    const { classes, entryRecord } = this.props;
    const listItem = {
      button: classes.listItem,
      root:
        entryRecord.labels.length === 0
          ? classes.listItemInvalid
          : classes.listItem,
    };
    const listItemText = { primary: classes.listItemText };
    return (
      <ListItem divider classes={listItem} onClick={this.onClick} button>
        <ListItemText
          classes={listItemText}
          primary={
            <div>
              <strong>Merge with {entryRecord.nm}</strong>
              <div>
                {entryRecord.labels.length === 0 && (
                  <span>No HDMS accounts linked - not valid for merge</span>
                )}
                {entryRecord.labels.map((lbl, i) => (
                  <Chip
                    key={i + lbl.display}
                    className={classNames(
                      classes.chip,
                      lbl.exact ? classes.exactHdmsAcct : classes.hdmsAcct
                    )}
                    label={lbl.display + (lbl.exact ? '*' : '')}
                    color={lbl.exact ? 'secondary' : 'primary'}
                  />
                ))}
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }
}

class DtWosEntry extends React.Component {
  render() {
    const { classes, entryRecord } = this.props;
    const listItem = {
      button: classes.listItem,
      root: classes.listItem,
    };
    const listItemText = { primary: classes.listItemText };
    return (
      <ListItem
        divider
        classes={listItem}
        onClick={this.props.onClick(entryRecord)}
        button>
        <ListItemText
          classes={listItemText}
          primary={entryRecord.wos_id}
          secondary={
            entryRecord.idns.length
              ? entryRecord.idns.join(',')
              : 'No Docflow Patient for Acct:' + entryRecord.account
          }
        />
      </ListItem>
    );
  }
}

class UserEntry extends React.Component {
  render() {
    const { classes, entryRecord } = this.props;
    const listItem = {
      button: classes.listItem,
      root: classes.listItem,
    };
    const listItemText = { primary: classes.listItemText };

    const onEntryClick = (e, linkParams = {}) => {
      let toGo;

      switch (linkParams.kind) {
        case 'rep':
          const repsPath = `/admin/idx/reps/${linkParams.id}`;
          toGo = repsPath;
          break;
        case 'oc':
          const ocPath = `/admin/idx/oc/${linkParams.id}`;
          toGo = ocPath;
          break;
        default:
          break;
      }

      if (!toGo) return;

      if (e.shiftKey && typeof window !== 'undefined') {
        const path = window.location.origin + toGo;

        return window.open(path, '_blank', 'noreferrer');
      }

      this.props.redirect(toGo);
    };

    return (
      <ListItem
        divider
        classes={listItem}
        onClick={(e) => {
          onEntryClick(e, entryRecord.linkParams);
        }}
        button>
        <ListItemText
          classes={listItemText}
          primary={
            <div>
              <div className={classes.title}>
                <strong
                  onClick={() => {
                    onEntryClick(entryRecord.linkParams);
                  }}>
                  {entryRecord.nm}{' '}
                </strong>
              </div>
              <div>
                {entryRecord.labels.map((lbl, i) => (
                  <Chip
                    key={i + lbl.display}
                    className={classNames(classes.chip)}
                    label={lbl.display + (lbl.exact ? '*' : '')}
                    color={lbl.exact ? 'secondary' : 'primary'}
                  />
                ))}
              </div>
            </div>
          }
        />
      </ListItem>
    );
  }
}

class MasterSearchResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageJobs: [],
    };
    this.toCreateShell = this.toCreateShell.bind(this); //from existing HDMS account
    this.toCreateEmptyShell = this.toCreateEmptyShell.bind(this); //without existing HDMS account
  }
  componentDidMount() {
    return this.checkSituation();
  }

  componentDidUpdate(prevProps) {
    const { idnShellInView: prev_idnShellInView } = prevProps;
    const { idnShellInView } = this.props;

    if (prev_idnShellInView.idn_shell === null && idnShellInView.idn_shell) {
      console.log('..idn shell created');
      this.props.redirectToAccountDocs(
        '/dfa/idn/' +
          idnShellInView.idn_shell._id.replace(/ /g, '_') +
          '/tabs/doc_submissions'
      );
      return;
    }

    return;
  }

  checkSituation() {
    return;
  }

  searchResultSelected(idn) {
    this.props.redirectToAccountDocs(
      '/dfa/idn/' + idn.replace(/ /g, '_') + '/tabs/doclisting'
    );
  }

  toCreateShell() {
    this.props.changePage(
      '/idn_create/' + this.props.masterSearch.in_hdms_only + '-1'
    );
    this.props.cancel_masterSearch_ac();
  }

  toCreateEmptyShell() {
    this.props.createIdnShell_ac(this.props.masterSearch.potentialNewIDN);
  }

  dtWosSelected = (entryRecord) => () => {
    if (entryRecord.idns.length) {
      this.props.redirectToAccountDocs(
        '/dfa/idn/' +
          entryRecord.idns[0].replace(/ /g, '_') +
          '/tabs/doclisting'
      );
    }
    this.props.cancel_masterSearch_ac();
  };

  render() {
    const {
      classes,
      masterSearch,
      idnInView,
      idnShellSearch,
      idnShellInView,
      me,
    } = this.props;

    if (!masterSearch.qualified) {
      if (idnShellSearch) {
        return (
          <div className={classes.root}>
            <div className={classes.pagination}>
              <Typography
                className={classes.pageStatement}
                color="textSecondary">
                Select First Name, Last Name and DOB to run search "...
                {masterSearch.searchStr}"
              </Typography>
            </div>
          </div>
        );
      }
      return (
        <div className={classes.root}>
          <div className={classes.pagination}>
            <div className={classes.pageStatement} color="textSecondary">
              Min. 3 characters to run search "...{masterSearch.searchStr}"
              <br />
              If searching with name AND birthdate, always put name parts first.
              <br />
              Examples:
              <br />
              <ul>
                <li>JOHN SMITH 1-5-2020</li>
                <li>
                  SMITH, JOH <i>(partial name)</i>
                </li>
                <li>
                  SMITH, JOHN 1-5 <i>(partial dob)</i>
                </li>
                <li>
                  SMITH JOHN<i>(no comma)</i>
                </li>
                <li>
                  SMITH, J 1-5-2020 <i>(partial name with dob)</i>
                </li>
                <li>
                  1-5-2020 <i>(dob only)</i>
                </li>
                <li>
                  888-123-1234<i>(by phone)</i>
                </li>
                <li>
                  john@somemail.com <i>(by email)</i>
                </li>
                <li>
                  - 123 Main, Downey <i>(by address)</i>
                </li>
              </ul>
              If results return multiple pages, add :pagenum to select other
              pages
              <br />
              <ul>
                <li>
                  JOHN SMITH:3 <i>(will select page 3)</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }

    if (masterSearch.isFetching) {
      return (
        <div className={classes.root}>
          <div className={classes.pagination}>
            <Typography className={classes.pageStatement} color="textSecondary">
              Search in progress for "{masterSearch.searchStr}"
            </Typography>
          </div>
        </div>
      );
    }

    const hasAltRes = Boolean(masterSearch.altRes);

    // Distribute height based on results
    const primaryHeight = hasAltRes
      ? Math.min(50, 10 + (masterSearch.results?.length || 0) * 10)
      : 100;

    // if hasAltRes, then show both results (half height each)
    const renderSearchRes = (masterSearch, hasAltRes, height = 100) => {
      return (
        <div
          className={classes.root}
          style={{
            whiteSpace: 'normal',
            ...(hasAltRes ? { height: `${height}%` } : {}),
          }}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
              <div className={classes.pagination}>
                {masterSearch.msg && (
                  <div style={{ color: 'blue', fontSize: 12 }}>
                    {masterSearch.msg}
                  </div>
                )}

                {masterSearch.warning && (
                  <div style={{ color: 'red', fontSize: 12 }}>
                    {masterSearch.warning}
                  </div>
                )}

                {masterSearch.in_hdms_only && (
                  <div style={{ color: 'blue', fontSize: 12 }}>
                    <Button onClick={this.toCreateShell}>
                      Create Shell in DocFlow for A{masterSearch.in_hdms_only}
                    </Button>
                  </div>
                )}

                {/* show button to create shell when shell is not available */}
                {idnShellSearch && masterSearch.results.length === 0 && (
                  <div style={{ color: 'blue', fontSize: 12 }}>
                    Open the Master Search panel to create the shell for with
                    this name and DOB.
                  </div>
                )}

                {masterSearch.results.length === 0 && !idnShellSearch && (
                  <div style={{ color: 'blue', fontSize: 12 }}>
                    Try searching by name if searching by account number did not
                    return any matches. <br />
                  </div>
                )}
                {masterSearch.results.length === 0 &&
                  !idnShellSearch &&
                  me.user.roles.includes('allow-create-shell') && (
                    <div style={{ marginTop: 20, color: 'blue', fontSize: 12 }}>
                      If you are trying to create an empty shell, your search
                      format must exactly be in{' '}
                      <strong>LAST, FIRST M-D-YYYY</strong> format. No leading
                      zeroes before day or month.
                    </div>
                  )}

                {idnShellSearch !== true &&
                  masterSearch.results.length === 0 &&
                  idnShellInView.isSubmitting === false && //nothing to do with idnShellSearch
                  masterSearch.potentialNewIDN && (
                    <div
                      style={{
                        color: 'blue',
                        fontSize: 12,
                        marginTop: 30,
                        marginBottom: 50,
                        paddingTop: 20,
                        borderTop: '1px solid #000',
                      }}>
                      Create New Shell as{' '}
                      <strong>{masterSearch.potentialNewIDN}</strong>
                      <br />
                      {idnShellInView.isSubmitting === false ? (
                        <Button
                          onClick={this.toCreateEmptyShell}
                          variant="contained"
                          color="primary">
                          Create Shell in DocFlow
                        </Button>
                      ) : (
                        <span>.....creating shell</span>
                      )}
                    </div>
                  )}
              </div>
            </Grid>
          </Grid>
          <div className={classes.scrollArea}>
            <Grid container className={classes.gridContainer}>
              <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
                <List component="nav" className={classes.list}>
                  {masterSearch.results.map((d, i) => {
                    switch (masterSearch.kind) {
                      case 'idn':
                        return idnInView.mode === null ? (
                          <IdnResultEntry
                            changePage={this.props.changePage}
                            classes={classes}
                            entryRecord={d}
                            onClick={
                              d.unlinked
                                ? () => {}
                                : this.searchResultSelected.bind(this, d.nm)
                            }
                            key={i}
                          />
                        ) : (
                          <IdnReplacementtEntry
                            classes={classes}
                            entryRecord={d}
                            mergeIdnSetup_ac={this.props.mergeIdnSetup_ac}
                            onClick={this.searchResultSelected.bind(this, d.nm)}
                            key={i}
                          />
                        );
                      case 'dt_wos':
                        return (
                          <DtWosEntry
                            classes={classes}
                            entryRecord={d}
                            onClick={this.dtWosSelected}
                            key={i}
                          />
                        );
                      case 'user':
                        return (
                          <UserEntry
                            classes={classes}
                            redirect={this.props.redirectToAccountDocs}
                            entryRecord={d}
                            key={i}
                          />
                        );
                      default:
                        return <div />;
                    }
                  })}
                </List>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    };

    return (
      <>
        {renderSearchRes(masterSearch, hasAltRes, primaryHeight)}
        {hasAltRes &&
          renderSearchRes(masterSearch.altRes, hasAltRes, 100 - primaryHeight)}
      </>
    );
  }
}

MasterSearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch,
  idnInView: stateFromStore.idnInView,
  idnShellInView: stateFromStore.idnShellInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createIdnShell_ac,
      mergeIdnSetup_ac,
      masterSearch_ac,
      cancel_masterSearch_ac,
      changePage: (path) => push(path),
      redirectToAccountDocs: (path) => {
        return push(path);
      },
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MasterSearchResults));
