import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import { green, red } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { PaginationWithNums } from '../PaginationNew'
import Loader from '../Shared/Loader'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  padding8: {
    padding: 8
  },
  scrollableTable: {
    height: '100%',
    overflowY: 'auto'
  },
  rowCell: {
    borderBottom: 'unset',
    padding: '8px'
  },
  collapsableRowContent: {
    backgroundColor: 'rgb(0, 0, 0, 0.04)'
  },
  collapsableCellSpacing: {
    padding: '4px',
    margin: '4px 0px'
  },
  collapsableCellScroll: {
    maxHeight: '200px',
    overflowY: 'auto'
  },
  dashedBorderBottom: {
    borderBottom: '2px dashed #c5c5c5'
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    color: theme.palette.primary.main,
    transition: 'transform 0.3s'
  },
  expandedIcon: {
    transform: 'rotate(180deg)',
    color: theme.palette.secondary.main,
    transition: 'transform 0.3s'
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3
  },
  confirmedChip: {
    backgroundColor: green['300']
  },
  notConfirmedChip: {
    backgroundColor: theme.palette.secondary['300']
  },
  cancelledChip: {
    color: 'white',
    backgroundColor: red['400']
  },
  itemContainer: {
    marginBottom: 8,
    padding: 4,
    borderTop: '1px solid #c5c5c5'
  }
})

const stateToChipMap = {
  CONFIRMED: 'confirmedChip',
  'NOT CONFIRMED': 'notConfirmedChip',
  CANCELLED: 'cancelledChip'
}

const ExpandableRow = props => {
  const { record, classes, isEditing } = props

  const [isExpanded, setIsExpanded] = useState(false)
  const [reSortedConfLogs, setResortedConfLogs] = useState([])

  useEffect(() => {
    const reSortedConfLogs =
      !record.confirmation_log || !record.confirmation_log.length
        ? []
        : [...record.confirmation_log].sort((logA, logB) => {
            let aTS = logA.ts ? new Date(logA.ts) : ''
            let bTS = logB.ts ? new Date(logB.ts) : ''
            if (aTS < bTS) return 1
            return -1
          })

    setResortedConfLogs([...reSortedConfLogs.splice(1)])
  }, [record])

  return (
    <>
      <TableRow style={{ backgroundColor: isEditing ? 'yellow' : '' }}>
        <TableCell
          width="1%"
          style={{ padding: 0 }}
          className={classes.rowCell}>
          <IconButton
            className={isExpanded ? classes.expandedIcon : classes.expandIcon}
            onClick={() => setIsExpanded(!isExpanded)}
            disabled={record.confirmation_log.length < 2}>
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
        <TableCell width="20%" className={classes.rowCell}>
          <Typography variant="body2" inline>
            <strong>W{record._id}</strong>
          </Typography>{' '}
          <Typography variant="body2" inline>
            A{record.account}
          </Typography>
          {record.confirmation_entry && (
            <Typography variant="caption" color="textPrimary">
              {moment(record.confirmation_entry.ts).format(
                'MM-DD-YYYY hh:mm a'
              )}
            </Typography>
          )}
          <Button
            onClick={() => setIsExpanded(!isExpanded)}
            style={{ padding: '3px 4px' }}
            disabled={record.confirmation_log.length < 2}>
            <Typography
              variant="caption"
              color={!isExpanded ? 'primary' : 'secondary'}>
              {record.confirmation_log.length < 2
                ? 'No History'
                : !isExpanded
                ? 'Show History'
                : 'Hide History'}
            </Typography>
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => props.openEditForm(record)}
            disabled={isEditing}>
            {isEditing ? (
              'Editing'
            ) : (
              <>
                <EditIcon fontSize="inherit" />
                &ensp;Edit
              </>
            )}
          </Button>
        </TableCell>

        <TableCell className={classes.rowCell}>
          {record.confirmation_entry && (
            <>
              <Typography color="primary" variant="body2">
                <strong>{record.confirmation_entry.u_nm}</strong>
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {record.confirmation_entry.u_id}
              </Typography>
            </>
          )}
        </TableCell>

        <TableCell width="15%" className={classes.rowCell}>
          {record.confirmation_entry && (
            <Typography variant="body2" color="textPrimary">
              <strong>{record.confirmation_entry.pcr.nm}</strong>
            </Typography>
          )}
        </TableCell>

        <TableCell width="24%" className={classes.rowCell}>
          {record.confirmation_entry && record.confirmation_entry.state ? (
            <Chip
              className={
                classes.chip +
                ' ' +
                classes[stateToChipMap[record.confirmation_entry.state]]
              }
              label={record.confirmation_entry.state}
            />
          ) : (
            ''
          )}

          {record.confirmation_entry &&
            record.confirmation_entry.issue_lbls.map((issue_lbl, issueIdx) => (
              <Typography
                key={issueIdx}
                gutterBottom
                className={classes.dashedBorderBottom}>
                {issue_lbl}
              </Typography>
            ))}
        </TableCell>

        <TableCell width="24%" className={classes.rowCell}>
          {record.confirmation_entry && (
            <Typography variant="body2" color="textPrimary">
              {record.confirmation_entry.comment}
            </Typography>
          )}
        </TableCell>
      </TableRow>

      {reSortedConfLogs.length > 0 && (
        <TableRow style={{ height: 0 }}>
          <TableCell width="1%" style={{ padding: 0 }} />
          <TableCell
            colSpan={5}
            style={{ padding: 0 }}
            width="98%"
            className={classes.collapsableRowContent}>
            <Collapse in={isExpanded}>
              <div className={classes.collapsableCellScroll}>
                <Table padding="dense">
                  <TableBody>
                    {reSortedConfLogs.map((log, logIdx) => (
                      <TableRow key={logIdx} className={classes.itemContainer}>
                        <TableCell width="20.20%" className={classes.rowCell}>
                          <Typography variant="caption" color="textPrimary">
                            {moment(log.ts).format('MM-DD-YYYY hh:mm a')}
                          </Typography>
                        </TableCell>
                        <TableCell width="16.16%%" className={classes.rowCell}>
                          <Typography color="primary" variant="body2">
                            <strong>{log.u_nm}</strong>
                          </Typography>
                          <Typography color="textSecondary" variant="body2">
                            {log.u_id}
                          </Typography>
                        </TableCell>
                        <TableCell width="15.15%" className={classes.rowCell}>
                          <Typography variant="body2" color="textPrimary">
                            <strong>{log.pcr.nm}</strong>
                          </Typography>
                        </TableCell>
                        <TableCell width="24.24%" className={classes.rowCell}>
                          {log.state ? (
                            <Chip
                              className={
                                classes.chip +
                                ' ' +
                                classes[stateToChipMap[log.state]]
                              }
                              label={log.state}
                            />
                          ) : (
                            ''
                          )}

                          {log.issue_lbls.map((issue_lbl, issueIdx) => (
                            <Typography
                              key={issueIdx}
                              gutterBottom
                              className={classes.dashedBorderBottom}>
                              {issue_lbl}
                            </Typography>
                          ))}
                        </TableCell>
                        <TableCell width="24.24%" className={classes.rowCell}>
                          <Typography variant="body2" color="textPrimary">
                            {log.comment}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const ConfirmationLogTable = props => {
  const {
    classes,
    confirmationLog,
    selectedRecordForEdit,
    hasSingleEntry
  } = props
  const { records, error, isFetching } = confirmationLog
  const pagination = confirmationLog.pagination || { currentPageNum: 0 }
  const [selectedPageNum, setPageNum] = useState(_.get(pagination, 'currentPageNum', 0))

  useEffect(() => {
    setPageNum(pagination.currentPageNum)
  }, [pagination.currentPageNum])

  const handlePageChange = newPage => {
    let targetPage = parseInt(newPage, 10)

    setPageNum(targetPage - 1)

    return props.fetch(targetPage - 1)
  }

  const getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  if (isFetching) {
    return <Loader classes={{ message: classes.message }} />
  }

  if (error) {
    return (
      <Typography color="error" className={classes.message}>
        Error fetchnig report!
      </Typography>
    )
  }

  if (!(records && pagination)) {
    return (
      <div className={classes.message}>...</div>
    )
  }

  return (
    <>
      {!hasSingleEntry && (
        <Grid container item className={classes.padding8}>
          <Grid item xs={6}>
            <Typography color="textSecondary">
              {getPageStatement(pagination)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {pagination.totalPagesAvailable > 1 && (
              <PaginationWithNums
                currentPageNum={selectedPageNum}
                totalPagesAvailable={pagination.totalPagesAvailable}
                onPageChange={handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Grid
        container
        item
        className={[classes.padding8, classes.scrollableTable].join(' ')}
        style={{ minHeight: 200 }}
      >
        <Grid item xs={12} className={classes.scrollableTable}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell width="1%" />
                <TableCell className={classes.padding8} width="20%">
                  Work Order
                </TableCell>
                <TableCell className={classes.padding8}>User</TableCell>
                <TableCell className={classes.padding8} width="15%">
                  PCR
                </TableCell>
                <TableCell className={classes.padding8} width="24%">
                  Status / Issues
                </TableCell>
                <TableCell className={classes.padding8} width="24%">
                  Comment
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.length ? (
                records.map((record, idx) => (
                  <ExpandableRow
                    key={idx}
                    record={record}
                    classes={classes}
                    openEditForm={record => props.openEditForm(record, true)}
                    isEditing={
                      (selectedRecordForEdit &&
                        selectedRecordForEdit._id === record._id) ||
                      hasSingleEntry
                    }
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={6}
                    className={classes.collapsableRowContent}>
                    <Typography variant="subtitle1" color="textSecondary">
                      No Records Found!
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(ConfirmationLogTable)
