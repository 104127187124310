import React from 'react';
import LogItem from './LogItem';
import Loader from '../../Shared/Loader';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    padding: 12,
  },
});

const LogList = (props) => {
  const { logs, isLoading, classes } = props;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.container}>
      <Typography component="div" variant="body2" gutterBottom>
        <strong>Logs:</strong>
      </Typography>

      {logs?.length
        ? logs.map((log, idx) => (
            <Grid item key={idx} xs={12}>
              <LogItem log={log} />
            </Grid>
          ))
        : 'No Logs'}
    </div>
  );
};

export default withStyles(styles)(LogList);
