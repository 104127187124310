import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
});

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.nm;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  if (suggestion.disabled) {
    return (
      <div style={{ color: 'red' }}>
        <div>{suggestion.nm}</div>
        <div style={{ fontSize: '.88em' }}>Account Disabled</div>
        <div style={{ fontSize: '.88em' }}>{suggestion.department}</div>
      </div>
    );
  }
  return (
    <div>
      <div>{suggestion.nm}</div>
      <div style={{ fontSize: '.88em' }}>{suggestion.department}</div>
    </div>
  );
};

class UserSearch extends React.Component {
  constructor() {
    super();

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: '',
      suggestions: [],
    };
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.onSuggestionsFetchRequested = _.debounce(
      this.onSuggestionsFetchRequested.bind(this),
      500
    );
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionSelected(
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    if (this.props.onSelect) {
      this.props.onSelect(suggestion);
    }
    this.setState({ value: '' });
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    if (value.trim().length < 2) return;
    let searchStr = value.trim();
    let postData = {
      searchStr,
      includeDisabled: this.props.includeDisabled || false,
    };

    return window.sch
      .post(`/api/users/search`, postData)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }
        this.setState({
          suggestions: result.results,
        });
      })
      .catch((error) => {
        this.setState({
          suggestions: [],
        });
      });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderInputComponent(inputProps) {
    return (
      <TextField
        {...inputProps}
        label={this.props.placeholder || 'Search for Team Member'}
      />
    );
  }

  render() {
    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: '',
      value,
      onChange: this.onChange,
    };

    // Finally, render it!
    return (
      <Autosuggest
        theme={{
          input: { width: '100%', fontSize: 12, padding: 3 },
          suggestionsContainer: {
            backgroundColor: 'rgba(218, 226, 226, 0.54)',
          },
          suggestionsList: { paddingLeft: 16, marginTop: 1 },
          suggestion: {
            listStyleType: 'none',
            padding: '4px 4px',
            fontSize: 12,
            marginBottom: 2,
            cursor: 'pointer',
          },
        }}
        suggestions={suggestions}
        focusInputOnSuggestionClick={false}
        highlightFirstSuggestion
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

UserSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch, propsOfTodoForm) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UserSearch));
