import React from 'react';
import Moment from 'react-moment';

import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { Clear as ClearIcon, Done as DoneIcon } from '@material-ui/icons';

const NotesView = ({ readonly = false, notes = [], onAdd, isAddingNote }) => {
  const [note, setNote] = React.useState('');

  const handleAddClick = () => {
    onAdd(note, () => {
      setNote('');
    });
  };

  const sortedNotes = React.useMemo(
    () =>
      notes.sort((a, b) => {
        return new Date(b.ts) - new Date(a.ts);
      }),
    [notes]
  );

  return readonly && sortedNotes?.length === 0 ? null : (
    <div style={{ margin: '16px 0px' }}>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: '12px' }}>
        {'Admin Notes: '}
      </Typography>
      <Grid container spacing={8} style={{ marginBottom: '12px' }}>
        <Grid item xs={9}>
          {!readonly && (
            <TextField
              margin="dense"
              placeholder="Jot down a new note"
              helperText="Minimum 3 characters required."
              multiline
              variant="standard"
              size="small"
              fullWidth
              disabled={isAddingNote}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      style={{ padding: 8 }}
                      onClick={handleAddClick}
                      disabled={isAddingNote || note.length < 3}>
                      <DoneIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      style={{ padding: 8 }}
                      disabled={isAddingNote || note.length === 0}
                      onClick={() => setNote('')}>
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </>
                ),
              }}
            />
          )}
          <div style={{ marginTop: '8px' }}>
            {' '}
            {sortedNotes.map((note, nIdx) => {
              let textParts = note.txt.split(/\n/g);
              textParts = textParts.map((p, pi) => {
                if (pi === textParts.length - 1) return p;
                return (
                  <span key={pi}>
                    {p}
                    <br />
                  </span>
                );
              });
              return (
                <Typography
                  key={nIdx}
                  paragraph
                  style={{
                    color: '#4e6c7a',
                    borderRadius: 4,
                    marginBottom: 4,
                    padding: 4,
                    backgroundColor: '#ccdbe2',
                  }}
                  color="textSecondary">
                  {textParts}
                  <i
                    style={{
                      marginLeft: 4,
                      color: '#244e91',
                      fontSize: '.98em',
                    }}>
                    {' '}
                    - {note.u_nm}{' '}
                    <Moment format="M-DD-YY h:mma">{note.ts}</Moment>
                  </i>
                </Typography>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotesView;
