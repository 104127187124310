/* eslint no-unused-vars: "off" */
import { Grid, Tab, Tabs, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { addMacroItemsToCart_ac } from '../../actions/shopping_cart.ac.js'
import { open_snack_ac } from '../../actions/snack.ac.js'
import AccountCMNs from '../Dfa/AccountCMNs.js'
import { AccountPAs } from '../Dfa/AccountPAs.js'
import CatalogLanding from './CatalogLanding'
import SummaryTabBottomComponent from './ProductCategory/SummaryTabBottomComponent'
import SummaryTabTopComponent from './ProductCategory/SummaryTabTopComponent'
import { ShoppingCart } from './ShoppingCart/ShoppingCart'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  tableRow: {
    fontSize: 12
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  tabLabelContainer: {
    padding: '3px 12px 3px 12px'
  },
  tabRoot: {
    minWidth: 16,
    minHeight: 24
  },
  tabsRoot: {
    minHeight: 24
  },
  fullHeight: {
    height: '100%'
  }
})

class PlansOfService extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 'catalog'
    }
  }

  addAllItemToCart(items = []) {
    if (!items.length) return
    const vendorItems = []
    items.forEach(item => {
      let preferredItem = item.vendors.find(vendor => vendor.preferred)
      if (preferredItem) {
        preferredItem.prod_code_v_id = `${item.prod_code} - ${
          preferredItem.v_id
        }`
        preferredItem.uomcount = item.macro_qty
        preferredItem.isUsed = false
        preferredItem.state = 'new'
        vendorItems.push(preferredItem)
      }
    })
    return this.props.addMacroItemsToCart_ac(vendorItems)
  }

  onTabSelect = (e, _selectedTab) => {
    return this.setState({
      activeTab: _selectedTab
    })
  }

  render() {
    const { classes, me, catalog } = this.props
    const { activeTab } = this.state
    let addAllToCartBtn = ''
    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot
    }
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer
    }

    addAllToCartBtn = catalog.lastParams && catalog.lastParams.is_macro && (
      <Button
        onClick={() => this.addAllItemToCart(catalog.pkg.items)}
        variant="outlined"
        size="small"
        color="secondary">
        Add Macro Items to Cart
      </Button>
    )

    return (
      <main className={classes.content}>
        <Grid container direction="row" spacing={8}>
          <Grid item xs={8}>
            {process.env.REACT_APP_IS_DEV === 'yes' && (
              <Tabs
                classes={tabsClass}
                value={activeTab}
                onChange={this.onTabSelect}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="off">
                <Tab value="catalog" label="Catalog" classes={tabClass} />
                <Tab
                  value="cmns_and_pas"
                  label="CMNS and PAS"
                  classes={tabClass}
                />
                <Tab value="delivery" label="Delivery" classes={tabClass} />
                <Tab value="summary" label="Summary" classes={tabClass} />
              </Tabs>
            )}
          </Grid>
          <Grid item xs={2}>
            {addAllToCartBtn}
            <Typography className={classes.pageStatement} color="textSecondary">
              Shopping Cart
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={8}
          className={classes.scrollArea}>
          <Grid
            item
            xs={8}
            className={[classes.tableRow, classes.fullHeight].join(' ')}>
            {activeTab === 'catalog' && <CatalogLanding />}
            {activeTab === 'summary' && (
              <div className={classes.scrollArea}>
                <SummaryTabTopComponent />
                <SummaryTabBottomComponent />
              </div>
            )}
            {activeTab === 'cmns_and_pas' && (
              <>
                <AccountPAs
                  open_snack_ac={this.props.open_snack_ac}
                  hidePopulateBtn={true}
                />
                <AccountCMNs />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            className={[classes.tableRow, classes.fullHeight].join(' ')}>
            <ShoppingCart />
          </Grid>
        </Grid>
      </main>
    )
  }
}

PlansOfService.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  catalog: stateFromStore.catalog,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      open_snack_ac,
      addMacroItemsToCart_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PlansOfService))
)
