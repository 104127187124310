import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchHourlyWos_ac,
  fetchHourlyWosList_ac,
} from '../../actions/hourly_wos.ac';
import { fetchHourlyWosGroup_ac } from '../../actions/hourly_wos_group.ac';
import { fetchHourlyWosSummary_ac } from '../../actions/hourly_wos_summary.ac';
import { fetchSingleWo_ac } from '../../actions/single_wo.ac.js';
import IdnWoEntry from '../Dfa/IdnWoEntry';
import { PaginationWithNums } from '../PaginationNew';
import {
  HourlyWosFilter,
  HourlyWosList,
  Loading,
  TextInfo,
  WosListSummary,
} from './components';
import { Accordion } from '../common';

const todayDate = moment().format('YYYY-MM-DD');

const defaultFilters = {
  startDate: todayDate,
  endDate: todayDate,
  page: 0,
  size: 24,
};

const summaryFilters = ['startDate', 'endDate'];

class HourlyWosListView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: defaultFilters,
      activeWosId: null,
      filterChanged: false,
      activeWosGroup: null,
      summaryFilterChanged: false,
      wosFilters: {},
      wos_id: null,
      wo_List: [],
    };
  }

  componentDidMount() {
    Promise.all([
      this.fetchHourlyWosList(defaultFilters),
      this.fetchWosListSummary(defaultFilters),
      this.fetchHourlyWorkorders(defaultFilters, this.state.wosFilters),
    ]);
  }

  componentDidUpdate(prevProps, prevState) {
    const { hourlyWosGroup } = this.props;
    const { wos_id, wosFilters } = this.state;

    // Check if wosFilters have changed
    if (wosFilters !== prevState.wosFilters) {
      if (wos_id) {
        this.fetchHourlyWosGroup(wos_id);
      } else {
        this.fetchHourlyWorkorders(this.state.filters, wosFilters);
      }
    }

    // Check if hourlyWosGroup has changed
    if (hourlyWosGroup !== prevProps.hourlyWosGroup) {
      const wo_List = hourlyWosGroup.data?.wosRecordMap
        ? Object.values(hourlyWosGroup.data.wosRecordMap)
        : [];

      this.setState({ wo_List });
    }
  }

  fetchHourlyWorkorders = (filters = this.state.filters, wosFilters) => {
    const { hourlyWos, fetchHourlyWosList_ac } = this.props;
    if (hourlyWos.isFetching) {
      return;
    }

    return fetchHourlyWosList_ac(filters, wosFilters)
      .then(() => {
        const { hourlyWos } = this.props;
        const wo_List = hourlyWos.wosList;
        this.setState({ wo_List });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  fetchHourlyWosList = (filters = this.state.filters) => {
    const { hourlyWos, fetchHourlyWos_ac } = this.props;

    if (hourlyWos.isFetching) return;

    return fetchHourlyWos_ac(filters);
  };

  fetchWosListSummary = (filters = this.state.filters) => {
    const { hourlyWosSummary, fetchHourlyWosSummary_ac } = this.props;

    if (hourlyWosSummary.isFetching) return;

    return fetchHourlyWosSummary_ac({
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
  };

  fetchHourlyWosGroup = (id) => {
    const { fetchHourlyWosGroup_ac } = this.props;

    return fetchHourlyWosGroup_ac(id, this.state.wosFilters);
  };

  fetchSingleWo = (id) => {
    const { fetchSingleWo_ac } = this.props;

    return fetchSingleWo_ac(undefined, undefined, id);
  };

  setFilters = (filters, shouldReset = false, otherChanges = {}) => {
    const newState = { filters, ...otherChanges };
    if (shouldReset) {
      newState.activeWosId = null;
      newState.activeWosGroup = null;
      newState.filterChanged = false;
      newState.summaryFilterChanged = false;
    }
    this.setState(newState);
  };

  setWosFilter = (idx, value) => {
    this.setState((prevState) => {
      const prevFilters = prevState.wosFilters[idx] || [];
      const newFilters = prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value];

      return {
        wosFilters: {
          ...prevState.wosFilters,
          [idx]: newFilters,
        },
      };
    });
  };

  handleChange = (event) => {
    this.setFilters(
      {
        ...this.state.filters,
        [event.target.name]: event.target.value,
      },
      false,
      {
        filterChanged: true,
        summaryFilterChanged: summaryFilters.includes(event.target.name)
          ? true
          : this.state.summaryFilterChanged,
      }
    );
  };

  handleReset = () => {
    if (_.isEqual(this.state.filters, defaultFilters)) return;
    this.setFilters(defaultFilters, false, { filterChanged: true });
  };

  handleFilter = async () => {
    await this.fetchHourlyWorkorders(this.state.filters).then(() => {
      this.setState({
        wo_List: this.props.hourlyWos.wosList,
        wos_id: null,
      });
    });

    if (!this.state.filterChanged) return;
    this.setState(
      {
        activeWosGroup: null,
        activeWosId: null,
        filterChanged: false,
        filters: { ...this.state.filters, page: 0 },
      },
      () => {
        this.fetchHourlyWosList();
        if (this.state.summaryFilterChanged) {
          this.fetchWosListSummary();

          this.setState({ summaryFilterChanged: false });
        }
      }
    );
  };

  handleNextPage = () => {
    const { filters } = this.state;
    const newFilters = { ...filters, page: filters.page + 1 };
    this.setFilters(newFilters, true);
    this.fetchHourlyWosList(newFilters);
  };

  handlePreviousPage = () => {
    const { filters } = this.state;
    const newFilters = { ...filters, page: filters.page - 1 };
    this.setFilters(newFilters, true);
    this.fetchHourlyWosList(newFilters);
  };

  onPageChange = (newPage) => {
    const { filters } = this.state;
    const newFilters = { ...filters, page: newPage - 1 };
    this.setFilters(newFilters, true);
    this.fetchHourlyWosList(newFilters);
  };

  onWosPageChange = (newPage) => {
    const filters = {
      ...this.state.filters,
      page: newPage - 1,
    };

    this.fetchHourlyWorkorders(filters);
  };

  handleWosGroupClick = (id) => {
    if (this.props.hourlyWosGroup?.data?._id === id) return;
    this.fetchHourlyWosGroup(id);
  };

  handleWosIdClick = (id) => {
    if (this.props.singleWo?.wo_id === id) return;

    this.fetchSingleWo(id);
  };

  render() {
    const {
      isFetching: isFetchingHourlyWos,
      data: hourlyWosList,
      meta,
    } = this.props.hourlyWos;
    const { data: hourlyWosListSummary } = this.props.hourlyWosSummary;
    const { isFetching: isFetchingHourlyWosGroup } = this.props.hourlyWosGroup;

    const { singleWo } = this.props;
    const workorderData = this.state.wo_List;
    const tableData = workorderData.length ? workorderData : workorderData.data;

    const pageCount = Math.ceil(meta.total / meta.size);

    return (
      <Card style={{ marginTop: 8 }}>
        <CardContent
          style={{
            overflowY: 'scroll',
          }}>
          <Grid container>
            <HourlyWosFilter
              filters={this.state.filters}
              onChange={this.handleChange}
              onReset={this.handleReset}
              onFilter={this.handleFilter}
              isloading={isFetchingHourlyWos}
              infoData={{
                total: hourlyWosListSummary.total,
                ...hourlyWosListSummary.state,
              }}
            />
          </Grid>

          <Accordion
            isExpanded={false}
            includeHeaderClick={true}
            header={
              <>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}>
                  {'Workorder List Sumary:'}
                </span>

                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: 50 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState((prev) => ({
                      ...prev,
                      wosFilters: {},
                    }));
                  }}>
                  Reset selected summary
                </Button>
              </>
            }>
            <WosListSummary
              data={hourlyWosListSummary}
              setWosFilter={this.setWosFilter}
              wosFilters={this.state.wosFilters}
            />
          </Accordion>
          <Grid container style={{ marginTop: '20px' }}>
            <Grid
              item
              xs={2}
              style={{
                borderRight: '2px solid rgba(0, 0, 0, 0.12)',
              }}>
              {isFetchingHourlyWos ? (
                <Loading />
              ) : (
                <Grid
                  item
                  xs={12}
                  style={{
                    maxHeight: '67vh',
                    overflowY: 'auto',
                  }}>
                  <List>
                    {hourlyWosList.length ? (
                      hourlyWosList.map((item, index) => (
                        <ListItem key={index}>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="textPrimary"
                                style={{
                                  cursor: 'pointer',
                                  fontWeight:
                                    this.state.wos_id === item._id
                                      ? 'bold'
                                      : 'normal',
                                }}
                                onClick={() => {
                                  this.setState((prev) => ({
                                    ...prev,
                                    wos_id: item._id,
                                  }));

                                  this.handleWosGroupClick(item._id);
                                }}>
                                {moment(item.ts).format('ddd M-D-YY hh:mma')}
                                <span style={{ color: '#2196f3' }}>
                                  {' '}
                                  {item.ids.length
                                    ? ` (${item.ids.length})`
                                    : ''}
                                </span>
                              </Typography>
                            }
                          />
                          <Divider component="hr" />
                        </ListItem>
                      ))
                    ) : (
                      <TextInfo value="No records to show" type="error" />
                    )}
                  </List>
                </Grid>
              )}
              <Grid item xs={12} style={{ marginTop: '12px' }}>
                <PaginationWithNums
                  style={{ width: 'auto', margin: 'auto' }}
                  currentPageNum={meta.page ? meta.page : 0}
                  totalPagesAvailable={pageCount}
                  onPageChange={this.onPageChange}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                maxHeight: '70vh',
                overflowY: 'auto',
                borderRight: '2px solid rgba(0, 0, 0, 0.12)',
                padding: 12,
              }}>
              <List>
                <HourlyWosList
                  wosRecordMap={tableData}
                  meta={workorderData.meta}
                  onPageChange={this.onWosPageChange}
                  onWosIdClick={this.handleWosIdClick}
                  isLoading={isFetchingHourlyWos || isFetchingHourlyWosGroup}
                />
              </List>
            </Grid>
            <Grid item xs={4} style={{ padding: '14px' }}>
              <div style={{ height: '70vh', overflowY: 'scroll' }}>
                {singleWo.error || !singleWo.wo_id ? (
                  <TextInfo value={'No WOS Record selected'} />
                ) : singleWo.isFetching ? (
                  <Loading />
                ) : singleWo.wo ? (
                  <>
                    <IdnWoEntry
                      posInSet={0}
                      wo={singleWo.wo}
                      expanded={true}
                      readOnly={true}
                    />
                  </>
                ) : (
                  <TextInfo
                    value={`WOS record not found for W${singleWo.wo_id}`}
                    type="error"
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  hourlyWos: state.hourlyWos,
  hourlyWosGroup: state.hourlyWosGroup,
  hourlyWosSummary: state.hourlyWosSummary,
  singleWo: state.singleWo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchHourlyWos_ac,
      fetchHourlyWosList_ac,
      fetchHourlyWosSummary_ac,
      fetchHourlyWosGroup_ac,
      fetchSingleWo_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles({})(HourlyWosListView))
);
