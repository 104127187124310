import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { green, grey, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchFlowReport_ac } from '../../actions/df_workunits_reports.ac';
import { lookups_ac } from '../../actions/me.ac.js';
import { open_snack_ac } from '../../actions/snack.ac';
import DateUtil from '../../util/dateProcessing';
import FilterListing from '../FilterListing';
import Loader from '../Shared/Loader';

const styles = (theme) => ({
  tableScrollable: {
    minHeight: '220px',
    height: 'calc(100% - 96px)',
    overflowY: 'auto',
  },
  titleContainer: {
    padding: '8px',
    backgroundColor: grey['100'],
  },
  tableHeader: {
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      boxShadow: '3px 1px 1px 1px black',
      backgroundColor: 'white',
    },
  },
  message: {
    fontSize: '1em',
    padding: '8px',
  },
  totalRow: {
    backgroundColor: theme.palette.secondary['50'],
  },
  doneColumn: {
    color: green['500'],
  },
  cancelledColumn: {
    color: red['500'],
  },
});

const DEFAULT_FILTERS = {
  groupBy: {
    kind: 'radio',
    lbl: 'Grouping',
    options: [
      { lbl: 'Group By Assignee', code: 'user' },
      { lbl: 'Group By Team', code: 'team' },
    ],
  },
  filterBy: {
    kind: 'radio',
    lbl: 'Limit to (Only when grouping by team)',
    options: [
      { lbl: 'All Teams', code: 'all' },
      { lbl: 'My Teams', code: 'myteams' },
      { lbl: 'MEDNEC Only', code: 'mdnec' },
      { lbl: 'AR LOGGERS Only', code: 'arlogger' },
    ],
  },
  dateCode: {
    kind: 'radio',
    lbl: 'Preset time periods',
    options: [
      { lbl: 'Since Last Close of Biz', code: 'close_of_biz' },
      { lbl: 'Trailing 7 Days', code: 'trailing_days_7' },
      { lbl: 'Trailing 6 Hours', code: 'trailing_hours_6' },
      { lbl: 'Trailing 24 Hours', code: 'trailing_hours_24' },
      { lbl: 'Trailing 1 Hour', code: 'trailing_hours_1' },
      { lbl: 'Custom', code: 'custom' },
    ],
  },
  startDate: {
    lbl: 'From',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mm a',
    value: '',
    dirty: false,
  },
  endDate: {
    lbl: 'To',
    kind: 'dateTime',
    placeholder: 'MM-DD-YYYY hh:mm a',
    value: '',
    dirty: false,
  },
};

const CustomTableCellGroup = (props) => {
  const { classes, rowData } = props;

  return (
    <>
      <TableCell>
        <Typography>{rowData._id}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography color="primary">
          <strong>{rowData.new}</strong>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{rowData.adhoc_in}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{rowData.ra_in}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{rowData.ra_out}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography className={classes.doneColumn}>
          <strong>{rowData.done}</strong>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography className={classes.cancelledColumn}>
          <strong>{rowData.cancelled}</strong>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          <strong>{rowData.total_in}</strong>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          <strong>{rowData.total_out}</strong>
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          <strong>{rowData.net_in}</strong>
        </Typography>
      </TableCell>
    </>
  );
};

class FlowReport extends React.Component {
  constructor() {
    super();

    this.state = {
      filterSequence: [
        'groupBy',
        'filterBy',
        'dateCode',
        'startDate',
        'endDate',
      ],
      filters: { ...DEFAULT_FILTERS },
      selectedFilters: {
        groupBy: 'user',
        filterBy: 'all',
        dateCode: 'close_of_biz',
      },
    };
  }

  componentDidMount() {
    const { lookups, me, lookups_ac } = this.props;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return lookups_ac();
    }

    return this.fetch();
  }

  componentDidUpdate() {
    const { dfWorkunitsReports, lookups, me, lookups_ac } = this.props;

    if (!me.user) return;

    if (lookups.ink === null) {
      if (lookups.isFetching) return;
      return lookups_ac();
    }

    if (
      !dfWorkunitsReports.isFetching &&
      !dfWorkunitsReports.error &&
      !dfWorkunitsReports.flowReport
    ) {
      return this.fetch();
    }
  }

  fetch = () => {
    return;
  };

  onFilterChange = (fieldName) => (event) => {
    const { value } = event.target;
    let isDirty = false;

    if (this.state.filters[fieldName].kind === 'dateTime') {
      isDirty = !DateUtil.isValidDateTime(value);
    }

    this.setState({
      filters: {
        ...this.state.filters,
        [fieldName]: {
          ...this.state.filters[fieldName],
          value: value,
          dirty: isDirty,
        },
      },
    });
  };

  onRadioFilterChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [name]: value,
      },
    });
  };

  onFilterClearField = (fieldName) => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [fieldName]: {
          ...this.state.filters[fieldName],
          value: '',
          dirty: false,
        },
      },
    });
  };

  applyFilters = () => {
    if (this.state.selectedFilters.dateCode === 'custom') {
      const isStartDateValid = DateUtil.isValidDateTime(
        this.state.filters.startDate.value
      );
      const isEndDateValid = DateUtil.isValidDateTime(
        this.state.filters.endDate.value
      );

      if (!(isStartDateValid && isEndDateValid)) {
        return this.props.open_snack_ac({
          variant: 'error',
          message: `'From' and 'To' fields require valid dates for 'Custom' time-period preset!`,
        });
      }
    }

    this.fetch();
  };

  render() {
    const { classes, dfWorkunitsReports } = this.props;
    const { flowReport, isFetching, error } = dfWorkunitsReports;

    if (isFetching) {
      return <Loader classes={{ message: classes.message }} />;
    }

    if (error) {
      return (
        <Typography color="error" className={classes.message}>
          Error fetchnig report!
        </Typography>
      );
    }

    return (
      <>
        <FilterListing
          me={this.props.me}
          applyFilters={this.applyFilters}
          filters={this.state.filters}
          filterSequence={this.state.filterSequence}
          onFilterChange={this.onFilterChange}
          onRadioFilterChange={this.onRadioFilterChange}
          onFilterClearField={this.onFilterClearField}
          selected={this.state.selectedFilters}
        />
        {flowReport && (
          <>
            <Typography variant="body1" className={classes.titleContainer}>
              <strong>{flowReport.title}</strong>
            </Typography>
            <div className={classes.tableScrollable}>
              <Table padding="dense">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <CustomTableCellGroup
                      classes={classes}
                      rowData={flowReport.hdr}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {flowReport.rows.map((row, idx) => (
                    <TableRow
                      key={idx}
                      className={
                        flowReport.rows.length - 1 === idx
                          ? classes.totalRow
                          : ''
                      }>
                      <CustomTableCellGroup classes={classes} rowData={row} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  dfWorkunitsReports: stateFromStore.dfWorkunitsReports,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      lookups_ac,
      open_snack_ac,
      fetchFlowReport_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FlowReport))
);
