import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '92vh'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  }
})

class Home extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Grid container spacing={8}>
                <Grid item xs={12} sm={3}>
                  <main className={classes.content}>
                    <label className={classes.label}>DocFlow+</label>
                  </main>
                </Grid>
              </Grid>
            </main>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = (dispatch, propsOfHome) =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))
)
