import { uuidv4 } from '../../../util/string'
import { uploadToS3 } from '../../../util/image'

export const requestUploadUrl = async (_id, imageName, contentType) => {
  const res = await window.sch.post('/api/catalog/admin/getuploadurl', {
    _id,
    imageName,
    contentType
  })

  return res
}

export const uploadImage = async (blob, _id, type) => {
  const { upload_url: uploadUrl, bucket, key } = await requestUploadUrl(
    _id,
    `${_id}.img`,
    type
  )
  const uploadRes = await uploadToS3(uploadUrl, blob, type)

  const uid = uuidv4()

  const image_url =
    ['https://s3.us-west-2.amazonaws.com', bucket, key].join('/') + `?${uid}`

  const image_tn_url = image_url.replace(/orig/g, 'tn') + `?${uid}`

  return { urls: { image_url, image_tn_url }, ...uploadRes }
}
