import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2,
    width: '100%',
    boxShadow: 'none',
  },
  chip: {
    margin: theme.spacing.unit / 2,
    width: '45%',
  },
});

const ProductChipsItem = (props) => {
  const { vendors } = props;
  const { classes } = props;

  const showChip = (vendor) => {
    let icon = null;

    if (vendor.preferred) {
      icon = <TagFacesIcon />;
    }

    return (
      <Chip
        key={vendor.v_id}
        icon={icon}
        label={vendor.nm}
        className={classes.chip}
      />
    );
  };

  return (
    <Paper className={classes.root}>
      {vendors.map((vendor) => showChip(vendor))}
    </Paper>
  );
};

ProductChipsItem.propTypes = {
  vendors: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductChipsItem);
