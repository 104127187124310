export const actionTypes = {
  FETCHING_IDX_ADMIN_LOGS: 'FETCHING_IDX_ADMIN_LOGS',
  RECEIVED_IDX_ADMIN_LOGS: 'RECEIVED_IDX_ADMIN_LOGS',
  FAILED_FETCHING_IDX_ADMIN_LOGS: 'FAILED_FETCHING_IDX_ADMIN_LOGS',
};

export const fetchIdxAdminLogs_ac = (params) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCHING_IDX_ADMIN_LOGS,
    });

    window.sch
      .post('/api/idx/admin/log/list', { ...params, page: params.page || 0 })
      .then((res) => {
        dispatch({
          type: actionTypes.RECEIVED_IDX_ADMIN_LOGS,
          data: res.pkg,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_IDX_ADMIN_LOGS,
          error: error,
        });
      });
  };
};
