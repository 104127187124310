import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

import { Tab, Tabs } from '@material-ui/core';
import { setTitle_ac } from '../../actions/me.ac.js';
import { withStyles } from '@material-ui/core/styles';

import Queues from './Queues';

const styles = () => ({
  tabsRoot: {
    minHeight: 24,
    maxHeight: 48
  },
  tabRoot: {
    minWidth: 40,
    '& .labelContainer': {
      padding: '6px 12px'
    },
    '&:first-child': {
      '& .labelContainer': {
        paddingLeft: 6
      }
    },
    '&:last-child': {
      '& .labelContainer': {
        paddingRight: 6
      }
    }
  },
  tabSpecificContainer: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
    overflowX: 'hidden',
    backgroundColor: '#F9F9F8'
  }
});

const ConnectHoldsLanding = props => {
  const onTabSelect = (e, _selectedTab) => {
    let toPath = props.match.path.replace(/:tabName/, _selectedTab);
    props.changePage(toPath);
    return;
  };

  const { classes, match } = props;
  const tabsClass = {
    root: classes.tabsRoot
  };
  const tabClass = {
    root: classes.tabRoot,
    labelContainer: 'labelContainer'
  };

  return (
    <>
      <Tabs
        classes={tabsClass}
        value={match.params.tabName}
        onChange={onTabSelect}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="off"
      >
        <Tab label="Queues" value="queues" classes={tabClass} />
      </Tabs>
      <div className={classes.tabSpecificContainer}>
        <Switch>
          <Route exact path="/admin/connect-holds/queues" component={Queues} />

          <Redirect to="/admin/connect-holds/queues" />
        </Switch>
      </div>
    </>
  );
};

ConnectHoldsLanding.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      changePage: path => {
        return push(path.replace(/:tabParam/, ''));
      }
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ConnectHoldsLanding))
);
