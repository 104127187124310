import { IndexingActionTypes } from '../actions/indexing_details.ac'
import { actionTypes as IndexingJobActionTypes } from '../actions/indexing_job.ac'

export function indexingDetailsInView(
  state = {
    isFetching: false,
    params: null,
    records: null,
    tags: null,
    pagination: null,
    error: null,
    isTransferringOwnership: false,
    transferOwnershipError: null
  },
  action
) {
  switch (action.type) {
    case IndexingActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      }
    case IndexingActionTypes.RECEIVED_INDEXING_DETAILS:
      return {
        ...state,
        pagination: action.pagination,
        records: action.records,
        tags: action.tags
      }

    case IndexingActionTypes.FAILED_RECEIVED_INDEXING_DETAILS:
      return {
        ...state,
        pagination: null,
        records: null,
        tags: null,
        error: action.error
      }
    case IndexingActionTypes.RESET_INDEXING_DETAILS:
      return {
        ...state,
        params: null,
        records: null,
        tags: null,
        pagination: null,
        error: null
      }

    case IndexingActionTypes.TRANSFER_OWNERSHIP_PENDING:
      return {
        ...state,
        isTransferringOwnership: true,
        transferOwnershipError: null
      }

    case IndexingActionTypes.TRANSFER_OWNERSHIP_ERROR:
      return {
        ...state,
        isTransferringOwnership: false,
        transferOwnershipError: action.error
      }

    case IndexingActionTypes.TRANSFER_OWNERSHIP_SUCCESS:
      return {
        ...state,
        isTransferringOwnership: false,
        records: state.records?.map(record => {
          const eq = action.results.find(
            result => result.faxJobId === record._id
          )
          return eq && eq.ok
            ? {
                ...record,
                ...eq.faxJob,
                stateMsg: `Indexing in Progress ${eq.faxJob.indexingClaimedBy}`
              }
            : record
        })
      }

    case IndexingJobActionTypes.UNTRASH_INDEXING_CONTEXT_SUCCESS: 
      return {
        ...state,
        records: state.records?.map(record => {
          return record._id === action.data.faxJobId
            ? {
                ...record,
                state: 200,
                okToUntrash: false,
                okToClaim: true
            }
            : record
        })
      }

    default:
      return state
  }
}
