import {
  Button,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core'
import {
  Backspace as BackSpaceIcon,
  Refresh as RefreshIcon
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import React, { useState, useEffect } from 'react';

const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'stretch'
  },
  adornedEnd: {
    paddingRight: 4
  },
  iconButtonRoot: {
    padding: 10
  },
  buttonRoot: {
    margin: '8px 0px 4px 8px'
  }
})

const OrderSearchField = props => {
  const {
    searchStr: propSearchStr,
    submitSearchStr,
    classes
  } = props

  const [searchStr, setSearchStr] = useState(props.searchStr);

  useEffect(() => {
    setSearchStr(propSearchStr);
  }, [propSearchStr])

  const onSearchStrChange = event => {
    const { value } = event.target;

    setSearchStr(value);
  }

  const onSearchStrEnterPress = event => {
    if (event.key === 'Enter' && searchStr) {
      submitSearchStr(searchStr)
    }
  }

  const resetSearch = () => {
    setSearchStr('');

    if (!propSearchStr) return

    submitSearchStr('')
  }

  return (
    <div className={classes.container}>
      <TextField
        variant="outlined"
        margin="dense"
        placeholder="Search by id or patient name"
        value={searchStr || ''}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!searchStr}
                onClick={() => setSearchStr('')}
                classes={{
                  root: classes.iconButtonRoot
                }}
              >
                <BackSpaceIcon />
              </IconButton>
            </InputAdornment>
          ),
          classes: {
            adornedEnd: classes.adornedEnd
          }
        }}
        onChange={onSearchStrChange}
        onKeyUp={onSearchStrEnterPress}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => submitSearchStr(searchStr)}
        disabled={!searchStr}
        classes={{ root: classes.buttonRoot }}
      >
        Search
      </Button>
      {propSearchStr && (
        <Button
          variant="contained"
          onClick={resetSearch}
          classes={{ root: classes.buttonRoot }}
        >
          <RefreshIcon />
        </Button>
      )}
    </div>
  )
}

export default withStyles(styles)(OrderSearchField);
