import { Tab, Tabs } from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'

import OpenCounts from './OpenCounts'
import ClosedCounts from './ClosedCounts'
import FlowReport from './FlowReport'

const styles = () => ({
  filterRoot: {
    paddingLeft: 16
  }
})

const tabList = [
  {
    value: 'opencounts',
    label: 'OpenCounts'
  },
  {
    value: 'closedcounts',
    label: 'ClosedCounts'
  },
  {
    value: 'flowreport',
    label: 'Flow Report'
  },
  {
    value: 'allopen',
    label: 'All Open'
  },
  {
    value: 'closed',
    label: 'Closed'
  },
  {
    value: 'lifecaredocs',
    label: 'Lifecare Docs'
  }
]

class DFWorkunitsReportsLanding extends React.Component {
  constructor() {
    super();

    this.state = {
      currentTab: ''
    }
  }

  onTabSelect = (e, _selectedTab) => {
    let toPath = this.props.match.path.replace(/:tabName/, _selectedTab)
    this.props.changePage(toPath)

    return this.setState({ currentTab: _selectedTab })
  }

  render() {
    const { classes, match } = this.props

    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot
    }
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer
    }

    return (
      <>
        <Tabs
          classes={tabsClass}
          value={match.params.tabName}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off"
        >
          {
            tabList.map((tab, idx) => (
              <Tab
                key={idx}
                value={tab.value}
                label={tab.label}
                classes={tabClass}
              />
            ))
          }
        </Tabs>
        <Switch>
          <Route
            exact
            path="/reports/dfworkunits/opencounts"
            component={OpenCounts}
          />
          <Route
            exact
            path="/reports/dfworkunits/closedcounts"
            component={ClosedCounts}
          />
          <Route
            exact
            path="/reports/dfworkunits/flowreport"
            component={FlowReport}
          />
        </Switch>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => {
        return push(path)
      }
    },
    dispatch
  )

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withStyles(styles)(DFWorkunitsReportsLanding))
)
