import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';

const styles = () => ({
  field: {
    fontSize: 11,
    backgroundColor: '#ffffff',
    '&:focus': {
      backgroundColor: '#ffffff',
    },
  },
  selectInput: {
    padding: 8,
    fontSize: 11,
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  formContainer: {
    padding: 4,
    marginBottom: 10,
  },
  formControl: {
    flexDirection: 'column',
    marginRight: 24,
    fontSize: 11,
    width: 250,
  },
  checkbox: {
    padding: 2,
    fontSize: 11,
  },
  formLabel: {
    marginRight: 8,
    fontSize: 11,
  },
  date: {
    width: '100%',
    padding: '3px 8px',
    borderRadius: '4px',
    margin: '8px 0 4px 0',
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },
});

const Filters = (props) => {
  const {
    classes,
    filterData,
    onFilterChange,
    handleClearFilters,
    disabled,
    hideApplyFilterButton,
    submitLabel = 'Apply Filters',
  } = props;

  return (
    <div className={classes.formContainer}>
      <Grid container spacing={8} alignItems="flex-end">
        <Grid item>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="start_date" className={classes.formLabel}>
              Confirmed Start Date
            </FormLabel>

            <DatePicker
              name="start_date"
              dateFormat={'MM-dd-yyyy'}
              className={classes.date}
              selected={filterData.start_date}
              onChange={(date) =>
                onFilterChange(moment(date).toDate(), 'start_date')
              }
            />
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="end_date" className={classes.formLabel}>
              Confirmed End Date
            </FormLabel>

            <DatePicker
              name="end_date"
              dateFormat={'MM-dd-yyyy'}
              className={classes.date}
              selected={filterData.end_date}
              onChange={(date) =>
                onFilterChange(moment(date).toDate(), 'end_date')
              }
            />
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="grouping" className={classes.formLabel}>
              Grouping
            </FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              id="grouping"
              name="grouping"
              InputProps={{
                style: { height: '32px' },
              }}
              placeholder="Filter by Grouping"
              value={filterData.grouping || ''}
              onChange={onFilterChange}
              className={classes.field}
              style={{ minWidth: 250 }}
              disabled={disabled}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          style={{
            maxWidth: 150,
          }}>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="acct_start" className={classes.formLabel}>
              Account Start
            </FormLabel>
            <TextField
              style={{
                maxWidth: 130,
              }}
              margin="dense"
              id="acct_start"
              name="acct_start"
              variant="outlined"
              className={classes.field}
              onChange={onFilterChange}
              value={filterData.acct_start || ''}
              placeholder="Range Start"
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 1 },
                style: { height: '32px' },
              }}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          style={{
            maxWidth: 150,
            paddingLeft: 16,
          }}>
          <FormControl className={classes.formControl}>
            <FormLabel htmlFor="acct_end" className={classes.formLabel}>
              Account End
            </FormLabel>
            <TextField
              style={{
                maxWidth: 130,
              }}
              margin="dense"
              id="acct_end"
              name="acct_end"
              placeholder="Range End"
              variant="outlined"
              className={classes.field}
              onChange={onFilterChange}
              value={filterData.acct_end || ''}
              type="number"
              InputProps={{
                inputProps: { min: 1, step: 1 },
                style: { height: '32px' },
              }}
            />
          </FormControl>
        </Grid>

        {hideApplyFilterButton !== true && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onFilterSubmit}
              style={{ marginBottom: 6 }}
              disabled={disabled}>
              {submitLabel}
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleClearFilters}
            style={{ marginBottom: 6 }}
            disabled={disabled}>
            Clear All
          </Button>
        </Grid>
        {(props.extraControls || []).map((cfg, idx) => (
          <Grid item style={{ marginLeft: 8 }} key={idx}>
            <Button
              color="primary"
              variant="outlined"
              onClick={cfg.onClick}
              style={{ marginBottom: 6 }}
              disabled={disabled}>
              {cfg.label || ''}
            </Button>
          </Grid>
        ))}
        {props.children}
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Filters);
