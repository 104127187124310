import { connect } from 'react-redux';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';

import { Button, Typography, Grid } from '@material-ui/core';

import { open_snack_ac } from '../../../actions/snack.ac';
import { LocationsField } from '../../common/LocationsField';

const CreateLocationsView = (props) => {
  const { locations, setLocations, metaInfo } = props;

  const [isEditMode, setIsEditMode] = useState(false);

  const handleLocationAdd = (loc, cb) => {
    setLocations([
      ...locations,
      {
        ...loc,
        loc_id: loc.loc_id,
        s: loc.s || null,
        e: loc.e || null,
      },
    ]);

    if (cb) cb();
  };

  const handleLocationUpdate = (uloc) => {
    setLocations(
      locations.map((loc) => {
        if (loc.loc_id === uloc.loc_id) {
          return {
            ...uloc,
            loc_id: uloc.loc_id,
            s: uloc.s || null,
            e: uloc.e || null,
          };
        }
        return loc;
      })
    );
  };

  const handleLocationDelete = (toDeleteLoc, idx) => {
    setLocations(
      locations.filter(
        (loc) => loc.loc_id !== (toDeleteLoc?.loc_id || locations[idx].loc_id)
      )
    );
  };

  const handleLocationsEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <div style={{ margin: '16px 0px' }}>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: '12px' }}>
        {'Add locations: '}
      </Typography>
      <Grid container spacing={8}>
        <Grid item xs={9}>
          <LocationsField
            locations={locations}
            disabled={false}
            editMode={isEditMode}
            onLocationAdd={handleLocationAdd}
            onLocationUpdate={handleLocationUpdate}
            onLocationDelete={handleLocationDelete}
            metaInfo={metaInfo}
            placeholder={'Search a new location to add.'}>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disabled={false}
              style={{
                marginTop: '12px',
              }}
              onClick={handleLocationsEditModeToggle}>
              {isEditMode ? 'Cancel' : 'Add Locations'}
            </Button>
          </LocationsField>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      open_snack_ac,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(CreateLocationsView);
