import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { open_snack_ac } from '../../../actions/snack.ac';
import * as routes from '../../../constants/routes';
import { getErrorMessage } from '../../../util/string';
import { EmailField, PhoneField, TagField } from '../../common/ComplexFields';
import Loader from '../../Shared/Loader';
import CreateLocationsView from './CreateLocationsView';

const INITIAL_FORM_DATA = {
  first_name: '',
  last_name: '',
  oc_level: '',
  in_pecos: true,
  npi: '',
  license_num: '',
  license_exp_dt: '',
  phone: [],
  fax: [],
  email: [],
  tags: [],
  meta_note: '',
};

const updatableFields = [
  'first_name',
  'last_name',
  'oc_level',
  'in_pecos',
  'npi',
  'license_num',
  'license_exp_dt',
  'phone',
  'fax',
  'email',
  'meta_note',
];

const MAX_CONTACT_DETAILS = 5;
const MAX_CHIP_LABEL_LENGTH = 50;

const styles = (theme) => ({
  fieldGroupContainer: {
    padding: '4px 4px 4px 8px',
    marginBottom: 8,
  },
  labelFont: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
});

const SmallChip = withStyles({
  root: {
    height: 24,
    borderRadius: 2,
    margin: 2,
  },
  deleteIcon: {
    height: '80%',
    fontSize: 20,
    margin: '0 2px 0 -10px',
  },
})(Chip);

const ocToFormData = (oc = {}) => {
  oc.in_pecos = Boolean(oc.in_pecos) || true;
  return oc;
};

const IdxOrderingCliniciansEditForm = (props) => {
  const {
    classes,
    repMode = false,
    createMode = true,
    data = {},
    isFetching = false,
    onUpdate,
    widgetMode = false,
    metaInfo = false,
  } = props;

  // Can be decided with 'idx-admin' role as well
  const requireMetaNote = repMode || widgetMode;

  const [ocLevelOptions, setOCLevelOptions] = useState([]);
  const [isSavingOrderingClinician, setIsSavingOrderingClinician] =
    useState(false);
  const [formData, setFormData] = useState({
    ...INITIAL_FORM_DATA,
    ...ocToFormData(data),
  });
  const [isOCLevelTypesLoading, setIsOCLevelTypesLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setIsOCLevelTypesLoading(true);

    window.sch
      .post('/api/idx/admin/lookups/list', { kind: 'oc_level' })
      .then((res) => {
        setOCLevelOptions([...res.rr]);
        setIsOCLevelTypesLoading(false);
      })
      .catch((error) => {
        setIsOCLevelTypesLoading(false);
      });
  }, []);

  const trimContactChipLabel = (value = '') => {
    if (value.length < MAX_CHIP_LABEL_LENGTH) return value;

    return value.slice(0, MAX_CHIP_LABEL_LENGTH) + '...';
  };

  const handlePhoneNumberAdd = (newPhone) => {
    if (formData.phone.length >= MAX_CONTACT_DETAILS) return;

    if (formData.phone.findIndex((phone) => phone === newPhone) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Phone: '${newPhone}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      phone: [...formData.phone, newPhone],
    }));
  };

  const handlePhoneNumberDelete = (phoneIdx) => {
    let newPhoneList = [
      ...formData.phone.slice(0, phoneIdx),
      ...formData.phone.slice(phoneIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      phone: [...newPhoneList],
    }));
  };

  const handleEmailAdd = (newEmail) => {
    if (formData.email.length >= MAX_CONTACT_DETAILS) return;

    if (formData.email.findIndex((email) => email === newEmail) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Email: '${newEmail}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      email: [...formData.email, newEmail],
    }));
  };

  const handleEmailDelete = (emailIdx) => {
    let newEmailList = [
      ...formData.email.slice(0, emailIdx),
      ...formData.email.slice(emailIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      email: [...newEmailList],
    }));
  };

  const handleFaxAdd = (newFax) => {
    if (formData.fax.length >= MAX_CONTACT_DETAILS) return;

    if (formData.fax.findIndex((fax) => fax === newFax) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Fax: '${newFax}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      fax: [...formData.fax, newFax],
    }));
  };

  const handleFaxDelete = (faxIdx) => {
    let newFaxList = [
      ...formData.fax.slice(0, faxIdx),
      ...formData.fax.slice(faxIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      fax: [...newFaxList],
    }));
  };

  const handleTagAdd = (newTag) => {
    if (formData.tags.length >= MAX_CONTACT_DETAILS) return;

    if (formData.tags.findIndex((tag) => tag === newTag) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Tag: '${newTag}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      tags: [...formData.tags, newTag],
    }));
  };

  const handleTagDelete = (tagIdx) => {
    let newTagist = [
      ...formData.tags.slice(0, tagIdx),
      ...formData.tags.slice(tagIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      tags: [...newTagist],
    }));
  };

  const handleFreeFormFieldChange = (event) => {
    const { name, value } = event.target;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleFormChange = (name, value) => {
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleOCSave = () => {
    if (formData.first_name.length < 2 || formData.last_name.length < 2)
      return props.open_snack_ac({
        variant: 'error',
        message: `Name fields are required!`,
      });

    if (formData.oc_level === '')
      return props.open_snack_ac({
        variant: 'error',
        message: `'OC Level' field is REQUIRED`,
      });

    const ocData = createMode ? formData : _.pick(formData, updatableFields);

    if (!createMode) {
      ocData._id = data._id;
    } else {
      ocData.locations = locations;
    }

    if (metaInfo) {
      ocData.meta_info = metaInfo;
    }

    ocData.rep_mode = repMode;

    setIsSavingOrderingClinician(true);
    window.sch
      .post(`/api/idx/admin/oc/${createMode ? 'add' : 'update'}`, ocData)
      .then((res) => {
        props.open_snack_ac({
          variant: 'success',
          message: `Ordering clinician ${
            createMode ? 'created' : 'updated'
          } successfully!`,
        });
        if (createMode && res.new_oc?._id) {
          if (!widgetMode)
            props.history.push(
              (repMode
                ? routes.SALES_REP_DOCTOR_VIEW
                : routes.ADMIN_IDX_OC_VIEW
              ).replace(':id', res.new_oc._id)
            );

          if (widgetMode && onUpdate) {
            onUpdate(res.new_oc);
          }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsSavingOrderingClinician(false);
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to ${createMode ? 'create' : 'update'} the clinician!`
          ),
        });
        setIsSavingOrderingClinician(false);
      });
  };

  const handleFormCancel = () => {
    props.onCancel();
  };

  return isFetching ? (
    <Loader />
  ) : (
    <div style={{ padding: 4, paddingBottom: '32px' }}>
      <div className={classes.fieldGroupContainer}>
        <Typography variant="body1" gutterBottom className={classes.labelFont}>
          Basic Details&ensp;
        </Typography>
        <Grid container spacing={8}>
          <Grid item>
            <TextField
              required
              id="first_name"
              name="first_name"
              label="First Name"
              variant="standard"
              value={formData.first_name}
              placeholder="First name"
              onChange={handleFreeFormFieldChange}
              helperText="Min 2 characters required"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="last_name"
              name="last_name"
              label="Last name"
              variant="standard"
              value={formData.last_name}
              placeholder="Last name"
              onChange={handleFreeFormFieldChange}
              helperText="Min 2 characters required"
            />
          </Grid>
          <Grid item>
            <TextField
              select
              required
              id="oc_level"
              name="oc_level"
              variant="standard"
              label="OC Level"
              style={{ minWidth: 150 }}
              value={formData.oc_level}
              onChange={handleFreeFormFieldChange}
              helperText={isOCLevelTypesLoading ? 'Loading...' : null}>
              <MenuItem value="">
                <Typography variant="inherit" color="textSecondary">
                  None
                </Typography>
              </MenuItem>
              {ocLevelOptions.map((option) => (
                <MenuItem key={option._id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              id="npi"
              name="npi"
              label="NPI"
              variant="standard"
              value={formData.npi}
              placeholder="Enter NPI"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="license_num"
              name="license_num"
              label="License #"
              variant="standard"
              value={formData.license_num}
              placeholder="Enter License #"
              onChange={handleFreeFormFieldChange}
            />
            <TextField
              id="license_exp_dt"
              name="license_exp_dt"
              label="License Exp Date"
              variant="standard"
              value={formData.license_exp_dt}
              placeholder="Enter License Exp Date"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              label={<Typography variant="body1">{'In pecos'}</Typography>}
              name={'in_pecos'}
              labelPlacement="start"
              control={
                <Checkbox
                  className={classes.inlineBlock}
                  checked={formData.in_pecos || false}
                  onChange={(_) =>
                    handleFormChange('in_pecos', !formData.in_pecos)
                  }
                />
              }
            />
          </Grid>
        </Grid>
      </div>

      <Divider style={{ marginBottom: 8 }} />

      <div className={classes.fieldGroupContainer}>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.labelFont}
          style={{ marginBottom: '8px' }}>
          Contact Details
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.labelFont}>
              Phone(s)
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.phone.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.phone.map((phone, idx) => (
                <SmallChip
                  label={phone}
                  key={`${phone}_${idx}`}
                  onDelete={() => handlePhoneNumberDelete(idx)}
                />
              ))}
              <PhoneField
                withExtensionNumber
                onAdd={handlePhoneNumberAdd}
                restrictAddition={formData.phone.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" className={classes.labelFont}>
              Email(s)
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.email.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.email.map((email, idx) => (
                <SmallChip
                  title={email}
                  key={`${email}_${idx}`}
                  label={trimContactChipLabel(email)}
                  onDelete={() => handleEmailDelete(idx)}
                />
              ))}
              <EmailField
                onAdd={handleEmailAdd}
                restrictAddition={formData.email.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" className={classes.labelFont}>
              Fax(es)
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.fax.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.fax.map((fax, idx) => (
                <SmallChip
                  label={fax}
                  key={`${fax}_${idx}`}
                  onDelete={() => handleFaxDelete(idx)}
                />
              ))}
              <PhoneField
                onAdd={handleFaxAdd}
                restrictAddition={formData.fax.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider style={{ margin: '8px 0px' }} />
      {createMode && (
        <>
          <div
            className={classes.fieldGroupContainer}
            style={{ marginBottom: '8px' }}>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.labelFont}>
              Other Details
            </Typography>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.labelFont}>
                  Tag(s)
                  <Typography variant="caption" color="inherit" inline>
                    &ensp; (
                    <strong>
                      {formData.tags.length}/{MAX_CONTACT_DETAILS}
                    </strong>{' '}
                    Added)
                  </Typography>
                </Typography>
                <div className={classes.fieldGroupContainer}>
                  {formData.tags.map((tag, idx) => (
                    <SmallChip
                      label={tag}
                      key={`${tag}_${idx}`}
                      onDelete={() => handleTagDelete(idx)}
                    />
                  ))}
                  <TagField
                    onAdd={handleTagAdd}
                    restrictAddition={
                      formData.tags.length >= MAX_CONTACT_DETAILS
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <Divider style={{ marginBottom: '8px' }} />
        </>
      )}
      {createMode && (
        <CreateLocationsView
          locations={locations}
          setLocations={setLocations}
          metaInfo={metaInfo}
        />
      )}
      {requireMetaNote && (
        <Grid item style={{ marginTop: '-6px', marginBottom: '16px' }}>
          <TextField
            multiline
            fullWidth
            id="meta_note"
            name="meta_note"
            label="Additional Note"
            variant="standard"
            value={formData.meta_note}
            placeholder="Add note to help the approver here!"
            onChange={handleFreeFormFieldChange}
            helperText={
              'This note will be included in the notification to the admin.'
            }
          />
        </Grid>
      )}
      <Grid container spacing={8} justify="flex-start">
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={isSavingOrderingClinician}
            onClick={handleOCSave}>
            {createMode ? 'Create' : 'Update'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleFormCancel}
            disabled={isSavingOrderingClinician}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ open_snack_ac }, dispatch);
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(IdxOrderingCliniciansEditForm));
