import _ from 'lodash'
import {
  Button,
  Card,
  Grid,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState, useEffect, Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchReferralDetails_ac
} from '../../actions/all_scripts_report.ac.js'
import {
  setSelectedValueFromReferral_ac
} from '../../actions/new_patient_record.ac'
import { open_snack_ac } from '../../actions/snack.ac.js'
import Loader from '../Shared/Loader'

const styles = theme => ({
  treeContainer: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10
  },
  cardContent: {
    height: 580,
    '-webkitOverflowScrolling': 'touch',
    overflowY: 'scroll'
  },
  cardHdrRoot: {
    backgroundColor: '#b2dfdb',
    paddingBottom: 8
  },
  cardHdrSubHdr: {
    fontSize: '.92em'
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800
  }
})

const ExpandableTreeNode = props => {
  const [isExpanded, setIsExpanded] = useState(props.isExpanded ? true : false)
  const [isClickable, setIsClickable] = useState(props.isClickable ? true : false)

  useEffect(() => {
    setIsExpanded(props.isExpanded)
    setIsClickable(props.isClickable)

  }, [props.isExpanded, props.isClickable])

  if (!props.isLeaf) {
    return (
      <div
        className={classNames(
          props.classes.expandableNode,
          { 'expanded': isExpanded }
        )}
      >
        <Typography
          inline
          variant="body1"
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: 'pointer' }}
        >
          <ArrowRightIcon
            className={props.classes.arrow}
            color={!isExpanded ? 'primary' : 'secondary'}
            style={{
              transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)'
            }}
          />
          <strong>{props.header}</strong>
        </Typography>
        {isExpanded && (
          <div>
            {props.children}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={props.classes.leaf}>
      <Typography
        inline
        variant="body2"
        color="inherit"
      >
        <span
          className={isClickable ? props.classes.pointer : ''}
          onClick={() => isClickable ? props.selectValue(props.header) : undefined}
        >
          <strong>{props.header} {isClickable}</strong>
        </span>
      </Typography>
    </div>
  )
}

const StyledTreeNode = withStyles(() => ({
  expandableNode: {
    position: 'relative',
    paddingLeft: 24,
    '&.expanded': {
      '&::before': {
        content: '""',
        height: 'calc(100% - 20px)',
        position: 'absolute',
        left: 12,
        top: 20,
        borderLeft: '1px dashed #00000060'
      }
    }
  },
  arrow: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  leaf: {
    padding: 2,
    color: 'grey',
    display: 'inline-block'
  },
  pointer: {
    cursor: 'pointer'
  }
}))(ExpandableTreeNode)

class ReferralXMLView extends Component {

  constructor(props) {
    super(props)

    this.state = {
      allExpanded: false
    }
  }


  componentDidMount() {
    const { referralId, referralExists } = this.props
    if (!referralExists) {
      const params = {
        id: referralId
      }
      this.props.fetchReferralDetails_ac(params)
    }
  }

  generateParsedXMLTree = obj => {
    const { autoExpanded, isClickable } = this.props

    if (obj === null || obj === undefined) {
      return (
        <StyledTreeNode
          header={'N/A'}
          isLeaf
          selectValue={this.selectValue}
          isClickable={isClickable}
        />
      )
    }

    if (!_.isObject(obj)) {
      return (
        <StyledTreeNode
          header={`${obj}`}
          isLeaf
          selectValue={this.selectValue}
          isClickable={isClickable}
        />
      )
    }

    if (_.isArray(obj)) {
      return obj.map((o, oIdx) => (
        <React.Fragment key={oIdx}>
          {this.generateParsedXMLTree(o)}
        </React.Fragment>
      ));
    }

    return Object.keys(obj).map((key, idx) => {
      return (
        <StyledTreeNode
          key={key}
          header={key}
          isExpanded={this.state.allExpanded || autoExpanded}
        >
          {this.generateParsedXMLTree(obj[key])}
        </StyledTreeNode>
      )
    });
  }

  controlExpand = () => {
    this.setState(preState => ({
      allExpanded: !preState.allExpanded
    }))
  }

  selectValue = val => {
    this.props.setSelectedValueFromReferral_ac(val);
  }

  render() {
    const { allScriptsReportInView, classes, autoExpanded, isClickable } = this.props
    const { referral } = allScriptsReportInView

    if (allScriptsReportInView.isFetchingReferralDetails) {
      return (
        <main className={classes.content}>
          <Loader message="...loading referral details" />
        </main>
      )
    }

    if (!referral) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>Referral record Not Found</h4>
        </div>
      )
    }

    const xmlObj = referral.parsedXML[0]?.OutboundDataFeed.Patient

    const cardHdrClasses = {
      root: classes['cardHdrRoot'],
      title: classes['cardHdrContent'],
      subheader: classes['cardHdrSubHdr']
    }
    return (
      <Card className={classes.card}>
        <CardHeader
          classes={cardHdrClasses}
          title={
            <span style={{ fontSize: 12, fontWeight: 600 }}>
              Referral ID: {referral.id}
              {
                referral.parsedXML[0]?.OutboundDataFeed?.Patient?.Gender?.DisplayValue
              }
            </span>
          }
          subheader={
            <span style={{ fontSize: 12 }}>
              Hospital Name:{' '}
              {
                referral.parsedXML[0]?.OutboundDataFeed?.Patient?.HospitalName
              }
            </span>
          }
        />
        <CardContent>
          <Grid container className={classes.cardContent}>
            <Grid item xs={12}>
              <div className={classes.treeContainer}>
                <Button variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.controlExpand}
                >
                  [Expand/Collapse]
                </Button>
                <StyledTreeNode
                  header="Patient"
                  isExpanded={this.state.allExpanded || autoExpanded}
                  isClickable={isClickable}
                >
                  {this.generateParsedXMLTree(xmlObj)}
                </StyledTreeNode>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  }
}

ReferralXMLView.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  allScriptsReportInView: stateFromStore.allScriptsReportInView,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchReferralDetails_ac,
      setSelectedValueFromReferral_ac,
      open_snack_ac
    },
    dispatch
  )

export const ReferralXML = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ReferralXMLView))
)
