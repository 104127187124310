export const actionTypes = {
  SET_IS_FETCHING_ADJ_REQUESTS_REPORT: 'SET_IS_FETCHING_ADJ_REQUESTS_REPORT',
  RECEIVED_ADJ_REQUESTS_REPORT: 'RECEIVED_ADJ_REQUESTS_REPORT',
  FAILED_ADJ_REQUESTS_REPORT: 'FAILED_ADJ_REQUESTS_REPORT'
}

export function fetchAdjRequestsReport_ac(params) {
  return dispatch => {
    dispatch(setIsFetching(true))

    window.sch
      .post('/api/adj_requests/gen/list', params)
      .then(response => {
        dispatch({
          type: actionTypes.RECEIVED_ADJ_REQUESTS_REPORT,
          report: {
            records: response.pkg.rr,
            pagination: response.pkg.pagination
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.FAILED_ADJ_REQUESTS_REPORT,
          error: true
        })
      })
      .finally(() => {
        dispatch(setIsFetching(false))
      })
  }
}

function setIsFetching(isFetching) {
  return {
    type: actionTypes.SET_IS_FETCHING_ADJ_REQUESTS_REPORT,
    isFetching
  }
}
