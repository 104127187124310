import { open_snack_ac } from './snack.ac.js'
export const MailBoxesActionTypes = {
    RECEIVED_MAIL_BOXES_DATA: 'RECEIVED_MAIL_BOXES_DATA',
    FAILED_RECEIVING_MAIL_BOXES_DATA: 'FAILED_RECEIVING_MAIL_BOXES_DATA',
    FORWARDING_STATUS_CHANGED: 'FORWARDING_STATUS_CHANGED',
    SET_IS_FETCHING: 'SET_IS_FETCHING',
    POLLING_STATUS_CHANGED: 'POLLING_STATUS_CHANGED',

    // MailboxPatchModal
    MAILBOX_EMAIL_PATCH_PENDING: 'MAILBOX_EMAIL_PATCH_PENDING',
    MAILBOX_EMAIL_PATCH_SUCCESS: 'MAILBOX_EMAIL_PATCH_SUCCESS',
    MAILBOX_EMAIL_PATCH_FAILED: 'MAILBOX_EMAIL_PATCH_FAILED',
    MAILBOX_NOTIFY_CC_PATCH_PENDING: 'MAILBOX_NOTIFY_CC_PATCH_PENDING',
    MAILBOX_NOTIFY_CC_PATCH_SUCCESS: 'MAILBOX_NOTIFY_CC_PATCH_SUCCESS',
    MAILBOX_NOTIFY_CC_PATCH_FAILED: 'MAILBOX_NOTIFY_CC_PATCH_FAILED',
};
export function fetchMailBoxes_ac(params) {
    return (dispatch) => {
        dispatch(fetching(true));
        return window.sch.post('/api/mailboxes:list', { ...params })
            .then(result => {
                if (result.error) {
                    throw new Error(result.error)
                }
                dispatch({
                    type: MailBoxesActionTypes.RECEIVED_MAIL_BOXES_DATA,
                    records: result.rows,
                    tags: result.tagCounts,
                    pagination: result.pagination
                });
            })
            .catch(error => {
                dispatch({
                    type: MailBoxesActionTypes.FAILED_RECEIVING_MAIL_BOXES_DATA,
                    error
                });
            })
            .finally(() => {
                dispatch(fetching(false));
            });
    }
}

export function patchMailboxEmail_ac(params, { onSuccess, onError }) {
  return (dispatch) => {
    dispatch({
      type: MailBoxesActionTypes.MAILBOX_EMAIL_PATCH_PENDING,
    });
    return window.sch
      .post('/api/mailbox:update:email', params)
      .then((result) => {
        if (result.error) {
          if (onError) {
            onError(result.error);
          }
          throw new Error(result.error);
        }
        dispatch({
          type: MailBoxesActionTypes.MAILBOX_EMAIL_PATCH_SUCCESS,
          ...params,
        });

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: MailBoxesActionTypes.MAILBOX_EMAIL_PATCH_FAILED,
          error,
        });

        if (onError) {
          onError();
        }
      });
  };
}

export function patchMailboxNotifyCC_ac(params, { onSuccess, onError }) {
  return (dispatch) => {
    dispatch({
      type: MailBoxesActionTypes.MAILBOX_NOTIFY_CC_PATCH_PENDING,
    });
    return window.sch
      .post('/api/mailbox:update:notify:cc', params)
      .then((result) => {
        if (result.error) {
          if (onError) {
            onError(result.error);
          }
          throw new Error(result.error);
        }
        dispatch({
          type: MailBoxesActionTypes.MAILBOX_NOTIFY_CC_PATCH_SUCCESS,
          ...params,
        });

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: MailBoxesActionTypes.MAILBOX_NOTIFY_CC_PATCH_FAILED,
          error,
        });

        if (onError) {
          onError();
        }
      });
  };
}

export function change_forwarding_ac(params) {
    return (dispatch) => {
        return window.sch.post('/api/mailbox:change:forwarding', { ...params })
            .then(result => {
                if (result.error) {
                    throw new Error(result.error)
                }
                dispatch({
                    type: MailBoxesActionTypes.FORWARDING_STATUS_CHANGED,
                    ...params
                });
            })
            .catch(error => {
                dispatch(open_snack_ac({
                    variant: 'error',
                    message: 'error forwarding'
                }))
            })
    }
}
export function changePolling_ac(params) {
    return (dispatch) => {
        return window.sch.post('/api/mailbox:change:polling', { ...params })
            .then(result => {
                if (result.error) {
                    throw new Error(result.error)
                }
                dispatch({
                    type: MailBoxesActionTypes.POLLING_STATUS_CHANGED,
                    ...params
                });
            })
            .catch(error => {
                dispatch(open_snack_ac({
                    variant: 'error',
                    message: 'error changing polling status'
                }))
            })
    }
}

export const fetching = isFetching => ({
    type: MailBoxesActionTypes.SET_IS_FETCHING,
    isFetching
})