import {
  Button,
  Grid,
  LinearProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { TABLE_RULES } from '../../../util/docDetails'

const styles = theme => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  filterRoot: {
    paddingLeft: 16
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  title: {
    flexGrow: 1
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  spacer: {
    height: 40
  },
  flexContainer: {
    display: 'block'
  },
  tabLabelContainer: {
    padding: '3px 12px 3px 12px'
  },
  tabRoot: {
    minWidth: 16,
    minHeight: 24
  },
  tabsRoot: {
    minHeight: 24
  },
  fullHeight: {
    height: '100%'
  }
})

const groupBy = (sourceData, groupBy = 'Type') => {
  const groupedData = {}
  sourceData.forEach(item => {
    const value = item[groupBy]
    if (groupedData[value]) {
      groupedData[value].push(item)
    } else {
      groupedData[value] = [item]
    }
  })
  return groupedData
}

const extractDataFromJson = (rawData = {}) => {
  return {
    entities: rawData.json.Entities,
    textract_source_blocks: rawData.json.textract_source.Blocks
  }

  /*
  return Object.values(rawData).reduce(
    (acc, item) => {
      acc.entities = [...acc.entities, ...item.json.Entities]
      acc.textract_source_blocks = [
        ...acc.textract_source_blocks,
        ...item.json.textract_source.Blocks
      ]
      return acc
    },
    { entities: [], textract_source_blocks: [] }
  )
  */
}

const getZeroFilled2DArray = (ySize, xSize) => {
  let xArr = []
  let yArr = []

  for (let i = 0; i < xSize; ++i) xArr.push(0)
  for (let j = 0; j < ySize; ++j) yArr.push([...xArr])

  return yArr
}

const prepareTextractTables = blocks => {
  if (!(blocks && blocks.length)) {
    return []
  }
  const tables = []
  const cellsMap = {}
  const wordsMap = {}
  blocks.forEach(block => {
    if (block.BlockType === 'CELL') {
      cellsMap[block.Id] = { ...block }
    } else if (block.BlockType === 'WORD') {
      wordsMap[block.Id] = { ...block }
    } else if (block.BlockType === 'TABLE') {
      tables.push(block)
    }
  })
  const textractTables = tables.map(table => {
    let rowSize = 0
    let colSize = 0
    const cellIds = _.get(table, ['Relationships', '0', 'Ids'], [])

    cellIds.forEach(cellId => {
      if (cellsMap[cellId] && cellsMap[cellId].RowIndex > rowSize) {
        rowSize = cellsMap[cellId].RowIndex
      }
      if (cellsMap[cellId] && cellsMap[cellId].ColumnIndex > colSize) {
        colSize = cellsMap[cellId].ColumnIndex
      }
    })

    let rows = getZeroFilled2DArray(rowSize, colSize)
    cellIds.forEach(cellId => {
      const cell = cellsMap[cellId]
      if (!cell) return;
      const children = _.get(cell, ['Relationships', '0', 'Ids'], [])
      rows[cell.RowIndex - 1][cell.ColumnIndex - 1] = {
        ...cell,
        cellText: children
          .map(wordId => _.get(wordsMap[wordId], ['Text'], ''))
          .join(' ')
      }
    })
    return rows
  })
  return [...textractTables]
}

const GroupByFullListView = ({ stateData }) => (
  <Table padding="dense">
    <TableHead>
      <TableRow>
        <TableCell>Entity</TableCell>
        <TableCell>Type</TableCell>
        <TableCell>Category</TableCell>
        <TableCell>Traits</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {stateData.entities
        .slice(
          stateData.page * stateData.rowsPerPage,
          stateData.page * stateData.rowsPerPage + stateData.rowsPerPage
        )
        .map((item, index) => (
          <TableRow key={index}>
            <TableCell variant="body">
              <div style={{ flex: 1, color: 'blue' }}>{item.Text}</div>
              <div style={{ flex: 1, color: 'lightgray' }}>
                {item.Score.toFixed(2)} Score
              </div>
            </TableCell>
            <TableCell variant="body">
              <div style={{ flex: 1 }}>{item.Type}</div>
            </TableCell>
            <TableCell variant="body">
              <div style={{ flex: 1 }}>{item.Category}</div>
            </TableCell>
            <TableCell variant="body">
              {item.Traits.length ? (
                <>
                  <div style={{ flex: 1 }}>{item.Traits[0].Name}</div>
                  <div style={{ flex: 1 }}>{item.Traits[0].Score} Score</div>
                </>
              ) : (
                  '-'
                )}
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>
)

const GroupByTypeView = ({ stateData }) => {
  const groupedData = groupBy(stateData.entities)
  return (
    <Table padding="dense">
      <TableHead>
        <TableRow>
          <TableCell>Entity</TableCell>
          <TableCell>Category</TableCell>
          <TableCell>Traits</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(groupedData)
          .slice(
            stateData.page * stateData.rowsPerPage,
            stateData.page * stateData.rowsPerPage + stateData.rowsPerPage
          )
          .map((key, i) => (
            <React.Fragment key={i}>
              <TableRow>
                <TableCell colSpan={3}>
                  <div>{key}</div>
                </TableCell>
              </TableRow>
              {groupedData[key].map((item, index) => (
                <TableRow key={index}>
                  <TableCell variant="body">
                    <div style={{ flex: 1, color: 'blue' }}>{item.Text}</div>
                    <div style={{ flex: 1, color: 'lightgray' }}>
                      {item.Score.toFixed(2)} Score
                    </div>
                  </TableCell>

                  <TableCell variant="body">
                    <div style={{ flex: 1 }}>{item.Category}</div>
                  </TableCell>
                  <TableCell variant="body">
                    {item.Traits.length ? (
                      <>
                        <div style={{ flex: 1 }}>{item.Traits[0].Name}</div>
                        <div style={{ flex: 1 }}>
                          {item.Traits[0].Score} Score
                        </div>
                      </>
                    ) : (
                        '-'
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
      </TableBody>
    </Table>
  )
}

class EntitiesAnalysisComponent extends Component {
  constructor() {
    super()
    this.state = {
      page: 1,
      rowsPerPage: 50,
      entities: [],
      isSavingTable: false,
      textract_source_blocks: [],
      textractTables: [],
      activeTab: 'full_list'
    }
    this.onTableSave = this.onTableSave.bind(this)
    this.keyValueTheTable = this.keyValueTheTable.bind(this)
  }

  keyValueTheTable(table) {
    let o = {}
    table.forEach(row => {
      //row is an array of cells, assume row[0] is field name
      if (row.length >= 2) {
        return (o[row[0].cellText] = row[1].cellText)
      }
    })

    const FI = TABLE_RULES[0].fields_of_interest
    let kvs = _.keys(FI)
      .map(f => {
        if (o[f] === undefined) {
          return false
        }

        return { ...FI[f].get(o[f]) }
      })
      .filter(p => p)

    if (kvs.length >= TABLE_RULES[0].minimum_required) {
      if (this.props.currentDetails) {
        if (TABLE_RULES[0].already_saved(this.props.currentDetails)) {
          return {
            situation: 'alreadySaved',
            btnLbl: TABLE_RULES[0].btnLbl
          }
        }
      }

      let pkg = {}
      kvs.forEach(kv => {
        pkg = { ...pkg, ...kv }
      })

      this.props.onHasSaveableTableData(this.props.pageOfDocument)
      return {
        situation: 'allowSave',
        btnLbl: TABLE_RULES[0].btnLbl,
        details: {
          ...pkg,
          ...TABLE_RULES[0].extras
        }
      }
    }

    return {
      situation: 'noButton',
      btnLbl: TABLE_RULES[0].btnLbl
    }
  }

  onTableSave = idx => () => {
    console.log(this.state.kvTables[idx])
    this.setState({ isSavingTable: true })
    this.props.onDocUpdateFormAction({
      actionKind: 'updateDocDetails',
      details: this.state.kvTables[idx].details,
      page: parseInt(this.props.pageOfDocument, 10)
    })
  }

  componentDidMount() {
    const { json } = this.props
    if (json.state !== 'ready') return

    const extractedData = extractDataFromJson(json)
    const textractTables = prepareTextractTables(
      extractedData.textract_source_blocks
    )

    this.setState({
      ...extractedData,
      textractTables,
      kvTables: textractTables.map(this.keyValueTheTable)
    })
  }

  componentDidUpdate(prevProps) {
    const { pageOfDocument, json } = this.props
    const { pageOfDocument: prev_pageOfDocument } = prevProps

    if (json.state !== 'ready') return

    if (prev_pageOfDocument === pageOfDocument) return

    const extractedData = extractDataFromJson(json)
    const textractTables = prepareTextractTables(
      extractedData.textract_source_blocks
    )
    this.setState({
      ...extractedData,
      textractTables,
      kvTables: textractTables.map(this.keyValueTheTable)
    })
  }

  onTabSelect = (e, _selectedTab) => {
    return this.setState({
      activeTab: _selectedTab
    })
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    })
  }

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    })
  }
  renderButtonSwitch(param, index, btnLbl) {
    let el = ''
    switch (param) {
      case 'allowSave':
        el = (
          <Button
            style={{ margin: 3 }}
            variant="contained"
            disabled={this.state.isSavingTable}
            size="small"
            color="primary"
            onClick={this.onTableSave(index)}
            className={this.props.classes.button}>
            {btnLbl}
          </Button>
        )
        break
      case 'alreadySaved':
        el = (
          <div>
            <Button
              style={{ margin: 3 }}
              variant="contained"
              disabled
              size="small"
              color="primary"
              className={this.props.classes.button}>
              {btnLbl}
            </Button>
            <span style={{ marginLeft: 8 }}>
              Data Saved and Attached to Document
            </span>
          </div>
        )
        break
      case 'noButton':
        el = ''
        break
      default:
        return ''
    }
    return el
  }

  render() {
    const { classes, json } = this.props
    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot
    }
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer
    }
    if (json.state === 'exhausted') {
      return (
        <Grid container className={classes.fullHeight}>
          <Grid container spacing={0} item className={classes.fullHeight}>
            <main className={classes.content}>
              <span>
                Analysis results unaavailable for this page - status:{' '}
                {json.state}
              </span>
            </main>
          </Grid>
        </Grid>
      )
    }

    if (json.state !== 'ready') {
      return (
        <Grid container className={classes.fullHeight}>
          <Grid container spacing={0} item className={classes.fullHeight}>
            <main className={classes.content}>
              <span>...processing analysis page - status: {json.state}</span>

              <LinearProgress variant="indeterminate" />
            </main>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container className={classes.fullHeight}>
        <Grid container spacing={0} item className={classes.fullHeight}>
          <main className={classes.content}>
            <div className={classes.scrollArea}>
              <div className={classes.root}>
                <div style={{ margin: 2, padding: 8 }}>
                  <label style={{ fontSize: 10, fontWeight: 'bold' }}>
                    {this.state.textractTables.length} Tables Found in this
                    page. Entities analysis displayed below.
                  </label>
                  <div
                    style={{
                      borderBottom: '1px solid #FFA726',
                      marginBottom: 6,
                      paddingnBottom: 6
                    }}>
                    {this.state.textractTables.length
                      ? this.state.textractTables.map((table, index) => (
                        <div key={index} style={{ paddingBottom: 4 }}>
                          <label style={{ fontSize: 10, fontWeight: 'bold' }}>
                            Table {index + 1} :
                            </label>
                          {this.renderButtonSwitch(
                            this.state.kvTables[index].situation,
                            index,
                            this.state.kvTables[index].btnLbl
                          )}
                          <Table key={index} padding="dense">
                            <TableBody>
                              {table.map((row, i) => (
                                <TableRow key={i}>
                                  {row.map((cell, index) => (
                                    <TableCell
                                      component={'td'}
                                      style={{
                                        border: '1px solid grey',
                                        padding: 4
                                      }}
                                      colSpan={cell.ColumnSpan}
                                      rowSpan={cell.RowSpan}
                                      key={index}>
                                      {cell.cellText}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </div>
                      ))
                      : ''}
                  </div>
                  <label style={{ fontSize: 10, fontWeight: 'bold' }}>
                    Entities Analysis:
                  </label>
                  <Tabs
                    classes={tabsClass}
                    value={this.state.activeTab}
                    onChange={this.onTabSelect}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="off">
                    <Tab
                      value="full_list"
                      label="Full List"
                      classes={tabClass}
                    />
                    <Tab
                      value="group_by_type"
                      label="Group By Type"
                      classes={tabClass}
                    />
                  </Tabs>
                  {this.state.activeTab === 'full_list' && (
                    <GroupByFullListView
                      classes={classes}
                      stateData={this.state}
                    />
                  )}
                  {this.state.activeTab === 'group_by_type' && (
                    <GroupByTypeView classes={classes} stateData={this.state} />
                  )}
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={this.state.entities.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </div>
              </div>
            </div>
          </main>
        </Grid>
      </Grid>
    )
  }
}

EntitiesAnalysisComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export const EntitiesAnalysis = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(EntitiesAnalysisComponent))
)
