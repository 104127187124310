export const WORKUNIT_DETAIL = {
  _id: '63508dd55b77a933c4c986a4',
  is_active: true, // ?
  cancel_reason: '', // ?
  nm: ' ALVAREZ, ANGELA 1-6-1970',
  displayAs: ' ALVAREZ, ANGELA 1-6-1970',
  pageCount: 2,
  assigned_to: 'dev2@ibreathecenters.com', // ?
  labels: {
    payer_type: ['MEDICARE'],
    prod_group: ['DME', 'SUCTION / 50 PSI'],
    referral: ['OTHER'],
    trm_source: ['FAX'],
    order_type: ['REGULAR'],
  },
  notes: [
    {
      user: 'dev2@ibreathecenters.com',
      date: new Date(),
      text: 'This is a note',
    },
  ],
  docs: [
    {
      kind: 'divider',
      id: '-alvarez,-angela-1-6-1970:cmn-rx-dhs',
      nm: 'CMN-RX-DHS',
      displayAs: ' ALVAREZ, ANGELA 1-6-1970:CMN-RX-DHS',
      current: '',
      dt: '',
      pages: [
        {
          idx: 0,
          k: 'pg1of2',
          idxInFax: 0,
        },
        {
          idx: 1,
          k: 'pg2of2',
          idxInFax: 1,
        },
      ],
      detail: {},
      faxJobIdx: 0,
      rotation: [0, 0],
    },
  ],
  faxJobs: [
    {
      _id: '5ec1333c3de67d3936a04f9b',
      s3bucketPath: 'ringcentral/2020/05/17',
      sourceMailboxId: 1250172011,
      faxTs: '2020-05-17T12:47:29Z',
      dTs: '2020-05-17T12:51:08.266Z',
      keys: ['RELJLo2NDIflRshYUt1aGA==', 'wbPS7Ru3cD6ummkKP6KDtw=='],
      pageCount: 2,
    },
  ],
  oc: {
    _id: 10818,
    first_name: 'JEFFREY',
    last_name: 'POLITO',
    oc_level: 'SILVER',
    in_pecos: true,
    npi: '1679525034',
    phone: ['1-805-681-1490'],
    fax: ['1-805-681-1593'],
    email: [],
    locations: [
      {
        loc_id: 10078,
        s: '2022-07-07T07:00:00.000Z',
        e: null,
        _id: 10078,
        name: '334 S PATTERSON AVE:SUITE 209:SANTA BARBARA',
        phone: [],
        addr1: '334 S PATTERSON AVE',
        addr2: 'SUITE 209',
        city: 'SANTA BARBARA',
        state: 'CA',
        zip: '93111',
      },
    ],
    reps: [],
    creator_id: 'acolbert@supercare.com',
    creator_nm: 'ANGELINA COLBERT',
    created_dt: '2022-07-07T22:02:59.880Z',
    ts: '2022-07-08T21:09:56.055Z',
  },
  state: 1000,
  phase_keys: [
    'CS Order:DOCUMENT QUALIFICATION',
    'CS Order:INSURANCE VERIFICATION',
  ],
  phases: [
    {
      workunit_type: 'CS Order',
      phase: 'INSURANCE VERIFICATION',
      team: 'CS:PRECCS',
      wut_version: 15,
      checkboxes: [
        {
          name: 'Coverage Verified',
          pending_labels: [
            { name: 'PendLabelOne', sla: { max_minutes: 90, minutes: 45 } },
            { name: 'PendLabelTwo', sla: { max_minutes: 120, minutes: 60 } },
          ],
          //complete_labels: [{ name: 'CompLabelOne' }],
          complete_labels: [],
        },
        {
          name: 'Patient Responsibility Obtained',
          pending_labels: [
            { name: 'PendLabelThree', sla: { max_minutes: 90, minutes: 45 } },
            { name: 'PendLabelFour', sla: { max_minutes: 120, minutes: 60 } },
          ],
          complete_labels: [{ name: 'CompLabelTwo' }],
        },
        {
          name: 'Same/Similar Verified',
          pending_labels: [
            { name: 'PendLabelFive', sla: { max_minutes: 90, minutes: 45 } },
            { name: 'PendLabelSix', sla: { max_minutes: 120, minutes: 60 } },
          ],
          complete_labels: [{ name: 'CompLabelThree' }],
        },
      ],
      sla: {
        _id: 10018,
        minutes: 180,
        eff_hours: 's',
        deadline: '2022-09-30T09:18:44.504Z',
      },
    },
    {
      workunit_type: 'CS Order',
      phase: 'DOCUMENT QUALIFICATION',
      team: 'SPECIALTY DME',
      checkboxes: [],
      wut_version: 15,
      dq: [
        {
          product: 'DME',
          crit: [
            {
              wur: 'FACE TO FACE',
              config: 'rca',
              pending_labels: [
                { name: 'PendLabelOne', sla: { max_minutes: 90, minutes: 45 } },
              ],
              complete_labels: [],
            },
          ],
        },
        {
          product: 'SUCTION / 50 PSI',
          crit: [
            {
              wur: 'Testy',
              config: 'rc',
              pending_labels: [
                {
                  name: 'PendLabelTest',
                  sla: { max_minutes: 90, minutes: 45 },
                },
              ],
              complete_labels: [],
            },
            {
              wur: 'SWO',
              config: 'rcn',
              pending_labels: [
                { name: 'PendLabelOne', sla: { max_minutes: 90, minutes: 45 } },
              ],
              complete_labels: [],
            },
          ],
        },
      ],
      sla: {
        _id: 10018,
        minutes: 180,
        eff_hours: 's',
        deadline: '2022-09-30T09:18:44.504Z', // m.created.ts + sla.minutes
      },
    },
  ],
  min_deadline: '2022-09-30T09:18:44.504Z', // min deadline of all phases
  hdmsWIds: [],
  hdmsAccts: ['44755-1'],
  rae_id: 10175,
  hcc_id: null,
  m: {
    created: {
      u_id: 'rie@supercare.com',
      ts: '2022-09-30T06:18:44.504Z',
      x_id: '62f2a8fa4fe7f9b3c48fee68',
    },
  },
  ra_log: [],
  idxVersion: 3,
};

export const WORKUNIT_STATUS_SUMMARY = {
  work_queue: 30,
  new_orders: 25,
  discharge_orders: 5,
  follow_up_orders: 30,
  past_sla: 5,
};
