import { Button, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { orange, purple } from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { logout_ac } from '../actions/me.ac.js';
import GLogin from './GLogin';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange,
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
});

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  gridContainer: {
    padding: 16,
  },
  center: {
    alignItems: 'center',
    textAlign: 'center',
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
});

class NotLoggedIn extends React.Component {
  constructor(props) {
    super(props);
    this.onFailedGoogleResponse = this.onFailedGoogleResponse.bind(this);
    this.state = {
      msg: '',
    };
  }

  onFailedGoogleResponse() {
    this.setState({ msg: 'Login Via Google Failed.' });
  }
  clearLocalStorage() {
    window.localStorage.remove(process.env.REACT_APP_SA_CACHE_KEY);
  }

  render() {
    const { classes } = this.props;
    /*
    let msg = ''
    let href = ''
    let vtag = ''
    switch (window.location.host) {
      case 'dflow-int.supercaredev.com':
        msg = `You are on the version of DF+ that is available ONLY over CITRIX or 
        from inside a SuperCare location.  If niether of those situations apply to you, 
        AND you have been approved by IT Helpdesk as 2Factor enabled, 
        use this version instead:`
        href = window.location.href.replace(/dflow-int/, 'dflow')
        vtag = <a href={href}>https://dflow.supercaredev.com</a>
        break
      case 'dflow.supercaredev.com':
        msg = `You are on the version of DF+ that is only available if you have been approved
        by IT Helpdesk as 2Factor Enabled. If you are using CITRIX or in a SuperCare location,
        use this version instead:`
        href = window.location.href.replace(/dflow/, 'dflow-int')
        vtag = <a href={href}>https://dflow-int.supercaredev.com</a>
        break
      case 'scus08.supercaremed.com:3000':
        msg = `YOU ARE ON A TEST ENVIRONMENT`
        break
      case 'localhost:3000':
      case 'localhost:3002':
        msg = `YOU ARE ON LOCALHOST`
        break
      default:
        break
    }
    */

    const alignItems = 'center';
    const justify = 'space-around';

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Grid
              container
              spacing={16}
              alignItems={alignItems}
              direction="column"
              justify={justify}>
              {this.props.me.error && (
                <Grid item className={classes.gridItem}>
                  <div
                    style={{
                      padding: '12px 48px',
                      marginTop: 48,
                    }}>
                    {false && (
                      <Button
                        style={{ backgroundColor: 'red', color: '#fff' }}
                        onClick={this.props.logout_ac}
                        variant="contained"
                        size="small"
                        className={classes.button}>
                        Reset Settings
                      </Button>
                    )}
                    {this.props.me.error.code === 'AUTH_MISSING' ? (
                      <h3>---</h3>
                    ) : (
                      <h3>ERR CODE: {this.props.me.errorFrom}</h3>
                    )}
                  </div>
                </Grid>
              )}

              <Grid item className={classes.gridItem}>
                {this.state.msg && (
                  <h5 style={{ color: 'red' }}>{this.state.msg}</h5>
                )}
              </Grid>

              <Grid item className={classes.gridItem}>
                <p
                  style={{
                    paddingRight: 48,
                    paddingLeft: 48,
                    fontSize: 12,
                    marginBottom: 24,
                  }}>
                  If login prompt is not shown, refresh this page. You should
                  expect 2 sets of pop ups.
                </p>
              </Grid>

              <Grid item className={classes.gridItem}>
                <GLogin
                  onFailedGoogleResponse={this.onFailedGoogleResponse}
                  theme="dark"
                  clientId={process.env.REACT_APP_DOCFLOW_LOGIN_CLIENT_ID}
                  btnLabel="Login with @supercare.com account"
                />
              </Grid>
              <Grid item className={classes.gridItem}>
                <hr />
                <h5>DF VERSION {process.env.REACT_APP_VER}</h5>
              </Grid>
            </Grid>
          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotLoggedIn))
);

//export default withStyles(styles)(NotLoggedIn)
