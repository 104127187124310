export function todosInView(
  state = {
    isFetching: false,
    params: null,
    records: null,
    pagination: null,
    error: null,

    summary: null,
    isFetchingSummary: false,
    summaryError: null,

    //update activity details
    activity: {
      todoId: null,
      inprog: false,
      error: null
    }
  },
  action
) {
  switch (action.type) {
    case 'RESET_TODOS':
      return Object.assign({}, state, {
        params: null,
        records: null,
        pagination: null,
        error: null,

        summary: null,
        isFetchingSummary: false,
        summaryError: null,

        //update activity details
        activity: {
          todoId: null,
          inprog: false,
          error: null
        }
      })
    case 'REQUEST_TODOS':
      return Object.assign({}, state, {
        isFetching: true,
        params: action.params
      })

    case 'RECEIVED_TODOS':
      return Object.assign({}, state, {
        isFetching: false,
        pagination: action.pagination,
        records: action.records
      })
    case 'FAILED_RECEIVED_TODOS':
      return Object.assign({}, state, {
        isFetching: false,
        pagination: null,
        records: null,
        error: action.error
      })

    case 'REQUEST_TODOS_SUMMARY':
      return Object.assign({}, state, {
        isFetchingSummary: true,
        summary: null
      })

    case 'RECEIVED_TODOS_SUMMARY':
      return Object.assign({}, state, {
        isFetchingSummary: false,
        summary: action.summary
      })
    case 'FAILED_RECEIVED_TODOS_SUMMARY':
      return Object.assign({}, state, {
        isFetchingSummary: false,
        summary: null,
        summaryError: action.error
      })

    case 'UPDATE_TODO':
      return Object.assign({}, state, {
        activity: {
          todoId: action.pkg.todoId,
          inprog: true
        }
      })
    case 'RESPONSE_UPDATE_TODO':
      return Object.assign({}, state, {
        activity: {
          todoId: null,
          inprog: false
        }
      })
    case 'FAILED_UPDATE_TODO':
      return Object.assign({}, state, {
        activity: {
          error: action.error,
          inprog: false
        }
      })
    default:
      return state
  }
}

export function todoCreationActivity(
  state = {
    idn: null,
    state: 'new',
    error: false
  },
  action
) {
  switch (action.type) {
    case 'RESET_TODO_CREATION_ACTIVITY':
      return Object.assign({}, state, {
        idn: null,
        state: 'new',
        error: false
      })

    case 'CREATE_TODO':
      return Object.assign({}, state, {
        state: 'inprog',
        idn: action.idn
      })

    case 'RESPONSE_CREATE_TODO':
      return Object.assign({}, state, {
        state: 'done',
        resultPkg: action.resultPkg
      })

    case 'FAILED_CREATE_TODO':
      return Object.assign({}, state, {
        state: 'error',
        resultPkg: null,
        error: action.error
      })

    default:
      return state
  }
}

export function todosSummarySlicing(
  state = {
    isFetching: false,
    slicing: null,
    error: null,
    abc: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_TODOS_SLICING':
      return Object.assign({}, state, {
        isFetching: true,
        slicing: null
      })

    case 'RECEIVED_TODOS_SLICING':
      return Object.assign({}, state, {
        isFetching: false,
        slicing: action.slicing,
        abc: action.abc,
        error: null
      })

    case 'FAILED_RECEIVED_TODOS_SLICING':
      return Object.assign({}, state, {
        isFetching: false,
        slicing: null,
        abc: null,
        error: action.error
      })

    default:
      return state
  }
}
