import classNames from 'classnames';
import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  accordion_container: {
    height: '100%',
    minHeight: 'calc(100% - 10rem)'
  },
  accordion_list: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    backgroundColor: '#fff',
    overflow: 'hidden',
    borderRight: '1px solid #e6e6e6'
  },
  accordion_list_item: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)'
  },
  accordion_item: {
    '& .accordion_item_icon': {
      width: '2rem',
      height: '2rem',
      transition: 'transform 0.3s ease-in-out',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundImage:
        'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC)',
      opacity: '0.6',
      cursor: 'pointer'
    },
    '& .accordion_item_inner': {
      maxHeight: '0',
      overflow: 'hidden',
      transitionDuration: '0.5s',
      transitionProperty: 'max-height',
      zIndex: '1',
      position: 'relative'
    },
    '& .accordion_item_content': {
      opacity: '0',
      transform: 'translateY(-1rem)',
      transitionTimingFunction: 'linear, ease',
      transitionDuration: '0.1s',
      transitionProperty: 'opacity, transform',
      transitionDelay: '0.5s',
      padding: '0 1.2rem 1.2rem'
    },
    '&.opened .accordion_item_icon': {
      transform: 'rotate(180deg)'
    },
    '&.opened .accordion_item_inner': {
      maxHeight: '100rem',
      transitionTimingFunction: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
      transitionDuration: '0.5s',
      transitionProperty: 'max-height'
    },
    '&.opened .accordion_item_content': {
      opacity: '1',
      transform: 'translateY(0)',
      transitionDelay: '0.2s',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: '0.2s',
      transitionProperty: 'opacity, transform'
    }
  },
  accordion_item_line: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.8rem 1.2rem',
    backgroundColor: '#fff',
    zIndex: '2',
    position: 'relative'
  },
  accordion_item_title: {
    fontSize: '16px',
    margin: '0',
    fontWeight: '700',
    color: '#515151',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  special_title: {
    color: 'green',
    letterSpacing: '0.5px'
  },
  accordion_item_info: {
    color: 'grey',
    fontSize: '14px'
  },
  accordion_item_info_separator: {
    margin: '0px 6px',
    width: '5px',
    height: '5px',
    background: 'grey',
    borderRadius: '50%',
    display: 'inline-block'
  }
});

const AccordionItem = props => {
  const [opened, setOpened] = useState(true);

  const {
    classes,
    content: Content,
    title,
    value,
    special = false,
    onTitleClick,
    info,
    ...rest
  } = props;

  return (
    <div
      className={classNames(classes['accordion_item'], {
        opened
      })}
    >
      <div className={classes['accordion_item_line']}>
        <h4
          className={classes['accordion_item_title']}
          onClick={() => {
            if (onTitleClick) onTitleClick(value);
          }}
        >
          <span
            className={classNames(classes['accordion_item_label'], {
              [classes['special_title']]: special
            })}
          >
            {title}
          </span>
          {info ? (
            <>
              <i className={classes['accordion_item_info_separator']} />
              <span className={classes['accordion_item_info']}> {info}</span>
            </>
          ) : (
            ''
          )}
        </h4>
        <span
          className={'accordion_item_icon'}
          onClick={() => setOpened(!opened)}
        />
      </div>
      <div className={'accordion_item_inner'}>
        <div className={'accordion_item_content'}>
          <Content {...rest} />
        </div>
      </div>
    </div>
  );
};

const Accordion = ({ data, classes }) => {
  return (
    <div className={classes['accordion_container']}>
      <ul className={classes['accordion_list']}>
        {data.map((obj, key) => {
          return (
            <li className={classes['accordion_list_item']} key={key}>
              <AccordionItem {...obj} classes={classes} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default withStyles(styles)(Accordion);
