/* eslint no-unused-vars: "off" */
import { orange, purple } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import { createMuiTheme, withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { setTitle_ac } from '../../actions/me.ac.js'
import { fetchOpenOrdersTracking_ac } from '../../actions/open_orders.ac.js'
import FetchingStatementWrap from '../FetchingStatementWrap'
import Loader from '../Shared/Loader.js'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  title: {
    flexGrow: 1
  },
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  content: {
    marginTop: -48,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.25,
    height: 'calc(100% + 24px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  description: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

class OpenOdersTrackingLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      didBind: false
    }
  }

  componentDidMount() {
    this.props.setTitle_ac('Open Orders Tracking Report')
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation() {
    const { openOrders, match } = this.props
    if (openOrders.ifFetchingTrackingReport) return
    if (openOrders.trackingError) return

    let params = {
      startStr: this.props.match.params.start,
      endStr: this.props.match.params.end
    }
    params.startStr =
      params.startStr.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.startStr

    params.endStr =
      params.endStr.toLowerCase() === 'today'
        ? moment().format('MM-DD-YYYY')
        : params.endStr

    if (openOrders.html === null) this.props.fetchOpenOrdersTracking_ac(params)

    if(openOrders.html && !this.state.didBind) {
      window.$(".sec-hdr a.expand").bind("click", function(el){
        window.$( "#" + window.$(this).data('target') ).toggle();
      })
      window.$(".menuitem").bind("click",function(){
        window.$(this).children(".loading-msg").show();
      });
      window.$('.show-list-of-wids-btn').bind("click",function(){
        console.log('reveal wids');
        window.$("textarea", window.$(this).parent()).toggle();
      })
      this.setState({
        didBind: true
      })
    }
  }

  render() {
    const { classes, me, openOrders, lookups } = this.props
    const title = 'Open Orders Tracking Report'

    if (!lookups.ink) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    if (openOrders.ifFetchingTrackingReport) {
      return (
        <div className={classes.root}>
          <Loader message="...loading report" />
        </div>
      )
    }

    if (openOrders.trackingError) {
      return (
        <div className={classes.root}>
          <h4 className={classes.description}>
            ....error fetching open orders tracking report
          </h4>
        </div>
      )
    }

    if (!openOrders.html) {
      return (
        <>
          <FetchingStatementWrap
            title={title}
            msg={'This should not take more than 10 seconds'}
            isError={openOrders.trackingError}
          />
        </>
      )
    }

    return (
      <Grid container spacing={8} className={classes.fullHeight}>
        <Grid item xs={12} sm={12} className={classes.fullHeight}>
          <main className={classes.content}>
            <div dangerouslySetInnerHTML={{ __html: openOrders.html }} />
          </main>
        </Grid>
      </Grid>
    )
  }
}

OpenOdersTrackingLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  openOrders: stateFromStore.openOrders
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOpenOrdersTracking_ac,
      setTitle_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OpenOdersTrackingLanding))
)
