import {
  Chip,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  green,
  teal,
  yellow
} from '@material-ui/core/colors'
import {
  Mail as MailIcon,
  Phone as PhoneIcon
} from '@material-ui/icons'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import dateUtils from '../../util/dateProcessing'

const styles = theme => ({
  row: {
    verticalAlign: 'top',
    '&.even-row': {
      backgroundColor: yellow['50']
    },
    '&>td': {
      padding: 8
    }
  },
  chip: {
    height: 24,
    borderRadius: 6,
    fontWeight: 800,
    textTransform: 'uppercase',
    '&.state-new': {
      backgroundColor: '#ffffff',
      color: teal['700'],
      border: `2px solid ${teal['700']}`
    },
    '&.state-inprog': {
      backgroundColor: theme.palette.secondary['700'],
      color: '#ffffff'
    },
    '&.state-done': {
      backgroundColor: green['700'],
      color: '#ffffff'
    }
  },
  infoIcon: {
    fontSize: 14
  }
})

const SEX_MAP = {
  M: 'Male',
  F: 'Female',
  O: 'Other'
}
const STATE_MAP = {
  new: 'NEW',
  inprog: 'IN PROGRESS',
  done: 'DONE'
}
const SHIPPING_FIELDS = [
  'ShipToCareOf',
  'ShipToAddress_1',
  'ShipToAddress_2',
  'ShipToState',
  'ShipToCity',
  'ShipToZip'
]

const AddressBlock = props => {
  const { fields, isShipping } = props
  let fieldPrefix = isShipping ? 'ShipTo' : ''

  return (
    <>
      {fields[`${fieldPrefix}Address_1`] ? (
        <>
          {fields[`${fieldPrefix}Address_1`]}
          <br />
        </>
      ) : (
          ''
        )}
      {fields[`${fieldPrefix}Address_2`] ? (
        <>
          {fields[`${fieldPrefix}Address_2`]}
          <br />
        </>
      ) : (
          ''
        )}
      {fields[`${fieldPrefix}City`] ? `${fields[`${fieldPrefix}City`]}, ` : ''}
      {fields[`${fieldPrefix}State`] ? `${fields[`${fieldPrefix}State`]}, ` : ''}
      {fields[`${fieldPrefix}Zip`] || ''}
    </>
  )
}

const IconLabel = props => {
  const { icon, label } = props
  return (
    <div style={{ position: 'relative', paddingLeft: 18 }}>
      <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}>
        {icon}
      </div>
      <Typography variant="body2">
        {label}
      </Typography>
    </div>
  )
}

const RegistrationRow = props => {
  const { isEven, record, classes } = props
  const { fieldsMap } = record
  const hasShipping = SHIPPING_FIELDS.some(f => fieldsMap.hasOwnProperty(f))
  const rowClass = isEven ? 'even-row' : ''

  return (
    <TableRow className={[classes.row, rowClass].join(' ')}>
      <TableCell width="18%" style={{ padding: 8 }}>
        <Typography variant="body2">
          <strong>
            #{record._id}:&ensp;
            <span style={{ whiteSpace: 'nowrap' }}>
              {fieldsMap.Last_Name}, {fieldsMap.First_Name}
            </span>&ensp;
            <span style={{ whiteSpace: 'nowrap' }}>
              {fieldsMap.Birthday}
            </span>
          </strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>
            {SEX_MAP[fieldsMap.Sex || 'na']
              ? `${SEX_MAP[fieldsMap.Sex || 'na']} | `
              : ''}
            {fieldsMap.Height && `${fieldsMap.Height} | `}
            {fieldsMap.Weight}
          </strong>
        </Typography>
      </TableCell>

      <TableCell width="17%" style={{ padding: 8 }}>
        <Typography component="div" variant="body2">
          {fieldsMap.Email && (
            <IconLabel
              icon={<MailIcon color="primary" className={classes.infoIcon} />}
              label={fieldsMap.Email}
            />
          )}
          {fieldsMap.Phone && (
            <IconLabel
              icon={<PhoneIcon color="primary" className={classes.infoIcon} />}
              label={
                <>
                  {fieldsMap.Phone}
                  {fieldsMap.AltPhone && `, ${fieldsMap.AltPhone}`}
                </>
              }
            />
          )}
        </Typography>
      </TableCell>

      <TableCell width="16%" style={{ padding: 8 }}>
        <Typography component="div" variant="body2">
          <AddressBlock fields={fieldsMap} />
        </Typography>
      </TableCell>

      <TableCell width="20%" style={{ padding: 8 }}>
        <Typography
          component="div"
          variant="body2"
          gutterBottom
          style={{ borderBottom: '2px dashed #00000030' }}
        >
          {hasShipping ? (
            <>
              <Typography inline variant="inherit" color="primary">
                <strong>In Care of:&ensp;</strong>
              </Typography>
              {fieldsMap.ShipToCareOf || (
                <Typography color="textSecondary" {...props}>
                  N/A
                </Typography>
              )}
            </>
          ) : (
              <Typography variant="inherit" color="textSecondary">
                Same as billing
              </Typography>
            )}
        </Typography>

        <Typography component="div" variant="body2">
          <AddressBlock
            fields={fieldsMap}
            isShipping={hasShipping}
          />
        </Typography>
      </TableCell>

      <TableCell width="13%" style={{ padding: 8 }}>
        <Typography component="div" variant="body2">
          {fieldsMap.DoctorName}<br />
          {fieldsMap.DoctorPhone && (
            <IconLabel
              icon={<PhoneIcon color="primary" className={classes.infoIcon} />}
              label={fieldsMap.DoctorPhone}
            />
          )}
        </Typography>
      </TableCell>

      <TableCell width="16%" style={{ padding: 8 }}>
        <Typography component="div" gutterBottom>
          <Chip
            label={STATE_MAP[record.state]}
            className={`${classes.chip} state-${record.state}`}
          />
        </Typography>
        <Typography variant="body2">
          <strong>Created on:</strong>
          &ensp;{dateUtils.formatDate(record.created_ts)}
        </Typography>
        {record.created_ts !== record.last_ts && (
          <Typography variant="body2">
            <strong>Updated on:</strong>
            &ensp;{dateUtils.formatDate(record.last_ts)}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(RegistrationRow)
