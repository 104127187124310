import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { lookups_ac } from '../../actions/me.ac';
import {
  fetchMednecIssueLogsList_ac,
  getIssueCodes_ac,
} from '../../actions/mednec_issue_log.ac';
import { open_snack_ac } from '../../actions/snack.ac';
import dateUtil from '../../util/dateProcessing';
import FiltersForm from '../Shared/FiltersFormComponent/FiltersForm';
import { MednecIssueLogEntryForm } from './MednecIssueLogEntryForm';
import MednecIssueLogTable from './MednecIssueLogTable';
import { MednecIssueLogView } from './MednecIssueLogView';

const styles = (theme) => ({
  entryFormContainer: {
    height: '100%',
    overflowY: 'auto',
    padding: 8,
    borderLeft: '2px dashed #c5c5c5',
  },
  fullHeight: {
    height: '100%',
  },
});

const INITIAL_FILTER_VALUES = {
  issue_ids: [],
  export_to_google_sheet: 'no',
  start_date: '',
  end_date: '',
};

const validateDateField = (value) => {
  let isValid = value === '' || dateUtil.isValidDate(value);
  let errorText = isValid ? '' : 'Invalid date format!';

  return { isValid, errorText };
};

class MednecIssueLanding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultFilters: {
        export_to_google_sheet: {
          lbl: 'Export To Googlsheet',
          kind: 'select',
          options: [
            { code: 'yes', lbl: 'Yes' },
            { code: 'no', lbl: 'No' },
          ],
        },
        issue_ids: {
          lbl: 'Issues',
          kind: 'multi-select',
          options: [],
        },
        start_date: {
          kind: 'date',
          lbl: 'From',
          placeholder: 'MM-DD-YYYY',
          isRequired: true,
          validator: validateDateField,
        },
        end_date: {
          kind: 'date',
          lbl: 'To',
          placeholder: 'MM-DD-YYYY',
          isRequired: true,
          validator: validateDateField,
        },
      },
      filterData: {
        ...INITIAL_FILTER_VALUES,
        start_date: moment()
          .subtract(7, 'days') //default to past 7 days
          .format('MM-DD-YYYY'),
        end_date: moment().format('MM-DD-YYYY'),
      },
      selectedPageNum: 0,
      selectedRecordForEdit: null,
    };

    this.fetch = this.fetch.bind(this);
    this.onSelectForEdit = this.onSelectForEdit.bind(this);
  }

  componentDidMount() {
    const { me, mednecIssueLog } = this.props;
    const { isFetching, error, records } = mednecIssueLog;

    if (!me.user) return;

    if (
      !mednecIssueLog.isFetching &&
      !mednecIssueLog.error &&
      !mednecIssueLog.issues
    ) {
      this.props.getIssueCodes_ac();
    }

    if (isFetching || error || records) {
      return;
    }

    return this.fetch(this.state.selectedPageNum);
  }

  componentDidUpdate(prevProps) {
    const { me, mednecIssueLog } = this.props;
    const { isFetching, error, records } = mednecIssueLog;

    if (!me.user) return;

    if (
      mednecIssueLog.issues &&
      mednecIssueLog.issues.length &&
      !this.state.defaultFilters.issue_ids.options.length
    ) {
      this.setState((state) => {
        return {
          defaultFilters: {
            ...state.defaultFilters,
            issue_ids: {
              ...state.defaultFilters.issue_ids,
              options: mednecIssueLog.issues.map((issue) => ({
                code: issue._id.toString(),
                lbl: issue.lbl,
              })),
            },
          },
        };
      });
    }

    if (isFetching || error || records) {
      return;
    }
    this.fetch(this.state.selectedPageNum);
  }

  fetch(pageNum = 0) {
    const { filterData: filters } = this.state;
    let params = {
      issue_ids: filters.issue_ids,
      page: pageNum,
    };

    if (filters.start_date) {
      params.start_date = moment(filters.start_date).utc().format();
    }

    if (filters.end_date) {
      params.end_date = moment(filters.end_date).utc().format();
    }
    if (filters.export_to_google_sheet === 'yes') {
      params.export_to_google_sheet = 'yes';
    }
    this.props.fetchMednecIssueLogsList_ac(params);
  }

  applyFilters = (newFilters) => {
    this.setState(
      {
        selectedPageNum: 0,
        filterData: { ...newFilters },
      },
      () => this.fetch()
    );
  };

  onSelectForEdit(selectedRecord) {
    this.setState({
      selectedRecordForEdit: selectedRecord,
    });
  }

  render() {
    const { classes, mednecIssueLog } = this.props;
    const { selectedRecordForEdit } = this.state;

    return (
      <Grid container className={classes.fullHeight}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          item
          xs={8}
          className={classes.fullHeight}
          style={{ overflowY: 'auto' }}>
          <FiltersForm
            filters={{ ...this.state.defaultFilters }}
            filterSequence={[
              'issue_ids',
              'start_date',
              'end_date',
              'export_to_google_sheet',
            ]}
            initialValues={{ ...this.state.filterData }}
            applyFilters={this.applyFilters}
            isDisabled={mednecIssueLog.isFetching}
          />
          {mednecIssueLog.pagination && (
            <MednecIssueLogTable
              mednecIssueLog={mednecIssueLog}
              onSelectForEdit={this.onSelectForEdit}
              fetch={this.fetch}
            />
          )}
        </Grid>
        <Grid item xs={4} className={classes.entryFormContainer}>
          {selectedRecordForEdit && (
            <div>
              <div style={{ paddingLeft: 12 }}>
                <strong>
                  W{selectedRecordForEdit._id} A{selectedRecordForEdit.account}-
                  {selectedRecordForEdit.member}
                </strong>
              </div>
              <div>
                {selectedRecordForEdit.mednec_issue_log.map((entry, ei) => (
                  <MednecIssueLogView key={ei} issue={entry} />
                ))}
              </div>
              <div>
                <MednecIssueLogEntryForm
                  wo={selectedRecordForEdit}
                  wo_id={selectedRecordForEdit._id}
                  account={selectedRecordForEdit.account}
                />
              </div>
            </div>
          )}
          <hr />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  mednecIssueLog: stateFromStore.mednecIssueLog,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getIssueCodes_ac,
      fetchMednecIssueLogsList_ac,
      lookups_ac,
      open_snack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MednecIssueLanding))
);
