import { Grid, Typography } from '@material-ui/core';
import React from 'react';

const WoData = (props) => {
  const { wos_doc } = props;

  return (
    <Grid container style={{ padding: '16px' }}>
      <Grid item xs={12} style={{ marginBottom: '16px' }}>
        <Typography variant="h5" color="textPrimary">
          WO#: {wos_doc._id} | Account#: {wos_doc.acct} | Patient Name:{' '}
          {wos_doc.patient_nm}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ marginBottom: '8px' }}>
        <Typography variant="body1" color="textPrimary">
          <span style={{ display: 'block' }}>{wos_doc.addr_1}</span>
          {wos_doc.addr_2 && (
            <span style={{ display: 'block' }}>{wos_doc.addr_2}</span>
          )}
          <span style={{ display: 'block' }}>
            {wos_doc.city}, {wos_doc.state} {wos_doc.zip}
          </span>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body1" color="textPrimary">
          <strong>Shipping Instructions:</strong> {wos_doc.deliv_note}
        </Typography>
      </Grid>

      {/* Uncomment and adjust this section if you want to display tracking numbers */}
      {/* <Grid item xs={12}>
        <Typography variant="body1" color="textPrimary">
          <strong>Tracking Numbers:</strong>
          {wos_doc.trackingNumbers !== undefined ? (
            wos_doc.trackingNumbers.map((tn, i) => (
              <div key={'tn' + i.toString()}>{tn}</div>
            ))
          ) : (
            <div>No tracking numbers available</div>
          )}
        </Typography>
      </Grid> */}
    </Grid>
  );
};

export default WoData;
