export const actionTypes = {
  INIT_ACTION: 'INIT_ACTION',
  FLUSH_ACTION: 'FLUSH_ACTION',

  OPEN_STAGED_DOCS_VIEW: 'OPEN_STAGED_DOCS_VIEW',
  CLOSE_STAGED_DOCS_VIEW: 'CLOSE_STAGED_DOCS_VIEW',
  STAGE_DOC_FOR_COPY: 'STAGE_DOC_FOR_COPY',
  UNSTAGE_DOC_FOR_COPY: 'UNSTAGE_DOC_FOR_COPY',
  FLUSH_DOCS_FOR_COPY: 'FLUSH_DOCS_FOR_COPY',

  // Copy docs to an WU
  COPY_DOCS_TO_WU_PENDING: 'COPY_DOCS_TO_WU_PENDING',
  COPY_DOCS_TO_WU_SUCCESS: 'COPY_DOCS_TO_WU_SUCCESS',
  COPY_DOCS_TO_WU_ERROR: 'COPY_DOCS_TO_WU_ERROR',

  // ... other actions
};

export const initGlobalWUAction_ac = (actionName, props, callback) => ({
  type: actionTypes.INIT_ACTION,
  actionName,
  props,
  callback,
});

export const openStagedDocsView_ac = () => ({
  type: actionTypes.OPEN_STAGED_DOCS_VIEW,
});

export const closeStagedDocsView_ac = () => ({
  type: actionTypes.CLOSE_STAGED_DOCS_VIEW,
});

export const stageDocForCopy_ac = (doc, openStage = false) => ({
  type: actionTypes.STAGE_DOC_FOR_COPY,
  data: doc,
  openStage
});

export const unstageDocForCopy_ac = (doc) => ({
  type: actionTypes.UNSTAGE_DOC_FOR_COPY,
  data: doc,
});

export const flushDocsForCopy_ac = () => ({
  type: actionTypes.FLUSH_DOCS_FOR_COPY,
});

export const flushGlobalWUAction_ac = () => ({
  type: actionTypes.FLUSH_ACTION,
});

export function copyDocsToWU_ac(
  params,
  { before, after, onSuccess, onError } = {}
) {
  if (before) before();
  return (dispatch) => {
    dispatch({ type: actionTypes.COPY_DOCS_TO_WU_PENDING });

    return window.sch
      .post('/api/df/workunits/insert_docs', params)
      .then((result) => {
        if (result.error) {
          throw new Error(result.error);
        }

        dispatch({
          type: actionTypes.COPY_DOCS_TO_WU_SUCCESS,
          data: params,
        });

        if (onSuccess) {
          onSuccess(result);
        }

        if (after) {
          after(true);
        }
      })
      .catch((error) => {
        if (onError) {
          onError(error);
        }
        dispatch({
          type: actionTypes.COPY_DOCS_TO_WU_ERROR,
          error,
        });

        if (after) {
          after(false, error);
        }
      });
  };
}
