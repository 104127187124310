//reducer for dfaInView
export function myHistory(
  state = {
    isFetching: false,
    results: null,
    lastChecked: null,
    error: null,
    msg: ''
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_MY_HISTORY':
      return Object.assign({}, state, {
        isFetching: true,
        lastChecked: new Date(),
        msg: '',
        warning: action.warning || ''
      })
    case 'FAILED_RECEIVED_MY_HISTORY':
      return Object.assign({}, state, {
        isFetching: false,
        lastChecked: new Date(),
        error: action.error
      })
    case 'RECEIVED_MY_HISTORY':
      return Object.assign({}, state, {
        isFetching: false,
        results: action.results,
        msg: action.msg || ''
      })
    case 'RESET_MY_HISTORY': //reset the store
      return Object.assign({}, state, {
        isFetching: false,
        lastChecked: null,
        results: null,
        msg: ''
      })
    default:
      return state
  }
}
