import {
  REQUEST_HOURLY_WOS,
  RECEIVED_HOURLY_WOS,
  FAILED_HOURLY_WOS,
  REQUEST_HOURLY_WOS_LIST,
  RECEIVED_HOURLY_WOS_LIST
} from '../actions/hourly_wos.ac';

export function hourlyWos(
  state = {
    isFetching: false,
    error: null,
    data: [],
    meta: {},
    wosList: [],
  },
  action
) {
  switch (action.type) {
    case REQUEST_HOURLY_WOS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVED_HOURLY_WOS:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.pkg.data,
        meta: action.pkg.meta,
        error: null
      });

    case REQUEST_HOURLY_WOS_LIST:
      return Object.assign({}, state, {
        isFetching: true,
      });
    case RECEIVED_HOURLY_WOS_LIST:
      return Object.assign({}, state, {
        isFetching: false,
        wosList: action.pkg,
        error: null,
      });

    case FAILED_HOURLY_WOS:
      return Object.assign({}, state, {
        isFetching: false,
        data: [],
        meta: {},
        error: action.error,
        wosList: [],
      });

    default:
      return state
  }
}
