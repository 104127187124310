import {
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { blue, green, red, yellow } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchWorkUnitReport_ac } from '../../actions/dfworkunits_report_bydate_byhours.ac'
import { lookups_ac } from '../../actions/me.ac'
import { open_snack_ac } from '../../actions/snack.ac'
import DateUtil from '../../util/dateProcessing'
import FilterListing from '../FilterListing'
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent'
import Loader from '../Shared/Loader'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  pagination: {
    marginTop: 0
  },
  tableScrollable: {
    minHeight: '220px',
    height: 'calc(100% - 46px)',
    overflowY: 'auto'
  },
  tableHeader: {
    height: 40,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      boxShadow: '1px 0px 2px 1px black',
      backgroundColor: '#eaeaea'
    }
  },
  tableBodyRow: {
    height: 36
  },
  tableTotalRow: {
    height: 40,
    '& > th': {
      position: 'sticky',
      top: 40,
      left: 0,
      zIndex: 100,
      boxShadow: '0px 0px 0px 1px black',
      backgroundColor: '#eaeaea'
    }
  },
  totalRow: {
    backgroundColor: '#eaeaea',
    borderTop: '2px solid #101010',
    boxShadow: '0px 1px 2px 1px white'
  },
  tableBodyCell: {
    borderBottom: '1px solid black',
    boxShadow: '0px 1px 2px 1px white',
    cursor: 'pointer'
  },
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  fullHeight: {
    height: '100%'
  }
})

const DEFAULT_FILTERS = {
  bucket: {
    kind: 'radio',
    lbl: 'Bucket',
    options: [{ lbl: 'A', code: 'A' }, { lbl: 'B', code: 'B' }]
  },
  startDate: {
    lbl: 'From',
    kind: 'date',
    placeholder: 'MM-DD-YYYY',
    value: '',
    dirty: false
  },
  endDate: {
    lbl: 'To',
    kind: 'date',
    placeholder: 'MM-DD-YYYY',
    value: '',
    dirty: false
  }
}

const MAP_COLOR = {
  '0': red['100'],
  '1': yellow['100'],
  '2': green['100']
}

class DateAndHourReportComponent extends BaseLandingTemplate {
  constructor() {
    super()

    this.state = {
      filterSequence: ['bucket', 'startDate', 'endDate'],
      filters: {
        ...DEFAULT_FILTERS,
        startDate: {
          ...DEFAULT_FILTERS.startDate,
          value: moment('2000-01-01', 'YYYY-MM-DD').format('MM-DD-YYYY')
        },
        endDate: {
          ...DEFAULT_FILTERS.endDate,
          value: moment()
            .add(1, 'days')
            .format('MM-DD-YYYY')
        }
      },
      selectedFilters: {
        bucket: 'A'
      },
      selectedPageNum: 0
    }

    this.fetch = this.fetch.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
  }

  componentDidMount() {
    const { me, lookups, lookups_ac, workUnitReportsInView } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (
      workUnitReportsInView.isFetching ||
      workUnitReportsInView.error ||
      workUnitReportsInView.records
    ) {
      return
    }
    return this.fetch()
  }

  componentDidUpdate() {
    const { me, lookups, lookups_ac, workUnitReportsInView } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (
      workUnitReportsInView.isFetching ||
      workUnitReportsInView.error ||
      workUnitReportsInView.records
    ) {
      return
    }
    return this.fetch()
  }

  fetch() {
    const { selectedFilters, filters, selectedPageNum } = this.state

    const requestParams = {
      page: selectedPageNum,
      teams: this.props.match.params['teams']
        .split(',')
        .map(item => decodeURIComponent(item)),
      bucket: selectedFilters.bucket,
      start: moment(filters.startDate.value)
        .utc()
        .format(),
      end: moment(filters.endDate.value)
        .utc()
        .format()
    }
    this.props.fetchWorkUnitReport_ac(requestParams)
  }

  onRadioFilterChange = event => {
    const { name, value } = event.target

    this.setState({
      selectedFilters: {
        ...this.state.selectedFilters,
        [name]: value
      }
    })
  }

  onFilterClearField = fieldName => () => {
    this.setState({
      filters: {
        ...this.state.filters,
        [fieldName]: {
          ...this.state.filters[fieldName],
          value: '',
          dirty: false
        }
      }
    })
  }

  applyFilters = () => {
    const isStartDateValid = DateUtil.isValidDate(
      this.state.filters.startDate.value
    )
    const isEndDateValid = DateUtil.isValidDate(
      this.state.filters.endDate.value
    )

    if (!(isStartDateValid && isEndDateValid)) {
      return this.props.open_snack_ac({
        variant: 'error',
        message: `'From' and 'To' fields require valid dates`
      })
    }

    return this.fetch()
  }

  getPageStatement = pagination => {
    const { start, end, totalRecords, totalPagesAvailable } = pagination
    let pageEnd = totalRecords < end ? totalRecords : end

    return [
      `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
      ` Page ${this.state.selectedPageNum + 1} of ${totalPagesAvailable}`
    ].join('-')
  }

  onPageSelection = evt => {
    this.setState(
      {
        selectedPageNum: parseInt(evt.target.value, 10)
      },
      () => {
        this.fetch()
      }
    )
  }

  goToWorkUnitPage = (record, col_key) => {
    if (record[col_key] === 0) return
    const teams_in_report = this.props.match.params['teams'].split(/,/g)
    const dateToStr = [record._id.month, record._id.day, record._id.year].join(
      '-'
    )

    let url = `/dfa/workunits/b:${teams_in_report.join(
      ','
    )};d:${dateToStr};e:${col_key}/nd/1`
    this.props.changePath(url)
  }

  render() {
    const {
      filters,
      filterSequence,
      selectedFilters,
      selectedPageNum
    } = this.state
    const { me, workUnitReportsInView, classes, lookups } = this.props
    const filterListingComponent = (
      <FilterListing
        me={me}
        filters={filters}
        selected={selectedFilters}
        filterSequence={filterSequence}
        applyFilters={this.applyFilters}
        onFilterChange={this.onFilterChange}
        onFilterClearField={this.onFilterClearField}
        onRadioFilterChange={this.onRadioFilterChange}
      />
    )

    if (workUnitReportsInView.isFetching) {
      return <Loader classes={{ message: classes.message }} />
    }
    if (workUnitReportsInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>....error fetching reports</h4>
          {filterListingComponent}
        </div>
      )
    }

    if (!lookups.ink || !workUnitReportsInView.records) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
          {filterListingComponent}
        </div>
      )
    }

    if (!workUnitReportsInView.records.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No matching work units.</h4>
          {filterListingComponent}
        </div>
      )
    }
    const teams_in_report = this.props.match.params['teams'].split(/,/g)

    const headers = Object.keys(workUnitReportsInView.records[0])
      .slice(1)
      .filter(nm => nm.search(/_ids$/) === -1)
    const colWidth = (1 / (headers.length + 1)) * 100
    const totals = headers.reduce(
      (acc, curr) => Object.assign(acc, { [curr]: 0 }),
      {}
    )
    return (
      <Grid container spacing={0} className={classes.fullHeight}>
        <Grid item xs={12} md={6} className={classes.fullHeight}>
          {filterListingComponent}
          <main className={classes.content}>
            <div className={classes.pagination}>
              <Grid container alignItems="flex-end" style={{ padding: 8 }}>
                <Grid item xs={4}>
                  <Typography color="textSecondary">
                    {this.getPageStatement(workUnitReportsInView.pagination)}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography color="textPrimary">
                    DF Teams: {teams_in_report.join(', ')}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  <Select
                    value={selectedPageNum}
                    name="pageSelector"
                    onChange={this.onPageSelection}>
                    {workUnitReportsInView.pagination.totalPagesAvailable > 0 &&
                      _.range(
                        0,
                        workUnitReportsInView.pagination.totalPagesAvailable
                      ).map(pgNum => (
                        <MenuItem key={pgNum} value={pgNum}>
                          Page {1 + pgNum}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
            <div className={classes.tableScrollable}>
              <Table padding="dense">
                <TableHead>
                  <TableRow className={classes.tableHeader}>
                    <TableCell align="center" width={`${colWidth}% `}>
                      <Typography>Date in Logging Queue</Typography>
                    </TableCell>
                    {headers.map((header, hIdx) => (
                      <TableCell
                        key={hIdx}
                        align="center"
                        width={`${colWidth}% `}>
                        <Typography>{header}</Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow className={classes.tableTotalRow}>
                    <TableCell
                      align="center"
                      width={`${colWidth}% `}
                      className={classes.totalRow}>
                      <Typography>
                        <strong>Totals</strong>
                      </Typography>
                    </TableCell>
                    {headers.map((h, hi) => (
                      <TableCell
                        key={h}
                        align="center"
                        width={`${colWidth}% `}
                        className={classes.totalRow}>
                        <Typography>
                          <strong>
                            {workUnitReportsInView.bkt_label_hash[h].total}
                          </strong>
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {workUnitReportsInView.records.map((record, idx) => (
                    <TableRow key={idx} className={classes.tableBodyRow}>
                      <TableCell
                        align="center"
                        width={`${colWidth}% `}
                        className={classes.tableBodyCell}
                        style={{ backgroundColor: blue['100'] }}>
                        <Typography>
                          {[
                            record._id.month,
                            record._id.day,
                            record._id.year
                          ].join('-')}
                        </Typography>
                      </TableCell>
                      {headers.map((colKey, colIdx) => {
                        totals[colKey] += parseInt(record[colKey])

                        return (
                          <TableCell
                            key={colIdx}
                            align="center"
                            width={`${colWidth}% `}
                            className={classes.tableBodyCell}
                            style={{ backgroundColor: MAP_COLOR[`${colIdx} `] }}
                            onClick={() =>
                              this.goToWorkUnitPage(record, colKey)
                            }>
                            <Typography color="textPrimary">
                              <strong>{record[colKey]}</strong>
                            </Typography>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </main>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  workUnitReportsInView: stateFromStore.workUnitReportsInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac,
      open_snack_ac,
      fetchWorkUnitReport_ac,
      changePath: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DateAndHourReportComponent))
)
