import React from 'react';
import classnames from 'classnames';
import { MdAdd } from 'react-icons/md';

import { IconButton } from '@material-ui/core';

const QueueNode = props => {
  const { classes, activeQueue, queue, onClick } = props;

  const isActive = activeQueue && activeQueue._id === queue._id;

  return (
    <div className={classes['queueNode']}>
      <span
        className={classnames(classes['queueItem'], {
          queueItemActive: isActive
        })}
        onClick={() => {
          onClick(queue);
        }}
      >
        {queue.name}
      </span>
    </div>
  );
};

const QueueRow = props => {
  const {
    classes,
    onNodeClick,
    activeQueue,
    queues,
    handleAddClick,
    group
  } = props;

  return (
    <div className={classes['queueRow']}>
      {queues.map((queue, idx) => (
        <QueueNode
          key={idx}
          classes={classes}
          queue={queue}
          onClick={onNodeClick}
          activeQueue={activeQueue}
        />
      ))}
      <div style={{ display: 'inline-block' }}>
        <IconButton
          disabled={false}
          variant="outlined"
          className={classnames(classes['addButton'], {
            active:
              activeQueue &&
              !activeQueue._id &&
              (activeQueue.group && activeQueue.group._id === group._id)
          })}
          color="secondary"
          onClick={handleAddClick}
        >
          <MdAdd size="20" />
        </IconButton>
      </div>
    </div>
  );
};

export default QueueRow;
