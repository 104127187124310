import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import Loader from '../Shared/Loader'
import CustomSelect from './CustomSelect'
import CountViewTable from './CountViewTable'
import { fetchSalesDashRecords_ac } from '../../actions/salesdash.ac'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  totalsContainer: {
    padding: 8,
    marginBottom: 4,
    backgroundColor: `${theme.palette.secondary.main}10`
  },
  scrollableTableContainer: {
    height: 'calc(100% - 182px)',
    minHeight: '200px',
    overflowY: 'auto'
  }
});

const tabMap = {
  my_progress: {
    storeRecord: 'myProgress',
    apiPart: 'Progress'
  },
  my_buckets: {
    storeRecord: 'myBuckets',
    apiPart: 'Status'
  }
};

const totalsKeys = [
  "Setup This Month",
  "Potentially Commissionable This Month",
  "Commissionable This Month",
  "Setup This Quarter",
  "Potentially Commissionable This Quarter",
  "Potentially Commissionable Points"
];

class SummaryList extends React.Component {
  constructor() {
    super();

    this.state = {
      monthList: this.setMonthList(),
      quarterList: this.setQuarterList(),
      selection: {
        monthly: 1,
        selectedDate: moment().format('YYYY-MM-DD').toString()
      }
    };
  }

  componentDidMount() {
    window.prevSalesdashHomeTab = this.props.selectedTab;

    return this.fetch(this.state.selection);
  }

  componentDidUpdate(prevProps) {
    window.prevSalesdashHomeTab = this.props.selectedTab;

    const parentFilterChanged = (prevProps.region !== this.props.region)
      || (prevProps.salesPerson !== this.props.salesPerson);

    const tabChanged = prevProps.selectedTab !== this.props.selectedTab;

    if (parentFilterChanged || tabChanged) {
      return this.fetch(this.state.selection);
    }
  }

  fetch = ({ monthly, selectedDate }) => {
    let endpoint = [
      '/sales/summary',
      tabMap[this.props.selectedTab].apiPart,
      this.props.region,
      this.props.salesPerson,
      selectedDate.replace(/-/g, '/'),
      monthly
    ].join('/') + '/';

    return this.props.fetchSalesDashRecords_ac(this.props.selectedTab, { endpoint });
  }

  setMonthList = () => {
    let today = new Date();
    let monthList = [];

    for (let i = 0; i < 6; i += 1) {
      let currMonth = new Date(today.getFullYear(), today.getMonth() - i, 1);

      monthList.push({
        label: currMonth.toLocaleString('default', { month: 'short' }) + ' (' +
          currMonth.getFullYear() + ')',
        value: currMonth.toISOString().split('T')[0]
      });
    }

    return monthList;
  }

  setQuarterList = () => {
    let today = new Date();
    let quarter = Math.floor((today.getMonth() + 3) / 3);
    let quarterStart = new Date(today.getFullYear(), quarter * 3, 1);
    let quarterList = [];

    for (let i = 0; i < 18; i += 3) {
      let currQuarterStart = new Date(quarterStart.getFullYear(),
        quarterStart.getMonth() - (i + 3), 1);
      let currQuarterEnd = new Date(quarterStart.getFullYear(),
        quarterStart.getMonth() - i, 1);
      currQuarterEnd.setDate(currQuarterEnd.getDate() - 1);

      quarterList.push({
        label: currQuarterStart.toLocaleString('default', { month: 'short' }) + '-' +
          currQuarterEnd.toLocaleString('default', { month: 'short' }) + ' (' +
          currQuarterStart.getFullYear() + ')',
        value: currQuarterStart.toISOString().split('T')[0]
      });
    }

    return quarterList;
  }

  handleDateFilterChange = event => {
    const { name, value } = event.target;
    const monthly = name === 'monthly' ? 1 : 0;

    this.setState({
      selection: {
        monthly: monthly,
        selectedDate: value
      }
    });

    return this.fetch({ monthly, selectedDate: value });
  }

  getCountLink = summary => {
    let hrefUrl = [
      '/salesdash/wos',
      this.props.region,
      this.props.salesPerson,
      summary.work_bucket.replace(/ /g, '_'),
      summary.category.replace(/ /g, '_'),
      this.state.selection.selectedDate.replace(/-/g, '/')
    ].join('/') + '/';

    return hrefUrl;
  }

  isCountLink = summary => {
    if (this.props.selectedTab === 'my_progress') {
      return (summary.count > 0
        && !summary.count.toString().startsWith('+')
        && !summary.count.toString().includes('.'));
    }

    if (this.props.selectedTab === 'my_buckets') {
      return (summary.count > 0
        && summary.work_bucket !== 'Grand Total');
    }
  }

  getTotalCounts = (countsObj, record) => {
    let totalCounts = { ...countsObj };

    record.summary_list.forEach(summary => {
      if (totalCounts[summary.work_bucket]) {
        totalCounts[summary.work_bucket].count += parseFloat(summary.count)
      }
    })

    record.work_buckets.forEach(bucket => {
      if (bucket.includes('Goal') && totalCounts[bucket].count > 0) {
        return totalCounts[bucket].count = `+${totalCounts[bucket].count}`;
      }

      if (bucket.includes('Point')) {
        return totalCounts[bucket].count = totalCounts[bucket].count.toFixed(2);
      }
    })

    return totalCounts;
  }

  render() {
    const { selection, monthList, quarterList } = this.state;
    const { classes, salesdashRecords, selectedTab } = this.props;
    const { isFetchingRecords, recordError } = salesdashRecords;
    const summaryRecord = salesdashRecords[tabMap[selectedTab].storeRecord];

    let filtersComponent = (
      <Grid
        container
        spacing={16}
        direction="row-reverse"
        justify="center"
      >
        <Grid item xs={6} sm={4} md={2}>
          <CustomSelect
            name="quarterly"
            value={!selection.monthly ? selection.selectedDate : ''}
            label={(<>
              <span>Quarterly</span>
              {!selection.monthly
                ? (
                  <CheckCircleOutlineIcon
                    fontSize="small"
                    style={{ color: green['500'] }}
                  />
                )
                : ''}
            </>)}
            options={quarterList}
            onChange={this.handleDateFilterChange}
            disabled={isFetchingRecords}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <CustomSelect
            name="monthly"
            value={selection.monthly ? selection.selectedDate : ''}
            label={(<>
              <span>Monthly</span>
              {selection.monthly
                ? (
                  <CheckCircleOutlineIcon
                    fontSize="small"
                    style={{ color: green['500'] }}
                  />
                )
                : ''}
            </>)}
            options={monthList}
            onChange={this.handleDateFilterChange}
            disabled={isFetchingRecords}
          />
        </Grid>
      </Grid>
    );

    if (isFetchingRecords) {
      return (
        <>
          {filtersComponent}
          <Loader classes={{ message: classes.message }} />
        </>
      );
    }

    if (recordError) {
      return (
        <>
          {filtersComponent}
          <Typography
            color="error"
            className={classes.message}
          >
            Error fetchnig records!
        </Typography>
        </>
      );
    }

    if (!summaryRecord) {
      return (
        <>
          {filtersComponent}
          <div className={classes.message}>
            ...
        </div>
        </>
      );
    }

    const columnCounts = summaryRecord.work_buckets.slice(1)
      .reduce((acc, curr) => (
        Object.assign(acc, {
          [curr]: {
            count: 0,
            work_bucket: curr
          }
        })
      ), {})

    return (
      <>
        {filtersComponent}
        <div className={classes.scrollableTableContainer}>
          {selectedTab === 'my_progress'
            && !_.isEmpty(summaryRecord.totals)
            ? (
              <div className={classes.totalsContainer}>
                <Grid
                  container
                  spacing={16}
                  justify="space-evenly"
                >
                  {totalsKeys.map(totalsKey => (
                    summaryRecord.totals[totalsKey] !== undefined
                      ? (
                        <Grid
                          key={totalsKey}
                          container
                          item
                          xs={12 / totalsKeys.length}
                          direction="column"
                          alignItems="center"
                        >
                          <Typography variant="h5" color="primary">
                            <strong>
                              {summaryRecord.totals[totalsKey]}
                            </strong>
                          </Typography>
                          <Typography variant="subtitle1" align="center" color="textSecondary">
                            {totalsKey}
                          </Typography>
                        </Grid>
                      )
                      : ''))}
                </Grid>
              </div>
            )
            : ''}
          <CountViewTable
            headerList={summaryRecord.work_buckets}
            categories={summaryRecord.categories}
            cellList={summaryRecord.summary_list}
            isCountLink={this.isCountLink}
            getCountLink={this.getCountLink}
            totalCounts={this.getTotalCounts(columnCounts, summaryRecord)}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = stateFromStore => ({
  salesdashRecords: stateFromStore.salesdashRecords
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSalesDashRecords_ac
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SummaryList))
);
