import { actionTypes } from '../actions/idx_admin_locations.ac';

const initialState = {
  error: null,
  locations: null,
  isFetching: false
};

const initialFilterData = {
  name: '',
  addr1: '',
  city: '',
  order_type: '',
  zip: '',
  rep_id: '',
  oc_id: ''
};

const initialLocationData = {
  locationId: null,
  location: null,
  isFetching: false,
  error: null
};

export const idxAdminLocations = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_IDX_ADMIN_LOCATIONS:
      return {
        ...state,
        isFetching: true,
        locationId: action.locationId
      };

    case actionTypes.RECEIVED_IDX_ADMIN_LOCATIONS:
      return {
        ...state,
        error: null,
        isFetching: false,
        locations: action.locations
      };

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_LOCATIONS:
      return {
        ...state,
        error: true,
        locations: null,
        isFetching: false
      };

    default:
      return { ...state };
  }
};

export const idxAdminLocationDetails = (
  state = initialLocationData,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_LOCATION_ID:
      return {
        ...state,
        locationId: action.locationId
      };

    case actionTypes.FETCHING_IDX_ADMIN_LOCATION_DETAILS:
      return {
        ...state,
        isFetching: true
      };

    case actionTypes.RECEIVED_IDX_ADMIN_LOCATION_DETAILS:
      return {
        ...state,
        isFetching: false,
        location: {
          ...action.location,
          existingRelations: action.existingRelations || []
        },
        error: null
      };

    case actionTypes.FAILED_FETCHING_IDX_ADMIN_LOCATION_DETAILS:
      return {
        ...state,
        isFetching: false,
        location: null,
        error: true
      };

    default:
      return { ...state };
  }
};

export const idxAdminLocationSearchFilters = (
  state = initialFilterData,
  action
) => {
  switch (action.type) {
    case actionTypes.SAVE_LOCATION_SEARCH_FILTERS:
      return { ...action.filterData };

    default:
      return { ...state };
  }
};

export const idxAdminLocationSorting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SAVE_LOCATION_SORTING:
      return { ...action.filterData };

    default:
      return { ...state };
  }
};
