export const activeRentalsActionTypes = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  RECEIVED_ACTIVE_RENTALS_LIST: 'RECEIVED_ACTIVE_RENTALS_LIST',
  FAILED_ACTIVE_RENTALS_LIST: 'FAILED_ACTIVE_RENTALS_LIST',
  SET_CURRENT_IDN: 'SET_CURRENT_IDN',
  RECEIVED_IDN_ACTIVE_RENTAL: 'RECEIVED_IDN_ACTIVE_RENTAL',
  FAILED_IDN_ACTIVE_RENTAL: 'FAILED_IDN_ACTIVE_RENTAL'
}

export function fetchActiveRentalsList_ac(params) {
  return (dispatch) => {
    dispatch(setIsFetching(true))

    window.sch.post('/api/rentals/list', params)
      .then(response => {
        dispatch({
          type: activeRentalsActionTypes.RECEIVED_ACTIVE_RENTALS_LIST,
          record: response
        })
      })
      .catch(error => {
        dispatch({
          type: activeRentalsActionTypes.FAILED_ACTIVE_RENTALS_LIST,
          error: true
        })
      })
      .finally(() => {
        dispatch(setIsFetching(false))
      })
  }
}

export function updateIdnActiveRentalList_ac(params) {
  return (dispatch) => {
    dispatch(setIsFetching(true))

    window.sch.post('/api/rentals/list', params)
      .then(response => {
        dispatch({
          type: activeRentalsActionTypes.RECEIVED_IDN_ACTIVE_RENTAL,
          record: response,
          account: params.account
        })
      })
      .catch(error => {
        dispatch({
          type: activeRentalsActionTypes.FAILED_IDN_ACTIVE_RENTAL,
          error: true,
          account: params.account
        })
      })
      .finally(() => {
        dispatch(setIsFetching(false))
      })
  }
}

export function setCurrentIdn_ac(idn) {
  return (dispatch) => {
    dispatch({
      type: activeRentalsActionTypes.SET_CURRENT_IDN,
      idn
    })
  }
}

const setIsFetching = isFetching => ({
  type: activeRentalsActionTypes.SET_IS_FETCHING,
  isFetching
})
