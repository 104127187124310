import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography
} from '@material-ui/core'
import { green, grey, orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React, { useState } from 'react'

const styles = theme => ({
  expansionDetails: {
    flexWrap: 'wrap'
  },
  expansionDetailsItem: {
    margin: 4,
    padding: 4,
    borderBottom: `1px solid ${orange[500]}40`,
    backgroundColor: `${orange[500]}20`,
    flexGrow: 1
  },
  encounterIndex: {
    color: grey[500],
    fontWeight: 600
  },
  scheduleDate: {
    color: orange[500],
    fontWeight: 600
  },
  completeDate: {
    color: green[500],
    fontWeight: 600
  },
  undefinedLabel: {
    color: grey[500]
  },
  questionAnswers: {
    margin: 4,
    width: '100%',
    '&>.qa-head': {
      padding: 4,
      fontWeight: 600,
      borderBottom: `1px solid ${orange[500]}40`,
      backgroundColor: `${orange[500]}20`
    },
    '&>.qa-body': {
      padding: 4,
      border: `1px solid ${orange[500]}40`
    }
  },
  questionAnswerBlock: {
    display: 'flex',
    marginBottom: 4,
    padding: 4,
    borderBottom: `1px solid ${orange[500]}40`,
    '&.last-block': {
      borderBottom: 'unset'
    }
  },
  questionNumber: {
    flexGrow: 0,
    flexShrink: 0
  }
})

const QuestionAnswerBlock = props => {
  const { question, idx, subIdx = null, classes, isLastBlock = false } = props
  let qCount = 0

  return (
    <div
      className={
        classes.questionAnswerBlock + (isLastBlock ? ' last-block' : '')
      }>
      <Typography variant="body2" className={classes.questionNumber}>
        {idx + 1}.{subIdx !== null ? `${subIdx + 1}.` : ''}&ensp;
      </Typography>
      <div>
        <Typography variant="body2">
          {question.title ? (
            question.title
          ) : (
            <span className={classes.undefinedLabel}>No Title</span>
          )}
        </Typography>
        <Typography variant="body2" color="primary">
          {question.text ? (
            <strong>{question.text}</strong>
          ) : (
            <span className={classes.undefinedLabel}>No Text</span>
          )}
        </Typography>
        <div>
          {question.answer && (
            <Typography variant="body2" color="primary">
              <span className={classes.undefinedLabel}>
                <strong>Ans: </strong>
              </span>
              {question.answer.value ? (
                <>
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    component="span"
                    inline>
                    <strong>{question.answer.value}</strong>
                  </Typography>
                  {question.answer.dateAnswered && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                      inline>
                      &ensp;&ensp;
                      <i>
                        {moment(question.answer.dateAnswered).format(
                          'MM-DD-YYYY'
                        )}
                      </i>
                    </Typography>
                  )}
                </>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  inline>
                  No Answer
                </Typography>
              )}
            </Typography>
          )}
          {question.answerSeries &&
            question.answerSeries.map(as =>
              as.questions.map((subQuestion, idx) => (
                <QuestionAnswerBlock
                  key={`${subQuestion.id}${idx}`}
                  question={subQuestion}
                  idx={idx}
                  subIdx={qCount++}
                  classes={classes}
                />
              ))
            )}
        </div>
      </div>
    </div>
  )
}

const HealthCallEncounterCard = props => {
  const { encounter, idx, classes } = props

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <ExpansionPanel
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="body2" color="textPrimary" component="div">
          <span className={classes.encounterIndex}>#{idx + 1}.</span>&ensp;
          <strong>
            {encounter.transactionName || 'Encounter'}
            &ensp;|&ensp;
          </strong>
          {encounter.dateScheduled ? (
            <>
              From{' '}
              <span className={classes.scheduleDate}>
                {moment(encounter.dateScheduled).format('MM-DD-YYYY hh:mma')}
              </span>
            </>
          ) : (
            <span className={classes.undefinedLabel}>Not Scheduled</span>
          )}
          {encounter.scheduleDay !== null ||
          encounter.scheduleDay !== undefined ? (
            <>
              , For{' '}
              <strong>
                {encounter.scheduleDay}
                {encounter.scheduleDay === 1 ? ' day' : ' days'}
              </strong>
            </>
          ) : (
            <span className={classes.undefinedLabel}>
              , Undefined Schedule Day
            </span>
          )}
          {encounter.dateMarkedComplete ? (
            <>
              , Completed on{' '}
              <span className={classes.completeDate}>
                {moment(encounter.dateMarkedComplete).format(
                  'MM-DD-YYYY hh:mma'
                )}
              </span>
            </>
          ) : (
            <span className={classes.undefinedLabel}>, Not Completed</span>
          )}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionDetails}>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.expansionDetailsItem}>
          <strong>Program:</strong>&ensp;
          <Typography variant="body2" color="primary" component="span" inline>
            {encounter.program && encounter.program.name
              ? encounter.program.name
              : 'N/A'}
          </Typography>
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className={classes.expansionDetailsItem}>
          <strong>Episode of Care:</strong>&ensp;
          {encounter.episodeOfCare ? (
            <>
              <Typography
                variant="body2"
                color="primary"
                component="span"
                inline>
                {encounter.episodeOfCare.label}
                {encounter.episodeOfCare.externalId &&
                  ` (${encounter.episodeOfCare.externalId})`}
              </Typography>
              {encounter.episodeOfCare.dateStart && (
                <>
                  {', From '}
                  <strong>
                    {moment(encounter.episodeOfCare.dateStart).format(
                      'MM-DD-YYYY'
                    )}
                  </strong>
                  {encounter.episodeOfCare.dateEnd && (
                    <>
                      {' to '}
                      <strong>
                        {moment(encounter.episodeOfCare.dateEnd).format(
                          'MM-DD-YYYY'
                        )}
                      </strong>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            'N/A'
          )}
        </Typography>

        <div className={classes.questionAnswers}>
          <Typography variant="body2" className="qa-head">
            Encounter Question-Answers ({encounter.encounterQuestions.length})
          </Typography>
          <div className="qa-body">
            {encounter.encounterQuestions.map((encQ, idx) => (
              <QuestionAnswerBlock
                key={`${encQ.id}${idx}`}
                question={encQ}
                idx={idx}
                classes={classes}
                isLastBlock={idx === encounter.encounterQuestions.length - 1}
              />
            ))}
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

export default withStyles(styles)(HealthCallEncounterCard)
