import axios from 'axios';
export const TaskActionTypes = {
    RECEIVED_ALL_TASKS: 'RECEIVED_ALL_TASKS',
    RECEIVED_TASK_DETAILS: 'RECEIVED_TASK_DETAILS',
    FAILED_RECEIVING_ALL_TASKS: 'FAILED_RECEIVING_ALL_TASKS',
    FAILED_RECEIVING_TASK_DETAILS: 'FAILED_RECEIVING_TASK_DETAILS',
    SET_IS_FETCHING: 'SET_IS_FETCHING',
    SET_IS_FETCHING_DETAILS: 'SET_IS_FETCHING_DETAILS',
};
export function fetchAllTasksLists_ac(params) {
    return (dispatch) => {
        dispatch(fetching(true));
        const requests = params.map(param => (
            window.sch.post('/api/cc_proxy', { ...param })
        ))
        return axios.all(requests)
            .then(responses => {
                const result = responses.map(response => {
                    return {
                        cc_res: response.cc_res
                    }
                })
                dispatch({
                    type: TaskActionTypes.RECEIVED_ALL_TASKS,
                    records: result,
                });
            })
            .catch(error => {
                dispatch({
                    type: TaskActionTypes.FAILED_RECEIVING_ALL_TASKS,
                    error
                });
            })
            .finally(() => {
                dispatch(fetching(false));
            });
    }
}

export function fetchTaskDetails_ac(params, selectedTaskName) {
    return (dispatch) => {
        dispatch({
            type: TaskActionTypes.SET_IS_FETCHING_DETAILS,
            isFetchingDetails: true
        });
        window.sch.post('/api/cc_proxy', { ...params })
            .then(result => {
                dispatch({
                    type: TaskActionTypes.RECEIVED_TASK_DETAILS,
                    tasks: result.cc_res.tasks,
                    pagination: result.cc_res.pagination,
                    tags: result.cc_res.counts,
                    persons: result.cc_res.persons,
                    selectedTaskName
                });
            })
            .catch(error => {
                dispatch({
                    type: TaskActionTypes.FAILED_RECEIVING_TASK_DETAILS,
                    error
                });
            })
            .finally(() => {
                dispatch({
                    type: TaskActionTypes.SET_IS_FETCHING_DETAILS,
                    isFetchingDetails: false
                });
            });
    }
}

export const fetching = isFetching => ({
    type: TaskActionTypes.SET_IS_FETCHING,
    isFetching
})
