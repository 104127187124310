import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js';

const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {
    backgroundColor: 'red',
    color: '#fff',
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0',
  },
  formControl: {
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row',
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  padding4: {
    padding: 4,
  },
});

const PulseRedirect = ({
  changePage,
  applyMessage,
  match: {
    params: { pulse_code, packet_action },
  },
}) => {
  const [statusMsg, setError] = useState(
    '...retrieving appeal data from pulse code'
  );

  useEffect(() => {
    let endpoint =
      packet_action === 'initialize_packet'
        ? '/api/pulse/initialize_packet/' + pulse_code
        : '/api/pulse/view_packet/' + pulse_code;

    let errMsg =
      packet_action === 'initialize_packet'
        ? 'The Appeal data for ' +
          pulse_code +
          ' could not be fetched from Pulse'
        : 'Did not find Appeal packet linked with pulse Appeal ID' + pulse_code;
    window.sch
      .post(endpoint, { pulse_code })
      .then((data) => {
        // handle data
        let { msg } = data;
        msg.pulse_code = pulse_code;
        if (packet_action === 'initialize_packet') {
          applyMessage(msg);
        }
        changePage(
          '/redirect_to_patient/' + msg.account.toString() + '/appeal_requests'
        );
      })
      .catch((err) => {
        setError(errMsg);
      });
  }, [pulse_code, packet_action, applyMessage, changePage]);

  return <div>{statusMsg}</div>;
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  dcs: stateFromStore.dcs,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      close_snack_ac,
      applyMessage: (msg) => dispatch({ type: 'SOCKET_GOT_UB_MSG', msg }),
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PulseRedirect))
);
