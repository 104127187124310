/* eslint no-eval: "off" */
import * as jsPDF from 'jspdf';
import forge from 'node-forge';
import QRCode from 'qrcode';

window.jspdf = jsPDF;
//import * as robotomono from '../robotomono.js'

const PNG_FULL_WIDTH = 2550; //pixels
const PNG_FULL_HEIGHT = 3300; //pixels
//const X_OVER_Y = 0.772727272727273;
//const Y_OVER_X = 1.294117647058824;
const X_SCALE_FACTOR = 0.18;
const Y_SCALE_FACTOR = 0.18;
const PDF_WIDTH = 612;
const PDF_HEIGHT = 792;
//const Y_OFFSET = 9; //push down content this many pixels
const QR_CONSTANTS = { WIDTH: 26, HEIGHT: 26 };

function decrypt(pw, iv, encryptedBase64) {
  var encodedAsBytes = forge.util.decode64(encryptedBase64);
  /* parameter to decipher.update must be in forge buffer format */
  var encResultForgeBuf = forge.util.createBuffer(encodedAsBytes);
  var key = forge.pkcs5.pbkdf2(pw, '', 1, 16);
  var decipher = forge.cipher.createDecipher('AES-CBC', key);

  decipher.start({ iv: iv });
  decipher.update(encResultForgeBuf);
  decipher.finish();
  return forge.util.encode64(decipher.output.bytes());
}

function rotateBase64Image(image, rotation, callback) {
  var canvas = document.createElement('canvas');
  canvas.width = 1 * image.width;
  canvas.height = 1 * image.height;
  var ctx = canvas.getContext('2d');
  ctx.translate(image.width, image.height);

  //ctx.translate(PNG_FULL_WIDTH, PNG_FULL_HEIGHT)
  ctx.rotate((90 * rotation * Math.PI) / 180);
  ctx.drawImage(image, 0, 0);

  var rotated_image = new Image();
  rotated_image.src = canvas.toDataURL();
  rotated_image.onload = function () {
    callback(rotated_image);
  };
}

function loadImgFromPrivateStatic(urlWithKeys) {
  return new Promise((resolve, reject) => {
    return fetch(urlWithKeys.url, {
      mode: 'cors',
    })
      .then((res) => {
        return res.text();
      })
      .then((encryptedBase64) => {
        let decryptedImgData = decrypt(
          urlWithKeys.keys[0],
          forge.util.decode64(urlWithKeys.keys[1]),
          encryptedBase64
        );

        let image = new Image();
        image.onload = function () {
          if (urlWithKeys.rotation === 0) {
            return resolve(image);
          }

          //rotation needed
          return rotateBase64Image(image, urlWithKeys.rotation, resolve);
        };

        image.onerror = function (err) {
          console.error('err in image load', err);
          return reject(err);
        };

        image.src = 'data:image/png;base64,' + decryptedImgData;
      })
      .catch(() => {
        return reject(new Error('Failed proc img'));
      });
  });
}

const asyncLoadImgFromPrivateStatic = async (urlWithKeys) => {
  return await loadImgFromPrivateStatic(urlWithKeys);
};

function loadImg(url) {
  return new Promise((resolve, reject) => {
    var image = new Image();
    image.onload = function () {
      return resolve(image);
    };
    image.onerror = reject;
    image.src = url;
  });
}

function bold(str) {
  const boldItalicsRegex = /(\*{1,2})(.*?)\1/g;
  if (typeof str !== 'string') return false;

  str = str.replace(/\n/g, '|');
  let str_w_blanks = str.toString();
  let arr_of_matches = str.match(boldItalicsRegex);
  let coords = [];
  if (arr_of_matches === null) return false;

  arr_of_matches.forEach((a) => {
    let true_len =
      a.length - 4; /* len of text without wrapping ** (doubel astrisks) ** */

    let rg = new RegExp(
      a.replace(/\*/g, '\\*')
    ); /* regex to find start position of this match */
    let segment_without_asterisks = a.replace(/\*/g, '');

    let pos = str.search(rg); /* position of the 1st asterisk */
    coords.push({
      pos,
      len: true_len,
      val: segment_without_asterisks.split(''),
    });

    str = str.replace(a, segment_without_asterisks);
    str_w_blanks = str_w_blanks.replace(
      a,
      segment_without_asterisks.replace(/./g, ' ')
    );
  });

  let spaces = str.replace(/[^|]/g, ' ').split('');

  coords.forEach((c) => {
    for (let i = c.pos, j = 0; j < c.len; i++, j++) {
      spaces[i] = c.val[j];
    }
  });
  let pkg = {
    orig: str.replace(/\|/g, '\n'),
    orig_w_blanks: str_w_blanks.replace(/\|/g, '\n'),
    overlay: spaces.join('').replace(/\|/g, '\n'),
  };
  return pkg;
}

const generateQRImg = async (text) => {
  let qr_data_uri;
  try {
    qr_data_uri = await QRCode.toDataURL(text, {
      errorCorrectionLevel: 'H',
      version: 5,
    });
  } catch (err) {
    console.error(err);
    throw err;
  }
  let img = await loadImg(qr_data_uri);
  return img;
};

async function generatePdfFromExistingDocs(payloads) {
  let j = new jsPDF({
    unit: 'px',
    compress: true,
    format: [PDF_WIDTH, PDF_HEIGHT],
  });

  let pages = [];
  payloads.forEach((entry) => {
    let path_parts = entry.path.split(/\//g);
    let pngbaseid = path_parts.pop().split(/-/).shift();

    let baseUrl = [
      'https://s3-us-west-2.amazonaws.com/com.supercaremed.privatestatic',
      path_parts.join('/').replace(/faxes/, 'pngs'),
      pngbaseid + '-pg',
    ].join('/');

    entry.pages.forEach((pg, idxInPgs) => {
      pages.push({
        url: [baseUrl, (pg + 1).toString(), 'of', entry.pageCount].join(''),
        keys: entry.keys,
        rotation: entry.rotation ? entry.rotation[idxInPgs] : 0,
      });
    });
  });

  let bg_imgs = await Promise.all(pages.map(asyncLoadImgFromPrivateStatic));

  //j.context2d.rotate(180*Math.PI/180)

  pages.forEach((page, idx) => {
    if (idx > 0) {
      j.addPage([PDF_WIDTH, PDF_HEIGHT]);
    }

    j.addImage(
      bg_imgs[idx],
      'PNG',
      0,
      0,
      PNG_FULL_WIDTH * X_SCALE_FACTOR,
      PNG_FULL_HEIGHT * Y_SCALE_FACTOR
    ); //background image
  });
  let da;
  try {
    da = j.output('datauristring');
  } catch (joutErr) {
    console.error(joutErr);
  }
  //window.pdfData = da;
  return da;
}

function wrapStrToLines(str, max) {
  if (str.length < max) return [str];
  let parts = str.split(/ /g);
  let c = [];
  let t = '';
  parts.forEach((p) => {
    let count_of_asterisk_pairs = p.match(/\*\*/g);
    count_of_asterisk_pairs = count_of_asterisk_pairs
      ? count_of_asterisk_pairs.length
      : 0;
    if (t.length + p.length + 1 - count_of_asterisk_pairs > max) {
      c.push(t);
      t = '';
    }
    t += p.toString() + ' ';
  });
  if (t.trim().length) c.push(t);
  return c;
}

function addLineBreaks(str, max) {
  //str is probably a paragraph
  let lines = str.split(/\n/g);
  let _lines = [];
  lines.forEach((line) => {
    let j = wrapStrToLines(line, max);
    _lines = _lines.concat(j);
  });
  return _lines.join('\n');
}

async function generatePdfFromPagesData(pages) {
  //let qr_uri = await generateQR('hello there')
  let j = new jsPDF({
    unit: 'px',
    compress: true,
    format: [PDF_WIDTH, PDF_HEIGHT],
  });

  let bg_imgs = await Promise.all(
    pages.map((page) => loadImg(process.env.REACT_APP_WOS_API_URL + page.png))
  );

  j.setFontSize(12);
  pages.forEach(async (page, idx) => {
    if (idx > 0) {
      j.addPage([PDF_WIDTH, PDF_HEIGHT]);
    }
    j.addImage(
      bg_imgs[idx],
      'PNG',
      0,
      0,
      PNG_FULL_WIDTH * X_SCALE_FACTOR,
      PNG_FULL_HEIGHT * Y_SCALE_FACTOR
    ); //background image
    page.kv
      .filter((e) => e.txt)
      .forEach((entry) => {
        let [y, x] = entry.y_x;
        x *= X_SCALE_FACTOR;
        y *= Y_SCALE_FACTOR;
        y += page.y_offset ? page.y_offset : 0;
        j.text(entry.txt.toString(), x, y);
      });
  });
  return j.output('datauristring');
}

async function generatePdfFromInPageForm(pages, qrCodeHash) {
  const debugMode = false;
  let jConfig = {
    unit: 'px',
    compress: true,
    format: [PDF_WIDTH, PDF_HEIGHT],
  };

  if (pages[0].orientation) {
    if (pages[0].orientation === 'landscape') {
      jConfig = {
        unit: 'px',
        compress: true,
        format: [PDF_HEIGHT, PDF_WIDTH],
        orientation: 'landscape',
      };
    }
  }

  let j = new jsPDF(jConfig);

  let bg_imgs = await Promise.all(
    pages.map((page) =>
      loadImg(process.env.REACT_APP_WOS_API_URL + page.path_to_png)
    )
  );

  let qr_imgs = await Promise.all(
    pages.map((page, pageIdx) => {
      if (page.qr_code) return generateQRImg(qrCodeHash + ':PG' + pageIdx);
      return Promise.resolve(false);
    })
  );
  let formatters = {};
  pages.forEach(async (page, idx) => {
    const pageFontSize = page.fontSize ? page.fontSize : 11;
    const pageFontFamily = page.fontFamily ? page.fontFamily : 'courier';
    const lineHeightFactor = page.lineHeightFactor
      ? page.lineHeightFactor
      : 1.15;
    j.setLineHeightFactor(lineHeightFactor);

    j.setFont(pageFontFamily, 'normal');
    j.setFontSize(pageFontSize);
    if (page.formatters) {
      Object.keys(page.formatters).forEach((fnName) => {
        eval(
          "formatters['" +
            fnName +
            "']=function(str){" +
            page.formatters[fnName] +
            '}'
        );
      });
    }

    if (idx > 0) {
      if (page.orientation === 'landscape') {
        j.addPage([PDF_HEIGHT, PDF_WIDTH]);
      } else {
        j.addPage([PDF_WIDTH, PDF_HEIGHT]);
      }
    }

    if (!/BLANK_COVER/.test(page.path_to_png)) {
      if (page.orientation === 'landscape') {
        j.addImage(
          bg_imgs[idx],
          'PNG',
          0,
          0,
          PNG_FULL_HEIGHT * Y_SCALE_FACTOR,
          PNG_FULL_WIDTH * Y_SCALE_FACTOR
        ); //background image
      } else {
        j.addImage(
          bg_imgs[idx],
          'PNG',
          0,
          0,
          PNG_FULL_WIDTH * X_SCALE_FACTOR,
          PNG_FULL_HEIGHT * Y_SCALE_FACTOR
        ); //background image
      }
    }

    if (qr_imgs[idx] !== false) {
      j.addImage(
        qr_imgs[idx],
        'PNG',
        page.qr_code.x * X_SCALE_FACTOR,
        page.qr_code.y * Y_SCALE_FACTOR,
        QR_CONSTANTS.WIDTH,
        QR_CONSTANTS.HEIGHT
      ); //background image
    }

    if (debugMode) {
      j.setTextColor(255, 0, 0);
    }

    let pageFields = page.fields.reduce((acc, entry) => {
      if (entry.type !== 'text-multipos') {
        acc.push(entry);
        return acc;
      }
      for (let coord of entry.coords) {
        acc.push({
          ...entry,
          type: 'text',
          coords: coord,
        });
      }
      return acc;
    }, []);

    //page.fields.filter(e=>(e.value)).forEach( entry => {
    pageFields.forEach((entry) => {
      switch (entry.type) {
        case 'text': {
          if (undefined === entry.value || null === entry.value) {
            return;
          }

          let { x, y } = entry.coords;
          x *= X_SCALE_FACTOR;
          y *= Y_SCALE_FACTOR;
          y += page.y_offset ? page.y_offset : 0;

          j.setFontSize(entry.fontSize ? entry.fontSize : pageFontSize);
          if (debugMode) {
            if (
              entry.format &&
              typeof formatters[entry.format] === 'function'
            ) {
              j.text(formatters[entry.format](entry.nm), x, y);
            } else {
              j.text(entry.nm, x, y);
            }
          } else {
            let v = entry.value.toString();
            if (
              entry.format &&
              typeof formatters[entry.format] === 'function'
            ) {
              v = formatters[entry.format](v);
            }

            if (entry.maxWidth) {
              v = addLineBreaks(v, entry.maxWidth);
            }

            let b = bold(v);
            if (b === false) {
              if (typeof v !== 'string') {
                throw new Error('Invalid value type');
              }
              j.text(v, x, y);
              return;
            }

            //bold markup was found, do the thing
            j.text(b.orig, x, y);
            if (pageFontFamily === 'robotomono') {
              j.setFont(pageFontFamily, 'normal');
            } else {
              j.setFont(pageFontFamily, 'bold');
            }
            j.text(b.overlay, x, y);

            j.setFont(pageFontFamily, 'normal');
          }
          break;
        }
        case 'radio':
          entry.options
            .filter((o) => o.nm === entry.value)
            .forEach((o) => {
              let { x, y } = o.coords;
              x *= X_SCALE_FACTOR;
              y *= Y_SCALE_FACTOR;
              y += page.y_offset ? page.y_offset : 0;
              j.setFontSize(entry.fontSize ? entry.fontSize : pageFontSize);
              if (pageFontFamily === 'robotomono') {
                j.setFont(pageFontFamily, 'normal');
                j.text('x', x, y);
                j.text('x', x, y);
              } else {
                j.setFont(pageFontFamily, 'bold');
                j.text('x', x, y);
              }
              j.setFont(pageFontFamily, 'normal');
            });
          break;
        case 'checkbox':
          entry.options
            .filter((o) => o.checked === true)
            .forEach((o) => {
              let { x, y } = o.coords;
              x *= X_SCALE_FACTOR;
              y *= Y_SCALE_FACTOR;
              y += page.y_offset ? page.y_offset : 0;
              j.setFontSize(entry.fontSize ? entry.fontSize : pageFontSize);
              if (pageFontFamily === 'robotomono') {
                j.setFont(pageFontFamily, 'normal');
                j.text('x', x, y);
                j.text('x', x, y);
              } else {
                j.setFont(pageFontFamily, 'bold');
                j.text('x', x, y);
              }
              j.setFont(pageFontFamily, 'normal');
            });
          break;
        case 'table':
          entry.fields.forEach((tblField) => {
            [...Array(entry.nrows).keys()].forEach((tbl_row_pos) => {
              switch (tblField.type) {
                case 'text': {
                  let { x, y } = tblField.coords;
                  x *= X_SCALE_FACTOR;
                  y = y + tbl_row_pos * entry.y_add_offset;
                  y *= Y_SCALE_FACTOR;
                  y += page.y_offset ? page.y_offset : 0;

                  j.setFontSize(
                    tblField.fontSize ? tblField.fontSize : pageFontSize
                  );
                  let v = tblField.row_values[tbl_row_pos].toString();
                  if (
                    tblField.format &&
                    typeof formatters[tblField.format] === 'function'
                  ) {
                    v = formatters[entry.format](v);
                  }

                  let b = bold(v);
                  if (b === false) {
                    j.text(v, x, y);
                    return;
                  }

                  //bold markup was found, do the thing
                  j.text(b.orig, x, y);
                  if (pageFontFamily === 'robotomono') {
                    j.setFont(pageFontFamily, 'normal');
                  } else {
                    j.setFont(pageFontFamily, 'bold');
                  }
                  j.text(b.overlay, x, y);
                  j.setFont(pageFontFamily, 'normal');
                  break;
                }
                default:
                  break;
              }
            });
          });
          break;
        default:
          break;
      }
    });
  });
  return j.output('datauristring');
}

export default {
  decrypt,
  loadImgFromPrivateStatic,
  loadImg,
  bold,
  generateQRImg,
  generatePdfFromPagesData,
  generatePdfFromInPageForm,
  generatePdfFromExistingDocs,
};
