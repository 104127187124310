import React from 'react'
import util from '../../../util/index'

const border = {
  border: '1px solid black'
}
const borderRight = {
  border: '1px solid black',
  textAlign: 'right'
}

const getCartTableView = (STATE_COST_RULE, cartItems = [], total = 0) => {
  return (
    <>
      <p>SuperCare Cash Sale Quote</p>
      <table style={border}>
        <thead>
          <tr>
            <th style={border}>Product #</th>
            <th style={border}>Description</th>
            <th style={borderRight}>Per Unit Sale Price</th>
            <th style={borderRight}>Quantity</th>
            <th style={borderRight}>Extended Cost</th>
          </tr>
        </thead>

        <tbody>
          {cartItems.map(function(cartItem, i) {
            return (
              <tr key={i}>
                <td style={border}>
                  {cartItem.prod_code_v_id &&
                    cartItem.prod_code_v_id.split('-')[0].trim()}
                </td>
                <td style={border}>{cartItem.part_desc}</td>
                <td style={borderRight}>
                  {util.formatCurrency(
                    cartItem.unitCost * STATE_COST_RULE[cartItem.state]
                  )}
                </td>
                <td style={borderRight}>{cartItem.uomcount}</td>
                <td style={borderRight}>
                  {util.formatCurrency(
                    cartItem.uomcount *
                      cartItem.unitCost *
                      STATE_COST_RULE[cartItem.state]
                  )}
                </td>
              </tr>
            )
          })}
          <tr>
            <td colSpan={4}>Total Sale Price</td>
            <td style={borderRight}>{util.formatCurrency(total)}</td>
          </tr>
        </tbody>
      </table>
      <p>
        Note: Only Items/Supplies listed above are included in the Quote/Sale
        Price{' '}
      </p>
    </>
  )
}

export default getCartTableView
