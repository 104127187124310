import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/GetApp';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { markAsDownloaded_ac } from '../../actions/all_scripts_report.ac.js';
import { open_snack_ac } from '../../actions/snack.ac.js';
import dateUtil from '../../util/dateProcessing';
import Loader from '../Shared/Loader';
import { ReferralXML } from './ReferralXML.js';

import moment from 'moment';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5,
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '50%',
  },
  docType: { fontSize: 11, color: '#ff9100', fontWeight: 500 },
  docId: { fontSize: 11, fontWeight: 400 },
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
  },
  cardHdrRoot: {
    backgroundColor: '#b2dfdb',
    paddingBottom: 8,
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800,
  },
  cardContent: {
    height: 580,
    '-webkitOverflowScrolling': 'touch',
    overflowY: 'scroll',
  },
  tableScrollable: {
    minHeight: '220px',
    height: 'calc(100% - 46px)',
    overflowY: 'auto',
  },
  tableHeader: {
    height: 20,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
    },
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
  },
  tabLabelContainer: { padding: '3px 16px 3px 0' },
  tabRoot: {
    minWidth: 16,
    minHeight: 24,
  },
  tabsRoot: {
    minHeight: 26,
  },
});

const getDownloadUrl = (s3key) => {
  if (s3key === null) return;
  let target_url = `/api/s3/object/getsignedurl`;
  return window.sch
    .post(target_url, { s3key })
    .then((pkg) => {
      let download_url = pkg.signedUrl;
      window.open(download_url, pkg.basName);
    })
    .catch((error) => {
      console.error('error:', error);
    });
};

const FilesTableViewV2 = ({ files, classes }) => {
  return (
    <>
      <Divider component="hr" />
      <div className={classes.fake_tableScrollable}>
        <Table padding="dense">
          <TableHead padding="dense">
            <TableRow className={classes.tableHeader}>
              <TableCell align="left" width="10%">
                #
              </TableCell>
              <TableCell align="left" width="35%">
                Status
              </TableCell>
              <TableCell align="left" width="35%">
                Divider
              </TableCell>
              <TableCell align="left" width="20%">
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(files || []).map((file, fileIndex) => (
              <TableRow key={fileIndex}>
                <TableCell
                  align="left"
                  width="12%"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <span style={{ fontSize: 10 }}>
                    {(file.position || fileIndex) + 1}
                  </span>
                </TableCell>
                <TableCell
                  align="left"
                  width="38%"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}>
                  <div>{dateUtil.relativeTime(file.downloads.lastTs)}</div>
                  <div style={{ fontSize: 10 }}>
                    {file.downloads.count > 0
                      ? `Has ${file.downloads.count} import attempts`
                      : 'Not imported'}
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  variant="body"
                  style={{ padding: '0px' }}
                  className={classes.tableBodyCell}>
                  <div>
                    <span className={classes.docType}>
                      {file.comment.toUpperCase()}
                    </span>
                    <br />
                    <span className={classes.docId}>
                      {file.originalFileName}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  variant="body"
                  className={classes.tableBodyCell}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      getDownloadUrl(
                        's3://stub/' +
                          file.s3bucketName +
                          '/' +
                          file.s3pathAndKey
                      )
                    }>
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

class ReferralDetailsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      referralId: '',
      idn: '',
      isDownloading: false,
      selectedTab: 'details',
    };
  }

  componentDidUpdate(prevProps) {
    const { referral: previousReferral } = prevProps.allScriptsReportInView;
    const { referral, records } = this.props.allScriptsReportInView;
    if (previousReferral && previousReferral.id === referral.id) return;

    if (referral && records) {
      const referralRecord = records.find((item) => item.id === referral.id);
      this.setState({
        files: referralRecord?.files,
        idn: referralRecord?.idn,
        referralId: referralRecord?.id,
      });
    }
  }

  parseFiles = (files, user, referralId) => {
    // skip download attempted file
    return files
      .filter((file) => !file.downloads?.count)
      .filer((file) => file?.type === 'application/pdf')
      .map((file, idx) => ({
        type: file?.type,
        s3bucketName: file?.s3bucketName,
        s3pathAndKey: file?.s3pathAndKey,
        position: idx,
        meta: {
          autoindexRequest: `#autoindex #${this.state.idn} #${file.comment}`,
          assignToTeam: user?.team,
          assignToUser: user?.member,
          modeLabels: ['ALLSCRIPTS', 'R' + referralId.toString()],
          productLabel: user?.product,
          tmLabel: user?.rae || '',
        },
      }));
  };

  handleTabSelect = (e, _selectedTab) => {
    this.setState({ selectedTab: _selectedTab });
  };

  downloadReferral_v2 = async (includeDone = false) => {
    const { files, referralId } = this.state;
    const isImportAvailable = includeDone
      ? true
      : files.some((file) => file.downloads?.count === 0);
    if (!isImportAvailable) {
      this.props.open_snack_ac({
        variant: 'info',
        message: 'No files available to import!',
      });
    }

    let non_pdf = [];
    let total_files = files
      .filter((f) => {
        if (includeDone) return true;
        return f.downloads?.count === 0;
      })
      .filter((f) => {
        if (f?.type === 'application/pdf') return true;
        non_pdf.push(f);
        return f?.type === 'application/pdf';
      });

    //batchify the files into batches of a max of 10. For example, if there are 24 fiels, there should be 3 batches
    let batches = [];
    let batch = [];
    for (let i = 0; i < total_files.length; i++) {
      batch.push(total_files[i]);
      if (batch.length === 10) {
        batches.push(batch);
        batch = [];
      }
    }
    if (batch.length > 0) {
      batches.push(batch);
    }

    this.setState({
      isDownloading: true,
    });

    //construct message about the nonpdf files and the number of batches
    let message = `Beginning import ${batches.length}`;
    let non_pdf_msg = ``;
    if (non_pdf.length > 0) {
      non_pdf_msg += `\nNOTE: ${non_pdf.length} non-pdf files were NOT included in the import(s):\n`;
      non_pdf_msg += non_pdf
        .map((n) => {
          return n.originalFileName + ' (' + n.comment + ')';
        })
        .join('\n');
    }

    this.props.open_snack_ac({
      variant: 'info',
      message: message + non_pdf_msg,
    });

    //call await downloadbatch for each batch
    for (let i = 0; i < batches.length; i++) {
      await this.downloadBatch(batches[i], referralId, i);
    }

    this.props.open_snack_ac({
      variant: 'info',
      message: `Done with import attempts`,
    });

    const botText = `Imported ${total_files.length} files for referral ${referralId} in ${batches.length} batches. ${non_pdf_msg}`;
    const botPayload = {
      event_kind: 'REFERRAL_IMPORT',
      text: botText,
      subject: botText,
      text_for_email: botText,
    };

    return window.sch
      .post('/api/utils/chatbot/send', botPayload)
      .then((results) => {
        this.setState({
          isDownloading: false,
        });
      })
      .catch((botErr) => {
        console.log('error sending bot message', botErr);
        this.setState({
          isDownloading: false,
        });
      });
  };

  downloadBatch = (files, referralId, batchNum) => {
    let now = new moment();
    let payload = {
      assign_to: 'me',
      outputFileBasename: [
        'Merged',
        referralId + '-B' + batchNum,
        now.format('YYYYMMDD_hhmmss'),
      ].join('_'),
      s3keys: files.map((f) => {
        return [
          'https://s3.us-west-2.amazonaws.com',
          f.s3bucketName,
          f.s3pathAndKey,
        ].join('/');
      }),
    };

    const firstFileBaseName = files[0].s3pathAndKey.split(/\//g).pop();
    const s3bucketName = files[0].s3bucketName;
    const s3pathAndKeysForMarking = files.map((f) => f.s3pathAndKey);

    return window.sch
      .post('/api/utils/merge_pdfs_on_s3', payload)
      .then((results) => {
        console.log(results);

        let basePath = results.result.mergedKey.split(/\//g).slice(4, 5); //allscripts
        let mergedPdfName = results.result.mergedKey.split(/\//g).pop();
        let s3pathAndKey = [basePath, firstFileBaseName, mergedPdfName].join(
          '/'
        );

        const payload_for_ds_create = {
          trm_source: 'ALLSCRIPTS',
          assign_to: 'me',
          idn: 'NON-AUTOINDEX',
          account: 'NA',
          referral_id: referralId + '-B' + batchNum,
          files: [
            {
              meta: {
                trm_source: 'AllSCRIPTS',
                referralId,
              },
              type: 'application/pdf',
              s3bucketName,
              s3pathAndKey,
              position: 0,
            },
          ],
          qname:
            this.state.assignTo === 'unassigned'
              ? 'DIRECT-V2'
              : 'Q-' + this.props.me.user.nm,
          triggerWithMetadata: true,
        };
        return window.sch.post(
          '/api/docsubmission/create_from_s3_copy',
          payload_for_ds_create
        );
      })
      .then((response) => {
        const markAsDownloadParams = {
          id: referralId,
          ds_id: response.doc._id,
          s3pathAndKeys: s3pathAndKeysForMarking,
        };
        this.props.markAsDownloaded_ac(markAsDownloadParams);
      })
      .then((markResults) => {
        this.props.open_snack_ac({
          variant: 'info',
          message: referralId + '-B' + batchNum + ' Marked as Imported',
        });
      })
      .catch((error) => {
        this.props.open_snack_ac({
          variant: 'error',
          message: error.message || 'Error Importing Referral',
        });
      });
  };

  changeDescription = (description, fileIndex) => {
    const { files } = this.state;
    files[fileIndex].comment = description;
    this.setState({
      files,
    });
  };

  render() {
    const dividerOptions = _.get(this.props, 'lookups.ink.divs', []).map(
      (d) => {
        return { code: d, lbl: d };
      }
    );

    const { allScriptsReportInView, classes } = this.props;
    const { referral } = allScriptsReportInView;

    if (allScriptsReportInView.isFetchingReferralDetails) {
      return (
        <main className={classes.content}>
          <Loader message="...loading referral details" />
        </main>
      );
    }

    if (allScriptsReportInView.referralError) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>
            ....error fetching referral record
          </h4>
        </div>
      );
    }

    if (allScriptsReportInView.referral === null) {
      return (
        <div className={classes.pageStatement}>
          <Typography className={classes.details} align="center">
            Click on{' '}
            <span>
              {' '}
              <DownloadIcon />{' '}
            </span>{' '}
            to view details.
          </Typography>
        </div>
      );
    }

    if (!allScriptsReportInView.referral) {
      return (
        <div className={classes.pageStatement}>
          <h4 className={classes.details}>Referral record Not Found</h4>
        </div>
      );
    }

    const cardHdrClasses = {
      root: classes['cardHdrRoot'],
      title: classes['cardHdrContent'],
      subheader: classes['cardHdrSubHdr'],
    };

    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot,
    };
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer,
    };

    return (
      <div className={classes.content}>
        <Tabs
          classes={tabsClass}
          value={this.state.selectedTab}
          onChange={this.handleTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab
            value={'details'}
            label="Download Referral Docs"
            classes={tabClass}
          />
          <Tab value="tree" label="Details Tree View" classes={tabClass} />
        </Tabs>
        {this.state.selectedTab === 'details' && (
          <Card className={classes.card}>
            <CardHeader
              classes={cardHdrClasses}
              title={
                <span style={{ fontSize: 12, fontWeight: 600 }}>
                  Referral ID: {referral.id} {this.state.idn}{' '}
                  {
                    referral.parsedXML[0]?.OutboundDataFeed?.Patient?.Gender
                      ?.DisplayValue
                  }
                </span>
              }
              subheader={
                <span style={{ fontSize: 12 }}>
                  Hospital Name:{' '}
                  {
                    referral.parsedXML[0]?.OutboundDataFeed?.Patient
                      ?.HospitalName
                  }
                </span>
              }
            />
            <CardContent>
              <Grid container className={classes.cardContent}>
                <Grid item xs={6}>
                  <span style={{ fontSize: 11 }}>
                    Received At {dateUtil.relativeTime(referral.tsReceived)}{' '}
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <span style={{ fontSize: 11 }}>
                    MRN: {referral.parsedXML[0]?.OutboundDataFeed?.Patient?.MRN}
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <span style={{ fontSize: 11 }}>
                    B2B Code:{' '}
                    {referral.parsedXML[0]?.OutboundDataFeed?.Patient?.B2BCode}{' '}
                  </span>
                </Grid>

                <Grid item xs={12}>
                  <FilesTableViewV2
                    files={this.state.files}
                    classes={classes}
                    dividerOptions={dividerOptions}
                    changeDescription={this.changeDescription}
                  />
                </Grid>
                {/*<Grid item xs={12}>
                  <TeamPcrSelector
                    btnLabel={`Import ${nFilesToDownload} Files`}
                    disableBtn={
                      nFilesToDownload === 0 || this.state.isDownloading
                    }
                    disableNewdocs={true}
                    isReferralDownload={true}
                    onBtnClicked={this.downloadReferral}
                  />
                  </Grid>*/}
              </Grid>
              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                disabled={this.state.isDownloading}
                variant="contained"
                onClick={this.downloadReferral_v2}>
                Upload for V2 Indexing - Q-{this.props.me.user.nm} - Only Docs
                Not Already Imported
              </Button>

              <Button
                style={{ marginBottom: 10, marginTop: 10 }}
                variant="contained"
                disabled={this.state.isDownloading}
                onClick={() => this.downloadReferral_v2(true)}>
                Upload for V2 Indexing - Q-{this.props.me.user.nm} - Include
                Docs Already Imported
              </Button>
            </CardContent>
          </Card>
        )}

        {this.state.selectedTab === 'tree' && (
          <ReferralXML referralExists={true} autoExpanded={true} />
        )}
      </div>
    );
  }
}

ReferralDetailsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  allScriptsReportInView: stateFromStore.allScriptsReportInView,
  lookups: stateFromStore.lookups,
});

// might be needed in future
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      markAsDownloaded_ac,
      open_snack_ac,
    },
    dispatch
  );

export const ReferralDetails = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ReferralDetailsComponent))
);
