import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeNotifsPanel_ac, openNotifsPanel_ac } from '../actions/snack.ac.js'
import { updateNotifWorkerRecords_ac } from '../actions/web_workers.ac.js'

const styles = () => ({
  resultItem: {
    marginTop: 8,
    marginBottom: 4,
    paddingBottom: 4,
    borderBottom: '1px solid #90A4AE'
  },
  resultItemStrikeout: {
    marginTop: 8,
    marginBottom: 4,
    paddingBottom: 4,
    borderBottom: '1px solid #90A4AE',
    textDecoration: 'line-through',
    backgroundColor: 'orange'
  },
  idn: {
    display: 'block',
    padding: 0,
    paddingTop: 4,
    marginTop: 4,
    cursor: 'pointer',
    fontSize: 12
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 4,
    width: '100%',
    overflowY: 'hidden'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    padding: 12,
    paddingLeft: 0
  },
  patientLbl: {
    fontSize: 12,
    margin: '2px 8px 0 4px',
    fontWeight: 600,
    color: '#00675b',
    padding: 2
  },
  pageStatement: {
    paddingTop: 8,
    fontSize: 11
  },
  accountLbl: {
    fontSize: 12,
    padding: '3px',
    margin: '2px 8px 8px 4px',
    fontWeight: 600,
    color: '#000',
    backgroundColor: '#BBDEFB',
    borderBottom: '1px solid #000'
  },
  gridContainer: {
    paddingTop: 0,
    paddingRight: 0
  },
  grid: {
    paddingRight: 40
  },
  card: {
    minHeight: 40
  },
  cardContent: {
    root: {
      padding: 10
    }
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3
  },
  pending: { backgroundColor: 'orange', fontWeight: 400, color: '#fff' },
  owner: { color: '#7f8486' },
  tm: { backgroundColor: '#689F38' },
  hdmsAcct: { backgroundColor: '#BBDEFB', color: '#000' },
  exactHdmsAcct: {},
  pastSla: { backgroundColor: 'red', color: '#fff', fontWeight: 600 },
  listItem: {
    paddingLeft: 2
  },
  listItemInvalid: {
    paddingLeft: 2,
    backgroundColor: 'lightgray'
  },
  listItemText: {
    fontSize: 12,
    color: 'blue'
  },
  link: {
    color: 'blue',
    border: 'none',
    cursor: 'pointer',
    paddingRight: 4,
    paddingLeft: 4,
    fontSize: 10,
    borderRight: '1px solid #7aa9ff'
  },
  docEntry: {
    cursor: 'pointer',
    marginLeft: 12,
    padding: 8
  }
})

class MyNotifs extends React.Component {
  constructor(props) {
    super(props)
    this.gotoIdn = this.gotoIdn.bind(this)
    this.gotIt = this.gotIt.bind(this)
  }
  gotoIdn = (idn, tab) => () => {
    this.props.changePage(`/dfa/idn/${idn.replace(/ /g, '_')}/tabs/${tab}`)
    return
  }
  gotIt = record => () => {
    return window.sch
      .post('/api/alog/user/ack', { aId: record._id })
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }

        let _records = this.props.notifWebWorker.records.map(r => {
          if (record._id === r._id) {
            r.strikeout = true
          }
          return r
        })

        this.props.updateNotifWorkerRecords_ac(_records)
        return
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { classes, notifWebWorker } = this.props

    if (notifWebWorker.initiated === false) {
      return (
        <div className={classes.root}>
          <div className={classes.pagination}>
            <Typography className={classes.pageStatement} color="textSecondary">
              ...loading notifications
            </Typography>
          </div>
        </div>
      )
    }

    if (notifWebWorker.error) {
      return (
        <div className={classes.root}>
          <div className={classes.pagination}>
            <Typography className={classes.pageStatement} color="textSecondary">
              Error loading notifications.
            </Typography>
          </div>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={2} sm={12} md={6} className={classes.gridItem}>
            <div className={classes.pagination}>
              <div style={{ color: 'blue', fontSize: 12 }}>
                You have {notifWebWorker.records.length} notifications:
              </div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.scrollArea}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={12} md={12} className={classes.gridItem}>
              <List component="nav" className={classes.list}>
                {notifWebWorker.records.map((h, hi) => (
                  <div
                    className={
                      h.strikeout
                        ? classes.resultItemStrikeout
                        : classes.resultItem
                    }
                    key={hi}>
                    <div className={classes.idn}>{h.nm}</div>
                    <div className={classes.idn}>
                      {h.kind} {h.tsTxt}
                    </div>
                    <button onClick={this.gotIt(h)} className={classes.link}>
                      Got It
                    </button>
                    <button
                      className={classes.link}
                      onClick={this.gotoIdn(h.nm, 'workunits')}>
                      Go to Workunits
                    </button>
                  </div>
                ))}
              </List>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

MyNotifs.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  notifWebWorker: stateFromStore.notifWebWorker,
  idnInView: stateFromStore.idnInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path),
      updateNotifWorkerRecords_ac,
      openNotifsPanel_ac,
      closeNotifsPanel_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MyNotifs))
