import { formSubmissionActionTypes } from '../../actions/forms';

const {
  FETCH_FORM_SUBMISSIONS,
  FETCH_FORM_SUBMISSIONS_SUCCESSFUL,
  FETCH_FORM_SUBMISSIONS_FAILED,

  FETCH_FORM_SUBMISSION_PDF,
  FETCH_FORM_SUBMISSION_PDF_SUCCESSFUL,
  FETCH_FORM_SUBMISSION_PDF_FAILED,

  FLUSH_FORM_SUBMISSION_PDF,

  CREATE_FORM_SUBMISSION,
  CREATE_FORM_SUBMISSION_SUCCESSFUL,
  CREATE_FORM_SUBMISSION_FAILED,

  UPDATE_FORM_SUBMISSION,
  UPDATE_FORM_SUBMISSION_SUCCESSFUL,
  UPDATE_FORM_SUBMISSION_FAILED,

  REMOVE_FORM_SUBMISSION,
  REMOVE_FORM_SUBMISSION_SUCCESSFUL,
  REMOVE_FORM_SUBMISSION_FAILED,

  ADD_COMMENT_TO_SUBMISSION,
  ADD_COMMENT_TO_SUBMISSION_SUCCESSFUL,
  ADD_COMMENT_TO_SUBMISSION_FAILED,
} = formSubmissionActionTypes;

export const initialState = {
  list: {
    data: [],
    meta: {},
    isFetching: false,
    error: null,
  },
  pdf: {
    data: null,
    isFetching: false,
    error: null,
  },
  isCreatingFormSubmission: false,
  isUpdatingFormSubmission: false,
  isRemovingFormSubmission: false,
  isAddingComment: false,
};

export function formSubmissions(state = initialState, action) {
  switch (action.type) {
    // FETCH_FORM_SUBMISSIONS
    case FETCH_FORM_SUBMISSIONS:
      return Object.assign({}, state, {
        list: {
          ...state.list,
          isFetching: true,
        },
      });
    case FETCH_FORM_SUBMISSIONS_SUCCESSFUL:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: action.data?.results || [],
          meta: action.data?.meta || {},
          error: null,
        },
      });
    case FETCH_FORM_SUBMISSIONS_FAILED:
      return Object.assign({}, state, {
        list: {
          isFetching: false,
          data: [],
          meta: {},
          error: action.error,
        },
      });

    // FETCH_FORM_SUBMISSION_PDF
    case FETCH_FORM_SUBMISSION_PDF:
      return Object.assign({}, state, {
        pdf: {
          ...state.pdf,
          isFetching: true,
        },
      });

    case FETCH_FORM_SUBMISSION_PDF_SUCCESSFUL:
      return Object.assign({}, state, {
        pdf: {
          isFetching: false,
          data: action.data,
          error: null,
        },
      });

    case FETCH_FORM_SUBMISSION_PDF_FAILED:
      return Object.assign({}, state, {
        pdf: {
          isFetching: false,
          data: null,
          error: action.error,
        },
      });

    // CREATE_FORM_SUBMISSION
    case CREATE_FORM_SUBMISSION:
      return Object.assign({}, state, {
        isAddingFormSubmission: true,
      });
    case CREATE_FORM_SUBMISSION_SUCCESSFUL:
      return Object.assign({}, state, {
        isAddingFormSubmission: false,
      });
    case CREATE_FORM_SUBMISSION_FAILED:
      return Object.assign({}, state, {
        isAddingFormSubmission: false,
      });

    // UPDATE_FORM_SUBMISSION
    case UPDATE_FORM_SUBMISSION:
      return Object.assign({}, state, {
        isUpdatingFormSubmission: true,
      });
    case UPDATE_FORM_SUBMISSION_SUCCESSFUL:
      return Object.assign({}, state, {
        isUpdatingFormSubmission: false,
      });

    case UPDATE_FORM_SUBMISSION_FAILED:
      return Object.assign({}, state, {
        isUpdatingFormSubmission: false,
      });

    // FLUSH_FORM_SUBMISSION_PDF
    case FLUSH_FORM_SUBMISSION_PDF:
      return Object.assign({}, state, {
        pdf: {
          data: null,
          isFetching: false,
          error: null,
        },
      });

    // REMOVE_FORM_SUBMISSION
    case REMOVE_FORM_SUBMISSION:
      return Object.assign({}, state, {
        isRemovingFormSubmission: true,
      });
    case REMOVE_FORM_SUBMISSION_SUCCESSFUL:
      return Object.assign({}, state, {
        pdf: {
          ...state.pdf,
          data: null,
        },
        isRemovingFormSubmission: false,
      });
    case REMOVE_FORM_SUBMISSION_FAILED:
      return Object.assign({}, state, {
        isRemovingFormSubmission: false,
      });

    // ADD_COMMENT_TO_SUBMISSION
    case ADD_COMMENT_TO_SUBMISSION:
      return Object.assign({}, state, {
        isAddingComment: true,
      });

    case ADD_COMMENT_TO_SUBMISSION_SUCCESSFUL:
      return Object.assign({}, state, {
        isAddingComment: false,
      });

    case ADD_COMMENT_TO_SUBMISSION_FAILED:
      return Object.assign({}, state, {
        isAddingComment: false,
      });

    default:
      return state;
  }
}
