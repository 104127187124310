import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import StyledTooltip from '../../Shared/StyledTooltip'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: 'calc(100% - 16px)',
    margin: '2px 8px 2px 8px',
    padding: '4px',
    cursor: 'pointer',
    transition: 'opacity 2s',
    backgroundColor: '#f5f5f5',
    '&:hover': {
      backgroundColor: '#97a9d273'
    }
  },
  shortText: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden !important',
    textOverflow: 'ellipsis',
    color: 'rgba(68, 14, 232, 0.92)', //should match mfg shown in product listing
    textAlign: 'justify'
  }
})

class MfgNode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.onMfgClick = this.onMfgClick.bind(this)
  }

  onMfgClick() {
    this.props.onClick(
      this.props.mfg.mfg_nm || 'NO_MFG',
      this.props.mfg.is_macro
    )
  }
  render() {
    const { classes, mfg } = this.props

    return (
      <div onClick={this.onMfgClick} className={classes.root} elevation={1}>
        <StyledTooltip title={`${mfg.mfg_nm} (${mfg.n_items})`}>
          <span className={classes.shortText}>
            {mfg.mfg_nm || 'NO MANUFACTUERE'} ({mfg.n_items})
          </span>
        </StyledTooltip>
      </div>
    )
  }
}

MfgNode.propTypes = {
  mfg: PropTypes.object.isRequired
  //onToggleSelect: PropTypes.func.isRequired
}

export default withStyles(styles)(MfgNode)
