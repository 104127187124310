import { open_snack_ac } from './snack.ac.js';
export const FileBoundActionTypes = {
  ACCOUNTING_DOCS_RECEIVED: 'ACCOUNTING_DOCS_RECEIVED',
  FAILED_RECEIVING_ACCOUNTING_DOCS: 'FAILED_RECEIVING_ACCOUNTING_DOCS',
  ACCOUNTING_DOCS_DETAILS_RECEIVED: 'ACCOUNTING_DOCS_DETAILS_RECEIVED',
  FAILED_RECEIVING_ACCOUNTING_DOCS_DETAILS:
    'FAILED_RECEIVING_ACCOUNTING_DOCS_DETAILS',
  SET_IS_SAVING: 'SET_IS_SAVING',
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_FETCHING_DETAILS: 'SET_IS_FETCHING_DETAILS',
  SET_IS_FETCHING_COMPLETED_LIST: 'SET_IS_FETCHING_COMPLETED_LIST',
  COMPLETED_ACCOUNTING_DOCS_RECEIVED: 'COMPLETED_ACCOUNTING_DOCS_RECEIVED',
  FAILED_RECEIVING_COMPLETED_DOCS: 'FAILED_RECEIVING_COMPLETED_DOCS',
  EDIT_COMPLETED_DOC: 'EDIT_COMPLETED_DOC',
};

const getpath = (n) => {
  return (
    (Math.floor(n / 1000) * 1000).toString().padStart(8).replace(/ /g, '0') +
    '/' +
    n
  );
};

const gets3Key = (docId, ext = 'PDF') => {
  return [
    'https://s3.us-west-2.amazonaws.com',
    'sch.filebound',
    getpath(docId) + '.' + ext,
  ].join('/');
};

export const fetchFBAccountingDocs_ac = (params) => (dispatch) => {
  dispatch(fetching(true));
  return window.sch
    .post(`/api/fba/pending_docs`, params)
    .then((response) => {
      dispatch({
        type: FileBoundActionTypes.ACCOUNTING_DOCS_RECEIVED,
        accountingDocs: response.docs,
        pagination: response.pagination,
      });
    })
    .catch((error) => {
      dispatch({
        type: FileBoundActionTypes.FAILED_RECEIVING_ACCOUNTING_DOCS,
        error,
      });
    })
    .finally(() => {
      dispatch(fetching(false));
    });
};

export const fetchFBAccountingDocsDetails_ac =
  (params, fromMarkAsCompleteAction = false) =>
  (dispatch) => {
    dispatch(fetchingDetails(true));
    return window.sch
      .post(`/api/fba/pending_docs`, params)
      .then((response) => {
        // if complete and get next job button is clicked fetch the next doc and on success creat ajob updaitng document's status
        if (fromMarkAsCompleteAction) {
          dispatch(createJobForSelectedDoc_ac(params._id));
        }
        dispatch({
          type: FileBoundActionTypes.ACCOUNTING_DOCS_DETAILS_RECEIVED,
          doc: response.docs[0],
        });
      })
      .catch((error) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Error Fetching Accounting Document`s Details',
          })
        );
      })
      .finally(() => {
        dispatch(fetchingDetails(false));
      });
  };

export const createJobForSelectedDoc_ac = (docId) => (dispatch) => {
  return window.sch
    .post(`/api/fba/create_job`, {
      _id: docId,
      status: 10,
    })
    .then((response) => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: `Job Successfully Created`,
        })
      );
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: `Failed to Create job`,
        })
      );
    });
};

export const moveDocToTrash_ac = (docId) => (dispatch) => {
  return window.sch
    .post(`/api/fba/move_to_trash`, {
      _id: docId,
      status: -100,
    })
    .then((response) => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: `Accounting Doc Moved to Trash`,
        })
      );
      dispatch(fetchFBAccountingDocs_ac({ page: 0 }));
      dispatch(fetchFBAccountingCompletedDocs_ac({ page: 0 }));
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: `Failed to Move Accounting Doc to Trash`,
        })
      );
    });
};

export const markAsComplete_ac =
  (params, get_next_job) => (dispatch, getState) => {
    dispatch(saving(true));
    return window.sch
      .post(`/api/fba/indexing/complete`, params)
      .then((response) => {
        dispatch(
          open_snack_ac({
            variant: 'success',
            message: 'Job Successfully Marked as Completed',
          })
        );

        if (get_next_job) {
          const accountingDocs = getState().fileBound.accountingDocs;
          let nextDocIndex;
          accountingDocs.forEach((doc, index) => {
            if (doc._id === params._id) {
              nextDocIndex = index + 1;
            }
          });
          const nextDoc = accountingDocs[nextDocIndex];
          if (nextDoc) {
            dispatch(
              fetchFBAccountingDocsDetails_ac(
                {
                  _id: nextDoc._id,
                },
                true
              )
            );
          }
        }

        dispatch(fetchFBAccountingDocs_ac({ page: 0 }));
        dispatch(fetchFBAccountingCompletedDocs_ac({ page: 0 }));
      })
      .catch((error) => {
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Error marking as complete',
          })
        );
      })
      .finally(() => {
        dispatch(saving(false));
      });
  };

export const fetchFBAccountingCompletedDocs_ac = (params) => (dispatch) => {
  dispatch(fetchingCompletedList(true));
  return window.sch
    .post(`/api/fba/completed_docs`, params)
    .then((response) => {
      dispatch({
        type: FileBoundActionTypes.COMPLETED_ACCOUNTING_DOCS_RECEIVED,
        completedDocs: response.docs,
        completedPagination: response.pagination,
      });
    })
    .catch((error) => {
      dispatch({
        type: FileBoundActionTypes.FAILED_RECEIVING_COMPLETED_DOCS,
        error,
      });
    })
    .finally(() => {
      dispatch(fetchingCompletedList(false));
    });
};

export const retryImport_ac = (docId) => (dispatch) => {
  dispatch(saving(true));
  return window.sch
    .post(`/api/fba/retry_import`, {
      _id: docId,
    })
    .then((response) => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: `Job Retry Import Successful - check in 5 minutes`,
        })
      );
      dispatch(fetchFBAccountingDocs_ac({ page: 0 }));
      dispatch(fetchFBAccountingCompletedDocs_ac({ page: 0 }));
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: `Failed to retry import job`,
        })
      );
    })
    .finally(() => {
      dispatch(saving(false));
    });
};

export const untrashSelectedDoc_ac = (docId) => (dispatch) => {
  dispatch(saving(true));
  return window.sch
    .post(`/api/fba/untrash_job`, {
      _id: docId,
      status: 10,
    })
    .then((response) => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: `Job Untrash Successfull`,
        })
      );
      dispatch(fetchFBAccountingDocs_ac({ page: 0 }));
      dispatch(fetchFBAccountingCompletedDocs_ac({ page: 0 }));
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: `Failed to untrash selected job`,
        })
      );
    })
    .finally(() => {
      dispatch(saving(false));
    });
};

export const resetInProgressJob_ac = (docId) => (dispatch) => {
  dispatch(saving(true));
  return window.sch
    .post(`/api/fba/reset_job`, {
      _id: docId,
      status: 0,
    })
    .then((response) => {
      dispatch(
        open_snack_ac({
          variant: 'info',
          message: `Job Reset Successfull`,
        })
      );
      dispatch(fetchFBAccountingDocs_ac({ page: 0 }));
      dispatch(fetchFBAccountingCompletedDocs_ac({ page: 0 }));
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: `Failed to reset selected job`,
        })
      );
    })
    .finally(() => {
      dispatch(saving(false));
    });
};

export const markEditableForSelectedDoc_ac = (doc) => (dispatch) => {
  let s3key = gets3Key(doc.docid, doc.orig_params && doc.orig_params.ext);
  // fetch signed url
  window.sch
    .post('/api/s3/object/getsignedurl', { s3key })
    .then((result) => {
      doc.signedUrl = result.signedUrl;
    })
    .catch((err) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: `Error getting signedurl to load PDF`,
        })
      );
    })
    .finally(() => {
      dispatch({
        type: FileBoundActionTypes.EDIT_COMPLETED_DOC,
        payload: {
          ...doc,
          isEditing: true,
        },
      });
    });
};

export const updateCompletedDoc_ac = (params) => (dispatch) => {
  dispatch(saving(true));
  return window.sch
    .post(`/api/fba/indexing/update`, params)
    .then((response) => {
      dispatch(
        open_snack_ac({
          variant: 'success',
          message: 'Update Successful',
        })
      );

      dispatch(fetchFBAccountingDocs_ac({ page: 0 }));
      dispatch(fetchFBAccountingCompletedDocs_ac({ page: 0 }));
    })
    .catch((error) => {
      dispatch(
        open_snack_ac({
          variant: 'error',
          message: 'Error Updating',
        })
      );
    })
    .finally(() => {
      dispatch(saving(false));
    });
};

export const fetching = (isFetching) => ({
  type: FileBoundActionTypes.SET_IS_FETCHING,
  isFetching,
});

export const fetchingCompletedList = (isFetching) => ({
  type: FileBoundActionTypes.SET_IS_FETCHING_COMPLETED_LIST,
  isFetching,
});

export const saving = (isSaving) => ({
  type: FileBoundActionTypes.SET_IS_SAVING,
  isSaving,
});

export const fetchingDetails = (isFetchingDetails) => ({
  type: FileBoundActionTypes.SET_IS_FETCHING_DETAILS,
  isFetchingDetails,
});
