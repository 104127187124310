import { useCallback, useEffect, useRef } from 'react';

const useDebounce = (func, delay, deps, initialInvoke = false) => {
  const callback = useCallback(func, deps);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (initialInvoke && isFirstRender.current) {
      isFirstRender.current = false;
      callback();
      return;
    }

    const handler = setTimeout(callback, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay, initialInvoke]);
};

export default useDebounce;
