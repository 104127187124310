import { Tab, Tabs } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  updateAccountDocDivider_ac,
  updateDocCompliance_ac,
  updateDocDetails_ac
} from '../../../actions/account_in_view.ac.js'
import { lookups_ac, setTitle_ac } from '../../../actions/me.ac.js'
import { EntitiesAnalysis } from './EntitiesAnalysis.js'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  content: {
    flexGrow: 1,
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0'
  },
  filterRoot: {
    paddingLeft: 16
  },
  count: {
    paddingLeft: 5
  },
  title: {
    flexGrow: 1
  },
  flexContainer: {
    display: 'block'
  },
  tabLabelContainer: {
    padding: '3px 12px 3px 12px'
  },
  tabRoot: {
    minWidth: 16,
    minHeight: 24
  },
  tabRootWithData: {
    minWidth: 16,
    backgroundColor: 'yellow',
    minHeight: 24
  },
  tabsRoot: {
    minHeight: 24
  }
})

const MAP_COMPONENT_TITLE = {
  entities: 'Entities',
  exnorm_concept: 'ExNorm Concepts',
  icd_10_cm_concept: 'icd_10_cm_concept'
}

const AnalysisTabs = ({
  tabsClass,
  tabClassWithData,
  tabClass,
  activeTab,
  onTabSelect,
  jsonData
}) => {
  return (
    <Tabs
      classes={tabsClass}
      value={activeTab}
      onChange={onTabSelect}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="off">
      {Object.keys(jsonData).map(
        pgIdx =>
          (
            <Tab
              key={pgIdx}
              value={pgIdx.toString()}
              label={
                'PG' + (parseInt(pgIdx, 10) + 1) + ' ' + jsonData[pgIdx].state
              }
              classes={
                jsonData[pgIdx].hasSaveableDataTable === true
                  ? tabClassWithData
                  : tabClass
              }
            />
          ) || (
            <Tab
              key={pgIdx}
              value={pgIdx.toString()}
              label={
                'PG' + (parseInt(pgIdx, 10) + 1) + ' ' + jsonData[pgIdx].state
              }
            />
          )
      )}
      {/*
            <Tab value="exnorm_concept" label="ExNorm Concepts" classes={tabClass} />
            <Tab value="icd_10_cm_concept" label="ICD-10-CM Concepts" classes={tabClass} />
            */}
    </Tabs>
  )
}

class AnalysisLandingComponent extends Component {
  constructor() {
    super()
    this.state = {
      activeTab: '0'
    }
    this.onDocUpdateFormAction = this.onDocUpdateFormAction.bind(this)
  }

  componentDidMount() {
    const { lookups, setTitle_ac, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    setTitle_ac(MAP_COMPONENT_TITLE[this.state.activeTab])
  }

  componentDidUpdate() {
    const { lookups, me } = this.props
    if (!me.user) return
    if (lookups.ink === null) {
      if (lookups.isFetching) return
      return this.props.lookups_ac()
    }

    setTitle_ac(MAP_COMPONENT_TITLE[this.state.activeTab])
  }

  onTabSelect = (e, _selectedTab) => {
    return this.setState({
      activeTab: _selectedTab
    })
  }

  onDocUpdateFormAction(p) {
    const { docInView, idnInView } = this.props

    let postData = {
      docJobKey: this.props.docJobKey,
      details: { page: p.page, ...p.details },
      note: p.note
    }

    if (idnInView) {
      if (docInView.docJob.displayAs.split(/:/)[0] === idnInView.idnDoc._id) {
        if (idnInView.idnDoc) {
          postData.idnDoc = idnInView.idnDoc
        }
      }
    }

    switch (p.actionKind) {
      case 'updateDocDetails':
        return this.props.updateDocDetails_ac(postData)
      default:
        break
    }
    return p
  }

  render() {
    const { classes, lookups, jsonData, currentDetails } = this.props

    const tabsClass = {
      flexContainer: classes.flexContainer,
      root: classes.tabsRoot
    }
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: classes.tabLabelContainer
    }
    const tabClassWithData = {
      root: classes.tabRootWithData,
      labelContainer: classes.tabLabelContainer
    }

    if (!lookups.ink) {
      return (
        <div className={classes.root}>
          <AnalysisTabs
            tabClass={tabClass}
            tabsClass={tabsClass}
            tabClassWithData={tabClassWithData}
            activeTab={this.state.activeTab}
            jsonData={this.props.jsonData}
            onTabSelect={this.onTabSelect}
          />
          <main className={classes.content}>
            <h4 className={classes.details}>...</h4>
          </main>
        </div>
      )
    }

    return (
      <div className={classes.root}>
        <AnalysisTabs
          tabClass={tabClass}
          tabsClass={tabsClass}
          tabClassWithData={tabClassWithData}
          activeTab={this.state.activeTab}
          jsonData={this.props.jsonData}
          onTabSelect={this.onTabSelect}
        />

        <div className={classes.content}>
          {this.props.jsonData[this.state.activeTab] ? (
            <EntitiesAnalysis
              onHasSaveableTableData={this.props.onHasSaveableTableData}
              currentDetails={currentDetails}
              pageOfDocument={this.state.activeTab}
              onDocUpdateFormAction={this.onDocUpdateFormAction}
              json={jsonData[this.state.activeTab]}
            />
          ) : (
            <div>
              <span>...loading data for analysis....</span>
              <LinearProgress variant="indeterminate" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

AnalysisLandingComponent.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  idnInView: stateFromStore.idnInView,
  careConnectTasksInView: stateFromStore.careConnectTasksInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setTitle_ac,
      lookups_ac,
      updateAccountDocDivider_ac,
      updateDocDetails_ac,
      updateDocCompliance_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export const AnalysisLanding = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AnalysisLandingComponent))
)
