import { TeamsGridActionTypes } from '../actions/teams_grid.ac';

export function teamsGridInView(
    state = {
        isFetching: false,
        records: null,
        error: null,
    },
    action
) {
    switch (action.type) {
        case TeamsGridActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case TeamsGridActionTypes.RECEIVED_TEAMS_GRID_DATA:
            return {
                ...state,
                records: action.records,
            };
        case TeamsGridActionTypes.SET_IS_ADDING_MEMBER:
            return {
                ...state,
                teamsLoading: {
                    ...state.teamsLoading,
                    [action.teamName]: action.isAddingMember
                }
            };
        case TeamsGridActionTypes.ADD_MEMBER:
            state.records
                .find(item => item.nm === action.team)
                .members.push({
                    email: action.user.mail,
                    nm: action.user.nm,
                    totalAssignments: 0,
                    ts: new Date()
                })
            return {
                ...state,
            };
        case TeamsGridActionTypes.SET_IS_REMOVING_MEMBER:
            return {
                ...state,
                cardsLoading: {
                    ...state.cardsLoading,
                    [action.memberEmail]: {
                        [action.teamName]: action.isRemovingMember
                    }
                }
            };
        case TeamsGridActionTypes.REMOVE_MEMBER_FROM_TEAM:
            state.records
                .find(item => item.nm === action.team)
                .members.forEach((item, i, members) => {
                    if (item.email === action.user._id) {
                        members.splice(i, 1);
                    }
                });

            return {
                ...state,
            };
        case TeamsGridActionTypes.DOC_FLOW_STATE_CHANGED:
            state.records
                .find(item => item.nm === action.team)
                .members.forEach((member) => {
                    if (member.email === action.user._id) {
                        member.active = action.docFlowState
                    }
                });

            return {
                ...state,
            };
        case TeamsGridActionTypes.FAILED_RECEIVED_TEAMS_GRID_DATA:
            return {
                ...state,
                records: null,
                error: action.error,
            };

        default:
            return state;
    }
};
