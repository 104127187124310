import Button from '@material-ui/core/Button'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { push } from 'connected-react-router'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  fetchCcsPkg_ac,
  fetchSarDoc_ac,
  fetchUBList_ac
} from '../../actions/ub.ac.js'

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  sarInfo: {
    fontWeight: 700,
    fontSize: 12
  },
  sarDateInfo: {
    paddingLeft: 6,
    paddingRight: 6,
    color: '#455A64',
    fontWeight: 600,
    fontSize: 12
  },
  fullHeight: {
    height: '100%'
  }
})

//const PATH_TO_PA_DOCS = process.env.REACT_APP_WOS_API_URL + '/api/unbilled/files'

class SarEntry extends React.Component {
  constructor(props) {
    super(props)
    this.sarClicked = this.sarClicked.bind(this)
  }

  sarClicked() {
    return this.props.sarClicked(this.props.sar._id)
  }

  render() {
    const { classes, idx, sar } = this.props

    return (
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.sarInfo}>
            {1 + idx}) {sar._id}
          </Typography>
          <Typography className={classes.sarDateInfo}>
            FROM: {moment(sar.sarStartDate).format('MM-DD-YY')}
          </Typography>
          <Typography className={classes.sarDateInfo}>
            TO: {moment(sar.sarEndDate).format('MM-DD-YY')}
          </Typography>
          <Typography className={classes.sarInfo}>{sar.statusDesc}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ paddingTop: 2, paddingBottom: 2 }}>
          <Grid container spacing={8} direction="row">
            <Grid item xs={12} sm={8}>
              <div style={{ fontSize: 11 }}>
                Client Name: {sar.clientName} {sar.gender} -
                <br />
                County: {sar.county} {sar.finEligStatus}
                <br />
                Reg Status: {sar.regStatus} - App Status: {sar.applStatus}
                <br />
                Elig status:{sar.ccsEligStatus}
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={this.sarClicked}>
                Sar: {sar._id}
              </Button>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
        {sar.denial_notes && (
          <ExpansionPanelDetails style={{ paddingTop: 2, paddingBottom: 2 }}>
            <div
              style={{
                backgroundColor: 'lightgray',
                color: '#000',
                fontSize: 11
              }}>
              {sar.denial_notes}
            </div>
          </ExpansionPanelDetails>
        )}

        <ExpansionPanelDetails>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell style={{ paddingLeft: 0 }}>HCPC</TableCell>
                <TableCell>Desc</TableCell>
                <TableCell align="right">Units/QTY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sar.codes.map(c => (
                <TableRow key={c.id}>
                  <TableCell style={{ paddingLeft: 0 }}>
                    {c.serviceCd} {c.mods.join(',')}
                  </TableCell>
                  <TableCell>
                    {c.serviceDesc} ({c.serviceGroupInd})
                  </TableCell>
                  <TableCell align="right">
                    {c.units}/{c.quantity}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

class CCSPkg extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const { classes, pkg } = this.props

    return (
      <div className={classes.fullHeight}>
        <div className={classes.scrollArea}>
          {pkg.sars.map((sar, idx) => (
            <SarEntry
              classes={classes}
              key={sar._id}
              sarClicked={this.props.sarClicked}
              sar={sar}
              idx={idx}
            />
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  accountPAsInView: stateFromStore.accountPAsInView,
  idnInView: stateFromStore.idnInView,
  ubList: stateFromStore.ubList
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchUBList_ac,
      fetchSarDoc_ac,
      fetchCcsPkg_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CCSPkg))
)
