import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { markExpiringPA_ac } from '../../actions/expiringPAs.ac.js';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  payerName: {
    fontSize: theme.typography.pxToRem(13),
    marginRight: 8,
    color: '#65502f',
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13),
  },
  formControl: {
    display: 'block',
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row',
  },
  branch: {
    flexGrow: 1,
    fontSize: theme.typography.pxToRem(13),
    paddingBottom: 10,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  branchGridRoot: {
    flexGrow: 1,
    paddingBottom: 8,
  },
  paNumber: {
    fontWeight: 600,
  },
  notLogged: {
    color: '#757575',
  },
  byTabKlass: {
    minWidth: 16,
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0',
  },
  activityMsg: {
    fontSize: 11,
    color: 'orange',
  },
  noteUser: {
    color: '#607D8B',
    fontWeight: 600,
  },
  memoPara: {
    color: '#000',
    margin: '4px 4px 4px 0px',
    padding: 6,
    fontSize: 12,
    marginTop: 0,
    borderRadius: 4,
    backgroundColor: 'rgba(233, 236, 241, 0.49)',
  },
});

class ExpiringPaCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      inReAttempt: false,
      comment: '',
      modeVal: '',
      actionType: '',
      actionIssue: '',
      actionAction: '',
    };
    this.onActionTypeChange = this.onActionTypeChange.bind(this);
    this.onActionIssueChange = this.onActionIssueChange.bind(this);
    this.onActionActionChange = this.onActionActionChange.bind(this);
    this.onSaveActivity = this.onSaveActivity.bind(this);
    this.onComment = this.onComment.bind(this);
    this.onCommentText = this.onCommentText.bind(this);
    this.onReferenceTxt = this.onReferenceTxt.bind(this);
    this.onUpdateReference = this.onUpdateReference.bind(this);
  }

  onReferenceTxt(e) {
    if (e.target.value.trim() === '') return;
    this.setState({ referenceTxt: e.target.value });
  }
  onActionTypeChange(e) {
    this.setState({ actionType: e.target.value });
  }
  onActionIssueChange(e) {
    this.setState({ actionIssue: e.target.value });
  }
  onActionActionChange(e) {
    this.setState({ actionAction: e.target.value });
  }
  onCommentText(e) {
    this.setState({ comment: e.target.value });
  }
  onComment() {
    if (this.state.comment.trim() === '') return;
    let params = {
      payer_types: this.props.payer_types,
      pa_id: this.props.expPaDoc._id,
      kind: 'comment',
      comment: this.state.comment.trim(),
      posInSet: this.props.posInSet,
    };
    this.props.markExpiringPA_ac(params);
    this.setState({ comment: '' });
  }

  onUpdateReference() {
    let params = {
      pa_id: this.props.expPaDoc._id,
      payer_types: this.props.payer_types,
      kind: 'link_reference',
      reference_txt: this.state.referenceTxt,
      posInSet: this.props.posInSet,
    };
    this.props.markExpiringPA_ac(params);
  }

  onSaveActivity() {
    let params = {
      payer_types: this.props.payer_types,
      pa_id: this.props.expPaDoc._id,
      kind: 'ua',
      ua: {
        type: this.state.actionType,
        issue: this.state.actionIssue,
        action: this.state.actionAction,
      },
      posInSet: this.props.posInSet,
    };
    this.props.markExpiringPA_ac(params);
  }

  render() {
    const { classes, expPaDoc, lookups } = this.props;

    const dropdowns = lookups.ink?.pa_exp_actions;

    if (!dropdowns) return <div>no lookups</div>;

    let ua = expPaDoc.log ? expPaDoc.log[expPaDoc.log.length - 1] : false;

    return (
      <ExpansionPanel defaultExpanded={this.props.defaultExpanded}>
        <ExpansionPanelSummary
          style={{
            backgroundColor: expPaDoc.touched ? 'rgb(228, 234, 204)' : '#fff',
          }}
          expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.panelHdrTxt}>
            {'PA:' + expPaDoc._id}&nbsp;
            <span style={{ color: 'green' }}>{expPaDoc.qname}</span>&nbsp;
            <span style={{ color: 'blue' }}>
              A{expPaDoc.account} {expPaDoc.ptname}
            </span>
            &nbsp;
            <span style={{ color: 'red' }}>
              Expires:{' '}
              {moment(expPaDoc.paexpiration, 'YYYY-MM-DD').format('MM-DD-YYYY')}
            </span>
            {ua && (
              <span style={{ color: 'gray' }}>
                - Last action by {ua.u_nm} on{' '}
                {moment(ua.ts).format('M-D-YY hh:mma')}:
              </span>
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container direction="column" justify="flex-start" wrap="nowrap">
            <Grid item className={classes.branchGrid}>
              <Link to={'/dfa/account_docs/' + expPaDoc.account}>
                <Typography className={classes.payerName}>
                  Go to Patient Docs
                </Typography>
              </Link>

              <label className={classes.lbl}>Payer Type / Payer Name</label>
              <Typography className={classes.payerName}>
                {expPaDoc.payertype} / {expPaDoc.payername}
              </Typography>

              <label className={classes.lbl}>Med Group / Healthplan</label>
              <Typography className={classes.payerName}>
                {expPaDoc.medicalgroupname} / {expPaDoc.healthplanname}
              </Typography>
            </Grid>

            <Grid item className={classes.branchGrid}>
              <Grid container direction="row" justify="flex-start" spacing={8}>
                <Grid item sm={2}>
                  <label className={classes.lbl}>PA Eff Date</label>
                  <Typography className={classes.payerName}>
                    {moment(expPaDoc.pa_date, 'YYYY-MM-DD').format(
                      'MM-DD-YYYY'
                    )}
                  </Typography>
                  <label className={classes.lbl}>PA Expiration</label>
                  <Typography className={classes.payerName}>
                    {moment(expPaDoc.paexpiration, 'YYYY-MM-DD').format(
                      'MM-DD-YYYY'
                    )}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <label className={classes.lbl}>PA Shell Name</label>
                  <Typography className={classes.payerName}>
                    {expPaDoc.shell_description}
                  </Typography>

                  <label className={classes.lbl}>PA Doctor</label>
                  <Typography className={classes.payerName}>
                    {expPaDoc.padoctor ? expPaDoc.padoctor : '-'}
                  </Typography>
                </Grid>
                <Grid item sm={4}>
                  <label className={classes.lbl}>HCPCS (QTY)</label>
                  <Typography className={classes.payerName} />

                  <Typography className={classes.payerName}>
                    {expPaDoc.hcpcs
                      .map((h, i) => h + ' (' + expPaDoc.qtys[i] + ')')
                      .join(', ')}
                  </Typography>
                  <label className={classes.lbl}>DX</label>
                  <Typography className={classes.payerName}>
                    {[1, 2, 3, 4].map((num) => expPaDoc['dx' + num]).join(',')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {expPaDoc.show_ref_form && (
              <Grid item className={classes.branchGrid}>
                <label className={classes.lbl}>Auth Reference Number</label>
                {expPaDoc.reference && (
                  <Typography
                    paragraph
                    className={classes.noteTxt}
                    color="payerrName">
                    {expPaDoc.reference.txt}
                    <i className={classes.noteUser}>
                      {' '}
                      - Entered by {expPaDoc.reference.u_nm} on{' '}
                      <Moment format="M-DD-YY h:mma">
                        {expPaDoc.reference.ts}
                      </Moment>
                    </i>
                  </Typography>
                )}
                {!expPaDoc.reference && (
                  <Typography
                    paragraph
                    className={classes.noteTxt}
                    color="textSecondary">
                    Not linked.
                  </Typography>
                )}

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={8}>
                  <Grid item sm={9}>
                    <TextField
                      value={this.state.reference_txt}
                      fullWidth
                      onChange={this.onReferenceTxt}
                      label="Add Reference # - ONLY ENTER THE EXACT REFERENCE#"
                      className={classes.formComponent}
                    />
                  </Grid>
                  <Grid item sm={3}>
                    <div className={classes.btnContainer}>
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={this.onUpdateReference}>
                        Link Reference #
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl}>Comments</label>
              {_.get(expPaDoc, 'comments', []).map((n, nIdx) => {
                let textParts = n.txt.split(/\n/g);
                textParts = textParts.map((p, pi) => {
                  if (pi === textParts.length - 1) return p;
                  return (
                    <span key={pi}>
                      {p}
                      <br />
                    </span>
                  );
                });
                return (
                  <Typography
                    key={nIdx}
                    paragraph
                    className={classes.noteTxt}
                    color="textSecondary">
                    {textParts}
                    <i className={classes.noteUser}>
                      {' '}
                      - {n.u_nm} on{' '}
                      <Moment format="M-DD-YY h:mma">{n.ts}</Moment>
                    </i>
                  </Typography>
                );
              })}
              <Grid container direction="row" justify="flex-start" spacing={8}>
                <Grid item sm={9}>
                  <TextField
                    multiline
                    value={this.state.comment}
                    fullWidth
                    onChange={this.onCommentText}
                    label="Add Comment ([Enter] for multiline)"
                    className={classes.formComponent}
                  />
                </Grid>
                <Grid item sm={3}>
                  <div className={classes.btnContainer}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={this.onComment}>
                      Add Comment
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item className={classes.branchGrid} />

            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl} />
            </Grid>

            {ua && (
              <Grid item className={classes.branchGrid}>
                <label className={classes.lbl}>
                  Last action by {ua.u_nm} on{' '}
                  {moment(ua.ts).format('M-D-YY hh:mma')}:
                </label>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  spacing={8}>
                  <Grid item sm={3}>
                    <label className={classes.lbl}>Type:</label>
                    <Typography className={classes.payerName}>
                      {ua.type}
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <label className={classes.lbl}>Issue:</label>
                    <Typography className={classes.payerName}>
                      {ua.issue}
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <label className={classes.lbl}>Action:</label>
                    <Typography className={classes.payerName}>
                      {ua.action}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid item className={classes.branchGrid}>
              <label className={classes.lbl}>Activity</label>
              <Grid container direction="row" justify="flex-start" spacing={8}>
                <Grid item sm={3}>
                  <FormControl className={classes.formControl}>
                    <Select
                      onChange={this.onActionTypeChange}
                      displayEmpty
                      value={this.state.actionType}
                      fullWidth
                      name="age"
                      className={classes.selectEmpty}>
                      <MenuItem value="">Select Type</MenuItem>
                      {dropdowns.TYPE.map((t) => (
                        <MenuItem key={t} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl className={classes.formControl}>
                    <Select
                      onChange={this.onActionIssueChange}
                      displayEmpty
                      value={this.state.actionIssue}
                      fullWidth
                      name="age"
                      className={classes.selectEmpty}>
                      <MenuItem value="">Select Issue</MenuItem>
                      {dropdowns.ISSUES.map((t) => (
                        <MenuItem key={t} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl className={classes.formControl}>
                    <Select
                      onChange={this.onActionActionChange}
                      displayEmpty
                      fullWidth
                      value={this.state.actionAction}
                      name="age"
                      className={classes.selectEmpty}>
                      <MenuItem value="">Select Action</MenuItem>
                      {dropdowns.ACTIONS.map((t) => (
                        <MenuItem key={t} value={t}>
                          {t}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={3}>
                  <FormControl className={classes.formControl}>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      disabled={
                        this.state.actionType === '' ||
                        this.state.actionIssue === '' ||
                        this.state.actionAction === ''
                      }
                      onClick={this.onSaveActivity}>
                      Save Activity
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {/* activity update message  */}
            {expPaDoc.msg && (
              <Grid item className={classes.branchGrid}>
                <label className={classes.activityMsg}>{expPaDoc.msg}</label>
              </Grid>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      markExpiringPA_ac,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ExpiringPaCard));
