import { actionTypes } from '../actions/connect_holds.ac';

export const initialState = {
  queues: { isFetching: false, data: [], error: null },
  queueGroups: { isFetching: false, data: [], error: null },

  isRemovingQueue: false,
  removeQueueError: null,
  isUpdatingQueue: false,
  updateQueueError: null,
  isAddingQueue: false,
  addQueueError: null,

  isRemovingQueueGroup: false,
  removeQueueGroupError: null,
  isUpdatingQueueGroup: false,
  updateQueueGroupError: null,
  isAddingQueueGroup: false,
  addQueueGroupError: null
};

export function connectHolds(state = initialState, action) {
  switch (action.type) {
    // QUEUES ACTIONS:

    case actionTypes.FETCH_QUEUES_PENDING:
      return {
        ...state,
        queues: {
          ...state.queues,
          isFetching: true
        }
      };

    case actionTypes.FETCH_QUEUES_SUCCESS:
      return {
        ...state,
        queues: {
          isFetching: false,
          data: action.data || [],
          error: null
        }
      };

    case actionTypes.FETCH_QUEUES_ERROR:
      return {
        ...state,
        queues: {
          isFetching: false,
          data: [],
          error: action.error
        }
      };

    case actionTypes.REMOVE_QUEUE_PENDING:
      return {
        ...state,
        isRemovingQueue: true
      };
    case actionTypes.REMOVE_QUEUE_SUCCESS:
      return {
        ...state,
        isRemovingQueue: false,
        removeQueueError: null,
        queues: {
          ...state.queues,
          data: state.queues.data.filter(queue => queue._id !== action.data)
        }
      };
    case actionTypes.REMOVE_QUEUE_ERROR:
      return {
        ...state,
        isRemovingQueue: false,
        removeQueueError: action.error
      };

    case actionTypes.UPDATE_QUEUE_PENDING:
      return {
        ...state,
        isUpdatingQueue: true
      };

    case actionTypes.UPDATE_QUEUE_SUCCESS:
      return {
        ...state,
        isUpdatingQueue: false,
        updateQueueError: null,
        queues: {
          ...state.queues,
          data: state.queues.data.map(queue => {
            if (queue._id === action.data._id) {
              return action.data;
            }
            return queue;
          })
        }
      };

    case actionTypes.UPDATE_QUEUE_ERROR:
      return {
        ...state,
        isUpdatingQueue: false,
        updateQueueError: action.error
      };

    case actionTypes.ADD_QUEUE_PENDING:
      return {
        ...state,
        isAddingQueue: true
      };

    case actionTypes.ADD_QUEUE_SUCCESS:
      return {
        ...state,
        isAddingQueue: false,
        addQueueError: null,
        queues: {
          ...state.queues,
          data: [...state.queues.data, action.data]
        }
      };

    case actionTypes.ADD_QUEUE_ERROR:
      return {
        ...state,
        isAddingQueue: false,
        addQueueError: action.error
      };

    // QUEUE GROUP ACTIONS:

    case actionTypes.FETCH_QUEUE_GROUPS_PENDING:
      return {
        ...state,
        queueGroups: {
          ...state.queueGroups,
          isFetching: true
        }
      };

    case actionTypes.FETCH_QUEUE_GROUPS_SUCCESS:
      return {
        ...state,
        queueGroups: {
          isFetching: false,
          data: action.data || [],
          error: null
        }
      };

    case actionTypes.FETCH_QUEUE_GROUPS_ERROR:
      return {
        ...state,
        queueGroups: {
          isFetching: false,
          data: [],
          error: action.error
        }
      };

    case actionTypes.REMOVE_QUEUE_GROUP_PENDING:
      return {
        ...state,
        isRemovingQueueGroup: true
      };
    case actionTypes.REMOVE_QUEUE_GROUP_SUCCESS:
      return {
        ...state,
        isRemovingQueueGroup: false,
        removeQueueGroupError: null,
        queueGroups: {
          ...state.queueGroups,
          data: state.queueGroups.data.filter(
            group => group._id !== action.data
          )
        }
      };
    case actionTypes.REMOVE_QUEUE_GROUP_ERROR:
      return {
        ...state,
        isRemovingQueueGroup: false,
        removeQueueGroupError: action.error
      };

    case actionTypes.UPDATE_QUEUE_GROUP_PENDING:
      return {
        ...state,
        isUpdatingQueueGroup: true
      };

    case actionTypes.UPDATE_QUEUE_GROUP_SUCCESS:
      return {
        ...state,
        isUpdatingQueueGroup: false,
        updateQueueGroupError: null,
        queueGroups: {
          ...state.queueGroups,
          data: state.queueGroups.data.map(queue => {
            if (queue._id === action.data._id) {
              return action.data;
            }
            return queue;
          })
        }
      };

    case actionTypes.UPDATE_QUEUE_GROUP_ERROR:
      return {
        ...state,
        isUpdatingQueueGroup: false,
        updateQueueGroupError: action.error
      };

    case actionTypes.ADD_QUEUE_GROUP_PENDING:
      return {
        ...state,
        isAddingQueueGroup: true
      };

    case actionTypes.ADD_QUEUE_GROUP_SUCCESS:
      return {
        ...state,
        isAddingQueueGroup: false,
        addQueueGroupError: null,
        queueGroups: {
          ...state.queueGroups,
          data: [...state.queueGroups.data, action.data]
        }
      };

    case actionTypes.ADD_QUEUE_GROUP_ERROR:
      return {
        ...state,
        isAddingQueueGroup: false,
        addQueueGroupError: action.error
      };

    default:
      return state;
  }
}
