import { OpenOrdersActionTypes } from '../actions/open_orders.ac'

export function openOrders(
  state = {
    params: null,
    isFetching: false,
    pkg: null,
    error: false,
    ifFetchingTrackingReport: false,
    html: null,
    trackingError: false
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_DF_OPEN_ORDERS':
      return Object.assign({}, state, {
        params: action.params,
        isFetching: true
      })

    case 'RESPONSE_DF_OPEN_ORDERS':
      return Object.assign({}, state, {
        isFetching: false,
        pkg: action.pkg
      })

    case 'FAILED_DF_OPEN_ORDERS':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })

    case OpenOrdersActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        ifFetchingTrackingReport: action.isFetching
      }

    case OpenOrdersActionTypes.REPORT_FETCHED:
      return {
        ...state,
        html: action.html
      }

    case OpenOrdersActionTypes.REPORT_FETCHING_FAILED:
      return {
        ...state,
        trackingError: action.trackingError
      }

    default:
      return state
  }
}
