import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { orange, purple } from '@material-ui/core/colors';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { fetchExpiringCMNsReport_ac } from '../../actions/expiringCMNs.ac.js';
import { CMNIvrActivityPanel } from '../CMNIvrActivityCard';
import FetchingStatementWrap from '../FetchingStatementWrap';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange,
  },
  typography: {
    useNextVariants: true,
    fontSize: 12,
  },
  overrides: {
    MuiTableCell: {
      body: {
        fontSize: 12,
        verticalAlign: 'top',
      },
    },
  },
});
const styles = (theme) => ({
  filterRoot: {
    paddingLeft: 16,
  },
  button: {},
  title: {
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto',
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  summaryEntryWrap: {
    marginBottom: 2,
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2,
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em',
  },
  spacer: {
    height: 40,
  },
  tblCell: {
    verticalAlign: 'top',
  },
  fullHeight: {
    height: '100%',
  },
});

class ExpiringCMNsReportLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      filterSequence: ['automation_status'],
      filters: {
        automation_status: {
          lbl: 'Automation Status',
          options: [
            { code: 'ivr_happened', lbl: 'IVR Occurred', checked: true },
            { code: 'has_appt', lbl: 'Appointment Obtained', checked: false },
          ],
        },
      },
    };
    this.downloadAllCurrent = this.downloadAllCurrent.bind(this);
    this.downloadIndCurrent = this.downloadIndCurrent.bind(this);
    this.checkSituation = this.checkSituation.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.fetchWithPageReset = this.fetchWithPageReset.bind(this);
  }

  componentDidMount() {
    const { expiringCMNsReport } = this.props;
    if (expiringCMNsReport.isFetching) return;
    if (expiringCMNsReport.error) return;
    this.checkSituation();
    return;
  }

  componentDidUpdate() {
    const { expiringCMNsReport } = this.props;
    if (expiringCMNsReport.isFetching) return;
    if (expiringCMNsReport.error) return;
    this.checkSituation();
    return;
  }

  checkSituation() {
    const { expiringCMNsReport, match } = this.props;
    if (!match.params.days) {
      let path = `/reports/cmn_expiring/91/91`;
      return this.props.changePage(path);
    }

    let params = {
      by: 'days',
      days: match.params.days,
    };

    if (match.params.days_end) {
      params.days_end = match.params.days_end;
    }

    if (expiringCMNsReport.pkg === null && expiringCMNsReport.error === null) {
      this.props.fetchExpiringCMNsReport_ac(params);
    }
  }

  fetchWithPageReset() {
    //construct payload based on filters
    let selectedFilters = {};
    this.state.filterSequence.forEach((fName) => {
      const f = this.state.filters[fName];
      selectedFilters[fName] = f.options
        .filter((o) => o.checked)
        .map((o) => o.code);
    });

    this.props.fetchExpiringCMNsReport_ac({
      by: 'filters',
      val: selectedFilters,
      page: 0,
    });
    return;
  }

  downloadAllCurrent() {
    const path = '/api/expiring-pas-mco/csv/all';
    window.sch.download_as_csv(
      path,
      'MCO_Expiring_ALLQs_' +
        this.props.expiringCMNsReport.pkg.staging_info.date_range_str +
        '.csv'
    );
  }

  downloadIndCurrent() {
    const path = '/api/expiring-pas-mco/csv/ind';
    window.sch.download_as_csv(
      path,
      'MCO_Expiring_IND_' +
        this.props.expiringCMNsReport.pkg.staging_info.date_range_str +
        '.csv'
    );
  }

  onFilterChange = (filterName) => (evt) => {
    let sf = _.cloneDeep(this.state.filters[filterName]);
    sf.options = sf.options.map((s) => {
      if (evt.target.value === s.code) {
        s.checked = evt.target.checked;
      }
      return s;
    });
    this.setState({ filters: { ...this.state.filters, [filterName]: sf } });
  };

  onPageChange(evt) {
    this.setState({ page: parseInt(evt.target.value, 10) });
  }

  render() {
    const { match, classes, expiringCMNsReport } = this.props;
    const title = 'Expiring Oxygen Medicare CMNs';
    if (expiringCMNsReport.error) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'Something went wrong. Contact IT'}
        />
      );
    }

    if (!expiringCMNsReport.pkg) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      );
    }

    if (expiringCMNsReport.isFetching) {
      return (
        <FetchingStatementWrap
          title={title}
          msg={'This should not take more than 10 seconds'}
        />
      );
    }

    const { pkg } = expiringCMNsReport;
    let subtitle = 'MediCare - O2 Expiring CMNs ';
    if (match.params.days) {
      subtitle += ' - Expiring in ' + match.params.days + ' Days';
      if (match.params.days_end) {
        subtitle += ' to  ' + match.params.days_end + ' Days';
      }
    }

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={[classes.root, classes.fullHeight].join(' ')}>
            <main className={classes.content}>
              {/* <div className={classes.appBarSpacer} /> */}
              <Grid container spacing={8} className={classes.fullHeight}>
                {/* BEGIN STATS */}
                <Grid item xs={12} sm={12} className={classes.fullHeight}>
                  {pkg.rr && (
                    <main
                      className={[
                        classes.content,
                        classes.pagination,
                        classes.fullHeight,
                      ].join(' ')}>
                      <label className={classes.label}>
                        {subtitle} ({pkg.rr.length} accounts):
                      </label>
                      <div className={classes.scrollArea}>
                        <Table padding="dense">
                          <TableHead>
                            <TableRow>
                              <TableCell>Patient</TableCell>
                              <TableCell>Item/DOS</TableCell>
                              <TableCell>CMN Dates</TableCell>
                              <TableCell>CMN Return/WOPD Return</TableCell>
                              <TableCell>Doctor/Payer</TableCell>
                              <TableCell style={{ maxWidth: '25%' }}>
                                Automation
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pkg.rr.map((r, idx) => (
                              <TableRow key={idx}>
                                <TableCell variant="body">
                                  {r.Last_Name}, {r.First_Name}
                                  <br />
                                  <Link to={'/dfa/account_docs/' + r.Account}>
                                    {r.Account}-{r.Member}
                                  </Link>
                                </TableCell>
                                <TableCell variant="body">
                                  <div>{r.BillingCode}</div>
                                  <div>{r.BillingCodeCategoryDescription}</div>
                                  <div>
                                    {moment(
                                      r.DOS.substr(0, 10),
                                      'YYYY-MM-DD'
                                    ).format('MM-DD-YYYY')}
                                  </div>
                                </TableCell>

                                <TableCell variant="body">
                                  {moment(
                                    r.MyCMN_EffectiveDate.substr(0, 10),
                                    'YYYY-MM-DD'
                                  ).format('MM-DD-YYYY')}
                                  &nbsp;to&nbsp;
                                  <strong>
                                    {moment(
                                      r.MyCMN_ExpirationDate.substr(0, 10),
                                      'YYYY-MM-DD'
                                    ).format('MM-DD-YYYY')}
                                  </strong>
                                  <br />
                                  Days to Expiration: {r.daysAway}
                                  <br />
                                  {r.appt && (
                                    <strong style={{ color: 'blue' }}>
                                      Appointment Date:{' '}
                                      {r.appt.appt_date_mm_dd_yyyy}
                                    </strong>
                                  )}
                                </TableCell>
                                <TableCell variant="body">
                                  {r.MyCMN_CMNReturnDate
                                    ? moment(
                                        r.MyCMN_CMNReturnDate.substr(0, 10),
                                        'YYYY-MM-DD'
                                      ).format('MM-DD-YYYY')
                                    : 'None'}
                                  <br />
                                  {r.MyCMN_WrittenOrderReturnDate
                                    ? moment(
                                        r.MyCMN_WrittenOrderReturnDate.substr(
                                          0,
                                          10
                                        ),
                                        'YYYY-MM-DD'
                                      ).format('MM-DD-YYYY')
                                    : 'None'}
                                </TableCell>
                                <TableCell variant="body">
                                  Dr. {r.CMN_MD_Last_Name},{' '}
                                  {r.CMN_MD_First_Name}
                                  <br />
                                </TableCell>

                                <TableCell style={{ width: '25%' }}>
                                  {!_.isEmpty(r.ivr_info) && (
                                    <CMNIvrActivityPanel
                                      oxyCmnPkg={r.ivr_info}
                                    />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </main>
                  )}
                </Grid>
                {/* END TODO CARDS LIST */}
              </Grid>
            </main>
          </div>
        </MuiThemeProvider>
      </>
    );
  }
}

ExpiringCMNsReportLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  expiringCMNsReport: stateFromStore.expiringCMNsReport,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchExpiringCMNsReport_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ExpiringCMNsReportLanding))
);
