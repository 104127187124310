import { Grid } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
    cancel_masterSearch_ac,
    masterSearch_ac
} from '../../actions/masterSearch.ac.js'
import DateUtil from '../../util/dateProcessing'

const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit * 2,
        display: 'block',
        minWidth: 200
    }
})

const defaultSearchFields = {
    firstName: '',
    lastName: '',
    dob: ''
}
const validationFields = {
    firstName: false,
    lastName: false,
    dob: false
}
class IdnShellSearchForm extends React.Component {
    constructor() {
        super()
        this.state = {
            searchField: {
                ...defaultSearchFields
            },
            errors: {
                ...validationFields
            }
        }
    }

    executeSearch = () => {
        const isValidForm = this.validateForm()
        if (!isValidForm) return

        const { searchField } = this.state;
        this.idnSearchStr = [`${searchField.lastName},`, `${searchField.firstName}`, `${searchField.dob}`].join(' ').toUpperCase();
        this.props.masterSearch_ac(this.idnSearchStr)
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState(pre => ({
            searchField: {
                ...pre.searchField,
                [name]: value
            }
        }), () => {
            if (this.state.errors[name]) {
                this.validateForm()
            }
        })
    }

    validateForm = () => {
        let firstNameErr = false
        let lastNameErr = false
        let dobErr = false
        let isValidForm = true

        const { searchField } = this.state

        if (!searchField['firstName']) {
            firstNameErr = true
            isValidForm = false
        }

        if (!searchField['lastName']) {
            lastNameErr = true
            isValidForm = false
        }

        if (!DateUtil.isValidDate(searchField['dob'])) {
            dobErr = true
            isValidForm = false
        }

        this.setState({
            errors: {
                firstName: firstNameErr,
                lastName: lastNameErr,
                dob: dobErr,
            }
        })

        return isValidForm;
    }

    render() {
        const { classes, masterSearch } = this.props
        const { errors } = this.state;
        return (
            <>
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}
                        fullWidth
                    >
                        <FormLabel component="label">
                            <strong>First Name</strong>
                        </FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            required
                            error={errors.firstName}
                            helperText={errors.firstName ? 'Please enter first name' : ''}
                            placeholder="First Name"
                            name="firstName"
                        />
                        <FormLabel component="label">
                            <strong>Last Name</strong>
                        </FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            onChange={this.handleChange}
                            required
                            error={errors.lastName}
                            helperText={errors.lastName ? 'Please enter last name' : ''}
                            placeholder="Last Name"
                            name="lastName"
                        />
                        <FormLabel component="label">
                            <strong>D.O.B</strong>
                        </FormLabel>
                        <TextField
                            fullWidth
                            variant="outlined"
                            required
                            error={errors.dob}
                            helperText={errors.dob ? 'Please enter valid date' : ''}
                            onChange={this.handleChange}
                            placeholder="MM-DD-YYYY"
                            name="dob"
                        />
                    </FormControl>
                </Grid>
                <Divider component="hr" />
                <Grid item xs={12} sm={12} md={12}>
                    <FormControl
                        style={{ marginLeft: 0 }}
                        className={classes.formControl}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={this.executeSearch}
                            disabled={masterSearch.isFetching}
                        >
                            {masterSearch.isFetching ? 'Searching...' : 'Search'}
                        </Button>
                    </FormControl>
                </Grid>
            </>
        )
    }
}

IdnShellSearchForm.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
    masterSearch: stateFromStore.masterSearch,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            masterSearch_ac,
            cancel_masterSearch_ac,
        },
        dispatch
    )

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withStyles(styles)(IdnShellSearchForm))
)
