import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { push } from 'connected-react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchUBStats_ac } from '../../actions/ub.ac.js'
import { DfaAppBar } from '../Dfa/DfaAppBar'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'auto'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  fullHeight: {
    height: '100%'
  }
})

class UBReportFilters extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h5>Payer Group</h5>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup
            aria-label="Payer Group"
            className={classes.group}
            value={this.props.selectedFilters.payer_group}
            onChange={this.props.onPayerGroupFilterChange}>
            <FormControlLabel value="ccs" label="CCS" control={<Radio />} />
            <FormControlLabel
              value="mcal"
              label="Medi-Cal"
              control={<Radio />}
            />
          </RadioGroup>
        </FormControl>
      </div>
    )
  }
}

class UBStatsLanding extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFilters: {
        payer_group:
          _.get(this.props, 'match.params.payer_group', false) || 'ccs'
      }
    }
    this.payerGroupFilterChange = this.payerGroupFilterChange.bind(this)
  }

  componentDidMount() {
    return
  }

  componentDidUpdate(prevProps, prevState) {
    const { ubList, match } = this.props
    if (!match.params.payer_group) {
      let path = `/unbilled_stats/ccs`
      return this.props.changePage(path)
    }

    if (ubList.isFetching) return
    if (ubList.error) return
    if (
      ubList.stats === null ||
      this.props.match.params.payer_group !== prevProps.match.params.payer_group
    ) {
      return this.props.fetchUBStats_ac(this.props.match.params)
    }
  }

  payerGroupFilterChange(e, v) {
    let _selectedFilters = {
      payer_group: v
    }
    this.setState({ selectedFilters: _selectedFilters })
    let path = `/unbilled_stats/${v}`
    this.props.changePage(path)
  }

  render() {
    const { classes, ubList } = this.props

    let title =
      'Unbilled - Stats for ' +
      this.state.selectedFilters.payer_group.toUpperCase()
    let pa_doc_type
    switch (this.state.selectedFilters.payer_group) {
      case 'ccs':
        pa_doc_type = 'SAR'
        break
      case 'mcal':
        pa_doc_type = 'TAR'
        break
      default:
        pa_doc_type = 'SAR'
        break
    }

    let marginLessContent = [
      classes.content,
      classes.pagination,
      classes.fullHeight
    ].join(' ')

    if (ubList.isFetching) {
      return (
        <>
          <CssBaseline />
          <MuiThemeProvider theme={theme}>
            <div className={[classes.root, classes.scrollArea].join(' ')}>
              <DfaAppBar
                title={title}
                filterComponent={
                  <UBReportFilters
                    classes={classes}
                    selectedFilters={this.state.selectedFilters}
                    onPayerGroupFilterChange={this.payerGroupFilterChange}
                  />
                }
              />
              <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Grid container spacing={8} className={classes.fullHeight}>
                  {/* BEGIN STATS */}
                  <Grid item xs={12} sm={10} className={classes.fullHeight}>
                    {this.props.ubList.stats && (
                      <main className={marginLessContent}>
                        <div className={classes.scrollArea}>
                          <label className={classes.label}>
                            ...loading report
                          </label>
                        </div>
                      </main>
                    )}
                  </Grid>
                  {/* END TODO CARDS LIST */}

                  <Grid item xs={12} sm={2} className={classes.fullHeight}>
                    <main className={marginLessContent} />
                  </Grid>
                </Grid>
              </main>
            </div>
          </MuiThemeProvider>
        </>
      )
    }

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          <div className={[classes.root, classes.scrollArea].join(' ')}>
            <DfaAppBar
              title={title}
              filterComponent={
                <UBReportFilters
                  classes={classes}
                  selectedFilters={this.state.selectedFilters}
                  onPayerGroupFilterChange={this.payerGroupFilterChange}
                />
              }
            />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Grid container spacing={8} className={classes.fullHeight}>
                {/* BEGIN STATS */}
                <Grid item xs={12} sm={10} className={classes.fullHeight}>
                  {this.props.ubList.stats && (
                    <main className={marginLessContent}>
                      <div className={classes.scrollArea}>
                        <label className={classes.label}>
                          By {pa_doc_type} Status:
                        </label>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Status</TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">
                                Claim Lines Affected
                              </TableCell>
                              <TableCell align="right">
                                Unique SARS involved
                              </TableCell>
                              <TableCell align="right">Balance</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ubList.stats.by_state.map((r, idx) => (
                              <TableRow key={r._id.state}>
                                <TableCell component="th" scope="row">
                                  {r._id.state}
                                </TableCell>
                                <TableCell align="right" />
                                <TableCell align="right">
                                  {r.claim_lines}
                                </TableCell>
                                <TableCell align="right">
                                  {r.sar_count}
                                </TableCell>
                                <TableCell align="right">
                                  {r.bal.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        <label className={classes.label}>
                          Of the ClaimLines matching an AUTHORIZED {pa_doc_type}
                          , By Work Status:
                        </label>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Worked Status</TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">
                                Claim Lines Affected
                              </TableCell>
                              <TableCell align="right">
                                Unique SARS involved
                              </TableCell>
                              <TableCell align="right">Balance</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ubList.stats.by_work_state.map((r, idx) => (
                              <TableRow key={r._id.work_state}>
                                <TableCell component="th" scope="row">
                                  {r._id.work_state}
                                </TableCell>
                                <TableCell align="right" />
                                <TableCell align="right">
                                  {r.claim_lines}
                                </TableCell>
                                <TableCell align="right">
                                  {r.sar_count}
                                </TableCell>
                                <TableCell align="right">
                                  {r.bal.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        <label className={classes.label}>
                          Of the ClaimLines matching an AUTHORIZED {pa_doc_type}
                          , By User:
                        </label>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Worked Status</TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">
                                Claim Lines Affected
                              </TableCell>
                              <TableCell align="right">
                                Unique SARS involved
                              </TableCell>
                              <TableCell align="right">Balance</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ubList.stats.by_user.map((r, idx) => (
                              <TableRow key={r._id.nm}>
                                <TableCell component="th" scope="row">
                                  {r._id.nm}
                                </TableCell>
                                <TableCell align="right" />
                                <TableCell align="right">
                                  {r.claim_lines}
                                </TableCell>
                                <TableCell align="right">
                                  {r.sar_count}
                                </TableCell>
                                <TableCell align="right">
                                  {r.bal.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        <label className={classes.label}>
                          Of the ClaimLines matching an AUTHORIZED {pa_doc_type}
                          , By User, By Work Status:
                        </label>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>User</TableCell>
                              <TableCell align="right">
                                Activity Status
                              </TableCell>
                              <TableCell align="right">
                                Claim Lines Affected
                              </TableCell>
                              <TableCell align="right">
                                Unique SARS involved
                              </TableCell>
                              <TableCell align="right">Balance</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {ubList.stats.by_user_by_work_state.map(
                              (r, idx) => (
                                <TableRow key={idx}>
                                  <TableCell component="th" scope="row">
                                    {r._id.nm}
                                  </TableCell>
                                  <TableCell align="right">
                                    {r._id.work_state}
                                  </TableCell>
                                  <TableCell align="right">
                                    {r.claim_lines}
                                  </TableCell>
                                  <TableCell align="right">
                                    {r.sar_count}
                                  </TableCell>
                                  <TableCell align="right">
                                    {r.bal.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </div>
                    </main>
                  )}
                </Grid>
                {/* END TODO CARDS LIST */}

                <Grid item xs={12} sm={2} className={classes.fullHeight}>
                  <main className={marginLessContent} />
                </Grid>
              </Grid>
            </main>
          </div>
        </MuiThemeProvider>
      </>
    )
  }
}

UBStatsLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  ubList: stateFromStore.ubList
})

const mapDispatchToProps = (dispatch, propsOfUBStatsLanding) =>
  bindActionCreators(
    {
      fetchUBStats_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(UBStatsLanding))
)
