import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { OpsRecordCard, OpsRecordPdfViewer } from '../Dfa/IdnTabOpsForms'
import { PaginationWithNums } from '../PaginationNew'

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing.unit * 0.5,
    overflowY: 'auto'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  },
  pagination: {
    padding: 8,
    backgroundColor: '#00000005',
    borderBottom: '1px solid #00000030'
  },
  panelHdrTxt: {
    fontWeight: 800,
    fontSize: theme.typography.pxToRem(13)
  },
  chip: {
    borderRadius: 8,
    margin: 4
  },
  opsRecordPDFViewer: {
    [theme.breakpoints.up('md')]: {
      borderLeft: '2px dashed #00000020'
    },
    [theme.breakpoints.down('sm')]: {
      borderTop: '2px dashed #00000020'
    }
  },
  noteTxt: {
    color: '#4e6c7a',
    borderRadius: 4,
    marginBottom: 4,
    padding: 4,
    backgroundColor: '#ccdbe2'
  },
  noteUser: {
    marginLeft: 4,
    color: '#244e91',
    fontSize: '.98em'
  },
  link: {
    textDecoration: 'none',
    color: '#2e61ef',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      color: '#2196f3'
    }
  },
  fullHeight: {
    height: '100%'
  }
})

const PageStatment = props => {
  const { pg, selectedPageNum } = props
  const { start, end, totalRecords, totalPagesAvailable } = pg
  const pageEnd = totalRecords < end ? totalRecords : end

  return (
    <Typography color="textSecondary">
      {[
        `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
        ` Page ${selectedPageNum + 1} of ${totalPagesAvailable}`
      ].join('-')}
    </Typography>
  )
}

const OpsFormsReport = props => {
  const {
    report,
    selectedPageNum,
    handlePageChange,
    currentUser,
    classes
  } = props
  const { records = [], pagination = {} } = report

  const [allExpanded, setAllExpanded] = useState(false)
  const [pdfState, setPdfState] = useState({
    pdfURI: '',
    pdfError: null,
    isFetchingPDF: false,
    recordOnView: null
  })

  const fetchOpsRecordPDF = (opsRecord, kind) => {
    setPdfState(state => ({
      ...state,
      isFetchingPDF: true,
      recordOnView: { ...opsRecord }
    }))
    window.sch
      .get(`/api/ops_form/pdf_uri/${opsRecord._id}/${kind}`)
      .then(result => {
        setPdfState(state => ({
          ...state,
          pdfURI: result.datauri,
          pdfError: null
        }))
      })
      .catch(error => {
        setPdfState(state => ({
          ...state,
          pdfURI: '',
          pdfError: true
        }))
      })
      .finally(() => {
        setPdfState(state => ({ ...state, isFetchingPDF: false }))
      })
  }

  return (
    <Grid container spacing={8} className={classes.fullHeight}>
      <Grid item xs={12} md={6} className={classes.fullHeight}>
        <div className={classes.content}>
          <div className={classes.pagination}>
            <Grid container spacing={16} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <Typography
                  inline
                  color="textSecondary"
                  onClick={() => setAllExpanded(!allExpanded)}
                  style={{ cursor: 'pointer' }}>
                  <strong>
                    {allExpanded ? 'Collapse' : 'Expand'}
                    &ensp;All&ensp;[+/-]
                  </strong>
                </Typography>
                <PageStatment
                  pg={pagination}
                  selectedPageNum={selectedPageNum}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {pagination && pagination.totalPagesAvailable > 1 && (
                  <PaginationWithNums
                    currentPageNum={pagination.currentPageNum}
                    totalPagesAvailable={pagination.totalPagesAvailable}
                    onPageChange={handlePageChange}
                  />
                )}
              </Grid>
            </Grid>
          </div>

          <div className={classes.scrollArea} style={{ padding: 4 }}>
            {records.map((record, idx) => (
              <OpsRecordCard
                key={idx}
                classes={classes}
                ops_record={record}
                defaultExpanded={allExpanded}
                currentUser={currentUser}
                isGeneral
                onViewPDF={fetchOpsRecordPDF}
                isFetchingPDF={
                  pdfState.recordOnView &&
                  pdfState.recordOnView._id === record._id &&
                  pdfState.isFetchingPDF
                }
              />
            ))}
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.fullHeight}>
        <div
          className={[classes.content, classes.opsRecordPDFViewer].join(' ')}>
          <OpsRecordPdfViewer {...pdfState} />
        </div>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(OpsFormsReport)
