export function snack(
  state = {
    open: false,
    variant: 'info',
    message: '',
    notif_open: false,
    notif_variant: 'notif',
    notif_message: '',
    notifsPanelOpen: false,
    handleClick: () => { },
    additionalActions: []
  },
  action
) {
  switch (action.type) {
    case 'OPEN_SNACK':
      return Object.assign({}, state, {
        open: true,
        message: action.message,
        variant: action.variant,
        anchor: action.anchor,
        duration: action.duration,
        handleClick: action.handleClick,
        additionalActions: action.additionalActions
      })
    case 'CLOSE_SNACK':
      return Object.assign({}, state, {
        open: false,
        message: ''
      })
    case 'OPEN_NOTIF_SNACK':
      return Object.assign({}, state, {
        notif_open: true,
        notif_message: action.message,
        notif_variant: action.variant,
        notif_anchor: action.anchor,
        notif_duration: action.duration
      })
    case 'CLOSE_NOTIF_SNACK':
      return Object.assign({}, state, {
        notif_open: false,
        notif_message: ''
      })
    case 'CLOSE_NOTIFS_PANEL':
      return Object.assign({}, state, {
        notifsPanelOpen: false
      })
    case 'OPEN_NOTIFS_PANEL':
      return Object.assign({}, state, {
        notifsPanelOpen: true
      })
    default:
      return state
  }
}
