//reducer for dfaInView
export function dfaInView(
  state = {
    isFetching: false,
    searchStr: '',
    navList: null,
    results: null,
    pageNum: 1,
    //when tackling, put the  record in focus
    posOfActivity: null, //when not null, it's in focus
    isUpdating: false,
    wIdn: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_DOCFLOW_ASSIGNMENTS':
      return Object.assign({}, state, {
        isFetching: true,
        searchStr: action.searchStr,
        sortCode: action.sortCode,
        pageNum: action.pageNum,
        //reset anything being tackled
        posOfActivity: null,
        isUpdating: false
      })
    case 'FAILED_RECEIVED_DOCFLOW_ASSIGNMENTS':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    case 'RECEIVED_DOCFLOW_ASSIGNMENTS':
      return Object.assign({}, state, {
        results: action.results,
        isFetching: false,
        error: null
      })

    case 'DFA_TACKLED':
      return Object.assign({}, state, {
        results: action.results,
        posOfActivity: action.posOfActivity
      })
    case 'DFA_UNTACKLED':
      return Object.assign({}, state, {
        posOfActivity: null,
        results: action.results
      })

    case 'LOCAL_DFA_RECORD_UPDATE':
      return Object.assign({}, state, {
        results: action.results
      })

    //single workunit activity
    case 'UPDATE_DFA_WORKUNIT':
      return Object.assign({}, state, {
        isUpdating: true
      })
    case 'RESPONSE_UPDATE_DFA_WORKUNIT':
      return Object.assign({}, state, {
        isUpdating: false,
        results: action.updatedResults
      })

    case 'ERROR_UPDATE_DFA_WORKUNIT':
      return Object.assign({}, state, {
        isUpdating: false
      })

    case 'IDN_LABELS_RECEIVED':
      return Object.assign({}, state, {
        wIdn: action.w
      })

    default:
      return state
  }
}

//reducer for dfaInView
export function navList(
  state = {
    isFetching: false,
    loaded: false,
    baseItems: [],
    secondaryBaseItems: [],
    menus: []
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_DFA_NAV_LIST':
      return Object.assign({}, state, {
        isFetching: true
      })
    case 'FAILED_RECEIVED_DFA_NAV_LIST':
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      })
    case 'RECEIVED_DFA_NAV_LIST':
      return Object.assign({}, state, {
        isFetching: false,
        baseItems: action.baseItems,
        secondaryBaseItems: action.secondaryBaseItems,
        menus: action.menus
      })

    default:
      return state
  }
}
