import PropTypes from 'prop-types'
import React from 'react'

const SELECTION_COLOR = '#00ff00'
const SELECTED_FIELD_COLOR = '#ff6700'

const mainContainerStyle = {
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: '#0000ff20'
}

const primaryCanvasWrapperStyle = {
  width: '100%',
  padding: 8,
  height: 'calc(100% - 30px)',
  overflow: 'auto'
}

const primaryCanvasStyle = {
  cursor: 'crosshair'
}

const secondaryCanvasWrapperStyle = {
  position: 'absolute',
  top: 0,
  display: 'none'
}

const secondaryCanvasStyle = {
  display: 'none'
}

const defaultImageAreaLabel = {
  textAlign: 'center',
  color: 'grey',
  fontSize: 12,
  padding: 4
}

const toolbarStyle = {
  container: {
    height: '30px',
    fontSize: 12,
    alignItems: 'center',
    color: 'grey',
    display: 'flex',
    backgroundColor: '#f5f5f5',
    padding: 4
  },
  subContianer: {
    margin: '0px 8px 0px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  zoomLabel: {
    width: 80
  },
  indicatorContainer: {
    border: '1px solid #00000010'
  },
  indicatorSvg: {
    borderRight: '1px solid #00000010',
    borderLeft: '2px solid #00000010',
    backgroundColor: 'white'
  }
}

/**
 * Field selection and coordinate mapping tool
 * ### props:
 * - **`imgSrc`**{string} -> Source URL for the image to load.\
 * - **`mappedFields`**{array} -> Previously mapped or selected fields.
 * Where each field should contain `coords` property with atleast `x` and `y` values.
 * - **`returnSelectionData`**{function} -> Event/function to return the selected data
 */
class SelectionTool extends React.Component {
  constructor() {
    super()

    this.state = {
      isImageLoaded: false,

      primaryCanvas: null,
      primaryCtx: null,
      secondaryCanvas: null,
      secondaryCtx: null,
      imageElement: null,

      canvasWidth: 0,
      canvasHeight: 0,
      selectionCoords: {
        x1y1: [0, 0],
        x2y2: [0, 0]
      },
      zoomPercentage: 25,
      refZoomPercentage: 25,
      isSelecting: false,

      croppedImg: {
        width: 0,
        height: 0,
        dataUrl: ''
      }
    }
  }

  componentDidMount() {
    let primaryCanvas = document.getElementById('primary-canvas')
    let secondaryCanvas = document.getElementById('secondary-canvas')

    this.setState({
      primaryCanvas,
      primaryCtx: primaryCanvas.getContext('2d'),
      secondaryCanvas: secondaryCanvas,
      secondaryCtx: secondaryCanvas.getContext('2d')
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.loadDraft && this.state.imageElement) {
      this.drawAll()
    }

    if (this.props.imgSrc !== prevProps.imgSrc) {
      this.setState({
        isImageLoaded: false,
        imageElement: null,
        canvasWidth: 0,
        canvasHeight: 0,
        selectionCoords: {
          x1y1: [0, 0],
          x2y2: [0, 0]
        },
        isSelecting: false,

        croppedImg: {
          width: 0,
          height: 0,
          dataUrl: ''
        }
      })
    }
  }

  /**
   * Start selection when user clicks mouse on the image holding canvas.
   *
   * @param {object} event React's Synthetic event that wraps the nativeEvent
   * @param {object} event.nativeEvent Browser's native event
   */
  startSelection = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent

    if (!this.state.isSelecting) {
      this.setState(state => ({
        isSelecting: true,
        refZoomPercentage: state.zoomPercentage,
        selectionCoords: {
          x1y1: [offsetX, offsetY],
          x2y2: [0, 0]
        }
      }))
    }
  }

  /**
   * Resize selection region when user is holding the clicked mouse button and
   * moving around the canvas.
   *
   * @param {object} event React's Synthetic event that wraps the nativeEvent
   * @param {object} event.nativeEvent Browser's native event
   */
  resizeSelection = ({ nativeEvent }) => {
    if (this.state.isSelecting) {
      this.drawAll(this.state.selectionCoords.x1y1, [
        nativeEvent.offsetX,
        nativeEvent.offsetY
      ])
    }
  }

  /**
   * Complete or stop the selection when user releases the mouse button,then crop
   * the selected image region and draw that in the secondary canvas.
   *
   * @param {object} event React's Synthetic event that wraps the nativeEvent
   * @param {object} event.nativeEvent Browser's native event
   */
  releaseSelection = ({ nativeEvent }) => {
    const { offsetX: x2, offsetY: y2 } = nativeEvent
    const [x1, y1] = this.state.selectionCoords.x1y1
    let topLeft = [x1, y1]
    let bottomRight = [x2, y2]

    if (x1 > x2) {
      topLeft[0] = x2
      bottomRight[0] = x1
    }

    if (y1 > y2) {
      topLeft[1] = y2
      bottomRight[1] = y1
    }

    let cropWidth = Math.abs(bottomRight[0] - topLeft[0])
    let cropHeight = Math.abs(bottomRight[1] - topLeft[1])
    let imgTopLeft = this.getCanvasToImagePoint(topLeft)
    let imgBottomRight = this.getCanvasToImagePoint(bottomRight)
    let [imgWidth, imgHeight] = [
      imgBottomRight[0] - imgTopLeft[0],
      imgBottomRight[1] - imgTopLeft[1]
    ]

    if (this.state.isSelecting) {
      this.setState(
        state => ({
          isSelecting: false,
          selectionCoords: {
            ...state.selectionCoords,
            x1y1: [...topLeft],
            x2y2: [...bottomRight]
          },
          croppedImg: { width: imgWidth, height: imgHeight, dataUrl: '' }
        }),
        () => {
          if (cropHeight < 1 && cropWidth < 1) {
            return this.drawAll(topLeft, bottomRight)
          }

          if (cropHeight < 1 || cropWidth < 1) return

          this.state.secondaryCtx.drawImage(
            //Copy cropped image region to a secondary canvas
            this.state.imageElement,
            imgTopLeft[0],
            imgTopLeft[1],
            imgWidth,
            imgHeight,
            0,
            0,
            imgWidth,
            imgHeight
          )
          this.generateAndReturnData()
        }
      )
    }
  }

  /**
   * Draw a rectangular selection region.
   *
   * @param {[number, number]} p1 First point of rectangular region
   * @param {[number, number]} p2 Second point of rectangular region
   * @param {('stroke' | 'fill')} method Draw method. `stroke` for current selection and `fill` for existing selections
   * @param {string} color Color for drawing
   */
  drawASelectedRegion = (p1, p2, method, color) => {
    const { primaryCtx } = this.state

    primaryCtx[`${method}Style`] = color
    primaryCtx.beginPath()
    primaryCtx[`${method}Rect`](p1[0], p1[1], p2[0] - p1[0], p2[1] - p1[1])
  }

  /**
   * Draw the loaded image
   */
  drawLoadedImage = () => {
    const { imageElement, primaryCtx, zoomPercentage } = this.state
    let resizdedWidth = (this.state.canvasWidth * zoomPercentage) / 100
    let resizdedHeight = (this.state.canvasHeight * zoomPercentage) / 100

    primaryCtx.drawImage(imageElement, 0, 0, resizdedWidth, resizdedHeight)
  }

  /**
   * Draw a field
   *
   * @param {object} field
   * @param {number} zoomFactor
   * @param {string} fieldNumber
   */
  drawAField = (field, zoomFactor, fieldNumber) => {
    if (!(field.coords && field.coords.x && field.coords.y)) return

    let { x: imgX, y: imgY } = field.coords
    let x = parseInt(imgX * zoomFactor)
    let y = parseInt(imgY * zoomFactor)
    let x1 = parseInt((imgX + (field.coords.w || 15)) * zoomFactor)
    let y1 = parseInt((imgY + (field.coords.h || 40)) * zoomFactor)

    this.drawFieldNumber([x, y], fieldNumber)
    this.drawASelectedRegion([x, y], [x1, y1], 'stroke', SELECTED_FIELD_COLOR)
  }

  /**
   * Draw all objects.
   *
   * @param {[number, number]} currSelectionPt1 First point of current selection region
   * @param {[number, number]} currSelectionPt2 Second point of current selection region
   */
  drawAll = (currSelectionPt1, currSelectionPt2) => {
    const { primaryCtx, primaryCanvas, zoomPercentage } = this.state
    let zoomFactor = zoomPercentage / 100

    primaryCtx.clearRect(0, 0, primaryCanvas.width, primaryCanvas.height)
    this.drawLoadedImage()

    if (this.props.mappedFields.length) {
      primaryCtx.setLineDash([10, 3, 3, 3])
      this.props.mappedFields.forEach((field, idx) => {
        if (field.options) {
          return field.options.forEach((opt, optIdx) => {
            this.drawAField(opt, zoomFactor, `${idx + 1}.${optIdx + 1}`)
          })
        }

        this.drawAField(field, zoomFactor, `${idx + 1}`)
      })
      primaryCtx.setLineDash([])
    }
    if (currSelectionPt1 && currSelectionPt2) {
      this.drawASelectedRegion(
        currSelectionPt1,
        currSelectionPt2,
        'stroke',
        SELECTION_COLOR
      )
    }
  }

  /**
   * Draw field number in the canvas
   *
   * @param {[number, number]} point Top-left point of field
   * @param {string} fieldNumber Number of field
   */
  drawFieldNumber = (point, fieldNumber) => {
    const { primaryCtx } = this.state

    primaryCtx.font = '900 14px Arial'

    const { width } = primaryCtx.measureText(`${fieldNumber}`)
    const maxWidth = width + 8
    const maxHeight = 14 + 8
    primaryCtx.fillStyle = SELECTED_FIELD_COLOR

    primaryCtx.fillRect(point[0], point[1] - 20, maxWidth, maxHeight)

    primaryCtx.fillStyle = 'white'

    primaryCtx.fillText(`${fieldNumber}`, point[0] + 4, point[1] - 4)
  }

  /**
   * Handle the zoom in or out of image and primary canvas.
   *
   * @param {object} event
   */
  zoomCanvas = event => {
    const { value } = event.target

    this.setState(
      {
        zoomPercentage: parseInt(value)
      },
      () => {
        const { x1y1, x2y2 } = this.state.selectionCoords
        const { refZoomPercentage, zoomPercentage } = this.state
        let zoomFactor = zoomPercentage / refZoomPercentage

        let X1Y1 = [
          parseInt(x1y1[0] * zoomFactor),
          parseInt(x1y1[1] * zoomFactor)
        ]
        let X2Y2 = [
          parseInt(x2y2[0] * zoomFactor),
          parseInt(x2y2[1] * zoomFactor)
        ]

        this.drawAll(X1Y1, X2Y2)
      }
    )
  }

  /**
   * Handle zoom with buttons.
   *
   * @param {(1 | -1)} value Increment/decrement value
   */
  handleButtonZoom = value => {
    const { zoomPercentage } = this.state
    if (value === 1 && zoomPercentage > 99) return
    if (value === -1 && zoomPercentage < 1) return

    this.zoomCanvas({
      target: {
        value: this.state.zoomPercentage + value
      }
    })
  }

  /**
   * Setup image and canvas is state when the image is loaded successfully.
   *
   * @param {object} event
   */
  loadImage = event => {
    const { target: imgElement } = event

    if (imgElement instanceof HTMLImageElement) {
      this.setState(
        {
          imageElement: imgElement,
          canvasHeight: imgElement.height,
          canvasWidth: imgElement.width,
          isImageLoaded: true
        },
        () =>
          this.drawAll(
            this.state.selectionCoords.x1y1,
            this.state.selectionCoords.x2y2
          )
      )
      if (imgElement.width > imgElement.height) {
        this.props.setPageImgOrientation('landscape')
      } else {
        this.props.setPageImgOrientation('portrait')
      }
    }
  }

  /**
   * Map canvas selection coordinates to that of actual image
   *
   * @param {[number, number]} point
   */
  getCanvasToImagePoint = point => {
    if (!this.state.imageElement) return [0, 0]

    const imageResizeFactor = this.state.refZoomPercentage / 100

    let x = parseInt(point[0] / imageResizeFactor)
    let y = parseInt(point[1] / imageResizeFactor)

    if (x > this.state.imageElement.width) {
      x = this.state.imageElement.width
    }

    if (y > this.state.imageElement.height) {
      y = this.state.imageElement.height
    }

    return [x, y]
  }

  /**
   * Generate and return selection data with coordinates, dimensions and cropped-image dataURL
   */
  generateAndReturnData = () => {
    if (this.state.secondaryCanvas) {
      let dataUrl = this.state.secondaryCanvas.toDataURL() //Generate data URL for cropped image from secondary canvas
      const { x1y1, x2y2 } = this.state.selectionCoords
      const [x1, y1] = this.getCanvasToImagePoint(x1y1)
      const [x2, y2] = this.getCanvasToImagePoint(x2y2)

      this.props.returnSelectionData({
        coords: {
          x: x1 > x2 ? x2 : x1,
          y: y1 > y2 ? y2 : y1,
          w: Math.abs(x2 - x1),
          h: Math.abs(y2 - y1)
        },
        croppedImgURL: `${dataUrl}`
      })
    }
  }

  render() {
    const {
      isImageLoaded,
      croppedImg,
      isSelecting,
      canvasWidth,
      canvasHeight,
      zoomPercentage
    } = this.state
    //const { x1y1, x2y2 } = selectionCoords

    return (
      <div style={{ ...mainContainerStyle }}>
        <img
          crossOrigin="anonymous"
          id="current-image-holder"
          src={this.props.imgSrc}
          alt=""
          onLoad={this.loadImage}
          style={{ display: 'none' }}
        />

        <div style={{ ...secondaryCanvasWrapperStyle }}>
          <canvas
            id="secondary-canvas"
            width={croppedImg.width || 0}
            height={croppedImg.height || 0}
            style={{ ...secondaryCanvasStyle }}
          />
        </div>

        <div style={{ ...primaryCanvasWrapperStyle }}>
          {!isImageLoaded && (
            <div style={{ ...defaultImageAreaLabel }}>
              Select a mapping tool job
            </div>
          )}
          <canvas
            width={(canvasWidth * zoomPercentage) / 100}
            height={(canvasHeight * zoomPercentage) / 100}
            id="primary-canvas"
            onMouseDown={isImageLoaded ? this.startSelection : () => {}}
            onMouseUp={isImageLoaded ? this.releaseSelection : () => {}}
            onMouseMove={isImageLoaded ? this.resizeSelection : () => {}}
            style={{ ...primaryCanvasStyle }}
          />
        </div>

        <div style={{ ...toolbarStyle.container }}>
          <div style={{ ...toolbarStyle.subContianer }}>
            <label style={{ ...toolbarStyle.zoomLabel }}>
              Zoom:&ensp;{zoomPercentage}%&ensp;
            </label>
            <button
              onClick={() => this.handleButtonZoom(-1)}
              disabled={!isImageLoaded || zoomPercentage < 1 || isSelecting}>
              -
            </button>
            <input
              type="range"
              min="0"
              max="100"
              step="5"
              value={zoomPercentage}
              onChange={this.zoomCanvas}
              disabled={!isImageLoaded || isSelecting}
            />
            <button
              onClick={() => this.handleButtonZoom(1)}
              disabled={!isImageLoaded || zoomPercentage > 99 || isSelecting}>
              +
            </button>
          </div>

          <div style={{ ...toolbarStyle.subContianer }}>
            <div style={{ ...toolbarStyle.indicatorContainer }}>
              <svg
                height="28"
                width="30"
                style={{ ...toolbarStyle.indicatorSvg }}>
                <line
                  x1="0"
                  y1="14"
                  x2="9"
                  y2="14"
                  style={{ stroke: SELECTED_FIELD_COLOR, strokeWidth: 2 }}
                />
                <line
                  x1="12"
                  y1="14"
                  x2="15"
                  y2="14"
                  style={{ stroke: SELECTED_FIELD_COLOR, strokeWidth: 2 }}
                />
                <line
                  x1="18"
                  y1="14"
                  x2="27"
                  y2="14"
                  style={{ stroke: SELECTED_FIELD_COLOR, strokeWidth: 2 }}
                />
              </svg>
              &ensp;
              <span>Previous selections</span>&ensp;
            </div>
            &ensp;
            <div style={{ ...toolbarStyle.indicatorContainer }}>
              <svg
                height="28"
                width="30"
                style={{ ...toolbarStyle.indicatorSvg }}>
                <line
                  x1="0"
                  y1="14"
                  x2="28"
                  y2="14"
                  style={{ stroke: SELECTION_COLOR, strokeWidth: 2 }}
                />
              </svg>
              &ensp;
              <span>Current selection</span>&ensp;
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SelectionTool.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  mappedFields: PropTypes.array,
  returnSelectionData: PropTypes.func
}

SelectionTool.defaultProps = {
  imgSrc: '',
  mappedFields: [],
  returnSelectionData: () => {}
}

export default SelectionTool
