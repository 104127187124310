/* eslint-disable react/jsx-filename-extension */
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'

import CategoryNode from './CategoryNode'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: 'left',
    background: '#f1f1f1',
    maxHeight: '100%',
    overflowY: 'auto',
    boxShadow: 'none',
    padding: 4
  },
  box: {
    overflowX: 'auto',
    height: '20vh',
    maxHeight: 'max-content'
  },
  flexContainer: {
    display: 'block'
  },
  fullHeight: {
    height: '100%'
  }
})

const ProductCategory = props => {
  const { classes } = props
  const { onSelect, onExpand, onCollapse } = props
  const { categories } = props.categories

  return (
    <div className={classes.paper}>
      {
        categories.map(category => (
          <CategoryNode
            key={category.cat_id}
            category={category}
            onExpand={onExpand}
            onCollapse={onCollapse}
            onSelect={onSelect}
          />
        ))
      }
    </div>
  )
}

ProductCategory.propTypes = {
  categories: PropTypes.object.isRequired,
  onCollapse: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default withStyles(styles)(ProductCategory)
