import { confirmationLogActionTypes } from '../actions/confirmation_log.ac'

export function confirmationLog(
  state = {
    records: null,
    pagination: null,
    error: null,
    isFetching: false,
    isSaving: false
  },
  action
) {
  switch (action.type) {
    case confirmationLogActionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case confirmationLogActionTypes.SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving
      };
    case confirmationLogActionTypes.RECEIVED_CONFIRMATION_LOG_LIST:
      return {
        ...state,
        records: action.record.records,
        pagination: action.record.pagination,
        error: null
      };
    case confirmationLogActionTypes.FAILED_CONFIRMATION_LOG_LIST:
      return {
        ...state,
        records: null,
        pagination: null,
        error: action.error
      };
    case confirmationLogActionTypes.RECEIVED_UPDATED_CONFIRMATION_LOG_LIST:
      return {
        ...state,
        records: action.record.records,
        pagination: action.record.pagination
      }
    default:
      return state;
  }
}

export function confirmationEntryForm(
  state = {
    issues: null,
    issuesError: null,
    isFetchingIssues: false,
    formData: null
  },
  action
) {
  switch (action.type) {
    case confirmationLogActionTypes.SET_IS_FETCHING_CONFIRMATION_ISSUES:
      return {
        ...state,
        isFetchingIssues: action.isFetching
      };
    case confirmationLogActionTypes.RECEIVED_CONFIRMATION_ISSUES:
      return {
        ...state,
        issues: action.record.issues,
        issuesError: null
      };
    case confirmationLogActionTypes.FAILED_CONFIRMATION_ISSUES:
      return {
        ...state,
        issues: null,
        issuesError: action.error
      };
    case confirmationLogActionTypes.STORE_FORM_DATA:
      return {
        ...state,
        formData: action.data
      };
    default:
      return state;
  }
}
