import {
  Button,
  Checkbox,
  TextField,
  FormLabel,
  Typography,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
  formContainer: {
    padding: 8,
    border: '1px solid #00000020',
    borderRadius: 2,
    margin: '8px 0'
  },
  formSet: {
    marginBottom: 24,
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 200
  },
  formSetHeader: {
    textTransform: 'uppercase',
    fontWeight: 600
  },
  formElement: {
    marginBottom: 8,
    marginTop: 8,
    minWidth: 200
  },
  totalsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  totalsLabel: {
    width: 'calc(40% - 8px)',
    textAlign: 'right',
    margin: '8px 8px 8px 0px'
  },
  totalsValue: {
    width: 'calc(60% - 8px)',
    marginRight: 8
  },
  formFoot: {
    marginBottom: 8,
    borderTop: '1px solid #00000020'
  }
})

const COMPLETED_SECTIONS_MAP = {
  patientInfo: 'Patient Info',
  additionalInfo: 'Additional Info',
  insuranceInfo: 'Insurance Info',
  workOrderInfo: 'Work Order Info',
  cmnInfo: 'CMN Info',
  priorAuthInfo: 'Prior Auth Info'
}

const INVOICE_TOTALS_MAP = {
  subTotal: 'Sub Total',
  tax: 'Tax',
  total: 'Total',
  coPay: 'Co-Pay',
  coPayTax: 'Co-Pay Tax'
}

const COMPLETED_SECTIONS_KEYS = Object.keys(COMPLETED_SECTIONS_MAP)
const INVOICE_TOTALS_KEYS = Object.keys(INVOICE_TOTALS_MAP)

class SummaryTabTopComponent extends React.Component {
  constructor() {
    super()

    this.state = {
      formData: {
        completedSections: COMPLETED_SECTIONS_KEYS.reduce((acc, curr) => (
          Object.assign(acc, { [curr]: false })
        ), {}),
        selectionCount: 0
      },
      invoiceTotals: INVOICE_TOTALS_KEYS.reduce((acc, curr) => (
        Object.assign(acc, { [curr]: 0.00 })
      ), {}),
      planOfServiceCompleted: ''
    }
  }

  handleCompletedSectionsCheck = event => {
    const { name, value } = event.target

    this.setState(state => ({
      formData: {
        ...state.formData,
        completedSections: {
          ...state.formData.completedSections,
          [name]: value
        },
        selectionCount: value
          ? state.formData.selectionCount + 1
          : state.formData.selectionCount - 1
      }
    }))
  }

  handleSaveBtnClick = () => {
    console.log('SAVE CHANGES')
  }

  render() {
    const { formData, invoiceTotals, planOfServiceCompleted } = this.state
    const { classes } = this.props

    return (
      <div className={classes.formContainer}>
        <div className={classes.formSet}>
          <Typography
            variant="body2"
            color="primary"
            className={classes.formSetHeader}>
            Sections Completed
            </Typography>

          {COMPLETED_SECTIONS_KEYS.map(item => (
            <FormControlLabel
              key={item}
              name={item}
              label={
                <Typography variant="inherit" color="inherit">
                  <strong>{COMPLETED_SECTIONS_MAP[item]}</strong>
                </Typography>
              }
              checked={formData.completedSections[item]}
              control={<Checkbox style={{ padding: 8 }} />}
              className={classes.formElement}
              style={{ margin: 0 }}
              onChange={e => this.handleCompletedSectionsCheck({
                target: {
                  name: item,
                  value: !formData.completedSections[item]
                }
              })} />
          ))}

          <hr style={{ width: '100%' }} />

          <FormControlLabel
            name="allSectionsCompleted"
            label={
              <Typography variant="inherit" color="inherit">
                <strong>
                  Work order(s) are ready to be released for delivery
                  </strong>
              </Typography>
            }
            checked={formData.selectionCount === 6}
            control={<Checkbox style={{ padding: 8 }} />}
            className={classes.formElement}
            style={{ margin: 0 }}
            disabled />
        </div>

        <div className={classes.formSet}>
          <Typography
            variant="body2"
            color="primary"
            className={classes.formSetHeader}>
            Invoice Totals
            </Typography>

          {INVOICE_TOTALS_KEYS.map(item => (
            <React.Fragment key={item}>
              <div className={classes.totalsContainer}>
                <div className={classes.totalsLabel}>
                  <FormLabel>
                    {INVOICE_TOTALS_MAP[item]}
                  </FormLabel>
                </div>
                <div className={classes.totalsValue}>
                  <TextField
                    fullWidth
                    margin="dense"
                    value={`${invoiceTotals[item].toFixed(2)}`}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      style: {
                        textAlign: 'right'
                      }
                    }} />
                </div>
              </div>
              {item === 'tax' && (
                <hr style={{ width: '80%', marginLeft: '16%' }} />
              )}
            </React.Fragment>
          ))}
        </div>

        <div className={classes.formSet}>
          <Typography
            variant="body2"
            color="primary"
            className={classes.formSetHeader}>
            Plan of Service Completed
            </Typography>

          <TextField
            fullWidth
            margin="dense"
            value={planOfServiceCompleted}
            disabled />
        </div>

        <div className={[classes.formSet, classes.formFoot].join(' ')}>
          <div className={classes.formElement}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={this.handleSaveBtnClick}>
              Save Changes
              </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(SummaryTabTopComponent)
