import forge from 'node-forge';

const interpolate = (string, params) => {
  return string.replace(/:([a-zA-Z]+)/g, (match, key) => {
    return params[key] || match; // if key is not found fallback to the original match
  });
};

const toSnakeCase = (str) =>
  str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

const decodeBase64 = (encryptedBase64, [key1, key2], al = 'AES-CBC') => {
  const key = forge.pkcs5.pbkdf2(key1, '', 1, 16);
  const iv = forge.util.decode64(key2);

  const byteEncoded = forge.util.decode64(encryptedBase64);
  const buffer = forge.util.createBuffer(byteEncoded);
  const decipher = forge.cipher.createDecipher(al, key);

  decipher.start({ iv });
  decipher.update(buffer);
  decipher.finish();

  return forge.util.encode64(decipher.output.bytes());
};

const fetchImage = async (imageUrl, decryptionKeys) => {
  const shouldDecrypt = decryptionKeys && decryptionKeys.length;
  const res = await fetch(imageUrl, {
    mode: 'cors',
  });
  if (!res.ok) throw new Error(res.statusText);

  const rawRes = await res[shouldDecrypt ? 'text' : 'blob']();
  return shouldDecrypt
    ? 'data:image/png;base64,' + decodeBase64(rawRes, decryptionKeys)
    : URL.createObjectURL(rawRes);
};

const fetchImages = (urls, keys) => {
  return Promise.all(urls.map((url) => fetchImage(url, keys)));
};

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

const snakeCaseToTitleCase = (str) =>
  str
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

const getFullName = ({ first_name, last_name, middle = '' } = {}) =>
  Boolean(first_name)
    ? `${last_name}, ${first_name || ''} ${middle}`.replace(/\s+/g, ' ').trim()
    : '';

const getErrorMessage = (err, fallback) => {
  const msg = err?.error || err?.message || err.response?.data?.message;

  return (typeof msg === 'string' || msg instanceof String) &&
    !msg.includes('[object Object]')
    ? msg
    : fallback || 'Unknown error occurred!';
};

const formatPhoneNumber = (str) => {
  const cleaned = ('' + str).replace(/\D/g, '');

  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? '1' : '';
    return [intlCode, match[2], match[3], match[4]].join('-');
  }

  return null;
};

const parsePhoneNumber = (rawNum = '') => {
  if (!Boolean(rawNum)) return ['', '', ''];
  let num = rawNum.trim();
  num = num.replace(/[^0-9]/g, '');

  if (num.startsWith('+1')) num = num.substring(2);
  if (num.startsWith('1')) num = num.substring(1);

  if (num.length !== 10) return ['', '', ''];

  const areaCode = num.substring(0, 3);
  const areaCode2 = num.substring(3, 6);
  const phoneNum = num.substring(6);

  return [areaCode, areaCode2, phoneNum];
};

let c = 1;
const getNextId = () => {
  c++;

  return 'CST-' + c;
};

const truncate = (input, size = 5, suffix = '') => {
  if (!input) return '';
  if (input.length > size) {
    return input.substring(0, size) + '...' + suffix;
  }
  return input;
};

const nmToId = (nm) => nm.replace(/\s/g, '-').toLowerCase();

const getPageStatement = (pagination) => {
  const { start, end, totalRecords, totalPagesAvailable, currentPageNum } =
    pagination;
  let pageEnd = totalRecords < end ? totalRecords : end;

  return [
    `Displaying ${start + 1} - ${pageEnd} of ${totalRecords} Total `,
    ` Page ${currentPageNum + 1} of ${totalPagesAvailable}`,
  ].join('-');
};

const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

const _LETTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const _LETTERS_LEN = 26;

const genStr = (length = 1) => {
  let str = '';
  for (let i = 0; i < length; i++) {
    str += _LETTERS.charAt(Math.floor(Math.random() * _LETTERS_LEN));
  }
  return str;
};

const isValidString = (str, len = 0) => {
  return (typeof str === 'string' || str instanceof String) && str.length > len;
};

const _num_spec = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
const _num_des = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet',
];

const stringifyNumber = (n) => {
  if (n < 20) return _num_spec[n];
  if (n % 10 === 0) return _num_des[Math.floor(n / 10) - 2] + 'ieth';
  return _num_des[Math.floor(n / 10) - 2] + 'y-' + _num_spec[n % 10];
};

const parseIntOrErr = (string, radix) => {
  var result = parseInt(string, radix);
  if (isNaN(result)) {
    throw new Error('Unable to parse Int!');
  }
  return result;
};

const uid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

const toString = (val, mapper) => {
  if (val && 'length' in val)
    return val.length ? (mapper ? val.map(mapper) : val.join(', ')) : '-';

  return val;
};

const preventKeys = (e, keysToPrevent = ['e', 'E', '-', '+']) => {
  if (keysToPrevent.includes(e.key)) {
    e.preventDefault();
  }
};

export {
  toTitleCase,
  getFullName,
  snakeCaseToTitleCase,
  getNextId,
  isValidHttpUrl,
  uuidv4,
  uid,
  decodeBase64,
  nmToId,
  fetchImage,
  parsePhoneNumber,
  fetchImages,
  stringifyNumber,
  interpolate,
  isValidString,
  genStr,
  getPageStatement,
  getErrorMessage,
  formatPhoneNumber,
  truncate,
  parseIntOrErr,
  toSnakeCase,
  toString,
  preventKeys,
};
