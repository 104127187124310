//import CardActionArea from '@material-ui/core/CardActionArea';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  createTodo_ac,
  openSnack_ac,
  resetTodoCreationActivity_ac,
  updateTodo_ac,
} from '../../actions/todos.ac.js';
import GLogin from '../GLogin';
import UserSearch from '../Users/UserSearch';

//import { push } from 'connected-react-router'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflowX: 'auto',
  },
  linkTo: {
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
      fontWeight: 800,
      backgroundColor: '#eeff41',
    },
  },
  radioGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  radioGroupRowItem: {
    flex: '1 280px',
    marginLeft: 0,
    labelPlacementStart: {
      flexDirection: 'row',
    },
  },
  /*
  radioGroupRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'stretch',
    justifyContent: 'flex-start',
    height: '100%'
  },
  radioGroupRowItem: {
    flex: '1 0 18%',
    marginBottom: 16
  },
  */
  card: {
    minWidth: 275,
    marginBottom: 20,
    marginTop: 10,
  },
  cardHdrRoot: {
    paddingBottom: 8,
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
    color: '#000',
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800,
  },

  cardContentRoot: {
    paddingBottom: 40,
    marginBottom: 40,
  },

  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  listItemText: {
    color: '#3F51B5',
    fontWeight: 600,
    fontSize: '.92em',
  },

  title: {
    fontSize: 14,
  },
  posVA: {
    color: '#008AE0',
  },
  slaStart: {
    marginTop: 6,
    color: '#008AE0',
  },
  slaPasses: {
    color: '#E51400',
    marginBottom: 6,
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing.unit * 1,
    display: 'block',
  },
  formControlReq: {
    margin: theme.spacing.unit * 1,
    color: '#6200EA',
    display: 'block',
  },

  formControlLbl: {
    marginLeft: 0,
    marginBottom: theme.spacing.unit * 1,
    display: 'block',
  },
  formControlLblReq: {
    marginLeft: 0,
    marginBottom: theme.spacing.unit * 1,
    color: '#6200EA',
    display: 'block',
  },

  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'column',
  },
  memoField: {
    width: 500,
    fontSize: 12,
  },
});

/*
const CFI_FIELDS = [
  { nm: 'contact_nm', lbl: 'Name of Contact', kind: 'txt' },
  { nm: 'wos_ids', lbl: 'Related Work Order#s', kind: 'txt' },
  {
    nm: 'sentiment_beginning',
    lbl: 'How were their feelings at the beginning of the contact?',
    kind: 'radio',
    opts: ['Not Upset', 'A Little Upset', 'Moderately Upset', 'Very Upset']
  },
  {
    nm: 'sentiment_end',
    lbl: 'How were their feelings at the end of the contact?',
    kind: 'radio',
    opts: [
      'Not Satisfied',
      'Satisfied but still upset',
      'Satisfied and not upset'
    ]
  },
  {
    nm: 'urgency',
    lbl: 'Urgency of Response?',
    kind: 'radio',
    opts: [
      'Urgent, respond within 24 hours',
      'Not urgent, respond within 72 hours'
    ]
  }
]
*/

class TodoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      otherRecipientType: '',
      memo: this.props.defaultMemo || '',
      selectedAssignee: null,
      /*
      category_opts: [
        {code:"tr", lbl: "Troubleshoot" },
        {code:"va", lbl: "Ventilator Approval" },
        {code:"cr", lbl: "Clinical Review" },
        {code:"vpu", lbl: "Vent P/U Review" },
        {code:"cfi", lbl: "Customer Complaint", extra_fields: [].concat(CFI_FIELDS) }
      ],
      */
      selected_category: this.props.selectedCategory || 'general',

      atLeastOneDocTypeChecked: false,
      selectedHDMSAccountPosition: 0,
      extraFieldsValues: this.props.defaultValues
        ? Object.assign({}, this.props.defaultValues)
        : {},
    };
    this.categorySelected = this.categorySelected.bind(this);
    this.handleMemoChange = this.handleMemoChange.bind(this);
    this.handleCreateTodo = this.handleCreateTodo.bind(this);
    this.hdmsAccountPositionSelected =
      this.hdmsAccountPositionSelected.bind(this);
    this.extraRadioSelected = this.extraRadioSelected.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onSelectedAssignee = this.onSelectedAssignee.bind(this);
    this.getAssignmentRules = this.getAssignmentRules.bind(this);
  }

  hdmsAccountPositionSelected(evt, value) {
    this.setState({ selectedHDMSAccountPosition: parseInt(value, 10) });
  }

  getCategoryLabel() {
    const { TODO_STATE_REFERENCE: TODO_CATEGORIES } = this.props.lookups.ink.k;
    const _selected_category = TODO_CATEGORIES.filter(
      (e) => e.code === this.state.selected_category
    )[0];
    return _selected_category.lbl;
  }

  onSelectedAssignee(u) {
    this.setState({ selectedAssignee: u });
  }

  resetForm() {
    this.props.resetTodoCreationActivity_ac();
  }

  categorySelected(evt) {
    this.setState({ selected_category: evt.target.value });
  }

  componentDidUpdate(prevProps) {
    let _changes = {};
    if (this.props.defaultValues) {
      if (
        JSON.stringify(prevProps.defaultValues) !==
        JSON.stringify(this.props.defaultValues)
      ) {
        _changes['extraFieldsValues'] = this.props.defaultValues
          ? Object.assign({}, this.props.defaultValues)
          : {};
      }
    }

    if (this.props.defaultMemo !== prevProps.defaultMemo) {
      _changes['memo'] = this.props.defaultMemo;
    }

    if (!_.isEmpty(_changes)) {
      this.setState(_changes);
    }
  }

  handleCreateTodo = (assignTo) => () => {
    const { TODO_STATE_REFERENCE: TODO_CATEGORIES } = this.props.lookups.ink.k;
    const _selected_category = TODO_CATEGORIES.filter(
      (e) => e.code === this.state.selected_category
    )[0];

    const required_extra_fields = _.get(_selected_category, 'extra_fields', [])
      .filter((r) => r.required)
      .map((r) => r.nm);

    const display_only_extra_fields = _.get(
      _selected_category,
      'extra_fields',
      []
    )
      .filter((r) => r.display_only)
      .map((r) => r.nm);

    let pkg = {
      //selected doc types being requested

      idn: this.props.idnInView.idnStr,
      code: this.state.selected_category,
      txt: this.state.memo,
      hdms_note_category: _selected_category.hdms_note_category,
    };

    if (!_.isEmpty(this.state.extraFieldsValues)) {
      pkg.extra_fields = Object.assign({}, this.state.extraFieldsValues);
    } else {
      if (required_extra_fields.length) {
        return this.props.openSnack_ac({
          variant: 'error',
          message: 'Missing required fields',
        });
      }
    }

    const { assignee: assignee_by_rule } = this.getAssignmentRules();
    if (assignee_by_rule) {
      if (undefined === pkg.extra_fields) {
        pkg.extra_fields = {};
      }
      pkg.extra_fields.assignee = assignee_by_rule;
    }

    if (assignTo === 'self') {
      //force in extra field assignee
      if (undefined === pkg.extra_fields) {
        pkg.extra_fields = {};
      }
      pkg.extra_fields.assignee = this.state.selectedAssignee
        ? { by: 'email', email: this.state.selectedAssignee.mail }
        : { by: 'self' };
    }

    const required_extra_fields_with_no_value = required_extra_fields.filter(
      (extra_field_nm) => {
        return '' === _.get(pkg.extra_fields, extra_field_nm, '').trim();
      }
    );

    if (required_extra_fields_with_no_value.length > 0) {
      return this.props.openSnack_ac({
        variant: 'error',
        message: 'Missing required fields',
      });
    }

    const accountNumbers = _.get(this.props.idnInView, 'idnDoc.aa', []);
    if (accountNumbers.length) {
      [pkg.account, pkg.member] = accountNumbers[
        this.state.selectedHDMSAccountPosition
      ]
        .split(/-/)
        .map((n) => parseInt(n, 10));
    }

    if ('object' === typeof pkg.extra_fields) {
      display_only_extra_fields.forEach((unused_field_nm) => {
        delete pkg.extra_fields[unused_field_nm];
      });
    }
    this.props.createTodo_ac(pkg);
  };

  handleMemoChange(evt) {
    this.setState({ memo: evt.target.value });
  }

  extraFieldsTextChange = (extraFieldName) => (evt) => {
    this.setState({
      extraFieldsValues: Object.assign({}, this.state.extraFieldsValues, {
        [extraFieldName]: evt.target.value,
      }),
    });
  };

  extraRadioSelected = (extraFieldName) => (evt) => {
    const { TODO_STATE_REFERENCE: TODO_CATEGORIES } = this.props.lookups.ink.k;
    const _selected_category = TODO_CATEGORIES.filter(
      (e) => e.code === this.state.selected_category
    )[0];

    let currentEFValues = { ...this.state.extraFieldsValues };

    /*
    let _val
    if (
      _selected_category.extra_fields.filter(ef => ef.nm === extraFieldName)[0]
        .cond !== undefined
    ) {
      let alias = _selected_category.extra_fields.filter(
        ef => ef.nm === extraFieldName
      )[0].cond
      _val = alias.cats[currentEFValues[alias.parent]].opts[ parseInt(evt.target.value, 10) ]
      _val = evt.target.value;
    } else {
      _val = _selected_category.extra_fields.filter(
        ef => ef.nm === extraFieldName
      )[0].opts[parseInt(evt.target.value, 10)]
    }
    */
    let _val = evt.target.value;

    //check all other extra fields that are conditional on this field
    let conditional_fields = _selected_category.extra_fields
      .filter(
        (ef) => ef.nm !== extraFieldName && _.get(ef, 'cond.parent', false)
      )
      .map((ef) => {
        return { nm: ef.nm, parent: ef.cond.parent };
      });

    let complicit_parent_ids = [extraFieldName];
    let n_complicit = 0;
    do {
      n_complicit = _.remove(
        conditional_fields,
        (cf) => complicit_parent_ids.indexOf(cf.parent) !== -1
      ).map((cf) => {
        complicit_parent_ids.push(cf.nm);
        return cf;
      }).length;
    } while (n_complicit > 0 && conditional_fields.length > 0);

    complicit_parent_ids.forEach((cf_nm) => {
      delete currentEFValues[cf_nm];
    });

    this.setState({
      extraFieldsValues: {
        ...currentEFValues,
        [extraFieldName]: _val,
      },
    });
  };

  addOwner() {}

  getAssignmentRules() {
    const { TODO_STATE_REFERENCE: TODO_CATEGORIES } = this.props.lookups.ink.k;
    const _selected_category = TODO_CATEGORIES.filter(
      (e) => e.code === this.state.selected_category
    )[0];
    let assignment_rules_msg = '';
    let assignee = false;

    if (_selected_category.assignment_rules) {
      let source_parts =
        _selected_category.assignment_rules.source.split(/\./g);

      switch (source_parts[0]) {
        case 'ef':
          {
            let ef_v = _.get(
              this.state.extraFieldsValues,
              source_parts[1],
              false
            );
            let trr = _.get(
              _selected_category.assignment_rules.ref,
              ef_v,
              false
            );
            if (trr) {
              assignment_rules_msg =
                'Assignee will be chosen from Task Roles: ' + trr.join(',');
              assignee = {
                by: 'task_roles',
                task_roles: trr,
              };
            }
          }
          break;
        case 'assignee':
          assignment_rules_msg =
            'Assignee will be chosen from ' +
            _selected_category.assignment_rules.assignee.by +
            ': ' +
            _selected_category.assignment_rules.assignee[
              _selected_category.assignment_rules.assignee.by
            ];
          assignee = { ..._selected_category.assignment_rules.assignee };
          break;
        default:
          break;
      }
    }
    return { assignee, assignment_rules_msg };
  }

  render() {
    const { classes, lookups, idnInView, todoCreationActivity, me } =
      this.props;
    const { idnStr: idn } = idnInView;
    const { TODO_STATE_REFERENCE: TODO_CATEGORIES } = lookups.ink.k;
    const allowed_cat_codes = this.props.categories || [];
    let allowed_todo_categories = [];
    if (allowed_cat_codes.length) {
      allowed_todo_categories = TODO_CATEGORIES.filter(
        (c) => allowed_cat_codes.indexOf(c.code) !== -1
      ).filter((c) => {
        if (me.user.roles.includes('it-admin')) return true;

        if (c.allow_create_if) {
          let matches = _.intersection(
            c.allow_create_if.val,
            _.get(me.user, c.allow_create_if.field, [])
          ).length;
          switch (c.allow_create_if.rel) {
            case 'in':
              return matches > 0;
            case 'nin':
              return 0 === matches;
            default:
              return false;
          }
        }
        return true;
      });
    } else {
      allowed_todo_categories = TODO_CATEGORIES;
    }
    console.log('allowed_todo_categories', allowed_todo_categories);
    const formGroupRow = { root: classes.formGroupRoot };
    const formControlLbl = { root: classes.formControlLbl };
    const formControlLblReq = { root: classes.formControlLblReq };

    const cardContentRoot = { root: classes.cardContentRoot };
    const accountNumbers = _.get(idnInView, 'idnDoc.aa', []);
    const _selected_category = TODO_CATEGORIES.filter(
      (e) => e.code === this.state.selected_category
    )[0];
    let startNum = 1;

    const btnDisabled =
      this.state.memo.trim() === '' ||
      (todoCreationActivity.state !== 'new' &&
        todoCreationActivity.state !== 'error') ||
      false === this.props.lookups.isGapiValid;

    const { assignment_rules_msg } = this.getAssignmentRules();
    let allowPickAssignee = true;
    if (
      this.state.selected_category === 'cfi' &&
      _.get(me, 'user.roles', []).indexOf('beta-tester') === -1
    ) {
      allowPickAssignee = false;
    }

    return (
      <Card className={classes.card}>
        {this.props.showTestModeMsg && (
          <h4 style={{ color: 'red', padding: 10 }}>
            THIS FEATURE IS STILL IN TEST MODE - DO NOT CREATE REAL TASKS UNTIL
            FURTHER NOTICE
          </h4>
        )}
        {false === this.props.lookups.isGapiValid && (
          <div>
            <h5 style={{ color: 'red' }}>
              Using this tool requires granting Docflow access to your GMail
              account. Click the button below to flow through the grant process.
              Ensure that browser popups are not blocked. Then refresh this page
              after completing the grant process.
            </h5>
            <GLogin
              forceDisplay
              theme="dark"
              clientId={process.env.REACT_APP_DOCFLOW_LOGIN_CLIENT_ID}
              btnLabel="Grant GMail Permissions"
            />
          </div>
        )}

        <CardHeader
          className={classes.cardHdrRoot}
          title={
            'Create ' + this.props.title + ' for ' + idn.replace(/_/g, ' ')
          }
          subheader={
            'HDMS Account(s): ' + _.get(idnInView.idnDoc, 'aa', []).join(',')
          }
        />
        {(todoCreationActivity.state === 'done' ||
          todoCreationActivity.state === 'inprog') && (
          <CardContent classes={cardContentRoot}>
            <List className={classes.root}>
              <ListItem>
                <Avatar>
                  <DoneOutlineIcon />
                </Avatar>
                <ListItemText
                  className={classes.listItemText}
                  primary={
                    todoCreationActivity.state === 'inprog'
                      ? '..creating task'
                      : 'Task Created  - Task#: ' +
                        todoCreationActivity.resultPkg._id
                  }
                  secondary="Status"
                />
              </ListItem>
              {todoCreationActivity.state === 'done' && (
                <ListItem>
                  <Button
                    onClick={this.resetForm}
                    variant="contained"
                    color="primary"
                    className={classes.button}>
                    Reset Form
                  </Button>
                </ListItem>
              )}
            </List>
          </CardContent>
        )}

        {(todoCreationActivity.state === 'new' ||
          todoCreationActivity.state === 'error') && (
          <CardContent classes={cardContentRoot}>
            {allowed_todo_categories.length > 1 && startNum++ && (
              <FormControl required className={classes.formControlReq}>
                <FormLabel component="label">
                  {startNum - 1}) {this.props.title} Category (Required):
                </FormLabel>
                <RadioGroup
                  className={classes.radioGroupRow}
                  onChange={this.categorySelected}
                  value={this.state.selected_category}>
                  {allowed_todo_categories.map((a) => (
                    <FormControlLabel
                      labelPlacement="end"
                      className={classes.radioGroupRowItem}
                      key={a.code}
                      value={a.code}
                      label={a.lbl}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}

            {accountNumbers.length > 1 && startNum++ && (
              <FormControl
                required
                component="fieldset"
                className={classes.formControlReq}>
                <FormLabel component="label" classes={formControlLblReq}>
                  {startNum}) Check Account Number (Required):
                </FormLabel>
                <FormHelperText>
                  Make sure the correct account # is selected
                </FormHelperText>
                <RadioGroup
                  className={classes.radioGroupRow}
                  onChange={this.hdmsAccountPositionSelected}
                  value={this.state.selectedHDMSAccountPosition.toString()}>
                  {accountNumbers.map((a, pos) => (
                    <FormControlLabel
                      labelPlacement="left"
                      className={classes.radioGroupRowItem}
                      key={a}
                      value={pos.toString()}
                      label={a}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}

            <FormControl
              required
              component="fieldset"
              className={classes.formControlReq}>
              <FormLabel component="label" classes={formControlLblReq}>
                {startNum}){' '}
                {this.props.descTitle ||
                  this.props.title + ' Description (Required)'}
                :
              </FormLabel>
              <FormHelperText>
                You can add linebreaks by pressing [Enter] on your keyboard
              </FormHelperText>
              <FormGroup className={classes.formGroupRoot}>
                <TextField
                  label="Enter task description here"
                  multiline
                  margin="normal"
                  className={classes.memoField}
                  onChange={this.handleMemoChange}
                  value={this.state.memo}
                />
              </FormGroup>
            </FormControl>

            {_selected_category.extra_fields &&
              _selected_category.extra_fields.map((e, ei) => {
                switch (e.kind) {
                  case 'radio':
                    return (
                      <FormControl
                        key={'ex' + ei}
                        required={e.required === true ? true : false}
                        className={classes.formControl}
                        component="fieldset">
                        <FormLabel
                          component="label"
                          classes={
                            e.required ? formControlLblReq : formControlLbl
                          }>
                          {ei + startNum + 1}) {e.lbl}{' '}
                          {e.required === true ? '(Required)' : ''}:
                        </FormLabel>
                        {e.help_text && (
                          <FormHelperText>{e.help_text}</FormHelperText>
                        )}
                        <RadioGroup
                          className={classes.radioGroupRow}
                          onChange={this.extraRadioSelected(e.nm)}
                          value={this.state.extraFieldsValues[e.nm]}>
                          {e.cond !== undefined ? (
                            this.state.extraFieldsValues[e.cond.parent] !==
                              undefined &&
                            e.cond.cats[
                              this.state.extraFieldsValues[e.cond.parent]
                            ] !== undefined ? (
                              e.cond.cats[
                                this.state.extraFieldsValues[e.cond.parent]
                              ].opts.map((a, pos) => {
                                return (
                                  <FormControlLabel
                                    className={classes.radioGroupRowItem}
                                    labelPlacement="left"
                                    key={typeof a === 'string' ? a : a.nm}
                                    //value={pos.toString()}
                                    disabled={a.disabled === true}
                                    value={typeof a === 'string' ? a : a.nm}
                                    label={typeof a === 'string' ? a : a.nm}
                                    control={<Radio />}
                                  />
                                );
                              })
                            ) : (
                              <FormControlLabel
                                className={classes.radioGroupRowItem}
                                labelPlacement="left"
                                key={'Pending selection of ' + e.cond.parent}
                                value={'Pending selection of ' + e.cond.parent}
                                label={'Pending selection of ' + e.cond.parent}
                                disabled={true}
                                control={<Radio />}
                              />
                            )
                          ) : (
                            e.opts.map((a, pos) => (
                              <FormControlLabel
                                className={classes.radioGroupRowItem}
                                labelPlacement="left"
                                key={a}
                                value={a}
                                label={a}
                                control={<Radio />}
                              />
                            ))
                          )}
                        </RadioGroup>
                      </FormControl>
                    );
                  case 'txt':
                    return (
                      <FormControl
                        key={'ex' + ei}
                        required={e.required === true ? true : false}
                        component="fieldset"
                        className={classes.formControl}>
                        <FormLabel
                          component="label"
                          classes={
                            e.required ? formControlLblReq : formControlLbl
                          }>
                          {ei + startNum + 1}) {e.lbl}{' '}
                          {e.required === true ? '(Required)' : ''}:
                        </FormLabel>
                        <FormGroup className={classes.formGroupRoot}>
                          <TextField
                            label={e.lbl}
                            margin="normal"
                            multiline={e.multiline}
                            onChange={this.extraFieldsTextChange(e.nm)}
                            className={classes.memoField}
                            value={
                              this.state.extraFieldsValues[e.nm]
                                ? this.state.extraFieldsValues[e.nm]
                                : ''
                            }
                          />
                        </FormGroup>
                      </FormControl>
                    );
                  default:
                    return null;
                }
              })}

            <FormControl
              required
              component="fieldset"
              className={classes.formControl}>
              {_.get(this.props.defaultValues, 'assignee', false) && (
                <FormGroup classes={formGroupRow}>
                  <FormHelperText>
                    Note: {this.props.defaultValues.assignee.msg}
                  </FormHelperText>
                </FormGroup>
              )}
              <FormGroup style={{ marginTop: 24 }}>
                <Button
                  disabled={btnDisabled}
                  onClick={this.handleCreateTodo('auto')}
                  variant="contained"
                  color="primary">
                  {this.state.selected_category === 'cfi'
                    ? 'SUBMIT'
                    : `Create [${this.getCategoryLabel('category')}] ${
                        this.props.title
                      }`}
                </Button>
                <div style={{ fontSize: 12, marginTop: 12, marginBottom: 48 }}>
                  Click this button to auto assign based on task rules.
                  {assignment_rules_msg}
                </div>

                {allowPickAssignee && (
                  <>
                    <div style={{ fontSize: 12, marginBottom: 24 }}>
                      Alternatively, choose a specific assignee or assign to
                      yourself:
                    </div>
                    <Grid
                      container
                      direction="row"
                      justify="space-around"
                      alignItems="center">
                      <Grid item xs={12} md={6}>
                        <UserSearch onSelect={this.onSelectedAssignee} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button
                          disabled={btnDisabled}
                          onClick={this.handleCreateTodo('self')}
                          variant="contained"
                          color="secondary">
                          Create [{this.getCategoryLabel('category')}]{' '}
                          {this.props.title}
                          Assigned to{' '}
                          {this.state.selectedAssignee
                            ? this.state.selectedAssignee.orig_nm
                            : 'Me'}
                        </Button>
                      </Grid>
                    </Grid>

                    <div
                      style={{ fontSize: 12, marginTop: 12, marginBottom: 24 }}>
                      Click this button to assign this task to yourself. You
                      must still move this task to a PENDING status once it has
                      been created.
                    </div>
                  </>
                )}
              </FormGroup>

              {_selected_category.hdms_note_category && (
                <FormGroup classes={formGroupRow}>
                  <FormHelperText style={{ fontSize: 12 }}>
                    Note: Creating this task will add an HDMS note under
                    heading: {_selected_category.hdms_note_category}
                  </FormHelperText>
                </FormGroup>
              )}
            </FormControl>
          </CardContent>
        )}
      </Card>
    );
  }
}

TodoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  idnInView: stateFromStore.idnInView,
  todosInView: stateFromStore.todosInView,
  todoCreationActivity: stateFromStore.todoCreationActivity,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createTodo_ac,
      updateTodo_ac,
      resetTodoCreationActivity_ac,
      openSnack_ac,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TodoForm))
);
