import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  axiom_select_contract_ac,
  axiom_update_suggestions_ac,
} from '../../actions/axiom.ac';
import { truncate } from '../../util/string';
import SearchField from '../Shared/SearchField';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    //flexGrow: 1,
    padding: 4,
    width: '100%',
    overflowY: 'auto',
  },
  button: {
    marginTop: 24,
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    padding: 12,
    paddingLeft: 0,
  },
});

const ContractSearchField = (props) => {
  const {
    label,
    placeholder,
    selectContract,
    disabled = false,
    onSuggestionsReceived,
  } = props;

  const searchContracts = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr });
  };

  const renderContractSuggestion = (suggestion = {}) => {
    return (
      <div>
        <span
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#000',
            marginTop: '3px',
            display: 'block',
          }}>
          {suggestion._id && <>{suggestion._id}</>}
        </span>
        <span
          style={{
            fontSize: '10px',
            display: 'block',
          }}>
          {truncate(
            suggestion.Main[3].Contracted,
            40,
            suggestion.Misc[3] || ''
          )}
        </span>
      </div>
    );
  };

  return (
    <SearchField
      clearOnSelect
      onSuggestionsReceived={onSuggestionsReceived}
      delay={500}
      minChars={3}
      variant="outlined"
      label={label !== undefined ? label : 'Search Axiom by Payer Name'}
      resultsKey="pkg.rr"
      disabled={disabled}
      style={{ flexGrow: 1 }}
      search={searchContracts}
      selectSuggestion={selectContract}
      apiEndpoint="/api/axiom/contract/search"
      scrollableParentId="idxOrderingClinicians"
      renderSuggestion={renderContractSuggestion}
      helperText="Minimum 3 characters required"
      placeholder={
        placeholder !== undefined ? placeholder : 'Search Axiom by Payer Name'
      }
      fullWidth={true}
    />
  );
};

const SECTION_SEQUENCE = [
  'Products',
  'Contract Info',
  'DME Services',
  'COVID 19 APPROVED CHANGES',
  'Billing & CCS Dept Info',
  'Misc',
];
const YES_NO_SECTION = ['Products', 'DME Services'];
function isURL(str) {
  // Regular expression pattern to match a URL
  const urlPattern =
    /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d{2,5})?(\/\S*)?$/i;

  // Test if the string matches the URL pattern
  return urlPattern.test(str);
}

function generateLink(str) {
  if (isURL(str)) {
    // If the string is a URL, return an <a> tag to open a new tab
    return (
      <a href={str} rel="noreferrer noopener" target="_blank">
        {truncate(str, 40)}
      </a>
    );
  } else {
    // If the string is not a URL, return the input string as is
    return str;
  }
}

const renderMain = (entries) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <div style={{ color: 'black', fontSize: '13px', fontWeight: 'bold' }}>
        {entries[0].v}
      </div>
      <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
        <span style={{ marginRight: 8, color: 'black' }}>
          State: {entries[1].v}
        </span>
        <span
          style={{
            color: entries[2].v.toUpperCase() === 'YES' ? 'darkgreen' : 'red',
          }}>
          Contracted: {entries[2].v}
        </span>
      </div>
      <div style={{ display: 'flex', maginBottom: 20, flexWrap: 'wrap' }}>
        {entries.slice(3).map((p) => {
          return (
            <div key={p.k} style={{ paddingRight: '20px', float: 'left' }}>
              <label style={{ fontSize: '11px', color: 'darkgray' }}>
                {p.k}
              </label>
              <br />
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  overflowX: 'scroll',
                }}>
                {p.v.trim() === '' ? (
                  <span style={{ color: 'lightgray' }}>(blank)</span>
                ) : (
                  generateLink(p.v)
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/*  generate react component to render section, where section name is the base key, and then present each k,v pair as label and value */
const renderSection = (sectionName, entries) => {
  return (
    <div key={sectionName} style={{ marginBottom: 12 }}>
      <div>
        <span style={{ color: 'blue', fontSize: '12px', fontWeight: 'bold' }}>
          {sectionName}
        </span>
      </div>
      <div style={{ display: 'flex', maginBottom: 20, flexWrap: 'wrap' }}>
        {entries.map((p) => {
          return (
            <div key={p.k} style={{ paddingRight: '20px', float: 'left' }}>
              <label style={{ fontSize: '11px', color: 'darkgray' }}>
                {p.k}
              </label>
              <br />
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 500,
                  overflowX: 'scroll',
                }}>
                {p.v.trim() === '' ? (
                  <span style={{ color: 'lightgray' }}>(blank)</span>
                ) : (
                  generateLink(p.v)
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const renderYesNo = (sectionName, entries) => {
  return (
    <div key={sectionName} style={{ marginBottom: 12 }}>
      <div>
        <span style={{ color: 'blue', fontSize: '12px', fontWeight: 'bold' }}>
          {sectionName} ('N's are not shown)
        </span>
      </div>
      <div style={{ marginBottom: 20 }}>
        {entries
          .filter((p) => p.v !== 'N')
          .map((p) => {
            return (
              <div style={{ paddingRight: '18px' }}>
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    paddingBottom: '4px',
                  }}>
                  {p.k}
                  {p.v !== 'Y' && p.v.trim() !== '' ? (
                    <span style={{ color: 'red' }}>: {p.v}</span>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
      <div></div>
    </div>
  );
};

/*
class Axiom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoked: false,
      checkCount: 0,
    };
    this.maxCheckCount = 5;
  }
  render() {
  return 
    const { classes, axiom } = this.props;
*/
const Axiom = (props) => {
  const {
    classes,
    axiom,
    axiom_select_contract_ac,
    axiom_update_suggestions_ac,
  } = props;

  const fullView = () => {
    props.changePage('/axiom/contracts');
  };

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          className={classes.gridItem}
          style={{ marginBottom: '8px' }}>
          <ContractSearchField
            onSuggestionsReceived={axiom_update_suggestions_ac}
            labe={null}
            placeholder={'Contract Payer'}
            selectContract={(c) => axiom_select_contract_ac(c)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Button
            className={classes.button}
            style={{ marginRight: '8px' }}
            variant="contained"
            small
            onClick={() => axiom_select_contract_ac(null)}>
            Clear
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={fullView}>
            Full View
          </Button>
          <hr />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {axiom.selectedContract && renderMain(axiom.selectedContract.Main)}

          {axiom.selectedContract ? (
            SECTION_SEQUENCE.map((s) =>
              YES_NO_SECTION.includes(s)
                ? renderYesNo(s, axiom.selectedContract[s])
                : renderSection(s, axiom.selectedContract[s])
            )
          ) : (
            <div
              style={{
                fontSize: 11,
                padding: 4,
                marginTop: 8,
                color: 'darkgray',
              }}>
              Selected Payer details from Axiom will dispaly here
            </div>
          )}
        </Grid>

        {axiom.suggestions.length > 0 && (
          <Grid item xs={12} sm={12} md={12}>
            <label style={{ fontSize: 12, marginTop: 8, color: 'darkgray' }}>
              Recent Search Results (click to view details):
            </label>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {axiom.suggestions.map((contract) => (
                <div
                  key={contract._id}
                  style={{ cursor: 'pointer', fontSize: 12, paddingBottom: 8 }}
                  onClick={() => axiom_select_contract_ac(contract)}>
                  {contract._id}
                </div>
              ))}
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  axiom: stateFromStore.axiom,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      axiom_select_contract_ac,
      axiom_update_suggestions_ac,
      changePage: (path) => push(path),
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Axiom));
