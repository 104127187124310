import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const Accordion = (props) => {
  const { arrowStyles = {}, includeHeaderClick = false } = props;
  const [isExpanded, setIsExpanded] = useState(
    props.disabled ? false : props.isExpanded || true
  );
  const [isClickable, setIsClickable] = useState(
    props.isClickable ? true : false
  );

  useEffect(() => {
    setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  useEffect(() => {
    setIsClickable(props.isClickable);
  }, [props.isClickable]);

  const handleToggle = () => {
    if (props.disabled) {
      return;
    }

    if (props.setIsExpanded) {
      props.setIsExpanded(!isExpanded);
    }

    setIsExpanded(!isExpanded);
  };

  if (!props.isLeaf) {
    return (
      <div
        className={classNames(props.classes.expandableNode, props.className, {
          expanded: isExpanded,
        })}>
        <div style={{ cursor: 'pointer', display: 'inline' }}>
          <ArrowRightIcon
            onClick={handleToggle}
            className={props.classes.arrow}
            color={!isExpanded ? 'primary' : 'secondary'}
            style={{
              transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
              ...arrowStyles,
            }}
          />
          <strong
            onClick={(e) =>
              includeHeaderClick && handleToggle ? handleToggle() : undefined
            }>
            {props.header}
          </strong>
        </div>
        {isExpanded && <div>{props.children}</div>}
      </div>
    );
  }

  return (
    <div className={props.classes.leaf}>
      <Typography inline variant="body2" color="inherit">
        <span
          className={isClickable ? props.classes.pointer : ''}
          onClick={(e) =>
            isClickable ? props.onClick(e, props.header) : undefined
          }>
          <strong>
            {props.header} {isClickable}
          </strong>
        </span>
      </Typography>
    </div>
  );
};

export default withStyles(() => ({
  expandableNode: {
    position: 'relative',
    paddingLeft: 24,
    '&.expanded': {
      '&::before': {
        content: '""',
        height: 'calc(100% - 20px)',
        position: 'absolute',
        left: 12,
        top: 20,
        borderLeft: '1px dashed #00000060',
      },
    },
  },
  arrow: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  leaf: {
    padding: 2,
    color: 'grey',
    display: 'inline-block',
  },
  pointer: {
    cursor: 'pointer',
  },
}))(Accordion);
