import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Checkbox from '@material-ui/core/Checkbox'
import Chip from '@material-ui/core/Chip'
import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core/styles'
import T from '@material-ui/core/Typography'
import CreateIcon from '@material-ui/icons/Create'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  updateClaimLineActivity_ac,
  validateClaimLineFix_ac
} from '../../actions/pa_errors_list.ac.js'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: 'lightblue', //theme.palette.background.paper,
    overflowX: 'auto'
  },
  name: {
    color: 'gray',
    fontSize: '.90em'
  },
  claimId: {
    textDecoration: 'italic',
    color: 'red'
  },
  chipLabel: {
    paddingLeft: 4,
    paddingRight: 6
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3
  },
  activityChip: {
    backgroundColor: '#96b1a7',
    fontWeight: 400,
    color: '#000'
  },

  balance: {
    paddingLeft: 12,
    fontWeight: 800,
    top: '38px' //bottom aligned with  account #
  },
  pointer: {
    cursor: 'pointer'
  },
  card: {
    minWidth: 275,
    backgroundColor: '#eff7f5', //theme.palette.background.paper,
    marginBottom: 32,
    marginTop: 2
  },
  cardMarked: {
    minWidth: 275,
    backgroundColor: '#fcffd6',
    marginBottom: 32,
    marginTop: 2
  },
  cardHdrRoot: {
    paddingBottom: 8,
    backgroundColor: '#e0f7fa'
  },
  cardHdrRootMarked: {
    paddingBottom: 8,
    backgroundColor: '#4bff00'
  },
  cardHdrSubHdr: {
    fontSize: '.92em',
    color: 'blue'
  },
  cardHdrContent: {
    fontSize: '.96em',
    fontWeight: 800
  },
  hcpcBal: {
    color: '#1976d2'
  },
  prodDesc: {
    color: '#42a5f5'
  },
  qty: {
    paddingLeft: 12
  },
  paErrorMsg: {
    color: 'red',
    fontWeight: 600
  },
  actions: {
    display: 'flex'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  formControl: {
    margin: theme.spacing.unit * 2
  }
})

class PaErrorCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      lastAction: null,
      actionOpts: [
        {
          code: 'a',
          lbl: 'Attached correct Auth shell to claim',
          checked: false
        },
        { code: 'b', lbl: 'Added HCPC to Auth Shell', checked: false },
        {
          code: 'c',
          lbl: 'Auth File updated with correct span date',
          checked: false
        },
        {
          code: 'd',
          lbl: 'Corrected payer information, attached correct shell',
          checked: false
        }
      ]
    }
    this.handleActivityMarkAction = this.handleActivityMarkAction.bind(this)
    this.handleFormExpandClick = this.handleFormExpandClick.bind(this)
    this.handleValidate = this.handleValidate.bind(this)
  }

  componentDidMount() {
    if (this.props.paErrorsList.records.length === 0) return

    const c = this.props.paErrorsList.records[this.props.posInSet]
    const pae = _.get(c, 'ua.pa_correction_' + c['LineIdx'], false)
    if (!pae) {
      return
    }

    let opts = [...this.state.actionOpts]
    opts.map(o => {
      o.checked = pae.checks.indexOf(o.code) > -1
      return o
    })
    this.setState({
      actionOpts: opts,
      lastAction: {
        u: pae.u,
        ts: pae.ts
      }
    })
  }

  componentDidUpdate() {
    if (this.props.paErrorsList.records.length === 0) return

    const c = this.props.paErrorsList.records[this.props.posInSet]
    const pae = _.get(c, 'ua.pa_correction_' + c['LineIdx'], false)
    if (!pae) {
      return
    }
    return
  }

  handleFormExpandClick() {
    this.setState({ formExpanded: !this.state.formExpanded })
    return
  }

  handleValidate() {
    if (this.props.paErrorsList.isValidating) return
    const c = this.props.paErrorsList.records[this.props.posInSet]
    this.props.validateClaimLineFix_ac(
      parseInt(c['Claim ID'], 10),
      parseInt(c['LineIdx'], 10),
      this.props.posInSet
    )
  }

  handleActivityMarkAction = pos => event => {
    if (this.props.paErrorsList.activityUpdating) {
      return alert('Activity in progress')
    }

    if (this.props.paErrorsList.isValidating) {
      return alert('Validation in progress')
    }

    let opts = [...this.state.actionOpts]
    opts[pos].checked = event.target.checked
    this.setState({
      actionOpts: opts,
      lastAction: {
        u: this.props.me.user.mail,
        ts: new Date()
      }
    })

    const c = this.props.paErrorsList.records[this.props.posInSet]

    this.props.updateClaimLineActivity_ac(
      parseInt(c['Claim ID'], 10),
      parseInt(c['LineIdx'], 10),
      this.props.posInSet,
      'pa_correction',
      { checks: opts.filter(o => o.checked).map(o => o.code) }
    )
  }

  render() {
    const { classes, paErrorsList } = this.props
    if (paErrorsList.records.length === 0) return '<>Empty</>'
    const c = this.props.paErrorsList.records[this.props.posInSet]
    if (c === undefined) {
    }

    const cardClass = {
      root: classes.card
    }
    const cardHdrClasses = {
      root: classes.cardHdrRoot,
      title: classes.cardHdrContent,
      subheader: classes.cardHdrSubHdr
    }
    if (this.state.actionOpts.filter(a => a.checked).length) {
      cardClass['root'] = classes.cardMarked
      cardHdrClasses['root'] = classes.cardHdrRootMarked
    }

    const portal_check = !c['VALID ON PORTAL'] ? (
      ''
    ) : (
      <T className={classes.paErrorMsg} variant="body2">
        Valid on Portal: {c['VALID ON PORTAL']}
      </T>
    )
    const extras = !c['WARNINGS'] ? (
      ''
    ) : (
      <T className={classes.paErrorMsg} variant="body2">
        {c['WARNINGS']}
      </T>
    )

    let chips = this.state.actionOpts
      .filter(ao => ao.checked)
      .map(ao => (
        <Chip
          key={'k' + ao.code}
          className={classNames(classes.chip, classes.activityChip)}
          label={ao.lbl}
        />
      ))

    const store_pae = _.get(c, 'ua.pa_correction_' + c['LineIdx'], false)
    let activityLabel = `MARK ACTIVITY`
    if (store_pae) {
      activityLabel = `Last worked by ${store_pae.u} on
      ${moment(store_pae.ts).format('M-D-YY h:mma')}`
      if (this.state.lastAction) {
        if (this.state.lastAction.ts > store_pae.ts) {
          activityLabel = `Last worked by ${this.state.lastAction.u} on
          ${moment(this.state.lastAction.ts).format('M-D-YY h:mma')}`
        }
      }
    }
    if (this.state.lastAction) {
      activityLabel = `Last worked by ${this.state.lastAction.u} on
      ${moment(this.state.lastAction.ts).format('M-D-YY h:mma')}`
    }

    return (
      <Card classes={cardClass}>
        {c['PA Errors Resolved'] === true ? (
          <CardHeader
            title={
              'PA ERRORS RESOLVED - ' +
              c['LastName'] +
              ', ' +
              c['FirstName'] +
              ' ' +
              c['DOB']
            }
            subheader={
              'Claim ' +
              c['Claim ID'] +
              ' - A' +
              c['Account'] +
              ' - DOS:' +
              c['Billing Date']
            }
            classes={cardHdrClasses}
          />
        ) : (
          <CardHeader
            title={
              c['LastName'] +
              ', ' +
              c['FirstName'] +
              ' ' +
              c['DOB'] +
              ' - Record# ' +
              c['rec_idx']
            }
            subheader={
              'Claim ' +
              c['Claim ID'] +
              ' - A' +
              c['Account'] +
              ' - DOS:' +
              c['Billing Date']
            }
            classes={cardHdrClasses}
          />
        )}

        <CardContent>
          <div className={classes.hcpcBal}>
            {c['HCPC']}
            <span className={classes.qty}> QTY {c['QTY']}</span>
            <span className={classes.qty}> Bal </span>
            <span className={classes.balance}>${c.Balance.toFixed(2)} </span>
            <span className={classes.qty}>Line {c.LineIdx + 1}</span>
          </div>
          <T className={classes.prodDesc} variant="body1" component="h5">
            {c['Product']}
          </T>
          <T variant="body2">Claim Payer: {c['ClaimPayer']}</T>
          <T variant="body2">PA Payer: {c['PA Payer']}</T>
          <T variant="body2">
            PA: {c['PAName']} - {c['PANumber']}
          </T>
          {/*
          <T variant="body2">
            Attached On: {c["Attached on"]}
          </T>
          */}

          <T variant="body2">
            PCR: {c['PCR']} - W{c['WorkOrder#']}
          </T>
          <T variant="body2">Became Billable: {c['Became Billable']}</T>

          <T className={classes.paErrorMsg} variant="body2">
            {c['PA Errors']}
          </T>
          {portal_check}
          {extras}
          {chips}
          <hr />
          <T variant="body2">
            Last Synch: {moment(c['LastSync']).format('MM-DD-YYYY hh:mma')}
          </T>

          {c['SyncMsg'] && (
            <T className={classes.paErrorMsg} variant="body2">
              {c['SyncMsg']}
            </T>
          )}
        </CardContent>

        <Divider component="hr" />
        <CardActions className={classes.actions} disableActionSpacing>
          <Button
            size="small"
            color="primary"
            onClick={this.handleFormExpandClick}>
            {activityLabel}
          </Button>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: this.state.formExpanded
            })}
            onClick={this.handleFormExpandClick}
            aria-expanded={this.state.formExpanded}
            aria-label="Show Activity Form">
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.formExpanded} timeout="auto" unmountOnExit>
          <CardActions>
            <FormControl
              required
              component="fieldset"
              className={classes.formControl}>
              {/*<FormLabel component="legend">Check all applicable</FormLabel> */}
              <FormHelperText>Check all applicable</FormHelperText>
              <FormGroup>
                {this.state.actionOpts.map((a, pos) => (
                  <FormControlLabel
                    key={a.code}
                    control={
                      <Checkbox
                        checked={a.checked}
                        onChange={this.handleActivityMarkAction(pos)}
                        value={a.code}
                      />
                    }
                    label={a.lbl}
                  />
                ))}
              </FormGroup>
              <FormGroup>
                <Button
                  onClick={this.handleValidate}
                  variant="contained"
                  color="primary"
                  className={classes.button}>
                  Validate Correction
                  <CreateIcon className={classes.rightIcon}>send</CreateIcon>
                </Button>
              </FormGroup>
            </FormControl>
          </CardActions>
        </Collapse>
      </Card>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  paErrorsList: stateFromStore.paErrorsList
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateClaimLineActivity_ac,
      validateClaimLineFix_ac
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaErrorCard))
