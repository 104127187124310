import { AllScriptsReportActionTypes } from '../actions/all_scripts_report.ac';
const defaultState = {
    isFetching: false,
    records: null,
    pagination: null,
    error: null,
    referral: null,
    referralError: null,
    isFetchingReferralDetails: false,
};
export const allScriptsReportInView = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case AllScriptsReportActionTypes.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };
        case AllScriptsReportActionTypes.RECEIVED_ALL_SCRIPTS_REPORTS:
            return {
                ...state,
                pagination: action.pagination,
                records: action.records,
                error: null
            };

        case AllScriptsReportActionTypes.FAILED_RECEIVING_SCRIPTS_REPORTS:
            return {
                ...state,
                pagination: null,
                records: null,
                error: action.error,
            };
        case AllScriptsReportActionTypes.SET_IS_REFERRAL_DETAILS_FETCHING:
            return {
                ...state,
                isFetchingReferralDetails: action.isFetchingReferralDetails,
            };
        case AllScriptsReportActionTypes.RECEIVED_ALL_SCRIPTS_REFERRAL:
            return {
                ...state,
                referral: action.referral,
                referralError: null
            };

        case AllScriptsReportActionTypes.FAILED_RECEIVING_ALL_SCRIPTS_REFERRAL:
            return {
                ...state,
                referral: null,
                referralError: action.referralError,
            };
        case AllScriptsReportActionTypes.UPDATE_REFERRAL_FILES:
            const { records } = state;
            records
                .find(item => item.id === action.referralId)
                .files.forEach(file => {
                    if (~(action.s3pathAndKeys.indexOf(file.s3pathAndKey))) {
                        file.downloads = {
                            count: 1,
                            lastTs: Date.now()
                        }
                    }
                });

            return {
                ...state,
                records: records
            };
        default:
            return state;
    }
};
