import _ from 'lodash'

export const MednecIssueLogActionTypes = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  SET_IS_SAVING: 'SET_IS_SAVING',
  RECEIVED_MEDNEC_ISSUE_CODES: 'RECEIVED_MEDNEC_ISSUE_CODES',
  FAILED_RECEIVING_MEDNEC_ISSUE_CODES: 'FAILED_RECEIVING_MEDNEC_ISSUE_CODES',

  RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES:
    'RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES',
  FAILED_RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES:
    'FAILED_RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES'
}

export const fetchMednecIssueLogsList_ac = (params = {}) => dispatch => {
  dispatch(setIsFetching(true))
  return window.sch
    .post('/api/mednec_issues/fetch', params)
    .then(response => {
      dispatch({
        type: MednecIssueLogActionTypes.RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES,
        records: response.records,
        pagination: response.pagination
      })
    })
    .catch(err => {
      dispatch({
        type:
          MednecIssueLogActionTypes.FAILED_RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES,
        error: err
      })
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

export const getIssueCodes_ac = (params = {}) => dispatch => {
  dispatch(setIsFetching(true))

  return window.sch
    .post('/api/mednec_issues/get_issue_codes', params)
    .then(response => {
      dispatch({
        type: MednecIssueLogActionTypes.RECEIVED_MEDNEC_ISSUE_CODES,
        issues: response.issues
      })
    })
    .catch(err => {
      dispatch({
        type: MednecIssueLogActionTypes.FAILED_RECEIVING_MEDNEC_ISSUE_CODES,
        error: true
      })
    })
    .finally(() => {
      dispatch(setIsFetching(false))
    })
}

export const saveMednecIssue_ac = (params, fromIdnWoEntry) => (
  dispatch,
  getState
) => {
  dispatch(setIsSaving(true))
  let user = { ...getState().me.user }
  const { wo_id } = params

  return window.sch
    .post('/api/mednec_issue_log/save', params)
    .then(response => {
      dispatch({
        type: 'OPEN_SNACK',
        message: 'Saved Mednec Issue Successfully!',
        variant: 'success'
      })

      if (fromIdnWoEntry) {
        const idnWos = getState().idnWos
        let pkg = _.cloneDeep(idnWos.pkg)
        pkg.wos = pkg.wos.map(w => {
          if (w.hdms_wId !== wo_id) return w
          let entry = {
            comment: params.comment,
            issue_lbls: (params.issues || []).map(iss => iss.lbl),
            issue_ids: (params.issues || []).map(iss => iss._id),
            u_nm: user.nm,
            ts: Date.now()
          }
          if (!w.mednec) {
            w.mednec = { mednec_issue: { ...entry }, mednec_issue_log: [] }
          }

          w.mednec.mednec_issue_log.push(entry)
          return w
        })

        return dispatch({
          type: 'UPDATE_IDN_WOS_PKG',
          pkg: pkg
        })
      }

      //action was done from mednec issue landing
      let { records, pagination } = getState().mednecIssueLog
      records = records.map(r => {
        if (r._id !== wo_id) return r
        let entry = {
          comment: params.comment,
          issue_lbls: (params.issues || []).map(iss => iss.lbl),
          issue_ids: (params.issues || []).map(iss => iss._id),
          u_nm: user.nm,
          ts: Date.now()
        }

        if (!r.mednec_issue) {
          r.mednec_issue_log = []
        }
        r.mednec_issue = { ...entry }
        r.mednec_issue_log.push(entry)
        return r
      })

      return dispatch({
        type: 'RECEIVED_COHORT_WOS_WITH_MEDNEC_ISSUES',
        records,
        pagination
      })
    })
    .catch(err => {
      console.error(err)
      dispatch({
        type: 'OPEN_SNACK',
        message: 'Failed to save Mednec Issue!',
        variant: 'error'
      })
    })
    .finally(() => {
      dispatch(setIsSaving(false))
    })
}

function setIsFetching(isFetching) {
  return {
    type: MednecIssueLogActionTypes.SET_IS_FETCHING,
    isFetching
  }
}

function setIsSaving(isSaving) {
  return {
    type: MednecIssueLogActionTypes.SET_IS_SAVING,
    isSaving
  }
}
