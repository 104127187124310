import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { push } from 'connected-react-router'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchDCs_ac } from '../../actions/dcs.ac.js'
import { close_snack_ac, open_snack_ac } from '../../actions/snack.ac.js'
import FetchingStatementWrap from '../FetchingStatementWrap'

const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {
    backgroundColor: 'red',
    color: '#fff'
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  formControl: {
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row'
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200
  },
  title: {
    flexGrow: 1
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  },
  padding4: {
    padding: 4
  }
})
/*
const TF = {
  sched_dt_str: 'Schedule Date',
  patient_nm: 'Patient Name',
  addr_1: 'Address Line 1',
  //"addr_2":"Address Line 2",
  city: 'City',
  state: 'State',
  zip: 'ZIP',
  phone: 'PHONE',
  deliv_note: 'Instructions'
}
*/

const DEFAULT_VALUES = {
  dc_id: -1,
  driver_id: -1,
  patient_nm: '',
  sched_dt_str: moment().format('MM-DD-YYYY'),
  cats: '',
  method_id: 1,
  deliv_note: '',
  ticket_type: 'nonbill',
  addr_1: '',
  addr_2: '',
  city: '',
  state: '',
  zip: '',
  phone: '',
  is_not_encrypted: true
}

class DevOnly extends React.Component {
  constructor(props) {
    super(props)
    this.validate = this.validate.bind(this)
    this.state = {
      ticket_number: null,
      ticket: { ...DEFAULT_VALUES },
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null,
      valid: false,
      isSubmitting: false
    }
    this.onTest = this.onTest.bind(this)
    this.reset = this.reset.bind(this)
  }

  reset() {
    this.setState({
      ticket_number: null,
      ticket: { ...DEFAULT_VALUES },
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null,
      valid: false,
      isSubmitting: false
    })
  }

  validate() {
    let invalid_fields = _.keys(this.state.ticket)
      .filter(f => f !== 'addr_2')
      .filter(f => {
        return this.state.ticket[f] === -1 || this.state.ticket[f] === ''
      })

    return invalid_fields.length === 0
  }

  onChange = field => e => {
    this.setState({
      ticket: {
        ...this.state.ticket,
        [field]: e.target.value
      },
      valid: this.validate()
    })
  }

  onTest() {
    let params = {
      code: 'cr',
      txt: 'test',
      hdms_note_category: null,
      extra_fields: {
        sub_category: 'VENT-VEST PICK UP',
        issues: [],
        claim_line_ids: [1, 2, 3]
      }
    }
    return window.sch
      .post('/api/todos/check_assignment', params)
      .then(res => {
        console.log(res)
      })
      .catch(() => {
        this.props.open_snack_ac({
          variant: 'error',
          message: 'Could not establish DelieryTrack session - contact IT'
        })
      })
  }

  componentDidMount() {
    this.checkSituation()
  }
  componentDidUpdate() {
    this.checkSituation()
  }
  checkSituation() {
    const { dcs } = this.props
    if (dcs.isFetching) return
    if (dcs.error) return
    if (dcs.pkg === null) {
      this.props.fetchDCs_ac()
    }
  }

  render() {
    const { classes, dcs } = this.props
    if (dcs.error) {
      return (
        <FetchingStatementWrap
          title={'dcs Listing'}
          msg={'Something went wrong. Contact IT'}
        />
      )
    }

    if (!dcs.pkg || dcs.isFetching) {
      return (
        <FetchingStatementWrap
          title="DCs Listing"
          msg={'This should not take more than 10 seconds'}
        />
      )
    }

    //const { pkg } = dcs

    return (
      <main className={classes.content}>
        <div className={[classes.scrollArea, classes.padding4].join(' ')}>
          <Grid container direction="row" spacing={8}>
            <Grid item xs={12} md={4}>
              <div style={{ marginBottom: 24 }}>
                <h5>Dev Testing</h5>
              </div>

              <FormControl
                style={{ marginTop: 24 }}
                required
                component="fieldset"
                className={classes.formControl}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={this.onTest}>
                  Test
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </div>
      </main>
    )
  }
}

DevOnly.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  dcs: stateFromStore.dcs,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDCs_ac,
      open_snack_ac,
      close_snack_ac,
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DevOnly))
)
