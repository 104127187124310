export const IndexingActionTypes = {
  RECEIVED_INDEXING_DETAILS: 'RECEIVED_INDEXING_DETAILS',
  FAILED_RECEIVED_INDEXING_DETAILS: 'FAILED_RECEIVED_INDEXING_DETAILS',
  RESET_INDEXING_DETAILS: 'RESET_INDEXING_DETAILS',
  SET_IS_FETCHING: 'SET_IS_FETCHING',

  TRANSFER_OWNERSHIP_PENDING: 'TRANSFER_OWNERSHIP_PENDING',
  TRANSFER_OWNERSHIP_SUCCESS: 'TRANSFER_OWNERSHIP_SUCCESS',
  TRANSFER_OWNERSHIP_ERROR: 'TRANSFER_OWNERSHIP_ERROR'
}
export function fetchIndexingDetails_ac(params, { onSuccess, onError } = {}) {
  const { do_not_save, kind, tagFilters } = params;
  return dispatch => {
    dispatch(fetching(true))
    return window.sch
      .post('/api/faxes:list', { ...params })
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }
        dispatch({
          type: IndexingActionTypes.RECEIVED_INDEXING_DETAILS,
          records: result.rows,
          tags: result.tagCounts,
          pagination: result.pagination
        })
        if (!do_not_save) {
          dispatch({
            type: "ME_FILTER_UPDATE",
            alias: `idx.${kind}`,
            value: tagFilters
          })
        }
        if (onSuccess) {
          onSuccess(result)
        }
      })
      .catch(error => {
        dispatch({
          type: IndexingActionTypes.FAILED_RECEIVED_INDEXING_DETAILS,
          error
        })
        if (onError) {
          onError(error)
        }
      })
      .finally(() => {
        dispatch(fetching(false))
      })
  }
}

export function transferOwnerShip_ac(params, { onComplete, onError }) {
  return dispatch => {
    dispatch({ type: IndexingActionTypes.TRANSFER_OWNERSHIP_PENDING })
    return window.sch
      .post('/api/fax/transfer_ownership', params)
      .then(result => {
        if (result.error) {
          throw new Error(result.error)
        }
        dispatch({
          type: IndexingActionTypes.TRANSFER_OWNERSHIP_SUCCESS,
          results: result.results
        })

        if (onComplete) onComplete(result.results)
      })
      .catch(error => {
        dispatch({
          type: IndexingActionTypes.TRANSFER_OWNERSHIP_ERROR,
          error
        })

        if (onError) onError(error)
      })
      .finally(() => {
        dispatch(fetching(false))
      })
  }
}

export const fetching = isFetching => ({
  type: IndexingActionTypes.SET_IS_FETCHING,
  isFetching
})

export function resetIndexingDetails_ac() {
  return dispatch => {
    dispatch({
      type: IndexingActionTypes.RESET_INDEXING_DETAILS
    })
  }
}
