import {
  Button,
  Chip,
  Collapse,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchParachuteReport_ac } from '../../actions/parachute_report.ac.js';
import dateUtil from '../../util/dateProcessing';
import FilterListing from '../FilterListing';
import { PaginationWithNums } from '../PaginationNew';
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent';
import Loader from '../Shared/Loader';
import OrderSearchField from './OrderSearchField';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  pageCount: {
    color: '#4a148c',
    paddingLeft: 5,
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  title: {
    flexGrow: 1,
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    marginTop: 0,
    padding: 4,
    borderBottom: '2px solid #00000010',
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12,
  },
  chip: {
    height: 26,
    borderRadius: 8,
    margin: '4px 4px 4px 0px',
    padding: 3,
  },
  noBorderBottom: {
    borderBottom: 'unset',
  },
  collapsableRowContent: {
    backgroundColor: 'rgb(0, 0, 0, 0.04)',
    boxShadow: '0px 0px 5px grey inset',
  },
  collapsableCellSpacing: {
    padding: '4px',
    margin: '4px 0px',
  },
  collapsableCellScroll: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  paddingLeft16: {
    paddingLeft: '16px',
  },
  itemBlock: {
    padding: '4px',
    marginBottom: '8px',
    fontSize: '10px',
    backgroundColor: `${theme.palette.secondary.main}10`,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#0000000a',
      cursor: 'pointer',
    },
    '&.selected-row': {
      backgroundColor: '#b2dfdb7f',
    },
  },
});

const DEFAULT_FILTERS = {
  hasDocumentsForImport: {
    kind: 'checkbox',
    lbl: 'Has documents for import',
    options: [{ code: 'yes', lbl: 'Yes', checked: false }],
  },
  start: {
    kind: 'dateTime',
    lbl: 'From',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false,
  },
  end: {
    kind: 'dateTime',
    lbl: 'To',
    placeholder: 'MM-DD-YYYY hh::mm a',
    value: '',
    dirty: false,
    error: false,
  },
};

const ParachuteRow = (props) => {
  const { item, isSelected, isImporting, classes } = props;
  const [isCollapseOpen, setOpenState] = useState(false);

  const already_imported = item.order_info.order.documents.filter(
    (d) => d.state === 'done'
  ).length;

  return (
    <React.Fragment>
      <TableRow
        className={classNames(classes.tableRow, {
          'selected-row': isSelected,
        })}
        onClick={() => setOpenState(!isCollapseOpen)}
        style={{ padding: 4 }}>
        <TableCell
          align="left"
          width="15%"
          variant="body"
          className={classes.noBorderBottom}
          style={{ padding: 8 }}>
          <Typography variant="subtitle2" color="secondary">
            <Typography
              variant="inherit"
              color="textPrimary"
              component="span"
              inline>
              #{props.recordNumber})
            </Typography>{' '}
            {item.order_info.order_id}
          </Typography>
          <Typography variant="caption">
            {dateUtil.formatDate(item.created_ts)}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          width="27%"
          variant="body"
          onClick={() => setOpenState(!isCollapseOpen)}
          className={classes.noBorderBottom}
          style={{ padding: 8 }}>
          <Typography variant="subtitle2">
            <strong>
              {`${item.order_info.patient.first_name} ${item.order_info.patient.last_name}`.toUpperCase()}{' '}
              {item.order_info.patient.birth_date}
            </strong>
          </Typography>

          <Typography variant="subtitle2" style={{ fontSize: 11 }}>
            View Line Items
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          width="30%"
          variant="body"
          className={classes.noBorderBottom}
          style={{ padding: 8 }}>
          {_.isArray(item.events) && item.events.length ? (
            <Typography variant="subtitle2" color="secondary" inline>
              {item.events[item.events.length - 1].event_type
                .replace(/_/g, ' ')
                .toUpperCase()}
            </Typography>
          ) : (
            'N/A'
          )}
          {_.isArray(item.events) && item.events.length > 1 && (
            <Typography variant="caption" color="textSecondary" inline>
              {` and ${item.events.length - 1} more`}
            </Typography>
          )}
        </TableCell>
        <TableCell
          align="left"
          width="28%"
          variant="body"
          className={classes.noBorderBottom}
          style={{ padding: 8 }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenState(!isCollapseOpen)}>
            {item.order_info.order.documents.length ? (
              <Typography variant="subtitle2" color="secondary" inline>
                {item.order_info.order.documents
                  .slice(0, 3)
                  .map((d) => d.type)
                  .join(', ')
                  .replace(/_/g, ' ')
                  .toUpperCase()}
              </Typography>
            ) : (
              'N/A'
            )}
            {item.order_info.order.documents.length > 3 && (
              <Typography variant="caption" color="textSecondary" inline>
                {` and ${item.order_info.order.documents.length - 3} more`}
              </Typography>
            )}
            {already_imported > 0 && (
              <Typography variant="caption" color="textSecondary" inline>
                {' '}
                - {already_imported} already imported
              </Typography>
            )}
          </div>
          <div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => props.onImportClick(item)}
              disabled={isSelected || isImporting}>
              Import Docs
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <TableRow style={{ height: '0px' }}>
        <TableCell
          colSpan={6}
          width="100%"
          className={classes.collapsableRowContent}
          style={{ padding: '0px' }}>
          <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
            <Table padding="dense">
              <TableBody>
                <TableRow style={{ verticalAlign: 'top' }}>
                  <TableCell
                    align="left"
                    width="15%"
                    variant="body"
                    style={{ padding: 8 }}>
                    <div className={classes.collapsableCellScroll}>
                      <Typography variant="subtitle2" color="primary">
                        Created
                      </Typography>
                      <Typography gutterBottom>
                        {dateUtil.relativeTime(item.created_ts)}
                      </Typography>
                      <Typography variant="subtitle2" color="primary">
                        Last Updated
                      </Typography>
                      <Typography gutterBottom>
                        {item.last_ts
                          ? dateUtil.relativeTime(item.last_ts)
                          : 'Not updated.'}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    width="27%"
                    variant="body"
                    style={{ padding: 8 }}>
                    <div className={classes.collapsableCellScroll}>
                      <div className={classes.collapsableCellSpacing}>
                        <Typography variant="subtitle2" color="primary">
                          Insurance Coverages
                        </Typography>
                        {item.order_info.patient.insurance_coverages &&
                        item.order_info.patient.insurance_coverages.length ? (
                          item.order_info.patient.insurance_coverages.map(
                            (insurance, idx) => (
                              <div key={idx}>- {insurance.insurance_name}</div>
                            )
                          )
                        ) : (
                          <Typography color="textSecondary">
                            Not provided.
                          </Typography>
                        )}
                      </div>
                      <div className={classes.collapsableCellSpacing}>
                        <Typography variant="subtitle2" color="primary">
                          Facility
                        </Typography>
                        <Typography color="textPrimary">
                          {item.order_info.order.facility.name}
                        </Typography>
                      </div>
                      <div className={classes.collapsableCellSpacing}>
                        <Typography variant="subtitle2" color="primary">
                          Supplier
                        </Typography>
                        <Typography color="textPrimary">
                          {item.order_info.order.supplier.name.replace(
                            item.order_info.order.supplier.name.split(' ')[0],
                            item.order_info.order.supplier.name
                              .split(' ')[0]
                              .toUpperCase()
                          )}
                        </Typography>
                      </div>
                      <div className={classes.collapsableCellSpacing}>
                        <Typography variant="subtitle2" color="primary">
                          Line Items
                        </Typography>
                        {item.order_info.fulfillments &&
                          item.order_info.fulfillments.length &&
                          item.order_info.fulfillments.map(
                            (fulfillment, ffIdx) =>
                              fulfillment.items.length ? (
                                <div key={ffIdx} className={classes.itemBlock}>
                                  {fulfillment.items.map((ffItem, itemIdx) => (
                                    <div
                                      key={itemIdx}
                                      style={{
                                        borderBottom: '2px solid #000',
                                        paddingBottom: 6,
                                      }}>
                                      <Typography
                                        variant="inherit"
                                        gutterBottom>
                                        <strong>{ffItem.quantity}&ensp;</strong>
                                        <strong
                                          style={{
                                            backgroundColor: '#b7e8b4',
                                          }}>
                                          {ffItem.description}&ensp;
                                          {ffItem.hcpcs
                                            ? `[${ffItem.hcpcs}] `
                                            : ''}
                                        </strong>
                                        - needed for&ensp;
                                        <strong>
                                          {ffItem.length_of_need_months}{' '}
                                          days,&ensp;
                                        </strong>
                                        paid&ensp;
                                        <strong>
                                          {ffItem.private_pay
                                            ? 'PRIVATELY '
                                            : ''}
                                        </strong>
                                        via&ensp;
                                        <strong
                                          style={{
                                            textTransform: 'uppercase',
                                          }}>
                                          {ffItem.payment_method}
                                        </strong>
                                        .
                                      </Typography>
                                      <span
                                        style={{
                                          fontSize: 'inherit',
                                          color: 'purple',
                                        }}>
                                        {ffItem.product_category_name.toUpperCase()}
                                        : {ffItem.supplier_system_id}
                                      </span>
                                      {ffItem.attributes.map((attribute) => (
                                        <Typography
                                          key={attribute.name}
                                          variant="inherit"
                                          gutterBottom>
                                          <strong>{attribute.name}:</strong>
                                          &ensp;
                                          {attribute.value}
                                        </Typography>
                                      ))}
                                    </div>
                                  ))}
                                  <Typography
                                    variant="inherit"
                                    color="textSecondary">
                                    Delivery exptected on&ensp;
                                    {moment(
                                      fulfillment.delivery_date_expected || {}
                                    ).format('ddd MMMM Do YYYY')}
                                  </Typography>
                                </div>
                              ) : (
                                <div key={ffIdx} className={classes.itemBlock}>
                                  <Typography
                                    variant="inherit"
                                    color="textSecondary">
                                    No Items.
                                  </Typography>
                                </div>
                              )
                          )}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    width="30%"
                    variant="body"
                    style={{ padding: 8 }}>
                    <div className={classes.collapsableCellScroll}>
                      {_.isArray(item.events) && item.events.length ? (
                        item.events
                          .slice(0)
                          .reverse()
                          .map((event, idx) => (
                            <div
                              key={idx}
                              className={classes.collapsableCellSpacing}>
                              <Typography color="secondary" gutterBottom>
                                {event.event_type
                                  .replace(/_/g, ' ')
                                  .toUpperCase()}
                              </Typography>
                              {(event.comment && (
                                <div>
                                  <Typography color="primary">
                                    <strong>
                                      {`${event.comment.first_name} ${event.comment.last_name}`}
                                    </strong>
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    display="block"
                                    color="textSecondary"
                                    gutterBottom
                                    className={classes.paddingLeft16}>
                                    {dateUtil.relativeTime(
                                      event.event_datetime
                                    )}
                                  </Typography>
                                  <Typography className={classes.paddingLeft16}>
                                    {event.comment.content}
                                  </Typography>
                                </div>
                              )) ||
                                (event.document && (
                                  <div>
                                    {event.document.type && (
                                      <div component="div">
                                        <Typography color="primary" inline>
                                          Type:
                                        </Typography>
                                        <Typography inline>
                                          {` ${event.document.type
                                            .replace(/_/g, ' ')
                                            .toUpperCase()}`}
                                        </Typography>
                                      </div>
                                    )}
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                      className={classes.paddingLeft16}>
                                      {dateUtil.relativeTime(
                                        event.document.created_at
                                      )}
                                    </Typography>
                                  </div>
                                )) || (
                                  <Typography
                                    variant="caption"
                                    color="textSecondary">
                                    {dateUtil.relativeTime(
                                      event.event_datetime
                                    )}
                                  </Typography>
                                )}
                            </div>
                          ))
                      ) : (
                        <div className={classes.collapsableCellSpacing}>
                          <Typography color="textSecondary">
                            No events.
                          </Typography>
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    width="28%"
                    variant="body"
                    style={{ padding: 8 }}>
                    <div className={classes.collapsableCellScroll}>
                      {item.order_info.order.documents &&
                      item.order_info.order.documents.length ? (
                        <div>
                          {item.order_info.order.documents
                            .slice(0)
                            .reverse()
                            .map((document, idx) => (
                              <div
                                key={idx}
                                className={classes.collapsableCellSpacing}>
                                <Typography
                                  variant="subtitle1"
                                  color="secondary"
                                  gutterBottom>
                                  {document.type
                                    .replace(/_/g, ' ')
                                    .toUpperCase()}{' '}
                                  {document.state === 'done' &&
                                    '- Imported to DF ' +
                                      moment(document.ts).format('M-D hh:mma')}
                                </Typography>
                                {document.category && (
                                  <>
                                    <Tooltip
                                      title={`Document category: ${document.category.toUpperCase()}`}>
                                      <Chip
                                        color="primary"
                                        label={document.category.toUpperCase()}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                                <Typography
                                  variant="caption"
                                  color="textSecondary">
                                  {dateUtil.relativeTime(document.created_at)}
                                </Typography>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div className={classes.collapsableCellSpacing}>
                          <Typography color="textSecondary">
                            No documents.
                          </Typography>
                        </div>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

class ParachuteReportNewComponent extends BaseLandingTemplate {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        ...DEFAULT_FILTERS,
        start: {
          ...DEFAULT_FILTERS.start,
          value: `${moment()
            .subtract(14, 'days')
            .format('MM-DD-YYYY')} 00:00 am`,
        },
        end: {
          ...DEFAULT_FILTERS.end,
          value: moment().format('MM-DD-YYYY hh:mm a'),
        },
      },
      searchStr: '',
      filterSequence: ['hasDocumentsForImport', 'start', 'end'],
    };
    this.applyFilters = this.applyFilters.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterClearField = this.onFilterClearField.bind(this);
  }

  onPageChange = (newPage) => {
    const page = parseInt(newPage, 10);

    this.props.changePage(page);
  };

  componentDidMount() {
    return this.fetch(this.props.currentPage);
  }

  componentDidUpdate(prevProps) {
    const { parachuteReportInView, currentPage } = this.props;

    if (
      parachuteReportInView.isFetching === false &&
      !parachuteReportInView.error &&
      parachuteReportInView.records === null
    ) {
      return this.fetch();
    }

    if (currentPage !== prevProps.currentPage) {
      return this.fetch(currentPage);
    }
  }

  fetch(page = 1) {
    const { filters, searchStr } = this.state;

    let params = {
      page: page - 1,
    };

    if (filters.hasDocumentsForImport.options[0].checked) {
      params.hasDocumentsForImport = true;
    }

    if (searchStr) {
      params.searchStr = searchStr;
    }

    if (filters.start.value && dateUtil.isValidDateTime(filters.start.value)) {
      params.start = moment(filters.start.value).utc().format();
    }

    if (filters.end.value && dateUtil.isValidDateTime(filters.end.value)) {
      params.end = moment(filters.end.value).utc().format();
    }

    this.props.fetchParachuteReport_ac(params);
    this.props.cancelImport();
  }

  onFilterChange = (filterName) => (event) => {
    let changeObject = {};
    const { value } = event.target;

    switch (this.state.filters[filterName].kind) {
      case 'checkbox':
        const { checked } = event.target;
        changeObject = {
          options: this.state.filters[filterName].options.map((opt) => ({
            ...opt,
            checked: value === opt.code ? checked : opt.checked,
          })),
        };
        break;

      case 'dateTime':
        changeObject = {
          value: value,
          dirty: value ? true : false,
          error: value ? !dateUtil.isValidDateTime(value) : false,
        };
        break;

      default:
        changeObject = {
          value: value,
        };
    }

    return this.setState((state) => ({
      filters: {
        ...state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          ...changeObject,
        },
      },
    }));
  };

  onFilterClearField = (filterName) => () => {
    let changeObject = {};

    switch (this.state.filters[filterName].kind) {
      case 'dateTime':
        changeObject = {
          value: '',
          dirty: false,
          error: false,
        };
        break;

      default:
        changeObject = {
          value: '',
        };
    }

    return this.setState((state) => ({
      filters: {
        ...state.filters,
        [filterName]: {
          ...this.state.filters[filterName],
          ...changeObject,
        },
      },
    }));
  };

  submitSearchStr = (searchStr) => {
    if (!this.state.searchStr && !searchStr) return;

    this.setState(
      {
        searchStr: searchStr,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    if (this.props.currentPage === 1) {
      return this.fetch();
    }

    this.props.changePage(1);
  };

  render() {
    const { me, parachuteReportInView, classes, currentPage } = this.props;

    if (parachuteReportInView.isFetching) {
      return (
        <main className={classes.content}>
          <Loader message="...loading records" />
        </main>
      );
    }

    let filterListing = (
      <FilterListing
        me={me}
        filters={this.state.filters}
        filterSequence={this.state.filterSequence}
        applyFilters={this.applyFilters}
        onFilterChange={this.onFilterChange}
        onFilterClearField={this.onFilterClearField}
      />
    );

    if (parachuteReportInView.error) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>....error fetching Records</h4>
        </div>
      );
    }

    if (parachuteReportInView.records === null) {
      return (
        <div className={classes.root}>
          {filterListing}
          <h4 className={classes.details}>...</h4>
        </div>
      );
    }

    const { pagination: pg } = parachuteReportInView;

    let pageSelector = '';
    let pageStatement = '';
    if (pg) {
      pageStatement = [
        `Displaying 
        ${pg.start + 1} - ${pg.end} of ${pg.totalRecords} Total`,
        `Page ${currentPage} of ${pg.totalPagesAvailable}`,
      ].join('-');

      if (pg.totalPagesAvailable > 1) {
        pageSelector = (
          <PaginationWithNums
            currentPageNum={pg.currentPageNum}
            totalPagesAvailable={pg.totalPagesAvailable}
            onPageChange={this.onPageChange}
            disableAll={this.props.isImporting}
          />
        );
      }
    }

    return (
      <>
        {!this.props.isImporting && filterListing}
        <main className={classes.content}>
          <div className={classes.pagination}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <OrderSearchField
                  searchStr={this.state.searchStr}
                  submitSearchStr={this.submitSearchStr}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography
                  className={classes.pageStatement}
                  color="textSecondary">
                  {pageStatement}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                {pageSelector}
              </Grid>
            </Grid>
          </div>

          <div className={classes.scrollArea}>
            {!parachuteReportInView.records.length ? (
              <h4 className={classes.details}>No records found.</h4>
            ) : (
              <Table padding="dense">
                <TableHead padding="dense">
                  <TableRow>
                    <TableCell align="left" width="15%" style={{ padding: 8 }}>
                      Order Id
                    </TableCell>
                    <TableCell align="left" width="27%" style={{ padding: 8 }}>
                      Patient
                    </TableCell>
                    <TableCell align="left" width="30%" style={{ padding: 8 }}>
                      Events
                    </TableCell>
                    <TableCell align="left" width="28%" style={{ padding: 8 }}>
                      Documents
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parachuteReportInView.records.map((item, index) => (
                    <ParachuteRow
                      key={index}
                      recordNumber={index + pg.start + 1}
                      item={item}
                      classes={classes}
                      onImportClick={this.props.onImportClick}
                      isSelected={this.props.orderIdOnSelection === item._id}
                      isImporting={this.props.isImporting}
                    />
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </main>
      </>
    );
  }
}

ParachuteReportNewComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  parachuteReportInView: stateFromStore.parachuteReportInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchParachuteReport_ac,
    },
    dispatch
  );

export const ParachuteReportNew = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ParachuteReportNewComponent));
