import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

import Loader from '../Shared/Loader'
import CustomSelect from './CustomSelect'
import CountViewTable from './CountViewTable'
import { fetchSalesDashRecords_ac } from '../../actions/salesdash.ac'

const styles = () => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  },
  scrollableTableContainer: {
    height: 'calc(100% - 182px)',
    minHeight: '200px',
    overflowY: 'auto'
  }
});

class MyHistory extends React.Component {
  constructor() {
    super();

    const currentYear = moment().year();

    this.state = {
      headerList: ['Sales Category',
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      yearOptions: [currentYear, currentYear - 1, currentYear - 2]
        .map(year => (
          { label: `${year}`, value: `${year}` }
        )),
      selection: {
        year: `${currentYear}`,
        type: '0',
        unit: '0'
      }
    };
  }

  componentDidMount() {
    window.prevSalesdashHomeTab = 'my_history';

    return this.fetch(this.state.selection);
  }

  componentDidUpdate(prevProps) {
    window.prevSalesdashHomeTab = 'my_history';

    if (prevProps.region !== this.props.region
      || prevProps.salesPerson !== this.props.salesPerson) {
      return this.fetch(this.state.selection);
    }
  }

  fetch = (selection) => {
    let endpoint = [
      '/sales/history',
      this.props.region,
      this.props.salesPerson,
      selection.year,
      selection.type,
      selection.unit
    ].join('/') + '/';

    return this.props.fetchSalesDashRecords_ac('my_history', { endpoint });
  }

  handleFilterChange = event => {
    const { name, value } = event.target;

    this.setState((state, props) => ({
      selection: {
        ...state.selection,
        [name]: value
      }
    }));

    return this.fetch({
      ...this.state.selection,
      [name]: value
    });
  }

  getCountLink = history => {
    let workBucket = this.state.selection.type === '0'
      ? 'Previously Paid'.replace(/ /g, '_')
      : 'Setup This Month'.replace(/ /g, '_');

    let hrefUrl = [
      '/salesdash/wos',
      this.props.region,
      this.props.salesPerson,
      workBucket,
      history.category.replace(/ /g, '_'),
      this.state.selection.year,
      `0${history.month}`.slice(-2),
      '01'
    ].join('/') + '/';

    return hrefUrl;
  }

  isCountLink = history => {
    return (history.count > 0
      && !history.count.toString().startsWith('+')
      && !history.count.toString().includes('.'));
  }

  getTotalCounts = (countsObj, record) => {
    let totalCounts = { ...countsObj };
    const { headerList } = this.state;

    record.history_list.forEach(history => {
      if (totalCounts[headerList[history.month]]) {
        totalCounts[headerList[history.month]].count += parseFloat(history.count)
      }
    })

    return totalCounts;
  }

  render() {
    const { selection, headerList, yearOptions } = this.state;
    const { classes, salesdashRecords } = this.props;
    const { isFetchingRecords, recordError, myHistory } = salesdashRecords;

    let filtersComponent = (
      <Grid
        container
        spacing={16}
        direction="row-reverse"
        justify="center"
      >
        <Grid item xs={4} sm={3} md={2}>
          <CustomSelect
            name="unit"
            value={selection.unit}
            label="Qty / Pts"
            options={[
              { label: 'Qty', value: '0' },
              { label: 'Pts', value: '1' }
            ]}
            onChange={this.handleFilterChange}
            disabled={isFetchingRecords}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <CustomSelect
            name="type"
            value={selection.type}
            label="Comm. / Setup"
            options={[
              { label: 'Commission', value: '0' },
              { label: 'Setup', value: '1' }
            ]}
            onChange={this.handleFilterChange}
            disabled={isFetchingRecords}
          />
        </Grid>
        <Grid item xs={4} sm={3} md={2}>
          <CustomSelect
            name="year"
            value={selection.year}
            label="Year"
            options={yearOptions}
            onChange={this.handleFilterChange}
            disabled={isFetchingRecords}
          />
        </Grid>
      </Grid>
    );

    if (isFetchingRecords) {
      return (
        <>
          {filtersComponent}
          <Loader classes={{ message: classes.message }} />
        </>
      );
    }

    if (recordError) {
      return (
        <>
          {filtersComponent}
          <Typography
            color="error"
            className={classes.message}
          >
            Error fetchnig records!
        </Typography>
        </>
      );
    }

    if (!myHistory) {
      return (
        <>
          {filtersComponent}
          <div className={classes.message}>
            ...
        </div>
        </>
      );
    }

    const columnCounts = headerList.slice(1)
      .reduce((acc, curr, idx) => (
        Object.assign(acc, {
          [curr]: {
            count: 0,
            month: idx + 1
          }
        })
      ), {})

    return (
      <>
        {filtersComponent}
        <div className={classes.scrollableTableContainer}>
          <CountViewTable
            headerList={headerList}
            categories={myHistory.categories}
            cellList={myHistory.history_list}
            isCountLink={this.isCountLink}
            getCountLink={this.getCountLink}
            totalCounts={this.getTotalCounts(columnCounts, myHistory)}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = stateFromStore => ({
  salesdashRecords: stateFromStore.salesdashRecords
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSalesDashRecords_ac
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(MyHistory))
);
