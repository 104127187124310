export function fetchHdmsNotes_ac(acctMember) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'REQUEST_HDMS_NOTES_PKG', acctMember });

    let target_url = `/api/notes:get`;
    let postData = { acctMember, recordsPerPage: 500 };

    return window.sch
      .post(target_url, postData)
      .then((pkg) => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        dispatch({ type: 'RECEIVED_HDMS_NOTES_PKG', pkg: pkg });
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_RECEIVED_HDMS_NOTES_PKG', error });
      });
  };
}

export function clearNotesForRefresh_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'CLEAR_HDMS_NOTES_FOR_REFRESH' });
  };
}

export function fetchHdmsNoteHdrs_ac() {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'FETCH_NOTE_HDRS' });

    let target_url = `/api/notes:hdms:gethdrs`;
    return window.sch
      .get(target_url)
      .then((pkg) => {
        if (pkg.hdrs) {
          //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
          pkg.hdrs[pkg.selectedHdr.idx].checked = true;
          dispatch({ type: 'GOT_NOTE_HDRS', hdrs: pkg.hdrs });
          return;
        }
        throw new Error('Failed getting note headers');
      })
      .catch((error) => {
        console.error('error:', error);
        dispatch({ type: 'FAILED_NOTE_HDRS', error });
      });
  };
}
