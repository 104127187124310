import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setTitle_ac } from '../../actions/me.ac.js';

import HomeView from './Home';
import DoctorsView from './Doctors';

const styles = () => ({
  tabsRoot: {
    minHeight: 24,
    maxHeight: 48,
  },
  tabRoot: {
    minWidth: 40,
    '& .labelContainer': {
      padding: '6px 12px',
    },
    '&:first-child': {
      '& .labelContainer': {
        paddingLeft: 6,
      },
    },
    '&:last-child': {
      '& .labelContainer': {
        paddingRight: 6,
      },
    },
  },
  tabSpecificContainer: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
});

class IndexingLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }

  onTabSelect = (e, _selectedTab) => {
    let toPath = this.props.match.path.replace(/:tabName/, _selectedTab);
    this.props.changePage(toPath);
    return;
  };

  render() {
    const { classes, match } = this.props;
    const tabsClass = {
      root: classes.tabsRoot,
    };
    const tabClass = {
      root: classes.tabRoot,
      labelContainer: 'labelContainer',
    };

    return (
      <>
        <Tabs
          classes={tabsClass}
          value={match.params.tabName}
          onChange={this.onTabSelect}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="off">
          <Tab label="Sales rep home" value="home" classes={tabClass} />
          <Tab value="doctors" label="Doctors" classes={tabClass} />
        </Tabs>
        <div className={classes.tabSpecificContainer}>
          <Switch>
            <Route path="/sales/home" component={HomeView} />
            <Route path="/sales/doctors" component={DoctorsView} />
          </Switch>
        </div>
      </>
    );
  }
}

IndexingLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  masterSearch: stateFromStore.masterSearch,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTitle_ac,
      changePage: (path) => {
        return push(path.replace(/:tabParam/, ''));
      },
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(IndexingLanding))
);
