export function fetchExpiringCMNsReport_ac(params){
  //params passed in by thunk middleware
  return (dispatch) => {
    dispatch({type:'REQUEST_EXPIRING_CMNS_REPORT'})
    let path;
    if (params.by==='days'){
      path = (params.days_end) ?
      `/api/expiring-cmn-oxy/report/by_days_to_expiration/${params.days}/${params.days_end}`
      :
      `/api/expiring-cmn-oxy/report/by_days_to_expiration/${params.days}/${params.days}`
    }
    else if (params.by==='situation'){
      path = `/api/expiring-cmn-oxy/report/by_situation`;
    }

    return window.sch.post(path, params)
    .then( result => {
      dispatch({
        type: 'RECEIVED_EXPIRING_CMNS_REPORT',
        pkg: result.pkg
      })
    })
    .catch(rptErr=> {
      dispatch({
        type: 'FAILED_EXPIRING_CMNS_REPORT',
        rptErr
      })
    });
  }
}
