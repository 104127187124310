import {
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import ReactSelect from 'react-select';

const ReactHealthOnboardForm = (props) => {
  const { providers, clinicians, state, setState, disabled = false } = props;

  const title = (name) => (
    <Typography
      style={{
        fontSize: '14px',
        display: 'block',
        marginBottom: '12px',
        fontWeight: 'bold',
        color: 'grey',
        paddingLeft: '8px',
      }}>
      {name}
    </Typography>
  );

  const clinicianOptions =
    clinicians?.map((option) => ({
      value: option.clinician_id,
      label: `${option.first_name} ${option.last_name}`,
    })) || [];

  const providerOptions =
    providers?.map((option) => ({
      value: option.provider_id,
      label: `${option.provider_first_name} ${option.provider_last_name}`,
      NPI: option.npi,
      provider_last_name: option.provider_last_name,
    })) || [];

  return (
    <div>
      {title('Demographics')}
      <TextField
        required
        id="first_name"
        name="first_name"
        label="First Name"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.first_name}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, first_name: value }));
        }}
      />

      <TextField
        required
        id="last_name"
        name="last_name"
        margin="normal"
        label="Last Name"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.last_name}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, last_name: value }));
        }}
      />

      <TextField
        required
        type="date"
        id="birth_date"
        name="birth_date"
        margin="normal"
        label="Birth Date"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        value={state.birth_date}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, birth_date: value }));
        }}
      />

      <TextField
        required
        id="assigned_id"
        name="assigned_id"
        margin="normal"
        label="Patient ID"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.assigned_id}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, assigned_id: value }));
        }}
      />

      <TextField
        select
        required
        id="sex"
        name="sex"
        margin="normal"
        label="Gender"
        style={{ margin: 8, minWidth: 100 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        value={state.sex}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, sex: value }));
        }}>
        <MenuItem value={'M'}>Male</MenuItem>
        <MenuItem value={'F'}>Female</MenuItem>
        <MenuItem value={'O'}>Other</MenuItem>
      </TextField>

      <TextField
        id="phone"
        name="phone"
        margin="normal"
        label="Primary Phone"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.phone}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, phone: value }));
        }}
      />

      <TextField
        id="email"
        name="email"
        margin="normal"
        label="Email"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.email || ''}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, email: value }));
        }}
      />

      <Divider
        style={{
          margin: '10px 0px',
        }}
      />

      {title('Address')}

      <TextField
        id="city"
        name="city"
        margin="normal"
        label="City"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.city}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, city: value }));
        }}
      />

      <TextField
        id="state"
        name="state"
        margin="normal"
        label="State"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.state}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, state: value }));
        }}
      />

      <TextField
        id="zip"
        name="zip"
        margin="normal"
        label="Zip Code (5 digit)"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.zip}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, zip: value }));
        }}
      />

      <Divider
        style={{
          margin: '10px 0px',
        }}
      />

      {title('Equipment')}

      <TextField
        required
        type="date"
        id="therapy_start"
        name="therapy_start"
        margin="normal"
        label="Therapy Start"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        value={state.therapy_start}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, therapy_start: value }));
        }}
      />

      <TextField
        required
        id="sn"
        name="sn"
        margin="normal"
        label="Serial Number"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.sn || ''}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, sn: value }));
        }}
      />

      <TextField
        id="pin"
        name="pin"
        margin="normal"
        label="Pin"
        style={{ margin: 8 }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        variant="outlined"
        autoComplete="off"
        autoCapitalize="off"
        value={state.pin || ''}
        onChange={(e) => {
          const value = e.target.value;
          setState((state) => ({ ...state, pin: value }));
        }}
      />

      <Divider
        style={{
          margin: '10px 0px',
        }}
      />

      {title('Clinician and Provider')}

      {clinicians && (
        <FormControl
          style={{
            width: '40%',
            margin: 10,
          }}>
          <FormLabel
            component="label"
            style={{
              marginBottom: '8px',
            }}>
            Clinician
          </FormLabel>
          <ReactSelect
            required
            id="clinician"
            name="clinician"
            label="Clinician"
            placeholder="Select a Clinician"
            style={{ margin: 8 }}
            disabled={disabled}
            value={clinicianOptions.find((c) => c.value === state.clinician_id)}
            onChange={(selectedOption) => {
              const clinician_id = selectedOption.value;
              const clinician_username = clinicians.find(
                (c) => c.clinician_id === clinician_id
              ).clinician_username;

              setState((state) => ({
                ...state,
                clinician_id,
                clinician_username,
              }));
            }}
            options={clinicianOptions}
          />
        </FormControl>
      )}

      {providers && (
        <FormControl
          style={{
            width: '40%',
            margin: 10,
          }}>
          <FormLabel
            component="label"
            style={{
              marginBottom: '8px',
            }}>
            Provider
          </FormLabel>
          <ReactSelect
            required
            id="provider"
            name="provider"
            label="Provider"
            placeholder="Select a Provider"
            disabled={disabled}
            value={providerOptions.find((c) => c.value === state.provider_id)}
            onChange={(selectedOption) => {
              const { provider_id, NPI, provider_last_name } =
                selectedOption.value;

              setState((state) => ({
                ...state,
                provider_id,
                NPI,
                provider_last_name,
              }));
            }}
            options={providerOptions}
          />
        </FormControl>
      )}
    </div>
  );
};

export default ReactHealthOnboardForm;
