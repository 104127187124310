import {
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  updateLocationDetailsLocally_ac,
  updateLocationsListLocally_ac,
} from '../../../actions/idx_admin_locations.ac';
import { open_snack_ac } from '../../../actions/snack.ac';
import {
  EmailField,
  PhoneField,
  TagField,
  URLField,
} from '../../common/ComplexFields';
import SmallChip from '../../common/SmallChip';
import Loader from '../../Shared/Loader';
import {
  EDIT_MODE,
  FORM_MODE_MAP,
  G_PLACES_STUB_ID,
  INITIAL_LOCATION_FORM_DATA,
  MAX_CHIP_LABEL_LENGTH,
  MAX_CONTACT_DETAILS,
  MIN_TEXT_LENGTH,
} from './constants';

const createGooglePlaceService = (placesStubId) => {
  return new window.google.maps.places.PlacesService(
    document.getElementById(placesStubId)
  );
};

const styles = (theme) => ({
  fieldGroupContainer: {
    padding: '4px 4px 4px 8px',
    marginBottom: 8,
  },
  googleSearchSuggestionContainer: {
    boxShadow: theme.shadows[2],
    borderRadius: 2,
    backgroundColor: 'white',
  },
  googleSearchSuggestion: {
    ...theme.typography.body1,
    backgroundColor: 'inherit',
    padding: 8,
  },
  googleSearchSuggestionActive: {
    backgroundColor: '#c5c5c5',
  },
  stickyFormActions: {
    bottom: 0,
    paddingLeft: 8,
    position: 'sticky',
    backgroundColor: 'white',
    borderTop: '1px solid #0000001f',
  },
});

const IdxAdminLocationEditForm = (props) => {
  const {
    classes,
    formMode,
    locationInView,
    widgetMode = false,
    onSuccess,
    metaInfo,
  } = props;

  // Can be decided with 'idx-admin' role as well
  const requireMetaNote = widgetMode;

  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [isSavingLocation, setIsSavingLocation] = useState(false);
  const [formData, setFormData] = useState({ ...INITIAL_LOCATION_FORM_DATA });
  const [googlePlaceService, setGooglePlaceService] = useState(null);
  const [isOrderTypesLoading, setIsOrderTypesLoading] = useState(false);

  useEffect(() => {
    setIsOrderTypesLoading(true);

    window.sch
      .post('/api/idx/admin/lookups/list', { kind: 'order_type' })
      .then((res) => {
        setOrderTypeOptions([...res.rr]);
        setIsOrderTypesLoading(false);
      })
      .catch((error) => {
        setIsOrderTypesLoading(false);
      });
  }, []);

  useEffect(() => {
    if (formMode === EDIT_MODE && locationInView !== null)
      setFormData({
        addr1: locationInView.addr1 || '',
        addr2: locationInView.addr2 || '',
        city: locationInView.city || '',
        state: locationInView.state || '',
        zip: locationInView.zip || '',

        order_type: locationInView.order_type || '',
        name: locationInView.name || '',

        phones: locationInView.phone || [],
        emails: locationInView.email || [],
        faxes: locationInView.fax || [],
        urls: locationInView.url || [],

        tags: locationInView.tags || [],
        meta_note: locationInView.meta_note || '',
      });
  }, [formMode, locationInView]);

  const trimContactChipLabel = (value = '') => {
    if (value.length < MAX_CHIP_LABEL_LENGTH) return value;

    return value.slice(0, MAX_CHIP_LABEL_LENGTH) + '...';
  };

  const onGooglePlaceSelected = (e) => {
    let placeSerivice = null;

    if (!googlePlaceService) {
      placeSerivice = createGooglePlaceService(G_PLACES_STUB_ID);
      setGooglePlaceService(placeSerivice);
    } else {
      placeSerivice = googlePlaceService;
    }

    placeSerivice.getDetails(
      {
        fields: ['address_components', 'formatted_address'],
        placeId: e.place_id,
      },
      (addressData) => {
        let addressParts = _.keyBy(
          addressData.address_components.map((comp) => {
            comp.type = comp.types[0];

            return { ...comp };
          }),
          'type'
        );
        let newAddressFieldData = {
          addr1: [
            _.get(addressParts, 'street_number.short_name', ''),
            _.get(addressParts, 'route.short_name', ''),
          ]
            .join(' ')
            .trim()
            .toUpperCase(),
          city: _.get(addressParts, 'locality.short_name', '').toUpperCase(),
          state: _.get(
            addressParts,
            'administrative_area_level_1.short_name',
            ''
          ).toUpperCase(),
          zip: _.get(addressParts, 'postal_code.short_name', ''),
        };

        setFormData((formData) => ({ ...formData, ...newAddressFieldData }));
      }
    );
  };

  const handlePhoneNumberAdd = (newPhone) => {
    if (formData.phones.length >= MAX_CONTACT_DETAILS) return;

    if (formData.phones.findIndex((phone) => phone === newPhone) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Phone: '${newPhone}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      phones: [...formData.phones, newPhone],
    }));
  };

  const handlePhoneNumberDelete = (phoneIdx) => {
    let newPhoneList = [
      ...formData.phones.slice(0, phoneIdx),
      ...formData.phones.slice(phoneIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      phones: [...newPhoneList],
    }));
  };

  const handleEmailAdd = (newEmail) => {
    if (formData.emails.length >= MAX_CONTACT_DETAILS) return;

    if (formData.emails.findIndex((email) => email === newEmail) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Email: '${newEmail}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      emails: [...formData.emails, newEmail],
    }));
  };

  const handleEmailDelete = (emailIdx) => {
    let newEmailList = [
      ...formData.emails.slice(0, emailIdx),
      ...formData.emails.slice(emailIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      emails: [...newEmailList],
    }));
  };

  const handleFaxAdd = (newFax) => {
    if (formData.faxes.length >= MAX_CONTACT_DETAILS) return;

    if (formData.faxes.findIndex((fax) => fax === newFax) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Fax: '${newFax}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      faxes: [...formData.faxes, newFax],
    }));
  };

  const handleFaxDelete = (faxIdx) => {
    let newFaxList = [
      ...formData.faxes.slice(0, faxIdx),
      ...formData.faxes.slice(faxIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      faxes: [...newFaxList],
    }));
  };

  const handleURLAdd = (newURL) => {
    if (formData.urls.length >= MAX_CONTACT_DETAILS) return;

    if (formData.urls.findIndex((url) => url === newURL) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `URL: '${newURL}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      urls: [...formData.urls, newURL],
    }));
  };

  const handleURLDelete = (urlIdx) => {
    let newURLList = [
      ...formData.urls.slice(0, urlIdx),
      ...formData.urls.slice(urlIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      urls: [...newURLList],
    }));
  };

  const handleTagAdd = (newTag) => {
    if (formData.tags.length >= MAX_CONTACT_DETAILS) return;

    if (formData.tags.findIndex((tag) => tag === newTag) > -1)
      return props.open_snack_ac({
        variant: 'info',
        message: `Tag: '${newTag}' is already added!`,
      });

    setFormData((formData) => ({
      ...formData,
      tags: [...formData.tags, newTag],
    }));
  };

  const handleTagDelete = (tagIdx) => {
    let newTagist = [
      ...formData.tags.slice(0, tagIdx),
      ...formData.tags.slice(tagIdx + 1),
    ];

    setFormData((formData) => ({
      ...formData,
      tags: [...newTagist],
    }));
  };

  const handleFreeFormFieldChange = (event) => {
    const { name, value } = event.target;

    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  const handleLocationSave = () => {
    if (formData.name.length < MIN_TEXT_LENGTH)
      return props.open_snack_ac({
        variant: 'error',
        message: `'Name' field is REQUIRED with a value of minimum ${MIN_TEXT_LENGTH} characters`,
      });

    if (formData.order_type === '')
      return props.open_snack_ac({
        variant: 'error',
        message: `'Order Type' field is REQUIRED`,
      });

    let locationData = {
      name: formData.name,
      order_type: formData.order_type,
      address_pkg: {
        addr1: formData.addr1,
        addr2: formData.addr2,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
      },
      phone: formData.phones,
      fax: formData.faxes,
      url: formData.urls,
      email: formData.emails,
      tags: formData.tags,
      meta_note: formData.meta_note,
    };

    if (metaInfo) {
      locationData.meta_info = metaInfo;
    }

    if (formMode === EDIT_MODE) locationData._id = locationInView._id;

    setIsSavingLocation(true);
    window.sch
      .post(FORM_MODE_MAP[formMode].submitUrl, locationData)
      .then((res) => {
        props.open_snack_ac({
          variant: 'success',
          message: `Location ${FORM_MODE_MAP[formMode].successLabel} successfully!`,
        });

        if (!widgetMode && formMode === EDIT_MODE) {
          let localUpdateLocationData = {
            ...locationData,
            ...locationData.address_pkg,
          };

          delete localUpdateLocationData.address_pkg;

          props.updateLocationsListLocally_ac(localUpdateLocationData);
          props.updateLocationDetailsLocally_ac(localUpdateLocationData);
        }

        if (widgetMode && onSuccess) {
          onSuccess(res?.new_location || locationData);
        }

        props.onCancel();
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: err.message || `Failed to ${formMode} location!`,
        });
        setIsSavingLocation(false);
      });
  };

  const handleFormCancel = () => {
    props.onCancel();
  };

  return (
    <div style={{ padding: 4 }}>
      <div className={classes.fieldGroupContainer}>
        <Typography variant="body1" gutterBottom>
          <strong>Basic Details</strong>
        </Typography>
        <Grid container spacing={8}>
          <Grid item>
            <TextField
              required
              id="name"
              name="name"
              label={widgetMode ? 'Location name' : 'Name'}
              variant="standard"
              value={formData.name}
              placeholder="Enter Name"
              onChange={handleFreeFormFieldChange}
              helperText={`Min ${MIN_TEXT_LENGTH} characters required`}
            />
          </Grid>
          <Grid item>
            <TextField
              select
              required
              id="order_type"
              name="order_type"
              variant="standard"
              label="Order Type"
              style={{ minWidth: 150 }}
              value={formData.order_type}
              onChange={handleFreeFormFieldChange}
              helperText={isOrderTypesLoading ? 'Loading...' : null}>
              <MenuItem value="">
                <Typography variant="inherit" color="textSecondary">
                  None
                </Typography>
              </MenuItem>
              {orderTypeOptions.map((option) => (
                <MenuItem key={option._id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </div>

      <Divider style={{ marginBottom: 8 }} />

      <div className={classes.fieldGroupContainer}>
        <Typography variant="body1" gutterBottom>
          <strong>Address Details</strong>&ensp;
          <Typography variant="caption" inline>
            (Search and select from the search field to auto fill address
            fields)
          </Typography>
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <div style={{ display: 'none' }} id={G_PLACES_STUB_ID} />
            <div style={{ marginBottom: 4 }}>
              <GooglePlacesAutocomplete
                onSelect={onGooglePlaceSelected}
                placeholder={'Search for Address'}
                loader={
                  <div
                    className={[
                      classes.googleSearchSuggestionContainer,
                      classes.googleSearchSuggestion,
                    ].join(' ')}>
                    <Loader message="Loading..." />
                  </div>
                }
                renderInput={(props) => <TextField fullWidth {...props} />}
                autocompletionRequest={{
                  componentRestrictions: { country: 'us' },
                }}
                apiKey="AIzaSyCKDUYKNgUMVKHYNsdZ7S-s_W1nGMcWTJM"
                suggestionsClassNames={{
                  suggestion: classes.googleSearchSuggestion,
                  container: classes.googleSearchSuggestionContainer,
                  suggestionActive: classes.googleSearchSuggestionActive,
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <TextField
              id="addr1"
              name="addr1"
              label="Address 1"
              variant="standard"
              value={formData.addr1}
              placeholder="Enter Address 1"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="addr2"
              name="addr2"
              label="Address 2"
              variant="standard"
              value={formData.addr2}
              placeholder="Enter Address 2"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="city"
              name="city"
              label="City"
              variant="standard"
              value={formData.city}
              placeholder="Enter City"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="state"
              name="state"
              label="State"
              variant="standard"
              value={formData.state}
              placeholder="Enter State"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
          <Grid item>
            <TextField
              id="zip"
              name="zip"
              label="Zip"
              variant="standard"
              value={formData.zip}
              placeholder="Enter Zip"
              onChange={handleFreeFormFieldChange}
            />
          </Grid>
        </Grid>
      </div>

      <Divider style={{ marginBottom: 8 }} />

      <div className={classes.fieldGroupContainer}>
        <Typography variant="body1" gutterBottom>
          <strong>Contact Details</strong>
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>{widgetMode ? 'Location ' : ''}Phone(s)</strong>
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.phones.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.phones.map((phone, idx) => (
                <SmallChip
                  label={phone}
                  key={`${phone}_${idx}`}
                  onDelete={() => handlePhoneNumberDelete(idx)}
                />
              ))}
              <PhoneField
                withExtensionNumber
                onAdd={handlePhoneNumberAdd}
                restrictAddition={formData.phones.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>{widgetMode ? 'Location ' : ''}Email(s)</strong>
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.emails.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.emails.map((email, idx) => (
                <SmallChip
                  title={email}
                  key={`${email}_${idx}`}
                  label={trimContactChipLabel(email)}
                  onDelete={() => handleEmailDelete(idx)}
                />
              ))}
              <EmailField
                onAdd={handleEmailAdd}
                restrictAddition={formData.emails.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>{widgetMode ? 'Location ' : ''}Fax(es)</strong>
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.faxes.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.faxes.map((fax, idx) => (
                <SmallChip
                  label={fax}
                  key={`${fax}_${idx}`}
                  onDelete={() => handleFaxDelete(idx)}
                />
              ))}
              <PhoneField
                onAdd={handleFaxAdd}
                restrictAddition={formData.faxes.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>{widgetMode ? 'Location ' : ''}URL(s)</strong>
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.urls.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.urls.map((url, idx) => (
                <SmallChip
                  key={idx}
                  title={url}
                  onDelete={() => handleURLDelete(idx)}
                  label={trimContactChipLabel(decodeURIComponent(url))}
                />
              ))}
              <URLField
                onAdd={handleURLAdd}
                restrictAddition={formData.urls.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider style={{ margin: '8px 0px' }} />
      <div className={classes.fieldGroupContainer}>
        <Typography variant="body1" gutterBottom>
          <strong>Other{widgetMode ? ' location ' : ''}Details</strong>
        </Typography>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              <strong>Tag(s)</strong>
              <Typography variant="caption" color="inherit" inline>
                &ensp; (
                <strong>
                  {formData.tags.length}/{MAX_CONTACT_DETAILS}
                </strong>{' '}
                Added)
              </Typography>
            </Typography>
            <div className={classes.fieldGroupContainer}>
              {formData.tags.map((tag, idx) => (
                <SmallChip
                  label={tag}
                  key={`${tag}_${idx}`}
                  onDelete={() => handleTagDelete(idx)}
                />
              ))}
              <TagField
                onAdd={handleTagAdd}
                restrictAddition={formData.tags.length >= MAX_CONTACT_DETAILS}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      {requireMetaNote && (
        <Grid item style={{ marginTop: '-6px', marginBottom: '16px' }}>
          <TextField
            multiline
            fullWidth
            id="meta_note"
            name="meta_note"
            label="Additional Note"
            variant="standard"
            value={formData.meta_note}
            placeholder="Jot down the note here!"
            onChange={handleFreeFormFieldChange}
            helperText={'This note will be notified to concerned authorities!'}
          />
        </Grid>
      )}

      <Grid
        container
        spacing={8}
        className={!widgetMode ? classes.stickyFormActions : undefined}>
        <Grid item>
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={isSavingLocation}
            onClick={handleLocationSave}>
            {FORM_MODE_MAP[formMode].submitLabel}
            {widgetMode ? ' location' : ''}
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={handleFormCancel}
            disabled={isSavingLocation}>
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  locationInView: stateFromStore.idxAdminLocationDetails.location,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      open_snack_ac,
      updateLocationsListLocally_ac,
      updateLocationDetailsLocally_ac,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(IdxAdminLocationEditForm));
