import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
    //backgroundColor: theme.palette.background.paper,
  },
  appBarSpacer: {
    /*...theme.mixins.toolbar, */
    minHeight: 34
  },
  scrollArea: {
    overflowY: 'auto',
    height: '92vh'
  },
  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    height: '100vh',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  }
})

class Pagination extends React.Component {
  render() {
    const { pagination: pg, classes } = this.props
    if (!pg) {
      return <>No paging</>
    }

    let pageStatement = ''
    if (pg) {
      pageStatement = [
        'Displaying ' +
          (pg.start + 1) +
          '-' +
          pg.end +
          ' of ' +
          pg.totalRecords +
          ' Records matching filters:',
        'Page ' + (pg.currentPageNum + 1) + ' of ' + pg.totalPagesAvailable
      ].join(' - ')

      if (pg.totalPagesAvailable > 1) {
        return (
          <form className={classes.root} autoComplete="off">
            <FormControl className={classes.formControl}>
              <Select
                onChange={this.props.onPageChange}
                displayEmpty
                value={this.props.page}
                name="age"
                className={classes.selectEmpty}
              >
                {_.range(0, pg.totalPagesAvailable).map((pgNum,pi) => (
                  <MenuItem key={pi} value={pgNum}>
                    Page {1 + pgNum}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{pageStatement}</FormHelperText>
            </FormControl>
          </form>
        )
      }

      return <></>
    }
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Pagination))
)
