import moment from 'moment';

const relativeTime = (pastDate) => {
  if (!pastDate) return;
  return `${moment(pastDate).fromNow()} on ${moment(pastDate).format(
    'ddd MMMM Do YYYY, h:mm a'
  )}`;
};

const isValidDate = (dateString) => {
  if (!dateString) return;
  const regEx = /^\d{1,2}-\d{1,2}-\d{4}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  var date = new Date(dateString);
  var timeValue = date.getTime();
  if (!timeValue && timeValue !== 0) return false; // NaN value, Invalid date
  return true;
};
const isValidDateTime = (dateTimeString) => {
  const regEx = /^\d{2}-\d{2}-\d{4}\s \d{2}:\d{2} \s AM|am|PM|pm$/;
  if (!dateTimeString.match(regEx)) return false; // Invalid format
  var date = new Date(dateTimeString);
  var timeValue = date.getTime();
  if (!timeValue && timeValue !== 0) return false; // NaN value, Invalid date
  return true;
};

const formatDHM = (_minutes) => {
  let minutes = Math.abs(_minutes);
  // Calculate the number of days, hours, and minutes
  const days = Math.floor(minutes / 1440);
  const hours = Math.floor((minutes % 1440) / 60);
  const min = minutes % 60;

  // Initialize the result string
  let result = '';

  // Add the number of days to the result string
  if (days > 0) {
    result += `${days}d`;
  }

  // Add the number of hours to the result string
  if (hours > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${hours}h`;
  }

  // Add the number of minutes to the result string
  if (min > 0) {
    if (result.length > 0) {
      result += ' ';
    }
    result += `${min}m`;
  }

  // Return the result
  return _minutes > 0 ? `In ${result}` : _minutes < 0 ? `${result} Ago` : `Now`;
};

/**
 * Convert date to specified format.\
 * Pass 'ISO' specifically to get the date as ISO string.
 *
 * @param {any} date Date string or moment object
 * @param {string} dateFormat Format string
 */
const formatDate = (date, dateFormat = 'MM-DD-YYYY hh:mma') => {
  if (!date) return null;
  if (dateFormat === 'ISO') {
    return moment(date).toISOString();
  }

  return moment(date).format(dateFormat);
};

const toDisplayFormat = (date, incTime = true, incSeconds = false) => {
  return (
    date &&
    formatDate(
      date,
      !incTime ? 'LL' : incSeconds ? 'ddd, MMM DD, YYYY h:mm:ss A' : 'llll'
    )
  );
};

export default {
  relativeTime,
  isValidDate,
  toDisplayFormat,
  isValidDateTime,
  formatDate,
  formatDHM,
};
