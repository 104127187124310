import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import React from 'react'
import Loader from '../Shared/Loader'

export const SNLookupTableView = ({ snlookupsinview, classes }) => {
  const { isFetching, error, records } = snlookupsinview

  if (isFetching) {
    return <Loader classes={{ message: classes.errorMessage }} />
  }

  if (error) {
    return (
      <Typography color="error" className={classes.errorMessage}>
        Error fetching serial numbers
      </Typography>
    )
  }

  if (!records) {
    return null
  }

  if (!records.length) {
    return (
      <Typography className={classes.errorMessage}>
        No matching records found.
      </Typography>
    )
  }
  return (
    <>
      <div className={classes.scrollArea}>
        <Table padding="dense">
          <TableHead>
            <TableRow>
              <TableCell width="10%" style={{ padding: 8 }}>
                Order Id
              </TableCell>
              <TableCell width="10%" style={{ padding: 8 }}>
                Account
              </TableCell>
              <TableCell width="20%" style={{ padding: 8 }}>
                Product Code
              </TableCell>
              <TableCell width="20%" style={{ padding: 8 }}>
                Sched Date
              </TableCell>
              <TableCell width="20%" style={{ padding: 8 }}>
                Status
              </TableCell>
              <TableCell width="20%">S.N</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {records.map((record, idx) => (
              <TableRow key={idx}>
                <TableCell width="10%" style={{ padding: 8 }}>
                  {record.order_id}
                </TableCell>
                <TableCell width="10%" style={{ padding: 8 }}>
                  {record.account}
                </TableCell>
                <TableCell width="20%" style={{ padding: 8 }}>
                  {record.product_code}
                </TableCell>
                <TableCell width="20%" style={{ padding: 8 }}>
                  {record.sched_dt}
                </TableCell>
                <TableCell width="20%" style={{ padding: 8 }}>
                  {record.status}
                </TableCell>
                <TableCell width="20%" style={{ padding: 8 }}>
                  {record.serial_no}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
