import { actionTypes } from '../actions/sales_reps.ac';

export const initialState = {
  ocNotes: { isFetching: false, data: [], error: null },

  isAddingOCNote: false,
  addOCNoteError: null,
};

export function salesReps(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_OC_NOTES_PENDING:
      return {
        ...state,
        ocNotes: {
          ...state.ocNotes,
          isFetching: true,
        },
      };

    case actionTypes.FETCH_SALES_OC_NOTES_SUCCESS:
      return {
        ...state,
        ocNotes: {
          isFetching: false,
          data: action.data || [],
          error: null,
        },
      };

    case actionTypes.FETCH_SALES_OC_NOTES_ERROR:
      return {
        ...state,
        ocNotes: {
          isFetching: false,
          data: [],
          error: action.error,
        },
      };

    case actionTypes.ADD_SALES_OC_NOTE_PENDING:
      return {
        ...state,
        isAddingOCNote: true,
      };

    case actionTypes.ADD_SALES_OC_NOTE_SUCCESS:
      return {
        ...state,
        isAddingOCNote: false,
        addOCNoteError: null,
        ocNotes: {
          ...state.ocNotes,
          data: [action.data, ...state.ocNotes.data],
        },
      };

    case actionTypes.ADD_SALES_OC_NOTE_ERROR:
      return {
        ...state,
        isAddingOCNote: false,
        addOCNoteError: action.error,
      };

    default:
      return state;
  }
}
