import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  axiom_select_contract_ac,
  axiom_update_suggestions_ac,
} from '../../actions/axiom.ac';
import { truncate } from '../../util/string';
import SearchField from '../Shared/SearchField';
import { renderTables } from './render';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 4,
    width: '100%',
    overflowY: 'auto',
  },
  button: {
    marginTop: 24,
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%',
  },
  pagination: {
    padding: 12,
    paddingLeft: 0,
  },
  keyContainer: {
    background: '#CCCCCC',
    padding: '2px 4px',
    fontSize: '10px',
    borderTop: '1px solid #0A0A0A',
    borderLeft: '1px solid #0A0A0A',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  valueContainer: {
    background: '#F9E598',
    padding: '2px 4px',
    fontSize: '10px',
    borderTop: '1px solid #0A0A0A',
    fontWeight: 500,
    borderLeft: '1px solid #0A0A0A',
    borderRight: '1px solid #0A0A0A',
    display: 'flex',
    alignItems: 'center',
  },
  titleKeyContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#999999',
    padding: '2px 4px',
    fontWeight: 700,
    fontSize: '12px',
    borderTop: '1px solid #0A0A0A',
    borderLeft: '1px solid #0A0A0A',
  },
});

const ContractSearchField = (props) => {
  const {
    label,
    placeholder,
    selectContract,
    disabled = false,
    onSuggestionsReceived,
  } = props;

  const searchContracts = (apiEndPoint, { searchStr }) => {
    return window.sch.post(apiEndPoint, { searchStr });
  };

  const renderContractSuggestion = (suggestion = {}) => {
    return (
      <div>
        <span
          style={{
            fontSize: '12px',
            fontWeight: 'bold',
            color: '#000',
            marginTop: '3px',
            display: 'block',
          }}>
          {suggestion._id && <>{suggestion._id}</>}
        </span>
        <span
          style={{
            fontSize: '10px',
            display: 'block',
          }}>
          {truncate(
            suggestion.Main[3].Contracted,
            40,
            suggestion.Misc[3] || ''
          )}
        </span>
      </div>
    );
  };

  return (
    <SearchField
      clearOnSelect
      onSuggestionsReceived={onSuggestionsReceived}
      delay={500}
      minChars={3}
      variant="outlined"
      label={label !== undefined ? label : 'Search Axiom by Payer Name'}
      resultsKey="pkg.rr"
      disabled={disabled}
      fullWidth
      style={{ flexGrow: 1 }}
      search={searchContracts}
      selectSuggestion={selectContract}
      apiEndpoint="/api/axiom/contract/search"
      scrollableParentId="idxOrderingClinicians"
      renderSuggestion={renderContractSuggestion}
      helperText="Minimum 3 characters required"
      placeholder={
        placeholder !== undefined ? placeholder : 'Search Axiom by Payer Name'
      }
    />
  );
};

const Axiom = (props) => {
  const {
    classes,
    axiom,
    axiom_select_contract_ac,
    axiom_update_suggestions_ac,
  } = props;

  return (
    <div className={classes.root}>
      <Grid container className={classes.gridContainer}>
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          className={classes.gridItem}
          style={{ marginBottom: '8px' }}>
          <ContractSearchField
            onSuggestionsReceived={axiom_update_suggestions_ac}
            labe={null}
            placeholder={'Contract Payer'}
            selectContract={(c) => axiom_select_contract_ac(c)}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4}>
          <Button
            className={classes.button}
            style={{ marginLeft: '8px' }}
            variant="contained"
            small
            onClick={() => axiom_select_contract_ac(null)}>
            Clear
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            maxHeight: 'calc(100vh - 200px)',
            overflowY: 'auto',
            '-webkitOverflowScrolling': 'touch',
          }}>
          <div>
            {axiom?.selectedContract ? (
              renderTables(axiom.selectedContract, classes)
            ) : (
              <div
                style={{
                  fontSize: 11,
                  padding: 4,
                  marginTop: 8,
                  color: 'darkgray',
                }}>
                Selected Payer details from Axiom will display here
              </div>
            )}
          </div>

          {axiom.suggestions.length > 0 && (
            <div item xs={12} sm={12} md={12}>
              <label style={{ fontSize: 12, marginTop: 8, color: 'darkgray' }}>
                Recent Search Results (click to view details):
              </label>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {axiom.suggestions.map((contract) => (
                  <div
                    key={contract._id}
                    style={{
                      cursor: 'pointer',
                      fontSize: 12,
                      paddingBottom: 8,
                      width: 'fit-content',
                    }}
                    onClick={() => axiom_select_contract_ac(contract)}>
                    {contract._id}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (stateFromStore) => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  axiom: stateFromStore.axiom,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      axiom_select_contract_ac,
      axiom_update_suggestions_ac,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Axiom));
