import React, { useState, useEffect } from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Chip,
  Grid,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Cancel as CancelIcon, Check as CheckIcon } from '@material-ui/icons';

const MultiSelect = (props) => {
  const { label, name, onChange, value, options, style } = props;
  const [selectedNames, setSelectedNames] = useState(value);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setSelectedNames(value);
  }, [value]);

  const handleSelectChange = (event) => {
    setSelectedNames(event.target.value);
    onChange(event);
    setMenuOpen(false); // Close the menu when an item is selected
  };

  const handleChipDelete = (deletedId) => {
    const updatedSelectedNames = selectedNames.filter((id) => id !== deletedId);
    setSelectedNames(updatedSelectedNames);
    onChange({ target: { name, value: updatedSelectedNames } }); // Propagate the change to the parent component
  };

  return (
    <FormControl fullWidth={true} style={style}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        name={name}
        value={selectedNames}
        onChange={handleSelectChange}
        renderValue={(selected) => (
          <Grid container spacing={8} direction="row" wrap="wrap">
            {selected.map((data) => (
              <Chip
                style={{
                  height: '21px',
                }}
                key={data}
                label={options?.find((option) => option.value === data).label}
                onDelete={() => handleChipDelete(data)} // Call the custom function with the deleted item's ID
                deleteIcon={
                  <CancelIcon
                    fontSize="small"
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            ))}
          </Grid>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '200px',
            },
          },
        }}
        open={menuOpen} // Control the menu open state
        onOpen={() => setMenuOpen(true)} // Open the menu when requested
        onClose={() => setMenuOpen(false)}>
        {options.map((option, idx) => (
          <MenuItem
            key={idx}
            value={option.value}
            sx={{ justifyContent: 'space-between' }}>
            {option.label}
            {selectedNames.includes(option.value) && (
              <CheckIcon color="primary" />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

MultiSelect.propTypes = {
  label: PropTypes.string.isRequired, // Example: A required string for the label
  name: PropTypes.string.isRequired, // Example: A required string for the name
  onChange: PropTypes.func.isRequired, // Example: A required function for onChange
  value: PropTypes.arrayOf(PropTypes.number), // Example: Any type for the value
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired, // Example: A required string for each option's label
      value: PropTypes.number.isRequired, // Example: A required value for each option
    })
  ).isRequired, // Example: A required array of option objects
  style: PropTypes.object,
};

export default MultiSelect;
