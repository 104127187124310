import { actionTypes } from '../actions/open_counts_by_email.ac'

export function openCountsByEmail(
  state = {
    error: null,
    record: null,
    isFetching: false,
    email: ''
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING_OPEN_COUNTS_BY_EMAIL:
      return {
        ...state,
        isFetching: action.isFetching,
        email: action.email
      }

    case actionTypes.RECEIVED_OPEN_COUNTS_BY_EMAIL:
      return {
        ...state,
        record: action.record,
        error: null,
        email: action.email
      }

    case actionTypes.FAILED_OPEN_COUNTS_BY_EMAIL:
      return {
        ...state,
        record: null,
        error: action.error,
        email: action.email
      }

    default:
      return state
  }
}
