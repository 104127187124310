import endpoints from '../constants/endpoints';

/**
 * @function createAnnouncement
 * @description Post a notification to the server.
 * @param {Object} payload The notification payload.
 * @returns {Promise} A promise that resolves with the posted notification.
 */
export async function createAnnouncement(payload) {
  const data = await window.sch.post(endpoints.registerAnnouncements, payload);
  return data;
}

/**
 * @function getAnnouncements
 * @description Get a list of all announcements from the server.
 * @returns {Promise} A promise that resolves with the list of announcements.
 */
export async function getAnnouncements(payload) {
  const data = await window.sch.post(endpoints.getAnnouncements, payload);
  return data;
}
