import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { open_snack_ac } from '../../actions/snack.ac.js';
import TemplateList from './TemplateList.js';
import TemplateDetail from './TemplateDetail.js';
import {
  deleteTemplateMapping,
  fetchTemplateMappings,
  upsertTemplateMapping,
} from './api.js';
import {
  close_confirmation_dialog_ac,
  open_confirmation_dialog_ac,
} from '../../actions/dialog.ac.js';

class JotformLandingView extends React.Component {
  state = {
    selectedTemplate: null,
    isFetching: false,
    list: [],
    isSavingTemplate: false,
    isRemovingTemplate: false,
  };

  componentDidMount() {
    return this.fetchFormTemplates();
  }

  fetchFormTemplates = () => {
    fetchTemplateMappings({
      before: () => this.setState({ isFetching: true }),
      after: () => this.setState({ isFetching: false }),
      onSuccess: (data) => {
        this.setState({ list: data });
      },
      onError: (error) => {
        this.props.open_snack_ac({
          message: error.message || 'Unknown error',
          variant: 'error',
        });
      },
    });
  };

  addFormTemplate = (formId, callback) => {
    // Check if formId already exists
    if (this.state.list.find((template) => template.formId === formId)) {
      this.props.open_snack_ac({
        message: 'Template mapping already exists',
        variant: 'error',
      });

      return;
    }
    this.setState((state) => ({
      list: state.list.concat({
        formId,
        formName: '',
        modelSource: 'WO',
        metaKey: '',
        modelMapping: {},
        defaultValueMapping: {},
        changed: true,
      }),
    }));

    if (callback) {
      callback();
    }
  };

  saveFormTemplate = (form, callback) => {
    upsertTemplateMapping(form, {
      before: () => this.setState({ isSavingTemplate: true }),
      after: () => this.setState({ isSavingTemplate: false }),
      onSuccess: (updatedMapping) => {
        if (callback) {
          callback();
        }
        this.props.open_snack_ac({
          message: 'Template mapping saved successfully',
          variant: 'success',
        });

        this.setState((state) => ({
          list: state.list.map((template) =>
            template.formId === updatedMapping.formId
              ? updatedMapping
              : template
          ),
          selectedTemplate: updatedMapping,
        }));
      },
      onError: (error) => {
        this.props.open_snack_ac({
          message: error.message || 'Unknown error',
          variant: 'error',
        });
      },
    });
  };

  removeFormTemplate = (template, callback) => {
    if (!template._id) {
      this.setState((state) => ({
        list: state.list.filter((cT) => cT.formId !== template.formId),
        selectedTemplate: null,
      }));
      return;
    }
    this.props.open_confirmation_dialog_ac({
      title: 'Are you sure?',
      content: `This action cannot be undone!`,
      actions: [
        {
          label: 'Yes, Permanently Delete!',
          action: () => {
            deleteTemplateMapping(+template.formId, {
              before: () => this.setState({ isRemovingTemplate: true }),
              after: () => this.setState({ isRemovingTemplate: false }),
              onSuccess: (data) => {
                if (callback) {
                  callback();
                }
                this.props.open_snack_ac({
                  message: 'Template mapping removed successfully',
                  variant: 'success',
                });

                this.setState((state) => ({
                  list: state.list.filter(
                    (cT) => cT.formId !== template.formId
                  ),
                  selectedTemplate: null,
                }));
              },
              onError: (error) => {
                this.props.open_snack_ac({
                  message: error.message || 'Unknown error',
                  variant: 'error',
                });
              },
            });
            this.props.close_confirmation_dialog_ac();
            return true;
          },
        },
      ],
    });
  };

  handleTemplateClick = (template) => {
    if (template.formId === this.state.selectedTemplate?.formId) {
      return;
    }

    this.setState({ selectedTemplate: template });
  };

  handleTemplateMappingSaveClick = (tpl) => {
    this.saveFormTemplate(tpl);
  };

  handleTemplateMappingChange = (formId, newDetail) => {
    this.setState((state) => ({
      ...state,
      selectedTemplate: {
        ...state.selectedTemplate,
        ...newDetail,
        changed: true,
      },
      list: state.list.map((template) => {
        if (template.formId === formId) {
          return { ...template, ...newDetail, changed: true };
        }
        return template;
      }),
    }));
  };

  render() {
    const {
      list,
      selectedTemplate,
      isFetching,
      isSavingTemplate,
      isRemovingTemplate,
    } = this.state;

    return (
      <Grid container style={{ marginTop: '20px' }}>
        <Grid
          item
          xs={3}
          style={{
            borderRight: '2px solid rgba(0, 0, 0, 0.12)',
            height: '85vh',
            overflowY: 'auto',
          }}>
          <TemplateList
            isFetching={isFetching}
            list={list}
            selectedTemplate={this.state.selectedTemplate}
            onTemplateClick={this.handleTemplateClick}
            onAddClick={this.addFormTemplate}
            isSavingTemplate={isSavingTemplate}
            openSnack={this.props.open_snack_ac}
          />
        </Grid>
        <Grid
          item
          xs={9}
          style={{
            borderRight: '2px solid rgba(0, 0, 0, 0.12)',
          }}>
          <TemplateDetail
            isFetching={isFetching}
            template={selectedTemplate}
            onRemoveClick={this.removeFormTemplate}
            isRemovingTemplate={isRemovingTemplate}
            isSavingTemplate={isSavingTemplate}
            onChange={this.handleTemplateMappingChange}
            onSave={this.handleTemplateMappingSaveClick}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      open_snack_ac,
      open_confirmation_dialog_ac,
      close_confirmation_dialog_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles({})(JotformLandingView))
);
