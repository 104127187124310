import { open_snack_ac } from './snack.ac.js';
export const actionTypes = {
  FETCHING_REPORT_RECURRING: 'FETCHING_REPORT_RECURRING',
  RECEIVED_REPORT_RECURRING: 'RECEIVED_REPORT_RECURRING',
  FAILED_FETCHING_REPORT_RECURRING: 'FAILED_FETCHING_REPORT_RECURRING',
};

export const fetchRecurringReport_ac = (filterData = null, page = 0) => {
  return (dispatch) => {
    let params = filterData;

    dispatch({
      type: actionTypes.FETCHING_REPORT_RECURRING,
    });

    window.sch
      .post('/api/reports/recurring/automationtargets', { ...params, page })
      .then((res) => {
        dispatch({
          type: actionTypes.RECEIVED_REPORT_RECURRING,
          payload: res,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.FAILED_FETCHING_REPORT_RECURRING,
          error: error,
        });
        let errMsg = error.message || 'Error loading records';
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: errMsg,
          })
        );
      });
  };
};
