import { open_snack_ac } from './snack.ac.js'
function fetchDocRequests(param) {
  return dispatch => {
    //params passed in by thunk middleware
    let target_url = `/api/outbound/list`
    let postData
    if (typeof param === 'string') {
      postData = { by: 'idn', val: param.replace(/_/g, ' '), page: 0 }
      dispatch({ type: 'REQUEST_DOC_REQUESTS', idn: param })
    } else {
      dispatch({ type: 'REQUEST_DOC_REQUESTS', idn: null })
      postData = param
    }

    return window.sch
      .post(target_url, postData)
      .then(pkg => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        dispatch({
          type: 'RECEIVED_DOC_REQUESTS',
          records: pkg.rr,
          pagination: pkg.pagination
        })
      })
      .catch(error => {
        console.error('error:', error)
        dispatch({ type: 'FAILED_RECEIVED_DOC_REQUESTS', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Failed fetching MR requests'
          })
        )
      })
  }
}

export function commentDocRequest_ac(oDoc_id, comment_txt) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_UPDATE_FAXNUM' })
    let target_url = `/api/outbound/comment`
    return window.sch
      .post(target_url, { oDoc_id, txt: comment_txt })
      .then(pkg => {
        let _records = getState().docRequestsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_UPDATE_FAXNUM', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_UPDATE_FAXNUM', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Update failed'
          })
        )
      })
  }
}

export function updateTrmFaxNumber_ac(oDoc_id, mode_kind, mode_val) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_UPDATE_FAXNUM' })
    let target_url = `/api/outbound/update_trm_fax_number`
    return window.sch
      .post(target_url, { oDoc_id, mode_kind, mode_val })
      .then(pkg => {
        let _records = getState().docRequestsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_UPDATE_FAXNUM', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_UPDATE_FAXNUM', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Update failed'
          })
        )
      })
  }
}

export function attemptTransmission_ac(oDoc_id, opts) {
  return (dispatch, getState) => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTEMPT_TRANSMIT' })
    let target_url = `/api/outbound/trm`
    let params = { oDoc_id }
    if (opts) {
      params = { ...params, opts }
    }

    return window.sch
      .post(target_url, params)
      .then(pkg => {
        let _records = getState().docRequestsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_TRANSMIT', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message:
              'Transmission Failed - check if your attached PDFs are corrupt'
          })
        )
      })
  }
}

export function markTransmissionMan_ac(oDoc_id, mark_state) {
  return (dispatch, getState) => {
    dispatch({ type: 'ATTEMPT_TRANSMIT' })
    let target_url = `/api/outbound/mark`
    return window.sch
      .post(target_url, { oDoc_id, mark_state })
      .then(pkg => {
        let _records = getState().docRequestsInView.records.map(r => {
          if (r._id === pkg.oDoc._id) {
            return pkg.oDoc
          }
          return r
        })
        dispatch({ type: 'DONE_ATTEMPT_TRANSMIT', records: _records })
      })
      .catch(error => {
        dispatch({ type: 'FAILED_ATTEMPT_TRANSMIT', error })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'Update failed'
          })
        )
      })
  }
}

export const fetchDocRequests_ac = fetchDocRequests

// DOC REQUEST ACTIVITY RELATED
export function attachFilesToStore_ac(outboundFiles) {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTACH_DOC_REQ_FILES_TO_STORE', outboundFiles })
  }
}

export function resetFilesToStore_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'ATTACH_DOC_REQ_FILES_TO_STORE', outboundFiles: [] })
  }
}

export function resetDocRequestActivity_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'RESET_DOC_REQUEST_ACTIVITY' })
    dispatch({ type: 'ATTACH_DOC_REQ_FILES_TO_STORE', outboundFiles: [] })
  }
}

export function pre_createDocRequest_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'CREATE_DOC_REQUEST_ACTIVITY' })
  }
}

export function undo_createDocRequest_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'UNDO_CREATE_DOC_REQUEST_ACTIVITY' })
  }
}

export function createDocRequest_ac(pkg) {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'CREATE_DOC_REQUEST_ACTIVITY' })
    let target_url = `/api/outbound/create`
    let postData = { ...pkg }
    postData.idn = postData.idn.replace(/ /g, ' ')
    return window.sch
      .post(target_url, postData)
      .then(pkg => {
        //pkg: { "acctMember", "idnRecord", "newNotesCount", "counts", "pagination", "notes"}
        fetchDocRequests(pkg.idn)
        dispatch({ type: 'SUCCESS_DOC_REQUEST_ACTIVITY', successPkg: pkg })
        dispatch({ type: 'RESET_DOC_REQUESTS' })
      })
      .catch(error => {
        console.error('error:', error)
        dispatch({ type: 'FAILED_DOC_REQUEST_ACTIVITY' })
        dispatch(
          open_snack_ac({
            variant: 'error',
            message: 'MR Request creation failed'
          })
        )
      })
  }
}

export function getQrCodeHash_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({ type: 'GET_QR_CODE_HASH' })
    let target_url = `/api/hash:generate:enc/8`
    return window.sch
      .get(target_url)
      .then(pkg => {
        dispatch({ type: 'GOT_QR_CODE_HASH', qrCodeHash: pkg.hash })
      })
      .catch(error => {
        console.error('error:', error)
        dispatch({ type: 'FAILED_QR_CODE_HASH', qrCodeHash: null })
      })
  }
}
