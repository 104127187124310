import React from 'react';
import Select from 'react-select';
import { FormControl, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  inputLabel: {
    fontSize: 11,
    color: '#000000a3',
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: 5,
  },
});

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: 'none',
    borderBottom: '1px solid rgb(0 0 0 / 42%)', // Adjust the color and width as needed
    boxShadow: 'none',
    borderRadius: 0,
    '&:hover': {
      borderBottom: '2px solid #000', // Adjust the color and width as needed
    },

    valueContainer: (provided, state) => ({
      ...provided,
      padding: 0, // Adjust the padding as needed
    }),
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: '12px', // Adjust the font size as needed
    color: 'black', // Optional: Adjust the color as needed
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0, // Adjust the padding as needed
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    padding: '0', // Adjust the padding around the indicators as needed
  }),
};

const OPTIONS_LIMIT = 15;
const ReactSelect = ({
  name,
  value,
  label,
  styles,
  options,
  classes,
  onChange,
  components,
  placeholder,
}) => {
  return (
    <FormControl className={classes.formControl}>
      {label && (
        <label htmlFor={name} className={classes.inputLabel}>
          {label}
        </label>
      )}
      <Select
        isMulti
        name={name}
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        styles={{ ...styles, ...customStyles }}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
          ...components,
        }}
        inputId={name}
        isSearchable={options.length > OPTIONS_LIMIT}
      />
    </FormControl>
  );
};

export default withStyles(styles)(ReactSelect);
