export function idnWos(
  state = {
    isFetching: false,
    idn: null,
    error: null,
    activityPkg: null,
    pkg: null,
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_IDN_WOS':
      return Object.assign({}, state, {
        pkg: null,
        error: null,
        isFetching: true,
        idn: action.idn,
      });
    case 'FAILED_RECEIVED_IDN_WOS':
      return Object.assign({}, state, {
        pkg: null,
        error: action.error,
        isFetching: false,
      });
    case 'RECEIVED_IDN_WOS':
      return Object.assign({}, state, {
        pkg: action.pkg,
        error: null,
        isFetching: false,
      });
    case 'UPDATE_IDN_WOS_PKG':
      return Object.assign({}, state, {
        pkg: action.pkg,
      });
    case 'CLEAR_IDN_WOS_ACTIVITY_PKG':
      return Object.assign({}, state, {
        activityPkg: null,
      });
    case 'UPDATE_IDN_WOS_ACTIVITY_PKG':
      return Object.assign({}, state, {
        activityPkg: action.activityPkg,
      });
    case 'RESET_IDN_WOS':
      return Object.assign({}, state, {
        pkg: null,
        activityPkg: null,
        error: null,
        idn: null,
        isFetching: false,
      });

    default:
      return state;
  }
}
