import Button from '@material-ui/core/Button'
import { deepPurple } from '@material-ui/core/colors'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { cancelIvrActivity_ac } from '../actions/ivr_info.ac.js'
import CancelIvrForm from './CancelIvrForm'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  title: {
    flexGrow: 1
  },
  content: {
    marginTop: 0,
    flexGrow: 1,
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 2}px`,
    height: '100%',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    display: 'block',
    minWidth: 200
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 32,
    flexDirection: 'row'
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginBottom: 24,
    width: 500
  },
  scrollArea: {
    overflowY: 'auto',
    padding: 2,
    height: '100%'
  },
  scrollAreaInner: {
    overflowY: 'auto',
    height: '68%'
  },
  pagination: {
    marginTop: 0
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  sectionTitle: {
    paddingTop: 0,
    fontSize: 12,
    fontWeight: 700,
    color: '#000'
  },
  pcrAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: deepPurple[500]
  },
  idxNum: {
    marginTop: 10,
    paddingRight: 10,
    fontSize: 10
  },
  noteText: {
    display: 'block',
    color: '#1565c0'
  },
  inlinePointer: {
    display: 'inline',
    fontWeight: 800,
    cursor: 'pointer'
  },
  inline: {
    display: 'inline'
  },
  inlineBold: {
    display: 'inline',
    fontWeight: 700
  },
  tabLabelContainer: { padding: '3px 16px 3px 0' },
  byTabKlass: {
    minWidth: 12,
    minHeight: 24
  }
})

class CMNIvrActivityCard_ extends React.Component {
  constructor(props) {
    super(props)
    this.cancelIvr = this.cancelIvr.bind(this)
  }

  getDownloadUrl = s3key => () => {
    let target_url = `/api/s3/object/getsignedurl`
    return window.sch
      .post(target_url, { s3key: s3key })
      .then(pkg => {
        let download_url = pkg.signedUrl
        window.open(download_url, pkg.basName)
        return
      })
      .catch(error => {
        console.error('error:', error)
      })
  }

  onAccountPositionSelected(pos) {
    this.setState({ selectedAccountPosition: pos })
  }

  cancelIvr(cancelReason) {
    console.log('got cancel reason', cancelReason)
    let params = {
      acctMember: this.props.oxyCmnPkg._id,
      reason: cancelReason,
      workflow: 'medicare_oxygen'
    }
    return this.props.cancelIvrActivity_ac(params)
  }

  render() {
    const { classes, oxyCmnPkg } = this.props
    if (!oxyCmnPkg) return <>...loading</>

    return (
      <main className={classes.content} style={{ paddingTop: 16 }}>
        <div className={classes.pagination}>
          <Typography className={classes.sectionTitle}>
            Automated Expiring Oxy CMN Activity for {oxyCmnPkg._id}
          </Typography>
          <Typography className={classes.pageStatement}>
            Tree: {oxyCmnPkg.hdmsValues.ID_CMNShell}
            &nbsp;-&nbsp; Branch: {oxyCmnPkg.hdmsValues.ID_MyBranch}
            &nbsp;-&nbsp; Dr: {oxyCmnPkg.hdmsValues.CMN_MD_Last_Name},{' '}
            {oxyCmnPkg.hdmsValues.CMN_MD_First_Name}
          </Typography>
        </div>
        <div className={classes.scrollArea}>
          <List className={classes.notesList}>
            {oxyCmnPkg.nextAction && (
              <div>
                <Typography
                  component="strong"
                  className={classes.inlineBold}
                  color="textPrimary">
                  Next automated action: {oxyCmnPkg.nextAction.type} on{' '}
                  {oxyCmnPkg.nextAction.time}
                </Typography>
                <br />
                <CancelIvrForm classes={classes} onCancelIvr={this.cancelIvr} />
              </div>
            )}
            {oxyCmnPkg.msgs.map((p, idx) => (
              <ListItem alignItems="flex-start" key={idx}>
                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      className={classes.inline}
                      color="textPrimary">
                      {oxyCmnPkg.msgs.length - idx}){' '}
                      {'On ' + moment(p.ts).format('ddd M-D-YY hh:mma')}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        className={classes.inline}
                        color="textPrimary"
                      />
                      <Typography
                        component="span"
                        className={classes.noteText}
                        color="textPrimary">
                        {p.msg}
                      </Typography>
                      {_.get(p, 'kv', []).map((h, hIdx) => (
                        <React.Fragment key={hIdx}>
                          <br />
                          <span>{h[0]}</span>: <strong>{h[1]}</strong>
                        </React.Fragment>
                      ))}
                      {p.s3Key && (
                        <Typography
                          component="span"
                          className={classes.inlinePointer}
                          color="textPrimary">
                          <Button
                            size="small"
                            onClick={this.getDownloadUrl(p.s3Key)}>
                            Download
                          </Button>
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
      </main>
    )
  }
}

class CMNIvrActivityPanel_ extends React.Component {
  getDownloadUrl = s3key => () => {
    let target_url = `/api/s3/object/getsignedurl`
    return window.sch
      .post(target_url, { s3key: s3key })
      .then(pkg => {
        let download_url = pkg.signedUrl
        window.open(download_url, pkg.basName)
        return
      })
      .catch(error => {
        console.error('error:', error)
      })
  }

  render() {
    const { oxyCmnPkg, classes } = this.props
    return (
      <ExpansionPanel>
        <ExpansionPanelSummary
          style={{ margin: '6px 0' }}
          expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.pageStatement}>
            Tree: {oxyCmnPkg.hdmsValues.ID_CMNShell}
            &nbsp;-&nbsp; Branch: {oxyCmnPkg.hdmsValues.ID_MyBranch}
            &nbsp;-&nbsp; Dr: {oxyCmnPkg.hdmsValues.CMN_MD_Last_Name},{' '}
            {oxyCmnPkg.hdmsValues.CMN_MD_First_Name}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List className={classes.notesList}>
            {oxyCmnPkg.nextAction && (
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={
                    <Typography
                      component="strong"
                      className={classes.inlineBold}
                      color="textPrimary">
                      Next automated action: {oxyCmnPkg.nextAction.type} on{' '}
                      {oxyCmnPkg.nextAction.time}
                    </Typography>
                  }
                />
              </ListItem>
            )}

            {oxyCmnPkg.msgs.map((p, idx) => (
              <ListItem alignItems="flex-start" key={idx}>
                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      className={classes.inline}
                      color="textPrimary">
                      {oxyCmnPkg.msgs.length - idx}){' '}
                      {'On ' + moment(p.ts).format('ddd M-D-YY hh:mma')}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        className={classes.inline}
                        color="textPrimary"
                      />
                      <Typography
                        component="span"
                        className={classes.noteText}
                        color="textPrimary">
                        {p.msg}
                      </Typography>
                      {_.get(p, 'kv', []).map((h, hIdx) => (
                        <React.Fragment key={hIdx}>
                          <br />
                          <span>{h[0]}</span>: <strong>{h[1]}</strong>
                        </React.Fragment>
                      ))}
                      {p.s3Key && (
                        <Typography
                          component="span"
                          className={classes.inlinePointer}
                          color="textPrimary">
                          <Button
                            size="small"
                            onClick={this.getDownloadUrl(p.s3Key)}>
                            Download
                          </Button>
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  accountCMNsInView: stateFromStore.accountCMNsInView,
  ivrInfo: stateFromStore.ivrInfo,
  idnInView: stateFromStore.idnInView
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ cancelIvrActivity_ac }, dispatch)

CMNIvrActivityPanel_.propTypes = {
  classes: PropTypes.object.isRequired,
  oxyCmnPkg: PropTypes.object.isRequired
}

CMNIvrActivityCard_.propTypes = {
  classes: PropTypes.object.isRequired,
  oxyCmnPkg: PropTypes.object.isRequired
}

const CMNIvrActivityPanel = withStyles(styles)(CMNIvrActivityPanel_)

const CMNIvrActivityCard = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CMNIvrActivityCard_))
)

export { CMNIvrActivityCard, CMNIvrActivityPanel }
