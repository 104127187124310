import { Button, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { open_snack_ac } from '../../../actions/snack.ac';
import { getErrorMessage } from '../../../util/string';
import { RepsField } from '../../common/ComplexFields';

const RepsView = (props) => {
  const { reps, oc, onUpdate, readonly } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isSavingReps, setIsSavingReps] = useState(false);

  const handleRepChange = (rep, isAdding = true) => {
    setIsSavingReps(false);
    window.sch
      .post('/api/idx/admin/oc/change_rep', {
        oc_id: oc._id,
        rep_id: rep._id || rep.rep_id,
        rep_start: rep.s,
        rep_end: rep.e,
      })
      .then((res) => {
        props.open_snack_ac({
          variant: 'success',
          message: `Rep ${isAdding ? 'added' : 'updated'} successfully!`,
        });
        onUpdate();
        setIsEditMode(false);
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(
            err,
            `Failed to ${isAdding ? 'add' : 'update'} the rep!`
          ),
        });
        setIsEditMode(false);
      })
      .finally(() => {
        setIsSavingReps(false);
      });
  };

  const handleRepAdd = (rep) => {
    handleRepChange(rep);
  };

  const handleRepUpdate = (updatedRep) => {
    handleRepChange(updatedRep, false);
  };

  const handleRepDelete = (repIdx, rep) => {
    setIsSavingReps(false);
    window.sch
      .post('/api/idx/admin/oc/remove_rep', {
        oc_id: oc._id,
        rep_id: rep?._id || reps[repIdx].rep_id,
      })
      .then((res) => {
        props.open_snack_ac({
          variant: 'success',
          message: `Rep removed successfully!`,
        });
        onUpdate();
        setIsEditMode(false);
      })
      .catch((err) => {
        props.open_snack_ac({
          variant: 'error',
          message: getErrorMessage(err, 'Failed to delete the rep!'),
        });
        setIsEditMode(false);
      })
      .finally(() => {
        setIsSavingReps(false);
      });
  };

  const handleRepsEditModeToggle = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <div style={{ margin: '16px 0px' }}>
      <Typography
        variant="h6"
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: '12px' }}>
        {'Carve Out Reps: '}
      </Typography>
      <RepsField
        reps={reps}
        editMode={isEditMode}
        onRepAdd={handleRepAdd}
        onRepUpdate={handleRepUpdate}
        onRepDelete={handleRepDelete}
        placeholder={'Search a new rep to add.'}
      />
      {!readonly && (
        <Button
          size="small"
          color="primary"
          variant="outlined"
          disabled={isSavingReps}
          style={{
            marginTop: '12px',
          }}
          onClick={handleRepsEditModeToggle}>
          {isEditMode ? 'Cancel' : 'Edit Reps'}
        </Button>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      open_snack_ac,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(RepsView);
