import _ from 'lodash'
export function resetClaimsForAdjustments_ac() {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({
      type: 'RESET_CLAIMS_FOR_ADJ'
    })
  }
}

export function fetchClaimsForAdjustments_ac(idn, claimIds) {
  return dispatch => {
    //params passed in by thunk middleware
    dispatch({
      type: 'FETCH_CLAIMS_FOR_ADJ',
      isFetching: true,
      idn,
      claimIds
    })

    let proms = claimIds.map(claimId => {
      return new Promise((resolve, reject) => {
        return window.sch
          .get(`/api/claim/${claimId}`)
          .then(result => {
            return resolve(result.pkg.claimDoc)
          })
          .catch(error => {
            return reject(error)
          })
      })
    })

    return Promise.all(proms)
      .then(orig_records => {
        //explode claim lines
        let records = _.flatten(
          orig_records.map(r => {
            r.lines = r.lines
              /*
              .filter(line => {
                return (
                  line.tx.charge !== 0 ||
                  line.tx.mac !== 0 ||
                  line.tx.payment !== 0 ||
                  line.tx.adj !== 0
                )
              })
            */
              .map((line, lineIdx) => {
                line.claim = {
                  posInSet: lineIdx * 1,
                  claim_id: r.claim.claim_id,
                  account: r.claim.account,
                  member: r.claim.member,
                  claim_payer_name: r.claim.claim_payer_name
                }
                return _.pick(line, [
                  'claim',
                  'bill_id',
                  'tx',
                  'effective_item_hcpc',
                  'billing_date'
                ])
              })
            return r.lines
          })
        )

        let req_records = _.flatten(
          orig_records.map(r => {
            r.lines = r.lines
              .filter(line => {
                return (
                  line.tx.charge !== 0 ||
                  line.tx.mac !== 0 ||
                  line.tx.payment !== 0 ||
                  line.tx.adj !== 0
                )
              })
              .map((line, lineIdx) => {
                line.claim = {
                  posInSet: lineIdx * 1,
                  claim_id: r.claim.claim_id,
                  account: r.claim.account,
                  member: r.claim.member,
                  claim_payer_name: r.claim.claim_payer_name
                }
                return _.pick(line, [
                  'claim',
                  'tx',
                  'effective_item_hcpc',
                  'billing_date'
                ])
              })
            return r.lines
          })
        )

        let msg = {
          type: 'GOT_CLAIMS_FOR_ADJ',
          isFetching: false,
          disableAdjustment: false,
          records
        }
        if (req_records.length === 0) {
          msg.disableAdjustment = true
        }
        dispatch(msg)
      })
      .catch(error => {
        dispatch({
          type: 'FAILED_CLAIMS_FOR_ADJ',
          isFetching: false,
          error
        })
      })
  }
}

export function addAdjustmentHDMSNote_ac(params) {
  return (dispatch, getStore) => {
    let room = getStore().room
    let target_url = `/api/adj_requests/add_note`
    return window.sch
      .post(target_url, params)
      .then(res => {
        let msg = {
          kind: 'adj_note_added',
          success: true,
          msg: `HDMS Note added for Adjustment Request ID ${params._id}`
        }
        room.socket.emit('df_msg', msg, () => {
          dispatch({ type: 'SENT_DF_MSG', msg })
        })
      })
      .catch(error => {
        let msg = {
          kind: 'adj_note_added',
          success: false,
          msg: `HDMS Note failed to get added for Adjustment Request ID ${
            params._id
          }`
        }
        room.socket.emit('df_msg', msg, () => {
          dispatch({ type: 'SENT_DF_MSG', msg })
        })
      })
  }
}

export function getAdjustmentRequestDetail_ac(param) {
  return (dispatch, getStore) => {
    let room = getStore().room
    dispatch({ type: 'ATTEMPT_TRANSMIT' })
    let target_url

    if (param.account) {
      target_url = `/api/adj_requests/get_by_account/${param.account}`
    } else {
      target_url = `/api/adj_requests/get/${param._id}`
    }
    return window.sch
      .get(target_url)
      .then(res => {
        let msg = {}
        if (res.packet === null) {
          msg = {
            kind: 'get_adj_detail_response',
            success: false,
            msg: `Adj Request packet  not found`
          }
        } else {
          msg = {
            kind: 'get_adj_detail_response',
            success: true,
            msg: `Adj Request packet found`,
            packet: res.packet
          }
        }
        room.socket.emit('df_msg', msg, () => {
          dispatch({ type: 'SENT_DF_MSG', msg })
        })
      })
      .catch(error => {
        console.error(error)
        let msg = {
          kind: 'get_adj_detail_response',
          success: false,
          msg: `DF+ could not fetch adj request packet. System error`
        }
        room.socket.emit('df_msg', msg, () => {
          dispatch({ type: 'SENT_DF_MSG', msg })
        })
      })
  }
}

export function sendDfMsg_ac(msg) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    let room = getStore().room
    room.socket.emit('df_msg', msg, () => {
      dispatch({ type: 'SENT_DF_MSG', msg })
    })
  }
}

export function updateClaimRecordLocally_ac(recPos, updatedLineRecord) {
  return (dispatch, getStore) => {
    //params passed in by thunk middleware
    let records = getStore().adjustmentRequestsInView.records
    records[recPos] = updatedLineRecord
    dispatch({
      type: 'GOT_CLAIMS_FOR_ADJ',
      isFetching: false,
      records
    })
  }
}
