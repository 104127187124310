export function docRequestsInView(
  state={
    isFetching:false,
    idn: null,
    error: null,
    records: null,
    pagination: null,

    updateFaxNumInProgress: false,
    transmitInProgress: false,
    transmitActvityODocId: null
  },
  action
){
  switch (action.type) {
    case 'REQUEST_DOC_REQUESTS':
      return Object.assign({}, state, {
        records: null,
        error: null,
        pagination: null,
        isFetching: true,
        idn: action.idn
      })
    case 'FAILED_RECEIVED_DOC_REQUESTS':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        error: action.error,
        isFetching: false
      })
    case 'RECEIVED_DOC_REQUESTS':
      return Object.assign({}, state, {
        records: action.records,
        pagination: action.pagination,
        error: null,
        isFetching: false
      })

    case 'RESET_DOC_REQUESTS':
      return Object.assign({}, state, {
        records: null,
        pagination: null,
        error: null,
        params:null,
        idn: null,
        isFetching: false,
        transmitInProgress: false,
        transmitError: null
      })


    case 'ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: true,
       })
    case 'DONE_ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: false,
        records: action.records
       })
    case 'FAILED_ATTEMPT_UPDATE_FAXNUM':
      return Object.assign({}, state, {
        updateFaxNumInProgress: false,
        transmitErr: action.error
       })

    case 'ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: true,
       })
    case 'DONE_ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: false,
        records: action.records
       })
    case 'FAILED_ATTEMPT_TRANSMIT':
      return Object.assign({}, state, {
        transmitInProgress: false,
        transmitErr: action.error
       })

    default:
      return state
  }
}

export function docRequestActivity(
  state={
    outboundFiles: [],
    state:'new',
    error:false,
    successPkg: null,
    qrCodeFetchInProg: false,
    qrCodeHash: null,
  },
  action
){
  switch (action.type) {
    case 'ATTACH_DOC_REQ_FILES_TO_STORE':
      return Object.assign({}, state, {
        outboundFiles: action.outboundFiles
      })

    case 'GET_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeFetchInProg: true,
      })
    case 'GOT_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeHash:action.qrCodeHash,
        qrCodeFetchInProg: false
      })
    case 'FAILED_QR_CODE_HASH':
      return Object.assign({}, state, {
        qrCodeHash:null,
        qrCodeFetchInProg: false
      })


    case 'UNDO_CREATE_DOC_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        state:'new'
      })
    case 'CREATE_DOC_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        state:'inprog'
      })
    case 'FAILED_DOC_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        inProgress: false,
        state:'error',
        error: action.error
      })
    case 'SUCCESS_DOC_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        inProgress: false,
        state:'success',
        successPkg: action.successPkg,
        error: false
      })
    case 'RESET_DOC_REQUEST_ACTIVITY':
      return Object.assign({}, state, {
        outboundFiles: [],
        state:'new',
        qrCodeHash:null,
        qrCodeFetchInProg: false,
        successPkg: null,
        inProgress: false,
        error:false
      })
    default:
      return state
  }
}
