export function teams(
  state={
    isFetching:false,
    error:null,
    pkg: null
  },
  action
) {
  switch (action.type) {
    case 'REQUEST_TEAMS':
      return Object.assign({}, state, {
         isFetching: true
       })
    case 'RECEIVED_TEAMS':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: action.pkg,
         error:null
       })
    case 'FAILED_TEAMS':
      return Object.assign({}, state, {
         isFetching: false,
         pkg: null,
         error: action.error
       })
    default:
      return state
  }
}
