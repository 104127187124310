import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const PreviewPdfContent = (props) => {
  const { status, previewTabId, pdfUri } = props;
  return (
    <>
      {status === 'NEW' && (
        <div>
          {previewTabId && <h5>Preview for: {previewTabId}</h5>}
          <CircularProgress
            value={50}
            color="primary"
            variant="indeterminate"
            thickness={4}
          />
          <label>
            ...pdf preview rendering in progress, this should take a few
            seconds.
          </label>
        </div>
      )}

      {status === 'RENDERED' && (
        <>
          <div>
            <span style={{ fontSize: 12 }}>
              If PDF is not viewable below, try downloading it directly by
              clicking here:{' '}
              <a style={{ fontSize: 11 }} href={pdfUri} download="file.pdf">
                Download
              </a>
            </span>
          </div>
          <br />
          <embed
            type="application/pdf"
            src={pdfUri + '#zoom=50'}
            marginWidth="0"
            style={{ width: '99%', height: '94%' }}
          />

          {/*
            <iframe
            src={pdfUri}
            marginWidth="0" style={{width:'99%',height:'94%'}}
            frameBorder="0"
            allowfullScreen>
            ></iframe>
        */}
        </>
      )}
    </>
  );
};

export default PreviewPdfContent;
