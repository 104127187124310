import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
  fetchAllClientAppsList_ac,
  updateSelectedClientApps_ac,
} from '../../actions/client_apps.ac';
import dateUtil from '../../util/dateProcessing';
import BaseLandingTemplate from '../Reports/BaseLandingTemplate.Parent';
import Loader from '../Shared/Loader';

const styles = (theme) => ({
  root: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'auto',
  },

  content: {
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 72px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  details: {
    fontSize: '.92em',
    margin: '2px 8px 2px 0',
    padding: '2px 8px 2px 0',
  },
  title: {
    flexGrow: 1,
  },
  tableScrollable: {
    minHeight: '180px',
    height: 'calc(100% - 66px)',
    overflowY: 'auto',
  },
  tableRowSelected: {
    backgroundColor: 'yellow',
  },
  tableHeader: {
    height: 40,
    '& > th': {
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: 100,
      backgroundColor: '#fff',
    },
  },
  tableBodyCell: {
    borderBottom: 'unset',
    boxShadow: '0px 1px 2px 1px white',
  },
});

class ApplicationListComponent extends BaseLandingTemplate {
  constructor() {
    super();
    this.state = {
      selectedApplication: '',
    };
    this.fetch = this.fetch.bind(this);
  }

  viewClientApps = (app, mode) => {
    this.props.selectMode(mode);
    this.props.updateSelectedClientApps_ac(app);
    this.setState({
      selectedApplication: app._id,
    });
  };

  selectApp = (app) => {
    this.props.selectApplication(app);
    this.setState({
      selectedApplication: app._id,
    });
  };

  componentDidMount() {
    return this.fetch();
  }

  componentDidUpdate() {
    const { clientAppsInView } = this.props;
    if (
      clientAppsInView.isFetching === false &&
      !clientAppsInView.error &&
      clientAppsInView.records === null
    ) {
      return this.fetch();
    }
  }

  fetch() {
    let params = {};
    return this.props.fetchAllClientAppsList_ac(params);
  }

  render() {
    const { clientAppsInView, classes, forCommunicationPreference } =
      this.props;

    if (clientAppsInView.isFetching) {
      return (
        <Typography
          align="center"
          variant="h5"
          color="primary"
          style={{ width: '100%', padding: 8 }}>
          <Loader message="...loading records" />
        </Typography>
      );
    }

    if (clientAppsInView.records === null) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>...</h4>
        </div>
      );
    }

    if (clientAppsInView.error) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>....error fetching applications</h4>
        </div>
      );
    }

    if (!clientAppsInView.records.length) {
      return (
        <div className={classes.root}>
          <h4 className={classes.details}>No applications found.</h4>
        </div>
      );
    }

    const tblRowClass = {
      root: classes['tableRow'],
      selected: classes['tableRowSelected'],
    };

    return (
      <>
        <main className={classes.content}>
          <div className={classes.tableScrollable}>
            <Table padding="dense">
              <TableHead padding="dense">
                <TableRow className={classes.tableHeader}>
                  <TableCell align="left" width="20%">
                    Name / Mode
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Descriptions
                  </TableCell>
                  <TableCell align="left" width="30%">
                    Preferences
                  </TableCell>
                  {!forCommunicationPreference && (
                    <TableCell align="left" width="25%">
                      Key/Pairs
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {clientAppsInView.records.map((item, index) => {
                  let isSelected = this.state.selectedApplication === item._id;
                  return (
                    <TableRow
                      key={index}
                      selected={isSelected}
                      classes={tblRowClass}>
                      <TableCell
                        align="left"
                        width="20%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <div style={{ fontSize: 11, fontWeight: 'bold' }}>
                          {index + 1}. {item.nm}
                          <div
                            style={{
                              borderTop: '1px solid gray',
                              marginTop: 4,
                              paddingTop: 4,
                            }}>
                            {item.mode}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="25%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <span style={{ fontSize: 10 }}>{item.description}</span>
                        <span style={{ fontSize: 10, display: 'block' }}>
                          {item.created_ts
                            ? `Created ${dateUtil.relativeTime(
                                item.created_ts
                              )}`
                            : null}
                        </span>
                      </TableCell>
                      <TableCell
                        align="left"
                        width="30%"
                        variant="body"
                        className={classes.tableBodyCell}>
                        <div style={{ fontSize: 11, color: 'blue' }}>
                          {item.preferences?.length || 0} preferences
                        </div>
                        {!forCommunicationPreference && (
                          <Tooltip title="View or Add Preferences">
                            <Button
                              variant="contained"
                              onClick={() =>
                                this.viewClientApps(item, 'preferences')
                              }
                              size="small">
                              View or Add Prefs
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                      {forCommunicationPreference && (
                        <TableCell
                          align="left"
                          width="25%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          <Tooltip title="Select Application">
                            <Button
                              variant="contained"
                              onClick={() => this.selectApp(item)}
                              size="small">
                              Select
                            </Button>
                          </Tooltip>
                        </TableCell>
                      )}

                      {!forCommunicationPreference && (
                        <TableCell
                          align="left"
                          width="30%"
                          variant="body"
                          className={classes.tableBodyCell}>
                          <div style={{ fontSize: 11, color: 'blue' }}>
                            {item.keyPairs?.length || 0} pair(s) generated
                          </div>
                          <Tooltip title="View KeyPairs">
                            <Button
                              onClick={() =>
                                this.viewClientApps(item, 'key_pairs')
                              }
                              size="small">
                              View or Add KeyPairs
                            </Button>
                          </Tooltip>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </main>
      </>
    );
  }
}

ApplicationListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (stateFromStore) => ({
  clientAppsInView: stateFromStore.clientAppsInView,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllClientAppsList_ac,
      updateSelectedClientApps_ac,
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ApplicationListComponent))
);
