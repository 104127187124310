import { Button, Divider, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import TagsInput from '../common/TagsInput';
import { checkRule } from './api';

const RuleTester = (props) => {
  const [route, setRoute] = useState('');
  const [roles, setRoles] = useState([]);
  const [result, setResult] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const test = () => {
    if (!route) {
      return;
    }

    checkRule(roles, route, {
      before: () => {
        setIsProcessing(true);
      },
      onSuccess: (res) => {
        setResult(res);
      },
      onError: (err) => {
        props.openSnack({
          message: err.message || 'Failed to test rule!',
          variant: 'error',
        });
      },
      after: () => {
        setIsProcessing(false);
      },
    });
  };

  const reset = () => {
    setRoute('');
    setRoles([]);
    setResult(null);
  };

  return (
    <div
      style={{
        width: '100%',
      }}>
      <Typography
        style={{
          fontSize: 16,
        }}>
        Test Rule
      </Typography>
      <Divider
        style={{
          marginTop: 12,
          marginBottom: 12,
        }}
      />
      <Typography
        style={{
          fontSize: 14,
        }}>
        A user with roles:
      </Typography>
      <TagsInput
        margin="dense"
        variant="outlined"
        fullWidth
        disabled={isProcessing}
        style={{
          fontSize: 14,
          padding: 4,
        }}
        onChange={(newRoles) => {
          setRoles(newRoles);
        }}
        id="roles"
        name="roles"
        tags={roles}
        placeholder="Add Roles here"
      />
      <Typography
        style={{
          fontSize: 14,
          marginTop: 12,
          marginBottom: 6,
        }}>
        trying to access a route:
      </Typography>
      <TextField
        value={route}
        disabled={isProcessing}
        variant="outlined"
        placeholder="Enter Route"
        onChange={(e) => setRoute(e.target.value)}
        fullWidth
        style={{ fontSize: 14, color: 'blue', marginBottom: 12 }}
      />
      <div>
        <Button
          onClick={reset}
          disabled={isProcessing}
          style={{
            marginTop: 12,
            marginBottom: 12,
          }}
          color="primary"
          variant="outlined">
          Reset
        </Button>
        <Button
          onClick={test}
          disabled={isProcessing || !route}
          style={{
            marginTop: 12,
            marginBottom: 12,
            marginLeft: 12,
          }}
          color="primary"
          variant="outlined">
          Test Rule
        </Button>
      </div>

      <div
        style={{
          marginTop: 12,
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}>
        <Typography
          style={{
            fontSize: 13,
          }}>
          Result:
        </Typography>

        <Typography
          style={{
            fontSize: 16,
            marginLeft: 6,
            fontWeight: 'bold',
            color:
              result === true ? 'green' : result === false ? 'red' : 'grey',
          }}>
          {result === true ? 'Allowed' : result === false ? 'Denied' : 'N/A'}
        </Typography>
      </div>
    </div>
  );
};

export default RuleTester;
