import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { open_snack_ac } from '../../actions/snack.ac.js'
const styles = {}

class PricingCalc extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false
    }

    window.testDataSet = [
      {
        pcode: 'L8512',
        modif: 'NU',
        pdesc: 'Gel cap for trach voice pros - [NU]',
        price: 2.03,
        r1: ' $ 2.03 ',
        r2__: ' $ 2.03 \r',
        sss: 'L8512 Gel cap for trach voice pros - [NU]'
      },
      {
        pcode: 'L8514',
        modif: 'NU',
        pdesc: 'Repl trach puncture dilator - [NU]',
        price: 88.53,
        r1: ' $ 88.53 ',
        r2__: ' $ 88.53 \r',
        sss: 'L8514 Repl trach puncture dilator - [NU]'
      },
      {
        pcode: 'L8515',
        modif: 'NU',
        pdesc: 'Gel cap app device for trach - [NU]',
        price: 59.25,
        r1: ' $ 59.25 ',
        r2__: ' $ 59.25 \r',
        sss: 'L8515 Gel cap app device for trach - [NU]'
      }
    ]
  }

  componentDidMount() {
    this.checkState()
  }

  componentDidUpdate() {
    this.checkState()
  }

  checkState() {
    if (this.state.dataSet || this.state.isFetching) return

    this.setState({ isFetching: true })

    const contentWindowFunc = () => {
      let f = document.getElementById('inlineFrame')
      if (f === null) {
        console.log('waiting for inlineFrame to laod...')
        return setTimeout(contentWindowFunc, 1000)
      }
      f.contentWindow.postMessage(this.state.dataSet.pkg, '*')
      return
    }

    window.sch
      .get('/api/pricecalc/data')
      .then(d => {
        this.setState({
          isFetching: false,
          dataSet: d
        })

        setTimeout(contentWindowFunc, 2000)
        /*
        setTimeout(() => {
          let f = document.getElementById('inlineFrame')
          f.contentWindow.postMessage(d.pkg, '*')
        }, 2000)
        */
      })
      .catch(err => {
        console.error(err)
      })
  }

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <iframe
          style={{ width: '100%', height: '100%', border: 0 }}
          id="inlineFrame"
          name="priceCalcIframe"
          title="PricingCalcIframe"
          width="100%"
          height="100%"
          src="https://s3-us-west-2.amazonaws.com/pricingcalc.supercaredev.com/index.html"
          //src="http://localhost:4000"
        />
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      open_snack_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(PricingCalc))
)
