import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { fetchSalesDashRecords_ac } from '../../actions/salesdash.ac'
import Loader from '../Shared/Loader'
import StyledToolTip from '../Shared/StyledTooltip'
import BrownList from './BrownList'
import CustomSelect from './CustomSelect'
import HistoryList from './HistoryList'
import SummaryList from './SummaryList'

const styles = () => ({
  salesdashContainer: {
    height: 'calc(100% - 50px)',
    padding: '8px',
    width: 'calc(100vw - 48px)'
  },
  marginBottom16: {
    marginBottom: 16
  }
})

class SalesDashHome extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selection: {
        region: 'ALL',
        salesperson: '0'
      },
      currentTab: props.match.params.tabName
    }
  }

  componentDidMount() {
    const { lookups, me, lookups_ac } = this.props

    if (!me.user) return

    this.props.fetchSalesDashRecords_ac('refresh_ts', {
      endpoint: '/sales/refresh_ts/'
    })

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    return this.fetchSalesPersons(this.state.selection.region)
  }

  componentDidUpdate(prevProps) {
    const { salesdashSalesPersons, lookups, me, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }

    if (
      !salesdashSalesPersons.isFetchingSalesPersons &&
      !salesdashSalesPersons.salesPersonsError &&
      !salesdashSalesPersons.salesPersons
    ) {
      return this.fetchSalesPersons(this.state.selection.region)
    }

    if (prevProps.match.params.tabName !== this.props.match.params.tabName) {
      return this.setState((state, props) => ({
        currentTab: props.match.params.tabName
      }))
    }
  }

  fetchSalesPersons = region => {
    let endpoint = `/sales/person/list/${region}/`

    return this.props.fetchSalesDashRecords_ac('salespersons', { endpoint })
  }

  handleFilterChange = event => {
    const { name, value } = event.target
    const isNewRegion =
      name === 'region' && value !== this.state.selection.region

    if (!isNewRegion) {
      return this.setState((state, props) => ({
        selection: {
          ...state.selection,
          [name]: value
        }
      }))
    }

    this.setState((state, props) => ({
      selection: {
        ...state.selection,
        region: value,
        salesperson: '0'
      }
    }))

    return this.fetchSalesPersons(value)
  }

  getQuarterHeader = () => {
    const today = new Date()
    const monthYearStr = today.toLocaleDateString('default', {
      month: 'long',
      year: 'numeric'
    })
    const quarter = parseInt(today.getMonth() / 3) + 1

    return `${monthYearStr}, Q${quarter}`
  }

  render() {
    const { selection } = this.state
    const { classes, salesdashRefreshTS, salesdashSalesPersons } = this.props
    const {
      salesPersons,
      isFetchingSalesPersons,
      salesPersonsError
    } = salesdashSalesPersons
    const { refreshTS } = salesdashRefreshTS

    return (
      <div className={classes.salesdashContainer}>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sm={6}
            direction="column"
            justify="center"
            className={classes.marginBottom16}>
            <Grid item xs={12}>
              <Typography variant="h4">{this.getQuarterHeader()}</Typography>
              {refreshTS && moment(refreshTS).isValid() ? (
                <Typography variant="subtitle2" color="textSecondary">
                  HDMS Data Refreshed as of{' '}
                  {moment(refreshTS).format('MMMM DD, YYYY, hh:mma')}
                </Typography>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            spacing={16}
            direction="row"
            justify="center"
            className={classes.marginBottom16}>
            <Grid item xs={4} sm={6}>
              <CustomSelect
                name="region"
                label="Region"
                value={selection.region}
                options={[
                  { label: 'ALL', value: 'ALL' },
                  { label: 'NORCAL', value: 'NORCAL' },
                  { label: 'SOCAL', value: 'SOCAL' }
                ]}
                onChange={this.handleFilterChange}
              />
            </Grid>
            <Grid item xs={4} sm={6}>
              <CustomSelect
                name="salesperson"
                value={selection.salesperson}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>Salesperson</div>
                    {isFetchingSalesPersons && (
                      <Loader type="circular" size="15" />
                    )}
                    {salesPersonsError && (
                      <StyledToolTip title="Error Fetching Sales Persons!">
                        <ErrorOutlineIcon color="error" fontSize="small" />
                      </StyledToolTip>
                    )}
                  </div>
                }
                options={[
                  { label: 'ALL', value: '0' },
                  ...(salesPersons && salesPersons.length
                    ? salesPersons.map(person => ({
                        label: person.name,
                        value: `${person.id}`
                      }))
                    : [])
                ]}
                onChange={this.handleFilterChange}
                disabled={isFetchingSalesPersons}
              />
            </Grid>
          </Grid>
        </Grid>
        {(this.state.currentTab === 'my_progress' ||
          this.state.currentTab === 'my_buckets') && (
          <SummaryList
            selectedTab={this.state.currentTab}
            region={this.state.selection.region}
            salesPerson={this.state.selection.salesperson}
          />
        )}
        {this.state.currentTab === 'my_history' && (
          <HistoryList
            region={this.state.selection.region}
            salesPerson={this.state.selection.salesperson}
          />
        )}
        {this.state.currentTab === 'my_brown_list' && (
          <BrownList
            region={this.state.selection.region}
            salesPerson={this.state.selection.salesperson}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups,
  salesdashSalesPersons: stateFromStore.salesdashSalesPersons,
  salesdashRefreshTS: stateFromStore.salesdashRefreshTS
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSalesDashRecords_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SalesDashHome))
)
