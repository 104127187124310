import Button from '@material-ui/core/Button'
import { orange, purple } from '@material-ui/core/colors'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: orange
  },
  typography: {
    useNextVariants: true,
    fontSize: 12
  }
})
const styles = theme => ({
  filterRoot: {
    paddingLeft: 16
  },
  button: {
    backgroundColor: 'red',
    color: '#fff'
  },
  lbl: {
    fontSize: 11,
    color: '#9c27b0'
  },
  formControl: {
    display: 'block'
  },
  formGroupRoot: {
    marginTop: 8,
    marginBottom: 16,
    flexDirection: 'row'
  },
  textField: {
    marginRight: theme.spacing.unit,
    width: 200
  },
  title: {
    flexGrow: 1
  },
  content: {
    dispaly: 'flex',
    flexDirection: 'column',
    marginTop: 12,
    flexGrow: 1,
    padding: theme.spacing.unit * 0.5,
    height: 'calc(100% - 12px)',
    backgroundColor: '#fbf8f896',
    overflowY: 'hidden'
  },
  scrollArea: {
    overflowY: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  },
  label: {
    color: '#9c27b0',
    padding: '4px 8px 4px 0',
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase'
  },
  pageStatement: {
    paddingTop: 0,
    fontSize: 12
  },
  summaryEntryWrap: {
    marginBottom: 2
  },
  summaryEntry: {
    margin: '2px 2px 2px 0',
    padding: 0,
    fontSize: 12,
    fontWeight: 400
  },
  summaryCountWrap: {
    display: 'flex',
    direction: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 2
  },
  summaryCount: {
    flex: '1 40px',
    fontSize: '.82em'
  },
  spacer: {
    height: 40
  }
})

class PdfSplitForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: '',
      page_ranges: [],
      isProcessing: false,
      error: null,
      done: false
    }
    this.onPagesUpdate = this.onPagesUpdate.bind(this)
    this.apply = this.apply.bind(this)
  }

  onPagesUpdate(e) {
    if (this.state.isProcessing || this.state.done) return

    let splitAtPages = e.target.value
      .split(/,/)
      .map(k => k.trim())
      .map(k => parseInt(k, 10))
      .filter(k => !isNaN(k))
      .sort((a, b) => {
        return a > b ? 1 : -1
      })

    if (splitAtPages.length === 0) {
      this.setState({
        pages: e.target.value,
        page_ranges: []
      })
      return
    }

    let startPg = 1
    let page_ranges = []

    if (splitAtPages.length === 1 && splitAtPages[0] === 1) {
      //do nothing
    } else {
      // [ 22, 54 ] of a 72 page pdf -> [ 1-21, 22-54, 55-72]
      for (let i = 0; i < splitAtPages.length; i++) {
        let pg = splitAtPages[i] - 1
        if (startPg === pg) {
          page_ranges.push(startPg)
        } else {
          page_ranges.push([startPg, pg].join('-'))
        }
        startPg = 1 * splitAtPages[i]
      }
      page_ranges.push([startPg, 'Last Page'].join('-'))
    }

    this.setState({
      pages: e.target.value,
      page_ranges
    })
  }

  apply() {
    if (this.state.isProcessing || this.state.done) return

    this.setState({ isProcessing: true, error: null })

    let params = {
      gdriveFileId: this.props.pdf._id,
      splitAtPages: this.state.pages
    }
    return window.sch
      .post('/api/gdrive/pdfs/split_and_submit', params)
      .then(res => {
        this.setState({
          payload: null,
          pdfUri: null,
          done: true,
          msg: res.msg
        })
      })
      .catch(err => {
        this.setState({
          err: err
        })
      })
  }

  render() {
    const { classes, pdf } = this.props
    return (
      <FormControl
        required
        component="fieldset"
        className={classes.formControl}>
        <FormGroup className={classes.formGroupRoot}>
          <TextField
            label={'File: ' + pdf.name}
            onChange={this.onPagesUpdate}
            fullWidth
            value={this.state.pages}
            margin="normal"
          />

          {this.state.done === false && this.state.page_ranges.length > 0 && (
            <FormHelperText
              style={{
                color: '#000',
                fontWeight: 500,
                fontSize: 11,
                marginBottom: 12
              }}>
              <span style={{ color: 'blue' }}>
                PDF will be separated into {this.state.page_ranges.length} files
                comprised of pages: {this.state.page_ranges.join(', ')}
              </span>
            </FormHelperText>
          )}

          {this.state.page_ranges.length === 0 && (
            <FormHelperText
              style={{
                color: '#000',
                fontWeight: 500,
                fontSize: 11,
                marginBottom: 12
              }}>
              <span style={{ color: 'blue' }} />
            </FormHelperText>
          )}

          {this.state.page_ranges.length > 0 &&
            this.state.isProcessing === false &&
            this.state.done === false && (
              <Button color="primary" variant="outlined" onClick={this.apply}>
                Split and Send to Indexing Queue
              </Button>
            )}

          {this.state.done && (
            <FormHelperText
              style={{
                color: 'darkgreen',
                fontWeight: 800,
                fontSize: 13,
                marginBottom: 12
              }}>
              {this.state.msg}
            </FormHelperText>
          )}
        </FormGroup>
      </FormControl>
    )
  }
}

class CorrespondenceLockboxList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFetching: false,
      err: null,
      pdfs: null,
      msg: null
    }
    this.getList = this.getList.bind(this)
  }

  componentDidMount() {
    this.checkSituation()
  }
  componentDidUpdate() {
    this.checkSituation()
  }
  checkSituation() {
    if (
      this.state.isFetching === false &&
      this.state.err === null &&
      this.state.pdfs === null
    ) {
      return this.getList()
    }
    return
  }

  getList() {
    this.setState({ isFetching: true, pdfs: null, err: null })
    return window.sch
      .get('/api/gdrive/pdfs/list/1E1QAanmuJDBAkkXJf7-CTiiqRiqiuzdU')
      .then(res => {
        this.setState({
          pdfs: res.pdfs,
          isFetching: false
        })
      })
      .catch(err => {
        this.setState({
          err: err,
          isFetching: false
        })
      })
  }

  render() {
    const { classes } = this.props

    const { pdfs } = this.state
    if (pdfs === null) {
      return <div>...loading Correspondence PDFS</div>
    }

    return (
      <>
        <CssBaseline />
        <MuiThemeProvider theme={theme}>
          {/* <div className={classes.root}> */}
          <main className={classes.content}>
            {/* <div style={{ height: 38 }} /> */}
            <div className={classes.scrollArea} style={{ padding: 4 }}>
              <Grid container direction="row" spacing={8}>
                <Grid item xs={4} sm={4}>
                  <div style={{ fontSize: 13 }}>
                    <span style={{ color: 'black', marginRight: 4 }}>
                      Separate page numbers with commas. For example, enter
                    </span>
                    <span style={{ color: 'blue', marginRight: 4 }}>
                      21,41,61
                    </span>
                    <span style={{ color: 'black' }}>
                      to split an 80 page PDF into 4 pdfs made up of Pages 1-20,
                      21-40, 41-60, 61-80
                    </span>
                  </div>
                  <br />
                  {pdfs.map(p => (
                    <div key={p._id}>
                      <PdfSplitForm classes={classes} pdf={p} />
                    </div>
                  ))}
                </Grid>

                <Grid item xs={8} sm={8}>
                  {<div />}
                  <br />
                  {/*
                pdfUri &&
                <embed type="application/pdf"
                  src={pdfUri+'#zoom=50'}
                  marginWidth="0" style={{width:'99%',height:1200}}
                />
                */}
                </Grid>
              </Grid>
            </div>
          </main>
          {/* </div> */}
        </MuiThemeProvider>
      </>
    )
  }
}

CorrespondenceLockboxList.propTypes = { classes: PropTypes.object.isRequired }

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePage: path => push(path)
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CorrespondenceLockboxList))
)
