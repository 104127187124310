import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { lookups_ac } from '../../actions/me.ac'
import OpenCountsByEmail from './OpenCountsByEmail'

const styles = theme => ({
  message: {
    fontSize: '1em',
    padding: '8px'
  }
})

class OpenCountsLanding extends React.Component {
  constructor() {
    super()

    this.state = {}
  }

  componentDidMount() {
    this.checkSituation()
  }

  componentDidUpdate() {
    this.checkSituation()
  }

  checkSituation = () => {
    const { me, lookups, lookups_ac } = this.props

    if (!me.user) return

    if (lookups.ink === null) {
      if (lookups.isFetching) return

      return lookups_ac()
    }
  }

  render() {
    const { classes, lookups, match } = this.props

    if (!lookups.ink) {
      return <div className={classes.message}>...</div>
    }

    return <OpenCountsByEmail userEmail={match.params.email} />
  }
}

OpenCountsLanding.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  lookups: stateFromStore.lookups
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lookups_ac
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(OpenCountsLanding))
)
