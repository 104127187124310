import { Grid, Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
  internalsFile,
  readAllFiles,
  setCategory,
  setSubcategory,
  setSubsubcategory
} from '../../actions/notify_report.ac.js'

export function numCommas(x) {
  if (isNaN(x)) return x
  if (x > -1000 && x < 1000) return String(x)

  let n = String(x)
  let val = []
  for (let i = n.length - 1; i >= 0; i--) {
    if (i !== n.length - 1 && (n.length - 1 - i) % 3 === 0) val.push(',')
    val.push(n[i])
  }

  return val.reverse().join('')
}

const styles = theme => ({
  home: { fontSize: 11 },
  gc: {
    fontSize: 11
  },
  gi: {
    padding: 4,
    margin: 4,
    border: '1px solid #000'
  },
  table: {
    minWidth: 700
  },
  tr: {},
  td: {
    padding: 6
  },
  filterRoot: {
    paddingLeft: 16
  },
  button: {},
  title: {
    flexGrow: 1
  },
  tableContent: {
    marginTop: theme.spacing.unit * 3,
    overflowY: 'auto',
    overflowX: 'auto',
    '-webkitOverflowScrolling': 'touch',
    height: '100%'
  },
  pagination: {
    marginTop: 0
  }
})

class NotifyStats extends Component {
  constructor(props) {
    super(props)

    this.state = {
      window: { h: 0, w: 0 }
    }

    this.exportCSV = this.exportCSV.bind(this)
    this.renderIVRTable = this.renderIVRTable.bind(this)
    this.renderNotificationsTable = this.renderNotificationsTable.bind(this)
    this.renderCCSTable = this.renderCCSTable.bind(this)
    this.renderCustomersTable = this.renderCustomersTable.bind(this)
    this.renderContactTable = this.renderContactTable.bind(this)
  }

  componentDidMount() {
    this.props.internalsFile()
    this.props.readAllFiles()
  }

  exportCSV() {
    let fileName
    if (this.props.view.subsubcategory)
      fileName = `${this.props.view.category}_${this.props.view.subcategory}_${
        this.props.view.subsubcategory
      }.csv`
    else
      fileName = `${this.props.view.category}_${
        this.props.view.subcategory
      }.csv`

    let csv = ''
    for (let i = 0; i < this.table.length; i++) {
      csv += this.table[i].join(',') + '\r\n'
    }

    let blob = new Blob([csv], {
      type: 'text/csv;charset=utf-8;'
    })

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName)
    } else {
      let link = document.querySelector('#csv')
      if (link.download === undefined) {
        alert('This browser does not support CSV export')
      } else {
        let url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', fileName)
        link.click()
      }
    }
  }

  renderIVRTable() {
    if (
      !this.props.files.data.ivr ||
      Object.keys(this.props.files.data.ivr).length === 0
    )
      return null
    let data = JSON.parse(
      JSON.stringify(this.props.files.data.ivr[this.props.view.subsubcategory])
    )

    let dates = Object.keys(data)
    dates.sort((a, b) => {
      return a < b ? 1 : -1
    })

    let rows = [
      {
        title: 'Total called',
        desc: 'Total # of calls made'
      },
      {
        title: 'Unique recipients',
        desc: '# of unique phone numbers called'
      },
      {
        title: 'No answer',
        desc: '# of calls not picked up'
      },
      {
        title: 'Wrong number',
        desc:
          'Recipient indicated s/he is not the patient (capturing began 4/26/2020)'
      },
      {
        title: 'Incomplete',
        desc: 'Patient did not complete the IVR to the end of a flow'
      },
      {
        title: 'Completed flow',
        desc: 'Patient completed an IVR flow'
      },
      {
        title: 'Transferred',
        desc: "Patient's IVR flow resulted in a live transfer to CCS"
      },
      {
        title: 'Doctor changed',
        desc: 'Patient indicated that his/her doctor has changed'
      },
      {
        title: 'No appt - will schedule',
        desc: 'Patient will schedule an appointment'
      },
      {
        title: "No appt - won't schedule",
        desc: 'Patient will not schedule an appointment'
      },
      {
        title: 'Has appt - no date',
        desc: 'Patient has an appointment, but the date was not provided'
      },
      {
        title: 'Has appt - out of range',
        desc:
          'Patient has an appointment, but the date is out of accepted time range'
      },
      {
        title: 'Has appt - in range',
        desc: 'Patient has an appointment within the accepted time range'
      }
    ]

    let table = new Array(rows.length + 1)
    for (let i = 0; i < table.length; i++) {
      if (i === 0) {
        table[i] = [' '].concat(dates)
        continue
      }

      table[i] = new Array(dates.length + 1)
      table[i].fill(' ')
    }

    for (let i = 1; i < table.length; i++) {
      table[i][0] = rows[i - 1].title
    }

    for (let i = 1; i < table.length; i++) {
      for (let j = 1; j < table[i].length; j++) {
        let getVal1 = (param = 0) =>
          `${param} (${parseFloat(
            (100 * param) / data[dates[j - 1]].total
          ).toFixed(2)}%)`
        let getVal2 = (param = 0) =>
          `${param} (${parseFloat(
            (100 * param) / data[dates[j - 1]].uniqueDestinations
          ).toFixed(2)}%)`

        switch (i) {
          case 1:
            table[i][j] = data[dates[j - 1]].total
            break
          case 2:
            table[i][j] = data[dates[j - 1]].uniqueDestinations
            break
          case 3:
            table[i][j] = getVal1(data[dates[j - 1]].noAnswer)
            break
          case 4:
            table[i][j] = getVal2(data[dates[j - 1]].notCustomer)
            break
          case 5:
            table[i][j] = getVal1(data[dates[j - 1]].incomplete)
            break
          case 6:
            table[i][j] = getVal1(
              data[dates[j - 1]].total -
                data[dates[j - 1]].noAnswer -
                data[dates[j - 1]].incomplete
            )
            break
          case 7:
            table[i][j] = getVal2(data[dates[j - 1]].transferred)
            break
          case 8:
            table[i][j] = getVal2(data[dates[j - 1]].doctorChanged)
            break
          case 9:
            table[i][j] = getVal2(data[dates[j - 1]].noApptWillSched)
            break
          case 10:
            table[i][j] = getVal2(data[dates[j - 1]].noApptNoSched)
            break
          case 11:
            table[i][j] = getVal2(data[dates[j - 1]].hasApptNoDate)
            break
          case 12:
            table[i][j] = getVal2(data[dates[j - 1]].hasApptOutRange)
            break
          case 13:
            table[i][j] = getVal2(data[dates[j - 1]].hasApptInRange)
            break
          default:
            break
        }
      }
    }

    // For correct cumulative unique destionations
    let uniqueDestinations = {}
    let firstMonthOfIVR = table[0][table[0].length - 1]
    for (let i = 0; i < data[firstMonthOfIVR].destinations.length; i++) {
      if (!uniqueDestinations[data[firstMonthOfIVR].destinations[i]])
        uniqueDestinations[data[firstMonthOfIVR].destinations[i]] = true
    }

    if (this.props.view.subcategory === 'CUMULATIVE') {
      for (let i = 1; i < table.length; i++) {
        for (let j = table[i].length - 2; j >= 1; j--) {
          let a =
            typeof table[i][j] === 'string'
              ? Number(table[i][j].split(' ')[0])
              : table[i][j]
          let b =
            typeof table[i][j + 1] === 'string'
              ? Number(table[i][j + 1].split(' ')[0])
              : table[i][j + 1]
          let combined = a + b

          if (i > 5) {
            table[i][j] = `${combined} (${parseFloat(
              (100 * combined) / table[2][j]
            ).toFixed(2)}%)`
          } else if (i > 2) {
            table[i][j] = `${combined} (${parseFloat(
              (100 * combined) / table[1][j]
            ).toFixed(2)}%)`
          } else if (i === 2) {
            for (let k = 0; k < data[table[0][j]].destinations.length; k++) {
              if (!uniqueDestinations[data[table[0][j]].destinations[k]])
                uniqueDestinations[data[table[0][j]].destinations[k]] = true
            }
            table[i][j] = Object.keys(uniqueDestinations).length
          } else {
            table[i][j] = combined
          }
        }
      }
    }

    this.table = table

    return (
      <>
        <p>Explanations:</p>
        <p>
          - You are viewing results for the "{this.props.view.subsubcategory}"
          IVR campaign
        </p>
        <p>
          - "No answer", "Incomplete", and "Completed flow" percentages are
          based on total # of calls made, since they are associated with a call.
          Others are a percentage of the # of unique recipients called, since
          they are associated with a patient.
        </p>
        <div className={this.props.classes.tableContent}>
          <Table className={this.props.classes.table}>
            <TableBody>
              {table.map((row, i) => {
                return (
                  <TableRow className={this.props.classes.tr} key={i}>
                    {row.map((cell, j) => {
                      return (
                        <TableCell key={j} className={this.props.classes.td}>
                          {j === 0 && i > 0 ? (
                            <div
                              className="tooltipped"
                              data-position="right"
                              data-tooltip={rows[i - 1].desc}>
                              {cell}
                            </div>
                          ) : j > 0 &&
                            i > 0 &&
                            String(cell).split(' ').length > 1 ? (
                            `${numCommas(cell.split(' ')[0])} ${
                              cell.split(' ')[1]
                            }`
                          ) : (
                            numCommas(cell)
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }

  renderNotificationsTable() {
    let data = JSON.parse(
      JSON.stringify(
        this.props.files.data.notifications[this.props.view.subsubcategory]
      )
    )
    if (!data || Object.keys(data).length === 0) return null

    let dates = Object.keys(data)
    dates.sort((a, b) => {
      return a < b ? 1 : -1
    })

    let rowNames = [
      'Total sent',
      'Emails sent',
      'SMS sent',
      'Unique recipients',
      'Unique email addresses',
      'Unique phone numbers',
      'SMS Success %'
    ]

    let table = new Array(rowNames.length + 1)
    for (let i = 0; i < table.length; i++) {
      if (i === 0) {
        table[i] = [' '].concat(dates)
        continue
      }

      table[i] = new Array(dates.length + 1)
      table[i].fill(' ')
    }

    for (let i = 1; i < table.length; i++) {
      table[i][0] = rowNames[i - 1]
    }

    for (let i = 1; i < table.length; i++) {
      for (let j = 1; j < table[i].length; j++) {
        switch (i) {
          case 1:
            table[i][j] = data[dates[j - 1]].total
            break
          case 2:
            table[i][j] = data[dates[j - 1]].email
            break
          case 3:
            table[i][j] = data[dates[j - 1]].sms
            break
          case 4:
            table[i][j] = data[dates[j - 1]].unique.recipients
            break
          case 5:
            table[i][j] = data[dates[j - 1]].unique.email
            break
          case 6:
            table[i][j] = data[dates[j - 1]].unique.sms
            break
          case 7:
            if (dates[j - 1] === '2019-03' || dates[j - 1] === '2019-04') {
              table[i][j] = 'N/A'
            } else {
              let delivered = 0
              let total = 0
              let statuses = Object.keys(data[dates[j - 1]].status.sms)
              for (let k = 0; k < statuses.length; k++) {
                if (statuses[k] === 'DELIVERED')
                  delivered += data[dates[j - 1]].status.sms[statuses[k]]
                total += data[dates[j - 1]].status.sms[statuses[k]]
              }
              if (this.props.view.subcategory === 'CUMULATIVE')
                table[i][j] = { delivered, total }
              else
                table[i][j] = parseFloat((100 * delivered) / total).toFixed(2)
            }
            break
          default:
            break
        }
      }
    }

    if (this.props.view.subcategory === 'CUMULATIVE') {
      for (let i = 1; i < table.length; i++) {
        for (let j = table[i].length - 2; j >= 1; j--) {
          if (i === table.length - 1) {
            if (table[i][j] === 'N/A') {
              table[i][j] = 'N/A'
            } else if (table[i][j + 1] === 'N/A') {
              // Adding 0 basically
            } else {
              table[i][j].delivered += table[i][j + 1].delivered
              table[i][j].total += table[i][j + 1].total
            }
          } else {
            table[i][j] = table[i][j] + table[i][j + 1]
          }
        }
      }

      for (let i = 1; i < table[table.length - 1].length; i++) {
        let x = table[table.length - 1]
        if (x[i] !== 'N/A')
          x[i] = parseFloat((100 * x[i].delivered) / x[i].total).toFixed(2)
      }
    }

    this.table = table

    return (
      <>
        <p>Explanations:</p>
        <p>
          - You are viewing stats for the "{this.props.view.subsubcategory}"
          notification type
        </p>
        <p>
          - "Unique recipients" will not equal "unique email addresses" +
          "unique phone numbers" because a recipient can receive an email, an
          SMS, or both.
        </p>

        <div className={this.props.classes.tableContent}>
          <Table className={this.props.classes.table}>
            <TableBody>
              {table.map((row, i) => {
                return (
                  <TableRow className={this.props.classes.tr} key={i}>
                    {row.map((cell, j) => {
                      return (
                        <TableCell key={j} className={this.props.classes.td}>
                          {i > 0 && j > 0 ? numCommas(cell) : cell}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }

  renderCCSTable() {
    let data = JSON.parse(
      JSON.stringify(this.props.files.data.controlsInternal)
    )
    if (!data || Object.keys(data).length === 0) return null

    let dates = Object.keys(data)
    dates.sort((a, b) => {
      return a < b ? 1 : -1
    })

    if (this.props.view.subcategory === 'CUMULATIVE') {
      for (let i = dates.length - 2; i >= 0; i--) {
        data[dates[i]].total = data[dates[i]].total + data[dates[i + 1]].total

        let users = {}
        for (let j = 0; j < data[dates[i]].counts.length; j++) {
          let user = data[dates[i]].counts[j]
          if (users[user._id]) users[user._id].n += user.n
          else users[user._id] = user
        }
        for (let j = 0; j < data[dates[i + 1]].counts.length; j++) {
          let user = data[dates[i + 1]].counts[j]
          if (users[user._id]) users[user._id].n += user.n
          else users[user._id] = user
        }

        let newUsers = []
        let keys = Object.keys(users)
        for (let k = 0; k < keys.length; k++) {
          newUsers.push(users[keys[k]])
        }
        newUsers.sort((a, b) => {
          return a.n > b.n ? -1 : 1
        })

        data[dates[i]].counts = newUsers
      }
    }

    let maxLength = 0
    for (let i = 0; i < dates.length; i++) {
      maxLength = Math.max(maxLength, data[dates[i]].counts.length)
    }

    let table = new Array(maxLength + 2)
    for (let i = 0; i < table.length; i++) {
      if (i === 0) {
        table[i] = dates
        continue
      }

      table[i] = new Array(dates.length)
      table[i].fill(' ')
    }

    for (let i = 0; i < dates.length; i++) {
      table[1][i] = `Total: ${data[dates[i]].total}`
    }

    for (let i = 2; i < table.length; i++) {
      for (let j = 0; j < dates.length; j++) {
        if (data[dates[j]].counts.length > i - 2)
          table[i][j] = `${data[dates[j]].counts[i - 2]._id}: ${
            data[dates[j]].counts[i - 2].n
          }`
      }
    }

    this.table = table

    return (
      <>
        <p>Explanations:</p>
        <p>
          - "Total" = the total number of new patients onboarded for
          notifications. Remaining rows represent a team member and his/her
          number of new patients onboarded.
        </p>

        <div className={this.props.classes.tableContent}>
          <Table className={this.props.classes.table}>
            <TableBody>
              {table.map((row, i) => {
                return (
                  <TableRow className={this.props.classes.tr} key={i}>
                    {row.map((cell, j) => {
                      return (
                        <TableCell key={j} className={this.props.classes.td}>
                          {cell.split(' ')[0]} {numCommas(cell.split(' ')[1])}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }

  renderCustomersTable() {
    let data = JSON.parse(
      JSON.stringify(this.props.files.data.controlsExternal)
    )
    if (!data || Object.keys(data).length === 0) return null

    let dates = Object.keys(data)
    dates.sort((a, b) => {
      return a < b ? 1 : -1
    })

    let rows = [
      {
        title: 'Total created'
      },
      {
        title: 'Email only',
        desc: '# of patients who provided email address only'
      },
      {
        title: 'SMS only',
        desc: '# of patients who provided phone number only'
      },
      {
        title: 'Both email & SMS',
        desc: '# of patients who provided both'
      },
      {
        title: 'Unverified'
      },
      {
        title: 'Verified'
      },
      {
        title: 'Unsubscribed'
      },
      {
        title: 'Retries',
        desc: '# of patients CCS had to reattempt onboarding'
      }
    ]

    let table = new Array(rows.length + 1)
    for (let i = 0; i < table.length; i++) {
      if (i === 0) {
        table[i] = [' '].concat(dates)
        continue
      }

      table[i] = new Array(dates.length + 1)
      table[i].fill(' ')
    }

    for (let i = 1; i < table.length; i++) {
      table[i][0] = rows[i - 1].title
    }

    for (let i = 1; i < table.length; i++) {
      for (let j = 1; j < table[i].length; j++) {
        switch (i) {
          case 1:
            table[i][j] = data[dates[j - 1]].total
            break
          case 2:
            table[i][j] = data[dates[j - 1]].emailOnly
            break
          case 3:
            table[i][j] = data[dates[j - 1]].smsOnly
            break
          case 4:
            table[i][j] = data[dates[j - 1]].bothEmailSMS
            break
          case 5:
            table[i][j] = data[dates[j - 1]].unverified
            break
          case 6:
            table[i][j] = data[dates[j - 1]].verified
            break
          case 7:
            table[i][j] = data[dates[j - 1]].unsubscribed.orderStatus
            break
          case 8:
            table[i][j] = data[dates[j - 1]].retries
            break
          default:
            break
        }
      }
    }

    if (this.props.view.subcategory === 'CUMULATIVE') {
      for (let i = 1; i < table.length; i++) {
        for (let j = table[i].length - 2; j >= 1; j--) {
          table[i][j] = table[i][j] + table[i][j + 1]
        }
      }
    }

    this.table = table

    return (
      <>
        <p>Explanations:</p>
        <p>
          - These are records of onboarded patients in the database. They are
          associated with the month their record was created.
        </p>
        <p>
          - The values represent the state at the final day of that month. For
          the current month, the values represent the state at the last time the
          script was executed (weekly Monday script). There is another execution
          on the last day of the month for the final values.
        </p>
        <p>
          - We switched over to the new patient portal some time around May/June
          2020.
        </p>

        <div className={this.props.classes.tableContent}>
          <Table className={this.props.classes.table}>
            <TableBody>
              {table.map((row, i) => {
                return (
                  <TableRow className={this.props.classes.tr} key={i}>
                    {row.map((cell, j) => {
                      return (
                        <TableCell key={j} className={this.props.classes.td}>
                          {j === 0 && i > 0 && rows[i - 1].desc ? (
                            <div
                              className="tooltipped"
                              data-position="right"
                              data-tooltip={rows[i - 1].desc}>
                              {cell}
                            </div>
                          ) : i > 0 && j > 0 ? (
                            numCommas(cell)
                          ) : (
                            cell
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }

  renderContactTable() {
    // Not loaded yet
    if (!this.props.files.data.contact[this.props.view.subcategory]) return

    let data
    if (this.props.view.subsubcategory)
      data = this.props.files.data.contact[this.props.view.subcategory][
        this.props.view.subsubcategory
      ]
    else data = this.props.files.data.contact[this.props.view.subcategory]

    let headerMsg
    let columnNames
    let table
    let obj2Strs = obj => {
      let s = []
      for (let i = 0; i < Object.keys(obj).length; i++) {
        let key = Object.keys(obj)[i]
        if (key === 'Phone') s.push('p ' + obj[key])
        else if (key === 'AltPhone') s.push('ap ' + obj[key])
        else s.push('mp ' + obj[key])
      }
      return s.join(';')
    }
    let mult

    if (this.props.view.subcategory === 'diff') {
      headerMsg = `${data.length} records with different ${
        this.props.view.subsubcategory
      } values between notifications database and HDMS`
      columnNames = [
        'Account-Member',
        'AWS value',
        'HDMS value' + (this.props.view.subsubcategory === 'phone' ? '(s)' : '')
      ]
      columnNames = [...columnNames, ' ', ...columnNames]
      mult = 2
    } else if (this.props.view.subcategory === 'dynamoOnly') {
      headerMsg = `${data.length} records with ${
        this.props.view.subsubcategory
      } value in notifications database and not in HDMS`
      columnNames = ['Account-Member', 'AWS value']
      columnNames = [...columnNames, ' ', ...columnNames, ' ', ...columnNames]
      mult = 3
    } else if (this.props.view.subcategory === 'hdmsOnly') {
      headerMsg = `${data.length} records with ${
        this.props.view.subsubcategory
      } value in HDMS and not in notifications database`
      columnNames = [
        'Account-Member',
        'HDMS value' + (this.props.view.subsubcategory === 'phone' ? '(s)' : '')
      ]
      columnNames = [...columnNames, ' ', ...columnNames, ' ', ...columnNames]
      mult = 3
    } else {
      headerMsg = `${
        data.length
      } patient records in notifications database that do not exist in HDMS`
      columnNames = ['Account-Member', 'AWS email value', 'AWS phone value']
      mult = 1
    }

    table = new Array(Math.ceil(data.length / mult) + 1)
    for (let i = 0; i < table.length; i++) {
      table[i] = new Array(columnNames.length)
      table[i].fill(' ')
    }
    for (let i = 0; i < table[0].length; i++) {
      table[0][i] = columnNames[i]
    }

    console.log(`Table dimensions: ${table.length}r,${table[0].length}c`)
    console.log(
      `Supports up to ${table.length - 1}x${mult}=${(table.length - 1) *
        mult} items`
    )
    console.log('Data count', data.length)
    console.log('First, last elems', data[0].id, data[data.length - 1].id)

    for (let i = 1, d = 0; i < table.length; i++, d += mult) {
      if (this.props.view.subcategory === 'diff') {
        table[i][0] = data[d].id
        table[i][1] = data[d].dynamo
        table[i][2] =
          typeof data[d].hdms === 'object'
            ? obj2Strs(data[d].hdms)
            : data[d].hdms
        if (d + 1 < data.length) {
          table[i][4] = data[d + 1].id
          table[i][5] = data[d + 1].dynamo
          table[i][6] =
            typeof data[d + 1].hdms === 'object'
              ? obj2Strs(data[d + 1].hdms)
              : data[d + 1].hdms
        }
      } else if (this.props.view.subcategory === 'dynamoOnly') {
        table[i][0] = data[d].id
        table[i][1] = data[d].dynamo
        if (d + 1 < data.length) {
          table[i][3] = data[d + 1].id
          table[i][4] = data[d + 1].dynamo
        }
        if (d + 2 < data.length) {
          table[i][6] = data[d + 2].id
          table[i][7] = data[d + 2].dynamo
        }
      } else if (this.props.view.subcategory === 'hdmsOnly') {
        table[i][0] = data[d].id
        table[i][1] =
          typeof data[d].hdms === 'object'
            ? obj2Strs(data[d].hdms)
            : data[d].hdms
        if (d + 1 < data.length) {
          table[i][3] = data[d + 1].id
          table[i][4] =
            typeof data[d + 1].hdms === 'object'
              ? obj2Strs(data[d + 1].hdms)
              : data[d + 1].hdms
        }
        if (d + 2 < data.length) {
          table[i][6] = data[d + 2].id
          table[i][7] =
            typeof data[d + 2].hdms === 'object'
              ? obj2Strs(data[d + 2].hdms)
              : data[d + 2].hdms
        }
      } else {
        table[i][0] = data[d].id
        table[i][1] = data[d].email
        table[i][2] = data[d].phone
      }
    }

    this.table = table

    return (
      <>
        <p>Explanations:</p>
        <p>- {headerMsg}</p>
        {this.props.view.subsubcategory === 'phone' && (
          <p>- Legend: p = Phone, ap = AltPhone, mp = MobilePhone</p>
        )}
        <div className={this.props.classes.tableContent}>
          <Table className={this.props.classes.table}>
            <TableBody>
              {table.map((row, i) => {
                return (
                  <TableRow className={this.props.classes.tr} key={i}>
                    {row.map((cell, j) => {
                      if (cell && cell.includes(';')) {
                        return (
                          <TableCell key={j} className={this.props.classes.td}>
                            {cell.split(';').map((c, k) => {
                              return <p key={k}>{c}</p>
                            })}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={j} className={this.props.classes.td}>
                          {cell}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </>
    )
  }

  render() {
    // console.log('State:files', this.props.files)
    // if(this.props.files.filesRead === this.props.files.files.length)
    // console.log('State:files.data', this.props.files.data)

    return (
      <div className={this.props.classes.home}>
        <h4 className="light center">Notification Stats</h4>
        {this.props.files.filesRead < this.props.files.files.length ? (
          <h5 className="light center">
            Loading:{' '}
            {parseFloat(
              (this.props.files.filesRead * 100) / this.props.files.files.length
            ).toFixed(2)}
            %
          </h5>
        ) : (
          <div>
            <Grid container className={this.props.classes.gc} direction="row">
              <Grid item className={this.props.classes.gi}>
                Category
              </Grid>
              <Grid
                item
                className={this.props.classes.gi}
                onClick={() => {
                  this.props.setCategory('IVR')
                  this.props.setSubcategory('MONTHLY')
                  this.props.setSubsubcategory('ExpiringOxygenCMN')
                }}>
                IVR
              </Grid>
              <Grid
                item
                className={this.props.classes.gi}
                onClick={() => {
                  this.props.setCategory('NOTIFICATIONS')
                  this.props.setSubcategory('MONTHLY')
                  this.props.setSubsubcategory('OrderStatusSimple')
                }}>
                Notifications
              </Grid>
              <Grid
                item
                className={this.props.classes.gi}
                onClick={() => {
                  this.props.setCategory('CCS')
                  this.props.setSubcategory('MONTHLY')
                  this.props.setSubsubcategory(null)
                }}>
                CCS
              </Grid>
              <Grid
                item
                className={this.props.classes.gi}
                onClick={() => {
                  this.props.setCategory('CUSTOMERS')
                  this.props.setSubcategory('MONTHLY')
                  this.props.setSubsubcategory(null)
                }}>
                Customers
              </Grid>
              <Grid
                item
                className={this.props.classes.gi}
                onClick={() => {
                  this.props.setCategory('CONTACT')
                  this.props.setSubcategory('diff')
                  this.props.setSubsubcategory('email')
                }}>
                Customer contact info
              </Grid>
            </Grid>
            <Grid container className={this.props.classes.gc} direction="row">
              <Grid item className={this.props.classes.gi}>
                Subcategory
              </Grid>
              {this.props.view.category === 'CONTACT' ? (
                <>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubcategory('diff')
                      this.props.setSubsubcategory('email')
                    }}>
                    Differences AWS vs HDMS
                  </Grid>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubcategory('dynamoOnly')
                      this.props.setSubsubcategory('email')
                    }}>
                    Info in AWS, not in HDMS
                  </Grid>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubcategory('hdmsOnly')
                      this.props.setSubsubcategory('email')
                    }}>
                    Info in HDMS, not in AWS
                  </Grid>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubcategory('dynamoNoHDMS')
                      this.props.setSubsubcategory(null)
                    }}>
                    User only in AWS
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubcategory('MONTHLY')
                    }}>
                    Monthly
                  </Grid>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubcategory('CUMULATIVE')
                    }}>
                    Cumulative
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container className={this.props.classes.gc} direction="row">
              <Grid item className={this.props.classes.gi}>
                Subsubcategory
              </Grid>
              {this.props.view.category === 'IVR' ? (
                <Grid
                  item
                  className={this.props.classes.gi}
                  onClick={() => {
                    this.props.setSubsubcategory('ExpiringOxygenCMN')
                  }}>
                  Expiring oxygen CMN
                </Grid>
              ) : this.props.view.category === 'NOTIFICATIONS' ? (
                <Grid
                  item
                  className={this.props.classes.gi}
                  onClick={() => {
                    this.props.setSubsubcategory('OrderStatusSimple')
                  }}>
                  Order status
                </Grid>
              ) : this.props.view.category === 'CONTACT' &&
                this.props.view.subcategory !== 'dynamoNoHDMS' ? (
                <>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubsubcategory('email')
                    }}>
                    Email
                  </Grid>
                  <Grid
                    item
                    className={this.props.classes.gi}
                    onClick={() => {
                      this.props.setSubsubcategory('phone')
                    }}>
                    Phone
                  </Grid>
                </>
              ) : (
                <span>N/A</span>
              )}
            </Grid>
            {/*<div className="home-options">
              <div className="home-export" onClick={this.exportCSV}>
                Export data in this view to CSV
              </div>
              </div>*/}
            {this.props.view.category === 'IVR'
              ? this.renderIVRTable()
              : this.props.view.category === 'NOTIFICATIONS'
              ? this.renderNotificationsTable()
              : this.props.view.category === 'CCS'
              ? this.renderCCSTable()
              : this.props.view.category === 'CUSTOMERS'
              ? this.renderCustomersTable()
              : this.props.view.category === 'CONTACT'
              ? this.renderContactTable()
              : null}
            {this.props.files.internals && (
              <div className="home-internals">
                <p>
                  Last run:{' '}
                  {
                    new Date(this.props.files.internals.lastRun)
                      .toString()
                      .split('GMT')[0]
                  }
                </p>
                <p>
                  Next run:{' '}
                  {
                    new Date(this.props.files.internals.nextRun)
                      .toString()
                      .split('GMT')[0]
                  }
                </p>
              </div>
            )}
            <a id="csv" href="#!">
              &nbsp;
            </a>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = stateFromStore => ({
  me: stateFromStore.me,
  files: stateFromStore.notify_files,
  view: stateFromStore.notify_view
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      internalsFile,
      readAllFiles,
      setCategory,
      setSubcategory,
      setSubsubcategory
    },
    dispatch
  )

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(NotifyStats))
)
